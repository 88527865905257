import * as Hammer from 'hammerjs';
export class HammerControl
{
	public hammer: any;
	constructor(container:HTMLElement)
	{
		this.hammer = new Hammer(container);
		this.hammer.options.domEvents = true;
		// debugger;
		// this.hammer.options.threshold = 2;
		this.hammer.get('pan').set({ threshold: 2 });
		this.hammer.get('press').set({ enable: true });
		this.hammer.get('pinch').set({ enable: true });
		// this.hammer.get('tap').set({ enable: true });
		
		// hammertime.get('rotate').set({ enable: true });
		/*
		this.hammer = new Hammer(container);
		this.hammer.on("panstart", this.onPanStart.bind(this));
		this.hammer.on("panmove", this.onPanMove.bind(this));
		this.hammer.on("panend", this.onPanEnd.bind(this));
		this.hammer.on("")
		*/
	}

	public destroy():void
	{
		this.hammer.destroy();
	}
	/*

	private createEvent(evt:any):any
	{
		var customEvent:CustomEvent = new CustomEvent("hammer-"+evt.type, {
			detail:evt,
			bubbles:true, 
			cancelable:true
		});
		return customEvent;
	}
	onPanStart(evt:any)
	{
		evt.target.dispatchEvent(this.createEvent(evt));
	}
	onPanMove(evt:any)
	{
		evt.target.dispatchEvent(this.createEvent(evt));
	}
	onPanEnd(evt:any)
	{
		evt.target.dispatchEvent(this.createEvent(evt));
	}
	*/
}

/*
class HammerTransformEvent extends Event
{
	constructor(type:string, public data:any)
	{
		super(type);
	}
}
*/