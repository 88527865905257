import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators/debounceTime';
import { ThemeService } from 'src/app/service/theme.service';
@Component({
	selector: 'popup-toggle',
	template: `
	<ng-container *ngFor="let item of items;let last=last;">
		<ng-container *ngIf="!item.hideIf || !item.hideIf(item)">
			<div [ngClass]="['item', (toggle == item[bindValue] || toggle == item?'selected':'')]" [ngStyle]="(item.styles?item.styles:itemStyles)"
				(click)="clickSubject.next(item[bindValue])">
				<span [ngStyle]="(item.spanStyles?item.spanStyles:spanStyles)">{{ (item[bindLabel]) |translate}}</span>
			</div>
			<div [ngStyle]="options.separateStyles" *ngIf="options.separate && !last">{{options.separate}}</div>
		</ng-container>
	</ng-container>
    `,
	styleUrls: ['./popupToggle.component.scss']
})

export class PopupToggleComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public items: any[] = [];
	@Input() public toggle: any;
	@Input() public options:any={};
	@Input() public itemStyles:any;
	@Input() public spanStyles:any;
	@Input() public key = '';
	@Output() toggleChange = new EventEmitter();
	@Output() change = new EventEmitter();
	/*
	item = {label:string, value:string, styles:object, onEnter: callback, onLeave: callback}
	options = {themeStyles:object, hostStyles:object, itemStyles:object, bindLabel:string, bindValue: string}
	*/
	public selected;
	public bindLabel = 'label';
	public bindValue = 'value';
	public clickSubject: Subject<any> = new Subject<any>();
	
	constructor(private theme: ThemeService, private eleRef: ElementRef) {

	}

	ngOnInit(): void {
		this.initTheme();
		this.bindLabel = this.eleRef.nativeElement.getAttribute('bindLabel');
		this.bindValue = this.eleRef.nativeElement.getAttribute('bindValue');
		if (this.items.length > 0) {
			this.toggle = this.toggle ? this.toggle : this.items[0].value;
		}
		if (this.options){
			if (this.options.themeStyles){
				this.theme.applyStyleObj(this.options.themeStyles, this.eleRef.nativeElement);
			}
			if (this.options.hostStyles){
				this.eleRef.nativeElement.style = this.options.hostStyles;
			}
			if (this.options.itemStyles){
				this.itemStyles = this.options.itemStyles;
			}
			if (this.options.bindLabel){
				this.bindLabel = this.options.bindLabel;
			}
			if (this.options.bindValue){
				this.bindValue = this.options.bindValue;
			}
		}

		if (this.key != ''){
			this.loadFromLocalStorage();
		}

		this.clickSubject.subscribe(e => {
			if (e != this.toggle) {
				let found = this.items.find(e1=>e1[this.bindValue] == this.toggle);
				if (found){
					if (found.onLeave && (found.onLeave(this) == false)){
						return;
					}
				}
				found = this.items.find(e2=> e2[this.bindValue] == e);
				if (found){
					if (found.onEnter && (found.onEnter(this) == false) ){
						return;
					}
				}
				this.toggle = e;
				if (this.key != ''){
					localStorage.setItem('okaSelect-last-' + this.key, e);
				}
				this.toggleChange.emit(this.toggle);
				this.change.emit(e);
			}
		});
	}

	ngAfterViewInit(): void {
		
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	initTheme(){
		this.theme.getThemeJson("popupToggleModule.json").then((styleObj: any) => {
			const body = document.body;
			this.theme.applyStyleObj(styleObj, body);
		});
	}

	loadFromLocalStorage(){
		setTimeout(()=>{
			const localStorageData = localStorage.getItem('okaSelect-last' + this.key);
			if (localStorageData){
				const found = this.items.find(e=>{
					if (this.bindValue != ''){
						return e[this.bindValue] == localStorageData;
					} else {
						return e == localStorageData;
					}
				});
				if (found){
					const value = this.bindValue == ''?found:found[this.bindValue];
					this.clickSubject.next(value);
				}
			}
		});
	}

}
