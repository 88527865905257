import { Component, ElementRef, HostListener, OnDestroy, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/service/common.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { Subject, forkJoin } from 'rxjs';
import { NoRowsOverlay } from 'src/app/sharedModule/customAgGridModule/noRows.overlay';
import { AgGridService } from 'src/app/service/agGrid.service';
import { SubjectService } from 'src/app/sharedModule/subjectModule/subject.service';
import { CombinedRenderer } from '../customAgGridModule/combined.renderer';
import { DomSanitizer } from '@angular/platform-browser';
import { faTrash  } from '@fortawesome/pro-solid-svg-icons';
import { CheckBoxRenderer } from 'src/app/sharedModule/customAgGridModule/checkbox.renderer';
import { CheckBoxHeader } from 'src/app/sharedModule/customAgGridModule/checkbox.header';
import { BubbleBox2Component } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.component';
import { WhitePopupComponent } from 'src/app/sharedModule/whitePopupModule/whitePopup.component';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'question-aggrid',
	template: `
	<ag-grid-angular
		*ngIf="gridOptions && parent && parent.filter.datetime"
		class="ag-theme-alpine forList {{theme.theme$.value}}-theme question-aggrid" 
		[gridOptions]="gridOptions"
		[rowHeight]="50"
		></ag-grid-angular>

	<selectionPanel *ngIf="state != 'init'" [config]="selectionPanelConfig" [selectedItems]="selectedItems"></selectionPanel>

`,
	styleUrls: ['./question.aggrid.scss']
})

export class QuestionAggrid implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	@ViewChild('bubbleBox', { static: false }) bubbleBox: BubbleBox2Component;
	@ViewChild('whitePopup', { static: false }) whitePopup: WhitePopupComponent;
	public gridOptions;
	@Input() public questions = [];
	public subjects;
	public onDestroy = new Subject<void>();
	public bubbleBoxData;
	public whitePopupData;
	public faTrash = faTrash;
	public selectedItems: any[] = [];
	
	public selectionPanelConfig: any = {
		items: [
			{
				name: 'delete', icon: this.faTrash, iconSize: '18px', multiple: true, callback: () => {
					
				}
			}
		],
		style: { bottomLevel: 0, left: 0, width: '700px' }
	};
	public state = 'init';
	public lang;
	constructor(public datas: DataService, private trans: TranslateService, public ag: AgGridService, public coms: CommonService, private subject: SubjectService, private sans: DomSanitizer, private lds: LoadingService, private als: AlertService, public theme: ThemeService) {

	}

	ngOnInit(): void {
		this.lang = this.datas.lang;
	}

	ngAfterViewInit(): void {
		
	}

	ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}


}