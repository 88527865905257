import { ChangeDetectorRef, Component, ElementRef, ViewContainerRef } from "@angular/core";
import { fromEvent, ReplaySubject, Subject, Subscription } from "rxjs";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { DOMHelper } from "src/app/common/DOMHelper";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { CoordinateExpression } from "./CoordinateExpression";
import { DragManager } from "./DragManager";
import { ROComponent, ROScoringType } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";
import { XMLJSParser } from "./xml/XMLParser";
import { SelectMarkerIcon } from "./SelectMarkerIcon";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";

@Component({
	selector: 'ROSimpleDragLine',
	template:`
		<div class="container">
			
			<svg 
				[attr.viewBox]="svgViewBox" [attr.width]="w" [attr.height]="h" xmlns="http://www.w3.org/2000/svg" 
				overflow="visible"
				>
				<defs>
					<g id="selected-dot1" class="selected-dot">
						<circle 
							class="center"
							fill="#6E4C22"
							r="12"
							/>
						<circle 
							class="side"
							stroke="#6E4C22"
							fill="none"
							r="22.5"
							stroke-width="3"
							/>	
					</g>
					<g id="selected-dot2" class="selected-dot">
						<circle 
							class="center"
							fill="#358075"
							r="12"
							/>
						<circle 
							class="side"
							stroke="#358075"
							fill="none"
							r="22.5"
							stroke-width="3"
							/>	
					</g>
				</defs>
				<g *ngIf="!defaultAnswer">
					<g *ngFor="let line of lines" >
					<line 
						[attr.x1]="line.from.center.x"
						[attr.y1]="line.from.center.y"
						[attr.x2]="line.to.center.x"
						[attr.y2]="line.to.center.y"
						[attr.stroke]="lineColor"
						[attr.stroke-width]="lineWidth"
						/>
					</g>
				</g>
				
				<g *ngIf="isVerify && !defaultAnswer">
					<line 
						*ngFor="let line of lines" 
						class="verify"
						[class.correct]="line.correct"
						[class.incorrect]="line.incorrect"
						
						[attr.x1]="line.from.center.x"
						[attr.y1]="line.from.center.y"
						[attr.x2]="line.to.center.x"
						[attr.y2]="line.to.center.y"
						[attr.stroke]="lineColor"
						[attr.stroke-width]="lineWidth"
						/>
				</g>
				<g class="drawing" *ngIf="drawingLine">
					<line 
						[attr.x1]="drawingLine.from.center.x"
						[attr.y1]="drawingLine.from.center.y"
						[attr.x2]="drawingLine.to.center.x"
						[attr.y2]="drawingLine.to.center.y"
						[attr.stroke]="lineColor" 
						stroke-dasharray="10"
						/>

					<use 
						[attr.xlink:href]="drawingLine.from.aGroup == 0 ? '#selected-dot1' : '#selected-dot2' " 
						[class.group-0]="drawingLine.from.aGroup == 0"
						[class.group-1]="drawingLine.from.aGroup == 1"
						[attr.x]="drawingLine.from.center.x"
						[attr.y]="drawingLine.from.center.y"
					/>

					<use 
						*ngIf="drawingLine.to.douid"
						[attr.xlink:href]="drawingLine.to.aGroup == 0 ? '#selected-dot1' : '#selected-dot2' " 
						[class.group-0]="drawingLine.to.aGroup == 0"
						[class.group-1]="drawingLine.to.aGroup == 1"
						[attr.x]="drawingLine.to.center.x"
						[attr.y]="drawingLine.to.center.y"
					/>
				</g>

				<g class="dots"
					>
					<circle 
						
						*ngFor="let dot of dots" 
						[class.draging]="dot.draging"
						[class.group-0]="dot.aGroup == 0"
						[class.group-1]="dot.aGroup == 1"
						[attr.fill]="dot.aGroup == 0 ? '#6E4C22' : '#358075'"
						[attr.cx]="dot.center.x"
						[attr.cy]="dot.center.y"
						[attr.r]="dotRadius"
					/>
				</g>
				
				<g class="dots"
					>
					<circle 
						
						*ngFor="let dot of dots" 
						[class.draging]="dot.draging"
						[class.group-0]="dot.aGroup == 0"
						[class.group-1]="dot.aGroup == 1"
						[attr.fill]="dot.aGroup == 0 ? '#6E4C22' : '#358075'"
						[attr.cx]="dot.center.x"
						[attr.cy]="dot.center.y"
						[attr.r]="dotRadius"
					/>
				</g>

				<g  *ngIf="defaultAnswer">
					<g *ngFor="let line of defaultAnswer.lines" >
					<line 
						class="default-answer-line"
						[attr.x1]="line.from.center.x"
						[attr.y1]="line.from.center.y"
						[attr.x2]="line.to.center.x"
						[attr.y2]="line.to.center.y"
						stroke="#92C456"
						[attr.stroke-width]="lineWidth"
						
						/>
					</g>
				</g>

			</svg>
			<ng-container *ngIf="context.config.viewMode != 'edit' || _editInStage">
				<div *ngFor="let dot of dots" 
					class="dot-container" 
					[style.left.px]="dot.rect.x"
					[style.top.px]="dot.rect.y"
					[style.width.px]="dot.rect.w"
					[style.height.px]="dot.rect.h"
					[class.draging]="dot.draging"
					[ngClass]="[dot.douid]"
					(pointerdown)="dragStart(dot, $event, 'pointer')"
				></div>
			</ng-container>
			<ng-container *ngIf="_editInStage">
				<div *ngFor="let dot of dots"
					[ngClass]="'drag'+dot.aGroup"
					[style.left.px]="dot.aGroup==0 ? dot.rect.x - 42 : dot.rect.x + 50"
					[style.top.px]="dot.rect.y + 4"
					(pointerdown)="dragDotStart(dot, $event)"
				></div>
			</ng-container>
		</div>
		<div [ngClass]="['RW', correctState]"></div>
	`,
	styles:[

		`
			.RW.none{
				display:none;
			}
			.RW{
				position:absolute;
				right: -20px;
				top: -20px;
				width:40px;
				height:40px;
			}
			.RW.correct
			{
				background-image:url(./assets/img/component/verify/correct.svg);
			}
			.RW.partial_correct
			{
				background-image:url(./assets/img/component/verify/partial_correct.svg);
			}
			.RW.incorrect{
				background-image:url(./assets/img/component/verify/incorrect.svg);
			}
			svg{
				pointer-events: none;
			}
			.container{
				--line-color:#6E4C22;
			}
			line{
				stroke:var(--line-color);
				stroke-width: var(--line-width);
				stroke-linecap:round;
			}
			line.default-answer-line
			{
				stroke:#92C456;
			}
			line.verify{
				stroke: transparent;
			}
			line.correct{
				stroke: #92C45680;
				stroke-width: 10;
			}
			line.incorrect{
				stroke: #FF585880;
				stroke-width: 10;
			}
			.dot-container{
				z-index:var(--top-z-index);
				position:absolute;
				display:flex;
				align-items: center;
				justify-content: center;
				touch-action: none;
			}

			circle.dot{
				fill:var(--dot-color);
				r:var(--dot-radius);
			}

			.group-0{
				--dot-color:#6E4C22;
			}
			.group-1{
				--dot-color:#358075;
			}
			.selected-dot circle.center{
				fill:var(--dot-color);
			}
			.selected-dot circle.side{
				stroke:var(--dot-color);
			}
			.drag0,.drag1{width:42px; height:42px;cursor: move;background-repeat: no-repeat;background-position: center;position:absolute}
			.drag0{background-image:url(./assets/ro/theme/component_drag_line_move1.svg);}
			.drag1{background-image:url(./assets/ro/theme/component_drag_line_move2.svg);}
		`
	]
})
export class ROSimpleDragLine extends ROComponent
{
	
	public correctState:string = 'none';
	public dots:any [] ;
	public svgViewBox:string;
	public lines:any = [];
	public drawingLine:any;
	private correctAnswer:any;
	public isVerify:boolean;
	private dd:DragManager;
	public lineCount:number;

	public dotRadius:number;
	public lineColor:string = "#6E4C22";
	public lineWidth:number;
	public defaultAnswer:any = null;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.dd = new DragManager();
		this.answerChanged = true;
		this.canEditInside = true;
	}
	
	public hideDefaultAnswer(): void {
		this.defaultAnswer = null;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}

	protected _getCorrectAnswer():any[] {
		var tmp:any[] = [];
		for(var key in this.correctAnswer)
		{
			var targets:any []= this.correctAnswer[key];
			var fromDot = this.getDotByID(key);
			targets.forEach((targetID:string)=>{
				var toDot = this.getDotByID(targetID);
				if(fromDot && toDot)
				{
					tmp.push({
						from:fromDot,
						to:toDot
					})
				}
			});
		}

		return tmp;
	}

	public showDefaultAnswer(): void 
	{
		this.defaultAnswer = {lines:this._getCorrectAnswer()};
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}
	public getTagNames():string []
	{
		return ["SimpleDragLine"];
	}

	static getDefaultXMLString(douid:string = null, canDelete:boolean = true):string {
		if(!douid)
			douid = GUID.create("OKD guid");

		var a:string = GUID.create("OKD guid");
		var b:string = GUID.create("OKD guid");
		var c:string = GUID.create("OKD guid");
		var d:string = GUID.create("OKD guid");
		var e:string = GUID.create("OKD guid");
		var f:string = GUID.create("OKD guid");
		
		return '<SimpleDragLine '+(canDelete ? '' : 'canDelete="false" ')+
			'douid="'+douid+'" '+
//			'q="{&quot;show&quot;:1,&quot;y&quot;:-6,&quot;ballSize&quot;:36,&quot;freezed&quot;:1,&quot;level&quot;:0,&quot;color&quot;:&quot;noColor&quot;,&quot;x&quot;:-100}" '+
//			's="{&quot;show&quot;:1,&quot;y&quot;:-6,&quot;ballSize&quot;:38,&quot;freezed&quot;:0,&quot;enable&quot;:1,&quot;x&quot;:315}" '+
			'lineThick="5" dotSize="3" coordinateExpression="UA UK X 100 Y 0 D T 0 L 100 H 217 W 265" locked="false" '+
			'isQuestionComponent="true" hasScoring="true" scoringType="1" scoreN="1" unitScore="1" fullScore="1" questionIndex="0" '+
			'qInfo="{&quot;id&quot;:2,&quot;pid&quot;:0,&quot;rootIndex&quot;:0,&quot;root&quot;:1,&quot;level&quot;:0,&quot;index&quot;:0,&quot;order&quot;:1}">'+
			'<DragDot douid="'+a+'" node="'+a+'" aGroup="0" x="0" y="0" coordinateExpression="UA UK X 0 Y 0 D T 0 L 0 H 50 W 50" locked="false" pairUp="'+d+',"/>'+
			'<DragDot douid="'+b+'"  node="'+b+'" aGroup="0" x="0" y="84" coordinateExpression="UA UK X 0 Y 84 D T 84 L 0 H 50 W 50" locked="false" pairUp="'+e+',"/>'+
			'<DragDot douid="'+c+'"  node="'+c+'" aGroup="0" x="0" y="167" coordinateExpression="UA UK X 0 Y 167 D T 167 L 0 H 50 W 50" locked="false" pairUp="'+f+',"/>'+
			'<DragDot douid="'+d+'"  node="'+d+'" aGroup="1" x="215" y="0" coordinateExpression="UA UK X 215 Y 0 D T 0 L 215 H 50 W 50" locked="false" pairUp=""/>'+
			'<DragDot douid="'+e+'"  node="'+e+'" aGroup="1" x="215" y="84" coordinateExpression="UA UK X 215 Y 84 D T 84 L 215 H 50 W 50" locked="false" pairUp=""/>'+
			'<DragDot douid="'+f+'"  node="'+f+'" aGroup="1" x="215" y="167" coordinateExpression="UA UK X 215 Y 167 D T 167 L 215 H 50 W 50" locked="false" pairUp=""/>'+
		'</SimpleDragLine>';
	}

	protected setDefaultXMLData():void
	{
		let parser:XMLJSParser = new XMLJSParser();
		this.node = parser.parse(ROSimpleDragLine.getDefaultXMLString());
		this.node.createElement();
	}
	
	protected buildContent():void
	{
		/*
		var options:any = {
			"q": '"{"y":-6,"show":1,"level":0,"ballSize":36,"color":"noColor","freezed":0,"x":-100}"',
			"s": '"{"show":1,"y":-6,"ballSize":38,"freezed":0,"enable":1,"x":315}"',
			"lineThick": 5,
			"dotSize": 3,
			"coordinateExpression": "UA UK KW KH X 158 Y 66 D T 66 L 158 H 217 W 265",
			"locked": false,
			"isQuestionComponent": true,
			"hasScoring": true,
			"scoringType": 1,
			"scoreN": 1,
			"unitScore": 0.33,
			"fullScore": 1,
			"questionIndex": 0,
			"qInfo": '"{"order":2,"pid":0,"root":2,"level":0,"id":1,"rootIndex":0,"index":0}"',
			"douid": "41524568-DFD0-4201-BBE8-DAD73EA38F84"
		};
		*/
		var options:any = this.node.attributes;
		this.svgViewBox = `0 0 ${this.w} ${this.h}`;
		
		var dom:HTMLElement = this.elementRef.nativeElement;
		super.buildContent();

		this.dots = this.node.children.map((childNode:XMLNode)=>{
			
			var ce:CoordinateExpression = this.parseCE(childNode.attributes.coordinateExpression);
			
			return {
				aGroup:childNode.attributes.aGroup,
				douid:childNode.attributes.douid,
				center:ce.getCenter(),
				options:options,
				node:childNode,
				rect:ce.getRectangle()
			}
		});
		this.correctAnswer = {};
		this.lineCount = 0;
		this.dots.forEach((dot:any)=>{
			var attribute:any = dot.node.attributes
			if(attribute.pairUp)
			{
				var fromID:string = dot.douid;
				attribute.pairUp.split(",").forEach((pairID:string) => {
					if(pairID) pairID = pairID.trim();
					if(pairID){
						if(this.correctAnswer.hasOwnProperty(fromID) == false) this.correctAnswer[fromID] = [];
						this.correctAnswer[fromID].push(pairID)
						this.lineCount ++;
					}
				});
			}
		});
		
		var dotSizeMap = [0,10,12,16,20];
		var dotSize = dotSizeMap[options.dotSize];
		var dotRadius = dotSize / 2;
		StyleUtils.setStyleVariabe(dom, "dot-w", dotSize);
		StyleUtils.setStyleVariabe(dom, "dot-radius", dotRadius);
		StyleUtils.setStyleVariabe(dom, "line-width", (options.lineThick ) );
		this.dotRadius = dotRadius;
		this.lineWidth = options.lineThick;
	
		if(this.context.config.viewMode == "edit")
			this.lines = this._getCorrectAnswer();
	}

	private findNearestDotAt(pt:any, maxDistance:number):any
	{
		var len:number = this.dots.length;
		var bestDistance:number = maxDistance * maxDistance;
		var targetDot:any;
		for(var i = 0;i <len;i++)
		{
			var dot:any = this.dots[i];
			// var gp2:Point = dot.localToGlobal(lp);
			var p1:any = dot.center;
			var distance:number = this.distanceSquare(p1, pt);
			if (distance < bestDistance)
			{
				targetDot = dot;
				bestDistance = distance;
			}
		}
		return targetDot;
	}

	private distanceSquare(p1, p2):number
	{
		var dx = (p1.x - p2.x);
		var dy = (p1.y - p2.y);
		return dx * dx + dy * dy;
	}
	
	isPointInside(pt:any, rect:any):boolean
	{
		return (rect.x <= pt.x  && pt.x <= rect.x + rect.w) && (rect.y <= pt.y  && pt.y <= rect.y + rect.h);
	}
	
	getDotByID(douid:string):any
	{
		var len:number = this.dots.length;
		for(var i = 0;i < len;i++)
		{
			var dot:any = this.dots[i];
			if(dot.douid == douid) return dot;
		}
		return null;
	}
	
	getTargetDot(line:any)
	{
		var targetDot:any = line.to;
		return this.findNearestDotAt(targetDot.center, 40);
		
	}
	linesHasDot(dot:any):boolean
	{
		for(var i = 0; i< this.lines.length;i++)
		{
			if(this.lines[i].from === dot || this.lines[i].to === dot)
			{
				return true;
			}
		}
		return false;
	}

	removeDotFromLines(dot:any)
	{
		this.lines = this.lines.filter((line:any)=>{
			return (line.from !== dot && line.to !== dot);
		});
	}
				
	hasDuplicateLine(line:any)
	{
		var fromDot:any = line.from;
		var toDot:any = line.to;
		for(var i = 0; i< this.lines.length;i++)
		{
			var currentLine:any = this.lines[i];
			if(currentLine.from == fromDot && currentLine.to == toDot)
			{
				return true;
			}
		}
		return false;
	}
	
	dragStart(dot, event, type:string)
	{
		if(!this.context.canInteract(this)) return;
		
		event.stopImmediatePropagation();
		if(this.drawingLine)
		{
			return;
		}
		this.isVerify = false;
		var dom:HTMLElement = this.elementRef.nativeElement;
		var line;
		// var subject:Subject<any> = type == "touch" ? this.dd.touchStart(event): this.dd.dragStart(event);
		var subject:Subject<any> = this.dd.pointerStart(event);
		var subscription:Subscription = subject.subscribe((o:any)=>{
			// this.state = o.type;
			if(o.type == "start")
			{
				
				line = {
					from:dot,
					to:dot
				}
				this.drawingLine = line;
				var pt:any = DOMHelper.getLocalPoint(dom, o.point);
				console.log("pt", pt);
			} else if(o.type=="move")
			{
				var pt:any = DOMHelper.getLocalPoint(dom, o.point);
				this.dots.forEach((dot)=>{
					dot.draging = false;
				})
				var targetDot:any = this.findNearestDotAt(pt, 40);
				// var targetDot:any = this.getTargetDot(line);
				if(targetDot)
				{
					targetDot.draging = true
					line.to = targetDot;
				} else {
					// console.log("NO");
					line.to = {
						douid:null,
						center:pt
					}
				};
			} else if(o.type=="end")
			{
				subscription.unsubscribe();
				this.dots.forEach((dot)=>{
					dot.draging = false;
				})
				
				var targetDot:any = line.to.douid ?  line.to : null;
				if(targetDot)
				{
					if(line.to.aGroup != line.from.aGroup)
					{
						if(line.from.aGroup == 1 && line.to.aGroup == 0) ObjectUtils.swapKey(line, "from", "to");
						if(this.hasDuplicateLine(line) == false)
						{
							this.lines.push(this.drawingLine);
							this.answerChanged = true;
							this.resetVerify();
							this.context.subject.next({
								type:"action", 
								action:"answerChanged",
								data:this
							});
						}
					}
				} else {
					if(this.linesHasDot(line.from))
					{
						this.removeDotFromLines(line.from);
						this.answerChanged = true;
						this.resetVerify();
						this.context.subject.next({
							type:"action", 
							action:"answerChanged",
							data:this
						});
					}
				}
				this.drawingLine = null;
			} else if(o.type=="cancel")
			{
				subscription.unsubscribe();
				this.dots.forEach((dot)=>{
					dot.draging = false;
				})
				this.drawingLine = null;
			} else if(o.type == "timeout")
			{
				subscription.unsubscribe();
				this.dots.forEach((dot)=>{
					dot.draging = false;
				})
				this.drawingLine = null;
			}
		});
	}

	public dragDotStart(dot, event) {
		if(!this.context.canInteract(this)) return;
		
		event.stopImmediatePropagation();
		// 拋 select event

		var doms:any[] = event.target.parentElement.getElementsByClassName(dot.douid);
		if(doms.length>0) {
			var subject:Subject<any> = this.dd.pointerStart(event);
			var offset:any = {x:0,y:0};
			var subscription:Subscription = subject.subscribe((o:any)=>{
				var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
				pt.x = Math.round(pt.x);
				pt.y = Math.round(pt.y);
				if(o.type=="start") {
					offset.x = pt.x - (dot.rect.x);
					offset.y = pt.y - (dot.rect.y);
				}

				dot.rect.x = pt.x - offset.x;
				dot.center.x = dot.rect.x+25;
				dot.rect.y = pt.y - offset.y;
				dot.center.y = dot.rect.y+25;
				
				this.context.subject.next({
					type:"action",
					action:"selectMarkerTargetUpdate",
					data:{target:doms[0]}
				});
				if(o.type == "cancel" || o.type == "timeout" || o.type == "end")
					subscription.unsubscribe();
			});

			// 拋給 selector marker
			this.context.subject.next({
				type:"action",
				action:"selectMarkerTargetSelect",
				data:{
					editInStage:false,
					offsetX:0,
					offsety:0,
					x:dot.rect.x,
					y:dot.rect.y,
					width:dot.rect.width,
					height:dot.rect.height,
					dom:doms[0],
					target:doms[0],
					settings:{rotation:false,lockedAspectRatio:false,widthResizer:false,heightResizer:false,resizer:false,move:false}
				}
			});
		}
	}

	// =========================
	// data function
	// =========================
	public reset():void {
		this.defaultAnswer = null;
		this.answerChanged = false;
		this.lines = [];
		this.drawingLine  = null;
	}
	private addLine(fromID:string, toID:string):void
	{
		var dot1:any = this.getDotByID(fromID);
		var dot2:any = this.getDotByID(toID);
		if(dot1 && dot2)
		{
			var line:any = {
				from:dot1,
				to:dot2
			};
			this.lines.push(line);
		}
	}
	
	public set data(value:string) {
		this.reset();
		if(value)
		{
			var dataObj:Object = JSON.parse(value) as Object;
			for (var key in dataObj)
			{
				var fromID:string = key;
				dataObj[key].forEach((toID:string)=>{
					this.addLine(fromID, toID);
				});
			}
		}
	}

	public get data():string
	{
		if(this.lines && this.lines.length)
		{
			var map = {};
			this.lines.forEach((line) => {
				var fromID:string = line.from.douid;
				var toID:string = line.to.douid;
				if(map.hasOwnProperty(fromID) == false)
				{
					map[fromID] = [];
				}
				if(map[fromID].indexOf(toID) == -1)
				{
					map[fromID].push(toID);
				}
			});
			return JSON.stringify(map);
		} else  {
			return null;
		}
		
	}
	public hideScore(): void {
		this.correctState = '';
		super.hideScore();
	}
	
	public canVerify(): boolean {
		return true;
	}

	public getCorrectWrongStatus(_showScoring:boolean):any {
		var total:number = Math.max(this.lines.length, this.lineCount);
		var numOfCorrect:number = 0;
		var numOfIncorrect:number = 0;

		this.lines.forEach((line) => {
			line.verify = _showScoring;
			var correct:boolean = this.isLineCorrect(line);
			line.correct = correct;
			line.incorrect = !correct;
			if (correct)
				numOfCorrect++;
			else
				numOfIncorrect++;
		});

		return {
			total:total,
			numOfCorrect:numOfCorrect,
			numOfIncorrect:numOfIncorrect
		};
	}

	protected resetVerify():void {
		this.correctState = '';
		this.isVerify = false;
		this.resultObject = {correct:-1, score:0};
	}

	public verify(_showScoring:boolean):any {
		this.resetVerify();

		var res:any = this.getCorrectWrongStatus(_showScoring);
		var total:number = res.total;
		var numOfCorrect:number = res.numOfCorrect;
		var numOfIncorrect:number = res.numOfIncorrect;

		
		if (total <= numOfCorrect && numOfIncorrect == 0) 
		{
			this.resultObject = { correct: 2 };
		}else if (numOfCorrect > 0) 
		{
			this.resultObject = { correct: 1 };
		}else
		{
			this.resultObject = { correct: 0 };
		}
		var scoreType = this.node.attributes.scoringType;
		if (scoreType == ROScoringType.COMPLETE)
		{
			if (this.resultObject.correct == 2) 
			{
				this.resultObject.score = this.getFullScore();
			}else 
			{
				this.resultObject.score = 0;
			}
		} 
		else
		{
			
			var s:number 
			if (numOfCorrect == total)
			{
				s = this.mathFloor((numOfCorrect - numOfIncorrect * 0.5) * this.getUnitScore(), 100);
			} else {
				s = this.mathFloor((numOfCorrect ) * this.getUnitScore(), 100);
			}
			this.resultObject.score = Math.max(s, 0);
		}
		
		if(_showScoring)
		{
			if(this.resultObject.correct == 2)
				this.correctState = 'correct';
			else if(this.resultObject.correct == 1)
				this.correctState = 'partial_correct';
			else if(this.resultObject.correct == 0)
				this.correctState = 'incorrect';
		}
		
		if(this.sObj)
			if(_showScoring) {
				this.sObj.showScoring(_showScoring, "view", this.resultObject);	
			} else {
				this.sObj.hideScore();
			}
		
		return this.resultObject;
	}
	private mathFloor(num, size):number
	{
		return Math.floor(num * size)/ size;
	}

	private isLineCorrect(line:any):boolean
	{
		var fromID:string = line.from.douid;
		var toID:string = line.to.douid;
		return this.correctAnswer.hasOwnProperty(fromID) && this.correctAnswer[fromID].indexOf(toID) != -1;
	}


	// =========================
	// component type
	// =========================
	// 題目組件：有資料、計分/不計分、有/無對錯、出成績表
	public isQuestionComponent():boolean
	{
		return true;
	}
	

	// =======================================
	// edit relative function
	// =======================================
	public getPopupSelector():any []
	{
		var ary:any[] = super.getPopupSelector();
		if(this.editInStage) {
			ary.splice(1,0, {
				type:"action",
				action:"add",
				target:"component",
				component:this,
				icon:SelectMarkerIcon.ADD_ICON,
				name:this.context.translate("ro.components.SimpleDragLine.add_dot")
			},{
				type:"action",
				action:"autoArrange",
				target:"component",
				component:this,
				icon:SelectMarkerIcon.ADD_ICON,
				name:this.context.translate("ro.components.SimpleDragLine.auto_arrange")
			});
		}
		return ary;
	}

	public set editInStage(value:boolean)
	{
		if(this._editInStage && !value) {
			// 修改完成
			var left:number = Number.MAX_VALUE;
			var right:number = -Number.MAX_VALUE;
			var top:number = Number.MAX_VALUE;
			var bottom:number = -Number.MAX_VALUE;

			this.dots.forEach((dot:any)=>{
				var attr:any = dot.node.attributes;
				// 將現有連線結果轉答案
				var ids:any[] = this.lines.filter(e => e.from.douid == dot.node.attributes.douid).map(e => e.to.douid);
				dot.node.element.attributes.pairUp = attr.pairUp = ids.join(",");

				// 更新點座標記錄
				var _x:number = dot.rect.x;
				var _y:number = dot.rect.y;
				var ce:CoordinateExpression = this.parseCE(dot.node.attributes.coordinateExpression);
				ce.setX(_x);
				ce.setY(_y);
				dot.node.element.attributes.coordinateExpression = dot.node.attributes.coordinateExpression = ce.buildExpression();

				left = Math.min(left, _x);
				right = Math.max(right, _x+50);
				top = Math.min(top, _y);
				bottom = Math.max(bottom, _y+50);
			});

			// 計算移動偏移值
			var offsetx:number = -left;
			var offsety:number = -top;

			// 修正所有子控點的位置
			this.dots.forEach((dot:any)=>{
				// 更新點座標記錄
				var ce:CoordinateExpression = this.parseCE(dot.node.attributes.coordinateExpression);
				ce.setX(dot.rect.x + offsetx);
				ce.setY(dot.rect.y + offsety);
				dot.center=ce.getCenter();
				dot.rect=ce.getRectangle();
				dot.node.element.attributes.coordinateExpression = dot.node.attributes.coordinateExpression = ce.buildExpression();
			});

			// 更新題目佔據範圍
			this.w = right-left;
			this.h = bottom-top;
			this.moveBy( -offsetx, -offsety);
			this.svgViewBox = `0 0 ${this.w} ${this.h}`;
			this.savePosition();
			this.assignLTWH();

			// 更新題號位置
			if(this.qObj && !this.qObj.node.attributes.freezed)  // 自定位置時，修定位置
				this.qObj.moveBy( offsetx, offsety);			

			// 更新分數球位置
			if(this.sObj) {
				// 自定位置時，修定位置
				var attr:any = this.sObj.node.attributes;
				if(!attr.freezed && ['page','auto'].indexOf(attr.position)==-1)
					this.sObj.moveBy( offsetx, offsety);	
			} 
		}

		this._editInStage = value;
	}
	public get editInStage():boolean{
		return this._editInStage;
	}


	protected getDotsRange():any {
		var left:number = Number.MAX_VALUE;
		var right:number = -Number.MAX_VALUE;
		var top:number = Number.MAX_VALUE;
		var bottom:number = -Number.MAX_VALUE;

		var grp0:number = 0;
		var grp1:number = 0;

		this.dots.forEach((dot:any)=>{
			var _x:number = dot.rect.x;
			var _y:number = dot.rect.y;

			left = Math.min(left, _x);
			right = Math.max(right, _x+50);
			top = Math.min(top, _y);
			bottom = Math.max(bottom, _y+50);

			if(dot.aGroup == 0)
				grp0++;
			else
				grp1++;
		});

		return {
			left:left,
			right:right,
			top:top,
			bottom:bottom,
			grp0:grp0,
            grp1:grp1
		};
	}

	runCommand(action:string):void
	{
		if(action == "add") {
			var res:any = this.getDotsRange();
			var left:number = res.left;
			var right:number = res.right-50;
			var bottom:number = res.bottom+10;

			let parser:XMLJSParser = new XMLJSParser();
			var a:string = GUID.create("OKD guid");
			var pt1:XMLNode = parser.parse(`<DragDot douid="${a}" node="${a}" aGroup="0" x="${left}" y="${bottom}" coordinateExpression="UA UK X ${left} Y ${bottom} D L ${left} T ${bottom} H 50 W 50" locked="false" pairUp=""/>`);
			pt1.createElement();
			a = GUID.create("OKD guid");
			var pt2:XMLNode = parser.parse(`<DragDot douid="${a}" node="${a}" aGroup="1" x="${right}" y="${bottom}" coordinateExpression="UA UK X ${right} Y ${bottom} D L ${right} T ${bottom} H 50 W 50" locked="false" pairUp=""/>`);
			pt2.createElement();
			
			this.node.addChild(pt1);
			this.node.element.elements.push(pt1.element);
			this.node.addChild(pt2);
			this.node.element.elements.push(pt2.element);

			var ce:CoordinateExpression = this.parseCE(pt1.attributes.coordinateExpression);
			this.dots.push({
				aGroup:pt1.attributes.aGroup,
				douid:pt1.attributes.douid,
				center:ce.getCenter(),
				options:this.node.attributes,
				node:pt1,
				rect:ce.getRectangle()
			});
			ce = this.parseCE(pt2.attributes.coordinateExpression);
			this.dots.push({
				aGroup:pt2.attributes.aGroup,
				douid:pt2.attributes.douid,
				center:ce.getCenter(),
				options:this.node.attributes,
				node:pt2,
				rect:ce.getRectangle()
			});

		} else if(action == "autoArrange") {
			var res:any = this.getDotsRange();
			var left:number = res.left;
			var right:number = res.right;
			var top:number = res.top;
			var bottom:number = res.bottom;
			var grp0:number = res.grp0;
			var grp1:number = res.grp1;


			var nextHeight0:number = grp0>1 ? (bottom-top-50)/(grp0-1) : 0;
			var nextHeight1:number = grp1>1 ? (bottom-top-50)/(grp1-1) : 0;
			grp0 = 0;
			grp1 = 0;
			this.dots.forEach((dot:any)=>{
				var ce:CoordinateExpression = this.parseCE(dot.node.attributes.coordinateExpression);
				if(dot.aGroup == 0) {
					ce.setX(left);
					ce.setY(top+grp0*nextHeight0);
					grp0++;
				} else {
					ce.setX(right-50);
					ce.setY(top+grp1*nextHeight1);
					grp1++;
				}
				dot.center=ce.getCenter();
				dot.rect=ce.getRectangle();
				dot.node.element.attributes.coordinateExpression = dot.node.attributes.coordinateExpression = ce.buildExpression();
			});
			
		}
		
	}

	public canDelete(target:any = null):boolean {
		if(target == null) {
			// this component
			if(this.node.attributes.hasOwnProperty("canDelete"))
				return this.node.attributes.canDelete;
			return true;

		} else if(target instanceof ROComponent) {
			return (<ROComponent>target).canDelete();

		} else if(target instanceof HTMLElement) {
			var clist:any = (<HTMLElement>target).classList;
			for(var i:number=0;i<this.dots.length;i++) {
				if(clist.contains(this.dots[i].node.attributes.douid)) {
					return true;
				}
			}
		}
		return true;
	}

	removeChild(target:any):void {
		if(target instanceof HTMLElement) {
			var clist:any = (<HTMLElement>target).classList;
			for(var i:number=0;i<this.dots.length;i++) {
				if(clist.contains(this.dots[i].node.attributes.douid)) {
					// 移除點
					var dot:any = this.dots[i];
					this.node.removeChild(dot.node);
	//				this.node.element.elements.splice(i,1);
					this.dots.splice(i,1);

					// dot delete 後，關的答案要刪除
					for(var j:number=0; j<this.lines.length; j++) {
						var line:any = this.lines[j];
						if(line.from == dot || line.to == dot) {
							this.lines.splice(j,1);
							j--;
						}
					}

					// 更新 total answer
					return;
				}
			}
		}
	}
}
