import { ScriptLoader } from "src/app/common/ScriptLoader";
import { Base64 } from 'js-base64';


export class SWFTool
{
	public static createImageForSVG(svg:SVGElement):HTMLElement
	{
		var url = this.buildURLFromSVG(svg);
		var dom:HTMLElement = document.createElement("div");
		dom.style.borderImageSource = `url(${url})`;
		dom.style.borderImageRepeat = "stretch";
		var s = svg.getAttribute("scaleGrid");
		if(s)
		{
			dom.style.borderImageSlice = `${s} fill`;
			dom.style.borderImageWidth = s.split(" ").map((t)=>{return t +"px"}).join(" ");
		} else {
			dom.style.borderImageSlice = `0 0 0 0 fill`;
			dom.style.borderImageWidth = "0 0 0 0";
		}
		dom.style.width= `100%`;
		dom.style.height= `100%`;

		return dom;
	}
	public static buildURLFromSVG(svg:SVGElement, filename:string = "sample.svg")
	{
		let file: File = new File(
			['<?xml version="1.0" encoding="UTF-8"?>', svg.outerHTML ], 
			filename, 
			{type: "image/svg+xml"}
		);
		return URL.createObjectURL(file);
	}
}

export class SWFLoader {
	/**
	 * 
	 * @param bytes SWFLoader.loadBytes(bytes).then(
	 	(stage)=>{
	 		return stage.toSVG();
	 	})
	 * @returns swf2svg stage instance
	 */
	public static loadBytes(bytes:ArrayBuffer):Promise<any>
	{
		return SWFLoader.init().then((o:any)=>{
			var win:any = window;
			return win.swf2svg.loadBytes(
				bytes
			);
		})
	}
	public static loadBufferArray(buffer:ArrayBuffer):Promise<any>
	{
		return SWFLoader.init().then((o:any)=>{
			var win:any = window;
			return win.swf2svg.loadBufferArray(
				buffer
			);
		})
	}
	/**
	 * 
	 	SWFLoader.loadURL("http://something/a.swf").then(
	 	(stage)=>{
	 		return stage.toSVG();
	 	})
	 	@param bytes 
	 * @returns swf2svg stage instance
	 */
	public static loadURL(url:string):Promise<any>
	{
		return SWFLoader.init().then((o:any)=>{
			var win:any = window;
			return win.swf2svg.loadURL(url);
		});
	}

	public static svgToDataURL(svg:SVGGraphicsElement):string
	{
		var svgStr:string = svg.outerHTML
		
		/*
		const encoded = encodeURIComponent(svgStr)
		.replace(/'/g, '%27')
		.replace(/"/g, '%22')
		*/
		var base64Encoded = Base64.encode(svgStr, false);
			
		const header = "data:image/svg+xml;base64,";
		//const header = 'data:image/svg+xml,'
		
		return header + base64Encoded;
	}

	static createFile(svg: SVGGraphicsElement, filename: string): File {
		return new File(
			[svg.outerHTML], 
			filename, {
				type: "image/svg+xml",
			}
		);
	}

	
	/*
	public static createSVG(url:string): Promise<SVGGraphicsElement>
    {
		return SWFLoader.init().then((o:any)=>{
			var win:any = window;
			return win.swf2svg.loadURL(url);
		}).then((stage:any)=>{
			return stage.toSVG();
		});
    }
	*/
	
    public static init():Promise<any>
	{
		return ScriptLoader.loadArray(
			[
				{url:"assets/swf2js/canvas2svg.js", name:"C2S"},
				{url:"assets/swf2js/swf2svg.js", name:"swf2svg"}
			]
		)
	}

}