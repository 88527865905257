import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ICellEditorParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector:"ng-select-renderer",
    template: `
    <ng-select #ngSelecter class="custom2"
        [(ngModel)]="currentValue"
        [items]="params['options']"
        appendTo="body"
        bindLabel="title"
        bindValue="value"
        [closeOnSelect]="true"
        [searchable]="false"
		[clearable]="false"
		(close)="onClose($event)"
        (change)="onChange($event)"
    >
      <ng-template ng-label-tmp let-item="item">{{ item.title|translate }}</ng-template>
      <ng-template ng-option-tmp let-item="item">{{ item.title|translate }}</ng-template>
    </ng-select>
    `,
    styleUrls: ['./ngSelect.renderer.scss']
})


export class NgSelectRenderer implements AfterViewInit {
  @ViewChild('ngSelecter', {static:false}) ngSelecter: NgSelectComponent;
  public params: any;
  public currentValue:any;

  constructor(private coms:CommonService, public translate:TranslateService) {
  }

  ngAfterViewInit() {
	this.ngSelecter.open();
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.currentValue = params.value;
  }

  getValue(): any {
    return this.currentValue;
  }

  onClose($event)
  {
	this.params.api.stopEditing();
  }

  onChange($event)
  {
    if (this.params.onChange){
      this.params.onChange(this.params, $event.value);
    }
    this.params.api.stopEditing();
  }

}
