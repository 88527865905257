import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener, OnInit, TemplateRef } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'progressBox',
    template: `
<div class="container" [ngStyle]="{'background': backgroundStyle?backgroundStyle:'white'}">
    <div class="title" *ngIf="title">{{title}}</div>
    <div class="msgStatusContainer">
        <div class="msgStatus" *ngFor="let item of msgStatus">
            <div class="msg">{{item.msg}}</div>
            <div class="status completed"[style.width]="statusWidth" *ngIf="item.status=='completed'">{{completedString}}</div>
            <div class="status" [style.width]="statusWidth" *ngIf="item.status=='processing'">{{processingString}}</div>
        </div>
        <ng-container *ngTemplateOutlet="templateContent;"></ng-container>
        <ng-content></ng-content>
    </div>
</div>
    `,
    styleUrls: ['./progressBox.component.scss']
})

export class ProgressBoxComponent {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }
    @HostBinding('style.display') hostDisplay:string = 'none';

    @HostBinding('style.zIndex') public hostZIndex = 0;

    public isOpened:boolean = false;

    public processingString:string = ".";
    public completedString:string = "";
    public statusWidth:string = "35px";
    private timer:any;

    @Input() public title:string = "";
    @Input() public msgStatus:{msg:string, status:string}[] = [];
    @Input() public templateContent:TemplateRef<any> = null;
	@Input() public backgroundStyle:any = null
	@Input() public colorStyle:any = null

    constructor(private coms:CommonService, private dcs:DynamicComponentService, public translate:TranslateService) {
        this.completedString = this.translate.instant('progressBox.ok');
        this.timer = setInterval(()=>{
            this.processingString += '.';
            if (this.processingString=='....') this.processingString = '.';
        }, 1000);
    }

    public open(appendEle:HTMLElement = document.body):void {
        this.hostDisplay = 'flex';
        this.isOpened = true;
        this.hostZIndex = this.coms.getZIndex();
        this.dcs.open(this, appendEle);
    }

    public close():void {
        this.hostDisplay = 'none';
        this.isOpened = false;
        clearInterval(this.timer);
        this.dcs.close(this);
    }

}
