import { Component, Output, EventEmitter, ViewChild, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { StorageService } from 'src/app/service/storage.service';
import { faHeart as faHeartR } from '@fortawesome/pro-regular-svg-icons';
import { faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes, faPalette, faTriangleExclamation, faHeart as faHeartS } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { ThemeService } from 'src/app/service/theme.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubjectService } from '../subjectModule/subject.service';
import { Observable } from 'rxjs';

@Component({
  selector: "subjectSelector3",
  templateUrl: "subjectSelector3.component.html",
  styleUrls: ['./subjectSelector3.component.scss'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SubjectSelector3Component),
        multi: true
    }
  ]
})


export class SubjectSelector3Component implements ControlValueAccessor ,OnInit {
	@ViewChild("bubbleBox2", { static: true }) bubbleBox2: BubbleBox2Component;

    public faSearch = faSearch;
    public faCheck = faCheck;
    public faCircle = faCircle;
    public faTrash = faTrash;
    public faChevronRight = faChevronRight;
    public faChevronDown = faChevronDown;
    public faTimes = faTimes;
    public faCheckCircle = faCheckCircle;
    public faCheckCircleLight = faCheckCircleLight;
    public faPalette = faPalette;
    public faTriangleExclamation = faTriangleExclamation;
    public faHeartS = faHeartS;
    public faHeartR = faHeartR;

    @Input() public allowForAll:boolean = true;
    @Input() public multiple:boolean = true;
    @Input() public height:string = "350px";
    @Input() public anyOrForAll:boolean = true;
    @Input() public showMyFav:boolean = true;
	@Input() public leftBgColor;

    public isSubjectLoaded: boolean = false;
    public searchText: string = "";
    public isForAll: boolean = false;

	@Input() public sourceType:any[] = [{ label: "", value: "public" }, { label: "", value: "school" }]; //公用or校本
	@Input() public selectedSourceType:any = "";
    //-----------------------------------------------------------
    @Output() public confirm: EventEmitter<any[]> = new EventEmitter();
    @Output() public cancel: EventEmitter<void> = new EventEmitter();
    @Output() public onClose:EventEmitter<void> = new EventEmitter();
    public subjects:any[] = [];
    public favOpen:boolean = false;
    public groups:any[] = [
        { level: 'myFav', type:'myFav', open:true, subjects:[], color:"#FFDF5F" },
        { level: 'all', type:'public', open:true, subjects:[] },
        { level: 'all', type:'school', open:true, subjects:[] },
        { level: 'P1-6', type:'public', open:true, subjects:[] },
        { level: 'P1-6', type:'school', open:true, subjects:[] },
        { level: 'S1-3', type:'public', open:true, subjects:[] },
        { level: 'S1-3', type:'school', open:true, subjects:[] },
        { level: 'S4-6', type:'public', open:true, subjects:[] },
        { level: 'S4-6', type:'school', open:true, subjects:[] },
        { level: 'others', type:'public', open:true, subjects:[] },
        { level: 'others', type:'school', open:true, subjects:[] }
    ];

    public _subjects:any[] = []; //For ngModel binding
    public tempSubjects:any[] = [];
    public forAllSubject:any = {id:'forAll'};
    public isDirty:boolean = false;
	public isConfirmed:boolean = false;
    //-----------------------------------------------------------
    constructor(
		public subjectService: SubjectService,
		private coms: CommonService, public datas:DataService, public storage: StorageService, public translate: TranslateService, private sanitizer:DomSanitizer, private als: AlertService, public thms:ThemeService, public eleRef:ElementRef) {
        //設定Lang
        this.translate.use(this.datas.lang);
        //取得所有學年並設定現年
        this.coms.waitFor(()=>this.datas.userInfo.school_id, 30)
        .then(()=>this.getSubjectsApi())
        .then(()=>this.mapGroupSubject());

        this.coms.waitFor(()=>translate.translations[translate.currentLang].subjectSelector3.all, 30)
        .then((stringOfAll:string)=>this.forAllSubject.title = stringOfAll);

		// this.translate.onLangChange.subscribe(()=>{
			this.sourceType.map((typeObj:any)=>{
				typeObj.label = {
					tc: { public:'公用', school:'學校' },
					sc: { public:'公用', school:'学校' },
					en: { public:'Public', school:'School' }
				}[this.translate.currentLang][typeObj.value];
			});
		// });		
    }
	ngOnInit(): void {
		this.init_sourceType()
	}

	init_sourceType(){
		if (this.sourceType.length == 0) {
			this.sourceType = [{ label: "", value: "public" }, { label: "", value: "school" }]; //公用or校本
		}
		if (!this.selectedSourceType) {
			try {
				let school_id = this.datas.userInfo.school_id || this.storage.account.school_id ||0
				if (['1656'].indexOf(school_id) != -1) {
					this.selectedSourceType = "school";
					this.sourceType = this.sourceType.filter((item:any)=>item.value == 'school')
				}else{
					this.selectedSourceType = "public";
				}
			} catch (error) {}
		}
	}
    
    //-implements ControlValueAccessor---------------------------------------------------------------
    private onChange = (v: any) => {};
    private onTouched = () => {};
    public isDisabled:boolean = false;

    writeValue(subjects:any[]): void {
        if (subjects) {
            this._subjects = subjects;
			this.presetSelectedSubject(subjects);
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
      this.isDisabled = isDisabled;
    }
    //-------------------------------------------------------------------------------------------------
    public open(targetEle:HTMLElement, appendToEle:HTMLElement=document.body):Promise<any> {
//		if(this.bubbleBox2.opened)
//			return Promise.reject("opened");

        this.isDirty = false;
		this.isConfirmed = false;
		this.presetSelectedSubject(this._subjects);
		return new Promise<any>((_resolve, _reject) => {
			this.bubbleBox2.open(targetEle, appendToEle).then((o:any)=>{
				// 兼容新做法，popup close 時會return 到所選的 answer ，以前只會收到 bubbleBox2 return
				if(this.isConfirmed)
					_resolve(this._subjects);
				else
					_reject("cancel");
			}).catch((reason:any)=>{
				// click on same cell release
				this.isConfirmed = false;
				this.bubbleBox2.close();
			});
		});
    }
    //-------------------------------------------------------------------------------------------------
    public getSubjectsApi():Promise<void> {
		if(this.subjectService.subjects && this.subjectService.subjects.length>0) {
			if(!this.isSubjectLoaded)
				this.initSubjects();
			return Promise.resolve();
		}

		return new Promise((resolve, reject)=> {
			this.loadSubject().subscribe(e => {
				this.initSubjects();
				resolve();
			});
		});
		/*
        this.subjects = [];
        return this.datas.post2({ data: { api:'ROSchoolSubject.get_subjects', json:[] } }).then((res:any)=>{
            if (res.subjects) {
				this.subjects = res.subjects.map(e => {
					let sj: any = { type: e.type, school_subject_id: e.school_subject_id, public_subject_id: e.public_subject_id, level: e.level };
					sj.id = (sj.public_subject_id ? sj.public_subject_id : '0') + '-' + (sj.school_subject_id ? sj.school_subject_id : '0');
					sj.title = this.datas.lang == 'en' ? e.ename : e.cname;
					sj.short_name = e.short_name;
					return sj;
				});
			}
            this.isSubjectLoaded = true;
        });
		*/
    }
	private loadSubject():Observable<any>
	{
		if(this.subjectService.subjects)
		{
			return  new Observable((subscriber) => {
				subscriber.next(this.subjectService.subjects);
				subscriber.complete();
			});
		} else {
			return this.subjectService.loadSubject();
		}
	}
	protected initSubjects():void {
		this.subjects = this.subjectService.subjects;
		this.isSubjectLoaded = true;
	}
    //-------------------------------------------------------------------------------------------------
    private getMyFavSubjects():any[] {
        let result:any[] = [];
        try {
            let account = this.datas.userInfo;
            if (account.personalSettings) {
                if (typeof account.personalSettings == 'string') account.personalSettings = JSON.parse(account.personalSettings);
                if (account.personalSettings.workspace_fav_subjects) {
                    account.personalSettings.workspace_fav_subjects.forEach(e => {
                        let found = this.subjects.find(sj => sj.id == e);
                        if (found && result.indexOf(found) == -1) result.push(found);
                    });
                }
            }
        } catch (e) {
            return [];
        }
        return result;
    }
    //-------------------------------------------------------------------------------------------------
    public isMyFavSubject(subject:any):boolean {
        let myFavGroup:any = this.groups.find((gp:any)=>gp.level=='myFav');
        if (myFavGroup) return myFavGroup.subjects.find((sub:any)=>sub==subject);
        return false;
    }
    //-------------------------------------------------------------------------------------------------
    public mapGroupSubject():void {
        //我的最愛
        let myFavGroup:any = this.groups.find((gp:any)=>gp.level=='myFav');
        if (myFavGroup) myFavGroup.subjects = this.getMyFavSubjects();
        this.subjects.map((sub:any)=>{
            //小學/初中/高中 || 其他
            let forGroup:any = this.groups.find((gp:any)=>sub.level==gp.level && sub.type==gp.type) || this.groups.find((gp:any)=>sub.type==gp.type);
            if (forGroup) forGroup.subjects.push((sub));
        });
    }
  //-------------------------------------------------------------------------------------------------
    public updateTempSubjects(){
        //const filterData = this.filterData;
        this.tempSubjects = [];
        if (this.allowForAll && this.isForAll){
            this.tempSubjects = [this.forAllSubject];
        }
        this.subjects.forEach((sub:any)=>{
            if (sub.selected) this.tempSubjects.push(JSON.parse(JSON.stringify(sub)));
        });

        this.isDirty = true;
    }
  //-------------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------------
  public toggleIsForAllSelect(): void {
    if (!this.multiple || (!this.isForAll && this.anyOrForAll)) this.clearAll();
    this.isForAll = !this.isForAll;
    this.updateTempSubjects();
  }
  //-------------------------------------------------------------------------------------------------
	public setSubjects(subjectIDArray:any []):void
	{
		if (!this.multiple) this.clearAll();
		var subjectMap:any = {};
		this.subjects.forEach((s:any)=>{
			subjectMap[s.id] = s;
		})
		var subjects:any [] =[];
		subjectIDArray.forEach((subjectID:string)=>{
			if(subjectMap.hasOwnProperty(subjectID))
			{
				var sub = subjectMap[subjectID];
				sub.selected = true;
				subjects.push(sub);
			}
		});
		this._subjects = subjects;
		this.presetSelectedSubject(this._subjects);
	}
  public setSubjectSelect(subject: any, value: boolean): void {
    if (!this.multiple) this.clearAll();
    if (value && this.anyOrForAll) this.isForAll = false;
    this.subjects.filter((sub:any)=>sub.id==subject.id).map((sub:any)=>sub.selected=value);
    this.updateTempSubjects();
  }
  //-------------------------------------------------------------------------------------------------
  public unselectItem(subject: any): void {
    if (subject.id == 'forAll'){
		if (this.anyOrForAll){
			return;
		}
        this.isForAll = false;
    } else {
      const found = this.subjects.find(t=> t.id == subject.id);
      if (found) found.selected = false;
    }
    this.updateTempSubjects();
  }
  //-------------------------------------------------------------------------------------------------
  public getSearchResult():any[] {
    let results:any[] = [];

    this.subjects.map((sub:any) => {
        if (
            sub.title.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
            && results.indexOf(sub) == -1
            && (sub.id!='forAll' || this.allowForAll)
        ) results.push(sub);
    });

    return results;
  }
  //-------------------------------------------------------------------------------------------------
  public clearAll():void {
    this.isForAll = false;

    this.subjects.forEach((sub:any)=>{
        sub.selected = false;
    });
    this.updateTempSubjects();
  }
  //-------------------------------------------------------------------------------------------------
  public selectAllChild(event:any, group:any){
    event.stopPropagation();
    if (group.subjects) group.subjects.forEach((sub:any)=>sub.selected = true);
    this.updateTempSubjects();
  }
  //-------------------------------------------------------------------------------------------------
    public confirmClick():void {
		this.isConfirmed = true;
        this._subjects = [...this.tempSubjects];
		this.confirm.emit(this._subjects);
        this.onTouched();
        this.onChange(this._subjects);
        this.bubbleBox2.close();
	}
    //-------------------------------------------------------------------------------------------------
    public cancelClick():void {
        this.presetSelectedSubject(this._subjects);
        this.cancel.emit();
        this.bubbleBox2.close();
    }
    //-------------------------------------------------------------------------------------------------
    public presetSelectedSubject(subjects:any[]):void {
        if (this.isSubjectLoaded) {
            this.isForAll = (this.allowForAll && subjects && subjects.find((t:any)=> t && t.id=='forAll')) ? true : false;

			if (!subjects || !Array.isArray(subjects)) subjects = [];
            for (let sub of this.subjects) {
                sub.selected = (subjects.find((t:any)=> t && t.id==sub.id)) ? true : false;
            }
            this.updateTempSubjects();
        } else {
            setTimeout(()=>this.presetSelectedSubject(subjects), 100);
        }
    }
    //-------------------------------------------------------------------------------------------------
    public getBgColor():string {
        return this.thms.getStyleValue('section-maincolor', this.eleRef.nativeElement) || this.thms.getStyleValue('wpopup-bgcolor', this.eleRef.nativeElement) || '#FFFFFF';
    }
    //-------------------------------------------------------------------------------------------------
    public getLeftBgColor():string {
		if (this.leftBgColor){
			return this.leftBgColor;
		}
        let bgColor:string = this.getBgColor();
        let rgbArray:number[] = [255, 255, 255];
        if (bgColor.startsWith('rgb') || bgColor.startsWith('RGB')) {
            rgbArray = bgColor
            .split("(")[1]
            .split(")")[0]
            .split(",")
            .map((x:string)=>parseInt(x));
        } else if (bgColor.startsWith('#')) {
            rgbArray = bgColor
            .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1)
            .match(/.{2}/g)
            .map(x => parseInt(x, 16));
        }
        
        let result:string = "#" + [rgbArray[0], rgbArray[1], rgbArray[2]]
        .map((x:number)=>(Math.round(x * 0.8)).toString(16))
        .map((x:string)=>(x.length==1) ? "0"+x : x)
        .join("");
        return result;
    }
    //-------------------------------------------------------------------------------------------------
    public getSelectedTitle():string {
        return this._subjects.filter((t:any)=>t.type=='school')
        .concat(this._subjects.filter((t:any)=>t.type=='tag'))
        .concat(this._subjects.filter((t:any)=>t.type=='people'))
		.concat(this._subjects.filter((t:any)=>t.type=='public'))
        .map((t:any)=>t.title)
        .join(', ');
    }
    //-------------------------------------------------------------------------------------------------
    public getSelectedSubject():any[] {
        return this.subjects.filter((sub:any)=>sub.selected);
    }
    //-------------------------------------------------------------------------------------------------
    public subjectHeartClick(subject:any):void {
        this.datas.post2({ data: { api:'ROWorkspace.setFavSubject', json:[subject.id] } }).then((res:any)=>{
            if (
                res.personalSettings &&
                res.personalSettings.workspace_fav_subjects &&
                this.datas.userInfo &&
                this.datas.userInfo.personalSettings
            ) {
				this.datas.userInfo.personalSettings.workspace_fav_subjects = res.personalSettings.workspace_fav_subjects;
			}
            this.mapGroupSubject();
        });
	}
    //-------------------------------------------------------------------------------------------------
	public setProperties(obj:any):void {
		for (let key in obj) if (this.hasOwnProperty(key)) this[key] = obj[key];
	}
    //-------------------------------------------------------------------------------------------------
}
