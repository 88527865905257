import { ChangeDetectorRef, Component, ElementRef, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";

// =======================================
// icon
// =======================================
import { faVideo, faFilm, faArrowUpRightFromSquare, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
	template:`
	<div class="innerContent"></div>
	<div class="btns">
		<fa-icon (click)="takeVideo()" [icon]="faVideo"></fa-icon>
		<fa-icon (click)="selectVideo()" [icon]="faFilm"></fa-icon>
		<fa-icon (click)="externalPlayback()" [icon]="faExternal"></fa-icon>
	</div>
	`,
	styles:[
		`
		:host{
			background-color: #85D0BD;
			border-radius: 14px;
			position: relative;
			padding:7px;
		}
		.innerContent{
			width:100%;
			height:100%;
			background-color: #000;
			border-radius: 7px;
		}
		.btns{
			position:absolute;
			top:18px;
			right:-10px;
			display:flex;
			flex-flow:column;
		}
		.btns>fa-icon{
			position: relative;
			display:flex;
			color:#fff;
			font-size: 20px;
			width:42px;
			height:42px;
			margin-bottom:8px;
//			outline: solid 1px #fff !important;
//			outline-offset: -1px;
			border-radius: 15px;
			background: linear-gradient(to top left, #0A6466 0%, #239B9F 50%, #27B3B7 90%);
			box-shadow: #074243 0px -2px 0px 0px inset;
		}
		.btns>fa-icon:after{
			position: absolute;
			top:0;
			left:0;
			content: '';
			width:calc(100% - 2px);
			height:calc(100% - 2px);
			border-radius: 13px;
			border: solid 1px #fff;
		}

		:host ::ng-deep fa-icon svg{
			margin: auto;
			filter: drop-shadow(3px 3px 5px rgba(11,91,93,10));
		}
		`
	]
})
export class ROVideoCamera extends ROComponent
{
	public faVideo:IconDefinition = faVideo;
	public faFilm:IconDefinition = faFilm;
	public faExternal:IconDefinition = faArrowUpRightFromSquare;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["VideoCamera"];
	}
	
	public takeVideo():void {

	}

	public selectVideo():void {
		
	}

	public externalPlayback():void {

	}
}