import { Component, ViewChild, TemplateRef, ViewContainerRef, EmbeddedViewRef, ElementRef, HostListener } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

// =======================================
// icon
// =======================================
//import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faCameraRotate, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { DataService } from 'src/app/service/data.service';
import { trace } from 'console';
import { isArray } from 'util';

@Component({
	selector: 'CameraCapture',
	template: `
	<ng-template #ngTemplate>
		<div class="backdrop" [class.show]="_isShowing" [style.zIndex]="_backdropZindex">
			<div class="content" *ngIf="_isShowing"
				[class.landscape]="isLandscape" 
				[class.portrait]="!isLandscape"
			>
				<div class="camera" >
					<video #cameraScreen></video>
				</div>
				
				<div class="close" (click)="close()"></div>
				<div class="swap" *ngIf="videoDeviceList && videoDeviceList.length>1" (click)="swapCamera()"><fa-icon [icon]="faCameraRotate"></fa-icon></div>
				<div class="capture" (click)="capture()"><div class="btnCircle"></div></div>
				<canvas #offscreenCanvas></canvas>
			</div>
		</div>
	</ng-template>
   `,
	styleUrls: ['./CameraCapture.component.scss'],
})

export class CameraCaptureComponent{
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.isLandscape = window.innerWidth>=window.innerHeight;
	}
	
	@ViewChild("ngTemplate", {static:true}) ngTemplate:TemplateRef<any>;

	protected video:HTMLVideoElement;
	@ViewChild("cameraScreen", {static:false}) set cameraScreen(ele:ElementRef<HTMLVideoElement>) {
		if(ele) {
			this.video = ele.nativeElement;
			this.setVideoStream(this.stream);
		}
	}

	protected _offscreenCanvas:HTMLCanvasElement;
	@ViewChild("offscreenCanvas", {static:false}) set offscreenCanvas(ele:ElementRef<HTMLCanvasElement>) {
		if(ele)
			this._offscreenCanvas = ele.nativeElement;
	}


	//public faXmark:IconDefinition = faXmark;
	public faCameraRotate:any = faCameraRotate;

	public _isShowing:boolean = false;
	public _backdropZindex:number = 100;

	private _resolveFn:Function = null;
	private _rejectFn:Function = null;
	private _embeddedViewRef:EmbeddedViewRef<any>;

	private stream:any;
	public isLandscape:boolean = true;
	public currentVideoIndex:number;
	public videoDeviceList:any;

	constructor(
		private coms:CommonService,
		private viewContainerRef: ViewContainerRef,
		public datas:DataService,
	) {}

	public lanuch():Promise<any> {
		return new Promise((resolve:Function, reject:Function)=>{
			this._resolveFn = resolve;
			this._rejectFn = reject;

			if(location.protocol == "http:" && location.port == '') { //allow "ng serve" can run
				this._rejectFn({msg:"alert.NEED_SSL_TO_PROVIDE_FUN"});
				return;
			}

			this.isLandscape = window.innerWidth>=window.innerHeight;
			// view 加到body
			if (!this._embeddedViewRef)
				this._embeddedViewRef = this.viewContainerRef.createEmbeddedView( this.ngTemplate );
			for (let node of this._embeddedViewRef.rootNodes)
				document.body.appendChild(node);
			this._backdropZindex = this.coms.getZIndex();
			this._isShowing=true;

			// 首次取得 camera
			navigator.mediaDevices.getUserMedia({audio: false, video: this.datas.dev.isMobile ? { facingMode: 'environment'} : true }).then((stream)=>{
				this.setVideoStream(stream);
				return navigator.mediaDevices.enumerateDevices();

			}).then(deviceInfos => {
				this.videoDeviceList = deviceInfos.filter(e => e.kind == 'videoinput').map(e => e.deviceId); // 取得所有可用裝置資訊
				this.currentVideoIndex = 0;
				if(this.videoDeviceList.length>1) {
					let tracks:any = this.stream.getVideoTracks();

					if(isArray(tracks))
						tracks = tracks[0];
					if(tracks.hasOwnProperty("getCapabilities"))
						this.currentVideoIndex = this.videoDeviceList.indexOf(tracks.getCapabilities().deviceId); // 取得訊號相關資訊
				}
					
			}).catch(error => this.errorHandler(error));

		});
	}

	public setVideoStream(stream:any):void {
		this.stream = stream;
		if(this._isShowing) {
			if(this.video && this.stream) {
				if(this.video.srcObject!=this.stream) {
					this.video.srcObject = this.stream;
					// 標準是要 handle promise error 
					let promise = this.video.play();
					if (promise)
						promise.catch(error => this.errorHandler(error));
					else
						console.log("can't open video");

				} else
					console.log("same video source")
				
			}

		} else {
			// 鏡頭回應前已把 view 關閉
			this.dispose();
		}
	}

	protected errorHandler(error:any):void {
		console.log("open camera get error >> ",error);
		this._rejectFn({msg:"alert.DEVICE_NOT_FOUND"});
		this.dispose();
	}

	public swapCamera():void {
		if(this.videoDeviceList && this.videoDeviceList.length>1 && this.video && this.stream) {
			this.currentVideoIndex = (this.currentVideoIndex+1)%this.videoDeviceList.length;// next device
		
			// close current stream
			this.stream.getTracks().forEach(function(track) {
				track.stop();
			});
			this.video.srcObject = null;

			navigator.mediaDevices.getUserMedia({
				audio: false,
				video: {deviceId: this.videoDeviceList[this.currentVideoIndex]}}).then((stream)=>{
				this.setVideoStream(stream);
			}).catch(error => this.errorHandler(error));

		}
	}

	public close():void {
		this._rejectFn({msg:"cancel"});
		this.dispose();
	}

	public capture():void {
		this._offscreenCanvas.width = this.video.videoWidth;
		this._offscreenCanvas.height = this.video.videoHeight;

		var context = this._offscreenCanvas.getContext('2d');
		context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);
		this._offscreenCanvas.toBlob((blob)=> {
			let f:any = new File([blob],'takePhoto.jpg',{type: blob.type});
			this._resolveFn({file:f, width:this.video.videoWidth, height:this.video.videoHeight});
			this.dispose();;
		}, 'image/jpeg',90); // 'image/png'
	}

	protected dispose():void {
		if(this.stream) {
			this.stream.getTracks().forEach(function(track) {
				track.stop();
			});
			this.stream = null;
		}
		if(this.video) {
			this.video.srcObject = null;
			this.video = null;
		}
			
		this._isShowing=false;
		this._backdropZindex = 0;

		if (this._embeddedViewRef && this._embeddedViewRef.rootNodes) {
			for (let node of this._embeddedViewRef.rootNodes) {
				try { document.body.removeChild(node); } catch(err){}
			}
		}
	}
}