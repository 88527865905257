import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import * as moment from 'moment';
import { TagGroupService } from 'src/app/sharedModule/tagGroupModule/tagGroup.service';

@Component({
	selector: 'create-link-modal',
	template: `
	<modal2
		#modal class="{{targetSelecting?'target-selecting':''}}"
		[width]="'520px'" [title]="modalTitle" [contentStyle]="modalContentStyle"
		(confirmClick)="confirm()" [confirmLabel]="confirmLabel"
		(cancelClick)="closeClick()"
		>
		<ng-container *ngIf="ready">
			<div class="box__row first-row tag">
				<div class="box__row__label">{{'workspace.tag'|translate}}:</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<div class="tag-title" (click)="targetSelectorOpen($event)">{{tags_title}}</div>
					<targetSelector #targetSelector [allowForAll]="false"
						memberRole="student" [(ngModel)]="targets" 
						></targetSelector>
				</div>
			</div>
		
			<div class="box__row type-setting">
				<div class="box__row__label">{{'workspace.text-star'|translate}}:</div>
				<div class="box__row__data">
					<numberInput min="0" max="50" [(ngModel)]="star"></numberInput>
				</div>
			</div>
			<div class="box__row">
				<div class="box__row__label">{{'course.name'|translate}}*</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<input #nameRef type="text" [(ngModel)]="name" required [placeholder]="(showPlaceholder?('bookshelf.placeholder-name'|translate):'')"/>
				</div>
			</div>
			<div class="box__row url website">
				<div class="box__row__label">{{'course.url'|translate}}*</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<textarea [(ngModel)]="url" [placeholder]="(showPlaceholder?('bookshelf.placeholder-name'|translate):'')"></textarea>
				</div>
			</div>
			<div class="box__row last-row">
				<div class="box__row__label">{{'workspace.in-progress-time'|translate}}:</div>
				<div class="box__row__data">
					<div class="datetime in-progress" #inProgressTimeEl (click)="dateTimeClick(inProgressTimeEl, 'in-progress')">
						<ng-container *ngIf="!startTime && !endTime">
							<span class="datetime__text">{{'workspace.pls-select'|translate}}</span>
						</ng-container>
						<ng-container *ngIf="startTime && endTime">
							<span class="datetime__text">{{startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} - {{endTime | myDate: wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a'}}</span>
						</ng-container>
						<ng-container *ngIf="startTime != null && endTime == null">
							<span class="datetime__text">{{startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}}{{'workspace.up-to-forever' | translate}}</span>
						</ng-container>
					</div>
					<dateTimePicker3 #dateTimePicker [clearBtn]="true"></dateTimePicker3>
				</div>
			</div>
		</ng-container>
  	</modal2>
  `
	, styleUrls: ['./create-link.modal.scss'],
})



export class CreateLinkModal implements OnInit {
	@Input() data: any;
	@ViewChild('targetSelector', {static: false}) targetSelector;
	@ViewChild('modal', {static: false}) modal;
	@ViewChild('nameRef', {static: false}) nameRef;
	@ViewChild('dateTimePicker', {static: false}) dateTimePicker;
	@Output() close:EventEmitter<any> = new EventEmitter<any>();
	public url='';
	public name='';
	public modalTitle = '';
	public targets = [];
	public star = 0;
	public startTime;
	public endTime;
	public wholeDay;
	public tags_title;
	public okaPulldownStyle = { width: '204px' };
	public okaPulldownMenuStyle = {};
	public selectorPromise;
	public modalContentStyle = {minWidth: '520px'};	
	public targetSelecting = false;
	public ready = false;
	public confirmLabel = '';
	public showPlaceholder = false;
	public entryType = 'url';
	constructor(public datas: DataService, private script: ScriptService, private trans: TranslateService, private als: AlertService, private eleRef: ElementRef, private tg: TagGroupService, private lds: LoadingService) {
	}

	ngOnInit() {
		this.initTargets();
		this.initOptions();
		if (this.data.data){
			this.modalTitle = 'course.edit-website-link';
			if (this.data.type == 'youtube'){
				this.modalTitle = 'course.edit-youtube';
				this.entryType = 'youtube';
			}
			this.confirmLabel = 'commonService.confirm';
			this.url = this.data.data.url;
			this.name = this.data.data.name;
		} else {
			this.modalTitle = 'course.create-website-link';
			if (this.data.type == 'youtube'){
				this.modalTitle = 'course.create-youtube';
				this.entryType = 'youtube';
			}
			this.confirmLabel = 'common.add';
		}
		this.modalTitle = this.trans.instant(this.modalTitle);
		this.confirmLabel = this.trans.instant(this.confirmLabel);
	}

	ngAfterViewInit() {
		setTimeout(()=>{
			this.modal.open();
			setTimeout(()=>{ this.ready = true;}, 300);
			setTimeout(()=>{
				this.nameRef.nativeElement.focus();
			},600);
		},10);
	}

	ngOnDestroy(): void {
	}

	confirm(){
		this.url = this.url && this.url.indexOf('//') == -1?'http://' + this.url:this.url;
		if (this.name && this.url){
			if (this.data.bsid){
				this.updateShareLink();
			} else {
				this.confirmCreateLink();
			}
		} else {
			this.als.toastError('course.pls-fill-all-fields');
			this.showPlaceholder = true;
		}
	}

	async confirmCreateLink(){
		const individualUids = this.targets.filter(t => t.tType == 'people').map(e=> e.value);
		const targets = this.targets.filter(t => t.tType == 'tag');
		if (individualUids.length > 0) {
			const schoolId = this.datas.userInfo.school_id;
			const title = 'cid-share-' + moment().format('YYYYMMDD-HHmmss');
			const tagType = 13;
			const currentYear = this.tg.defaultSchoolYear;
			const yearId = currentYear?currentYear.id:'0';
			const  json: any[] = [schoolId, yearId, schoolId, title, tagType, 'main', individualUids];
			let res: any = await this.datas.post('ROCourse.create_virtual_tag_for_group_share', json).toPromise();
			targets.push({tType: 'tag', value: res.tid, title: title});
		}
		if (targets.length == 0){
			const msg = {tc:'請選擇組別', sc: '请选择组别', en:'Please select group'}[this.datas.lang];
			this.als.toastError(msg);
			return;
		}
		const name = this.name && this.name != ''?this.name:'閱讀報告'; 
		let book_entry:any = {type: this.data.type, name: name, url: this.url};
		if (this.entryType == 'youtube'){
			const yid = this.getYid(this.url);
			book_entry = {...book_entry, yid: yid};
		}
		this.datas.post('ROBookshelfNew.addFakeBook', [book_entry]).subscribe((res:any)=>{
			book_entry.entry_id = res.item.book_id;
			const startTime = this.startTime?this.startTime.toISOString():null;
			const endTime = this.endTime?this.endTime.toISOString():null;
			const options = {type: 'custom', star: this.star, start_time: startTime, end_time: endTime };
			const targetValues = targets.map(e=>e.value);
			const o = { entries: [{ id: book_entry.entry_id, type: this.entryType, title: this.name }], tags: targetValues, options: options };
			this.createCreate(o);
		});
	}

	createCreate(obj) {
		this.datas.post2({ data: { api: 'ROBookShare.create_multi_share', json: [obj] } }).then((res: any) => {
			if (this.data.close){
				this.data.close(res.shares);
			}
		});
	}

	initTargets() {
		if (this.data.tag){
			this.tags_title = this.data.tag.title;
			this.targets = [{tType: 'tag', title: this.tags_title, value: this.data.tag.id}];
		}
		if (this.targets.length > 0) {
			const targetTitles = this.targets.map(t => t.title);
			this.tags_title = targetTitles.join(', ');
		} else {
			this.targets = [];
			this.tags_title = this.trans.instant('commonService.please-select');
		}
	}

	initOptions() {
		if (this.data.bsid){
			this.star = this.data.data.star || 0;
			this.startTime = this.data.startTime;
			this.endTime = this.data.endTime;
		} else {
			this.startTime = moment();
		}
	}

	targetSelectorOpen($event){
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
			this.targetSelecting = false;
		} else {
			this.targetSelecting = true;
			setTimeout(()=>{
				this.selectorPromise = this.targetSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.targetSelecting = false;
					this.initTargets();
				});
			},250);
		}
	}

	dateTimeClick(el, dateType) {
		const dateTimePicker = this.dateTimePicker;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		if (this.startTime) {
			const last8Dight = this.startTime.format('HH:mm:ss');
			wholeDay = last8Dight == '00:00:00' ? 1 : 0;
			startTime = this.startTime.toDate();
		}
		if (this.endTime) {
			const last8Dight2 = this.endTime.format('HH:mm:ss');
			wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
			endTime = this.endTime.toDate();
		}
		const dtpOptions = {value: {startDateTime:startTime, endDateTime: endTime, isWholeDay: wholeDay }, skipLoadApi: true };
		dateTimePicker.open(el, dtpOptions).then(data => {
			this.startTime = data.startDateTime?data.startDateTime:null;
			this.endTime = data.endDateTime?data.endDateTime:null;
			this.wholeDay = data.isWholeDay?1:0;
		});
	}

	closeClick(){
		if (this.data.close){
			this.data.close();
		}
		this.close.emit();
	}

	updateShareLink(){
		const bsid = this.data.bsid;
		const start_time = this.startTime?this.startTime.toISOString() : null;
		const end_time = this.endTime?this.endTime.toISOString() : null;
		const options = {entry_type: this.data.entry_type, star: this.star, start_time: start_time, end_time: end_time };
		this.lds.add('update_link_share', 5000);
		this.datas.post('ROBookshelfNew.update_other_share', [bsid, options]).subscribe((res:any)=>{
			this.lds.removeAll();
			this.data.close(res.share);
		});
	}

	getYid(url){
		let yid = null;
		if (url.indexOf('youtu.be') > -1) {
			let arr = url.split('/');
			yid = arr[arr.length - 1];
		} else if (url.indexOf('?v=') > -1) {
			let start = url.indexOf('?v=') + 3;
			let end = url.indexOf('&', start);
			if (end > -1) {
				yid = url.substr(start, end - start);
			} else {
				yid = url.substr(start);
			}
		} else {
			url = url.substr(0, url.indexOf('#') == -1 ? url.length : url.indexOf('#'));
			url = url.substr(0, url.indexOf('?') == -1 ? url.length : url.indexOf('?'));
			let arr = url.split('/');
			yid = arr[arr.length - 1];
		}
		return yid;
	}
}
