import { NgModule } from '@angular/core';
import { RODocumentService } from './RODocumentService';

@NgModule({
    imports: [
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [ RODocumentService],
    bootstrap: []
})
export class ROModule { }
