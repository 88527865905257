import { NgModule } from "@angular/core";
import { AppStatusComponent } from "./AppStatus.component";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
		CommonModule
    ],
    declarations: [
        AppStatusComponent
    ],
    exports: [
        AppStatusComponent
    ],
    providers: [],
    bootstrap: []
})
export class AppStatusModule { }
