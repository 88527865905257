import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import {  ROComponent } from "./ROComponent";
import { ROQuestionNumber } from "./ROQuestionNumber";
import { XMLNode } from "./xml/XMLNode";
import { XMLJSNode } from "./xml/XMLParser";
///////////////
@Component({
	template:``
})

export class ROQBall extends ROComponent
{
	// questionNumber
	// ballSize <- 現在用 book setting 去控制，這裡的參數不用
	// ballColor

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["QBall"];
	}

	protected buildQuestionAndScoreObject():void
	{
		let xmlNode:XMLNode = new XMLJSNode().assign(
			{
				attributes: {
					"x": 0,"y": 0,"level": 0,"freezed": 1,"show": true,"ballSize": 36,
					"color":parseInt(this.node.attributes.ballColor.substring(1), 16)
				},
				tag:"QuestionNumber"
			}
		);
		this.qObj = <ROQuestionNumber> this.context.createForEF(this.elementRef, xmlNode, this.page, this);
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"ver": 1,
				"questionNumber": "1",
				"ballSize": 3,
				"douid": this.createDouid(),
				"coordinateExpression": "UA UK KW KH KR X 0 Y 0 D T 0 L 0 H 60 W 60",
				"hasScoring": true,
				"scoringType": 1,
				"scoreN": 1,
				"unitScore": 1,
				"fullScore": 1,
				"ballColor": "#5bb9d9",
				"locked": false
			},
			tag:"QBall"
		});
		this.node.createElement();
	}
}