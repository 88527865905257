import { Subscription, fromEvent } from "rxjs";
import { filter } from 'rxjs/operators';
export class TouchUtils
{
	constructor()
	{
	}
	
	static suppressesDoubleTaps():Subscription{
		return TouchUtils.clickPreventDefault();
	}

	static clickPreventDefault():Subscription{
		return fromEvent(document, "click").pipe(filter((event)=> {
			let blockEvent = true;
			if (event.target){
				const el = <HTMLElement>event.target;
				const tagName = el.tagName.toLowerCase();
				const attribute = ('' + el.getAttribute('type')).toLowerCase();
				if (tagName == "a" || tagName == 'input' || attribute == 'file'){
					blockEvent = false;
				}
			}
			return blockEvent;
		})).subscribe((event:any)=>{
			event.preventDefault();
		});
	}

	static touchEndPreventDefault():Subscription{
		var minZoomTouchDelta = 20;
		var lastTouchEnd = 0;
		var lastX = 0;
		var lastY = 0;
		var doubleTapTime:number = 300;
		return fromEvent(document, "touchend").subscribe((event:any)=>{
			// console.log(event);
			var now = (new Date()).getTime();
			if(!event.touches.length) 
			{
				if (now - lastTouchEnd <= doubleTapTime ) {
					event.preventDefault();
				}
				lastTouchEnd = now;
				return;
			}
			
			var { clientX, clientY } = event.touches[0];
			var dx = clientX - lastX;
			var dy = clientY - lastY;
			var dxdy = Math.abs(dx) + Math.abs(dy);
			
			if (now - lastTouchEnd <= doubleTapTime  && dxdy < minZoomTouchDelta) {
				event.preventDefault();
			}
			lastX = clientX;
			lastY = clientY;
			
			lastTouchEnd = now;
		});
	}
}