import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: "color-box-renderer",
	template: `
	<div class="wrapper" 
		[ngStyle]="wrapperStyle" 
		[style.backgroundColor]="color">
			<div class="text" *ngIf="text" 
				[ngStyle]="textStyle" 
				>{{ text | translate }}</div>
	</div>
    `,
	styleUrls: ['./colorBox.renderer.scss']
})


export class ColorBoxRenderer implements AfterViewInit {
	public params: any;
	public wrapperStyle;
	public textStyle;
	public color;
	public text = '';
	ngAfterViewInit() {

	}

	ngOnDestroy(): void {
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		if (this.params.wrapperStyle){
			this.wrapperStyle = this.params.wrapperStyle;
		}
		if (this.params.textStyle){
			this.textStyle = this.params.textStyle;
		}
		this.updateOutput();
	}

	updateOutput(){
		if (this.params.output){
			this.text = this.params.output.text?'' + this.params.output.text:'';
			this.color = this.params.output.color;
			this.wrapperStyle = this.params.output.wrapperStyle?this.params.output.wrapperStyle:this.wrapperStyle;
			this.textStyle = this.params.output.textStyle?this.params.output.textStyle:this.textStyle;
		}
	}

	refresh(params: ICellRendererParams){
		this.updateOutput();
		return true;
	}


}
