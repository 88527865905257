export interface LinkedListNode{
	key:string;
	value:any;
	prev:LinkedListNode;
	next:LinkedListNode;
};

export class LinkedList {
	public nodes:LinkedListNode[] ;
	constructor() {
		this.nodes = [];
	}
  
	get size():any
	{
		return this.nodes.length;
	}
  
	get head():LinkedListNode
	{
		return this.size ? this.nodes[0] : null;
	}
  
	get tail():LinkedListNode
	{
		return this.size ? this.nodes[this.size - 1] : null;
	}
  
	insertAt(index:number, value:any):void
	{
		const previousNode = this.nodes[index - 1] || null;
		const nextNode = this.nodes[index] || null;
		const node = { key:null, value, next: nextNode, prev: previousNode};
		if (previousNode) previousNode.next = node;
		this.nodes.splice(index, 0, node);
	}
  
	insertFirst(value:any):void
	{
		this.insertAt(0, value);
	}
  
	insertLast(value:any):void
	{
	 	this.insertAt(this.size, value);
	}
  
	getAt(index:number):any
	{
		return this.nodes[index];
	}
  
	removeAt(index:number):LinkedListNode []
	{
		const previousNode = this.nodes[index - 1];
		const nextNode = this.nodes[index + 1] || null;

		if (previousNode) previousNode.next = nextNode;
		if(nextNode) nextNode.prev = previousNode;

		return this.nodes.splice(index, 1);
	}
  
	clear():void{
		this.nodes = [];
	}
  
	reverse():void
	{
		this.nodes.reverse();
		this.nodes.forEach((node:LinkedListNode, index:number)=>{
			var nextIndex:number = index+1;
			var nextNode:LinkedListNode = null;
			if(nextIndex < this.nodes.length) nextNode = this.nodes[nextIndex];
			node.next= nextNode;
			if(nextNode) nextNode.prev = node;
		});
	}
	/*
	*[Symbol.iterator]() {
	  yield* this.nodes;
	}
	*/
}

export class SimpleLinkedList {
	
	// public nodes:LinkedListNode[] ;
	private _size:number = 0;
	private _head:LinkedListNode;
	private _tail:LinkedListNode;

	constructor() {
		this.clear();
	}
  
	get size():any
	{
		return this._size;
	}
  
	get head():LinkedListNode
	{
		return this._size ? this._head : null;
	}
  
	get tail():LinkedListNode
	{
		return this._size ? this._tail : null;
	}
  
	remove(node:LinkedListNode):void
	{
		const previousNode = node.prev;
		const nextNode = node.next;
		if (previousNode) previousNode.next = nextNode;
		if(nextNode) nextNode.prev = previousNode;
	}
  
	clear():void{
		this._head = {key:null, value:null, prev:null, next:null};
		this._tail = {key:null, value:null, prev:null, next:null};
		this._head.next = this._tail;
		this._tail.prev = this._head;
		this._size = 0;
	}
  
	prependNode(node: LinkedListNode) {
		this._size++;
		node.next = this._head.next;
		node.prev = this._head;
		this._head.next = node;
		return node;
	}

	appendNode(node: LinkedListNode) {
		this._size++;
		node.next = this._tail;
		node.prev = this._tail.prev;
		this._tail.prev = node;
		return node;
	}

	public removeNode(node:LinkedListNode):void
	{
		this._size--;
		node.prev.next = node.next;
		node.next.prev = node.prev;
	}

	prepend(data:any):LinkedListNode
	{
		this._size++;
		const node:LinkedListNode = { key:null, value:data, next: this._head.next, prev: this._head};
		this._head.next.prev = node;
		this._head.next = node;
		return node;
	}

	append(data:any):LinkedListNode
	{
		return this.push(data);
	}

	push(data:any):LinkedListNode
	{
		this._size++;
		const node:LinkedListNode = { key:null, value:data, next: this._tail, prev: this._tail.prev};
		this.tail.prev.next = node;
		this._tail.prev = node;
		return node;
	}

	pop():LinkedListNode
	{
		if(this._size > 0)
		{
			var node = this._tail.prev;
			this.removeNode(this._tail.prev);
			return node;
		}
		return null;
	}
	
}


export class MRUCache
{
	
	public linkedList:SimpleLinkedList;
	private map:any = {

	};
	
	constructor(private maxCacheEntries:number, private tearDown:Function = null)
	{	
		this.linkedList = new SimpleLinkedList();
	}

	removeCache(key:string):void
	{
		if(!this.map.hasOwnProperty(key)) return;
		var node:LinkedListNode = this.map[key];
		var key = node.key;
		delete this.map[key];
		this.linkedList.removeNode(node);
	}
	
	replaceCache(key:string, cache:any):void
	{
		var node:LinkedListNode;
		if(this.map.hasOwnProperty(key))
		{
			node = this.map[key];
			this.linkedList.removeNode(node);
		}
		if(this.linkedList.size >= this.maxCacheEntries)
		{
			var node:LinkedListNode = this.linkedList.pop();
			var value = node ? node.value : null;
			delete this.map[node.key];
			if(this.tearDown) this.tearDown(value);
		}
		node = this.linkedList.prepend(cache);
		node.key = key;
		this.map[key] = node;
	}
	addCache(key:string, cache:any):void
	{
		var node:LinkedListNode;
		if(this.map.hasOwnProperty(key))
		{
			node = this.map[key];
			this.linkedList.removeNode(node);
			this.linkedList.prepend(node);
			
			return ;
		}
		if(this.linkedList.size >= this.maxCacheEntries)
		{
			var node:LinkedListNode = this.linkedList.pop();
			var value = node ? node.value : null;
			delete this.map[node.key];
			if(this.tearDown) this.tearDown(value);
		}
		node = this.linkedList.prepend(cache);
		node.key = key;
		this.map[key] = node;
	}

	log() {
		var node:LinkedListNode = this.linkedList.head;
		var array = [];
		for(var i = 0;node && i < 10;i++)
		{
			array.push(node.value);
			node = node.next;
		}
		console.log("list head", array);

		var node:LinkedListNode = this.linkedList.tail;
		var array = [];
		for(var i = 0;node && i < 10;i++)
		{
			array.push(node.value);
			node = node.prev;
		}
		array.reverse();
		console.log("list tail", array);

	}

	getCache(key:string):any
	{
		var node:LinkedListNode = this.getCacheNode(key);
		if(node) return node.value;
		return null;
	}

	getCacheNode(key:string):LinkedListNode
	{
		if(this.map.hasOwnProperty(key)) return this.map[key];
		return null;
	}
}
export class MRUCacheProxy
{
	private cache: MRUCache;
	constructor(count:number, private tearDown:Function = null)
	{
		this.cache = new MRUCache(count, tearDown);
	}
	fetch(key, caller, shareSharePromise:boolean = false):Promise<any>
	{
		var c = this.cache.getCache(key);
		if(c) return Promise.resolve(c);
		var p = caller.call();
		if(shareSharePromise)
		{
			this.cache.addCache(key, p)
		}
		return p.then((o)=>{
			this.cache.replaceCache(key, o);
			return o;
		});
		
	}
}