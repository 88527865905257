import { NgModule } from '@angular/core';
import { TranslateModuleLoader } from 'src/app/common/TranslateModuleLoader';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PersonalRankingPage } from './personal-ranking.page';
import { PersonalRankingTable } from './personal-ranking.table';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
	declarations: [
		PersonalRankingPage, PersonalRankingTable
	],
	imports: [
		CommonModule, FormsModule, PerfectScrollbarModule,
		TranslateModuleLoader.forRoot(),
	],
	exports: [ PersonalRankingPage ],
	providers: [],
	bootstrap: []
})
export class PersonalRankingModule { }
