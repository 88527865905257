import { Component } from '@angular/core';

@Component({
  template: `
  <div class="layer" [style.zIndex]="zIndex">
    <div class="wheel">

        <div class="color-container">
            <div class="umbrella-color">
                <div *ngFor="let i of [1,2,3,4,5]" class="color">
                    <div class="color__inside"></div>
                </div>
            </div>


            <div class="fan-container">
                <div class="umbrella-fan">
                    <div class="fan">
                        <div class="fan__inside"></div>
                    </div>
                </div>

                <div class="center"></div>
            </div>

        </div>

    </div>
    
  </div>
  `,
  styleUrls: ['./loading.component.scss']
})

export class LoadingComponent {
    public zIndex:number = 999;

  constructor() {
  }
}
