import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // ngModel 需要
import { AssessmentViewerComponent } from './AssessmentViewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CameraCaptureModule } from '../CameraCaptureModule/CameraCapture.module';
import { ScoringModule } from '../scoringModule/scoring.module';
import { OKDQBModule } from './OKDQB.module';
import { AudioModule } from '../audioModule/audio.module';
import { ROBookModule } from '../roBookModule/ROBook.module';

@NgModule({
    imports: [
		FontAwesomeModule,CommonModule,TranslateModule
		,CameraCaptureModule, ScoringModule,FormsModule,OKDQBModule
		,AudioModule,ROBookModule
    ],
    declarations: [
        AssessmentViewerComponent
    ],
    exports: [
        AssessmentViewerComponent
    ],
    providers: [],
    bootstrap: []
})
export class AssessmentViewerModule { }
