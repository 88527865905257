import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { QBComponentBase } from "./QBComponentBase";
import { FileIOService } from "src/app/service/FileIO.service";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";

@Component({
	template:`

		<div class="q scroll-container">
			<div class="qContainer ">
				
				<div class="qnumCol flex-row-left">
					<div class="flex-column-center">
						<div class="qnum">
							{{qObject.questionIndex+1}}
						</div>
					
						<!-- speaker tts icon -->
						<!--
						<div  *ngIf="!qObject.isPageComponent" class="speaker" (click)="speechQ(qObject.question)"></div>
						-->
					</div>


					<div class="qcontent">
						
						<div class="longQContainer">
							<QText [qObj]="qObject" ></QText>
							
							<textarea class="longInput" rows="4" 
								(pointerdown)="onMouseDownInteractive($event)"
								[(ngModel)]="currentState.input" 
								
								[disabled]="!canEdit()" 
								
								autocomplete="off" (focusout)='onFocusOut()'></textarea>
							
						</div>
					
					</div>
				</div>
			</div>

			<div class="score">
						
				<!--
					<Scoring [displayType]="'ai'" 
					(onChange)="updateScore($event)" 
					*ngIf="isSubmitted() && qObject.type!='QBInfoBlock' && isDisplayAIScore()"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
					<Scoring (onChange)="updateScore($event)" *ngIf="isSubmitted(i) && qObject.type!='QBInfoBlock' && isDisplayTeacherScore(i)"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
				-->
			</div>

		</div>
	`,
	styleUrls:[
		"./QBComponentQuestionBase.scss"
	],
	styles:[`
	.shortInput,.longInput  {
		resize: none;
		width: 100%;
		font-size: inherit;
		color:#000;
		background-color: #F5edda !important;
		border-radius: 5px;
		//border: 1px solid var(--common-textcolor);
		margin: 0 0 5px;
		padding: 0 10px;
		line-height: 1.2em;
	}
	`]
})
/*
export class QBLongQuestion extends ROComponent
{
	public inEdit:boolean = false;
	public inEditMode:boolean=false;
	public qObject:any;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["QBLongQuestion"];
	}

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
	}

	
}
*/


export class QBLongQuestion extends QBComponentQuestionBase
{
	public qObject:any;
	public currentState:any = {input:""};
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, public fileio:FileIOService)
	{
		super(cdr, elementRef, fileio);
	}

	public isQuestionComponent(): boolean {
		return true;
	}
	
	public hasAnswer(): boolean {
		return this.currentState && this.currentState.input ? true : false;
	}
	set data(input:string)
	{
		this.currentState = input ? {input:input} : {input:""};
	}
	get data():string
	{
		if(this.hasAnswer()) return this.currentState.input;
		return null;
	}
	onMouseDownInteractive(event)
	{
		event.stopImmediatePropagation();
	}
	
	canEdit()
	{
		return true;
	}

	onFocusOut()
	{
		console.log("focusout");
	}

	public getTagNames():string []
	{
		return ["QBLongQuestion"];
	}

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
	}
	
}