import {
	Component,
	HostListener,
	NgZone,
	ViewChild,
	OnInit,
	Input,
	EventEmitter,
	OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { DomSanitizer } from "@angular/platform-browser";
import { LoadingService } from "src/app/sharedModule/loadingModule/loading.service";
import { DataService } from "src/app/service/data.service";
import { AlertService } from "src/app/service/alert.service";
import { CommonService } from "src/app/service/common.service";
import { ThemeService } from "src/app/service/theme.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ScriptService } from "src/app/service/script.service";
import { RODocumentService } from "src/app/sharedModule/roModule/RODocumentService";
import * as moment from 'moment';
import { ReportPersonalModal } from "../../report-personal.modal";

@Component({
	selector: "p-data-chart",
	template: `
		<div class="data-chart {{ theme.theme$.value }}-theme">
			<div class="panel {{lang}}" *ngIf="chartData">
				<div class="panel-chartType-wrapper">
					<div class="panel-chartType" [class.active]="chartType == ct" *ngFor="let ct of chartTypes" (click)="chartTypeClick(ct)">
						<div class="dot"></div>
						<div class="panel-chartType-label">{{ct |translate}}</div>
					</div>
				</div>
			</div>
			<div class="body {{lang}}">
				<ng-container *ngIf="chartData && libraryLoaded && isLoaded">
					
					<p-distribution-chart *ngIf="chartType == 'chart-type-distribution'"
						[parent]="this"></p-distribution-chart>

					<p-objective-radar-chart *ngIf="chartType == 'chart-type-objective-radar'"
						[parent]="this"></p-objective-radar-chart>

					<p-question-chart *ngIf="chartType == 'chart-type-questions'"
						[parent]="this"></p-question-chart>
						
					<p-chapter-chart *ngIf="chartType == 'chart-type-chapters' || chartType == 'chart-type-sections'"
						[parent]="this"></p-chapter-chart>

					<p-stickers-individual-chart *ngIf="chartType == 'chart-type-sticker-individual'"
						[parent]="this"></p-stickers-individual-chart>

					<p-box-plot-chart *ngIf="chartType == 'chart-type-box-plot'"
						[parent]="this"></p-box-plot-chart>
						
				</ng-container>
			</div>
		</div>
	`,
	styleUrls: ["./personal-data-chart.page.scss"],
})
export class PersonalDataChartPage implements OnInit, OnDestroy {
	@Input() parent: ReportPersonalModal;
	public chartType = "chart-type-distribution";
	public chartTypes = ['chart-type-distribution', 'chart-type-box-plot', 'chart-type-objective-radar', 'chart-type-questions','chart-type-chapters','chart-type-sticker-individual'];
	public modData;
	public tagType;
	public tagTypes;
	public shares;
	public tagTypeWidth = "160px";
	public tagsText;
	public customTags;
	public objectiveSrc;
	public chartData;
	public isLoaded = false;
	public libraryLoaded = false;
	public subscription;
	public filterChange:EventEmitter<any> = new EventEmitter();
	public onDestroy = new Subject();
	public ts;
	public selectedTags = [];
	public sourceType = 'first-submit';
	public sourceTypes = [{label: 'result-panel.first-submit', value: 'first-submit'}, {label: 'result-panel.correction-submit', value: 'correction-submit'}];
	public stickerImageMap;
	public lang;
	constructor(
		private datas: DataService,
		private sans: DomSanitizer,
		private lds: LoadingService,
		private common: CommonService,
		public theme: ThemeService,
		public roDoc: RODocumentService,
		public script: ScriptService,
	) {}

	ngOnInit() {
		if (sessionStorage.getItem('personalChartType')){
			this.chartType = sessionStorage.getItem('personalChartType');
		}
		if (this.parent.data.bookType == 'pdf-paper'){
			this.chartTypes = this.chartTypes.map(e=> e != 'chart-type-chapters'?e:'chart-type-sections'); 
		}
		this.initModData();
		this.common.waitFor(()=> this.modData, 30,300).then(()=>{
			this.getData();
		});

		const timeoutOptions = {timeout: 20000};
		this.script.load('[blob]/ken/roWeb-assets/chartjs-4.3.3.umd.min.js', timeoutOptions).then(()=>{
			const boxPlotPluginPromise = this.script.load('//cdn.jsdelivr.net/npm/@sgratzl/chartjs-chart-boxplot@4.2.4/build/index.umd.min.js', timeoutOptions);
			const labelPluginPromise = this.script.load('//cdnjs.cloudflare.com/ajax/libs/chartjs-plugin-datalabels/2.2.0/chartjs-plugin-datalabels.min.js', timeoutOptions);
			return Promise.all([boxPlotPluginPromise, labelPluginPromise]);
		}).then(()=>{
			this.libraryLoaded = true;
			this.lds.remove('loadLibrary');
		});

		this.parent.filterChange.pipe(takeUntil(this.onDestroy)).subscribe(event=>{
			this.getData();
		});
	}

	ngOnDestroy() {
		this.onDestroy.next();
		this.onDestroy.complete();
		sessionStorage.removeItem('personalChartType');
	}

	initModData(){
		const bsid = this.parent.data.bsid;
		const bookId = this.parent.data.book_id;
		this.datas.post('ROResultPanel.get_share_info_for_result_panel', [bsid, bookId]).subscribe((res: any) => {
			const share = res.share;
			share.color = '#7CCFFA';
			share.peoples = [];
			share.result = [];
			if (share.tag_title.indexOf('cid-') > -1){
				share.tag_title = share['tag_student_' + (this.datas.lang == 'en'?'ename':'cname')];
			}
			share.tag_fulltitle = share.tag_title;
			const tag = { id: share.tag_id, title: share.tag_title };
			this.modData = {
				bsid: bsid, bid: bookId, 
				tid: share.tag_id, tag: tag, lang: this.datas.lang, 
				appId: this.datas.appId2,
				bookType: res.entry_type,
				bookStatus: share.status,
				shares: [ share ],
				share: share,
				mainShare: share,
				uid: this.parent.modData.uid,
				studentName: this.parent.studentName
			};
			this.shares = this.modData.shares;
		});
	}

	getData(){
		this.chartData = null;
		this.isLoaded = false;
		this.lds.add('get_chart_data', 8000);
		const bsid = this.parent.data.bsid;
		const bookId = this.parent.data.book_id;
		const submitIndex = this.parent.selectedStatus;
		let apiLoaded = 0;
		this.datas.post('ROResultPanel.get_chart_data', [ bsid, bookId, bsid, submitIndex]).subscribe((res:any)=>{
			const { structure, chapters, scoredComponents, allComponents } = this.formatStructure(res.book.structure, this.shares, [...res.results]);
			res.peoples.forEach(ppl => {
				const bsids = ppl.bsids.split(',');
				this.shares.filter(e=> bsids.indexOf(e.bsid) > -1).forEach(share =>{
					const pplObj = {uid: ppl.uid, cname: ppl.cname, ename: ppl.ename, score: null, calculatedCid: [], stickers: [], active: ppl.active, status: ppl.status, classno: ppl.classno };
					share.peoples.push(pplObj);
				});
			});

			res.peoples.sort((a,b)=>{
				const aKey = ('' + a.classno).padStart(4,'0') + ('' +a.uid).padStart(8,'0');
				const bKey = ('' + b.classno).padStart(4,'0') + ('' +b.uid).padStart(8,'0');
				if (aKey > bKey){
					return 1;
				} else if (bKey > aKey){
					return -1;
				} else {
					return 0;
				}
			});

			res.results.forEach(r => {
				const share = this.shares.find(e=> e.bsid == r.bsid);
				const hasScoring = scoredComponents.find(e => e.cid == r.cid);
				if (share && hasScoring){
					share.result.push(r);
					const people = share.peoples.find(p=> p.uid == r.uid);
					if (people){
						const score = Math.round(parseFloat(r.score) * 10) / 10;
						if (people.calculatedCid.indexOf(r.cid) == -1){
							people.calculatedCid.push(r.cid);
							people.score += score;
						}
					}
				}
			});

			let stickers = [];
			if (res.stickers){
				res.stickers.forEach(row => {
					let image = null;
					if (row.type == "teacherStickerPublic" || row.type == 'teacherStickerSchool'){
						if (row.typeRefID == 2){
							image = this.script.assetsUrl + 'ro/app_icons/datamode_like.svg';
						} else if (row.typeRefID == 1){
							image = this.script.assetsUrl + 'ro/app_icons/datamode_sticker1.svg';
						} else if (row.image && row.image.indexOf('//') == 0){
							image = row.image;
						} else if (row.image){
							image = '//oka.blob.core.windows.net/media/' + row.image;
						} else {
							image = this.script.assetsUrl + 'ro/app_icons/datamode_sticker0.svg';
						}
					} else if (row.type == 'starPublic'){
						image = this.script.assetsUrl + 'ro/app_icons/datamode_star.svg';
					}
					const styledImage = this.sans.bypassSecurityTrustStyle(`url("${image}")`);
					const found = stickers.find(e=> e.typeRefID == row.typeRefID && e.type == row.type && e.bsid == row.bsid);
					if (found){
						found.num++;
					} else {
						let sticker = {typeRefID: row.typeRefID, type: row.type, bsid: row.bsid, num: 1, image: row.image, stickerImage: styledImage, stickerImageSrc: image};
						stickers.push(sticker);
					}
					for(let i=0;i < this.shares.length; i++){
						const share = this.shares[i];
						if (share.bsid == row.bsid){
							const student = share.peoples.find(e=> e.uid == row.uid);
							if (student){
								student.stickers.push(row);
								break;
							}
						}
					}
				});
			}

			this.shares.forEach(share => {
				share.peoples = share.peoples.filter(ppl => ppl.active == 1 || ppl.calculatedCid.length > 0);
			}); // 如果封存又沒有提交便不要
			
			this.chartData = { structure, chapters, scoredComponents, allComponents, shares: this.shares, stickers: stickers };
			this.chartData.bookType = this.modData.bookType;
			this.chartData.passCondition = res.book?res.book.pass_condition:null;
			if (res.markings){
				this.chartData.markings = res.markings.map(item=>{
					const data = this.parent.stringToObject(item.data);
					let obj:any = {uid: item.uid, bsid: item.bsid, data: data };
					return obj;
				});
			}
			// this.updateChartTitle();
			const isPDFPaperOrHasMCComp = this.modData.bookType == 'pdf-paper' || allComponents.find(e=> ['MC2','SimpleMC','GraphicMC'].indexOf(e.type) > -1);
			if (isPDFPaperOrHasMCComp){
				this.loadXML(bookId);
			}
			this.lds.remove('get_chart_data');
			this.chartData.uid = this.modData.uid;
			this.chartData.studentName = this.modData.studentName;
			apiLoaded++;
			this.isLoaded = apiLoaded == 2;
		});

		this.datas.post('ROResultPanel.get_data_for_objectiveManagerModal', [ bookId ]).subscribe((res:any)=>{
			this.common.waitFor(()=> this.chartData, 30, 300).then(chartData =>{
				chartData.learningObjectives = this.initPointData(chartData, res.points);
				apiLoaded++;
				this.isLoaded = apiLoaded == 2;
			});
		});
	}

	formatStructure(json, shares, results){
		let structure = JSON.parse(json);
		let chapters:any = [];
		let allComponents:any = [];
		let scoredComponents:any = [];
		structure.book.maxScore = 0;
		results = results.filter((r,i)=>{
			const index = results.findIndex(e=> e.cid == r.cid && e.uid == r.uid && e.bsid == r.bsid);
			return index == i;
		}); //remove duplicate user result for same cid same bsid
		structure.book.chapters.forEach(chapter=>{
			chapter.maxScore = 0;
			chapter.results = [];
			if (chapter.pages){
				chapter.pages.forEach(page => {
					if (page.components){
						page.components.forEach(comp => {
							if (comp.hasScoring){
								comp.maxScore = comp.score?parseFloat(comp.score.full):0;
								chapter.maxScore += comp.maxScore;
								structure.book.maxScore += comp.maxScore;
								scoredComponents.push(comp);
							}
							allComponents.push(comp);
							comp.results = results.filter(e=> e.cid == comp.cid);
							results = results.filter(e=>e.cid != comp.cid);
							chapter.results = [...chapter.results, ...comp.results];
						});
					}
				});
			}
			chapters.push(chapter);
		});
		if (this.modData.bookType == 'pdf-paper'){
			allComponents = allComponents.sort((a,b)=>{
				const aKey = +a.name;
				const bKey = +b.name;
				if (aKey > bKey){
					return 1;
				} else if (bKey > aKey){
					return 1;
				} else {
					return 0;
				}
			});

			scoredComponents = scoredComponents.sort((a,b)=>{
				const aKey = +a.name;
				const bKey = +b.name;
				if (aKey > bKey){
					return 1;
				} else if (bKey > aKey){
					return 1;
				} else {
					return 0;
				}
			});
			
			allComponents.forEach(comp => {
				const compBeforeInQS = allComponents.filter(e=> e.qSection == comp.qSection && +e.name < +comp.name);
				comp.qsNumName = (comp.qSection?comp.qSection:'') + (compBeforeInQS.length + 1);
			});
		}
		return { structure, chapters, scoredComponents, allComponents };
	}

	loadXML(book_id){
		return this.roDoc.open({id: book_id}).then((res:any)=>{
			this.chartData.compXmls = [];
			if (res.chapters){
				const parser = new DOMParser();
				res.chapters.forEach(ch => {
					if (ch.xml){
						const xml = ch.xml.replace(/&quot;/g,'\"');

						//handle ScoreCom
						let start = 0;
						let end = 0;
						while (xml.indexOf('ScoreCom', start) > -1){
							start = xml.indexOf('ScoreCom', start) - 1;
							end = xml.indexOf('>', start);
							const text = xml.substring(start, end + 1);
							const [ a, cid ] = text.match(/douid=\"(.*?)\"/) || [ null, null ];
							const [ b, questionSection ] = text.match(/questionSection=\"(.*?)\"/) || [null, null];
							const [ c, context ] = text.match(/context=\"(.*?)\}\"/) || [null, null];
							if (context){
								try {
									const obj = JSON.parse(context + '}');
									let newKey = 'ScoreCom';
									if (obj.type == 'free'){
										newKey = 'ScoreCom-free';
									} else if (obj.type == 'mc'){
										newKey = 'ScoreCom-mc';
									} else if (obj.type == 'tf' || obj.type == 'full'){
										newKey = 'ScoreCom-tf';
									}
									let compXmlObj:any = {cid: cid, key: newKey, questionSection: questionSection};
									this.chartData.compXmls.push(compXmlObj);
									const foundComp = this.chartData.allComponents.find(comp=> comp.cid == cid);
									if (foundComp){
										foundComp.type = newKey;
										if (newKey == 'ScoreCom-mc'){
											foundComp.mcType = obj.multiSelect?'multiple':'single';
										}
										compXmlObj.name = foundComp.name;
									}
								} catch (e){

								}
							}
							start = end + 1;
						}

						//handle MC2 multipleSelection:"true"|"false"
						start = 0;
						end = 0;
						while (xml.indexOf('<MC2', start) > -1){
							start = xml.indexOf('<MC2', start);
							end = xml.indexOf('>', start);
							const text = xml.substring(start, end + 1);
							const [ a, cid ] = text.match(/douid=\"(.*?)\"/) || [ null, null ];
							const [ b, multipleSelection ] = text.match(/multipleSelection=\"(.*?)\"/) || [ null, null ];
							const foundComp = this.chartData.allComponents.find(comp=> comp.cid == cid);
							if (foundComp){
								foundComp.mcType = multipleSelection == 'true'?'multiple':'single';
							}
							start = end + 1;
						}

						//handle SimpleMC multiSelection:true|0
						start = 0;
						end = 0;
						while (xml.indexOf('<SimpleMC', start) > -1){
							start = xml.indexOf('<SimpleMC', start);
							end = xml.indexOf('>', start);
							const text = xml.substring(start, end + 1);
							const [ a, cid ] = text.match(/douid=\"(.*?)\"/) || [ null, null ];
							const foundComp = this.chartData.allComponents.find(comp=> comp.cid == cid);
							if (foundComp){
								foundComp.mcType = text.indexOf('multiSelection":true') > -1?'multiple':'single';
							}
							start = end + 1;
						}

						//handle GraphicMC allowMultipleSelection:"true"|"false"
						start = 0;
						end = 0;
						while (xml.indexOf('<GraphicMC', start) > -1){
							start = xml.indexOf('<GraphicMC', start);
							end = xml.indexOf('>', start);
							const text = xml.substring(start, end + 1);
							const [ a, cid ] = text.match(/douid=\"(.*?)\"/) || [ null, null ];
							const [ b, allowMultipleSelection ] = text.match(/allowMultipleSelection=\"(.*?)\"/) || [ null, null ];
							const foundComp = this.chartData.allComponents.find(comp=> comp.cid == cid);
							if (foundComp){
								foundComp.mcType = allowMultipleSelection == 'true'?'multiple':'single';
							}
							start = end + 1;
						}
						
					}
				});

			}
			this.chartData.compXmls = this.chartData.compXmls.sort((a,b)=>{
				const aKey = ('' + a.name).padStart(5,'0');
				const bKey = ('' + b.name).padStart(5,'0');
				if (aKey > bKey){
					return 1;
				} else if (bKey > aKey){
					return -1;
				} else {
					return 0;
				}
			});
		}).catch(e=>{
			this.chartData.compXmls = [];
		});
	}

	
	initPointData(chartData, points){
		let learningObjectives:any = [];
		points.forEach(point=>{
			let lo:any = {learning_objective: point.learning_objective, title: point.title, form_id: point.form_id, form_name: point.form_name, cids: [ point.cid ], shares: [], maxScore: 0, avgScore: null, avgScorePt: null }; 
			const foundLo = learningObjectives.find(e=> e.learning_objective == point.learning_objective);
			if (foundLo){
				foundLo.cids.push(point.cid);
				lo = foundLo;
			} else {
				learningObjectives.push(lo);
			}
			const foundComp = chartData.scoredComponents.find(e=> e.cid == point.cid);
			if (foundComp){
				lo.maxScore += parseFloat(foundComp.maxScore);
			}
		});
		learningObjectives.forEach(lo => {
			let loPeopleCount = 0;
			let loPeopleScore = 0;
			lo.shares = chartData.shares.map(sh =>{
				let shareInLo:any = {bsid: sh.bsid, tag_title: sh.tag_title, tag_fulltitle: sh.tag_fulltitle, avgScore: 0, avgScorePt: null, peoples: [] };
				let shareSubmittedCount = 0;
				sh.color = '#7CCFFA';
				sh.peoples.forEach(people=>{
					let ppl:any = {uid: people.uid, cname: people.cname, ename: people.ename, score: null};
					chartData.scoredComponents.filter(e=> lo.cids.indexOf(e.cid) > -1).forEach(sComp=>{
						sComp.results.filter(e=> e.uid == ppl.uid && e.bsid == sh.bsid).forEach(r=>{
							ppl.score = ppl.score === null?0:ppl.score;
							let scoreInResult = isNaN(r.score)?0:parseFloat(r.score);
							try{
								let resultObj = JSON.parse(r.result); 
								if (resultObj[lo.learning_objective] !== undefined){
									scoreInResult = isNaN(resultObj[lo.learning_objective])?0:parseFloat(resultObj[lo.learning_objective]);
									scoreInResult = scoreInResult / 100 * sComp.maxScore;
								}
							} catch(e){}
							ppl.score += scoreInResult;
						});
					});
					ppl.percent = ppl.score === null?null: Math.round(ppl.score * 100 * 10 / lo.maxScore) / 10;
					if (ppl.percent !== null){
						shareSubmittedCount++;
						shareInLo.avgScore += ppl.score;
						loPeopleCount++;
						loPeopleScore += ppl.score;
					}
					shareInLo.peoples.push(ppl);
				});
				if (shareSubmittedCount > 0){
					shareInLo.avgScore = Math.round(shareInLo.avgScore * 10 / shareSubmittedCount) / 10;
					shareInLo.avgScorePt = Math.round(shareInLo.avgScore * 10 * 100 / lo.maxScore) / 10;
				}
				return shareInLo;
			});
			if (loPeopleCount > 0){
				lo.avgScore = Math.round(loPeopleScore * 10 / loPeopleCount) / 10;
				lo.avgScorePt = Math.round(lo.avgScore * 10 * 100/ lo.maxScore) / 10;
			}
		});
		return learningObjectives;
	}

	chartTypeClick(chartType){
		this.chartType = chartType;
		sessionStorage.setItem('personalChartType', chartType);
	}

}
