import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScriptService } from 'src/app/service/script.service';
import { StorageService } from 'src/app/service/storage.service';
import { ThemeService } from 'src/app/service/theme.service';
import { faXmark, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faVolume } from '@fortawesome/pro-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from '../loadingModule/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'dictionaryPopup',
	templateUrl: './dictionaryPopup.html',
	styleUrls: ['./dictionaryPopup.scss']
})

export class DictionaryPopupComponent implements OnInit {
	@ViewChild('audio1', { static: false }) audio1: any
	@Input() public BGstyle: any = {}
	@Output() public onClose: EventEmitter<any> = new EventEmitter<any>();
	public dictionary_text: any = "";
	public failed: boolean = false;
	public audio_link1 = ""
	public content_list = [];
	public audio: HTMLAudioElement = new Audio();
	public animation_list=[];
	public current_animation="";

	faXmark = faXmark
	faVolume = faVolume
	faCircleExclamation = faCircleExclamation

	public method_list = [1, 2]
	public fail_text_key = {
		tc: "未能取得相關資料",
		sc: "未能取得相关资料",
		en: "Unable to obtain relevant information"
	}


	constructor(
		private http: HttpClient, private loads: LoadingService, private datas: DataService, private sanitizer: DomSanitizer,
		public theme: ThemeService, public eleRef: ElementRef, public script: ScriptService, public storage: StorageService, public translate: TranslateService
	) {

	}

	ngOnInit(): void {
		this.init();
	}
	init() {
		this.dictionary_text = ""
		this.failed = false
		this.audio_link1 = ""
		this.content_list = []
		this.animation_list = ["start","popup_rise"]
		this.current_animation = "start"
	}
	open(text: string) {
		this.init()
		text = text.trim()
		this.dictionary_text = text

		this.loads.add('getting dictionary data')

		this.method1(text)
	}
	play_audio1() {
		if (this.audio_link1) {
			this.audio1.nativeElement.play()
		}
	}
	show_failed(method = 1) {
		console.log("failed");

		if (method != this.method_list[this.method_list.length - 1]) {
			this.run_method(this.method_list[this.method_list.indexOf(method) + 1])
			return
		}
		this.current_animation = this.animation_list[1]
		this.loads.remove('getting dictionary data')
		this.failed = true
	}
	close() {
		this.init()
		this.loads.remove('getting dictionary data')
		this.onClose.emit(true)
	}
	run_method(number = 1) {
		switch (number) {
			case 1:
				this.method1(this.dictionary_text)
				break;
			case 2:
				this.method2(this.dictionary_text)
				break;
			default:
				this.run_method(this.method_list[this.method_list.length - 1])
				break;
		}
	}
	method1(text) {
		console.log('method1()', text);

		this.datas.post('RODictionary.lookup_text_from_cache', ['en', text]).subscribe((res: any) => {
			console.log("res : ", res);
			if (res && res.code == 200 && res.cache) {
				this.datas.post('RODictionary.fetch_cache_result', [parseInt(res.cache)]).subscribe((res2: any) => {
					console.log("res2 : ", res2);
					if (res2 && res2.code == 200 && res2.status == "completed" && res2.result) {
						try {
							this.dictionary_text = res2.result.json.word
							let lexicalEntries = res2.result.json.results[0].lexicalEntries
							console.log(lexicalEntries);
							// audio
							try {
								this.audio_link1 = lexicalEntries[0].entries[0].pronunciations[0].audioFile
							} catch (error) {

							}

							// content
							for (const lexicalEntry of lexicalEntries) {
								let content = { lexicalCategory: lexicalEntry.lexicalCategory.text }
								let senses = lexicalEntry.entries[0].senses

								let sense_list = []
								for (const sense of senses) {
									console.log(sense);
									let definition = ""
									if (sense.definitions && sense.definitions[0]) {
										definition = sense.definitions[0]
									} else if (sense.crossReferences && sense.crossReferences[0]) {
										definition = sense.crossReferences[0]['text']
									}

									let example_list = []
									if (sense.examples) {
										example_list = sense.examples.map(item => `eg. ${item.text}`);
									}
									sense_list.push({ definition, example_list })
								}
								content['sense_list'] = sense_list

								console.log(content);
								this.content_list.push(content)
							}
							console.log(this.content_list);

							this.current_animation = this.animation_list[1]
							this.loads.remove('getting dictionary data')
						} catch (error) {
							console.error(error);
							this.show_failed(1)
						}
					} else {
						this.show_failed(1)
					}
				})
			} else {
				this.show_failed(1)
			}
		});
	}
	method2(text) {
		console.log('method2()', text);

		this.http.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${text}`).subscribe((res: any) => {
			console.log(res);
			// audio
			try {
				if (res[0] && res[0].phonetics) {
					if (res[0].phonetics[0] && res[0].phonetics[0]['audio']) {
						this.audio_link1 = res[0].phonetics[0]['audio']
					}
				}
			} catch (error) { }
			// content
			try {
				if (res[0] && res[0].meanings) {
					for (const meaning of res[0].meanings) {
						let content = { lexicalCategory: meaning.partOfSpeech }
						let sense_list = []
						for (const def of meaning.definitions) {
							let definition = ""
							if (def.definition) {
								definition = def.definition
							}
							let example_list = []
							if (def.example) {
								example_list.push("eg. " + def.example)
							}
							sense_list.push({ definition, example_list })
						}
						content['sense_list'] = sense_list
						this.content_list.push(content)
					}
				}
			} catch (error) {
				console.log(error);
				this.show_failed(2)
			}
			this.current_animation = this.animation_list[1]
			this.loads.remove('getting dictionary data')


		}, (err: Error) => {
			this.show_failed(2)
		})

	}
	public readText(): void {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: 'F',
			txt: this.dictionary_text,
			speed: 1.1,
			lang: 'en-GB',
			pitch: 1,
			name: "en-GB-LibbyNeural",
			style: "",
		};

		this.datas.get2({ url: url, data: data, jwt: false }).then((res: any) => {
			if (res.token && res.token.url) {
				let statusUrl: string = res.token.url;
				if (statusUrl.substr(0, 7) == "http://")
					statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
				this.datas
					.get2({ url: statusUrl, jwt: false })
					.then((res: any) => {
						if (res.url) {
							this.audio.setAttribute("src", res.url);
							this.audio.play();
						}
					});
			}
		});
	}
}