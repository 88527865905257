import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { DataService } from './data.service';
import { filter, map } from 'rxjs/operators';
import { CommonService } from './common.service';

export interface webSocketServiceSendOption {
	messageType?:string;
	content?:any;
	roomId?:string;
	wsId?:string;
	uid?:string;
}

@Injectable({ providedIn: 'root' })
export class WebSocketService {

	readonly channelId:string = 'rainbowone';
	public message$:Subject<any> = new Subject();
	public event$:Subject<any> = new Subject();

	private ws:WebSocket = null;
	public userInfo:any = null;
	private _isReady:boolean = false;
	private _lastUid:string = null;
	private _retry:number = 1;
	private _serverLastOnlineAt:number = 0;
	private _reconnectTimer:any = null;

	constructor(private datas:DataService, private coms:CommonService) {
		//check browser support webSocket
		if ('WebSocket' in window) {

			this.coms.waitFor(() => datas.jwt, 30).then(() => {
				setTimeout(()=>{
					this.checkBeforeConnect();
					//註冊檢查jwt更新時重連webSocket
					datas.jwt$.subscribe((jwt:string)=>this.checkBeforeConnect());
				}, 1000);
			});


			this.getMessageObservable('ready').subscribe((msg:any)=>{
				this.userInfo = (msg.content && msg.content.userInfo) || null;
				this._isReady = true;
				this._retry = 1;
			});

			//每10秒檢查所有ws client是否仍健在
			setInterval(()=>{
				if (this.isConnected()) {
					const now = +new Date();
					if (this._serverLastOnlineAt && (now - this._serverLastOnlineAt) > 38000) {
						this.disconnect();
						this.checkBeforeConnect();
					} else if (this._serverLastOnlineAt && (now - this._serverLastOnlineAt) > 28000) {
						this.send({messageType: 'ping'});
					}
				}
			}, 10000);
		} else {
			console.log('browser is does not support webSocket, will not init WebSocket');
		}
	}
	//---------------------------------------------------------------------------------------------
	public checkBeforeConnect():void {
		if (!this.datas || !this.datas.jwt || !this.datas.userInfo || !this.datas.userInfo.uid) {
			this.disconnect();
		} else if (this.isConnected() && (this.userInfo.uid == this.datas.userInfo.uid)) {
			return;
		} else {
			this.connect();
		}
	}
	//---------------------------------------------------------------------------------------------
	private connect():void {
		this.disconnect();
		const jwt:string = this.datas.jwt;
		if (!jwt) return;

		let url:string = (window.location.protocol == 'http:') ? environment.roWebSocketServer.httpHost : environment.roWebSocketServer.httpsHost;
		url += `?jwt=${jwt}`
		url += '&channelId=' + this.channelId;
		this.ws = new WebSocket(url);

		// Listen for messages
		this.ws.addEventListener("message", (event) => {
			this._serverLastOnlineAt = +new Date();
			this.event$.next({eventType:'message', event:event});

			if (event.data) {
				try {
					let msgObj:any = JSON.parse(event.data);
					if (msgObj.messageType=="ping") {
						this.send({messageType:'pong'});
					} else {
						this.message$.next(msgObj);
					}
				} catch(e) {
					this.message$.next(event.data);
				}
			}
		});

		this.ws.addEventListener("error", (event) => {
			this.event$.next({eventType:'error', event:event});
			this.handleConnectionError();
		});

		this.ws.addEventListener("open", (event) => {
			this.event$.next({eventType:'open', event:event});
		});

		this.ws.addEventListener("close", (event) => {
			this.handleConnectionError();
			this.event$.next({eventType:'close', event:event});
		});
	}
	//---------------------------------------------------------------------------------------------
	private handleConnectionError():void {
		this.disconnect();
		//無限retry，為免同時太多connect request，按retry次數幾何級delay，(retry * retry * 5000) = 5, 20, 45, 80, 125, 180, 245, 320, 405, 500秒
		if (!this._reconnectTimer) this._reconnectTimer = setTimeout(()=>{
			this._reconnectTimer = null;
			this._retry++;
			this.checkBeforeConnect();
		}, this._retry * this._retry * 5000);
	}
	//---------------------------------------------------------------------------------------------
	public disconnect():void  { 
		this._isReady = false;
		this.userInfo = null;
		if (this.ws && this.ws.close) this.ws.close();
	}
	//---------------------------------------------------------------------------------------------
	public isConnected(): boolean {
		return (this.ws && this.ws.readyState==1 && this._isReady);
	}
	//---------------------------------------------------------------------------------------------
	public send(option:webSocketServiceSendOption):void {
		if (this.isConnected()) this.ws.send(JSON.stringify(option));
	}
	//---------------------------------------------------------------------------------------------
	public getMessageObservable(messageType:string|null=null, roomId:string|null=null):Observable<any> {
		return this.message$.pipe(
			filter(m => {
				return (messageType==null && roomId==null) || 
				(messageType==m.messageType && roomId==null) ||
				(messageType==null && roomId==m.roomId) ||
				(messageType==m.messageType && roomId==m.roomId)
			}),
			map(m => m)
		);
	}
	//---------------------------------------------------------------------------------------------
	public getEventObservable(eventType:string|null=null):Observable<any> {
		return this.event$.pipe(
			filter(e => {
				return (eventType==null) || 
				(eventType==e.eventType)
			}),
			map(e => e)
		);
	}
	//---------------------------------------------------------------------------------------------
	public getWsId():string {
		return (this.userInfo && this.userInfo.wsId) || "";
	}
	//---------------------------------------------------------------------------------------------

}
