import { ChangeDetectorRef, Component, ElementRef, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";

// =======================================
// icon
// =======================================
import { faPrint, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
	template:`
	<div class="hitarea" [ngClass]="[icon!=null ? icon  : '']" (click)="print()">
		<fa-icon [icon]="faPrint"></fa-icon>
	</div>
	`,
	styles:[
		`
		.hitarea.none{
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
		}
		fa-icon{
			display:flex;
			color:#fff;
			font-size: 20px;
			width:42px;
			height:42px;
//			outline: solid 1px #fff !important;
//			outline-offset: -0.5px;
			border-radius: 15px;
			background: linear-gradient(to top left, #6D3C0D 0%, #95561A 50%, #AF743D 90%);
			box-shadow: #582E06 0px -2px 0px 0px inset;
		}
		fa-icon:after{
			position: absolute;
			top:0;
			left:0;
			content: '';
			width:calc(100% - 2px);
			height:calc(100% - 2px);
			border-radius: 13px;
			border: solid 1px #fff;
		}
		
		:host ::ng-deep fa-icon svg{
			margin: auto;
			filter: drop-shadow(3px 3px 5px rgba(0,0,0,0.5));
		}

		.large fa-icon{
			width:60px;
			height:60px;
			font-size: 29px;
			border-radius: 21px;
		}
		.large fa-icon:after{
			border-radius: 19px;
		}
		.none fa-icon{
			visibility: hidden;
		}
		`
	]
})
export class ROPrintBtn extends ROComponent
{
	public faPrint:IconDefinition = faPrint;
	public icon:string;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["PrintBtn"];
	}
	
	protected buildContent():void
	{
		this.icon = this.node.attributes.icon;

		// non transprent button, width & height is wrong
		if(this.icon=="small") {
			this.w = 42;
			this.h = 42;
			this.elementRef.nativeElement.style.width = this.w + "px";
			this.elementRef.nativeElement.style.height = this.h + "px";
		} else if(this.icon=="large") {
			this.w = 60;
			this.h = 60;
			this.elementRef.nativeElement.style.width = this.w + "px";
			this.elementRef.nativeElement.style.height = this.h + "px";
		}
	}

	public print():void {
		alert("print");
	}

}