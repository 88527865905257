import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ProgressModal } from './progress.modal';
import { UploadAlertModal } from './upload-alert.modal';
import { ScriptService } from 'src/app/service/script.service';
import { CaptureComponent } from './capture.component';
import { CameraCaptureModule } from '../CameraCaptureModule/CameraCapture.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UploadButton } from './upload-button.component';
import { PreviewModule } from '../previewModule/preview.module';

@NgModule({
    imports: [
		CommonModule,TranslateModule,FormsModule, CameraCaptureModule, FontAwesomeModule, PreviewModule
    ],
    declarations: [
        ProgressModal,UploadAlertModal, CaptureComponent, UploadButton
    ],
	exports:[
		UploadButton
	],
	entryComponents: [
        ProgressModal, UploadAlertModal, CaptureComponent
    ],
	providers:[ScriptService]
})
export class UploadModule { }
