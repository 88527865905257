import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PinYinKeyboardService } from './PinYinKeyboardService';

@Directive({
	selector: '[pinyin-keyboard]'
})
export class PinYinKeyboardDirective implements OnDestroy {
	constructor(
		private _elementRef: ElementRef,
		private keyboardService:PinYinKeyboardService
		// ,
		// @Optional() @Self() private _control?: NgControl
	) { 

		console.log("PinYinKeyboardDirective.constructor");
	}

	ngOnDestroy() {
		this.hideKeyboard();
	}

	@HostListener('focus', ['$event'])
	public showKeyboard() {
		console.log("show keyboard");
		this.keyboardService.showKeyboard(this._elementRef.nativeElement);
	}

	@HostListener('blur', ['$event'])
	public hideKeyboard() {
		this.keyboardService.dismiss();
	}

}
