import { Injectable } from '@angular/core';

import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=Open Knowledge Association Ltd.,LicensedApplication=rainbowone,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-023409,ExpiryDate=14_December_2022_[v2]_MTY3MDk3NjAwMDAwMA==4908735a2d122919f4e91a9613fb03e1");
import * as tc from '../../assets/langs/tc.json';
import * as sc from '../../assets/langs/sc.json';
import * as en from '../../assets/langs/en.json';
import { DataService } from './data.service';
@Injectable({ providedIn: 'root' })
export class AgGridService {
	private tc:any = tc;
	private sc:any = sc;
	private en:any = en;
	public localeObj;

	constructor(public datas: DataService) {
		this.loadLocale();
	}

	public loadLocale(){
		const lang = this.datas.lang;
		if (lang === 'sc') {
			this.localeObj = this.sc.default.agGrid;
		} else if (lang === 'en') {
			this.localeObj = this.en.default.agGrid;
        } else {
			this.localeObj = this.tc.default.agGrid;
		}
	}


}
