import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from 'src/app/service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-light-svg-icons';

@Component({
	selector: 'numberStepper',
	template: `
	<div class="button" [ngStyle]="buttonStyle" (click)="buttonClick('minus')">
		<fa-icon [ngStyle]="iconStyle" [icon]="faCircleMinus"></fa-icon>
	</div>
	<div class="number" [ngStyle]="numberStyle">{{value}}</div>
	<div class="button" [ngStyle]="buttonStyle">
		<fa-icon [ngStyle]="iconStyle" [icon]="faCirclePlus" (click)="buttonClick('plus')"></fa-icon>
	</div>
    `,
	styleUrls: ['./numberStepper.component.scss'],
})

export class NumberStepperComponent implements OnInit {
	public faCirclePlus: any = faCirclePlus;
	public faCircleMinus: any = faCircleMinus;
	@Input() public value;
	@Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() public disabled = false;
	@Input() public numberStyle;
	@Input() public buttonStyle;
	@Input() public iconStyle;
	@Input() public options:any = {max: 100, min: 0, step: 10};
	public isDisabled: boolean = false;
	constructor(private eleRef: ElementRef, private themeService: ThemeService) {

	}

	ngOnInit(): void {

	}

	buttonClick(action){
		let step = this.options.step?this.options.step:10;
		let max = this.options.max?this.options.max:0;
		let min = this.options.min?this.options.min:0;
		if (this.isDisabled){
			return;
		} else if (action == 'plus'){
			this.value = this.value + step;
			this.value = Math.min(this.value, max);
		} else {
			this.value = this.value - step;
			this.value = Math.max(this.value, min);
		}
		this.valueChange.emit(this.value);
	}
	
}
