import { EventEmitter, Injectable, Output } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ThemeService } from 'src/app/service/theme.service';

@Injectable()
export class StorageService {
  public json:any = {};
  public title = '';
  public image = '';
  public score = 0;
  public bookClass = 'red';
  public bookType = 'book';
  public remain = 0;
  public maxScore = 0;
  public question = 0;
  public fullCorrect = 0;
  public halfCorrect = 0;
  public submitted = 0;
  public wrong = 0;
  public numQuestion = 0;
  public chapters: any[] = [];
  public bookId = '';
  public token = '';
  public lang:string = 'tc';
  public appId;
  public dev:any = {};
  public maxGrid = 0;
  pieSrc = {'correct-2':0,'correct-1':0,'correct-0':0,'submitted':0,'blank':0};
  public pieData = [];
  public role;
  public openBookModal:any;
  public fromAppModal = 0;
  @Output() callOpenBookModal:EventEmitter<any> = new EventEmitter<any>();
  constructor(public translate:TranslateService, private deviceService: DeviceDetectorService, private theme: ThemeService){
    // translate.setDefaultLang('tc');
    this.dev.info = deviceService.getDeviceInfo();
    this.dev.isMobile = deviceService.isMobile() || deviceService.isTablet();
    this.fixIosScroll();
  }

  public initProp(){
    this.fullCorrect = 0 ;
    this.halfCorrect = 0 ;
    this.submitted = 0 ;
    this.wrong = 0;
    this.numQuestion = 0;
    this.pieSrc = {'correct-2':0,'correct-1':0,'correct-0':0,'submitted':0,'blank':0};
  }

  public loadBook(){
    let obj = this.json;
    this.initProp();
    try{
      this.title = obj.title;
      this.bookClass = obj.class;
      this.image = obj.image;
      this.maxScore = Number(obj.maxScore) || 0;
      this.numQuestion = Number(obj.numQuestion) || 0;
      this.chapters = obj.chapters;
      let usedHeight = 0;
      this.question = this.score = 0;
      this.maxGrid = Math.floor((window.innerWidth - 250) / 100);
      this.chapters.forEach((ele)=>{
        ele.components.forEach((e)=>{
          if (this.pieSrc[e.class] !== undefined)
            this.pieSrc[e.class]++;
        });
        this.fullCorrect += ele.components.filter((e)=>['correct-2'].indexOf(e.class) > -1).length;
        this.halfCorrect += ele.components.filter((e)=>['correct-1'].indexOf(e.class) > -1).length;
        this.wrong += ele.components.filter((e)=>['correct-0'].indexOf(e.class) > -1).length;
        this.question += this.fullCorrect + this.halfCorrect;
        ele.height = (Math.ceil(ele.components.length/this.maxGrid)) * 100;
        usedHeight += ele.height;
        this.score += Number(ele.score);
      });
      this.pieData = this.calPieData();
      this.remain = this.calRemain(usedHeight);
    } catch(e){
      console.error('storageService::loadBook');
      console.log(obj);
    }
  }

  public loadParams(params){
    this.lang = params[0];
    this.bookId = params[1];
    this.token = params[2];
  }

  calRemain(usedHeight):number{
    let out = window.innerHeight - 150 - usedHeight;
    if (this.dev.info.device == 'ipad'){
      out = out - 3;
      return Math.max(out,25);
    } else {
      out = out - 5;
      return Math.max(out,16);
    }
  }

  calPieData(){
    let colorClass = ['white','blue','red','orange','green'];
	const whiteColor = this.theme.themeSelected == 'dark'?'#3d3d55':'#fff';
	let colorMap = {white: whiteColor, blue: '#80bcf0', red: 'ff8383', orange: '#ffbf46', green: 'a8d473'};
    let statusClass = ['blank','submitted','correct-0','correct-1','correct-2'];
    let start = 0;
    let out = [];
    colorClass.forEach((e,k)=>{
      let value = Math.round((this.pieSrc[statusClass[k]] / this.numQuestion) * 360);
      // value += (k==0?start:0);
      let elClass = (value > 179? e + ' big':e);
      out.push({elClass: elClass, start:start, value:value, color: colorMap[e]});
      start = start + value;
    });
    return out;
  }

  fixIosScroll() {
    if (this.dev.isMobile) {
      document.ontouchstart = function (e) {
        document.body.scrollTop = 0;
      };
      document.ontouchend = function (e) {
        document.body.scrollTop = 0;
      };
    }
  }

}
