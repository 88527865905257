import { Component } from "@angular/core";
import { DataService } from "src/app/service/data.service";

//@Component({
//	selector: 'appStatus',
//	templateUrl: './AppStatus.component.html',
//	styleUrls: ['./AppStatus.component.scss'],
@Component({
	selector: 'appStatus',
	template: `
	<!--
		<loading-icon *ngIf="loadingList.length>0"></loading-icon>
	-->
		<div class="uploadingState" [ngClass]="[uploadingState, effect]"></div>
		`,
	styles:[
		`
		:host{
			position: absolute;
			top: 10px;
			right: 100px;
			user-select: none;
			pointer-events: none;
		}
		.uploadingState{
			display:block;
			width:40px;
			height:40px;
			background-repeat:no-repeat;
			background-position:center center;
			background-size: 100% 100%, auto;
		}
		.uploading{
			background-image: url('./assets/ro/state/uploading.svg');
		}
		.upload_success{
			background-image: url('./assets/ro/state/upload_success.svg');
		}
		.upload_fail{
			background-image: url('./assets/ro/state/upload_fail.svg');
		}

		.fade-in {
			transition: opacity 0.3s ease-in-out;
			opacity: 1;
		}
		.fade-out {
			transition: opacity 1.5s ease-in;
			opacity: 0;
		}
		.wait{opacity: 0;}
		`]
})

export class AppStatusComponent {
	// STATE_SYNC
	// STATE_CONTENT_LOADING
	// STATE_LESSON_RECONNECT
	public uploadingList:any[] = [];
	public uploadingState:string = "none";
	public effect:string = "wait";
	static instance:AppStatusComponent;

	protected uploadingFadeOutTimer:number = -1;

	constructor(public dataService:DataService) {
		AppStatusComponent.instance = this;
	}

	public getRandomString():string {
        return + new Date() + '-' + Math.round(Math.random() * 1000)
    }

	public addLoading():string {
		var key:string = this.getRandomString();
		this.uploadingList.push(key);
		this.uploadingState = "uploading";
		this.effect = "fade-in";
		return key;
	}

	public removeLoading(key:string):void {
		var haveLoding:boolean = this.uploadingList.length>0;
		this.uploadingList = this.uploadingList.filter(e =>e!=key);
		if(this.uploadingList.length == 0) {
			this.uploadingState = "upload_success";
			this.effect = "fade-out";
			if(this.uploadingFadeOutTimer!=-1)
				clearTimeout(this.uploadingFadeOutTimer);
			this.uploadingFadeOutTimer = window.setTimeout(() => {
				this.uploadingFadeOutTimer = -1;
				this.effect = "wait";
			}, 1500);
		}
			
	}

	protected _callAPI(api:string, params:any[]):Promise<any> {
		var recKey:string = this.addLoading();

		return new Promise<any>((resolve, reject) => {
			this.dataService.post2({data:{api:api, json:params}, loading: false}).then((res:any)=>{
				this.removeLoading(recKey);
				resolve(res);
			}).catch(reason=>{
				this.removeLoading(recKey);
				reject(reason);
			});
		});
	}

	public static callAPI(api:string, params:any):Promise<any> {
		return AppStatusComponent.instance._callAPI(api, params);
	}
}