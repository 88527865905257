import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ThemeService } from "src/app/service/theme.service";
import { BubbleBox2Component } from "src/app/sharedModule/bubbleBox2Module/bubbleBox2.component";
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: "questionBankMoveImagePopUp",
	templateUrl: './QuestionBankMoveImagePopUp.component.html',
	styleUrls: ['./QuestionBankMoveImagePopUp.component.scss']
})

export class QuestionBankMoveImagePopUpComponent {
	@ViewChild('bubbleBox2', {static:false}) public bubbleBox2:BubbleBox2Component;

	@Output() public confirm: EventEmitter<any> = new EventEmitter();
	@Output() public cancel: EventEmitter<any[]> = new EventEmitter();
	
	public data = null;
	public emitter: EventEmitter<any> = new EventEmitter();
	public originalTarget = null
	public currentTarget = null
	public originalEventKey = ""
	public eventKey = ""
	public fileObj = null
	public optionAlphabet = []

	public faCheckCircle = faCheckCircle;
    public faCheckCircleLight = faCheckCircleLight;
	public faChevronDown = faChevronDown;
	public faChevronUp = faChevronUp;
	public isQuestionBank = false;
	
	constructor(public translate: TranslateService, public themeService: ThemeService, private eleRef:ElementRef) {
		themeService.applyStyleObj({
			"default": {
				"wpopup-bgcolor": "#FFFFFF",
			},
			"dark": {
				"wpopup-bgcolor": "#2D2c43",
			}
		}, eleRef.nativeElement);
		this.optionAlphabet = []
		for (var i = 0; i < 26; i++) {
			this.optionAlphabet.push(String.fromCharCode(0x41+i));
		}
	}

	ngOnInit(): void {
		this.emitter.emit({ type: "onInit" });
	}

	public open(data, targetEle: HTMLElement, target = null, fileObj = null, key: string = "", isQuestionBank: boolean = false) {
		this.emitter.emit({ type: "open" });
		this.data = data.map(q => ({...q, 
			question: this.formatQuestion(q.question), 
			options: this.getTextMcOptions(q)
		}))
		this.originalTarget = target
		this.currentTarget = target
		this.fileObj = fileObj
		this.originalEventKey = key
		this.eventKey = key
		this.isQuestionBank = isQuestionBank
		
		return this.bubbleBox2.open(targetEle).catch((e)=>{});
	}

	public onCancel() {
		this.bubbleBox2.close()
		this.cancel.emit()
	}

	public onConfirm() {
		const isSameTarget = this.isQuestionBank ? this.currentTarget.qid == this.originalTarget.qid : this.currentTarget.douid == this.originalTarget.douid
		if (isSameTarget && this.originalEventKey == this.eventKey) {
			return this.onCancel()
		}
		this.confirm.emit({
			original: this.originalTarget, 
			target: this.currentTarget, 
			originalKey: this.originalEventKey,
			targetKey: this.eventKey, 
			asset: this.fileObj
		})

		this.bubbleBox2.close()
	}

	// find if the current question is matches the selected question
	private isTargetChecked(target, option: string) {
		const isSameQuestion = this.isQuestionBank ? this.currentTarget.qid == target.qid  : this.currentTarget.douid == target.douid
		const isTextMc = this.currentTarget.type == 'QBTextMC'
		
		if (isTextMc) {
			return isSameQuestion && option == this.eventKey
		} else {
			return isSameQuestion 
		}
	}

	// remove the html tag
	private formatQuestion(question: string) {
		return question.replace(/<[^>]*>/g, '');
	}

	// change the target question for moving the image
	private onChangeTarget(target, key = "") {
		this.currentTarget = target
		this.eventKey = key
	}

	// generate the text mc options for display usage
	public getTextMcOptions(q) {
		let array = []
		for (let i = 1; i < q.totalOptions; i ++) {
			array.push({
				text: this.formatQuestion(q["opt" + i]), 
				asset: q["opt" + i + "_asset"],
				key: "opt" + i + "_asset"
			})
		}
		return array
	}

	// for the toggle icon
	private hideTextMcOption(q) {
		if (q.type == "QBTextMC") {
			const found = this.data.find(item => {
				return this.isQuestionBank ? item.qid == q.qid :item.douid == q.douid
			})
			if (found) {
				found.optionShown = found.hasOwnProperty('optionShown') ? !found.optionShown : false
			}
		}
	}
	
}