import { NgModule } from '@angular/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OkaPulldownModule } from 'src/app/sharedModule/okaPulldownModule/okaPulldown.module';
import { WhitePopupModule } from 'src/app/sharedModule/whitePopupModule/whitePopup.module';
import { multiSelectPullDownModule } from 'src/app/sharedModule/multiSelectPullDownModule/multiSelectPullDown.module';
import { BubbleBox2Module } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.module';
import { PersonalDataChartPage } from './personal-data-chart.page';
import { TranslateModuleLoader } from 'src/app/common/TranslateModuleLoader';
import { CommonModule } from '@angular/common';
import { PersonalDistributionChart } from './personal-distribution.chart';
import { PersonalQuestionChart } from './personal-question.chart';
import { FormsModule } from '@angular/forms';
import { PersonalChapterChart } from './personal-chapter.chart';
import { PersnoalStickersIndividualChart } from './personal-stickers-individual.chart';
import { PersonalBoxPlotChart } from './personal-box-plot.chart';
import { PersonalObjectiveRadarChart } from './personal-objective-radar.chart';

@NgModule({
	declarations: [
		PersonalDataChartPage, PersonalDistributionChart, 
		PersonalQuestionChart, PersonalChapterChart, PersnoalStickersIndividualChart,
		PersonalDistributionChart, PersonalBoxPlotChart, PersonalObjectiveRadarChart
	],
	imports: [
		DeviceDetectorModule, FontAwesomeModule, OkaPulldownModule, WhitePopupModule,
		multiSelectPullDownModule, BubbleBox2Module, CommonModule, FormsModule,
		TranslateModuleLoader.forRoot(),
	],
	exports: [ PersonalDataChartPage ],
	providers: [],
	bootstrap: []
})
export class PersonalDataChartModule { }
