import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ROComponent, ROPageComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";

// =======================================
// icon
// =======================================
import { faCamera, faImage, faCrop, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { CameraCaptureComponent } from "../CameraCaptureModule/CameraCapture.component";
import { SafeStyle } from "@angular/platform-browser";
import { XMLJSNode } from "./xml/XMLParser";

@Component({
	template:`
	<div class="innerContent" [style.backgroundImage]="trustURLStyle"></div>
	<div class="btns">
		<fa-icon (click)="takePhoto()" [icon]="faCamera"></fa-icon>
		<fa-icon (click)="selectImage()" [icon]="faImage"></fa-icon>
		<!--<fa-icon (click)="cropScreen()" [icon]="faCrop"></fa-icon>-->
	</div>
	<CameraCapture #cameraCapture></CameraCapture>
	`,
	styles:[
		`
		:host{
			background-color: #88CFEA;
			border-radius: 14px;
			position: relative;
			padding:7px;
		}
		.innerContent{
			width:100%;
			height:100%;
			background-color: #000;
			border-radius: 7px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}
		.btns{
			position:absolute;
			top:18px;
			right:-10px;
			display:flex;
			flex-flow:column;
		}
		.btns>fa-icon{
			position: relative;
			display:flex;
			color:#fff;
			font-size: 20px;
			width:42px;
			height:42px;
			margin-bottom:8px;
//			outline: solid 1px #fff !important;
//			outline-offset: -1px;
			border-radius: 15px;
			background: linear-gradient(to top left, #095BAD 0%, #2080E0 50%, #53B4EB 90%);
			box-shadow: #0B4987 0px -2px 0px 0px inset;
			
		}
		.btns>fa-icon:after{
			position: absolute;
			top:0;
			left:0;
			content: '';
			width:calc(100% - 2px);
			height:calc(100% - 2px);
			border-radius: 13px;
			border: solid 1px #fff;
		}

		:host ::ng-deep fa-icon svg{
			margin: auto;
			filter: drop-shadow(3px 3px 5px rgba(19,88,157,10));
		}
		`
	]
})

export class ROCamera extends ROComponent
{
	@ViewChild('cameraCapture', {static:true}) public cameraCapture:CameraCaptureComponent;
	
	public faCamera:IconDefinition = faCamera;
	public faImage:IconDefinition = faImage;
	public faCrop:IconDefinition = faCrop;
	public ref:any;
	protected file:File; // for upload
	public trustURLStyle:SafeStyle;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	public getTagNames():string []
	{
		return ["Camera"];
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"ver": "1.1.1",
				"cameraMode": "image",
				"fileUrl": "",
				"frameStyle": "blue",
				"hasScoring": false,
				"douid": this.createDouid(),
				"coordinateExpression": "UA UK KR X 0 Y 0 D T 0 L 0 W 383 H 268",
				"q": "{\"x\":-60,\"color\":43163,\"show\":false,\"level\":1,\"y\":0,\"type\":\"auto\",\"ballSize\":36,\"freezed\":1}",
				"s": "{\"y\":0,\"optional\":false,\"x\":383,\"offset\":{\"y\":0,\"x\":0},\"enable\":false,\"reference\":\"rt\",\"freezed\":1}",
				"isQuestionComponent": true,
				"locked": false,
				"questionIndex": 0
				//"qInfo": "{\"index\":0,\"rootIndex\":0,\"pid\":5,\"level\":1,\"name\":\"a\",\"root\":0,\"order\":0,\"id\":3}"
			},
			tag:"Camera"
		});
		this.node.createElement();
	}

	public takePhoto():void {
		this.getImageResult(this.cameraCapture.lanuch());
	}

	public selectImage():void {
		this.getImageResult(this.context.service.fileIO.getLocalFile({fileType:'image'}));
	}

	public cropScreen():void {

	}

	protected getImageResult(p:Promise<any>):void {
		p.then((success)=> {
			this.file = success.file;
			this.ref = {
				key:this.douid,
				reference:this.context.createReference(),
				index:0
			};
			this.answerChanged = true;
			this.trustURLStyle = this.context.service.fileIO.trustURLStyle(URL.createObjectURL(this.file));

		}, (reason) => {
		});
	} 
	
	// ======================================================
	// data function
	// ======================================================
	public isQuestionComponent():boolean {
		return true;
	}

	public verify(_showScoring:boolean):any {
		// reset verify
		// reset score display
		this.sObj.hideScore();

		if(this.ref) {
			if(!this.resultObject)
				this.resultObject = {correct:-1};
		} else
			this.resultObject = null;

		// 顯示分數 / 已提交
		this.sObj.showScoring(_showScoring, "view", this.resultObject);
		return this.resultObject;
	}

	public get data():string {
		return this.ref ? this.objectToString(this.ref) : null;
	}

	public set data(input:string) {
		// clear verify and answer
		this.reset();
		this.answerChanged = true;

		if(input) {
			this.answerChanged = false; // 載入的資料不算有用戶改答案
			let jsonObj = this.stringToObject(input);
			if(jsonObj.hasOwnProperty("key")) {
				this.ref = jsonObj;
				this.loadFile(this.ref);
			}
		}
	}

	loadFile(obj: any):void
	{
		if(this.context.config.viewMode == "scoring")
		{
			this.context.loadStudentFile(
				<ROPageComponent> this.page,
				this, 
				this.context.config.student.uid,
				obj, 0
			).then((url:string)=>{
				this.trustURLStyle = this.context.service.fileIO.trustURLStyle(url);
			});
		} else if(this.context.config.viewMode == "view") {
			this.context.loadFile(<ROPageComponent> this.page, this, obj).then((url:string)=>{
				this.trustURLStyle = this.context.service.fileIO.trustURLStyle(url);
			});
		}
	}

	private reset():void {
		this.answerChanged = false;
		this.ref = null;
		this.file = null;
		this.trustURLStyle = null;
		this.sObj.hideScore();
	}

	public getAnswerAssets():any [] {
		if(this.answerChanged) return [{
			key:this.ref.key,
			reference:this.ref.reference,
			index:0,
			file:this.file
		}];
		return null;
	}
}