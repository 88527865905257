import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input, OnDestroy, ElementRef } from '@angular/core';
import { Modal2Component } from '../modal2Module/modal2.component';
import { Observable, Subject, Subscriber } from 'rxjs';


@Component({
	selector: 'number-pad-modal',
	template: `
		<modal2 
			#modal
			[title]="title"
			minWidth="300px"
		
			[lang]="lang"
			(confirmClick)="onConfirm()"
			(cancelClick)="onCancel()"
			>
			<div class="center-contaienr">
				<div class="numberpad-container">
					<input class="password" #input type="password" [(ngModel)]="code"autocomplete="off" (keyup.enter)="onConfirm()"/>
					<div *ngFor="let num of numpad" class="numpad" (click)="numPadClick($event,num)">{{num}}</div>
					<div class="numpad numpad-0" (click)="numPadClick($event,0)">0</div>
	        		<div class="numpad numpad-delete" (click)="numPadClick($event,-1)"></div>
				</div>
			</div>
		</modal2> 
	`,
	styles:[
		`
		.center-contaienr{
			margin-left: auto;
			margin-right: auto;
			width: 220px;
		}
		.numberpad-container {
			width: 220px;
			padding-left: 10px;
			display: inline-flex;
			flex-direction: row;
			flex-wrap: wrap;
			overflow: hidden;
		}
		.password{
			text-align: center;
			background-color: #fff;
			border: 0;
			outline: 0;
			width: 200px;
			height: 60px;
			min-height: 60px;
			border-radius: 10px;
			margin-bottom: 10px;
			font-family: 'pass';
			letter-spacing: 3px;
			font-size: 22px;
			color: #5C3510;
		}

		.numpad {
			background-color: #fff;
			border-radius: 10px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 60px;
			height: 60px;
			min-height: 60px;
			margin-right: 10px;
			margin-bottom: 10px;
			font-size: 30px;
			@extend %fast;
			color: #5C3510;
		} 

		.numpad-0{
			margin-bottom: 0;
			width: 130px;
		}
	
		.numpad-delete {
			margin-bottom: 0;
			background-image: url('./assets/img/icon_backspace_brown.svg');
			background-position: center;
			background-repeat: no-repeat;
		}
		`
	]
})
export class NumberPadModal implements AfterViewInit {
	
	public numpad: any[] = [7, 8, 9, 4, 5, 6, 1, 2, 3];
	@Input() lang:string = "tc";
	@Input() title:string = "";
	@Output() emitter:EventEmitter<any> = new EventEmitter();
	@ViewChild('modal', {static: true}) public modal:Modal2Component;
	@ViewChild('input', {static: true}) public input:ElementRef;
	public code:string = "";
	public subscriber:Subscriber<any>
	public reset():void
	{
		this.code = "";
	}
	ngAfterViewInit(): void {
		
	}
	public open(title:string, code:string):Observable<any>
	{
		this.input.nativeElement.focus();
		this.title = title;
		this.code = code;
		return new Observable((subscriber:Subscriber<any>)=>{
			this.subscriber = subscriber;
			this.emitter.emit("open");
			this.modal.open();
			
			return ()=>{
				this.emitter.emit("close");
				this.modal.close();
			}
		})
	}

	onConfirm():void
	{
		this.subscriber.next({type:"confirm", code:this.code});
	}

	onCancel():void
	{
		this.subscriber.next({type:"cancel"});
	}

	numPadClick($event, key) {
		$event.stopPropagation();
		if (key == -1 ) {
			if(this.code.length > 0) this.code = this.code.substring(0, this.code.length - 1);
		} else {
			this.code = this.code + ('' + key);
		}
	}
}