import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintComponent } from './Print.component';
import { PrintService } from './PrintService';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PrintComponent
  ],
  exports: [
    PrintComponent
  ],
  providers: [
	
  ],
  entryComponents: [PrintComponent]
})
export class PrintModule {
}
