import { Component, Input, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'dateTimePickerSelectMonth',
    template: `
<div class="rowYear">
    <img
        class="switchYearBtn"
        src="./assets/img/chevron-left.svg"
        (click)="calendarYear=calendarYear-1"
    >
    <div class="yearBtn">{{calendarYear}}</div>
    <img
        class="switchYearBtn"
        src="./assets/img/chevron-right.svg"
        (click)="calendarYear=calendarYear+1"
    >
</div>

<div class="rowMonth">
    <div
        class="month"
        *ngFor="let month of months; let i=index;"
        [class.selected]="date.getFullYear()==calendarYear && date.getMonth()==i"
        [style.backgroundColor]="(date.getFullYear()==calendarYear && date.getMonth()==i ? color1 : 'transparent')"
        (click)="onChange.emit({year:calendarYear, month:i})"
    >{{month}}</div>
</div>
    `,
    styleUrls: ['./dateTimePickerSelectMonth.component.scss']
})

export class DateTimePickerSelectMonthComponent implements OnChanges {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

    @Input() public lang:string = 'tc';
    @Input() public color1:string;
    @Input() public color2:string;
    @Input() public date:Date = new Date();

    public months:any[];
    public calendarYear:number;

    @Output() public onChange:EventEmitter<any> = new EventEmitter();

    constructor() {
        /*setTimeout(()=>{
            this.updateDates();
        }, 1000);*/
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.date && changes.date.currentValue) {
            this.calendarYear = changes.date.currentValue.getFullYear();
        }

        if (changes && changes.lang && changes.lang.currentValue) {
            if (changes.lang.currentValue=='tc' || changes.lang.currentValue=='sc') {
                this.months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
            }  else {
                this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            }
        }
    }
    //---------------------------------------------------------------------------------------------
    /*public getDateTextColor(date:Date):string {
        if (date.getDay()==0) return '#FEB9BD';
        if (date.getMonth()!=this.calendarMonth) return '#999';
        return '#000';
    }
    //---------------------------------------------------------------------------------------------
    public isSameDate(date1:Date, date2:Date):boolean {
        return (date1.getFullYear()==date2.getFullYear() && date1.getMonth()==date2.getMonth() && date1.getDate()==date2.getDate());
    }
    //---------------------------------------------------------------------------------------------
    public switchLastMonth():void {
        this.calendarMonth--;
        if (this.calendarMonth<0) {
            this.calendarMonth = 11;
            this.calendarYear--;
        }
        this.updateDates();
    }
    //---------------------------------------------------------------------------------------------
    public switchNextMonth():void {
        this.calendarMonth++;
        if (this.calendarMonth>11) {
            this.calendarMonth = 0;
            this.calendarYear++;
        }
        this.updateDates();
    }*/
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
}
