import { faClone, faCopy, faFile, faBars, faTrash, faGripDots, faImage, faFilm, faListMusic , faPencil, faCheck, faAdd, faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
export class SelectMarkerIcon{
	static COMPLETE_ICON:any = faCheck;
	static DELETE_ICON:any = faTrash;
	static DUPLICATE_ICON:any = faClone;
	static PENCIL_ICON:any = faPencil;
	static IMAGE_ICON:any = faImage;
	static SOUND_ICON:any = faListMusic;
	static MOVIE_ICON:any = faFilm;
	static ADD_ICON:any = faAdd;
	static BACK_ICON:any = faArrowLeft;
}