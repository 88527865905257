import { ByteArray } from 'openfl';
import { AMF3, Stream } from "./amf3-ts";

class ObjectConvertor
{
	toByteArray(array:number[]):ByteArray
	{
		var output = new ByteArray();
		output.objectEncoding = 3;
		array.forEach((byte:number)=>{
			output.writeByte(byte);
		})
		return output;
	}
	streamToByteArray(src:Stream):ByteArray
	{
		var output = new ByteArray();
		src.position = 0;
		while(src.bytesAvailable)
		{
			var byte = src.readByte();
			output.writeByte(byte);
		}
		return output;
	}
	
	byteArrayToStream(src:ByteArray):Stream
	{
		var stream = new Stream();
		src.position = 0;
		while(src.bytesAvailable)
		{
			var byte = src.readByte();
			stream.writeByte(byte);
		}
		return stream;
	}
	toByteArrayObject(obj:any):any
	{
		if(obj === null) return null;
		
		var t = typeof obj;
		if(t == "number")
		{
			return obj;
		} else if(t == "string")
		{
			return obj;
		} else if( Array.isArray( obj ))
		{
			return obj.map((element)=>{
				return this.toByteArrayObject(element);
			});
		} else if(obj instanceof ByteArray)
		{
			return obj
		
		} else if(obj instanceof Stream)
		{
			return this.streamToByteArray(obj);
			
		} else if(t == "object")
		{
			return this.cloneObjectForByteArray(obj);
		}
		return obj;
	}
	toStreamObject(obj:any)
	{
		if(obj === null) return null;
		
		var t = typeof obj;
		if(t == "number")
		{
			return obj;
		} else if(t == "string")
		{
			return obj;
		} else if( Array.isArray( obj ))
		{
			return obj.map((element)=>{
				return this.toStreamObject(element);
			});
		} else if(obj instanceof ByteArray)
		{
			return this.byteArrayToStream(obj);	
		
		} else if(obj instanceof Stream)
		{
			return obj;
			// console.log("this is stream");
			// return this.cloneStream(obj);
			
		} else if(t == "object")
		{
			return this.cloneObjectForStream(obj);
		}
		return obj;
		// 
	}
	
	cloneObjectForStream(obj:any)
	{
		var copy = {};
		for(var key in obj)
		{
			copy[key] = this.toStreamObject(obj[key]);
		}
		return copy;
	}
	cloneObjectForByteArray(obj:any)
	{
		var copy = {};
		for(var key in obj)
		{
			copy[key] = this.toByteArrayObject(obj[key]);
		}
		return copy;
	}
}
export class AMF3Encoder{
	convertor:ObjectConvertor;
	constructor()
	{
		this.convertor = new ObjectConvertor();
	}
	decodeByteArray(bytes:ByteArray):any
	{
		bytes.position = 0;
		var array = [];
		while(bytes.bytesAvailable)	 array.push(bytes.readByte());
		var o = AMF3.parse(array);
		return this.convertor.toByteArrayObject(o);
	}

	encode(obj:any)
	{
		var clone = this.convertor.toStreamObject(obj);
		return AMF3.stringify(clone);
	}
	
	encodeToByteArray(obj:any):ByteArray
	{
		var array = this.encode(obj);
		
		return this.convertor.toByteArray(array);
	}
}
