export class CubicSpline {
	ks: number[]
	constructor(private ys: number[]) {
		this.ks = this.getNaturalKs(new Float64Array(this.ys.length));
	}
	
	getNaturalKs(ks) {
		const n = this.ys.length - 1;
		const A:number[][] = zerosMat(n + 1, n + 2);

		for (
			let i = 1;
			i < n;
			i++ // rows
		) {
			A[i][i - 1] = 1;
			A[i][i] = 4;
			A[i][i + 1] = 1;
			A[i][n + 1] =
				3 *
				(
					(this.ys[i] - this.ys[i - 1]) +
					(this.ys[i + 1] - this.ys[i])
				);
		}

		A[0][0] = 2;
		A[0][1] = 1;
		A[0][n + 1] = (3 * (this.ys[1] - this.ys[0]));

		A[n][n - 1] = 1;
		A[n][n] = 2;
		A[n][n + 1] = (3 * (this.ys[n] - this.ys[n - 1]));

		return solve(A, ks);
	}

	/**
	 * inspired by https://stackoverflow.com/a/40850313/4417327
	 */
	getIndexBefore(target:number):number {
		let low:number = 0;
		let high:number = this.ys.length;
		let mid:number = 0;
		while (low < high) {
			mid = Math.floor((low + high) / 2);
			if (mid < target && mid !== low) {
				low = mid;
			} else if (mid >= target && mid !== high) {
				high = mid;
			} else {
				high = low;
			}
		}
		return low + 1;
	}

	at(x: number): number {
		let i = this.getIndexBefore(x);
		// var ks_n1:number = this.ks[i - 1];
		const t:number = (x - i + 1);
		const a:number = this.ks[i - 1] - (this.ys[i] - this.ys[i - 1]);
		const b:number = -this.ks[i] + (this.ys[i] - this.ys[i - 1]);
		const q:number = (1 - t) * this.ys[i - 1] + t * this.ys[i] + t * (1 - t) * (a * (1 - t) + b * t);
		return q;
	}
};

function solve(A, ks) {
	const m:number = A.length;
	let h:number = 0;
	let k:number = 0;
	while (h < m && k <= m) {
		let i_max:number = 0;
		let max:number = -Infinity;
		for (let i:number = h; i < m; i++) {
			const v = Math.abs(A[i][k]);
			if (v > max) {
				i_max = i;
				max = v;
			}
		}

		if (A[i_max][k] === 0) {
			k++;
		} else {
			swapRows(A, h, i_max);
			for (let i:number = h + 1; i < m; i++) {
				const f:number = A[i][k] / A[h][k];
				A[i][k] = 0;
				for (let j:number = k + 1; j <= m; j++) A[i][j] -= A[h][j] * f;
			}
			h++;
			k++;
		}
	}

	for (
		let i = m - 1;
		i >= 0;
		i-- // rows = columns
	) {
		var v = 0;
		if (A[i][i]) {
			v = A[i][m] / A[i][i];
		}
		ks[i] = v;
		for (
			let j = i - 1;
			j >= 0;
			j-- // rows
		) {
			A[j][m] -= A[j][i] * v;
			A[j][i] = 0;
		}
	}
	return ks;
}

function zerosMat(r, c):number[][]{
	const A:any [] = [];
	for (let i = 0; i < r; i++) A.push(new Float64Array(c));
	return A;
}

function swapRows(m:any[], k:number, l:number) {
	let p:any = m[k];
	m[k] = m[l];
	m[l] = p;
}
