import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionRemarkBallComponent} from './additionRemarkBall.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';


@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
		BubbleBox2Module
    ],
    declarations: [
        AdditionRemarkBallComponent
    ],
    exports: [
        AdditionRemarkBallComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class AdditionRemarkBallModule {
}