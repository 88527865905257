export class GUID 
{
	public static counter:number = 0;
	
	public static create(id:String):string
	{  
		let id1:number = new Date().getTime(); 
		let id2:number = Math.random()*Number.MAX_VALUE;
		let rawID:string = id1.toString()+id+id2.toString()+GUID.counter.toString();
		rawID = GUID.binb2hex(GUID.core_sha1(GUID.str2binb(rawID), rawID.length*8));
		GUID.counter++;
		return rawID.substring(0, 8) + "-" + rawID.substring(8, 12) + "-" + rawID.substring(12, 16) 
			+ "-" + rawID.substring(16, 20) + "-" + rawID.substring(20, 32);  
	}
	
	protected static core_sha1(x:number[], len:number):number[]
	{  
		x[len >> 5] |= 0x80 << (24-len%32);  
		x[((len+64 >> 9) << 4)+15] = len;  
		let w:any = [];
		for (let j:number = 0; j<80; j++)
			w[j] = 0;
		
		let a:number = 1732584193;  
		let b:number = -271733879, c:number = -1732584194;  
		let d:number = 271733878, e:number = -1009589776;  
		for (let i:number = 0; i<x.length; i += 16)
		{  
			let olda:number = a, oldb:number = b;  
			let oldc:number = c, oldd:number = d, olde:number = e;  
			for (let j:number = 0; j<80; j++)
			{  
				if (j<16) w[j] = x[i+j];  
				else w[j] = GUID.rol(w[j-3] ^ w[j-8] ^ w[j-14] ^ w[j-16], 1);  
				let t:number = GUID.safe_add(GUID.safe_add(GUID.rol(a, 5), GUID.sha1_ft(j, b, c, d)), GUID.safe_add(GUID.safe_add(e, w[j]), GUID.sha1_kt(j)));  
				e = d; d = c;  
				c = GUID.rol(b, 30);  
				b = a; a = t;  
			}  
			a = GUID.safe_add(a, olda);  
			b = GUID.safe_add(b, oldb);  
			c = GUID.safe_add(c, oldc);  
			d = GUID.safe_add(d, oldd);  
			e = GUID.safe_add(e, olde);  
		}  
		return [a, b, c, d, e];  
	}

	protected static sha1_ft(t:number, b:number, c:number, d:number):number
	{  
		if (t<20) return (b & c) | ((~b) & d);  
		if (t<40) return b ^ c ^ d;  
		if (t<60) return (b & c) | (b & d) | (c & d);  
		return b ^ c ^ d;  
	}

	protected static sha1_kt(t:number):number
	{
		return (t<20) ? 1518500249 : (t<40) ? 1859775393 : (t<60) ? -1894007588 : -899497514;  
	}

	protected static safe_add(x:number, y:number):number
	{
		let lsw:number = (x & 0xFFFF)+(y & 0xFFFF);  
		let msw:number = (x >> 16)+(y >> 16)+(lsw >> 16);  
		return (msw << 16) | (lsw & 0xFFFF);  
	}

	protected static rol(num:number, cnt:number):number
	{
		return (num << cnt) | (num >>> (32-cnt));  
	}

	protected static str2binb(str:string):number[]
	{  
		let bin:number[] = [];
		for (let i:number = 0; i<str.length*8; i += 8)
		{
			bin[i >> 5] |= str.charCodeAt(i / 8) << (24 - i % 32);
		}
		return bin;
	}  

	protected static binb2hex(binarray:number[]):string
	{  
		let str:string = "";  
		let tab:string = "0123456789ABCDEF";  
		for (let i:number = 0; i<binarray.length*4; i++)
		{
			str += tab.charAt((binarray[i >> 2] >> ((3-i%4)*8+4)) & 0xF) + tab.charAt((binarray[i >> 2] >> ((3-i%4)*8)) & 0xF);  
		}
		return str;  
	}
}