import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal2Component } from './modal2.component';
import { Modal2Service } from './Modal2Service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  imports: [
    CommonModule, FontAwesomeModule
  ],
  declarations: [
    Modal2Component
  ],
  exports: [
    Modal2Component
  ],
  providers: [
	Modal2Service
  ],
  entryComponents: [Modal2Component]
})
export class Modal2Module {
}
