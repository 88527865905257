import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainMenuBtnComponent } from './mainMenuBtn.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        MainMenuBtnComponent
    ],
    exports: [
        MainMenuBtnComponent
    ],
    providers: [],
    bootstrap: []
})
export class MainMenuBtnModule { }
