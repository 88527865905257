import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SoundRecorderPopupComponent } from './soundRecorderPopup.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
		CommonModule, TranslateModule
    ],
    declarations: [
		SoundRecorderPopupComponent
    ],
    exports: [
		SoundRecorderPopupComponent
    ],
    providers: [],
    bootstrap: []
})
export class SoundRecorderPopupModule { }
