import { ByteArray } from "openfl";

export class MarkingSticker{
	public constructor(public id:number, public x:number, public y:number)
	{
		
	}
	static fromBytes(id:number, ba:ByteArray):any
	{
		var x:number = ba.readInt();
		var y:number = ba.readInt();
		return {
			id:id & 0x7f,
			x:x,y:y
		}
		return new MarkingSticker(id & 0x7f, x, y);
	}
}
