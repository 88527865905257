/**
 * EPEN to do 
 * 1. read data (ok) and write data (not ok) 
 * 2. highlight support (not ok)
 * 3. playback support ( 未 做 )
 * 4. start and end cap circle // OK
 * 5. smoothing Line
 * 
 * 
 */
import { Observable, Subject, Subscriber, Subscription, fromEvent } from "rxjs";
import { DragManager } from "../DragManager";
import { ROContext } from "../ROContext";
import { DrawingBase } from "./DrawingBase";
import { DOMHelper } from "src/app/common/DOMHelper";
import { SmoothDrawingType } from "./SmoothDrawingType";
import { SmoothDrawing } from "./SmoothDrawing";
import { takeWhile } from "rxjs/operators";

export class NoteLayerController
{
	actionSubject:Subject<any>;
	dd:DragManager;
	context:ROContext;
	// public sm:DrawingBase;
	dom:HTMLElement;
	subscription:Subscription;
	constructor()
	{
		this.dd = new DragManager();
	}
	public initTouchContainer(dom:HTMLElement)
	{
		if(this.subscription) this.subscription.unsubscribe();
		
		this.dom = dom;
		this.dom.classList.add("note-layer-enabled");
		this.subscription = new Subscription(()=>{
			dom.classList.remove("note-layer-enabled");
		});
		
		this.subscription.add(
			fromEvent(this.dom, "touchstart", {capture: true}).subscribe(this.onTouchStart.bind(this))
		);
		this.subscription.add(
			fromEvent(this.dom, "pointerdown", {capture: true}).subscribe(this.onPointerDown.bind(this))
		)
	}
	
	public dispose():void
	{
		if(!this.subscription) return;
		this.subscription.unsubscribe();
		this.subscription = null;
		this.dom = null;
	}

	onPointerDown(event:any):void
	{
		var enabled = this.context.epenState.enabled;
		if(!enabled) return;
		event.preventDefault();
		event.stopImmediatePropagation();
		this.processEpen(event, "pointer");
	}

	onTouchStart(event:TouchEvent):void 
	{
		var enabled = this.context.epenState.enabled;
		if(!enabled) return;
		event.preventDefault();
		event.stopImmediatePropagation();
		this.processEpen(event, "touch");
	}

	protected processEpen(event:any, actionType:string):void {
		/*
		var accept = ()=>{
			event.preventDefault();
			event.stopImmediatePropagation();
			return this.processEpenStart(event, actionType);
		}
		*/
		var stop:boolean = (event instanceof PointerEvent) && event.button !== 0; // not (Left Mouse, Touch Contact, Pen contact)
		if(stop) return;

		this.context.subject.next({
			type:"notify", 
			notify:{
				type:"ePenNoteRequest",
				// accept:accept,
				createObservable:(touch:HTMLElement)=>{
					return new Observable((subscriber:Subscriber<any>)=>{
						event.preventDefault();
						event.stopImmediatePropagation();
						this.processEpenStart(touch, event, actionType, subscriber)
					})
				}
			}
		});
		
	}

	protected processEpenStart(touch:HTMLElement, event:any, actionType:string, subscriber:Subscriber<any>):Subscription
	{
		
		this.context.subject.next({
			type:"notify", 
			notify:{
				type:"ePenNoteStart"
			}
		});


		var subscription:Subscription = new Subscription(()=>{
			this.context.subject.next({
				type:"notify", 
				notify:{
					type:"ePenNoteEnd"
				}
			});
			subscriber.complete();
		})

		var subject:Subject<any>;
		// 寫 marking epen
		if(actionType == "touch")
			subject = this.dd.touchStart(event, touch);
		else if(actionType == "pointer")
			subject = this.dd.pointerStart(event, touch);
		 

		/*
		// this.change.emit({type:"epenStart", from:"processEpen"})
		subject.pipe(takeWhile((o) => {
			return !(o.type == "cancel" || o.type == "timeout");
		})).subscribe((o:any)=>{

		})
		*/
		
		subscription.add(subject.subscribe((o:any)=>{
			if(o.type == "cancel" || o.type == "timeout") {
				subscriber.next(o);
				subscriber.complete();
				subscription.unsubscribe();
				// this.sm = null;
				this.actionSubject = null;

			} else {
				subscriber.next(o);
				if(o.type == "end") {
					subscriber.complete();
				}
				/*
				// console.log(o.type, o.point);
				//var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
				var pt = o.point;
				// pt.x = Math.round(pt.x);
				// pt.y = Math.round(pt.y);
				// this.context.epenState.color
				if(o.type == "start") {
					var strokeColor:number = this.context.epenState.color;
					
					this.sm = new SmoothDrawing().setup(
						SmoothDrawingType.DT_EPEN,
						this.context.epenState.strokeWidth,
						strokeColor,1
					);
					
				}
				
				// this.sm.addPoint(pt, true);
				
				if(o.type == "end") {
					subscription.unsubscribe();
					// this.sm.simplify();
					// this.addEpen(this.sm, true);

					// this.sm = null;
					this.actionSubject = null;
				}
				
				*/
			}
		}));

		return subscription;
	}
}