import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { QBComponentBase } from "./QBComponentBase";
import { FileIOService } from "src/app/service/FileIO.service";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";


@Component({
	template:`
		<div class="q scroll-container">
			<div class="qContainer ">
				
				<div class="qnumCol flex-row-left">
					<div class="flex-column-center">
						<div class="qnum">
							{{qObject.questionIndex+1}}
						</div>
					
						<!-- speaker tts icon -->
						<!--
						<div  *ngIf="!qObject.isPageComponent" class="speaker" (click)="speechQ(qObject.question)"></div>
						-->
					</div>


					<div class="qcontent">
						
						
						<QText [qObj]="qObject" ></QText>
						<input type="text" class="shortInput" 
							[(ngModel)]="currentState.input" 
							[disabled]="!canEdit()" 
							autocomplete="off" 
							(ngModelChange)="onModelChanged()"
							(focusout)='onFocusOut()'/>
					
					</div>
				</div>
			</div>

			<div class="score">
						
				<!--
					<Scoring [displayType]="'ai'" 
					(onChange)="updateScore($event)" 
					*ngIf="isSubmitted() && qObject.type!='QBInfoBlock' && isDisplayAIScore()"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
					<Scoring (onChange)="updateScore($event)" *ngIf="isSubmitted(i) && qObject.type!='QBInfoBlock' && isDisplayTeacherScore(i)"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
				-->
			</div>
		</div>

	`,

	styleUrls:[
		"./QBComponentQuestionBase.scss"
	],
	styles:[`
		.shortInput,.longInput  {
			resize: none;
			width: 100%;
			font-size: inherit;
			color:#000;
			background-color: #F5edda !important;
			border-radius: 5px;
			//border: 1px solid var(--common-textcolor);
			margin: 0 0 5px;
			padding: 0 10px;
			line-height: 1.2em;
		}
	`]
})
export class QBShortAnswer extends QBComponentQuestionBase
{
	public qObject:any;
	public currentState:any = {input:""};
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, public fileio:FileIOService)
	{
		super(cdr, elementRef, fileio);
	}

	public canVerify(): boolean {
		return true;
	}

	onModelChanged()
	{
		this.answerChanged = true;
	}
	
	hasAnswer():boolean
	{
		return this.currentState && this.currentState.input;
	}

	public resetData()
	{
		this.currentState = {input:""};
		this.resultObject = null;
	}

	verify(show:boolean)
	{
		var correct = this.qObject.answer.trim() == this.currentState.input.trim();
		var maxScore:number = this.getFullScore();
		if(correct)
		{
			this.resultObject = {
				correct:2,
				correction:false,
				score:maxScore,
				maxScore:maxScore
			}
		} else {
			this.resultObject = {
				correct:0,
				correction:false,
				score:0,
				maxScore:maxScore
			}
		}
		return this.resultObject;
	}
	canEdit()
	{
		return true;
	}

	onFocusOut()
	{
		console.log("focusout");
	}

	public getTagNames():string []
	{
		return ["QBShortAnswer"];
	}

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
	}
	public set data(input:string)
	{
		this.currentState = input === null ? {input:""} : {input:input};
	}

	public get data()
	{
		if(this.hasAnswer)
		{
			var o = this.getResultObjectAndAnswer()
			return o.answerForSubmit;
		} else {
			return null;
		}
	}
	protected getResultObjectAndAnswer():any {
		let qObj:any = this.qObject;
		let qVar:any = this.currentState;
		let totalAns:number = 1;
		let correct:number = 0;
		let wrong:number = 0;
		let answerForSubmit:any;

		totalAns = 1;
		answerForSubmit = qVar.input = qVar.input.trim();
		if(qObj.context.type == "exact") {
			if(qObj.answer=="")
				wrong = 1;
			else if(qVar.input==qObj.answer.trim())
				correct = 1;
			else
				wrong = 1;
		} else if(qObj.context.type == "keyword") {
			var cmp:string[] = qObj.answer.split(",");
			totalAns = cmp.length;
			cmp = cmp.map(s => {
				return s.trim();
			});
			cmp.forEach(s => {
				if(qVar.input.indexOf(s)>=0)
					correct++;
			});
		}


		
//		if(correct==0 && wrong==0)
//			return null; // 無答過
		return {
			answerForSubmit:answerForSubmit,
			correct:correct,
			wrong:wrong,
			totalAns:totalAns,
			result:this.getResultObject(qObj, correct, wrong, totalAns)
		};

	}

	protected getResultObject(qObj:any, correct:number, wrong:number, totalAns:number):any {
		if(correct==-1) // 無指定答案
			return {correction:false, correct:-1, maxScore:qObj.fullScore, teacherScore:null};
		
		if(correct == totalAns && wrong == 0) // 全對
			return {
				correction:false,
				score:parseFloat(qObj.fullScore),
				correct:2,
				maxScore:qObj.fullScore
			};
		
		if(correct==0 || parseInt(qObj.scoringType)==1) // 全錯
			return {
				correction:true, // Incorrect 要改正
				score:0,
				correct:0,
				maxScore:qObj.fullScore
			};
		
		// 有對及錯
		let correctCount:number = ["QBTextMC","QBGraphicMC","QBVoiceMC"].indexOf(qObj.type)>=0 ? Math.max(0,correct-wrong) : correct;
		return {
			correction:true, // Incorrect 要改正
			score:Math.round(10*parseFloat(qObj.fullScore)*correctCount/totalAns)/10,
			correct:1,
			maxScore:qObj.fullScore
		};
	}
	

}