import { Component, OnInit, Input, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { StorageService } from './service/storage.service';
import { DataOldService } from './service/data-old.service';
import { ScriptService } from 'src/app/service/script.service';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
declare var Chart;

@Component({
	selector: 'app-objective',
	template: `
	<div class="container">
		<div class="panel {{data.theme}}-theme">
			<div class="panel-box panel-box-0"></div>
			<div class="panel-text">{{'report-personal.average'|translate}}</div>
			<div class="panel-box panel-box-1"></div>
			<div class="panel-text">{{data.studentName}}</div>
		</div>
		<div class="chart {{data.theme}}-theme">
			<canvas #chartCanvas></canvas>
		</div>
	</div>
  `,
	styleUrls: ['./objective.component.scss']
})

export class ObjectiveComponent implements OnInit, OnDestroy {
	@Input() public data;
	@ViewChild('chartCanvas', {static: false}) chartCanvas;
	public okaPulldownStyle = { width: '210px' };
	public radarDataSets;
	public radarOptions;
	public legends:any = [];
	public chartObj;
	public radarColors = [{ backgroundColor: 'rgba(254,178,195,0.4)', pointBorderColor: '#ffffff', pointBackgroundColor: 'rgba(253,101,133,1)', borderColor: 'rgba(253,101,133,1)'}];
	constructor(public storageService: StorageService, public dataOldService: DataOldService, private script: ScriptService, private coms: CommonService, public datas: DataService, public http: HttpClient, public trans: TranslateService) {

	}

	ngOnInit() {
		const borderColor = this.data.theme == 'dark-theme'?'red':'rgba(253,101,133,1)';
		const t = '';
		this.initRadarOptions();
		this.radarDataSets = [
			{ label: this.trans.instant('report-personal.average'), backgroundColor: 'rgba(159,210,244,0.4)', borderColor: 'rgba(61,163,232,1)', pointBackgroundColor: 'rgba(61,163,232,1)', pointBorderWidth: 6, order: 1 },
			{ label: this.data.studentName, backgroundColor: 'rgba(254,178,195,0.4)', borderColor: 'rgba(253,101,133,1)', pointBackgroundColor: 'rgba(253,101,133,1)', pointBorderWidth: 6, order: 2 }
		];
		this.genRadarData(this.data.pointType);
	}

	ngOnDestroy(): void {
	}

	@HostListener('window:resize') onWinResize(){
		if (this.chartCanvas && this.legends.length > 0){
			const ele = this.chartCanvas.nativeElement;
			this.renderChart(ele);
		}
	}

	initRadarOptions(){
		this.radarOptions = {
			responsive: false,
			maintainAspectRatio: true,
            legend: {
                display: false,
            },
            animation: {
                duration: 500,
				onComplete: event=>{
					const r = this.chartObj.scale;
					this.legends.forEach((l, i)=>{
						const pos = r.getPointPosition(i, r.drawingArea + 5);
					});
				}
            },
            layout: {
                padding: { top: 30, bottom: 60, left: 60, right: 60 }
            },
			line: {
				borderWidth: 15
			},
            scale: {
                gridLines: {
                    color: this.data.theme == 'dark' ? '#53536a' : '#ccc',
                    zeroLineColor: this.data.theme == 'dark' ? '#53536a' : '#ccc',
                },
                angleLines: {
                    color: this.data.theme == 'dark' ? '#53536a' : '#ccc'
                },
                ticks: {
                    beginAtZero: true,
                    fontColor: this.data.theme == 'dark'?'#53536a':'#ccc',
                    backdropColor: 'transparent',
					fontSize: 20,
                    max: 10
                },
                pointLabels: {
                    display: true,
					fontColor: this.data.theme == 'dark'?'#fff':'#333',
					fontSize: 15,
					callback: (label, data) =>{
						const legend = this.legends.find(e=> e.alias == label);
						if (legend.max){
							const title = label + '. ' + legend.title + ':';
							const splitedTitle = title.match(/.{1,15}/g);
							return [ ...splitedTitle, legend.studentWeightedScore ];
						} else {
							return [];
						}
					}
                }
            },
		};
	}

	genRadarData(pointType){
		const data = this.data;
		this.legends = [];
		const peoples = this.data.peoples;
		const selectedPoints = data.points.filter(e=>e.form_id == pointType || pointType == 'all');
		selectedPoints.forEach((point, index)=>{
			const alias = String.fromCharCode(index + 65);
			const legend:any = {...point};
			legend.alias = alias;
			legend.max = 0;
			legend.groupAverageScore = 0;
			legend.studentScore = 0;
			let pplTotalSum = 0;
			let pplTotalCount = 0;
			let t1 = []; let t2 = []; let t3 = [];
			data.components.forEach(comp => {
				if (point.bcid.indexOf(comp.id) > -1){
					const compScore = parseFloat(comp.score);
					t3.push(compScore);
					legend.max += compScore;
				}
			});

			peoples.forEach((ppl, pplIndex) => {
				let pplSum = 0;
				let submitted = false;
				this.data.data.forEach(e=> {
					if (point.bcid.indexOf(e.bcid) > -1){
						const score = e.score?parseFloat(e.score):0;
						if (e.uid == ppl.uid){
							submitted = true;
							pplSum += score;
						}
						if (e.uid == data.studentUid && data.studentUid == ppl.uid){
							legend.studentScore += score;
							t2.push(score);
						}
					}
				});
				if (submitted){
					pplTotalSum += pplSum;
					t1.push(pplSum);
					pplTotalCount++;
				}
			});


			if (pplTotalCount > 0){
				legend.groupAverageScore = Math.round(pplTotalSum * 10 / pplTotalCount) / 10;
			}
			// data.components.forEach(comp =>{
			// 	comp.results = this.data.data.filter(e=> e.bcid == comp.id).map(e=> parseFloat(e.score));
			// 	if (point.bcid.indexOf(comp.id) > -1){
			// 		legend.max += isNaN(comp.score)?0:parseFloat(comp.score);
			// 		if (comp.results.length > 0){
			// 			legend.groupAverageScore += comp.results.reduce((a, b) => a + b) / comp.results.length;
			// 		}
			// 		const compDatas = data.data.filter(e=> e.bcid == comp.id && e.source == 'student');
			// 		compDatas.forEach(cd=>{
			// 			legend.studentScore += parseFloat(cd.score);
			// 		});
			// 	}
			// });
			if (legend.max == 0){
				legend.studentWeightedScore = 0;
				legend.groupWeightedScore = 0;
			} else {
				legend.studentWeightedScore = Math.round(legend.studentScore * 10 * 10 / legend.max) / 10;
				legend.groupWeightedScore = Math.round(legend.groupAverageScore * 10 * 10 / legend.max) / 10;
			}
			// console.log(`${legend.studentWeightedScore}/${legend.groupWeightedScore}`);
			console.log(t1,t2,t3);
			this.legends.push(legend);
		});
		while (this.legends.length < 3){
			const fakeLegend = {alias: '', title: '', studentWeightedScore: null, groupWeightedScore: null };
			this.legends.push(fakeLegend);
		}
		this.radarDataSets[0].data = this.legends.map(l => l.groupWeightedScore);
		this.radarDataSets[1].data = this.legends.map(l => l.studentWeightedScore);
		this.coms.waitFor(()=> this.chartCanvas).then(eleRef=>{
			const ele = eleRef.nativeElement;
			this.renderChart(ele);
		});
	}

	renderChart(ele){
		ele.width = ele.parentElement.offsetWidth - 40;
		ele.height = ele.parentElement.offsetHeight - 40;
		const ctx = ele.getContext('2d');
		if (this.chartObj){
			this.chartObj.destroy();
		}
		this.chartObj = new Chart(ctx, {
            type: 'radar',
            data: {
                labels: this.legends.map(l => l.alias),
                datasets: this.radarDataSets
            },
            options: this.radarOptions,
			plugins: [
				{	id: 'custom_labels',
					afterDraw: (chart, args) => {
						const scale = chart.scales.scale;
						// this.drawPointLabel(scale);
					} 
				}
			]
		});
	}

	drawPointLabel(scale){
		const offset = 36;
		const r = scale.drawingArea + offset;
		const ctx = scale.ctx;
		this.legends.forEach((legend, index)=>{
			const angle = scale.getIndexAngle(index) - Math.PI / 2;
			const x = scale.xCenter + Math.cos(angle) * r;
			const y = scale.yCenter + Math.sin(angle) * r;
			ctx.save();
			ctx.translate(x, y);
			ctx.textAlign = 'center';
			ctx.fillStyle = '#000';
			ctx.fillText(legend.alias + ': ' + legend.title, 0 , 0);
			ctx.fillStyle = 'red';
			ctx.fillText(legend.studentWeightedScore, 0, 16);
			ctx.fillStyle = 'blue';
			ctx.fillText(legend.groupWeightedScore, 0, 16);
		});
	}





}
