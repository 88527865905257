import { Component, HostBinding, Input, Output, EventEmitter, HostListener,OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  template: `
	print
  `
})

export class PrintComponent{

}
