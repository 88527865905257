import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TeacherSelectorComponent } from './teacher-selector.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
    imports: [
		CommonModule,TranslateModule,FormsModule, FontAwesomeModule,
		PerfectScrollbarModule
    ],
	exports:[
		TeacherSelectorComponent
	],
    declarations: [
		TeacherSelectorComponent
    ],
	entryComponents: [
		TeacherSelectorComponent
    ],
	providers:[]
})
export class MemberInfoModule { }
