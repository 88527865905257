import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YellowStarSvgComponent } from './yellow_star.component';


@NgModule({
    imports: [
		CommonModule
    ],
    declarations: [
        YellowStarSvgComponent
    ],
    exports: [
		YellowStarSvgComponent
	],
    providers: [],
    entryComponents: []
})
export class YellowStarSvgModule {
}