import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhitePopupComponent, WhitePopupFormComponent } from './whitePopup.component';
import { TranslateModule } from '@ngx-translate/core';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WhitePopupService } from './whitePopup.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TickBoxModule } from '../tickBoxModule/tickBox.module';



@NgModule({
    imports: [
		TickBoxModule,
		UiSwitchModule,
		CommonModule,
		TranslateModule,
		BubbleBox2Module,
		FontAwesomeModule
    ],
    declarations: [
      WhitePopupComponent, WhitePopupFormComponent
    ],
    exports: [
      WhitePopupComponent, WhitePopupFormComponent
    ],
    providers: [
		WhitePopupService
    ],
    entryComponents: [WhitePopupComponent, WhitePopupFormComponent]
})
export class WhitePopupModule {
}
