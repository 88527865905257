import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { DOMHelper } from "src/app/common/DOMHelper";
import { DragManager } from "./DragManager";
import { Base64ObjectUtils, ROColorUtils, ROComponent, ROContainerComponent, ROLabel, ROScoringType } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";
import { Subject, Subscription } from "rxjs";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { XMLJSNode, XMLJSParser } from "./xml/XMLParser";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import fa from "@mobiscroll/angular/dist/js/i18n/fa";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";
import { SelectMarkerIcon } from "./SelectMarkerIcon";
import { forEach } from "lodash";

///////////////
const LINE_COLORS:string[] = [
	/*'#74400D', '#74400D',*/'#FFC400', '#83B621', 
	'#41CBD9', '#C854E7', '#999999', '#276CCB', 
	'#20B16D', '#6D5401', '#4E720A', '#126E77', 
	'#652577', '#514F4F', '#0F3364', '#0B563F'];

@Component({
	template:`
	<div *ngIf="context.config.viewMode=='edit'" class="editQuestionLayer">
		<svg [attr.viewBox]="svgViewBox" [attr.width]="w" [attr.height]="h" xmlns="http://www.w3.org/2000/svg" overflow="visible">
			<g>
				<g *ngFor="let line of lines" >
					<line 
						[attr.x1]="line.from.point.x"
						[attr.y1]="line.from.point.y"
						[attr.x2]="line.to.point.x"
						[attr.y2]="line.to.point.y"
						[attr.stroke]="line.color"
						stroke-width="2"
						
						/>
				</g>
			</g>

		</svg>
		
		<div class="dropAreaPoint" *ngFor="let itm of catArray" 
			[style.left.px]="itm.point.x" [style.top.px]="itm.point.y" [style.backgroundColor]="itm.point.color"></div>
		<div class="dragObjectPoint" *ngFor="let itm of itemArray"
			[style.left.px]="itm.point.x" [style.top.px]="itm.point.y" [style.background]="itm.point.color" (pointerdown)="startCreateConnect(itm, $event)"></div>


		<svg *ngIf="createConnect" [attr.viewBox]="svgViewBox" [attr.width]="w" [attr.height]="h" xmlns="http://www.w3.org/2000/svg" overflow="visible">
			<g>
				<g>
					<line 
						[attr.x1]="createConnect.x1"
						[attr.y1]="createConnect.y1"
						[attr.x2]="createConnect.x2"
						[attr.y2]="createConnect.y2"
						stroke="#461919"
						stroke-width="4"
						
						/>
				</g>
			</g>

		</svg>
	</div>
	`,
	styles:[
		`
		.editQuestionLayer{position:relative;z-index:var(--verify-z-index);pointer-events:none}
		.editQuestionLayer svg{position:absolute;left:0;top:0;}
		.dropAreaPoint, .dragObjectPoint{position:absolute;border-radius:50%;transform:translate(-50%, -50%);}
		.dropAreaPoint{width:16px;height:16px;}
		.dragObjectPoint{width:34px;height:34px;border:solid 1px #888888;pointer-events:all}
		::ng-deep .dragItem2{z-index:2}
		::ng-deep .dropArea2{z-index:1}
		`]
	/*
	template:`
		<div class="copy">{{node.tag}} {{clonedDragItems.length}}</div>
	`,
	styles:[
		`
		.copy{
			position:absolute;
			left:-10px;
			top:-20px;
		}
		`
	]
	*/
})

export class RODragDropBasic extends ROContainerComponent
{
	protected dd:DragManager = new DragManager();
	protected clonedDragItems:RODragItem[];

	public svgViewBox:string;
	public lines:any[] = [];
	public createConnect:any;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.canEditInside = true;
	}

	protected resetForDefaultAnswer():void
	{
		this.sObj.hideScore();
		this.catArray.forEach((dropArea:RODropArea)=>{
			dropArea.correctState = -1;
		})
		this.itemArray.forEach((dragItem:RODragItem)=>{
			dragItem.assignTo(null, false);
			dragItem.correctState = -1;
		})
		this.clonedDragItems.forEach((dragItem:RODragItem)=>{
			dragItem.assignTo(null, false);
			dragItem.destroy();
		})
	}

	public canVerify():boolean
	{
		return true;
	}



	public defaultAnswer: any;
	public showDefaultAnswer(): void {
		if(!this.defaultAnswer)
		{
			this.defaultAnswer = {
				answer:this.data
			};
			if(this.sObj)
				this.sObj.elementRef.nativeElement.style.display = 'none';
		}
	}
	
	public hideDefaultAnswer(): void 
	{
		if(this.defaultAnswer)
		{
			this.data = this.defaultAnswer.answer;
			this.defaultAnswer = null;
			if(this.sObj)
				this.sObj.elementRef.nativeElement.style.display = null;
		}
	}

	public isQuestionComponent(): boolean {
		return true;
	}

	public getTagNames():string []
	{
		return [];
	}
	
	public itemArray:RODragItem [];
	public catArray:RODropArea [];
	protected buildChildren(): void {
		super.buildChildren();
		this.children.forEach((child:any)=>{
			if(child instanceof RODragItem || child  instanceof RODropArea)
			{
				child.initDragDrop(this);
			} 
		});
		this.clonedDragItems = [];
		this.catArray = this.children.filter((child)=>{
			return child instanceof RODropArea;
		});
		this.itemArray = this.children.filter((child)=>{
			return child instanceof RODragItem;
		});

		if(this.context && this.context.config && this.context.config.viewMode=='edit') {
			// update all connection point
			this.children.forEach((child:any)=>{
				if(child instanceof RODragItem || child  instanceof RODropArea)
					child.updatePoint(this);
			});

			this.rebuildAllConnection();
		}
	}

	protected initContext():void
	{

	}

	protected buildContent():void
	{
		this.initContext();
		var options:any = this.node.attributes;
		
		var dom:HTMLElement = this.elementRef.nativeElement;
		var options:any = this.node.attributes;
		
		if(options.avisible)
		{
			StyleUtils.setStyleVariabe(dom, "drop-area-bg-color", ROColorUtils.formatColor(options.baseColor, "transparent"));
			StyleUtils.setStyleVariabe(dom, "drop-area-border-color", ROColorUtils.formatColor(options.borderColor, "transparent"));
			StyleUtils.setStyleVariabe(dom, "drop-area-border-width", options.borderWidth +"px");
			StyleUtils.setStyleVariabe(dom, "drop-area-border-style",options.lineType== "line" ? "solid" : "dashed");
		} else {
			StyleUtils.setStyleVariabe(dom, "drop-area-bg-color", "transparent");
			StyleUtils.setStyleVariabe(dom, "drop-area-border-color", "transparent");
			StyleUtils.setStyleVariabe(dom, "drop-area-border-width", "0px");
			StyleUtils.setStyleVariabe(dom, "drop-area-border-style", "none");
		}

		this.svgViewBox = `0 0 ${this.w} ${this.h}`;
	}
	
	protected cloneDragItemIfNecessary(dragItem:RODragItem):RODragItem
	{
		var options:any = this.node.attributes;
		if(dragItem && options.reusable && dragItem.isCopy == false)
		{
			dragItem = dragItem.clone();
			this.clonedDragItems.push(dragItem);
			dragItem.emitter.subscribe((data:any)=>{
				if(data.type == "remove")
				{
					this.removeClonedDragItem(data.view);
				}
			});
		}
		return dragItem;
	}

	private hideVerifyIcon():void
	{
		if(this.catArray) this.catArray.forEach((dropArea:RODropArea)=>{
			dropArea.correctState = -1;
		});
		if(this.itemArray) this.itemArray.forEach((dragItem:RODragItem)=>{
			dragItem.correctState = -1;
		});
		if(this.clonedDragItems) this.clonedDragItems.forEach((dragItem:RODragItem)=>{
			dragItem.correctState = -1;
		});
	}

	@HostListener('pointerdown', ['$event'])
	onMouseDown(event) {
		if(!this.context.canInteract(this)) return;
		this.hideVerifyIcon();

		if(this.context.config.viewMode == "edit")
			return;
		
		var dom:HTMLElement = DOMLookup.findClassnameElement(this.elementRef.nativeElement, event.target, "drag");
		if(dom)
		{
			var dragItem:RODragItem = this.getDragItem(dom);
			if(!dragItem) return; 
			event.stopImmediatePropagation();
			var scale:number = DOMHelper.getElementScale(dom);
			var left:number = dom.style.left ? parseFloat(dom.style.left) : 0;
			var top:number =  dom.style.top ? parseFloat(dom.style.top) : 0;
			var position;
			
			dragItem = this.cloneDragItemIfNecessary(dragItem);
			dragItem.floatUp();
			var subscription:Subscription = new Subscription(()=>{
				dom.style.pointerEvents = "";
				dragItem.floatDown();
				this.itemGotIt(dragItem);
				this.answerChanged = true;

				this.context.subject.next({
					type:"action", 
					action:"answerChanged",
					data:this
				});
				
			});
			subscription.add(
				this.dd.pointerStart(event).subscribe((o:any)=>{
					if(o.type == "start")
					{
						dom.style.pointerEvents = "none";
						position = o.point;
					} else if(o.type=="move")
					{
						var px:number = ( (o.point.x - position.x) / scale )  + left;
						var py:number = ( (o.point.y - position.y) / scale )  + top;
						dragItem.moveTo(px, py);
					} else if(o.type=="end")
					{
						subscription.unsubscribe();
					}
				})
			);
		} 
	}

	public getDragItems():RODragItem []
	{
		return this.children.filter((child)=>{
			return child instanceof RODragItem;
		});
	}
	
	private getDragItem(dom:HTMLElement):RODragItem
	{
		var length:number = this.itemArray.length;
		var child:any;
		for(var i = 0;i <length;i++)
		{
			child = this.itemArray[i];
			if(child.dom == dom)
			{
				return child;
			}
		}
		length = this.clonedDragItems.length;
		for(var i = 0;i <length;i++)
		{
			child = this.clonedDragItems[i];
			if(child instanceof RODragItem)
			{
				if(child.dom == dom)
				{
					return child;
				}
			}
		}
		return null;
	}

	private withInBound(rect:any, px:number, py:number)
	{
		var offsetX:number = px - rect.x;
		var offsetY:number = py - rect.y;
		return offsetX >= 0 && offsetX < rect.width && offsetY >= 0 && offsetY < rect.height;
	}

	private isBetween(n:number, rangeStart:number, rangeEnd:number):boolean
	{
		return n >= rangeStart && n < rangeEnd;
	}

	
	protected getDropAreaList():RODropArea[]
	{
		return this.children.filter((child)=>{
			return child instanceof RODropArea;
		});
	}

	protected findDropAreaByID(douid:string):RODropArea
	{
		var len:number = this.catArray.length;
		for(var i = 0;i < len;i++)
		{
			var child:RODropArea = this.catArray[i];
			if(child.douid == douid) return child;
		}
		return null;
	}

	protected findDropAreaAt(cPx:number, cPy:number):RODropArea
	{
		var dropAreaList:any [] = this.catArray.filter((child)=>{
			var bounds = {
				x:child.x,
				y:child.y,
				width:child.w,
				height:child.h
			};
			;
			if (this.withInBound(bounds, cPx, cPy))
			{
				return child;
			}
			return false;
		});
		return dropAreaList.length ? dropAreaList[0] : null;
	}
	
	public removeClonedDragItem(dragItem:RODragItem):void
	{
		if(dragItem.isCopy)
		{
			ArrayUtils.removeElement(this.clonedDragItems, dragItem);
			// dragItem.destroy();
		}
	}

	public itemGotIt(dragItem:RODragItem):void
	{
		var cPx:number = dragItem.dragX + dragItem.w / 2;
		var cPy:number = dragItem.dragY + dragItem.h / 2;
		var dropArea:RODropArea = this.findDropAreaAt(cPx, cPy);
		dragItem.assignTo(this.canAssignTo(dropArea, dragItem) ? dropArea : null, true);
	}
	
	protected canAssignTo(da:RODropArea, di:RODragItem):boolean
	{
		return true;
	}
	
	private reset():void
	{
		this.clonedDragItems.concat().forEach((dragItem:RODragItem)=>{
			dragItem.assignTo(null, false);
		})
		this.itemArray.forEach((child)=>{
			var dragItem:RODragItem = child;
			dragItem.assignTo(null, false);
		});
	}
	
	private createDragItemByID(douid:string):RODragItem
	{
		for(var i = 0;i < this.itemArray.length;i++)
		{
			var child:any = this.itemArray[i];
			if(child.douid == douid)
			{
				var dragItem:RODragItem = child;
				return this.cloneDragItemIfNecessary(dragItem);
			}
		}
		return null;
	}
	private setDragItem(node:XMLNode):void
	{
		// douid: '1D6C34B8-EF49-2FA4-A420-7993E98AA3F8', x: 169, y: '-6', correctGroup: 
		var dragItemID:string = node.getAttribute("douid");
		var correctGroup:string = node.getAttribute("correctGroup");
		var px:number = node.getAttribute("x")
		var py:number = node.getAttribute("y");
		var dragItem:RODragItem = this.createDragItemByID(dragItemID);
		var dropArea:RODropArea = this.findDropAreaByID(correctGroup);
		if(dragItem)
		{
			dragItem.moveTo(px,py);
			if(dropArea)
				dragItem.assignTo(dropArea, false);
		}
	}
	
	public set data(value: string) 
	{
		this.answerChanged = false;
		this.reset();
		if(value)
		{
			var parser:XMLJSParser = new XMLJSParser();
			var node:XMLNode = parser.parse(value);
			node.children.forEach((childNode:XMLNode)=>{
				this.setDragItem(childNode);
			});
		}
	}
	
	public get data(): string {
		this.verify(false);
		var axml:XMLNode = new XMLJSNode().assign({
			tag:"DragdragData", attributes:{ answered:true }
		});
		var hasMoved:boolean = false;
		var nCount:number = 0;
		this.getDropAreaList().forEach((da:RODropArea)=>{
			var items:RODragItem [] = da.getAllSnapedItems();
			if(items.length) hasMoved = true;
			items.forEach((item:RODragItem)=>{
				var t:XMLNode = new XMLJSNode().assign({
					tag:"dragItem", attributes:{ douid:item.douid, x:Math.floor(item.dragX), y:Math.floor(item.dragY) }
				});
				var dropArea:RODropArea = item.currentDropAreaTarget;
				if (dropArea)
				{
					t.setAttribute("correctGroup", dropArea.douid);
					nCount++;
				}
				else
				{
					t.setAttribute("correctGroup", "");
				}
				axml.addChild(t);
			})
		});
		
		if (!hasMoved) return null;
		axml.setAttribute("correct", this.resultObject ? this.resultObject.correct : -1);
		return axml.toXMLString();
	}
	
	protected getScoreCount():number
	{
		// var dropAreaList:RODropArea[] = this.getDropAreaList();
		var dragItems:RODragItem [] = this.getDragItems();
		var count:number = 0;
		
		// var len:number = dropAreaList.length;
		dragItems.forEach((di:RODragItem)=>{
			var options:any = di.node.attributes;
			if (options.correctGroupID && !di.isCopy)
			{
				count += options.correctGroupID.split(",").length;
			}
		})
		return count;
	}

	protected getDropAreaAnswer(da:RODropArea):RODragItem []
	{
		var len:number = this.itemArray.length;
		var output:RODragItem [] = [];
		for (var i:number = 0; i < len; i++)
		{
			var di:RODragItem = this.itemArray[i];
			if (di && !di.isCopy )
			{
				if (di.correctGroupID.indexOf(da.myID.toString()) !== -1){
					output.push(di)
				}
			}
		}
		return output;
	}
	
	public getCorrectWrongStatus(_showScoring:boolean):any {
		var total:number = this.getScoreCount(); // 連線總數
		var numOfCorrect:number = 0;
		var numOfIncorrect:number = 0;
		var numOfDummy:number = 0;

		var dropAreaList:RODropArea[] = this.getDropAreaList();
		dropAreaList.forEach((cat:RODropArea)=>{
			// 取得被 drop 物件
			var anss:any[] = cat.getAllSnapedItems();
			if (anss && anss.length) {
				var modelAnswer:string[] = this.getDropAreaAnswer(cat).map(di=>{
					return di.myID;
				});
				var catTotalAns:number = modelAnswer.length;
				
				var cCount:number = 0;
				cat.correctState = -1;
				var catHasCorrect:boolean = false;
				var catHasIncorrect:boolean = false;
				var lastDragItem:RODragItem;

				anss.forEach((di:RODragItem)=>{
					lastDragItem = di;
					di.correctState = -1;

					var i:number = modelAnswer.indexOf(di.myID);
					if(i>=0) {
						modelAnswer.splice(i,1);
						catHasCorrect = true;
						cCount++;
					} else {
						catHasIncorrect = true;
						numOfIncorrect++;
						numOfDummy++;
					}
				});
				numOfCorrect += cCount;

				var target:any = this.findShowScoreComponent(cat, lastDragItem);
				if(_showScoring && catTotalAns) {
					if(catTotalAns == cCount && !catHasIncorrect) {
						target.correctState = 2;
					} else {
						target.correctState = catHasCorrect ? 1 : 0;
					}
				} else {
					target.correctState = -1;
				}
			}
		});

		return {
			total:total,
			numOfCorrect:numOfCorrect,
			numOfIncorrect:numOfIncorrect,
			numOfDummy:numOfDummy
		};
	}

	public verify(_showScoring: boolean):any {
		var res:any = this.getCorrectWrongStatus(_showScoring);
		var score:number = this.getFullScore();
		var totalCount:number = res.total; // 連線總數
		var numberOfCorrect:number = res.numOfCorrect;
		var numberOfIncorrect:number = res.numOfIncorrect;
		var total:number = this.getDropAreaList().length;

		var allCorrect:boolean = (numberOfCorrect == totalCount);
		if (total)
		{
			if (allCorrect && numberOfIncorrect == 0)
			{
				this.resultObject = { correct: 2,  score:score};
			} else if (numberOfCorrect)
			{
				this.resultObject = { correct: 1,  score:score};
			} else {
				this.resultObject = { correct: 0,  score:score};
			}
		} else {
			// not sure
			this.resultObject = { correct: 0,  score:0};
		}
		this.updateScore(allCorrect, numberOfCorrect, numberOfIncorrect);
		if(_showScoring && this.sObj)
			this.sObj.showScore(this.resultObject.correct, this.resultObject.score);
		
		return this.resultObject;
	}

	protected updateScore(allCorrect:boolean, correctCount:number, dummyCount:number):void
	{
		var scoringType:any = this.node.attributes.scoringType;
		
		if (scoringType == ROScoringType.COMPLETE)
		{
			if (allCorrect && dummyCount == 0)
			{
				this.resultObject.score = this.getFullScore();
			} else {
				this.resultObject.score = 0;
			}
		}
		else
		{
			if (allCorrect)
			{
				this.resultObject.score = this.context.roundScore(this.getUnitScore() * (correctCount - (0.5 * dummyCount)) );
			} else {
				this.resultObject.score = this.context.roundScore(this.getUnitScore() * correctCount );
			}
		}
		if (this.resultObject.score < 0)
		{
			this.resultObject.score = 0;
		}
	}
	
	
	protected findShowScoreComponent(da:RODropArea, di:RODragItem):any
	{
		var dropAreaOption:any = da.node.attributes;
		// var dragItemOption:any = di.node.attributes;
		if (dropAreaOption.avisible == false)
			return di;
		if (da.w >= di.w && da.h >= di.h)
			return da;
		return di;
	}
	/**
		 * 
		 * @param	id : "A"/"B"
		 * @return
		 */
	protected findCat2(id:String):RODropArea
	{
		for (var i:number = 0; i < this.catArray.length; i++) {
			var cat:RODropArea = this.catArray[i];
			if (cat.myID == id) {
				return cat;
			}
		}
		return null;
	}
	
	
	// =======================================
	// edit relative function
	// =======================================
	public getPopupSelector():any []
	{
		var ary:any[] = super.getPopupSelector();
		if(this.editInStage) {
			ary.splice(1,0, {
				type:"action",
				action:"addDragItem",
				target:"component",
				component:this,
				icon:SelectMarkerIcon.ADD_ICON,
				name:this.context.translate("ro.components.name.dragItem2")
			},{
				type:"action",
				action:"addDropArea",
				target:"component",
				component:this,
				icon:SelectMarkerIcon.ADD_ICON,
				name:this.context.translate("ro.components.name.dropArea2")
			});
		}
		return ary;
	}

	protected getNextDragItemID():string
	{
		return String.fromCharCode(65 + this.itemArray.length);
	}
	protected getNextDropAreaID():string
	{
		return String.fromCharCode(65 + this.catArray.length);
	}

	public addDragItem():void
	{
		var ax:number = Math.round(Math.random() * 100);
		var ay:number = Math.round(Math.random() * 100);
		
		if (this.itemArray.length > 0) 
		{
			var itm = this.itemArray[this.itemArray.length-1];
			ax = this.itemArray[this.itemArray.length-1].x + ax;
			ay = this.itemArray[this.itemArray.length-1].y + ay;
		}

		let parser:XMLJSParser = new XMLJSParser();
		var douid:string = GUID.create("OKD guid");
		var myID:string = this.getNextDragItemID();
		var _node:XMLNode = parser.parse(`<dragItem2 ver="1.4" x="${ax}" y="${ay}" w="101" h="101" douid="${douid}" myID="${myID}" correctGroupID=""/>`);
		_node.createElement();
		this.node.addChild(_node);
		this.node.element.elements.push(_node.element);
		var child = this.context.createForEF(this.elementRef, _node, this.page, this);
		child.initDragDrop(this);
		this.children.push(child);
		this.itemArray.push(child);

		child.updatePoint(this);
	}
	
	public addDropArea():void
	{
		var ax:number = Math.round(Math.random() * 100);
		var ay:number = Math.round(Math.random() * 100);
		
		if (this.catArray.length > 0) 
		{
			ax = this.catArray[this.catArray.length-1].x + ax;
			ay = this.catArray[this.catArray.length-1].y + ay;
		}

		let parser:XMLJSParser = new XMLJSParser();
		var douid:string = GUID.create("OKD guid");
		var myID:string = this.getNextDropAreaID();
		
		var _node:XMLNode;
		if(this instanceof RODragDropC)
			_node = parser.parse(`<dropArea2 ver="1.4" x="${ax}" y="${ay}" w="240" h="120" gridX="1" gridY="1" douid="${douid}" myID="${myID}" snapType="false" snapGrid="false" coordinateExpression="UA UK X ${ax} Y ${ay} D T ${ay} L ${ax} H 120 W 240"/>`);
		else
			_node = parser.parse(`<dropArea2 ver="1.4" x="${ax}" y="${ay}" w="120" h="120" gridX="1" gridY="1" douid="${douid}" myID="${myID}" snapType="true" snapGrid="false" coordinateExpression="UA UK X ${ax} Y ${ay} D T ${ay} L ${ax} H 120 W 120"/>`);
		_node.createElement();
		this.node.addChild(_node);
		this.node.element.elements.push(_node.element);
		var child = this.context.createForEF(this.elementRef, _node, this.page, this);
		child.initDragDrop(this);
		this.children.push(child);
		this.catArray.push(child);

		child.updatePoint(this);
	}
	
	runCommand(action:string):void
	{
		if(action == "addDragItem") {
			this.addDragItem();

		} else if(action == "addDropArea") {
			this.addDropArea();
		}
		
	}

	public getDragItemAnswer(dragItem:RODragItem):any[]
	{
		if(!this.catArray)
			return [];
		return this.catArray.filter(e => dragItem.correctGroupID.indexOf(e.myID.toString()) !== -1);
	}

	/*
	[drag 對 drop]
	A. 一對一			取消別人連結
	B. 多對一(重用)		取消別人連結
	C. 一對多(分組)		不取消別人連結
	F. 多對多			不取消別人連結
	[drag 對 drag]
	D. 分組互換			取消別人連結
	E. 分組互換排序		取消別人連結
	*/
	protected rebuildAllConnection():void
	{
		// get connections
		this.lines = [];
		if((this instanceof RODragDropD) || (this instanceof RODragDropE)) {
			// TODO:

		} else {
			this.itemArray.forEach((di:RODragItem)=>{
				this.catArray.forEach((da:RODropArea)=>{
					if(di.correctGroupID.indexOf(da.myID)>=0)
						this.createConnection(di, da);
				});
			});
		}
	}

	protected createConnection(di:RODragItem, da:any):void {
		this.lines.push({
			from:di,
			to:da,
			color:LINE_COLORS[da.myID.charCodeAt(0)-65]
		});
	}

	public startCreateConnect(dragItem:RODragItem, event:any):void
	{
		if(this.context.editLayerManager.currentLayer != this)
			return;
		event.stopImmediatePropagation();

		var subject:Subject<any> = this.dd.pointerStart(event);
		var subscription:Subscription = subject.subscribe((o:any)=>{
			var pt = DOMHelper.getLocalPoint(this.elementRef.nativeElement, o.point);
			this.createConnect={
				x1:dragItem.point.x,
				y1:dragItem.point.y,
				x2:pt.x,
				y2:pt.y
			}

			var dom:HTMLElement = DOMHelper.findROComDOM(<HTMLElement>document.elementFromPoint(o.point.x, o.point.y));
			if(o.type == "cancel" || o.type == "timeout" || o.type == "end") {
				subscription.unsubscribe();
				this.createConnect = null;
				this.updateConnectionRecord(dragItem, this.catArray.find(itm => itm.getElementRef().nativeElement == dom));
				this.rebuildAllConnection();
			}
				
		});
	}

	protected updateConnectionRecord(di:RODragItem, da:any):void {
		// 個別 class handle
	}


	public getSupportedComponentPanel():string
	{
		return "none";
	}

	protected editInStageFinish():void
	{
		this.updateBoundaryAndChildPosition();
		this.svgViewBox = `0 0 ${this.w} ${this.h}`; // 這個 area 不在 container 的更新內
	}

	public removeComponent(com:ROComponent):void
	{
		super.removeComponent(com);
		if(com instanceof RODragItem)
			this.clearDragItemConnection(<RODragItem>com);
		else
			this.clearDropAreaConnection(<RODropArea>com);

		this.rebuildAllConnection();
		//this.getXMLJSON();
		console.log(">>>>>>>>>>>>>>> remove");
		console.log(this);
	}

	public clearDragItemConnection(dragItem:RODragItem):void
	{
		this.itemArray = this.itemArray.filter(itm => itm!=dragItem);
		dragItem.correctGroupID = ""; // 取消所有連結
	}

	public clearDropAreaConnection(dropArea:RODropArea):void
	{
		this.catArray = this.catArray.filter(itm => itm!=dropArea);
		// 找出那些drag item 指住這個 drop area
		this.itemArray.forEach(itm => {
			var id:string = dropArea.myID;
			if(itm.correctGroupID.indexOf(id)>=0) {
				itm.correctGroupID = (this instanceof RODragDropB || this instanceof RODragDropF) ?
					itm.correctGroupID.split(",").filter(id2=>id2!=id).join(",") : "";					
			}
		});
	}
}


export class RODragDropA extends RODragDropBasic
{

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public showDefaultAnswer(): void {
		super.showDefaultAnswer();
		super.resetForDefaultAnswer();

		var locX:number;
		var locY:number;
		var da:RODropArea;
		
		
		this.itemArray.forEach((dI:RODragItem)=>{
			var correctGroupID:string = dI.correctGroupID;
			if (correctGroupID)
			{
				var cgrp:string [] = correctGroupID.split(",");
				cgrp.forEach((groupID:string)=>{
					var diCopy:RODragItem = this.cloneDragItemIfNecessary(dI);
					da  = this.findCat2(groupID);
					if (da) 
					{
						locX = da.x+(da.w - dI.w) / 2;
						locY =  da.y+(da.h - dI.h) / 2;
						diCopy.moveTo(locX, locY);
						diCopy.assignTo(da, false);
					} else {
						diCopy.assignTo(null, false);
					}
				});
			} else {
				dI.assignTo(null, false);
			}
		
		}); // end for loop
		
	} // end show default
	
	public getTagNames():string []
	{
		return [
			"DragDropA"
		];
	}
	static getDefaultXMLString(douid:string = null, canDelete:boolean = true):string {
		if(!douid)
			douid = GUID.create("OKD guid");

		var a:string = GUID.create("OKD guid");
		var b:string = GUID.create("OKD guid");
		var c:string = GUID.create("OKD guid");
		var d:string = GUID.create("OKD guid");

		return '<DragDropA '+(canDelete ? '' : 'canDelete="false" ')+'douid="'+douid+'" '+
//			'q="{&quot;show&quot;:1,&quot;y&quot;:-6,&quot;ballSize&quot;:36,&quot;freezed&quot;:1,&quot;level&quot;:0,&quot;color&quot;:&quot;noColor&quot;,&quot;x&quot;:-100}" '+
//			's="{&quot;show&quot;:1,&quot;y&quot;:-6,&quot;ballSize&quot;:38,&quot;freezed&quot;:0,&quot;enable&quot;:1,&quot;x&quot;:315}" '+
			'ver="1.3" coordinateExpression="UA UK X 0 Y 0 D T 0 L 0 H 269 W 279" '+
			'reusable="false" baseColor="#F5EDDA" borderColor="#B89160" borderWidth="1" avisible="true" isSnapping="true"  lineType="line" locked="false">'+
			'<dragItem2 ver="1.4" x="10" y="168" w="101" h="101" douid="'+a+'" myID="A" correctGroupID="A"/>'+
			'<dragItem2 ver="1.4" x="169" y="168" w="101" h="101" douid="'+b+'" myID="B" correctGroupID="B"/>'+
			'<dropArea2 ver="1.4" x="0" y="0" w="120" h="120" gridX="1" gridY="1" douid="'+c+'" myID="A" snapType="true" snapGrid="false" coordinateExpression="UA UK X 0 Y 0 D T 0 L 0 H 120 W 120"/>'+
			'<dropArea2 ver="1.4" x="159" y="0" w="120" h="120" gridX="1" gridY="1" douid="'+d+'" myID="B" snapType="true" snapGrid="false" coordinateExpression="UA UK X 159 Y 0 D T 0 L 159 H 120 W 120"/>'+
		'</DragDropA>';
	}

	protected setDefaultXMLData():void
	{
		let parser:XMLJSParser = new XMLJSParser();
		this.node = parser.parse(RODragDropA.getDefaultXMLString());
		this.node.createElement();
	}


	public verify(_showScoring: boolean) {
		var tag:string = this.node.tag;
		var score:number = this.getFullScore();
		var totalCount:number = this.getScoreCount();
		var numberOfCorrect:number = 0;
		var numberOfIncorrect:number = 0;
		var numberOfDummy:number = 0;

		var dropAreaList:RODropArea[] = this.getDropAreaList();

		var total:number = dropAreaList.length;
		
		dropAreaList.forEach((cat:RODropArea)=>{
			var array:any [] = cat.getAllSnapedItems();
			if (array && array.length)
			{
				cat.correctState = -1;
				var catHasCorrect:boolean = false;
				var catHasIncorrect:boolean = false;
				var lastDragItem:RODragItem;
				array.forEach((item:RODragItem)=>{
					lastDragItem = item;
					item.correctState = -1;
					var correct:boolean = item.checkAnswer();
					if (correct) // if correct
					{
						catHasCorrect = true;
						numberOfCorrect++;
					} else {
						catHasIncorrect = true;
						numberOfIncorrect++;
						numberOfDummy++;
					}
				})
				var target:any = this.findShowScoreComponent(cat, lastDragItem);
				if(_showScoring){
					if(catHasIncorrect && catHasCorrect)
					{
						target.correctState = 0;
					} else if(catHasCorrect)
					{
						target.correctState = 2;
					} else {
						target.correctState = 0;
					}
				} else {
					target.correctState = 0;
				}
			} else {
				cat.correctState = -1;
			}
		});
		
		var allCorrect:boolean = (numberOfCorrect == totalCount);
		if (total)
		{
			if (allCorrect && numberOfDummy == 0)
			{
				this.resultObject = { correct: 2,  score:score};
			} else if (numberOfCorrect)
			{
				this.resultObject = { correct: 1,  score:score};
			} else {
				this.resultObject = { correct: 0,  score:score};
			}
		} else {
			// not sure
			this.resultObject = { correct: 0,  score:0};
		}
		this.updateScore(allCorrect, numberOfCorrect, numberOfDummy);
		if(_showScoring && this.sObj)
			this.sObj.showScore(this.resultObject.correct, this.resultObject.score);
		return this.resultObject;
	}
	
	// =======================================
	// edit relative function
	// =======================================
	// A. 一對一			取消別人連結
	protected updateConnectionRecord(di:RODragItem, da:any):void
	{
		di.correctGroupID = "";
		if(da instanceof RODropArea) {
			// 查看 target 有無連結，有則取消他
			this.itemArray.forEach((itm:RODragItem)=>{
				if(itm.correctGroupID.indexOf(da.myID)>=0)
					itm.correctGroupID = "";
			});

			// 設定連結
			di.correctGroupID = da.myID;
		}
			
	}
}

export class RODragDropB extends RODragDropA
{

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return [
			"DragDropB"
		];
	}
}
export class RODragDropC extends RODragDropBasic
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	// C. 一對多(分組)		不取消別人連結
	protected updateConnectionRecord(di:RODragItem, da:any):void
	{
		di.correctGroupID = "";
		if(da instanceof RODropArea) {
			// 設定連結
			di.correctGroupID = da.myID;
		}
			
	}

	public getTagNames():string []
	{
		return [
			"DragDropC"
		];
	}

	static getDefaultXMLString(douid:string = null, canDelete:boolean = true):string {
		if(!douid)
			douid = GUID.create("OKD guid");

		var a:string = GUID.create("OKD guid");
		var b:string = GUID.create("OKD guid");
		var c:string = GUID.create("OKD guid");
		var d:string = GUID.create("OKD guid");
		var e:string = GUID.create("OKD guid");
		var f:string = GUID.create("OKD guid");

		return '<DragDropC '+(canDelete ? '' : 'canDelete="false" ')+'douid="'+douid+'" '+
//			'q="{&quot;show&quot;:1,&quot;y&quot;:-6,&quot;ballSize&quot;:36,&quot;freezed&quot;:1,&quot;level&quot;:0,&quot;color&quot;:&quot;noColor&quot;,&quot;x&quot;:-100}" '+
//			's="{&quot;show&quot;:1,&quot;y&quot;:-6,&quot;ballSize&quot;:38,&quot;freezed&quot;:0,&quot;enable&quot;:1,&quot;x&quot;:315}" '+
			'ver="1.3" coordinateExpression="UA UK X 0 Y 0 D T 0 L 0 H 270 W 526" '+
			'reusable="false" baseColor="#F5EDDA" borderColor="#B89160" borderWidth="1" avisible="true" isSnapping="true"  lineType="line" locked="false">'+
			'<dragItem2 ver="1.4" x="0" y="169" w="101" h="101" douid="'+a+'" myID="A" correctGroupID="A"/>'+
			'<dragItem2 ver="1.4" x="138" y="169" w="101" h="101" douid="'+b+'" myID="B" correctGroupID="A"/>'+
			'<dragItem2 ver="1.4" x="286" y="169" w="101" h="101" douid="'+c+'" myID="C" correctGroupID="B"/>'+
			'<dragItem2 ver="1.4" x="426.55" y="169" w="101" h="101" douid="'+d+'" myID="D" correctGroupID="B"/>'+
			'<dropArea2 ver="1.4" x="0" y="0" w="240" h="120" gridX="1" gridY="1" douid="'+e+'" myID="A" snapType="false" snapGrid="false" locked="false" coordinateExpression="UA UK X 0 Y 0 D T 0 L 0 H 120 W 240"/>'+
			'<dropArea2 ver="1.4" x="286" y="0" w="240" h="120" gridX="1" gridY="1" douid="'+f+'" myID="B" snapType="false" snapGrid="false" locked="false" coordinateExpression="UA UK X 286 Y 0 D T 0 L 286 H 120 W 240"/>'+
		'</DragDropC>';
	}

	protected isSnapable():boolean
	{
		var catArray:RODropArea[] = this.getDropAreaList();
		var len:number = catArray.length;
		for (var i:number = 0; i < len; i++)
		{
			return catArray[i].node.attributes.snapType;
		}
		return false;
	}
	
	public showDefaultAnswer(): void {
		super.showDefaultAnswer();
		super.resetForDefaultAnswer();
		
		this.itemArray.forEach((dI:RODragItem)=>{
			if(dI.correctGroupID != ""){
				var cgrp:string [] = dI.correctGroupID.split(",");
				var da:RODropArea  = this.findCat2(cgrp[0]);
				if (da) 
				{
					da.autoAppend(dI);
				}
			}
		
		}); // end for loop
	}
	
}
export class OKDDragDropInterchange extends RODragDropBasic
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public showDefaultAnswer():void 
	{
		super.showDefaultAnswer();
		super.resetForDefaultAnswer();
		/*
		if (inorder) {
			this.sortDragOrder();
		}
		*/
		var iOrder:number;
		var n:number=1;
		var id:string = String.fromCharCode(n + 64);
		
		// loop through groups to check answer
		var groups:any = {};
		this.itemArray.forEach((dragItem:RODragItem)=>{
			dragItem.restore();
		});
		this.itemArray.forEach((dragItem:RODragItem)=>{
			var groupName:string = dragItem.correctGroupID;
			if(groupName)
			{
				if(groups.hasOwnProperty(groupName) == false)
				{
					groups[groupName] = {
						key:groupName,
						items:[]
					};
				};
				groups[groupName].items.push(dragItem);
			}
		})
		var groupArray = [];
		for(var key in groups)
		{
			groupArray.push(groups[key]);
		}
		if(groupArray.length <= this.catArray.length)
		{
			var boxArray:RODropArea []  = this.catArray.concat();
			groupArray.forEach((group:any)=>{
				this.sortItemOrder(group.items);
			})
			groupArray.forEach((group:any, groupIndex:number)=>{
				var dropArea:RODropArea = this.findNearestDropArea(boxArray, group.items);
				if(dropArea)
				{
					ArrayUtils.removeElement(boxArray, dropArea);
					group.items.forEach((dragItem:RODragItem)=>{
						dropArea.autoAppend(dragItem);
					});
				} else {
					// do nothing
				}
			});
		}
		
	}
	private getDistance(dropArea:ROComponent, dragItem:ROComponent):number
	{
		var dx:number = dropArea.x - dragItem.x;
		var dy:number = dropArea.y - dragItem.y;
		return Math.sqrt(dx * dx + dy * dy);
	}
	private findNearestDropArea(dropAreaArray:RODropArea [], dragItems:RODragItem []):RODropArea
	{
		if(!dragItems.length) return null;
		var minDistanceTarget:any = {
			target:null,
			index:-1,
			distance:9999999999
		}
		dropAreaArray.forEach((dropArea:RODropArea, index:number)=>{
			var totalDistance:number = 0;
			dragItems.forEach((dragItem:RODragItem)=>{
				totalDistance += this.getDistance(dropArea, dragItem);
			})
			var distance = totalDistance / dragItems.length;
			if(distance < minDistanceTarget.distance)
			{
				minDistanceTarget = {
					index:index,
					distance:distance,
					target:dropArea
				};
			}
		});
		return minDistanceTarget.target;
	}
	sortItemOrder(items:RODragItem [] ) {
		if(items && items.length)
		{
			var item:RODragItem = items[0];
			var firstDragItem:RODragItem;
			if(item.startPair || item.endPair)
			{
				if(item.startPair)
				{
					var tmp:RODragItem = item;
					while(item.startPair && item != tmp)
					{
						item = item.startPair;
						firstDragItem = item;
					}
				} else {
					firstDragItem = item;
				}

				var dragItem:RODragItem = firstDragItem;
				var counter:number = 0;
				while(dragItem && counter < 200)
				{
					dragItem.order = counter;
					dragItem = dragItem.endPair;
					counter ++;
				}
				
				items.sort((a:RODragItem, b:RODragItem)=>{
					return a.order - b.order;
				});
			}
		}
		
	}

	protected createGroupListFromNode(node:RODragItem):RODragItem []
	{
		var node:RODragItem = this.findFirstNode(node);
		return this.iterateAllNode(node);
		
	}
	protected findFirstNode(node:RODragItem):RODragItem
	{
		var firstNode:RODragItem = node;
		var list:any[] = []
		for(var i:number = 0;i < 1024 && firstNode.startPair;i++)
		{
			list.push(firstNode);
			firstNode = firstNode.startPair;
			if (list.indexOf(firstNode) != -1)
			{
				break;
			}
		}
		return firstNode;
	}

	private iterateAllNode(firstNode:RODragItem):RODragItem []
	{
		var output:RODragItem[] = [];
		for (var i:number = 0; i < 100 && firstNode; i++)
		{
			output.push(firstNode);
			firstNode = firstNode.endPair;
			if (output.indexOf(firstNode) != -1)
			{
				break;
			}
		}
		return output;
	}

}
export class RODragDropD extends OKDDragDropInterchange
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return [
			"DragDropD"
		];
	}

	public verify(_showScoring: boolean) 
	{
		var cat:RODropArea;
		var items:any [] ;
		var i:number;
		var totalCount:number = this.getScoreCount();
		
		
		var numberOfCorrect:number = 0;
		//var numberOfIncorrect:int;
		var numberOfDummy:number = 0;
		var scoreCount:number = 0;
		//var nCorrect:int = 0;
		//var iCorrect:int = 0;
		//var hasError:Boolean;
		var catArray:RODropArea [] = this.getDropAreaList();
		var total:number =  catArray.length;
		var selectedItemsArray:any [] = [];
		for (i = 0; i < total; i++)
		{
			cat = catArray[i];
			items = cat.getAllSnapedItems();
			selectedItemsArray[i] = items;
		}
		for (i = 0; i < total; i++)
		{
			var good:number = 0;
			cat = catArray[i];
			items = selectedItemsArray[i];
			if (items.length == 1)
			{
				numberOfDummy++;
				cat.correctState = _showScoring ? 0 : -1;
			} else if(items.length){
				
				var firstItem:RODragItem = items[0];
				
				if (this.isSameGroup(items) && !this.isInOtherGroup(selectedItemsArray, i, firstItem))
				{
					var correctItems:any [] = this.getItemsByGroupID(firstItem.node.attributes.correctGroupID);
					good += items.length;
					var c:number;
					if (items.length == correctItems.length)
					{
						numberOfCorrect++;
						c = 2;
					} else if (items.length)
					{
						c = 1;
					} else {
						
						c = 0;
					}
					cat.correctState = _showScoring ? c : -1;
						
				} else {
					numberOfDummy++;
					cat.correctState = _showScoring ? 0 : -1;
				}
				
			}
			scoreCount += good;
		}
		
		var allCorrect:boolean = (numberOfCorrect == totalCount);
		if (total)
		{
			if (allCorrect && numberOfDummy == 0)
			{
				this.resultObject = { correct: 2 };
			} else if (scoreCount)
			{
				this.resultObject = { correct: 1 };
			} else {
				this.resultObject = { correct: 0 };
			}
		} else {
			// not sure
			this.resultObject = { correct: 0 };
		}
		this.updateScore(allCorrect, numberOfCorrect, numberOfDummy);
		if(_showScoring)
			this.sObj.showScore(this.resultObject.correct, this.resultObject.score);
		return this.resultObject;
		
	}
	
	private isSameGroup(items:any []):Boolean
	{
		
		var firstItem:RODragItem = items[0];
		var groupID:String = firstItem.node.attributes.correctGroupID;
		if (groupID)
		{
			var len:number = items.length;
			for (var i:number = 1; i < len; i++)
			{
				if (items[i].correctGroupID != groupID)
				{
					return false;
				}
			}
			return true;
		} else {
			return false;
		}
	}

	protected getItemsByGroupID(groupID:string):RODragItem []
	{
		var dragItems:any [] = this.getDragItems();
		return dragItems.filter((item:RODragItem)=>{
			return (item.node.attributes.correctGroupID == groupID);
		});
	}
	
	private isInOtherGroup(answerArray:any [], index:number, item:RODragItem):boolean
	{
		var len:number = answerArray.length;
		for (var i:number = 0; i < len; i++)
		{
			if (i != index)
			{
				var categoryAnswer:any [] = answerArray[i];
				if (this.isInCategoryAnswer(item, categoryAnswer))
				{
					return true;
				}
			}
		}
		// trace(answerArray);
		return false;
	}
	private isInCategoryAnswer(item:RODragItem, categoryAnswer:any []):Boolean
	{
		var len:number = categoryAnswer.length;
		for (var i:number = 0; i < len; i++)
		{
			var o:RODragItem = categoryAnswer[i];
			if (this.isSameGroup([o, item]))
			{
				return true;
			}
		}
		return false;
	}

	protected hasDummyItem(items:RODragItem []):boolean
	{
		var len:number = items.length;
		for (var i:number = 0; i < len; i++)
		{
			var item:RODragItem = items[i];
			if (!item.correctGroupID)
			{
				return true;
			}
		}
		return false;
	}

	protected findFirstNode(node:RODragItem):RODragItem
	{
		var firstNode:RODragItem = node;
		var list:any [] = []
		for(var i:number = 0;i < 1024 && firstNode.startPair;i++)
		{
			list.push(firstNode);
			firstNode = firstNode.startPair;
			if (list.indexOf(firstNode) != -1)
			{
				break;
			}
		}
		return firstNode;
	}

	protected getScoreCount():number
	{
		return this.numberOfGroup();
	}

	protected numberOfGroup():number
	{
		var count:number = 0;
		var group:any = {
			
		}
		this.itemArray.forEach((item:RODragItem)=>{
			var groupID:string = item.correctGroupID;
			if (groupID)
			{
				if (!group.hasOwnProperty(groupID))
				{
					group[groupID] = 1;
					count++;
				}
			}
		})
		return count;
	}

}

export class RODragDropE extends RODragDropD
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	

	protected buildChildren(): void {
		super.buildChildren();
		this.fillStartAndEndPair();
	}

	public getTagNames():string []
	{
		return [
			"DragDropE"
		];
	}

	

	protected getScoreCount():number
	{
		return this.numberOfGroup();
	}
	
	public verify(_showScoring:boolean):any
	{
		var totalCount:number = this.getScoreCount();
		var numberOfCorrect:number = 0;
		var numberOfDummy:number = 0;
		
		var total:number =  this.catArray.length;
		for (var i:number = 0; i < total; i++)
		{
			var cat:RODropArea  = this.catArray[i];
			var items:RODragItem [] = cat.getAllSnapedItems();
			if (items.length == 1)
			{
				cat.correctState = _showScoring ? 0 : -1;
			} else if (items.length > 1 )
			{
				var c:number;
				if(this.isCategoryCorrect(cat, items))
				{
					numberOfCorrect++;
					c = 2;
				} else {
					if (this.hasDummyItem(items))
					{
						numberOfDummy++;
					}
					c = 0;
				}
				cat.correctState = _showScoring ? c : -1;
			}
		}
		var correctItemCount:number = numberOfCorrect;
		
		var allCorrect:boolean = (numberOfCorrect == totalCount);
		if (total)
		{
			if (allCorrect && numberOfDummy == 0)
			{
				this.resultObject = { correct: 2 };
			} else if (correctItemCount)
			{
				this.resultObject = { correct: 1 };
			} else {
				this.resultObject = { correct: 0 };
			}
		} else {
			this.resultObject = { correct: 0 };
		}
		this.updateScore(allCorrect, numberOfCorrect, numberOfDummy);
		if(_showScoring)
			this.sObj.showScore(this.resultObject.correct, this.resultObject.score);
		return this.resultObject;
	}

	private isCategoryCorrect(sameGroup:RODropArea, items:RODragItem []):Boolean
	{
		var correctArray:any [] = this.createGroupListFromNode(items[0]);
		return this.isSameArray(correctArray, items);
	}
	
	
	private isSameArray(array1:any [], array2:any []):boolean
	{
		if (array1.length != array2.length)
		{
			return false;
		}
		var len:number = array1.length;
		for (var i:number = 0; i < len; i++)
		{
			if (array1[i] != array2[i])
			{
				return false;
			}
		}
		return true;
	}

	
	fillStartAndEndPair():void
	{
		var idMap:any = {};
		this.itemArray.forEach((drag:RODragItem)=>{
			idMap[drag.myID] = drag;
		})
		this.itemArray.forEach((drag:RODragItem)=>{
			drag.startPair = idMap[drag.startDragID];
			drag.endPair = idMap[drag.endDragID];
		});
		
	}

}

export class RODragDropF extends RODragDropBasic
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return [
			"DragDropF" 
		];
	}

	private showNoDefaultAnswer():void
	{
		if(this.catArray.length)
		{
			this.catArray[0].noDefaultAnswer = true;
		}
	}
	private cancelNoDefaultAnswer():void
	{
		if(this.catArray.length)
		{
			this.catArray.forEach((cat:RODropArea)=>{
				cat.noDefaultAnswer = false;
			})
		}
	}

	private _showDefaultAnswer():void
	{
		super.resetForDefaultAnswer();
		this.itemArray.forEach((item:RODragItem)=>{
			if(item.correctGroupID != ""){
				var cgrp:string [] = item.correctGroupID.split(",");
				cgrp.forEach((groupID:string)=>{
					var c:RODragItem = this.cloneDragItemIfNecessary(item);
					var da:RODropArea = this.findCat2(groupID);
					if (da) {
						da.autoAppend(c);
					}
					// for j loop
				});
			} // if item got answer 
		});// for i loop
	}
	
	
	public showDefaultAnswer(): void 
	{
		super.showDefaultAnswer();
		var options:any = this.node.attributes;
		if (options.dragReusable)
		{
			this._showDefaultAnswer();
		} else {
			this.showNoDefaultAnswer();
		}
	}

	public hideDefaultAnswer(): void 
	{
		super.hideDefaultAnswer();
		this.cancelNoDefaultAnswer();
	}


	protected buildChildren(): void {
		super.buildChildren();
		this.fixDragDropF();
	}
	
	fixDragDropF():void
	{
		var tag:string = this.node.tag;
		var options:any = this.node.attributes;
	
		options.reusable = options.dragReusable;
		var snapType = !options.reusable;
		this.catArray.forEach((child:RODropArea)=>{
			child.node.attributes.snapType = snapType;
		});
	}
	// override protected getScoreCount():int
	protected getScoreCount(): number
	{
		var options:any = this.node.attributes;
		var key:string;
		var i:number;
		var di:RODragItem;
		var count:number = 0;
		var len:number = this.itemArray.length;
		if (options.dragReusable)
		{
			for (i = 0; i < len; i++)
			{
				di = this.itemArray[i];
				di.isCopy
				if (di.correctGroupID && di.copyFrom == null)
				{
					count += di.correctGroupID.split(",").length;
				}
			}
		} else {
			var group:any = {}
			for (i = 0; i < len; i++)
			{
				di = this.itemArray[i];
				if (di.correctGroupID && di.copyFrom == null)
				{
					var groupList:string [] = di.correctGroupID.split(",");
					for (key in groupList)
					{
						var groupName:string = groupList[key];
						group[groupName] = 1;
					}
					// 
				}
			}
			for (key in group)
			{
				count ++;
			}
		}
		return count;
		
	}
	
	public verify(_showScoring: boolean) 
	{
		var item:RODragItem;
		var j:number;
		var jLen:number;
		var items:RODragItem [];
		var cat:RODropArea;
		var i:number;
		var totalCount:number = this.getScoreCount();
		
		var numberOfCorrect:number = 0;
		var numberOfIncorrect:number = 0;
		var numberOfDummy:number = 0;
		var len:number = this.catArray.length;
		var total:number = len;
		
		if (this.node.attributes.reusable)
		{
			for (i = 0; i < len; i++)
			{
				cat = this.catArray[i];
				items = cat.getAllSnapedItems();
				 var modelAnswer:RODragItem [] = this.getDropAreaAnswer(cat);
				 //var modelAnswer:Array = [];
				if (items && items.length)
				{
					jLen = items.length;
					var good:number = 0;
					var bad:number = 0;
					for (j = 0; j < jLen; j++)
					{
						item = items[j];
						if (item.checkAnswer()) // if correct
						{
							good++;
						} else {
							bad++;
						}
					}
					if (_showScoring)
					{
						
						if (good >= modelAnswer.length && bad == 0)
						{
							cat.correctState = 2;
						} else if (good > 0)
						{
							cat.correctState = 1;
						} else {
							cat.correctState = 0;
						}
					}
					numberOfCorrect += good;
					numberOfIncorrect += bad;
				}
			}
		} else {
			for (i = 0; i < len; i++)
			{
				cat = this.catArray[i];
				items = cat.getAllSnapedItems();
				if (items && items.length)
				{
					item = items[0];
					var showScoreComponent:any = this.findShowScoreComponent(cat, item);
					if (item.checkAnswer()) // if correct
					{
						numberOfCorrect++;
						showScoreComponent.correctState = _showScoring ? 2 : -1;
					} else {
						numberOfIncorrect++;
						showScoreComponent.correctState = _showScoring ? 0 : -1;
					}
				}
			}
		}
		numberOfDummy = numberOfIncorrect;
		
		var allCorrect:boolean = (numberOfCorrect == totalCount);
		if (total)
		{
			if (allCorrect && numberOfDummy == 0)
			{
				this.resultObject = { correct: 2 };
			} else if (numberOfCorrect)
			{
				this.resultObject = { correct: 1 };
			} else {
				this.resultObject = { correct: 0 };
			}
		} else {
			// not sure
			this.resultObject = { correct: 0 };
		}
		
		this.updateScore(allCorrect, numberOfCorrect, numberOfDummy);
		if(_showScoring)
			this.sObj.showScore(this.resultObject.correct, this.resultObject.score);
		return this.resultObject;
	}

	protected canAssignTo(da:RODropArea, di:RODragItem):boolean
	{
		if (da)
		{
			var items:RODragItem[] = da.getAllSnapedItems();
			if (!this.hasDragItemIn(di, items))
			{
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	hasDragItemIn(item: RODragItem, list: RODragItem[]) :boolean
	{
		var len:number = list.length;
		for (var i:number = 0; i < len; i++)
		{
			var l:RODragItem = list[i];
			if (this.getItemSourceId(l) == this.getItemSourceId(item))
			{
				return true;
			}
		}
		return false;
	}

	protected getItemSourceId(item:RODragItem):string
	{
		if (item.copyFrom)
		{
			return this.getItemSourceId(item.copyFrom);
		}
		return item.myID.toString();
	}
	
}

@Component({
	template:`
	<div class="">1
	</div>
	`
	/*
	template:`
		<div class="copy">{{node.tag}} {{clonedDragItems.length}}</div>
	`,
	styles:[
		`
		.copy{
			position:absolute;
			left:-10px;
			top:-20px;
		}
		`
	]
	*/
})

export class RODragDropCount extends RODragDropBasic
{
	public reference:any;
	public showTotal:boolean;
	public totalLabel:ROLabel;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	protected initContext():void
	{
		var base64Context:string = this.node.attributes.context
		this.reference = Base64ObjectUtils.base64Decode(base64Context);
		this.showTotal = this.reference.da.total.show;
	}

	protected buildChildren(): void {
		super.buildChildren();
		this.children.forEach((instance:any)=>{
			if(instance instanceof ROLabel)
			{
				this.totalLabel = instance;
			}
		});
		
		this.totalLabel.text = "0";
		this.totalLabel.visible = this.showTotal;
	}

	public showCurrentValue(com:RODropArea, size:number):void
	{
		/*
		var value:number = this.reference.value;// com.getContext("value");
		var r:DropValueResult = DropValueResult(com.reference.result)
		r.value = value;
		r.render(this.context.navigate("unit"), size);
		com.addChild(r);
		*/
	}

	private updateDropAreaValue():void
	{
		var size:number = this.reference.da.total.size;
		this.catArray.forEach((cat:RODropArea)=>{
			this.showCurrentValue(cat, size);
		});
	}

	public getTagNames():string []
	{
		return [
			"DragDropCount"
		];
	}
	public set data(value: string) {
		
		
	}
	public get data(): string {
		return null;
	}
}


@Component({

	template:`
		
		<div class="drop-area-inner-container" 
			*ngFor="let box of dropAreaBoxArray" 
			[class.left]="box.left" [class.right]="box.right"
			[class.top]="box.top" [class.bottom]="box.bottom"
			>
			<span class="label">{{box.label}}</span>
		</div>
		<ro-verify-icon class="verify-icon" *ngIf="correctState != -1" [correctState]="correctState"></ro-verify-icon>
		<!-- <div class="info">{{myID}}</div> -->
		<div class="no-default-answer" *ngIf="noDefaultAnswer">{{"ro.page.no_default_answer"|translate}}</div>
	`,
	
	styles:[`
		:host{
			display: grid;
		}
		
		.no-default-answer{
			line-height:40px;
			text-align:center;
			font-size:15;
			width:120px;
			height:40px;
			left:50%;
			top:50%;
			transform:translate(-50%, -50%);
			background-color:#543922;
			color:white;
			position:absolute;
			border-radius:10px;
		}

		.info{
			position:absolute;
			left:-10px;
			top:-10px;
		}
		.verify-icon{
			position:absolute;
			right:0px;
			z-index:var(--verify-z-index);
		}
		.drop-area-inner-container{
			color:white;
			text-align:center;
			line-height:var(--box-size-h);
			border-width:var(--drop-area-border-width);
			border-color:var(--drop-area-border-color);
			background-color:var(--drop-area-bg-color);
			border-style: var(--drop-area-border-style);
			border-left-style:none;
			border-top-style: none;
		}
		.left{
			border-left-style:var(--drop-area-border-style);
		}
		.top{
			border-top-style:var(--drop-area-border-style);
		}
		.top.left{
			border-top-left-radius:10px;
		}
		.top.right{
			border-top-right-radius:10px;
		}
		.bottom.left{
			border-bottom-left-radius:10px;
		}
		.bottom.right{
			border-bottom-right-radius:10px;
		}
		
	`]
	/*
	[style.left.px]="box.position.x"
	[style.top.px]="box.position.y"
	[style.width.px]="box.position.w"
	[style.height.px]="box.position.h"
	position:absolute;
	border-style:solid;
	*/
})

export class RODropArea extends ROComponent
{
	
	
	public dragDropOptions:any;
	public dropAreaOptions: any;
	public cellWidth: number;
	public cellHeight: number;
	public correctState: any;
	public noDefaultAnswer: boolean = false;
	
	public point:any;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	private autoPoint:any = {};
	autoAppend(dragItem: RODragItem) {
		var options:any = this.node.attributes;
		if(options.gridX == 1 && options.gridY ==  1)
		{	
			if(this.snappedObjects.length == 0)
			{
				dragItem.moveTo(this.x, this.y);
				this.autoPoint = {
					lineCount:1,
					x:dragItem.w,
					y:0,
					lineHeight:dragItem.h,
					count:1
				};
			} else {
				var r:number = dragItem.w + this.autoPoint.x;
				if(r <= this.w)
				{
					// same line
					dragItem.moveTo(this.x + this.autoPoint.x, this.y + this.autoPoint.y);
					this.autoPoint.x += dragItem.w;
					if(dragItem.h > this.autoPoint.lineHeight ) this.autoPoint.lineHeight = dragItem.h;
					
				} else {
					// next line
					this.autoPoint.y += this.autoPoint.lineHeight;
					dragItem.moveTo(this.x , this.y + this.autoPoint.y);
					this.autoPoint.x = dragItem.w;
					this.autoPoint.lineCount ++;
					this.autoPoint.lineHeight = dragItem.h;
				}
				this.autoPoint.count++;
			}
		} else {
			var box = this.dropAreaBoxArray[this.snappedObjects.length];
			dragItem.moveTo(this.x + box.position.left, this.y + box.position.top);
		}
		dragItem.assignTo(this, false);
	}

	public currentAnswer:string;
	public showDefaultAnswer(): void {
	}

	public hideDefaultAnswer(): void {
		
	}

	public get myID():any
	{
		return this.node.attributes.myID;
	}
	getAllSnapedItems(): any[] {
		var sortedSnappedObjects = this.snappedObjects.sort((a:any, b:any)=>{
			return a.row != b.row ? a.row - b.row  : a.col - b.col;
		});
		return sortedSnappedObjects.map((element)=>{
			return element.item;
		});
	}
	
	public initDragDrop(dragDrop:RODragDropBasic):void
	{
		this.dragDropOptions = dragDrop.node.attributes;
		this.render();
	}

	public getTagNames():string []
	{
		return ["dropArea2"];
	}
	protected buildContent():void
	{
		this.dropAreaOptions = this.node.attributes;
	}
	public dropAreaBoxArray:any [] = [];
	
	protected render():void
	{
		/*
		// drop area
		{
			"ver":1.2,
			"x":0,"y":0,"w":235,"h":121,
			"gridX":2,"gridY":1,
			"myID":"A",
			"snapType":false,"snapGrid":false,
			"douid":"39AE7DC4-A8A9-FE3A-659C-1D40CB8E8CF0",
			"coordinateExpression":"UA UK X 0 Y 0 D T 0 L 0 H 121 W 235"
		}

		// drag drop
		{
			"ver":1.3,
			"inorder":true,
			"baseColor":"#f5edda",
			"borderColor":"#b89160",
			"borderWidth":1,
			"avisible":true,
			"toGroup":false,
			"lineType":"line",
			"isSnapping":false,
			"locked":false,"coordinateExpression":"UA UK X 20 Y 23 D T 23 L 20 H 267 W 528",
			"hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":0.5,"fullScore":1,
			"q":"{\"ballSize\":36,\"color\":\"noColor\",\"freezed\":1,\"show\":0,\"level\":0}","s":"{\"y\":0,\"optional\":false,\"offset\":null,\"enable\":1,\"reference\":\"rt\",\"freezed\":1,\"show\":1,\"x\":528}",
			"isQuestionComponent":true,"questionIndex":0,"qInfo":"{\"root\":0,\"level\":0,\"id\":2,\"rootIndex\":0,\"index\":0,\"order\":0,\"pid\":0}",
			"douid":"D49EBA28-0582-A9DF-FBAB-48582A75EBA2","w":528,"h":267
		}
		*/ 
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.gridTemplateColumns = new Array(this.dropAreaOptions.gridX).fill("auto").join(" ");
  		
		this.dropAreaBoxArray = [];
		this.cellWidth = this.dropAreaOptions.w / this.dropAreaOptions.gridX;
		this.cellHeight = this.dropAreaOptions.h / this.dropAreaOptions.gridY;
		StyleUtils.setStyleVariabe(dom, "box-size-w", this.cellWidth+"px");
		StyleUtils.setStyleVariabe(dom, "box-size-h", this.cellHeight+"px");
		var singleGrid:boolean = this.dropAreaOptions.gridX == 1 && this.dropAreaOptions.gridX == 1;
		var labelNumber = 1;
		var py:number = 0;
		for(var j = 0; j < this.dropAreaOptions.gridY;j++)
		{
			var px:number = 0;
			for(var i = 0; i < this.dropAreaOptions.gridX;i++)
			{
				this.dropAreaBoxArray.push({
					col:i, row:j,
					top:(j == 0), left:(i == 0),
					bottom:(j == this.dropAreaOptions.gridY-1), right:(i == this.dropAreaOptions.gridX-1),
					label: singleGrid ? "": labelNumber,
					position:{
						left:px, top:py, 
						right:px + this.cellWidth, bottom:py + this.cellHeight,
						w:this.cellWidth, h:this.cellHeight
					}
				});
				labelNumber++;
				px += this.cellWidth;
			}
			py += this.cellHeight;
		}
	}
	public snappedObjects:any [] = [];
	removeSnapObject(dragItem:RODragItem):void
	{
		this.snappedObjects = this.snappedObjects.filter((snappedObject:any)=>{
			return snappedObject.item !== dragItem;
		});
	}

	

	addSnapObject(dragItem:RODragItem, tween:boolean):boolean
	{
		this.removeSnapObject(dragItem);
		var dropAreaOptions:any = this.node.attributes;
		var gridX:number = dropAreaOptions.gridX;
		var gridY:number = dropAreaOptions.gridY;
		var snapGrid:boolean = dropAreaOptions.snapGrid;
		var snapType:boolean = dropAreaOptions.snapType;


		if(gridX == 1 && gridY == 1)
		{
			if (snapGrid)
			{
				// scale dragItem to drop area width and height
			}
			if(snapType)
			{
				var px:number = (this.w - dragItem.w )/2
				var py:number = (this.h - dragItem.h )/2
				dragItem.moveTo(this.x + px, this.y + py);
				
			} else {
				// do nothing;
				// dragItem.moveTo(this.x, this.y);
			}
			this.replaceSnappedObject(snapType, dragItem, 0, 0, tween);
			return true;
		}
		// ###############################################3
		// grid style
		// more than 1 drag item drop area
		snapType = true;
		var offsetX:number = (this.cellWidth - dragItem.w )/ 2;
		var offsetY:number = (this.cellHeight - dragItem.h )/ 2;
		var a:number = dragItem.dragX + dragItem.w / 2 - this.x;
		var b:number = dragItem.dragY + dragItem.h / 2 - this.y;
		for(var i = 0; i < this.dropAreaBoxArray.length;i++)
		{
			var box:any = this.dropAreaBoxArray[i];
			var position:any = box.position;
			if(
				(position.left <= a && a <= position.right) && 
				(position.top <= b  && b <= position.bottom)
			)
			{
				if(snapGrid)
				{
					dragItem.moveTo(
						position.left + this.x + offsetX,
						position.top + this.y + offsetY
					) ;
				} else {
					dragItem.moveTo(
						position.left + this.x ,
						position.top + this.y 
					) ;
				}
				this.replaceSnappedObject(snapType, dragItem, box.col, box.row, tween);
				return true;
			}
		}

		return false;
		
	}

	replaceSnappedObject(snapType:boolean, item: RODragItem, col: number, row: number, tween:boolean):void
	{
		if(snapType)
		{
			this.snappedObjects.forEach((snappedObject:any)=>{
				if(snappedObject.item) {
					if(col == snappedObject.col && row == snappedObject.row)
					{
						var tmp:RODragItem = snappedObject.item;
						tmp.assignTo(null, tween);
					}
				}
			});
		}
		this.snappedObjects.push({item:item, col:col, row:row});
	}
	
	/*
	removeSnapObject(dragItem:any):void
	{
		if (this.deepRemovedFromArray(obj, snappedObjectArray))
		{
			return ;
		}
		if (!idMC2)
		{
			idMC2 = new Sprite();
		}
		
		if (snappedObjectArray.length == 0)
		{
			idMC2.visible = false;
		}
	}
	addSnapObject(dragItem:any):void
	{
		//#########################33
		// student mode or present mode
		
		var ax:int;
		var ay:int;
		var j:int;
		var i:int;
		
		var rect:Rectangle = obj.getLocalBounds();
		
		// ##################################
		// only 1 group 
		if (gridX == 1 && gridY == 1)
		{
			
			// if drag item will be scaled
			if (snapGrid)
			{
				
				var widthRatio:Number = rect.width / this.w;
				var heightRatio:Number = rect.height / this.h;
				
				if (widthRatio > heightRatio)
				{
					
					obj.width = this.w;
					obj.height = obj.height / widthRatio;
				}
				else
				{
					obj.height = this.h;
					obj.width = obj.width / heightRatio;
				}
			}
			
			if (snapType)
			{
				
				ax = this.x + (this.width - rect.width) / 2;
				ay = this.y + (this.height - rect.height) / 2;
				// Tweener.addTween(obj, {x: ax, y: ay, time: 0.2, transition: "easeOutQuart"});
				ObjectUtils.copyAttributes({x: ax, y: ay}, obj);
			}
			
			if (totalSnapObject[0][0] != null)
			{
				
				if (parent is OKDDragDropBasic)
				{
					// get parent // only accept 1 to 1
					if (snapType)
					{
						
						// remove if snapped
						var gonnaRemovedCopy:DragItem2 = totalSnapObject[0][0][0];
						
						if (gonnaRemovedCopy)
						{
							if (gonnaRemovedCopy.copyFrom)
							{
								gonnaRemovedCopy.assignAnswer();
								ddClass.removeAnswer(gonnaRemovedCopy);
								//(parent as OKDDragDropBasic).removeComponent(gonnaRemovedCopy); // ask original to remove a copied child
							
							}
							else
							{
								gonnaRemovedCopy.resetPosition();
								gonnaRemovedCopy.assignAnswer();
							
							}
						}
						totalSnapObject[0][0] = [];
					}
				}
				
			}
			else
			{
				totalSnapObject[0][0] = [];
			}
			
			totalSnapObject[0][0].push(obj);
			
			setTimeout(onSnapItem, 0);
			return true;
			
		}
		
		// ###############################################3
		// grid style
		// more than 1 drag item drop area
		var a:int = obj.x + rect.width / 2;
		var b:int = obj.y + rect.height / 2;
		//obj.drawPoint(a, b);
		for (i = 0; i < gridY; i++)
		{
			for (j = 0; j < gridX; j++)
			{
				
				ax = this.x + j * cellWidth; // + ((cellWidth - rect.width) / 2);
				ay = this.y + i * cellHeight; // + ((cellHeight - rect.height) / 2);
				
				if (a >= ax && a <= ax + cellWidth && b >= ay && b <= ay + cellHeight)
				{
					if (snapGrid)
					{
						widthRatio = rect.width / cellWidth;
						heightRatio = rect.height / cellHeight;
						if (widthRatio > heightRatio)
						{
							obj.width = cellWidth;
							obj.height = obj.height / widthRatio;
						}
						else
						{
							obj.height = cellHeight;
							obj.width = obj.width / heightRatio;
						}
						ax = this.x + j * cellWidth + ((cellWidth - obj.width) / 2);
						ay = this.y + i * cellHeight + ((cellHeight - obj.height) / 2);
					}
					
					if (totalSnapObject[i][j])
					{
						//if (parent is OKDDragDropBasic && (parent as OKDDragDropBasic).reusable)
						if (ddClass && ddClass.isReusable())
						{
							gonnaRemovedCopy = totalSnapObject[i][j];
							
							if (gonnaRemovedCopy.copyFrom)
							{
								gonnaRemovedCopy.assignAnswer();
								
								ddClass.removeComponent(gonnaRemovedCopy);
								//(parent as OKDDragDropBasic).removeComponent(gonnaRemovedCopy);
								
							}
							else
							{
								totalSnapObject[i][j].resetPosition();
								totalSnapObject[i][j].assignAnswer();
								
							}
							
						}
						else
						{
							// not reusable
							totalSnapObject[i][j].resetPosition();
							totalSnapObject[i][j].assignAnswer();
							
						}
						
					}
					if (toTween)
					{
						Tweener.addTween(obj, {x: ax, y: ay, time: 0.2, transition: "easeOutQuart"});
					} else 
					{
						obj.x = ax;
						obj.y = ay;
					}
					
					totalSnapObject[i][j] = obj;
					return true;
					
				} // if drag item hit within a grid of the drop area
				
			} //for j
			
		} // for i
		
		return false;
	
	}
	*/

	
	// =======================================
	// edit relative function
	// =======================================
	public updatePoint(dragDrop:RODragDropBasic):void {
		this.point ={
			x:this.x+this.w/2,
			y:this.y+this.h/2,
			color:LINE_COLORS[this.myID.charCodeAt(0)-65]
		};
	}

	public setPosition(px:number, py:number):void
	{
		super.setPosition(px, py);
		this.updatePoint(<RODragDropBasic>this.parent);
	}

	public getSelectorSettings():any {
		return {
			popupSelector:this.getPopupSelector(),
			rotation:false,lockedAspectRatio:false,widthResizer:false,heightResizer:false,resizer:true,move:true
		};
	}

	public resize(width:number, height:number):any
	{
		this.w = width;
		this.h = height;
		this.assignLTWH();
		this.updatePoint(<RODragDropBasic>this.parent);
		return null;
	}
}


////
@Component({
	template:`
		<div class="empty-container" *ngIf="empty">?</div>
		<!-- <div class="info">{{startDragID}}-{{myID}}-{{endDragID}}</div> -->
		<ro-verify-icon class="verify-icon" *ngIf="correctState != -1" [correctState]="correctState"></ro-verify-icon>
	`,
	styles:[
		`
		.info{
			position:absolute;
			left:0px;
			top:-10px;
			font-size:10px;
			line-height:10px;
		}
		:host{
			transform:translate(0px, 0px);
		}

		:host > *{
			z-index:var(--dragging-item-z-index);
			position:relative;
		}
		
		:host.dragging{
			transform:translate(0px, -10px);
			filter: drop-shadow(10px 0px 40px #0000004d);
		}
		.empty-container{
			font-size:26px;
			color:#8B6032;
			border:dashed 1px #74400D;
			width:100px;
			height:100px;
			text-align:center;
			line-height:100px;
			background-color:transparent;
			margin:auto;
		}
		:host.dragging{
			z-index:var(--dragging-item-z-index);
		}
		.verify-icon{
			position:absolute;
			right:0px;
			z-index:var(--verify-z-index);
		}
		
		`
		/*
		:host{
			background-color:#00FF0050;
		}
		*/
	]
})

export class RODragItem extends ROContainerComponent implements OnDestroy
{
	public empty:boolean;
	public options:any;
	public moved:boolean;
	public dom:HTMLElement;
	public dragX:number;
	public dragY:number;
	public currentDropAreaTarget:RODropArea;
	public isCopy:boolean = false;
	public copyFrom:RODragItem = null;
	public startPair: RODragItem;
	public endPair: RODragItem;
	public order:number = 0;
	public emitter:EventEmitter<any> = new EventEmitter();
	
	public correctState: number;
	public point:any;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.dom = elementRef.nativeElement;
		this.canEditInside = true;
	}

	public get myID():any
	{
		return this.node.attributes.myID;
	}

	public get startDragID():any
	{
		return this.node.attributes.startDragID;
	}

	public get endDragID():any
	{
		return this.node.attributes.endDragID;
	}
	
	public get correctGroupID():any{
		return this.node.attributes.correctGroupID;
	}
	
	public set correctGroupID(val:any){
		this.node.setAttribute("correctGroupID", val);
		this.updatePoint(<RODragDropBasic>this.parent);
	}

	public destroy():void
	{
		this.emitter.next({type:"remove", view:this});
		this.componentRef.destroy();
	}

	ngOnDestroy(): void {
		// if(this.dom.parentElement) this.dom.parentElement.removeChild(this.dom);
	}

	clone(): RODragItem {
		var clone = this.context.createForDOM(this.dom.parentElement, this.node, this.page, this.parent)
		clone.copyFrom = this;
		clone.isCopy = true;
		return clone;
	}

	public assignTo(dropArea:RODropArea, tween:boolean):boolean
	{
		// transition: transform 0.2s;
		this.dom.style.transition = tween ? "left 0.2s, top 0.2s, transform 0.2s" : "";
		if(this.currentDropAreaTarget) this.currentDropAreaTarget.removeSnapObject(this);
		this.currentDropAreaTarget = dropArea;
		if(dropArea && dropArea.addSnapObject(this, tween))
		{
			return true;
		} else {
			var flag:boolean = this.restore();
			if(this.isCopy)
			{
				if(tween)
				{
					PromiseUtils.delay(200, null).then(()=>{
						this.destroy();
					});
				} else {
					this.destroy();
				}
			}
			return flag;
		}
	}
	
	public moveTo(px:number, py:number):void
	{
		this.dragX = px;
		this.dragY=  py;
		this.dom.style.left = px+"px";
		this.dom.style.top = py+"px";
	}
	public  floatUp():void
	{
		this.dom.style.transition = "";
		this.dom.classList.add("dragging");
	}
	public floatDown():void 
	{
		this.dom.classList.remove("dragging");
	}
	
	public restore():boolean
	{
		this.dom.style.left = this.x +"px";
		this.dom.style.top = this.y +"px";
		if(this.isCopy)
		{
			return false;
		} else {
			return true;
		}
	}

	public getTagNames():string []
	{
		return ["dragItem2", "dragItem3", "DragItemCount"];
	}

	protected initCoordinateExpression():void
	{
		super.initCoordinateExpression();
		this.elementRef.nativeElement.classList.add('drag');
	}

	public initDragDrop(dragDrop:RODragDropBasic):void
	{
		this.options = dragDrop.node.attributes;
	}

	protected buildChildren():void
	{
		// <!--{"ver":1.2,"x":13,"y":173.5,"w":100,"h":100,"correctGroupID":"A","douid":"466D2685-0F9F-DF94-3B6D-AB97AF47FF2C","myID":"A","boundUpdated":true,"locked":false}-->
		this.empty = this.node.children.length == 0;
		if(this.node.children.length)
		{
			super.buildChildren();
		} else {
			this.children = [];
		}
	}
	// public correctState:any = -1;
	checkAnswer(): boolean {
		var correctGroupID:string = this.correctGroupID;
		if (correctGroupID == "" && this.currentDropAreaTarget == null) {
			// this.correctState = -1;
			return true;
		} else if (this.currentDropAreaTarget == null || correctGroupID == "") {
			// this.correctState = -1;
			return false
		}
		var myID:string = this.currentDropAreaTarget.node.attributes.myID

		var t:string [] = correctGroupID.split(",");
		for (var i:number = 0; i < t.length; i++) {
			if (myID == t[i]) {
				// this.correctState = 2;
				return true;
			}
		}
		// this.correctState = 0;
		return false;
	}

	// =======================================
	// edit relative function
	// =======================================
	public updatePoint(dragDrop:RODragDropBasic):void {
		var ary:any[] = dragDrop ? dragDrop.getDragItemAnswer(this) : [];
		var colors:any[];
		if(ary.length>0) {
			colors = ary.map((e,index)=>{
				return LINE_COLORS[e.myID.charCodeAt(0)-65]+" "+(index*100/ary.length)+"% "+((index+1)*100/ary.length)+"%";
			});
		}

		this.point ={
			x:this.x+this.w/2,
			y:this.y,
			color:this.context.service.domSanitizer.bypassSecurityTrustStyle(ary.length>0 ? "conic-gradient("+colors.join(",")+")": "#ffffff")
		};
	}

	public setPosition(px:number, py:number):void
	{
		super.setPosition(px, py);
		this.updatePoint(<RODragDropBasic>this.parent);
	}

	/*protected editInStageFinish():void
	{
		this.updateBoundaryAndChildPositrion();
		this.updatePoint(<RODragDropBasic>this.parent);
	}*/

	public addComponentByTag(componentTag:string, assetSrc:string = null):ROComponent {
		var com:ROComponent = super.addComponentByTag(componentTag, assetSrc);
		this.empty = this.node.children.length == 0;
		return com;
	}

	public removeComponent(com:ROComponent):void {
		super.removeComponent(com);
		this.empty = this.node.children.length == 0;
	}

	protected updateBoundaryAndChildPosition():void
	{
		this.empty = this.node.children.length == 0;
		if(this.empty) {
			// 計算移動偏移值
			var offsetx:number = -(this.w - 100)/2;
			var offsety:number = 0;
	
			// 更新題目佔據範圍
			this.w = 100;
			this.h = 100;
			this.moveBy( -offsetx, -offsety);
			this.savePosition();
			this.assignLTWH();

		} else {
			super.updateBoundaryAndChildPosition();
		}

		this.updatePoint(<RODragDropBasic>this.parent);
	}

}

class DOMLookup
{
	public static findClassnameElement(stopDOM:HTMLElement, dom:HTMLElement, className:string):HTMLElement
	{
		while(dom && stopDOM !== dom)
		{
			if(dom.classList.contains(className))
			{
				return dom;
			}
			dom = dom.parentElement;
		}
		return null;
	}
	
}


/*
class DragManager2{

	private sub:Subscription;
	public panEnable:boolean = true;
	public tranformInfo:any;
	public enabled:boolean = true;

	public touch:HTMLElement;
	public targetElement:HTMLElement;
	public dragBoundary = null;
	
	public panOnlyForOversize:boolean = false;

	constructor()
	{
	}
	public swithcTarget(newTarget:HTMLElement):void
	{	
		this.targetElement = newTarget;
	}
	
	public destroy():void
	{
		this.transform.unsubscribe();
		this.transformEnd.unsubscribe();
	}

	setTransform(x:number,y:number, scale:number):void
	{
		this.targetElement.style.transform = `translate3d(${x}px, ${y}px, 0px) scale(${scale})`;
	}
	
	public dragStart(dom:HTMLElement, event:any):Observable<any>
	{
		this.targetElement = dom;
		var startPoint:any = {x:event.x, y:event.y};
		
		this.sub = new Subscription(()=>{
		});
		var originalTransform:any = DOMHelper.getElementTransform3D(this.targetElement);
		
		var globalScale:number = DOMHelper.getElementScale(this.targetElement.parentElement);
		// var scaleArray:any [] = DOMHelper.getElementScale2(this.targetElement.parentElement);
		// this.sendInfo(target, "gScale", scaleArray);
		var panStartPosition:any;
		var isTransforming:boolean
		if(this.panEnable)
		{
			this.sub.add(
				// fromEvent(window, "panstart").subscribe((evt:any)=>{
				fromEvent(this.touch, "panstart").subscribe((evt:any)=>{
					// this.sendInfo(evt.target, "type", "panstart");
					isTransforming = true;
					// console.log(evt);
					// this.onPanStart(evt);
					this.blockEvent(evt);
					var detail:any = this.getEventDetail(evt);
					panStartPosition = {
						x:detail.center.x - detail.deltaX ,
						y:detail.center.y - detail.deltaY
					}
				})
			);
			this.sub.add(
				fromEvent(window, "panmove").subscribe((evt:any)=>{
					if(isTransforming)
					{
						// this.sendInfo(evt.target, "type", "panmove");
						this.blockEvent(evt);
						
						var detail:any = this.getEventDetail(evt);
						var px:number = (detail.center.x - panStartPosition.x) / globalScale + originalTransform.x;
						var py:number = (detail.center.y - panStartPosition.y) / globalScale + originalTransform.y;
						var tmpTransform:any = {
							x:px,
							y:py,
							scale:originalTransform.scale
						}
						
						
						this.transform.next(tmpTransform);
						this.targetElement.style.transform = `translate3d(${tmpTransform.x}px, ${tmpTransform.y}px, 0px) scale(${tmpTransform.scale})`;
						// this.sendInfo(evt.target, "move", tmpTransform);
					}
				})
			);
			this.sub.add(
				fromEvent(window, "panend").subscribe((evt:any)=>{
					if(isTransforming)
					{
						// this.sendInfo(event.target, "type", "panend");
						this.transformEnd.next({
							transform:DOMHelper.getElementTransform3D(this.targetElement),
							evt:evt
						});
					}
					this.release();
				})
			);
			this.sub.add(
				fromEvent(window, "pancancel").subscribe((evt:any)=>{
					this.release();
				})
			);
		}
		var pinchStartInfo:any;
		if(this.pinZoomEnable)
		{
			this.sub.add(fromEvent(this.touch, "pinchstart").subscribe((evt:any)=>{
				// this.sendInfo(evt.target, "type", "pinchstart");
				isTransforming = true;
				// console.log(evt);
				pinchStartInfo = {
					x:evt.gesture.center.x,
					y:evt.gesture.center.y
				}
				this.blockEvent(evt);
			}));
			this.sub.add(fromEvent(window, "pinchmove").subscribe((evt:any)=>{
				if(isTransforming)
				{
					// this.sendInfo(evt.target, "type", "pinchmove");
					var detail:any = this.getEventDetail(evt);
					// console.log("scale", detail.scale , "dx",  detail.deltaX, "dy", detail.deltaY);
					var px:number = originalTransform.x + detail.deltaX;
					var py:number = originalTransform.y + detail.deltaY;
					var tmpScale:number = originalTransform.scale * detail.scale;
					var tmpTransform:any = {
						x:px,y:py,scale:tmpScale
					};
					
					this.transform.next(tmpTransform);
					this.targetElement.style.transform = `translate3d(${tmpTransform.x}px, ${tmpTransform.y}px, 0px) scale(${tmpTransform.scale})`;
				}
			}));
			this.sub.add(fromEvent(window, "pinchend").subscribe((evt:any)=>{
				// console.log(evt);
				if(isTransforming)
				{
					// this.sendInfo(evt.target, "type", "pinchend");
					this.transformEnd.next({
						transform:DOMHelper.getElementTransform3D(this.targetElement),
						evt:evt
					});
				}
				this.release();
			}));
		}
	}

	private release():void
	{
		if(this.sub)
		{
			this.sub.unsubscribe();
			this.sub = null;
		}
	}

	
	public onPanStart(evt:any):void{
		this.blockEvent(evt);
		var detail:any = this.getEventDetail(evt);
		this.tranformInfo = null;
		if(!this.enabled) return;
		this.tranformInfo = {
			transform:this.getElementTransformInfo(this.targetElement),
			scale:DOMHelper.getElementScale(this.targetElement.parentElement),
			start:{
				x:detail.center.x - detail.deltaX,
				y:detail.center.y - detail.deltaY
			}
		};
	}
	// @HostListener('window:panmove', ['$event'])
	public onPanMove(evt:any):void
	{
		console.log("directive.panmove", evt);
		if(!this.tranformInfo) return;
		if(!this.enabled) return;
		this.blockEvent(evt);
		var detail:any = this.getEventDetail(evt);
		// var detail:any = evt;
		// var detail:any = evt.detail;
		this.updateTransform(detail.center.x, detail.center.y, 1);
	}

	// @HostListener('window:panend', ['$event'])
	public onPanEnd(evt:any):void
	{
		if(!this.tranformInfo) return;
		if(!this.enabled) return;
		this.blockEvent(evt);
		// var detail:any = evt;
		var detail:any = this.getEventDetail(evt);
		// var detail:any = evt.detail;
		var info:any = this.updateTransform(detail.center.x, detail.center.y, this.tranformInfo.scale);
		info.width = this.targetElement.clientWidth;
		info.height = this.targetElement.clientHeight;
		// this.hammerTranformEnd.emit(info);
		this.tranformInfo = null;
	}
	private updateTransform(globalX:number, globalY:number, scale:number):any
	{
		var px:number = (globalX - this.tranformInfo.start.x) / this.tranformInfo.scale + this.tranformInfo.transform.x;
		var py:number = (globalY - this.tranformInfo.start.y) / this.tranformInfo.scale + this.tranformInfo.transform.y;
		this.targetElement.style.transform = `translate3d(${px}px, ${py}px, 0px)`;
		return {
			x:px,
			y:py
		};
	}
	private blockEvent(evt:any):void
	{
		evt.stopImmediatePropagation();
		evt.stopPropagation();
		evt.preventDefault();
	}
	private getEventDetail(evt:any):any
	{
		return evt.gesture;
	}
	
	private getElementTransformInfo(el:HTMLElement)
	{
		return DOMHelper.getElementTransform3D(el);
		
	}

}
*/
