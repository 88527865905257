import { Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemeService } from 'src/app/service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'checkbox-icon',
    template: `
    <fa-icon class="boxIcon" 
		[icon]="(selected ? faCheckSquare : faSquare)" 
		[class.checked]="selected" 
		[ngStyle]="(config.faIconStyle?config.faIconStyle:null)"
		[style.color]="(color?color:null)"
		(click)="toggle()"
		></fa-icon>
    `,
    styleUrls: ['./tickBox.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>TickBoxComponent), multi: true, }],
})

export class TickBoxIconComponent  {
    @Input() public faSquare:any = faSquare;
    @Input() public faCheckSquare:any = faCheckSquare;
	@Input() public config:any = {};
	@Input() public color:string = null;
	@Input() public selected:boolean = false;
	@Output() public selectedChanged:EventEmitter<any> = new EventEmitter();
	@Input() public enabled:boolean = true;
	constructor()
	{

	}
	toggle()
	{
		if(this.enabled) {
			this.selected = !this.selected;
			this.selectedChanged.emit(this.selected);
		}
	}
}

@Component({
    selector: 'tickBox',
    template: `
<ng-container *ngIf="isMultiple">
    <span class="checkBox" *ngFor="let option of options" [ngStyle]="(config.style?config.style:null)" (click)="checkBoxOnClick(option)">
        <fa-icon class="boxIcon" 
			[icon]="((selected && selected.includes(option)) ? faCheckSquare : faSquare)" 
			[class.checked]="selected && selected.includes(option)" 
			[ngStyle]="(config.faIconStyle?config.faIconStyle:null)"
			[style.color]="(color?color:null)"></fa-icon>
        <span class="label" [ngStyle]="(config.labelStyle?config.labelStyle:null)">{{ option[bindLabel] || (option[bindLabelKey] | translate) || option}}</span>
    </span>
</ng-container>
<ng-container *ngIf="!isMultiple">
    <span class="checkBox" (click)="checkBoxOnClick()" [ngStyle]="(config.style?config.style:null)">
        <fa-icon class="boxIcon" 
			[icon]="(selected ? faCheckSquare : faSquare)" 
			[class.checked]="selected" 
			[ngStyle]="(config.faIconStyle?config.faIconStyle:null)"
			[style.color]="(color?color:null)"></fa-icon>
        <span class="label" [ngStyle]="(config.labelStyle?config.labelStyle:null)">{{ label || (labelKey | translate)}}</span>
    </span>
</ng-container>
    `,
    styleUrls: ['./tickBox.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>TickBoxComponent), multi: true, }],
})

export class TickBoxComponent implements ControlValueAccessor {
    @Input() public faSquare:any = faSquare;
    @Input() public faCheckSquare:any = faCheckSquare;

    @Input() public isMultiple:boolean = true;
    //For Multiple checkbox
    @Input() public options:any[] = [];
    @Input() public bindLabel:string = 'label';
    @Input() public bindLabelKey:string = 'labelKey';
    @Input() public bindValue:string = 'value';
    //For Single checkbox
    @Input() public label:string = "";
    @Input() public labelKey:string = "";

	@Input() public config:any = {};
    @Input() public color:string = null;
    public selected:any = null;
    public isDisabled:boolean = false;

    constructor(public translate:TranslateService, private eleRef:ElementRef, private themeService:ThemeService) {
        themeService.getThemeJson("tickBoxModule.json").then((styleObj:any)=>{
            themeService.applyStyleObj(styleObj, eleRef.nativeElement);
        });
    }
    //-implements ControlValueAccessor---------------------------------------------------------------
    private onChange = (v: any) => {};
    private onTouched = () => {};

    public registerOnChange(fn: any):void { this.onChange = fn; }
    public registerOnTouched(fn: any): void { this.onTouched = fn; }
    public setDisabledState?(isDisabled: boolean): void { this.isDisabled = isDisabled; }
    
    public writeValue(value:any): void {
        if (this.isMultiple) {
            if (!value) value = [];
            if (this.bindValue) {
                this.selected = value.map((v:any)=>this.options.find((o:any)=>o[this.bindValue]==v));
            } else {
                this.selected = value;
            }
        } else {
            this.selected = value || false;
        }
    }
    //-------------------------------------------------------------------------------------------------
    public checkBoxOnClick(option:any=null):void {
        if (this.isMultiple) {
            this.selected.includes(option) ? this.selected.splice(this.selected.indexOf(option), 1) : this.selected.push(option);
            this.onChange(this.bindValue ? this.selected.map((s:any)=>s[this.bindValue]) : this.selected);
        } else {
            this.selected = !this.selected;
            this.onChange(this.selected);
        }
        this.onTouched();
    }
    //-------------------------------------------------------------------------------------------------

}
