import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from '../loadingModule/loading.service';
import { ThemeService } from 'src/app/service/theme.service';
import { faVolume } from '@fortawesome/pro-solid-svg-icons';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';

@Component({
  selector: 'ai-oral-practice-modal',
  templateUrl: './ai-oral-practice.component.html',
  styleUrls: ['./ai-oral-practice.component.scss']
})
export class AiOralPracticeComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('modal', {static: true}) modal;
	@ViewChild('thread', {static: false}) thread;
	@ViewChild('showGradingBB', {static:false}) showGradingBB: BubbleBox2Component;
	@Input() data: any;
	@Input() fromCourse = true;
	@Output() close = new EventEmitter<any>();
	public audio: HTMLAudioElement = new Audio();
	public score = {
		speakingRate: 5,
		rhythm: 5,
		vocabularyDepth: 6,
		RichnessOfContent: 8,
		creativity: 6,
		gammar: 2,
	}

	public threadData;
	public practiceSetting;
	public status = 'open';
	public ready = false;
	public titleLabelStyle ;
	private bookStructure;
	public lang;
	public maxScore = 0;
	public totalScore = 0;
	public rows = [];
	public displayComment = '';
	public faVolume = faVolume;
	public onDestroy = new Subject();
	get titleLabel():string{
		switch (this.status) {
			case 'finished':
				return '已完成';
			case 'aiChecking':
				return '批改中'
			default:
				return '';
		}
	}

	constructor(private datas: DataService,  private lds: LoadingService,  public theme: ThemeService, public eleRef: ElementRef) { }

	ngOnInit() {
		this.modal.open();
		this.threadData = {
			"status": "opened",
			"thread": {
				"id": "31",
				"created_at": "2021-02-22 13:56:24",
				"updated_at": "2021-07-08 13:31:06",
				"deleted": "0",
				"title": "test",
				"uid": "41344",
				"content": "test",
				"allow_image": "1",
				"allow_voice": "1",
				"post_time": "1625722267000",
				"cname": "Lampp-teacher",
				"ename": "teacher",
				"tag_id": "18862",
				"tag_ids": "18862",
				"tag_name": "1A",
				"author": "1A",
				"unread": 0
			},
			"messages": [
			
			],
			"peoples": [
				{
					"uid": "42",
					"user_role": "2",
					"gender": "2",
					"cname": "黃蘇菲",
					"ename": "wong sophie",
					"color": "#128487",
				},
			],
			"quote": {},
			"readonly": this.data.readonly
		};

		Promise.all([this.getPracticeSetting(), this.getStatus()])
		.then(([practiceSetting, status]) => {
			if(this.status != 'open'){
				this.getScore();
			}
		})
		.catch((error) => {
			// Handle error if any of the promises reject
			console.error(error);
		});
		console.log(this.data)

		this.getBookShare();
		if (this.fromCourse){
			if (location.href.indexOf('/review') > -1){
				this.data.status = 'read-only';
			}
		}
		this.test();
		this.lang = this.datas.lang;
	}

	ngAfterViewInit(): void {

	}

	ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	private test(){

	}

	private async getBookShare(){
		let res = await this.datas.post2({ data: { api: 'ROLearnProgress.getBookShare', json: [this.data.bsid] } });
		if(res){
			try {
				this.bookStructure = JSON.parse(res.structure);
			} catch (error) {
				
			}
		}
		
	}


	private async getScore(){
		const uid = this.datas.userInfo.uid;
		let res = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_oral_scores', json: [this.data.book_id, this.data.bsid, uid] } });
		if(res ){
			this.displayComment = res.teacher_comment || res.ai_comment || {tc:'未有評語',sc:'未有評語', en:'No comment'}[this.datas.lang];
			if(res.teacher_score){
				try {
					const teacherScores = JSON.parse(res.teacher_score);
					this.rows.forEach((row, index)=>{
						row.teacher = teacherScores[index];
						row.ai = res.ai_score ? res.ai_score[index] : null;
					});
				} catch (error) {
					console.log("error", error);
				}
			}
			this.rows.forEach((row,index)=>{
				if(row.teacher !== undefined && row.teacher !== null){
					row.overall = row.teacher
				}else if(row.ai !== undefined && row.ai !== null){
					row.overall = row.ai
				}else{
					row.overall = null;
				}
				this.totalScore += row.overall || 0;
			});
		}
		
	}
	private async getStatus(){
		let res = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_oral_status', json: [this.data.bsid] } });
		if(res && res.status){
			this.status = res.status;
		}
	}

	private async getPracticeSetting(){
		this.practiceSetting = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_ai_oral_practice_setting', json: [this.data.book_id] } });
		try {

			let scoringItems = JSON.parse(this.practiceSetting.scoring_items);
			console.log("scoreItems", scoringItems);
			if(scoringItems && scoringItems.length > 0){
				this.rows = scoringItems.map(item=>{
					let obj = {title: item.title, score: item.score, ai: null, teacher: null };
					this.maxScore += parseFloat(item.score);
					return obj;
				});
				this.threadData.scoringItems = scoringItems.map(e=> { return {title: e.title, score: e.score}; });
			}
		} catch (error) {
			console.log("error", error);
		}
		this.getMessagesHistory(this.practiceSetting.id);
	}

	private async getMessagesHistory(threadId: number){
		var studentId: number = this.datas.userInfo.user_role == "2" ? this.datas.userInfo.uid: 0 ; 
		const messages = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_ai_oral_message', json: [threadId, studentId] } });
		if(messages){
			if(messages.some(((message)=> message.content_type == "comment"))){
				this.status = "finished";
				this.titleLabelStyle = {'background-color':'#7FC42D'};
			}
			this.threadData.messages = messages;
		}
		this.threadData.thread.id = threadId;
		this.ready = true;

	}
	closeModal($event){
		this.modal.close();
		this.close.emit();
	}

	confirmClicked(){
		if (this.fromCourse){
			this.confirmOnCourse();
		} else {
			this.confirmOnBookshelf();
		}
	}

	confirmOnCourse(){
		if (location.href.indexOf('review') > -1){ //read only
			this.modal.close();
			this.close.emit();
			return;
		}
		else{
			this.ready = false;
			this.modal.close();
			this.generateRandomScore();
			this.status = 'showReport';
		}
		//write later
		
	}

	confirmOnBookshelf(){
		if (this.status != 'open'){
			this.close.emit();
			return;
		}
		const bsid = this.data.bsid;
		const bookId = this.data.book_id;
		this.lds.add('save_ai_oral_answer',10000);

		const pageId = this.bookStructure.book.chapters[0].pages[0].id;
		const result = { correction: false, score: 0, correct: -1};
		let records:any = [];

		this.bookStructure.book.chapters[0].pages[0].components.forEach(comp=>{
			const compId = comp.cid;
			const addRecord = { type: 'add', tag: 'ai-oral', doc: bookId, page: pageId, com: compId, answer: '', result: JSON.stringify(result) };
			const submitRecord = { lesson_id: 0, doc: bookId, "page": pageId, mode: "none", ref_id: 0, type: "submit" };
			records.push(addRecord, submitRecord);
		});
		let totalCount = 0;
		if(this.rows && this.rows.length > 0){
			totalCount = this.rows.length;
		}
		this.datas.post('ROBookShare.save', [bsid, records, bookId]).subscribe((res)=>{
			const summary = {score: 0, green_count: 0, red_count: 0, orange_count: 0,blue_count: totalCount, total_count: totalCount, forceNotPass: 1};
			this.datas.post('ROCourse.start_then_end_book_session',[bsid, bookId, summary]).subscribe((res)=>{
				setTimeout(() => {
					this.lds.remove('save_ai_oral_answer');
				}, 20000);
			});
			if (this.data.callback){
				this.data.callback(this.data);
			}
		});

		// blue count = number of criteria = total_count
		
		this.modal.close();
		this.close.emit();
	}

	public playAudio(){
		let url: string =
			"//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";

		let data: any = {
			gender: 'F',
			txt: this.displayComment,
			speed: 1,
			lang: 'zh-HK',
			pitch: 1,
			name: 'zh-HK-HiuGaaiNeural',
		};
		this.datas
			.get2({ url: url, data: data, jwt: false })
			.then((res: any) => {
				if (res.token && res.token.url) {
					let statusUrl: string = res.token.url;
					if (statusUrl.substr(0, 7) == "http://")
						statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
					this.datas
						.get2({ url: statusUrl, jwt: false })
						.then((res: any) => {
							this.audio.setAttribute("src", res.url);
							this.audio.play();
						});
				}
			});
	}

	showGrading($event){
		if (this.showGradingBB && this.showGradingBB.opened){
			this.showGradingBB.close();
			return;
		}
		const ele = $event.target;
		this.showGradingBB.open(ele).catch(e=>{});
	}

	public quitButtonClicked(){
		this.close.emit();
	}

	//temp for demo
	public generateRandomScore(){
		//generate random score from 1-10 for each field of score
		const properties = ['speakingRate', 'rhythm', 'vocabularyDepth', 'richnessOfContent', 'creativity', 'grammar'];
		for (const property of properties) {
		  this.score[property] = Math.floor(Math.random() * 10) + 1;
		}
	}

}