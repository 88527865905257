import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faSearch, faRedo, IconDefinition, faHundredPoints } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight,faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: "search-button",
	templateUrl: "./search-button.component.html",
	styleUrls: ["./search-button.scss"]
})

export class SearchButtonComponent implements OnInit {
	public faSearch: IconDefinition = faSearch;
	public leftArrow: IconDefinition = faChevronLeft;
	public rightArrow: IconDefinition = faChevronRight;
	public faCircleXmark: IconDefinition = faCircleXmark
	@ViewChild('searchBoxInput',{static :false}) public searchBoxInput
	@ViewChild('advancedSearchBox',{static :false}) public advancedSearchBox
	@Input() public isShowSearchBox: boolean = false;
	// @Output() public onShowSearchBox: EventEmitter<any> = new EventEmitter();

	@Output() public emitter: EventEmitter<any> = new EventEmitter();
	@Output() public onStart: EventEmitter<any> = new EventEmitter();
	@Output() public onSearch: EventEmitter<any> = new EventEmitter();
	@Output() public onClear: EventEmitter<any> = new EventEmitter();
	@Output() public resultChanged: EventEmitter<any> = new EventEmitter();

	@Input() public placeholder: string = "";
	@Input() public searchText: string = "";

	@Input() public searchInfo: any = null;
	@Input() public advanced: boolean = false
	public advanced_search_text = ""
	public is_show_advanced_search = false 

	public hasSearch: boolean;
	constructor(private translate :TranslateService,public theme : ThemeService) {


	}
	ngOnInit(): void {
		// this.advanced = false
		console.log("advanced search btn: ", this.advanced);
		console.log(this.theme);
		
		switch (this.translate.langs[0]) {
			case "en":
				this.advanced_search_text ="Advanced search"
				break;
			case "tc":
				this.advanced_search_text ="進階搜尋"
				break;
			case "sc":
				this.advanced_search_text ="进阶搜寻"
				break;
		}
	}

	next(): void {
		if (this.searchInfo && this.searchInfo.hasNext()) {
			this.searchInfo.next();
			this.resultChanged.emit(null);
		}
	}

	prev(): void {
		if (this.searchInfo.hasPrev()) {
			this.searchInfo.prev();
			this.resultChanged.emit(null);
		}
	}
	private lastSearchValue: string;
	/**
	 * 
		  (keyup.enter)="confirm(searchBoxInput.value)"
		(keyup.escape)="cancel();" 
	 */
	onKey(text: string, event): void {
		console.log(text);
		
		if (event.key == "Enter") {
			this.confirm(text);
		} else if (event.key == "PageUp") {
			this.prev();
		} else if (event.key == "PageDown") {
			this.next();
		} else if (event.key == "Escape") {
			this.cancel();
		} else {
			if (this.searchInfo && this.searchInfo.searchText) {
				this.searchInfo.searchText = null;
				this.lastSearchValue = null;
			}
		}
	}
	confirm(value: string): void {
		if (value !== this.lastSearchValue || this.advanced == true) {
			this.emitter.emit({ "type": "search", "text": value });
			this.onSearch.emit(value);
			this.hasSearch = true;
			this.lastSearchValue = value;
		} else {
			this.next();
		}
	}
	onStartHandler(): void {
		this.lastSearchValue = null;
		this.emitter.emit({ 'type': 'start' });
		this.onStart.emit(null);
		this.is_show_advanced_search = false
	}

	onIconClicked(){
		this.isShowSearchBox=true;
		if (!this.advanced) {
			console.log(this.searchBoxInput);
			this.searchBoxInput.nativeElement.focus();
		}else{
			this.advancedSearchBox.nativeElement.focus();
		}
		
		this.onStartHandler();
	}
	advanced_search_clicked(){
		console.log('clicked');
		this.is_show_advanced_search = true
		this.emitter.emit({"type":"Show advanced search"})
	}
	close_advanced_search(){
		console.log('closed');
		this.is_show_advanced_search = false
		this.emitter.emit({"type":"Close advanced search"})
	}
	cancel(): void {
		this.isShowSearchBox = false;
		this.searchText = "";
		this.emitter.emit({ 'type': 'end' });
		if (this.hasSearch) {
			this.emitter.emit({ "type": "cancel" });
			this.onClear.emit(null);
			this.hasSearch = false;
		}
		this.lastSearchValue = this.searchText;
	}
}