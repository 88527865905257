import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderIconBtnModule } from '../headerIconBtnModule/headerIconBtn.module';
import { UserMenuModule } from '../userMenuModule/user-menu.module';
import { TopRighToolBarComponent } from './topRightToolBar.component';
import { NotificationPopupComponent } from './notificationPopup.component';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { WhitePopupModule } from '../whitePopupModule/whitePopup.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        UserMenuModule,
        HeaderIconBtnModule,
		BubbleBox2Module,
		WhitePopupModule,
		FontAwesomeModule
    ],
    declarations: [
        TopRighToolBarComponent,
		NotificationPopupComponent
    ],
    exports: [
        TopRighToolBarComponent
    ],
    providers: [],
    bootstrap: []
})
export class TopRightToolBarModule { }
