import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'iconBtn',
    template: `
    `,
    styleUrls: ['./iconBtn.component.scss']
})

export class IconBtnComponent {    
    @Input() @HostBinding('style.backgroundImage') public icon:string = 'url(./assets/img/btn_home_ro.svg)';
    @Input() @HostBinding('style.width') @HostBinding('style.minWidth') public width:string = '50px';
    @Input() @HostBinding('style.height') @HostBinding('style.minHeight') public height:string = '50px';
    @Input() @HostBinding('style.backgroundSize') public backgroundSize:string = 'auto';


    /*@Output() public roLogoClick:EventEmitter<any> = new EventEmitter();
    @Output() public searchIconClick:EventEmitter<any> = new EventEmitter();
    @Output() public helpIconClick:EventEmitter<any> = new EventEmitter();*/

    constructor() {
    }


}
