import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
// import { StorageService } from '../service/storage.service';
import { faCamera, faMicrophone } from '@fortawesome/pro-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from 'src/app/service/theme.service';
import { faRobot } from '@fortawesome/pro-regular-svg-icons';
import { DataService } from 'src/app/service/data.service';
// import { environment } from '../../../../../environments/environment';
// import { ApiService } from '../service/api.service';
// import { ModalService } from '../service/modal.service';

@Component({
	selector: 'app-message',
	template: `
	<ng-container *ngIf="msg.content_type =='hint'"> 
		<div class="hint-container">
			<p class="heading">
				{{'bookshelf.hints' | translate}}:
			</p>
			<p class="content">
				{{msg.data}}
			</p>
		</div>
	</ng-container>
	<ng-container *ngIf="msg.content_type =='voice' || msg.content_type =='text'"> 
		<div class="date" *ngIf="!sameDate">{{trim(msg.created_at, 0)}}</div>
			<div [ngClass]="['msg', 'id-' + msg.id, msg.author == 0 ? 'from-other':'from-me', isLast?'last-msg':'', isFirst?'first-msg':'']">
				<div [ngClass]="'box ' + msg.content_type">
					<div class="box__triangle"></div>
					<div class="box__star" *ngIf="false"></div>
					<div class="box__title" [style.color]="author.color"> </div>
					<ng-select *ngIf="false" class="msg-action" [items]="actions" [clearable]="false" [searchable]="false" appendTo="app-thread">
					<ng-template ng-option-tmp let-item="item">
						<div class="msg-action" >{{ ('home-screen-news.' + item) | translate}}</div>
					</ng-template>
					</ng-select>
					<div class="box__quote" *ngIf="msg.quote.id " [style.color]="msg.quote.color" (click)="scrollMoveToMsg(msg.quote.id)">
					<div class="box__quote__title">{{msg.quote.title}}</div>
					<div [ngClass]="['box__quote__text', msg.quote.isEmoji?'emoji-only':'']" *ngIf="msg.quote.content_type == 'text'">
						<span [innerHTML]="msg.quote.data"></span>
					</div>
					<ng-container *ngIf="msg.quote.content_type == 'image'">
						<fa-icon class="camera" [icon]="faCamera" size="lg"></fa-icon>
						<div class="box__quote__desc">{{'msg-image'|translate}}</div>
						<div class="box__quote__image" [style.backgroundImage]="quoteImage"></div>
					</ng-container>
					<ng-container *ngIf="msg.quote.content_type == 'voice'">
						<fa-icon class="microphone" [icon]="faMicrophone" size="lg"></fa-icon>
						<div class="box__quote__desc">{{'msg-voice'|translate}} ({{msg.quote.data.duration}})</div>
					</ng-container>
					</div>
					<div [ngClass]="['box__content',msg.content_type, msg.isEmoji?'emoji-only':'']" >
					<ng-container *ngIf="msg.content_type == 'text'">
						<span [innerHtml]="msg.data"></span>
					</ng-container>
					<ng-container *ngIf="msg.content_type == 'image'">
						<div class="image" [style.backgroundImage]="image"></div>
					</ng-container>
					<ng-container *ngIf="msg.content_type == 'voice'">
						<div class="voice-message-container">
							<audio-player #audioPlayer [msg]="msg" (teacherSpeaking)="teacherSpeaking.emit($event)"></audio-player>
							<div class="line" *ngIf="msg.voice_text && showText"></div>
							<span class="voice-text" *ngIf="msg.voice_text && showText">{{msg.voice_text}}</span>
						</div>
					</ng-container>
				</div>
				<div class="box__time">{{trim(msg.created_at, 1) }}</div>
				<!-- <div class="box__time">{{msg.created_at | myDate:'timeStr':storage.lang}}</div> -->
			</div>
			<div [ngClass]="['photo', 'role-' + author.user_role,'gender-'+ author.gender]" [class.robot-background]="msg.author == 0" [style.backgroundImage]="author.photo">
				<fa-icon *ngIf="msg.author == 0" [icon]="faRobot"> </fa-icon>
			</div>
		</div>
	</ng-container>

  `,
	styleUrls: ['./message.component.scss']
})

export class MessageComponent implements OnInit, AfterViewInit {
	@ViewChild('audioPlayer', { static: false }) audioPlayer;
	@Input() public msg;
	@Input() public data;
	@Input() public lastMsg;
	@Input() public isFirst;
	@Input() public isLast;
	@Input() public student;
	@Input() public showText = false;
	@Output() teacherSpeaking: EventEmitter<any> = new EventEmitter();

	public sameDate = false;
	public author: any = { ename: 'unknown', cname: '匿名', color: '#999' };
	public image;
	public photo;
	public quoteImage;
	public faCamera = faCamera;
	public faMicrophone = faMicrophone;
	public faRobot = faRobot;
	public actions = ['msg-reply'];

	constructor(private sanitizer: DomSanitizer, private theme: ThemeService, public datas: DataService) {
	}

	ngOnInit() {
		this.theme.applyStyleObj(
			{
				default: {
					"from-me-message-background-color": "#DCEEFA",
					"from-me-message-shadow-color": "#AED4ED",
					"second-background-color": "#FFFFFF",
					"hint-container-background-color": "#B3DEFA",
				},
				dark: {
					"from-me-message-background-color": "#1B4F7E",
					"from-me-message-shadow-color": "#1C415A",
					"second-background-color": "#38374F",
					"hint-container-background-color": "#1D3651",
				},
			},
			document.body
		);
		const msg = this.msg;
		const anonymous = { ename: 'unknown', cname: '匿名', color: '#999', photo: this.sanitizePhoto() };
		if (!this.author) {
			this.author = anonymous;
		}
		if (!this.isFirst) {
			this.sameDate = msg.created_at.substr(0, 10) == this.lastMsg.created_at.substr(0, 10);
		}
		// if (this.isLast){
		//   setTimeout(()=>{this.author.color = '#A8284B';},10);
		// }
		if (msg.content_type == 'text') {
			if (msg.data.length <= 2) {
				const emojiReg = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
				if (emojiReg.test(msg.data)) {
					msg.isEmoji = true;
				}
			}
		} else if (msg.content_type == 'image') {
			let url = '';
			const index = msg.data.indexOf('tmp_upload');
			if (index > -1) {
				url = msg.data.substr(index);
				if (location.hostname.indexOf('localhost') > -1) {
					url = '//ro2.azurewebsites.net/RainbowOne/' + url;
				} else {
					url = '//' + location.hostname + '/RainbowOne/' + url;
				}
			} else if (msg.data.indexOf('//') == -1) {
				url = '//oka.blob.core.windows.net/media/' + msg.data;
			}
			this.image = this.sanitizer.bypassSecurityTrustStyle("url('" + url + "')");
		}
		// if ((msg.author == this.storage.uid || this.storage.role > 2)&& !msg.deleted){
		//   this.actions.push('msg-delete');
		// }
		if (msg.quote && msg.quote.id && !msg.deleted) {
			const quoteAuthor = this.data.peoples.find(ppl => ppl.uid == msg.quote.author);
			const nameLang = 'ename';
			msg.quote.title = quoteAuthor ? quoteAuthor[nameLang] : anonymous[nameLang];
			if (msg.quote.content_type == 'image') {
				let url = '';
				if (msg.quote.data.indexOf('//') == -1) {
					url = '//oka.blob.core.windows.net/media/';
				}
				this.quoteImage = this.sanitizer.bypassSecurityTrustStyle("url('" + url + msg.quote.data + "')");
			}
		}
		this.setAuthorPhoto();
	}

	ngAfterViewInit() {

	}

	setAuthorPhoto(){
		if(this.student && this.student.uid == this.msg.author){
			if(this.student.url){
				this.author.photo = this.sanitizer.bypassSecurityTrustStyle("url('https://oka.blob.core.windows.net/media/"+ this.student.url+ "')")
			}
			this.author.gender = this.student.gender;
			this.author.user_role = this.student.user_role;

		}
	}

	sanitizePhoto(photo = null) {
		let urls = "url('" + "assets/img/news/icon_no_avatar.svg')";
		if (photo) {
			urls = "url('" + photo + "')," + urls;
		}
		return this.sanitizer.bypassSecurityTrustStyle(urls);
	}

	public scrollMoveToMsg(id) {
		const el = document.querySelector('.id-' + id);
		if (el) {
			el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
		}
	}

	//   msgAction(item){
	//     console.log(item);
	//     let msg = this.msg;
	//     if (item == 'msg-star'){
	//       this.api.get(['ROMobile.reward_star', msg.id, this.storage.lang]).subscribe((res)=>{
	//         msg.reward_star = 1;
	//         this.actions[this.actions.indexOf('msg-star')] = 'msg-cancel-star';
	//         this.actions = [...this.actions];
	//       },(err)=>{
	//         this.modal.alert(err);
	//       });
	//     } else if (item == 'msg-cancel-star'){
	//       this.api.get(['ROMobile.cancel_reward_star', msg.id, this.storage.lang]).subscribe((res)=>{
	//         msg.reward_star = 0;
	//         this.actions[this.actions.indexOf('msg-cancel-star')] = 'msg-star';
	//         this.actions = [...this.actions];
	//       },(err)=>{
	//         this.modal.alert(err);
	//       });
	//     } else if (item == 'msg-reply'){
	//       this.data.quote = {id: msg.id, content_type: msg.content_type, title: this.author[this.storage.lang == 'en'?'ename':'cname'], data: msg.data, author: msg.author, created_at: msg.created_at, reward_star: msg.reward_star};
	//       if (msg.content_type == 'image'){
	//         this.data.quote.image = this.image;
	//       }
	//       const textarea = <HTMLElement>document.querySelector('textarea');
	//       textarea.style.height = '40px';
	//       textarea.scrollTop = 0;
	//       setTimeout(()=>{this.adjBoardHeight();}, 50);
	//     } else if (item == 'msg-delete'){
	//       setTimeout(()=>{this.msg.deleted = 1;},10);
	//       this.api.get(['ROMobile.delete_message', msg.id]).subscribe();
	//       //call api
	//     }
	//   }


	adjBoardHeight() {
		const header = <HTMLElement>document.querySelector('.thread-header');
		const board = <HTMLElement>document.querySelector('.thread-board');
		const content = <HTMLElement>document.querySelector('.thread-content');
		const panel = <HTMLElement>document.querySelector('message-panel > .container');
		const windowRoot = <HTMLElement>document.querySelector('window-root');
		const vh = windowRoot.offsetHeight;
		if (header && board && panel) {
			const contentHeight = content ? (content.offsetHeight > 30 ? 120 : 30) : 0;
			const height = vh - header.offsetHeight - contentHeight - panel.offsetHeight - 17; //num is margin
			board.style.height = height + 'px';
			setTimeout(() => { board.scrollTop = board.scrollHeight; }, 50);
		}
	}

	trim(date,o:number){
		var part = date.split(' ')[o];
		if(o == 1){

			return part.split(':')[0]+":" + part.split(':')[1];
		}
		return this.transformDateFormat(part);
	}

	private  transformDateFormat(dateString: string): string {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	  }
	  

}
