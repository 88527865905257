import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { QBComponentBase } from "./QBComponentBase";
import { FileIOService } from "src/app/service/FileIO.service";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";


@Component({
	template:`
	<div class="q scroll-container">
		<div class="qContainer">
			<div class="qnumCol flex-row-left">

				<div class="flex-column-center">
					<div class="qnum"> {{qObject.questionIndex+1}} </div>
				
					<!-- speaker tts icon -->
					<!--
					<div  *ngIf="!qObject.isPageComponent" class="speaker" (click)="speechQ(qObject.question)"></div>
					-->
				</div>
				<div class="qcontent">

					<QText [qObj]="qObject" ></QText>

					<div class="options">
						<div 
							class="option"
							*ngFor="let ans of getCanChooseOptions(); let j = index" 
							[class.selected]="isMCSelected(j)"
							(click)="answerClick(j)"
							(pointerdown)="onMouseDownInteractive($event)" 
							
							>
							
							<!-- [ngClass]="['option', answerIsSelected(i,j) ? 'selected' : '', isMCAnswer(i,j) ? 'answer' : '', showVerify(i)  ? 'verify' : '']"
							(click)="answerClick(i,j)"
							-->
							<div class="ans pointer-events-none">
			
			
								<div [innerHTML]="optionNames[j]+' '+ans"></div>
			
			
			
								<ng-container *ngFor="let f of getOptionAssetFiles(j)">
									<QMediaAssets [file]="f"></QMediaAssets>
								</ng-container>
								
							</div>
							
						</div>
					</div>

				</div>
			</div>
			
		</div>

		<div class="score">
					
			<!--
				<Scoring [displayType]="'ai'" 
				(onChange)="updateScore($event)" 
				*ngIf="isSubmitted() && qObject.type!='QBInfoBlock' && isDisplayAIScore()"
				[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
				[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
				[componentName]="getText('componentName.'+qObject.type)"></Scoring>
				<Scoring (onChange)="updateScore($event)" *ngIf="isSubmitted(i) && qObject.type!='QBInfoBlock' && isDisplayTeacherScore(i)"
				[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
				[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
				[componentName]="getText('componentName.'+qObject.type)"></Scoring>
			-->
		</div>

	</div>
	


	
	`,
	styleUrls:[
		"./QBComponentQuestionBase.scss",
		"./QBTextMC.scss"
	],
	styles:[
		`
		.scroll-container
		{
			height:768px;
			overflow:auto;
			padding-bottom:100px;
		}
		`
	]
})
export class QBTextMC extends QBComponentQuestionBase
{
	public faBars:any = null;
	public faPaperclip:any = null;
	public faCheckCircle:any = null;
	public faCheckCircleLight:any = null;
	public faTrash:any = null;

	
	
	public fontSize:number = 32
	public state:any = {
		inEdit:false
	};
	// public inEdit:boolean = false;
	// public inEditMode:boolean=false;
	public qObject:any;
	// public optionNames = [];
	public currentState:any = {selected:[]};
	public optionNames:string[] = ["A.","B.","C.","D."];
	public selectedAnswers:any[];
	public answer:any;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, public fileio:FileIOService)
	{
		super(cdr, elementRef, fileio);
	}
	public addAsset($event, key:string)
	{

	}

	public toggleMCOption(j:number)
	{
		
	}
	////////// scoring //////////

	isDisplayAIScore()
	{
		return true;
	}
	isSubmitted()
	{
		return true;
	}

	getText(name)
	{
		return name;
	}
	updateScore($event)
	{

	}
	////////// scoring //////////
	onMouseDownInteractive(event)
	{
		event.stopImmediatePropagation();
	}

	public canVerify(): boolean {
		return true;
	}

	public isQuestionComponent():boolean
	{
		return true;
	}

	public hasAnswer():boolean
	{
		return this.selectedAnswers && this.selectedAnswers.length > 0;
	}

	public set data(value:string) 
	{
		if(value)
		{
			var items = value.split(",");
			this.selectedAnswers = items.map((s:string)=>{
				return parseInt(s);
			})
			this.currentState = {
				selected:this.selectedAnswers
			}
		} else {
			if(this.hasAnswer())
			{
				this.answerChanged = true;	
			}
			// null
			this.currentState = {selected:[]};
			this.selectedAnswers = this.currentState.selected;

		}
	}

	public get data():string {
		if(this.selectedAnswers && this.selectedAnswers.length)
		{
			var o = this.getResultObjectAndAnswer();
			return o.answerForSubmit;
		}
		return null;
	}

	public verify(show:boolean):any
	{
		var o = this.getResultObjectAndAnswer();
		this.resultObject = o.result;
		return o.result;
	}

	protected getResultObjectAndAnswer():any {
		let qObj:any = this.qObject;
		let qVar:any = this.currentState;
		let totalAns:number = 1;
		let correct:number = 0;
		let wrong:number = 0;
		let answerForSubmit:any;

		let qans:any[] = qObj.answer.indexOf(',') ? qObj.answer.split(",") : [qObj.answer];
		totalAns = parseInt(qObj.scoringType)==1 ? 1 : qans.length;
		answerForSubmit = qVar.selected.join(",");
		if(answerForSubmit!="") {
			// 不是細項計分當一題, 細項計分用幾多個答案
			if(parseInt(qObj.multiSelect)==1 && parseInt(qObj.scoringType)!=1) {
				// 多選題 個別答案計分
				let maxAns:number = 0;
				
				qVar.selected.forEach(ans => {
					if(qans.indexOf(ans.toString())>=0)
						correct++;
					else
						wrong++; // 選擇錯誤
				});
				//wrong += qans.length - correct; // 漏選的正確答案

				// 多選時有錯就歸零
				//if(wrong>0)
				//	correct = 0;
				
			} else if(qObj.answer != answerForSubmit){
				wrong = 1;
			} else {
				correct = 1;
			}
		}

//		if(correct==0 && wrong==0)
//			return null; // 無答過
		return {
			answerForSubmit:answerForSubmit,
			correct:correct,
			wrong:wrong,
			totalAns:totalAns,
			result:this.getResultObject(qObj, correct, wrong, totalAns)
		};
	}


	@HostListener('wheel', ['$event'])
	onMouseWheel(event) {
		if(event.ctrlKey)
		{
			
		} else {
			// allow content scroll
			event.stopImmediatePropagation();
		}
	}
	
	
	public getOptionAssetFiles(i)
	{
		var key = `opt${i+1}_asset`;
		if(this.qObject.hasOwnProperty(key)) return this.qObject[key].files;
		return null;
	}
	
	public getTagNames():string []
	{
		return ["QBTextMC"];
	}
	mcTextChg(event, i)
	{
		

	}

	public getCanChooseOptions():any {
		return this.options;
	}
	public createChooseOptions()
	{
		let ary:any = [];
		for(let i:number=1; i<=this.qObject.totalOptions; i++) {
			ary.push(this.continuesSpaceConvert(this.qObject["opt"+i]));
		}
		this.options = ary;
	}

	public continuesSpaceConvert(str:string):string {
		var ary:string[] = str.split("");
		ary.forEach((t,i)=> {
			if(t == " ") {
				if(i+1<ary.length && ary[i+1]==" ")
					ary[i] = "&nbsp;";
			}
		});
		return ary.join("");
	}

	public isMCAnswer(grpIndex:number):boolean {
		if(this.qObject.answer==null)
			return false;
		if(typeof this.qObject.answer !== 'string')
			this.qObject.answer = this.qObject.answer.toString();
		return this.qObject.answer.indexOf((grpIndex+1).toString())>=0;
	}
	
	public isMCSelected(index:number):boolean
	{
		return (this.selectedAnswers && this.selectedAnswers.indexOf(index) != -1);
	}

	/*
	public isMCAnswer(q:number, index:number, subIndex:number = 0):boolean {
		let qObj:any = this.questionList[q];

		if(qObj.type == "QBToggleOptions") {
			// 文字切換題
			let grpOpt = qObj.question.find(e => e.type=="option" && e.grp==index && e.id==subIndex);
			return grpOpt && grpOpt.answer;

		} else if(["QBTextMC", "QBGraphicMC", "QBVoiceMC"].indexOf(qObj.type)>=0) {
			// MC
			if(typeof qObj.answer !== 'string')
				return false;
			return qObj.answer.indexOf((index+1).toString())>=0;
		}

		return false;
	}
	*/


	options:any [];
	drop(event)
	{

	}
	delMCOption(i)
	{

	}
	canAddOption()
	{
		return true;
	}
	addMCOption()
	{

	}
	
	answerClick(j:number):void
	{
		console.log("answerClick",  j);
		if(!this.selectedAnswers) {
			this.selectedAnswers = [];
			this.currentState.selected = this.selectedAnswers;
		}
		var foundIndex = this.selectedAnswers.indexOf(j);
		if(foundIndex == -1)
		{
			// not found
			this.selectedAnswers.push(j);
		} else {
			this.selectedAnswers.splice(foundIndex, 1);
		};
		this.answerChanged = true;
	}
	

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
		this.createChooseOptions();
		
	}

	
}