import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
	selector: "photo-renderer",
	template: `
	<div *ngIf="photo" [ngClass]="['photo', (params.output.photoClass?params.output.photoClass:'')]" 
		[style.backgroundImage]="photo"
		[ngStyle]="photoStyle" (click)="clickSubject.next('photo')">{{params.output.photoText?params.output.photoText:''}}</div>
	<div *ngIf="text !== null" class="text" [ngStyle]="textStyle" (click)="clickSubject.next('text')">
		<span>{{text}}</span>
		<span *ngIf="tagName !== null" class="tagName" [ngStyle]="tagNameStyle">{{tagName}}</span>
	</div>
	<fa-icon *ngIf="faIcon" [icon]="faIcon" [ngStyle]="faIconStyle" (click)="clickSubject.next('fa-icon')"></fa-icon>
    `,
	styleUrls: ['./photo.renderer.scss']
})


export class PhotoRenderer implements AfterViewInit {
	public params: any;
	public photoStyle:any;
	public textStyle:any;
	public tagNameStyle:any;
	public faIconStyle:any;
	public currentValue: any;
	public photo:any;
	public text = '';
	public tagName = '';
	public faIcon:any;
	public clickSubject = new Subject();
	public tooltip;
	constructor(public eleRef: ElementRef){

	}

	ngAfterViewInit() {
		this.clickSubject.pipe(debounceTime(100)).subscribe(e=>{
			if (e == 'text' && this.params.textClick){
				this.params.textClick(this.params);
			} else if (e == 'photo' && this.params.photoClick){
				this.params.photoClick(this.params);
			} else if (e == 'fa-icon' && this.params.faIconClick){
				this.params.faIconClick(this.params);
			}
		});
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	agInit(params: ICellEditorParams): void {
		this.params = params;
		if (this.params.style){
			if (this.params.style.photo) {
				this.photoStyle = this.params.style.photo;
			}
			if (this.params.style.text) {
				this.textStyle = this.params.style.text;
			}
			if (this.params.style.tagName) {
				this.tagNameStyle = this.params.style.tagName;
			}
			if (this.params.style.faIcon) {
				this.faIconStyle = this.params.style.faIcon;
			}
			if (this.params.style.host){
				let hostStyle = this.eleRef.nativeElement.style;
				for (let prop in this.params.style.host){
					hostStyle[prop] = this.params.style.host[prop];
				}
			}
		}
		if (this.params.tooltip){
			this.tooltip = this.params.tooltip;
		}
		if (this.params.output){
			this.photo = this.params.output.photo;
			this.text = this.params.output.text;
			this.tagName = this.params.output.tagName;
			this.faIcon = this.params.output.faIcon; 
			if (this.params.output.textStyle){
				this.textStyle = this.params.output.textStyle;
			}
			if (this.params.output.tagNameStyle){
				this.tagNameStyle = this.params.output.tagNameStyle;
			}
			if (this.params.output.faIconStyle){
				this.faIconStyle = this.params.output.faIconStyle;
			}
			if (this.params.output.tooltip){
				this.tooltip = this.params.output.tooltip;
			}
		}

	}


}
