import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { ROComponent, ROContainerComponent, ROTLFTextComponent } from "./ROComponent";


@Component({
	template:`
		<div 
			[class.show-default-answer]="defaultAnswer"
			[class.verify]="showVerify"
			[class.correct]="answer.correct"
			[class.selected]="(defaultAnswer && answer.correct) || (!defaultAnswer && answer.selected )"
			class="answer-bg" 
			*ngFor="let answer of answers2"
			[style.left.px]="answer.position.x"
			[style.top.px]="answer.position.y"
			[style.width.px]="answer.position.w"
			[style.height.px]="answer.position.h"
			
			(tap)="toggleAnswer(answer) "
			>
			<div class="label">{{answer.label}}.</div>
		</div>
		`
		
		,
	styles:[
		`
			.label{
				margin-left: 17px;
				font-size: 20px;
				line-height: 15px;
				margin-top: 15px;
			}
			.answer-bg::after{
				content:" ";
				position:absolute;
				right:5px;
				width:40px;
				height:40px;
				top:50%;
				transform:translate(0%, -50%);
			}
			
			.answer-bg{
				background-color:white;
				position:absolute;
				border-radius:10px;
				border:solid 2px #4C4C33;
			}
			.answer-bg.verify.correct
			{
				border-color:#63AA0E;
			}
			.answer-bg.verify.selected::after
			{
				background-image: url(assets/img/component/verify/incorrect.svg);
			}
			.answer-bg.verify.correct.selected::after, .answer-bg.show-default-answer.correct::after
			{
				background-image: url(assets/img/component/verify/correct.svg);
			}
			.answer-bg.selected{
				background-color:#F6DFB2;
			}
		`
		/**
		 * .answer-bg.selected.incorrect::after{
				background-image: url(assets/img/component/verify/incorrect.svg);
			}
		 */
	]
})

export class ROMC extends ROContainerComponent
{
	public showVerify:boolean;
	public title:any;
	public answers:any [];
	public positionArray:any [] ;
	/**
	 * 
	 	<MC2 
			version="0.0.1" viewMode="interactive" 
			q="{&quot;freezed&quot;:1,&quot;show&quot;:true,&quot;color&quot;:6011353}" ver="1.1" coordinateExpression="H 250 W 350" 
			questionNumber="1" direction="2" spacing="20" 
			ballSize="3" answer="10">
			<TLFText autoHeight="true" coordinateExpression="H 30 W 283">
				<TextFlow fontFamily={fontFamily} fontSize={fontSize} lineBreak="explicit" whiteSpaceCollapse="preserve" version="2.0.0"
					xmlns="http://ns.adobe.com/textLayout/2008">
					<span>{defaultText}</span>
				</TextFlow>
			</TLFText>
			<TLFText autoHeight="true" coordinateExpression="H 30 W 97">
				<TextFlow fontFamily={fontFamily} fontSize={fontSize} lineBreak="explicit" whiteSpaceCollapse="preserve" version="2.0.0"
					xmlns="http://ns.adobe.com/textLayout/2008">
					<span>{answer1}</span>
				</TextFlow>
			</TLFText>
			<TLFText autoHeight="true" coordinateExpression="H 30 W 97">
				<TextFlow fontFamily={fontFamily} fontSize={fontSize} lineBreak="explicit" whiteSpaceCollapse="preserve" version="2.0.0"
					xmlns="http://ns.adobe.com/textLayout/2008">
					<span>{answer2}</span>
				</TextFlow>
			</TLFText>
		</MC2>
	 */
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	toggleAnswer(answer)
	{
		if(!this.context.answerEnabled) return;
		this.showVerify = false;
		this.answerChanged = true;
		if(this.node.attributes.multipleSelection)
		{
			answer.selected = !answer.selected;
		} else {
			this.answers.forEach((answer)=>{
				answer.selected = false;
			})
			answer.selected = true;
		}
		
	}
	
	public isQuestionComponent(): boolean {
		return true;
	}

	public getTagNames(): string[] {
		return  ["MC2"];
	}

	protected buildContent(): void {
		
		/*
		console.log("test:ArrayUtils.randomize");
		console.log(ArrayUtils.randomize([1,2,3,4]));
		console.log(ArrayUtils.randomize([1,2,3,4]));
		console.log(ArrayUtils.randomize([1,2,3,4]));
		console.log(ArrayUtils.randomize([1,2,3,4]));
		*/
	}
	public answers2:any [] 
	protected buildChildren():void
	{
		super.buildChildren();
		
		
		var tlfArray:ROTLFTextComponent [] = this.children.filter((child:any)=>{
			return child instanceof ROTLFTextComponent;
		});
		if(tlfArray.length)
		{
			this.title = tlfArray[0];
			
			var s:string= this.node.attributes.answer+"";
			var correctAnswer:boolean [] = s.split("").map((v)=>{
				return v == "1";
			});
			var answerTLFArray = tlfArray.slice(1, tlfArray.length);
			var maxW:number = 0;
			answerTLFArray.forEach((tlf:ROTLFTextComponent) => {
				tlf.mouseEnabled = false;
			});
			answerTLFArray.forEach((tlf:ROTLFTextComponent, index:number)=>{
				if(tlf.w > maxW) maxW = tlf.w;
			})
			var xArray = answerTLFArray.map((tlf:ROTLFTextComponent, index:number)=>{
				return tlf.x;
			})
			////  because dom does not support textWidth 
			// quick fix for ui overlap problem
			var diffMax = 0;
			xArray.reduce((prev, current)=>{
				var diff = current - prev;
				if(diff > diffMax)
				{
					diffMax = diff;
				}
				return current;
			});
			var expectedMax = diffMax - 115;
			if(expectedMax > 0 && maxW > expectedMax)
			{
				maxW = expectedMax;
			}
			////////////
			this.positionArray = answerTLFArray.map((tlf:ROTLFTextComponent, index:number)=>{
				return {
					x:tlf.x, 
					y:tlf.y,
					w:maxW, h:tlf.h
				}
			});

			this.answers = answerTLFArray.map((tlf:ROTLFTextComponent, index:number)=>{
				
				return {
					index:index,
					correct:index < correctAnswer.length? correctAnswer[index] : 0,
					tlf:tlf
				}
			});
			if(this.node.attributes.randomize)
			{
				this.answers2 =  ArrayUtils.randomize(this.answers);
			} else {
				this.answers2 = this.answers.concat();
			}

			this.answers2.forEach((answer:any, index:number)=>{
				var tlf:ROTLFTextComponent = answer.tlf;
				answer.label = String.fromCharCode(65 + index);;
				var position:any = this.positionArray[index];
				answer.position = {
					x:position.x - 59, y:position.y - 10,
					w:position.w + 113, h:position.h + 15
				}
				tlf.moveTo(position.x, position.y);
			});
		} else {
			this.answers = [];
			this.answers2 = [];
		}
		console.log("this.answers", this.answers);
	}

	private reset():void
	{
		this.answers.forEach((answer:any)=>{
			answer.selected = false;
		})
	}
	public hasAnswer():boolean
	{
		var flag:boolean = false;
		this.answers.forEach((answer)=>{
			if(answer.selected) flag = true;
		})
		return flag;
	}
	public canVerify(): boolean {
		return this.answers.filter(
			(answer)=>{
				return answer.correct
			}
		).length > 0;
	}
	public set data(value: string) {
		this.reset();
		if(value)
		{
			var items:any [] = value.split("");
			if(items.length == this.answers.length)
			{
				items.forEach((selected:any, index:number)=>{
					this.answers[index].selected = selected == "1";
				});
			}
		}
	}
	public get data(): string {
		if(this.hasAnswer)
		{
			return this.answers.map((answer)=>{
				return answer.selected ? 1 : 0;
			}).join("");
		}
		return null;
	}

	public verify(_showScoring: boolean) {
		this.showVerify = _showScoring;
		var info:any = {
			correctCount:0,
			incorrectCount:0,
			total:0
		}
		this.answers.forEach((answer:any)=>{
			if(answer.correct)
			{
				info.total++;
			}
			if(answer.selected)
			{
				if(answer.selected == answer.correct)
				{
					info.correctCount++;
				} else {
					info.incorrectCount++;
				}
			}
		})

		var correctCount:number = info.correctCount - info.incorrectCount;
		var answerCount:number = info.total;
		var fullScore = this.getFullScore();
		if (answerCount <= 0)
		{
			this.resultObject = {
				correct:-1, 
				maxScore:fullScore
			};
		} 
		else if (correctCount == answerCount)
		{
			this.resultObject = {
				correct:2, 
				score:fullScore, 
				maxScore: fullScore
			};
		} 
		else 
		{
			var multipleSelection:boolean = this.node.attributes.multipleSelection;
			if (multipleSelection && info.correctCount > 0)
			{
				var score:number = this.context.roundScore(correctCount * fullScore / answerCount);
				if (score < 0)
				{
					score = 0;
				}
				this.resultObject = {
					correct:1, 
					score:score, 
					maxScore:fullScore
				};
			} else {
				this.resultObject = {
					correct:0, 
					score:0, 
					maxScore:fullScore
				};
			}
		}
		if(_showScoring) this.sObj.showScore(this.resultObject.correct, this.resultObject.score);
		return this.resultObject;
		
	}

	// public defaultAnswer: any;
	public showDefaultAnswer(): void {
		this.defaultAnswer = true;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}
	public hideDefaultAnswer(): void {
		this.defaultAnswer = false;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}

}