import { Component } from '@angular/core';

@Component({
  selector: 'agGridIconBtnFormatter',
  template: `
<ng-container *ngIf="params.icon && (!params.hideIf || !params.hideIf(params))">
  <img *ngIf="params.iconType=='img'" [src]="params.icon" (click)="params.onClick && params.onClick(params)">
  <fa-icon *ngIf="params.iconType=='fa'" [icon]="params.icon" [ngStyle]="params.iconStyle" (click)="params.onClick && params.onClick(params)"></fa-icon>
</ng-container>
  `,
  styleUrls: ['./iconBtn.renderer.scss']
})

export class IconBtnRenderer {
  public params: any;

  constructor() {
  }

  agInit(params: any){
      this.params = params;
  }
}
