import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { LoadingComponent } from './loading.component';

export class LoadingDotControl {
	readonly key:string;
	readonly add:Function;
	readonly remove:Function;
	constructor(key:string, add:Function, remove:Function) {
		this.key = key;
		this.add = add;
		this.remove = remove;
	}
}

@Injectable({providedIn: 'root'})
export class LoadingService {

    private _apps:string[] = [];
    private _loadingEle:HTMLElement;
    private _componentRef:ComponentRef<LoadingComponent>;
    public isLoading:Boolean = false;

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector:Injector) {
        this._componentRef = this.componentFactoryResolver
            .resolveComponentFactory(LoadingComponent)
            .create(this.injector);

        this.appRef.attachView(this._componentRef.hostView);

        this._loadingEle = (this._componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    }

    public setZindex(zIndex:number):void {
        this._componentRef.instance.zIndex = zIndex;
    }

    public getRandomString():string {
        return + new Date() + '-' + Math.round(Math.random() * 1000)
    }

    public add(appName:string=this.getRandomString(), timeout=0):Function{
        //let key:string = appName ? appName + "_" : '' + Date.now();
        this._apps.push(appName);
        this.checkLoading();
		if (timeout > 0){
			setTimeout(()=> {this.remove(appName);}, timeout);
		}
        return ()=>{ this.remove(appName); };
    }

    public remove(appName:string):void {
        for (let i=0; i<this._apps.length; i++) {
            if (this._apps[i]==appName) {
                this._apps.splice(i, 1);
            }
        }
        this.checkLoading();
    }

	public removeAll(){
		this._apps = [];
		this.checkLoading();
	}

    private checkLoading():void {
        if (this._apps.length>0 && !this._loadingEle.parentElement) {
            setTimeout(()=>this.isLoading = true);
            document.body.appendChild(this._loadingEle);
            //console.log('addLoadingEle');
        } else if (this._apps.length<1 && this._loadingEle.parentElement) {
            setTimeout(()=>this.isLoading = false);
            document.body.removeChild(this._loadingEle);
            //console.log('removeLoadingEle');
        }
    }

    //---------------------------------------------------------------------------------------------
    public load(appName:string=this.getRandomString()):Promise<any> {
        return new Promise((resolve, reject)=>{
            this._apps.push(appName);
            this.checkLoading();
            resolve(()=>this.remove(appName));
        });
    };

    //---------------------------------------------------------------------------------------------
	//FOR_LOADINGDOT
	public dotApps:any = {};
	protected dotCtrls:LoadingDotControl[] = [];
	public getLoadingDotControl(moduleName:string=this.getRandomString()): LoadingDotControl {
		if (this.dotCtrls[moduleName]) return this.dotCtrls[moduleName];
		this.dotApps[moduleName] = [];
		let dotApps:string[] = this.dotApps[moduleName];

		let add:Function = (appName:string=this.getRandomString()):Function => {
			dotApps.push(appName);
			return ()=>remove(appName);
		}
		
		let remove:Function = (appName:string):void => {
			for (let i=0; i<dotApps.length; i++) {
				if (dotApps[i]==appName) {
					dotApps.splice(i, 1);
				}
			}
		}

		this.dotCtrls[moduleName] = new LoadingDotControl(moduleName, add, remove);
		return this.dotCtrls[moduleName];
	}
    //---------------------------------------------------------------------------------------------

	public get apps(){
		return this._apps;
	}
    
}
