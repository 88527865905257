import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";

import {  ROComponent } from "./ROComponent";
import { IAudioPlayer } from "./TTSManager";
import { XMLNode } from "./xml/XMLNode";
import { SelectMarkerIcon } from "./SelectMarkerIcon";
import { XMLJSNode } from "./xml/XMLParser";



///////////////

@Component({
	template:`
		<div class="button player-container" (tap)="togglePlay()">
			<div class="player" 
				[class.state]=""
				[class.played]="played" >
				<div [class]="'btn state-'+ state">
				</div>
			</div>
		</div>
	`,
	styleUrls:["ROSound.scss"]
})

export class ROSound extends ROComponent
{
	/**
	{
		"ver":1.4,"videoSrc":"",
		"autoPlay":false,
		"sysCtrlAutoPlay":false,
		"controlVisible":"mini",
		"playAllPages":true,
		"isBGM":false,"coordinateExpression":"UA UK KW KH KR X 358 Y 570 D T 570 L 358 H 60 W 60","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"srcType":"TTS","ttsSpeechRate":1,"ttsPitch":1,"locked":false,"tts":"中文字","soundObject":"{\"tts\":{\"setting\":{\"preprocess\":0,\"pitch\":1,\"speed\":1},\"custom\":1,\"engine\":{\"name\":\"zh-CN-XiaoyouNeural\",\"gender\":\"F\",\"lang\":\"zh-CN\"},\"text\":\"中文字\"},\"type\":\"tts\"}","ttsLanguage":"zh-CN","douid":"2177C318-2BA2-2F8F-EAD6-0C6DB3EF64FF","iconType":"big","x":358,"y":570,"w":60,"h":60}
	 */
	
	// @ViewChild("player", {static: false}) public playerEF:ElementRef;
	public enabled:boolean = true;
	public assetURL:string;
	public soundObject:any;
	public played:boolean;
	public paused:boolean;
	public state:string = "idle";
	public autoplay:boolean; // 自動播放
	public sysCtrlAutoPlay:boolean; // 自動轉頁
	public playedOnce:boolean = false;
	public playAllPages:boolean;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["Sound", "SoundAllPages"];
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"douid": this.createDouid(),
				"coordinateExpression": "UA UK KH X 0 Y 0 D SIZE 1024 768 1024 768 T 0 L 0 W 60 H 60","locked":false,
				"hasScoring":false,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,

				"ver":1.4,"videoSrc":"","autoPlay":false,"sysCtrlAutoPlay":false,"controlVisible":"mini",
				"playAllPages":false,"isBGM":false,"iconType":"big"
				
			},
			tag:"Sound"
		});
		this.node.createElement();
	}

	public togglePlay():void
	{
		if(this.player)
		{
			this.player.toggle();
		} else {
			this.preparePlayer();
		}
		if(this.player)
		{
			this.context.playerManager.focusPlayer(this.player);
		}
	}
	private player:IAudioPlayer;

	play() {
		this.playedOnce = true;
		if(this.player)
		{
			this.context.playerManager.focusPlayer(this.player);
			this.player.play();
		} else {
			this.preparePlayer();
			if(this.player)
			{
				this.context.playerManager.focusPlayer(this.player);
			}
		}
		
	}
	private preparePlayer():void
	{
		// this.context.playerManager.test();
		// throw new Error("Method not implemented.");
		this.state = "loading";
		if(this.node.attributes.srcType == "mp3")
		{
			this.player = this.context.playerManager.createPlayer({
				type:"url",
				url:this.node.attributes.videoSrc
			});
			this.player.load().then(()=>{
				// this.player.play();
			})
			/*
			this.reference.subject.subscribe(
				{
					next:this.onNext.bind(this),
					error:this.onError.bind(this),
					complete:this.onComplete.bind(this)
				}
			);
			*/
		} else if(this.soundObject)
		{
			
			this.player = this.context.playerManager.createPlayer(this.soundObject);
			this.player.load();
			/*
			this.context.player.playReference(this.reference);
			this.reference.subject.subscribe(
				{
					next:this.onNext.bind(this),
					error:this.onError.bind(this),
					complete:this.onComplete.bind(this)
				}
			);
			*/
			/*
			this.subscription = this.context.player.play(
				this.soundObject,
				true
			).subscribe(
				{
					next:this.onNext.bind(this),
					error:this.onError.bind(this),
					complete:this.onComplete.bind(this)
				}
			);
			*/
		} 
		if(this.player)
		{
			this.player.subject.subscribe(
				{
					next:this.onNext.bind(this),
					error:this.onError.bind(this),
					complete:this.onComplete.bind(this)
				}
			)
		}
	}

	private subscription:Subscription;
	private removeSubscription():void
	{
		if(this.subscription){
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}
	private onNext(o:any):void
	{
		if(o)
		{
			if(o.type == "state") {
				this.state = o.state;
				if(this.state == "completed")
				{
					this.onPlayCompleted();
				}
			}
		}
	}
	private onPlayCompleted():void
	{
		if(this.sysCtrlAutoPlay)
		{
			this.player.close();
			this.context.subject.next({type:"action", action:"next-page"});
		}
	}
	
	private onError(reason:any):void
	{
		this.state = "error";
		this.removeSubscription();
	}
	private onComplete():void
	{
		this.state = "completed";
		this.removeSubscription();
	}
	

	protected buildContent(): void {
		// <!--{"ver":1.4,"videoSrc":"","autoPlay":false,"sysCtrlAutoPlay":false,"controlVisible":"mini","playAllPages":true,"isBGM":false,"coordinateExpression":"UA UK KW KH KR X 378 Y 629 D T 629 L 378 H 60 W 60","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"srcType":"TTS","ttsSpeechRate":1,"ttsPitch":1,"locked":false,"tts":"中文字","soundObject":"{\"tts\":{\"setting\":{\"preprocess\":0,\"pitch\":1,\"speed\":1},\"custom\":1,\"engine\":{\"name\":\"zh-CN-XiaoyouNeural\",\"gender\":\"F\",\"lang\":\"zh-CN\"},\"text\":\"中文字\"},\"type\":\"tts\"}","ttsLanguage":"zh-CN","douid":"2177C318-2BA2-2F8F-EAD6-0C6DB3EF64FF","iconType":"big","x":378,"y":629,"w":60,"h":60}-->
		var dom:HTMLElement = this.elementRef.nativeElement;
		this.soundObject = this.getSoundObject();
		var options:any = this.node.attributes;

		if(options.controlVisible == "none")
		{
			dom.classList.add("transparent")
		} else if(options.playAllPages)
		{
			dom.classList.add("green")
		} else {
			dom.classList.add("brown")
		}
		if(options.iconType)
			dom.classList.add("size-"+ options.iconType);

		this.autoplay = options.autoPlay;
		this.sysCtrlAutoPlay = options.sysCtrlAutoPlay;
		this.playAllPages = options.playAllPages;
		// will be handle by activate
		
	}

	public setPropertiesThroughPanel(key:string, val:any):boolean {
		if(key == "assetSrc") {
			if(!val) return false;

			this.node.setAttribute("soundObject", JSON.stringify({
				"type": "asset",
				"asset": {
					"resource": val,
					"type": "2",
					"server": "1"
				}
			}));
			return true;
		}

		return super.setPropertiesThroughPanel(key, val);
	}

	public activate():void
	{
		if(this.playedOnce == false && this.autoplay)
		{
			this.play();
		}
	}
	public deactivate():void
	{
		if(!this.playAllPages)
		{
			if(this.player)
			{
				this.player.close();
			}
		}
	}
	
	private getSoundObject():any
	{
		/**
		 
		 {
		  "tts": {
		    "setting": {
		      "speed": 1,
		      "pitch": 1,
		      "preprocess": 0
		    },
		    "custom": 1,
		    "text": "中文字",
		    "engine": {
		      "name": "auto",
		      "gender": "M",
		      "lang": "inherit"
		    }
		  },
		  "type": "tts"
		} 

		 {
		  "asset": {
		    "checksum": "6AE64094",
		    "resource": "OKG://id/479756/checksum/6AE64094/type/1/title/C1_bk101_V8.mp3",
		    "folderId": "63",
		    "groupKey": "elfie",
		    "thumbnail": "//oka.blob.core.windows.net/media/",
		    "id": "479756",
		    "type": "2",
		    "title": "C1_bk101_V8.mp3",
		    "server": "1",
		    "url": "//oka.blob.core.windows.net/media/resource/2017/08/16/100914_6AE64094"
		  },
		  "type": "asset"
		} 
		 */
		var sound:string = this.node.getAttribute("soundObject");
		var sndObject = JSON.parse(sound);
		return sndObject;
	
	}

	// =======================================
	// edit relative function
	// =======================================
	public getPopupSelector():any []
	{
		return [this.getPSName(), {
			type:"action",
			action:"changeSound",
			target:"editor",
			color:"#FBB226",
			icon:SelectMarkerIcon.SOUND_ICON
		}, this.getPSDel()];
	}


}

/*
////////

@Component({
	template:`
		錄音
		<div class="player">
			<div [class]="state"
		</div>
	`,
	styles:[
		`
		.player{
			background-image:url('./assets/img/component/recorder_skin.svg#soundbg');
			width:100px;
			height:100px;

		}
		`
		// recBtn // playBtn // pauseBtn
	]

	// ,
	// styleUrls:["ROSound.scss"]
	
})

export class ROSoundRecorder extends ROComponent
{
	public state:string ="idle";

	constructor(elementRef:ElementRef)
	{
		super(elementRef);
	}
	// {"hasScoring":false,"ver":1.3,"maxLength":20,"controlVisible":"full","douid":"7667672E-985F-CBB0-9F70-2A54608852FB","coordinateExpression":"UA UK KW KH KR X 338 Y 676 D T 676 L 338 H 64 W 258","q":"{\"freezed\":1,\"color\":\"noColor\",\"ballSize\":36,\"show\":0,\"level\":0}","s":"{\"freezed\":1,\"y\":0,\"enable\":0,\"optional\":false,\"offset\":{\"y\":0,\"x\":0},\"reference\":\"rt\",\"x\":258}","isQuestionComponent":true,"locked":false,"questionIndex":0,"qInfo":"{\"id\":1,\"rootIndex\":0,\"order\":0,\"root\":0,\"level\":0,\"pid\":0,\"index\":0}","x":338,"y":676,"w":258,"h":64}
	public getTagNames():string []
	{
		return ["Recorder"];
	}


	
}

*/