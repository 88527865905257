import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { ROContext } from "./ROContext";
import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
import { ROComponent, ROPageComponent } from "./ROComponent";
import { AnswerSource, ROBookConfig } from "./ROBookConfig";
import { MarkingLayer } from "./epen/MarkingLayer";
import { DataService } from "src/app/service/data.service";
import { StickerBase } from "./epen/StickerBase";
import { XMLNode } from "./xml/XMLNode";
import { AppStatusComponent } from "../appStatusModule/AppStatus.component";
import { Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

@Component({
	selector: 'ro-page',
	template:`
		<div>
		<div
			#elementRef
			class="page-container"
			[style.width.px]="page.attributes.w"
		[style.height.px]="page.attributes.h"
		[style.backgroundColor]="page.attributes.color" 
		>
		</div>
		<MarkingLayer #markingLayer [class.hide]="context && (context.showDefaultAnswer || !context.showMarkingLayer)" [pageCom]="pageCom" [viewMode]="context.config.viewMode" [context]="context" (change)="onMarkingChange($event)"
		></MarkingLayer>
	`,
	styles:[
		`
		.page-container{
			position:relative;
			overflow:hidden;
		}
		.hide{display: none;}
		`
	]
})
export class ROPageComponentContainer implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@ViewChild('elementRef', {static:true}) elementRef:ElementRef;
	@Input() info:any = null;
	@Input() book:any = null;
	@Input() page:any = null;
	@Input() context:ROContext = null;
	@Input() public printing:boolean = false;
	@ViewChild('markingLayer', {static:false}) public markingLayer:MarkingLayer;

	public built:boolean = false;
	public pageCom:ROPageComponent;
	// protected delayUpdateTimer:number = -1;
	private onMarkingChangeSubject:Subject<any> = new Subject();
	public hasData: boolean = false;

	public bookType = "";
	
	constructor(
		protected cdr:ChangeDetectorRef,
		private vc:ViewContainerRef,
		private dcs:DynamicComponentService,
	)
	{
		
		
	}
	ngOnDestroy(): void {
		if(this.pageCom)this.pageCom.destroy();
	}

	ngOnInit(): void {
		
		
			
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		
	}
	
	ngAfterViewInit(): void {
		this.onMarkingChangeSubject = new Subject();
		this.onMarkingChangeSubject.pipe(
			debounceTime(1000),
			filter((o)=>{
				return o ? true : false;
			})
		).subscribe(this.saveMarking);
	}

	updatePageInfo() :void
	{
		this.pageCom.updatePageInfo()
		this.page.info.score = this.pageCom.pageScore
		this.page.info.questionCount = this.pageCom.questionCount;
	}

	build():ROPageComponent
	{
		if(this.built == false)
		{
			/*
			console.log(this.context);
			var domContainer:HTMLElement = this.elementRef.nativeElement.querySelector(".page-container");
			var dom:HTMLElement = this.buildDOM();
			domContainer.appendChild(dom);
			*/
			this.built = true;
			this.pageCom = <ROPageComponent>this.context.createForEF(this.elementRef, this.page.pageNode, null, null);
			this.pageCom.chapter = this.page.chapter;
			this.pageCom.printing = this.printing;
		}

		if (this.pageCom.dataComponents[0] && this.pageCom.dataComponents[0].ctx) {
			if (this.pageCom.dataComponents[0].ctx.type) {
				this.bookType = this.pageCom.dataComponents[0].ctx.type
			}
		}
		return this.pageCom;
	}
	
	showComponentScore():void
	{
		this.pageCom.dataComponents.forEach((com:ROComponent)=>{
			if(com.isQuestionComponent())
			{
				com.showComponentScore();
			}
		});
	}
	setPageData(dataSource: AnswerSource) {
		this.setPageDataForVerify(dataSource, "auto");
	}

	protected waitReadyToSetData:any;

	setPageDataForVerify(dataSource: AnswerSource, verifyType:any) {
		if(dataSource) this.hasData = true;
		this.pageCom.source = dataSource;
		var chapterId = this.page.chapter.id;
		var pageId = this.page.attributes.douid;
		var answer:any = dataSource ? dataSource.getAnswer(chapterId, pageId, pageId) : null;
		this.pageCom.assignAnswer(answer);
		var verify:boolean;
		if(verifyType == "auto")
		{
			verify  = this.pageCom.state == 1;
		} else if(verifyType == "enabled")
		{
			verify = true;
		} else {
			verify = false;
		}

		let isSubmitted:boolean = this.pageCom.state == 1;
		let showVerifyAndScore:boolean = true; // todo 要根據分享的設定是否即時對

		var epens:any[] = dataSource ? dataSource.getMultipleEpenMarkings(chapterId, pageId) : [];
		// 不顯示都要 reset
		this.waitReadyToSetData = {
			markingEpen:this.context.config.showMarking && epens ? epens : [],
			markingSticker:this.context.config.showMarking && dataSource ? dataSource.getMarking(chapterId, pageId) : [],
			correction:dataSource ? dataSource.getCorrection(chapterId, pageId) : null,
			correctionPhoto:dataSource ? dataSource.getCorrectionPhoto(chapterId, pageId) : null
		};
		this.waitMarkingLayerReady();

		this.pageCom.dataComponents.forEach((com:ROComponent)=>{
			if (this.bookType && this.bookType === "speechPractice") {
				com.answerEnable(true);
			} else {
				com.answerEnable(!this.context.config.readOnly);
			}

			var answer:any = dataSource ? dataSource.getAnswer(chapterId, pageId, com.douid) :null;
			try
			{
				com.assignAnswer(answer);
				if(verify)
					com.verify(showVerifyAndScore);
			} catch(err)
			{
				console.error("assign answer failed", com, answer,err);
			}
		});
	}

	protected waitMarkingLayerReady():void {
		if(this.markingLayer) {
//			console.log("marking layer ready");
			if(this.waitReadyToSetData) {
//				console.log("set data", this.waitReadyToSetData);
				this.markingLayer.initEpenMarkingsAndCorrection(this.waitReadyToSetData.markingEpen, 
					this.waitReadyToSetData.correction, this.waitReadyToSetData.correctionPhoto);
				this.markingLayer.data2 = this.waitReadyToSetData.markingSticker;
				this.waitReadyToSetData = null;
			}
		} else {
//			console.log("wait layer ready", this.waitReadyToSetData);
			setTimeout(()=> {this.waitMarkingLayerReady()},10);
		}
	}

	activate():void
	{
		// console.log("activate", this.pageCom);
		this.pageCom.activate();
		if(this.markingLayer) this.markingLayer.reattach();
		this.cdr.reattach();
	}
	
	deactivate():void
	{
		// console.log("deactivate", this.pageCom);
		this.pageCom.deactivate();
		if(this.markingLayer) this.markingLayer.detach();
		this.cdr.detach();
	}

	@HostListener('pointerdown', ['$event'])
	ePressOnMarkingLayer(event:any):void {
		if(this.markingLayer && (this.context.config.viewMode=="scoring" || this.context.config.viewMode=="correction"))
			this.markingLayer.ePress(event);
	}
	@HostListener('touchstart', ['$event'])
	eTouchOnMarkingLayer(event:TouchEvent):void {
		if(this.markingLayer && (this.context.config.viewMode=="scoring" || this.context.config.viewMode=="correction"))
			this.markingLayer.ePress2(event,"touch");
	}


	public getPageNumberByID(douid:string):number {
		var pages:any[] = this.context.config.pages;
		var num:number = 0;
		for(let i:number=0; i<pages.length; i++) {
			var pageNode:XMLNode = pages[i].pageNode;
			if(pageNode.attributes.broadcast) {
				num++;
				if(pageNode.attributes.douid == douid)
					return num;
			}
		}
		return -1;
	}

	// =======================================================
	// update function
	// =======================================================
	private saveMarking(params):void
	{
		AppStatusComponent.callAPI('ROBookShare.update_student_result_by_index', params).then((res:any)=>{
			console.log("update marking success", res);
		}).catch(reason=>{
		});
	}
	public onMarkingChange(event):void {
		var chapterId = this.page.chapter.id;
		var pageId = this.page.attributes.douid;
		var dataSource:AnswerSource = this.pageCom.source;
		var config:ROBookConfig = this.context.config;
		if(event.type == "epenStart")
		{
			this.onMarkingChangeSubject.next(null);
		} else if(event.type == "epen") {
			var updateEpen:any = this.markingLayer.getChangedEpenMarkings();
			var tagName:string = updateEpen.tag;
			var comID:string = updateEpen.tag == "#correction#" ? tagName : tagName+updateEpen.creator;

			// 更新記憶體內記錄
			dataSource.setAnswer(chapterId, pageId, comID, updateEpen.data, null);
			
			// 更新 DB 記錄
			var chapterId = this.page.chapter.id;
			var pageId = this.page.attributes.douid;
			var dataSource:AnswerSource = this.pageCom.source;
			var config:ROBookConfig = this.context.config;
			var params:any[] = [
				config.share.id, config.book.id, dataSource.student.uid, config.index,
				chapterId, pageId, comID, updateEpen.data, null, tagName
			];

			this.onMarkingChangeSubject.next(params);
			/*
			this.clearDelayUpdateTimer();
			this.delayUpdateTimer = window.setTimeout((params)=> {
				AppStatusComponent.callAPI('ROBookShare.update_student_result_by_index', params).then((res:any)=>{
					console.log("update marking success", res);
				}).catch(reason=>{
				});
			}, 1000, params);
			*/
			this.markingLayer.redraw(event.redraw ? true :false);

		} else if(event.type == "correctionPhoto") {
			var updatePhoto:string = this.markingLayer.getChangedCorrectionPhotos();
			var tagName:string = "#correction_photo#";
			var comID:string = tagName;

			// 更新記憶體內記錄
			dataSource.setAnswer(chapterId, pageId, comID, updatePhoto, null);
			
			// 更新 DB 記錄
			var chapterId = this.page.chapter.id;
			var pageId = this.page.attributes.douid;
			var dataSource:AnswerSource = this.pageCom.source;
			var config:ROBookConfig = this.context.config;
			var params:any[] = [
				config.share.id, config.book.id, dataSource.student.uid, config.index,
				chapterId, pageId, comID, updatePhoto, null, tagName
			];

			this.onMarkingChangeSubject.next(params);

		} else if(event.type == "addSticker") {
			var sticker:StickerBase = event.data;
			var lesson_id = 0;// todo
			var pageNum = this.getPageNumberByID(pageId);
			
			AppStatusComponent.callAPI('Prize.addStudentMarking', 
			[sticker.sourceJSON.type, sticker.sourceJSON.remark.id, sticker.sourceJSON.num, dataSource.student.uid,
				config.share.school_year, config.share.tags[0].id,
				lesson_id, config.share.id, config.book.id, chapterId, pageId, config.index, pageNum, sticker.sourceJSON.remark
			]).then((res:any)=>{
				console.log("add sticker success", res);
				sticker.dataID = res.log_id;
				sticker.createTime = res.createTime;
				// todo 如果是星星，要更新畫面星星數量
			}).catch(reason=>{
			});

		} else if(event.type == "updateSticker") {
			var sticker:StickerBase = event.data;
			if(sticker.dataID==-1) {
				if(sticker.retryCount>3) {
					sticker.retryCount = 0; // 等有人叫佢再試
					return;
				}
				
				sticker.retryCount++;
				window.setTimeout((e)=>{
					this.onMarkingChange(e);
				}, 500, event);
				return;
			}

			AppStatusComponent.callAPI('Prize.updateStudentMarking', [sticker.dataID, sticker.sourceJSON.remark]).then((res:any)=>{
				console.log("update sticker success", res);
			}).catch(reason=>{
			});

		} else if(event.type == "removeSticker") {
			var sticker:StickerBase = event.data;
			AppStatusComponent.callAPI('Prize.updateStudentMarking', [sticker.dataID, null]).then((res:any)=>{
				console.log("remove sticker success", res);
				// todo 如果是星星，要更新畫面星星數量
			}).catch(reason=>{
			});
		}
	}

	/*
	protected clearDelayUpdateTimer():void {
		if(this.delayUpdateTimer!=-1) {
			clearTimeout(this.delayUpdateTimer);
			this.delayUpdateTimer = -1;
		}
	}
	*/
}
