import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, ElementRef, Renderer2 } from '@angular/core';
import { ModalService } from '../../modal.service';
import { CommonOldService } from '../../common-old.service';
import { DynamicCompDirective } from './dynamicComp.directive';
import { StorageService } from '../../storage.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';

@Component({
  selector: 'ng-modal',
  template: `
  <div #container [ngStyle]="style.modalStyle" [style.zIndex]="zIndex + 1" [ngClass]="['modal', modelLoaded?'':'not-ready', modal.name?modal.name:'']">
    <div class="modal__header" *ngIf="!modal.hideHeader">
      <div class="modal__header__cancel" (click)="headerClick('cancel')">{{'cancel'|translate}}</div>
      <div class="modal__header__title">{{title|translate}}</div>
      <div class="modal__header__confirm" (click)="headerClick('confirm')">{{'confirm'|translate}}</div>
    </div>
    <div class="modal__body" #body>
      <div class="top-shadow" *ngIf="!modal.hideHeader"></div>
      <ng-template #comp dynamic-comp></ng-template>
    </div>
  </div>
  <div [ngClass]="{'grey-layer':true,'hidden':!modelLoaded,'pc':storage.dev.isMobile == false}" [style.zIndex]="zIndex" (click)="layerClick()">
    <div class="grey-layer__content"></div>
  </div>
  `,
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {
  @Input() modal: any = {};
  // @ViewChild(DynamicCompDirective,{static: false}) public dynamicComp: DynamicCompDirective;
  @ViewChild(DynamicCompDirective,{static: true}) public dynamicComp: DynamicCompDirective;
  @ViewChild('body',{static: false}) public body: ElementRef;
  @ViewChild('container',{static: false}) public container: ElementRef;
  public comp;
  public zIndex;
  public style;
  public title;
  public modelLoaded;
  public modalIndex;
  constructor(public modalService: ModalService, public commonOld: CommonOldService, private factory: ComponentFactoryResolver,private renderer: Renderer2, public storage:StorageService, private lds: LoadingService) {
    this.modelLoaded = false;
  }

  ngOnInit() {
    if (this.modal.addLoading) {
      setTimeout(() => { this.lds.add('model'); }, 10);
    }
    this.modalIndex = this.modalService.modalList.indexOf(this.modal);
    this.zIndex = (this.modalIndex + 1) * 10;
    this.loadComp();
    // window.location.hash = '#ro/showDialog';
    this.renderer.addClass(document.body, 'lock-scroll');
  }

  loadComp() {
    let componentFactory = this.factory.resolveComponentFactory(this.modal.comp);
    let viewContainerRef = this.dynamicComp.viewContainerRef;
    let componentRef = viewContainerRef.createComponent(componentFactory);
    this.comp = componentRef.instance;
    this.style = {
      modalStyle: this.comp.modalStyle,
      headerColor: this.comp.headerColor
    };
    this.title = this.modal.data.title || this.comp.title;
    if (this.modal.data) {
      this.comp.data = this.modal.data;
      this.comp.data.container = this;
    }
    let tt$ = this.comp.loaded.subscribe(() => {
      this.modelLoaded = true;
      setTimeout(() => { this.lds.remove('model'); }, 10);
      tt$ = null;
    });
  }

  headerClick(btn) {
    if (btn == 'confirm') {
      if (this.checkValid() && this.comp.saveModal()) {
        this.closeModal();
      } else {
        return false;
      }
    } else if (btn == 'cancel') {
      if (this.comp.cancelModal())
        this.closeModal();
      else
        return false;
    }

  }

  closeModal() {
    let index = this.modalService.modalList.indexOf(this.modal);
    this.modalService.modalList.splice(index, 1);
    this.renderer.removeClass(document.body, 'lock-scroll');
    // window.location.hash = '#ro/hideDialog';
  }

  checkValid() {
    let invalid = this.body.nativeElement.querySelectorAll('.ng-invalid');
    if (invalid.length > 0) {
      invalid.forEach((el) => {
        if (el.classList.contains('ng-pristine')) {
          el.classList.remove('ng-pristine','ng-untouched');
          el.classList.add('ng-dirty','ng-touched');
        }
      });
      return false;
    } else {
      return true;
    }
  }

  layerClick(){
    if (this.modal.clickToExit) {
      this.closeModal();
    }
  }

}
