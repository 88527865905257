import { Component, HostListener, Output, EventEmitter, Input, ElementRef, OnInit, ViewChild, ApplicationRef, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes, faPalette } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { SubjectService } from './subject.service';
import { faCaretDown, faCaretUp, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';

import { Observable } from 'rxjs';
import { DOMHelper } from 'src/app/common/DOMHelper';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';

@Component({
	selector: "subject-selector2",
	template: `
    <div #openFrom>
	<a class="edit-target" [ngStyle]="options.editTargetStyle" (click)="editTargetClick($event, openFrom)" *ngIf="!dropDownOnly">
		<div *ngIf="buttonStyle=='text'" [ngClass]="['edit-target__text',(options.disabled?'disabled':''),'text']">
			<span>{{selectedItemsName}}</span>
		</div>

		<div *ngIf="buttonStyle=='select'" [ngClass]="['edit-target__text',(options.disabled?'disabled':''),'select']">
			<span>{{selectedItemsName}}</span>
			<div class="edit-target__text__arrow {{isOn?'opened':''}}"></div>
		</div>

		<div *ngIf="buttonStyle=='okaselect'" [ngClass]="['okaselect', isOn?'opened':'']">
			<div class="title">{{title}}{{titleSubfix}}</div>
			<div class="content">
				<span class="label">{{selectedItemsName}}</span>
				<fa-icon
				[icon]="(isOn ? faCaretUp : faCaretDown)"
				></fa-icon>
			</div>
		</div>
	</a>
    </div>
	<div #reference class="reference">
	
	
	
	<div class="layer" id="layer" *ngIf="isOn" (click)="layerClick($event)"></div>
	<bubbleBox2 
		#bubble 
		[padding]="0" 
		[backgroundColor]="'var(--section-maincolor, #684ccc)'"
		(onClose)="onClickOutside($event)"
		>
	<div #dropdown class="dropdown_container">
		<!--  *ngIf="isOn" -->
		<div  class="dropdown" [ngStyle]="options.dropdownStyle">
			<!-- <div class="triangle" [ngStyle]="options.triangleStyle"></div> -->
			<div class="left">
				<div class="searchBox">
					<input placeholder="{{'workspace.please-enter-keywords' | translate}}" [(ngModel)]="searchText">
					<div [ngClass]="['icon',searchText==''?'zoom':'close']" (click)="searchText=''"></div>
				</div>
				<div *ngIf="!searchText" class="tagRowContainer">
					<perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">

					<ng-container *ngIf="modalData.allSubject">
						<div class="option-row lineBelow" (click)="subjectClick(allSubject)">
							<div class="check">
								<fa-icon [class.checked]="isSelected(allSubject)" [icon]="(isSelected(allSubject)?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</div>
							<span>{{allSubject.title}}</span>
						</div>
					
						<div class="line"></div>
					</ng-container>

					<ng-container *ngIf="!options.publicOnly">
						<div class="option-row group-row">
						<fa-icon class="open" [icon]="(favOpen ? faChevronDown : faChevronRight)" (click)="favOpen = !favOpen"></fa-icon>
							<span class="fav-sj" (click)="favOpen = !favOpen">{{'workspace.my-favourites'|translate}}</span>
						</div>
						<ng-container *ngIf="favOpen">
						<div class="option-row tag-row" *ngFor="let sj of favSubjects">
							<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</div>
							<span class="fav-sj" (click)="subjectClick(sj)">{{sj.title}} ({{('workspace.subject-type-' + sj.type)|translate}}, {{('workspace.subject-level-' + sj.level) |translate}})</span>
							<div *ngIf="!options.publicOnly" class="option-row__heart bookmarked" (click)="subjectHeartClick(sj)"></div>
						</div>
						</ng-container>
						<div class="line"></div>
					</ng-container>

					<div class="option-row group-row">
						<fa-icon class="open" [icon]="(p1Open? faChevronDown : faChevronRight)" (click)="p1Open = !p1Open"></fa-icon>
						<span (click)="p1Open = !p1Open">{{'workspace.subject-type-p1'|translate}}</span>
					</div>
					<ng-container *ngIf="p1Open">
						<div *ngFor="let sj of p1" class="option-row tag-row" [attr.sjid]="sj.id">
						<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
						</div>
						<span (click)="subjectClick(sj)">{{sj.title}}</span>
						<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
					</ng-container>

					<ng-container *ngIf="!options.publicOnly">
						<div class="option-row group-row">
						<fa-icon class="open" [icon]="(s1Open? faChevronDown : faChevronRight)" (click)="s1Open = !s1Open"></fa-icon>
						<span (click)="s1Open = !s1Open">{{'workspace.subject-type-s1'|translate}}</span>
						</div>
						<ng-container *ngIf="s1Open">
						<div *ngFor="let sj of s1" class="option-row tag-row" [attr.sjid]="sj.id">
							<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</div>
							<span (click)="subjectClick(sj)">{{sj.title}}</span>
							<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
						</ng-container>
					</ng-container>

					<div class="option-row group-row">
						<fa-icon class="open" [icon]="(p2Open? faChevronDown : faChevronRight)" (click)="p2Open = !p2Open"></fa-icon>
						<span (click)="p2Open = !p2Open">{{'workspace.subject-type-p2'|translate}}</span>
					</div>
					<ng-container *ngIf="p2Open">
						<div *ngFor="let sj of p2" class="option-row tag-row" [attr.sjid]="sj.id">
						<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
						</div>
						<span (click)="subjectClick(sj)">{{sj.title}}</span>
						<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
					</ng-container>

					<ng-container *ngIf="!options.publicOnly">
						<div class="option-row group-row">
						<fa-icon class="open" [icon]="(s2Open? faChevronDown : faChevronRight)" (click)="s2Open = !s2Open"></fa-icon>
						<span (click)="s2Open = !s1Open">{{'workspace.subject-type-s2'|translate}}</span>
						</div>
						<ng-container *ngIf="s2Open">
						<div *ngFor="let sj of s2" class="option-row tag-row" [attr.sjid]="sj.id">
							<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</div>
							<span (click)="subjectClick(sj)">{{sj.title}}</span>
							<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
						</ng-container>
					</ng-container>

					<div class="option-row group-row">
						<fa-icon class="open" [icon]="(p3Open? faChevronDown : faChevronRight)" (click)="p3Open = !p3Open"></fa-icon>
						<span (click)="p3Open = !p3Open">{{'workspace.subject-type-p3'|translate}}</span>
					</div>
					<ng-container *ngIf="p3Open">
						<div *ngFor="let sj of p3" class="option-row tag-row" [attr.sjid]="sj.id">
						<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
						</div>
						<span (click)="subjectClick(sj)">{{sj.title}}</span>
						<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
					</ng-container>

					<ng-container *ngIf="!options.publicOnly">
						<div class="option-row group-row">
						<fa-icon class="open" [icon]="(s3Open? faChevronDown : faChevronRight)" (click)="s3Open = !s3Open"></fa-icon>
						<span (click)="s3Open = !s3Open">{{'workspace.subject-type-s3'|translate}}</span>
						</div>
						<ng-container *ngIf="s3Open">
						<div *ngFor="let sj of s3" class="option-row tag-row" [attr.sjid]="sj.id">
							<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</div>
							<span (click)="subjectClick(sj)">{{sj.title}}</span>
							<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
						</ng-container>
					</ng-container>

					<div class="option-row group-row">
						<fa-icon class="open" [icon]="(p0Open? faChevronDown : faChevronRight)" (click)="p0Open = !p0Open"></fa-icon>
						<span (click)="p0Open = !p0Open">{{'workspace.subject-type-p0'|translate}}</span>
					</div>
					<ng-container *ngIf="p0Open">
						<div *ngFor="let sj of p0" class="option-row tag-row" [attr.sjid]="sj.id">
						<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
						</div>
						<span (click)="subjectClick(sj)">{{sj.title}}</span>
						<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
					</ng-container>

					<ng-container *ngIf="!options.publicOnly">
						<div class="option-row group-row">
						<fa-icon class="open" [icon]="(s0Open? faChevronDown : faChevronRight)" (click)="s0Open = !s0Open"></fa-icon>
						<span (click)="s0Open = !s0Open">{{'workspace.subject-type-s0'|translate}}</span>
						</div>
						<ng-container *ngIf="s0Open">
						<div *ngFor="let sj of s0" class="option-row tag-row" [attr.sjid]="sj.id">
							<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</div>
							<span (click)="subjectClick(sj)">{{sj.title}}</span>
							<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
						</div>
						</ng-container>
					</ng-container>

					</perfect-scrollbar>
				</div>

				<div *ngIf="searchText" class="tagRowContainer">
				<perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
					<ng-container *ngFor="let sj of subject.subjects">
					<div class="option-row tag-row" *ngIf="sj.title.indexOf(searchText)>-1 && (!options.publicOnly || sj.type == 'public')" [attr.sjid]="sj.id">
						<div class="check" (click)="subjectClick(sj)">
							<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?faCheckCircle:faCheckCircleLight)"></fa-icon>
						</div>
						<span (click)="subjectClick(sj)">{{sj.title}} ({{('workspace.subject-type-' + sj.type)|translate}}, {{('workspace.subject-level-' + sj.level) |translate}})</span>
						<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
					</div>
					</ng-container>
				</perfect-scrollbar>
				</div>
			</div>

			<div class="right">
				<div class="label">
					<span>{{'workspace.selected' | translate}} ({{modalSelectedItems.length}}):</span>
					<div class="clear-all" (click)="clear()">{{'workspace.clear-all'|translate}}</div>
				</div>
				<div class="selected-list">
					<perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
					<div class="selected-list__selected-item" *ngFor="let subject of modalSelectedItems">
						<fa-icon class="icon faPalette" [icon]="faPalette"></fa-icon>
						<div class="text">
							<span>{{subject.title}}</span>
						</div>
						<fa-icon class="trash" [icon]="faTrash" (click)="clear(subject)"></fa-icon>
					</div>
					</perfect-scrollbar>

					<div *ngIf="multiSelect==false" class="selected-list__notice">
						<div class="selected-list__notice__image"></div>
						<div class="selected-list__notice__text">{{'workspace.single-subject-only'|translate}}</div>
					</div>
				</div>

				<div class="button-panel">
					<div class="confirm" (click)="confirmClick()">{{'alert.confirm'|translate}}</div>
					<div class="cancel" (click)="cancelClick()">{{'alert.cancel'|translate}}</div>
				</div>
			</div>
		</div>
		
	</div>
	</bubbleBox2>
	
	</div>
	
    `,
	styleUrls: ['./subject-selector2.component.scss']
})


export class SubjectSelector2Component implements OnInit, OnChanges {
	@ViewChild('bubble', { static: false }) bubble:BubbleBox2Component;
    @ViewChild('openFrom', { static: true }) openFrom:HTMLDivElement;
//    @HostBinding('style.zIndex') public hostZIndex = 0;
	
	public faCaretDown:IconDefinition = faCaretDown;
	public faCaretUp:IconDefinition = faCaretUp;
    public faPalette = faPalette;
	@Input() public buttonStyle:string = "text";
	@Input() public multiSelect:boolean = true;
	@Input() public title:string = "";
	@Input() public titleSubfix:string = "";
	@Input() public modalData:any = {};
	@Input() public field;
	@Input() public options: any = {};
	@Input() public dropDownOnly: boolean = false;
	@Output() public change: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('dropdown', { static: false }) dropdown;
	@ViewChild('reference', { static: false }) reference;
	
	@Input() public selectedItems: any = [];
	@Output() public selectedItemsChange = new EventEmitter<any>();
	@Output() public open = new EventEmitter<any>();
	@Output() public close = new EventEmitter<any>();
	public modalSelectedItems: any = [];
	public faSearch = faSearch;
	public faCheck = faCheck;
	public faCircle = faCircle;
	public faTrash = faTrash;
	public faChevronRight = faChevronRight;
	public faChevronDown = faChevronDown;
	public faTimes = faTimes;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public isTagLoaded: boolean = false;

	public searchText: string = "";
	public favSubjects: any[] = [];
	public allSubject:any;
	public p0: any = [];
	public p1: any = [];
	public p2: any = [];
	public p3: any = [];
	public s0: any = [];
	public s1: any = [];
	public s2: any = [];
	public s3: any = [];
	public isForAll: boolean = false;
	public isOn = false;
	public year: any = null;
	public favOpen = true;
	public p0Open = true;
	public p1Open = true;
	public p2Open = true;
	public p3Open = true;
	public s0Open = true;
	public s1Open = true;
	public s2Open = true;
	public s3Open = true;
	constructor(public subject: SubjectService,
		private elRef:ElementRef,public themeService:ThemeService,
		private translate: TranslateService, public datas: DataService, private load: LoadingService, public appRef: ApplicationRef, private trans: TranslateService, private coms:CommonService) {
		this.allSubject = {id:"all", title:"", titleKey:"schoolSetting.label.subject_level_type.all"};
        this.coms.waitFor(
        	()=>this.translate.translations[translate.currentLang].schoolSetting.label.subject_level_type.all, 30,100
        	).then(()=>{
            this.allSubject.title = this.translate.instant(this.allSubject.titleKey);
        });

		themeService.applyStyleObj({
			"default": {
				"okaselect-menubgcolor": "#f0f0f0"
			},
			"dark": {
				"okaselect-menubgcolor": "#2d2c43"
			}
		}, elRef.nativeElement);
		
//		this.hostZIndex = this.coms.getZIndex();
	}

	ngOnInit() {
	
	}
	
	private updateSubjectInfo():void
	{
		this.loadSubject().subscribe(e => {
			this.initSubjects();
		});
	}
	private loadSubject():Observable<any>
	{
		if(this.subject.subjects)
		{
			return  new Observable((subscriber) => {
				subscriber.next(this.subject.subjects);
				subscriber.complete();
			});
		} else {
			return this.subject.loadSubject();
		}
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		this.updateSubjectInfo();
	}

	editTargetClick($event:any, target:HTMLElement) {
        if(!this.isOn)
		{
            this.bubble.open(target, target);
            
            if($event)
                $event.stopPropagation();
            if (!this.modalData.disabled) {

                // 檢查有無設定預設的 style ，無則預設向下
                if(this.options && !this.options.hasOwnProperty("dropdownStyle")) {
                    let targetRect:DOMRect = this.elRef.nativeElement.getBoundingClientRect() as DOMRect;
                    this.options.dropdownStyle = {
                        left: ((targetRect.width-610)/2)+'px', 
                        top: (targetRect.height+(this.buttonStyle=='okaselect'?-3:5)-2)+'px'
                    };
                    if(this.options.offset)
                    {
                        this.options.triangleStyle = { 
                            left: (295 - this.options.offset.x)+'px', 
                            top: (-18 - this.options.offset.y)+ 'px', 
                            borderBottom: '10px solid' 
                        };
                    } else {
                        this.options.triangleStyle = { left: '295px', top: '-18px', borderBottom: '10px solid' };
                    }
                    
                }

                this.modalSelectedItems = this.selectedItems.map(e=>{
                    if(e == "all")
                        return this.allSubject;

                    let arr = e?e.split('-'):[];
                    let found  = null;
                    if(arr[0]>0)
                        found = this.subject.subjects.find(e=> e.public_subject_id == arr[0]);
                    else
                        found = this.subject.subjects.find(e=> e.school_subject_id == arr[1]);
                    return found;
                }).filter(e=>e);
                this.setOnOff();
            }
        }
		if(this.isOn)
		{
			if(this.options.appendTo)
			{
				var htmlContainer:HTMLElement
				if(typeof this.options.appendTo == "string")
				{
					htmlContainer = document.querySelector(this.options.appendTo);
				} else if(this.options.appendTo instanceof HTMLElement)
				{
					htmlContainer = this.options.appendTo;
				}
				
				if(htmlContainer)
				{
					var targetElement = this.dropdown.nativeElement;
					htmlContainer.appendChild(targetElement);
					var element = this.reference.nativeElement;
					var position = DOMHelper.getDOMGlobalPosition(element);
					var position2 = DOMHelper.getDOMGlobalPosition(htmlContainer);
					position.left -= position2.left;
					position.top -= position2.top;
					if(this.options.offset)
					{
						position.left += this.options.offset.x;
						position.top += this.options.offset.y;
					}

					targetElement.style.left = position.left+"px";
					targetElement.style.top = position.top+"px";
					targetElement.style.position = "absolute";
					targetElement.style.zIndex = this.coms.getZIndex();
				}
			}
			
		}
	}
	
	getSelectedSubjectTitle() {
		if (!this.modalData.subject || this.subject.subjects == null) {
			return '';
		} else {
			let subject = '' + this.modalData.subject;
			let arr = subject.split('-');
			if (arr.length == 2) {
				const found = this.subject.subjects.find(sj => sj.id == subject);
				return found ? found.title : '';
			} else {
				const found = this.subject.subjects.find(sj => sj.public_subject_id == subject);
				return found ? found.title : '';
			}
		}
	}

	layerClick($event) {
		$event.stopPropagation();
		this.setOnOff();

		if(!this.isOn)
			this.close.emit(this.selectedItems);
	}

	setOnOff() {
		this.isOn = !this.isOn;
		if (this.isOn) {
			this.modalData.subjectSelecting = 1;
			// debugger;
			// this.appRef.attachView(this.dropdown);
			// let appRoot = document.querySelector('app-root');
			// appRoot.appendChild(this.dropdown.elementRef.nativeElement);
		} else {
			this.modalData.subjectSelecting = 0;
		}
		this.restoreDOM();
	}

	private restoreDOM():void
	{
		if(!this.isOn)
		{
			if(this.options.appendTo)
			{
				DOMHelper.removeElement(this.dropdown.nativeElement);
			}
		}
	}


	initSubjects() {
		const subject = this.subject;
		subject.subjects.forEach(sj => {
			let varname = sj.type == 'public' ? 'p' : 's';
			const levelMap = [null, 'P1-6', 'S1-3', 'S4-6'];
			const index = levelMap.indexOf(sj.level);
			varname = varname + (index > -1 ? index : 0);
			this[varname].push(sj);
		});

		let promise = new Promise((resolve, reject) => {
			let s = setInterval(() => {
				if (this.datas.userInfo) {
					clearInterval(s);
					resolve(null);
				}
			}, 100);
		}).then((o:any):any=>{
			return this.loadAccountFavSubject(null);
		});
	}

	private favSubjectsReady:boolean= false;
	private loadAccountFavSubject(o:any = null):Promise<any>
	{
		if(this.favSubjectsReady) return; 
		let account = this.datas.userInfo;
		if (account.personalSettings) {
			if (typeof account.personalSettings == 'string') {
				account.personalSettings = JSON.parse(account.personalSettings);
			}
			if (account.personalSettings.workspace_fav_subjects) {
				account.personalSettings.workspace_fav_subjects.forEach(e => {
					const found = this.subject.subjects.find(sj => sj.id == e);
					if (found) {
						this.favSubjects.push(found);
					}
				});
			}
			this.favSubjectsReady = true;
		}
		return Promise.resolve(null);
	}

	subjectClick(sj) {
		if(!this.multiSelect) {
			// 不是多選，清空已選取，保留新選擇
			this.modalSelectedItems = [sj];
			return;
		}
			
		let found = this.modalSelectedItems.find(e => e.id == sj.id);
		if(found) {
			// 已是選取的，就取消選取
			this.modalSelectedItems = this.modalSelectedItems.filter(e => e.id != sj.id);
			// 有使用 all subject
			if(this.modalData.allSubject && this.modalSelectedItems.length==0)
				this.modalSelectedItems = [this.allSubject];
			return;
		}

		// 未選取
		if(sj == this.allSubject) {
			// 如果選擇 all ，就只有 all
			this.modalSelectedItems = [sj];
			return;
		}

		// 不選擇 all ，則要移除 all
		this.modalSelectedItems = this.modalSelectedItems.filter(e => e != this.allSubject);

		// 加入選擇
		this.modalSelectedItems.push(sj);
	}

	subjectHeartClick(sj) {
		const index = this.favSubjects.indexOf(sj);
		this.load.add('fav');
		this.datas.post('ROWorkspace.setFavSubject', [sj.id]).subscribe((res: any) => {
			if (index == -1) {
				this.favSubjects.push(sj);
			} else {
				this.favSubjects.splice(index, 1);
			}
			let ps = this.datas.userInfo.personalSettings;
			ps.workspace_fav_subjects = res.personalSettings.workspace_fav_subjects;
			this.load.remove('fav');
		});
	}

	isSelected(item) {
		return this.modalSelectedItems.find(e => e.id == item.id);
	}

	clear(subject = null) {
		if (subject) {
			this.modalSelectedItems = this.modalSelectedItems.filter(e => e.id != subject.id);
		} else {
			this.modalSelectedItems = [];
		}
		// 有使用 all subject
		if(this.modalData.allSubject && this.modalSelectedItems.length==0)
			this.modalSelectedItems = [this.allSubject];
	}

	confirmClick() {
		this.selectedItems = this.modalSelectedItems.map(e => e.id);
		this.selectedItemsChange.emit(this.selectedItems);
		this.cancelClick();
	}

	onClickOutside($event):void
	{
		this.cancel();
	}
	
	cancelClick() {
		this.bubble.close();
		this.cancel();
	}
	
	cancel():void
	{
		this.close.emit(this.selectedItems);
		this.isOn = false;
		this.modalData.subjectSelecting = 0;
		
		this.restoreDOM();
	}
	
	@Input() public placeHolder:string;
	get selectedItemsName() {
		if (!this.subject.subjects){
			return this.trans.instant('okaColorPulldown.pleaseSelect')+"...";
		}
		let subjects = this.selectedItems.map(e => {
			if(e == "all")
				return this.allSubject.title;
			if(e == null)
				return null;
			
			let subject_parts:any = e.split("-");
			let subObj:any = null;
			if(subject_parts[0]==0)
				subObj = this.subject.subjects.find(e=> e.school_subject_id == subject_parts[1]); // use school subject id
			else
				subObj = this.subject.subjects.find(e=> e.public_subject_id == subject_parts[0]); // use public subject id
			return subObj?subObj.title: null;
		}).filter(e => e);
		if(subjects.length > 0)
		{
			return subjects.join(', ');
		} else {
			if(this.placeHolder)
			{
				return this.placeHolder
			} else {
				return this.trans.instant('okaColorPulldown.pleaseSelect')+"...";
			}
			
		}
		// return subjects.length > 0 ? subjects.join(', ') : this.trans.instant('okaColorPulldown.pleaseSelect')+"...";
	}

}
