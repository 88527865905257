import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, EventEmitter, Injectable, Injector, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { WhitePopupComponent, WhitePopupFormComponent } from './whitePopup.component';
import { ObjectUtils } from 'src/app/common/ObjectUtils';

@Injectable({ providedIn: 'root' })
export class WhitePopupService {
    
    constructor(
		private componentFactoryResolver: ComponentFactoryResolver, 
		private appRef: ApplicationRef, 
		private injector: Injector
	) 
	{
    }
    

	/**
	this.whitePopupService.showForm(target, 
	  	{
			validate:()=>{

			},
			setting:{

			},
			value:{

			},
			fields:[
				{type:"boolean", key:"string", name: 'AAA'},
				{type:"boolean", key:"string", name: 'AAA'}
			]
	  	}
	).then((value:any)=>{
			
	})
	 
	 */
	public showForm(targetEle:HTMLElement, options:any):Observable<any>
	{
		var popup:WhitePopupFormComponent = this.create(WhitePopupFormComponent, {options:options})
		this.open(popup, targetEle);
		popup.waitUntilReady().then(()=>{
			var promise:Promise<any> = popup.open(targetEle);
			promise.finally(()=>{
				setTimeout(()=>{
					this.destroy(popup);
				}, 1000);
				
			})
			return promise.then(()=>{
				var data:any = popup.getValue();
				if(data)
				{
					return Promise.resolve(data);
				} else {
					return Promise.reject("cancel");
				}
			})
		});

		return popup.subject;

	}
	/**
	 * this.whitePopupService.promptSelection(target, [
			{title: 'AAA'},
			{title: 'BBB'},
			{title: 'CCC'}
		]).then(()=>{
			
		})
	 * @param selection 
	 * @param options : {bindLabel:"title"}
	 * @returns 
	 */
	public showSelection(targetEle:HTMLElement, selection:any[], options:any = null):Promise<any>
	{
		// <whitePopup #wp4 [menuItems]="wp4menu" (onMenuClick)="als.alert2($event.title)"></whitePopup>
		if(!selection || selection.length == 0) return Promise.reject('nothing to select');
		var o = {menuItems:selection};
		ObjectUtils.copyTo(options, o);
		var popup:WhitePopupComponent = this.create(WhitePopupComponent, o);
		this.open(popup, targetEle);
		return popup.waitUntilReady().then(()=>{
			var promise:Promise<any> = popup.open(targetEle);
			promise.finally(()=>{
				setTimeout(()=>{
					this.destroy(popup);
				}, 1000);
				
			})
			return promise.then(()=>{
				var data:any = popup.getSelectedItem();
				if(data)
				{
					return Promise.resolve(data);
				} else {
					return Promise.reject("cancel");
				}
			})
		});
	}

	/**
	 * this.whitePopupService.showMultiSelection(target, [
			{title: 'AAA', type},
			{title: 'BBB', selected:true},
			{title: 'CCC', selected:false}
		]).then(()=>{
			
		})
	 * @param selection 
	 * @param options : {bindLabel:"title"}
	 * @returns 
	 */
	public showMultiSelection(targetEle:HTMLElement, selection:any[], options:any = null):Observable<any>
	{
		var o = {menuItems:selection, multiSelect:true};
		ObjectUtils.copyTo(options, o);
		var popup:WhitePopupComponent = this.create(WhitePopupComponent, o);
		this.open(popup, targetEle);
		popup.waitUntilReady().then(()=>{
			var promise:Promise<any> = popup.open(targetEle);
			promise.finally(()=>{
				setTimeout(()=>{
					this.destroy(popup);
				}, 1000);
			});
		});
		return popup.emitter;
	}



	
//-------------------------------------------------------------------------------------------------
    public create<T>(T:Type<T>, para:any = {}):T {
        let compRef:ComponentRef<T> = this.componentFactoryResolver.resolveComponentFactory(T).create(this.injector);
        let comp:T = compRef.instance;
        comp['compRef'] = compRef;
        for (let key in para) if (comp.hasOwnProperty(key)) comp[key] = para[key];

        return comp;
    }
//-------------------------------------------------------------------------------------------------
    public open<T>(comp:T, appendEle:HTMLElement = document.body):void {
        if (!comp['compRef'] || comp['compRef'].hostView['_appRef']) return;
        this.appRef.attachView(comp['compRef'].hostView);
        appendEle.appendChild((comp['compRef'].hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
    }
//-------------------------------------------------------------------------------------------------
	public destroy<T>(comp:T):void {
        if (!comp['compRef'] || !comp['compRef'].hostView['_appRef']) return;
		comp["compRef"].destroy();
    }
    public close<T>(comp:T):void {
        if (!comp['compRef'] || !comp['compRef'].hostView['_appRef']) return;
        this.appRef.detachView(comp['compRef'].hostView);
    }
}
