import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SectionTitleComponent } from './sectionTitle.component';


@NgModule({
    imports: [
      CommonModule,
    ],
    declarations: [
      SectionTitleComponent
    ],
    exports: [
      SectionTitleComponent
    ],
    providers: [],
    bootstrap: []
})
export class SectionTitleModule { }
