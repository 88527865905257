import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { speechBox } from "./speechBox";
import { AlertService } from "src/app/service/alert.service";
import { ChatService } from "src/app/service/chatGPT.service";
import { DataService } from "src/app/service/data.service";
import { StorageService } from "src/app/service/storage.service";
import { LoadingService } from "../../loadingModule/loading.service";
import * as RecordRTC from 'recordrtc';
import { UploadService } from "../../uploadModule/upload.service";
import { ThemeService } from "src/app/service/theme.service";
import { ObjectUtils } from "src/app/common/ObjectUtils";

@Component({
	selector: "conversationPractice",
	templateUrl: "./conversationPractice.html",
	styleUrls: ["./conversationPractice.scss"],
})
export class conversationPractice {
	@Input() public context;
	@Input() public myData;
	@Input() public myRecorder;
	@Input() public lang;
	@Input() public parent;
	@Input() public redoConversation;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	@ViewChild("speechBox", { static: false }) speechBoxContainer: speechBox;
	@ViewChild('dialogContainer', { static: false }) dialogContainer!: any;
	@ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;

	// For media
	public recorder: any = null;
	public stream: any = null;
	public timer;
	public recordingTime = 30;
	public recordTimer;
	public isResReceived = false;
	public status: string = "idle";							// "idle" / "recording" / "loading" / "playing"
	public robotImageStatus: string = "idle";				// "idle" / "talk" / "correct" / "wrong" / "wrong_last"
	public hintQuota = 3;
	public audio: HTMLAudioElement = new Audio();
	public speed = 1.1;
	public AiSpeedOptions: any[] = [
		{ label: "Slow", value: 0.9 },
		{ label: "Normal", value: 1.1 },
		{ label: "Fast", value: 1.3 },
	];

	// For EDB Question
	public objective = [
		{ number: 1, content: "Use appropriate register when speaking to familiar interlocutors such as teachers and peers" },
		{ number: 2, content: "Apply grammar rules such as subject-verb agreement correctly" },
		{ number: 3, content: "Connect ideas by using cohesive devices" },
		{ number: 4, content: "Use appropriate intonation and stress, and vary volume, tone of voice and speed to convey intended meanings and feelings" },
		{ number: 5, content: "Open an interaction by greeting someone in an appropriate manner" },
		{ number: 6, content: "Open an interaction by introducing oneself giving some details" },
		{ number: 7, content: "Open an interaction by eliciting a response by asking questions or providing information on a topic" },
		{ number: 8, content: "Maintain an interaction by controlling participation in an interaction or group activities" },
		{ number: 9, content: "Maintain an interaction by recognizing others' desire to speak" },
		{ number: 10, content: "Maintain an interaction by asking and responding to others' opinions" },
		{ number: 11, content: "Maintain an interaction by acknowledging, agreeing or disagreeing, asking questions, replying, adding or giving examples and explaining, using formulaic expressions where appropriate" },
		{ number: 12, content: "Maintain an interaction by self-correcting or rephrasing questions and answers if they are not understood" },
		{ number: 13, content: "Maintain an interaction by predicting the likely development of a conversation and responding accordingly" },
		{ number: 14, content: "Close an interaction by using appropriate formulaic expressions" },
		{ number: 15, content: "Close an interaction by giving reasons" },
	];
	public objectiveUsed = [];
	public EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	// For Default Question
	public defaultQuestion = [];
	public questionIndex = 0;

	// Config
	public userName;
	public starNumberInExercise = 0;
	public totalStarNumber;
	public audioInfo = [];
	public promptLog = [];
	public conversation = [];
	public eachQuestionScore = [];
	public questionNumber = 1;

	public recordEnable = false;
	public isAnswerDefaultQuestion = false;
	public userGreetingPass = false;
	public userGreetingLimit = Math.floor(Math.random() * 2) + 3;
	public userGreetingCounter = 1;
	public userFirstEDBPass = false;
	public userEDBLimit = Math.floor(Math.random() * 2) + 3;
	public userEDBCounter = 1;
	public userEDB_retry_limit = 3;
	public userEDB_retry = 0;
	public userSecondEDBPass = false;
	public userDefaultQuestion_retry_limit = 3;
	public userDefaultQuestion_retry = 0;
	public defaultQuestionFinish = false;
	public receiveMyData = false;
	public isConfirmButtonShown = false;

	public aiOralChar = 'student';
	public networkCheckOn: boolean = false;

	// For Sound Wave
	private audioContext: AudioContext | null = null;
	private analyserNode: AnalyserNode | null = null;
	private mediaStreamAudioSourceNode: MediaStreamAudioSourceNode | null = null;
	private animationFrameId: number | null = null;
	private frameCount: number = 0;
	private framesToSkip: number = 4;

	constructor(
		private chat: ChatService, public dataService: DataService, public storage: StorageService, private lds: LoadingService,
		private alert: AlertService, public upload: UploadService, public datas: DataService, private eleRef: ElementRef, private theme: ThemeService
	) {
		this.theme.applyStyleObj({
			"default": { "section-maincolor": "#0052AF" },
			"dark": { "section-maincolor": "#0052AF" }
		}, this.eleRef.nativeElement);
	}

	async ngOnInit() {
		try {
			let viewMode = this.context.config.viewMode;
			this.userName = this.dataService.userInfo.last_name;
			this.aiOralChar = sessionStorage.getItem('aiOralChar') || '1';
			this.preloadImage();
			this.setDefaultQuestion();

			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			this.audioContext = new AudioContext();
			this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(stream);
			this.analyserNode = this.audioContext.createAnalyser();
			this.analyserNode.fftSize = 1024;
			this.mediaStreamAudioSourceNode.connect(this.analyserNode);

			if (viewMode !== "scoring" && viewMode !== "preview" && viewMode !== "review") {
				// if (this.parent.subType === "oralAI") this.greeting();
				// else this.intro();
				this.greeting();
			}

			setTimeout(() => {
				this.parent.totalPageNumber = this.defaultQuestion.length;
			}, 1000)
		} catch (err) {
			console.error('Error accessing microphone:', err);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes)
		if (!this.receiveMyData && changes.myData &&
			(changes.myData.currentValue.conversationData ||
				changes.myData.currentValue.conversationRecord)) {
			this.receiveMyData = true;
			this.conversation = changes.myData.currentValue.conversationRecord;
		} else if (changes.redoConversation && changes.redoConversation.previousValue === false && changes.redoConversation.currentValue === true) {
			this.hintQuota = 3;
			this.objectiveUsed = [];
			this.EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0];
			this.audioInfo = [];
			this.promptLog = [];
			this.conversation = [];
			this.eachQuestionScore = [];
			this.isAnswerDefaultQuestion = false;
			this.userGreetingPass = false;
			this.userGreetingCounter = 1;
			this.userFirstEDBPass = false;
			this.userSecondEDBPass = false;
			this.defaultQuestionFinish = false;
			// this.intro();
			this.greeting();
		}
	}

	ngOnDestroy() {
		if (this.audioContext) this.audioContext.close();
	}

	/////////////////////////////////////////
	//             Media function          //
	/////////////////////////////////////////
	public record(): void {
		this.status = "recording";
		navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
			let options = {
				mimeType: "audio/wav",
				numberOfAudioChannels: 1,
				desiredSampRate: 16000,
			};
			//Start Actuall Recording
			let StereoAudioRecorder: any = RecordRTC.StereoAudioRecorder;
			this.recorder = new StereoAudioRecorder(stream, options);
			this.stream = stream;
			this.recorder.record();
		});

		this.timer = setInterval(() => {
			if (this.recordingTime === 0) {
				this.finishRecording();
			} else {
				this.recordingTime = this.recordingTime - 1;
			}
		}, 1000);
	}

	startChecking() {
		this.status = "recording";
		if (this.analyserNode) {
			const canvas = this.canvas.nativeElement;
			const canvasCtx = canvas.getContext('2d');
			const data = new Float32Array(this.analyserNode.fftSize);

			const drawWaveform = () => {
				this.frameCount++;
				if (this.frameCount % this.framesToSkip === 0) {
					this.analyserNode.getFloatTimeDomainData(data);
					canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
					const barWidth = (canvas.width / data.length) * 80;
					const barGap = 15;
					let x = 0;
					for (let i = 0; i < data.length; i++) {
						const v = data[i] * 1000.0;
						canvasCtx.fillStyle = 'rgb(255, 255, 255)';
						canvasCtx.fillRect(x, canvas.height / 2 - v / 2, barWidth, v);
						x += barWidth + barGap;
					}
				}
				this.animationFrameId = requestAnimationFrame(drawWaveform);
			};
			drawWaveform();
		}
	}

	recordAction() {
		this.record();
		this.startChecking();
	}

	public finishRecording(): void {
		this.status = "loading";
		this.recordEnable = false;

		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = null;
		}

		this.recordingTime = 30;
		clearInterval(this.timer);
		this.recorder.stop((blob) => {
			let file: File = new File([blob], "record.mp3");

			let reference: string = this.context.createReference();
			let uid = this.context.service.dataService.userInfo.uid;

			if (this.parent.myData == null) {
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
				this.parent.myData = {
					user: uid,
					key: this.parent.fileReference.key,
					reference: this.parent.fileReference.reference
				}
			} else {
				this.parent.myData.user = uid;
				this.parent.myData.reference = reference;
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
			}

			this.parent.answerChanged = true;

			this.upload.uploadFileObject(file).then((res) => {
				const hostname = location.href.indexOf('localhost') > -1 ? 'dev.openknowledge.hk' : location.hostname;
				let filePath = '//' + hostname + '/RainbowOne/' + res.path;
				if (this.parent.subType === "oralAI") {
					//free conversation
					let file: File = new File([blob], "test.mp3");
					this.getSttApi(file, filePath);
				} else {
					this.ttsApi(filePath);
				}
			});

			let tracks = this.stream.getTracks();
			for (let track of tracks) track.stop();
		});
		this.status = "idle";
	}

	stopChecking() {
		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = null;
		}
	}

	stopAction() {
		this.finishRecording();
		this.stopAction();
	}

	private getSttApi(wavFile: File, filePath): Promise<void> {
		let lang = "en-US";
		let data: FormData = new FormData();
		data.append('api', 'ROSpeechRecognition.recognize_tts');
		data.append("json", JSON.stringify([filePath, "", lang]));
		const delayTime = 2000;

		return this.datas.post2({ data: data, loading: false }).then(async (res: any) => {
			console.log("delayTime", delayTime);
			var message: string = "";

			if (!res.result || !res.result.DisplayText) {
				setTimeout(() => {
					this.alert.alert2(this.translateText(
						"偵測不到聲音，請重新再試。",
						"偵測不到聲音，請重新再試。",
						"Cannot detect the sound recording, please try again"
					), null, { btns: [["ok"]] });
				}, 2000);
			} else {
				message = res.result.DisplayText;

			}
			this.promptLog.push({ role: "user", content: message })
			this.conversation.push({ role: "user", content: message, audioPath: filePath })

			let promptInput = [...this.promptLog].filter(message => !message.isHint).slice(-4);
			promptInput.unshift(this.promptLog[0])
			const robotRes = await this.sentPromptApi(promptInput);
			this.scrollToBottom();
			let content = JSON.parse(robotRes).choices[0].message.content;

			let audio_res = await this.playAudio(content, this.replaceSymbol(content), false);
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
			await audio_res.is_finish;
			this.recordEnable = true;
			return;
		}).catch((err: any) => {
			return;
		});
	}

	async ttsApi(filePath: string) {
		let lang = "en-US";
		let dataWithAnswer: FormData = new FormData();
		let dataWithoutAnswer: FormData = new FormData();
		dataWithAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithoutAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		if (this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.defaultQuestion[this.questionIndex].answer, lang]));
		} else {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.defaultQuestion[this.questionIndex].name, lang]));
		}
		dataWithoutAnswer.append("json", JSON.stringify([filePath, "", lang]));

		try {
			this.isResReceived = false;
			let firstRecordTime = Date.now();

			const recordTimeCount = setInterval(async () => {
				this.recordTimer = Date.now();
				console.log(this.recordTimer - firstRecordTime)

				// 超時
				if (this.recordTimer - firstRecordTime > 10000 && !this.isResReceived) {
					clearInterval(recordTimeCount);
					this.alert.toastError(this.translateText(
						"網絡繁忙，自動重試中，請稍候",
						"网络繁忙，自动重试中，请稍后",
						"Network latency, repeat processing automatically, result is going to release"
					));
				}
			}, 1000);

			if (this.isAnswerDefaultQuestion) {
				const firstRequest = await this.dataService.post2({ data: dataWithAnswer, loading: false });
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([firstRequest, secondRequest]).then(([firstRequest, secondRequest]) => {
					if (!firstRequest.result || !secondRequest.result ||
						firstRequest.result.RecognitionStatus === "InitialSilenceTimeout" ||
						secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						let userAnswer = this.handleUserAnswer(secondRequest.result.DisplayText);
						// console.log(userAnswer)
						if (this.parent.subType === "oralSentence") {
							this.conversation.push(
								{
									role: "user",
									content: this.defaultQuestion[this.questionIndex].name,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else if (this.parent.subType === "oralParagraph") {
							this.conversation.push(
								{
									role: "user",
									content: this.defaultQuestion[this.questionIndex].name,
									isParagraph: true,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else {
							this.conversation.push({ role: "user", content: userAnswer, audioPath: filePath })
						}
						this.promptLog.push({ role: "user", content: userAnswer })

						// console.log(firstRequest.result)
						this.eachQuestionScore[this.questionIndex] = {
							PronScore: Math.round(firstRequest.result.NBest[0].PronScore),
							AccuracyScore: Math.round(firstRequest.result.NBest[0].AccuracyScore),
							CompletenessScore: Math.round(firstRequest.result.NBest[0].CompletenessScore),
							FluencyScore: Math.round(firstRequest.result.NBest[0].FluencyScore)
						};

						this.scrollToBottom();
						if (this.parent.subType === "oralVocab" || this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
							// console.log(secondRequest.result)
							this.checkPronunciation(secondRequest.result);
						} else if (this.parent.subType === "oralSentence" || this.parent.subType === "oralParagraph") {
							// console.log(firstRequest.result)
							this.checkPronunciation(firstRequest.result);
						}
					}
				})
			} else {
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([secondRequest]).then(([secondRequest]) => {
					if (!secondRequest.result || secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						// console.log(secondRequest.result)
						this.promptLog.push({ role: "user", content: secondRequest.result.DisplayText })
						this.conversation.push({ role: "user", content: secondRequest.result.DisplayText, audioPath: filePath })

						this.scrollToBottom();
						if (!this.userGreetingPass) this.checkUserGreeting(secondRequest.result.DisplayText);
						else if (!this.userFirstEDBPass) this.checkUserFirstEDB(secondRequest.result.DisplayText);
						// else if (!this.userSecondEDBPass) this.checkUserSecondEDB(secondRequest.result.DisplayText);
					}
				})
			}
		} catch (err) {
			console.error("Assessment Fail", err);
		}
	}

	/////////////////////////////////////////
	//           GPT chat function         //
	/////////////////////////////////////////
	sentPromptApi(prompt: any[]) {
		return this.chat.oralChat(prompt, "EP_AI_ENG_CHAT");
	}

	content_filter_handle() {
		this.alert.alert2(this.translateText(
			"偵測到不適合用詞，請重新再試。",
			"侦测到不适合用词，请重新再试。",
			"Inappropriate word detected, please try again."
		), null, { btns: [["ok"]] });
		this.recordEnable = true;
	}

	// async intro() {
	// 	let promptA = {
	// 		role: "system",
	// 		content: `You are a teacher, you are having an oral training with a student of primary 6 student. From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`
	// 	}

	// 	let promptB = {
	// 		role: "system",
	// 		content: `The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): Foul: Please be polite and speak in a positive way. Yes: Could you explain more please, so I can better understand? No: Are you sure? I promise it will be fun! I don't know: I'm sorry to hear that. What do you need help on? idk: I'm sorry to hear that. What do you need help on? What?: Would you like me to repeat that? I don't mind. Um, like, you know: You've got a point! Just try to say it more directly without the fillers. Inappropriate words: Thank you for sharing! But that language isn't suitable for the discussion. Can you try again? Overly long response: That's a rich answer! It could be even better with some trimming.`
	// 	}
	// 	this.promptLog.push(promptA);
	// 	this.promptLog.push(promptB);

	// 	try {
	// 		const res = await this.sentPromptApi(this.promptLog);
	// 		let content = JSON.parse(res).choices[0].message.content;
	// 		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

	// 		if (res) {
	// 			let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
	// 			if (audioRes) setTimeout(() => this.greeting(), audioRes.duration * 1000);
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}

	// }

	async greeting() {
		if (this.parent.subType !== "oralAI") {
			let background = {
				role: "system",
				content: `You are a teacher, you are having an oral training with a student of primary 6 student. The topic is Food and Drink: Favorite food and drink. From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`
			};
			let scenario = {
				role: "system",
				content: `If the student answer you in a good response, please smoothly start the topic. The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): <Foul>: Please be polite and speak in a positive way. <Yes>: Could you explain more please, so I can better understand? <No>: Are you sure? I promise it will be fun! <I don't know>: I'm sorry to hear that. What do you need help on? <What>: Would you like me to repeat that? I don't mind. <Um, like, you know>: You've got a point! Just try to say it more directly without the fillers. <Overly long response>: That's a rich answer! It could be even better with some trimming.`
			};
			this.promptLog.push(background);
			this.promptLog.push(scenario);
		}

		let prompt = {
			role: "system",
			content: `Please greet the student and include his/her name ${this.userName} without starting the topic. Let the student respond to your greeting.`
		};

		if (this.parent.subType === "oralAI") {
			prompt = {
				role: "system",
				content: `Assistant is user's best friend John, Assistant has been living in Germany for five years and will be returning to Hong Kong during the Easter holidays. Assistant misses the local food in Hong Kong and wants user to take him to user's favorite restaurant. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
			};
		}
		this.promptLog.push(prompt);
		try {
			let content = "";
			if (this.parent.subType === "oralAI") {
				content = `Hi ${this.userName}, It's so great to be back in Hong Kong! I've really missed the food here. What restaurant do you recommend we go to?`

				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
				await audioRes.is_finish;
				this.recordEnable = true;
			} else {
				const res = await this.sentPromptApi(this.promptLog);
				content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserGreeting(content: string) {
		const prompt = {
			role: "system",
			content: `
Please determine whether the student's response correctly or not. 
If the student respond a greet or answered your question, please reply <correctGreet>. 
Only If the student saying something inappropriate, reply <wrongGreet>. 
Please be careful, the student may not only greet, but may also say other things. As long as the content is not inappropriate, you should reply <correctGreet>. 
If the student needs attention, do not reply <wrongGreet>, please reply <correctGreet>.
Determine <correctGreet> or <wrongGreet>.
`,
		}
		this.promptLog.push(prompt);
		try {
			const res = await this.sentPromptApi(this.promptLog);
			
			let res_obj = JSON.parse(res);
			if(res_obj.error && res_obj.error.code === "content_filter") {
				this.content_filter_handle();
				return ;
			}

			const content = res_obj.choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

			let result = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
			let resPrompt = { role: "system", content: "", };
			if (result === "wrongGreet") {
				if (this.userGreetingCounter === this.userGreetingLimit) {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. Also tell the student that he/she can not talk more like this and we must start our discussion topic now. (important) Do not use interrogative sentence or ask any question.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.userGreetingPass = true;
								this.firstEDBQuestion();
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. Also try to encourage the student to greet again. Do not start the topic.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.recordEnable = true;
								this.userGreetingCounter++;
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				}
			} else if (result === "correctGreet") {
				resPrompt.content = `Please respond to the student's greeting without questions. Also, answer their question if you can. If the student needs attention, use a sentence or two to comfort him or her. Finally, start describe the topic in one sentence without asking the student any questions. No question allowed.`
				this.promptLog.push(resPrompt);

				try {
					const res = await this.sentPromptApi(this.promptLog);
					const content = JSON.parse(res).choices[0].message.content;
					this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

					if (res) {
						let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
						if (audioRes) setTimeout(() => {
							this.userGreetingPass = true;
							this.firstEDBQuestion();
						}, audioRes.duration * 1000);
					}
				} catch (error) {
					console.log(error);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async firstEDBQuestion() {
		let background = {
			role: "system",
			content: `This is the English Speaking skills curriculum from the Education Bureau in Hong Kong (Known as the curriculum) please remember it. Present information, ideas and feelings clearly and coherently:
1. use appropriate register when speaking to familiar interlocutors such as teachers and peers (e.g. May I go to the toilet?)
2. apply grammar rules such as subject-verb agreement correctly (e.g. Peter plays football every Sunday.)
3. connect ideas by using cohesive devices (e.g. also, at last, before)
4. use appropriate intonation and stress, and vary volume, tone of voice and speed to convey intended meanings and feelings
5. open an interaction by greeting someone in an appropriate manner
6. open an interaction by introducing oneself giving some details
7. open an interaction by eliciting a response by asking questions or providing information on a topic (e.g. I’ve borrowed three very interesting books. Would you like to have a look?)
8. maintain an interaction by controlling participation in an interaction or group activities, e.g. taking one’s turn at the right moment
9. maintain an interaction by recognising others’ desire to speak (e.g. It’s my turn . . . It’s your turn now.)
10. maintain an interaction by asking and responding to others’ opinions (e.g. Do you like that book? What do you think of (name of a character in the book)?)
11. maintain an interaction by acknowledging, agreeing or disagreeing, asking questions, replying, adding or giving examples and explaining, using formulaic expressions where appropriate
12. maintain an interaction by self-correcting or rephrasing questions and answers if they are not understood
13. maintain an interaction by predicting the likely development of a conversation and responding accordingly
14. close an interaction by using appropriate formulaic expressions (e.g. See you tomorrow.)
15. close an interaction by giving reasons (e.g. Sorry, I have to see my teacher now.)`
		};
		let material = {
			role: "system",
			content: `This is the training material. 
Introducing their own favourite restaurants: Students can introduce their favourite restaurants with the AI while given hints on various aspects they are expected to share, such as their favourite menu, the restaurant’s location, the atmosphere of the restaurant, etc. They can be provided with interesting opportunities to talk about their familiar restaurants and elaborate on their everyday observations, strengthening their ability to express their feelings and describe a certain object, hence developing their language skills.
Grammar:
-Use “would” and “could” to politely express offers, requests, and ask for permission
-Present tense + future tense because the scene is taking place while speaking, rather than describing a past restaurant experience
Vocabulary Exercise (Easy)
Restaurant Waiter Breakfast Lunch Dinner
Cup Bottle Glass Plate Taste
Sweet Spicy Juice Coffee Egg
Can you name 5 more things you can see in a restaurant?
Vocabulary Exercise (Difficult)
Chef a professional cook in a restaurant
Organic produced without the use of chemicals
Menu the list of food available
Condiment a substance that is used to add flavour to food
Brunch a late morning meal between breakfast and lunch
Omelette a dish made by mixing eggs together and frying them, often with small pieces of other food such as cheese or vegetables
Stir-fry to fry small pieces of meat, vegetables, etc. quickly while mixing them around
Order to ask for something to be made, supplied, or delivered, especially in a restaurant or shop
Savour to enjoy food slowly, in order to enjoy it as much as possible
Feast a special meal with very good food or a large meal for many people
Serve to provide food or drinks; to work for
Etiquette the set of rules or customs that tells you what you should do in a particular situation, e.g. at the dinner table
Tender (of meat or vegetables) easy to cut or chew
Crispy very crunchy in a pleasant way, like potato chips
Buffet a meal where people serve themselves different types of food
Short Phrases Exercise
This restaurant is always open and serves breakfast, lunch and dinner.
Waiter, may I have some scrambled eggs, a cup of coffee, and two glasses of orange juice?
This special curry dish tastes both sweet and spicy.
Careful, sir. The plate is hot.
There are 2 bottles.
There is an extra charge if you wish to bring your own bottle of wine.
Short Phrases Exercise (Difficult)
I would like to order some crispy chicken to go along with my stir-fried.
The brunch menu says the omelette is their specialty.
This buffet is a feast, and I intend to savour every bite.
My compliments to the chef, for preparing such a tender steak.
It is typical restaurant etiquette to be seated before ordering.
Single Word Answer Exercise (Easy)
My wife and I would like a table for (讀答案) please. Two
May I have a () of water, please? Glass
Careful, sir. The () is hot. Plate
We just stopped () breakfast, sir. Serving
Enjoy your (), sir. Meal
Single Word Answer Exercise (Difficult)
I’d like some deep-fried (停一陣) chicken. Crispy
All our foods are sourced locally, completely () without chemicals. Organic
We do not serve () to minors. Alcohol
Ketchup, mustard, and mayonnaise are different kinds of (). Condiments
It’s bad (____) to chew with your mouth open. Etiquette
Short Phrase Answer Exercise (Easy) (
Good morning. Would you like some water? (聽題目)
A: I don’t drink water. (不用聽)
B: Yes, I would like some water.*
Are you ready to order?
A: Not yet. Could you give me a few more minutes?*
B: I don’t want to order.
How is your meal?
A: It is yum yum, so no thank you.
B: It’s delicious, thank you for asking.*
Is there anything else I can get you?
A: No, thank you.*
B: No, I want more to drink.
Would you like the bill?
A: Yes, please. I would like to pay by credit card.*
B: Yes, but I don’t want to pay.
Short Phrase Answer Exercise (Difficult)
I’m sorry sir, but we are fully booked.
A: I don’t care. I demand to be seated!
B: We didn’t have time to make a reservation. Can you help us out?
C: That’s alright. How long do I need to wait, then?*
D: What kind of terrible service is this?
We don’t start serving lunch until noon.
A: I see. I’ll have some breakfast instead, then.*
B: Can’t you make an exception for me?
C: Let me speak to your manager.
D: Give me lunch, or give me death!
Excuse me, sir, but your credit card has been declined.
A: That’s your problem, not mine.
B: I will pay by cash, then.*
C: There must be something wrong with your machine.
D: Run! We need to run now!
AI Conversation Exercise (Easy)
The AI is the cashier at a fast food restaurant, and the student has to order a meal. The menu would be provided on the screen, and students can choose what meal to order, as long as they successfully make their order.
AI Conversation Exercise (Difficult)
John, your best friend has been living in Germany for five years and will be returning to Hong Kong during the Easter holidays. He misses the local food in Hong Kong and wants you to take him to your favorite restaurant. Which restaurant would you recommend? What aspects particularly appeal to you?
Speaking Exercise
Read the following Passage
Tonight Mum taught me how to make pumpkin soup.
First, I heated the butter on a saucepan over medium heat. When it melted, Mum added some chopped onion and cooked them for two minutes. Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat. I did what happy Mum asked me to do and she prepared a small bowl for me.
I stirred the flour, ginger, corn and some salt together in the bowl. Mum mixed some milk and an egg together, then added the mixture to the soup. We cooked the soup over a low heat for 10 minutes until it became hot.
We also made some garlic bread, peach salad and vegetable spaghetti. We had a tasty meal tonight!
Mum and Dad clapped their hands and called me ‘Cherry the Best Cook !’ I am so happy!
`
		};
		let prompt = {
			role: "system",
			content: `You should generate base the English speaking skills curriculum and the training material topic and content. Put the curriculum point number inside <> and then put your question after that. Do not repeat the curriculum. Now generate your first question and emphasize the relationship to topic. For example: "<1> question content"`
		};
		this.promptLog.push(background);
		this.promptLog.push(material);
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			const content = JSON.parse(res).choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
			this.objectiveUsed.push(EDBNumber)
			let question = content.replace(/<.*?>\s*/g, '');

			if (res) {
				let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
				if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserFirstEDB(reply: string) {
		const prompt = {
			role: "system",
			content: `Rate students performance within 10 to 100 according to the point your selected, at least 10 marks should be given and only give the mark.`,
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			console.log(res);

			const content = JSON.parse(res).choices[0].message.content;
			this.EDBScore[this.objectiveUsed[this.objectiveUsed.length - 1] - 1] = parseInt(`${content}`);
			this.parent.EDBScore[this.objectiveUsed[this.objectiveUsed.length - 1] - 1] = parseInt(`${content}`);

			let resPrompt = { role: "system", content: "", };
			if (res) {
				if (this.userEDBCounter >= this.userEDBLimit + this.userEDB_retry) {
					let allow_retry = this.userEDB_retry < this.userEDB_retry_limit
					let need_retry = false
					if (allow_retry) {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave. (important) Do not use interrogative sentence or ask any question. All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							if the student answer is out of topic or not making sense, add <retry> in your reply and encourage student to retry (important).
							Your reply should related to the last question you asked. No question allowed.
						`;
					} else {
						resPrompt.content = `
							Answer their question with humor if you can. 
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave. (important) Do not use interrogative sentence or ask any question. All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important)Do not ask student to retry and end this section.
						`;
					}
					// resPrompt.content = `Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario and end this section but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave. (important) Do not use interrogative sentence or ask any question. All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.`;
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						let content = JSON.parse(res).choices[0].message.content;

						console.log("allow_retry : ", allow_retry);
						console.log("promptLog : ", this.promptLog);
						console.log("res: ", res);
						console.log("content: ", content);
						// debugger

						if (content.includes("<retry>")) {
							content = content.replace("<retry>", "");
							this.userEDB_retry++
							need_retry = true
							let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
							if (audioRes) setTimeout(async () => {
								if (need_retry == true) {
									resPrompt = { role: "system", content: `Try to rephrase previous question you asked.` }
								}
								this.promptLog.push(resPrompt);
								const res = await this.sentPromptApi(this.promptLog);
								const content = JSON.parse(res).choices[0].message.content;
								this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

								let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
								this.objectiveUsed.push(EDBNumber)
								let question = content.replace(/<.*?>\s*/g, '');

								if (res) {
									let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
									if (audioRes) setTimeout(() => {
										this.recordEnable = true;
										this.userEDBCounter++;
									}, audioRes.duration * 1000);
								}
							}, audioRes.duration * 1000);
						} else {
							this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
							if (res) {
								let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
								if (audioRes) setTimeout(() => {
									this.userFirstEDBPass = true;
									this.defaultQuestionSession();
								}, audioRes.duration * 1000);
							}
						}


					} catch (error) {
						console.log(error);
					}
				} else {
					let allow_retry = this.userEDB_retry < this.userEDB_retry_limit
					let need_retry = false
					if (allow_retry) {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave. (important) Do not use interrogative sentence or ask any question. All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							if the student answer is out of topic or not making sense, add <retry> in your reply and encourage student to retry (important).
						`;
					} else {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave. (important) Do not use interrogative sentence or ask any question. All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important)Do not ask student to retry.
						`;
					}

					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						let content = JSON.parse(res).choices[0].message.content;

						console.log("allow_retry : ", allow_retry);
						console.log("promptLog : ", this.promptLog);
						console.log("res: ", res);
						console.log("content: ", content);
						// debugger

						if (content.includes("<retry>")) {
							content = content.replace("<retry>", "");
							this.userEDB_retry++
							need_retry = true
						}
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

						if (res) {
							let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
							if (audioRes) setTimeout(async () => {
								if (need_retry == false) {
									resPrompt = { role: "system", content: `Try to ask a new question.` }
								} else {
									resPrompt = { role: "system", content: `Try to rephrase previous question you asked.` }
								}

								this.promptLog.push(resPrompt);
								const res = await this.sentPromptApi(this.promptLog);
								const content = JSON.parse(res).choices[0].message.content;
								this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

								let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
								this.objectiveUsed.push(EDBNumber)
								let question = content.replace(/<.*?>\s*/g, '');

								if (res) {
									let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
									if (audioRes) setTimeout(() => {
										this.recordEnable = true;
										this.userEDBCounter++;
									}, audioRes.duration * 1000);
								}
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	// async secondEDBQuestion() {
	// 	let prompt = {
	// 		role: "system",
	// 		content: `Your goal is to stimulate critical thinking and ensure deep understanding of the material. Your task is to generate a question based on one of the specific educational objectives from ${this.objective}. Return with the format: [objective's number, "question you generated"], the question you generated must with quotes and avoid using the objective number ${this.objectiveUsed[this.objectiveUsed.length - 1]}. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`
	// 	};
	// 	this.promptLog.push(prompt);

	// 	try {
	// 		const res = await this.sentPromptApi(this.promptLog);
	// 		const question = JSON.parse(res).choices[0].message.content;
	// 		const questionString = this.modifiedString(question)
	// 		const array = JSON.parse(questionString)
	// 		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(questionString) });

	// 		if (res) {
	// 			let audioRes = await this.playAudio(array[1], this.replaceSymbol(`${array[1]}`), false, array[0]);
	// 			if (audioRes) setTimeout(() => {
	// 				this.objectiveUsed.push(array[0]);
	// 				this.recordEnable = true;
	// 			}, audioRes.duration * 1000);
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }

	// async checkUserSecondEDB(content: string) {
	// 	const prompt = {
	// 		role: "system",
	// 		content: `Determine whether ${content} is a correct response to your question or not, and give score over 100 based on how the content relates to the ${this.objective[this.objectiveUsed.length - 1].content}. Return with the format: [true / false, the score], the response must put into an array. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`,
	// 	}
	// 	this.promptLog.push(prompt);

	// 	try {
	// 		const res = await this.sentPromptApi(this.promptLog);
	// 		const content = JSON.parse(res).choices[0].message.content;
	// 		this.EDBScore[this.objectiveUsed[1] - 1] = parseInt(`${content[content.length - 3]}${content[content.length - 2]}`);
	// 		this.parent.EDBScore[this.objectiveUsed[1] - 1] = parseInt(`${content[content.length - 3]}${content[content.length - 2]}`);
	// 		let jsonObject = JSON.parse(content);
	// 		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

	// 		let resPrompt = { role: "system", content: "", };
	// 		if (res) {
	// 			if (jsonObject[0]) {
	// 				resPrompt.content = `${this.userName} did well in the last question, your task is to praise ${this.userName} for the performance. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`;
	// 			} else {
	// 				resPrompt.content = `${this.userName} could perform better in the last question, your task is to encourage ${this.userName}. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`;
	// 			}
	// 			this.promptLog.push(resPrompt);

	// 			const res = await this.sentPromptApi(this.promptLog);
	// 			const content = JSON.parse(res).choices[0].message.content;
	// 			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

	// 			if (res) {
	// 				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
	// 				if (audioRes) setTimeout(() => {
	// 					this.userSecondEDBPass = true;
	// 					this.defaultQuestionSession();
	// 				}, audioRes.duration * 1000);
	// 			}
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }

	async defaultQuestionSession() {
		this.isAnswerDefaultQuestion = true;
		try {
			if (this.questionIndex < 1) {
				const prompt = {
					role: "system",
					content: `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to inform ${this.userName} that you are going to start the exercise about today's topic. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`
				};
				this.promptLog.slice(0, 1);
				this.promptLog.push(prompt);

				const res = await this.sentPromptApi(this.promptLog);
				const content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => {
						this.askDefaultQuestion();
					}, audioRes.duration * 1000);
				}
			} else {
				this.askDefaultQuestion();
			}
		} catch (error) {
			console.log(error)
		}
	}

	async askDefaultQuestion() {
		let intro = "";
		let question = this.defaultQuestion[this.questionIndex].name;
		let reading = this.defaultQuestion[this.questionIndex].reading;
		let audioContent;

		if (this.parent.subType === "oralFillIn") intro = `Please answer the missing part of the phrase:`
		else if (this.parent.subType === "oralMc") intro = `Which response would you choose, if someone says:`
		else if (this.parent.subType === "oralParagraph") intro = `Please read this:`
		else if (this.parent.subType === "oralSentence") intro = `Please read the phrase:`
		else if (this.parent.subType === "oralVocab") intro = `Please read the vocab:`
		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(intro) });

		if (this.parent.subType === "oralFillIn") {
			const split = question.split("(");
			let firstPartReadingText = split[0].trim();
			let secondPartReadingText = split[1].split(")")[1].trim();
			let fillInTypeTopic = `${intro} ${firstPartReadingText} ${''} ${secondPartReadingText}`;

			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${reading}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: fillInTypeTopic, question: `${intro} ${question}` };
			}
		} else if (this.parent.subType === "oralMc") {
			audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
		} else {
			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: intro, question: `${intro} ${question}` };
			}
		}

		let audioRes = await this.playAudio(audioContent.intro, audioContent.question, true);
		if (audioRes) setTimeout(async () => {
			this.parent.totalPageNumber = this.defaultQuestion.length;
			this.parent.pageIndex = this.questionNumber;
			if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					let content = `A:${this.defaultQuestion[this.questionIndex].choice[0]}`
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(async () => {
						let content = `B:${this.defaultQuestion[this.questionIndex].choice[1]}`
						let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);

						if (audioRes) setTimeout(async () => {
							this.recordEnable = true;
						}, audioRes.duration * 1000)
					}, audioRes.duration * 1000)
				} else {
					let contentA = `A:${this.defaultQuestion[this.questionIndex].choice[0]}`
					this.conversation.push({ role: "assistant", content: contentA });
					let contentB = `B:${this.defaultQuestion[this.questionIndex].choice[1]}`
					this.conversation.push({ role: "assistant", content: contentB });

					this.recordEnable = true;
					this.scrollToBottom();
				}
			} else {
				this.recordEnable = true;
			}
		}, audioRes.duration * 1000);
	}

	async checkPronunciation(content: any) {
		console.log({content})

		let prompt = { role: "system", content: "", };
		if (this.parent.subType === "oralVocab") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.DisplayText} as the same as ${this.defaultQuestion[this.questionIndex].name} and ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If one of these criteria's result is false, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralSentence") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralFillIn") {
			let userAnswer = content.NBest[0].Lexical.charAt(0).toUpperCase() + content.NBest[0].Lexical.slice(1);
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${userAnswer} as the same as ${this.defaultQuestion[this.questionIndex].answer} and ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} to practice more. If yes, tell the student that ${userAnswer} is correct. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralMc") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.DisplayText} as the same as ${this.defaultQuestion[this.questionIndex].answer} and ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralParagraph") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not use the number as the response and end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		}
		this.promptLog.push(prompt);
		// this.promptLog.push({
		// 	role: "system",
		// 	content: `Put a <continue> in the end of you response if the student doing greet. Put a <retry> in the end of your response if you think the student need a retry.`
		// })

		try {
			const res = await this.sentPromptApi(this.promptLog);
			console.log(res);
			let res_obj = JSON.parse(res);
			if(res_obj.error && res_obj.error.code === "content_filter") {
				this.content_filter_handle();
				this.askDefaultQuestion();
				return ;
			}
			const content = res_obj.choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			if (res) {
				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				if (audioRes) setTimeout(() => {
					if (this.questionIndex < this.defaultQuestion.length - 1) {
						this.questionIndex++;
						this.questionNumber++;
						this.defaultQuestionSession();
					} else {
						this.finishing();
					}
				}, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error)
		}
	}

	public async finishing() {
		try {
			let content = "Your exercise is finished! Here is your result."
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
			if (audioRes) setTimeout(() => {
				// this.isConfirmButtonShown = true;
				this.passingBackToParent();
			}, audioRes.duration * 1000);
		} catch (error) {
			console.log(error);
		}
	}

	/////////////////////////////////////////
	//          js handling function       //
	/////////////////////////////////////////
	scrollToBottom(): void {
		if (this.dialogContainer) {
			const container = this.dialogContainer.nativeElement;
			setTimeout(() => {
				container.scrollTop = container.scrollHeight;
			}, 500);
		}
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}

	extractValue(stringOfObject) {
		const regex = /"[^"]+"\s*:\s*(true|false)/;
		const match = stringOfObject.match(regex);
		if (match) {
			if (match[1] === "true") return true;
			else return false;
		}
		else return false;
	}

	setRobotImageStatus(status: string) {
		if (this.robotImageStatus === status) return;
		this.robotImageStatus = status;
		if (status === "wrong") {
			setTimeout(() => {
				this.setRobotImageStatus("wrong_last");
			}, 3000);
		} else if (status === "correct") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			});
		} else if (status === "wrong_last") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			}, 3000);
		}
	}

	async playAudio(text: string, conversationContent: string, askDefaultQuestion: boolean, EDBNumber?: null | string) {
		let preLoad = { role: "assistant", content: "", loading: true };
		this.conversation.push(preLoad);
		this.scrollToBottom();

		try {
			let res = await this.getAudioDuration(text)
			if (res) {
				preLoad.loading = false;
				this.audioInfo.push({ url: res.url, duration: res.duration })

				if (EDBNumber) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), EDBNumber: EDBNumber }, preLoad
					);
				} else if (this.parent.subType === "oralParagraph" && this.isAnswerDefaultQuestion) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), isParagraph: true }, preLoad
					);
				} else {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent) }, preLoad
					);
				}

				if (askDefaultQuestion) {
					if (this.parent.subType === "oralVocab") {
						this.conversation.push({ role: "assistant", content: this.defaultQuestion[this.questionIndex].url });
					}
				}

				this.scrollToBottom();
				this.status = 'playing';
				this.setRobotImageStatus("talk");
				this.audio.setAttribute("src", res.url);
				this.audio.play();
				res.is_finish = new Promise((resolve) => {
					setTimeout(() => resolve(true), res.duration * 1000);
				})

				setTimeout(() => {
					this.setRobotImageStatus("idle");
					this.status = 'idle';
				}, res.duration * 1000);

				return (res);
			}
		} catch (error) {
			console.log(error)
		}
	}

	getAudioDuration(text: string): Promise<any> {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: 'F',
			txt: text,
			speed: this.speed,
			lang: 'en-GB',
			pitch: 1,
			name: "en-GB-LibbyNeural",
			style: "",
		};
		this.setRobotImageStatus("talk");

		return new Promise((resolve) => {
			this.datas.get2({ url: url, data: data, jwt: false, loading: false }).then((res: any) => {
				if (res.token && res.token.url) {
					let statusUrl: string = res.token.url;
					if (statusUrl.substr(0, 7) == "http://") statusUrl = "https://" + statusUrl.substring(7);
					this.datas.get2({ url: statusUrl, jwt: false, loading: false }).then((res: any) => {
						if (res.url) {
							const audio = new Audio(`${res.url}`);
							audio.addEventListener('loadedmetadata', () => {
								const url = res.url;
								const duration = Math.round(audio.duration);
								resolve({ url, duration });
							});
						}
					});
				}
			});
		});
	}

	passingBackToParent() {
		let duration = this.audioInfo[this.audioInfo.length - 1].duration + 2;
		if (this.parent.subType !== "oralParagraph") duration = duration + 2;
		else duration = duration + 4;
		setTimeout(() => {
			this.parent.isOralVocabReportOpen = true;
			this.parent.conversationData = this.eachQuestionScore;
			this.parent.myData.conversationData = this.eachQuestionScore;
			this.parent.myData.conversationFinish = true;
			this.parent.myData.objectiveUsed = [...(new Set(this.objectiveUsed)).values()];
			this.parent.myData.EDBScore = this.EDBScore;
			let stringifyResult = JSON.stringify(this.conversation);
			let uriEncodeResult = encodeURIComponent(stringifyResult);
			let encodeResult = btoa(uriEncodeResult);
			this.parent.myData.conversationRecord = encodeResult;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, duration * 1000)
	}

	listenButtonClicked(filePath) {
		this.audio.setAttribute("src", filePath);
		this.audio.play();
	}

	replaceSymbol(content) {
		if (content.includes('’')) {
			content = content.replace(/’/g, "'");
		}
		if (content.includes('"')) {
			content = content.replace(/"/g, "");
		}
		if (content.includes(';')) {
			content = content.replace(/-/g, ",");
		}
		return content;
	}

	setDefaultQuestion() {
		if (this.parent.subType === "oralVocab") {
			if (this.parent.difficulty !== "hard") {
				this.defaultQuestion = [
					{
						name: "Restaurant",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/restaurant.png"
					},
					{
						name: "Waiter",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/waiter.png"
					},
					{
						name: "Breakfast",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/breakfast.png"
					},
					{
						name: "Lunch",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/lunch.png"
					},
					{
						name: "Dinner",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/dinner.png"
					},
					// {
					// 	name: "Cup",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/cup.png"
					// },
					{
						name: "Bottle",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/bottle.png"
					},
					// {
					// 	name: "Glass",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/glass.png"
					// },
					// {
					// 	name: "Plate",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/plate.png"
					// },
					{
						name: "Taste",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/taste.png"
					},
					// {
					// 	name: "Sweet",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/sweet.png"
					// },
					// {
					// 	name: "Spicy",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/spicy.png"
					// },
					// {
					// 	name: "Juice",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/juice.png"
					// },
					{
						name: "Coffee",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/coffee.png"
					},
					// {
					// 	name: "Egg",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/egg.png"
					// },
				]
			} else {
				this.defaultQuestion = [
					{
						name: "Chef",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/chef.png"
					},
					{
						name: "Organic",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/organic.png"
					},
					{
						name: "Menu",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/menu.png"
					},
					{
						name: "Condiment",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/condiment.png"
					},
					{
						name: "Brunch",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/brunch.png"
					},
					{
						name: "Omelette",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/omelette.png"
					},
					// {
					// 	name: "Stir-fry",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/stir-fry.png"
					// },
					// {
					// 	name: "Order",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/order.png"
					// },
					// {
					// 	name: "Savour",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/savour.png"
					// },
					// {
					// 	name: "Feast",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/feast.jpg"
					// },
					// {
					// 	name: "Serve",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/serve.png"
					// },
					// {
					// 	name: "Etiquette",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/etiquette.png"
					// },
					{
						name: "Tender",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/tender.png"
					},
					// {
					// 	name: "Crispy",
					// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/crispy.png"
					// },
					{
						name: "Buffet",
						url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/buffet.png"
					},
				]
			}
		} else if (this.parent.subType === "oralSentence") {
			if (this.parent.difficulty !== "hard") {
				this.defaultQuestion = [
					{
						name: "This restaurant is always open and serves breakfast, lunch and dinner."
					},
					{
						name: "Waiter, may I have some scrambled eggs, a cup of coffee, and two glasses of orange juice?"
					},
					{
						name: "This special curry dish tastes both sweet and spicy."
					},
					{
						name: "Careful, sir. The plate is hot."
					},
					{
						name: "There is an extra charge if you wish to bring your own bottle of wine."
					}
				]
			} else {
				this.defaultQuestion = [
					{
						name: "I would like to order some crispy chicken to go along with my stir-fried."
					},
					{
						name: "The brunch menu says the omelette is their specialty."
					},
					{
						name: "This buffet is a feast, and I intend to savour every bite."
					},
					{
						name: "My compliments to the chef, for preparing such a tender steak."
					},
					{
						name: "It is typical restaurant etiquette to be seated before ordering."
					}
				]
			}
		} else if (this.parent.subType === "oralFillIn") {
			if (this.parent.difficulty !== "hard") {
				this.defaultQuestion = [
					{
						name: "My wife and I would like a table for (____) please.",
						reading: "My wife and I would like a table for two please.",
						answer: "Two"
					},
					{
						name: "Careful, sir. The (____) is hot.",
						reading: "Careful, sir. The plate is hot.",
						answer: "Plate"
					},
					{
						name: "Enjoy your (____), sir.",
						reading: "Enjoy your meal, sir.",
						answer: "Meal"
					},
					{
						name: "May I have a (____) of water, please?",
						reading: "May I have a glass of water, please?",
						answer: "Glass"
					},
					{
						name: "We just stopped (____) breakfast, sir.",
						reading: "We just stopped serving breakfast, sir.",
						answer: "Serving"
					}
				]
			} else {
				this.defaultQuestion = [
					{
						name: "I would like some deep-fried (____) chicken.",
						reading: "I would like some deep-fried crispy chicken.",
						answer: "Glass"
					},
					{
						name: "All our foods are sourced locally, completely (____) without chemicals.",
						reading: "All our foods are sourced locally, completely organic without chemicals.",
						answer: "Organic"
					},
					{
						name: "We do not serve (____) to minors.",
						reading: "We do not serve alcohol to minors.",
						answer: "Alcohol"
					},
					{
						name: "Ketchup, mustard, and mayonnaise are different kinds of (____).",
						reading: "Ketchup, mustard, and mayonnaise are different kinds of condiments.",
						answer: "Condiments"
					},
					{
						name: "It's bad (____) to chew with your mouth open.",
						reading: "It's bad etiquette to chew with your mouth open. ",
						answer: "Etiquette"
					},
				]
			}
		} else if (this.parent.subType === "oralMc") {
			if (this.parent.difficulty !== "hard") {
				this.defaultQuestion = [
					{
						name: "Good morning. Would you like some water?",
						choice: ["I don't drink water.", "Yes, I would like some water."],
						answer: "Yes, I would like some water."
					},
					{
						name: "Are you ready to order?",
						choice: ["Not yet. Could you give me a few more minutes?", "I don't want to order."],
						answer: "Not yet. Could you give me a few more minutes?"
					},
					{
						name: "How is your meal?",
						choice: ["It is yum yum, so no thank you.", "It's delicious, thank you for asking."],
						answer: "It's delicious, thank you for asking."
					},
					{
						name: "Is there anything else I can get you?",
						choice: ["No, thank you.", "No, I want more to drink."],
						answer: "No, thank you."
					},
					{
						name: "Would you like the bill?",
						choice: ["Yes, please. I would like to pay by credit card.", "Yes, but I don't want to pay."],
						answer: "Yes, please. I would like to pay by credit card."
					}
				]
			} else {
				this.defaultQuestion = [
					{
						name: "I'm sorry sir, but we are fully booked.",
						choice: ["I don't care. I demand to be seated!", "That's alright. How long do I need to wait, then?"],
						answer: "That's alright. How long do I need to wait, then?"
					},
					{
						name: "We don't start serving lunch until noon.",
						choice: ["I see. I'll have some breakfast instead, then.", "Can't you make an exception for me?"],
						answer: "I see. I'll have some breakfast instead, then."
					},
					{
						name: "Excuse me, sir, but your credit card has been declined.",
						choice: ["That's your problem, not mine.", "I will pay by cash, then."],
						answer: "I will pay by cash, then."
					}
				]
			}
		} else if (this.parent.subType === "oralParagraph") {
			this.defaultQuestion = [
				{
					name: `Tonight Mum taught me how to make pumpkin soup.`,
				},
				{
					name: `First, I heated the butter on a saucepan over medium heat.`,
				},
				{
					name: `When it melted, Mum added some chopped onion and cooked them for two minutes.`,
				},
				{
					name: `Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat.`,
				},
			]
		}
	}

	modifiedString(string: string): string {
		return string.replace(/(\d+), (.+)\]/, (match, p1, p2) => {
			// Check if p2 is already enclosed in quotes
			if (p2.startsWith('"') && p2.endsWith('"')) {
				return `${p1}, ${p2}]`;
			} else {
				return `${p1}, "${p2}"]`;
			}
		});
	}

	preloadImage() {
		let url = ['oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/robot_waiter_talk.png', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_correct.png', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong.gif', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong_last.png'];
		if (this.aiOralChar == '2') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/panda_talking.png');
		} else if (this.aiOralChar == '3') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/robot_talk.png');
		} else {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/student_talk.png');
		}
		url.forEach(e => {
			const imgEle = new Image();
			imgEle.src = '//' + e;
		});
	}

	handleUserAnswer(userAnswer: string) {
		if (this.parent.subType === "oralFillIn") {
			let question = this.defaultQuestion[this.questionIndex].reading;
			let answer = this.defaultQuestion[this.questionIndex].answer;
			let isIncluded = question.toLowerCase().includes(answer.toLowerCase());
			let index = question.toLowerCase().indexOf(answer.toLowerCase());
			let isUppercase = false;
			if (index !== -1) {
				isUppercase = question[index] === question[index].toUpperCase();
			}

			// console.log(isIncluded, isUppercase)
			if (isUppercase) return userAnswer;
			else return userAnswer.charAt(0).toLowerCase() + userAnswer.slice(1);
			// else if (isIncluded) return userAnswer.charAt(0).toLowerCase() + userAnswer.slice(1);
			// else return userAnswer;
		} else if (this.parent.subType === "oralMc") {
			// let choices = ["choice a", "choice b", "choice c", "choice d"];
			// choices.forEach(choice => {
			// 	let index = userAnswer.toLowerCase().indexOf(choice);
			// 	if (index !== -1) {
			// 		return userAnswer = userAnswer.slice(0, index) + userAnswer.slice(index + choice.length + 2);
			// 	} else {
			// 		return userAnswer;
			// 	}
			// });
			return userAnswer;
		} else {
			return userAnswer;
		}
	}

	handleRecognitionWord(word: Array<any>) {
		let recognitionWordArray = []
		for (let item of word) {
			recognitionWordArray.push(item);
		}
		// console.log(recognitionWordArray);
		return recognitionWordArray;
	}

	readText(text: string): void {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: 'F',
			txt: text,
			speed: this.speed,
			lang: 'en-GB',
			pitch: 1,
			name: "en-GB-LibbyNeural",
			style: "",
		};
		this.setRobotImageStatus("talk");

		this.datas.get2({ url: url, data: data, jwt: false }).then((res: any) => {
			if (res.token && res.token.url) {
				let statusUrl: string = res.token.url;
				if (statusUrl.substr(0, 7) == "http://")
					statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
				this.datas
					.get2({ url: statusUrl, jwt: false })
					.then((res: any) => {
						if (res.url) {
							this.audio.setAttribute("src", res.url);
							this.audio.play();
							setTimeout(() => {
								this.setRobotImageStatus("idle");
							}, 5000);

							//});
						}
					});
			}
		});
	}

	getHint() {
		if (this.hintQuota <= 0) {
			return
		}
		//只用最後4條訊息獲取對話 節省tokens
		var tempConversation = [...this.conversation].filter(message => !message.isHint).slice(-4);
		tempConversation.push(
			{
				role: 'user',
				content: "How should I respond your question? give me an example in 20 words"
			}
		)

		this.chat.oralChat(tempConversation).then(res => {
			let content = JSON.parse(res).choices[0].message.content;
			this.conversation.push({ role: "user", content: this.replaceSymbol(content), isHint: true });
			this.hintQuota--;
			this.scrollToBottom();
		});
	}

	not_repeat_hint(hint,hint_idx){
		for (const index in this.objectiveUsed) {
			if (index >= hint_idx) {
				break
			}
			if (this.objectiveUsed[index] == hint) {
				return false
			}
		}
		return true
	}
}
