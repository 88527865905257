import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { ObjectUtils } from "../common/ObjectUtils";

@Injectable({ providedIn: 'root' })
export class TTSService {
	protected _textProcessor:TTSTextPreprocessor;
	protected _ttsChannel:HTMLAudioElement;
	protected state:string = "empty";
	protected sentencesURL:string[];
	protected sentenceIndex:number;

	constructor(private datas: DataService) {
		this._textProcessor = new TTSTextPreprocessor();
		this._ttsChannel = new Audio();
		this._ttsChannel.addEventListener('ended', ()=>{
			this.playNextSentence();
		});
	}

	loadTTS(ttsSetting:any):Promise<any> {
		if(!ttsSetting || !ttsSetting.txt || ttsSetting.txt=="")
			return Promise.reject("input error");
		
		let defaultValue = {
			gender:"F",
			speed: 0.8,
			lang: "zh-HK",
			pitch: 1,
			name: "zh-HK-HiuGaaiNeural",
			style: ""
		};

		for(var key in defaultValue)
		{
			if(!ttsSetting.hasOwnProperty(key))
				ttsSetting[key] = defaultValue[key];
		}

		return this.datas.get2({
			url: "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token",
			data: ttsSetting,
			jwt: false
		}).then((res: any) => {
			if (res.token && res.token.url) {
				let statusUrl: string = res.token.url;
				if (statusUrl.substr(0, 7) == "http://")
					statusUrl = "https://" + statusUrl.substring(7);
				return this.datas
					.get2({ url: statusUrl, jwt: false })
					.then((res: any) => {
						if (res.url)
							return Promise.resolve(res.url);
						return Promise.reject("load fail");
					});
			}
			return Promise.reject("load fail");
		});
	}

	play(text:string, preprocess:string = "0", ttsSetting:any = {}):Promise<any> {
		if(!text || text=="")
			return Promise.reject("input error");
		
		let defaultValue = {
			gender:"F",
			speed: 0.8,
			lang: "zh-HK",
			pitch: 1,
			name: "zh-HK-HiuGaaiNeural",
			style: ""
		};

		for(var key in defaultValue) {
			if(!ttsSetting.hasOwnProperty(key))
				ttsSetting[key] = defaultValue[key];
		}

		var sentences:string[] = this.conbineToSentences(this._textProcessor.process(ttsSetting.lang, preprocess, text));
		console.log(sentences);
		var requests:any[] = [];
		sentences.forEach((txt:string) => {
			var reqData:any = ObjectUtils.clone(ttsSetting);
			reqData.txt = txt;
			requests.push(this.loadTTS(reqData));
		});

		this.state = "loading";
		this._ttsChannel.pause();

		return Promise.all(requests).then(urls=>{
			this.sentenceIndex = 0;
			this.sentencesURL = urls;
			this.playNextSentence();
			return Promise.resolve(urls);
		});
	}

	stop():void {
		if(this.state == "playing") {
			this.state = "stop";
			this._ttsChannel.pause();
			this.sentenceIndex = 0;
			this._ttsChannel.setAttribute("src", this.sentencesURL[this.sentenceIndex]);
		}
	}

	pause():void {
		if(this.state == "playing") {
			this.state = "pause";
			this._ttsChannel.pause();
		}
	}

	protected playNextSentence():void {
		if(this.sentencesURL && this.sentenceIndex<this.sentencesURL.length) {
			this.state = "playing";
			this._ttsChannel.setAttribute("src", this.sentencesURL[this.sentenceIndex]);
			this._ttsChannel.play();
			this.sentenceIndex++;
		} else {
			this.stop();
		}
	}

	protected conbineToSentences(blocks:any[]):string[] {
		var txtLength:number = 0;
		var txtArray:any[] = [];
		blocks.forEach((b:any) => {
			if(b.type == "text") {
				var original:string = b.text;
				var txt:string = original.trim().replace(/^\|(.*)/, "$1").replace(/^(.*)\|$/, "$1");
				if(txt) {
					if(txtLength + txt.length >= 128) {
						txtLength = 0;
						txtArray.push("¶");
					}
					txtLength += txt.length + 1;
					txtArray.push(txt);
					if(original.indexOf("¶") != -1) {
						txtLength = 0;
						txtArray.push("¶");
					}
				}
			} else if(b.type == "pause") {
				txtArray.push("|");
			} else if(b.type == "break") {
				txtLength = 0;
				txtArray.push("¶");
			} else if(b.type == "abbr") {
				txtLength = 0;
				txtArray.push(b.text);
			}
		});

		return txtArray.join(" ").replace(/( \| )|( \|)|(\| )/g, "|").split("¶")
			.map(str=>this.trimTTS(str))
			.filter(str=>str!="");
	}

	protected trimTTS(txt:string):string {
		return txt.trim()
			.replace(/^\|(.*)/, "$1")
			.replace(/^(.*)\|$/, "$1")
			.trim().replace(/( ){1,}/g, " ");
	}
}

// =======================================
// 
// =======================================
class TTSTextPreprocessor {
	private mathProcessor:MathTextPreprocessor;
	private punctuationProcessor:PunctuationPreprocessor;
	private breakObject:Object = {type:"break"};
	private splitter:SentenceSplitter;
	
	constructor() {
		this.mathProcessor = new MathTextPreprocessor();
		this.punctuationProcessor = new PunctuationPreprocessor();
		this.splitter = new SentenceSplitter ();
	}

	/* return [
		{type:"text/pause/break",text:""}, ...
	]
	*/
	public process(lang:string, preprocess:string, text:string):any[] {
		if (lang == "en-GB") {
			lang = "eng";
		} else if (lang == "zh-HK" || lang == "zh-CN") {
			lang = "chi";
		} else {
			return [{text:text, type:"text"}];
		}
		
		var output:any[];
		if (preprocess == "math") {
			output = this.mathProcessor.process(lang, text);
		} else if (preprocess == "punctuation") {
			output = this.punctuationProcessor.process(lang, text);
		} else {
			// ! ? <> { } [ ] ( ) , ; " " : .
			output = [this.replaceContent(text)];
		}
		
		
		/*
		var output:Array = splitText(
			queue,
			[": ", "; ", "。", ", ", ". ", " “", "，", "! ", "\" ", "” ", "? ", "\n"], // split at the end of the pattern
			[" because ", " at ", " who ", " how ", " when ", " where ", " why ", " what ", " and ", " or "]  // split at the beginning of the pattern
		);
		*/
		
		return this.splitter.split(output);
	}
	
	private replaceContent(text:string):string {
		// replaceContent
		["_", ">", "<", "《", "》", "「", "」", "/", " "].forEach((t:string) => {
			text = text.split(t).join(" ");
		});
		return text;
	}
	
	private splitAndAppendToQueue(queue:any[], text:string, textArray:any[], index:number):void {
		textArray.forEach((t:string) => {
			var start:number = text.indexOf(t, 0);
			while (start >= 0) {
				queue.push((start + index) + ((index >= 0) ? 0 : (t.length + 1)));
				start = text.indexOf(t, start + t.length);
			}
		});
	}

	private splitText(queue:any[], splitPattern1Array:any[], splitPattern2Array:any[]):any[] {
		var outputArray:any[] = [];
		queue.forEach((q:any) => {
			var array:any[] = [];
			if (typeof q === 'string') {
				var text:string = q as string;
				this.splitAndAppendToQueue(array, text, splitPattern1Array, -1);
				this.splitAndAppendToQueue(array, text, splitPattern2Array, 0);
				
				array.sort((a, b) => a - b);
				array.push(text.length);

				var start:number = 0;
				array.forEach((end:any) => {
					outputArray.push(text.substring(start, end));
					start = end;
				});
			} else {
				outputArray.push(q);
			}
		});

		outputArray.forEach((q:any, i:number) => {
			if (typeof q === 'string')
				outputArray[i] = (q == "\n" || q == "\r") ? this.breakObject : {text:outputArray[i], type:"text"};
		});
		return outputArray;
	}
	
	private splitLines(lang:string, text:string):any[] {
		return text.split(lang == "chi" ? /[。，\n]/g : ". ");
	}

}

// =======================================
// 
// =======================================
class PunctuationPreprocessor {
	private dictionary:any[];
	private symbolMap:any;
	private pauseObject:any = {type:"pause"};

	constructor() {
		this.dictionary = [
			{"reg": /[—]{1,2}/g, "chi": "破折號", "eng": "dash"},
			{"reg": /[᠁⋯…]{1,2}/g, "chi": "省略號", "eng": "ellipsis"},
			{"symbol": "......", "chi": "省略號", "eng": "ellipsis"},
			{"symbol": "...", "chi": "省略號", "eng": "ellipsis"},
			{"symbol": "&", "chi": "和", "eng": "and"},
			{"symbol": "<", "chi": "", "eng": ""},
			{"symbol": ">", "chi": "", "eng": ""},
			{"symbol": "[", "chi": "左方括號", "eng": "Opening Square Bracket"},
			{"symbol": "]", "chi": "右方括號", "eng": "Closing Square Bracket"},
			{"symbol": "{", "chi": "左花括號", "eng": "Opening Curly Bracket"},
			{"symbol": "}", "chi": "右花括號", "eng": "Closing Curly Bracket"},
			
			////////////////
			{"symbol": "「", "chi": "開引號", "eng": "Left Bracket"},
			{"symbol": "」", "chi": "關引號", "eng": "Right Bracket"},
			{"symbol": "《", "chi": "開書名號"},
			{"symbol": "》", "chi": "關書名號"},
			{"symbol": "（", "chi": "開括號", "eng": "Left Bracket"},
			{"symbol": "）", "chi": "關括號", "eng": "Right Bracket"},
			{"symbol": "、", "chi": "頓號"},
			{"symbol": "，", "chi": "逗號", "eng": "Comma"},
			{"symbol": "。", "chi": "句號"},
			{"symbol": "；", "chi": "分號", "eng": "Semicolon"},
			{"symbol": "：", "chi": "冒號", "eng": "Colon"},
			{"symbol": "！", "chi": "感嘆號", "eng": "Exclamation mark"},
			{"symbol": "？", "chi": "問號", "eng": "Question mark"},
			{"symbol": "／", "chi": "或且", "eng": "or"},
			{"symbol": "＝", "chi": "等於", "eng": "Equals to"},
			{"symbol": "(", "chi": "開括號", "eng": "Left Parentheses"},
			{"symbol": ")", "chi": "關括號", "eng": "Right Parentheses"},
			{"symbol": ",", "chi": "逗號", "eng": "Comma"},
			{"reg": /(\.$)|(\. )/g, "eng": "Full Stop"},
			{"symbol": ";", "chi": "分號", "eng": "Semicolon"},
			{"symbol": ":", "chi": "冒號", "eng": "Colon"},
			{"symbol": "!", "chi": "感嘆號", "eng": "Exclamation mark"},
			{"symbol": "?", "chi": "問號", "eng": "Question mark"},
			{"symbol": "/", "chi": "或且", "eng": "or"},
			{"symbol": "=", "chi": "等於", "eng": "Equals to"}
		];

		this.symbolMap = {};
		this.dictionary.forEach((o:any) => {
			this.symbolMap[o.symbol] = o;
		});
	}
	
	private replaceSymbol(queue:any[], lang:string, symbol:string):void {
		if (this.symbolMap.hasOwnProperty(symbol)) {
			var o:any = this.symbolMap[symbol];
			if (o.hasOwnProperty(lang))
				queue.push(this.pauseObject, o[lang], this.pauseObject);
		}
	}

	private replace(lang:string, text:string, obj:any):string {
		if (obj.hasOwnProperty(lang)) {
			var glue:string = "|" + obj[lang] + "|";
			if (obj.symbol) {
				if (typeof obj.symbol === 'string') {
					return text.split(obj.symbol).join(glue);
				} else if (Array.isArray(obj.symbol)) {
					(obj.symbol as []).forEach((splitter:string)=> {
						text = text.split(splitter).join(glue);
					});
					return text;
				}
			} else if (obj.reg) {
				return text.replace(obj.reg, glue);
			} 
		}
		return text;
	}

	public process(lang:string, text:string):any[] {
		this.dictionary.forEach((o:any) => {
			text = this.replace(lang, text, o);
		});

		return this.splitText(text, ["|"], [], this.pauseObject);
	}
	
	private splitText(text:string, splitPattern1Array:any[], splitPattern2Array:any[], glue:Object):any[] {
		var array:any[] = [];
		
		this.splitAndAppendToQueue(array, text, splitPattern1Array, -1);
		this.splitAndAppendToQueue(array, text, splitPattern2Array, 0);
		
		array.sort((a, b) => a - b);
		array.push(text.length);
		var outputArray:any[] = [];
		var start:number = 0;
		array.forEach((end:any, i:number) => {
			var t:string = text.substring(start, end);
			if (i != 0)
				outputArray.push(glue);
			t = t.replace(/\|/g, "");
			outputArray.push(t);
			start = end;
		});

		return outputArray;
	}

	private splitAndAppendToQueue(queue:any[], text:String, textArray:any[], index:number):void {
		textArray.forEach((t:string) => {
			var start:number = text.indexOf(t, 0);
			while (start >= 0) {
				queue.push((start + index) + ((index >= 0) ? 0 : (t.length + 1)));
				start = text.indexOf(t, start + t.length);
			}
		});
	}
}

// =======================================
// 
// =======================================
class MathTextPreprocessor {
	private pauseObject:any = {type:"pause"};
	private dictionary:any[];
	private symbolMap:any;
		
	constructor() {
		this.dictionary = [
			{"symbol": ">", "chi": "大於", "eng": "larger than"},
			{"symbol": "<", "chi": "小於", "eng": "smaller than"},
			{"symbol": "＝", "chi": "等於", "eng": "Equals to"},
			{"symbol": "[", "chi": "開括號", "eng": "Open Bracket"},
			{"symbol": "]", "chi": "關括號", "eng": "Close Bracket"},
			{"symbol": "(", "chi": "開括號", "eng": "Open parentheses"},
			{"symbol": ")", "chi": "關括號", "eng": "Close Parentheses"},
			{"symbol": ";", "chi": "分號", "eng": "Colon"},
			{"symbol": "=", "chi": "等於", "eng": "Equals to"},
			{"symbol": "＋", "chi": "加", "eng": "Plus"},
			{"symbol": "+", "chi": "加", "eng": "Plus"},
			{"symbol": "—", "chi": "減", "eng": "Minus"},
			{"symbol": "-", "chi": "減", "eng": "Minus"},
			{"symbol": "×", "chi": "乘", "eng": "Times"},
			{"symbol": "*", "chi": "乘", "eng": "Times"},
			{"symbol": "÷", "chi": "除", "eng": "Divided by"},
			{"symbol": "/", "chi": "除", "eng": "Divided by"},
			{"symbol": "±", "chi": "正負", "eng": "plus and minus"},
			{"symbol": "--", "chi": "負", "eng": "negative"},
			{"symbol": "++", "chi": "正", "eng": "postive"},
			{"symbol":"!", "eng":"factorial", "chi":"階乘"}
		];
		this.symbolMap = {};
		this.dictionary.forEach((o:any) => {
			this.symbolMap[o.symbol] = o;
		});
	}
	
	private replaceSymbol(queue:any[], lang:string, symbol:string):void {
		if (this.symbolMap.hasOwnProperty(symbol)) {
			var o:any = this.symbolMap[symbol];
			if (o.hasOwnProperty(lang))
				queue.push(o[lang]);
		}
		queue.push(symbol);
	}
	
	public process(lang:string, text:string):any[] {
		var queue:any[] = [];
		/*
		{"fn":ChineseTextHelper.countBig5Txt, "label":"big", "key":"big", "operation":matchTxt}, // 
				{"fn":ChineseTextHelper.countGBTxt, "label":"gb", "key":"gb", "operation":matchTxt}, // ,
				{"range":"30-39", "label":"num", "key":"num", "operation":matchRange} ,
				{"text":"「」《》（）；：！.,。，？", "label":"math", "key":"symbol", "operation":matchText},
				*/
				
		var i:number;
		var filterSize:number;
		var mapList:any[] = [
			{reg:"[0-9.]", type:"N"},
			{reg:"[A-Z|a-z]", type:"V"},
			{reg:"[!+*\\x2f\\x2d\\xd7\\x25\\xf7]", type:"O", once:1},
			{reg:'[=><]', type:"E", once:1},
			{reg:"[\\x28\\x29\\x5b\\x5d]", type:"P", once:1 },
			// other text
			// {"range":"3040-309F", "key":"ja", "operation":matchRange}, // jap
			{reg:"[\\u3040-\\u309F]", type:"T", "label":"Hiragana"},
			// {"range":"AC00-D7AF", "key":"kor", "operation":matchRange}, // korean
			{reg:"[\\uAC00-\\uD7AF]", type:"T", "label":"Hangul"},
			// {"range":"4E00-9FFF", "label":"CJK Unified Ideographs", "key":"cjk", "operation":matchRange}, // jap or chinese
			{reg:"[\\u4E00-\\u9FFF]", type:"T", label:"CJK Unified Ideographs"}
			// {"range":"0020-007F", "label":"Basic Latin", "key":"latin", "operation":matchRange   }, // english
			/*
			{
				reg:"[\\u0020-\\u007F]",
				type:"T"
				label:"latin"
			}
			*/
		];
		var regArray:any[] = [];
		var map:any = {};

		mapList.forEach((o:any, _i:number) => {
			regArray.push(
				"(" + 
					o.reg + 
					(o.once ? "" : "{1,}") + 
				")"
			);
			map[_i+1] = o.type;
		});

		var r:string = "/" + regArray.join("|") + "/";
		var a:any[] = r.match(/^\/(.+)\/([igsmx]*)$/);
		var reg:RegExp = new RegExp(a[1], "g");
		var match:any;
		var list:any[] = [];
		while (match = reg.exec(text)) {
			for (var i:number = 1; i <= filterSize; i++) {
				if (match[i] !== undefined) {
					list.push({
						type:map[i],
						text:match[0],
						index:match.index
					})
					break;
				}
			}
		}
		filterSize = list.length;
		var m:any[] = [
			{
				replacement:"--",
				format:[
					[null, {type:"P", text:"("}, {type:"E"}],
					[{type:"O",text:"-"}],
					[{type:"N"}]
				],
				replace:1
			},
			{
				replacement:"×",
				format:[
					[
						{type:"P", text:")"}, {type:"N"}
					],
					[
						{type:"V",text:"x"},
						{type:"V",text:"X"}
					],
					[
						{type:"P", text:"("}, {type:"N"}
					]
				],
				replace:1
			}, 
			{
				replacement:"×",
				format:[
					[
						{type:"N"}
					],
					[
						{type:"V"}
					],
					[
						{type:"V",text:"x"},
						{type:"V",text:"X"}
					],
					[
						{type:"N"}, {type:"P", text:"("}
					]
				],
				replace:2
			}, 
			{
				replacement:"×",
				format:[
					[{type:"V"}],
					[
						{type:"V",text:"x"},
						{type:"V",text:"X"}
					]
				],
				replace:1
			},
			{
				replacement:"++",
				format:[
					[{type:"E"}, {type:"P", text:"("}],
					[{type:"O",text:"+"}],
					[{type:"N"}]
				],
				replace:1
			}
			
		]
		this.runFilter(m, list);
		this.replace(queue, lang, list);
		return queue;
		
	}

	isMatch(filter:any, start:number, input:any[], reference:any):boolean {
		var filterSize:number = filter.format.length;
		for (var i:number = 0; i < filterSize; i++) {
			var ary:any[] = filter.format[i];
			var jLen:number = ary.length;
			var index:number = start + i;
			var flag:boolean = false;
			
			if (index >= input.length) {
				return false;
			}
			for (var j:number = 0; j < jLen; j++) {
				if (ary[j] === null) {
					if (start == 0) {
						flag = true;
						start --;
						break;
					}
				} else if (this.underscoreMatch(input[index], ary[j])) {
					flag = true;
					break;
				}
			}
			if (flag == false) {
				return false;
			}
		}
		var match:any = input[start + filter.replace];
		match.value = filter.replacement;
		reference.length = filter.format.length;
		return true;
	}

	underscoreMatch(input:any, filter:any):boolean
	{
		if (typeof filter === 'function')
			return (filter).call(null, input);

		for(var key in filter){
			if (input[key] !== filter[key])
				return false;
		}

		return true;
	}

	runFilter(filterArray:any[], input:any[]):any[] {
		var reference:any = {};
		var filterSize:number = input.length;
		var filterLen:number = filterArray.length;
		for (var i:number = 0; i < filterSize; i++) {
			for (var filterIndex:number = 0; filterIndex < filterLen; filterIndex++) {
				var filter:any = filterArray[filterIndex];
				if (this.isMatch(filter, i, input, reference)) {
					i += filter.replace;
					break;
				}
			}
		}
		return null;
	}

	replace(queue:any[], lang:string, input:any[]):void {
		input.forEach((o:any)=> {
			var symbol:string = o.value;
			if (symbol) {
				o.value = this.replaceSymbol(queue, lang, symbol);
			} else if (o.type == "N") {
				o.value = o.text;
				queue.push(o.value);
			} else if (o.type == "V") {
				o.value = o.text.split("").join(" ");
				queue.push(o.value);
			} else {
				o.value = this.replaceSymbol(queue, lang, o.text)
			}
		});
	}
}

// =======================================
// 
// =======================================
class LLNode{
	public prev:LLNode;
	public next:LLNode;
	public val:any;
	constructor(v:any, p:LLNode = null, n:LLNode = null) {
		this.val = v;
		this.prev = p;
		this.next = n;
		if(this.prev == null)
			this.prev = this;
		if(this.next == null)
			this.next = this;
	}
}

// =======================================
// 
// =======================================
class LList {
	public head:LLNode;
    protected size:number;
	 
	constructor() {
		this.clean();
	}

	public add(obj:any):void {
		this.head.prev.next = this.head.prev = new LLNode(obj, this.head.prev, this.head);
		this.size++;
	}

	public addAt(obj:any, i:number):void {
		var c:LLNode = this.head;
		if(i > this.size)
			throw(new RangeError());
		for(; i > 0; i--)
			c = c.next;
		c.next = c.next.prev = new LLNode(obj, c, c.next);
		this.size++;
	}

	public addFirst(obj:any):void {
		this.head.next.prev = this.head.next = new LLNode(obj, this.head, this.head.next);
		this.size++;
	}

	public addLast(obj:any):void {
		this.add(obj);
	}
	 
	public removeAt(i:number):void {
		var c:LLNode = this.head;
		if(i >= this.size){
			throw(new RangeError());
		}
		for(; i >= 0; i--){
			c = c.next;
		}
		c.prev.next = c.next;
		c.next.prev = c.prev;
		this.size--;
	}
	 
	public remove(obj:any):void {
		for(var c:LLNode = this.head.next; !(c.val instanceof LLNode); c = c.next){
			if(c.val == obj){
				c.prev.next = c.next;
				c.next.prev = c.prev;
				this.size--;
				return;
			}
		}
		throw new Error("NoSuchElement");
	}
	 
	public removeCount(obj:any, i:number):void {
		if(i <= 0){
			throw new Error("IllegalArgument");
		}
		for(var c:LLNode = this.head.next; !(c.val instanceof LLNode); c = c.next){
			i--;
			if(c.val == obj && i == 0){
				c.prev.next = c.next;
				c.next.prev = c.prev;
				this.size--;
				return;
			}
		}
		throw new Error("NoSuchElement");
	}
	 
	public removeLastCount(obj:any, i:number):void {
		if(i <= 0){
			throw new Error("IllegalArgument");
		}
		for(var c:LLNode = this.head.prev; !(c.val instanceof LLNode); c = c.prev){
			i--;
			if(c.val == obj && i == 0){
				c.prev.next = c.next;
				c.next.prev = c.prev;
				this.size--;
				return;
			}
		}
		throw new Error("NoSuchElement");
	}
	 
	public get(i:number):any {
		if(i >= this.size){
			throw new RangeError();
		}
		for(var c:LLNode = this.head.next; i > 0; i--){
			c = c.next;
		}
		return c.val;
	}
	 
	public get length():number{
		return this.size;
	}
	
	public clean():void {
		this.head = new LLNode(new LLNode(null));
		this.size = 0;
	}

}

// =======================================
// 
// =======================================
class SentenceSplitter {
	constructor() {
	}

	private insertNodeAfterNode(node:LLNode, value:any):LLNode {
		var nNode:LLNode = new LLNode(value, node, node.next);
		node.next = nNode;
		return nNode;
	}
	
	private splitText(node:LLNode, type:string, abbr:string):void {
		var original:string = node.val as string;
		var lowercaseString:string = original.toLowerCase();
		var index:number = lowercaseString.indexOf(abbr);
		if (index !== -1) {
			var firstPart:string = original.substr(0, index);
			var originalAbbr:string = original.substr(index, abbr.length);
			var nextPart:string = original.substring(index + abbr.length);
			node.val = firstPart;
			var n2:LLNode = this.insertNodeAfterNode(
				node,
				{
					type:type,
					text:originalAbbr
				}
			);
			this.insertNodeAfterNode(n2, nextPart);
		}
	}
	
	private splitArray(linkedList:LList, array:any, type:string):void {
		array.forEach((abbr:string) => {
			var node:LLNode  = linkedList.head.next;
			while (node  && !(node.val instanceof LLNode)) {
				if (typeof node.val === 'string')
					this.splitText(node, type, abbr);
				node = node.next;
			}
		});
	}

	private toLinkList(array:any[]):LList {
		var list:LList = new LList();
		array.forEach((element:any, index:number, reference:any[]) => {
			list.add(element);
		});
		return list;
	}
	
	private linkedListToArray(linkedList:LList):any[] {
		var node:LLNode  = linkedList.head.next;
		var output:any[] =  [];
		while (node  && !(node.val instanceof LLNode)) {
			output.push(node.val);
			node = node.next;
		}
		return output;
		
	}

	public split(queue:any[]):any[] {
		var list:LList = this.toLinkList(queue);
		var abbrArray:any[] = EnglishAbbreviation.ABBREVIATION.concat();
		abbrArray = abbrArray.map((abbr:string, index:number, reference:any[])=> {
			return " " + abbr.toLowerCase() + " ";
		});
		this.iterateLinkedList(list, (node:LLNode)=> {
			if (typeof node.val === 'string')
				node.val = " " + node.val +" ";
		});
		
		this.splitArray(list, abbrArray, "abbr");
		this.splitSymbols(
			list,
			[
				{
					type:"pause",
					splitter:[" “", "\"", "” "],
					index:-1
				},
				{
					type:"pause",
					splitter:[": ", "; ", ", ", "，", "...", "…"],
					index:-1
				},
				{
					type:"break",
					splitter:["。", ". ", "!", "?", "\r", "\n"],
					index:-1
				}
			]
		);
		return this.filterAndTrimElements(list);
	}
	
	private filterAndTrimElements(list:LList):any[] {
		var output:any[] = [];
		this.iterateLinkedList(list, (node:LLNode)=> {
			if (typeof node.val === 'string') {
				if (node.val) {
					node.val = (node.val as String).trim();
					if (node.val)
						output.push({type:"text", text:node.val});
				}
			} else {
				output.push(node.val);
			}
		});
		return output;
	}
	
	private splitSymbols(list:LList, symbols:any[]):void {
		symbols.forEach((symbol:any) => {
			this.splitArray(list, symbol.splitter, symbol.type);
		});
	}

	private iterateLinkedList(ll:LList, fn:Function):void {
		var node:LLNode  = ll.head.next;
		while (node  && !(node.val instanceof LLNode)) {
			fn.call(null, node);
			node = node.next;
		}
	}

}


// =======================================
// 
// =======================================
class EnglishAbbreviation
{
	public static ABBREVIATION:any[] = [
		"Adj.",
		"Adm.",
		"Adv.",
		"Al.",
		"Ala.",
		"Alta.",
		"Apr.",
		"Arc.",
		"Ariz.",
		"Ark.",
		"Art.",
		"Assn.",
		"Asst.",
		"Attys.",
		"Aug.",
		"Ave.",
		"Bart.",
		"Bld.",
		"Bldg.",
		"Blvd.",
		"Brig.",
		"Bros.",
		"Btw.",
		"Cal.",
		"Calif.",
		"Capt.",
		"Cl.",
		"Cmdr.",
		"Co.",
		"Col.",
		"Colo.",
		"Comdr.",
		"Con.",
		"Conn.",
		"Corp.",
		"Cpl.",
		"Cres.",
		"Ct.",
		"D.phil.",
		"Dak.",
		"Dec.",
		"Del.",
		"Dept.",
		"Det.",
		"Dist.",
		"Dr.",
		"Dr.phil.",
		"Dr.philos.",
		"Drs.",
		"E.g.",
		"Ens.",
		"Esp.",
		"Esq.",
		"Etc.",
		"Exp.",
		"Expy.",
		"Ext.",
		"Feb.",
		"Fed.",
		"Fla.",
		"Ft.",
		"Fwy.",
		"Fy.",
		"Ga.",
		"Gen.",
		"Gov.",
		"Hon.",
		"Hosp.",
		"Hr.",
		"Hway.",
		"Hwy.",
		"I.e.",
		"Ia.",
		"Id.",
		"Ida.",
		"Ill.",
		"Inc.",
		"Ind.",
		"Ing.",
		"Insp.",
		"Is.",
		"Jan.",
		"Jr.",
		"Jul.",
		"Jun.",
		"Kan.",
		"Kans.",
		"Ken.",
		"Ky.",
		"La.",
		"Lt.",
		"Ltd.",
		"Maj.",
		"Man.",
		"Mar.",
		"Mass.",
		"May.",
		"Md.",
		"Me.",
		"Med.",
		"Messrs.",
		"Mex.",
		"Mfg.",
		"Mich.",
		"Min.",
		"Minn.",
		"Miss.",
		"Mlle.",
		"Mm.",
		"Mme.",
		"Mo.",
		"Mont.",
		"Mr.",
		"Mrs.",
		"Ms.",
		"Msgr.",
		"Mssrs.",
		"Mt.",
		"Mtn.",
		"Neb.",
		"Nebr.",
		"Nev.",
		"No.",
		"Nos.",
		"Nov.",
		"Nr.",
		"Oct.",
		"Ok.",
		"Okla.",
		"Ont.",
		"Op.",
		"Ord.",
		"Ore.",
		"P.",
		"Pa.",
		"Pd.",
		"Pde.",
		"Penn.",
		"Penna.",
		"Pfc.",
		"Ph.",
		"Ph.d.",
		"Pl.",
		"Plz.",
		"Pp.",
		"Prof.",
		"Pvt.",
		"Que.",
		"Rd.",
		"Rs.",
		"Ref.",
		"Rep.",
		"Reps.",
		"Res.",
		"Rev.",
		"Rt.",
		"Sask.",
		"Sec.",
		"Sen.",
		"Sens.",
		"Sep.",
		"Sept.",
		"Sfc.",
		"Sgt.",
		"Sr.",
		"St.",
		"Supt.",
		"Surg.",
		"Tce.",
		"Tenn.",
		"Tex.",
		"Univ.",
		"Usafa.",
		"U.S.",
		"Ut.",
		"Va.",
		"V.",
		"Ver.",
		"Vs.",
		"Vt.",
		"Wash.",
		"Wis.",
		"Wisc.",
		"Wy.",
		"Wyo.",
		"Yuk.",
		"Adm.",
		"Attys.",
		"Brig.",
		"Capt.",
		"Cmdr.",
		"Col.",
		"Cpl.",
		"Det.",
		"Dr.",
		"Gen.",
		"Gov.",
		"Ing.",
		"Lt.",
		"Maj.",
		"Mr.",
		"Mrs.",
		"Ms.",
		"Mt.",
		"Messrs.",
		"Mssrs.",
		"Prof.",
		"Ph.",
		"Rep.",
		"Reps.",
		"Rev.",
		"Sen.",
		"Sens.",
		"Sgt.",
		"St.",
		"Supt.",
		"V.",
		"Vs."
	]
}