import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mmss',
  pure: false
})

export class MmssPipe implements PipeTransform {
  transform(value: any, max:any = null) {
    if (max){
      value = Math.min(value, max);
    }
    let m:any = Math.floor(value / 60);
    if (m > 99){
      return '99:59';
    } else if (m < 10){
      m = '0' + m;
    }
    m = isNaN(m)?0:m;
    let s:any = Math.floor(value - (m * 60));
    s = isNaN(s)?0:s;
    s = s < 10?'0' + s:s;
    return m + ':' + s;
  }

}
