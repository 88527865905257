import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WhitePopupComponent } from 'src/app/sharedModule/whitePopupModule/whitePopup.component';
import { faCircle, faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as faCircleLight } from '@fortawesome/pro-light-svg-icons';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: "status-selector-renderer",
	template: `
	<ng-container *ngIf="selectedItem">
		<div class="wrapper" #wrapper
			[ngStyle]="params.wrapperStyle" 
			[style.backgroundColor]="(selectedItem.bgColor?selectedItem.bgColor:'#ccc')"
			[style.color]="(selectedItem.color?selectedItem.color:'#fff')"
			[style.borderColor]="(selectedItem.borderColor?selectedItem.borderColor:'transparent')"
			[attr.ref]="(params.ref?params.ref:'')"
			(click)="clickSubject.next($event)">
				<div class="text" 
					[ngStyle]="params.textStyle" 
					[style.backgroundColor]="(selectedItem.bgColor?selectedItem.bgColor:null)"
					[style.color]="(selectedItem.color?selectedItem.color:'#fff')">
						<span>{{ selectedItem.titleKey |translate }}</span>
					</div>
				<fa-icon class="triangle" [icon]="(clicked ? faCaretUp : faCaretDown)"></fa-icon>
				<!--<div class="triangle" [class.opened]="clicked" 
					[style.borderTopColor]="(clicked?'transparent':(selectedItem.color?selectedItem.color:'#fff'))"
					[style.borderBottomColor]="(!clicked?'transparent':(selectedItem.color?selectedItem.color:'#fff'))"></div>-->
		</div>
		<div *ngIf="params.after" [ngStyle]="params.afterStyle">{{params.after}}</div>
	</ng-container>
	
	<whitePopup #whitePopup *ngIf="clicked"></whitePopup>
    `,
	styleUrls: ['./statusSelector.renderer.scss']
})


export class StatusSelectorRenderer implements AfterViewInit {
	public params: any;
	public text = '';
	public clickSubject = new Subject();
	public items:any[] = [];
	public selectedItem;
	public faCircle = faCircle;
	public faCircleLight = faCircleLight;
	public faCaretDown = faCaretDown;
	public faCaretUp = faCaretUp;
	public clicked = false;
	@ViewChild('whitePopup', { static: false }) public whitePopup: WhitePopupComponent;
	@ViewChild('wrapper', {static: false}) public wrapper:ElementRef;
	@ViewChild('ref', {static: false}) public ref:ElementRef;
	ngAfterViewInit() {
		this.clickSubject.pipe(debounceTime(10)).subscribe(event=>{
			if (!this.clicked){
				let ele = this.wrapper.nativeElement;
				this.clicked = true;
				setTimeout(()=>{
					this.whitePopup.menuItems = this.items;
					this.whitePopup.menuItems.forEach(e=>{
						if (e.borderColor){
							e.faIcon = this.faCircleLight;
							e.iconStyle = {fontSize: '14px', color: e.borderColor };
						} else {
							e.faIcon = this.faCircle;
							e.iconStyle = {fontSize: '14px', color: e.bgColor };
						}
						e.labelStyle = {textAlign: 'left', paddingRight: '10px'};
						e.menuStyle = {padding: '0', minWidth: '140px'};
						e.click = ()=>{
							let data:any = JSON.parse(JSON.stringify(this.params.data));
							data.ref = this.params.ref;
							if (e.callback){
								e.callback(data);
							}
							this.clicked = false;
						};
					});
					this.whitePopup.open(ele).finally(()=>{
						setTimeout(()=>{this.clicked = false;}, 300);
					});
				}, 10);
			}
		});
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	constructor(private themeService:ThemeService){
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		this.items = this.params.items;
		let value = this.params.output;
		this.selectedItem = this.items.find(e=> e.value == value);

		if (this.params.module ){
			if (this.params.module == 'calendar'){
			  this.changeBgColor()
			}
		  }
	}

	refresh(params: ICellRendererParams){
		let value = this.params.output;
		this.selectedItem = this.items.find(e=> e.value == value);
		return true;
	}

	changeBgColor(){
		let items = this.params.items
		if (items != undefined ) {
			items.map(item =>{
				if (item.value == 'reject' || item.value == 'free' || item.value == 'no'){
				item.bgColor = this.themeService.themeSelected=='light' ? '#fff' : '#2d2c44'

				}
			})

		}
	
	
	
	}



}
