import { Component, Input } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
	selector:'loadingDot',
	template: `
	<div class="loadingDotWrapper" [ngStyle]="wrapperStyle">
		<svg *ngIf="lds.dotApps[moduleKey] && lds.dotApps[moduleKey].length>0" [ngStyle]="svgStyle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120px" height="14px" viewBox="44 44 12 12" preserveAspectRatio="xMidYMid">
			<g transform="translate(20 50)">
				<circle cx="0" cy="0" r="6" fill="#e66d91">
				<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
				</circle>
			</g><g transform="translate(40 50)">
				<circle cx="0" cy="0" r="6" fill="#ffb533">
				<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
				</circle>
			</g><g transform="translate(60 50)">
				<circle cx="0" cy="0" r="6" fill="#368288">
				<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
				</circle>
			</g><g transform="translate(80 50)">
				<circle cx="0" cy="0" r="6" fill="#2f5795">
				<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
				</circle>
			</g>
		</svg>
		<!--<img *ngIf="isLoading" src="./assets/img/loading_dot.svg" [ngStyle]="imgStyle">-->
	</div>
	`,
	styleUrls: ['./loadingDot.component.scss']
})

export class LoadingDotComponent {
	@Input() public moduleKey:string = "global";
    //@Input() public isLoading:boolean = false;
	@Input() public wrapperStyle:any = {};
	@Input() public svgStyle:any = {};

	public apps:string[] = [];

	constructor(public lds:LoadingService) {
	}

    /*public getRandomString():string {
        return + new Date() + '-' + Math.round(Math.random() * 1000);
    }
	
    public add(appName:string=this.getRandomString()):Function{
        this.apps.push(appName);
        this.checkLoading();
        return ()=>{ this.remove(appName); };
    }
	
    public remove(appName:string):void {
        for (let i=0; i<this.apps.length; i++) {
            if (this.apps[i]==appName) {
                this.apps.splice(i, 1);
            }
        }
        this.checkLoading();
    }
	
    private checkLoading():void {
		this.isLoading = this.apps.length>0;
    }*/
}
