import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {StorageService} from './storage.service';

@Injectable()

export class DataOldService {
  constructor(public storageService:StorageService, public http:HttpClient){
  }

  public getStudentReport(){
    let lang = this.storageService.lang;
    let bookId = this.storageService.bookId;
    let token = this.storageService.token;
    let http = null;
    let path = this.getUrl(lang,bookId,token);
    http = this.http.get(path);


    http.subscribe((res)=>{
      if (res.book){
        this.storageService.json = res.book;
        this.storageService.loadBook();
        // setTimeout(()=>{window.location.hash='ro/ready';},500);
      } else {
        alert('data error:' + res);
      }
    });
  }

  getUrl(lang,bookId,token){
    let path = '/RainbowOne/index.php/Report/get_my_report/';
    path = (environment.production?'':'//ro2.azurewebsites.net/') + path;
    path = path.replace(':lang',lang);
    path = path.replace(':id',bookId);
    return path.replace(':token',token);
  }

}
