import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges } from "@angular/core";
import { HammerControl } from "./HammerControl";
import * as Hammer from 'hammerjs';
import { fromEvent, Subscription } from "rxjs";
import { DOMHelper } from "src/app/common/DOMHelper";
import { HammerTransformControl } from "./HammerTransformControl";


@Component({
	selector: 'pan-zoom',
	template:`
	
	<div 
		#touch
		class="zoom  transparent-bg" [ngStyle]="hostStyle" [class.disabled]="!enable">
		
		<div 
			class="touch" 
			
		>
		
		</div>
		
		<div #transformLayer class="center-container" 
			>
			<div #paperContainer class="paper-container" >
				
				<div 
					#target 
					class="target" 
					[style.left.px]="pageObject ? -pageObject.width/2 : 0"
					[style.top.px]="pageObject ? -pageObject.height/2 : 0"
					[class.animate]="animate"
					[style.width.px]="pageObject ? pageObject.width : 0"
					[style.height.px]="pageObject ?  pageObject.height : 0"
				>
					<ng-content>


					</ng-content>
					
				</div>
			</div>
		</div>
		
		
		<!--- 
		<div *ngIf="showDebug" class="info" hammertransform>
			<div>pageObject:{{pageObject|json}}</div>
		</div>
		--->
		
	</div>
	`,
	styleUrls:["./PanZoom.scss"]
})
export class PanZoomComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges{
	// @Output() onZoom:EventEmitter<number> = new EventEmitter();
	@Output() panOutside:EventEmitter<any> = new EventEmitter();
	@Input() public disabled:boolean = false;
	@Input() showDebug:boolean;
	@Input() public padding:number = 20;
	public pageObject:any;
	@Input() hostStyle;
	@ViewChild('transformLayer', {static:false}) transformLayer:ElementRef;
	@ViewChild('target', {static:true}) target:ElementRef;
	@ViewChild('paperContainer', {static:false}) paperContainer:ElementRef;
	@ViewChild('touch', {static:true}) touchLayer:ElementRef;
	@ViewChild('bounds', {static:true}) bounds:ElementRef;
	
	private targetDOM:HTMLElement;
	public animate:boolean;
	public panning:boolean = false;
	private  hammerControl: HammerControl;
	private control:HammerTransformControl;
	@Output() public zoomChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() public transforming: EventEmitter<any> = new EventEmitter<any>();
	@Input() public enable: boolean = true;
	@Input() public options:any = {};
	@Input() public set page(value:any)
	{
		this.pageObject = value;
		if(this.pageObject)
		{
			this.pageObject.width = parseInt(this.pageObject.width);
			this.pageObject.height = parseInt(this.pageObject.height);
		}
	}
	@Output() public tapElements: EventEmitter<any> = new EventEmitter<any>();
	constructor(private elementRef:ElementRef)
	{
		this.hammerControl = new HammerControl(this.elementRef.nativeElement);

		
	}
	

	ngOnInit(): void {

	}
	
	ngAfterViewInit(): void {
		var dom:HTMLElement = this.elementRef.nativeElement;
		this.targetDOM = this.target.nativeElement;
		this.control = new HammerTransformControl(
			this.touchLayer.nativeElement, 
			this.targetDOM,
			dom.getAttribute("dragBoundary")
		);
		if (this.options.maxScale){
			this.control.maxScale = this.options.maxScale; 
		}
		if (this.options.minScale){
			this.control.minScale = this.options.minScale; 
		}
		this.control.panOnlyForOversize = true;
		
		this.control.transform.subscribe((info)=>{
			this.transforming.emit(info);
			this.zoomChange.emit(info.scale);
		});
		this.control.pinZoomEnable = true;
		this.targetDOM.addEventListener('transitionend', ()=>{
			this.animate = false;
		})
		
		setTimeout(
			()=>{
				this.fitPage();
			}, 
		0);
	}
	
	ngOnDestroy(): void {
		if(this.control) this.control.destroy();
		if(this.hammerControl) this.hammerControl.destroy();
	}

	ngOnChanges(changes){
		if (changes.enable && this.control){
			this.control.enabled = changes.enable.currentValue;
		}
	}

	private originalZoom:number;

	public fitPageTo(page:any)
	{
		this.pageObject = page;
		this.fitPage();
	}
	public fitPage():void
	{
		var container:HTMLElement = this.touchLayer.nativeElement;
		var p2:number = this.padding * 2;
		var w:number = container.clientWidth - p2;
		var h:number = container.clientHeight - p2;
		var wScale:number =  w / this.pageObject.width;
		var hScale:number =  h / this.pageObject.height;
		var newZoom:number = wScale <= hScale ?  wScale : hScale;
		this.originalZoom = newZoom;
		// this.control.setZoom(newZoom);
		this.control.setTransform(0, 0, newZoom);
		this.zoomChange.emit(newZoom);
	}
	
	public positionReset():void
	{
		this.animate = true;
		this.control.setTransform(0, 0, this.originalZoom);
		this.zoomChange.emit(this.originalZoom);
	}

	public zoomReset():void
	{
		this.animate = true;
		this.control.setZoom(this.originalZoom);
		this.zoomChange.emit(this.originalZoom);
	}

	public zoomIn():void
	{
		this.animate = true;
		var newZoom:number = this.control.scaleUp(1.125);
		this.zoomChange.emit(newZoom);
	}
	public zoomOut():void
	{
		this.animate = true;
		var newZoom:number = this.control.scaleUp(0.9);
		this.zoomChange.emit(newZoom);
	}


	@Input() set zoom(zoomValue:number)
	{
		if(this.control) this.control.setZoom(zoomValue);
	}

	@HostListener('wheel', ['$event'])
	public onWheel(event:any):void
	{
		// this.zoom *= (event.deltaY > 0 ? 0.8 : 1.25);
		if (this.enable == false){
			return;
		}
		var newZoom:number = this.control.scaleUp(event.deltaY > 0 ? 0.8 : 1.25);
		this.zoomChange.emit(newZoom);
	}

	@HostListener('tap', ['$event'])
	public onTap(event:any):void
	{
		const x = event.center.x;
		const y = event.center.y;
		const eles = <HTMLElement[]>document.elementsFromPoint(x,y);
		this.tapElements.emit(eles);
	}
	
}
