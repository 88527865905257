import { Component, HostListener, OnInit, Input, Output, EventEmitter, OnDestroy, ElementRef } from '@angular/core';
import { StorageService } from './service/storage.service';
import { DataOldService } from './service/data-old.service';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ScriptService } from 'src/app/service/script.service';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'report-personal',
	template: `
    <ng-container *ngIf="!noBookStructure && modData">
      <info [modData]="modData" [viewSubmitOnly]="viewSubmitOnly" ></info>
      <detail [modData]="modData"></detail>
      <!-- <div class="line"></div> -->
    </ng-container>
    <div class="no-book-structure" *ngIf="noBookStructure"> {{'no-book-structure'|translate:{bsid: modData.bsid, bid: modData.bid} }}</div>
  `,
	styleUrls: ['./report-personal.component.scss']
})

export class ReportPersonalComponent implements OnInit, OnDestroy {
	@Input() public modData: any = {};
	@Input() public parent;
	@Output() public callOpenBookModal: EventEmitter<any> = new EventEmitter();

	public noBookStructure = false;
	public viewSubmitOnly = false;
	private sub$: Subscription;
	public objectiveChartData;
	public onDestroy = new Subject();
	constructor(public storageService: StorageService, public dataOldService: DataOldService, private script: ScriptService, private coms: CommonService, public theme:ThemeService, public eleRef: ElementRef, public http: HttpClient, private trans: TranslateService) {

	}

	ngOnInit() {
		this.test();
		let modData = this.modData;
		this.viewSubmitOnly = this.initViewMode(modData);
		this.storageService.image = modData.bookImage;
		this.storageService.bookType = modData.bookType;
		this.storageService.lang = modData.lang;
		this.storageService.appId = modData.appId;
		if (modData.structure) {
			this.initStudentReport(modData.structure, modData.result);
		}
		this.sub$ = this.storageService.callOpenBookModal.subscribe((obj) => {
			this.callOpenBookModal.emit(obj);
		});

		this.initTheme(this.eleRef.nativeElement);
		let hostname = location.port == "" ? location.hostname : 'dev.openknowledge.hk';
		const apiUrl = '//' + hostname + '/RainbowOne/index.php/Report/get_book_share_summary/' + modData.bsid + '/' + modData.bid + '/1';

		Promise.all([
			this.script.load('chart-2.9.4.js/chart.min.js'),
			this.http.post(apiUrl, {}).toPromise()
		]).then((res) => {
			const bookRes = res[1];
			if (typeof res[1] === 'object') {
				this.initObjectiveChartData(bookRes);
			}
		}).catch();
		let section = this.getSection();
		modData.section = section;
		this.parent.filterChange.pipe(takeUntil(this.onDestroy)).subscribe(event=>{
			this.http.post(apiUrl, {}).toPromise().then(res=>{
				this.initObjectiveChartData(res);
			});
		});
	}

	ngOnDestroy(): void {
		this.sub$.unsubscribe();
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	initStudentReport(structure, result) {
		try {
			let json = JSON.parse(structure.structure);
			this.initProp();
			let book = this.formatBook(json.book, structure.pass_level);
			if (this.storageService.bookType == 'pdf-paper'){
				book = this.formatPdfPaper(book);
			}
			book = this.loadResult(book, result);
			let storage = this.storageService;
			storage.chapters = book.chapters;
			storage.numQuestion = book.numQuestion;
			storage.pieData = storage.calPieData();
			if (storage.maxScore) {
				if (storage.score * 100 / storage.maxScore >= book.passLevel) {
					storage.bookClass = 'green';
				}
			}
		} catch (e) {
			console.log(e);
			// this.noBookStructure = true;
		}
	}

	initProp() {
		this.storageService.initProp();
	}

	initTheme(ele){
		let section = 'default';
		const href = location.href;
		const sections = ['bookshelf','course'];
		for (let sec of sections){
			if (href.indexOf('/' + sec + '/') > -1){
				section = sec;
			}
		}

	}

	formatBook(book, passLevel) {
		book.maxScore = 0;
		book.numQuestion = 0;
		this.storageService.title = book.title;
		book.passLevel = parseFloat(passLevel);
		const bookType = this.storageService.bookType || 'book'; 
		book.chapters = book.chapters.map(chapter => {
			let newChapter: any = { id: chapter.id, title: chapter.title, maxScore: 0, components: [] };
			chapter.pages.forEach(page => {
				page.components.forEach((component, index) => {
					let compType = component.type ? component.type.toLowerCase() : 'unknown';
					let newComponent: any = { id: component.cid, pageNumber: page.pageNumber, pid: page.id, type: compType, hasScoring: component.hasScoring };
					if (bookType == 'assessment'){
						newComponent.q = '' + page.pageNumber;
					} else if (chapter.pages.length == 1 && ['book','pdf-paper'].indexOf(bookType) == -1){
						newComponent.q = '' + (index + 1);
					} else {
						newComponent.q = page.pageNumber + '.' + (index + 1);
					}
					
					newComponent.title = this.modData.compName[this.modData.lang][compType];
					if (component.name2){
						newComponent.title = component.name2; 
					}
					if (newComponent.hasScoring) {
						newComponent.maxScore = component.score.full ? parseFloat(component.score.full) : 0;
						newComponent.maxScore = Math.round(newComponent.maxScore * 10) / 10;
						newChapter.maxScore += newComponent.maxScore;
						book.maxScore += newComponent.maxScore;
					} else {
						newComponent.maxScore = 0;
					}
					if (compType == 'scorecom'){
						const info = this.modData.compXmlInfo.find(e => e.indexOf(component.cid) > -1);
						if (info){
							if (info.indexOf('"type":"mc"') > -1){
								newComponent.type = 'scorecom-mc';
								newComponent.title = this.trans.instant('ro.components.name.ScoreCom-mc');
							} else if (info.indexOf('"type":"free"') > -1){
								newComponent.type = 'scorecom-free';
								newComponent.title = this.trans.instant('ro.components.name.ScoreCom-free');
							} else if (info.indexOf('"type":"tf"') > -1 || info.indexOf('"type":"full"') > -1){
								newComponent.type = 'scorecom-tf';
								newComponent.title = this.trans.instant('ro.components.name.ScoreCom-tf');
							}
							const questionSectionIndex = info.indexOf("questionSection=");
							if (questionSectionIndex > -1){
								newComponent.questionSection = info.substr(questionSectionIndex + 17, 1);
							}
						}
					} else if (compType == 'exampaper' || compType == 'totalscorecom'){
						return false;
					}
					book.numQuestion++;
					newChapter.components.push(newComponent);
				});
			});
			return newChapter; 
		});
		this.storageService.maxScore = book.maxScore;
		return book;
	}

	formatPdfPaper(book){
		let sections = [];
		let questionNumTool:any = {A:1,B:1,C:1,D:1,E:1,F:1,G:1,H:1,I:1,J:1};
		if ( book.chapters){
			book.chapters.forEach(chapter=>{
				if (chapter.components){
					chapter.components.forEach(comp=>{
						if (comp.questionSection){
							comp.q = comp.questionSection + questionNumTool[comp.questionSection];
							questionNumTool[comp.questionSection]++;
							const questionSectionName = {tc:'環節',sc:'環節',en:'Section '}[this.storageService.lang] + comp.questionSection;
							let section:any = sections.find(sec => sec.title == questionSectionName);
							if (section){
								section.components.push(comp);
							} else {
								section = {title: questionSectionName, components: [comp], maxScore: 0};
								sections.push(section);
							}
							if (comp.hasScoring && comp.score > 0){
								section.maxScore += parseFloat(comp.score);
							}
						}
					})
				}
			});
			book.chapters = sections;
		}
		return book;
	}

	loadResult(book, result = []) {
		let storage = this.storageService;
		storage.score = 0;
		if (book.chapters) {
			book.chapters.forEach((chapter: any) => {
				chapter.score = 0;
				chapter.submitted = false;
				if (chapter.components) {
					chapter.components.forEach(component => {
						let found = result.find(r => r.cid == component.id);
						if (found) {
							chapter.submitted = true;
							storage.submitted++;
							let result: any = {};
							try {
								let resultObj:any = JSON.parse(found.result);
								if (typeof resultObj === 'string'){
									resultObj = JSON.parse(resultObj);
								}
								result = resultObj ? resultObj : {};
							} catch (e) {
								result = {};
							}
							if (result == null || typeof result !== 'object') {
								result = {};
							}
							if (result.score === undefined) {
								storage.pieSrc['submitted']++;
								component.class = 'submitted';
							} else if (component.hasScoring && !this.viewSubmitOnly) {
								let score = parseFloat(found.score);
								if (result.score !== undefined){
									score = parseFloat(result.score);
								}
								score = Math.round(score * 10) / 10;
								component.score = score;
								chapter.score += score;
								if (score >= component.maxScore) {
									component.class = 'correct-2';
									storage.fullCorrect++;
									storage.pieSrc['correct-2']++;
								} else if (score > 0) {
									component.class = 'correct-1';
									this.storageService.halfCorrect++;
									storage.pieSrc['correct-1']++;
								} else if (result.correct == -1 && this.storageService.bookType != 'pdf-paper') { //pdf-paper不會有submitted
									storage.pieSrc['submitted']++;
									component.class = 'submitted';
								} else {
									component.class = 'correct-0';
									this.storageService.wrong++;
									storage.pieSrc['correct-0']++;
								}
							} else if (found.correct !== undefined) {
								try {
									if (result.correct == 2) {
										component.class = 'green-tick';
										storage.fullCorrect++;
										storage.pieSrc['correct-2']++;
									} else if (result.correct == 1) {
										component.class = 'orange-tick';
										storage.halfCorrect++;
										storage.pieSrc['correct-1']++;
									} else if (result.correct == 0) {
										component.class = 'red-cross';
										this.storageService.wrong++;
										storage.pieSrc['correct-0']++;
									} else {
										storage.pieSrc['submitted']++;
										component.class = 'submitted';
									}
								} catch (e) {
									storage.pieSrc['submitted']++;
									component.class = 'submitted';
								}
							} else {
								storage.pieSrc['submitted']++;
								component.class = 'submitted';
							}
						} else {
							storage.pieSrc['blank']++;
							component.class = 'blank';
						}
					});
				}
				if (chapter.score >= chapter.maxScore * (book.passLevel / 100)) {
					chapter.class = 'green';
				} else if (chapter.submitted) {
					chapter.class = 'red';
				} else {
					chapter.class = 'blank';
				}
				storage.score = storage.score + chapter.score;
			});
		}
		return book;
	}

	initViewMode(modData) {
		if (modData.bookType == 'pdf-paper') {
			return false;
		} else if (modData.viewerRole > 2 && modData.viewerRole != 6) {
			return false;
		} else if (modData.live_verify) {
			return false;
		} else if (modData.book.status == 'complete' || modData.book.status == 'finished') {
			return false;
		}
		return true;
	}

	test() {

	}

	initObjectiveChartData(bookRes) {
		if (bookRes.point.length == 0) {
			return;
		}
		this.initPointTypes(bookRes.point);
	}

	private initPointTypes(points) {
		this.objectiveChartData = {};
		const objectiveChartData = this.objectiveChartData;
		objectiveChartData.pointTypes = [{ label: '所有', value: 'all' }];
		points.forEach(pt => {
			pt.form_id = pt.form_id ? pt.form_id : 0;
			pt.form_name = pt.form_name ? pt.form_name : '其他';
			let found = objectiveChartData.pointTypes.find(e => e.value == pt.form_id);
			if (found == null) {
				objectiveChartData.pointTypes.push({ label: pt.form_name, value: pt.form_id });
			}
		});
		if (objectiveChartData.pointTypes.length > 2) {
			objectiveChartData.pointType = 'all';
		} else {
			objectiveChartData.pointType = objectiveChartData.pointTypes[1].value;
		}
	}

	getSection(){
		let section = 'default';
		const href = location.href;
		if (href.indexOf('roWeb-ie') > -1){
			return 'default';
		}
		const sections = ['bookshelf','course','lesson'];
		const sessionStorageSection = sessionStorage.getItem('openBookFrom');
		for (const sec of sections){
			if (href.indexOf('/'+sec + '/') > -1 || sessionStorageSection == sec){
				section = sec;
			}
		}
		if (location.href.indexOf('bookViewer') > -1){
			section = 'bookshelf';
		}
		return section;
	}

}
