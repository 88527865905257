import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import {  ROComponent, ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";

@Component({
	// template:`Ball{{node.attributes|json}}`
	template:`<div 
		[class]="[node.tag, 'ball'].join(' ')"
		[style.width.px]="size" 
		[style.height.px]="size" 
		[style.lineHeight.px]="size"
		>
		{{node.attributes.count}}
	</div>`,
	styles:[
		`.ball{
			border-radius:50%;
			color:white;
			text-align:center;
		}
		.OrangeBall.ball{
			background-color:orange;
		}
		.Ball.ball{
			background-color:blue;
		}
		`
	]
	
})
export class ROBall extends ROComponent
{
	public size:number = 32;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["Ball", "OrangeBall"];
	}

	protected initVariables():void
	{
		super.initVariables();
		this.size = this.node.getAttribute("size");
		
	}
	/*
	protected buildContent():void
	{
		
	}
	*/
}