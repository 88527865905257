
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { multiSelectPullDownComponent } from './multiSelectPullDown.component';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
		TranslateModule,
		PerfectScrollbarModule
    ],
    declarations: [
        multiSelectPullDownComponent
    ],
    exports: [
        multiSelectPullDownComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class multiSelectPullDownModule {
}