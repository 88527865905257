import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ScriptService } from 'src/app/service/script.service';
import { faXmark, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faCameraRotate } from '@fortawesome/pro-regular-svg-icons';
declare var MediaRecorder;
declare var FFmpeg;

@Component({
	selector: 'ng-capture',
	template: `
		<div class="content"
			[class.landscape]="isLandscape"
			[class.portrait]="!isLandscape"
		>
			<div class="camera" >
				<video playsinline #cameraScreen autoplay></video>
			</div>

			<div class="close" (click)="cancelRecord()"></div>

			<div class="swap" *ngIf="cameras.length > 1" (click)="swapCamera()">
				<fa-icon [icon]="faCameraRotate"></fa-icon>
			</div>

			<div class="capture {{isRecording?'recording':''}}" (click)="submitVideo()">
        <div class="btnCircle"></div>
		
      </div>

			<canvas #offscreenCanvas></canvas>
      <div class="timer" *ngIf="isRecording">{{displayTime}}</div>
		</div>
  `
	, styleUrls: ['./capture.component.scss'],
})
export class CaptureComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() options: any = {};
	@ViewChild('offscreenCanvas', { static: false }) offscreenCanvas;
	@ViewChild('cameraScreen', { static: false }) cameraScreen;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	public faCameraRotate = faCameraRotate;
	public faXmark = faXmark;
	public isLandscape = true;
	public time = 0;
	public displayTime;
	private recordedChunks = [];
	public file;
	public uInt8Array;
	public url;
	public player;
	public recorder;
	public isRecording = false;
	public cameras: any = [];
	public cameraIndex = 0;
	private stream;
	private swapCameraStarting = false;
	constructor(private script: ScriptService) {
	}

	ngOnInit() {

	}

	ngOnDestroy() {

	}

	ngAfterViewInit(): void {
		navigator.mediaDevices.enumerateDevices().then(devices => {
			this.cameras = devices.filter(dev => dev.kind == 'videoinput');
			this.renderVideo();
		});
	}



	async renderVideo() {
		let constraints = {
			video: { width: 1280, height: 720, deviceId: this.cameras[this.cameraIndex].deviceId },
			audio: {
				echoCancellation: { exact: true }
			}
		};
		navigator.mediaDevices.getUserMedia(constraints).then(stream => {
			let cameraScreen = this.cameraScreen.nativeElement;
			cameraScreen.srcObject = stream;
			this.stream = stream;
		}).catch(e => {
			this.close.emit(e);
		});
	}

	swapCamera() {
		this.swapCameraStarting = true;
		this.stream.getTracks().forEach(function (track) {
			if (track.readyState == 'live') {
				track.stop();
			}
		});
		if (this.cameras.length == this.cameraIndex + 1) {
			this.cameraIndex = 0;
		} else {
			this.cameraIndex++;
		}

		setTimeout(() => {
			this.swapCameraStarting = false;
			this.renderVideo();
		}, 50);
	}

	requestData() {
		if (this.recorder.state == 'recording') {
			this.recorder.requestData();
			setTimeout(() => { this.requestData(); }, 1000);
		}
	}

	submitVideo() {
		if (this.isRecording) {
			this.stream.getTracks().forEach(function (track) {
				if (track.readyState == 'live') {
					track.stop();
				}
			});
			this.isRecording = false;
		} else {
			this.isRecording = true;
			this.startRecord();
		}

	}

	cancelRecord() {
		this.stream.getTracks().forEach(function (track) {
			if (track.readyState == 'live') {
				track.stop();
			}
		});
		this.close.emit('cancel');
	}

	startRecord() {
		// windows/mac edge/chrome support video/webm; codecs="h264"
		let mimeType = 'video/webm; codecs="h264"';
		let fileType = 'video/webm';
		let filename = 'video.webm';
		let outputFilename:string = 'video.mp4';

		if (!MediaRecorder.isTypeSupported(mimeType)) {
			// safari mp4 (iOS / mac)
			mimeType = 'video/mp4';
			fileType = 'video/mp4';
			filename = 'video.mp4';
			outputFilename = 'video.mp4';

			if (!MediaRecorder.isTypeSupported(mimeType)) {
				// vp8
				mimeType = 'video/webm;codecs=vp8,opus';
				fileType = 'video/webm';
				filename = 'video.webm';
				outputFilename = 'video.webm';
			}
		}

		

		/*
		android / iOS browser (不包括 webview) 可用這句叫出系統拍片
		<input type="file" accept="video/*" capture="camera" />

		RO 內就用指令叫返系統拍片
		*/
		if (!MediaRecorder.isTypeSupported(mimeType)) {
			let msg = mimeType + ' is not supported';
			throw new Error(msg);
		}
		this.recorder = new MediaRecorder(this.stream, {
			mimeType: mimeType,
			videoBitsPerSecond: 2500000,
		});
		this.recorder.addEventListener('dataavailable', e => {
			if (e.data && e.data.size > 0) {
				this.recordedChunks.push(e.data);
			}
		});
		this.recorder.addEventListener('stop', e => {
			if (this.swapCameraStarting == false) {
				this.file = new File(this.recordedChunks, filename, { type: fileType });
				this.url = URL.createObjectURL(this.file);

				const fileReader = new FileReader();
				fileReader.onload = () => {
					let arrayBuffer: any = fileReader.result;
					this.uInt8Array = new Uint8Array(arrayBuffer);
///					this.close.emit('submit');
					// 需轉換
					if(filename!=outputFilename) {
						let ffmpegEncoder = new ffmpegWorker(this.script);
						ffmpegEncoder.encode(filename, outputFilename, this.uInt8Array).then(output=> {
							// output:Uint8Array
							this.file = new File([output], outputFilename, { type: fileType });
							this.url = URL.createObjectURL(this.file);
							this.uInt8Array = output;
							this.close.emit('submit');
						});

					} else {
						this.close.emit('submit');
					}
				};
				fileReader.readAsArrayBuffer(this.file);
			}
		});
		this.recorder.start(1000);
		this.updateTimer();
	}

	updateTimer() {
		let max = this.options && this.options.limit ? this.options.limit : 60 * 60 * 100;
		this.displayTime = this.transform(this.time, max);
		setTimeout(() => {
			this.time++;
			this.updateTimer();
		}, 1000);
	}



	transform(value: any, max: any = null) {
		if (max) {
			value = Math.min(value, max);
		}
		let m: any = Math.floor(value / 60);
		if (m > 99) {
			return '99:59';
		} else if (m < 10) {
			m = '0' + m;
		}
		m = isNaN(m) ? 0 : m;
		let s: any = Math.floor(value - (m * 60));
		s = isNaN(s) ? 0 : s;
		s = s < 10 ? '0' + s : s;
		return m + ':' + s;
	}



}

class ffmpegWorker{
	constructor(private script: ScriptService) {
	}

	async encode(inFilename:string, outFilename:string, filedata:Uint8Array):Promise<File> {
		// https://juejin.cn/post/7150962372130111518
		await this.script.load('js/ffmpeg.min.js');
		const ffmpeg = FFmpeg.createFFmpeg({
			// 打开log
			log: true,
			mainName: 'main',
			//使用单线程版
			corePath: 'https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js'});

		await ffmpeg.load();
		ffmpeg.FS('writeFile', inFilename, filedata);
		await ffmpeg.run('-i', inFilename, '-c:v', 'copy','-c:a', 'aac',outFilename);
		let output:File = ffmpeg.FS('readFile', outFilename);
		//console.log(output);
		return Promise.resolve(output);
	}

	/*
	async encode_old(inFilename:string, outFilename:string, filedata:Uint8Array):Promise<File> {
		let inputFiles = [{name: inFilename, data: filedata}];
		let finalResult = await this.ffmpegExecWorker(inputFiles, [
			'-i', inFilename,
			'-c:v', 'copy',
			'-c:a', 'aac',
			outFilename
		]);

		// finalResult.files[outFilename]:Uint8Array
		// finalResult.output:String[]			console output
		console.log("ffmpeg", finalResult);
		return Promise.resolve(finalResult.files[outFilename]);
	}

	async ffmpegExecWorker(files, args):Promise<any> {
		let output = [];
		let resolveCallback = null;
		let worker = new Worker("assets/js/ffmpeg-worker-mp4.js");
		worker.onerror = (e) => {
			console.log(e);
		};

		worker.onmessage = (ev:any):any => {
			let msg = ev.data;
			switch (msg.type) {
			case "ready":
				worker.postMessage({
					type: 'run',
					MEMFS: files,
					arguments: args,
				});
				break;
			case "stdout":
				break;
			case "stderr":
				output.push(msg.data);
				break;
			case "exit":
				worker.terminate();
				break;
			case "done":
				if (resolveCallback) {
					resolveCallback({files: msg.data, output: output});
				}
				
				worker.terminate();
				break;
			}
			return null;

		};

		return new Promise(function(resolve){
			resolveCallback = resolve;
		});
	}
	*/
}