/**
 * @exports
 * @enum
 */
export enum Sizes {
  INT8_MIN = -128,
  INT8_MAX = 127,

  UINT8_MIN = 0,
  UINT8_MAX = 255,

  INT16_MIN = -32768,
  INT16_MAX = 32767,

  UINT16_MIN = 0,
  UINT16_MAX = 65535,

  UINT29_1 = 128,
  UINT29_2 = 16384,
  UINT29_3 = 2097152,
  UINT29_4 = 1073741824,

  INT32_MIN = -2147483648,
  INT32_MAX = 2147483647,

  UINT32_MIN = 0,
  UINT32_MAX = 4294967295
}
