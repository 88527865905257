import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { ScriptService } from 'src/app/service/script.service';
import { LoadingService } from '../loadingModule/loading.service';
import * as moment from 'moment';
import { faMagnifyingGlass, faVolume } from '@fortawesome/pro-solid-svg-icons';
import { faTimer } from '@fortawesome/pro-regular-svg-icons';
import { TTSService } from 'src/app/service/TTS.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { SettingService } from 'src/app/service/setting.service';

@Component({
	selector: 'ai-composition-submission-modal',
	template: `
	<modal2
		#modal class="ai-composition-submission-modal {{theme.theme$.value}}-theme"
		[contentStyle]="modalContentStyle"
		(confirmClick)="confirm()" [confirmLabel]="confirmLabel"
		(cancelClick)="closeClick()"
		>
		<div class="customTitle" customTitle>
			{{modalTitle}}<div *ngIf="status != 'open'" class="status {{status||''}}">{{ statusText }}</div>
		</div>
		<div *ngIf="finalComment && status == 'finished'" class="final">
			<div class="final-label"> {{ {tc:'AI總評',sc: 'AI总评', en: 'AI final comment'}[lang] }}</div>
			<div class="final-comment">{{finalComment.comments}}</div>
			<div class="final-box">
				<div class="final-box-top">
					<div class="final-box-label">{{ {tc:'AI總分',sc:'AI总分',en:'AI total score'}[lang] }}:</div>
					<div class="final-box-score">{{finalComment.total_score}}</div>
					<div class="final-box-label">/100</div>
				</div>
				<div class="final-box-bottom" *ngIf="finalComment.scoringItems">
					<div class="final-box-item" *ngFor="let item of finalComment.scoringItems">{{ item.title + ': ' + item.score }}</div>
				</div>
			</div>
		</div>
		<div class="modal-wrapper" [class.disabled]="status != 'open' || readOnly">
			<div class="modal-wrapper-top">
				<div class="info" [class.withGuide]="info.prewritingAIGuideEnabled">
					<div class="info-topic" [class.withMedia]="info.mediaUrl">
						<span>{{ {tc: '標題', sc:'标题', en:'Topic'}[lang]}}: {{info.name}}</span>
						<div class="info-grading" (click)="showGrading($event)">{{ {tc:'評分準則', sc: '评分准则', en:'Grading'}[lang] }}...</div>
					</div>
					<div class="info-description" [class.withMedia]="info.mediaUrl">
						<p>{{ info.description }}</p>
					</div>
					<div *ngIf="info.mediaUrl" class="info-media" 
						[style.backgroundImage]="media"
						(click)="previewMedia()">
						<div class="magnify_icon">
							<fa-icon [icon]="faMagnifyingGlass"></fa-icon>
						</div>
					</div>
				</div>
				<div class="guide" *ngIf="info.prewritingAIGuideEnabled">
					<div class="guide-panel">
						<div class="guide-topic">
							{{{tc: '寫作前提示', sc:'写作前提示',en: '寫作前提示'}[lang]}}
						</div>
						<okaPulldown2 [okaPulldownStyle]="okaPulldownStyle" 
							bindLabel="label" bindValue="value"
							[options]="aiGuideOptions" [(ngModel)]="aiGuide" (ngModelChange)="pdChange()"></okaPulldown2>
						<div class="askAIButton" *ngIf="status == 'open'"
							[class.disabled]="aiGuideUsed - info.prewritingAIGuideTimes >= 0" 
							(click)="askAIClick('guide')"> {{askAIText}} ({{info.prewritingAIGuideTimes - aiGuideUsed}})</div>
					</div>
					<div class="guide-body">
						<p>{{ displayGuide }}</p>
					</div>
				</div>
			</div>
			<div class="modal-wrapper-bottom" *ngIf="info.paragraph">
				<div class="paragraph" *ngFor="let paragraph of paragraphs;let pi = index; trackBy: trackParagraph">
					<div class="paragraph-label">{{ {tc:'段落',sc:'段落',en:'Paragraph'}[lang] + (lang == 'en'?' ':'') + (pi + 1) }}:</div>
					<div class="paragraph-history" *ngIf="aiFeedbacksUsed[pi] > 0" (click)="historyClick($event, pi)">
						<fa-icon [icon]="faTimer"></fa-icon>({{aiFeedbacksUsed[pi]}})
					</div>
					<textarea class="paragraph-body" 
						[(ngModel)]="paragraphs[pi].text" (change)="paragraph.feedback = ''"></textarea>
					<div class="paragraph-right {{theme.theme$.value}}-theme">

						<div class="suggestion" *ngIf="info.prewritingAISuggestionEnabled">
							<div class="suggestion-panel">
								<div class="suggestion-topic">
									{{ {tc:'段落',sc:'段落',en:'Paragraph'}[lang] + {tc:'提示',sc:'提示', en: ' Guide'}[lang] }}
								</div>
								<okaPulldown2 [okaPulldownStyle]="okaPulldownStyle" 
									[options]="aiSuggestionsOptions" [(ngModel)]="aiSuggestions[pi].text"
									(ngModelChange)="pdChange()"></okaPulldown2>
								<div class="askAIButton" *ngIf="status == 'open'"
									[class.disabled]="info.prewritingAISuggestionTimes - aiSuggestionsUsed[pi] == 0" (click)="askAIClick('suggestion', pi)"> {{askAIText}} ({{info.prewritingAISuggestionTimes - aiSuggestionsUsed[pi] }})</div>
							</div>
							<ng-container>
								<div class="suggestion-body">
									<div class="suggestion-body-text">{{ paragraph.suggestion }}</div>
									<div class="suggestion-voice" *ngIf="paragraph.suggestion" (click)="playVoiceObj($event, aiSuggestionsResults[pi][aiSuggestions[pi].text - 1], 'suggestion', pi)">
									<fa-icon [icon]="faVolume"></fa-icon>
								</div>
								</div>
							</ng-container>
						</div>

						<div class="feedback" *ngIf="info.prewritingAIFeedbackEnabled">
							<div class="feedback-panel">
								<div class="feedback-topic">
									{{ {tc:'段落',sc:'段落',en:'Paragraph'}[lang] + {tc:'評語',sc:'评语', en: ' Feedback'}[lang] }}
								</div>
								<div class="askAIButton" *ngIf="status == 'open'"
									[class.disabled]="info.prewritingAIFeedbackTimes - aiFeedbacksUsed[pi] == 0" (click)="askAIClick('feedback', pi)"> {{askAIText}} ({{info.prewritingAIFeedbackTimes - aiFeedbacksUsed[pi]}})</div>
							</div>
							<ng-container>
								<div class="feedback-body">
									<div class="feedback-body-text">{{ paragraph.feedback }}</div>
									<div class="feedback-voice" *ngIf="paragraph.feedback" 
										(click)="playVoiceObj($event, aiFeedbacksResults[pi][aiFeedbacks[pi].text - 1], 'feedback',pi)">
										<fa-icon [icon]="faVolume"></fa-icon>
									</div>
								</div>
							</ng-container>

						</div>

					</div>
				</div>
			</div>

			<div class="modal-wrapper-bottom" *ngIf="info.paragraph === 0">
				<div class="paragraph whole-essay">
					<div class="paragraph-label">{{ {tc:'全文',sc:'全文',en:'Essay'}[lang] }}:</div>
					<div class="paragraph-history" *ngIf="aiFeedbacksUsed[0] > 0" (click)="historyClick($event, 0)">
						<fa-icon [icon]="faTimer"></fa-icon>({{aiFeedbacksUsed[0]}})
					</div>
					<textarea class="paragraph-body" 
						[(ngModel)]="paragraphs[0].text" (change)="paragraphs[0].feedback = ''"></textarea>
					<div class="paragraph-right {{theme.theme$.value}}-theme">
						<div class="feedback" *ngIf="info.prewritingAIFeedbackEnabled">
							<div class="feedback-panel">
								<div class="feedback-topic">
									{{ {tc:'段落',sc:'段落',en:'Paragraph'}[lang] + {tc:'評語',sc:'评语', en: ' Feedback'}[lang] }}
								</div>
								<div class="askAIButton" *ngIf="status == 'open'"
									[class.disabled]="(info.prewritingAIFeedbackTimes || 0) - aiFeedbacksUsed[0] == 0" (click)="askAIClick('feedback', 0)"> {{askAIText}} ({{(info.prewritingAIFeedbackTimes || 0) - aiFeedbacksUsed[0]}})</div>
							</div>
							
							<ng-container>
								<div class="feedback-body">
									<div class="feedback-body-text">{{ paragraphs[0].feedback }}</div>
									<div class="feedback-voice" *ngIf="paragraphs[0].feedback" 
										(click)="playVoiceObj($event, aiFeedbacksResults[0][aiFeedbacks[0].text - 1], 'feedback',0)">
										<fa-icon [icon]="faVolume"></fa-icon>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<bubbleBox2 #historyBB [marginLeft]="30" (close)="history = null">
			<div class="record-wrapper" *ngIf="history && history.items">
				<div class="record-wrapper-2">
					<div class="record" [class.last]="last" *ngFor="let item of history.items;let i = index;let last = last;">
						<div class="record-label">{{ {tc:'紀錄',sc:'纪录',en:'Record '}[lang] + (i + 1) }}:</div>
						<div class="record-body">{{ item.answer }}</div>
						<div class="record-right {{theme.theme$.value}}-theme">
							<div class="record-comment-topic">{{ {tc:'評語', sc: '评语', en:'Comment'}[lang] }}</div>
							<div class="record-comment-body">
								<div class="record-comment-body-text">{{ item.response }}</div>
								<div class="record-voice" (click)="playVoiceObj($event, item, 'history', history.pi)">
									<fa-icon [icon]="faVolume"></fa-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</bubbleBox2>

		<bubbleBox2 #showGradingBB>
			<table class="gd-table">
				<tr class="gd-header">
					<th class="gd-header-item">{{ {tc:'準則',sc:'準則', en: 'Grading'}[lang] }}</th>
					<th class="gd-header-score">{{ {tc:'滿分',sc:'滿分', en: 'Score'}[lang] }}</th>
				</tr>
				<tr class="gd-row" [class.last-row]="last" *ngFor="let row of info.scoringItems;let last = last;">
					<td class="gd-row-item">{{row.title}}</td>
					<td class="gd-row-score">{{row.score}}</td>
				</tr>
			</table>
		</bubbleBox2>

	</modal2>
	
	`,
	styleUrls: ['./aiCompositionSubmission.modal.scss'],
})

export class AICompositionSubmissionModal implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('modal', { static: false }) modal;
	@ViewChild('historyBB', { static: false }) historyBB: BubbleBox2Component;
	@ViewChild('showGradingBB', {static: false}) showGradingBB: BubbleBox2Component;
	public photo;
	public details;
	public rows: any[] = [];
	private joinTime;
	public videoType;
	private sessionToken;
	public modalTitle = '';
	public modalContentStyle = {};
	public confirmLabel;
	public info;
	public faMagnifyingGlass = faMagnifyingGlass;
	public faVolume = faVolume;
	public lang;
	public media;
	public okaPulldownStyle = { width: '50px' };
	public aiGuideOptions: any = [];
	public aiGuide = 1;
	public aiGuideUsed = 0;
	public aiGuideResults: any = [];
	public aiSuggestionsOptions: any = [];
	public aiSuggestions: any = [];
	public aiSuggestionsUsed: any = [];
	public aiSuggestionsResults: any = [];
	public aiFeedbacksOptions: any = [];
	public aiFeedbacks: any = [];
	public aiFeedbacksUsed: any = [];
	public aiFeedbacksResults: any = [];
	public aiSuggestionsDisplayText: any = [];
	public aiFeedbacksDisplayText: any = [];
	public paragraphs: any = [];
	public askAIText;
	public audioObj;
	public aiLoading = false;
	public faTimer = faTimer;
	public history;
	public displayGuide = '';
	public finalComment;
	public status = 'open';
	public statusText;
	public readOnly = false;
	constructor(private san: DomSanitizer, private trans: TranslateService, public datas: DataService, private http: HttpClient, private als: AlertService, public eleRef: ElementRef, public theme: ThemeService, private common: CommonService, private lds: LoadingService, public tts: TTSService, public zone: NgZone) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.modalTitle = this.data.entry.entry_title;
		this.modalContentStyle = { width: 'calc(100vw - 40px)', height: 'calc(100dvh - 94px)', overflow: 'hidden' };
		this.askAIText = { tc: '問AI', sc: '问AI', en: 'Ask AI' }[this.datas.lang];
		this.info = this.data.info;
		this.readOnly = this.data.readOnly;
		this.initTheme(this.eleRef.nativeElement);
		console.log(this.info);
		this.initOptions(this.info);
		this.initStatus(this.data.share);
		this.getData();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.modal.open().then(event => {

			});
		}, 10);
	}

	ngOnDestroy(): void {
	}

	getData() {
		const bsid = this.data.share.id;
		const bookId = this.data.entry.entry_id;
		this.datas.post('ROBookshelfNew.get_ai_composition_student', [bsid, bookId]).subscribe((res: any) => {
			if (res.answer) {
				const answer = JSON.parse(res.answer);
				if (answer.paragraphs) {
					answer.paragraphs.forEach((text, index) => {
						this.paragraphs[index].text = text;
					});
				}
			}

			if (res.response) {
				try {
					const responseObj = JSON.parse(res.response);
					this.finalComment = { comments: '', res: responseObj };
					if (res.row.teacher_comment) {
						this.finalComment.comments = res.row.teacher_comment;
					} else if (res.row.ai_comment) {
						this.finalComment.comments = res.row.ai_comment;
					}
				} catch (e) {

				}
			}
			if (res.histories) {
				res.histories.forEach((history, index) => {
					const content = JSON.parse(history.content || {});
					const paragraphIndex = content.paragraph ? content.paragraph - 1 : 0;
					const paragraph = this.paragraphs[paragraphIndex];
					const answer = content.answer ? content.answer : null;
					const historyObj: any = { answer: answer, query_type: history.query_type, response: history.response, index: +history.index };
					const url = content.url ? content.url : '';
					if (history.query_type == 'guide') {
						this.aiGuideUsed++;
						this.aiGuide++;
						const withoutTextIndex = this.aiGuideResults.findIndex(e=> e.text === '');
						if (withoutTextIndex < this.aiGuideResults.length){
							this.aiGuideResults[withoutTextIndex].text = history.response;
						} else {
							let result = {text: history.response, voice: null, url: ''};
							this.aiGuideResults.push(result);
						}
						this.displayGuide = history.response;
					} else if (history.query_type == 'feedback') {
						this.aiFeedbacksUsed[paragraphIndex]++;
						if (this.aiFeedbacksResults.length > paragraphIndex){
							if (this.paragraphs[paragraphIndex].text == answer) { //當前評語才顯示
								if (history && history.index !== undefined && this.aiFeedbacksResults[paragraphIndex].length > history.index){
									this.aiFeedbacksResults[paragraphIndex][history.index].text = history.response;
									this.aiFeedbacksResults[paragraphIndex][history.index].url = url;
									this.aiFeedbacks[paragraphIndex].text = this.aiFeedbacksUsed[paragraphIndex];
									paragraph.feedback = history.response;
								}
							}
						}
					} else if (history.query_type == 'suggestion') {
						this.aiSuggestionsUsed[paragraphIndex]++;
						this.aiSuggestionsResults[paragraphIndex][history.index].text = history.response;
						this.aiSuggestionsResults[paragraphIndex][history.index].url = url;
						this.aiSuggestions[paragraphIndex].text = this.aiSuggestionsUsed[paragraphIndex];
						paragraph.suggestion = history.response;
					}
					paragraph.histories.push(historyObj);
				});
				console.log(this.aiGuideResults, this.paragraphs);
				this.paragraphs.forEach(paragraph => {
					paragraph.histories = paragraph.histories.sort((a, b) => {
						if (a > b) {
							return 1;
						} else if (b > a) {
							return -1;
						} else {
							return 0;
						}
					});
				});

				this.aiGuide = this.aiGuideUsed == 0 ? 1 : this.aiGuideUsed;
				this.pdChange();
			}
		});	
	}

	initOptions(info) {
		if (info.prewritingAIGuideEnabled) {
			for (let i = 1; i <= info.prewritingAIGuideTimes; i++) {
				this.aiGuideOptions.push({ label: i, value: i });
				this.aiGuideResults.push({ text: '', voice: null, url: '' });
			}
		}
		for (let i = 0; i < (info.paragraph || 1); i++) {
			this.aiSuggestions.push({ text: 1 });
			this.aiSuggestionsUsed.push(0);
			this.aiFeedbacks.push({ text: 1 });
			this.aiFeedbacksUsed.push(0);
			this.aiSuggestionsResults.push([]);
			this.aiFeedbacksResults.push([]);
			this.aiFeedbacksDisplayText.push('');
			this.aiSuggestionsDisplayText.push('');
		}
		const paragraph = parseInt(info.paragraph);
		this.paragraphs = [];
		for (let i = 1; i <= (paragraph || 1); i++) {
			this.paragraphs.push({ id: i, updated: +new Date(), text: '', feedback: '', suggestion: '', histories: [] });
		}
		if (info.prewritingAISuggestionEnabled && paragraph > 1) {
			const prewritingAISuggestionTimes = parseInt(info.prewritingAISuggestionTimes);
			for (let i = 0; i < prewritingAISuggestionTimes; i++) {
				this.aiSuggestionsOptions.push({ label: i + 1, value: i + 1 });
			}

			for (let i = 0; i < paragraph; i++) {
				this.aiSuggestionsResults[i] = [];
				for (let j = 0; j < prewritingAISuggestionTimes; j++) {
					this.aiSuggestionsResults[i].push({ text: '', voice: null, url: '' });
				}
			}
		}

		if (info.prewritingAIFeedbackEnabled) {
			const prewritingAIFeedbackTimes = parseInt(info.prewritingAIFeedbackTimes);
			for (let i = 0; i < prewritingAIFeedbackTimes; i++) {
				this.aiFeedbacksOptions.push({ label: i + 1, value: i + 1 });
			}

			for (let i = 0; i < (paragraph || 1); i++) {
				this.aiFeedbacksResults[i] = [];
				for (let j = 0; j < prewritingAIFeedbackTimes; j++) {
					this.aiFeedbacksResults[i].push({ text: '', voice: null, url: '' });
				}
			}
		}
		if (info.mediaUrl) {
			const url = '//oka.blob.core.windows.net/media/' + info.mediaUrl;
			this.media = this.san.bypassSecurityTrustStyle("url('" + url + "')");
		}
	}

	initTheme(ele) {
		this.theme.applyStyleObj({
			default: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#333",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-selectedtextcolor": "var(--section-maincolor)",
				"pulldown-overbgcolor": "#e2e2e2",
				"popupform-panelbgcolor": "#f5f5f5",
				"color-ccc-38374f": "#ccc",
				"popupform-rowbgcolor": "#fff",
				"popupframe-headerbgcolor": "#fff",
				"popupframe-titlecolor": "#333",
				"popupform-subtitlecolor": "#333",
				"popupform-textcolor": "var(--section-maincolor)",
				"color-f9f9f9-1e1e29": "#f9f9f9",
				"color-333-fff": "#333",
				"alert-bgcolor": "#fff",
				"color-f1f1f7-262635": "#f1f1f7",
				"color-f7f7f7-222237": "#f7f7f7",
				"color-ececec-262635": "#ececec",
				"pulldown-selectedbgcolor": "var(--section-maincolor)"
			},
			dark: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-selectedtextcolor": "#fff",
				"pulldown-overbgcolor": "#1e1e29",
				"popupform-panelbgcolor": "#38374f",
				"color-ccc-38374f": "#38374f",
				"popupform-rowbgcolor": "#1e1e29",
				"popupframe-headerbgcolor": "#1e1e29",
				"popupframe-titlecolor": "#fff",
				"popupform-subtitlecolor": "#a0a0a0",
				"popupform-textcolor": "#fff",
				"color-f9f9f9-1e1e29": "#1e1e29",
				"color-333-fff": "#fff",
				"alert-bgcolor": "#2d2c43",
				"color-f1f1f7-262635": "#262635",
				"color-f7f7f7-222237": "#222237",
				"color-ececec-262635": "#262635",
				"pulldown-selectedbgcolor": "#fff"
			}
		}, ele);

	}

	previewMedia() {
		const previewComp = this.data.previewComp;
		const url = '//oka.blob.core.windows.net/media/' + this.info.mediaUrl;
		const fileObj: any = { name: "", url: url, ext: "png" };
		previewComp.setFile(fileObj);
		previewComp.open();
	}

	closeClick() {
		if (this.data.callback) {
			this.data.callback(null);
		}
		this.close.emit();
	}

	confirm() {
		if (this.status != 'open') {
			this.close.emit();
			return;
		}
		const bsid = this.data.share.id;
		const bookId = this.data.entry.entry_id;
		let paragraphs = this.paragraphs.map(e => e ? e.text : '');
		let answer: any = { paragraphs: paragraphs };
		this.lds.add('save_ai_composition_answer', 10000);
		const pageId = this.data.structure.book.chapters[0].pages[0].id;
		const result = { correction: false, score: 0, correct: -1 };
		let records: any = [];
		this.data.structure.book.chapters[0].pages[0].components.forEach(comp => {
			const compId = comp.cid;
			const addRecord = { type: 'add', tag: 'ai-composition', doc: bookId, page: pageId, com: compId, answer: '', result: JSON.stringify(result) };
			const submitRecord = { lesson_id: 0, doc: bookId, "page": pageId, mode: "none", ref_id: 0, type: "submit" };
			records.push(addRecord, submitRecord);
		});
		const saveShare = this.datas.post('ROBookShare.save', [bsid, records, bookId]);
		const summary = { score: 0, green_count: 0, red_count: 0, orange_count: 0, blue_count: records.length, total_count: records.length, forceNotPass: 1 };
		const endSession = this.datas.post('ROCourse.start_then_end_book_session', [bsid, bookId, summary]);
		const saveAnswer = this.datas.post('ROBookshelfNew.save_ai_composition_answer', [bsid, bookId, answer]);

		saveShare.subscribe(event => {
			forkJoin([endSession, saveAnswer]).subscribe(event => {
				this.lds.remove('save_ai_composition_answer');
				if (this.data.callback) {
					this.data.callback(this.data);
				}
				this.close.emit(answer);
			});
		});


	}

	askAIClick(aiType, index = 0) {
		const bsid = this.data.share.id;
		let content: any = { bsid: bsid };
		if (aiType == 'suggestion' || aiType == 'feedback') {
			content.paragraph = index + 1;
			content.answer = this.paragraphs[index].text;
		}
		if (aiType == 'guide' && this.info.prewritingAIGuideTimes - this.aiGuideUsed == 0) {
			return;
		}
		if (aiType == 'feedback' && this.info.prewritingAIFeedbackTimes - this.aiFeedbacksUsed[index] == 0) {
			return;
		}
		if (aiType == 'suggestion' && this.info.prewritingAISuggestionTimes - this.aiSuggestionsUsed[index] == 0) {
			return;
		}
		const hostname = 'dev.openknowledge.hk';
		const url = '//' + hostname + '/RainbowOne/index.php/PHPGateway/proxy/2.8';
		let askIndex = 0;
		if (aiType == 'guide') {
			askIndex = this.aiGuideUsed;
		} else if (aiType == 'suggestion') {
			askIndex = this.aiSuggestionsUsed[content.paragraph - 1];
		} else { //feedback
			askIndex = this.aiFeedbacksUsed[content.paragraph - 1];
		}
		this.lds.add('ask_ai', 10000);
		this.datas.post('ROChatGPT.ask_ai_composition_queue', [aiType, content, this.data.entry.entry_id, bsid, askIndex], url).subscribe((res: any) => {
			const recordId = res.recordId;
			let message = null;
			const checkContent = () => {
				setTimeout(() => {
					this.datas.post('ROChatGPT.ask_ai_composition_check', [recordId], url).subscribe((res2: any) => {
						if (res2.response) {
							const regex =  /^{.*}$/;
							if (regex.test(res2.response)){
								this.lds.removeAll();
								const msg = {tc: '系統現時繁忙，請稍後再試。',sc:'系统现时繁忙，请稍后再试。',en:'The system is busy. Try again later.'}[this.lang];
								this.als.toastError(msg);
								return;
							}
							message = res2.response.replace(/^"/g, '').replace(/"$/g, '');

							this.tts.loadTTS({ txt: message }).then((url: any) => {
								this.zone.run(() => {
									content.url = url;
									let time = 0;
									// this.insertHistory(message, aiType, content);
									if (aiType == 'guide') {
										this.aiGuideResults[this.aiGuideUsed].text = message;
										this.aiGuide = this.aiGuideUsed == 0 ? 1 : this.aiGuideUsed + 1;
										time = this.aiGuideUsed;
										this.aiGuideUsed++;
										this.pdChange();
									} else {
										if (aiType == 'suggestion') {
											this.aiSuggestionsResults[index][this.aiSuggestionsUsed[index]].text = message;
											this.aiSuggestionsResults[index][this.aiSuggestionsUsed[index]].url = url;
											this.aiSuggestionsUsed[index] = this.aiSuggestionsUsed[index] + 1;
											this.aiSuggestions[index].text = this.aiSuggestionsUsed[index] == 0 ? 1 : this.aiSuggestionsUsed[index];
											time = this.aiSuggestionsUsed[index] - 1;
											this.paragraphs[index].suggestion = message;
										} else if (aiType == 'feedback') {
											this.aiFeedbacksResults[index][this.aiFeedbacksUsed[index]].text = message;
											this.aiFeedbacksResults[index][this.aiFeedbacksUsed[index]].url = url;
											this.aiFeedbacksUsed[index] = this.aiFeedbacksUsed[index] + 1;
											this.aiFeedbacks[index].text = this.aiFeedbacksUsed[index] == 0 ? 1 : this.aiFeedbacksUsed[index];
											time = this.aiFeedbacksUsed[index] - 1;
											this.paragraphs[index].feedback = message;
										}
										this.aiLoading = true;
										this.paragraphs[index].updated = +new Date();
										this.pdChange();
									}
									this.lds.remove('ask_ai');
									setTimeout(() => { this.aiLoading == false }, 50);
									let historyItem: any = { query_type: aiType, answer: content.answer, response: message, index: time };
									this.paragraphs[index].histories.push(historyItem);
								});
							});
						} else {
							checkContent();
						}
					});
				}, 2000);
			};
			checkContent();
		});
	}

	playVoiceObj($event, obj, aiType, pi) {
		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}
		if (aiType == 'feedback' || aiType == 'suggestion') {
			let timeIndex = 0;
			if (aiType == 'feedback') {
				timeIndex = this.aiFeedbacks[pi].text - 1;
				obj = this.aiFeedbacksResults[pi][timeIndex];
			} else if (aiType == 'suggestion') {
				timeIndex = this.aiSuggestions[pi].text - 1;
				obj = this.aiSuggestionsResults[pi][timeIndex];
			}
		}
		if (!obj.url) {
			this.tts.loadTTS({ txt: obj.text }).then((url: any) => {
				obj.url = url;
				this.playVoiceObj($event, obj, aiType, pi);
			}).catch(e => {
				debugger;
				console.error(e);
			});
			return;
		}
		if (this.audioObj && !this.audioObj.paused && this.audioObj.getAttribute('url') == obj.url) {
			this.audioObj.pause();
			this.audioObj = null;
			return;
		}
		this.audioObj = new Audio();
		this.audioObj.setAttribute("src", obj.url);
		this.audioObj.play();
	}

	trackParagraph(index, item) {
		return item.updated;
	}

	historyClick($event, index) {
		let items = this.paragraphs[index].histories.filter(e => e.query_type == 'feedback');
		items = items.map(e => {
			return { text: e.response, answer: e.answer, response: e.response, url: '' };
		});
		this.history = { items: items, pi: index };
		const buttonEl = $event.target;
		const targetEl = this.eleRef.nativeElement.querySelector('modal2');
		this.historyBB.open(buttonEl, targetEl).catch();
	}

	insertHistory(message, aiType, content) {
		const bsid = this.data.share.id;
		const bookId = this.data.entry.entry_id;
		let index = 0;
		if (aiType == 'guide') {
			index = this.aiGuideUsed;
		} else if (aiType == 'suggestion') {
			index = this.aiSuggestionsUsed[content.paragraph - 1];
		} else { //feedback
			index = this.aiFeedbacksUsed[content.paragraph - 1];
		}
		this.datas.post('ROBookshelfNew.insert_ai_composition_history', [bsid, bookId, aiType, message, content, index]).subscribe((res: any) => {
			//nothing
			console.log(res);
		});
	}

	pdChange() {
		if (this.aiGuideResults.length > this.aiGuide && this.aiGuideResults[this.aiGuide - 1].text !== '') {
			this.displayGuide = this.aiGuideResults[this.aiGuide - 1].text;
		} else {
			const hasTextIndex = this.aiGuideResults.findIndex(e=> e.text !== '');
			if (hasTextIndex && hasTextIndex < this.aiGuideResults.length){
				this.displayGuide = this.aiGuideResults.length > hasTextIndex?this.aiGuideResults[hasTextIndex].text:'';
			}
		}
		this.paragraphs.forEach((paragraph, pi) => {
			let feedbackTimeIndex = this.aiFeedbacks[pi].text - 1;
			if (feedbackTimeIndex !== null && this.aiFeedbacksResults[pi][feedbackTimeIndex]) {
				const text = this.aiFeedbacksResults[pi][feedbackTimeIndex].text;
				if (text !== ''){
					paragraph.feedback = this.aiFeedbacksResults[pi][feedbackTimeIndex].text;
				}
			}
			let suggestionTimeIndex = this.aiSuggestions[pi].text - 1;
			if (suggestionTimeIndex !== null && this.aiSuggestionsResults[pi][suggestionTimeIndex]) {
				const text = this.aiSuggestionsResults[pi][suggestionTimeIndex].text;
				if (text !== ''){
					paragraph.suggestion = text;
				}
			}
		});
	}

	initStatus(share) {
		const lang = this.lang;
		this.confirmLabel = { tc: '提交', sc: '提交', en: 'Submit' }[lang];
		if (share.status == 'checking') {
			this.status = 'checking';
			this.statusText = { tc: '批改中', sc: '批改中', en: 'checking' }[lang];
			this.confirmLabel = { tc: '確定', sc: '确定', en: 'Confirm' }[lang];
		} else if (share.status == 'finished') {
			this.status = 'finished';
			this.statusText = { tc: '已完成', sc: '已完成', en: 'Finished' }[lang];
			this.confirmLabel = { tc: '確定', sc: '确定', en: 'Confirm' }[lang];
		}
	}

	showGrading($event){
		const el = $event.target;
		this.showGradingBB.open(el, this.eleRef.nativeElement).catch(e=>{});
	}

}
