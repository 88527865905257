import { XMLNode } from "./xml/XMLNode";

export class CoordinateExpression
{
	public static COORDINATE_MODE_DOCUMENT:string = "COORDINATE_MODE_DOCUMENT";
	public static COORDINATE_MODE_STAGE:string = "COORDINATE_MODE_STAGE";
	
	private instanceX:number;
	private instanceY:number;
	
	public left:number = 0;
	public right:number = Number.NaN;
	public hozonitalCenter:number = Number.NaN;
	public top:number = 0;
	public bottom:number = Number.NaN;
	public verticalCenter:number = Number.NaN;
	public advanceMode:Boolean = false;
	public rotation:number;
	protected _w:number = 0;
	protected _h:number = 0;
	protected _scaleX:number = 1;
	protected _scaleY:number = 1;

	public coordinateMode:string;
	protected size:Object;
	
	public pageWidth:number = 1024;
	public pageHeight:number = 768;
	public screenWidth:number = 1024;
	public screenHeight:number = 768;
	//////// Lock Type
	public lock:Boolean = false;
	public lockWidth:Boolean = false;
	public lockHeight:Boolean = false;
	public lockScale:Boolean = false;
	public lockRotation:Boolean = false;
	public lockXY:Boolean;

	// A = 進階模式
	// UA = 一般模式
	// D = 頁面座標
	// S = 螢幕座標
	// K = 鎖
	// UK = 不鎖
	// L = 左
	// R = 右
	// M = 水平中
	// T = 上
	// B = 下
	// C = 垂直中
	// W = 闊
	// H = 高
	// SX = ScaleX;
	// SY = ScaleY;
	constructor(private page:XMLNode = null, private parent:any)
	{
		this.coordinateMode = CoordinateExpression.COORDINATE_MODE_DOCUMENT;
		this.left = 0;
		this.top = 0;
		this._w = 0;
		this._h = 0;
		this.rotation = 0;
	}
	
	// ===================================================
	// 
	// ===================================================
	public parseExpression(str:string):void
	{
		this.advanceMode = false;
		this.lockScale = false;
		this.lockWidth = false;
		this.lockHeight = false;
		this.lockRotation = false;
		this.lockXY = false;
		var hasPosition:Boolean  = false;
		var ary:any [] = str.split(" ").map((str:string)=>{
			if(/[A-z]/.test(str))
			{
				// non number
				return str;
			} else if(/[0-9]{1}/.test(str))
			{
				return parseFloat(str);
			} else {
				return str;
			}
		});
		
		this.rotation = 0;
		for (var i:number = 0; i < ary.length; i++)
		{
			var type:string = ary[i];
			// set 1
			if (type == "D")
			{
				this.coordinateMode = CoordinateExpression.COORDINATE_MODE_DOCUMENT;
			}
			else if (type == "S")
			{
				this.coordinateMode = CoordinateExpression.COORDINATE_MODE_STAGE;
			}
			// set 2
			else if (type == "O")
			{
				this.rotation = ary[i + 1];
				i++;
			}
			else if (type == "X")
			{
				hasPosition = true;
				this.instanceX = ary[i + 1];
				if (Math.abs(this.instanceX) > 100000000) this.instanceX = 0; // unknow error appear
				i++;
			}
			else if (type == "Y")
			{
				this.instanceY = ary[i + 1];
				if (Math.abs(this.instanceY) > 100000000) this.instanceY = 0; // unknow error appear
				i++;
			}
			else if (type == "T")
			{
				
				this.top = ary[i + 1];
				if (Math.abs(this.top) > 100000000) this.top = 0; // unknow error appear
				this.verticalCenter = Number.NaN;
				this.bottom = Number.NaN;
				i++;
			}
			else if (type == "C")
			{
				this.top = Number.NaN;
				this.verticalCenter = ary[i + 1];
				this.bottom = Number.NaN;
				i++;
			}
			else if (type == "B")
			{
				this.top = Number.NaN;
				this.verticalCenter = Number.NaN;
				this.bottom = ary[i + 1];
				i++;
			}
			// set 3
			else if (type == "L")
			{
				this.left = ary[i + 1];
				if (Math.abs(this.left) > 100000000) this.left = 0; // unknow error appear
				this.hozonitalCenter = Number.NaN;
				this.right = Number.NaN;
				i++;
			}
			else if (type == "M")
			{
				this.left = Number.NaN;
				this.hozonitalCenter = ary[i + 1];
				this.right = Number.NaN;
				i++;
			}
			else if (type == "R")
			{
				this.left = Number.NaN;
				this.hozonitalCenter = Number.NaN;
				this.right = ary[i + 1];
				i++;
			}
			// set 4
			else if (type == "W")
			{
				this._w = ary[i + 1];
				i++;
			}
			else if (type == "LR")
			{
				this.left = ary[i + 1];
				this.hozonitalCenter = Number.NaN;
				this.right = ary[i + 2];
				i+=2;
			}
			// set 5
			else if (type == "H")
			{
				this._h = ary[i + 1];
				i++;
			} else if (type == "SX")
			{
				this._scaleX = ary[i + 1];
				i++;
			} else if (type == "SY")
			{
				this._scaleY = ary[i + 1];
				i++;
			}
			else if (type == "TB")
			{
				this.top = ary[i + 1];
				this.verticalCenter = Number.NaN;
				this.bottom = ary[i + 2];
				i+=2;
			}
			else if (type == "SIZE")
			{
				this.pageWidth = ary[i + 1];
				this.pageHeight = ary[i + 2];
				this.screenWidth = ary[i + 3];
				this.screenHeight = ary[i + 4];
				i += 4;
			}
			else if (type == "A")
			{
				this.advanceMode = true;
			}
			else if (type == "UA")
			{
				this.advanceMode = false;
			}
			else if (type == "K")
			{
				this.lock = true;
			} else if ( type == "KW")
			{
				this.lockWidth = true;
			} else if (type == "KH")
			{
				this.lockHeight = true;
			}
			else if (type == "UK")
			{
				this.lock = false;
			} else if (type == "KS")
			{
				this.lockScale = true;
			} else if (type == "KP")
			{
				this.lockXY = true;
			} else if (type == "KR")
			{
				this.lockRotation = true;
			}
		}
		if(hasPosition == false)
		{
			
			if(this.advanceMode)
			{
				//this.instanceX = x;
				//this.instanceY = y;
			} else {
				this.advanceMode = true;
				//this.instanceX = x;
				//this.instanceY = y;
				this.advanceMode = false;
			}
		}
	}

	public buildExpression(withSizeReference:boolean = false):string
	{
		var ary:any[] = [];
		
		ary.push((this.advanceMode) ? "A" : "UA");
		ary.push(this.lock ? "K" : "UK");
		
		if(this.lockWidth)		ary.push("KW");
		if(this.lockHeight)		ary.push("KH");
		if(this.lockScale)		ary.push("KS");
		if(this.lockXY)			ary.push("KP");
		if(this.lockRotation)	ary.push("KR");
		
		ary.push("X", this.instanceX);
		ary.push("Y", this.instanceY);
		if(this._scaleX != 1)	ary.push("SX", this._scaleX);
		if(this._scaleY != 1)	ary.push("SY", this._scaleY);
		
		ary.push((this.coordinateMode == CoordinateExpression.COORDINATE_MODE_DOCUMENT) ? "D" : "S");
		
		if (withSizeReference)
			ary.push("SIZE", this.pageWidth, this.pageHeight, this.screenWidth, this.screenHeight);
		
		if (!isNaN(this.top))
		{
			if (!isNaN(this.bottom))
			{
				// top bottom
				ary.push("TB", this.top, this.bottom);
			}
			else
			{
				// from top
				ary.push("T", this.top);
			}
		}
		else if (!isNaN(this.bottom))
		{
			// from bottom
			ary.push("B", this.bottom);
		}
		else
		{
			// from center
			ary.push("C", this.verticalCenter);
		}
		
		if (!isNaN(this.left))
		{
			if (!isNaN(this.right))
			{
				// left right
				ary.push("LR", this.left, this.right);
			}
			else
			{
				// left
				ary.push("L", this.left);
			}
		}
		else if (!isNaN(this.right))
		{
			// right
			ary.push("R", this.right);
		}
		else
		{
			// middle
			ary.push("M", this.hozonitalCenter);
		}
		
		if (isNaN(this.top) || isNaN(this.bottom))
		{
			ary.push("H", this._h);
		}
		
		if (isNaN(this.left) || isNaN(this.right))
		{
			ary.push("W", this._w);
		}
		
		if (this.rotation)
		{
			ary.push("O", Math.round(this.rotation));
		}
		return ary.join(" ");
	}

	// ===================================================
	// coordinate get function
	// ===================================================
	public getX():number
	{
		if (this.advanceMode)
		{
			if (!isNaN(this.left))
			{
				return this.left;
			} else if(!isNaN(this.right))
			{
				var w:number = this.page.attributes.w
				return w - this.right - this._w;
			} else if(!isNaN(this.hozonitalCenter))
			{
				return (this.page.attributes.w - this._w)/ 2 + this.hozonitalCenter;
			}
		}
		if (!isNaN(this.instanceX))
		{
			return this.instanceX;
		}
		return this.left;
		
	}
	public getY():number
	{
		if (this.advanceMode)
		{
			if (!isNaN(this.top))
			{
				return this.top;// left right, left
			} else if(!isNaN(this.bottom))
			{
				var h:number = this.page.attributes.h
				return h - this.bottom - this._h;
			} else if(!isNaN(this.verticalCenter))
			{
				return (this.page.attributes.h - this._h)/ 2 + this.verticalCenter;
			}
		}
		if (!isNaN(this.instanceY))
		{
			return this.instanceY;
		}
		return this.top;
	}
	public getWidth():number
	{
		if (this.advanceMode)
		{
			if (!isNaN(this.left) && !isNaN(this.right))
			{
				return this.refWidth - this.left - this.right;
			}
		}
		return this._w;
	}
	public getHeight():number
	{
		if (this.advanceMode)
		{
			if (!isNaN(this.top) && !isNaN(this.bottom))
			{
				return this.refHeight - this.top - this.bottom;
			}
		}
		return this._h;
	}

	public get refWidth():number
	{
		return (this.coordinateMode == CoordinateExpression.COORDINATE_MODE_DOCUMENT) ? 
			this.page.getAttribute("w") : 
			this.page.getAttribute("w");//  screenWidth;
			
	}
	
	public get refHeight():number
	{

		return (this.coordinateMode == CoordinateExpression.COORDINATE_MODE_DOCUMENT) ? 
			this.page.getAttribute("h") : 
			this.page.getAttribute("h");// screenHeight;
	}

	getRectangle(): any {
		return {
			x:this.getX(),
			y:this.getY(),
			w:this.getWidth(),
			h:this.getHeight()
		};
	}
	getCenter(): any {
		return {
			x:this.getX() + this.getWidth() / 2,
			y:this.getY() + this.getHeight() / 2,
		};
	}

	// ===================================================
	// coordinate set function
	// ===================================================
	public setX(val:number):void
	{
		this.instanceX = val;
//		val -= xoff;
		
		if (!isNaN(this.left))
		{
			this.left = val;
		}
		else if (!isNaN(this.right))
		{
			// right
			this.right = this.refWidth - this._w;
		}
		else
		{
			// middle
			this.hozonitalCenter = val + (this._w - this.refWidth)/2;
		}
	}
	
	public setY(val:number):void
	{
		this.instanceY = val;
//		val -= yoff;
		
		if (!isNaN(this.top))
		{
			this.top = val;
		}
		else if (!isNaN(this.bottom))
		{
			// bottom
			this.bottom = this.refHeight - (val + this._h);
		}
		else
		{
			// center
			this.verticalCenter = val + (this._h - this.refHeight)/2;
		}
	}
	
	public setWidth(val:number):void
	{
		// if (!lockWidth) 
		this._w = val;
	}
	
	public setHeight(val:number):void
	{
		// if (!lockHeight) 
		this._h = val;
	}

}