import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { FileIOService } from "src/app/service/FileIO.service";


@Component({
	selector:"QMediaAsset",
	template:`
	<ng-container *ngIf="input">
		<img *ngIf="fileio.isGraphic(input.name) && initImageSize(input)" class="imgContent"
			draggable="false"
			(load)="imgLoad($event, input)"
			[src]="fileio.getAssetsUrl(input)"
			(click)="openComMenu($event, assetKey, input)"
			[style.width.px]="input.width" [style.height.px]="input.height"
			 />
		<div *ngIf="fileio.isSound(input.name)" style="display: flex;">
			<audioPlayer [class.edit]="state.inEdit" 
				[audio_src]="fileio.getAssetsUrl(input)" [timeline_width]="'100px'"
				(click)="openComMenu($event, assetKey, input, 'AUDIOPLAYER')"></audioPlayer>
		</div>
		<video *ngIf="fileio.isVideo(input.name)" class="videoContent" controls="" 
			controlslist="nodownload nofullscreen noremoteplayback" [src]="fileio.getAssetsUrl(input)"
			(click)="openComMenu($event, assetKey, input)"></video>
	</ng-container>
	`
})

export class QMediaAsset implements OnChanges
{
	@Input() file:any;
	@Input() input:any;
	@Input() assetKey:string;
	@Input() state:any = {};

	constructor(elementRef:ElementRef, 	public fileio:FileIOService)
	{
		
	}
	imgLoad(event, input)
	{

	}
	ngOnChanges(changes: SimpleChanges): void {
	}


	openComMenu(event, key, input, name = null)
	{
		
	}
	public initImageSize(asset:any):boolean {
		if(!asset.hasOwnProperty("width")) {
			asset.width = 0;
			asset.height = 0;
		}
		return true;
	}

}


@Component({
	selector:"QMediaAssets",
	template:`
	<ng-container *ngIf="input && assetKey">
		<ng-container *ngIf="input[assetKey].files && input[assetKey].files.length>0">
			<ng-container *ngFor="let f of input[assetKey].files">
				<!-- 
				<div *ngTemplateOutlet="mediaAsset; context: {$implicit: f, assetKey: assetKey}"></div>
				-->
				<QMediaAsset [input]="f"></QMediaAsset>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="files && files.length">
		<ng-container *ngFor="let f of files">
			<!-- 
			<div *ngTemplateOutlet="mediaAsset; context: {$implicit: f, assetKey: assetKey}"></div>
			-->
			
			<QMediaAsset [input]="f"></QMediaAsset>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="file">
		
		<QMediaAsset [input]="file"></QMediaAsset>
	</ng-container>
	
	`
})
export class QMediaAssets implements OnChanges
{

	@Input() files:any[];
	@Input() file:any;
	@Input() input:any;
	@Input() assetKey:string;
	
	constructor(elementRef:ElementRef)
	{
		
	}
	ngOnChanges(changes: SimpleChanges): void {
	}

}
