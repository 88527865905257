import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { CommonService } from "src/app/service/common.service";
import { StorageService } from "src/app/service/storage.service";
import { ModalService } from 'src/app/service/modal.service';
import * as moment from 'moment';
import * as RecordRTC from 'recordrtc';
import { RoService } from 'src/app/service/ro.service';
import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';
import { faPaperPlaneTop, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ScriptService } from 'src/app/service/script.service';
import { DataService } from 'src/app/service/data.service';
import { ChineseTextHelper } from 'src/app/common/ChineseTextHelper';
import { faMicrophone } from '@fortawesome/pro-solid-svg-icons';
import { set } from 'lodash';
import { AlertService } from 'src/app/service/alert.service';

@Component({
	selector: 'audio-recorder',
	template: `
			<div *ngIf="status == 'idle'" class="voice" [class.small-icon]="smallIcon" (click)="onRecordBtnClick()" [ngClass]="('not-touch')" [class.readonly]="panel.data.readonly"></div>
			<div *ngIf="status == 'recording'" class="recording" [class.container]="practiceSetting.input_mode == 'speechText' || localInputMode =='speechText'">
				<div class="trash-button" [class.speechText]="practiceSetting.input_mode == 'speechText'|| localInputMode =='speechText'" (click)="cancelClick($event)">
					<fa-icon [icon]="faTrash" ></fa-icon>
				</div>
				<ng-container *ngIf="practiceSetting.input_mode == 'speech' || localInputMode == 'speech'">
					<div class="button"  (click)="onStopBtnClick()">
						<fa-icon [icon]="faPaperPlane" ></fa-icon>
					</div>
					<div class="time-box">
						<span class="recording-time">{{formatTime(recordingTime)}}/01:00</span>
					</div>
				</ng-container>
			</div>
		
	  `,
	  styleUrls: ['./audio-recorder.component.scss']
	})
	
	// <div class="panel {{panelAnimation}}" *ngIf="recordObj">
	//   <div class="panel__time">{{recordObj.currentTime * 1000 }}</div>
	//   <div class="panel__button cancel" (click)="cancelClick($event)" [ngClass]="(common.inTouch?'':'not-touch')"></div>
	//   <div class="panel__button ok" (click)="okClick($event)" [ngClass]="(common.inTouch?'':'not-touch')"></div>
	// </div>
export class AudioRecorderComponent implements OnInit {
	@Input() panel;
	@Input() smallIcon = false;
	@Input() practiceSetting;
	@Input() localInputMode;
	@Output() sentVoiceMesaage: EventEmitter<any> = new EventEmitter();
	@Output() trashBinClicked: EventEmitter<any> = new EventEmitter();
	@Output() recording: EventEmitter<any> = new EventEmitter();
	@Output() studentSpeaking: EventEmitter<any> = new EventEmitter();


	public panelAnimation: any = 'move-up';
	public faPaperPlane: any = faPaperPlaneTop;
	public faTrash: any = faTrash;
	public status: string = "idle"; //"idle", "recording", "loading";
	public recTimeLimit: number = 300;
	public recorder: any = null;
	public stream: any = null;
	public timer;
	public recordingTime = 0;
	public faMicrophone = faMicrophone;

	constructor(public common: CommonService,private als: AlertService, public storage: StorageService, private modal: ModalService, private ro: RoService, private upload: UploadService, private script: ScriptService, public datas: DataService, private zone: NgZone ) {
		this.loadScript();

	 }

	private loadScript() {
		this.script.load('web-audio-recorder/WebAudioRecorder.min.js');
	}
	ngOnInit() {
	}

	// get recordingTime(): number {
		
	// 	if (this.status === "recording") {
	// 	  return Math.floor((Date.now() - this.timer)); // Calculate elapsed time in seconds
	// 	} else {
	// 	  return 0;
	// 	}
	//   }
	public formatTime(seconds: number): string {
		const minutes: number = Math.floor(seconds / 60);
		const remainingSeconds: number = seconds % 60;
		return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
	  }
	  

	public onRecordBtnClick(): void {
		setTimeout(() => {
			this.studentSpeaking.emit(true);
			this.recording.emit();
		}, 1000);
		this.timer = setInterval(() => {
			if(this.recordingTime >= 60){
				this.onStopBtnClick();
			}else{
				this.recordingTime++;
			}
		  }, 1000);
		// this.recordObj = { status: () => '' };
		let markAsRecordingFn: Function = () => {
			this.status = "recording";
			let recTime: number = 0;

			// let timer = setInterval(() => {
			// 	if (this.status != 'recording') {
			// 		clearInterval(timer);
			// 	} else if (recTime >= this.recTimeLimit) {
			// 		clearInterval(timer);
			// 		this.onStopBtnClick();
			// 	} else {
			// 		recTime++;
			// 	}
			// }, 1000);
		}

		// if (sessionStorage.getItem('in-web')) {
		// 	console.log(1);
			navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
				markAsRecordingFn();

				let options = {
					mimeType: "audio/wav",
					numberOfAudioChannels: 1,
					desiredSampRate: 16000,
				};
				//Start Actuall Recording
				let StereoAudioRecorder: any = RecordRTC.StereoAudioRecorder;
				this.recorder = new StereoAudioRecorder(stream, options);
				this.stream = stream;
				this.recorder.record();
			});
		// } else {
		// 	console.log(2);
		// 	markAsRecordingFn();
		// 	this.ro.request("audioRecordStart", {});
		// }

	}

	voiceClick($event) {
		$event.stopPropagation();
		clearTimeout(this.panel.parent.timer$);
		this.panel.parent.timer$ = null;
		
	}

	public onStopBtnClick(): void {
		this.studentSpeaking.emit(false);
		this.status = "loading";
		this.recordingTime = 0;
		clearInterval(this.timer);
		// if (sessionStorage.getItem('in-web')) {
			this.recorder.stop((blob) => {

				let file: File = new File([blob], "test.mp3");
				this.getSttApi(file);
			
				let tracks = this.stream.getTracks();
				for (let track of tracks) track.stop();
			});
		// } else {
		// 	this.ro.request("audioRecordStop", { complete: 1 }).then((res: any) => {
		// 		/*{
		// 			"len":139564,
		// 			"id":113935,
		// 			"duration":4454,
		// 			"code":1,
		// 			"key":"16130a3c8bf66725e69528",
		// 			"token":"88e551cefd3594624e284669dac5a24a",
		// 			"filename":"tmp_upload/2021/09/2021-09-02-181328-6130a3c8-16130a3c8bf839-2811f404.wav"
		// 		}*/

		// 		//https://ro2.azurewebsites.net/RainbowOne/tmp_upload/2021/09/2021-09-02-181328-6130a3c8-16130a3c8bf839-2811f404.wav
		// 		let server: string = environment.production ? '//api.openknowledge.hk/RainbowOne/' : '//ro2.azurewebsites.net/RainbowOne/'
		// 		let url: string = server + res.filename

		// 		this.datas.get2({ url: url, option: { headers: new HttpHeaders(), responseType: "blob" } })
		// 			.then((file: Blob) => this.getSttApi(<File>file));
		// 	});
		// }
		this.status = "idle";
	}

	public getSttApi(wavFile: File): Promise<void> {
		let data: FormData = new FormData();
		data.append('api', 'ROMediaLibrary.getSttFromWav');
		data.append('file', wavFile);

		var sttLangCode;
		if(this.practiceSetting.ai_language=="en-GB" || this.practiceSetting.ai_language =="en-US"){
			sttLangCode = "en-GB";
		}else if(this.practiceSetting.ai_language =="zh-HK"){
			sttLangCode = "yue-Hant-HK";
		}else if(this.practiceSetting.ai_language =="zh-CN"){
			sttLangCode = "zh";
		}
		
		data.append('json', JSON.stringify([sttLangCode]));

		return this.datas.post2({ data: data, loading: true }).then((res: any) => {
			if(!res.data || res.data.length == 0){
				setTimeout(() => {
					const msg = {tc:"系統偵測不到聲音，請再試一次", sc:"系统侦测不到声音，请再试一次" ,en:"System can't detect any sound, please try again"}
					this.als.toastError(msg[this.datas.lang]);
				}, 2000);
			}
			var message:string = "";
			if (res.data) {
				for (let data of res.data) {
					message = data.transcript;
				}
			}
			if(sttLangCode == "zh"){
				message = ChineseTextHelper.toSimplized(message);
			}else{
				message = ChineseTextHelper.toTraditionalized(message);

			}
			this.status = "idle";
			this.addMessage(wavFile, message);
			this.sentVoiceMesaage.emit(message);
			return;
		}).catch((err: any) => {
			return;
		});
	}

	public addMessage(file, message:string){
		this.upload.uploadFileObject(file).then((data: any) => {
			const panel = this.panel;
			const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
			const json = JSON.stringify({ path: data.url, size: data.len, length: data.len });
			const msgObj = { id: 0, content_type: 'voice', data: json, quote: panel.data.quote, author: this.datas.userInfo.uid, created_at: createdAt, voice_text: message };
			setTimeout(() => { panel.updateMsgObj(msgObj); }, 100);
			setTimeout(() => { panel.parent.scrollToBottom(); }, 800);
		});
	}

	cancelClick($event) {
		this.studentSpeaking.emit(false);
		this.status = "idle";
		this.recordingTime = 0;
		clearInterval(this.timer);
		this.timer = null;
		$event.stopPropagation();
		this.trashBinClicked.emit();
		this.recorder.stop((blob) => {

			let tracks = this.stream.getTracks();
			for (let track of tracks) track.stop();
		});
	}

	okClick($event) {
		$event.stopPropagation();
	}

	closeRecorder() {
		this.panelAnimation = 'move-down';
		let tt$ = setTimeout(() => {
			this.panelAnimation = 'move-up';
		}, 1000);
	}
}
