import { AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewContainerRef, OnChanges, OnDestroy } from "@angular/core";
import { fromEvent, Subscription, Subject } from "rxjs";
import { DOMHelper } from "src/app/common/DOMHelper";
import { HammerTransformControl } from "./HammerTransformControl";
import { takeUntil } from 'rxjs/operators';
// import * as Hammer from 'hammerjs';
@Directive({
	selector: '[hammertransform]'
})
export class HammerTransformDirective implements AfterContentInit, OnChanges, OnDestroy  {
	// hammer: Hammer;
	@Input() public transformTraget:any;
	@Input() public hammerTranformEnabled:boolean = true;
	@Output() public hammerTranformEnd:EventEmitter<any> = new EventEmitter();
	@Output() onTransformEnd:EventEmitter<any> = new EventEmitter();
	@Input() public boundary;
	private tranformInfo:any;
	public targetElement:any;
	control: HammerTransformControl;
	private onDestroy = new Subject();

	constructor(private elementRef: ElementRef) { 
		
		/*
		var element:HTMLElement = this.elementRef.nativeElement;
		element.addEventListener("touchstart", this.onStart.bind(this));
		element.addEventListener("mousedown", this.onStart.bind(this));
		*/
		/*
		element.addEventListener("hammer-panstart", this.onPanStart.bind(this));
		element.addEventListener("hammer-panmove", this.onPanMove.bind(this));
		element.addEventListener("hammer-panend", this.onPanEnd.bind(this));
		
		*/
		
		// element.addEventListener("mousedown", this.onMouseDown.bind(this));
		// element.addEventListener("pressstart", this.onPressStart.bind(this));
		// element.addEventListener("press", this.onPressStart.bind(this));
		// element.addEventListener("pressdown", this.onPressStart.bind(this));
		
		// element.addEventListener("panstart", this.onPanStart.bind(this));
		// element.addEventListener("panmove", this.onPanMove.bind(this));
		// element.addEventListener("panend", this.onPanEnd.bind(this));
		
		/*
		this.hammer = new Hammer(element);
		this.hammer.domEvents = true;
		this.hammer.on("panstart", this.onPanStart.bind(this));
		this.hammer.on("panmove", this.onPanMove.bind(this));
		this.hammer.on("panend", this.onPanEnd.bind(this));
		*/
		
	}
	
	
	ngAfterContentInit(): void {
		if(this.transformTraget)
		{
			if(this.transformTraget instanceof HTMLElement)
			{
				this.targetElement = this.transformTraget;
			} else if(this.transformTraget instanceof ElementRef)
			{
				this.targetElement = (<ElementRef>this.transformTraget).nativeElement;
			} else {
				this.targetElement = this.elementRef.nativeElement;
			}
		} else {
			this.targetElement = this.elementRef.nativeElement;
		}
		if (this.boundary){
			this.control = new HammerTransformControl(this.elementRef.nativeElement, this.targetElement, this.boundary, true);
		} else {
			this.control = new HammerTransformControl(this.elementRef.nativeElement, this.targetElement, null, true);
		}
		this.control.transformEnd.pipe(takeUntil(this.onDestroy)).subscribe(evt=>{
			this.hammerTranformEnd.emit(evt);
		});
	}

	ngOnChanges(changes){
		if (changes.hammerTranformEnabled && this.control){
			this.control.enabled = changes.hammerTranformEnabled.currentValue;
		}
	}

	ngOnDestroy(){
		this.onDestroy.next();
		this.onDestroy.complete();
	}
	
	// element.addEventListener("panmove", this.onPanMove.bind(this));
	// element.addEventListener("panend", this.onPanEnd.bind(this));
	public sub:Subscription;
	private startMonitorEvent():void
	{
		if(this.sub) return;
		this.sub = new Subscription(()=>{
		});
		// element.addEventListener("panstart", this.onPanStart.bind(this));
		this.sub.add(
			fromEvent(window, "panstart").subscribe((evt:any)=>{
				// console.log(evt);
				this.onPanStart(evt);
			})
		);
		this.sub.add(
			fromEvent(window, "panmove").subscribe((evt:any)=>{
				// console.log(evt);
				this.onPanMove(evt);
			})
		);
		this.sub.add(
			fromEvent(window, "panend").subscribe((evt:any)=>{
				this.onPanEnd(evt);
				this.sub.unsubscribe();
				this.sub = null;
			})
		);
		this.sub.add(
			fromEvent(window, "pancancel").subscribe((evt:any)=>{
				// this.onPanEnd(evt);
				console.log("pancancel");
				// this.sub.unsubscribe();
				// this.sub = null;
			})
		);		
	}
	
	public onStart(evt:any):void{
		this.tranformInfo = null;
		if(!this.hammerTranformEnabled) return;
		this.blockEvent(evt);
		this.startMonitorEvent();
	}
	public onPanStart(evt:any):void{
		this.blockEvent(evt);
		var detail:any = this.getEventDetail(evt);
		this.tranformInfo = null;
		if(!this.hammerTranformEnabled) return;
		this.tranformInfo = {
			transform:DOMHelper.getElementTransform3D(this.targetElement),
			scale:DOMHelper.getElementScale(this.targetElement.parentElement),
			start:{
				x:detail.center.x - detail.deltaX ,
				y:detail.center.y - detail.deltaY
			}
		};
	}
	
	private updateTransform(globalX:number, globalY:number, scale:number):any
	{
		var px:number = (globalX - this.tranformInfo.start.x) / this.tranformInfo.scale + this.tranformInfo.transform.x;
		var py:number = (globalY - this.tranformInfo.start.y) / this.tranformInfo.scale + this.tranformInfo.transform.y;
		if (this.boundary){
			const ele = <HTMLElement>document.querySelector(this.boundary);
			if (ele){

			}
		}
		this.targetElement.style.transform = `translate3d(${px}px, ${py}px, 0px)`;
		return {
			x:px,
			y:py
		};
	}
	// @HostListener('window:panmove', ['$event'])
	public onPanMove(evt:any):void
	{
		// console.log("directive.panmove", evt);
		if(!this.tranformInfo) return;
		if(!this.hammerTranformEnabled) return;
		this.blockEvent(evt);
		var detail:any = this.getEventDetail(evt);
		// var detail:any = evt;
		// var detail:any = evt.detail;
		this.updateTransform(detail.center.x, detail.center.y, 1);
	}

	// @HostListener('window:panend', ['$event'])
	public onPanEnd(evt:any):void
	{
		if(!this.tranformInfo) return;
		if(!this.hammerTranformEnabled) return;
		this.blockEvent(evt);
		// var detail:any = evt;
		var detail:any = this.getEventDetail(evt);
		// var detail:any = evt.detail;
		var info:any = this.updateTransform(detail.center.x, detail.center.y, this.tranformInfo.scale);
		info.width = this.targetElement.clientWidth;
		info.height = this.targetElement.clientHeight;
		this.hammerTranformEnd.emit(info);
		this.tranformInfo = null;
	}
	/// 
	// mapping
	private blockEvent(evt:any):void
	{
		if (evt.stopImmediatePropagation){
			evt.stopImmediatePropagation();
		}
		if (evt.stopPropagation){
			evt.stopPropagation();
		}
		if (evt.preventDefault){
			evt.preventDefault();
		}
	}
	private getEventDetail(evt:any):any
	{
		return evt.gesture;
	}
	
	/*
	private getElementTransformInfo(el:HTMLElement)
	{
		return DOMHelper.getElementTransform3D(el);
		
	}
	*/

	
	/*
	monitorEvent(events: string) {
		var eventArray:string[] = events.split(" ");
		eventArray.forEach((eventType:string)=>{
			this.addEventListener(eventType);
		})
	}
	
	addEventListener(eventType: string) {
		this.hammer.on(eventType, function(ev) {
			// console.log(eventType, ev);
		});
	}
	*/
};