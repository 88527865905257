import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faPlusCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { WhitePopupComponent } from 'src/app/sharedModule/whitePopupModule/whitePopup.component';
import { isFunction } from 'util';
import { WhitePopupService } from '../whitePopupModule/whitePopup.service';

@Component({
	selector: 'agGridFileFormatter',
	template: `
	<ng-container *ngIf="showData">
		<fa-icon *ngIf="multiAssets || (params && params.jsonObj && params.jsonObj.files && params.jsonObj.files.length==0)" 
			right [icon]="faPlusCircle" 
			class="addBtn" [class.disable]="!realEditable"
			(click)="addFile($event)"></fa-icon>

		<ng-container *ngIf="params && params.jsonObj && params.jsonObj.files && params.jsonObj.files.length>0">
			<span
				class="filename"
				*ngFor="let fileObj of params.jsonObj.files"
				[class.color]="params.sectionColor"
				(click)="openMenu($event, fileObj)"
			>{{fileObj.name}}</span>
		</ng-container>
	</ng-container>
	`,
	styleUrls: ['./fileFormatter.component.scss']
})

export class FileFormatterComponent /*implements AfterViewInit*/ {
	public faPlusCircle:IconDefinition = faPlusCircle;

	public params: any;
	public curFileObj:any;
	public multiAssets:boolean = true;
	public realEditable:boolean = false;
	public showData:boolean = false;
	/*
	assets data 格式：
	{
		files:[
			{
				name,
				url
			}, ...
		]
	}
	*/
	
	constructor(public translate:TranslateService, private eleRef:ElementRef, private whitePopupService:WhitePopupService) {
	}
    
    /*ngAfterViewInit(): void {
        //Required by Hung, 左邊不要margin(paddingLeft)
        setTimeout(()=>{
            if (this.eleRef && this.eleRef.nativeElement && this.eleRef.nativeElement.parentElement) {
                this.eleRef.nativeElement.parentElement.style.paddingLeft = '0px';
            }
        });
    }*/

	agInit(params:any): void {
		this.params = params;
		if(this.params.colDef.cellEditorParams && this.params.colDef.cellEditorParams.multiAssets)
			this.multiAssets = this.params.colDef.cellEditorParams.multiAssets;

		if(!this.params.colDef.orgEditable)
			this.params.colDef.orgEditable = params.colDef.editable;
		params.colDef.editable = false; // 防止 edit component create

		this.realEditable = this._realEditable();
		this.showData = !params.hideIf || !params.hideIf(params);
	}

	public _realEditable():boolean {
		if(isFunction(this.params.colDef.orgEditable))
			return this.params.colDef.orgEditable(this.params);
		return this.params.colDef.orgEditable;
	}

	public addFile(event):void {
		if(this.realEditable) {
			if(this.params.onSoundRecord) {
				this.whitePopupService.showSelection(
					event.target, [{
						hideIf: (data:any)=>false, titleKey: 'profile.uploadMedia',
						click: (data:any)=>this.params.onFileAdd(this.params)
					},{
						hideIf: (data:any)=>false, titleKey: 'profile.soundRecord',
						click: (data:any)=>this.params.onSoundRecord(this.params, null)
					}]
				).catch((reason)=>{
				});
		
			} else
				this.params.onFileAdd(this.params);
		}		
	}

	public openMenu(event, fileObj):void {
		this.curFileObj = fileObj;

		this.whitePopupService.showSelection(
			event.target, [{
				hideIf: (data:any)=>!this.params.onFilePreview,
				titleKey: 'profile.preview',
				click: (data:any)=>this.params.onFilePreview(this.params, this.curFileObj, this.params.jsonObj.files)
			},{
				hideIf: (data:any)=>!this.params.onFileReplace || !this.realEditable,
				titleKey: 'profile.replace',
				click: (data:any)=>this.params.onFileReplace(this.params, this.curFileObj)
			},{
				hideIf: (data:any)=>!this.params.onSoundRecord || !this.realEditable,
				titleKey: 'profile.soundRecord',
				click: (data:any)=>this.params.onSoundRecord(this.params, this.curFileObj)
			},{
				hideIf: (data:any)=>!this.params.onFileDownload,
				titleKey: 'profile.download',
				click: (data:any)=>this.params.onFileDownload(this.params, this.curFileObj)
			},{
				hideIf: (data:any)=>!this.params.onFileDelete || !this.realEditable,
				titleKey: 'profile.delete',
				click: (data:any)=>this.params.onFileDelete(this.params, this.curFileObj)
			}]
		).catch((reason)=>{
		});
	}
}
