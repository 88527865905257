import {
	Component,
	HostListener,
	NgZone,
	ViewChild,
	OnInit,
	Input,
	OnDestroy,
	AfterViewInit,
} from "@angular/core";
import { LoadingService } from "src/app/sharedModule/loadingModule/loading.service";
import { DataService } from "src/app/service/data.service";
import { ThemeService } from "src/app/service/theme.service";
import { Subject } from "rxjs";
import { takeUntil, throttleTime } from "rxjs/operators";
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { CommonService } from "src/app/service/common.service";
declare var Chart;

@Component({
	selector: "p-objective-radar-chart",
	template: `
		<div class="card" *ngIf="chartData && chartData.learningObjectives.length > 0">
			<div class="panel" [ngClass]="theme.theme$.value + '-theme'">
				<ng-container *ngIf="chartDatasets">
					<div class="item" *ngFor="let ds of chartDatasets;let i = index">
						<div
							class="item-box"
							[style.backgroundColor]="ds.backgroundColor"
							[style.borderColor]="ds.borderColor"
							[style.borderWidth]="ds.borderWidth + 'px'"
						>
						</div>
						<div class="item-label">{{ ds.label }}</div>
					</div>
				</ng-container>
				
			</div>
			<div class="body">
				<div class="chart-wrapper">
					<canvas #canvas></canvas>
				</div>
			</div>
		</div>

		<div class="no-data" *ngIf="chartData && chartData.learningObjectives.length == 0">
			<div class="no-data-image"></div>
			<div class="no-data-text">{{ {tc:'未有題目',sc:'未有题目',en:'No question'}[datas.lang] }}</div>
		</div>
	`,
	styleUrls: ["./personal-objective-radar.chart.scss"],
})
export class PersonalObjectiveRadarChart implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	public chartData: any;
	public chartDatasets: any;
	public chartLabels: any;
	public chartObj: any;
	public chartOptions: any;
	public yMax = 100;
	public stepSize = 1;
	public onDestroy = new Subject();
	@ViewChild("canvas", { static: false }) canvas;
	public faCheck = faCheck;
	public selectedStudent;
	public students:any = [];
	public currentGroupStudents:any = [];
	constructor(
		public lds: LoadingService,
		public datas: DataService,
		public theme: ThemeService,
		public coms: CommonService
	) {}

	ngOnInit() {
		this.chartData = this.parent.chartData;
		this.initStudents(this.chartData);
	}

	ngAfterViewInit(): void {
		this.coms.waitFor(()=> this.chartData.learningObjectives, 20, 300).then(()=>{
			this.initChartData();
			this.initChartOptions();
			this.renderChart();
		});
		this.parent.parent.filterChange.pipe(takeUntil(this.onDestroy), throttleTime(100)).subscribe(event=>{
			if (this.parent.parent.selectedTags.find(e=> e.bsid == this.selectedStudent.bsid) == null){
				this.selectedStudent = this.students.find(e=> e.optionClass == 'student');
			}
			this.initChartData();
			this.initChartOptions();
			this.chartObj.data.datasets = this.chartDatasets;
			this.chartObj.update();
		});
	}

	ngOnDestroy() {
		this.onDestroy.next();
		this.onDestroy.complete();
		if (this.chartObj){
			this.chartObj.destroy();
		}
	}

	initChartOptions() {
		const axisXLabel = { tc: "分數%", sc: "分数%", en: "Score%" }[
			this.datas.lang
		];
		const axisYLabel = { tc: "人數", sc: "人数", en: "Number of students" }[
			this.datas.lang
		];
		this.chartOptions = {
			maintainAspectRatio: false,
			interaction: {
				mode: "nearest",
				axis: 'x',
				intersect: true,
			},
			animation: {
				duration: 300,
			},
			scales: {
				r: {
					min: 0,
					max: 100,
					pointLabels:{
						padding: 40,
					},
					ticks:{
						display: false
					}
				},
			},
			responsive: true,
			layout: { padding: { left: 0, right: 0, top: -20, bottom: -60 } },
			plugins: {
				legend: {
					display: false,
					labels: {
						display: false,
					},
				},
			},
		};
	}

	initChartData() {
		let avgDataset:any = {
			"label": {tc: '平均值', sc: '平均值', en: 'Average'}[this.datas.lang],
			"backgroundColor": '#7ccffa66',
			"pointBorderColor": "#ffffff",
			"pointBackgroundColor": "#7ccffa",
			"borderColor": "#7ccffa",
			"pointBorderWidth": 1,
			"borderWidth": 1,
			"pointRadius": 7,
			data: []
		};
		let studentDataset:any = {
			"label": "學生A",
			"backgroundColor": "#FD658566",//#FD6585
			"pointBorderColor": "#ffffff",
			"pointBackgroundColor": "#FD6585",
			"borderColor": "#FD6585",
			"pointBorderWidth": 2,
			"borderWidth": 2,
			"pointRadius": 7,
			data: []
		}
		this.chartData.learningObjectives.forEach(lo => {
			const shareData = lo.shares.find(sh => sh.bsid == this.parent.modData.bsid);
			const avgScorePt = shareData.avgScorePt === null?0:shareData.avgScorePt;
			avgDataset.data.push(avgScorePt);
			const studentShare = lo.shares.find(sh=> sh.bsid == this.selectedStudent.bsid);
			const student = studentShare.peoples.find(e=> e.uid == this.selectedStudent.uid);
			studentDataset.data.push(student.percent);
			studentDataset.label = student[this.datas.lang == 'en'?'ename':'cname'];
		});
		this.chartDatasets = [ avgDataset, studentDataset ];
		
		if (this.yMax > 20) {
			this.stepSize = Math.floor(this.yMax / 10);
		}

		this.chartLabels = this.chartData.learningObjectives.map(e=> {
			return e.title;
		});
	}

	renderChart() {
		if (this.chartData.learningObjectives.length == 0){
			return;
		}
		if (this.chartObj) {
			this.chartObj.destroy();
		}
		this.coms.waitFor(()=> this.canvas && this.canvas.nativeElement, 20, 200).then(()=>{
			let canvasEle = this.canvas.nativeElement;
			let ctx = canvasEle.getContext("2d");
			this.lds.add("renderChart", 5000);
			setTimeout(() => {
				this.chartObj = new Chart(ctx, {
					type: "radar",
					data: {
						datasets: this.chartDatasets,
						labels: this.chartLabels,
					},
					options: this.chartOptions,
					plugins: [
						{
							afterRender: (chart, args, options)=> {
								this.afterRender(chart);
								this.lds.remove('renderChart');
							} 
						}
					]
				});
			}, 800);
		});
	}

	initStudents(chartData){
		this.students = [];
		chartData.shares.forEach(share=>{
			const groupObj = {uid: null, cname: share.tag_fulltitle, ename: share.tag_fulltitle, bsid: share.bsid, optionClass: 'tag' };
			this.students.push(groupObj);
			share.peoples.forEach(ppl => {
				const pplObj = {uid: ppl.uid, cname: ppl.cname, ename: ppl.ename, bsid: share.bsid, optionClass: 'student', classno: ppl.classno };
				this.students.push(pplObj);
				if (share.bsid == this.parent.modData.bsid){
					this.currentGroupStudents.push(pplObj);
				}
			});
		});
		
		this.selectedStudent = this.students.find(e=> e.uid == this.chartData.uid);
	}

	studentChange(){
		this.initChartData();
		this.chartObj.data.datasets = this.chartDatasets;
		this.chartObj.update();
	}

	afterRender(chart){
		this.coms.waitFor(()=> this.chartObj.scales.r._pointLabelItems, 20, 300).then((pointLabelItems) =>{
			const ctx = chart.ctx;
			ctx.font = "14px 'Noto Sans T Chinese' bold";
			const avgDataset = this.chartDatasets[0];
			const studentDataset = this.chartDatasets[1];
			pointLabelItems.forEach((item,index)=>{
				const avgScore = avgDataset.data[index];
				const studentScore = studentDataset.data[index] == null? '-':studentDataset.data[index];
				ctx.fillStyle = avgDataset.borderColor;
				ctx.textAlign = item.textAlign;
				ctx.fillText(avgScore, item.x, item.y < 400 ? item.y + 30: item.y - 20);
				ctx.fillStyle = studentDataset.borderColor;
				ctx.fillText(studentScore, item.x, item.y < 400 ? item.y + 40: item.y - 10);
				ctx.save();
			});
		});
	}

	get filteredStudents(){
		const filteredBsid = this.parent.parent.selectedTags.map(e=> e.bsid);
		let filtered = this.students.filter(e=> filteredBsid.indexOf(e.bsid) > -1);
		return filtered;
	}

}

