import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import {  ROComponent, ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";

@Component({
	template:
		`
		`,
		styles:[
			`
			
			`
		]

	
})
export class ROYoutubeSearch extends ROComponent
{
	iframe: HTMLIFrameElement;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	
	public getTagNames():string []
	{
		return ["YoutubeSearch"];
	}

	protected buildContent(): void {
		// <!--{"ver":1.3,"urlLink":"https://www.youtube.com/watch?v=JHregeIsjPQ","vidID":"JHregeIsjPQ","douid":"0CB94186-B986-313A-5B6C-47869D782B55","coordinateExpression":"UA UK KS X 44 Y 35 D T 35 L 44 H 209.80906143204624 W 419.6181228640925","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"locked":false}-->
		var url = "http://ro2.azurewebsites.net/RainbowOne/youtube/?videoID="+ this.node.attributes.vidID;
		this.iframe = document.createElement("iframe");
		this.iframe.src = url;
		this.iframe.style.width = "100%";
		this.iframe.style.height = "100%";
		
	}
	public activate(): void {
		var dom = this.elementRef.nativeElement;
		dom.appendChild(this.iframe);
	}
	public deactivate(): void {
		if(this.iframe.parentElement)
		{
			this.iframe.parentElement.removeChild(this.iframe);
		}
	}
}


@Component({
	template:
		`
		`,
		styles:[
			`
			
			`
		]

	
})
export class ROWebBrowser extends ROComponent
{
	iframe: HTMLIFrameElement;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	
	public getTagNames():string []
	{
		return ["WebBrowser"];
	}

	protected buildContent(): void {
		/*
			{
				"ver": 1.3,
				"urlLink": "https://www.youtube.com/watch?v=JHregeIsjPQ",
				"isURLSet": true,
				"addressBarVisible": true,
				"douid": "E66BA456-88B8-B498-CAB9-40C285F6E374",
				"coordinateExpression": "UA UK X 55 Y 334 D T 334 L 55 H 353.3156934306569 W 602.9927884615385",
				"hasScoring": true,
				"scoringType": 1,
				"scoreN": 1,
				"unitScore": 1,
				"fullScore": 1,
				"locked": false,
				"x": 55,
				"y": 334,
				"w": 602.9927884615385,
				"h": 353.3156934306569
			}
		*/
		var dom = this.elementRef.nativeElement;
		var url = this.node.attributes.urlLink;
		this.iframe = document.createElement("iframe");
		this.iframe.src = url;
		this.iframe.style.width = "100%";
		this.iframe.style.height = "100%";
	}
	public activate(): void {
		var dom = this.elementRef.nativeElement;
		dom.appendChild(this.iframe);
	}
	public deactivate(): void {
		if(this.iframe.parentElement)
		{
			this.iframe.parentElement.removeChild(this.iframe);
		}
	}
}


