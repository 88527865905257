import { Component, Input, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dateTimePicker3SelectMonth',
    template: `
<ng-container *ngIf="curView=='selectMonth'">
	<div class="rowYear">
		<fa-icon [icon]="faChevronLeft" class="switchYearBtn" (click)="calendarYear=calendarYear-1"></fa-icon>
		<div class="yearBtn" (click)="curView='selectYear'">{{calendarYear}}</div>
		<fa-icon [icon]="faChevronRight" class="switchYearBtn" (click)="calendarYear=calendarYear+1"></fa-icon>
	</div>

	<div class="rowMonth">
		<div
			class="month"
			*ngFor="let month of months; let i=index;"
			[class.selected]="date.getFullYear()==calendarYear && date.getMonth()==i"
			(click)="onChange.emit({year:calendarYear, month:i})"
		>{{month}}</div>
	</div>
</ng-container>

<dateTimePicker3SelectYear *ngIf="curView=='selectYear'" [date]="date" (onChange)="calendarYear=$event; curView='selectMonth';"></dateTimePicker3SelectYear>
    `,
    styleUrls: ['./dateTimePicker3SelectMonth.component.scss']
})

export class DateTimePicker3SelectMonthComponent implements OnChanges {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

    public faChevronLeft:IconDefinition = faChevronLeft;
    public faChevronRight:IconDefinition = faChevronRight;

    @Input() public date:Date = new Date();

    public months:any[];
    public calendarYear:number;

    @Output() public onChange:EventEmitter<any> = new EventEmitter();

	public curView:string = 'selectMonth';

    constructor(public translate:TranslateService) {
        if (translate.currentLang=='tc' || translate.currentLang=='sc') {
			this.months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
		}  else {
			this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		}
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.date && changes.date.currentValue) {
            this.calendarYear = changes.date.currentValue.getFullYear();
        }
    }
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
}
