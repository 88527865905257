import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { LoadingService } from '../loadingModule/loading.service';
import { TTSService } from 'src/app/service/TTS.service';
import { faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { faVolume, faTimer } from '@fortawesome/pro-solid-svg-icons';
import { ScriptService } from 'src/app/service/script.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';

@Component({
	selector: 'ai-oral-marking-details',
	template: `
	<div class="left">
		<div class="thread-container">
			<app-thread #thread *ngIf="ready" [markingStudent]="selectedStudent" [ngClass]="data.status" [practiceSetting]="practiceSetting" [data]="threadData" [isMarking]="true"></app-thread>
		</div>
	</div>
	
	<div class="right-bg {{theme.theme$.value}}-theme">
		<div class="right">
			<div class="right-ai-score-button"  (click)="aiCheckClick($event)" *ngIf="enableAIButton && !fromCourse">
			<svg-icon *ngIf="loadedSvg" name="ai_marking"></svg-icon>
			{{ {tc:'AI評分',sc: 'AI评分', en: 'AI Score'}[lang] }}
			</div>
			<bubbleBox2 #aiCheckPopup padding="0" backgroundColor="var(--wpopup-bgcolor)">
			<div class="aiCheckPopup">
				<div class="aiCheckPopup-row">
					<div class="aiCheckPopup-label">{{ {tc:'AI總評', sc:'AI总评', en: 'Comment'}[lang] }}</div>
					<ui-switch class="useCssVar" [checked]="enableComment" 
						(change)="enableComment = $event"></ui-switch>
					<okaPulldown2 [options]="commentOptions" 
						[okaPulldownStyle]="{width: '140px'}"
						bindLabel="label" bindValue="value" 
						[(ngModel)]="commentOption"></okaPulldown2>
				</div>
				<div class="aiCheckPopup-panel">
					<div class="aiCheckPopup-check"
					(click)="aiCheck.emit({enableComment:enableComment, commentOption:commentOption});aiCheckPopup.close();">{{ {tc:'開始評分',sc:'开始评分',en:'Check'}[lang] }}</div>
					<div class="aiCheckPopup-cancel" 
						(click)="aiCheckPopup.closeNow()">{{ {tc:'取消',sc:'取消',en:'Cancel'}[lang] }}</div>
				</div>
			</div>
		</bubbleBox2>
			<div class="right-score">
				<span>{{ {tc:'總分',sc: '总分', en: 'Score'}[lang] }}:</span>
				<span>{{ score === null?'--':score }}</span>
				<span>/{{ maxScore === null?'--':maxScore }}</span>
			</div>
			<div class="right-instruction" *ngIf="!isPersonal && !fromCourse">
				<span>{{ {tc:'請於老師欄的方格內評分',sc:'请于老师栏的方格内评分',en:'Please score the box in the teacher column'}[lang] }}:</span>
			</div>
			<div class="table">
				<div class="table-header">
					<div class="table-header-cell main {{theme.theme$.value}}-theme">{{ {tc:'準則',sc: '準則', en: 'Scale'}[lang] }}</div>
					<div class="table-header-cell {{theme.theme$.value}}-theme">{{ {tc:'滿分',sc: '总分', en: 'Max.'}[lang] }}</div>
					<div class="table-header-cell {{theme.theme$.value}}-theme">{{ {tc:'AI',sc: '总分', en: 'Score'}[lang] }}</div>
					<div class="table-header-cell {{theme.theme$.value}}-theme" *ngIf="!fromCourse">{{ {tc:'老師',sc: '老师', en: 'Teacher'}[lang] }}</div>
				</div>
				<div class="table-body">
					<div class="table-row" *ngFor="let row of rows">
						<div class="table-row-cell main {{theme.theme$.value}}-theme">
							<span>{{row.title}}</span>
						</div>
						<div class="table-row-cell score {{theme.theme$.value}}-theme">{{row.score}}</div>
						<div class="table-row-cell ai {{theme.theme$.value}}-theme">{{row.ai === null?'':row.ai}}</div>
						<div class="table-row-cell teacher {{theme.theme$.value}}-theme" *ngIf="!fromCourse"
							[class.unfilled]="row.teacher === null || row.teacher === undefined">
							<input type="number" [(ngModel)]="row.teacher"
								class="{{theme.theme$.value}}-theme"
								[max]="row.score" (change)="saveTeacherScore($event, row)" />
						</div>
					</div>
				</div>
			</div>
			<div class="right-separate {{theme.theme$.value}}-theme"></div>
			<div class="right-topic">
				<div class="right-topic-text">{{ {tc:'總評',sc:'总评',en:'Comment'}[lang] }}:</div>
				<div class="right-topic-audio" *ngIf="isPersonal" (click)="speak('comment')"></div>
			</div>
			<div class="right-overall" *ngIf="isPersonal">
				<span>{{commentOverall}}</span>
			</div>

			<ng-container *ngIf="!isPersonal && !fromCourse">
				<div class="right-ai">
					<div class="right-title">AI:</div>
					<div class="right-text">{{ commentAI }}</div>
				</div>
				<div class="right-teacher">
					<div class="right-title">{{ {tc:'老師', sc: '老師', en: 'Teacher'}[lang] }}:</div>
					<div class="right-text">
						<textarea [ngClass]="theme.theme$.value + '-theme'" 
							(change)="commentTeacherChange($event)" [(ngModel)]="commentTeacher"></textarea>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<bubbleBox2 #historyPopup [marginLeft]="30" position="right" (close)="history = null">
		<div class="record-wrapper" *ngIf="history">
			<div class="record" *ngFor="let index of [1,2,3];">
				<ng-container>
					<div class="record-label">{{ {tc:'紀錄',sc:'纪录',en:'Record '}[lang] + index }}:</div>
					<div class="record-body">{{ history['text'+ index] }}</div>
					<div class="record-right {{theme.theme$.value}}-theme">
						<div class="record-comment-topic">{{ {tc:'評語', sc: '评语', en:'Comment'}[lang] }}</div>
						<div class="record-comment-body">
							<div class="record-voice" (click)="speak('history', history.pi + ':' + index)">
								<fa-icon [icon]="faVolume"></fa-icon>
							</div>
							<div class="record-comment-body-text">{{ history['feedback'+index] }}</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</bubbleBox2>
	
	`,
	styleUrls: ['./aiOralMarkingDetails.scss'],
})

export class AIOralMarkingDetails implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	@Input() enableAIButton:boolean = true;
	@Input() selectedStudent: any;
	@ViewChild('historyPopup', {static: false}) historyPopup: BubbleBox2Component;
	@ViewChild('aiCheckPopup', {static: false}) aiCheckPopup: BubbleBox2Component;
	@Output() aiCheck: EventEmitter<any> = new EventEmitter();

	public photo;
	public info:any;
	public lang;
	public score:number = null;
	public maxScore:number = null;
	public rows:any[] = [];
	public commentOverall;
	public commentAI;
	public commentTeacher = '';
	public faMagnifyingGlass = faMagnifyingGlass;
	public faVolume = faVolume;
	public faTimer = faTimer;
	public aiGuideOptions:any[] = [];
	public commentOptions:any[] = [];
	public commentOption;
	public enableComment = true;
	public aiGuide = 1;
	public paragraphs:any = [];
	public displayGuide;
	public aiSuggestionOptions:any[] = [];
	public history:any = null;
	private isStudentSubmitted = 0;
	public isPersonal = false;
	public entryType;
	public practiceSetting;
	public threadData;
	public loadedSvg: boolean = false;
	public ready: boolean = false;
	public teachingCommentChanged: boolean = false;
	public fromCourse: boolean = false;
	constructor(private trans: TranslateService, public datas: DataService, private iconReg: InlineSvgService, private als: AlertService, public eleRef: ElementRef, public theme: ThemeService, private common: CommonService, private lds: LoadingService, public tts: TTSService, public zone: NgZone, public sans: DomSanitizer, public script: ScriptService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.isPersonal = !!this.data.isPersonal;
		this.fromCourse = location.href.indexOf('/course/') > -1;
		this.entryType = this.data.share.entry_type;
		this.initOptions();
		this.loadStudent();
		this.iconReg.regSvgFiles([
			{url: 'icon_aimarking.svg', name: 'ai_marking'},

		]).then((res:any)=>{
			this.loadedSvg = true;
		});
	}

	ngAfterViewInit() {
	
	}

	ngOnDestroy(): void {
	}

	ngOnChanges(changes){
		this.isStudentSubmitted = 0;
		this.getPracticeSetting();
		this.getScore();
	}

	private async getScore(){
		this.commentAI = {tc:'未進行AI批改。',sc:'未进行AI批改。',en:'Not yet marking by AI.'}[this.lang];
		this.rows.forEach(row=>{
			row.ai = null;
			row.teacher = null;
		});
		let res = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_oral_scores', json: [this.data.share.book_id, this.data.share.bsid, this.data.selectedUid] } });
		if(res){
			try {
				if(res.ai_score){
					let aiScore = JSON.parse(res.ai_score.replace(/'/g, '"').replace(/\s/g, ''));
					this.rows.forEach((row, index) => {
						const title = row.title;
						if (aiScore[title]) {
							row.ai = aiScore[title];
						}
					});
					this.commentAI = res.ai_comment;
				}
			} catch (error) {
			console.log("error", error);
			console.log("aiscore", res.ai_score);
			}
		}
	}

	aiCheckClick($event){
		const el = $event.target;
		this.aiCheckPopup.open(el, this.eleRef.nativeElement).catch(e=>e);
	}

	initOptions(){
		const lang = this.lang;
		this.commentOptions = [
			{label: {tc:'大約10字以內', sc:'大約10字以內', en: 'Around 10 words'}[lang], value: 10},
			{label: {tc:'大約30字以內', sc:'大約30字以內', en: 'Around 30 words'}[lang], value: 30},
			{label: {tc:'大約50字以內', sc:'大約50字以內', en: 'Around 50 words'}[lang], value: 50}
		];
		this.commentOption = this.commentOptions[0].value;
		if (this.data.info){
			const info = this.data.info;
			this.info = info;
			if (info.mediaUrl){
				this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/" + this.info.mediaUrl +"')");
			}
			const noUsedGuideText = {tc:'「未有使用提示」',sc:'「未有使用提示」', en:'"Not yet used."'}[lang];
			const noUsedFeedbackText = {tc:'「未有使用評語」',sc:'「未有使用评语」', en:'"Not yet used."'}[lang];
			if (info.paragraph > 0){
				for(let i = 0;i < info.paragraph; i++){
					let paragraph:any = {text: '', selectedSuggestion: '1', used: 0, comment: noUsedFeedbackText};
					this.paragraphs.push(paragraph);
				}
			} else {
				this.paragraphs = [{text:'', selectedSuggestion: '1', used: 0, comment: noUsedFeedbackText}];
			}
			if (info.prewritingAIGuideEnabled){
				this.aiGuideOptions = [];
				for(let i = 1; i <= info.prewritingAIGuideTimes; i++){
					let item:any = {label: '' + i, text: noUsedGuideText};
					this.aiGuideOptions.push(item);
				}
				this.aiGuide = this.aiGuideOptions[0].value;
			}
			if (info.prewritingAISuggestionEnabled){
				this.aiSuggestionOptions = [];
				for(let i = 1; i <= info.prewritingAISuggestionTimes; i++){
					let item:any = {label: '' + i, value: i };
					this.aiSuggestionOptions.push(item);
					this.paragraphs.forEach(e=>{
						e['suggestion'+i] = noUsedGuideText;
					});
				}
			}
			if (info.prewritingAIFeedbackEnabled){
				for(let i = 1; i <= info.prewritingAIFeedbackTimes; i++){
					this.paragraphs.forEach(e=>{
						e['text'+i] = '';
						e['feedback'+i] = noUsedFeedbackText;
					});
				}
			}
			this.rows = [];
			if (info.scoringItems){
				this.maxScore = 0;
				this.rows = info.scoringItems.map(item=>{
					let obj = {title: item.title, score: item.score, ai: null, teacher: null };
					this.maxScore += parseFloat(item.score);
					return obj;
				});
			}
			this.commentAI = {tc:'未進行AI批改。',sc:'未进行AI批改。',en:'Not yet marking by AI.'}[lang];
		}
	}

	speak(type, data:any = ''){
		let text = '';
		if (type == 'topic'){
			text = {tc: '標題', sc:'标题', en:'Topic'}[this.lang] + ':' + this.info.name + ';' + this.info.description;
		} else if (type == 'comment'){
			text = this.paragraphs[+data].comment;
		} else if (type == 'suggestion'){
			text = this.paragraphs[+data].selectedSuggestionText;
		} else if (type == 'history'){
			const arr = data.split(':');
			text = this.paragraphs[+arr[0]]['suggestion'+ arr[1]];
		}
		if (text == '' || text == null){
			const msg = {tc:'沒有內容，無法朗讀', sc: '沒有內容，無法朗讀', en: 'Blank.'}[this.lang];
			this.als.toastError(msg);
			return;
		}
		this.tts.play(text).catch(e=> e);
	}

	previewMedia(){
		const previewComp = this.data.previewComp;
		const url = '//oka.blob.core.windows.net/media/' + this.info.mediaUrl;
		const fileObj:any = { name:"", url: url, ext:"png" };
        previewComp.setFile(fileObj);
        previewComp.open();
	}

	historyClick($event, index){
		const el = $event.target;
		this.history = {...this.paragraphs[index]};
		this.history.pi = index;
		this.historyPopup.open(el, this.eleRef.nativeElement).catch(e=> e);
	}

	selectedSuggestionChange(value, index){
		this.paragraphs[index].selectedSuggestionText = this.paragraphs[index]['suggestion' + value]; 
	}

	aiGuideChange($event){
		const found = this.aiGuideOptions.find(e=> e.value == $event);
		this.displayGuide = found.text;
	}

	saveTeacherScore($event, row){
		if (!this.isStudentSubmitted){
			$event.stopPropagation();
			$event.preventDefault();
			row.teacher = null;
			this.als.toastError({tc:'學生尚未提交。', sc:'学生尚未提交。', en:'Student not submitted.'}[this.lang]);
			return;
		}
		if (isNaN(row.teacher)){
			row.teacher = null;
			return;
		}
		if (row.teacher > row.score){
			row.teacher = row.score;
		}
		let score = 0;
		let dirty = false;
		this.rows.forEach(row=>{
			if (row.teacher !== null){
				score += parseFloat(row.teacher);
				dirty = true;
			} else if (row.ai !== null){
				score += parseFloat(row.ai);
				dirty = true;
			}
		});
		this.score = dirty?score:null;
		const student = this.data.students.find(e=> e.uid == this.data.selectedUid);
		student.result.teacher_score = this.rows.map(e=> e.teacher);
		student.result.teacher_comment = null;
		console.log("student", student);
	}

	loadStudent(){
		const uid = this.data.selectedUid;
		const lang = this.lang;
		this.clearStudentData();
		const student = this.data.students.find(e=> e.uid == uid);
		this.isStudentSubmitted = student.submitted;
		if (student && student.result && student.result.answer  && student.result.answer.paragraphs){
			student.result.answer.paragraphs.forEach((text, index)=>{
				this.paragraphs[index].text = text;
			});
		}
		if (student && student.aiAsks){
			student.aiAsks.forEach(ask=>{
				const content = JSON.parse(ask.content);
				const paragraphIndex = content.paragraph?content.paragraph:null;
				let index = +ask.index + 1;
				if (ask.query_type == 'guide'){
					index = index < this.aiGuideOptions.length?index:this.aiGuideOptions.length - 1;
					this.aiGuideOptions[index].text = ask.response;
				} else if (ask.query_type == 'suggestion' && paragraphIndex){
					this.paragraphs[paragraphIndex - 1]['suggestion' + index] = ask.response;
				} else if (ask.query_type == 'feedback' && paragraphIndex){
					this.paragraphs[paragraphIndex - 1]['text' + index] = content.answer?content.answer:'';
					this.paragraphs[paragraphIndex - 1]['feedback' + index] = ask.response;
					this.paragraphs[paragraphIndex - 1].used = Math.max(index, this.paragraphs[paragraphIndex - 1].used);
				}
			});
			// this.displayGuide = this.aiGuideOptions[0].text;

			const noUsedFeedbackText = {tc:'「未有使用評語」',sc:'「未有使用评语」', en:'"Not yet used."'}[this.lang];
			this.paragraphs.forEach(paragraph=>{
				paragraph.selectedSuggestionText = paragraph.suggestion1;
				paragraph.comment = noUsedFeedbackText;
				for(let i = 5;i > 0;i--){
					if (paragraph['feedback'+i] && paragraph['feedback'+i] != noUsedFeedbackText){
						paragraph.comment = paragraph['feedback'+i];
						break;
					}
				}
			});	
		}
		if (student && student.result){
			if (this.isPersonal){
				this.commentOverall = student.result.teacher_comment?student.result.teacher_comment:student.result.ai_comment;
			} else {
				this.commentTeacher = student.result.teacher_comment?student.result.teacher_comment:'';
				this.commentAI = student.result.ai_comment?student.result.ai_comment:{tc:'未進行AI批改。',sc:'未进行AI批改。',en:'Not yet marking by AI.'}[lang];
			}
			let score = 0;
			this.rows.forEach((row,index)=>{
				if (student.result.ai_score && student.result.ai_score.length > index){
					row.ai = +student.result.ai_score[index];
				}
				if (student.result.teacher_score && student.result.teacher_score.length > index){
					const teacherScore = student.result.teacher_score[index] === null?null:+student.result.teacher_score[index];
					row.teacher = teacherScore;
				}
				if(row.teacher !== undefined && row.teacher !== null){
					row.overall = row.teacher
				}else if(row.ai !== undefined && row.ai !== null){
					row.overall = row.ai
				}else{
					row.overall = null;
				}
				score += row.overall || 0;
			});
			this.score = score;
		}

	}

	clearStudentData(){
		const lang = this.lang;
		// const noUsedGuideText = {tc:'「未有使用提示」',sc:'「未有使用提示」', en:'"Not yet used."'}[this.lang];
		// const noUsedFeedbackText = {tc:'「未有使用評語」',sc:'「未有使用评语」', en:'"Not yet used."'}[this.lang];
		// this.paragraphs.forEach(paragraph=>{
		// 	for(let i = 1; i < 5; i++){
		// 		paragraph['text' + i] = '';
		// 		paragraph['suggestion' + i] = noUsedGuideText;
		// 		paragraph['feedback' + i] = noUsedFeedbackText;
		// 	}
		// 	paragraph.text = '';
		// 	paragraph.selectedSuggestion = '1';
		// 	paragraph.selectedSuggestionText = paragraph.suggestion1;
		// 	paragraph.comment = noUsedFeedbackText;
		// 	paragraph.used = 0;
		// });
	
		if (this.isPersonal){
			this.commentOverall = '';
		} else {
			this.commentAI = {tc:'未進行AI批改。',sc:'未进行AI批改。',en:'Not yet marking by AI.'}[lang];
			this.commentTeacher = '';
		}
		// this.aiGuideOptions.forEach(option=>{
		// 	option.text = noUsedGuideText;
		// });
		// this.displayGuide = noUsedGuideText;
		this.rows.forEach(row => {
			row.teacher = null;
			row.ai = null;
		});
		this.score = null;
	}

	commentTeacherChange($event){
		if (!this.isStudentSubmitted){
			$event.stopPropagation();
			$event.preventDefault();
			this.commentTeacher = null;
			this.als.toastError({tc:'學生尚未提交。', sc:'学生尚未提交。', en:'Student not submitted.'}[this.lang]);
			return;
		}
		const student = this.data.students.find(e=> e.uid == this.data.selectedUid);
		student.result.teacher_comment = this.commentTeacher;
		this.teachingCommentChanged = true;
	}

	private async getPracticeSetting(){
		this.practiceSetting = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_ai_oral_practice_setting', json: [this.data.share.book_id] } });
		this.getOralMessagesHistory(this.practiceSetting.id);
	}

	private async getOralMessagesHistory(threadId: number){
		this.threadData = {
			"status": "opened",
			"thread": {
				"id": "31",
				"created_at": "2021-02-22 13:56:24",
				"updated_at": "2021-07-08 13:31:06",
				"deleted": "0",
				"title": "init title",
				"image": {},
				"uid": "41344",
				"content": "test",
				"allow_image": "1",
				"allow_voice": "1",
				"post_time": "1625722267000",
				"cname": "cname",
				"ename": "ename",
				"tag_id": "18862",
				"tag_ids": "18862",
				"tag_name": "1A",
				"author": " 1A",
				"unread": 0
			},
			"messages": [
			
			],
			"peoples": [
				{
					"uid": "42",
					"user_role": "2",
					"gender": "2",
					"cname": "黃蘇菲",
					"ename": "wong sophie",
					"color": "#128487",
					"photo": {
						"changingThisBreaksApplicationSecurity": "url('//oka.blob.core.windows.net/media/profile_pic/2016/05/04_144835_EB692D20'),url('//api.openknowledge.hk/RainbowOne/webapp/2.8/roWeb/assets/img/news/avatar_student_female.svg')"
					}
				},
			],
			"quote": {}
		};
		var studentId: number = this.data.selectedUid; 
		const messages = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_ai_oral_message', json: [threadId, studentId] } });
		if(messages){
			this.threadData.messages = messages;
			if(messages.length >= 2){
				let student = this.data.students.find(e=> e.uid == this.data.selectedUid);
				this.isStudentSubmitted = student.submitted = 1;
			}
		}
		this.threadData.thread.id = threadId;
		this.ready = true;

	}

}
