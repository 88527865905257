import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { DataService } from 'src/app/service/data.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingService } from 'src/app/service/setting.service';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { ThemeService } from 'src/app/service/theme.service';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';

@Component({
	selector: 'preset-button4',
	template: `
  <a class="preset-button4" (click)="buttonClick($event)">
    <span>{{('workspace.share-type-' + preset.share_type)|translate}}</span>
    <div class="triangle"></div>
  </a>
  <div class="dropdown" *ngIf="isOn">
    <div class="dropdown__left">
      <a *ngFor="let item of tabs" [ngClass]="['tab', preset.share_type == item.share_type?'active':'']" (click)="tabClick($event, item.share_type)">
        <span>{{('workspace.share-type-' + item.share_type) |translate}}</span>
      </a>
    </div>
    <div class="dropdown__right">
      <div class="row">
        <div class="topic">{{'workspace.settings'|translate}}:</div>
      </div>
      <ng-container *ngFor="let tabItem of tabs">
        <ng-container *ngIf="preset.share_type == tabItem.share_type">
          <ng-container *ngIf="preset.share_type != 'teacher'">
			<div [ngClass]="['row']" *ngFor="let field of ['pass_level','time_limit']">
				<div class="row__label">{{('workspace.text-' + field) |translate}}:</div>
				<okaPulldown2 [okaPulldownStyle]="{width: '130px'}" [options]="items[field]" [bindLabel]="'title'" [(ngModel)]="tabItem[field]"></okaPulldown2>
			</div>
			<div class="row prize_type">
				<div class="row__label">{{('workspace.text-prize_type') |translate}}:</div>
				<okaPulldown2 class="prize_type" [okaPulldownStyle]="{width: '130px', marginRight: '10px'}" 
					[options]="items['prize_type']" bindLabel="title" [(ngModel)]="tabItem['prize_type']"></okaPulldown2>
				<okaPulldown2 [okaPulldownStyle]="{width: '130px'}" *ngIf="tabItem['prize_type'] == 'star'" 
					[options]="items['star']" bindLabel="title" [(ngModel)]="tabItem.star"></okaPulldown2>
				<okaPulldown2 [okaPulldownStyle]="{width: '130px'}" *ngIf="tabItem['prize_type'] == 'ruby'" 
					[options]="items['ruby']" bindLabel="title" [(ngModel)]="tabItem.ruby"></okaPulldown2>
				<okaPulldown2 [okaPulldownStyle]="{width: '130px'}" *ngIf="tabItem['prize_type'] == 'emberald'" 
					[options]="items['emberald']" bindLabel="title" [(ngModel)]="tabItem.emberald"></okaPulldown2>
				<okaPulldown2 [okaPulldownStyle]="{width: '130px'}" *ngIf="tabItem['prize_type'] == 'crystal'" 
					[options]="items['crystal']" bindLabel="title" [(ngModel)]="tabItem.crystal"></okaPulldown2>
			</div>
			<div [ngClass]="['row']">
              <div class="row__label">{{'workspace.text-max_submission_count' |translate}}:</div>
			  <okaPulldown2 [okaPulldownStyle]="{width: '130px'}" [options]="items['max_submission_count']" [bindLabel]="'title'" [(ngModel)]="tabItem['max_submission_count']"></okaPulldown2>
            </div>
		  </ng-container>
          <div [ngClass]="['row']" *ngFor="let field of selection">
            <div *ngIf="field !== null" class="row__label row-switch">{{('workspace.text-' + field) |translate}}:</div>
            <ui-switch *ngIf="field !== null" class="useCssVar" [checked]="tabItem[field] == 1" (change)="switchChange($event, tabItem.share_type, field)"></ui-switch>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="triangle"></div>
  </div>
  <div class="layer" *ngIf="isOn" (click)="layerClick($event)">

  </div>
  `,
	styleUrls: ['./preset-button4.component.scss']
})

export class PresetButton4Component implements OnInit {
	@Input() public preset: any = {};
	public tabs = [
		{ share_type: 'normal', pass_level: '50', time_limit: 'null', star: 0, max_submission_count: 'null', live_verify: 1, show_default_answer: 1, auto_submit: 1, ruby: 0, emberald: 0, crystal: 0, prize_type: 'star' },
		{ share_type: 'homework', pass_level: '50', time_limit: 'null', star: 0, max_submission_count: 'null', live_verify: 0, show_default_answer: 0, auto_submit: 1, ruby: 0, emberald: 0, crystal: 0, prize_type: 'star' },
		{ share_type: 'test', pass_level: '50', time_limit: 'null', star: 0, max_submission_count: 'null', live_verify: 0, show_default_answer: 0, auto_submit: 1, ruby: 0, emberald: 0, crystal: 0, prize_type: 'star' },
		{ share_type: 'exam', pass_level: '50', time_limit: 'null', star: 0, max_submission_count: 'null', live_verify: 0, show_default_answer: 0, auto_submit: 1, ruby: 0, emberald: 0, crystal: 0, prize_type: 'star' }
	];
	public isOn: boolean = false;
	public items: any;
	public trueFalseItems = [0, 1];
	public faTimes = faTimes;
	public faCaretDown = faCaretDown;
	public svgLoaded = false;
	public selection = ['auto_submit','live_verify', this.preset.hideDefaultAnswer ? 'show_default_answer' : null]
	constructor(public datas: DataService, public elRef: ElementRef, private trans: TranslateService, public setting: SettingService, public theme: ThemeService, public svgReg: InlineSvgService) {

	}

	ngOnInit() {
		this.elRef.nativeElement.style.position = 'relative';
		const t = this.trans;
		const p = 'workspace.';
		this.items = { pass_level: [{ title: t.instant(p + 'none'), value: 'null' }, { title: t.instant(p + 'pass-level-all-question'), value: 'all-question' }, { title: '100%', value: '100' }], time_limit: [{ title: t.instant(p + 'none'), value: 'null' }], max_submission_count: [{ title: t.instant(p + 'no-limit'), value: 'null' }], star: [{ title: t.instant(p + 'none'), value: 0 }, { title: t.instant(p + 'star-per-question'), value: 'each' }] };
		this.initItems();
		if (this.preset.share_type == 'teacher'){
			this.tabs = [{ share_type: 'teacher', pass_level: '50', time_limit:'null', star: 0, max_submission_count: 'null', live_verify: 1, show_default_answer: 1, auto_submit: 1, ruby: 0, emberald: 0, crystal: 0, prize_type: 'star'}];
		}
		this.svgReg.regSvgFiles([
			{url: 'news/icon_star_report.svg', name: 'icon_star_report'},
			{url: 'redeem/icon_gem_ruby (1).svg', name: 'icon_gem_ruby (1).svg'},
			{url: 'redeem/icon_gem_emberald (1).svg', name: 'icon_gem_emberald (1).svg'},
			{url: 'redeem/icon_gem_crystal.svg', name: 'icon_gem_crystal.svg'},
		]).then(()=>{
			this.svgLoaded = true;
		});
		if (this.setting.schoolSettings.PASSING_RATE){
			this.tabs.forEach(tab=> tab.pass_level = this.setting.schoolSettings.PASSING_RATE);
		}
		this.loadPreset(this.preset);
	}

	initItems() {
		const lang = this.datas.lang;
		for (let i = 90; i > 9; i -= 10) {
			this.items['pass_level'].push({ title: '>=' + i + '%', value: '' + i });
		}
		for (let i = 1; i < 4; i++) {
			const unit = { tc: '分鐘', sc: '分鈡', en: ' mins' };
			this.items['time_limit'].push({ title: i + unit[lang], value: i * 60 });
		}
		for (let i = 5; i < 181; i += 5) {
			const unit = { tc: '分鐘', sc: '分鈡', en: ' mins' };
			this.items['time_limit'].push({ title: i + unit[lang], value: i * 60 });
		}
		for (let i = 1; i < 51; i++) {
			const unit = { tc: '粒', sc: '粒', en: ' stars' };
			this.items['star'].push({ title: '' + i + unit[lang], value: i });
		}
		this.items['ruby'] = [...this.items['star']];
		this.items['emberald'] = [...this.items['star']];
		this.items['crystal'] = [...this.items['star']];

		for (let i = 1; i < 11; i++) {
			const title = this.datas.lang == 'en' ? i + ' times' : i + '次';
			this.items['max_submission_count'].push({ title: title, value: i });
		}
		this.items['prize_type'] = [
			{ title: {tc:'星星',sc: '星星', en:'Star'}[lang], value: 'star', optionClass: 'opt-star' },
			{ title: {tc:'紅寶石',sc: '紅寶石', en:'Ruby'}[lang], value: 'ruby', optionClass: 'opt-ruby'},
			{ title: {tc:'綠寶石',sc: '綠寶石', en:'Emberald'}[lang], value: 'emberald', optionClass: 'opt-emberald' },
			{ title: {tc:'水晶',sc: '水晶', en:'Crystal'}[lang], value: 'crystal', optionClass: 'opt-crystal' }];
	}

	loadPreset(preset) {
		let found: any = this.tabs.find(t => t.share_type == this.preset.share_type);
		if (found) {
			for (let prop in found) {
				if (prop == 'auto_submit' || prop == 'live_verify' || prop == 'show_default_answer') {
					found[prop] = this.preset[prop] == '1' ? 1 : 0;
				} else if (this.preset[prop] == null && prop == 'prize_type'){
					this.preset.prize_type = 'star';
				} else if (this.preset[prop] == null && ['ruby','emberald','crystal'].indexOf(prop) > -1){
					found[prop] = 0;
				} else if (this.preset[prop] == null || (prop == 'time_limit' && (this.preset['time_limit'] == "0" || this.preset['time_limit'] == "null"))) {
					found[prop] = 'null';
				} else if (prop == 'star' || prop == 'time_limit' || prop == 'max_submission_count') {
					found[prop] = isNaN(this.preset[prop])?this.preset[prop]:parseInt(this.preset[prop]);
				} else {
					found[prop] = this.preset[prop];
				}
			}
		}
	}

	buttonClick($event) {
		$event.stopPropagation();
		if (this.isOn) {
			this.savePreset();
		} else {
			this.setOnOff(true);
		}
	}

	tabClick($event, item) {
		$event.stopPropagation();
		this.preset.share_type = item;
	}

	layerClick($event) {
		$event.stopPropagation();
		this.savePreset();
	}

	switchChange($event, share_type, field) {
		let tab = this.tabs.find(t => t.share_type == share_type);
		tab[field] = $event ? 1 : 0;
	}

	savePreset() {
		let selectedTab = this.tabs.find(t => t.share_type == this.preset.share_type);
		if (selectedTab){
			for (let prop in selectedTab) {
				const value = selectedTab[prop] == 'null' ? null : selectedTab[prop];
				this.preset[prop] = value;
			}
			['star','ruby','emberald','crystal'].forEach(prop=> {
				if (selectedTab.prize_type != prop){
					selectedTab[prop] = 0;
				}
			});
		}
		this.setOnOff(false);
	}

	uploadClick(item) {

	}

	clearClick(item) {

	}

	setOnOff(state) {
		this.isOn = state;
	}

}
