import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { QBComponentBase } from "./QBComponentBase";
import { FileIOService } from "src/app/service/FileIO.service";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";


@Component({
	template:`

	
		<div class="q scroll-container">
			<div class="qContainer ">
				
				<div class="qnumCol flex-row-left">
					<div class="flex-column-center">
						<div class="qnum">
							{{qObject.questionIndex+1}}
						</div>
					
						<!-- speaker tts icon -->
						<!--
						<div  *ngIf="!qObject.isPageComponent" class="speaker" (click)="speechQ(qObject.question)"></div>
						-->
					</div>


					<div class="qcontent">
						<div class="qtxt firstLine">
							<ng-container *ngFor="let q of items">
								
								<a 
									*ngIf="q.type=='url'" 
									target="_blank" [href]="q.str" [innerHTML]="q.str"></a>
								<span 
									*ngIf="q.type=='text' || q.type=='splitter'" 
									[innerHTML]="q.str"
									></span>
								
								<span *ngIf="q.type=='option'" >{{q.str|json}}</span>
								<!-- 
								[ngClass]="['optStyle1', answerIsSelected(q.grp,q.id) ? 'selected' : '', isMCAnswer(q.grp,q.id) ? 'answer' : '', showVerify(i)  ? 'verify' : '']"
								[class.cIcon]="itm.var.showVerify && isMCAnswer(q.grp,q.id)"
								[class.wIcon]="itm.var.showVerify && !isMCAnswer(q.grp,q.id)"
								(click)="answerClick(q.grp,q.id)" 
								-->
								<span 
									*ngIf="q.type=='input'" 
									[ngClass]="['FIB']">
									<span *ngFor="let ans of q.answer" [innerHTML]="ans"></span>
									<input type="text" 
										[disabled]="!canEdit()"
										autocomplete="off" 
										(focusout)='onFocusOut()' [(ngModel)]="currentState.inputs[q.grp]"/>
										<!-- [(ngModel)]="currentState.inputs[q.grp]"  -->
									<!-- 
									<div *ngIf="showVerify(i) && isGrpCorrect(q.grp)" class="correctIcon"></div>
									<div *ngIf="showVerify(i) && !isGrpCorrect(q.grp)" class="wrongIcon"></div>
									-->
								</span>
								
							</ng-container>
						</div>
					</div>
				</div>
			</div>

			<div class="score">
						
				<!--
					<Scoring [displayType]="'ai'" 
					(onChange)="updateScore($event)" 
					*ngIf="isSubmitted() && qObject.type!='QBInfoBlock' && isDisplayAIScore()"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
					<Scoring (onChange)="updateScore($event)" *ngIf="isSubmitted(i) && qObject.type!='QBInfoBlock' && isDisplayTeacherScore(i)"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
				-->
			</div>

		</div>






		
	`,
	styleUrls:[
		"./QBComponentQuestionBase.scss",
		"./QBFillingBlank.scss"
	]
})
export class QBFillingBlank extends QBComponentQuestionBase
{
	public inEdit:boolean = false;
	public inEditMode:boolean=false;
	public items:any [];
	public currentState:any = {input:["", "", "", ""]};
	
	public tmpQText:any;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, public fileio:FileIOService)
	{
		super(cdr, elementRef, fileio);
	}

	public canVerify(): boolean {
		return true;
	}

	canEdit()
	{
		return true;
	}
	onFocusOut()
	{

	}
	public isGrpCorrect()
	{
		return true;
	}
	
	public getTagNames():string []
	{
		return ["QBFillingBlank"];
	}
	
	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;//  "1 + [    B    ] = 9, a.1, b.8, c.10"
		this.items = this.createQ();
	}


	protected createQ()
	{
		if(this.tmpQText)
			return this.tmpQText;
		
		let text:string = this.qObject.question.replaceAll("\n","<br/>");
		if(this.qObject.type == "QBToggleOptions" || this.qObject.type == "QBFillingBlank") {
			// 文字切換題
			let parts:any = [];
			let pos:number = 0;
			let re = /(\[[^\]]+\])/ig;
			let myArray;
			let grp:number = 0;
			while ((myArray = re.exec(text)) !== null) {
				// 前面有普通字串
				if(pos < myArray.index)
					parts.push({type:"text", str:text.substring(pos, myArray.index)});
				// option
				let opts = text.substring(myArray.index+1, re.lastIndex-1).split("/");
				if(this.qObject.type == "QBToggleOptions") {
					opts.forEach((opt:string,index:number) => {
						if(opt.substring(0,1)=="#")
							parts.push({type:"option", grp:grp, id:index, answer:true, str:opt.substr(1)});
						else
							parts.push({type:"option", grp:grp, id:index, answer:false, str:opt});
						if(index+1<opts.length)
							parts.push({type:"text", str:"/"});
					});

				} else {
					// opts = arraw of string;
					// 
					parts.push({type:"input", grp:grp, answer:opts, studentAnswer:""});
				}
				grp++;
				pos = re.lastIndex;
			}
			if(pos < text.length)
				parts.push({type:"text", str:text.substring(pos, text.length)});

			this.tmpQText = parts;
		} else {
			this.tmpQText = [{type:"text", str:text}];
		}

		return this.tmpQText;
	}
	set data(input:string)
	{
		// console.log("QBFillingBlank.data = ", input);
		var blanks = this.items.filter((item:any)=>{
			return item.type=="input";
		});
		var itemCount = blanks.length;
		var inputs = new Array(itemCount);
		inputs.fill("");
		if(input)
		{
			try{
				var array = JSON.parse(input);
				array.forEach((element, index) => {
					inputs[index] = element;
				});
				this.currentState = {inputs:inputs};
			} catch(err)
			{
				console.error(err);
			}
		} else {
			this.currentState = {inputs:inputs};
		}
	}
	get data()
	{
		if(this.hasAnswer()) return JSON.stringify(this.currentState.inputs);
		return null;
	}

	public verify(show:boolean):any
	{
		var o = this.getResultObjectAndAnswer();
		this.resultObject = o.result;
		return o.result;
	}

	
	protected getResultObjectAndAnswer():any {
		let qObj:any = this.qObject;
		let qVar:any = this.currentState;
		
		let totalAns:number = 1;
		let correct:number = 0;
		let wrong:number = 0;
		let answerForSubmit:any;

	
		totalAns = 0;
		this.items.forEach(e=> {
			if(e.type=="input")
				totalAns = Math.max(e.grp+1, totalAns);
		});
		answerForSubmit = JSON.stringify(qVar.inputs);
		qVar.inputs.forEach((e,inputIndex) => {
			if(e!="") {
				let grpOpt = this.items.find(e2 => e2.type=="input" && e2.grp==inputIndex);
				if(grpOpt && this.strAnswerCompare(grpOpt.answer, e))
					correct++;
				else
					wrong++; // 填錯
			}
		});

//		if(correct==0 && wrong==0)
//			return null; // 無答過
		return {
			answerForSubmit:answerForSubmit,
			correct:correct,
			wrong:wrong,
			totalAns:totalAns,
			result:this.getResultObject(qObj, correct, wrong, totalAns)
		};
	}

	protected strAnswerCompare(answers:string[], yourAns:string):boolean {
		yourAns = yourAns.toLocaleLowerCase().trim();
		answers = answers.map((ans)=>{
			return ans.replace(/^#/, "");
		});
		for(var i:number=0; i<answers.length; i++) {
			var ans:string = (answers[i] as any).replaceAll("&nbsp;","").toLocaleLowerCase().trim();
			if(ans == yourAns)
				return true;
		}
		return false;
	}

}