import { ChangeDetectorRef, Component, ElementRef, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";

@Component({
	template:`
		<div class="checkbox-container" [ngClass]="[borderType]">
			<div
				(tap)="toggle(i)"
				*ngFor="let checkbox of checkboxes; let i = index" class="box"
				[class.selected]="defaultAnswer ? defaultAnswer.answer == i : i==selectedIndex">
			</div>
		</div>
		<div [ngClass]="['RW', correctState]"></div>
	`,
	styles:[
		`
		.box{
			width:var(--checkbox-size);
			height:var(--checkbox-size);
			display:block;
			background-size: contain;
			border: solid 2px #330000;
			background-color:#fff;
		}
		.round .box {
			border-radius: 5px;
		}

		:host.cross .box.selected{background-image:url('./assets/img/component/btn_cross.svg');}
		:host.tick .box.selected{background-image:url('./assets/img/component/btn_tick.svg');}
		:host.horizontal > .checkbox-container{
			display:flex;
			flex-flow:row;
		}
		:host.horizontal > .checkbox-container .box:nth-child(n+2) {
			margin-left:var(--space-gap);
		}
		:host.vertical > .checkbox-container{
			display:flex;
			flex-flow:column;
		}
		:host.vertical > .checkbox-container .box:nth-child(n+2) {
			margin-top:var(--space-gap);
		}

		.RW{position:absolute;width:46px;height:46px;display:block;top: -24px;right: -58px;}
		.RW.correct{background-image:url('./assets/img/component/mc_icons.svg?t=1#correct_icon');}
		.RW.incorrect{background-image:url('./assets/img/component/mc_icons.svg?t=2#incorrect_icon');}
		`
	]
	// ,
	// entryComponents:[ROGroupComponent]
})
export class RORadioAnswer extends ROComponent
{
	public checkboxes:any [];
	public size:number ;
	public borderType:string = 'rect';
	public selectedIndex:number = -1;
	public correctState:string = 'none';

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.answerChanged = true;
		this.canEditInside = true;
	}

	public toggle(index:number):void {
		if(!this.context.canInteract(this)) return;
		this.selectedIndex = this.selectedIndex==index ? -1 : index;
	}
	public getTagNames():string []
	{
		return ["RadioAnswer"];
	}
	

	
	protected buildContent():void
	{
		
		/*
		{"ver":"1.3.1",
		"answer":2,"toggleType":"tick",
		"checkAnswer":true,"douid":"4B22066E-B9F8-2CEE-A094-DDD65A759CA6",
		
		"coordinateExpression":"UA UK KW KH KR X 63 Y 244 D T 244 L 63 H 60 W 200",
		"hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,
		"q":"{\"freezed\":1,\"color\":\"noColor\",\"ballSize\":36,\"show\":0,\"level\":0}",
		"s":"{\"reference\":\"rt\",\"y\":0,\"optional\":false,\"freezed\":1,\"enable\":1,\"offset\":{\"y\":0,\"x\":0},\"x\":200}",
		"direction":"horizontal",
		"spaceGap":10,
		"isQuestionComponent":true,
		"locked":false,"questionIndex":1,
		"qInfo":"{\"pid\":0,\"id\":2,\"rootIndex\":1,\"level\":0,\"root\":4,\"index\":1,\"order\":4}","w":200,"h":60,"x":63,"y":244}-->
		*/
		//this.buildTemplate();
		var dom:HTMLElement = this.elementRef.nativeElement;
		
		// "direction":"horizontal","spaceGap":10,
		var preview = true;
		

		super.buildContent();
		this.checkboxes=this.node.children;
		this.size = this.getNodeSize( this.node.children[0] );
		dom.classList.add(this.node.attributes.toggleType);
		dom.classList.add(this.node.attributes.direction);
		StyleUtils.setStyleVariabe(dom, "space-gap", this.node.attributes.spaceGap + "px");
		StyleUtils.setStyleVariabe(dom, "checkbox-size", this.size + "px");

		// input have wrong size
		this.elementRef.nativeElement.style.width = "auto";
		this.elementRef.nativeElement.style.height = "auto";
		
		this.borderType = this.size>=25 ? 'round' : 'rect';

		/*if(preview)
		{
			console.log(">> ", this.node.attributes.answer);
			var answerIndex = this.node.attributes.answer;
			this.checkboxes[answerIndex].attributes.selected = true;
		}*/
	}
	private getNodeSize(node:XMLNode):number
	{
		var reg:RegExp = /W ([0-9.]{1,})/;
		var match = reg.exec(node.attributes.coordinateExpression);
		return parseFloat(match[1]);
	}

	// =========================
	// data function
	// =========================
	public reset():void {
		this.selectedIndex = -1;

		this.sObj.hideScore();
	}

	public set data(value:string) {
		this.defaultAnswer = null;
		// clear verify and answer
		this.answerChanged = true;
		this.reset();

		if(value) {
//			this.answerChanged = false; // 載入的資料不算有用戶改答案
			let d:any = value.split(",");
			this.selectedIndex = parseInt(d[0]);
			if(this.selectedIndex!=-1)
				this.checkboxes[this.selectedIndex].selected = true;
		}
	}

	public get data():string
	{
		return this.selectedIndex+",1";
	}
	
	public canVerify(): boolean {
		return true;
	}

	public defaultAnswer:any;
	
	public hideDefaultAnswer(): void {
		this.defaultAnswer = null;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}
	
	public showDefaultAnswer(): void {
		if(this.node.attributes.answer==-1) {
			this.defaultAnswer = {
				answer:-1
			};
		} else {
			this.defaultAnswer = {
				answer:this.node.attributes.answer
			};
		}
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}
	public verify(_showScoring:boolean):any {
		// reset verify
		this.correctState = '';
		// reset score display
		this.sObj.hideScore();


		if(this.node.attributes.answer==-1) {
			if(!this.resultObject)
				this.resultObject = {correct:-1};
			
		} else {
			// 取得對錯狀態
			this.resultObject = this.selectedIndex==this.node.attributes.answer ? {correct:2} : {correct:0};
			// 計算分數
			this.resultObject.score = this.resultObject.correct == 2 ? this.node.attributes.fullScore : 0;
		}

		//console.log(this.selectedIndex, this.node.attributes.answer,this.resultObject);
			
		if(_showScoring) {
			// 顯示 verify 狀態
			if(this.resultObject.correct == 2)
				this.correctState = 'correct';
			else if(this.resultObject.correct == 0)
				this.correctState = 'incorrect';
		}

		// 顯示分數 / 已提交
		this.sObj.showScoring(_showScoring, "view", this.resultObject);		
		return this.resultObject;
	}


	// =========================
	// component type
	// =========================
	// 題目組件：有資料、計分/不計分、有/無對錯、出成績表
	public isQuestionComponent():boolean
	{
		return true;
	}
	
}