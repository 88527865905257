
import { CommonModule } from "@angular/common";
import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { UiSwitchModule } from "ngx-ui-switch";
import { InputSwitchModule } from "primeng/inputswitch";
import { DirectiveModule } from "src/app/directive/DirectiveModule";
import { BubbleBox2Module } from "src/app/sharedModule/bubbleBox2Module/bubbleBox2.module";
import { HeaderIconBtnModule } from "src/app/sharedModule/headerIconBtnModule/headerIconBtn.module";
import { InlineSvgModule } from "src/app/sharedModule/inlineSvgModule/inlineSvg.module";
import { InlineSvgService } from "src/app/sharedModule/inlineSvgModule/inlineSvg.service";
import { NumberInputModule } from "src/app/sharedModule/numberInputModule/numberInput.module";
import { OkaPulldownModule } from "src/app/sharedModule/okaPulldownModule/okaPulldown.module";
import { SelectionPanelModule } from "src/app/sharedModule/selectionPanelModule/selectionPanel.module";
import { SubjectModule } from "src/app/sharedModule/subjectModule/subject.module";
import { SubjectSelector3Module } from "src/app/sharedModule/subjectSelector3Module/subjectSelector3.module";
import { TargetSelectorModule } from "src/app/sharedModule/targetSelectorModule/targetSelector.module";
import { WhitePopupModule } from "src/app/sharedModule/whitePopupModule/whitePopup.module";

@Component({
	selector: 'ro-book-icon',
	template:`

		<svg-icon *ngIf="ready"
			
			[name]="icon"></svg-icon>
			<!-- style="padding: 0 5px 0 8px;top: 3px; position: relative;"-->
	
	<!-- 
		<svg-icon *ngIf="r2Modal.multi_file_type" name="fileicon3_others"
			></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='pdf-paper'"
			name="fileicon3_testpaper" style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='powerpoint'" name="fileicon3_ppt"
			style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='excel'" name="fileicon3_xls"
			style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='image'" name="fileicon3_image"
			style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='pdf'" name="fileicon3_pdf"
			style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='word'" name="fileicon3_doc"
			style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='audio'" name="fileicon3_audio"
			style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		<svg-icon *ngIf="!r2Modal.multi_file_type && r2Modal.files[0]['type']=='assessment'" name="fileicon3_qb"
			style="width: fit-content;height: 36px; margin: 0 5px 0 10px;"></svg-icon>
		-->
	`
})
export class BookIconComponent implements OnChanges{
	@Input() public file:any;
	public ready:boolean = false;
	public icon:string;
	constructor(public svgReg: InlineSvgService)
	{
		this.svgReg.regSvgFiles([
			{"url":"fileicon/fileicon3_testpaper.svg","name":"pdf-paper"},
			{"url":"fileicon/fileicon3_ppt.svg","name":"powerpoint"},
			{"url":"fileicon/fileicon3_xls.svg","name":"excel"},
			{"url":"fileicon/fileicon3_image.svg","name":"image"},
			{"url":"fileicon/fileicon3_pdf.svg","name":"pdf"},
			{"url":"fileicon/fileicon3_doc.svg","name":"word"},
			{"url":"fileicon/fileicon3_audio.svg","name":"audio"},
			{"url":"fileicon/fileicon3_qb.svg","name":"assessment"},
			{"url":"fileicon/icon_book_15x18.svg","name":"book"},
			{"url":"fileicon/icon_book_15x18_purple.svg","name":"purple-book"}
		]).then((o:any)=>{
			this.ready = true;
		})
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.icon = this.getIcon(this.file);
	}
	getIcon(entry:any):string
	{
		if(!entry) return null;
		var type:string = entry.type;
		if(type == "book")
		{
			if(entry.permission)
			{
				return "purple-book";
			} else {
				return "book"
				
			}
		}
		return type;
	}
}



@NgModule({
    imports: [
		OkaPulldownModule,
		FontAwesomeModule,
		SubjectModule,
		TranslateModule,
		FormsModule,
		NgSelectModule, CommonModule,
		InputSwitchModule,
		SelectionPanelModule,
		DirectiveModule,
		UiSwitchModule,
		WhitePopupModule,
		HeaderIconBtnModule,
		TargetSelectorModule,
		InlineSvgModule,
		NumberInputModule,
		SubjectSelector3Module,
		BubbleBox2Module
    ],
    declarations: [BookIconComponent],
    exports: [BookIconComponent],
	entryComponents:[BookIconComponent],
    providers: [],
})
export class BookIconModule {

}
