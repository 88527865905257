import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertModule } from '../alertModule/alert.module';
import { LoadingModule } from '../loadingModule/loading.module';
import { TranslateModule } from '@ngx-translate/core';
import { Modal2Module } from '../modal2Module/modal2.module';
import { OtherBookTypeSubmissionModal } from './otherBookTypeSubmissionModal.modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PreviewModule } from 'src/app/sharedModule/previewModule/preview.module';
import { LessonSubmissionModal } from './lessonSubmissionModal.modal';
import { AICompositionSubmissionModal } from './aiCompositionSubmission.modal';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { FormsModule } from '@angular/forms';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { AICompositionMarkingModal } from './aiCompositionMarking.modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AICompositionMarkingDetails } from './aiCompositionMarkingDetails';
import { AICompositionPersonalReport } from './aiCompositionPersonalReport.modal';
import { AdditionRemarkBallModule } from '../additionRemarkBallModule/additionRemarkBall.module';
import { AIOralMarkingDetails } from './aiOralMarkingDetails';
import { ThreadModule } from '../threadModule/thread.module';
import { InlineSvgModule } from '../inlineSvgModule/inlineSvg.module';

@NgModule({
	imports: [
		CommonModule, AlertModule, LoadingModule,
		TranslateModule, FontAwesomeModule, PreviewModule, Modal2Module,
		FormsModule, OkaPulldownModule, BubbleBox2Module, UiSwitchModule,
		AdditionRemarkBallModule,
		ThreadModule,InlineSvgModule

	],
	declarations: [
		OtherBookTypeSubmissionModal, LessonSubmissionModal, AICompositionSubmissionModal, AICompositionMarkingModal,
		AICompositionMarkingDetails, AICompositionPersonalReport, AIOralMarkingDetails
	],
	exports: [
		OtherBookTypeSubmissionModal, LessonSubmissionModal, AICompositionSubmissionModal, 
		AICompositionMarkingModal, AICompositionPersonalReport, AIOralMarkingDetails
	],
	providers: [],
	bootstrap: []
})
export class OtherBookTypeSubmissionModalModule { }
