import { Component, HostBinding, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { TopLayer } from 'src/app/common/TopLayer';

@Component({
    selector: 'bobbleBox',
    template: `
    <div class="backdrop" [style.zIndex]="backdropZindex" (click)="(submitOnClose ? submit() : close())"></div>
    <div
        #contentContainer
        class="contentContainer"
        [style.zIndex]="contentContainerZindex"
        [style.left.px]="boxPosX"
        [style.top.px]="boxPosY"
        [style.backgroundColor]="backgroundColor"
    >
        <ng-content></ng-content>
        <ng-container *ngIf="contentContainer">
            <div
                *ngIf="boxPos=='bottom'"
                class="boxTail"
                [style.left.px]="contentContainer.offsetWidth / 2 - 9.9"
                [style.top.px]="-9"
                [style.backgroundColor]="backgroundColor"
                style="border-left: 1px solid #CCCCCC; border-top: 1px solid #CCCCCC;"
            ></div>
            <div
                *ngIf="boxPos=='top'"
                class="boxTail"
                [style.left.px]="contentContainer.offsetWidth / 2 - 9.9"
                [style.top.px]="contentContainer.offsetHeight - 9"
                [style.backgroundColor]="backgroundColor"
                style="border-right: 1px solid #CCCCCC; border-bottom: 1px solid #CCCCCC;"
            ></div>
            <div
                *ngIf="boxPos=='left'"
                class="boxTail"
                [style.left.px]="contentContainer.offsetWidth - 9"
                [style.top.px]="contentContainer.offsetHeight / 2 - 9.9"
                [style.backgroundColor]="backgroundColor"
                style="border-top: 1px solid #CCCCCC; border-right: 1px solid #CCCCCC;"
            ></div>
            <div
                *ngIf="boxPos=='right'"
                class="boxTail"
                [style.left.px]="-9"
                [style.top.px]="contentContainer.offsetHeight / 2 - 9.9"
                [style.backgroundColor]="backgroundColor"
                style="border-left: 1px solid #CCCCCC; border-bottom: 1px solid #CCCCCC;"
            ></div>
        </ng-container>
    </div>
    `,
    styleUrls: ['./bobbleBox.component.scss']
})

export class BobbleBoxComponent {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }
    @ViewChild("contentContainer", {static:true}) contentContainer: ElementRef;
    //@HostListener('click', []) onClick() { this.close(); }

    //@HostBinding('style.z-index') hostZindex:number = 100;
    @HostBinding('style.display') hostDisplay:string = 'none';

    @Input() submitOnClose:boolean = false;
    @Input() backgroundColor:string = '#FFFFFF';

    public backdropZindex:number = 100;
    public contentContainerZindex:number = 101;

    public boxPos:string = "bottom"; //top/bottom/left/right;
    public targetRect:DOMRect = null;
    public boxPosX:number = -10000;
    public boxPosY:number = -10000;

    private targetEle:HTMLElement = null;
    private resolveFn:Function = null;

    constructor() {
    }

//-------------------------------------------------------------------------------------------------
    public open(targetEle:HTMLElement):Promise<any> {
        return new Promise((resolve:Function, reject:Function)=>{
            this.resolveFn = resolve;
            this.targetEle = targetEle;

            this.backdropZindex = TopLayer.getNextIndex();
            this.contentContainerZindex = TopLayer.getNextIndex();

            this.resize();

            this.hostDisplay = 'flex';
        });
    }
    //-------------------------------------------------------------------------------------------------
    public submit():void {
        this.resolveFn();
        this.close();
    }
    //-------------------------------------------------------------------------------------------------
    public close():void {
        this.resolveFn = null;
        this.targetEle = null;
        this.hostDisplay = 'none';
        this.boxPosX = -10000;
        this.boxPosY = -10000;
        //if (this.submit) this.submit();
    }
    //-------------------------------------------------------------------------------------------------
    public resize():void {
        if (!this.targetEle) return;
        let counter:number = 1000;

        let timer = setInterval(() => {
            counter--;
            if (counter<1) clearInterval(timer);
            let boxEle = this.contentContainer.nativeElement;
            if (boxEle.clientHeight && boxEle.clientWidth){
                clearInterval(timer);

                let screenWidth:number = document.body.offsetWidth;
                let screenHeight:number = document.body.offsetHeight;
                let boxHeight:number = boxEle.offsetHeight;
                let boxWidth:number = boxEle.offsetWidth;
                let targetRect:ClientRect = this.targetEle.getBoundingClientRect();

                if ((targetRect.bottom + boxHeight) <= screenHeight) {
                    // Set box to bottom
                    this.boxPos = 'bottom';
                    this.boxPosX = targetRect.left + ((targetRect.width - boxWidth) / 2);
                    this.boxPosY = targetRect.bottom + 10;
                } else if ((targetRect.top - boxHeight) >= 0) {
                    // Set box to top
                    this.boxPos = 'top';
                    this.boxPosX = targetRect.left + ((targetRect.width - boxWidth) / 2);
                    this.boxPosY = targetRect.top - boxHeight - 10;
                } else if ((targetRect.right + boxWidth) <= screenWidth) {
                    // Set box to right
                    this.boxPos = 'right';
                    this.boxPosX = targetRect.right + 10;
                    this.boxPosY = targetRect.top + ((targetRect.height - boxHeight) / 2);
                } else {
                    // Set box to left
                    this.boxPos = 'left';
                    this.boxPosX = targetRect.left - boxWidth - 10;
                    this.boxPosY = targetRect.top + ((targetRect.height - boxHeight) / 2);
                }

                if (this.boxPosX<0) this.boxPosX = 0;
                if ((this.boxPosX + boxWidth) > screenWidth) this.boxPosX = screenWidth - boxWidth;
                if (this.boxPosY<0) this.boxPosY = 0;
                if ((this.boxPosY + boxHeight) > screenHeight) this.boxPosY = screenHeight - boxHeight;


            }
        },10);
    }
    //-------------------------------------------------------------------------------------------------

}
