import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SubmitResponseComponent } from './SubmitResponse.component';

@NgModule({
    imports: [
		CommonModule,
        TranslateModule
    ],
    declarations: [ SubmitResponseComponent ],
	exports:[ SubmitResponseComponent ],
	entryComponents: [],
	providers:[]
})
export class SubmitResponseModule {}