import { Component, Input, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'dateTimePickerSelectDate',
    template: `
<div class="rowToday">
    <div class="todayBtn" [style.color]="color1" (click)="selectToday()">{{{tc:'今天',sc:'今天',en:'Today'}[lang]}}</div>
</div>

<ng-container *ngIf="curView=='selectDate'">
    <div class="rowMonth">
        <img
            class="switchMonthBtn"
            src="./assets/img/chevron-left.svg"
            (click)="switchLastMonth()"
        >
        <div class="monthBtn" (click)="curView='selectMonth'">{{calendarYear + '/' + (calendarMonth+1)}}</div>
        <img
            class="switchMonthBtn"
            src="./assets/img/chevron-right.svg"
            (click)="switchNextMonth()"
        >
    </div>
    <div class="rowDate">
        <div class="day" *ngFor="let day of daies; let i=index" [style.color]="(i==0?'#FEB9BD':'#999')">{{day}}</div>
        <div
            class="date"
            *ngFor="let calDate of dates"
            [class.today]="isToday(calDate)"
            [class.selected]="isSameDate(date, calDate)"
            [style.backgroundColor]="(isSameDate(date, calDate) ? color1 : 'transparent')"
            [style.color]="getDateTextColor(calDate)"
            [style.borderColor]="color1"
            (click)="dateClickHandler(calDate)"
        >{{calDate.getDate()}}</div>
    </div>
</ng-container>
<dateTimePickerSelectMonth *ngIf="curView=='selectMonth'" [date]="date" [lang]="lang" [color1]="color1" [color2]="color2" (onChange)="monthChanged($event)"></dateTimePickerSelectMonth>
    `,
    styleUrls: ['./dateTimePickerSelectDate.component.scss']
})

export class DateTimePickerSelectDateComponent implements OnChanges {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

    @Input() public lang:string = 'tc';
    @Input() public color1:string;
    @Input() public color2:string;
    @Input() public date:Date = new Date();

    public curView:string = 'selectDate'; //SelectMonth

    public daies:string[];
    public dates:Date[] = [];

    public calendarYear:number;
    public calendarMonth:number;

    @Output() dateClick:EventEmitter<Date> = new EventEmitter();

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.date && changes.date.currentValue) {
            this.calendarYear = changes.date.currentValue.getFullYear();
            this.calendarMonth = changes.date.currentValue.getMonth();
            this.updateDates();
        }

        if (changes && changes.lang && changes.lang.currentValue) {
            if (changes.lang.currentValue=='tc' || changes.lang.currentValue=='sc') {
                this.daies = ['日', '一', '二', '三', '四', '五', '六'];
            }  else {
                this.daies = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
            }
        }
    }
    //---------------------------------------------------------------------------------------------
    private getLastSunday(curDate:Date=new Date()):Date {
        let lastSunday:Date = new Date(curDate);
        lastSunday.setDate(curDate.getDate() - curDate.getDay());
        return lastSunday;
    }
    //---------------------------------------------------------------------------------------------
    public updateDates(year:number=this.calendarYear, month:number=this.calendarMonth):void {
        this.dates.splice(0, this.dates.length);
        let firstDateOfMonth:Date = new Date(year, month, 1, 0, 0, 0, 0);
        let lastDateOfMonth:Date = new Date(firstDateOfMonth);
        lastDateOfMonth.setMonth(lastDateOfMonth.getMonth() + 1);
        lastDateOfMonth.setDate(lastDateOfMonth.getDate() - 1);

        let firstShowDate:Date = this.getLastSunday(firstDateOfMonth);
        let lastShowDate:Date = new Date(firstShowDate);
        while(lastShowDate<=lastDateOfMonth) {
            for (let i=0; i<7; i++) {
                let thisDate:Date = new Date(lastShowDate);
                this.dates.push(thisDate);
                lastShowDate.setDate(lastShowDate.getDate() + 1);
            }
        }

        lastShowDate.setDate(lastShowDate.getDate() - 1);
    }
    //---------------------------------------------------------------------------------------------
    public getDateTextColor(date:Date):string {
        if (date.getDay()==0) return '#FEB9BD';
        if (date.getMonth()!=this.calendarMonth) return '#999';
        return '#000';
    }
    //---------------------------------------------------------------------------------------------
    public isSameDate(date1:Date, date2:Date):boolean {
        if (!date1 || !date2) return false;
        return (date1.getFullYear()==date2.getFullYear() && date1.getMonth()==date2.getMonth() && date1.getDate()==date2.getDate());
    }
    //---------------------------------------------------------------------------------------------
    public isToday(date:Date):boolean {
        return this.isSameDate(date, new Date());
    }
    //---------------------------------------------------------------------------------------------
    public switchLastMonth():void {
        this.calendarMonth--;
        if (this.calendarMonth<0) {
            this.calendarMonth = 11;
            this.calendarYear--;
        }
        this.updateDates();
    }
    //---------------------------------------------------------------------------------------------
    public switchNextMonth():void {
        this.calendarMonth++;
        if (this.calendarMonth>11) {
            this.calendarMonth = 0;
            this.calendarYear++;
        }
        this.updateDates();
    }
    //---------------------------------------------------------------------------------------------
    public monthChanged(yearMonthObj:any={year: this.date.getFullYear(), month: this.date.getMonth()}):void {
        //this.calendarYear = this.date.getFullYear();
        //this.calendarMonth = this.date.getMonth();
        this.calendarYear = yearMonthObj.year;
        this.calendarMonth = yearMonthObj.month;

        this.updateDates();
        this.curView='selectDate';
    }
    //---------------------------------------------------------------------------------------------
    public selectToday():void {
        let today:Date = new Date();
        this.dateClickHandler(today);
        /*this.date.setFullYear(today.getFullYear());
        this.date.setMonth(today.getMonth());
        this.date.setDate(today.getDate());
*/
        this.monthChanged();
    }
    //---------------------------------------------------------------------------------------------
    public dateClickHandler(date:Date):void {
        this.date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        this.dateClick.emit(this.date);
    }
}
