import { Component, HostListener, Output, EventEmitter, Input, ElementRef, OnInit } from '@angular/core';
import { } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faUserFriends, faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { TagGroupService } from './tagGroup.service';

@Component({
	selector: "tag-selector",
	templateUrl: './tagSelector.component.html',
	styleUrls: ['./tagSelector.component.scss']
})


export class TagSelectorComponent implements OnInit {
	@Input() public modalData;
	@Input() public field;
	@Input() public selectedItems: any = [];
	@Output() public selectedItemsChange = new EventEmitter<any>();
	@Output() public open = new EventEmitter<any>();
	@Output() public close = new EventEmitter<any>();
	@Input() public options: any = {};
	public modalSelectedItems: any;
	public faSearch = faSearch;
	public faCheck = faCheck;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public faTrash = faTrash;
	public faChevronRight = faChevronRight;
	public faChevronDown = faChevronDown;
	public faTimes = faTimes;
	public faUserFriends = faUserFriends;
	public selectedYear;
	public isTagLoaded: boolean = false;

	public searchText: string = "";
	public myTags: any[] = [];
	public otherTags: any[] = [];
	public groups: any[] = [];
	public members: any[] = [];
	public isForAll: boolean = false;
	public isOn = false;
	public year: any = null;
	public myTagOpen = false;
	public otherTagOpen = false;
	constructor(public tg: TagGroupService, private translate: TranslateService, public datas: DataService) {
	}

	ngOnInit() {
		this.tg.getTags().then(() => {
			if (this.selectedItems.length == 0) {
				this.selectedYear = this.tg.getDafaultYear();
			} else {
				let found = this.tg.tags.find(t => t.id == this.selectedItems[0]);
				if (found) {
					this.selectedYear = this.tg.schoolYears.find(y => y.id == found.school_year);
				} else {
					this.selectedYear = this.tg.schoolYears[0];
				}
			}
			this.modalSelectedItems = this.selectedItems.map(e=>{
				return this.tg.tags.find(t=>t.id == e);
			});
			this.initTags();
		});
	}

	editTargetClick($event) {
		$event.stopPropagation();
		if (!this.options.disabled) {
			this.setOnOff();
		}
	}

	layerClick($event) {
		$event.stopPropagation();
		this.setOnOff();
	}

	setOnOff() {
		this.isOn = !this.isOn;
		if (this.isOn) {
			this.open.emit();
		} else {

		}
	}

	initTags() {
		this.myTags = this.selectedYear.tags.filter(t => t.my_tag == 1 && t.type == 3).map(e => {
			let tag: any = { id: e.id, title: e.title, type: 3, school_year: e.school_year, open: false };
			return tag;
		});
		this.groups = this.selectedYear.tagClass.map(e => {
			let group: any = { id: e.id, title: e.title, type: 9, school_year: e.school_year, open: false };
			group.tags = e.tags.map(t => {
				let tag: any = { id: t.id, title: t.title, type: 3, school_year: t.school_year };
				return tag;
			})
			return group;
		});
		this.otherTags = this.selectedYear.tags.filter(t => t.type == 3 && !this.groups.find(g => g.id == t.pid)).map(e => {
			let tag: any = { id: e.id, title: e.title, type: 3, school_year: e.school_year, open: false };
			return tag;
		});
	}

	checkClick(item = null) {
		for(let idx in this.modalSelectedItems){
            if(this.modalSelectedItems[idx].id == item.id){
                this.modalSelectedItems.splice(idx,1)
                return
            }
        }
        this.modalSelectedItems.push(item);
	}

	myGroupClick() {
		this.myTags.forEach(t => {
			this.checkClick(t);
		});
	}

	clear(tag = null) {
		if (tag) {
			this.modalSelectedItems = this.modalSelectedItems.filter(t => t.id != tag.id);
		} else {
			this.modalSelectedItems = [];
		}
	}

	confirmClick() {
		this.selectedItems = this.modalSelectedItems.map(e => e.id);
		this.selectedItemsChange.emit(this.selectedItems);
		this.close.emit(this.selectedItems);
		this.isOn = false;
	}

	cancelClick() {
		this.close.emit(this.selectedItems);
		this.modalSelectedItems = this.selectedItems.map(e=>{
			return this.tg.tags.find(t=>t.id == e);
		});
		this.isOn = false;
	}

	isSelected(tag) {
		return this.modalSelectedItems.find(e => e.id == tag.id);
	}

	get searchedItems() {
		const text = this.searchText;
		let items = this.myTags.filter(t => t.title.indexOf(text) > -1);
		this.groups.forEach(t => {
			if (t.title.indexOf(text) > -1) {
				items.push(t);
			}
		});
		this.otherTags.forEach(t => {
			if (t.title.indexOf(text) > -1 && !items.find(e => e.id == t.id)) {
				items.push(t);
			}
		});
		return items;
	}

	get isMyTagsSelected() {
		return false;
		let num = 0;
		this.modalData.targets.forEach(e => {
			if (e.target_type == 'tag' && this.myTags.find(t => t.id == e.target)) {
				num++;
			}
		});
		return num == this.myTags.length && num > 0;
	}

	public tagYearChange($event) {
		console.log(this, $event);
		this.modalSelectedItems = [];
		this.selectedYear = this.tg.schoolYears.find(e => e.id == $event.id);
		this.initTags();
	}

	selectAllChild(tag) {
		tag.tags.forEach(t => {
			this.checkClick(t);
		});
	}

	get selectedItemsName() {
		let arr = this.selectedItems.map(e=>{
			let found = this.tg.tags.find(t=> t.id == e);
			return found?found.title:null;
		});
		return arr.length > 0?arr.join(', '): this.translate.instant('course.pls-select...');
	}

}
