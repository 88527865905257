import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { TextFlowUtils } from "./TextFlowUtils";
import { AllROComponents } from "./AllROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLJSNode } from "./xml/XMLParser";
import { SWFLoader } from "./SWFLoader";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Color, ColorTool } from "./color/ColorTool";
import { UniqueID } from "src/app/common/UniqueID";


@Component({
	template:`
	
	<svg xmlns="http://www.w3.org/2000/svg" overflow="hidden" width="100%" height="100%"
		
		>
		<defs>
		
			<filter [attr.id]="filterID.id" color-interpolation-filters="sRGB">
				<feColorMatrix
				  in="SourceGraphic"
				  type="matrix"
				  [attr.values]="colorMatrixValue" />
			</filter>
			<g [attr.id]="imageID.id">
				<image [attr.transform]="'scale('+imageScale+') translate(-35,-35)'" [attr.href]="svg"/>
			</g>
			<g [attr.id]="fourImageID.id">
				<use  												[attr.href]="imageID.link" />
				<use 				  [attr.y]="patternHeight" 			[attr.href]="imageID.link" />
				<use [attr.x]="patternWidth" 							[attr.href]="imageID.link" />
				<use [attr.x]="patternWidth" [attr.y]="patternHeight" 	[attr.href]="imageID.link" />
			</g>
			
			<pattern 
				[attr.id]="patternID.id" 
				[attr.width]="patternWidth" 
				[attr.height]="patternHeight" 
				[attr.patternTransform]="patternTransform"
				patternUnits="userSpaceOnUse"
				>
				<use  [attr.href]="fourImageID.link" />
				<use 
					*ngIf="includeCenter" 
					[attr.x]="halfPatternW" 
					[attr.y]="halfPatternH" 
					[attr.href]="fourImageID.link" />
			</pattern>
			<g *ngIf="svg">
				<text>{{ detach()}}</text>
			</g>
		</defs>
		<g>
			<rect x="0" y="0" width="100%" height="100%" [attr.fill]="bg" [attr.opacity]="opacity" />
			<g class="object-color" [attr.opacity]="opacity"
				[attr.filter]="filterID.url"
				>
				<rect width="100%" height="100%" [attr.fill]="patternID.url" />
			</g>
		</g>
		
	</svg>
	`,
	styles:[
		`host:{
			border:solid 1px red;

		}`
	]
})
export class ROPatternComponent extends ROComponent
{
	public patternURL:string;
	public src:string;
	
	public imageID:ObjectID = new ObjectID("image");
	public fourImageID:ObjectID = new ObjectID("image");
	public patternID:ObjectID = new ObjectID("pattern");
	public filterID:ObjectID = new ObjectID("filter");
	
	public svg:SafeResourceUrl;
	public objectColorFilter:string = "";
	
	public opacity:number = 1;
	public patternWidth:number;
	public patternHeight:number;
	public halfPatternW:number;
	public halfPatternH:number;
	public includeCenter:boolean = false;
	public bg:string;
	public imageScale:number = 1;
	public centerX:number;
	public centerY:number;
	public offsetX:number;
	public offsetY:number;
	public angle1:number;
	public angle2:number;
	public patternTransform:string;
	public colorMatrixValue:string;
	public active:boolean;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public activate(): void {
		this.active = true;
		super.activate()
	}
	public deactivate(): void {
		this.active = false;
		super.deactivate();
	}

	public getTagNames():string []
	{
		return ["Pattern"];
	}

	
	protected setDefaultXMLData():void
	{
		
		
	}
	
	private loadURL():void
	{
		if(!this.src)
		{
			this.patternURL = "";
		} else {
			this.context.fileCacheProxy.fetch(this.src, ()=>{
				return this.context.service.resourceSource.lookupResource(this.src).then((url:string)=>{
					this.patternURL = url;
					return this.loadPatternAsFile();
				}).then((file:File)=>{
					var dataURL = URL.createObjectURL(file)
					return dataURL;
				})
			}, true).then((dataURL)=>{
				this.svg = dataURL;
			})
			
		}
	}

	private loadPatternAsFile():Promise<any>
	{
		// var dom:HTMLElement = this.elementRef.nativeElement;
		return SWFLoader.loadURL(this.patternURL).then((stage)=>{
			return stage.toSVG();
		}).then((svg:SVGGraphicsElement)=>{
			return SWFLoader.createFile(svg, "file.svg");
			
		});
	}

	private toColor(colorString:string):Color
	{
		return new Color().fromInt(parseInt(colorString));
	}

	protected buildContent():void
	{
		/*
		R  1  0  0  0  1 
		G  0  1  0  0  0 
		B  0  0  1  0  0 
		A  0  0  0  1  0
		*/
		var tool:ColorTool = new ColorTool();
		var patternColor:Color = this.toColor(this.node.getAttribute("colorOffset"));
		this.src = this.node.getAttribute("src");
		this.objectColorFilter = tool.colorToFilter(patternColor);
		this.colorMatrixValue = tool.colorToMatrixValue(patternColor)
		this.bg = "#"+this.node.getAttribute("bg").replace("0x", "").padStart(6, '0');
		this.opacity = this.node.getAttribute("transparency") / 100;
		this.patternWidth = this.node.getAttribute("patternWidth");
		this.patternHeight = this.node.getAttribute("patternHeight");
		this.includeCenter = this.node.getAttribute("includeCenter");
		this.imageScale = this.node.getAttribute("patternScale");
		this.halfPatternW = this.patternWidth / 2;
		this.halfPatternH = this.patternWidth / 2;
		this.centerX = this.w / 2;
		this.centerY = this.h / 2;
		this.offsetX = this.node.getAttribute("offsetX");
		this.offsetY = this.node.getAttribute("offsetY");
		this.angle1 = this.node.getAttribute("angle1");
		this.angle2 = this.node.getAttribute("angle2");
		this.patternTransform = `rotate(${this.angle2}) translate(${this.centerX + this.offsetX} ${this.centerY + this.offsetY}) rotate(${this.angle1})`;
		this.loadURL();
	}
}

@Component({
	template:`
	<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="w" [attr.height]="h">
		<defs *ngIf="obj">
			<pattern *ngIf="obj.direction == 'v'"
				[attr.id]="patternID.id" 
				[attr.width]="obj.patternSize * obj.colorCount" 
				[attr.height]="h" 
				patternUnits="userSpaceOnUse"
				>
				<rect 
					*ngFor="let color of colors; let i = index" 
					[attr.x]="i * obj.patternSize" 
					y="0" 
					[attr.width]="obj.patternSize" 
					[attr.height]="h" 
					[attr.fill]="color" 
				/>
			</pattern>

			<pattern *ngIf="obj.direction == 'h'"
				[attr.id]="patternID.id" 
				[attr.width]="w" 
				[attr.height]="obj.patternSize * obj.colorCount" 
				patternUnits="userSpaceOnUse"
				>
				<rect 
					*ngFor="let color of colors; let i = index" 
					x="0"  [attr.y]="i * obj.patternSize" 
					[attr.height]="obj.patternSize" 
					[attr.width]="w" 
					[attr.fill]="color" 
				/>
			</pattern>
			<g>
				<text>{{ detach()}}</text>
			</g>
		</defs>
		<g [attr.opacity]="obj.transparency / 100">
			<rect x="0" y="0" width="100%" height="100%" [attr.fill]="patternID.url"/>
		</g>
		
	</svg>
	`,
	styles:[
		`
		`
	]
})
export class ROColorPatternComponent extends ROComponent
{
	// public json:string

	public obj:any;
	public colors:any[];
	public patternID:ObjectID = new ObjectID("pattern");
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["ColorPattern"];
	}

	protected setDefaultXMLData():void
	{
		
	}
	/*
	test()
	{
		this.detach()
		this.cdr.detach();
	}
	*/
	protected buildContent():void
	{
		
		// JSON.stringify(this.node.attributes);
		var str:string = this.node.getAttribute("p");
		this.obj = this.stringToObject(str);
		this.colors = this.obj.colors.slice(0, this.obj.colorCount).map((color:number)=>{
			return this.colorCodeToHex(color);
		})

		
	}

	private colorCodeToHex(color:number):string
	{
		var colorString:string = color.toString(16);
		return "#"+colorString.padStart(6, "0");
	}


	
}

class ObjectID
{
	public id: string;
	public link:string;
	public url:string;
	constructor(prefix:string = "")
	{
		this.id = UniqueID.createID(prefix);
		this.link = "#"+this.id;
		this.url = `url(${this.link})`;
	}
	
}