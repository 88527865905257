import {
	Directive,
	HostBinding,
	HostListener,
	Output,
	EventEmitter
} from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
/**
 * <div native-drag-drop (files)="filesDropped($event)"></div>
 * filesDropped(files: any[]): void {
		debugger;
		// this.files = files;
	}
 * 
 */
/*
export interface FileHandle {
	file: File,
	url: SafeUrl
}
*/
@Directive({
	selector: "[native-drag-drop]"
})
export class NativeDragDropDirective {
	@Output() files: EventEmitter<any []> = new EventEmitter();

	@HostBinding("style.background") private background = "#eee";

	constructor(private sanitizer: DomSanitizer) { }

	@HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = "#999";
	}

	@HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = "#eee";
	}

	@HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#eee';

		let files: any[] = [];
		for (let i = 0; i < evt.dataTransfer.files.length; i++) {
			const file = evt.dataTransfer.files[i];
			const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
			files.push({ file:file, url:url });
		}
		if (files.length > 0) {
			this.files.emit(files);
		}
	}
}
