import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
// import { DeviceDetectorModule } from 'ngx-device-detector';
// import { CommonService } from 'src/app/service/common.service';
// import { ApiService } from './service/api.service';
// import { StorageService } from './service/storage.service';
// import { ModalService } from './service/modal.service';
// import { ModalComponent } from './component/modal.component';
// import { FullModalComponent } from './component/full-modal.component';
// import { DynamicCompDirective } from './directive/dynamicComp.directive';
// import { MiniModal } from './modal/mini.modal';
import { UiSwitchModule } from 'ngx-ui-switch';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { BookMarkingModal } from './modal/book-marking.modal';
// import { BookTimeLimitModal } from './modal/book-time-limit.modal';
import { BobbleBoxModule } from '../bobbleBoxModule/bobbleBox.module';
// import { AlertComponent } from './component/alert.component';
// import { TestPage } from './page/test.page';
// import { chatPage } from './page/chat.page';
import { ThreadComponent } from './thread.component';
import { NgSelectModule } from '@ng-select/ng-select';
// import { MessagePanelComponent } from './component/message-panel.component';
// import { myDatePipe } from './pipe/myDate.pipe';
import { AudioRecorderComponent } from './audio-recorder.component';
// import { FileSizePipe } from './pipe/file-size.pipe';
// import { Ms2TimePipe } from './pipe/ms2Time.pipe';
// import { MessageComponent } from './message.component';
// import { AudioPlayerComponent } from 'src/app/coreModule/HomeScreenModule/windowModule/component/audio-player.component';
import { RingLoadingComponent } from './ring-loading.component';
// import { NumToArrayPipe } from './pipe/num-to-array.pipe';
// import { ChatDateTimePipe } from './pipe/chatDateTime.pipe';
import { BubbleBox2Module } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.module';

import { Ms2TimePipe } from './pipe/ms2Time.pipe';
import { FileSizePipe } from './pipe/file-size.pipe';
import { MessagePanelComponent } from './message-panel.component';
import { AudioPlayerComponent } from './audio-player.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HttpLoaderFactory } from '../resultPanelModule/module/shared/shared.module';
import { MessageComponent } from './message.component';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { InlineSvgModule } from '../inlineSvgModule/inlineSvg.module';
// import { LogoutComponent } from './component/logout.component';
// import { HttpLoaderFactory } from 'src/app/app.module';
// import { OpenBookModule } from 'src/app/sharedModule/openBookModule/openBook.module';
@NgModule({
  declarations: [
    ThreadComponent, MessageComponent, Ms2TimePipe, MessagePanelComponent, AudioRecorderComponent, AudioPlayerComponent, RingLoadingComponent,
	FileSizePipe
  ],
  imports: [
    CommonModule, FormsModule, HttpClientModule, PerfectScrollbarModule, 
	TranslateModule.forChild({
		loader: {
		  provide: TranslateLoader,
		  useFactory: HttpLoaderFactory,
		  deps: [HttpClient]
		}
	  }),
    UiSwitchModule,
	BubbleBox2Module,
    FontAwesomeModule, PickerModule,
    BobbleBoxModule, NgSelectModule,
	OkaPulldownModule,
	InlineSvgModule
  ],
  exports: [ ThreadComponent ],
  bootstrap: [ ]
})
export class ThreadModule { }
