import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';
import { AlertService } from 'src/app/service/alert.service';
import { PersonalRankingTable } from './personal-ranking.table';

@Component({
	selector: 'p-ranking',
	template: `
<div class="app-mod-ranking" *ngIf="loaded">
  <div class="panel" [style.visibility]="'visible'">
    <div class="panel__topic">{{ {tc:'資料來源', sc:'资料来源', en: 'Source'}[lang] }}</div>
    <div class="panel__label">{{ {tc:'組別', sc:'组别', en: 'Group'}[lang] }}: {{ rankingData.tag_name }}</div>
  </div>
  <div [ngClass]="['body', 'in-main']">
    <div class="chapter">
		<perfect-scrollbar [disabled]="datas.dev.isMobile">
        	<div class="chapter__title">
				{{(isPdfPaper?'result-panel.question-section-info':'chapter-info')|translate}}:</div>
        	<div class="chapter__item" *ngFor="let chapter of rankingData.book.chapters;let i = index;">
          		<div class="chapter__item__label">{{(isPdfPaper?'result-panel.question-section':'ch')|translate}}{{i + 1}} ({{chapter.scorePt?chapter.scorePt:('no-question'|translate)}}):</div>
          		<div class="chapter__item__title section-{{parent.modData.section}}">{{chapter.title}}</div>
        	</div>
      	</perfect-scrollbar>
    </div>
    <p-ranking-table #table [parent]="this" *ngIf="rankingData.rows.length > 0 && loaded"></p-ranking-table>
    <div class="no-student-answer" *ngIf="rankingData.rows.length == 0 && loaded">
      <div class="no-student-answer__image"></div>
      <div class="no-student-answer__text">{{'result-panel.no-student-answer'|translate}}</div>
    </div>
  </div>
</div>
  `,
	styleUrls: ['./personal-ranking.page.scss']
})
export class PersonalRankingPage implements OnInit, OnDestroy {
	public chapter;
	public loaded = false;
	public inMain: boolean = false;
	public page;
	@Input() public parent;
	@ViewChild('table', { static: false }) table: PersonalRankingTable;
	public onDestroy = new Subject();
	private needGetXMLComp = [];
	public isPdfPaper = false;
	@Input() public rankingData;
	public lang;
	constructor(public datas: DataService, private lds: LoadingService, private common: CommonService, public als: AlertService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.rankingData = this.parent.rankingData;
		this.isPdfPaper = this.rankingData.bookType == 'pdf-paper';
		this.parent.filterChange.pipe(takeUntil(this.onDestroy)).subscribe(event => {
			this.loaded = false;
			this.getData();
		});
		this.getData();
	}

	ngOnDestroy() {
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	getData() {
		const rankingData = this.rankingData;
		const bsid = rankingData.bsid;
		const statusValue = this.parent.selectedStatus;//2 改正，1 初次提交
		this.lds.add('dataTableData2', 3000);
		this.datas.post('ROResultPanel.dataTableData2', [ bsid, statusValue, rankingData.bookId, -1, [ bsid ] ]).subscribe((res: any) => {
			if (res.people) {
				if (this.isPdfPaper){
					res.people = res.people.filter(e=> e.active == 1);
				}
				rankingData.students = [];
				if (res.people.length > 0) {
					res.people.forEach(row => {
						let classno = row.classno ? row.classno : null;
						let first2digit = isNaN(row.cname.substr(0, 2)) ? null : row.cname.substr(0, 2);
						first2digit = first2digit ? first2digit : (isNaN(row.cname.substr(0, 2)) ? null : row.cname.substr(0, 2));
						classno = classno ? classno : first2digit;
						classno = classno ? parseInt(classno) : parseInt(row.uid);
						if (row.active == 2){
							row.cname = row.cname + ' (封存)'; 
							row.ename = row.ename + ' (archived)'; 
						}
						if (this.datas.appId2 == 'rainbowse' && classno){
							row.cname = classno + '. ' + row.cname;
							row.ename = classno + '. ' + row.ename;
						}
						rankingData.students.push({ uid: row.uid, ename: row.ename, cname: row.cname, tag_title: row.tag_title });
					});
				}
			}
			try {
				let json = JSON.parse(res.book.structure);
				rankingData.book = this.formatBook(json.book, res.book.pass_level);
				if (this.isPdfPaper){
					rankingData.book = this.formatPdfPaper(rankingData.book);
				}
				rankingData.book.type = res.book.type;
				rankingData.bookStatus = res.book.status;
				let foundChapter = rankingData.book.chapters.find(c => c.id == this.rankingData.docid);
				this.chapter = foundChapter ? foundChapter : rankingData.book.chapters[0];
				let foundPage = this.chapter.pages.find(p => p.id == this.rankingData.pageid);
				this.page = foundPage ? foundPage : this.chapter.pages.length > 0 ? this.chapter.pages[0] : { id: 'no-page', num: 1, title: '', max: 1, components: [] };
				rankingData.rows = this.formatData(res.result, res.needAssetsRow ? res.needAssetsRow : []);
			} catch (e) {
				const msg = {tc:'書本結構讀取失敗',sc:'书本结构读取失败',en:'book structure data error'}[this.lang];
				this.als.toastError(msg);
			}
			this.loaded = true;
			this.lds.remove('dataTableData2');
		});
	}

	formatBook(book, passLevel) {
		book.max = 0;
		book.passLevel = parseInt(passLevel);
		book.components = [];
		book.chapters = book.chapters.map(chapter => {
			let newChapter: any = { id: chapter.id, title: chapter.title, max: 0 };
			newChapter.maxComponentCount = 0;
			newChapter.pages = chapter.pages.map(page => {
				let newPage: any = { id: page.id, num: page.pageNumber, title: page.title, max: 0, docid: newChapter.id };
				newPage.components = page.components.map(component => {
					let newComponent: any = { id: component.cid, type: component.type.toLowerCase(), hasScoring: component.hasScoring, order: component.order };
					newComponent.name = component.name ? component.name : '?';
					if (newComponent.type == 'vote') {
						newComponent.hasScoring = false;
					}
					if (newComponent.type == 'scorecom') {
						if (!this.needGetXMLComp.find(c => c.comp.id == newComponent.id)) {
							this.needGetXMLComp.push({ comp: newComponent, chapterId: chapter.id, pageId: page.id, xml: false });
						}
					}
					if (newComponent.hasScoring) {
						newComponent.max = component.score.full?parseFloat(component.score.full):0;
						newChapter.max += newComponent.max;
						newPage.max += newComponent.max;
						book.max += newComponent.max;
					} else {
						newComponent.max = 0;
					}
					if (newComponent.type != 'exampaper' && newComponent.type != 'totalscorecom'){
						book.components.push(newComponent);
					}
					newChapter.maxComponentCount++;
					return newComponent;
				});
				return newPage;
			});
			return newChapter;
		});
		book.chapters.forEach(chapter => {
			if (chapter.max == 0) {
				chapter.scorePt = null;
				chapter.scorePtValue = 0;
			} else {
				let scorePt = (chapter.max / book.max) * 100;
				chapter.scorePtValue = Math.round(scorePt * 10) / 10;
				chapter.scorePt = chapter.scorePtValue + '%';
			}
		});
		return book;
	}

	formatPdfPaper(book){
		let sections:any = [];
		book.components.forEach(comp=>{
			const cid = comp.id;
			const foundXml = this.rankingData.compXmlInfo.find(e=> e.indexOf(cid) > -1);
			comp.questionSection = {tc: '無環節', sc: '无环节', en: 'No section'}[this.lang];
			if (foundXml){
				const regex = /questionSection="([^"]+)"/;
				const match = foundXml.match(regex);
				if (match){
					comp.questionSection = match[1];
				}
			}
			const foundSection = sections.find(e=> e.title == comp.questionSection);
			if (foundSection){
				foundSection.pages[0].components.push(comp);
				foundSection.pages[0].max += comp.max;
				foundSection.max += comp.max;
				foundSection.maxComponentCount++;
			} else {
				let page:any = {max: 0, num: 1, components: [comp]};
				let newSection:any = {id: sections.length + 1, title: comp.questionSection, max: comp.max, maxComponentCount: 1,  maxComponentPage: 1, pages: [ page ]};
				sections.push(newSection);
			}
		});
		sections.forEach(section => {
			section.scorePtValue = Math.round(section.max * 10 * 100 / book.max) / 10;
			section.scorePt = section.scorePtValue + '%';
		});
		book.chapters = sections;
		return book;
	}

	formatData(result = [], needAssetRows = []) {
		let rows = result.filter(row => {
			if (row.tag == 'Recorder' || row.tag == 'VideoCamera' || row.tag == 'Camera') {
				return needAssetRows.find(e => e.u == row.uid && e.c == row.cid)
			}
			return true;
		});
		rows = rows.map(row => {
			let comp = this.page.components.find(c => c.id == row.cid);
			let max = 0;
			if (comp) {
				max = comp.max ? comp.max : 0;
			}
			let output: any = { uid: row.uid, sid: row.sid, cid: row.cid, score: null, max: max, data: row.data, cType: row.tag.toLowerCase(), updatedAt: row.updated_at };
			let json = JSON.parse(row.result);
			output.result = json;
			if (json) {
				output.score = json.score;
				if (/time/.test(row.result)) {
					output.time = json.time;
				}
			}
			return output;
		});
		return rows;
	}

	update() {
		this.table.updateAll();
	}


}
