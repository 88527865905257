import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NumberInputComponent } from './numberInput.component';

@NgModule({
    imports: [
      CommonModule,
      FontAwesomeModule,
    ],
    declarations: [
        NumberInputComponent
    ],
    exports: [
        NumberInputComponent
    ],
})
export class NumberInputModule { }
