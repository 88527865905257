import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { faArrowRotateRight } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "header",
	templateUrl: "./header.html",
	styleUrls: ["./header.scss"]
})
export class speechPracticeHeader {
	@Input() public parent;
	@Input() public subType;
	@Input() public bookConfig;
	@Input() public viewMode;
	@Input() public isRecorded;
	@Input() public pageIndex;
	@Input() public totalPageNumber;
	@Input() public progressPercent;
	@Input() public isOralVocabReportOpen;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	public refresh = faArrowRotateRight;
	public oralVocabProgressPercent = "0%";
	public isButtonHide = false;

	constructor( public router: Router ) {}

	ngOnInit() {
		if (!this.parent.conversationPractice.includes(this.subType)) {
			this.pageIndex += 1;
		}
		this.isButtonHide = this.viewMode === 'review' || this.viewMode === 'scoring'
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.parent.conversationPractice.includes(this.subType) && changes.pageIndex) {
			this.updateOralProgressPercent();
		}
	}

	updateOralProgressPercent(): void {
		if (this.pageIndex > 0) {
			this.oralVocabProgressPercent = `${this.pageIndex / this.totalPageNumber * 100}%`;
		} else {
			this.oralVocabProgressPercent = "0%";
		}
	}

	close() {
		if (!this.parent.conversationPractice.includes(this.subType)) {
			let userTotalScore = 0;
			let pageWithScore = Object.values(this.bookConfig.dataSource.answerMap).filter((item: any) => item.result);
			let submittedNumber = pageWithScore.length;
			let eachPageScore = pageWithScore.map((item: any) => item.result["2280-1"]);
			for (let score of eachPageScore) userTotalScore += score;

			if ((this.pageIndex === this.totalPageNumber && submittedNumber === this.totalPageNumber) ||
				(this.pageIndex === this.totalPageNumber && this.isRecorded)) {
				this.emitter.emit({
					action: "close",
					isFinalReportOpen: true,
					userTotalScore: userTotalScore,
				});
			} else {
				this.emitter.emit({ action: "close", isFinalReportOpen: false });
			}
		} else {
			if (this.isOralVocabReportOpen || this.subType == "oralAI") {
				this.emitter.emit({ action: "close", isFinalReportOpen: true });
			} else {
				this.emitter.emit({ action: "close", isFinalReportOpen: false });
			}
		}
	}
	
	reload() {
		let currentUrl = this.router.url;
		this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
			this.router.navigate([currentUrl]);
		});
	} 
}
