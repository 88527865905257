import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberPadModal } from './numberPad.component';
import { NumberPadService } from './numberPad.service';
import { Modal2Module } from '../modal2Module/modal2.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule,Modal2Module],
    declarations: [NumberPadModal],
	entryComponents:[NumberPadModal],
    exports: [NumberPadModal],
    providers: [NumberPadService],
    bootstrap: []
})
export class NumberPadModule { }
