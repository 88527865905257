import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { TranslateModule } from '@ngx-translate/core';
import { SubjectSelector3Component } from './subjectSelector3.component';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';

@NgModule({
    imports: [
		CommonModule,
        FormsModule,
        TranslateModule,
        FontAwesomeModule,
		NgSelectModule,
		PerfectScrollbarModule,
        BubbleBox2Module,
		OkaPulldownModule
    ],
    declarations: [ SubjectSelector3Component ],
	exports:[ SubjectSelector3Component ],
	entryComponents: [],
	providers:[]
})
export class SubjectSelector3Module {}
