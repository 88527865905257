import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { RoService } from 'src/app/service/ro.service';

@Component({
	selector: 'upload-alert-modal',
	template: `
  <div class="wrapper">
      <div class="upper">
        <div class="message">{{data.title|translate}}</div>
      </div>
      <div class="down">
        <div class="panel confirm" *ngIf="data.type == 'confirm'">
          <a (click)="data.confirm()">
            <span>{{('upload.' + data.confirmText) | translate}}</span>
          </a>
          <a class="confirm__no" (click)="data.close()">
            <span>{{('upload.' + data.cancelText) | translate}}</span>
          </a>
        </div>

		<div class="panel confirm" *ngIf="data.type == 'alert'">
          <a (click)="data.confirm()">
            <span>{{('upload.' + data.confirmText) | translate}}</span>
          </a>
        </div>
      </div>
    </div>
  `,
	styleUrls: ['./upload-alert.modal.scss'],
})

export class UploadAlertModal implements OnInit {
	@Input() public data;
	constructor(private ros: RoService, private common: CommonService, private eleRef: ElementRef) {
	}

	ngOnInit() {
		this.eleRef.nativeElement.style.zIndex = this.common.getZIndex();
	}

}
