import { ComponentRef, ElementRef, Injectable, ViewContainerRef } from "@angular/core";
import { ROComponent, ROComponentMap, ROContainerComponent, ROGraphicComponent, ROImageComponent, ROLabel, ROPageComponent, ROShapeBubbleSpeakComponent, ROShapeCircleComponent, ROShapeCustomComponent, ROShapeRectangleComponent, ROSuperTextComponent, ROTLFTextComponent, ROVideoComponent } from "./ROComponent";

import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
import { XMLNode } from "./xml/XMLNode";
import { DataService } from "src/app/service/data.service";
import { ROAudioPlayerManager } from "./TTSManager";
import { RODocumentService } from "../roModule/RODocumentService";
import { RODocument} from "./RODocument";
import { FileIOService } from "src/app/service/FileIO.service";
import { AlertService } from "src/app/service/alert.service";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";
import { AnswerSource, ROBookConfig } from "./ROBookConfig";
import { UploadService } from "../uploadModule/upload.service";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import { Subject, Subscription, fromEvent, merge } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import JSZip from "jszip"
import { ByteLoader, FileUtils } from "src/app/common/FileUtils";
import { ScriptLoader } from "src/app/common/ScriptLoader";
import { WhitePopupService } from "../whitePopupModule/whitePopup.service";

////////// RainbowOne Component Defintiions
import { ROSimpleDragLine } from "./ROSimpleDragLine";
import { ROPinYinBasicElementComponent, ROPinYinTextBasicComponent, ROPinYinTextFillingComponent } from "./ROPinYinTextBasicComponent";
import { ROWebBrowser, ROYoutubeSearch } from "./ROWebView";
import { OKDDragDropInterchange, RODragDropA, RODragDropB, RODragDropBasic, RODragDropC, RODragDropCount, RODragDropD, RODragDropE, RODragDropF, RODragItem, RODropArea } from "./RODragDrop";
import { RODigitalClock, ROWatch } from "./ROWatch";
import { ROQuestionNumber } from "./ROQuestionNumber";
import { ROQuestionScore } from "./ROQuestionScore";
import { RORadioAnswer } from "./RORadioAnswer";
import { ROFillingBlankComponent } from "./ROFillingBlank";
import { ROSound } from "./ROSound";
import { ROSoundRecorder } from "./ROSoundRecorder";
import { ROCamera } from "./ROCamera";
import { ROExamPaper } from "./ROExamPaper";
import { ROVideoCamera } from "./ROVideoCamera";
import { ROPrintBtn } from "./ROPrintBtn";
import { ROBall } from "./ROBall";
import { ROPinYinOld } from "./ROPinYinOld";
import { ROShapeCurveLineComponent, ROShapeLineComponent } from "./ROShapeComponent";
import { ROPhotoHuntComponent } from "./ROPhotoHuntComponent";
import { ROButton, ROButton2 } from "./ROButton";
import { ROGroupComponent, ROMask } from "./ROMask";
import { ROHeading, ROSubHeading } from "./ROHeading";
import { ROQBall } from "./ROQBall";
import { ROScoreCom, ROTotalScoreCom } from "./ROScoring";
import { speechPracticeROSTT } from "./speech-practice/speechPracticeROSTT";
import { ROMC } from "./ROMC";
import { ROLink } from "./ROLink";
import { ROBrowserService } from "./ROBrowserService";
import { DomSanitizer } from "@angular/platform-browser";
import { ROPopupNote, ROPopupNoteBubbleContainer, ROPopupNotePinContainer } from "./ROPopupNote";
import { UserInfoHelperService } from "src/app/service/UserInfoHelper.service";
import { NumberPadService } from "../numberPadModule/numberPad.service";
import { DelayCaller } from "./DelayCaller";
import { AppStatusComponent } from "../appStatusModule/AppStatus.component";
import { WindowUnloadService } from "src/app/service/WindowUnloadService";
import { ScriptService } from "src/app/service/script.service";
import { StickerImageService } from "src/app/service/StickerImageService";
import { PDFService } from "src/app/service/PDFService";
import { MRUCache, MRUCacheProxy } from "src/app/common/LinkedList";
import { LoadingService } from "../loadingModule/loading.service";
import { ROStudentDataSourceManager } from "src/app/coreModule/BookViewerModule/ROStudentDataSourceManager";
import { ROColorPatternComponent, ROPatternComponent } from "./ROPattern";
import { ROToggleOptionComponent } from "./ROToggleOptions";
import { ROEPenComponent } from "./ROEPen";
import { EPenState } from "./epen/EPenState";
import { QBFillingBlank } from "./QBFillingBlank";
import { QBLongQuestion } from "./QBLongQuestion";
import { QBInfoBlock } from "./QBInfoBlock";
import { QBTextMC } from "./QBTextMC";
import { QBRecorder } from "./QBRecorder";
import { QBTakePhoto } from "./QBTakePhoto";
import { QBToggleOptions } from "./QBToggleOptions";
import { QBShortAnswer } from "./QBShortAnswer";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";
import { QBComponentBase } from "./QBComponentBase";



export const ROComponentDefinition:any [] = [
	ROToggleOptionComponent,
	ROEPenComponent,
	ROPatternComponent, 
	ROColorPatternComponent,
	ROPageComponent,
	ROImageComponent,
	ROGraphicComponent,
	ROTLFTextComponent,
	ROSuperTextComponent,

	ROQuestionNumber,ROQuestionScore,
	RORadioAnswer,
	ROFillingBlankComponent, 
	ROSound,
	ROSoundRecorder,
	ROCamera, 
	ROVideoCamera,
	ROVideoComponent,
	ROExamPaper,
	ROPrintBtn,
	ROBall, 
	ROPinYinOld, 
	ROPinYinTextBasicComponent,
	ROPinYinTextFillingComponent,
	ROPinYinBasicElementComponent,
	ROSimpleDragLine,
	RODropArea, RODragItem, 
	RODragDropBasic, OKDDragDropInterchange, RODragDropA, RODragDropB, RODragDropC, RODragDropD, RODragDropE, RODragDropF, RODragDropCount,
	ROShapeBubbleSpeakComponent, ROShapeRectangleComponent, ROShapeCustomComponent, ROShapeCircleComponent,
	ROShapeCurveLineComponent, ROShapeLineComponent,
	ROPhotoHuntComponent, 
	ROButton, ROButton2,
	ROGroupComponent, ROMask,
	ROHeading, ROSubHeading,
	ROWatch, RODigitalClock,
	ROQBall,
	ROYoutubeSearch, ROWebBrowser,
	ROScoreCom,
	speechPracticeROSTT,
	ROLabel,
	ROMC,
	ROLink,
	ROPopupNote, ROPopupNoteBubbleContainer, ROPopupNotePinContainer,
	ROTotalScoreCom,
	QBFillingBlank,
	QBLongQuestion,
	QBInfoBlock,
	QBTextMC,
	QBRecorder,
	QBTakePhoto,
	QBShortAnswer,
	QBToggleOptions,
	QBComponentQuestionBase,
	QBComponentBase
];

@Injectable({ providedIn: 'root' })
export class ROContextService
{
	constructor(
		public pdfService:PDFService,
		public documentService:RODocumentService,
		public dataService:DataService,
		public dcs:DynamicComponentService,
		public translateService:TranslateService,
		public fileIO: FileIOService,
		public alertService: AlertService,
		public whitePopupService:WhitePopupService,
		public browserService:ROBrowserService,
		public domSanitizer: DomSanitizer,
		public userInfoHelper:UserInfoHelperService,
		public numberPadService:NumberPadService,
		public roClipboardService:ROClipboardService,
		public resourceSource:CacheResourceSource,
		public roBookReviewService:ROBookReviewService,
		public dataSourceService:RODataSourceService,
		public script: ScriptService,
		public stickerImageService:StickerImageService,
		public loadingService:LoadingService
	)
	{

	}
}

export class ROLayerEditManager
{
	public currentLayer:ROComponent;
	public editLayers:ROComponent []  = [];
	public releaseEditLayer():void
	{
		var editLayerClone:ROComponent [] = this.editLayers.concat();
		for(var i = editLayerClone.length - 1;i >= 0;i--)
		{
			editLayerClone[i].editInStage = false;
		}
		this.editLayers = [];
	}

	public setEditLayer(layer:ROComponent):void
	{
		layer.editInStage = true;
		this.editLayers.push(layer);

		if(this.editLayers.length>1 && this.currentLayer)
			this.currentLayer.getElementRef().nativeElement.style.zIndex = null;
		this.currentLayer = layer;
		layer.getElementRef().nativeElement.style.zIndex = 11;
	}
	public popEditLayer():void
	{
		if(!this.editLayers.length)  return;
		var layer:ROComponent = this.editLayers.pop();
		layer.editInStage = false;
		layer.getElementRef().nativeElement.style.zIndex = null;

		if(this.editLayers.length > 0) {
			this.currentLayer = this.editLayers[this.editLayers.length-1];
			this.currentLayer.getElementRef().nativeElement.style.zIndex = 11;
		} else {
			this.currentLayer = null;
		}
	}
}
var myCacheProxy = new MRUCacheProxy(100);
var fileCacheProxy = new MRUCacheProxy(20);
export class ROContext
{
	public epenState:EPenState;
	public vocabDictionary:VocabDictionaryDatabase;
	public map:ROComponentMap;
    // public resourceSource:ResourceSource;
	public playerManager:ROAudioPlayerManager;
	public config: ROBookConfig;
	public assetUploader: ROAnswerAssetUpload;
	public subject:Subject<any>;
	public pageResult:any;
	public answerEnabled:boolean = true;
	public service:ROContextService;
	public showDefaultAnswer: boolean;
	public showSticker:boolean = true;
	public showPinYin:boolean = true;
	public showPinYinText:boolean = true;
	public editLayerManager:ROLayerEditManager;
	public layers:any;
	public showMarkingLayer:boolean = true;
	public mruCacheProxy:MRUCacheProxy;
	public fileCacheProxy:MRUCacheProxy;
	constructor()
	{
		this.mruCacheProxy = myCacheProxy;
		this.fileCacheProxy = fileCacheProxy;
		this.layers = {
			
		};
		this.subject = new Subject();
		this.prepareTTSVoice();
		this.map = new ROComponentMap();
	}
	
	clone(): ROContext {
		var copy:ROContext = new ROContext();
		copy.service = this.service;
		copy.map = this.map;
		return copy;
	}

	/*
	public canLiveVerify():boolean{
		debugger;
		if (share.live_verify is String)
		{
			share.live_verify = parseInt(share.live_verify);
		}
		return share.live_verify;
	}
	*/
	updateBookPageNumber(bookConfig: ROBookConfig) 
	{
		var pageNo:number = 0;
		bookConfig.pages.forEach((page)=>{
			if(page.attributes.broadcast)
			{
				pageNo++;
				page.pageNo = pageNo;
				page.broadcast = true;
			} else {
				page.pageNo = "T";
				page.teacherNote = true;
			}
		})
	}
	

	roundScore(score:number):number{
		return Math.round(score * 100) / 100;
	}

	public canInteract(com:ROComponent):boolean
	{
		if(this.isEditingComponent(com))
			return true;
		if(this.isAnswerEnabled())
			return true;
		return false;
	}

	public isEditingComponent(com:ROComponent):boolean
	{
		return this.editLayerManager && this.editLayerManager.currentLayer == com;
	}

	public isAnswerEnabled():boolean
	{
		if(this.config.viewMode == "preview" || this.config.viewMode == "view" )
		{
			return !this.showDefaultAnswer && this.answerEnabled;
		} else if(this.config.viewMode == "scoring")
		{
			return false;
		} else if(this.config.viewMode == "edit")
		{
			return false;
		} else {
			return false;
		}
	}
	
	private prepareTTSVoice():void
	{
		var voices = speechSynthesis.getVoices();
		// console.log("TTSManager", "voices", voices);
	}

	public translate(key:string):string {
		return this.service.translateService.instant(key);
	}
	
	///////// temp usage
	
	resetPage(page: ROPageComponent, saveToDataSource:boolean = true) {
		let bookID:number = this.config.book.id;
		let docID:any = page.chapter.id;
		let pageID:string = page.douid;
		page.dataComponents.forEach((c:ROComponent) => {
			c.assignAnswer(null);
		});
		// test code - need to be fixed later
		if(saveToDataSource)
			this.config.dataSource.resetPage(docID, pageID);
		page.reset();
	}

	public uploadComponentAsset(c:ROComponent):Promise<any>
	{
		var page:ROPageComponent = <ROPageComponent> c.page ;
		var chapter:any = page.chapter;
		var answerAssets:any [] = c.getAnswerAssets();
		var book:any = this.config.book;
		var share:any = this.config.share;
		var shareID:number = share? share.id : 0;
		var bookID:number = book.id;
		var docID:string = chapter.id;
		var pageID:string = page.douid;
		var uploadArray:any [] ;
		if(answerAssets && answerAssets.length)
		{
			uploadArray = answerAssets.map((asset:any)=>{
				return {
					component:{
						chapterId:docID, 
						pageId:pageID, 
						componentId:c.douid, 
					},
					ref:asset,
					share:{
						bsid:shareID,
						bookId:bookID
					}
				};
			});
		}
		
		return this.assetUploader.upload(uploadArray);
	}

	resetCorrectionData(page: ROPageComponent):any {
		let docID:any = page.chapter.id;
		let pageID:string = page.douid;
		page.reset();
		page.dataComponents.forEach((c:ROComponent) => {
			let tag:any = c.getTagNames();
			tag = tag && tag.length>0 ? tag[0] : "";
			if(c.resultObject && c.resultObject.hasOwnProperty("correction") && c.resultObject.correction) {
				c.data = null;
				this.config.dataSource.setAnswer(docID, pageID, c.douid, null, null);
				c.answerChanged = true;

			}
		});
	}

	updateChangeToDataSource(page: ROPageComponent):any {
		let docID:any = page.chapter.id;
		let pageID:string = page.douid;

		page.dataComponents.forEach((c:ROComponent) => {
			let tag:any = c.getTagNames();
			tag = tag && tag.length>0 ? tag[0] : "";
			
			let answer:string = c.data;
			let scoreResult:any = null;
			if(answer)
			{
				if(c.canVerify())
				{
					scoreResult = c.verify(true);
				} else {
					if(c.resultObject == null) c.resultObject = {correct:-1, maxScore:c.getFullScore()};
					scoreResult = c.resultObject;
				}
			} else {
				c.hideScore();
			}

			if(c.answerChanged)
			{
				if(answer)
				{
					this.config.dataSource.setAnswer(docID, pageID, c.douid, answer, scoreResult);
				} else {
					this.config.dataSource.setAnswer(docID, pageID, c.douid, null, null);
				}
			}
		});
	}

	updateAnswerToDatasource(page: ROPageComponent):any {

	}

	submitPage(page: ROPageComponent, pageScale:number):any {
		this.pageResult = {
			incorrectCount:[], 
			partialCorrectCount:[], 
			correctCount:[], 
			submitted:[],
			unfinished:[],
			count:[]
		};

		let bookID:number = this.config.book.id;
		let docID:any = page.chapter.id;
		let pageID:string = page.douid;
		let lessonID:number = 0; // todo
		let shareID:number = this.config.share ? this.config.share.id : 0;
		var index = this.config.index;
		let mode:string = "none"; // 這裡只有一般做題目的答案
		let ref_id:number = 0; // use by  mode = competitionPersonal(個人比賽模式記錄) / competitionGroup(分組比賽模式記錄)
		let list:any[] = [];
		let comChg:any[] = [];
		
		var uploadArray:any [] = [];
		var pageData = page.data;
		if(pageData)
		{
			list.push({
				type:"add", 
				tag:"Page", 
				doc:docID, 
				page:pageID, 
				com:page.douid, 
				answer:pageData, 
				result:null
			});
		}
		/**
		 * 
		 * 
		 * 
		 */
		console.log(page)
		page.dataComponents.forEach((c:ROComponent) => {
			// Update book structure (update score in each learning object)

			// ==============
			// -- Call api --
			// ==============
			
			// c.accuracyScore / c.completenessScore / c.fluencyScore
			// c.bookId = ro_book_structure->unique_id (ro_entries/c.bookId)
			// c.myData.key = cid

			let tag:any = c.getTagNames();
			tag = tag && tag.length>0 ? tag[0] : "";
			
			let answer:string = c.data;
			let scoreResult:any = null;
			if(answer)
			{
				if(c.canVerify())
				{
					scoreResult = c.verify(true);
				} else {
					if(c.resultObject == null) 
					{
						var max:number = c.getFullScore();
						c.resultObject = {correct:-1, maxScore:max};
					}
					scoreResult = c.resultObject;
				}
			} else {
				c.hideScore();
			}

			if(c.answerChanged)
			{
				if(answer)
				{
					list.push({
						type:"add", 
						tag:tag, 
						doc:docID, 
						page:pageID, 
						com:c.douid, 
						answer:answer, 
						result:scoreResult
					});
				} else {
					list.push({
						"com":c.douid, 
						"page":pageID, 
						"doc":docID, 
						"type": "remove"
					})
				}
				comChg.push(c);

				var answerAssets:any [] = c.getAnswerAssets();
				if(answerAssets && answerAssets.length)
				{
					/**
						asset {
						  	file:
						  	key:
						  	reference:
						  	index:
						}
					**/
					answerAssets.forEach((asset:any)=>{
						uploadArray.push({
							component:{
								chapterId:docID, 
								pageId:pageID, 
								componentId:c.douid, 
							},
							ref:asset,
							share:{
								bsid:shareID,
								bookId:bookID
							}
						});
					});
				}
				// this.config.dataSource.setAnswer(docID, pageID, c.douid, answer, scoreResult);
			}

			let res:any = c.resultObject;
			// console.log("answer", answer, "res", res);
			if(res) {
				if (c.hasScoring() == false) {
					this.pageResult.submitted.push(c);
				} else if (res.correct == 0) {
					this.pageResult.incorrectCount.push(c);
				} else if (res.correct == 1) {
					this.pageResult.partialCorrectCount.push(c);
				} else if (res.correct == 2) {
					this.pageResult.correctCount.push(c);
				} else if (res.correct == -1 || res.correct == 3) {
					this.pageResult.submitted.push(c);
				}
				this.pageResult.count.push(c);
			}
		});

//		page.capture(pageScale);
//		return this.pageResult;
		if(list.length && shareID!=0)
		{
			// 等 verify 更新 
			setTimeout(() => {
				page.capture(pageScale).then(answerAssets => {
					// 有答案改先 capture thumbnail 及提交
					page.state = 1;
					if(answerAssets && answerAssets.length)
					{
						answerAssets.forEach((asset:any)=>{
							uploadArray.push({
								component:{
									chapterId:docID, 
									pageId:pageID, 
									componentId:"#thumbnail#", 
								},
								ref:asset,
								share:{
									bsid:shareID,
									bookId:bookID
								}
							});
						});
					}

					let answer:string = page.data;
					let scoreResult:any = page.verify(true);
					list.push({type:"add", tag:"Page", doc:docID, page:pageID, com:"#thumbnail#", answer:answer, result:scoreResult});
					list.push({type:"add", tag:"Page", doc:docID, page:pageID, com:pageID, answer:answer, result:scoreResult});

					// call internal_submit_page
					list.push({"type":"submit","lesson_id":lessonID,"doc":docID,"page":pageID, "mode":mode,"ref_id":ref_id});
				
					// 移除指定 component record
					// list.push({"type":"remove","doc":docID,"page":pageID, "com":comID});
					// 移除指定 page record
					// list.push({"type":"remove_page","doc":docID,"page":pageID});
					// asset record
					// list.push({"type":"asset","doc":docID,"page":pageID, "com":comID,"key":"","reference":"","reference_index":0});
					
					//////////////////

					// call internal_submit_page
					

					console.log("============ answer =======================")
					//console.log(list);
					Promise.resolve(1).then(()=>{
						return this.assetUploader.upload(uploadArray, false);
					}).then(()=>{
						AppStatusComponent.callAPI('ROBookShare.save', [shareID, list, bookID, lessonID, index]).then((res:any)=>{
							if(res.code==0) {
								comChg.forEach(c => {
									c.answerChanged = false;
								});
							}
							
						}).then(()=>{
							list.forEach((o:any)=>{
								if(o.type == "add")
								{
									this.config.dataSource.setAnswer(o.doc, o.page, o.com, o.answer, o.result);
								} else if(o.type == "remove")
								{
									this.config.dataSource.setAnswer(o.doc, o.page, o.com, null, null);
								} else {
									// ignored
								}
							});
							// save into answer source
							console.log("saving current page data into answer source");
						}).catch(reason=>{
							this.service.alertService.alert("fail");
						});
			
					})
				});
			});
			
		} else {
//			this.alertService.alert("nothing to submit");
		}
		
		return this.pageResult;
	}

	public getBookAnswerAsset(chapterId:number,ref:any, showLoading:boolean = true):Promise<any> {
		var key:string = [this.config.share.id, 
			0,
			chapterId, 
			"", 
			"", 
			ref.index, 
			ref.key, 
			ref.reference, 1
		].join("/");
		return this.mruCacheProxy.fetch(key, ()=>{
			return this.service.dataService.post2(
				{
					loading:showLoading,
					data: {
						api: "ROAnswer.get_resource",
						json: [
							this.config.share.id, 
							0,
							chapterId, 
							"", 
							"", 
							ref.index, 
							ref.key, 
							ref.reference, 1
						]
					}
				}
			).then((o:any):Promise<any>=>{
				if(o && (o.code == 200||o.code == 0 )  )
				{
					return Promise.resolve("https://oka.blob.core.windows.net/media/"+o.url);
				}
				return Promise.reject(o);
			});
		})
		
	}

	public loadStudentFile(page:ROPageComponent,
//		com:ROComponent,
		com:any, // 修改令 marking 的 resource 都能夠 load
		uid:any,
		ref:any,
		resourceIndex:number = 0
		):Promise<any>
		{
			var bsid = this.config.share.id
			//var bookId = this.config.book.id;
			var chapterId = page.chapter.id;
			var pageId = page.douid;
			var componentId = com.douid;
			var key:string = [
				bsid, 
				uid,
				// bookId, 
				chapterId, 
				pageId, 
				componentId, 
				resourceIndex, 
				ref.key, 
				ref.reference, 1
			].join("/");
			return this.mruCacheProxy.fetch(key, ()=>{
				var showLoading:boolean = !page.printing;
				return this.service.dataService.post2(
					{
						data: {
							api: "ROAnswer.get_resource",
							json: [
								bsid, 
								uid,
								// bookId, 
								chapterId, 
								pageId, 
								componentId, 
								resourceIndex, 
								ref.key, 
								ref.reference, 1
							]
						},
						loading:showLoading
					}
				).then((o:any):Promise<any>=>{
					
					if(o && (o.code == 200||o.code == 0 )  )
					{
						return Promise.resolve("https://oka.blob.core.windows.net/media/"+o.url);
					}
					return Promise.reject(o);
				})
			});
			
		/*
			// bsid, uid, url, 
		this.dataService.call(
			"ROAnswer.get_resource",
			bsid, user,
			docURL, 
			pageID, 
			comID, 
			resourceIndex, 
			ref.key, ref.reference, 1
		)

		
		[
		455948,
		42,
		"item:/checksum/59AE5EB6/dirt/0/draft/0/id/2151430/len/16166/time/1674045571/uid/42/version/0/version/65535",
		"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2",
		"7667672E-985F-CBB0-9F70-2A54608852FB",
		0,
		"2a_-3BCCB69F_B785F4","63c7bb35_662ECC",
		1
		]
		*/

	}
	public loadFile(
		page:ROPageComponent,
		com:ROComponent,
		ref:any
		):Promise<any>
		{
			var bsid = this.config.share.id
			var bookId = this.config.book.id;
			var chapterId = page.chapter.id;
			var pageId = page.douid;
			var componentId = com.douid;
			var key:string = `load-file/${bsid}/${bookId}/${chapterId}/${pageId}/${componentId}`;
			return this.mruCacheProxy.fetch(key, ()=>{
				var showLoading:boolean = !page.printing;
				return this.service.dataService.post2({
					data: {
						api: "ROAnswer.get_my_resource",
						json: [
							bsid, 
							bookId, 
							chapterId, 
							pageId, 
							componentId, 
							0, 
							ref.key, 
							ref.reference, 1
						]
					},
					loading:showLoading
				}).then((o:any)=>{
					if(o && o.code == 200)
					{
						return o.url;
					}
					return Promise.reject(o);
				})
			});
		/*
			// bsid, uid, url, 
		this.dataService.call(
			"ROAnswer.get_resource",
			bsid, user,
			docURL, 
			pageID, 
			comID, 
			resourceIndex, 
			ref.key, ref.reference, 1
		)

		
		[
		455948,
		42,
		"item:/checksum/59AE5EB6/dirt/0/draft/0/id/2151430/len/16166/time/1674045571/uid/42/version/0/version/65535",
		"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2",
		"7667672E-985F-CBB0-9F70-2A54608852FB",
		0,
		"2a_-3BCCB69F_B785F4","63c7bb35_662ECC",
		1
		]
		*/

	}
	public createAssetKey():string
	{
		return GUID.create("ASSET");
	}

	private randomByte():number
	{
		var byte = Math.floor(Math.random() * 256 );
		if(byte == 256)return 255;
		return byte;
	}
	public createReference():string
	{
		return new Date().getTime().toString(16);
	}
	public randomX(len:number):string
	{
		var bytes:string [] = [];
		for(var i = 0;i < len;i++)
		{
			bytes.push(
				this.byteToHex(this.randomByte())
			);
		}
		return bytes.join("");
	}
	private byteToHex(byte:number):string
	{
		return ('0' + (byte & 0xFF).toString(16)).slice(-2);
	}

	///////////

	renderTemplate(container: HTMLElement, file: string):void{
		var id:string = /id\/([0-9]{1,})/.exec(file)[1];
		if(id)
		{
			this.service.documentService.openDocument({id:id}).then((chapter:any)=>{
				var o:any = {
					book:null,
					chapters:[chapter]
				};
				// console.log("chapter", chapter.xml);
				var roDocument:RODocument = new RODocument(o, this.service.dataService);
				var pages:any [] = roDocument.getPageNodes().map((pageNode:XMLNode)=>{
					return {
						doc:roDocument,
						pageNode:pageNode,
						attributes:pageNode.attributes
					}	
				});
				return pages;
			}).then((pages:any [])=>{
				if(pages.length)return pages[0]	;
				return Promise.reject("no page");
			}).then((pageInfo:any)=>{
				var pageNode:XMLNode = pageInfo.pageNode;
				this.createForDOM(container, pageNode, null, null);
			});
			
		}
	}
    
	/*
    lookupResource(src: string):Promise<any>{
		if(src.indexOf("http://") == 0 || src.indexOf("https://") == 0)
		{
			return Promise.resolve(src);
		}
        return this.dataService.call("Resource.lookup_array", [src]).then((o:any)=>{
            var url:string = o.mapping[0].to;
            return url;
        })
    }
	*/
	public createComponentRef(node:XMLNode, page:any):ComponentRef<any>
	{
		var definition:any = this.map.getDefinition(node.tag);
		if(!definition) return null;
		var componentRef:ComponentRef<any>= this.service.dcs.createComponentRef(
			definition,
			{
				parent:parent,
				page:page,
				node:node,
				context:this
			}
		);
		componentRef.instance.componentRef = componentRef;
		return componentRef;
	}

    public createForDOM(container:HTMLElement, node:XMLNode, page:any, parent:any):any
	{
		var definition:any = this.map.getDefinition(node.tag);
		if(!definition) return null;
        // console.log("createForEF", node.tag, definition);
        
		try{
	        var componentRef:ComponentRef<any>= this.service.dcs.createComponentRef(
                definition,
                {
					parent:parent,
					page:page,
                    node:node,
                    context:this
                }
            );
            this.service.dcs.appendVFToDOM(componentRef.hostView, container);
			componentRef.instance.componentRef = componentRef;
		    componentRef.instance.build();
			if(page && page instanceof ROPageComponent &&
				componentRef.instance instanceof ROComponent )
			{
				(<ROPageComponent>page).addToComponentsList(<ROComponent>componentRef.instance);
			}
			return componentRef.instance;
		} catch(err)
		{
			console.log(err);
			debugger;
			
		}
        

		return null;
	}

	public createForEF2(viewContainerRef:ViewContainerRef, elementRef:ElementRef, node:XMLNode, page:any, parent:any):any
    {
	    var definition:any = this.map.getDefinition(node.tag);
		if(!definition) return null;
        // console.log("createForEF", node.tag, definition);
        
		try{
			var componentRef:ComponentRef<any>= this.service.dcs.createComponentRef(
                definition,
                {
					parent:parent,
					page:page,
                    node:node,
                    context:this
                }
            );
            this.service.dcs.appendVFToEF2(viewContainerRef, componentRef.hostView, elementRef);
			componentRef.instance.componentRef = componentRef;
			componentRef.instance.build();
			if(page && page instanceof ROPageComponent &&
				componentRef.instance instanceof ROComponent )
			{
				(<ROPageComponent>page).addToComponentsList(<ROComponent>componentRef.instance);
			}

			return componentRef.instance;
		} catch(err)
		{
			console.log(err);
			debugger;
			/*
			var componentRef:ComponentRef<any>= this.service.dcs.createComponentRef(
                definition,
                {
                    node:node,
                    context:this
                }
            );
            this.service.dcs.appendVFToEF(componentRef.hostView, elementRef);
            componentRef.instance.build();

			debugger;
			*/
		}

		return null;
    }

    public createForEF(elementRef:ElementRef, node:XMLNode, page:any, parent:any):any
    {
	    var definition:any = this.map.getDefinition(node.tag);
		if(!definition) return null;
        // console.log("createForEF", node.tag, definition);
        
		try{
			var componentRef:ComponentRef<any>= this.service.dcs.createComponentRef(
                definition,
                {
					parent:parent,
					page:page,
                    node:node,
                    context:this
                }
            );
            this.service.dcs.appendVFToEF(componentRef.hostView, elementRef);
			componentRef.instance.componentRef = componentRef;
			componentRef.instance.build();
			if(page && page instanceof ROPageComponent &&
				componentRef.instance instanceof ROComponent )
			{
				(<ROPageComponent>page).addToComponentsList(<ROComponent>componentRef.instance);
			}

			return componentRef.instance;
		} catch(err)
		{
			console.log(err);
			debugger;
			/*
			var componentRef:ComponentRef<any>= this.service.dcs.createComponentRef(
                definition,
                {
                    node:node,
                    context:this
                }
            );
            this.service.dcs.appendVFToEF(componentRef.hostView, elementRef);
            componentRef.instance.build();

			debugger;
			*/
		}

		return null;
    }

    public createForVC(vc:ViewContainerRef, node:XMLNode):void
    {
        if(!vc) return;
        var definition:any = this.map.getDefinition(node.tag);
        if(definition)
        {
            var componentRef:ComponentRef<any>= this.service.dcs.createComponentRef(
                definition,
                {
                    node:node,
                    context:this
                }
            );
            componentRef.instance.componentRef = componentRef;
            vc.insert(componentRef.hostView);
            componentRef.instance.build();
        }
    }
}
   
export class ResourceSource
{
	constructor(protected dataService:DataService, protected fileIO:FileIOService)
	{

	}
	lookupResource(src: string):Promise<any>{

		if(src.indexOf("http://") == 0 || src.indexOf("https://") == 0)
			return Promise.resolve(src);

		// QB tmp url
		var lookup:boolean = false;
		if(src.indexOf("tmp_upload/")==0)
		{
			lookup = false;
		} else if(src.indexOf("graphics/") == 0)
		{
			lookup = true;
		} else if(src.indexOf("OKG://") == 0)
		{
			lookup = true;
		} else {
			lookup = false;
		}
		if(lookup == false) 
			return Promise.resolve(this.fileIO.getResourceServer(src)+src);
		// if(src.indexOf("tmp_upload/")==0 || src.indexOf("OKG://") == -1)
		// 	

        return this.dataService.call("Resource.lookup_array", [src]).then((o:any)=>{
            var url:string = o.mapping[0].to;
			if(url.indexOf("http://") == 0)
				url = url.substring(5);
			else if(url.indexOf("https://") == 0)
				url = url.substring(6);
            return url;
        });
    }
}

export class CacheResourceSource
{
	private caller:DelayCaller;
	private callProxy:MRUCacheProxy;
	constructor(protected dataService:DataService, protected fileIO:FileIOService)
	{
		this.callProxy = new MRUCacheProxy(100);
		this.caller = new DelayCaller((list:any [])=>{
			var urlArray:string [] = list.map((obj)=>{
				return obj.input;
			});
			return this.dataService.post2({
				data: {
					api: "Resource.lookup_array",
					json: [urlArray, true]
				},
				loading:false
			}).then((o:any)=>{
				list.forEach((o2:any, inputIndex:number)=>{

					o.mapping.forEach((temp:any, index:number)=>{
						if(temp.from == o2.input)
						{
							o2.output = temp.to;
						}
					});
				});
			});
		}, 200);

	}
	lookupResource(src: string):Promise<any>
	{
		if(!src) return Promise.reject("not defined");
		if(src.indexOf("http://") == 0 || src.indexOf("https://") == 0)
			return Promise.resolve(src);

		// QB tmp url
		var lookup:boolean = false;
		if(src.indexOf("tmp_upload/")==0)
		{
			lookup = false;
		} else if(src.indexOf("graphics/") == 0)
		{
			lookup = true;
		} else if(src.indexOf("OKG://") == 0)
		{
			lookup = true;
		} else {
			lookup = false;
		}
		if(lookup == false) 
			return Promise.resolve(this.fileIO.getResourceServer(src)+src);
		// if(src.indexOf("tmp_upload/")==0 || src.indexOf("OKG://") == -1)
		// 	return Promise.resolve(this.fileIO.getResourceServer(src)+src);
		
		return this.callProxy.fetch(src, ()=>{
			return this.caller.append({
				input:src
			}).then((obj:any)=>{
				if(obj.output) return obj.output;
				return Promise.reject(obj);
			}).then((data:any)=>{
				return new Promise((resolve)=>{
					setTimeout(()=>{
						resolve(data);
					}, 10);
				});
			});
		});
	}
}


export class ROAnswerAssetUpload{
	constructor(private dataService:DataService, private uls:UploadService)
	{

	}

	public upload(assets:any [], showLoading:boolean = true):Promise<any>
	{
		if(!assets)return Promise.resolve(null);
		return PromiseUtils.reduce(
			assets, 
			(prev:any, uploadObject:any, index:number, reference:any [])=>{
				/*
				component:{
					chapterId:docID, 
					pageId:pageID, 
					componentId:c.douid, 
				},
				ref:asset,
				share:{
					bsid:shareID,
					book_id:bookID
				}
				*/
				return this.uploadAsset(
					uploadObject.share,
					uploadObject.component,
					uploadObject.ref,
					uploadObject.ref.file,
					showLoading
				);
			}, 
			null
		).then((o:any)=>{
			return null;
		})
	}


	
	/**
	 * 
	 * @param share // bsid:any, bookId:any, 
	 * @param component // {chapterId, pageId, componentId};
	 * @param record // {index, key:"", reference:""};
	 * @param file 
	 * @returns 
	 */
	public uploadAsset(share:any, component:any, record:any, file:any, showLoading:boolean = true):Promise<any> 
	{
		return this.uls.uploadFileObject(file, showLoading).then((asset)=>{
			return this.moveAssetToResourceServer(
				share,
				component, 
				record, 
				asset,
				showLoading
			).then((success2)=>{
				console.log("move to blob success", success2);
				return success2.url;
			});
		});
	}

	private moveAssetToResourceServer(
		share:any, // {bsid, bookId}
		component:any,// {chapterId, pageId, componentId};
		record:any, // {index, key:"", reference:""};
		asset:any // asset token
		, showLoading:boolean = true
	):Promise<any> {
		return this.dataService.post2({
			data: { 
				api: 'ROAnswer.submit_resource3', 
				json:[
					share.bsid, share.bookId, 
					component.chapterId, component.pageId, component.componentId, 
					record.index,
					record.key, record.reference,
					asset
				]
			},loading: showLoading
		});
	}
	
}

/*
var db = new VocabDictionaryDatabase();
db.init().then((instance:any)=>{
	db.fetchRows("")
})
*/

export class VocabDictionaryDatabase
{
	public db:any;
	public initPromise:Promise<any>;
	public ready:boolean;
	init():Promise<any>
	{
		if(this.ready)return Promise.resolve(1);
		this.initPromise = Promise.all(
			[
				this.loadSQLJS(),
				this.loadDatabaseFile()
			]
		).then((list)=>{
			var SQL:any = list[0];
			var uint8View = list[1];
			this.db = new SQL.Database(uint8View);
			this.ready = true;
			this.initPromise = null;
			return this;
		});
		return this.initPromise;
	}

	fetchRow(query:string, parameters:any [] = null):any
	{
		var element:any;
		const stmt = this.db.prepare(query);
		if(parameters) stmt.bind(parameters);
		if(stmt.step()) element = stmt.getAsObject();
		stmt.free();
		return element;
	}

	fetchRows(query:string, parameters:any [] = null):any []
	{
		const stmt = this.db.prepare(query);
		if(parameters) stmt.bind(parameters);
		var list = [];
		while (stmt.step())  list.push(stmt.getAsObject());
		stmt.free();
		return list;
	}

	loadSQLJS()
	{
		return ScriptLoader.load(
			"assets/js/sqljs/sql-wasm.js", 
			"initSqlJs"
		).then((fn)=>{
			return fn({
				locateFile: (file) => {
					return "assets/js/sqljs/sql-wasm.wasm";
				}
			});
			
		})
	}
	loadDatabaseFile():Promise<any>
	{
		return ZipUtils.loadURLAsBlob("assets/ro/vocab_dictionary.zip").then((blob:any)=>{
			return blob.arrayBuffer();
		}).then((buffer:any)=>{
			// console.log("database buffer", buffer);
			var uint8View = new Uint8Array(buffer);
			return uint8View;
		});
	}

}
////////
class ZipUtils
{
	static loadURLAsJSON(url:string):Promise<any>
	{
		return ZipUtils.loadURLAsType(url, "blob").then((blob:any)=>{
			return blob.text();
		}).then((text:string)=>{
			return JSON.parse(text);
		});
	}
	static loadURL(url:string):Promise<any>
	{
		return new ByteLoader().load("GET", url).then((buffer:ArrayBuffer)=>{
			const zip = new JSZip();
			return zip.loadAsync(buffer);
		})
	}

	static loadURLAsFile(url:string):Promise<any>
	{
		return ZipUtils.loadURL(url).then((zipFile:any)=>{
			var fileNames:string [] = []
			for(var key in zipFile.files)
			{
				fileNames.push(key);
			}
			var firstFileName:string = fileNames[0];
			var file = zipFile.files[firstFileName];
			return file;
		});
	}

	static loadURLAsType(url:string, type:string):Promise<any>
	{
		return ZipUtils.loadURLAsFile(url).then((file:any)=>{
			return file.async(type);
		});
	}

	static loadURLAsBlob(url:string):Promise<any>
	{
		return ZipUtils.loadURLAsFile(url).then((file:any)=>{
			return file.async("blob");
		});
	}
	
	static loadURLAsText(url:string):Promise<any>
	{
		return ZipUtils.loadURLAsFile(url).then((file:any)=>{
			return file.async("text");
		});
	}
}


// new SQLLiteLoader().init();
class SQLLiteLoader
{
	constructor()
	{

	}
	init():Promise<any>
	{
		return ScriptLoader.load("assets/js/sqljs/sql-wasm.js", "initSqlJs" ).then((fn)=>{
			return fn({
				locateFile: file => "assets/js/sqljs/sql-wasm.wasm"
			});
		})
	}
}

export class ROClipboardService
{
	private referenceComponents:ROComponent[];
	private operation:string;
	private counter:number = 0;

	public hasReferenceComponents():boolean
	{
		return this.referenceComponents && this.referenceComponents.length > 0;
	}

	clearOperation():void
	{
		this.operation = null;
		this.referenceComponents = null;
	}

	copy(components:ROComponent [])
	{
		this.operation = "copy";
		this.referenceComponents = components;
		this.counter = 0;
	}

	cut(components:ROComponent [])
	{
		this.operation = "cut";
		this.referenceComponents = components;
		this.counter = 0;
		components.forEach((component:ROComponent)=>{
			if(component.parent)
			{
				component.parent.removeComponent(component);
			}
		});
	}
	
	paste(layer:ROContainerComponent, samePlace:boolean = false):ROComponent []
	{
		if(!(this.referenceComponents && this.operation)) return [];
		
		if(this.operation == "copy")
		{
			this.counter++;
			return this.copyTo(this.referenceComponents, layer, samePlace);
		} else if(this.operation == "cut")
		{
			var components:ROComponent[]= this.cutAndPasteTo(this.referenceComponents, layer);
			this.clearOperation();
			return components;
		}
	}
	removeComponents(components:ROComponent[]):void
	{
		components.forEach((com:ROComponent)=>{
			if(com.parent)
			{
				com.parent.removeComponent(com);
				com.detach();
			}
		})
	}

	cutAndPasteTo(components:ROComponent[], to:ROContainerComponent):ROComponent[]
	{
		var canAddComponent:boolean = true;
		components.forEach((com:ROComponent)=>{
			if(!to.canAddComponent(com)) canAddComponent = false;
		});
		if(!canAddComponent) return [];
		components.forEach((com:ROComponent)=>{
			to.addComponent(com);
			com.reattach();
		});
		return components;
	}

	copyTo(components:ROComponent[], to:ROContainerComponent, samePlace:boolean):ROComponent[]
	{
		var offset:number = samePlace ? 0 : this.counter * 10;
		var clones:ROComponent [] = components.map((com)=>{
			return com.clone();
		});
		var canAddComponent:boolean = true;
		clones.forEach((com:ROComponent)=>{
			if(!to.canAddComponent(com)) canAddComponent = false;
		});
		if(!canAddComponent) return [];
		clones.forEach((com:ROComponent)=>{
			to.addComponent(com);
			if(offset) {
				com.moveBy(offset, offset);
				if(to instanceof ROPageComponent)
				{
					var moved:boolean = false;
					if(com.x + com.w > to.w)
					{
						com.x = to.w - com.w;
						moved = true;
					}
					if(com.y + com.h > to.h)
					{
						com.y = to.h - com.h;
						moved = true;
					}
					if(moved) com.updatePosition();
				}
				com.saveCoordinateExpression();
			}
			com.reattach();
		});
		return clones;
	}

	
}


@Injectable({ providedIn: 'root' })
export class RODataSourceService
{
	constructor(public dataService:DataService, )
	{
		

	}

	public loadAllStudentData(shareID, bookID, uidArray, showLoading:boolean = true):Promise<any>
	{
		return this.dataService.post2({data:{
			// api:'ROBookShare.fetch_student_page_answers_by_index',
			api:'ROBookShare.fetch_all_student_page_answers',
			json:[
				shareID, bookID, uidArray, null, null
			]
		}, loading: showLoading}).then((response:any)=>{
			if(! 
				(response.code == 0 || response.code == 200)
			)
			{
				return Promise.reject(response);
			}
			return response;
		})
	}
	
	
	public submssionToDataSoureManager(submissions:any []):any
	{
		var dataSourceManager = new ROStudentDataSourceManager();
		
		submissions.forEach((e1) => {
			if(typeof(e1.index) == "string") e1.index = parseInt(e1.index);
			if(typeof(e1.uid) == "string") e1.uid = parseInt(e1.uid);			
			e1.records.forEach(e2 => {
				if(typeof(e2.active) == "string") e2.active = parseInt(e2.active);
				e2.result = JSON.parse(e2.result);
			});
		});
		
		submissions.forEach((e1) => {
			if(e1.index == 1)
			{
				var student:any = {
					uid:e1.uid
				};
				// var index:number = e1.index;
				var source:AnswerSource = dataSourceManager.getSource(1, student);
				var source2:AnswerSource = dataSourceManager.getSource(2, student);
				e1.records.forEach(e2 => {
					if(!e2.active) return;
					
					// var r:any;
					// e2.result = JSON.parse(e2.result);
					// find section e2 from page
					// 
					// e2.question = this.findComponentSection(e2, questionMap);
					// r = this.findDataSourceObject(1, e2.bid, e2.pid, e1.uid);
					// r.records.push(e2);
					source.setAnswer(e2.bid, e2.pid, e2.cid, e2.data, e2.result);
					// 第一次批改不需要合拼入改正記錄
					if(e2.tag != "#marking#") {
					
						//r = this.findDataSourceObject(2, e2.bid, e2.pid, e1.uid);
						// r.records.push(e2);
						source2.setAnswer(e2.bid, e2.pid, e2.cid, e2.data, e2.result);
					}
				});

				if(e1.markings && e1.markings.length)
				{
					this.fillStickerMarkingData(dataSourceManager, 1, e1.markings);
				}
			}
		});

		
		submissions.forEach((e1) => {
			if(e1.index == 2)
			{
				var student:any = {
					uid:e1.uid
				};
				// var index:number = e1.index;
				var source:AnswerSource = dataSourceManager.getSource(2, student);
				e1.records.forEach(e2 => {
					if(!e2.active)
					{
						// need to remove records that has been added to dataSource
						// source.remove( page, component)
						// 
						return;
					}
					
					// e2.question = this.findComponentSection(e2, questionMap);
					// let r:any = this.findDataSourceObject(index, e2.bid, e2.pid, e1.uid);
					// r.records.push(e2);
					source.setAnswer(e2.bid, e2.pid, e2.cid, e2.data, e2.result);
				});

				if(e1.markings)
				 	this.fillStickerMarkingData(dataSourceManager, 2, e1.markings);
			}
		});
		return dataSourceManager;
	}

	private fillStickerMarkingData(dataSourceManager:ROStudentDataSourceManager, index:number, data:any[]):void {
		// group 返同人同頁 data
		var grp:any={};
		data.forEach(m => {
			var key:string = `${m.uid}/${m.doc_id}/${m.pid}`;
			if(!grp.hasOwnProperty(key))
				grp[key] = {uid:m.uid, doc:m.doc_id, pid:m.pid, data:[]};
			m.id = parseInt(m.id);
			grp[key].data.push(m);
		});
		for(let key in grp) {
			let g = grp[key];
			g.data.sort((a, b) => a.id - b.id);
			dataSourceManager.getSource(index, {uid:g.uid}).setMarking(g.doc, g.pid, g.data);
		}
	}

}

@Injectable({ providedIn: 'root' })
export class ROBookReviewService
{
	private elements:any  []
	public waiting:boolean;
	// public dirty:boolean;
	subscription: any;
	constructor(
		public dataService:DataService, 
		public unloadService:WindowUnloadService,
	)
	{
		this.elements = [];
		// this.init()

	}
	/*
	init()
	{
		this.subscription = new Subscription(()=>{})
		
		window.addEventListener('popstate', (event:any)=>{
			if(this.dirty)
			{
				alert("please wait a little more");
				this.trigger();
			}
		});
		
		
		this.subscription.add(
			merge(
				fromEvent(window, "beforeunload"),
				fromEvent(window, "popstate")
			).subscribe((event:any)=>{
			if(this.dirty)
			{
				event.preventDefault();
				event.returnValue = 'stop';
				// if(event.type == "popstate") alert("please wait a little more");
				this.trigger();
			}
		}));
	}
	*/
	public save(shareID:number,  bookID:number, uid:number,  index:number,
		chapterID:string, pageID:string,  componentID:string, 
		data, newResult, tag):Promise<any>
	{
		var element:any = {
			bsid:shareID,
			book_id:bookID,
			uid:uid,
			index:index
		};
		this.elements.push(element);
		this.wait();
		
		return AppStatusComponent.callAPI('ROBookShare.update_student_result_by_index', [shareID,  bookID, uid,  index,
			chapterID, pageID,  componentID, 
			data, newResult, tag, false]);
		/*return this.dataService.call(
			"ROBookShare.update_student_result_by_index",
			shareID,  bookID, uid,  index,
			chapterID, pageID,  componentID, 
			data, newResult, tag, false
		);*/
	}
	/*
	public triggerIfNecessary():Promise<any>
	{
		return this.unloadService.unfinishedBusinessService.completeUnfinishedBusiness();
	}
	*/
	private wait():void
	{
		if(!this.waiting)
		{
			this.waiting = true;
			var task = this.unloadService.unfinishedBusinessService.add(this.trigger, this);
			this.unloadService.idleService.wait().subscribe(()=>{
				this.waiting = false;
				task.run();
			});
		}
	}

	public get dirty():boolean
	{
		// return false;
		return this.unloadService.unfinishedBusinessService.hasUnfinishedBusiness();
	}
	private saving:boolean;
	private trigger():Promise<any>
	{
		var currentElements:any [] = this.elements;
		var map:any = {};
		currentElements = currentElements.filter((element:any)=>{
			var key:string = `${element.bsid}-${element.book_id}-${element.uid}-${element.index}`;
			if(map.hasOwnProperty(key))
			{
				return false;
			} else {
				map[key] = 1;
				return true;
			}
		});
		this.elements = [];
		if(currentElements.length)
		{
			this.saving = true;
			return AppStatusComponent.callAPI("ROBookShare.update_student_summary", [currentElements]).then((res:any)=>{
				
				this.saving = false;
			}).catch(reason=>{
				this.saving = false;
			});
		} else {
			if(this.saving == false) {
				return Promise.resolve("ready");
			} else {
				return Promise.reject("saving");
			}
		}
	}
	/*
	public updateStudentSummary():Promise<any>
	{
		var index:any = this.context.config.index;
		var shareID:any = this.context.config.share.id;
		var bookID:any = this.context.config.book.id;
		var uidArray = [];
		return this.context.service.dataService.call(
			"ROBookShare.update_student_summary",
			shareID,  bookID, uidArray,  index,
		);
	}
	*/
}

