import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AssetService {
	public assetsUrl:string;
	public imgUrl:string;
	constructor() {
		this.initAssetUrl();
	}

	initAssetUrl(){
		let app_base_href:string = document.querySelector("base").getAttribute("href");
		this.assetsUrl = app_base_href+'assets/';
		this.imgUrl = this.assetsUrl + 'img/';
	}

	getPath(filename:string):string{
		filename = filename.replace('[blob]', '//oka.blob.core.windows.net/media');
		let url:string = filename.indexOf("//") == -1 ? this.assetsUrl + filename : filename;
		return url;
	}
}
