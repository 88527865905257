import { Injectable } from '@angular/core';
import { CommonOldService } from './common-old.service';
import { MiniModal } from './module/shared/mini.modal';
@Injectable()

export class ModalService {
  public modalList: any[] = [];

  constructor(public commonOldService: CommonOldService) { }

  create(obj: any) {
    this.modalList.push(obj);
  }

  close(index = -1) {
    if (index == -1)
      this.modalList.pop();
    else
      this.modalList.splice(index, 1);
  }

  alert(msg, callback = () => { }) {
    let obj = {
      comp: MiniModal, data: {
        message: msg, type: 'alert',
        close: () => {
          callback();
          this.close();
        }
      }, hideHeader: true
    };
    this.modalList.push(obj);
  }

  confirm(msg, callback) {
    let obj = {
      comp: MiniModal,
      data: { message: msg, type: 'confirm', close: () => { this.close(); }, confirm: callback },
      hideHeader: true
    };
    this.modalList.push(obj);
  }

  error(msg) {
    let obj = { comp: MiniModal, data: { message: msg, type: 'alert', close: () => { this.close() } }, hideHeader: true };
    this.modalList.push(obj);
  }

}
