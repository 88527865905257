import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { ChatResponse, Message } from './interface/chatResponse';
// import { EditResponse } from './interface/editResponse';
// import { ImageResponse } from './interface/imageResponse';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from '../sharedModule/loadingModule/loading.service';
// import { OpenAIClient, AzureKeyCredential } from "@azure/openai";
export interface ChatResponse {
	choices?: MessageBox[];
	created?: number;
	id?: string;
	model?: string;
	object?: string;
	usage?: Usage;
}

export interface MessageBox {
	finish_reason?: string;
	index?: number;
	message?: Message;
}

/*
example
    messages=[
        {"role": "system", "content": "Assistant is a large language model trained by OpenAI."},
        {"role": "user", "content": "Who were the founders of Microsoft?"}
    ]
*/
export interface Message {
	content?: string;
	role?: string;
}

export interface Usage {
	completion_tokens?: number;
	prompt_tokens?: number;
	total_tokens?: number;
}
@Injectable({
	providedIn: 'root'
})
export class ChatService {

	constructor(private http: HttpClient, private datas:DataService, private lds: LoadingService) { }

	public async chat2(conversation: Message[]){
		let res = await this.datas.post2({data: { api:'ROChatGPT.chat', json:[conversation] }, loading:true});
		return res;

	}

	public async oralChat(conversation: Message[], config?: string):Promise<any>{
		const hostname = 'dev.openknowledge.hk';
		const url = '//' + hostname + '/RainbowOne/index.php/PHPGateway/proxy/2.8';
		let json: any[] = [conversation];
		if(config){
			json.push(config);
		}
		this.lds.add("oralchat");
		let res = await this.datas.post2({data:{api:'ROChatGPT.chat', json:[conversation, "EP_AI_ENG_CHAT"]}});
		this.lds.remove("oralchat");
		return res;
			
	}

	public async oralChatQueue(conversation: Message[]){
		let res = await this.datas.post2({data: { api:'ROChatGPT.oral_chat_queue', json:[conversation] }, loading:true});
		return res;

	}


}
