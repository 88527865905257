
export class LineGeneralization{
	// =======================================
	// simplify function
	// =======================================
	
	// this function is called by simplifyLang
	public simplifyLang(lookAhead:number, tolerance:number, points:any[]):any[]{
		
		if ( lookAhead <= 1 || points.length < 3)
				return points;
		
		var nP:any[] = [];
		var offset:number;
		var len:number;
		var count:number;
		len= points.length;
		
		if (lookAhead > len - 1) {
			lookAhead = len - 1;
		}
		nP.push(points[0]);
		
		count = 1;
		for (var i:number = 0; i < len; i++)
		{
			if (i + lookAhead > len) {
				lookAhead = len - i -1
			};
			
			offset = this.recursiveToleranceBar(points, i, lookAhead, tolerance);
							
			if(offset>0 && points[i+offset]){
				nP.push(points[i + offset]);
				i += offset-1;// don't loop through the skipped points
				count++;
			}
		}
		
		nP.push(points[len - 1]);
		return nP;
	}
	
	private recursiveToleranceBar(points:any[], i:number, lookAhead:number, tolerance:number):number{
		
		var n:number = lookAhead;
		var cP:any, cLP:any, v1:any, v2:any, angle:number, dx:number, dy:number;
		var lH:number
		cP = points[i];// current point
		
		
		if(i+n >= points.length){
			return 0;
		}
		
		// the vector through the current point and the max look ahead point
		v1 = {x:points[i+n].x - cP.x, y:points[i+n].y - cP.y};
		// loop through the intermediate points
		
		
		
		for(var j:number = 1;  j <= n; j++){
			// the vector	through the current point and the current intermediate point
			cLP = points[i+j]; // current look ahead point
			v2 = {x: cLP.x - cP.x, y:cLP.y - cP.y};
			angle = Math.acos((v1.x * v2.x + v1.y * v2.y)/(Math.sqrt(v1.y * v1.y + v1.x * v1.x)*Math.sqrt(v2.y * v2.y + v2.x * v2.x)));
			if(isNaN(angle)){angle = 0;}
			// the hypothenuse is the line between the current point and the current intermediate point
			dx = cP.x - cLP.x; dy = cP.y - cLP.y;
			lH = Math.sqrt(dx*dx+dy*dy);// lenght of hypothenuse

			
			// length of opposite leg / perpendicular offset 	
			if( Math.sin(angle) * lH >= tolerance){// too long, exceeds tolerance
				n--;
				if(n>0){// back the vector up one point
					//trace('== recursion, new lookAhead '+n);
					return this.recursiveToleranceBar(points, i, n, tolerance);
				}else{
					//trace('== return 0, all exceed tolerance');
					return 0;// all intermediate points exceed tolerance
				}
				
			}
			
		}
		
		
		return n;
	}

		
}