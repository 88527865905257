import { Component, ElementRef } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
    selector:"popup-select-renderer",
    template:``
})


export class PopupSelectRenderer {
	public currentValue:any;
	public params: any;

	constructor(private elRef:ElementRef) {}

	// ag grid call
	// ag grid edit start
	agInit(params: ICellEditorParams): void {
		this.params = params;
			
		this.currentValue = params.value;
		if(params.colDef.cellEditorParams.hasOwnProperty("openSelector")) {
			params.colDef.cellEditorParams.openSelector(params.eGridCell, this.elRef.nativeElement, params.value.concat()).then((result:any[])=>{
				//console.log("更新，使用新值 >>", result);
				this.currentValue = result;
				this.params.api.stopEditing();
			}).catch((reason)=>{
				// 取消，用回原值
				//console.log("取消，用回原值 >>", reason);
				this.params.api.stopEditing();
			});
		}
	}

	// ag grid call
	getValue(): any {
		return this.currentValue;
	}

	// ag grid call
	// 要有這個才可 popup 在面
	isPopup(): boolean {
        return true;
    }
}
