import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraCaptureComponent } from './CameraCapture.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
    imports: [
        FontAwesomeModule,CommonModule
    ],
    declarations: [
        CameraCaptureComponent
    ],
    exports: [
        CameraCaptureComponent
    ],
    providers: [
    ],
    entryComponents: [CameraCaptureComponent]
})
export class CameraCaptureModule {
}
