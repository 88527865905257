import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // ngModel 需要
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AiOralPracticeComponent } from './ai-oral-practice.component';
import { Modal2Module } from '../modal2Module/modal2.module';
// import { WindowModule } from '../../coreModule/HomeScreenModule/windowModule/window.module';
// import { WindowModule } from 'src/app/coreModule/HomeScreenModule/windowModule/window.module';
import { ThreadModule } from '../threadModule/thread.module';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
@NgModule({
    imports: [
		FontAwesomeModule,
		CommonModule,
		TranslateModule,
		FormsModule, 
		Modal2Module,
		ThreadModule, BubbleBox2Module
    ],
    declarations: [
        AiOralPracticeComponent
    ],
    exports: [
        AiOralPracticeComponent
    ],
    providers: [],
})
export class AiOralPracticeModule { }
