import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent, interval, timer } from 'rxjs';
import { ThemeService } from 'src/app/service/theme.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { faFile, faBars, IconDefinition, faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';
import { delay, takeUntil } from 'rxjs/operators';

// import { faFile, faBars, faTrash, faGripDots, faImage, faFilm, faListMusic, IconDefinition, faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'componentMenuBar',
	template: `
	<!--  [backgroundColor]="'var(--section-maincolor)'" -->
<bubbleBox2
	#bubbleBox2
	padding="0"
	[targetMargin]="10"
	[autoTailColor]="false"
	[autoClose]="autoClose" 
	[backgroundColor]="backgroundColor" 
	[boxShadow]="themeService.getStyleValue('wpopup-shadow') || '0px 5px 30px rgba(0,0,0,0.3)'"
>
	<div class="content">
		<ng-container *ngFor="let itm of menuItems">
			<ng-container *ngIf="itm && (itm.enabled === undefined ||  itm.enabled)">
				<div *ngIf="itm.type=='complete'" class="complete" (click)="process($event, itm, null)">
					<fa-icon [icon]="itm.icon"></fa-icon>
					<div *ngIf="menuItems.length==1">{{itm.name}}</div>
				</div>
				<div *ngIf="itm.type=='name'" class="name" [style.backgroundColor]="backgroundColor2">
					<span *ngIf="itm.translate === undefined || itm.translate">{{itm.name | translate}}</span>
					<span *ngIf="itm.translate === false">{{itm.name}}</span>
				</div>
				<fa-icon *ngIf="itm.type=='btn'" [icon]="itm.icon" [ngStyle]="itm.iconStyle" (click)="menuClick($event, itm)"></fa-icon>
				<div 
					*ngIf="itm.type=='action'"  
					class="inner-item action" 
					[class.icon]="itm.icon"
					(click)="process($event, itm, null)">
					<fa-icon class="icon" *ngIf="itm.icon" [icon]="itm.icon" [ngStyle]="itm.iconStyle" ></fa-icon>
					<div *ngIf="itm.text" class="button-name">{{itm.text}}</div>
					<div *ngIf="itm.name" class="action-name">{{itm.name}}</div>
				</div>
				<div 
					class="inner-item number" *ngIf="itm.type=='number'">
					<div class="option-name">{{itm.name}}: </div>
					<fa-icon class="small-icon-btn" [icon]="minusIcon" 
					
					(pointerdown)="buttonDown($event, itm, '-', itm.value)"
					></fa-icon>
					<!-- 
					(click)="process($event, itm, '-', itm.value)"
					(click)="process($event, itm, '-', itm.value)" -->
					<input class="value" 
					type="number" 
					min="item.min ? item.min  : ''"
					step="itm.step ? item.step : 1"
					max="item.max ? item.max  : ''"
					[(ngModel)]="itm.newValue" 
					(change)="process(null, itm, 'update', itm.newValue)"
					>
					<!-- 
					
					// (ngModelChange)="process(null, itm, 'update', $event)"-->
					<fa-icon class="small-icon-btn" [icon]="plusIcon" 
						(pointerdown)="buttonDown($event, itm, '+', itm.value)"
						></fa-icon>
					<!-- (click)="process($event, itm, '+', itm.value)" -->
				</div>

				<div 
					class="inner-item options" *ngIf="itm.type=='options'"
					(click)="process($event, itm, 'select')"
					>
					<div class="section-name">{{itm.name}}: </div>
					<div class="options-label-container">
						<ng-container *ngFor="let o of itm.options">
							<span class="label" *ngIf="o.selected">
								{{o.valueLabel ? o.valueLabel: o.label}}
							</span>
						</ng-container>
					</div>
					<div class="selector-icon"></div>
				</div>
				<div 
					class="inner-item boolean" *ngIf="itm.type=='boolean'"
					>
					<div class="section-name">{{itm.name}}: </div>
					<ui-switch 
						class="useCssVar" 
						[checked]="itm.value"
						color="#FCB422" 
						defaultBgColor="#003834"
						(valueChange)="process($event, itm, 'toggle')"
						></ui-switch>
				</div>
			</ng-container>
			
		</ng-container>

		
	</div>
</bubbleBox2>
	`,
	styleUrls: ['./componentMenuBar.component.scss']
})

export class ComponentMenuBarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	public icon1:IconDefinition = faFile;
	public icon2:IconDefinition = faBars;
	public plusIcon:IconDefinition = faPlus;
	public minusIcon:IconDefinition = faMinus;
	
	@Input() autoClose:boolean = true;
	@Input() target:HTMLElement;
	@Input() positionTarget:any;
	@Input() positionUpdateCount:number = 0;
	@Input() backgroundColor = '#3D901E';
	@Input() backgroundColor2 = 'var(--selector-bg-color)';
	@ViewChild('bubbleBox2', {static:false}) public bubbleBox2:BubbleBox2Component;
	@Input() public menuItems:any[] = [];
	@Output() public onMenuClick:EventEmitter<any> = new EventEmitter();
	public _curData:any = null;
	private _selectedItem:any;
	public bubbleBox2BgColor:string = "#FFF";
	@Output() public emitter:EventEmitter<any> = new EventEmitter();
	// public position:any = "top";
	public position:any = "top";
	@Input() reference:any = null;

	constructor(public translate:TranslateService, public themeService:ThemeService) {
	}
	
	
	ngOnInit(): void {
		this.emitter.emit({type:"onInit"});
	}

	ngAfterViewInit(): void {
		if(this.target)
		{
			setTimeout(()=>{
				if(this.positionTarget) {
					this.position = "bar";
					this.autoClose = false;
					this.open(this.positionTarget, null);
				} else {
					// this.position = "top";
					this.position = "top";
					this.autoClose = false;
					this.open(this.target, null);
				}
				
			}, 10);
		}
	}
	ngOnChanges(changes: SimpleChanges): void {
		if(this.bubbleBox2 && (changes.positionUpdateCount || changes.menuItems))
		{
			var target:HTMLElement = this.bubbleBox2.getCurrentTargetElement();
			if(target)
			{
				this.bubbleBox2.updatePosition();
			}
		}
	}

	ngOnDestroy(): void {
		this.bubbleBox2.close();
	}
	
	@Input() set showSelector(value:boolean)
	{
		if(this.bubbleBox2) this.bubbleBox2.show = value;
	}
	public getSelectedItem():any {
		return this._selectedItem;
	}

	public waitUntilReady():Promise<any> {
		if(this.bubbleBox2)
			return Promise.resolve('ready');
		return new Promise((resolve:Function)=>{
			var sub:Subscription = this.emitter.subscribe(()=>{
				sub.unsubscribe();
				resolve('ready');
			})
		})
	}

	public open(targetEle:HTMLElement, data:any=null):Promise<any> {
		//this.position = "top";
		this.bubbleBox2.position = this.position;
		this.emitter.emit({type:"open"});
		this._curData = data;
		return this.bubbleBox2.open(targetEle).catch((e)=>{});
	}
	/*
	public onStepperChanged(event:any, item:any, parameter:any):void
	{
		if(parameter)
		{
			var newValue:number = item.value; 
			if(parameter == "+") newValue += item.step;
			else if(parameter == "-") newValue -= item.step;
			newValue = parseFloat(newValue.toFixed(item.dp));
			if(newValue > item.max) newValue = item.max;
			else if(newValue < item.min) newValue = item.min;
			this.emitter.emit({type:"process", event:event, item:item, parameter:newValue});
		}
	}
	*/
	buttonDown(event:any, item:any, parameter:any, value:any = null)
	{
		const source = interval(100);
		const timer$ = fromEvent(document.body, "pointerup");
		this.process(event, item, parameter, value);
		source.pipe(delay(500)).pipe(takeUntil(timer$)).subscribe((o:any)=>{
			this.process(event, item, parameter, value);
		})
	}
	public process(event:any, item:any, parameter:any, value:any = null):void {
		this.emitter.emit({
			type:"process", 
			event:event, 
			item:item, 
			parameter:parameter,
			value:value,
			reference:this.reference
		});
		if (item.fn) item.fn(event, item, parameter);
	}
	public menuClick(event:any, menuItem:any):void {
		this._selectedItem = menuItem;
		if (menuItem.click) menuItem.click(this._curData);
		this.onMenuClick.emit(menuItem);
		this.bubbleBox2.close();
	}
}
