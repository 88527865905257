import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ms',
})

export class MinuteSecondPipe implements PipeTransform {
  transform(sec: number) {
    let output = '';
    if (sec < 60) {
      output = Math.round(sec) + '"';
    } else {
      output = Math.floor(sec / 60) + "'" + Math.round(sec % 60) + '"';
    }
    return output;
  }
}
