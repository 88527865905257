// import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DOMDirective } from './DomDirective';
import { LongPressDirective } from './LongPressDirective';
import { NativeDragDropDirective } from './NativeDragDropDirective';
import { DragToScrollDirective } from './DragToScrollDirective';
import { DragToMoveDirective } from './DragToMoveDirective';
import { VirtualScrollGridViewDirective } from './VirtualScrollGridViewStrategy';
import { ContainRequired } from './ContainRequired.directive';

@NgModule({
    imports: [
    ],
    declarations: [
		LongPressDirective,
		DOMDirective,
		NativeDragDropDirective,
		DragToScrollDirective,
		DragToMoveDirective, 
		VirtualScrollGridViewDirective, ContainRequired
    ],
	entryComponents: [
	],
    providers: [
       
    ],
    exports: [
		LongPressDirective,
		DOMDirective,
		NativeDragDropDirective,
		DragToScrollDirective,
		DragToMoveDirective, 
		VirtualScrollGridViewDirective, ContainRequired
    ],
	schemas:[CUSTOM_ELEMENTS_SCHEMA]
})

///////////////////////////////////////////////////////////////////////////////////////////////////
export class DirectiveModule {
    constructor() {}
}
