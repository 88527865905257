import { ByteArray } from "openfl";

export class TimeColorStroke 
{
	pts:any [];
	// public timeDict:any;
	constructor(
		public type:number, 
		public color:number, 
		public thickness:number, 
		alpha:Number = 1)
	{
		this.thickness = thickness;
		this.pts = [];
		// sm = new SmoothDrawing();
		// this.timeDict = {};
		/*
		ui = new MovieClip();
		ui.graphics.lineStyle(thickness, color);
		ui.alpha = alpha;
		ui.ref = {
			typeID: typeID,
			color: color,
			thickness: thickness,
			pts: pts
		};
		*/
	}
	
	build() {
		
	}
	public getLatestTime():number
	{
		return this.pts.length ? this.pts[this.pts.length - 1].time : 0;
	}
	static fromBytes(typeID:number, ba:ByteArray):TimeColorStroke
	{
		var color:number = ba.readUnsignedInt(); // ARGB
		var alpha:number = ((color >> 24) & 0xff) / 255;
		var thickness:number = ba.readFloat(); // thickness
		var len:number = ba.readUnsignedInt(); // total points
		var ms:TimeColorStroke = new TimeColorStroke(typeID, color & 0xffffff, thickness, alpha);
		for (var i:number = 0; i < len; i++)
		{
			var p:any = {
				x:ba.readFloat(),
				y:ba.readFloat(),
				time:ba.readUnsignedInt()
			};
			ms.pts.push(p);
			// ms.timeDict[p] = ba.readUnsignedInt();
		}
		ms.build();
		return ms;
	}

	// DATA_TYPE_EPEN (1B)			ARGB (4B), thickness (4B), total points (4B), [x (4B), y (4B), time (4B), ...]
	public writeBytes(ba:ByteArray):void 
	{
		var len:number = this.pts.length;
		if (len > 1)
		{
			ba.writeByte(this.type); // type ID
			// ba.writeUnsignedInt(this.color | (int(ui.alpha*255)<<24)); // ARGB
			ba.writeUnsignedInt(this.color | 0xFF000000);
			ba.writeFloat(this.thickness); // thickness
			ba.writeUnsignedInt(len);// total points
			
			for (var i:number = 0; i < len; i++)
			{
				var pt:any = this.pts[i];
				ba.writeFloat(pt.x);
				ba.writeFloat(pt.y);
				ba.writeUnsignedInt(pt.time);
			}
		}
	}

}

