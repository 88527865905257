import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { FileIOService } from "src/app/service/FileIO.service";
import { QBComponentBase } from "./QBComponentBase";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";


@Component({
	template:`
	<div class="q scroll-container">
		<div class="qContainer ">
			
			<div class="qnumCol flex-row-left">
				<div class="flex-column-center">
					<div class="qnum">
						{{qObject.questionIndex+1}}
					</div>
				
					<!-- speaker tts icon -->
					<!--
					<div  *ngIf="!qObject.isPageComponent" class="speaker" (click)="speechQ(qObject.question)"></div>
					-->
				</div>


				<div class="qcontent">
					
					<QText [qObj]="qObject" ></QText>
					<div class="recorder" [ngClass]="{disable:disableAction}">
						<div class="btn playStopBtn" 
							*ngIf="inState(['play and rec'])" 
							(click)="playSnd()">
							<div class="arrow-right"></div>
							</div>
						<div class="btn playStopBtn" 
							*ngIf="inState(['playing','recording'])" 
							(click)="stopSnd()">
							<div class="stop"></div>
						</div>
						<div class="btn recBtn" 
							*ngIf="inState(['play and rec','rec'])" 
							(click)="recSnd()">
							<div class="redDot"></div></div>
						<div class="time" *ngIf="inState(['playing', 'recording'])">
							{{currentState.positionText}}
						</div>
						<div class="duration" *ngIf="inState(['playing'])">
							/{{getDurationText()}}
						</div>
					</div>
				
				</div>
			</div>
		</div>

		<div class="score">
					
			<!--
				<Scoring [displayType]="'ai'" 
				(onChange)="updateScore($event)" 
				*ngIf="isSubmitted() && qObject.type!='QBInfoBlock' && isDisplayAIScore()"
				[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
				[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
				[componentName]="getText('componentName.'+qObject.type)"></Scoring>
				<Scoring (onChange)="updateScore($event)" *ngIf="isSubmitted(i) && qObject.type!='QBInfoBlock' && isDisplayTeacherScore(i)"
				[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
				[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
				[componentName]="getText('componentName.'+qObject.type)"></Scoring>
			-->
		</div>

	</div>



	
		
		
`,
styleUrls:[
	"./QBComponentQuestionBase.scss",
	"./QBRecorder.scss"
],
	styles:[`
	.recorder {
		width:246px;
		height:60px;
		background-color: #1D5C98;
		box-shadow: #8CB0D4 0px 2px 0px 0px inset;
		border-radius: 10px;
		border: 1px solid #000;
		display:flex;
		flex-direction: row;
		align-items: center;
		position: relative;

		.btn {
			position: absolute;
			height: 38px;
			width: 38px;
			align-items: center;
			border-radius: 50%;
			border: 2px solid #0B3554;
			text-align:center;
			display:flex;

			div {
				margin-left: auto;
				margin-right: auto;
			}
		}
		.playStopBtn {
			margin-left:13px;
			background-color: #5AB8D8;
			box-shadow: #ADDCEC 0px 2px 0px 0px inset;

			.arrow-right {
				width: 0; 
				height: 0; 
				border-top: 9px solid transparent;
				border-bottom: 9px solid transparent;
				border-left: 15px solid #ffffff;
			}

			.pause {
				width: 16px;
				height: 15px;
				border-left: 6px solid #ffffff;
				border-right: 6px solid #ffffff;
			}

			.stop {
				width: 0;
				height: 12px;
				border-left: 12px solid #ffffff;
			}
		}
		.recBtn {
			margin-left:60px;
			background-color: #E0DEDE;
			box-shadow: #ffffff 0px 2px 0px 0px inset;

			.redDot {
				width:18px;
				height:18px;
				background-color: #C24945;
				border-radius: 50%;
			}
		}
		.time, .duration {
			position: absolute;
			top:10px;
			font-size:18px;
			font-weight: bold;

		}
		.time{
			left:130px;
			color: #fff;
		}
		.duration{
			left:179px;
			color: #17CEEE;
		}
	}

	
	`]
})
export class QBRecorder extends QBComponentQuestionBase
{
	public inEdit:boolean = false;
	public inEditMode:boolean=false;
	public qVar:any;
	public currentState:any = {state:'rec', duration:0};
	protected sndChannel = new Audio();
	public disableAction:boolean = false;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, public fileio:FileIOService)
	{
		super(cdr, elementRef, fileio);
	}

	public hasAnswer()
	{
		return this.currentState && this.currentState.duration > 0;
	}

	public canVerify(): boolean {
		return false;
	}

	canEdit()
	{
		return false;
	}

	getDurationText()
	{
		if(!this.currentState) return "--:--";
		var o = this.currentState;
		return o.duration==0 ? "--:--" : this.toTimeText(Math.round(o.duration));
	}

	inState(array:string[])
	{
		return array.indexOf(this.currentState.state) != -1;
	}

	stopSnd()
	{
		if(this.currentState.state == "recording") {
			this.currentState.state = "converting";
			this.fileio.stopRecord();

		} else {
			this.sndChannel.pause();
			this.currentState.state = "play and rec";

			if(this.currentState.timer) {
				clearInterval(this.currentState.timer);
				delete this.currentState.timer;
			}
		}
	}
	recSnd()
	{
		let qVar:any = this.currentState;
		// this.markQChange(qVar);

		qVar.state = "recording";
		qVar.duration = 0;
		qVar.file = null;
		qVar.position = 0;
		let timer = setInterval(() => {
			qVar.position++;
			qVar.positionText = this.toTimeText(qVar.position);
		}, 1000);
		
		this.fileio.record().then((success:any)=> {
			qVar.duration = success.duration;
			qVar.url = null;
			qVar.file = success.file;
			qVar.state = "play and rec";
			qVar.position = 0;
			qVar.positionText = "00:00";
			clearInterval(timer);

		},(reason:any) => {
			qVar.state = reason.msg;
			clearInterval(timer);
		});
	}
	public playSnd():void {
		let qVar:any = this.currentState;
		qVar.state = "playing";

		qVar.positionText = "00:00";
		qVar.timer = setInterval(() => {
			qVar.positionText = this.toTimeText(Math.floor(this.sndChannel.currentTime));
			if(qVar.duration==0 && this.sndChannel.duration)
				qVar.duration = this.sndChannel.duration;
		}, 100);

		if(qVar.file || qVar.url) {
			this.sndChannel.src = qVar.url ? this.fileio.getResourceServer(qVar.url)+qVar.url : URL.createObjectURL(qVar.file);
			this.sndChannel.controls = false;
			this.sndChannel.addEventListener('ended', ()=> {
				this.sndChannel.pause();
				qVar.state = "play and rec";

				if(qVar.timer) {
					clearInterval(qVar.timer);
					delete qVar.timer;
				}
			});
			this.sndChannel.play();
		}
	}

	protected toTimeText(num:number):string {
		return this.toTwoDigi(Math.floor(num/60))+":"+this.toTwoDigi(num%60);
	}
	protected toTwoDigi(num:number):string {
		return num<10 ? "0"+num : num.toString();
	}
	public getTagNames():string []
	{
		return ["QBRecorder"];
	}

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
	}
	set data(input:string)
	{
		if(input)
		{
			
		} else {

		}
	}

	get data():string
	{
		if(this.hasAnswer())
		{
			var qObj:any = this.qObject;
			var qVar = this.currentState;
			let asset:any = qObj.type == "QBRecorder" ? {url:qVar.url, duration:qVar.duration} : {url:qVar.url};
			return (qVar.file ? "file data" : JSON.stringify({assets:[asset]}));	
		} else {
			return null;
		}
		
	}
	
	protected getResultObjectAndAnswer(qIndex:number):any {
		let qObj:any = this.qObject;
		let qVar:any = this.currentState;
		let totalAns:number = 1;
		let correct:number = 0;
		let wrong:number = 0;
		let answerForSubmit:any;


//			if(qVar.file || qVar.url)
			correct = -1;
		let asset:any = qObj.type == "QBRecorder" ? {url:qVar.url, duration:qVar.duration} : {url:qVar.url};
		answerForSubmit = (qVar.file ? "file data" : JSON.stringify({assets:[asset]}));
			
		
//		if(correct==0 && wrong==0)
//			return null; // 無答過
		return {
			answerForSubmit:answerForSubmit,
			correct:correct,
			wrong:wrong,
			totalAns:totalAns,
			result:this.getResultObject(qObj, correct, wrong, totalAns)
		};
	}

}