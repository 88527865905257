import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateTimePickerComponent } from './dateTimePicker.component';
import { DateTimePickerSelectTimeComponent } from './dateTimePickerSelectTime.component';
import { DateTimePickerSelectDateComponent } from './dateTimePickerSelectDate.component';
import { DateTimePickerSelectMonthComponent } from './dateTimePickerSelectMonth.component';
import { BobbleBoxComponent } from './bobbleBox.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        DateTimePickerComponent,
        DateTimePickerSelectTimeComponent,
        DateTimePickerSelectDateComponent,
        DateTimePickerSelectMonthComponent,
        BobbleBoxComponent
    ],
    exports: [
        DateTimePickerComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class DateTimePickerModule {
}
