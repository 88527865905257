import { Component, Input, Output, ViewChild, EventEmitter, OnInit, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';

@Component({
    selector: 'collection-modal',
    template: `
    <div [ngClass]="['collection-modal', maxColumn == 1?'single':'multi']" [style.width]="modalWidth">
      <div class="modal-header">
        <div class="modal-header__title">
          <span>{{data.name}}</span>
        </div>
        <a class="modal-header__close" (click)="cancelModal()">{{'commonService.confirm'|translate}}</a>
      </div>
      <div class="modal-body" #body>
        <section *ngFor="let col of columns;let i=index" [attr.level]="i">
          <div class="wrapper">
            <div [ngClass]="['item', item.type, item.isSelected || selectedBookId == item.id?'selected':'']" *ngFor="let item of col.children" (click)="itemClick(item)">
			  <div class="icon-wrapper">
				<svg-icon [name]="getSvgiconName(item)"></svg-icon>
			  </div>			
              <div class="item__name">{{item.title}}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  `
    , styleUrls: ['./collection.modal.scss'],
})

export class CollectionModal implements OnInit {
    @Input() data: any;
    public modalStyle;
    public modalWidth;
    public columnWidth;
    public headerColor;
    private firstColPos = 0;
    public maxColumn: number = 0;
    public columns: any[] = [];
    public root;
    private moveFirstColumn$;
    public selectedBookId;
    @ViewChild('body', { static: false }) body;
    constructor(private datas: DataService, private zone: NgZone, private sanitizer: DomSanitizer, private lds: LoadingService) {
    }

    ngOnInit() {
        this.root = this.data.info.entries[0];
        this.initWidth();
        this.initData();
    }

    private initData() {
        this.columns[0] = this.root;
    }

    private initWidth() {
        this.getTotalColumns(this.root, 0);
        this.columnWidth = 284;
        if (this.maxColumn >= 3) {
            this.modalWidth = (284 * 3 + 86) + 'px';
        } else if (this.maxColumn == 2) {
            this.modalWidth = (284 * 2) + 'px';
        } else {
            this.columnWidth = 400;
            this.modalWidth = '400px';
        }
    }

    private getTotalColumns(entry, cur) {
        this.maxColumn = Math.max(this.maxColumn, cur);
        if (entry.children) {
            entry.children.forEach(obj => {
                obj.isSelected = false;
                obj.level = cur;
                this.getTotalColumns(obj, cur + 1);
            });
        }
    }

    public itemClick(item, forceOpen = 0) {
        let body = this.body.nativeElement;
        this.firstColPos = Math.ceil(body.scrollLeft / this.columnWidth);
        console.log('start:fc=' + this.firstColPos + ';lv=' + item.level);
        this.columns.forEach((v, i) => {
            if (i > item.level)
                v.isSelected = false;
        });
        if (item.type == 'collection') {
            item.isSelected = true;
            item.children.sort((a, b) => {
                let aKey = (a.type == 'collection' ? 1 : 2) + a.title;
                let bKey = (b.type == 'collection' ? 1 : 2) + b.title;
                if (aKey > bKey) return 1;
                if (aKey < bKey) return -1;
                return 0;
            });
        }
        if (item.type != 'collection') {
            item.isSelected = true;
            this.selectedBookId = item.id;
			//open book
			this.data.book_id = item.id;
			this.data.entryType = item.type;
			this.data.type = item.type;
			this.lds.add('collection-load');
			this.data.close(this.data);
        } else if (this.columns.length == item.level + 1) {
            console.log('case2');
            this.columns.push(item);
            if (item.level >= this.firstColPos + 2) {
                let target = body.scrollLeft + 286;
                this.firstColPos = this.firstColPos + 1
                console.log('case2a:tar=' + target);
                setTimeout(() => { this.moveFirstColumn(target, 0); }, 100);
            }
        } else if (item == this.columns[item.level + 1]) {
            console.log('case3');
            console.log('fc=' + this.firstColPos + 'lv=' + item.level);
            if (this.firstColPos < item.level - 1) {
                console.log('case3a');
                let target = body.scrollLeft + 286;
                this.firstColPos += 1;
                setTimeout(() => { this.moveFirstColumn(target, 0); }, 100);
            } else if (this.firstColPos > 0) {
                console.log('case3b');
                let target = body.scrollLeft - 286;
                this.firstColPos = this.firstColPos - 1;
                let kill = this.columns.length - item.level - 2;
                setTimeout(() => { this.moveFirstColumn(target, kill); }, 100);
            } else if (item.level == 0) {
                console.log('case3c');
                this.columns.splice(1, this.columns.length, item);
            } else if (this.columns.length > item.level + 2) {
                console.log('case3d');
                this.columns.splice(item.level + 2, this.columns.length);
            }
        } else {
            console.log('case4');
            if (this.columns[1] != item)
                this.columns[1].isSelected = false;
            if (body.scrollLeft > 0) {
                console.log('case4a');
                setTimeout(() => { this.moveFirstColumn(0, 0); }, 10);
                setTimeout(() => {
                    let len = this.columns.length;
                    this.columns.splice(item.level + 1, len, item);
                }, 500);
            } else {
                console.log('case4b');
                let len = this.columns.length;
                this.columns.splice(item.level + 1, len, item);
            }

        }
    }

    moveFirstColumn(target, kill) {
        clearTimeout(this.moveFirstColumn$);
        let body = this.body.nativeElement;
        // let body = document.querySelector('body');
        let step = body.scrollLeft < target ? 16 : -16;
        let swap = body.scrollLeft;
        body.scrollLeft = body.scrollLeft + step;
        if (Math.abs(body.scrollLeft - target) <= 16 && body.scrollLeft != target) {
            body.scrollLeft = target;
            this.columns.splice(this.columns.length - kill, kill);
        } else if (body.scrollLeft != target && swap != body.scrollLeft) {
            this.moveFirstColumn$ = setTimeout(() => {
                this.moveFirstColumn(target, kill);
            }, 10);
        }

    }

    public saveModal() {
        return true;
    }

    public confirmModal() {
        if (this.data.confirm) {
            this.data.confirm();
        }
        this.data.close();
    }

    public cancelModal() {
        this.data.close();	
        return true;
    }

	getSvgiconName(item){
		if (item.type == 'collection'){
			return 'icon_folder_blue';
		} else if (item.type == 'pdf'){
			return 'fileicon2_pdf';
		} else if (item.type == 'audio'){
			return 'fileicon2_aduio';
		} else if (item.type == 'word'){
			return 'fileicon2_doc';
		} else if (item.type == 'image'){
			return 'fileicon2_others';
		} else if (item.type == 'ppt'){
			return 'fileicon2_ppt';
		} else if (item.type == 'assessment'){
			return 'fileicon2_qb';
		} else if (item.type == 'video'){
			return 'fileicon2_video';
		} else if (item.type == 'excel'){
			return 'fileicon2_xls';																											
		} else {
			return 'icon_book';
		}
	}
}
