import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Injectable, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
// import { AlertComponent, PromptDialogComponent, SelectionComponent, SelectionComponent2 } from '../sharedModule/alertModule/alert.component';
// import { DynamicComponentService } from './dynamicComponent.service';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { Modal2Component } from '../modal2Module/modal2.component';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { LinkedListNode, MRUCache, SimpleLinkedList } from 'src/app/common/LinkedList';

@Component({
	selector:"ro-browser",
    template: `

		<div class="full-screen" >
			<div class="header">
				<div class="space"></div>
				<div class="button open-icon" (click)="openExternal()"></div>
				<div class="button reload-icon" (click)="refresh()"></div>
				<div class="button close-icon" (click)="closeDialog()"></div>
			</div>
			<div class="error-warning" [class.has-error]="errorWarning" >
				<div [innerHtml]="errorWarning">
				</div>
			</div>
			<div class="iframe-container"></div>
		</div>
    `,
	styles:[
		`	
			
			.here{
				text-decoration: underline;
				color:#22406B;
			}
			.error-warning{
				transition: height 0.5s;
				flex: none;
				height:0px;
				padding-left:15px;
				padding-right:15px;
				background-color:#FFB951;
				line-height:40px;
				font-size:13px;
				color:#000000;
				overflow:hidden;
			}

			.error-warning.has-error{
				height:40px;
			}

			.full-screen{
				display:flex;
				-webkit-flex-direction: column;
				flex-direction: column;
				
				position:absolute;
				left:0px;
				top:0px;
				right:0px;
				bottom:0px;
				z-index:999999;
			}
			.header{
				flex: none;
				display:flex;
				background-color:#45556D;
				height:40px;
			}
			
			.iframe-container{
				width:100%;
				height:100%;
				background-color:#ECECEC;
			}

			.iframe-container{
				width:100%;
				height:100%;
				background-color:#ECECEC;
			}

			.iframe-container > *{
				border: none;
			}

			.iframe{
				position:absolute;
				left:0px;
				right:0px;
				top:40px;
				bottom:0px;
				border: none;
			}
			.button{
				width:40px;
				height:40px;
			}
			.button:active{
				transform:translate(0px, 1px);
			}
			.button.close-icon{
				background-image:url(./assets/img/component/browser-icon/close-icon.svg);
			}
			.button.reload-icon{
				background-image:url(./assets/img/component/browser-icon/reload-icon.svg);
			}
			.button.open-icon{
				background-image:url(./assets/img/component/browser-icon/open-icon.svg);
			}
			.space{
				margin-left:auto;
			}
		`
	]
})
export class ROBrowserComopnent implements AfterViewInit
{
	// @ViewChild('modal', {static: true}) public modal:Modal2Component;
	@Input() public safeUrl:SafeResourceUrl;
	@Input() public url:string;
	@Output() emitter:EventEmitter<any>= new EventEmitter();
	public errorWarning:string;
	constructor(
		private translateService:TranslateService, 
		private elementRef:ElementRef, 
		private browserService:ROBrowserService
	)
	{

	}

	private getIFrame():any
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		return dom.querySelector("iframe");
	}

	private initIframe():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom = dom.querySelector(".iframe-container");
		dom.innerHTML = `<iframe class="iframe" frameborder="0" src="${this.url}" width="100%" height="100%"></iframe>`;
	}

	ngAfterViewInit(): void {
		this.initIframe();
		this.browserService.canShowIframe(this.url).then(
			null,
			(reason)=>{
				this.setErrorWarning();
			}
		);
		
	}

	private setErrorWarning():void
	{
		this.errorWarning = this.translateService.instant("ro.page.x_iframe_option_warning", {url:this.url});
		console.log(this.errorWarning);
		// this.errorWarning = this.translateService.instant("ro.page.test", {abc:this.url});
		// If you cannot see the website below, click <a class="here" [attr.href]="url">here</a> to open external browser
		// 	如你未能攪到以下內容，請<a class="here" [attr.href]="url">按此</a>打開外部視窗
			
	}

	openExternal():void
	{
		window.open(this.url, "_blank");
	}

	refresh():void
	{
		var frame = this.getIFrame();
		if(frame)
		{
			frame.src += "";
		}
	}

	closeDialog():void
	{
		// this.modal.close();
		this.emitter.complete();
	}

	
}

// ///

@Injectable({ providedIn: 'root' })
export class ROBrowserService {
	private _cache:MRUCache;
	constructor(
		private dataService:DataService, private domSanitizer: DomSanitizer,private dcs:DynamicComponentService, private translate:TranslateService
	)
	{
		this._cache = new MRUCache(20);
    }

	canShowIframe(url:string):Promise<any>
	{
		var data:any = this._cache.getCache(url);
		if(data)
		{
			if(data.valid) return Promise.resolve(data);
			return Promise.reject(data);
		}

		return this.dataService.call("ROWebTool.get_url_headers", url).then((o:any)=>{
			if(o.code == 200)
			{
				var headers:any = o.headers;
				if(headers.hasOwnProperty("x-frame-options"))
				{
					var data = {valid:0};
					this._cache.addCache(url, data);
					return Promise.reject(data);
				} else {
					var data = {valid:1};
					this._cache.addCache(url, data);
					return Promise.resolve(data);
				}
			} else {
				var data = {valid:0};
				this._cache.addCache(url, data);
				return Promise.reject(data);
			}
		});
		/*
		return this.dataService.call("ROWebTool.check_iframe", url).then((o:any)=>{
			if(o && o.code == 200)
			{
				var data = {valid:1};
				this._cache.addCache(url, data);
				return Promise.resolve(data);
			} else {
				var data = {valid:0};
				this._cache.addCache(url, data);
				return Promise.reject(data);
			}
		});
		*/
	}

	public createFullScreenBrowser(url:string):ROBrowserComopnent
	{
		// //dev.openknowledge.hk/RainbowOne/index.php/transport/Master/get_file/1?file=resource/2021/03/15/uid(54202)/time(134830)-cs(B7DAA9F0).mp4
		// var sampleURL:any = this.domSanitizer.bypassSecurityTrustResourceUrl("https://hk.news.yahoo.com/");
		// var sampleURL:SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
		// var sampleURL:SafeResourceUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
		// https://hk.news.yahoo.com/?guccounter=1
		// var browser:any = this.dcs.create(ROBrowserComopnent, {url:url});
		var safeURL:SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
		var browser:any = this.dcs.create(ROBrowserComopnent, {safeUrl:safeURL, url:url});
		var browserInstance:ROBrowserComopnent = browser.compRef.instance;
		this.dcs.open(browser, document.body);
		var sub:Subscription = browserInstance.emitter.subscribe({
			next:(param)=>{
				
			},
			error:()=>{
				this.dcs.destroy(browser);
				sub.unsubscribe();
			},
			complete:()=>{
				this.dcs.destroy(browser);
				sub.unsubscribe();
			}
		})
		return browserInstance;
	}
	/*
	public createBrowserDialog(url:string):ROBrowserComopnent
	{
		// //dev.openknowledge.hk/RainbowOne/index.php/transport/Master/get_file/1?file=resource/2021/03/15/uid(54202)/time(134830)-cs(B7DAA9F0).mp4
		// var sampleURL:any = this.domSanitizer.bypassSecurityTrustResourceUrl("https://hk.news.yahoo.com/");
		
		var sampleURL:SafeResourceUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
		// var sampleURL:SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
		// https://hk.news.yahoo.com/?guccounter=1
		// var browser:any = this.dcs.create(ROBrowserComopnent, {url:url});
		var browser:any = this.dcs.create(ROBrowserComopnent, {safeUrl:sampleURL});
		var browserInstance:ROBrowserComopnent = browser.compRef.instance;
		this.dcs.open(browser, document.body);
		return browserInstance;
	}
	*/
}
