import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { faCheckCircle, faCircleMinus } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';

@Component({
	selector: "checkbox-header",
	template: `
	<div class="container" *ngIf="status == 'all'" (click)="clickSubject.next('none')">
		<fa-icon  class="checked" [icon]="faCheckCircle" ></fa-icon>
	</div>
	<div class="container" *ngIf="status == 'none'" (click)="clickSubject.next('all')">
		<fa-icon [icon]="faCheckCircleLight" ></fa-icon>
	</div>
	<div class="container" *ngIf="status == 'some'" (click)="clickSubject.next('none')">
		<fa-icon  class="minus" [icon]="faCircleMinus"></fa-icon>
	</div>
    `,
	styleUrls: ['./checkbox.header.scss']
})


export class CheckBoxHeader implements AfterViewInit {
	public params: any;
	public faCheckCircle = faCheckCircle;
	public faCircleMinus = faCircleMinus;
	public faCheckCircleLight = faCheckCircleLight;
	public clickSubject = new Subject();
	public status = 'all';
	ngAfterViewInit() {
		this.clickSubject.pipe(debounceTime(100)).subscribe(e => {
			let api = this.params.api;
			if (e == 'all') {
				this.status = 'all';
				api.selectAllFiltered();
			} else {
				this.status = 'none';
				api.deselectAll();
			}
			api.refreshCells();
		});
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	agInit(params: ICellEditorParams): void {
		this.params = params;
		let api:any = params.api;
		let selectedRows = api.getSelectedRows();
		let filteredRows = 0;
		if (api.clientSideRowModel){
			api.forEachNodeAfterFilter(node => {
				if(node.selectable)
					filteredRows++;
			});
		} else {
			let displayedRow = api.getDisplayedRowCount();
			console.log(displayedRow);
			console.log('not work in serverSide rowModel since not sure all rows are checked');
		}
		if (selectedRows.length == 0) {
			this.status = 'none';
		} else if (selectedRows.length == filteredRows){
			this.status = 'all';
		} else {
			this.status = 'some';
		}
	}

	refresh(params: ICellEditorParams) {
		return true;
	}


}
