import { ByteArray, Endian } from "openfl";

class Randomizer 
{
	static  zr:number = 362436069;
	static  wr:number = 521288629;
	// list:any [int>;
	buf:number [];

	constructor (private list:number[])
	{
		this.buf = [];
	}
	
	 next():number
	{
		if (this.list.length == 0)
		{
			if (this.buf.length == 0)
				return 0;
			
			this.list = Randomizer.randomIntVector(this.buf);
			this.buf = [];
		}
		else
			this.buf.push(this.list.shift());
		return this.current();
	}
	
	 current():number
	{
		return this.list.length > 0 ?  this.list[0] : 0;
	}
	
	static  createRandomSequence(length:number):Randomizer
	{
		Randomizer.setRandomSeed(Math.floor(Math.random() * 1000000000));
		return new Randomizer(Randomizer.randomSequence(length));
	}
	
	/**
	 * 設定隨機數的 seed 值
	 *
	 * @param seed 隨機數的 seed 值
	 * @langversion ActionScript 3.0
	 * @playerversion Flash 9.0
	 */
	static  setRandomSeed(seed:number):void
	{
		Randomizer.zr = 362436069 ^ seed;
		Randomizer.wr = 521288629 + seed;
	}

	/**
	 * 取得隨機整數
	 *
	 * @param num 機數整數範圍需小於的值
	 * @return 隨機整數(0 至 num-1)
	 * @langversion ActionScript 3.0
	 * @playerversion Flash 9.0
	 */
	static  random(num:number):number
	{
		return (
			(
				(
					(
						Randomizer.zr = 36969 * 
						(Math.floor(Randomizer.zr) & 65535) + (Randomizer.zr >> 16)) ^ 
						(Randomizer.wr = 18000 * (Randomizer.wr & 65535)+
						(Randomizer.wr >> 16)
					)
				)
			) & 0x7fffffff
		) %num;
	}
		
	/**
	 * 將陣列隨機排列
	 *
	 * @param ary 要隨機排列的陣列
	 * @return 隨機排列的陣列
	 * @langversion ActionScript 3.0
	 * @playerversion Flash 9.0
	 */
	static  randomArray(ary:any[]):any[] {
		ary = ary.concat();
		
		var out:any[] = [];
		while(ary.length) {
			out.push(ary.splice(this.random(ary.length),1)[0]);
		}
		return out;
	}
	
	static  randomIntVector(ary:any []):any [] {
		ary = ary.concat();
		
		var out:any [] = [];
		while(ary.length)
			out.push(ary.splice(this.random(ary.length),1)[0]);
		return out;
	}

	/**
	 * 建立隨機排列數序陣列
	 *
	 * @param length 數序長度
	 * @return 隨機排列數序陣列
	 * @langversion ActionScript 3.0
	 * @playerversion Flash 9.0
	 */
	static  randomSequence(length:number):number[]{
		var ary:number[] = [];
		for (var i:number = 0; i < length; i++)
			ary.push(i);
		
		return Randomizer.randomIntVector(ary);
		/*var out:any [int> = new any [int>();
		while (ary.length)
			out.push(ary.splice(random(ary.length), 1)[0]);
		return out;*/
	}
	
	static randomSequence2(length:number):any [] {
		var index1:number;
		var index2:number;
		var i:number;
		var temp:number;
		var ary:any [] = [];
		for (i = 0; i < length; i++)
		{
			ary.push(i);
		}
		for (i = 0; i < length; i++)
		{
			index1 = Randomizer.random(length);
			index2 = Randomizer.random(length);
			if (index1 != index2)
			{
				temp = ary[index1];
				ary[index1] = ary[index2];
				ary[index2] = temp;
			}
		}
		return ary;
	}

}


export class LookUpTable 
{
	static encrypt(seed:number, bytes:ByteArray):ByteArray
	{
		Randomizer.setRandomSeed(seed);
		var table:any [] = Randomizer.randomSequence(256);
		
		var out:ByteArray = new ByteArray();
		out.writeInt(seed);
		
		bytes.position = 0;
		//bytes.deflate();
		var length:number = bytes.bytesAvailable;
		for (var j:number = 0; j < length; j++)
		{
			out.writeByte(table[bytes.readUnsignedByte()]);
		}
		return out;
	}
	
	static decrypt(bytes:ByteArray):ByteArray
	{
		bytes.position = 0;
		Randomizer.setRandomSeed(bytes.readInt());
		var encryptTable:any [] = Randomizer.randomSequence(256);
		var table:any [] = [];
		for (var i:number = 255; i >= 0; i--)
		{
			table[encryptTable[i]] = i;
		}
		
		var out:ByteArray = new ByteArray();
		var length:number = bytes.bytesAvailable;
		for (var j:number = 0; j < length; j++)
		{
			out.writeByte(table[bytes.readUnsignedByte()]);
		}
		return out;
	}
	
	
	static encrypt2(seed:number, bytes:ByteArray):ByteArray
	{
		Randomizer.setRandomSeed(seed);
		var table:any [] = Randomizer.randomSequence2(256);
		
		var out:ByteArray = new ByteArray();
		out.writeInt(seed);
		
		bytes.position = 0;
		//bytes.deflate();
		var length:number = bytes.bytesAvailable;
		for (var j:number = 0; j < length; j++)
		// while ( bytes.bytesAvailable )
		{
			out.writeByte(table[bytes.readUnsignedByte()]);
		}
		
		return out;
	}
	
	static decrypt2(bytes:ByteArray):ByteArray
	{
		bytes.position = 0;
		Randomizer.setRandomSeed(bytes.readInt());
		var encryptTable:any [] = Randomizer.randomSequence2(256);
		var table:any [] = [];
		for (var i:number = 255; i >= 0; i--)
		{
			table[encryptTable[i]] = i;
		}
		
		var out:ByteArray = new ByteArray();
		out.endian = Endian.BIG_ENDIAN;
		var length:number = bytes.bytesAvailable;
		for (var j:number = 0; j < length; j++)
		{
			out.writeByte(table[bytes.readUnsignedByte()]);
		}
		return out;
	}
}
