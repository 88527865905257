import { NgModule } from '@angular/core';
import { SelectionPanelComponent } from './selectionPanel.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
		TranslateModule,
		FontAwesomeModule
    ],
    declarations: [
        SelectionPanelComponent
    ],
    exports: [
        SelectionPanelComponent
    ],
    providers: [],
    bootstrap: []
})
export class SelectionPanelModule { }
