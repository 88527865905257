import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input, ElementRef, TemplateRef, ViewChildren, QueryList, SimpleChanges, AfterViewChecked, Directive, OnChanges } from '@angular/core';
//import { DomSanitizer } from '@angular/platform-browser';
//import { DataService } from 'src/app/service/data.service';
//import { environment } from 'src/environments/environment';
//import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
//import { ThemeService } from 'src/app/service/theme.service';
//import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';

//import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';

// =======================================
// icon
// =======================================
import { faCamera, faImage, faBars, faTrash, faPen, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';

//import { StudentListCellComponent } from './studentListCell.component';
//import moment from 'moment';
//import { RowNode } from 'ag-grid-community';
//import { ArrayUtils } from 'src/app/common/ArrayUtils';
//import { AgGridAngular } from 'ag-grid-angular';
import { FileIOService } from 'src/app/service/FileIO.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ROSimpleDragLine } from '../roBookModule/ROSimpleDragLine';
import { XMLNode } from '../roBookModule/xml/XMLNode';
import { AssessmentViewerComponent } from './AssessmentViewer.component';
import { ScriptService } from 'src/app/service/script.service';
import { CustomQuillEditorComponent } from '../customQuillEditorModule/customQuillEditor.component';
import { DataService } from 'src/app/service/data.service';


@Directive({
	selector: '[textareaAutoResize]'
})
export class AutoResizeDirective implements AfterViewChecked {
	constructor(private elementRef:ElementRef) {
	}

	ngAfterViewChecked(): void {
		var style:any = this.elementRef.nativeElement.style;
		style.height = 0;
		style.height = (this.elementRef.nativeElement.scrollHeight) + "px"; // auto size
	}
}

@Component({
	selector: 'OKDQB',
	templateUrl: './OKDQB.component.html',
	styleUrls: ['./OKDQB.component.scss'],
})

export class OKDQBComponent  {
	@ViewChild('QBInfoBlock', {static:true}) public qbInfoBlock:TemplateRef<any>;
	@ViewChild('QBTextMC', {static:true}) public qbTextMC:TemplateRef<any>;
	@ViewChild('QBRecorder', {static:true}) public qbRecorder:TemplateRef<any>;
	@ViewChild('QBTakePhoto', {static:true}) public qbTakePhoto:TemplateRef<any>;
	@ViewChild('QBShortAnswer', {static:true}) public qbShortAnswer:TemplateRef<any>;
	@ViewChild('QBLongQuestion', {static:true}) public qbLongQuestion:TemplateRef<any>;
	@ViewChild('QBFillingBlank', {static:true}) public qbFillingBlank:TemplateRef<any>;
	@ViewChild('QBToggleOptions', {static:true}) public qbToggleOptions:TemplateRef<any>;
	@ViewChild('PageCom', {static:true}) public pageCom:TemplateRef<any>;
	@ViewChild('QBTrueFalse', {static:true}) public qbTrueFalse:TemplateRef<any>;
	
	@ViewChildren(CustomQuillEditorComponent) customQuillEditors: QueryList<CustomQuillEditorComponent>;

	@Input() public viewMode:string = "view";
	@Input() public qObj:any;
	@Output() public change:EventEmitter<any> = new EventEmitter<any>();

	@Input() public qSize:number;
	@Input() public scoreSize:number;
	@Input() public fontSize:number;

	public faCamera:IconDefinition = faCamera;
	public faImage:IconDefinition = faImage;
	public faPaperclip:IconDefinition = faPaperclip;
	public faTrash:IconDefinition = faTrash;
	public faCheckCircle:IconDefinition = faCheckCircle;
	public faCheckCircleLight:IconDefinition = faCheckCircleLight;
	public faBars:IconDefinition = faBars;
	public faPen:IconDefinition = faPen;

	public qVar:any = {};
	public inEdit:boolean = true;
	public disableAction:boolean = true;
	public inEditMode:boolean = true;
	protected tmpQText:any;

	public optionNames:string[];
	public _questionText: SafeHtml  = ""
	// editor
	public quills: any[] = []; 
	public quill: any = null; 
	// hints
	public qHintContent = ""


	constructor(
		protected elementRef:ElementRef,
		public translate:TranslateService, 
		private sans: DomSanitizer, 
		public fileio:FileIOService,
		public datas:DataService,
		private script: ScriptService,
		private alertService:AlertService) {
		this.optionNames = [];
		for (var i = 0; i < 26; i++)
			this.optionNames.push(String.fromCharCode(0x41+i)+".");
	}

	ngOnChanges(changes: SimpleChanges) {
		var conv = [22, 29, 36, 44, 52];
		var s = this.elementRef.nativeElement.style;
		if(changes.hasOwnProperty("qSize"))
			s.setProperty('--qSize', conv[this.qSize - 1]);
		if(changes.hasOwnProperty("scoreSize"))
			s.setProperty('--scoreSize', conv[this.scoreSize - 1]);
		if(changes.hasOwnProperty("fontSize"))
			s.setProperty('--fontSize', this.fontSize+"px");
		if(changes.hasOwnProperty("viewMode")) {
			if(this.qObj.type == 'QBTextMC' && changes.viewMode.previousValue == "edit")
				this.qVar.options = null;
			
			this.inEdit = this.viewMode == 'edit';
			this.disableAction = ['edit','edit preview'].indexOf(this.viewMode)>=0;
			this.inEditMode = ['edit','edit preview'].indexOf(this.viewMode)>=0;
			this.getQuestionText()
			this.getHints()
		}
	}

	public getTemplateRef():TemplateRef<any> {
		if(this.qObj) {
			if(this.qObj.type == 'QBTextMC' || this.qObj.type == 'QBGraphicMC'  || this.qObj.type == 'QBVoiceMC')
				return this.qbTextMC;
			else if(this.qObj.type == 'QBRecorder')
				return this.qbRecorder;
			else if(this.qObj.type == 'QBTakePhoto')
				return this.qbTakePhoto;
			else if(this.qObj.type == 'QBShortAnswer')
				return this.qbShortAnswer;
			else if(this.qObj.type == 'QBLongQuestion')
				return this.qbLongQuestion;
			else if(this.qObj.type == 'QBFillingBlank')
				return this.qbFillingBlank;
			else if(this.qObj.type == 'QBToggleOptions')
				return this.qbToggleOptions;
			else if(AssessmentViewerComponent.isPageQuestionComponent(this.qObj.type))
				return this.pageCom;
			else if(this.qObj.type == 'QBInfoBlock')
				return this.qbInfoBlock;
			else if(this.qObj.type == 'QBTrueFalse')
				return this.qbTrueFalse;

		}
		
		return this.qbTextMC;
	}

	public continuesSpaceConvert(str:string):string {
		var ary:string[] = str.split("");
		ary.forEach((t,i)=> {
			if(t == " ") {
				if(i+1<ary.length && ary[i+1]==" ")
					ary[i] = "&nbsp;";
			}
		});
		return ary.join("");
	}
	
	public onQuillCreated(quill: any) {
		this.quill = quill.quill;
		const obj = {[quill.editorId]: quill.quill}
		this.quills = [obj]
	}

	// =======================================
	// mc function
	// =======================================
	public getQuestionText():SafeHtml {
		var output:any = this.qObj ? this.qObj.question.replaceAll("\n","<br/>") : "";
		let result = this.continuesSpaceConvert(output);
		let safeHtml = this.sans.bypassSecurityTrustHtml(result)
		this._questionText = safeHtml
		return safeHtml
	}

	// get hints
	public getHints(): string {
		const lang = this.datas.lang;
		if (this.qObj && this.qObj.type) {
			let hint = ""
			switch(this.qObj.type) {
				case "QBFillingBlank":
					const fillInText = {tc:'請將答案放在[ ]內。例: 我有一個[蘋果]。', sc:'请将答案放在[ ]内。例: 我有一个[苹果]。', en:'Place the answer in [ ]. E.g. I have an [apple].'}[lang]
					const fillInKeyword = {
						tc:'請將關鍵字放在[ ]內，以「,」分隔。任何關鍵字都被視為正確答案。例: 我有一個[蘋果,橙]。',
						sc:'请将关键字放在[ ]内，以「,」分隔。任何关键字都被视为正确答案。例: 我有一个[苹果,橙]。',
						en:'Place the keyword in [ ], separated by ",". Any keyword will be considered a correct answer. E.g. I have an [apple,orange].'}[lang]
					hint = this.qObj.blank && this.qObj.blank.type == "keyword" ? fillInKeyword : fillInText
					break
				case "QBShortAnswer":
					const match = {tc:'完全一致的答案才視為正確。',sc:'完全一致的答案才视为正确。',en:'Only exact matches will be considered correct.'}[lang]
					const keyword = {tc:'請將關鍵字以「,」分隔。任何關鍵字都被視為正確答案。',sc:'请将关键字以「,」分隔。任何关键字都被视为正确答案。',en:'Separated the keywords by ",". Any keyword will be considered a correct answer.'}[lang]
					hint = this.qObj.context.type && this.qObj.context.type == "keyword" ? keyword : match
					break
				case "QBToggleOptions":
					hint = {
						tc:'請將選項放在[ ]內，以「/」分隔，在正確答案前加「#」。例: 你好[#嗎/媽]?', 
						sc:'请将选项放在[ ]内，以「/」分隔，在正确答案前加「#」。例: 你好[#吗/妈]?',
						en:'Place the options in [ ], separated by "/", and add "#" before the correct answer. E.g. How are [#you/me]?'
					}[lang]
					break
				default:
					break
			}
			return this.qHintContent = hint
		}
		return ""
	}

	public getCanChooseOptions():any {
		if(!this.qVar.options) {
			let ary:any = [];
			for(let i:number=1; i<=this.qObj.totalOptions; i++) {
				ary.push(this.continuesSpaceConvert(this.qObj["opt"+i]));
			}

			this.qVar.options = ary;
		}

		return this.qVar.options;
	}

	public isMCAnswer(grpIndex:number):boolean {
		if(this.qObj.answer==null)
			return false;
		if(typeof this.qObj.answer !== 'string')
			this.qObj.answer = this.qObj.answer.toString();
		return this.qObj.answer.indexOf((grpIndex+1).toString())>=0;
	}

	public getOptionAssetFiles(index:number):any {
		let asset:any = this.qObj["opt"+(index+1)+"_asset"];
		return (asset && asset.files) ? asset.files : [];
	}

	// =======================================
	// recorder function
	// =======================================
	public inState(states:any):boolean {
		//return states.indexOf(this.qVar.state)>=0;
		return states.indexOf("rec")>=0;
	}
	public playSnd():void {
		console.log("playSnd click");

	}
	public stopSnd():void {
		console.log("stopSnd click");
	}
	public recSnd():void {
		console.log("recSnd click");
	}
	public getDurationText():string {
		return "";
	}

	// =======================================
	// take photo function
	// =======================================
	public getCameraImageURLCSS():any {
		let url:string;
		if(this.qVar.file) {
			if(!this.qVar.url)
				this.qVar.url = URL.createObjectURL(this.qVar.file);
			url = this.qVar.url;
		} else if(this.qVar.url)
			url = this.fileio.getResourceServer(this.qVar.url)+this.qVar.url;

		if(url)
			return this.sans.bypassSecurityTrustStyle("url('"+url+"')");
		return "none";
	}

	public takePhoto():void {
//		this.getImageResult(this.cameraCapture.lanuch());
	}

	public selectImage():void {
		this.getImageResult(this.fileio.getLocalFile({fileType:'image'}));
	}

	protected getImageResult(p:Promise<any>):void {
		p.then((success)=> {
//			this.markQChange(this.qVar);
			this.qVar.url = null;
			this.qVar.file = success.file;
		}, (reason) => {
//			if(reason.msg!="cancel" && reason.msg!="cancel-by-close-window")
//				this.alertService.alert(this.getText(reason.msg));
		});
	}


	// =======================================
	// =======================================
	public getQuestionParts():any {
		if(this.tmpQText)
			return this.tmpQText;
		
		if(!this.qObj.question) {
			this.tmpQText = "";
			return this.tmpQText;
		}
		
		let text:string = this.qObj.question.replaceAll("\n","<br/>");
		if(this.qObj.type == "QBToggleOptions" || this.qObj.type == "QBFillingBlank") {
			// 文字切換題
			let parts:any = [];
			let pos:number = 0;
			let re = /(\[[^\]]+\])/ig;
			let myArray;
			let grp:number = 0;
			while ((myArray = re.exec(text)) !== null) {
				// 前面有普通字串
				if(pos < myArray.index)
					parts.push({type:"text", str:text.substring(pos, myArray.index)});
				// option
				let opts = text.substring(myArray.index+1, re.lastIndex-1).split("/");
				if(this.qObj.type == "QBToggleOptions") {
					opts.forEach((opt,index) => {
						if(opt.substr(0,1)=="#")
							parts.push({type:"option", grp:grp, id:index, answer:true, str:opt.substr(1)});
						else
							parts.push({type:"option", grp:grp, id:index, answer:false, str:opt});
						if(index+1<opts.length)
							parts.push({type:"text", str:"/"});
					});

				} else {
					parts.push({type:"input", grp:grp, answer:opts});
				}
				grp++;
				pos = re.lastIndex;
			}
			if(pos < text.length)
				parts.push({type:"text", str:text.substring(pos, text.length)});

			this.tmpQText = parts;
		} else {
			this.tmpQText = [{type:"text", str:text}];
		}
		return this.tmpQText;
	}

	// for filing blank (with html)
	public getFillingBlankQuestionParts(): any {
		if (this.tmpQText) return this.tmpQText;
		if(!this.qObj.question) return this.tmpQText = "";

		let text: string = this.qObj.question.replaceAll("\n", "<br/>").replace(/<\/?p>/g, '');
		let parts: any = [];
		let pos: number = 0;
		let re = /(\[[^\]]+\])/ig;
		let myArray: RegExpExecArray | null;
		let grp: number = 0;

		while ((myArray = re.exec(text)) !== null) {
			if (pos < myArray.index) {
				let precedingText = text.substring(pos, myArray.index);
				parts.push({ type: "text", pos: 'before', str: precedingText, innerHtml: this.formatHtml(precedingText) });
			}

			let opts = text.substring(myArray.index+1, re.lastIndex-1).split("/");
			parts.push({type:"input", grp:grp, answer:opts});
			grp++;
			pos = re.lastIndex;
		}

		if (pos < text.length) {
			let remainingText = text.substring(pos, text.length);
			parts.push({ type: "text", pos: 'after', str: remainingText, innerHtml: this.formatHtml(remainingText) });
		}

		this.tmpQText = parts;
		return this.tmpQText;
	}

	// for toggle option (with html)
	public getToggleQuestionParts(): any {
		if (this.tmpQText) return this.tmpQText;
	
		let text: string = this.qObj.question.replaceAll("\n", "<br/>").replace(/<\/?p>/g, '');
		let parts: any = [];
		let pos: number = 0;
		let re = /(\[[^\]]+\])/ig;
		let myArray: RegExpExecArray | null;
		let grp: number = 0;
	
		while ((myArray = re.exec(text)) !== null) {
			if (pos < myArray.index) {
				let precedingText = text.substring(pos, myArray.index);
				parts.push({ type: "text", pos: 'before', str: precedingText, innerHtml: this.formatHtml(precedingText) });
			}

			let bracketContent = text.substring(myArray.index + 1, re.lastIndex - 1);
			let opts = bracketContent.split(/(?<!<[^>]*)\/(?![^<]*>)/).map(opt => opt.trim());
	
			let optGrp: any[] = [];
			opts.forEach((opt, index) => {
				let isAnswer = opt.includes("#");
				let optionStr = isAnswer ? opt.substring(opt.indexOf("#") + 1) : opt;
	
				optGrp.push({ type: "option", grp: grp, id: index, answer: isAnswer, str: optionStr, innerHtml: this.formatHtml(optionStr) });
	
				if (index + 1 < opts.length) {
					optGrp.push({ type: "text", str: "/", innerHtml: this.formatHtml("/") });
				}
			});
	
			parts.push({ type: "grp", grp: grp, options: optGrp });
			grp++;
			pos = re.lastIndex;
		}
	
		if (pos < text.length) {
			let remainingText = text.substring(pos, text.length);
			parts.push({ type: "text", pos: 'after', str: remainingText, innerHtml: this.formatHtml(remainingText) });
		}
		this.tmpQText = parts;
	
		return this.tmpQText;
	}

	// =======================================
	// form edit view function
	// =======================================
	public qTextChg(event):void {
		this.tmpQText = null;
		this.qObj.question = event.target.value;
//		event.target.style.height = 0;
//		event.target.style.height = (event.target.scrollHeight) + "px"; // auto size

		this.qChange();
	}

	public onQuillContentChange(content: string) {
		this.tmpQText = null;
		this.qObj.question = content;
		this.qChange();
	}

	public ansTextChg(event):void {
		this.qObj.answer = event.target.value;

		this.qChange();
	}

	public mcTextChg(event, optIndex:number):void {
		this.qChange();
	}

	public onMCQuillContentChange(content, optIndex: number) {
		this.qObj['opt' + (optIndex+1)] = content
		this.qChange();
	}

	public drop(event: CdkDragDrop<string[]>) {
		// 記錄答案設定
		let ans:any = this.getMCAnsSetting();

		// 交換答案文字
		let tmp:any = this.qObj["opt"+(event.previousIndex+1)];
		this.qObj["opt"+(event.previousIndex+1)] = this.qObj["opt"+(event.currentIndex+1)];
		this.qObj["opt"+(event.currentIndex+1)] = tmp;

		// 交換答案 asset
		tmp = this.qObj["opt"+(event.previousIndex+1)+"_asset"];
		this.qObj["opt"+(event.previousIndex+1)+"_asset"] = this.qObj["opt"+(event.currentIndex+1)+"_asset"];
		this.qObj["opt"+(event.currentIndex+1)+"_asset"] = tmp;

		// 交換答案設定
		tmp = ans[event.previousIndex];
		ans[event.previousIndex] = ans[event.currentIndex];
		ans[event.currentIndex] = tmp;

		// 更新答案設定
		this.updateMCAnsSetting(ans);

		this.qVar.options = null;
		this.qChange();
	}

	public toggleMCOption(optIndex:number):void {
		if(this.qObj.type == "QBTextMC") {
			if(parseInt(this.qObj.multiSelect)==1) {
				// 多選用，toggle 方式
				let ans:any = this.getMCAnsSetting();
				ans[optIndex] = !ans[optIndex];
				this.updateMCAnsSetting(ans);

			} else {
				// 單選，指定單一答案
				this.qObj.answer = (optIndex+1).toString();
			}

		} else {
			this.qObj.answer = (optIndex).toString();
		}

		this.change.emit({type:"MCOptionChg", qObj:this.qObj});
	}

	protected getMCAnsSetting():any {
		let ans:any = [];
		for(let i=0;i<this.qObj.totalOptions;i++)
			ans.push(this.isMCAnswer(i));
		return ans;
	}

	protected updateMCAnsSetting(src:any):void {
		let tmp = [];
		for(let i=0;i<src.length;i++) {
			if(src[i])
				tmp.push(i+1);
		}
		this.qObj.answer = tmp.join(",");
	}

	public canAddOption():boolean {
		return true;
		// return parseInt(this.qObj.totalOptions)<4;
	}
	
	public addMCOption():void {
		this.qObj.totalOptions++;
		this.qObj["opt"+this.qObj.totalOptions] = "";
		this.qObj["opt"+this.qObj.totalOptions+"_asset"] = {files:[]};
		this.qVar.options = null;

		this.change.emit({type:"addMCOption", qObj:this.qObj});
	}

	public delMCOption(optIndex:number):void {
		this.change.emit({type:"delMCOption", qObj:this.qObj, optIndex:optIndex+1});
		this.qVar.options = null;
	}

	public addAsset(event, key:string):void {
		this.change.emit({type:"addAsset", qObj:this.qObj, key:key, target:event.target});
	}

	public openComMenu(event, key:string, asset:any, targetTag:string = null):void {
		let target:any = this.findCorrectFocus(event.target, targetTag);
		if(target)
			this.change.emit({type:"comMenu", qObj:this.qObj, key:key, asset:asset, target:target});
	}

	public qChange():void {
		this.change.emit({type:"text", qObj:this.qObj});
	}

	protected findCorrectFocus(target:any, targetTag:string = null):any {
		let i:number = 0;
		let tags:string[] = targetTag ? [targetTag] : ["IMG","VIDEO","AUDIOPLAYER"];
		while(target && tags.indexOf(target.nodeName)==-1 &&i<5) {
			target = target.parentNode;
			i++;
		}
		return target;
	}

	public imgLoad(event:any, asset:any):void {
		var dom = event.target;
		if(!asset.hasOwnProperty("width") || asset.width==0) {
			var size:any = this.fileio.calContainSizeInSquare(dom.naturalWidth, dom.naturalHeight, 368);
			asset.width = size.width;
			asset.height = size.height;
		}
	}

	public initImageSize(asset:any):boolean {
		if(!asset.hasOwnProperty("width")) {
			asset.width = 0;
			asset.height = 0;
		}
		return true;
	}

	public showPageComponentEditPopup():void {
		this.change.emit({type:"showPageComponentEditPopup", qObj:this.qObj});
	}
	
	// format the question
	public formatHtml(string): SafeHtml {
		let safeHtml = this.sans.bypassSecurityTrustHtml(string)
		return safeHtml
	}

	// format textMC options
	public formatTextMCOptions(optIndex: number, ans: string) {
		let modifyOption = ans.replace(/<\/?p>/g, '');
		const option = `${this.optionNames[optIndex]} ${modifyOption}`
		return this.formatHtml(option)
	}
}