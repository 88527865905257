import { ChangeDetectorRef, ElementRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { FileIOService } from "src/app/service/FileIO.service";
import { QBComponentBase } from "./QBComponentBase";


export class QBComponentQuestionBase extends QBComponentBase
{
	public qObject:any;
	public currentState:any;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, public fileio:FileIOService)
	{
		super(cdr, elementRef);
	}

	public isQuestionComponent(): boolean {
		return true;
	}

	canEdit()
	{
		return false;
	}
	
	public getFullScore(): number {
		return this.qObject.fullScore;
	}

	protected getResultObject(qObj:any, correct:number, wrong:number, totalAns:number):any {
		if(correct==-1) // 無指定答案
			return {correction:false, correct:-1, maxScore:qObj.fullScore, teacherScore:null};
		
		if(correct == totalAns && wrong == 0) // 全對
			return {
				correction:false,
				score:parseFloat(qObj.fullScore),
				correct:2,
				maxScore:qObj.fullScore
			};
		
		if(correct==0 || parseInt(qObj.scoringType)==1) // 全錯
			return {
				correction:true, // Incorrect 要改正
				score:0,
				correct:0,
				maxScore:qObj.fullScore
			};
		
		// 有對及錯
		let correctCount:number = ["QBTextMC","QBGraphicMC","QBVoiceMC"].indexOf(qObj.type)>=0 ? Math.max(0,correct-wrong) : correct;
		return {
			correction:true, // Incorrect 要改正
			score:Math.round(10*parseFloat(qObj.fullScore)*correctCount/totalAns)/10,
			correct:1,
			maxScore:qObj.fullScore
		};
	}


	
}