import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { LoadingService } from '../loadingModule/loading.service';
import { TTSService } from 'src/app/service/TTS.service';
import { faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { faVolume, faTimer } from '@fortawesome/pro-solid-svg-icons';
import { ScriptService } from 'src/app/service/script.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';

@Component({
	selector: 'ai-composition-marking-details',
	template: `
	<div class="left">
		<div class="left-top {{isPersonal?'personal':''}}">
			<div class="info" *ngIf="info">
				<div *ngIf="photo" class="info-media" 
					[style.backgroundImage]="photo"
					(click)="previewMedia()">
					<div class="magnify_icon">
						<fa-icon [icon]="faMagnifyingGlass"></fa-icon>
					</div>
				</div>
				<div class="info-topic" [class.withMedia]="info && info.mediaUrl">
					<span>{{ {tc: '標題', sc:'标题', en:'Topic'}[lang]}}: {{info.name}}</span>
					<div class="speak-wrapper" [class.withMedia]="photo" (click)="speak('topic')">
						<fa-icon [icon]="faVolume"></fa-icon>
					</div>
				</div>
				<div class="info-description" [class.withMedia]="info.mediaUrl">
					<p>{{ info.description }}</p>
				</div>
			</div>
			
			<div class="guide" *ngIf="info && info.prewritingAIGuideEnabled">
				<div class="guide-panel">
					<div class="guide-topic">
						{{{tc: '寫作前提示', sc:'写作前提示',en: 'Hint'}[lang]}}
					</div>
					<okaPulldown2 [okaPulldownStyle]="{width: '60px'}" 
						bindLabel="label" bindValue="value"
						[options]="aiGuideOptions" [(ngModel)]="aiGuide" (ngModelChange)="aiGuideChange($event)"></okaPulldown2>
				</div>
				<div class="guide-body">
					<div class="guide-text">{{ displayGuide }}</div>
					<div class="speak-wrapper" (click)="speak('guide')">
						<fa-icon [icon]="faVolume"></fa-icon>
					</div>
				</div>
			</div>
		</div>

		<div class="paragraph-wrapper" *ngIf="info && info.paragraph == 0">
			<div class="paragraph-left">
				<div>{{ {tc:'全文',sc:'全文',en:'Essay '}[lang] }}:</div>
				<div class="paragraph-history" *ngIf="paragraphs[0].used > 0" (click)="historyClick($event, 0)">
					<fa-icon [icon]="faTimer"></fa-icon>({{ paragraphs[0].used }})
				</div>
			</div>
			<div class="paragraph whole-essay">
				<div class="paragraph-top" [innerHTML]="paragraphs[0].text"></div>
				<div class="paragraph-bottom">
					<div class="paragraph-panel">
						<div class="paragraph-topic">{{ {tc:'提示',sc:'提示',en:'Hint'}[lang] }}</div>
						<okaPulldown2 [okaPulldownStyle]="{width: '60px'}" 
							bindLabel="label" bindValue="value"
							[options]="aiSuggestionOptions" [(ngModel)]="paragraphs[0].selectedSuggestion" 
							(ngModelChange)="selectedSuggestionChange($event, 0)"></okaPulldown2>
					</div>
					<div class="paragraph-comment">
						<div class="paragraph-suggestion-text">{{ paragraphs[0].comment }}</div>
						<div class="speak-wrapper" (click)="speak('comment', 0)">
							<fa-icon [icon]="faVolume"></fa-icon>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="info && info.paragraph > 1">
			<div class="paragraph-wrapper" *ngFor="let paragraph of paragraphs;let i = index;">
				<div class="paragraph-left">
					<div>{{ {tc:'段落',sc:'段落',en:'Paragraph '}[lang] + (i+1)}}:</div>
					<div class="paragraph-history" *ngIf="paragraph.used > 0" (click)="historyClick($event, i)">
						<fa-icon [icon]="faTimer"></fa-icon>({{ paragraph.used }})
					</div>
				</div>
				<div class="paragraph" [class.personal]="isPersonal">
					<div class="paragraph-top" [innerHTML]="paragraph.text"></div>
					<div class="paragraph-bottom">
						<div class="paragraph-panel">
							<div class="paragraph-topic">
								{{ {tc:'段落',sc:'段落',en:'Paragraph '}[lang] + (i + 1) + {tc:'提示',sc:'提示',en:'Hint'}[lang] }}
							</div>
							<okaPulldown2 [okaPulldownStyle]="{width: '60px'}" 
								bindLabel="label" bindValue="value"
								[options]="aiSuggestionOptions" [(ngModel)]="paragraph.selectedSuggestion" 
								(ngModelChange)="selectedSuggestionChange($event, i)"></okaPulldown2>
						</div>
						<div class="paragraph-suggestion">
							<div class="speak-wrapper" (click)="speak('suggestion', i)">
								<fa-icon [icon]="faVolume"></fa-icon>
							</div>
							<div class="paragraph-suggestion-text">{{ paragraph.selectedSuggestionText }}</div>
						</div>
						<div class="paragraph-topic">
							{{ {tc:'段落',sc:'段落',en:'Paragraph '}[lang] + (i + 1) + {tc:'評語',sc:'評語',en:' Comment'}[lang] }}
						</div>
						<div class="paragraph-comment">
							<div class="speak-wrapper" (click)="speak('comment', i)">
								<fa-icon [icon]="faVolume"></fa-icon>
							</div>
							<div class="paragraph-comment-text">{{ paragraph.comment }}</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	
	<div class="right-bg {{theme.theme$.value}}-theme">
		<div class="right">
			<div class="right-ai-score-button"  (click)="aiCheckClick($event)" *ngIf="enableAIButton">
				<svg-icon *ngIf="loadedSvg" name="icon_aimarking"></svg-icon>
				{{ {tc:'AI評分',sc: 'AI评分', en: 'AI Score'}[lang] }}
			</div>
			<div class="right-score">
				<span>{{ {tc:'總分',sc: '总分', en: 'Score'}[lang] }}:</span>
				<span>{{ score === null?'--':score }}</span>
				<span>/{{ maxScore === null?'--':maxScore }}</span>
			</div>
			<div class="right-instruction" *ngIf="!isPersonal">
				<span>{{ {tc:'請於老師欄的方格內評分',sc:'请于老师栏的方格内评分',en:'Please score the box in the teacher column'}[lang] }}:</span>
			</div>
			<div class="table">
				<div class="table-header">
					<div class="table-header-cell main {{theme.theme$.value}}-theme {{isPersonal?'personal':''}}">{{ {tc:'準則',sc: '準則', en: 'Scale'}[lang] }}</div>
					<div class="table-header-cell {{theme.theme$.value}}-theme">{{ {tc:'滿分',sc: '总分', en: 'Max.'}[lang] }}</div>
					<div *ngIf="!isPersonal" class="table-header-cell {{theme.theme$.value}}-theme">{{ {tc:'AI',sc: 'AI', en: 'AI'}[lang] }}</div>
					<div *ngIf="!isPersonal" class="table-header-cell {{theme.theme$.value}}-theme">{{ {tc:'老師',sc: '老师', en: 'Teacher'}[lang] }}</div>
					<div *ngIf="isPersonal" class="table-header-cell {{theme.theme$.value}}-theme">{{ {tc:'分數',sc: '分数', en: 'Score'}[lang] }}</div>
				</div>
				<div class="table-body">
					<div class="table-row" *ngFor="let row of rows">
						<div class="table-row-cell main {{theme.theme$.value}}-theme {{isPersonal?'personal':''}}">
							<span>{{row.title}}</span>
						</div>
						<div class="table-row-cell score {{theme.theme$.value}}-theme">{{row.score}}</div>
						<ng-container *ngIf="!isPersonal"> 
							<div class="table-row-cell ai {{theme.theme$.value}}-theme">{{row.ai === null?'':row.ai}}</div>
							<div class="table-row-cell teacher {{theme.theme$.value}}-theme" 
								[class.unfilled]="row.teacher === null || row.teacher === undefined">
								<input type="number" 
									[(ngModel)]="row.teacher"
									class="{{theme.theme$.value}}-theme"
									[max]="row.score" (change)="saveTeacherScore($event, row)" />
							</div>
						</ng-container>
						<ng-container *ngIf="isPersonal">
							<div class="table-row-cell overall {{theme.theme$.value}}-theme" 
								[class.is-ai]="row.teacher === null && row.overall !== null && !isChecking">{{row.overall === null || isChecking?'?':row.overall}}</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="right-separate {{theme.theme$.value}}-theme"></div>
			<div class="right-topic">
				<div class="right-topic-text">{{ {tc:'總評',sc:'总评',en:'Comment'}[lang] }}:</div>
				<div class="right-topic-audio" *ngIf="isPersonal" (click)="speak('comment')"></div>
			</div>
			<div class="right-overall" *ngIf="isPersonal && !isChecking">
				<span>{{commentOverall}}</span>
			</div>

			<ng-container *ngIf="!isPersonal">
				<div class="right-ai">
					<div class="right-title">AI:</div>
					<div class="right-text">{{ commentAI }}</div>
				</div>
				<div class="right-teacher">
					<div class="right-title">{{ {tc:'老師', sc: '老師', en: 'Teacher'}[lang] }}:</div>
					<div class="right-text">
						<textarea [ngClass]="theme.theme$.value + '-theme'" 
							(change)="commentTeacherChange($event)" [(ngModel)]="commentTeacher"></textarea>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<bubbleBox2 #historyPopup [marginLeft]="30" position="right" (close)="history = null">
		<div class="record-wrapper" *ngIf="history">
			<div class="record-wrapper-2">
				<div class="record" [class.last]="index == 3" *ngFor="let index of [1,2,3];">
					<ng-container>
						<div class="record-label">{{ {tc:'紀錄',sc:'纪录',en:'Record '}[lang] + index }}:</div>
						<div class="record-body">{{ history['text'+ index] }}</div>
						<div class="record-right {{theme.theme$.value}}-theme">
							<div class="record-comment-topic">{{ {tc:'評語', sc: '评语', en:'Comment'}[lang] }}</div>
							<div class="record-comment-body">
								<div class="record-comment-body-text">{{ history['feedback'+index] }}</div>
								<div class="record-voice" (click)="speak('history', history.pi + ':' + index)">
									<fa-icon [icon]="faVolume"></fa-icon>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</bubbleBox2>

	<bubbleBox2 #aiCheckPopup padding="0" backgroundColor="var(--wpopup-bgcolor)">
		<div class="aiCheckPopup">
			<div class="aiCheckPopup-row">
				<div class="aiCheckPopup-label">{{ {tc:'AI總評', sc:'AI总评', en: 'Comment'}[lang] }}</div>
				<okaPulldown2 [options]="commentOptions" 
					[okaPulldownStyle]="{width: '200px'}"
					bindLabel="label" bindValue="value" 
					[(ngModel)]="commentOption"
					></okaPulldown2>
			</div>
			<div class="aiCheckPopup-panel">
				<div class="aiCheckPopup-check"
				(click)="aiCheckConfirm()">{{ {tc:'開始評分',sc:'开始评分',en:'Start'}[lang] }}</div>
				<div class="aiCheckPopup-cancel" 
					(click)="aiCheckPopup.closeNow()">{{ {tc:'取消',sc:'取消',en:'Cancel'}[lang] }}</div>
			</div>
		</div>
	</bubbleBox2>
	
	`,
	styleUrls: ['./aiCompositionMarkingDetails.scss'],
})

export class AICompositionMarkingDetails implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	@Input() enableAIButton:boolean = true;
	@ViewChild('historyPopup', {static: false}) historyPopup: BubbleBox2Component;
	@ViewChild('aiCheckPopup', {static: false}) aiCheckPopup: BubbleBox2Component;
	@Output() aiCheck: EventEmitter<any> = new EventEmitter();
	public photo;
	public info:any;
	public lang;
	public score:number = null;
	public maxScore:number = null;
	public rows:any[] = [];
	public commentOverall;
	public commentAI;
	public commentTeacher = '';
	public faMagnifyingGlass = faMagnifyingGlass;
	public faVolume = faVolume;
	public faTimer = faTimer;
	public aiGuideOptions:any[] = [];
	public aiGuide = 1;
	public paragraphs:any = [];
	public displayGuide;
	public aiSuggestionOptions:any[] = [];
	private audioUrl:any = {};
	private audioObj;
	public history:any = null;
	private isStudentSubmitted = 0;
	public isPersonal = false;
	public isChecking = false;
	public loadedSvg = false;
	public commentOptions;
	public commentOption;
	
	constructor(private trans: TranslateService, public datas: DataService, private http: HttpClient, private als: AlertService, public eleRef: ElementRef, public theme: ThemeService, private common: CommonService, private lds: LoadingService, public tts: TTSService, public zone: NgZone, public sans: DomSanitizer, public script: ScriptService, public iconReg: InlineSvgService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.isPersonal = !!this.data.isPersonal;
		this.isChecking = this.data.share.status == 'checking';
		this.initOptions();
		this.test();
		this.loadStudent();
		this.iconReg.regSvgFiles([
			{url: 'icon_aimarking.svg', name: 'icon_aimarking'},
		]).then((res:any)=>{
			this.loadedSvg = true;
		});
	}

	ngAfterViewInit() {
	
	}

	ngOnDestroy(): void {
	}

	test(){
		/*this.rows = [
			{title: '流利性與連貫性', score: 40, ai: 8, teacher: 8, isEllipsis: false},
			{title: '詞滙多樣性詞滙多樣性詞滙多樣性詞滙多樣性', score: 30, ai: 8, teacher: 20, isEllipsis: true},
			{title: '語法多樣及準確性多樣及準確性', score: 30, ai: 20, teacher: 26, isEllipsis: false },
		];*/
	}

	initOptions(){
		const lang = this.lang;
		if (this.data.info){
			const info = this.data.info;
			this.info = info;
			if (info.mediaUrl){
				this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/" + this.info.mediaUrl +"')");
			}
			const noUsedGuideText = {tc:'「未有使用提示」',sc:'「未有使用提示」', en:'"Not yet used."'}[lang];
			const noUsedFeedbackText = {tc:'「未有使用評語」',sc:'「未有使用评语」', en:'"Not yet used."'}[lang];
			if (info.paragraph > 0){
				for(let i = 0;i < info.paragraph; i++){
					let paragraph:any = {text: '', selectedSuggestion: '1', used: 0, comment: noUsedFeedbackText};
					this.paragraphs.push(paragraph);
				}
			} else {
				this.paragraphs = [{text:'', selectedSuggestion: '1', used: 0, comment: noUsedFeedbackText}];
			}
			if (info.prewritingAIGuideEnabled){
				this.aiGuideOptions = [];
				for(let i = 1; i <= info.prewritingAIGuideTimes; i++){
					let item:any = {label: '' + i, text: noUsedGuideText, value: i};
					this.aiGuideOptions.push(item);
				}
				this.aiGuide = this.aiGuideOptions[0].value;
			}
			if (info.prewritingAISuggestionEnabled){
				this.aiSuggestionOptions = [];
				for(let i = 1; i <= info.prewritingAISuggestionTimes; i++){
					let item:any = {label: '' + i, value: i };
					this.aiSuggestionOptions.push(item);
					this.paragraphs.forEach(e=>{
						e['suggestion'+i] = noUsedGuideText;
					});
				}
			}
			if (info.prewritingAIFeedbackEnabled){
				for(let i = 1; i <= info.prewritingAIFeedbackTimes; i++){
					this.paragraphs.forEach(e=>{
						e['text'+i] = '';
						e['feedback'+i] = noUsedFeedbackText;
					});
				}
			}
			this.rows = [];
			if (info.scoringItems){
				this.maxScore = 0;
				this.rows = info.scoringItems.map(item=>{
					let obj = {title: item.title, score: item.score, ai: null, teacher: null };
					this.maxScore += parseFloat(item.score);
					return obj;
				});
			}
			this.commentAI = {tc:'未進行AI批改。',sc:'未进行AI批改。',en:'Not yet marking by AI.'}[lang];
		}
		this.commentOptions = [
			{label: {tc:'沒有AI總評', sc:'没有AI总评', en: 'No comment'}[lang], value: 0},
			{label: {tc:'大約10字以內', sc:'大約10字以內', en: 'Around 10 words'}[lang], value: 10},
			{label: {tc:'大約30字以內', sc:'大約30字以內', en: 'Around 30 words'}[lang], value: 30},
			{label: {tc:'大約50字以內', sc:'大約50字以內', en: 'Around 50 words'}[lang], value: 50}
		];
		this.commentOption = 10;
	}

	speak(type, data:any = ''){
		let text = '';
		if (type == 'topic'){
			text = {tc: '標題', sc:'标题', en:'Topic'}[this.lang] + ':' + this.info.name + ';' + this.info.description;
		} else if (type == 'comment'){
			text = this.paragraphs[+data].comment;
		} else if (type == 'suggestion'){
			text = this.paragraphs[+data].selectedSuggestionText;
		} else if (type == 'history'){
			const arr = data.split(':');
			text = this.paragraphs[+arr[0]]['suggestion'+ arr[1]];
		} else if (type == 'guide'){
			text = this.displayGuide;
		}
		if (text == '' || text == null){
			const msg = {tc:'沒有內容，無法朗讀', sc: '沒有內容，無法朗讀', en: 'Blank.'}[this.lang];
			this.als.toastError(msg);
			return;
		}
		this.tts.play(text).catch(e=> e);
	}

	previewMedia(){
		const previewComp = this.data.previewComp;
		const url = '//oka.blob.core.windows.net/media/' + this.info.mediaUrl;
		const fileObj:any = { name:"", url: url, ext:"png" };
        previewComp.setFile(fileObj);
        previewComp.open();
	}

	historyClick($event, index){
		const el = $event.target;
		this.history = {...this.paragraphs[index]};
		this.history.pi = index;
		this.historyPopup.open(el, this.eleRef.nativeElement).catch(e=> e);
	}

	selectedSuggestionChange(value, index){
		this.paragraphs[index].selectedSuggestionText = this.paragraphs[index]['suggestion' + value]; 
	}

	aiGuideChange($event){
		const found = this.aiGuideOptions.find(e=> e.value == $event);
		this.displayGuide = found.text;
	}

	saveTeacherScore($event, row){
		if (!this.isStudentSubmitted){
			$event.stopPropagation();
			$event.preventDefault();
			row.teacher = null;
			this.als.toastError({tc:'學生尚未提交。', sc:'学生尚未提交。', en:'Student not submitted.'}[this.lang]);
			return;
		}
		if (isNaN(row.teacher)){
			row.teacher = null;
			return;
		}
		if (row.teacher > row.score){
			row.teacher = row.score;
		}
		let score = 0;
		let dirty = false;
		this.rows.forEach(row=>{
			if (row.teacher !== null){
				score += parseFloat(row.teacher);
				dirty = true;
			} else if (row.ai !== null){
				score += parseFloat(row.ai);
				dirty = true;
			}
		});
		this.score = dirty?score:null;
		const student = this.data.students.find(e=> e.uid == this.data.selectedUid);
		student.result.teacher_score = this.rows.map(e=> +e.teacher);
		student.result.teacher_comment = null;
	}

	loadStudent(){
		const uid = this.data.selectedUid;
		const lang = this.lang;
		this.clearStudentData();
		const student = this.data.students.find(e=> e.uid == uid);
		this.isStudentSubmitted = student.submitted;
		let typos = [];
		if (student.result && student.result.comment){
			try{
				const commentObj = JSON.parse(student.result.comment);
				if (commentObj.typos && commentObj.typos.length > 0){
					typos = commentObj.typos;
				}
			} catch (e){

			}
		}
		if (student && student.result && student.result.answer.paragraphs){
			student.result.answer.paragraphs.forEach((text, index)=>{
				let typosCheckedText = text;
				typos.forEach(item=>{
					const wrong = item.wrong.split('');
					const correct = item.correct.split('');
					let word = '';
					wrong.forEach(char=>{
						if (correct.indexOf(char) === -1){
							word = word + '<span style="color: #f7434c;">' + char + '</span>';
						} else {
							word = word + char;
						}
					});
					typosCheckedText = typosCheckedText.replace(item.wrong, word);
				});

				this.paragraphs[index].text = this.sans.bypassSecurityTrustHtml(typosCheckedText);
			});
		}

		if (student && student.aiAsks){
			student.aiAsks = student.aiAsks.sort((a,b)=>{ //asc
				const aKey = a.query_type + a.index;
				const bKey = b.query_type + b.index;
				if (aKey > bKey){
					return 1;
				} else if (aKey < bKey){
					return -1;
				} else {
					return 0;
				}
			});
			let indexUsed = {guide:0, suggestion:0, feedback:0};
			student.aiAsks.forEach(ask=>{
				const content = JSON.parse(ask.content);
				const paragraphIndex = content.paragraph?content.paragraph:null;
				let index = ++indexUsed[ask.query_type];
				if (ask.query_type == 'guide'){
					index = index < this.aiGuideOptions.length?index:this.aiGuideOptions.length - 1;
					this.aiGuideOptions[index].text = ask.response;
				} else if (ask.query_type == 'suggestion' && paragraphIndex){
					this.paragraphs[paragraphIndex - 1]['suggestion' + index] = ask.response;
				} else if (ask.query_type == 'feedback' && paragraphIndex){
					this.paragraphs[paragraphIndex - 1]['text' + index] = content.answer?content.answer:'';
					this.paragraphs[paragraphIndex - 1]['feedback' + index] = ask.response;
					this.paragraphs[paragraphIndex - 1].used = Math.max(index, this.paragraphs[paragraphIndex - 1].used);
				}
			});
			this.displayGuide = this.aiGuideOptions[0].text;

			const noUsedFeedbackText = {tc:'「未有使用評語」',sc:'「未有使用评语」', en:'"Not yet used."'}[this.lang];
			this.paragraphs.forEach(paragraph=>{
				paragraph.selectedSuggestionText = paragraph.suggestion1;
				paragraph.comment = noUsedFeedbackText;
				for(let i = 5;i > 0;i--){
					if (paragraph['feedback'+i] && paragraph['feedback'+i] != noUsedFeedbackText){
						paragraph.comment = paragraph['feedback'+i];
						break;
					}
				}
			});	
		}
		if (student && student.result){
			if (this.isPersonal){
				this.commentOverall = student.result.teacher_comment?student.result.teacher_comment:student.result.ai_comment;
			} else {
				this.commentTeacher = student.result.teacher_comment?student.result.teacher_comment:'';
				this.commentAI = student.result.ai_comment?student.result.ai_comment:{tc:'未進行AI批改。',sc:'未进行AI批改。',en:'Not yet marking by AI.'}[lang];
			}
			let score = 0;
			this.rows.forEach((row,index)=>{
				if (student.result.ai_score && student.result.ai_score.length > index){
					row.ai = +student.result.ai_score[index];
				}
				if (student.result.teacher_score && student.result.teacher_score.length > index){
					const teacherScore = student.result.teacher_score[index] === null?null:+student.result.teacher_score[index];
					row.teacher = teacherScore;
				}
				row.overall = row.teacher || row.ai || null; 
				score += row.overall || 0;
			});
			this.score = score;
		}

	}

	clearStudentData(){
		const lang = this.lang;
		const noUsedGuideText = {tc:'「未有使用提示」',sc:'「未有使用提示」', en:'"Not yet used."'}[this.lang];
		const noUsedFeedbackText = {tc:'「未有使用評語」',sc:'「未有使用评语」', en:'"Not yet used."'}[this.lang];
		this.paragraphs.forEach(paragraph=>{
			for(let i = 1; i < 5; i++){
				paragraph['text' + i] = '';
				paragraph['suggestion' + i] = noUsedGuideText;
				paragraph['feedback' + i] = noUsedFeedbackText;
			}
			paragraph.text = '';
			paragraph.selectedSuggestion = '1';
			paragraph.selectedSuggestionText = paragraph.suggestion1;
			paragraph.comment = noUsedFeedbackText;
			paragraph.used = 0;
		});
	
		if (this.isPersonal){
			this.commentOverall = '';
		} else {
			this.commentAI = {tc:'未進行AI批改。',sc:'未进行AI批改。',en:'Not yet marking by AI.'}[lang];
			this.commentTeacher = '';
		}
		this.aiGuideOptions.forEach(option=>{
			option.text = noUsedGuideText;
		});
		this.displayGuide = noUsedGuideText;
		this.rows.forEach(row => {
			row.teacher = null;
			row.ai = null;
		});
		this.score = null;
	}

	commentTeacherChange($event){
		if (!this.isStudentSubmitted){
			$event.stopPropagation();
			$event.preventDefault();
			this.commentTeacher = null;
			this.als.toastError({tc:'學生尚未提交。', sc:'学生尚未提交。', en:'Student not submitted.'}[this.lang]);
			return;
		}
		const student = this.data.students.find(e=> e.uid == this.data.selectedUid);
		student.result.teacher_comment = this.commentTeacher;
	}

	aiCheckClick(event){
		this.aiCheckPopup.open(event.target, this.eleRef.nativeElement).catch(e=>e);
	}

	aiCheckConfirm(){
		const obj = {enableComment: this.commentOption > 0, commentOption: this.commentOption};
		this.aiCheck.emit(obj);
		this.aiCheckPopup.closeNow();
	}

}
