import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingDotComponent } from './loadingDot.component';



@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LoadingComponent,
		LoadingDotComponent
    ],
    exports: [
		LoadingDotComponent
    ],
    providers: [
    ],
    entryComponents: [
        LoadingComponent
    ]
})
export class LoadingModule {
}
