export class StudentInfo
{
	public static getStudentName(userObj:any = null, lang:string):string
	{
		if (userObj)
		{
			var displayname:string = "";
			
			var english:boolean = lang == "en";
			var chinese:boolean = !lang || lang == "tc" || lang == "sc";
			
			if (chinese && (userObj.c_last_name  ||  userObj.c_first_name ))
				displayname = userObj.c_last_name + userObj.c_first_name;
			if (displayname == "" && 
				english && (userObj.last_name || userObj.first_name)
			)
				displayname = userObj.last_name +" " + userObj.first_name;					
			/*
			if (displayname == "" && (userObj.uacc_username))
				displayname = userObj.uacc_username;
			if (displayname == "" && (userObj.uacc_email))
				displayname = userObj.uacc_email;
			*/
			if (displayname == "" && chinese && (userObj.cname))
				displayname = userObj.cname;
			if (displayname == "" && english && (userObj.name))
				displayname = userObj.name;
			if (displayname == "" && userObj.display_name)
				displayname = userObj.display_name;
			return displayname;
			// return "{displayname}({userObj.user_role}-{userObj.uid}-{userObj.school_id})";
		}
		return "";
	}

	public static getDisplayName(userObj:any = null, lang:string ):string
	{
		if (userObj)
		{
			var displayname:string = "";
			
			var english:boolean = lang == "en";
			var chinese:boolean = !lang || lang == "tc" || lang == "sc";
			
			if (chinese && (userObj.c_last_name  ||  userObj.c_first_name ))
				displayname = userObj.c_last_name + userObj.c_first_name;
			if (displayname == "" && 
				english && (userObj.last_name || userObj.first_name)
			)
				displayname = userObj.last_name +" " + userObj.first_name;					
			if (displayname == "" && (userObj.uacc_username))
				displayname = userObj.uacc_username;
			if (displayname == "" && (userObj.uacc_email))
				displayname = userObj.uacc_email;
			if (displayname == "" && chinese && (userObj.cname))
				displayname = userObj.cname;
			if (displayname == "" && english && (userObj.name))
				displayname = userObj.name;
			if (displayname == "" && userObj.display_name)
				displayname = userObj.display_name;
			return displayname;
			// return "{displayname}({userObj.user_role}-{userObj.uid}-{userObj.school_id})";
		}
		return "";
	}
}