import { ChangeDetectorRef, ElementRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { FileIOService } from "src/app/service/FileIO.service";


export class QBComponentBase extends ROComponent
{
	public qObject:any;
	public currentState:any;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	
}