import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InlineSvgService } from './inlineSvg.service';

@NgModule({
    imports: [
        AngularSvgIconModule
    ],
    exports: [
		AngularSvgIconModule
    ],
	providers:[
		InlineSvgService
	]
})
export class InlineSvgModule {
}
