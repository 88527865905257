import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
	selector: "okaPulldown2-renderer",
	template: `
	<ng-container *ngIf="selectedItem">
		<okaPulldown2 [options]="items" 
			[okaPulldownStyle]="okaPulldownStyle" [menuStyle]="okaPulldownMenuStyle"
			bindLabel="label" bindValue="value"
			[(ngModel)]="params.output" (ngModelChange)="pulldownChange($event)"></okaPulldown2> 
	</ng-container>
	
    `,
	styleUrls: ['./okaPulldown2.renderer.scss']
})


export class OkaPulldown2Renderer implements AfterViewInit {
	public params: any;
	public text = '';
	public items:any[] = [];
	public selectedItem;
	public okaPulldownStyle:any = {};
	public okaPulldownMenuStyle:any = {};
	ngAfterViewInit() {

	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		if (this.params.okaPulldownStyle){
			this.okaPulldownStyle = this.params.okaPulldownStyle;
		}
		this.items = this.params.items;
		this.selectedItem = this.params.output;
		this.disableTransform();
	}
	

	refresh(params: ICellRendererParams){
		let value = this.params.output;
		this.selectedItem = this.items.find(e=> e.value == value);
		return true;
	}

	isPopup(): boolean {
        return true;
    }

	pulldownChange(event){
		let selectedItem = this.items.find(e=>e.value == event);
		if (selectedItem.callback){
			selectedItem.callback(this.params.data);
		}
	}

	disableTransform(){
		let ele = this.params.eGridCell.closest('.ag-center-cols-clipper');
		ele.style.transform = 'none';
	}


}