import { Injectable } from '@angular/core';
import { CSSLoader, ScriptLoader } from "src/app/common/ScriptLoader";
import { AssetService } from './AssetService';
import { MRUCache, MRUCacheProxy } from '../common/LinkedList';
import { PromiseUtils } from '../common/PromiseUtils';
import { PromiseQueue } from '../common/PromiseQueue';



@Injectable({ providedIn: 'root' })
export class PDFService
{
	public definition:any;
	mruCacheProxy: MRUCacheProxy;
	private loadingTask:Promise<any>
	public promiseQueue:PromiseQueue
	constructor(private assetService:AssetService)
	{
		this.promiseQueue = new PromiseQueue(1);
		this.setupAllSettledPromise();
		this.mruCacheProxy = new MRUCacheProxy(5, this.onDocumentTearDown.bind(this));
	}
	 
	private onDocumentTearDown(document:any):void
	{
		document.cleanup();
		document.destroy();
	}
	
	public load():Promise<any>
	{
		if(this.definition)
		{
			return Promise.resolve(this.definition);
		}
		if(this.loadingTask)
		{
			return this.loadingTask;
		}
		this.loadingTask = this.loadPDFCSS().then(()=>{
			return this.loadPDFLibrary();
		});
		this.loadingTask.then((o:any)=>{
			this.loadingTask = null;
		}).catch((reason:any)=>{
			this.loadingTask = null;
		});
		return this.loadingTask;
	}

	getDocument(src:any, type:string = "string"):Promise<any>
	{
		var info:any = {
			cMapUrl:this.assetService.getPath("pdfjs/3.9.179/node_modules/pdfjs-dist/cmaps/")
		}
		if (type == "string" ) {
			info.url = src;
		} else if (type == "ArrayBuffer" ) // if(src is ArrayBuffer)
		{
			// debugger;
			// if (isArrayBuffer(src)) 
			info.data = src;
			// src = { data: src };
		}
		var fn = ()=>{
			return this.load().then((def)=>{
				return this.definition.pdfjsLib.getDocument(info).promise;
			});
		};

		return this.promiseQueue.add(() => {
			if(type == "string")
			{
				return this.mruCacheProxy.fetch(src, fn);
			} else {
				return fn();
			}
		});
	}
	private setupAllSettledPromise():void
	{
		var p:any = Promise;
		if(!p.allSettled)
		{
			p.allSettled = ((promises) => Promise.all(
				promises.map((p) => 
				{
					return Promise.resolve(p).then(value => ({
						status: "fulfilled",
						value
					}))
					.catch(reason => ({
						status: "rejected",
						reason
					}))
				})
			));
		}
	}
	private loadPDFCSS():Promise<any>
	{
		console.log("------------------load pdf css------------------");
		// <link rel="stylesheet" href="/assets/pdfjs/3.9.179/node_modules/pdfjs-dist/web/pdf_viewer.css">
		return CSSLoader.loadCSS(
			this.assetService.getPath("pdfjs/3.9.179/node_modules/pdfjs-dist/web/pdf_viewer.css")
		);
	}
	private loadPDFLibrary():Promise<any>
	{
		console.log("------------------loadPDFLibrary------------------");
		var list:any [] = [
			{url:"pdfjs/3.9.179/node_modules/pdfjs-dist/build/pdf.js", name:"pdfjsLib"},
			{url:"pdfjs/3.9.179/node_modules/pdfjs-dist/web/pdf_viewer.js", name:"pdfjsViewer"}
		];
		list.forEach((item)=>{
			item.url = this.assetService.getPath(item.url);
		})

		return ScriptLoader.loadArray(list).then((instances:any [])=>{
			var definition = {
				pdfjsLib:instances[0],
				pdfjsViewer:instances[1]
			}
			definition.pdfjsLib.GlobalWorkerOptions.workerSrc = this.assetService.getPath("pdfjs/3.9.179/node_modules/pdfjs-dist/build/pdf.worker.js");
			this.definition = definition;
			return definition;
		});
	}

	public getPDFPage(document, pageNo:number):Promise<any>
	{
		if(pageNo == 0) return Promise.reject("invalid page number")
		if(pageNo < 0) pageNo = document.numPages + pageNo
		if(pageNo > document.numPages) return Promise.reject("page number out of range");

		return this.promiseQueue.add(()=>{
			return document.getPage(pageNo);
		});
	}

	public renderPageAsCanvas(canvas:HTMLCanvasElement, page, scale:number = 4):Promise<any>
	{
		return this.promiseQueue.add(()=>{
			// you can now use *page* here
			var viewport = page.getViewport({scale:scale});
			if(!canvas) canvas = document.createElement("canvas");
			canvas.style.position = "absolute";
			canvas.style.top = "0px";
			canvas.style.left = "0px";
			canvas.style.width  = "100%";
			canvas.style.height  = "100%";

			var context = canvas.getContext('2d');
			canvas.height = viewport.height;
			canvas.width = viewport.width;
			// this.elementRef.nativeElement.appendChild(canvas);

			// Render PDF page into canvas context
			var renderContext = {
				  canvasContext: context,
				  viewport: viewport
			};
			var renderTask = page.render(renderContext);
			renderTask.promise.then(() => {
				console.log('Page rendered');
			});

			return Promise.resolve(canvas);
		})
		
	}
}