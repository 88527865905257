import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NumberStepperComponent } from './numberStepper.component';


@NgModule({
    imports: [
      CommonModule,
      FontAwesomeModule,
      TranslateModule
    ],
    declarations: [
        NumberStepperComponent
    ],
    exports: [
        NumberStepperComponent
    ],
})
export class NumberStepperModule { }
