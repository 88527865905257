import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OpenBookHandler } from './openBook.handler';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { Modal2Module } from '../modal2Module/modal2.module';
import { LoadingModule } from '../loadingModule/loading.module';
import { InlineSvgModule } from '../inlineSvgModule/inlineSvg.module';
import { CourseBookRepeatOpenModal } from './courseBookRepeatOpen.modal';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { StudentReadingReportModalModule } from '../studentReadingReportModalModule/studentReadingReportModal.module';
import { OtherBookTypeSubmissionModalModule } from '../otherBookTypeSubmissionModalModule/otherBookTypeSubmissionModal.module';
import { PreviewModule } from '../previewModule/preview.module';
import { CodeScanModule } from '../codeScanModule/codeScan.module';
import { BookTimeLimitModal } from './bookTimeLimit.modal';
import { AssessmentEditNoRouterModule } from './assessmentEditNoRouterModule/assessmentEditNoRouter.module';
import { InstallAppModalModule } from '../installAppModalModule/installAppModal.module';
import { BookReadOnlyModal } from './bookReadOnly.modal';
import { CollectionModal } from './collection.modal';
import { Alert2Module } from '../alert2Module/alert2.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AiOralPracticeModule } from '../aiOralPracticeModule/ai-oral-practice.module';
import { BookFinishModal } from './bookFinish.modal';

@NgModule({
	imports: [
		CommonModule, TranslateModule, NgSelectModule, FormsModule, Modal2Module, CodeScanModule,
		LoadingModule, InlineSvgModule, BubbleBox2Module, StudentReadingReportModalModule, PreviewModule,
		OtherBookTypeSubmissionModalModule, AssessmentEditNoRouterModule, InstallAppModalModule, Alert2Module,
		FontAwesomeModule, AiOralPracticeModule
	],
	declarations: [
		OpenBookHandler, CourseBookRepeatOpenModal,
		BookTimeLimitModal, BookReadOnlyModal, CollectionModal, BookFinishModal
	],
	exports: [
		OpenBookHandler
	],
	providers: [
	],
	entryComponents: [OpenBookHandler]
})
export class OpenBookModule {
}
