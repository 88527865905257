import { fromEvent, Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { ChineseTextHelper } from "src/app/common/ChineseTextHelper";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import { ScriptLoader } from "src/app/common/ScriptLoader";
import { CacheResourceSource, ResourceSource } from "./ROContext";


export class CompactLanguageDetector
{
	
	constructor()
	{

	}
	
	
	
	static detectWithEngine(engine: any, text: string) :Promise<any>
	{
		if(!engine || engine.name == "auto")
		{
			return CompactLanguageDetector.detect(text);
		} else {
			return Promise.resolve(engine.lang);
		}
	}

	static detect(text:string):Promise<any>
	{
		return ScriptLoader.load("assets/js/cld-min.js", "detectLanguage").then(
			(detectLanguage)=>{
				// // Chineset // Japanese // Chinese // English
				var lang = detectLanguage(text);;
				if(lang == "English")
				{
					return "en-US";
				} else {
					var browserLanguage = navigator.language;
					if(browserLanguage == "zh-TW" || browserLanguage == "zh-HK" )
					{
						if(ChineseTextHelper.hasSimplifiedChinese(text))
						{
							return "zh-CN";
						} else {
							return "zh-HK";
						}
					} else {
						if(ChineseTextHelper.hasTraditionalChinese(text))
						{
							return "zh-HK";
						} else {
							return "zh-CN";
						}
					}
					
				}
			}
		);
	}
}
/*
export class TTSManager
{
	constructor()
	{
		// window.speechSynthesis
		var voices = speechSynthesis.getVoices();
		console.log("TTSManager", "voices", voices);
	}
	createUtterance(text:string, lang:any):SpeechSynthesisUtterance
	{
		var utterance:SpeechSynthesisUtterance = new SpeechSynthesisUtterance();
  		utterance.text = text;
		var voices = speechSynthesis.getVoices()
		console.log("voices", voices);
		
		var avaiableVoices = voices.filter(function(voice) { 
			return voice.lang == lang; 
		});
		utterance.voice = avaiableVoices[0];
		
		return utterance;
	}
	
	setupSubject(utterance:SpeechSynthesisUtterance):Observable<any>
	{
		// Queue this utterance.
		var subject:Subject<any> = new Subject();
		var eventList:string [] = ["start", "pause", "end", "resume", "error", "boundary"];
		eventList.forEach((name:string)=>{
			utterance.addEventListener(name, (event:any)=>{
				subject.next({
					type:'event',
					event:event
				});
				if(event.type == "start")
				if(event.type == "end")
				{
					subject.complete();
				} else if(event.type == "error")
				{
					subject.error(event);
				}
			})
		});
		
		return subject;
	}
	
	public speak(utterance:SpeechSynthesisUtterance):void
	{
		window.speechSynthesis.speak(utterance);
	}

	
	speak(text:string, lang:any):Observable<any>
	{
		var utterance:SpeechSynthesisUtterance =this.createUtterance(text, lang);
		return this.setupSubject(utterance);
		
	}
	
}
*/
export class AudioPlayer
{
	audio: HTMLAudioElement;
	constructor()
	{
		this.audio = document.createElement('audio');
		this.audio.autoplay = true;
	}

	public load(url:string):void
	{
		this.audio.src = url;
		this.audio.load();
	}
}
export class ROAudioPlayerManager
{
	
	// private ttsPlayer:TTSManager;
	// private audioPlayer:AudioPlayer;
	// private reference:any;
	public subject:Subject<any>;

	constructor(private source:CacheResourceSource)
	{
		this.subject = new ReplaySubject(1);
	}
	/*
	public test():void
	{
		// var player = new MyAudioPlayer("https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3");
		var player = new MyAudioPlayer(
			"https://samplelib.com/lib/preview/mp3/sample-6s.mp3"
		);
		this.subject.next({type:"player", player:player});
	}
	*/
	
	
	/**
	 * 
	 * @param target 
		{
	 		"asset": {
			    "checksum": "6AE64094",
			    "resource": "OKG://id/479756/checksum/6AE64094/type/1/title/C1_bk101_V8.mp3",
			    "folderId": "63",
			    "groupKey": "elfie",
			    "thumbnail": "//oka.blob.core.windows.net/media/",
			    "id": "479756",
			    "type": "2",
			    "title": "C1_bk101_V8.mp3",
			    "server": "1",
			    "url": "//oka.blob.core.windows.net/media/resource/2017/08/16/100914_6AE64094"
		  	},
	 	}
		{
			type:"url"
	 		"url": "//oka.blob.core.windows.net/media/resource/2017/08/16/100914_6AE64094"
	 	},
		{
		  "tts": {
		    "setting": {
		      "speed": 1,
		      "pitch": 1,
		      "preprocess": 0
		    },
		    "custom": 1,
		    "text": "中文字",
		    "engine": {
		      "name": "auto",
		      "gender": "M",
		      "lang": "inherit"
		    }
		  },
		  "type": "tts"
		} 
		//////////

	 * @returns 
	 */
	public createReference(target:any):any
	{
		// var subject:Subject<any> = new ReplaySubject(1);
		return {
			target:target,
			player:null
		};
	}

	
	/*
	public playURL(src:string, top:boolean = false):Observable<any>
	{
		var subject:Subject<any>;
		if(!this.replaceReference(src))
		{
			if(!this.reference.subject)
			{
				subject = new ReplaySubject(1);
				this.reference.subject = subject;
				subject.error("unknown");
			}
			return this.reference.subject;
		}

		subject = new ReplaySubject(1);
		this.reference.subject = subject;

		var tmp:any = this.reference;
		var p:Promise<string>
		if(tmp.url)
		{
			p = Promise.resolve(tmp.url);
		} else {
			p = this.source.lookupResource(src);
		}
		p.then((url:string)=>{
			tmp.url = url;
			this.audioPlayer.load(url);
			subject.complete();
		})
		return subject;
	}
	*/
	/*
	public playText(text:string, lang:string = null):Observable<any>
	{
		var engine:any ={};
		if(lang)
		{
			engine.lang = lang;
		} else {
			engine.name = "auto";
			engine.lang = null;
		}
		return this.play({
			type:"tts",
			engine:engine,
			tts:{
				text:text
			}
		});
	}
	*/
	public focusPlayer(player:IAudioPlayer):void
	{
		this.subject.next({type:"player", player:player});
	}
	private encodeB64(buffer:ArrayBuffer):string
	{
		var binary = '';
		var bytes = new Uint8Array( buffer );
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode( bytes[ i ] );
		}
		return window.btoa( binary );
	}
	private convertURLToDataURI(url:string):Promise<string>
	{
		return ByteLoader.get(url).then((bytes:ArrayBuffer)=>{
			var base64:string = this.encodeB64(bytes);
			var dataURI:string = "data:audio/mpeg;base64," + base64;
			return dataURI;
		});
	}
	public createPlayer(snd:any):IAudioPlayer
	{
		// var snd:any = reference.target;
		if(snd.type == "url")
		{
			return new MyAudioPlayer(()=>{
				return this.source.lookupResource(snd.url).then((url:string)=>{
					return this.convertURLToDataURI(url);
				});
			});
		} else if(snd.type == "asset")
		{
			var resourcePath = snd.asset.resource;
			return new MyAudioPlayer(()=>{
				return this.source.lookupResource(resourcePath).then((url:string) => {
					return this.convertURLToDataURI(url);
				})
			})
		} else if(snd.type == "tts")
		{
			return new MyTTSTextPlayer(
				()=>{
					var tts:any = snd.tts;
					if(!tts.text)
					{
						return Promise.reject("empty text");
					}
					return CompactLanguageDetector.detectWithEngine(tts.engine, tts.text).then((lang:string)=>{
						return {
							text:snd.tts.text, 
							lang:lang
						};
					});
				}
			)
			return null;
			
		}
		return null;
	}

	playText(text:string, lang:string):Promise<any>
	{
		if(!text)
		{
			return Promise.reject("empty text");
		}
		return new Promise((resolve:Function, reject:Function)=>{
			var player:MyTTSTextPlayer = new MyTTSTextPlayer(
				()=>{
					return Promise.resolve({
						text:text, 
						lang:lang
					});
				}
			)
			var sub:Subscription = player.subject.subscribe((o:any)=>{
				if(o.type == "ready")
				{
					player.play();
				} else if(o.type == "state")
				{
					if(o.state == "completed")
					{
						resolve(null);
						sub.unsubscribe();
					}
				}
			});
			player.load();
		})
	}
	
}

/////////////

// handler
export interface IAudioPlayer
{
	subject:Subject<any>;
	isReady:boolean;
	load(): Promise<any>;
	toggle(): void;
	play():void;
	stop():void;
	pause():void;
	resume():void;
	moveTo(time:number):void;
	getDuration():number;
	getCurrentTime(): number;
	hasTimeline():boolean;
	close():void;
};
export class MyTTSTextPlayer implements IAudioPlayer
{
	subject: Subject<any>;
	utterance:SpeechSynthesisUtterance;
	public state:string;
	public inSession:boolean;
	public currentTime:number = 0;
	isReady:boolean = false;
	constructor(private src:any)
	{
		//  text:string, lang:string
		//  text:string, lang:string
		this.subject = new Subject();
	
		
	}
	private textLength:number;
	private hasCompleted:boolean;
	private duration:number = 1;
	
	createUtterance(text:string, lang:any):Promise<SpeechSynthesisUtterance>
	{
		return this.getVoiceList().then((voices:any [])=>{
			var utterance:SpeechSynthesisUtterance = new SpeechSynthesisUtterance();
			var avaiableVoices = voices.filter(function(voice) { 
				return voice.lang == lang; 
			});
			if(avaiableVoices.length)
			{
				utterance.voice = avaiableVoices[0];
			}
			utterance.text = text;
			return utterance;
		});
	}

	getVoiceList():Promise<any []>
	{
		var voices:any [] = speechSynthesis.getVoices()
		if(voices.length)
		{
			return Promise.resolve(voices);
		} else {
			return PromiseUtils.delay(100, null).then(()=>{
				return speechSynthesis.getVoices();
			});
		}
	}

	setupSubject(utterance:SpeechSynthesisUtterance):void
	{
		var eventList:string [] = ["start", "pause", "end", "resume", "error", "boundary"];
		eventList.forEach((name:string)=>{
			utterance.addEventListener(name, (event:SpeechSynthesisEvent)=>{
				/*
				this.subject.next({
					type:'event',
					event:event
				});
				*/
				console.log("utterance.event", event.type);
				if(event.type == "error")
				{
					this.subject.error(event);
				} else if(event.type == "start")
				{
					this.inSession = true;
					this.subject.next({type:"state", state:"playing"});
				} else if(event.type == "pause")
				{
					this.subject.next({type:"state", state:"paused"});
				} else if(event.type == "resume")
				{
					this.subject.next({type:"state", state:"playing"});
				} else if(event.type == "end")
				{
					this.subject.next({type:"state", state:"completed"});
					this.hasCompleted = true;
					this.duration = event.elapsedTime / 1000;
					this.inSession = false;
				} else if(event.type == "boundary")
				{
					/*
					this.currentTime =  event.elapsedTime / 1000;
					if(this.hasCompleted)
					{
						this.subject.next({
							type:"time", time:this.currentTime, list:[event.charIndex, event.charLength , this.textLength
						]});
					} else if(!this.hasCompleted)
					{
						if(event.charIndex == 0 && event.charLength == 0)
						{
							this.duration = 100 * this.currentTime;
						} else {
							var ratio:number  = (event.charIndex == 0) ? 
								(event.charLength / 2  / this.textLength): 
								(event.charIndex / this.textLength);
							if(ratio)
							{
								this.duration = this.currentTime / ratio;
							} else {
								this.duration = this.currentTime;
							}
						}
						this.subject.next({
							type:"time", time:this.currentTime, list:[event.charIndex, event.charLength , this.textLength
						]});
						this.subject.next({type:"duration", duration:this.duration});
					}
					*/
				}
			})
		});
	}
	
	load(): Promise<any> {
		var fn = this.src;
		return fn().then((info:any)=>{
			this.textLength = info.text.length;
			return this.createUtterance(info.text, info.lang).then((utterance)=>{
				this.utterance = utterance;
				this.setupSubject(this.utterance);
				return null;
			});
		}).then(()=>{
			this.isReady = true;
			this.subject.next({type:"ready", player:this});
		});
	}
	toggle(): void {
		if(this.inSession)
		{
			if(window.speechSynthesis.speaking)
			{
				console.log("toggle->window.speechSynthesis.pause();");
				window.speechSynthesis.pause();
			} else {
				console.log("toggle->window.speechSynthesis.resume();");
				window.speechSynthesis.resume();
			}
		} else {
			console.log("toggle->window.speechSynthesis.speak(this.utterance);");
			window.speechSynthesis.speak(this.utterance);
		}
	}
	
	play(): void {
		
		if(this.inSession)
		{
			if(!window.speechSynthesis.speaking)
			{
				console.log("play->window.speechSynthesis.resume();");
				window.speechSynthesis.resume();
			}
		} else {
			if(window.speechSynthesis.speaking)
			{
				window.speechSynthesis.cancel();
			}
			console.log("play->window.speechSynthesis.speak(this.utterance);");
			window.speechSynthesis.speak(this.utterance);
		}
	}
	stop(): void {
		if(this.inSession && window.speechSynthesis.speaking)
		{
			console.log("stop->window.speechSynthesis.cancel();");
			window.speechSynthesis.cancel();
		}
	}
	pause(): void {
		if(this.inSession && window.speechSynthesis.speaking)
		{
			console.log("pause->window.speechSynthesis.pause();");
			window.speechSynthesis.pause();
		}
	}
	resume(): void {
		if(this.inSession)
		{
			console.log("resume->window.speechSynthesis.resume();");
			window.speechSynthesis.resume();
		}
	}
	moveTo(time: number): void {
		// not support
	}
	getDuration(): number {
		return 1;
	}
	getCurrentTime(): number {
		return this.currentTime;
	}
	hasTimeline(): boolean {
		return false;
	}
	close(): void {
		this.subject.next({type:"close"});
	}
	
}
class MyAudioPlayer implements IAudioPlayer
{
	audio: HTMLAudioElement;
	public subject:Subject<any>;
	state: string = "idle";
	public duration:number = 1;
	public currentTime:number = 0;
	public isReady:boolean;
	constructor(private src:any)
	{
		this.audio = new Audio();
		this.subject = new Subject();
		var sub:Subject<any> = this.subject;
		var map:any = {
			play:(event)=>{
				this.state = "playing";
				sub.next({type:"state", state:"playing"});
			},
			playing:(event)=>{
				this.state = "playing";
				sub.next({type:"state", state:"playing"});
			},
			timeupdate:(event:any)=>{
				this.currentTime = event.currentTarget.currentTime;
				sub.next({type:"time", time:event.currentTarget.currentTime});
				// this.playbackTime = event.currentTarget.currentTime * 1000;
				// console.log(this.playbackTime);
			},
			ended:(event)=>{
				this.state = "completed";
				sub.next({type:"state", state:"completed"});
			},
			pause:(event)=>{
				this.state = "paused";
				sub.next({type:"state", state:"paused"});
			},
			suspend:(event)=>{
				this.state = "idle";
				sub.next({type:"state", state:"idle"});
			},
			loadeddata:(event)=>{
				this.duration = event.currentTarget.duration;
				sub.next({type:"duration", duration:event.currentTarget.duration});
				// this.duration = this._snd.duration * 1000;
			}
		};
		for(var eventName in map)
		{
			this.audio.addEventListener(eventName, (e:any)=>{
				// console.log(e.type);
				map[e.type].call(this, e);
			})
		}
		
	}
	getCurrentTime(): number {
		return this.currentTime;
	}
	load(): Promise<any> {
		var p:Promise<any>;
		if(typeof this.src == "string")
		{
			this.audio.src = this.src;
			p = Promise.resolve(null);
		} else {
			var fn = this.src;
			p = fn().then((url:string)=>{
				this.audio.src = url;
				return null;
			});
		}
		return p.then(()=>{
			this.isReady = true;
			this.subject.next({type:"ready", player:this});
		})
	}
	toggle(): void {
		if(this.state == "playing")
		{
			this.pause();
		} else {
			this.play();
		}
	}
	close(): void {
		this.subject.next({type:"close"});
	}
	
	pause(): void {
		this.audio.pause();
	}
	resume(): void {
		this.audio.play();
	}
	play() {
		this.audio.play();
	}
	stop() {
		this.audio.pause();
		this.audio.currentTime = 0;
	}

	moveTo(time: number): void {
		this.audio.currentTime = time;
	}

	getDuration(): number {
		return this.duration;
	}
	hasTimeline(): boolean {
		return true;
	}
}
class TestCode{
	public ui:any;
	public myPlayer:IAudioPlayer;
	constructor()
	{
		var playerManager:ROAudioPlayerManager2 = new ROAudioPlayerManager2(null);
		// Audio Player Setup
		/*
		playerManager.addPlayer("url",
			(data:any):Promise<any>=>{
				return Promise.resolve(
					new MyAudioPlayer(data.url)
				);
			}
		)
		*/
			
		// UI
		playerManager.subject.subscribe((data)=>{
			if(data.type == "player")
			{
				this.ui.attach(data.player);
			}
		});

		// Client
		// var reference:any = playerManager.createReference({type:"url", url:"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"});
		var reference:any = playerManager.createReference({
			type:"url", 
			// url:"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
			url:"https://samplelib.com/lib/preview/mp3/sample-6s.mp3"
		});
		playerManager.getPlayer(reference).then((player:IAudioPlayer)=>{
			this.myPlayer = player;
			playerManager.assignPlayer(this.myPlayer);
			this.myPlayer.play();
		})
		
	}
}
export class ROAudioPlayerManager2
{
	
	public subject:Subject<any>;
	private handlerMap:any;
	constructor(private source:CacheResourceSource)
	{
		this.handlerMap = {};
	}

	public addPlayer(type:string, handler:any)
	{
		this.handlerMap[type] = handler;
	}

	
	public createReference(target:any):any
	{
		var subject:Subject<any> = new ReplaySubject(1);
		var reference:any = {
			type:target.type,
			target:target
		};
		return reference;
	}

	public getPlayer(reference:any):Promise<IAudioPlayer>
	{
		if(this.handlerMap.hasOwnProperty(reference.type))
		{
			return this.handlerMap[reference.type](reference);
		} else {
			return Promise.reject("no handler");
		}
	}
	public assignPlayer(player:IAudioPlayer):void
	{
		this.subject.next({"type":"player", player:player});
	}
	
}

class ByteLoader
{
	constructor()
	{

	}

	static load(method, url):Promise<ArrayBuffer>
	{
		return new Promise((resolve, reject)=>{
			var xmlHttpRequest = new XMLHttpRequest();
			xmlHttpRequest.open(method, url, true);
			xmlHttpRequest.responseType = "arraybuffer";
			xmlHttpRequest.onreadystatechange =  function()
			{
				var readyState = xmlHttpRequest.readyState;
				if (readyState === 4) {
					var status = xmlHttpRequest.status;
					switch (status) {
						case 200:
						case 304:
							resolve(xmlHttpRequest.response);
							break;
						default :
							// alert(xmlHttpRequest.statusText);
							reject(`load ${url} failed-`+ xmlHttpRequest.statusText);
							break;
					}
				}
			}.bind(this);
			xmlHttpRequest.send(null);	
		});
	}
	static post(url):Promise<ArrayBuffer>
	{
		return ByteLoader.load("POST", url);
	}
	static get(url):Promise<ArrayBuffer>
	{
		return ByteLoader.load("GET", url);
	}
}