import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { CheckBoxRenderer } from 'src/app/sharedModule/customAgGridModule/checkbox.renderer';
import { CheckBoxHeader } from 'src/app/sharedModule/customAgGridModule/checkbox.header';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { NoRowsOverlay } from 'src/app/sharedModule/customAgGridModule/noRows.overlay';
import { AgGridService } from 'src/app/service/agGrid.service';
import { ThemeService } from 'src/app/service/theme.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'select-library-modal',
	template: `
  <div class="select-library-modal" *ngIf="classNames">
	<div class="modal-header">
		<a class="modal-header__cancel" (click)="cancelClick($event)">{{'commonService.cancel'|translate}}</a>
		<div class="modal-header__title">{{'open-book.select-e-book'|translate}}</div>
		<a class="modal-header__confirm" (click)="clickSubject.next('confirm')">{{'open-book.add'|translate}}</a>
	</div>
	<div class="modal-body">	
		<ag-grid-angular 
		[gridOptions]="agGridOptions" (gridReady)="gridReady($event)"
		class="ag-theme-alpine useCssVar courseModule-enroll {{datas.dev.isMobile?'mobile':''}}"
		></ag-grid-angular>
	</div>
  </div>
  `
	, styleUrls: ['./selectLibrary.modal.scss'],
})



export class SelectLibraryModal implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	public okaPulldownStyle = { width: '204px' };
	public okaPulldownMenuStyle = {};
	public agGridOptions;
	public classNames;
	public clickSubject = new Subject();
	public rowData: any = [];
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	constructor(private load: LoadingService, public datas: DataService, private als: AlertService, private ag:AgGridService, private eleRef:ElementRef,private theme: ThemeService, private trans: TranslateService) {
	}

	ngOnInit() {
		this.initTheme(this.eleRef.nativeElement);
		this.load.add('listBookClass');
		this.datas.post('ROLibrary.listBookClass', [this.datas.lang]).subscribe((res:any)=>{
			this.classNames = res.rows;
			this.initAgGrid();
			this.load.remove('listBookClass');
		});
	}

	ngAfterViewInit() {
		this.clickSubject.pipe(throttleTime(100)).subscribe(e => {
			if (e == 'confirm') {
				let rows = this.agGridOptions.api.getSelectedRows();
				this.close.emit(rows[0]);
			}
		});
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	initTheme(ele){
		this.theme.applyStyleObj({
			default: {
				'aggrid-bgcolor':'#fff',
				'aggrid-headerbgcolor':'#ededed',
				'color-3A2E64-fff':"#3a2e64",
			},
			dark: {
				'aggrid-bgcolor':'#2D2C43',
				'aggrid-headerbgcolor':'#181821',
				'color-3A2E64-fff':"#fff",
			}
		}, ele);
	}

	initAgGrid() {
		let columnDefs = [
			{
				headerName: { 'tc': '圖書名稱', 'sc': '图書名称', 'en': 'Book title' }[this.datas.lang],
				field: 'title', width: 190, filter: 'agTextColumnFilter',
				valueGetter: event=> event.data.title && event.data.title != 'null'?event.data.title:''
			},
			{
				headerName: { 'tc': '分類', 'sc': '分類', 'en': 'Classification' }[this.datas.lang],
				field: 'classification', width: 120, filter: 'agTextColumnFilter',
				valueGetter: event => {
					let arr = event.data.class_id?event.data.class_id.split(','):[];
					return arr.map(e=> {
						let found = this.classNames.find(c=>c.id == e);
						return found?found.tag_name:null;
					}).filter(e=>e).join(',');
				}
			},
			{
				headerName: { 'tc': '作者', 'sc': '作者', 'en': 'Author' }[this.datas.lang],
				field: 'author', width: 190,filter: 'agTextColumnFilter',
				valueGetter: event=> event.data.author && event.data.author != 'null'?event.data.author:''
			},
			{
				headerName: { 'tc': '出版社', 'sc': '出版社', 'en': 'Publisher' }[this.datas.lang],
				field: 'publisher', width: 190,filter: 'agTextColumnFilter',
				valueGetter: event=> event.data.publisher && event.data.publisher != 'null'?event.data.publisher:''
			},
			{
				width: 50, suppressSizeToFit: true,
				cellRendererFramework: CheckBoxRenderer, cellClass: 'cell-button', resizable: false,
				cellRendererParams: { blockClick: true }, filter: false, floatingFilter: false,
				// headerComponent: 'checkBoxHeader',
				valueGetter: (params) => { return params.node.isSelected(); }
			},
		];
		this.agGridOptions = {
			rowDragManaged: false,
			animateRows: true,
			rowSelection: 'single',
			singleClickEdit: false,
			suppressRowTransform: true,
			suppressRowClickSelection: false,
			stopEditingWhenCellsLoseFocus: true,
			suppressDragLeaveHidesColumns: true,
			headerHeight: 50,
			rowHeight: 50,
			rowModelType:'serverSide',
			// serverSideInfiniteScroll: true,
			serverSideDatasource: this.myDataSource,
			serverSideStoreType: 'partial',
			cacheBlockSize: 20,
			maxBlocksInCache: 10,
			defaultColDef: {
				editable: false,
				resizable: true,
				suppressMenu: true,
				filter: true,
				floatingFilter: true,
				suppressSizeToFit: false,
				cellStyle: { fontSize: '15px' }
			},
			columnDefs: columnDefs,
			noRowsOverlayComponent: 'noRowsOverlay',
			noRowsOverlayComponentParams: { text: 'course.no-enroll-student' },
			frameworkComponents: {
				checkBoxRenderer: CheckBoxRenderer, checkBoxHeader: CheckBoxHeader,
				noRowsOverlay: NoRowsOverlay
			},
			onFilterChanged: event => {

			},
			onSelectionChanged: event=>{
				event.api.refreshCells();
			},
			onModelUpdated: event=>{
				event.api.sizeColumnsToFit();
			},
			onColumnResized: (event) => {
				if (event.column && event.source == 'uiColumnDragged') {
					event.column.colDef.suppressSizeToFit = true;
					const newColumns = event.api.getColumnDefs();
					event.api.setColumnDefs(newColumns);
				}
			},
			localeText: this.ag.localeObj
		};
	}

	gridReady(event) {
		event.api.hideOverlay();
	}

	get myDataSource(){
		return {
			getRows: event => {
				let request = event.request;
				console.log(request);
				/*if (event.request.filterModel['ag-Grid-AutoColumn']) {
					event.request.filterModel['country'] = event.request.filterModel['ag-Grid-AutoColumn'];
					delete params.request.filterModel['ag-Grid-AutoColumn'];
				}*/
				this.datas.post('ROLibrary.fetch_selection',[request]).subscribe((res:any)=>{
					if (res.books){
						res.books.forEach((b,i)=>{
							b.classification='小說';
						});
						let currentLastRow = (request.startRow || 0) + res.books.length;
						let count = currentLastRow < (request.endRow || 0) ? currentLastRow : currentLastRow + 60;
						event.success({rowData: res.books, rowCount: count});
					}
				});
			}
		};
	}

	cancelClick(event) {
		event.stopPropagation();
		this.close.emit();
	}

}
