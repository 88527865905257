import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip, forkJoin, BehaviorSubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { LoadingService } from '../loadingModule/loading.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { Modal2Component } from '../modal2Module/modal2.component';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faUserRobot } from '@fortawesome/pro-light-svg-icons';
import { environment } from 'src/environments/environment';
import { AICompositionMarkingDetails } from './aiCompositionMarkingDetails';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
	selector: 'ai-composition-marking-modal',
	template: `
	<modal2
		#modal class="{{theme.theme$.value}}-theme"
		[contentStyle]="modalContentStyle"
		(confirmClick)="confirm()"
		(cancelClick)="closeClick()"
		>
		<div class="customTitle" customTitle>
			{{modalTitle}}<div *ngIf="status && status != 'open'" class="status status-{{status||''}}">{{ statusText }}</div>
		</div>

		<div class="middle-bg"></div>
		<div class="middle-bg2 {{theme.theme$.value}}-theme"></div>
		<div class="middle">
			<div class="middle-checking-progress cp-{{checkingProgress}}">
				<div class="middle-checking-progress-label"> {{ {tc:'批改進度',sc:'批改進度',en:'Checking progress'}[lang] }} </div>
				<div class="middle-checking-progress-num"> {{ checkingProgressNum }}</div>
			</div>
			<div class="middle-3-pt-button" (click)="archiveSelectClick($event)">
				<fa-icon [icon]="faEllipsisVertical"></fa-icon>
			</div>
			<div class="middle-tag-name">{{ tagName }}</div>
		</div>

		<div class="bottom">
			<div class="left-member-selector">
				<ng-container *ngFor="let student of data.students">
					<div class="student cp-{{student.checked}}" *ngIf="showArchive || student.active== '1'" 
						[class.selected]="selectedStudent == student" (click)="studentChange(student)">
						<div class="student-classno" *ngIf="student.classno">{{student.classno}}</div>
						<div class="student__photo" [style.backgroundImage]="student.photo"></div>
						<div class="student__name">
							<span>{{student.name}}</span>
						</div>
					</div>
				</ng-container>
			</div>

			<ng-container *ngIf="selectedStudent">
				<ai-composition-marking-details 
					#details [enableAIButton]="enableAIButton"
					[data]="data" *ngIf="entryType != 'ai-oral'" (aiCheck)="aiCheckConfirm($event)">
				</ai-composition-marking-details>
				<ai-oral-marking-details 
					#details [enableAIButton]="enableAIButton"
					[selectedStudent]="selectedStudent"
					[data]="data" *ngIf="entryType == 'ai-oral'" (aiCheck)="aiCheckConfirm($event)">
				</ai-oral-marking-details>
			</ng-container>

		</div>
		
		<bubbleBox2 #aiCheckPopup padding="0" backgroundColor="var(--wpopup-bgcolor)">
			<div class="aiCheckPopup">
				<div class="aiCheckPopup-row">
					<div class="aiCheckPopup-label">{{ {tc:'AI總評', sc:'AI总评', en: 'Comment'}[lang] }}</div>
					<okaPulldown2 [options]="commentOptions" 
						[okaPulldownStyle]="{width: '200px'}"
						bindLabel="label" bindValue="value" 
						[(ngModel)]="commentOption"
						(ngModelChange)="commentOptionChange()"></okaPulldown2>
				</div>
				<div class="aiCheckPopup-panel">
					<div class="aiCheckPopup-check"
					(click)="confirmAiCheck()">{{ {tc:'開始評分',sc:'开始评分',en:'Start'}[lang] }}</div>
					<div class="aiCheckPopup-cancel" 
						(click)="aiCheckPopup.closeNow()">{{ {tc:'取消',sc:'取消',en:'Cancel'}[lang] }}</div>
				</div>
			</div>
		</bubbleBox2>

		<bubbleBox2 #studentListPopup padding="0" backgroundColor="var(--wpopup-bgcolor)">
			<div class="studentListPopup">
				<div class="switch">
					<div class="label">{{ {tc:'顯示封存學生', sc:'显示封存学生', en: 'Show archive'}[lang] }}</div>
					<div><ui-switch class="useCssVar" [checked]="showArchive" 
					(change)="toggleArchive($event)"></ui-switch></div>
				</div>
			</div>
		</bubbleBox2>

	</modal2>
	
	`,
	styleUrls: ['./aiCompositionMarking.modal.scss'],
})

export class AICompositionMarkingModal implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('modal', { static: false }) modal: Modal2Component;
	@ViewChild('aiCheckPopup', { static: false }) aiCheckPopup: BubbleBox2Component;
	@ViewChild('studentListPopup', { static: false }) studentListPopup: BubbleBox2Component;
	@ViewChild('details', { static: false }) details: AICompositionMarkingDetails;
	public photo;
	public modalTitle = '';
	public modalContentStyle: any = {};
	public confirmLabel;
	public info;
	public lang;
	public faEllipsisVertical = faEllipsisVertical;
	public status;
	public statusText;
	public faUserRobot = faUserRobot;
	public students: any = [];
	public selectedStudent: any;
	public showArchive = false;
	public commentOptions;
	public commentOption;
	public enableComment = true;
	public enableAIButton = true;
	public onDestroy = new Subject();
	public entryType = 'ai-composition';
	public checkingProgress = 'not-start';
	public checkingProgressNum = '';
	public tagName;
	constructor(private san: DomSanitizer, private trans: TranslateService, public datas: DataService, private http: HttpClient, private als: AlertService, public eleRef: ElementRef, public theme: ThemeService, private coms: CommonService, private lds: LoadingService, public zone: NgZone, public sans: DomSanitizer, private dcs: DynamicComponentService, private ntfs: NotificationService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.entryType = this.data.share.entry_type;
		if (this.data.share.details && this.data.share.details.subType == 'aiOral'){
			this.entryType = 'ai-oral';
		}
		this.modalTitle = this.data.share.name || this.data.share.title || '';
		this.modalContentStyle = { width: 'calc(100vw - 40px)', height: 'calc(100dvh - 94px)', overflow: 'hidden', padding: '0' };
		this.initTheme(this.eleRef.nativeElement);
		this.initOptions();
		this.getData();
		this.initTagName();
		this.test();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.modal.open().then(event => {

			});
		},10);

		if (this.coms.sectionComponent){
			this.coms.sectionComponent.eventEmitter.pipe(takeUntil(this.onDestroy)).subscribe(event=>{
				if (event.action == 'aiCheckStatusUpdate'){
					if (this.data.selectedUid){
						const student = this.data.students.find(e=> e.uid == this.data.selectedUid);
						this.studentChange(student, true);
						this.statusText = {tc:'已批改(AI)',sc:'已批改(AI)',en:'Marked(AI)'}[this.datas.lang];
					}
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	test() {
	}

	initOptions() {
		const lang = this.lang;
		this.commentOptions = [
			{label: {tc:'沒有AI總評', sc:'没有AI总评', en: 'No comment'}[lang], value: 0},
			{label: {tc:'大約10字以內', sc:'大約10字以內', en: 'Around 10 words'}[lang], value: 10},
			{label: {tc:'大約30字以內', sc:'大約30字以內', en: 'Around 30 words'}[lang], value: 30},
			{label: {tc:'大約50字以內', sc:'大約50字以內', en: 'Around 50 words'}[lang], value: 50}
		];
		this.commentOption = 10;
		this.status = this.data.share.status;
		this.statusText = this.data.share.statusText;
		this.enableAIButton = true;
		if (this.status == 'finished' || this.datas.userInfo.user_role == 2){
			this.enableAIButton = false;
		}
	}

	getData() {
		//getAICompositionMarkingInfo
		const bsid = this.data.share.bsid;
		const bookId = this.data.share.book_id;
		const tagId = this.data.share.assignee;
		if (this.data.students === undefined) {
			this.data.students = [];
		}

		const api = this.entryType == 'ai-oral' ? 'ROBookshelfNew.getAIOralMarkingInfo' : 'ROBookshelfNew.getAICompositionMarkingInfo';

		this.datas.post(api, [bsid, bookId, tagId]).subscribe((res: any) => {
			if (res.structure){
				this.data.structure = JSON.parse(res.structure);
				console.log(this.data.structure.book.chapters[0]);
				this.data.components = this.data.structure.book.chapters[0].pages[0].components.map(comp => comp.cid);
			}
			if (!this.data.info){ //course 的structure 沒有 info
				let info:any = { scoringItems: JSON.parse(res.info.scoring_items), passLevel: res.info.pass_level };
				this.data.info = info;
			}
			if (res.peoples) {
				this.data.students = res.peoples.map(e => this.formatPeople(e));
			}
			if (this.data.students.length > 0) {
				this.selectedStudent = this.data.students[0];
				this.data.selectedUid = this.selectedStudent.uid;
			}
			this.data.students = this.data.students.sort((a,b)=>{
				const aKey = ('' + a.classno).padStart(4,'0') + ('' +a.uid).padStart(8,'0');
				const bKey = ('' + b.classno).padStart(4,'0') + ('' +b.uid).padStart(8,'0');
				if (aKey > bKey){
					return 1;
				} else if (bKey > aKey){
					return -1;
				} else {
					return 0;
				}
			});

			this.updateCheckingProgress();
		});
	}

	initTheme(ele) {
		this.theme.applyStyleObj({
			default: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#333",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-selectedtextcolor": "var(--section-maincolor)",
				"pulldown-overbgcolor": "#e2e2e2",
				"popupform-panelbgcolor": "#f5f5f5",
				"color-ccc-38374f": "#ccc",
				"popupform-rowbgcolor": "#fff",
				"popupframe-headerbgcolor": "#fff",
				"popupframe-titlecolor": "#333",
				"popupform-subtitlecolor": "#333",
				"popupform-textcolor": "var(--section-maincolor)",
				"color-f9f9f9-1e1e29": "#f9f9f9",
				"color-333-fff": "#333",
				"alert-bgcolor": "#fff",
				"color-f1f1f7-262635": "#f1f1f7",
				"color-f7f7f7-222237": "#f7f7f7",
				"color-ececec-262635": "#ececec",
				"pulldown-selectedbgcolor": "var(--section-maincolor)"
			},
			dark: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-selectedtextcolor": "#fff",
				"pulldown-overbgcolor": "#1e1e29",
				"popupform-panelbgcolor": "#38374f",
				"color-ccc-38374f": "#38374f",
				"popupform-rowbgcolor": "#1e1e29",
				"popupframe-headerbgcolor": "#1e1e29",
				"popupframe-titlecolor": "#fff",
				"popupform-subtitlecolor": "#a0a0a0",
				"popupform-textcolor": "#fff",
				"color-f9f9f9-1e1e29": "#1e1e29",
				"color-333-fff": "#fff",
				"alert-bgcolor": "#2d2c43",
				"color-f1f1f7-262635": "#262635",
				"color-f7f7f7-222237": "#222237",
				"color-ececec-262635": "#262635",
				"pulldown-selectedbgcolor": "#fff"
			}
		}, ele);

	}

	formatPeople(student) {
		student.name = this.datas.lang == 'en' ? student.ename : student.cname;
		let url = '//dev.openknowledge.hk/RainbowOne/webapp/2.8/roAnalyticsChart/assets/img/avatar_student_' + (student.gender == 1 ? 'male' : 'female') + '.svg';
		if (student.url && student.server) {
			url = environment.resourceServer[student.server] + student.url;
		}
		student.photo = this.sans.bypassSecurityTrustStyle("url('" + url + "')");
		if (student.result){
			['answer','teacher_score','ai_score'].forEach(prop=>{
				if (student.result[prop]){
					student.result[prop] = JSON.parse(student.result[prop]);
				} else if (prop != 'answer') {
					student.result[prop] = this.data.info.scoringItems.map(e => null);
				}
			});
			const teacherScore = student.result?student.result.teacher_score || [] : [];
			const aiScore = student.result?student.result.ai_score || [] : [];
			if (teacherScore.indexOf(null) == -1){
				student.checked = 'teacher';
			} else if (teacherScore.find(e=> e !== null)){
				student.checked = 'dirty';
			} else if (aiScore.find(e=> e !== null)){
				student.checked = 'ai';
			} else {
				student.checked = 'not-start';
			}
			student.submitted = 1;
		} else {
			student.result = { comment_teacher: '', comment_ai: null, answer: { paragraphs: [] }, teacher_score: [], ai_score: [] };
			student.submitted = 0;
			student.checked = 'not-start';
		}
		return student;
	}

	confirm() {
		const bsid = this.data.share.bsid;
		const bookId = this.data.share.book_id;
		const components = this.data.components;
		let submissionDataChanges:any = [];
		let students = this.data.students.filter(e=> e.submitted).map(e=>{
			let totalScore = 0;
			let maxScore = 0;
			let greenCount = 0;
			let redCount = 0;
			let blueCount = 0;
			this.data.info.scoringItems.forEach((item,index)=>{
				const aiScore = e.result.ai_score[index];
				const teacherScore = e.result.teacher_score[index];
				const score = teacherScore || aiScore;
				totalScore += score || 0;
				maxScore += item.score;
				const correct = (score || 0) >= (this.data.info.passLevel / 100) * item.score ? 2 : 0;
				if (correct == 2) {
					greenCount++;
				} else if (score !== null) {
					redCount++;
				} else {
					blueCount++;
				}
				const resultObj = {correction: false, score: score, correct: correct, aiScore: aiScore, teacherScore: teacherScore };
				const sd = {uid: e.uid, bsid: bsid, cid: components[index], resultObj: JSON.stringify(resultObj) };
				submissionDataChanges.push(sd);
			});
			const pass = totalScore >= (this.data.info.passLevel / 100) * maxScore ? 1 : 0;
			return { uid: e.uid, teacher_score: e.result.teacher_score, teacher_comment: e.result.teacher_comment, total_score: totalScore, pass: pass, green_count: greenCount, red_count: redCount, blue_count: blueCount };
		});
		if (students.length > 0) {
			const bsid = this.data.share.bsid;
			this.lds.add('ROBookshelfNew.saveAICompositionTeacherScore', 4000);
			if(this.entryType == "ai-oral"){
				this.datas.post('ROBookshelfNew.saveAIOralTeacherScore', [bsid, bookId, students, submissionDataChanges]).subscribe((res:any)=>{
					this.lds.remove('ROBookshelfNew.saveAIOralTeacherScore');
					this.close.emit(this.data);
					this.dcs.close(this);
				});
			} else {
				this.datas.post('ROBookshelfNew.saveAICompositionTeacherScore', [bsid, bookId, students, submissionDataChanges]).subscribe((res: any) => {
					this.lds.remove('ROBookshelfNew.saveAICompositionTeacherScore');
					this.close.emit(this.data);
					this.dcs.close(this);
				});
			}
			return;
		}
		this.als.toastSuccess({ tc: '評語及手動輸入的分數已經儲存。', sc: '评语及手动输入的分数已经储存。', en: 'Comment and score inputed by manual were saved.' }[this.lang]);
		this.close.emit(this.data);
		this.dcs.close(this);
	}

	closeClick() {
		this.close.emit(null);
		this.dcs.close(this);
	}

	studentChange(student, forceUpdate = false) {
		this.selectedStudent = student;
		this.data.selectedUid = student.uid;
		if (student.aiAsks && !forceUpdate) {
			this.details.loadStudent();
		} else {
			if (this.entryType == 'ai-oral') {
				this.lds.add('ROBookshelfNew.getAIOralMarkingInfoForStudent', 5000);
				this.datas.post('ROBookshelfNew.getAIOralMarkingInfoForStudent', [this.data.share.bsid, student.uid]).subscribe((res: any) => {
					student.result = res.result;
					this.formatPeople(student);
					this.updateCheckingProgress();
					this.details.loadStudent();
					this.lds.remove('ROBookshelfNew.getAIOralMarkingInfoForStudent');
				});
			} else {
				this.lds.add('ROBookshelfNew.getAICompositionMarkingInfoForStudent', 5000);
				this.datas.post('ROBookshelfNew.getAICompositionMarkingInfoForStudent', [this.data.share.bsid, student.uid]).subscribe((res: any) => {
					student.aiAsks = res.aiAsks;
					student.result = res.result;
					this.formatPeople(student);
					this.updateCheckingProgress();
					this.details.loadStudent();
					this.lds.remove('ROBookshelfNew.getAICompositionMarkingInfoForStudent');
				});
			}
		}
	}



	archiveSelectClick($event) {
		const el = $event.target;
		this.studentListPopup.open(el, this.eleRef.nativeElement).catch(e => e);
	}

	toggleArchive($event) {
		this.showArchive = $event;
		if (!this.showArchive && this.selectedStudent && this.selectedStudent.active != '1') {
			let found = this.students.find(e => e.active == '1');
			if (found) {
				this.selectedStudent = found;
			}
		}
	}

	aiCheckClick($event) {
		const el = $event.target;
		this.aiCheckPopup.open(el, this.eleRef.nativeElement).catch(e => e);
	}

	async confirmAiCheck() {
		const lang = this.lang;
		const share = this.data.share;
		const bsid = share.bsid;
		const bookId = share.book_id;
		this.aiCheckPopup.closeNow();

		let options: any = { give_comment: this.enableComment, clear_old_ai: true, comment_word_limit: this.commentOption };

		if (this.entryType == 'ai-oral') {
			options.payload = { queryType: 'ai-oral', startTime: share.start_time, bsid: bsid, title: share.title, messageType: 'start', entryType: 'ai-oral' };
		} else {
			options.payload = { queryType: 'ai-composition', startTime: share.start_time, bsid: bsid, title: share.title, messageType: 'start', entryType: 'ai-composition' };
		}

		this.datas.post('ROBookshelfNew.getAICheckingStatus', [bsid]).subscribe((res: any) => {
			if (res.status == 'checking') {
				this.als.toastError({ tc: 'AI正在批改中，請稍後檢查。', sc: 'AI正在批改中，请稍后检查。', en: 'AI checking in progress. Please check later.' }[lang]);
			} else if (res.status == 'checked') {
				const msg = { tc: 'AI已曾評分。如重新評分後，已評的分數將被覆蓋，確定要重新評分？', sc: 'AI已曾评分。如重新评分后，已评的分数将被覆盖，确定要重新评分？', en: 'AI has already scored. After reevaluation, the previously scored scores will be overwritten. Confirm to proceed AI rescoring?' }[lang];
				const btnStyle = { fontSize: '17px' };
				const rescoringText = { tc: '重新評分', sc: '重新评分', en: 'Rescoring' }[lang];
				const rescoringBtn = { key: 'rescoring', label: rescoringText, ngStyle: { ...btnStyle, backgroundColor: 'var(--section-maincolor)' } };
				const keepText = { tc: '保留已評，只處理未評', sc: '保留已评，只处理未评', en: 'Keep scored, process unscored' }[lang];
				const keepBtn = { key: 'keep', label: keepText, ngStyle: { ...btnStyle, backgroundColor: 'var(--section-maincolor)' } };
				const cancelText = { tc: '取消', sc: '取消', en: 'Cancel' }[lang];
				const cancelBtn = { key: 'cancel', label: cancelText, ngStyle: btnStyle };
				this.als.alert2(msg, null, { btns: [[rescoringBtn], [keepBtn], [cancelBtn]] }).then((key: string) => {
					options.clear_old_ai = key == 'rescoring';
					if (key != 'cancel') {
						this.entryType == 'ai-oral' ? this.confirmAICheckOral(options) : this.confirmAICheckComposition(options);
					}
				});
			} else {
				this.entryType == 'ai-oral' ? this.confirmAICheckOral(options) : this.confirmAICheckComposition(options);
			}
		});
	}

	private confirmAICheckOral(options) {
		const lang = this.lang;
		const share = this.data.share;
		const bsid = share.bsid;
		const bookId = share.book_id;
		const hostname = 'dev.openknowledge.hk';
		const url = '//' + hostname + '/RainbowOne/index.php/PHPGateway/proxy/2.8';
		
		this.datas.post('ROChatGPT.checkOral', [bsid, bookId, options], url)
			.pipe(takeUntil(this.onDestroy))
			.subscribe((res:any)=>{
				/*
				const student = this.data.students.find(e=> e.uid == this.data.selectedUid);
				this.studentChange(student, true);
				this.statusText = {tc:'已批改(AI)',sc:'已批改(AI)',en:'Marked(AI)'}[lang];*/
		});
		setTimeout(()=>{
			if (this.coms.sectionComponent && location.href.indexOf('/bookshelf/') > -1){
				this.coms.sectionComponent.updateAggridRowStatus(bsid); 
				this.ntfs.checkNewMessage();
			} 
		}, 1000);
		this.status = 'checking';
		this.statusText = {tc:'批改中(AI)',sc:'批改中(AI)',en:'Marking(AI)'}[lang];
	}

	confirmAICheckComposition(options) {
		const lang = this.lang;
		const share = this.data.share;
		const bsid = share.bsid;
		const bookId = share.book_id;
		// this.datas.post('ROChatGPT.marking_all_ai_composition', [bsid, bookId, options])
		const hostname = 'dev.openknowledge.hk';
		const url = '//' + hostname + '/RainbowOne/index.php/PHPGateway/proxy/2.8';
		this.datas.post('ROChatGPT.marking_all_ai_composition_queue', [bsid, bookId, options], url)
			.pipe(takeUntil(this.onDestroy))
			.subscribe((res:any)=>{
				// this.datas.post('')
			// const student = this.data.students.find(e=> e.uid == this.data.selectedUid);
			// this.studentChange(student, true);
			// this.statusText = {tc:'已批改(AI)',sc:'已批改(AI)',en:'Marked(AI)'}[lang];
		});
		setTimeout(()=>{ 
			this.coms.sectionComponent.updateAggridRowStatus(bsid); 
			this.ntfs.checkNewMessage();
			this.ntfs.boost(360, 5, 60);
		}, 1000);
		this.status = 'checking';
		this.statusText = { tc: '批改中(AI)', sc: '批改中(AI)', en: 'Marking(AI)' }[lang];
	}

	updateCheckingProgress(){
		let checkedArray = [];
		const studentCheckeds = this.data.students.forEach(e=> {
			if (checkedArray.indexOf(e.checked) == -1){
				checkedArray.push(e.checked);
			}
		});
		if (checkedArray.length == 1){
			this.checkingProgress = checkedArray[0];
		} else if (checkedArray.indexOf('dirty') > -1 || checkedArray.indexOf('not-start') > -1) {
			this.checkingProgress = 'dirty';
		} else { // deemed ai + teacher
			this.checkingProgress = 'teacher';
		}
		const checkedNum = this.data.students.filter(e=> e.checked == 'ai' || e.checked == 'teacher').length;
		this.checkingProgressNum = checkedNum + '/' + this.data.students.length;
	}

	commentOptionChange(){
		if (this.commentOption == 0){
			this.enableComment = false;
		} else {
			this.enableComment = true;
		}
	}

	initTagName(){
		this.tagName = this.data.share.tag_name;
		if (this.tagName && this.tagName.indexOf('cid-') > -1){
			if (this.datas.lang == 'en'){
				this.tagName = this.data.share.tag_students_ename;
			} else {
				this.tagName = this.data.share.tag_students_cname;
			}
		}
	}

	aiCheckConfirm(option){
		this.enableComment = option.enableComment;
		this.commentOption = option.commentOption;
		this.confirmAiCheck();
	}

}
