import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { ROComponent } from "./ROComponent";

@Component({
	template:`<div class='epen-button button' (tap)="tapOnButton($event)"></div>`,
	styles:[
		`
		.epen-button{
			width: 100%;
			height: 100%;
			background-image: url(./assets/ro/component/epen_skin.svg);
			background-size: cover;
		}
		`
	]
})

export class ROEPenComponent extends ROComponent
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	tapOnButton(event):void
	{
		this.context.subject.next({
			type:"action", 
			action:"showEPen",
			data:this
		});
	}
	reset()
	{
		
	}

	assignData(answer:any):void
	{
		
	}

	public set data(value:string) {
		this.answerChanged = false;
		if(value)
		{
			try{
				var o:any = JSON.parse(value);
				this.assignData(o);
			} catch(err)
			{
				this.reset();
			}
		} else {
			this.reset();
		}
	}	
	
	public get data():string {
		
		return null;
	}

	
	public answerEnable(flag: boolean): void {
		super.answerEnable(flag);
	}

	public getTagNames():string []
	{
		return ["Epen"];
	}

	protected setDefaultXMLData():void
	{
		
	}
	
	protected buildContent():void
	{
		
	}

	public isQuestionComponent():boolean
	{
		return true;
	}
}
