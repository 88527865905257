// import { XMLParser as FXMLParser } from "fast-xml-parser";
import XMLJS from "xml-js";
import { XMLNode, XMLNodeIterator } from "./XMLNode";
import { ArrayUtils } from "src/app/common/ArrayUtils";
// import { js2xml, xml2js } from "xml-js";

export interface XMLNodeXMLParser
{
	parse(xml:string):XMLNode
}
/*
export class FastXMLParser implements XMLNodeXMLParser
{
	private parser: FXMLParser;
	constructor()
	{
		
		this.parser = new FXMLParser(
			{
				preserveOrder:true,
				attributeNamePrefix:"",
				attributesGroupName:"attr",
				textNodeName: ":@text",
				ignoreAttributes: false,
				removeNSPrefix:true,
				allowBooleanAttributes:true,
				parseTagValue: false,
				parseAttributeValue: true,
				trimValues: true,
				processEntities:true
			}
		);
	}
	
	public parse(xml:string):XMLNode
	{
		var nodes:any [] = this.parser.parse(xml);
		return new XMLNodeIterator().build(nodes[0],
			{
				assignTag:this.assignTag,
				assignAttributes:this.assignAttributes, 
				assignText:this.assignText,
				getChildren:this.getChildren
			}	
		);
	}

	private assignTag(element:any, nodeXML:XMLNode):void
	{
		for(var key in element)
		{
			if(key == ":@")
			{
				// this.attributes = node[key].attr;
			} else if(key == ":@text")
			{
				// this.text = node[key];
			} else {
				nodeXML.tag = key;
			}
		}
	}

	private getChildren(element:any):any [] 
	{
		var output = [];
		for(var key in element)
		{
			if(key == ":@")
			{
				// this.attributes = node[key].attr;
			} else if(key == ":@text")
			{
				// this.text = node[key];
			} else {
				return element[key];
			}
		}
		return output;

	}
	private assignAttributes(element:any, node:XMLNode):void
	{
		if(element && element.hasOwnProperty(":@"))
		{
			node.attributes = element[":@"].attr
		}
	}
	private assignText(element:any, node:XMLNode):void
	{
		if(element.hasOwnProperty(":@text"))
		{
			node.text = element[":@text"];
		}
	}
}
*/

export class XMLJSNode extends XMLNode
{
	constructor()
	{
		super();
	}

	public assign(o: any): XMLNode {
		var tag:string = o.tag;
		var attributes = o.attributes;
		var elements:any[] = o.children? o.children : [];
		this.initElement({
			type:"element",
			name:tag,
			attributes:attributes,
			elements:elements
		});
		return this;
	}

	public setAttribute(name: string, value: any): void {
		this.attributes[name] = value;
		this.element.attributes[name] = value;
	}

	public removeChild(childNode: XMLNode): void 
	{
		ArrayUtils.removeElement(this.children, childNode);
		ArrayUtils.removeElement(this.element.elements, childNode.element);
	}
	////////
	public initElement(element:any):XMLNode
	{
		this.element = element;
		this.tag = this.element.tag;
		this.assignTag(element, this);
		this.children = [];
		this.assignAttributes(element, this);
		this.assignText(element, this);
		if(this.element && this.element.elements)
		{
			this.element.elements.forEach((childElement:any)=>{
				if(childElement instanceof XMLNode)
				{
					this.children.push(childElement);
				} else {
					var childNode:XMLJSNode = new XMLJSNode();
					this.children.push(childNode);
					childNode.initElement(childElement);
				}
			})
		}
		return this;
	}

	private assignTag(element:any, nodeXML:XMLNode):void
	{
		if(element.type == "element") nodeXML.tag = element.name;
	}
	
	private assignText(element:any, node:XMLNode):void
	{
		if(element.type == "text")
		{
			node.text = element.text;
		}
	}

	private assignAttributes(element:any, node:XMLNode):void
	{
		var attr:any = {};
		for(var key in element.attributes)
		{
			var value:any = element.attributes[key];
			if(typeof value == "string")
			{
				if(/^[0]{1,}[0-9]{1,}$/.test(value))
				{
					// non valid number value;
					// consider value as text
				} else if(/^[\-]{0,1}[0-9]{1,}$/.test(value))
				{
					value = parseInt(value)
				} else if(/^[\-]{0,1}[0-9]{1,}\.[0-9]{1,}$/.test(value))
				{
					value = parseFloat(value);
				} else if(value == "true")
				{
					value = true;
				} else if(value == "false")
				{
					value = false;
				}
			}
			attr[key] = value;
		}
		node.attributes = attr;
	}

	deepCopy(): XMLNode {
		var clonedElement:any = JSON.parse(JSON.stringify(this.element));
		var clone:XMLJSNode = new XMLJSNode();
		clone.initElement(clonedElement);
		return clone;
	}
	/*
	toXML():string
	{
		return XMLJS.js2xml(
			{elements:[this.element]},
			{
				attributeValueFn:(v: string,
					attributeName: string,
					currentElementName: string,
					currentElementObj: any
				)=> {
					if(!v) return v;
					v = v.replace(/&quot;/g,  '"');
					return this.encodeHTMLEntities(v).replace(/"/g, '&quot;');
				}
			}
		);
	}
	private encodeHTMLEntities(text) {
		let textArea = document.createElement('textarea');
		textArea.innerText = text;
		let encodedOutput=textArea.innerHTML;
		let arr=encodedOutput.split('<br>');
		encodedOutput=arr.join('\n');
		if(text != encodedOutput)
		{
			console.log("compare", text , encodedOutput);
		}
		return encodedOutput;
	}
	*/

}

export class XMLJSParser implements XMLNodeXMLParser
{
	private parserOptions:any;
	constructor()
	{
		this.parserOptions = { 
			ignoreComment: true, 
			alwaysChildren: true ,
			alwaysArray:true
		};
		// this.testCode();
	}

	/*
	testCode() {
		
		
		var xmlArray = [
		//	`<xml x="abc"/>`,
			`<xml x="http://a.com/?a=b&amp;c=d"/>`
		];
		xmlArray.forEach((xml:string)=>{
			this.testXML(xml);
		})
	
	}
	*/
	/*
	private encodeHTMLEntities(text) {
		let textArea = document.createElement('textarea');
		textArea.innerText = text;
		let encodedOutput=textArea.innerHTML;
		let arr=encodedOutput.split('<br>');
		encodedOutput=arr.join('\n');
		if(text != encodedOutput)
		{
			console.log("compare", text , encodedOutput);
		}
		return encodedOutput;
	}
	*/
	/*
	parseEntity(entity:string) {
		var num
		var numStr = ''
		if (entity.charAt(0) === '#') {
		  if (entity.charAt(1) === 'x') {
			entity = entity.slice(2)
			num = parseInt(entity, 16)
			numStr = num.toString(16)
		  } else {
			entity = entity.slice(1)
			num = parseInt(entity, 10)
			numStr = num.toString(10)
		  }
		}
		entity = entity.replace(/^0+/, '')
		if (isNaN(num) || numStr.toLowerCase() !== entity) {
		  return '&' + entity + ';'
		}
	
		return String.fromCodePoint(num)
	}
	*/

	public parse(xml:string):XMLNode
	{
		var jsonString:string
		try
		{
			jsonString = XMLJS.xml2json(xml, this.parserOptions);
		} catch(err)
		{
			// try to remove & from title
			xml =xml.replace(/\"OKG:\/\/.*?\"/g, (found)=>{
				return  found.replace(/\/title\/(.*?)\"/g, (title)=>{
					return title.replace(/\&/g, "");
				}) ;
			});
			jsonString = XMLJS.xml2json(xml, this.parserOptions);
		}
		
		var data:any = JSON.parse(jsonString);
		
		var node:XMLJSNode = new XMLJSNode()
		node.initElement(data.elements[0]);
		return node;
		/*
		return new XMLNodeIterator().build(
			data.elements[0],
			{
				assignTag:this.assignTag,
				assignAttributes:this.assignAttributes, 
				assignText:this.assignText,
				getChildren:this.getChildren,
				removeChild:this.removeChild
			}
		);
		*/
	}
	/*
	private removeChild(parentNode:XMLNode, node:XMLNode):void
	{
		ArrayUtils.removeElement(parentNode.children, node);
		ArrayUtils.removeElement(parentNode.element.elements, node.element);
	}
	private assignTag(element:any, nodeXML:XMLNode):void
	{
		if(element.type == "element") nodeXML.tag = element.name;
	}
	private getChildren(element:any):any [] 
	{
		if(element && element.elements)
		{
			return element.elements;
		}
		return [];
	}
	private assignAttributes(element:any, node:XMLNode):void
	{
		var attr:any = {};
		for(var key in element.attributes)
		{
			var value:any = element.attributes[key];
			if(typeof value == "string")
			{
				if(/^[0]{1,}[0-9]{1,}$/.test(value))
				{
					// non valid number value;
					// consider value as text
				} else if(/^[0-9]{1,}$/.test(value))
				{
					value = parseInt(value)
				} else if(/^[0-9]{1,}\.[0-9]{1,}$/.test(value))
				{
					value = parseFloat(value);
				} else if(value == "true")
				{
					value = true;
				} else if(value == "false")
				{
					value = false;
				}
			}
			attr[key] = value;
		}
		node.attributes = attr;
	}

	
	private assignText(element:any, node:XMLNode):void
	{
		if(element.type == "text")
		{
			node.text = element.text;
		}
	}
	*/
}

