import { Injectable } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { from } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/service/common.service';
@Injectable({ providedIn: 'root' })

export class MemberService {
	public members:any = [];
	private schoolId;
	private pageIndex = 0;
	public loadedAllMembers = false;
	constructor(private datas: DataService, private sans: DomSanitizer, private common: CommonService) {
		if (this.datas.userInfo && this.loadedAllMembers == false) {
			this.loadMembers().subscribe(members=>{
				this.members = members;
			});
		}
	}

	loadMembers() {
		this.loadedAllMembers = false;
		this.common.waitFor(()=> this.datas.jwt, 10, 200).then(()=>{
			this.fetchMembers();
		});
		return from(this.common.waitFor(()=>{
			return this.loadedAllMembers?this.members:false;
		}, 1000, 200));
	}

	getMembers(options:any={active: 'all', role: 'all'}){
		return new Promise((resolve, reject)=>{
			let interval = setInterval(()=>{
				if (this.loadedAllMembers){
					clearInterval(interval);
					let members = this.members;
					if (options.active != 'all' && options.active !== null){
						members = members.filter(m=> m.active == options.active);
					}
					if (options.role != 'all' && options.role !== null){
						members = members.filter(m=>{
							if (options.role == 'teacher'){
								return m.user_role > 2 && m.user_role != 6;
							} else {
								return m.user_role == 2 || m.user_role == 6;
							}
						});
					}
					resolve(members);
				}
			},100);
			setTimeout(()=>{
				clearInterval(interval);
				reject({msg: 'getMembers: timeout'});
			},15000);
		});
	}

	resetDataifSchoolChange(){
		if (this.datas.userInfo && this.datas.userInfo.school_id){
			if (this.datas.userInfo.school_id != this.schoolId){
				this.loadMembers().subscribe(members=>{
					this.members = members;
				});
			}
		}
	}

	fetchMembers(){

		this.datas.post('ROMemberInfo.getMembers', [this.pageIndex]).subscribe((res: any) => {
			if (res.account){
				this.schoolId = res.account.school_id;
			}
			let members = [];
			if (res.members) {
				members = res.members.map(e => {
					let role = e.user_role > 2 ? 'teacher' : 'student';
					let url = '//ro2.azurewebsites.net/RainbowOne/webapp/2.5/roAnalyticsChart/assets/img/avatar_student_male.svg';
					if (e.url) {
						url = environment.resourceServer[e.server] + e.url;
						const photo = new Image();
						photo.onerror = () => {
							url = '//ro2.azurewebsites.net/RainbowOne/webapp/2.5/roAnalyticsChart/assets/img/avatar_' + role + '_' + (e.gender == 1 ? 'male' : 'female') + '.svg';
							e.photo = this.sans.bypassSecurityTrustStyle("url('" + url + "')");
						};
						photo.src = url;
					} else {
						url = '//ro2.azurewebsites.net/RainbowOne/webapp/2.5/roAnalyticsChart/assets/img/avatar_' + role + '_' + (e.gender == 1 ? 'male' : 'female') + '.svg';
					}
					e.photo = this.sans.bypassSecurityTrustStyle("url('" + url + "')");
					return e;
				});
			}
			this.members = [...this.members, ...members];
			if (res.isLast){
				this.pageIndex = 0;
				this.loadedAllMembers = true;
			} else {
				this.pageIndex++;
				if (this.datas.userInfo){
					this.fetchMembers();
				}
			}
		});
	}


}
