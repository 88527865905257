import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, EmbeddedViewRef, ComponentRef, RendererFactory2, Renderer2 } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AlertService } from 'src/app/service/alert.service';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { ScriptService } from 'src/app/service/script.service';
import { CodeScanComponent } from './codeScan.component';
declare const Html5Qrcode: any;
declare const Html5QrcodeScanner: any;
declare const ZXing:any;

@Injectable({ providedIn: 'root' })
export class CodeScanService {
	private ele: HTMLElement;
	private _componentRef: ComponentRef<CodeScanComponent>;
	private renderer2: Renderer2;
	private html5Qrcode;
	private codeReader;
	private pauseScan = false;
	public cancelScan = false;
	private codeScanner;
	private formatsToSupports = [
		'QR_CODE', 'AZTEC', 'CODABAR', 'CODE_39', 'CODE_93', 'CODE_128', 'DATA_MATRIX', 'MAXICODE', 'ITF', 'EAN_13', 'EAN_8', 'PDF_417',
		'RSS_14', 'RSS_EXPANDED', 'UPC_A', 'UPC_E', 'UPC_EAN_EXTENSION'
	];
	private cancelCheckInternal:any;
	public cameraDeviceId;
	constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector, private script: ScriptService, public renderer2Factory: RendererFactory2, private als: AlertService, private ros: RoService, private coms: CommonService, private datas: DataService) {
		this._componentRef = this.componentFactoryResolver
			.resolveComponentFactory(CodeScanComponent)
			.create(this.injector);
		this.appRef.attachView(this._componentRef.hostView);
		this.ele = (this._componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		this.renderer2 = this.renderer2Factory.createRenderer(null, null);
		
	}

	async scan(options: any={}){
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb){
			const loaded = await this.script.load('js/zxing.min.js');
			if (location.protocol == 'http:' && location.port == "") {
				return new Promise(resolve => {
					resolve({ code: 0, msg: 'support-https-only' });
				});
			}
			this.moveElement(true);
			return this.callCodeScan(options);
		} else {
			return this.scanByApp();
		}
	}

	callCodeScan(options: any = {}) {
		let component = this._componentRef.instance;
		if (options.codeType == 'barcode'){
			options.redLine = true;
		}
		component.options = options;
		console.log('cameraDeviceId:' + this.cameraDeviceId);
		let scanPromise = new Promise((resolve, reject) => {
			if (options.codeType == 'barcode'){
				this.codeReader = new ZXing.BrowserBarcodeReader();
			} else {
				this.codeReader = new ZXing.BrowserQRCodeReader();
			}
			navigator.mediaDevices.enumerateDevices().then(devices=>{
				this.codeReader.getVideoInputDevices()
					.then((videoInputDevices) => {
						let selectedDevice = videoInputDevices.find(e => /back|rear|environment/gi.test(e.label));
						selectedDevice = selectedDevice? selectedDevice:videoInputDevices[0];
						this.cameraDeviceId = selectedDevice.deviceId;
						if (this.datas.dev.isIpad || navigator.userAgent.indexOf('iPhone') > -1){
							this.cameraDeviceId = null; //iOS set null 反而識取后camera
						}
						console.log(this.cameraDeviceId+':'+videoInputDevices[0].deviceId);
						this.codeReader.decodeOnceFromVideoDevice(null, 'code-reader').then((result) => {
							resolve({ code: 1, text: result.text });
							this.codeReader.reset();
							this.moveElement(false);
						}).catch((err) => {
							this.codeReader.reset();
							console.error(err)					
						})
					});
			});
		});
		
		let cancelPromise = new Promise(resolve => {
			let startTime = +new Date();
			this.cancelCheckInternal = setInterval(() => {
				let now = +new Date();
				if (component.isOff) {
					clearInterval(this.cancelCheckInternal);
					resolve({ code: 0, msg: 'cancel' });
					component.isOff = false;
					this.codeReader.reset();
					this.moveElement(false);
				} else if (options.time && now >= startTime + options.time) {
					clearInterval(this.cancelCheckInternal);
					this.codeReader.reset();
					this.moveElement(false);
					resolve({ code: 0, msg: 'timer' });
				}
			}, 1000);
		});
		// return scanPromise;
		return Promise.race([scanPromise, cancelPromise]);
	}

	/*
	async scan(options: any = {}) {
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb){
			const loaded = await this.script.load('html5-qrcode.min.js');
			if (location.protocol == 'http:' && location.port == "") {
				return new Promise(resolve => {
					resolve({ code: 0, msg: 'support-https-only' });
				});
			}
			this.moveElement(true);
			return this.callScan(options);
		} else {
			return this.scanByApp();
		}
	}

	callScan(options: any = {}) {
		let component = this._componentRef.instance;
		component.options = options;
		let readerConfig = {
			fps: options.fps ? options.fps : 10,
			qrbox: { width: options.width ? options.width : 250, height: options.height ? options.height : 250 },
			formatsToSupport: this.formatsToSupports,
			experimentalFeatures: {
				useBarCodeDetectorIfSupported: false //not work on android
			},
			aspectRatio: 1.7777778
		};
		let scanPromise = new Promise((resolve, reject) => {
			this.codeReader = new Html5Qrcode("code-reader", readerConfig);
			this.codeReader.start(
				{ facingMode: options.facingMode ? options.facingMode : "environment" },//environment(back camera),user(front camera)
				readerConfig,
				(decodedText, decodedResult) => {
					console.log(decodedText);
					clearInterval(this.cancelCheckInternal);
					resolve({ code: 1, text: decodedText, result: decodedResult });
					component.isOff = false;
					this.stopScanner();
				},
				errorMessage => {
					component.message = errorMessage;
				})
				.catch(err => {
					this.moveElement(false);
					reject(err);
				});
		});
		
		let cancelPromise = new Promise(resolve => {
			let startTime = +new Date();
			this.cancelCheckInternal = setInterval(() => {
				let now = +new Date();
				if (component.isOff) {
					clearInterval(this.cancelCheckInternal);
					resolve({ code: 0, msg: 'cancel' });
					component.isOff = false;
					this.stopScanner();
				} else if (options.time && now >= startTime + options.time) {
					clearInterval(this.cancelCheckInternal);
					resolve({ code: 0, msg: 'timer' });
					this.stopScanner();
				}
			}, 1000);
		});
		// return scanPromise;
		return Promise.race([scanPromise, cancelPromise]);
	}*/

	moveElement(isOn = true) {
		if (isOn && !this.ele.parentElement) {
			this.renderer2.appendChild(document.body, this.ele);
		} else {
			this.renderer2.removeChild(document.body, this.ele);
		}
	}

	stopScanner() {
		this.codeReader.stop();
		this.moveElement(false);
	}

	scanByApp(){
		return new Promise((resolve,reject)=>{
			this.ros.request('scanBarcode').then((code:any)=>{
				if (typeof code === 'object' || code == '[object Object]'){
					resolve({ code: 0, msg: 'cancel' });
				} else {
					resolve({ code: 1, text: code, result: code });
				}
			  }).catch(err=>{
					this.coms.log(err);
					resolve({ code: 0, msg: err});
			  });
		});
	}

}
