import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingService } from '../loadingModule/loading.service';
import { catchError, throwIfEmpty, timeout } from 'rxjs/operators';
import { of } from 'rxjs';
import { SpeedTestService } from './speedtest.service';
import { ScriptService } from 'src/app/service/script.service';
declare var Chart;
import { faRedo, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faThList } from '@fortawesome/pro-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
	selector: 'network-check',
	template: `
	<div class="wrapper">
  		<div class="header">
			<fa-icon [icon]="faRedo" class="header__refresh" (click)="refreshClick()"></fa-icon>
			<div class="header__title">{{'network-check.title'|translate}}</div>
			<div class="header__confirm" (click)="close.emit()">{{'commonService.confirm'|translate}}</div>
		</div>
		<div class="content">
			<!--<div class="test">
				<canvas id="chart-test"></canvas>
			</div>-->
			<div class="topic">
				<div class="topic__ping {{datas.lang}}">{{'network-check.ping'|translate}}</div>
				<div class="topic__download {{datas.lang}}">{{'network-check.download'|translate}}</div>
				<div class="topic__upload {{datas.lang}}">{{'network-check.upload'|translate}}</div>
			</div>
			<div class="row" *ngFor="let row of rows">
				<div class="row__title">{{('network-check.' + row.title)|translate}}</div>
				<div class="row__result">
					<div [ngClass]="['row__icon', row.status?row.status:'', row.avgResponse?'':'no-response']"></div>
					<div class="row__time" *ngIf="row.avgResponse">{{row.avgResponse}} ms</div>
					<div class="row__dot" *ngIf="!row.avgResponse && row.pingStarted && row.pingStatus != 'fail'">{{ dot }}</div>
					<div class="row__ping-cant-connect" *ngIf="!row.avgResponse && row.pingStarted && row.pingStatus == 'fail'">{{ 'network-check.cant-connect'|translate }}</div>
				</div>
				<div class="row__chart" *ngIf="row.download && row.downloadStatus && row.downloadStatus != 'fail'" [style.backgroundImage]="row.downloadBg">
					<div class="row__chart__fill download" [style.width.px]="(row.download / 500) * 120"></div>
					<div class="row__chart__bg"></div>
					<canvas id="chart-{{row.title}}-download"></canvas>
				</div>
				<div class="row__speed" *ngIf="row.download && row.downloadStatus && row.downloadStatus != 'fail'">{{row.download + ' Mbps'}}</div>
				<!--<div *ngIf="row.downloadUrl && row.downloadStarted && !row.downloadStatus" class="row__in-progress download">{{ dot }}</div>-->
				<div *ngIf="inProgressConnection == row.title + '-d'" class="row__in-progress download">{{ dot }}</div>
				<div *ngIf="!row.downloadUrl" class="row__in-progress download na">{{ 'network-check.unable-preview'|translate }}</div>
				<div class="row__cant-connect download" *ngIf="inProgressConnection != row.title + '-d' && row.downloadStatus == 'fail'">{{ 'network-check.cant-connect'|translate }}</div>
				
				<div class="row__chart" *ngIf="row.upload && row.uploadStatus && row.uploadStatus != 'fail'" [style.backgroundImage]="row.uploadBg">
					<div class="row__chart__fill upload" [style.width.px]="(row.upload / 500) * 120"></div>
					<div class="row__chart__bg"></div>
					<canvas id="chart-{{row.title}}-upload"></canvas>
				</div>
				<div class="row__speed" *ngIf="row.upload && row.uploadStatus && row.uploadStatus != 'fail'">{{row.upload + ' Mbps'}}</div>
				<!--<div *ngIf="row.uploadUrl && row.uploadStarted && !row.uploadStatus" class="row__in-progress upload">{{ dot }}</div>-->
				<div *ngIf="inProgressConnection == row.title + '-u'" class="row__in-progress upload">{{ dot }}</div>
				<div *ngIf="!row.uploadUrl" class="row__in-progress upload na">{{ 'network-check.unable-preview'|translate }}</div>
				<div class="row__cant-connect upload" *ngIf="inProgressConnection != row.title + '-u' && (row.downloadStatus == 'fail' || row.uploadStatus == 'fail')">{{ 'network-check.cant-connect'|translate }}</div>
			</div>
			<div class="conclusion" *ngIf="showConclusion">
				<span class="type-{{conclusion?conclusion:''}}">{{('network-check.'+ conclusion)|translate}}</span> 
				<span>({{checkTime|mo}})</span>
			</div>
		</div>
  	</div>
  `,
	styleUrls: ['./network-check.component.scss'],
})

export class NetworkCheckComponent implements OnInit, OnDestroy {
	public checkTime: any;
	public rows: any[] = [];
	public conclusion;
	public options;
	public data;
	private timer$;
	public dot = '.';
	public faRedo: IconDefinition = faRedo;
	@Output() close: EventEmitter<any> = new EventEmitter();
	private refresh$: Subject<string> = new Subject<string>();
	private refreshSub$: Subscription;
	private httpRequest;
	public showConclusion = false;
	public inProgressConnection = '';
	constructor(public datas: DataService, private http: HttpClient, private st: SpeedTestService, private script: ScriptService, private sans: DomSanitizer) {
	}

	ngOnInit() {
		this.script.load('chart-3.3.2.js/chart.js').then(() => {
			this.initOption();
			this.initCheckItems();
			this.test();
			this.check(0, 0);
		});
		this.timer$ = setInterval(() => {
			this.dot = this.dot.length < 3 ? this.dot + '.' : '.';
		}, 1000);

		this.refreshSub$ = this.refresh$.pipe(debounceTime(50)).subscribe((res: any) => {
			this.st.cancelAllReq();
			if (this.httpRequest){
				this.httpRequest.unsubscribe();
			}
			this.conclusion = null;
			this.rows.forEach(r => {
				r.status = null;
				r.downloadStatus = null;
				r.uploadStatus = null;
				r.downloadQueue = [];
				r.uploadQueue = [];
				r.avgResponse = null;
				r.download = null;
				r.upload = null;
				r.pingStarted = 0;
				r.downloadStarted = 0;
				r.uploadStarted = 0;
			});
			setTimeout(() => {
				this.initCheckItems();
				this.check(0, 0);
			}, 10);
		});
	}

	ngOnDestroy(): void {
		this.st.cancelAllReq();
		if (this.httpRequest){
			this.httpRequest.unsubscribe();
		}
		this.refresh$.unsubscribe();
		this.refreshSub$.unsubscribe();
	}

	test() {
		let chartTest = document.getElementById('chart-test');
		let data = { labels: [0, 1, 2, 3, 4], datasets: [{ label: 'a', data: [10, 110, 220, 330, 500], fill: false, borderColor: '#980061' }] };
		let config = { type: 'line', data: data, options: this.options };
		if (chartTest) {
			let ctx = new Chart(chartTest, config);
		}
	}

	initOption() {
		this.options = {
			responsive: true,
			animation: false,
			scales: {
				x: {
					ticks: { display: false, max: 100, min: -1, step: 1, padding: -7 },
					grid: { display: false, drawBorder: false },
					max: 500, min: 0, step: 10, beginAtZero: true, offset: true,
					afterFit: (axis) => {
						axis.paddingLeft = 5;
						axis.paddingRight = 5;
					}
				},
				y: {
					ticks: { display: false, max: 100, min: -1, step: 1, padding: -7 },
					grid: { display: false, drawBorder: false },
					max: 500, min: 0, step: 10, beginAtZero: false, offset: true,

				}
			},
			plugins: {
				legend: { display: false },
				tooltip: false,
			},
			layout: { padding: { top: 0, bottom: -50, left: -5, right: -10 } },
			// elements: { point: { radius: 0 } },
			// bezierCurve: false,
			scaleShowLabels: false,
			tooltipEvents: [],
			scaleShowGridLines: false,
			maintainAspectRatio: false,
			pointRadius: 2,
		};
	}

	initCheckItems() {
		this.rows = [
			{ title: 'e-Book-and-login', url: '//api.openknowledge.hk/RainbowOne/index.php/PHPGateway/proxy/2.8', downloadUrl: '//api.openknowledge.hk/RainbowOne/webapp/ken/1MB.bin', uploadUrl: this.datas.apiUrl + '?api=ROHomeScreen.networkCheckUpload&json=[]' },
			{ title: 'media-services', url: '//oka.blob.core.windows.net/test/sample.png', downloadUrl: '//oka.blob.core.windows.net/media/sample.mp3', uploadUrl: this.datas.apiUrl + '?api=ROHomeScreen.networkCheckUpload&json=[]' },
			{ title: 'speech-generation', url: '//rainbowone.azurewebsites.net/CI2/index.php', downloadUrl: '//api.openknowledge.hk/RainbowOne/webapp/ken/1MB2.bin', uploadUrl: this.datas.apiUrl + '?api=ROHomeScreen.networkCheckUpload&json=[]' },
			{ title: 'speech-recognition', url: '//api.openknowledge.hk/RainbowOne/index.php/PHPGateway/proxy/2.8', downloadUrl: '//api.openknowledge.hk/RainbowOne/webapp/ken/1MB3.bin', uploadUrl: this.datas.apiUrl + '?api=ROHomeScreen.networkCheckUpload&json=[]' },
			{ title: 'image-recognition', url: '//api3.openknowledge.hk:8083/api/getText', downloadUrl: '//api3.openknowledge.hk:8083/speedTest/10mb.bin', uploadUrl: '//api3.openknowledge.hk:8083/api/networkCheckUpload' },
			{ title: 'lesson-services', url: '//lesson.openknowledge.hk:7778/index.html' },
		];
		if (location.protocol == 'https:') {
			this.rows[4].url = '//api3.openknowledge.hk:8088/api/getText';
			this.rows[4].downloadUrl = '//api3.openknowledge.hk:8088/speedTest/10mb.bin';
			this.rows[4].uploadUrl = '//api3.openknowledge.hk:8088/api/networkCheckUpload';
		}
		this.rows.forEach(r => {
			r.responses = [];
			r.downloadQueue = [];
			r.uploadQueue = [];
			r.downloadChart = null;
			r.uploadChart = null;
			r.avgResponse = null;
			r.status = null;
			r.start = null;
			r.upload = null;
			r.uploadStatus = null;
			r.download = null;
			r.downloadStatus = null;
			r.pingStarted = 0;
			r.uploadStarted = 0;
			r.downloadStarted = 0;
			r.testStatus = 'pending';
		});
		this.showConclusion = false;
	}

	refreshClick() {
		this.refresh$.next();
	}

	get elExist(){
		return !!document.querySelector('network-check');
	}

	check(count = 0, index = 0) {
		if (this.rows.length == 0) {
			return;
		}
		this.checkTime = moment();
		let r = this.rows[index];
		let start = moment();
		let url = r.url + '?ts=' + +new Date();
		r.pingStarted = 1;
		this.httpRequest = this.http.get(url, { responseType: 'text' }).pipe(timeout(2000), catchError((err) => {
			if (this.rows.length > 0 && r.avgResponse == null) {
				r.pingStatus = 'fail';
				r.responses.push(0);
				if (r.responses.length > 5) {
					r.responses.splice(0, 1);
				} else if (r.responses.length == 5) {
					r.avgResponse = r.responses.reduce((a, b, i) => i < 5 ? a + b : a);
					r.avgResponse = Math.round(r.avgResponse / 5);
					if (r.avgResponse <= 200) {
						r.status = 'ok';
					} else if (r.avgResponse <= 500) {
						r.status = 'problem';
					} else {
						r.status = 'fail';
					}
				}
				if (r == this.rows[index] && this.elExist) {
					this.callNextCheck(count, index);
				}
			}
			return of('fail');
		})).subscribe((res: any) => {
			if (res != 'fail' && this.rows.length > 0 && r.avgResponse == null) {
				let now = moment();
				r.responses.push(now.diff(start, 'milliseconds'));
				if (r.responses.length > 5) {
					r.responses.splice(0, 1);
				} else if (r.responses.length == 5) {
					r.avgResponse = r.responses.reduce((a, b, i) => i < 5 ? a + b : a);
					r.avgResponse = Math.round(r.avgResponse / 5);
					if (r.avgResponse <= 200) {
						r.status = 'ok';
					} else if (r.avgResponse <= 500) {
						r.status = 'problem';
					} else {
						r.status = 'fail';
					}
				}
				if (r == this.rows[index] && this.elExist) {
					this.callNextCheck(count, index);
				}
			}
		});
	}

	callNextCheck(count, index) {
		this.rows.forEach(r=>{
			r.downloadStarted = 0;
			r.uploadStarted = 0;
			r.download = 0;
			r.upload = 0;
			r.downloadQueue = [];
			r.uploadQueue = [];
		});
		if (count == 4) {
			count = 0;
			index = index + 1;
		} else {
			count++;
		}
		if (index == 6) {
			setTimeout(() => { this.conclude(); }, 10);
		} else {
			setTimeout(() => { this.check(count, index); }, 100);
		}
	}

	conclude() {
		this.rows.forEach(r => {
			if (r.responses.length == 5) {
				r.avgResponse = r.responses.reduce((a, b) => a + b);
				r.avgResponse = Math.round(r.avgResponse / 5);
				if (r.avgResponse <= 200) {
					r.status = 'ok';
				} else if (r.avgResponse <= 500) {
					r.status = 'problem';
				} else {
					r.status = 'fail';
				}
			} else {
				r.status = 'fail';
			}
		});
		let fails = this.rows.filter(r => r.status == 'fail');
		if (fails.length == 0) {
			this.conclusion = 'summary-ok';
		} else if (fails.length == 1) {
			this.conclusion = 'summary-problem';
		} else {
			this.conclusion = 'summary-fail';
		}
		this.drawChart(this.rows[0]);
	}

	drawChart(item: any) {
		if (!this.elExist){
			return;
		}
		if (item.downloadUrl) {
			item.testStatus = 'downloading';
			item.downloadStarted = 1;
			setTimeout(()=>{
				this.inProgressConnection = item.title + '-d';
				this.st.testDownload(item).then((item: any) => {
					this.inProgressConnection = '';
					if (item.downloadStatus == null){
						item.downloadStatus = item.download ? 'ok' : 'fail';
					}
					setTimeout(() => {
						let downloadCtx = document.getElementById('chart-' + item.title + '-download');
						if (downloadCtx) {
							let data = this.prepareChartData(item, 'download');
							item.downloadChartConfig = { type: 'line', data: data, options: this.options };
							setTimeout(() => {
								// let chartStatus = Chart.getChart('chart-' + item.title + '-download');
								let chartStatus = this.st.chartInstance['chart-'+item.title + '-downmload'];
								if (chartStatus) {
									chartStatus.destroy();
								}
								try {
									item.downloadChart = new Chart(downloadCtx, item.downloadChartConfig);
									this.st.chartInstance['chart-'+ item.title + '-download'] = item.downloadChart;
								} catch (e) { }
							}, 10);
						}
						new Promise((resolve, reject) => {
							item.uploadStarted = 1;
							setTimeout(() => { resolve(null); }, 10);
						}).then(() => {
							if (item.uploadUrl) {
								this.inProgressConnection = item.title + '-u';
								this.st.testUpload(item).then((item: any) => {
									this.inProgressConnection = '';
									if (item.uploadStatus == null){
										item.uploadStatus = item.upload ? 'ok' : 'fail';
									}
									setTimeout(() => {
										let uploadCtx = document.getElementById('chart-' + item.title + '-upload');
										if (uploadCtx) {
											let data = this.prepareChartData(item, 'upload');
											item.uploadChartConfig = { type: 'line', data: data, options: this.options };
											setTimeout(() => {
												// let chartStatus = Chart.getChart('chart-' + item.title + '-upload');
												let chartStatus = this.st.chartInstance['chart-'+ item.title + '-upload'];
												if (chartStatus) {
													chartStatus.destroy();
												}
												try { 
													item.uploadChart = new Chart(uploadCtx, item.uploadChartConfig); 
													this.st.chartInstance['chart-'+ item.title + '-upload'] = item.uploadChart;
												} catch (e) { }

											}, 10);
										}
										this.drawNext(item);
									}, 10);
								}, (err) => {
									this.inProgressConnection = '';
									if (!item.upload){
										item.uploadStatus = 'fail';
									}
									this.drawNext(item);
								});
							} else {
								this.drawNext(item);
							}
							item.testing = false;
						});
					}, 10);
				}, (err) => {
					this.inProgressConnection = '';
					if (!item.download){
						item.downloadStatus = 'fail';
					}
					if (!item.upload){
						item.uploadStarted = 1;
						item.uploadStatus = 'fail';
					}
					this.drawNext(item);
				});
			}, 10);
		} else {
			this.drawNext(item);
		}
	}

	drawNext(item) {
		let index = this.rows.indexOf(item);
		if (item.downloadStarted && !item.downloadStatus){
			item.downloadStatus = 'fail';
		} else if (item.uploadStarted && !item.uploadStatus){
			item.uploadStatus = 'fail';
		}
		if (this.elExist) {
			setTimeout(() => {
				if (index < this.rows.length - 1) {
					if (this.rows[index + 1].downloadStarted == 0){
						this.drawChart(this.rows[index + 1]);
					}
				} else {
					let rows = this.rows.map(e => { return { ping: e.avgResponse, download: e.download, upload: e.upload }; });
					let obj = { device: JSON.stringify(this.datas.dev.info), rows: rows };
					let downloadUploadFail = this.rows.find(r=> r.downloadStatus == 'fail' || r.uploadStatus == 'fail');
					if (downloadUploadFail){
						this.conclusion = 'summary-fail';
					}
					this.datas.post('ROHomeScreen.networkCheckLog', [obj]).subscribe((res: any) => {
						this.showConclusion = true;
					});
				}
			}, 10);
		}
	}

	prepareChartData(row, field = 'download') {
		let labels = [0, 1, 2, 3, 4];
		let data = row[field + 'Queue'].map(e => e > 500 ? 500 : Math.round(e));
		let count = row[field + 'Queue'].length;
		for (; count < 5; count++) {
			data.push(data[count - 1]);
		}
		// data = data.map(d=> field == 'upload'? d * 10: d);
		return {
			labels: labels,
			datasets: [
				{
					label: 'a',
					data: data,
					// data: [1001, 20, 50, 20, 100],
					fill: false,
					borderColor: field == 'download' ? '#00639f' : '#980061',
					pointBackgroundColor: field == 'download' ? '#00639f' : '#980061',
					borderWidth: 2
				}
			]
		};
	}

}
