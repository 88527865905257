import { MbscModule } from '@mobiscroll/angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AlertModule } from './sharedModule/alertModule/alert.module';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingModule } from './sharedModule/loadingModule/loading.module';
import { FormsModule } from '@angular/forms';
import { AngularDraggableModule } from 'angular2-draggable';
import { ProgressBoxModule } from './sharedModule/progressBoxModule/progressBox.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuillModule } from 'ngx-quill';
import { UploadModule } from './sharedModule/uploadModule/upload.module';
import { Alert2Module } from './sharedModule/alert2Module/alert2.module';
import { LanguageFileSetting, TranslateModuleLoader } from './common/TranslateModuleLoader';
import { CodeScanModule } from './sharedModule/codeScanModule/codeScan.module';
import { AssessmentFormModule } from './coreModule/EvaluationFormModule/AssessmentForm.module';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppStatusModule } from './sharedModule/appStatusModule/AppStatus.module';
import { ThemeService } from './service/theme.service';
import { OpenBookModule } from './sharedModule/openBookModule/openBook.module';
import { DirectiveModule } from './directive/DirectiveModule';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	//suppressScrollX: true
};

export function HttpLoaderFactory(http: HttpClient) {
	let ts = +new Date();
	return new TranslateHttpLoader(http, "./assets/", ".json?" + ts);
}
/*
export function moduleHttpLoaderFactory(http: HttpClient) {
	const baseTranslateUrl = './assets/langs/';
	const options: any = {
		disableNamespace: true,
		lowercaseNamespace: true,
		version: +new Date(),
		modules: [
			{ baseTranslateUrl },
			{ baseTranslateUrl, moduleName: 'home-screen'},
			{ baseTranslateUrl, moduleName: 'home-screen-news'},
			{ baseTranslateUrl, moduleName: 'course'},
			{ baseTranslateUrl, moduleName: 'userAndGroup'},
			{ baseTranslateUrl, moduleName: 'redeem'},
			{ baseTranslateUrl, moduleName: 'enroll'},
            { baseTranslateUrl, moduleName: 'pdfConverter'},
		]
	};
	return new ModuleTranslateLoader(http, options);
}*/

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [ 
    	MbscModule,  
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		LoadingModule,
		AlertModule,
		Alert2Module,
		ProgressBoxModule,
		DeviceDetectorModule.forRoot(),
		TranslateModuleLoader.forRoot(),
		// TranslateModule,
		AngularDraggableModule, CodeScanModule,
		FontAwesomeModule, UploadModule, ToastModule,
		QuillModule.forRoot(),
		AppStatusModule,
		OpenBookModule
	],
	providers: [
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
		{
			provide: LanguageFileSetting, 
			useValue: {
				basePath:"./assets/langs/",
				languages:["home-screen", "home-screen-news"]
			}
		}, MessageService, ThemeService
	],
	bootstrap: [AppComponent],
	entryComponents: []
})
export class AppModule { }
