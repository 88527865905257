import { Component, ElementRef, ViewChild } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { ThemeService } from 'src/app/service/theme.service';
import { NotificationService } from 'src/app/service/notification.service';
import { CommonService } from 'src/app/service/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'notificationPopup',
    template: `
	<bubbleBox2 #bubbleBox2 padding="0" 
		[autoTailColor]="false"
		[backgroundColor]="theme.getStyleValue('wpopup-bgcolor') || '#FFFFFF'"
		[boxShadow]="theme.getStyleValue('wpopup-shadow') || '0px 5px 30px rgba(0,0,0,0.3)'"
	>
		<div class="title">{{'common.notify'|translate}}</div>
		<div class="nav">
			<div class="left">
				<div class="navItem" [class.selected]="curView=='all'" (click)="curView='all'">
					<div class="label">{{'common.all'|translate}}</div>
				</div>
				<div class="navItem" [class.selected]="curView=='unread'" (click)="curView='unread'">
					<div class="label">{{'common.unread'|translate}}</div>
				</div>
			</div>
			<div class="right">
				<headerIconBtn #optionBtn [icon]="faEllipsisVertical" (click)="optionBtnWpopup.open($event.target)"></headerIconBtn>
				<whitePopup #optionBtnWpopup [menuItems]="optionBtnWpopupMenuItems"></whitePopup>
			</div>
		</div>
		<div class="msgList" (scroll)="onScroll($event)">
			<ng-container *ngIf="curMessages.length>0; else noAnyMessage;">
				<div
					*ngFor="let msg of curMessages"
					class="msgItem"
					[class.unread]="!msg.status || !msg.status.read_at"
					(click)="msgOnClick(msg)"
				>
					<div class="fmIconWrapper" *ngIf="msg.config && msg.config.iconSetting; let iconSetting;">
						<fa-icon *ngIf="iconSetting.icon" [icon]="iconSetting.icon" [ngStyle]="iconSetting.ngStyle"></fa-icon>
					</div>
					<div class="newDotWrapper"><div class="newDot"></div></div>
					<div class="contentWrapper">
						<div class="text">
							<img *ngIf="msg.config && msg.config.fileIconPath" class="fileIcon" [src]="msg.config.fileIconPath">
							{{msg.config.title}}
						</div>
						<div class="date">{{getDateString(msg.created_at)}}</div>
					</div>
					<div class="sectionTagWrapper" *ngIf="msg.config && msg.config.tagSetting; let tagSetting;">
						<div class="sectionTag" [ngStyle]="tagSetting.ngStyle">{{tagSetting.title}}</div>
					</div>
				</div>
				<!--<div *ngIf="curView=='all' ? ntfs.isNoMoreAllMsg : ntfs.isNoMoreUnreadMsg" class="msgItem noMessage">{{'common.noMessage'|translate}}</div>-->
			</ng-container>
			<ng-template #noAnyMessage>
				<div class="noMessageWrapper">
					<img src="./assets/img/notification/img_people_msg.svg">
					<div class="noMessageText">{{'common.noMessage'|translate}}</div>
				</div>
			</ng-template>
		</div>

	</bubbleBox2>
    `,
    styleUrls: ['./notificationPopup.component.scss']
})

export class NotificationPopupComponent {
	@ViewChild('bubbleBox2', { static: false }) public bubbleBox2: BubbleBox2Component;
	public faEllipsisVertical:IconDefinition = faEllipsisVertical;

	private _curView:"all"|"unread" = "all";
	public set curView(type:"all"|"unread") {
		this._curView = type;
		this.curMessages = {all:this.ntfs.allMessages, unread:this.ntfs.unreadMessages}[this.curView];
	}
	public get curView():"all"|"unread" {
		return this._curView;
	}
	public curMessages:any[] = {all:this.ntfs.allMessages, unread:this.ntfs.unreadMessages}[this.curView];

	public optionBtnWpopupMenuItems:any[] = [
		{
			titleKey: 'common.markAllAsRead',
			click: ()=>this.ntfs.markAllAsRead(),
			disableIf: ()=>this.ntfs.unread<1
		}
	]

    constructor(private coms:CommonService, public theme:ThemeService, public ntfs:NotificationService, public eleRef: ElementRef, private translate:TranslateService) {
		this.theme.applyStyleObj({
			"default": {
				"unread-bgcolor": "#f7f7f7"
			},
			"dark": {
				"unread-bgcolor": "#3f3e63"
			}
		});
    }

	public open(targetEle: HTMLElement, appendToEle: HTMLElement = document.body): Promise<any> {
		this.ntfs.checkNewMessage();
		return this.bubbleBox2.open(targetEle, appendToEle).catch((e) => { });
	}

	public msgOnClick(msg:any):void {
		this.bubbleBox2.close();
		this.ntfs.lastClickedMessage = msg;
		if (!msg.status || !msg.status.read_at) this.ntfs.markAsRead(msg);
		if (msg.config && msg.config.click) msg.config.click(msg);
	}

	public getDateString(createdAt:string):string {
		let dateString:string = this.coms.getDateString(new Date(createdAt), 'yyyymmddhhmm', '/', ':', ' ', false);

		let today:Date = new Date();
		let yesterday:Date = new Date((new Date()).setDate(today.getDate() - 1));
		let todayString:string = this.coms.getDateString(today, 'yyyymmdd', '/');
		let yesterdayString:string = this.coms.getDateString(yesterday, 'yyyymmdd', '/');

		dateString = dateString.replace(todayString, {tc:'今天',sc:'今天',en:'Today'}[this.translate.currentLang]);
		dateString = dateString.replace(yesterdayString, {tc:'昨天',sc:'昨天',en:'Yesterday'}[this.translate.currentLang]);

		return dateString;
	}

	public onScroll(event: any) {
		if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
			if ((this.curView=="all" && !this.ntfs.isNoMoreAllMsg) || (this.curView=="unread" && !this.ntfs.isNoMoreUnreadMsg)) {
				this.ntfs.getOlderMessageApi(this.curView);
			}
		}
	}

}
