import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { CommonService } from 'src/app/service/common.service';
import Quill from 'quill'

@Component({
	selector: 'customQuillEditor',
	template: `
	    <quill-editor #quillEditor class="customQuillEditor"
			placeholder=""
			[modules]="{toolbar: false}" 
			[(ngModel)]="content"
			(onSelectionChanged)="quillSelectionChanged($event)"
      		(onEditorCreated)="onEditorCreated($event)" 
			(ngModelChange)="onContentChange($event)"
			(onFocus)="onFocus($event)"
            (onBlur)="onBlur($event)"
		></quill-editor>`,
	styleUrls: ['./customQuillEditor.component.scss'],
})

export class CustomQuillEditorComponent {
	@Input() content: string;
	@Input() editorId: string;
	@Output() editorCreated = new EventEmitter<any>();
	@Output() contentChange = new EventEmitter<string>();

	public quill:any = null;
	public isBold: boolean = false
	public isItalic: boolean = false
	public isUnderline: boolean = false
	public isFocus:boolean = false
	public textColor:string | null = null
	public backgroundColor:string | null = null
	public selection = null
	public isBlur:boolean = false

	constructor(public coms:CommonService) {}

	onEditorCreated(quill: any) {
		this.quill = quill
		this.editorCreated.emit({ quill, editorId : this.editorId });
	}

	onContentChange(content: string) {
		this.contentChange.emit(content);
	}

	onFocus(event) {
		this.coms.log('quillFocus');
		this.isFocus = true
		this.isBlur = false
	}

	onBlur(event) {
		this.coms.log('quillBlur');
		this.isBlur = true
		// prevent the blue issue in safari
		if (event.editor.selection.savedRange.length >= 1) {
			this.selection = event.editor.selection.savedRange
			this.quill.setSelection(event.editor.selection.savedRange)
		} else {
			this.quill.setSelection(this.selection)
		}
	}

	public getSelection() {
		if (this.quill) return this.quill.getSelection()

		return { length: 0 }
	}

	public getTextColor() {
		return this.quill.getFormat()
	}

	// detect if the current text is formatted
	public quillSelectionChanged(data:any, force:boolean = false):void {
		if (this.quill) {
			// prevent the blue issue in safari
			// if (this.isBlur && data && data.oldRange && (!data.range || data.range.length == 0) ) {
			// 	this.isBlur = false
			// 	this.quill.setSelection(data.oldRange)
			// }

			if (force || (data && data.range)) {
				this.isBold = this.quill.getFormat().bold || false;
				this.isItalic = this.quill.getFormat().italic || false;
				this.isUnderline = this.quill.getFormat().underline || false;
				this.textColor = this.quill.getFormat().color || null;
				this.backgroundColor = this.quill.getFormat().background || null;
			}
		}
    }
	
	// Method to format text (for question bank)	
	public formatText(style: string, color = "" ,type: string = null) {
		const quill = this.quill;
		
		if (!quill) return;
		let selection = quill.getSelection();
		// assign the saved selection to prevent blur issue in safari
		if (!selection || selection.length == 0) selection = this.selection
		const text = quill.getText(selection.index, selection.length);
		const isFullTextSelected = quill.getText().trim() === text.trim();

		const toggleFormat = (style: string, color: string) => {
			if (style !== "color" && style !== "background") {
				quill.formatText(selection.index, selection.length, { [style]: false }, 'user');
			} else {
				quill.formatText(selection.index, selection.length, { [style]: color }, 'user');
			}
		};
		
		const applyStyle = (style: string) => {
			switch (style) {
				case "bold":
				case 'italic':
				case 'underline':
					quill.formatText(selection.index, selection.length, { [style]: !quill.getFormat()[style] }, 'user');
					break;
				case "color":
				case "background":
					quill.formatText(selection.index, selection.length, { [style]: color }, 'user');
					break;
			}
		};
		
		const excludedChars = type === "QBFillingBlank" ? ['[', ']'] : ['#', '[', ']', '/'];

		if (type === "QBFillingBlank" || type === "QBToggleOptions") {
			if (isFullTextSelected && quill.getFormat(selection.index, 1)[style] && quill.getFormat(selection.length -1, 1)[style]) {
				toggleFormat(style, color);
			} else {
				applyStyle(style);
			}

			if (type === "QBFillingBlank") {
				for (let i = 0; i < text.length; i++) {
					let textIndex = selection.index + i
					const fullText = quill.getText().trim()
	
					if (excludedChars.includes(text[i])) {
						quill.formatText(selection.index + i, 1, {
							'bold': false,
							'italic': false,
							'underline': false,
							'color': null,
							'background': null
						} ,'user');
					}
	
					// Stack to track open brackets
					let bracketStack = [];
					
					for (let j = 0; j < fullText.length; j++) {
						if (fullText[j] === '[') {
							bracketStack.push(j);
						} else if (fullText[j] === ']') {
							if (bracketStack.length > 0) {
								let openBracketIndex = bracketStack.pop();
								let closeBracketIndex = j;
	
								// Check if current text index is within this bracket pair
								if (textIndex > openBracketIndex && textIndex < closeBracketIndex) {
									quill.formatText(selection.index + i, 1, {
										'bold': false,
										'italic': false,
										'underline': false,
										'color': null,
										'background': null
									} ,'user');
									break;
								}
							}
						}
					}
				}
			} else {
				for (let i = 0; i < text.length; i++) {
					if (excludedChars.includes(text[i])) {
						quill.formatText(selection.index + i, 1, {
							'bold': false,
							'italic': false,
							'underline': false,
							'color': null,
							'background': null
						} ,'user');
					}
				}
			}
		} else {
			applyStyle(style);
		}
		this.quillSelectionChanged(null, true)
	}
}
