import { AfterViewInit, Component, HostListener, Input, ViewChild } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
// import { Subject } from 'rxjs';
// import { debounceTime } from 'rxjs/operators';
@Component({
	selector: "course-name-renderer",
	template: `
	<div class="icon {{iconType}}">
		<div *ngIf="iconType == 'top-folder'">{{group}}</div>
	</div>
	
	<div class="text" >
		<!-- [ngStyle]="textStyle" -->
		<span>{{name}}</span>
	</div>
    `,
	styleUrls: ['./course-name.renderer.scss']
})


export class CourseNameRenderer implements AfterViewInit {
	/*
		data should include name, group, level

	*/
	public params: any;
	public faIconStyle:any;
	public currentValue: any;
	public photo:any;
	public iconType;
	public group;
	public name = '';
	public faIcon:any;
	ngAfterViewInit() {
		
	}

	ngOnDestroy(): void {
	}

	agInit(params: ICellEditorParams): void {
		this.params = params;
		let data = params.data;
		this.name = data.name;
		this.group = data.group;
		this.iconType = data.type == 'folder' && data.level == 0?'top-folder': data.type;
		this.iconType = 'type-' + this.iconType;
	}


}
