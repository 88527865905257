import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { LoadingService } from '../loadingModule/loading.service';
import * as moment from 'moment';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { Modal2Component } from '../modal2Module/modal2.component';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faUserRobot } from '@fortawesome/pro-light-svg-icons';
import { environment } from 'src/environments/environment';
import { AICompositionMarkingDetails } from './aiCompositionMarkingDetails';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { PreviewComponent } from '../previewModule/preview.component';

@Component({
	selector: 'ai-composition-personal-report',
	template: `
	<modal2
		#modal
		[contentStyle]="modalContentStyle" [showCancelBtn]="false"
		(confirmClick)="confirm()"
		>
		<div class="customTitle" customTitle>
			{{modalTitle}}<div *ngIf="status != 'open'" class="status {{status||''}}">{{ statusText }}</div>
		</div>

			<ai-composition-marking-details 
				#details [enableAIButton]="false"
				[data]="data"></ai-composition-marking-details>

	</modal2>
	<preview #preview></preview>
	
	`,
	styleUrls: ['./aiCompositionPersonalReport.modal.scss'],
})

export class AICompositionPersonalReport implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('modal', {static: false}) modal: Modal2Component;
	@ViewChild('details', {static: false}) details: AICompositionMarkingDetails;
	@ViewChild('preview', {static: false}) preview: PreviewComponent;
	public modalTitle = '';
	public modalContentStyle:any = {};
	public confirmLabel;
	public info;
	public lang;
	public faEllipsisVertical = faEllipsisVertical;
	public status;
	public statusText;
	constructor(private san: DomSanitizer, private trans: TranslateService, public datas: DataService, private als: AlertService, public eleRef: ElementRef, public theme: ThemeService, private common: CommonService, private lds: LoadingService, public zone: NgZone, public sans: DomSanitizer) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.data.isPersonal = true;
		this.modalTitle = this.data.share.name;
		this.modalContentStyle = {width: 'calc(100vw - 40px)', height: 'calc(100dvh - 94px)', overflow: 'hidden', padding: '0'};
		this.initTheme(this.eleRef.nativeElement);
		this.status = this.data.share.status;
		this.statusText = this.data.share.statusText;
		this.getData();
		this.test();
	}

	ngAfterViewInit() {
		this.data.previewComp = this.preview;
		setTimeout(()=>{
			this.modal.open().then(event=>{
				
			});
		},10);
	}

	ngOnDestroy(): void {
	}

	test(){
	}

	getData(){
		const bsid = this.data.share.id;
		const uid = this.data.selectedUid;
		const student = this.data.students[0];
		this.lds.add('ROBookshelfNew.getAICompositionMarkingInfoForStudent',5000);
		this.datas.post('ROBookshelfNew.getAICompositionMarkingInfoForStudent', [bsid, uid]).subscribe((res:any)=>{
			student.aiAsks = res.aiAsks;
			student.result = res.result;
			this.formatPeople(student);
			this.details.loadStudent();
			this.lds.remove('ROBookshelfNew.getAICompositionMarkingInfoForStudent');
		});
	}

	initTheme(ele) {
		this.theme.applyStyleObj({
			default: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#333",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-selectedtextcolor": "var(--section-maincolor)",
				"pulldown-overbgcolor": "#e2e2e2",
				"popupform-panelbgcolor": "#f5f5f5",
				"color-ccc-38374f": "#ccc",
				"popupform-rowbgcolor": "#fff",
				"popupframe-headerbgcolor": "#fff",
				"popupframe-titlecolor": "#333",
				"popupform-subtitlecolor": "#333",
				"popupform-textcolor": "var(--section-maincolor)",
				"color-f9f9f9-1e1e29": "#f9f9f9",
				"color-333-fff": "#333",
				"alert-bgcolor": "#fff",
				"color-f1f1f7-262635": "#f1f1f7",
				"color-f7f7f7-222237": "#f7f7f7",
				"color-ececec-262635": "#ececec",
				"pulldown-selectedbgcolor": "var(--section-maincolor)"
			},
			dark: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-selectedtextcolor": "#fff",
				"pulldown-overbgcolor": "#1e1e29",
				"popupform-panelbgcolor": "#38374f",
				"color-ccc-38374f": "#38374f",
				"popupform-rowbgcolor": "#1e1e29",
				"popupframe-headerbgcolor": "#1e1e29",
				"popupframe-titlecolor": "#fff",
				"popupform-subtitlecolor": "#a0a0a0",
				"popupform-textcolor": "#fff",
				"color-f9f9f9-1e1e29": "#1e1e29",
				"color-333-fff": "#fff",
				"alert-bgcolor": "#2d2c43",
				"color-f1f1f7-262635": "#262635",
				"color-f7f7f7-222237": "#222237",
				"color-ececec-262635": "#262635",
				"pulldown-selectedbgcolor": "#fff"
			}
		}, ele);

	}

	formatPeople(student){
		student.name = this.datas.lang == 'en' ? student.ename : student.cname;
		let url = '//dev.openknowledge.hk/RainbowOne/webapp/2.8/roAnalyticsChart/assets/img/avatar_student_' + (student.gender == 1 ? 'male' : 'female') + '.svg';
		if (student.url && student.server) {
			url = environment.resourceServer[student.server] + student.url;
		}
		student.photo = this.sans.bypassSecurityTrustStyle("url('" + url + "')");
		if (student.result){
			['answer','teacher_score','ai_score'].forEach(prop=>{
				if (student.result[prop]){
					student.result[prop] = JSON.parse(student.result[prop]);
				} else if (prop != 'answer'){
					student.result[prop] = this.data.info.scoringItems.map(e=> null);
				}
			});
			student.submitted = 1;
		} else {
			student.result = {comment_teacher: '', comment_ai: null, answer: {paragraphs: []}, teacher_score: [], ai_score: [] };
			student.submitted = 0;
		}
		return student;
	}

	confirm(){
		this.close.emit(null);
	}
	

}
