import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ms2Time',
})

export class Ms2TimePipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value))
      return '--:--';
    value = value * 0.001;
    const minutes: number = Math.floor(value / 60);
    const mm = (minutes < 10 ? '0' + minutes : minutes);
    const seconds: number = Math.round(value - minutes * 60);
    const ss = (seconds < 10 ? '0' + seconds: seconds);
    return mm + ':' + ss;
  }
}
