
import { Component } from '@angular/core';
@Component({
	selector: "ro-book",
	template:`RO Book
		<button (click)="open()"></button>
		<div class="container">

		</div>

	`
})

export class ROBookComponent
{
	constructor()
	{
		
	}
	
	public open():void{
		alert("HELLO");
	}
}
