import { HttpClient } from "@angular/common/http";
import { InjectionToken } from "@angular/core";
// import { ModuleTranslateLoader } from "@larscom/ngx-translate-module-loader";
import { NgxTranslateModuleLoader } from "../module/ngxTranslateModule/ngx-translate-module.loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";


export interface ILanguageFileSetting {
	languages:string[],
	basePath:string
}

export const LanguageFileSetting = new InjectionToken<ILanguageFileSetting>('language');

// @Injectable()
export const startUpTime = +new Date();
export function TranlsateModuleLoaderFactory(http: HttpClient,  languageFileSetting: ILanguageFileSetting = null) {
	var baseTranslateUrl = languageFileSetting.basePath;
	 
	var options: any = {
		disableNamespace: true,
		lowercaseNamespace: true,
		version: startUpTime,
		modules: [
			{ baseTranslateUrl },
		]
	};
	languageFileSetting.languages.forEach((lang:string)=>{
		options.modules.push({baseTranslateUrl:baseTranslateUrl, moduleName:lang});
	});
	return new NgxTranslateModuleLoader(http, options);
}

export class TranslateModuleLoader
{
	constructor()
	{

	}
	public static forRoot()
	{
		return TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranlsateModuleLoaderFactory,
				deps: [
					HttpClient,
					LanguageFileSetting
				]
			}
		});
	}
}

