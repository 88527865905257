import { AnswerSource } from "src/app/sharedModule/roBookModule/ROBookConfig";

export class ROStudentDataSourceManager
{
	public map:any;
	private pageMap:any;
	constructor()
	{
		this.map = {};
		this.pageMap = {};
	}
	navigate(obj:any, linkage:string):any
	{
		var parts:string [] = linkage.split("/");
		var pointer:any = obj;
		var length = parts.length;
		for(var i = 0;i < length;i++)
		{
			var link:string = parts[i];
			if(!pointer.hasOwnProperty(link)) {
				return null;
			}
			pointer = pointer[link];
			if(!pointer) return null;
		}
		return pointer;
	}
	assign(obj:any, linkage:string, data:any):void{
		var parts:string [] = linkage.split("/");
		var pointer:any = obj;
		var lastKey:string = parts.pop();
		var length = parts.length ;
		for(var i = 0;i < length;i++)
		{
			var link:string = parts[i];
			if(!pointer.hasOwnProperty(link)) pointer[link] = {};
			pointer = pointer[link];
			if(!pointer) return null;
		}
		pointer[lastKey] = data;
	}
	/*
	assignAnswer(uid: any, index: any, bid: any, pid: any, e2: any) 
	{
		var pageData:any = this.navigate(this.map, `${uid}/${index}/${bid}/${pid}`)
		if(!pageData.hasOwnProperty("records"))
		{
			pageData.records = [];
		}
		pageData.records.push(e2);
		this.assign(pageData, `map/${e2.cid}`, e2);
		
	}
	*/

	/*
	addSource(source: AnswerSource) {
		// auto added
		var index:any = source.index;
		var student:any = source.student;
		var key:string = `${index}/${student.uid}`;
		this.assign(this.map, key, source);
	}
	*/
	public setPageReady(pageInfo:any):void
	{
		var key:string = `${pageInfo.index}/${pageInfo.chapterID}/${pageInfo.pageID}`;
		this.pageMap[key] = true;
	}
	public hasPageInfo(pageInfo:any):boolean
	{
		var key:string = `${pageInfo.index}/${pageInfo.chapterID}/${pageInfo.pageID}`;
		return this.pageMap.hasOwnProperty(key);
	}
	getSource(index:number, student:any):AnswerSource
	{
		var key:string = `${index}/${student.uid}`;
		var source:AnswerSource = this.navigate(this.map, key);
		if(!source)
		{
			source = new AnswerSource();
			source.index = index;
			source.student = student;
			this.assign(this.map, key, source);
		}
		return source;
	}
	
}
