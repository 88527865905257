import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubbleBox2Component } from './bubbleBox2.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
      BubbleBox2Component
    ],
    exports: [
      BubbleBox2Component
    ],
    providers: [
    ],
    entryComponents: []
})
export class BubbleBox2Module {
}
