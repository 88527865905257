import { ByteArray } from "openfl";
import { SmoothDrawingType } from "./SmoothDrawingType";
import { LineGeneralization } from "./LineGeneralization";
// import { simplify } from "./SimpleSimplify"; 
// import { LineGeneralization } from "./LineGeneralization";
export class DrawingBase
{

	public dataID:number = -1; // 0:old data, -1:non sync state
	public dataType:number = 1;
	public thickness:number;
	public color:number;
	public hexColor:string;
	public alpha:number;

	public path:string = "";
	public pathArray:string [] = [];
	public pts:any[] = [];

	protected lastPoint:any = null;
	public completed:boolean = false;
	public minD:number = 4;
	
	constructor()
	{
		this.reset();
	}
	setup(dataType:number, thickness:number, color:number, alpha:number):DrawingBase{
		this.dataType = dataType;
		this.thickness = thickness;
		this.color = color;
		this.hexColor = this.toHexColor();
		this.alpha = alpha;
		return this;
	}

	public toHexColor():string {
		var output:string = this.color.toString(16);
		while(output.length<6)
			output = "0"+output;
		return "#"+output;
	}
	
	public addPoint(point:any, savePoint:boolean = true):string {
		// Straight Line Drawing
		var add:boolean;
		if(savePoint)
		{
			if(!this.lastPoint || this.distance(this.lastPoint, point) > this.minD)
			{
				add = true;
			} else if(this.pts.length > 1)
			{
				this.pathArray.pop();
				this.pts.pop();
			}
		} else {
			add = true;
		}
		this.pts.push(point);
		if(this.path == "") {
			if(this.dataType == SmoothDrawingType.DT_EPEN){
				this.pathArray = [`M${point.x} ${point.y}`];
			}	
		} else {
			if(this.dataType == SmoothDrawingType.DT_EPEN) {
				// 用線連點
				//this.path += " L"+point.x+" "+point.y;
				// 用曲線連點
				this.pathArray.push(`${point.x} ${point.y}`);
			}
		}
		this.path = this.pathArray.join(" "); 
		if(add) this.lastPoint = point;
		return this.path;
	}

	public lineTo(point:any, savePoint:boolean = true):string {
		if(this.pts.length<2) {
			this.pts.push(point);
		} else {
			this.pts[1] = point;
		}
		var pt:any = this.pts[0];
		this.pathArray = [`M${pt.x} ${pt.y}`];
		if(this.pts.length == 2){
			this.pathArray.push(`${point.x} ${point.y}`);
		}
		this.path = this.pathArray.join(" ");
		this.lastPoint = point;
		return this.path;
	}

	public simplify():void {
		var l:LineGeneralization = new LineGeneralization();
		var pts:any [] = l.simplifyLang(2, 0.1, this.pts)
		// var pts:any [] = simplify(this.pts, 0.1, false)
		this.reset();
		pts.forEach((pt) => {
			this.addPoint(pt, false);
		});
		this.pts = pts;
	}

	protected reset():void
	{
		this.path = "";
		this.pathArray = [];
		this.pts = [];
	}

	public hitTestEraser(eraser:any):boolean {
		/*if (highlight)
		{
			var pt:Point = eraser.parent.localToGlobal(new Point(eraser.x, eraser.y));
			return drawSpr.hitTestPoint(pt.x, pt.y, true);
		}*/
			
		var detectDistance:number = (eraser.width + this.thickness) / 2;
		if (this.pts.length < 2)
			return this.pts.length == 0 ? false : this.distance(this.pts[0], eraser) <= detectDistance;
		
		for (var i:number = 1; i < this.pts.length; i++) {
			var pt1:any = this.pts[i-1];
			var pt2:any = this.pts[i];
			var res:any = this.getDistanceFromLine(pt1, pt2, eraser, true);
			if (res.dist>=0 && res.dist <= detectDistance)
				return true;
		}
		
		return false;
	}

	// =======================================
	// math function
	// =======================================
	public distance(pt1:any, pt2:any):number {
		return Math.sqrt(Math.pow(pt1.x-pt2.x, 2) + Math.pow(pt1.y-pt2.y, 2));
	}

	public getDistanceFromLine(a:any, b:any, c:any, as_seg:boolean = false):any {
		c = {x:c.x, y:c.y*-1}; // flip for Flash
		var obj:any = {dist:-1};
		if (a.x == b.x) {
			c.y *= -1;
			// in vertical
			if (!as_seg || (a.y<=c.y && c.y<=b.y) || (b.y<=c.y && c.y<=a.y)) {
				obj.dist = Math.abs(c.x - a.x);
				obj.poi = {x:a.x, y:c.y};
			}
		} else if (a.y == b.y) {
			c.y *= -1;
			// in hoz
			if (!as_seg || (a.x<=c.x && c.x<=b.x) || (b.x<=c.x && c.x<=a.x)) {
				obj.dist = Math.abs(c.y - a.y);
				obj.poi = {x:c.x, y:a.y};
			}
		} else {
			var m:number = (a.y - b.y) / (a.x - b.x);
			var B:number = (1/m)*c.x - c.y;
			var d:any = {x:b.x, y:b.x*(-1/m)+B};
			var e:any = {x:a.x, y:a.x*(-1/m)+B};
			var poi:any = this.lineIntersectLine(a, b, d, e, as_seg); // use Keith's function to check for an intersection
			if (poi != null) {
				var dx:number = poi.x - c.x;
				var dy:number = poi.y + c.y;
				obj.dist = Math.floor(Math.pow(dx * dx + dy * dy, .5));
				obj.poi = poi;
			}
		}
		return obj;
	}

	public lineIntersectLine(A:any, B:any, E:any, F:any, as_seg:Boolean = false):any {
		var a1:number = B.y-A.y;
		var b1:number = A.x-B.x;
		var c1:number = B.x*A.y - A.x*B.y;
		var a2:number = F.y-E.y;
		var b2:number = E.x-F.x;
		var c2:number = F.x*E.y - E.x*F.y;

		var denom:number = a1 * b2 - a2 * b1;
		if (denom == 0) return null;
			
		var ip:any = {x:(b1*c2 - b2*c1)/denom, y:(a2*c1 - a1*c2)/denom};
		//---------------------------------------------------
		//Do checks to see if intersection to endpoints
		//distance is longer than actual Segments.
		//Return null if it is with any.
		//---------------------------------------------------
		if (as_seg) {
			var chk:number = Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2);
			if (Math.pow(ip.x - B.x, 2) + Math.pow(ip.y - B.y, 2) > chk ||
				Math.pow(ip.x - A.x, 2) + Math.pow(ip.y - A.y, 2) > chk)
				return null;
			
			chk = Math.pow(E.x - F.x, 2) + Math.pow(E.y - F.y, 2);
			if (Math.pow(ip.x - F.x, 2) + Math.pow(ip.y - F.y, 2) > chk ||
				Math.pow(ip.x - E.x, 2) + Math.pow(ip.y - E.y, 2) > chk)
				return null;
		}
		return ip;
	}

	// =======================================
	// save/load function
	// =======================================
	public fromBytes(typeID:number, ba:ByteArray):DrawingBase {
		var color:number = ba.readUnsignedInt(); // 4 bytes
		var alpha:number = ((color >> 24) & 0xff) / 255;
		var thickness:number = ba.readFloat(); // 4 bytes
		if (thickness >= 8) alpha = 0.5;
		var len:number = ba.readUnsignedInt(); // 4 bytes
		var sm:DrawingBase = this.setup(typeID, thickness, color & 0xffffff, alpha);
		var p:any = {};
		p.x = ba.readInt();
		p.y = ba.readInt();
		sm.addPoint(p);
		for (var i:number = 1; i < len; i++) {
			var tx:number = ba.readInt();
			var ty:number = ba.readInt();
			var p2:any = {x:p.x + tx, y:p.y + ty};
			sm.addPoint(p2);
			p = p2;
		}
		sm.dataID = 0;
		return sm;
	}

	public writeBytes(ba:ByteArray):void {
		var len:number = this.pts.length;
		if (len > 1) {
			ba.writeUnsignedInt(this.color | (Math.floor(this.alpha*255)<<24));
			ba.writeFloat(this.thickness);
			ba.writeUnsignedInt(len);// points
				
			var pt:any = this.pts[0];
			ba.writeInt(pt.x);
			ba.writeInt(pt.y);
			for (var i:number = 1; i < len; i++) {
				var pt2:any = this.pts[i];
				ba.writeInt(pt2.x - pt.x);
				ba.writeInt(pt2.y - pt.y);
				pt = pt2;
			}
		}
	}
}
