
import { CoordinateExpression } from './CoordinateExpression';
import { DataService } from "src/app/service/data.service";
import { XMLNode } from "./xml/XMLNode";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLJSParser, XMLNodeXMLParser } from "./xml/XMLParser";
import { ROBookStructureReader } from 'src/app/ro/hk/openknowledge/ro/RODocumentDecoder';
import { ROSectionHelper } from './ROSectionHelper';
import { AnswerSource, ROBookConfig } from './ROBookConfig';
import { QuestionSectionParser } from './ROQuestionNumber';

export class RODocument
{
	private chapters:any [];
	private pageNodes:any[];
	private pages:any = [];
	private parser: XMLNodeXMLParser;
	private _textFlowUtils: TextFlowUtils;
	
	public questionCount:number;
	public bookScore:number;

	constructor(private o:any, private dataService:DataService)
	{
		this._textFlowUtils = new TextFlowUtils();
		// this.parser = new FastXMLParser();
		this.parser = new XMLJSParser();
		this.chapters = o.chapters;
		this.pageNodes = [];
		this.pages = [];
		var questionSectionParser:QuestionSectionParser = new QuestionSectionParser()
		var reader:ROBookStructureReader = new ROBookStructureReader()
		o.chapters.forEach((chapter:any)=>{
			chapter.xml = chapter.xml.replace(/　/g, "");
			var node = this.parser.parse(chapter.xml);
			chapter.node = node;
			chapter.pageNodes = node.selectAll("Chapter.Page");
			chapter.pageNodes.forEach((node:XMLNode)=>{
				this.pageNodes.push(node);
				var info = reader.extractPageInfo(node);
				var pageScore:number = 0;
				
				info.components.forEach((comInfo:any)=>{
					if(comInfo.score)
					{
						if(
							(comInfo.s && comInfo.s.enable )||
							(comInfo.hasScoring)
						)
						{
							pageScore += comInfo.score.full;
						}
					}
				})
				var s:string = node.getAttribute("questionSectionStart")
				var questionSectionStart = questionSectionParser.parseSectionInfo(s);
				if(!s)
				{
					var start:number = node.getAttribute("questionStartIndex");
					questionSectionStart.X = start;
				}
				this.pages.push({
					id:node.getAttribute("douid"),
					components:info.components,
					chapter:chapter,
					page:node,
					questionSectionStart:questionSectionStart,
					info:{
						questionCount:info.components.length,
						score:pageScore
					}
				})
			});
		}); 
	}
	getChapterCount()
	{
		return this.chapters.length;
	}
	updateBookInfo() 
	{
		var questionCount:number = 0;
		var bookScore:number = 0;
		var sectionInfo:any = {};

		this.pages.forEach((pageInfo:any, pageIndex:number)=>{
			var pageNode:XMLNode = pageInfo.page;
			var questionSection:string = pageNode.getAttribute("questionSection", "");
			// 
			if(!questionSection) questionSection = pageInfo.info.questionCount;
			var pageSectionInfo:any = ROSectionHelper.parse(questionSection);
			var sectionStart:string = ROSectionHelper.stringify(sectionInfo, pageSectionInfo);
			// console.log(pageIndex, "開始", sectionStart, "本頁", questionSection);
			// pageNode.setAttribute("questionSection", sectionStart);
			pageNode.setAttribute("questionSectionStart", sectionStart);
			pageNode.setAttribute("questionStartIndex", questionCount);

			bookScore += pageInfo.info.score;
			questionCount += pageInfo.info.questionCount;
			sectionInfo = ROSectionHelper.unionInfo(sectionInfo, pageSectionInfo);
		})
		this.questionCount = questionCount;
		this.bookScore = bookScore;
	}

	public getBook():any
	{
		return this.o.book;
	}
	getPageNode(pageID:string):void
	{
		return this.pageNodes.find((node:XMLNode)=>{
			return node.getAttribute("douid") == pageID;
		});
	}

	getPages():any []
	{
		return this.pages;
	}
	
	getPageNodes():XMLNode []
	{
		return this.pageNodes;
	}

	getPageIDArray():string [] 
	{
		return this.pageNodes.find((node:XMLNode)=>{
			return node.getAttribute("douid");
		});
	}

	getFirstPageNode()
	{
		return this.pageNodes.length ?this.pageNodes[0] : null;
	}

	render(node:XMLNode):HTMLElement
	{
		// console.log(JSON.stringify(node.node));
		return this.createComponentDOM(node);
		
	}

	createComponentDOM(node:XMLNode):HTMLElement
	{
		
		var dom:HTMLElement = document.createElement("div");
		var douid = node.getAttribute("douid");
		// dom.style.backgroundColor = "#FF000010"
		dom.classList.add("ro-component");
		dom.classList.add(node.tag);
		dom.setAttribute("type", node.tag);
		dom.setAttribute("douid", douid);
		/*
		if(douid == "37CB5AD0-99C9-9A2D-C289-1E75EDEC29B1")
		{
			console.log(1);
		}
		*/
		if(node.tag == "Page" || node.tag == "Group")
		{
			if(node.tag == "Page")
			{
				// dom.style.opacity = "0.2";
				// dom.style.backgroundColor = node.getAttribute("color");
				dom.setAttribute("title", node.getAttribute("slideTitle"));
				dom.setAttribute("questionCount", node.getAttribute("questionCount"));
			}
			node.children.forEach((childNode:XMLNode)=>{
				var childDOM:HTMLElement = this.createComponentDOM(childNode);
				dom.appendChild(childDOM);
			})
		} else if(node.tag == "ShapeRectangle")
		{
			dom.style.backgroundColor = node.getAttribute("shapeColor");
			// debugger;
			
			
		} else if(node.tag == "TLFText" || node.tag == "SuperText")
		{
			var textflow:string = node.children[0].text;
			

			var t:any = node.getAttribute("transparency", null)
			
			var topTextFlow:HTMLElement = this._textFlowUtils.textFlowToDOM(textflow, false)
			dom.appendChild(topTextFlow);
			
			if(t !== null)
			{
				dom.style.opacity = `${t /100}`;
			}
			
			
		} else if(node.tag == "Image")
		{
			this.renderImage(dom, node);
			/*
		// } else if(node.tag == "Graphic" || node.tag == "Loader" )
		// } else if(node.tag == "Graphic")
		// {
		// 	this.renderSWF(dom, node);
			*/
		} else {
			dom.innerText = node.tag +"/ not support";
			dom.classList.add("unknown");
			// dom.className = "unknown";
			// dom.style.border = "solid 1px red";

		} 
		// dom.style.border = "solid 1px red";
		
		this.updateBounds(dom, node);
		return dom;
	}
	private renderSWF(dom:HTMLElement, node:XMLNode):void
	{
		
		var src:string=  node.getAttribute("src");
		if(!src) return;
		
		if(src.indexOf("OKG://") == 0){
			this.dataService.call("Resource.lookup_array", [src]).then((o:any)=>{
				var url:string = o.mapping[0].to;
				// style.backgroundImage = `url(${url})`;
				if(location.protocol=="http") {
					if(url.indexOf("https://") == 0)
						url = location.protocol+url.substring(6);
				} else if(url.indexOf("http://") == 0) {
					url = location.protocol+url.substring(5);
				}
				console.log("swf", url);
				var win:any = window;
				win.swf2js.load(
					url,
					{
						tagId: dom
					}
				);
			})
		}
	}
	private renderImage(dom:HTMLElement, node:XMLNode):void
	{
		var style:CSSStyleDeclaration = dom.style;
		
		var src:string=  node.getAttribute("src");
		style.backgroundSize = "contain";
		if(!src) return;
		if(src.indexOf("https://") == 0){
			style.backgroundImage = `url(${src})`;
		}
		if(src.indexOf("OKG://") == 0){
			this.dataService.call("Resource.lookup_array", [src]).then((o:any)=>{
				var url:string = o.mapping[0].to;
				if(url.indexOf("http://") == 0)
					url = url.substring(5);
				else if(url.indexOf("https://") == 0)
					url = url.substring(6);
				style.backgroundImage = `url(${url})`;
				
			})
		}
	}

	private updateBounds(dom:HTMLElement, node:XMLNode):void
	{
		var style:CSSStyleDeclaration = dom.style;
		style.position = "absolute";
		
		var ce:CoordinateExpression = this.parseCE(node.getAttribute("coordinateExpression"));
		if(node.tag == "Page")
		{
			style.width = node.getAttribute("w") + "px";
			style.height = node.getAttribute("h") + "px";
			
		} else {
			if(!ce) return ;
			
			// transform: translate(-150px, -100px) rotate(45deg) translate(150px, 100px);
			style.left = ce.getX() + "px";
			style.top = ce.getY() + "px";
			style.width = ce.getWidth() + "px";
			style.height = ce.getHeight() + "px";
			if(ce.rotation)
			{
				style.transformOrigin = "left top";
				style.transform = `rotate(${ce.rotation}deg)`;
				// style.transform = `translate(-50%, -50%) rotate(${ce.rotation}deg) translate(50%, 50%)`;
			}
		}
	}
	private parseCE(ceString:string):any
	{
		if(!ceString) return null;
		var ce:CoordinateExpression = new CoordinateExpression(null, null);
		ce.parseExpression(ceString);
		return ce;
	}
	

	// init all page (簡化重複使用的 code)
	// return bookConfig
	public initAllPage(book:any, setting:any):any {
		var pages:any [] = this.getPages().map((o:any)=>{
			return {
				info:o.info,
				doc:this,
				pageNode:o.page,
				chapter:o.chapter,
				attributes:o.page.attributes
			};
		});

		var maxHeight:number = 0;
		var maxWidth:number = 0;
		pages.forEach((page)=>{
			if(page.attributes.w > maxWidth) maxWidth = page.attributes.w;
			if(page.attributes.h > maxHeight) maxHeight = page.attributes.h;
		});

		pages.forEach((page)=>{
			page.scaleX = page.attributes.w / maxWidth;
			page.scaleY = page.attributes.h / maxHeight;
			
			page.percentW = Math.floor(page.scaleX * 100);
			page.percentH = Math.floor(page.scaleY * 100);
			
		});

		var config:ROBookConfig = {
			index: 0,
			student: null,
			dataSource: new AnswerSource().fillData(book.serverData ? book.serverData : null),
			document: this,
			book: this.getBook(),
			chapters: this.chapters,
			pages: pages,
			context: {
				maxHeight: maxHeight,
				maxWidth: maxWidth
			},

			viewerID: book.viewerID,
			ownerID: book.ownerID,
			viewMode: book.viewMode,
			course: book.course,
			share: book.share,
			setting: setting,

			// viewer use values
			isMainSlide: false,
			splitViewEnable: false,
			pageIndex: 0,
			subViewMode: '',
			markingEnabled: false,
			showMarking: false,
			readOnly: false
		};
		return config;
	}
} 

