import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryPopupComponent } from './dictionaryPopup';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    declarations: [
        DictionaryPopupComponent
    ],
    exports: [
        DictionaryPopupComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class DictionaryPopupModule {
}