import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: "tick-renderer",
	template: `
<svg-icon *ngIf="svgIcon" 
	[class.isSelected]="params.isSelected"
	[name]="svgIcon" [ngStyle]="style" 
	></svg-icon>

<fa-icon *ngIf="faIcon" 
	[class.isSelected]="params.isSelected"
	[icon]="faIcon" [ngStyle]="style" 
	></fa-icon>
    `,
	styleUrls: ['./tick.renderer.scss']
})


export class TickRenderer implements AfterViewInit {
	public params: any;
	public text = '';
	public clickSubject = new Subject();
	public items:any[] = [];
	public faIcon:any;
	public svgIcon = '';
	public style;

	ngAfterViewInit() {

	}

	ngOnDestroy(): void {
	}

	@HostListener('click', ['$event']) onClick(event){
		if (this.params.click !== undefined){
			const res = this.params.click(this.params, event);
		}
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		this.style = this.params.style;
		this.faIcon = this.params.faIcon;
		this.svgIcon = this.params.svgIcon;
	}

	refresh(params){
		console.log(params);
		// this.params.isSelected = params.isSelected;
		return true;
	}


}
