export class PromiseUtils {
	public static delay(time: any, value: any): Promise<any> {
		return new Promise((resolve: any, reject: any) => {
			setTimeout(() => {
				resolve(value);
			}, time);
		});
	}
	/**
	 * 
	 * @param array 
	 * @param fn 
	 * @param firstValue 
	 * @returns 
	 */
	/*
	return PromiseUtils.reduce(
		array, 
		(prev:any, currentElement:any, index:number, reference:any [])=>{
			return something;
		}, 
		null
	).then((o:any)=>{
		return response;
	})
	*/
	public static reduce(array:any [], fn:Function, start:any) {
		const length = array.length;
		if (length === 0) {
			return Promise.resolve(start);
		}
		return array.reduce(function (promise, curr, index, arr) {
			return promise.then(function (prev) {
				if (prev === undefined && length === 1) {
					return curr;
				}
				return fn(prev, curr, index, arr);
			})
		}, Promise.resolve(start));
	}
}
