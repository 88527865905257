import { Component, OnInit, Input } from "@angular/core";
import { StorageService } from "../service/storage.service";
import { DomSanitizer } from "@angular/platform-browser";
import { faFileImage, faFileVideo, faFileAudio, faFile, faBook, faMessageLines, faBookBlank } from "@fortawesome/pro-solid-svg-icons";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { InlineSvgService } from "../../inlineSvgModule/inlineSvg.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "info",
	templateUrl: "./info.component.html",
	styleUrls: ["./info.component.scss"],
})

export class InfoComponent implements OnInit {
	public pieData: any = {};
	public image;
	public faIcon;
	public svgIcon;
	public svgIconLoaded = false;
	public faFileImage = faFileImage;
	public faFileAudio = faFileAudio;
	public faFileVideo = faFileVideo;
	public faBook = faBook;
	public faFile = faFile;
	public faGlobe = faGlobe;
	public faMessageLines = faMessageLines;
	public faBookBlank = faBookBlank;
	public numQuestionTitle;
	public roundedScore = 0;
	@Input() public modData;
	@Input() public viewSubmitOnly = false;
	constructor(
		public storageService: StorageService,
		private sanitizer: DomSanitizer, public svgReg: InlineSvgService, public trans: TranslateService
	) {}

	ngOnInit() {
		const isEng = this.storageService.lang == 'en';
		this.numQuestionTitle = this.trans.instant('report-personal.num-question');
		const bookType = this.modData.bookType;
		if (this.storageService.image){
			this.image = this.sanitizer.bypassSecurityTrustStyle(
				"url('" + this.storageService.image + "')"
			);
		} else {
			if (bookType == 'image'){
				this.faIcon = this.faFileImage;
			} else if (bookType == 'video'){
				this.faIcon = this.faFileVideo;
			} else if (bookType == 'audio'){
				this.faIcon = this.faFileAudio;
			} else if (bookType == 'url'){
				this.svgIcon = 'icon_weblink_16x16';
			} else if (bookType == 'youtube'){
				this.svgIcon = 'icon_youtube_16x12';
			} else if (bookType == 'report'){
				this.image = null;
				this.faIcon = this.faBook;
			} else if (bookType == 'ai-composition'){
				this.svgIcon = 'icon_writing_24x30';
			// } else if (share.share_type == 'lesson'){
				// this.svgIcon = 'icon_lesson';
			} else if (bookType == 'pdf-paper'){
				this.svgIcon = 'icon_textpaper_white';
			} else if (bookType == 'url'){
				this.faIcon = this.faGlobe;
			} else if (bookType == 'ai-composition'){
				this.svgIcon = 'icon_writing_white';
			} else if (bookType == 'ai-oral'){
				this.faIcon = this.faMessageLines;
			} else if (bookType == 'assessment'){
				this.svgIcon = 'icon_qb_white';
			} else if (bookType == 'ppt'){
				this.svgIcon = 'icon_ppt_white';
			} else if (bookType == 'excel'){
				this.svgIcon = 'icon_excel_white';
			} else if (bookType == 'word'){
				this.svgIcon = 'icon_word_white';
			} else if (bookType == 'pdf'){
				this.svgIcon = 'icon_pdf_white';
			} else if (bookType == 'book'){
				this.faIcon = this.faBookBlank;
			} else if (bookType == 'game'){
				this.svgIcon = 'icon_game_32x19';
			} else {
				this.faIcon = this.faFile;
			}
		}

		if (this.svgIcon){
			this.svgReg.regSvgFiles([
				{ url: 'course/icon_qb_white.svg', name: 'icon_qb_white' },
				{ url: 'course/icon_ppt_white.svg', name: 'icon_ppt_white' },
				{ url: 'course/icon_excel_white.svg', name: 'icon_excel_white' },
				{ url: 'course/icon_word_white.svg', name: 'icon_word_white' },
				{ url: 'course/icon_pdf_white.svg', name: 'icon_pdf_white' },
				{ url: 'course/icon_textpaper_white.svg', name: 'icon_textpaper_white' },
				{ url: 'fileicon/icon_reading_report_15x18_en.svg', name: 'icon_reading_report_15x18_en' },
				{ url: 'fileicon/icon_reading_report_15x18.svg', name: 'icon_reading_report_15x18' },
				{ url: 'fileicon/icon_writing_white.svg', name: 'icon_writing_white' },
				{ url: 'fileicon/icon_youtube_16x12.svg', name: 'icon_youtube_16x12' },
				{ url: 'fileicon/icon_weblink_16x16.svg', name: 'icon_weblink_16x16'},
				{ url: 'fileicon/icon_game_32x19.svg', name: 'icon_game_32x19'},
			]).then(()=>{
				this.svgIconLoaded = true;
			});
		}
		if (bookType == 'pdf-paper'){
			this.numQuestionTitle = this.trans.instant('report-personal.num-question-pdf-paper');
		}
		this.roundedScore = this.storageService.score !== undefined?Math.round(+this.storageService.score):0;
	}
}

