import { Component,Input,OnInit} from '@angular/core';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'additionRemarkBall',
    templateUrl: './additionRemarkBall.component.html',
    styleUrls: ['./additionRemarkBall.component.scss']
})

export class AdditionRemarkBallComponent implements OnInit {
	@Input() public bubbleText = "null";
	@Input() public width = "220px";
	@Input() public bgColor = "var(--section-maincolor)";
	@Input() public color = "#FFFFFF"
	public faEllipsis = faEllipsis;
    constructor() {

    }
	
    ngOnInit(): void {
    
    }
}