import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'util';

@Component({
  selector: 'mini-modal',
  template: `
    <div class="mini-modal">
      <div class="upper">
        <div class="message">{{message.msg|translate:message.obj}}</div>
      </div>
      <div class="down">
        <div class="panel alert" *ngIf="data.type=='alert'">
          <a (click)="cancelModal()">{{'i-see'|translate}}</a>
        </div>
        <div class="panel confirm" *ngIf="data.type=='confirm'">
          <a (click)="confirmModal()">
            <i class="confirm__yes__icon"></i>
            <span>{{(data.confirmText?data.confirmText:'confirm')|translate}}</span>
          </a>
          <a class="confirm__no" (click)="cancelModal()">
            <i class="confirm__no__icon"></i>
            <span>{{(data.cancelText?data.cancelText:'cancel')|translate}}</span>
          </a>
        </div>
      </div>
    </div>
  `
  , styleUrls: ['./mini.modal.scss'],
})
export class MiniModal implements OnInit{
  @Input() data: any;
  @Output() public loaded: EventEmitter<any> = new EventEmitter();
  public modalStyle;
  public headerColor;
  public message: { msg: string, obj: any } = { msg: '', obj: {}};
  constructor(private translate: TranslateService) {
    this.initModal();
  }

  ngOnInit() {
    this.map(this.data.message);
    let tt$ = setTimeout(() => {
      this.loaded.emit(null);
    }, 100);
  }

  protected initModal() {
    this.modalStyle = {
      width: '300px',
      // marginTop: 'calc((100vh - 180px) / 2)',
      // marginLeft: 'calc((100vw - 300px) / 2)'
    };
  }

  private map(message) {
    if (!isObject(message)) {
      this.message.msg = message;
    } else {
      this.message = message;
    }
    let match = this.message.msg.match(/\$\{.+\}/);
    if (match) {
      let key = match[0].substr(2, match[0].length - 3);
      let tt$ = this.translate.get(key).subscribe((translated) => {
        this.message.msg = this.message.msg.replace(/\$\{.+\}/,translated);
      });
    }
  }

  public saveModal() {
    return true;
  }

  public confirmModal() {
    if (this.data.confirm) {
      this.data.confirm();
    }
    this.data.close();
  }

  public cancelModal() {
    this.data.close();
    return true;
  }

}
