import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { OkaPulldownComponent } from './okaPulldown.component';
import { OkaPulldown2Component } from './okaPulldown2.component';
import { InlineSvgModule } from '../inlineSvgModule/inlineSvg.module';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';


@NgModule({
    imports: [
      CommonModule,
      FontAwesomeModule,
      PerfectScrollbarModule,
      TranslateModule,
      FormsModule,
      ScrollingModule, InlineSvgModule
    ],
    declarations: [
      OkaPulldownComponent, OkaPulldown2Component
    ],
    exports: [
      OkaPulldownComponent, OkaPulldown2Component
    ],
    providers: [ InlineSvgService ],
    bootstrap: []
})
export class OkaPulldownModule { }
