import { Component, Input, ViewChild } from "@angular/core";
import { BubbleBox2Component } from "../../bubbleBox2Module/bubbleBox2.component";
import { Modal2Component } from "../../modal2Module/modal2.component";
import { Subject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: 'ro-book-print-dialog',
	template:`

	<modal2 
		#modal 

		(cancelClick)="cancel()" 
		(confirmClick)="confirm()"
		[contentStyle]="{paddingTop:0, width:'610px'}"
		>
	
		<div class="header_info">
			<div class="title">
				<div style="padding: 0 8px;">
					<span *ngIf="isPDF">{{'ro.print.pdf-paper'| translate}}</span>
					<span *ngIf="!isPDF">{{'ro.print.book'| translate}}</span>
					:
				</div>
				<ro-book-icon 
					style="padding: 0 5px 0 0px;top: 3px; position: relative;"
					[file]="model.book"></ro-book-icon>
				<div style="max-width: 280px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
					{{model.title}} 
				</div>
			</div>
			<div class="info">
				<div class="item" *ngIf="model.allStudents">
					
					<span *ngIf="isPDF">{{ "ro.print.pdf-paper-page-count" | translate}}</span>
					<span *ngIf="!isPDF">{{ "ro.print.book-page-count" | translate}}</span>
					: {{model.pageCount}}
				</div>
				<div class="item" *ngIf="model.allStudents">
					{{ "ro.print.student-count" | translate}}: {{model.studentCount}}/{{model.allStudents.length}}
				</div>
				<div class="item">
					{{ "ro.print.print-total-page-count" | translate}}: {{model.totalPageCount}}
				</div>
			</div>
		</div>
		
		<div class="form" *ngIf="enabled">
			<ng-container *ngFor="let option of options" >
		    	<div class="line" *ngIf="isOptionEnabled(option)">
					<div class="label">{{option.label}}</div>
					<div class="value">
						<radioBox 
							[(value)]="model[option.key]" 
							[options]="option.showItems" 
							(valueChange)="onSelectionChanged()"
						></radioBox>

						<multiSelectPullDown 
							*ngIf="option.extra && option.extra.enabled()"
							(check_callback)="option.extra.onChange($event)"
							[width]="'150px'"
							[list]="option.extra.items()" 
							[value]="option.extra.value" 
							[label]="option.extra.label"
							[(preset)]="model[option.extra.key]">
						</multiSelectPullDown>
					</div>
				</div>
			</ng-container>
		</div>
    </modal2>

	`,
	styles:[

		`
		.line{
			display: flex;
		    height: 46px;
		}
		.line .value{
			background-color: var(--popupform-rowbgcolor);
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: var(--section-maincolor);
		}
		.header_info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 46px;
			width: 100%;
			color: var(--section-maincolor);
			font-size: 15px;
			font-weight: bold;
		}

		.header_info .title {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	
		.header_info .info {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.header_info .info .item{
			padding: 0 5px 0 15px;
		}

		.container{
			width:500px;
			height:500px;
		}

		.line:first-child{
			border-top-left-radius:10px;
			border-top-right-radius:10px;
		}
		.line:last-child{
			border-bottom-left-radius:10px;
			border-bottom-right-radius:10px;
		}
		
		.line{
			color: var(--common-textcolor);
			background-color: var(--popupform-rowbgcolor);
    		line-height:46px;
			margin-bottom:1px;
		}
		.line > div{
			display: inline-flex;
		}
		.line > .label{
			padding-left:10px;
			width:160px;
		}
		
		`

	]
})

export class ROBookPrintOptionDialog
{
	@Input() viewMode:string;
	@ViewChild('modal', {static:false}) modal!: Modal2Component;
	public subject:Subject<any> = new Subject();
	@Input() bookType: any;
	@Input() multiChapter:boolean = false;
	
	//public displaySVG:boolean = false;
	@Input() public isPDF:boolean = false;
	public enabled:boolean;

	constructor(private translateService:TranslateService)
	{

	}

	

	// public selectedPageCount:number = 1;
	// public selectedStudentCount:number = 1;
	// public totalPrintPageCount:number = 1;
	// public students:any[] = null;

	public onSelectionChanged():void
	{
		this.updateCount();
	}

	public updateCount():void
	{
		var pCount:number;
		if(this.model.pages == "book")
		{
			pCount = this.model.bookPageCount;
		} else if(this.model.pages == "chapter")
		{
			pCount = this.model.chapterPageCount;
		} else {
			pCount = 1;
		}
		if(this.model.allStudents)
		{
			if(this.model.printStudentRange == "all")
			{
				this.model.students = this.model.allStudents;
			} else {
				this.model.students = this.model.individuals;
				// this.model.students = this.selectedStudents;
			}
			this.model.studentCount = this.model.students.length;
		} else {
			this.model.students = null;
			this.model.studentCount = 1;
		}
		this.model.pageCount = pCount;
		this.model.totalPageCount = this.model.pageCount * this.model.studentCount;
	}
	/*
	public getTitle()
	{
		this.onSelectionChanged();
		var sCount:number = this.model.printStudentRange == "all" ? this.studentCount : 1;
		var pCount:number
		if(this.model.pages == "book")
		{
			pCount = this.bookPageCount;
		} else if(this.model.pages == "chapter")
		{
			pCount = this.chapterPageCount;
		} else {
			pCount = 1;
		}
		var total = sCount * pCount;
		return `${sCount}學生 x ${pCount}頁 = 共${total}頁`;
		
	}
	*/
	public isOptionEnabled(option:any):boolean
	{
		var type:string = typeof option.enabled;
		if(type == 'undefined') return true;
		if(type == 'boolean') return option.enabled;
		if(type == 'function') return option.enabled();
		return true;
	}
	public model:PrintBookOption = {
		book:null,
		bookPageCount:1,
		chapterPageCount:1,
		title:"",
		allStudents:[],
		individuals:[],
		totalPageCount:0,
		pageCount:0,
		studentCount:0,
		students:[],
		// printStudentRange:"currentStudent",
		printStudentRange:"individual",
		showSticker:true,
		showChapter:false,
		showMarking:true,
		submissionIndex:1,
		printType:"printer",
		pages:"page",
		withAnswer:false,
		withButton:false,
		showTitle:true,
		orientation:"auto",
		showContentBorder:false,
		showTimestamp:true
		// paperSize:"A4",
		// resolution:300
	};

	public boldLabel = {'font-weight': 700 };
	/*
	public studentsOptions:any[] = [
		{ 
			label: "所有", value: "all", lang:"ro.print.all-students",
			getTitle:(label:string)=>{ 
				if(this.model.allStudents)
				{
					return `${label}(${this.model.allStudents.length})`;
				} else {
					return `${label}`;
				}
			}
		},
		{ 
			label: "已選學生", value: "currentStudent", lang:"ro.print.current-student"
		},
	];
	*/
	public options:any[] = [
		{
			type:"radio",
			label:"頁數",
			key:"pages",
			lang:"ro.print.page-range",
			items:[
				{
					label: "全書", 
					value: "book", 
					lang:()=>{
						if(this.bookType == "pdf-paper")
						{
							return "ro.print.whole-pdf-paper";
						} else {
							return "ro.print.whole-book";
						}
					},
					getTitle:(label:string)=> { return `${label}(${this.model.bookPageCount})`; }
				},
				{
					label: "本章節", 
					value: "chapter", 
					lang:"ro.print.current-chapter", 
					enabled:()=>{ return this.multiChapter ? true : false; },
					getTitle:(label:string)=> { return `${label}(${this.model.chapterPageCount})`; }
				},
				{ label: "本頁", value: "page", lang:"ro.print.current-page"}
			]
		},
		{
			type:"radio",
			enabled:()=>{
				return this.viewMode == "scoring" ;
			},
			label:"學生",
			key:"printStudentRange",
			lang:"ro.print.students-range",
			items:[
				{ 
					label: "所有", value: "all", lang:"ro.print.all-students",
					// getTitle:(label:string)=>{ return `${label}(${this.model.studentCount})`;}
					/*
					getTitle:(label:string)=>{ 
						if(this.model.allStudents)
						{
							return `${label}(${this.model.allStudents.length})`;
						} else {
							return `${label}`;
						}
					}
					*/

				},
				{ 
					label: "個別學生", value: "individual", lang:"ro.print.individual-student"
				}
			],
			extra:{
				enabled:()=>{
					return this.model.printStudentRange == "individual" ;
				},
				value:"uid",
				label:"displayname",
				type:"options",
				key:"individuals",
				items:()=>{
					return this.model.allStudents;
				},
				onChange:(items:any[])=>{

					this.model.individuals = items;
					this.updateCount();
				}
			}
		},
		{
			type:"radio",
			enabled:()=>{
				return this.viewMode == "scoring" ;
			},
			label:"資料",
			key:"submissionIndex",
			lang:"ro.print.submission-index",
			items:[
				{ label: "批改", value: 1, lang:"ro.print.first-submission"},
				{ label: "改正", value: 2, lang:"ro.print.second-submission"}
			]
		},
		/*
		{
			type:"radio",
			enabled:()=>{
				return this.viewMode == "scoring" ;
			},
			label:"批改",
			key:"showMarking",
			lang:"ro.print.show-marking",
			items:[
				{ label: "是", value: true, lang:"ro.print.print-on"},
				{ label: "否", value: false, lang:"ro.print.print-off"}
			]
		},
		*/
		{
			type:"radio",
			label:"貼紙",
			key:"showSticker",
			lang:"ro.print.show-sticker",
			items:[
				{ label: "是", value: true, lang:"ro.print.print-on"},
				{ label: "否", value: false, lang:"ro.print.print-off"}
			]
		},
		/*
		{
			enabled:()=>{
				return this.viewMode == "view" || this.viewMode == "scoring" ;
			},
			label:"答案",
			lang:"ro.print.with-answer",
			key:"withAnswer",
			items:[
				{ label: "否", value: false, lang:"common.no"},
				{ label: "是", value: true, lang:"common.yes"}
			]
		},
		*/
		{
			type:"radio",
			enabled:()=>{
				return this.viewMode == "view" ;
			},
			label:"答案",
			lang:"ro.print.with-answer",
			key:"withAnswer",
			items:[
				{ label: "是", value: true, lang:"ro.print.print-on"},
				{ label: "否", value: false, lang:"ro.print.print-off"}
			]
		},
		/*
		{
			label:"組件按鈕",
			key:"withButton",
			lang:"ro.print.with-button",
			items:[
				{ label: "否", value: false, lang:"common.no"},
				{ label: "是", value: true, lang:"common.yes"}
			]
		},
		*/
		{
			type:"radio",
			// label:"書名及頁碼",
			lang:()=>{
				if(this.bookType == "pdf-paper")
				{
					return "ro.print.show-pdf-paper-page-title-no";
				} else {
					return "ro.print.show-page-title-no";
				}
			},
			key:"showTitle",
			items:[
				{ label: "是", value: true, lang:"ro.print.print-on"},
				{ label: "否", value: false, lang:"ro.print.print-off"}
			]
		},
		{
			type:"radio",
			// label:"書名及頁碼",
			lang:"ro.print.orientation",
			key:"orientation",
			items:[
				{ label: "橫", value: "landscape", lang:"ro.print.landscape"},
				{ label: "直", value: "portrait", lang:"ro.print.portrait"},
				{ label: "自動", value: "auto", lang:"ro.print.auto"}
			]
		},
		
		{
			type:"radio",
			// label:"書名及頁碼",
			lang:"ro.print.show-content-border",
			key:"showContentBorder",
			items:[
				{ label: "是", value: true, lang:"ro.print.print-on"},
				{ label: "否", value: false, lang:"ro.print.print-off"}
			]
		},
		{
			type:"radio",
			// label:"timestamp",
			lang:"ro.print.show-timestamp",
			key:"showTimestamp",
			items:[
				{ label: "是", value: true, lang:"ro.print.print-on"},
				{ label: "否", value: false, lang:"ro.print.print-off"}
			]
		},
		/*
		{
			label:"解像度",
			key:"resolution",
			items:[
				{ label: "低(72)", value: 72 },
				{ label: "中(144)", value: 144 },
				{ label: "高(300)", value: 300 }
			]
		},
		
		{
			
			label:"紙張大小",
			key:"paperSize",
			items:[
				{ label: "Letter", value: "Letter" },
				{ label: "A4", value: "A4" },
				{ label: "A3", value: "A3" },
				{ label: "A5", value: "A5" }
			]
		}*/
	] ;

	confirm():void{
		this.subject.next({type:"confirm", data:this.model});
		this.modal.close();
	}
	cancel():void{
		this.subject.next({type:"cancel"});
		this.modal.close();
	}
	close():void
	{
		this.modal.close();
	}
	private initLabels():void
	{
		this.model.showChapter = this.multiChapter;
		this.options.forEach((opt:any)=>{
			var label:string = this.translateService.instant(this.evaluate(opt.lang));
			opt.label = opt.getTitle ? opt.getTitle.call(this, opt.label) : label;
			opt.showItems = opt.items.filter((item)=>{
				var itemLabel = this.translateService.instant(this.evaluate(item.lang));
				item.label = item.getTitle ? item.getTitle.call(this, itemLabel) : itemLabel;
				item.show = item.hasOwnProperty("enabled") ? this.evaluate(item.enabled) : true;
				return item.show;
			});
		})
	}

	evaluate(n:any):any
	{
		if(n === true )
		{
			return true;
		} else if(n === false)
		{
			return false;
		} else if(n instanceof Function)
		{
			return n();
		} else {
			return n;
		}
	}

	
	public subscribe(a:any):Subscription
	{
		this.initLabels();
		this.modal.title = this.translateService.instant("ro.print.title");
		this.modal.open();
		this.updateCount();
		this.isPDF = this.bookType == "pdf-paper";
		var sub:Subscription = this.subject.subscribe(a);
		this.enabled = true;
		sub.add(()=>{
			this.enabled = false;
		});
		return sub;
	}
}

export interface PrintBookOption{
	book:any;
	bookPageCount: number;
	chapterPageCount:number;
	title: string;
	individuals:any[],
	totalPageCount: number;
	pageCount: number;
	studentCount: number;
	students:any[];
	allStudents:any[],
	printStudentRange:string;
	showSticker:boolean;
	showChapter:boolean;
	showMarking:boolean;
	submissionIndex:number;
	printType:string;
	pages:string;
	withAnswer:boolean;
	withButton:boolean;
	showTitle:boolean;
	orientation:string;
	showContentBorder:boolean;
	showTimestamp:boolean;
};