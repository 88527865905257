import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { FileIOService } from "src/app/service/FileIO.service";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertService } from 'src/app/service/alert.service.js';

// =======================================
// icon
// =======================================
import { faCamera, faImage, faUp, faCheck, faXmark, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-light-svg-icons';
import { faPen, faVolume } from '@fortawesome/pro-solid-svg-icons';
import { isNumber } from "util";
import { QBComponentBase } from "./QBComponentBase";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";
// 
// import lang_tc from './lang/tc.json';

@Component({
	template:`
	
	<div class="q scroll-container">
		<div class="qContainer ">
			
			<div class="qnumCol flex-row-left">
				<div class="flex-column-center">
					<div class="qnum">
						{{qObject.questionIndex+1}}
					</div>
				
					<!-- speaker tts icon -->
					<!--
					<div  *ngIf="!qObject.isPageComponent" class="speaker" (click)="speechQ(qObject.question)"></div>
					-->
				</div>


				<div class="qcontent">
					
					<QText [qObj]="qObject" ></QText>
					<div class="camera" [ngClass]="{disable:disableAction}">
						<div class="imgContent" [style.backgroundImage]="getCameraImageURLCSS()"></div>
						<ng-container>
							<fa-icon class="btn capture" (click)="takePhoto()" [icon]="faCamera"></fa-icon>
							<fa-icon class="btn selectFile" (click)="selectImage()" [icon]="faImage"></fa-icon>
						</ng-container>
					</div>
				
				</div>
			</div>
		</div>

		<div class="score">
					
			<!--
				<Scoring [displayType]="'ai'" 
				(onChange)="updateScore($event)" 
				*ngIf="isSubmitted() && qObject.type!='QBInfoBlock' && isDisplayAIScore()"
				[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
				[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
				[componentName]="getText('componentName.'+qObject.type)"></Scoring>
				<Scoring (onChange)="updateScore($event)" *ngIf="isSubmitted(i) && qObject.type!='QBInfoBlock' && isDisplayTeacherScore(i)"
				[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
				[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
				[componentName]="getText('componentName.'+qObject.type)"></Scoring>
			-->
		</div>

	</div>




	
	`,
	styleUrls:[
		"./QBComponentQuestionBase.scss",
		"./QBTakePhoto.scss"
	]
})
export class QBTakePhoto extends QBComponentQuestionBase
{
	public disableAction:boolean = false;

	public faCamera:IconDefinition = faCamera;
	public faImage:IconDefinition = faImage;
	public faUp:IconDefinition = faUp;
	public faCirclePlus:IconDefinition = faCirclePlus;
	public faCircleMinus:IconDefinition = faCircleMinus;
	public faCheck:IconDefinition = faCheck;
	public faXmark:IconDefinition = faXmark;
	public faPen:IconDefinition = faPen;
	public faVolume:IconDefinition = faVolume;

	public inEdit:boolean = false;
	public inEditMode:boolean=false;
	public qObject:any;
	public currentState:any = {};
	public langData:any;

	constructor(
		cdr:ChangeDetectorRef, 
		elementRef:ElementRef, 
		public fileio:FileIOService,
		private alertService:AlertService,
		private sans: DomSanitizer
	)
	{
		super(cdr, elementRef, fileio);
		// this.langData = lang_tc;
	}
	public takePhoto()
	{
		
	}

	public getCameraImageURLCSS():any {
		let url:string;
		if(this.currentState.file) {
			if(!this.currentState.url)
				this.currentState.url = URL.createObjectURL(this.currentState.file);
			url = this.currentState.url;
		} else if(this.currentState.url)
			url = this.fileio.getResourceServer(this.currentState.url)+this.currentState.url;

		if(url)
			return this.sans.bypassSecurityTrustStyle("url('"+url+"')");
		return "none";
	}
	
	public selectImage():void {
		this.getImageResult(this.fileio.getLocalFile({fileType:'image'}));
	}

	protected getImageResult( p:Promise<any>):void {
		let qVar:any = this.currentState;

		p.then((success)=> {
			this.markQChange(qVar);
			qVar.url = null;
			qVar.file = success.file;
		}, (reason) => {
			if(reason.msg!="cancel" && reason.msg!="cancel-by-close-window")
				this.alertService.alert(this.getText(reason.msg));
		});
	}

	public getText(key:string):string {
		return key;
		// return this.langData ? this.translate.parser.getValue(this.langData, key) : key;
	}

	public markQChange(qVar:any):void {
		qVar.submitted = false;
		qVar.scoreResult = null;
		qVar.showVerify = false;
	}


	public getTagNames():string []
	{
		return ["QBTakePhoto"];
	}

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
	}

	set data(input:string)
	{
		if(input)
		{
			
		} else {
			
		}
	}

	get data():string
	{
		if(this.hasAnswer())
		{
			var qObj:any = this.qObject;
			var qVar = this.currentState;
			let asset:any = qObj.type == "QBRecorder" ? {url:qVar.url, duration:qVar.duration} : {url:qVar.url};
			return (qVar.file ? "file data" : JSON.stringify({assets:[asset]}));	
		} else {
			return null;
		}
		
	}
	
	protected getResultObjectAndAnswer():any {
		let qObj:any = this.qObject;
		let qVar:any = this.currentState;
		let totalAns:number = 1;
		let correct:number = 0;
		let wrong:number = 0;
		let answerForSubmit:any;


//		if(qVar.file || qVar.url)
		correct = -1;
		let asset:any = qObj.type == "QBRecorder" ? {url:qVar.url, duration:qVar.duration} : {url:qVar.url};
		answerForSubmit = (qVar.file ? "file data" : JSON.stringify({assets:[asset]}));
//		if(correct==0 && wrong==0)
//			return null; // 無答過
		return {
			answerForSubmit:answerForSubmit,
			correct:correct,
			wrong:wrong,
			totalAns:totalAns,
			result:this.getResultObject(qObj, correct, wrong, totalAns)
		};
	}
}