import { Injectable } from '@angular/core';
import { NumberPadModal } from './numberPad.component';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class NumberPadService {
	public appendTo:HTMLElement;
	constructor(private dcs:DynamicComponentService) {
    }
	public setupContainer(container:HTMLElement)
	{
		this.appendTo = container;
	}

	/**
	 * 
	 	this.numberPadService.setupContainer(this.elementRef.nativeElement);
	 	var subscription:Subscription = this.numberPadService.prompt("password", "1").subscribe((data:any)=>{
			if(data.type == "confirm")
			{
				// do something and then unsubscribe
			} else if(data.type == "cancel")
			{
				subscription.unsubscribe();
			}
		})
	*/
	public prompt(title:string, code:string):Observable<any> {
        let modal:any = this.dcs.create(NumberPadModal);
		modal.title = title;
		modal.code = code;
		this.dcs.open(modal, this.appendTo ? this.appendTo : document.body);
		var subscription:Subscription = modal.emitter.subscribe((o)=>{
			if(o == "close")
			{
				subscription.unsubscribe();
				this.dcs.close(modal);
			}
		})
  		return modal.open(title, code);
	}

	
	public promptAsPromise(title:string, code:string):Promise<any> {
		return new Promise((resolve, reject)=>{
			let modal:any = this.dcs.create(NumberPadModal);
			modal.title = title;
			modal.code = code;
			
			this.dcs.open(modal, this.appendTo ? this.appendTo : document.body);
		  	modal.open(title, code).subscribe((o:any)=>{
				if(o.type == "confirm")
				{
					resolve(o.code);
					this.dcs.close(modal);
				} else if(o.type == "cancel")
				{
					reject(o.type);
					this.dcs.close(modal);
				}
			})
		})
        
	}
}
