import { Component, ElementRef } from "@angular/core";
import { PinYinMapUtils } from "./PinYinMapUtils";
import { PinYinUtils } from "./PinYinUtils";
import { ROComponent } from "./ROComponent";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";


///////
interface IPinYinObject
{
	shengmu:string;// "zh",
	yunmutone:string; // "uéng"
	yunmu:string;  // "ueng"
	toneNumber:number; // 2
	tonePosition:number; // 1
	pinyin:string;
	s:string;
	y:string;
	ti:number;
	selectedTone:string;
	isER:number;
}



export class PinYinObject implements IPinYinObject
{
	//shengmu, yunmutone, yunmu, tone, toneSign, toneNumber, tonePosition
	//public var tone:string; // "é"
	//public var toneSign:string; // "ˊ"
	
	public shengmu:string;// "zh",
	public yunmutone:string; // "uéng"
	public yunmu:string;  // "ueng"
	public toneNumber:number; // 2
	public tonePosition:number; // 1
	public pinyin:string;
	public s:string;
	public y:string;
	public ti:number;
	public selectedTone:string;
	
	public isER:number;
	
	
	
	public PinYinObject()
	{
		
	}
	
	public fromPinYin(pinyin:string):void
	{
		//this.pinyin = pinyin;
		var o:any = PinYinUtils.analyzePinYin(pinyin);
		this.shengmu = o.shengmu;
		this.yunmu = o.yunmu;
		this.toneNumber = o.toneNumber;
		this.update();
	}
	
	
	/*
	public setup(shengmu:string, yunmu:string, tone:number):void
	{
		this.shengmu = shengmu;
		this.yunmu = yunmu;
		this.tone = tone;
	}
	*/
	
	/*
	 * zhuéng
	 * 
	 * 
	public fromPinYin(pinyin:string):void
	{
		this.pinyin = pinyin;
		var o:Object = PinYinUtils.analyzePinYin(pinyin);
		for (var key:string in o)
		{
			this[key] = o[key];
		}
	}
	*/
	public setup(shengmu:string, yunmu:string, tone:number):void
	{
		this.shengmu = shengmu;
		this.yunmu = yunmu;
		this.toneNumber = tone;
		//this.updateAll();
		
		//this.s = o.v
		/*
		
		public var shengmu:string;// "zh",
		public var yunmutone:string; // "uéng"
		public var yunmu:string;  // "ueng"
		public var toneNumber:number; // 2
		public var tonePosition:number; // 1
		public var pinyin:string;
		public var s:string;
		public var y:string;
		public var ti:number;
		
		
		return {
			o:{
				s:shengmu,
				y:yunmu
			},
			v:{
				py:l1 + yunmuTone,
				yt:yunmuTone,
				s:l1,
				y:l2,
				ti:tIndex
			}
		}
		*/
	}
	public shouldBeSeparated():boolean
	{
		return !this.shengmu && /^[aoe]/.test(this.yunmu);
	}
	
	public compare(pinyinObject:PinYinObject):boolean
	{
		return true;
		/*
		var keyArray:Array = [
			"shengmu", 
			"yunmutone",
			"yunmu",
			"tone",
			"toneSign",
			"toneNumber",
			"tonePosition",
			"pinyin",
			"isER"
		];
		var len:number = keyArray.length;
		for (var i:number = 0; i < len; i++)
		{
			var key:string = keyArray[i];
			if (this[key] != pinyinObject[key])
			{
				return false;
			}
		}
		return true;
		*/
	}
	
	public toObject():any
	{
		var output:Object = {};
		
		
		var keyArray:string [] = [
			"shengmu",
			"yunmutone",
			"yunmu",
			"toneNumber",
			"tonePosition",
			"pinyin",
			"s",
			"y",
			//"ti",
			"isER",
			"selectedTone"
			/*
			"shengmu", 
			"yunmutone",
			"yunmu",
			"tone",
			"toneSign",
			"toneNumber",
			"tonePosition",
			"pinyin",
			"isER"
			*/
		];
		var len:number = keyArray.length;
		for (var i:number = 0; i < len; i++)
		{
			var key:string = keyArray[i];
			output[key] = this[key];
		}
		return output;
	}
	
	public clone():PinYinObject 
	{
		var output:PinYinObject = new PinYinObject();
		var keyArray:string [] = [
			"shengmu",
			"yunmutone",
			"yunmu",
			"toneNumber",
			"tonePosition",
			"pinyin",
			"s",
			"y",
			//"ti",
			"isER",
			"selectedTone"
			/*"shengmu", 
			"yunmutone",
			"yunmu",
			"tone",
			"toneSign",
			"toneNumber",
			"tonePosition",
			"pinyin",
			"isER"*/
		];
		var len:number = keyArray.length;
		for (var i:number = 0; i < len; i++)
		{
			var key:string = keyArray[i];
			output[key] = this[key];
		}
		return output;
	}
	
	public fromObject(object:Object):PinYinObject 
	{
		if(!object) return  null;
		for (var key in object)
		{
			try
			{
				this[key] = object[key];
			} catch (err)
			{
				
			}
		}
		// version fix for ti
		// if (ti) {}
		if (object && object.hasOwnProperty("ti")){
			//trace("need a fix herer");
			
			if (this.y)
			{
				try
				{
					this.selectedTone = this.y.charAt(this.ti);
				} catch (err)
				{
					
				}
			}
		}
		return this;
	}
	/*
	public generate():void
	{
		
	}
	*/
	/*
	public updateAll():void
	{
		//var array:Array = ErrorHandler.instance().errorToArray(new Error());
		//Cc.infoch("PY.updateAll", array.join(" < "));
		var o:Object = PinYinMapUtils.analysis(shengmu, yunmu, this.toneNumber);
		this.pinyin = o.v.py;
		this.yunmutone = o.v.yt;
		this.s = o.v.s;
		this.y = o.v.y;
		this.ti = o.v.ti;
		
	}
	
	public update2():void 
	{
		//var array:Array = ErrorHandler.instance().errorToArray(new Error());
		//Cc.errorch("PY.update", array.join(" < "));
		var o:Object = PinYinMapUtils.analysis(shengmu, yunmu, this.toneNumber, this.ti);
		this.pinyin = o.v.py;
		this.yunmutone = o.v.yt;
		this.s = o.v.s;
		this.y = o.v.y;
		this.ti = o.v.ti;
	}
	*/
	
	
	public update():void
	{
		
		var o:any = PinYinMapUtils.analysis(this.shengmu, this.yunmu, this.toneNumber);
		
		this.pinyin = o.v.py;
		this.yunmutone = o.v.yt;
		this.s = o.v.s;
		this.y = o.v.y;
	}
	
	public reset():void
	{
		this.shengmu = null;
		this.yunmu = null;
		this.toneNumber = 0;
		this.pinyin = null;
		this.yunmutone = null;
		this.s = null;
		this.y = null;
		this.ti = 0;
		this.selectedTone = null;
	}
}



export class PinYinWord
{
	text:string;
	pinyin:PinYinObject;
	styles:any[];
	options:any;
	constructor()
	{

	}
}

class PinYinBlock
{

}


