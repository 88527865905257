import { Component, HostBinding, Input, Output, EventEmitter, HostListener,OnInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularViewUtils } from 'src/app/common/AngularViewUtils';
import { CommonService } from 'src/app/service/common.service';
import { ThemeService } from 'src/app/service/theme.service';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'modal2',
  template: `
<div class="modal" 
  [ngClass]="[maxHeight || height?'custom-height':'']"
  [style.minWidth]="minWidth" [style.maxHeight]="maxHeight" [style.height]="height" [style.width]="width" [style.marginBottom]="offsetY+'px'">
  <div class="header" [ngStyle]="headerStyle">
    <div class="cancelBtnWrapper" [style.visibility]="(showCancelBtn ? 'inherit' : 'hidden')" (click)="onCancel($event);">
      <div class="cancelBtn">{{cancelLabel}}</div>
    </div>
    <div class="title">
		{{title}} <span *ngIf="warning" class="warning"> {{warning}} </span><div *ngIf="titleLabel" class="title-label-wrapper" [ngStyle]="titleLabelStyle">{{titleLabel}}</div>
		<ng-content select="[customTitle]"></ng-content>
	</div>
    <div style="display: flex;">
        <div class="confirmBtnWrapper" [class.disabled]="isSaveBtnDisabled()" [style.visibility]="(showSaveBtn ? 'inherit' : 'hidden')" (click)="onSave();">
            <div class="saveBtn">{{saveLabel}}</div>
        </div>
        <div class="confirmBtnWrapper" [style.visibility]="(showConfirmBtn ? 'inherit' : 'hidden')" (click)="onConfirm();">
        <div class="confirmBtn" [ngStyle]="{'opacity':confirmDisabled?'0.3':'1'}">{{confirmLabel}}</div>
        </div>
    </div>
  </div>
  <div #contentContainer class="content" [ngStyle]="contentStyle"
  	[ngClass]="[maxHeight || height?'custom-height':'']">
	<div class="topBar" *ngIf="topBar" [ngStyle]="topBar.style">
		<fa-icon *ngIf="topBar.faIcon" [icon]="topBar.faIcon"></fa-icon>
		<div class="topBar-text">
			<span>{{topBar.text}}</span>
		</div>
	</div>
    <ng-content></ng-content>
  </div>
</div>
  `,
  styleUrls: ['./modal2.component.scss']
})

export class Modal2Component implements OnInit{
	@HostListener('dblclick', []) onDblClick() { console.log(this); }
	@HostListener("click", ["$event"]) public onClick(event:PointerEvent):void { event.stopPropagation(); }
	@HostBinding('style.zIndex') public hostZIndex = 99;
	@HostBinding('style.display') public hostDisplay:string = 'none';

	@Input() public offsetY:number = 0;
	@Input() public width:string = "";
	@Input() public minWidth:string = "400px";
	@Input() public maxHeight:any = false;
	@Input() public height:any = false;
	@Input() public title:string = "";
	@Input() public warning:string = "";
	@Input() public topBar:{ bgColor: any, text: string, faIcon: IconDefinition, style: any };
    @Input() public headerStyle:any = {}
    @Input() public contentStyle:any = {};
	@Input() public titleLabelStyle:any = {};
	@Input() public titleLabel:string = "";


    @Input() public showConfirmBtn:boolean = true;
    @Input() public showCancelBtn:boolean = true;
    @Input() public showSaveBtn:boolean = false;
    @Input() public saveBtnDisabled:boolean|Function = false;

    @Input() public cancelLabel:string = "";
    @Input() public confirmLabel:string = "";
    @Input() public saveLabel:string = "";
	@Input() public confirmHandler:Function;
    @Input() public data:any = null;

	@Input() public confirmDisabled: boolean = false

    @Output() public confirmClick:EventEmitter<any> = new EventEmitter();
    @Output() public saveClick:EventEmitter<any> = new EventEmitter();
	@Output() public emitter:EventEmitter<any> = new EventEmitter();
    @Output() public cancelClick:EventEmitter<any> = new EventEmitter();
	
	@ViewChild('contentContainer', {static:true}) public contentContainer:any;
	public contentDOM:HTMLElement;
	private resolveFn:Function = ()=>{};
	public hasCustomTitle = false;
    constructor(private coms:CommonService, private eleRef:ElementRef, private themeService:ThemeService, public translate:TranslateService, public datas: DataService) {
      themeService.getThemeJson("modal2Module.json").then((styleObj:any)=>{
        themeService.applyStyleObj(styleObj, eleRef.nativeElement);
      });
	  this.contentDOM = document.createElement("div");

      //設定Lang
      if (translate.currentLang||translate.defaultLang) this.setLang(translate.currentLang||translate.defaultLang);
      translate.onLangChange.subscribe((data:any)=>{
        if (data.lang) this.setLang(data.lang);
      })
    }
	ngOnInit(): void {
		AngularViewUtils.appendChild(
			this.contentContainer,
			this.contentDOM
		);

	}

	onConfirm():void
	{	
		if (this.confirmDisabled == true) {
			return
		}
		if(this.confirmHandler != undefined && this.confirmHandler !== null)
		{
			this.confirmHandler().then((o)=>{
				this.confirmClick.emit(o);
				this.emitter.emit(o);
			})
		} else {
			this.confirmClick.emit(null);
			this.emitter.emit(null);
		}
		if (this.resolveFn)	this.resolveFn(this.data);
	}
    onSave():void {
        if (!this.isSaveBtnDisabled()) this.saveClick.emit();
        // this.emitter.emit("save");
    }
	onCancel($event = null):void
	{
		this.cancelClick.emit($event);
		this.emitter.error("cancel");
	}

    private setLang(lang:string="tc") {
        this.cancelLabel = this.cancelLabel || { tc:"取消", sc:"取消", en:"Cancel" }[lang];
        this.confirmLabel = this.confirmLabel || { tc:"確定", sc:"确定", en:"OK" }[lang];
        this.saveLabel = this.saveLabel || { tc:"儲存", sc:"储存", en:"Save" }[lang];
    }

	
	public getContentContainer():HTMLElement
	{
		return this.contentDOM;
	}

    public open():Promise<any> {
		return new Promise((resolve, reject)=>{
			this.resolveFn = resolve;
			if (sessionStorage.getItem('in-app') || !this.datas.isInWeb) {
				window.location.hash='#ro/showDialog';
			}
			this.hostZIndex = this.coms.getZIndex();
			this.hostDisplay = 'flex';
		});
    }

    public close() {
        if (sessionStorage.getItem('in-app') || !this.datas.isInWeb) {
			window.location.hash='#ro/hideDialog';
		}
        this.hostDisplay = 'none';
    }

    public isSaveBtnDisabled():boolean {
        try {
            if (typeof this.saveBtnDisabled === 'function') {
                return this.saveBtnDisabled();
            } else return this.saveBtnDisabled;
        } catch(e) {
            return false;
        }
    }


}
