import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LoadingService } from '../loadingModule/loading.service';

/*
SVG在Safari 13.1前有bug，可能顯示defs fill有問題，會變全黑
https://github.com/FortAwesome/angular-fontawesome/issues/86#issuecomment-604427364
*/
@Component({
    selector: 'mainMenuBtn',
    template: `
    <svg class="mainMenuBtn" [style.width.px]="width" [style.height.px]="height">
        <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" [style.stopColor]="color1" />
                <stop offset="50%" [style.stopColor]="color2" />
                <stop offset="100%" [style.stopColor]="color3" />
            </linearGradient>
            <linearGradient id="loadingGrad" x1="0" y1="1" x2="1" y2="1">
                <stop offset="0%" stop-color="rgba(255, 255, 255, 1)"></stop>
                <stop offset="40%" stop-color="rgba(255, 255, 255, 1)"></stop>
                <stop offset="100%" stop-color="rgba(255, 255, 255, 0)"></stop>
            </linearGradient>
        </defs>
        
        <path d="M0 0 L60 0 L60 30 Q60 50 40 50 L0 50 Z" fill="url(#grad1)"/>

        <ng-container *ngIf="!lds.isLoading">
            <rect *ngFor="let dot of dots" [attr.x]="dot.left" [attr.y]="dot.top" rx="2" ry="2" width="7" height="7" style="fill:#FFF;stroke-width:2;" />
        </ng-container>

        <ng-container *ngIf="lds.isLoading">
            <circle class="loader-path1" cx="30" cy="25" r="12" fill="none" stroke="#FFF" stroke-width="4" />
            <circle class="loader-path2" cx="30" cy="25" r="12" fill="none" stroke="url(#loadingGrad)" stroke-width="4" />
        </ng-container>
    </svg>
    `,
    styleUrls: ['./mainMenuBtn.component.scss']
})

export class MainMenuBtnComponent implements OnChanges {
    @Input() public width:number = 60;
    @Input() public height:number = 50;

    @Input() public color1:string = "#00C1C8";
    @Input() public color2:string = "#008DE2";
    @Input() public color3:string = "#005D98";

    public dots:any[] = [];

    constructor(public lds:LoadingService) {
        this.calculateDots();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes.width || changes.height)) {
            this.calculateDots();
        }
    }

    private calculateDots():void {
        let top:number = (this.height - 27) / 2;
        let left:number = (this.width - 27) / 2;
        let dots:any[] = [];
        for (let i=0; i<3; i++) {
            for (let j=0; j<3; j++) {
                dots.push({
                    top: top + (i * 10),
                    left: left + (j * 10)
                })
            }
        }
        this.dots = dots;
    }
    
}
