import { AfterViewInit, ApplicationRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef, isDevMode } from "@angular/core";
import { AnswerSource, ROBookConfig } from "./ROBookConfig";
import { StyleUtils } from "./StyleUtils";
import { ROContext } from "./ROContext";
import { ReplaySubject, Subject, Subscription, interval } from "rxjs";

import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import { ROPageComponentContainer } from "./ROPage";
import { ROPageComponent } from "./ROComponent";
import * as moment from 'moment';
import { StudentInfo } from "src/app/common/StudentInfo";

@Component({
	template:`
		<ng-container #container >
			<ng-container *ngIf="printInfo && context && bookConfig">
				<div *ngFor="let pageInfo of pageInfoArray " class="paper-container"
					[style.width.px]="printInfo.pageSize.width"
					[style.height.px]="printInfo.pageSize.height"
					>
					<div 
						class="paper"
						[style.transform]="'scale(' + pageInfo.scale+','+ pageInfo.scale+')'"
						>
						<div
							class="border-container"
							[style.width.px]="pageInfo.width" [style.height.px]="pageInfo.height" >
							<ro-page
								class="show-border"
								#pageRef
								[info]="pageInfo.info"
								[book]="bookConfig.book"
								[page]="pageInfo.page"
								[context]="context"
								[printing]="true"
								>
							</ro-page>
							<div 
								*ngIf="printOption.showContentBorder"
								class="border"
								></div>
						</div>
					</div>
					<div class="footer">
						<div class="timestamp" *ngIf="printOption.showTimestamp">{{"ro.print.print-timestamp-format"|translate: printInfo  }}</div>
						<div class="page_info" *ngIf="printOption.showTitle">{{pageInfo.title}}</div>
					</div>
					<!-- 

					<div 
						*ngIf="printOption.showContentBorder"
						class="border"
						></div>
						-->
				</div>
			</ng-container>
		</ng-container>
	`,
	styles:[
		`
		.border-container{
			position:absolute;
		}
		.paper-container{
			page-break-inside: avoid;
			position:relative;
			overflow:hidden;
		}
		.border{
			position:absolute;
			left:1px;
			right:1px;
			top:1px;
			bottom:1px;
			border:solid 1px #999999;
		}
		.paper{
			transform-origin: top left;
		}
		.footer{
			z-index:999999;
			position:absolute;
			left:5px;
			right:10px;
			bottom:1px;
			line-height:40px;
			height:40px;
			font-size:12px;
			white-space: nowrap;
		}
		.page_info{
			position:absolute;
			right:0px;
		}

		.timestamp{
			position:absolute;
			left:0mm;
			bottom:1px;
		}
	`
	]
	/**
	 	page-break-after  : always ;
		page-break-before : always ;
			
	 */
})


export class PrintBookView implements AfterViewInit, OnDestroy
{
	@Input() public bookConfig:ROBookConfig;
	@Input() public context:ROContext;
	@Input() public printOption:any;
	@Input() public pageInfo:any;
	@Input() public pageInfoArray:any [];
	@Input() public jobs:any [];
	// @ViewChildren('pageRef', { read: ROPageComponentContainer }) pagesRef: ROPageComponentContainer[];
	// @ViewChildren('pageRef', { read: false }) pagesRef: ROPageComponentContainer[] = [];
	@ViewChildren('pageRef', { read: false }) pagesRef: QueryList<ROPageComponentContainer>;

	@Output() public progress:number = 0;
	// 
	
	@ViewChild("container", {read: ViewContainerRef, static: false}) container: ViewContainerRef;
	// public pages:any[];
	public subject:Subject<any>;
	public componentRefArray:ComponentRef<any> [];
	public printInfo: any;
	public styleContainer:HTMLElement;
	constructor(private dcs:DynamicComponentService, private elementRef:ElementRef)
	{
		this.styleContainer = document.createElement("style");
		this.elementRef.nativeElement.appendChild(this.styleContainer);
		StyleUtils.applyStyleObj(
			this.elementRef.nativeElement, 
			{
				"page-title-visible" :  "block"
			}
		);
		this.subject = new ReplaySubject(1);
		this.subject.subscribe((o)=>{

		})
		this.componentRefArray = [];
	}
	toLandscapeMode()
	{
		this.styleContainer.innerText = `
	        @media print {
	          	@page {
	            	size: A4 landscape !important;
	          	}
				body{
					padding:0 !important;
					margin: 0mm !important;
				}
	      }
	    `.replace(/[\r\n]/g, " ");
	}
	toPortraitMode()
	{
		this.styleContainer.innerText = `
	        @media print {
				@page {
					size: A4 portrait !important;
				}
				body{
					padding:0 !important;
					margin: 0mm !important;
				}
	      }
		  `.replace(/[\r\n]/g, " ");
	}
	ngOnDestroy(): void {
		this.componentRefArray.forEach((compRef:ComponentRef<any>)=>{
			compRef.destroy();
		});
	}

	ngAfterViewInit(): void {
		
		setTimeout(()=>{
			this.buildAllPages();
		}, 100);
	}
	/*
	private loadData():Promise<any>
	{
		if(this.printOption.includeAnswer)
		{
			return Promise.resolve(null);
		} else {
			return Promise.resolve(null);
		}
	}
	this.printOption.includeAnswer
	this.bookConfig.dataSource
	*/
	private filterPages():any []
	{
		/*
		{
			"printType": "printer",
			"pages": "page",
			"includeAnswer": false,
			"button": false,
			"includeTitle": true,
			"resolution": 300
		   }*/
		var filter = {
			page:null,
			chapter:null
		};
		if(this.printOption.pages == "page")
		{
			var pageID:string = this.pageInfo.page.attributes.douid;
			filter.page = pageID;
		} else if(this.printOption.pages == "chapter")
		{
			filter.chapter = this.pageInfo.chapter.id;
		}
		var pages = this.bookConfig.pages.filter((page:any)=>{
			if(filter.chapter)
			{
				return page.chapter.id == filter.chapter;
			} else if(filter.page)
			{
				return page.pageNode.getAttribute("douid") == filter.page;
			}
			return true;
		});
		return pages;
	}
	private calculatePrintInfo2(printOption:any, pages:any[]):any
	{
		
		var maxW = 0;
		var maxH = 0;
		var landscapeCount = 0;
		var portraitCount = 0;
		pages.forEach((page)=>{
			var pageW = page.attributes.w;
			var pageH = page.attributes.h;
			console.log(page);
			if(pageW > maxW) maxW = pageW;
			if(pageH > maxH) maxH = pageH;
			if(pageW > pageH) {
				landscapeCount ++;
			} else {
				portraitCount ++;
			}
		});
		if(printOption.orientation)
		// var orientation = landscapeCount > portraitCount ? "landscape" : "portrait";
		// var orientation = maxW > maxH ? "landscape" : "portrait";
		var orientation;
		if(printOption.orientation == "auto")
		{
			orientation = maxW > maxH ? "landscape" : "portrait";
		} else {
			orientation = printOption.orientation;
		}
	 
		
		// 133 dpi 1099.91 x 1554.77
		// 8.27 x 11.69
		// 1.244152046783626
		// 1.206611570247934
		// var pageSize = orientation == "landscape" ? {width:1554  , height:1099 - 50} : {width:1099, height:1554 - 100};
		// 133 dpi 1099.91 x 1554.77
		// width:790px;			height:1119px;
		// width:1125px; height:867px;
		// var pageSize = orientation == "landscape" ? {width:1554 , height:1099 - 50 } : {width:1099, height:1554 - 50};
		// var pageSize = orientation == "landscape" ? {width:1125 , height:790 } : {width:790, height:1119};
		// var pageSize = orientation == "landscape" ? {width:1688 , height:1185 } : {width:1185, height:1678};
		var pageSize = orientation == "landscape" ? {width:1688 , height:1185 - 100 } : {width:1185, height:1678 - 100};
		
		var footerHeight = 40;
		pages.forEach((page)=>{
			var pageW = page.attributes.w;
			var pageH = page.attributes.h;
			var scaleX = pageSize.width / pageW;
			var scaleY = (pageSize.height - footerHeight) / pageH ;
			page.scale =  Math.min( scaleX, scaleY);
		});
		// var scaleX =  pageSize.width / maxW;
		// var scaleY =   pageSize.height / maxH ;
		// var scale = Math.min( scaleX, scaleY);
		return {
			orientation:orientation,
			// scale:scale,
			pageSize:pageSize
		}
	}
	/*
	private calculatePrintInfo(pages:any[]):any
	{
		
		var maxW = 0;
		var maxH = 0;
		pages.forEach((page)=>{
			var pageW = page.attributes.w;
			var pageH = page.attributes.h;
			console.log(page);
			if(pageW > maxW) maxW = pageW;
			if(pageH > maxH) maxH = pageH;
			
		});
		// var orientation = landscapeCount > portraitCount ? "landscape" : "portrait";
		var orientation = maxW > maxH ? "landscape" : "portrait";
		// 133 dpi 1099.91 x 1554.77
		// width:790px;			height:1119px;
		// width:1125px; height:867px;
		// var pageSize = orientation == "landscape" ? {width:1554 , height:1099 - 50 } : {width:1099, height:1554 - 50};
		var pageSize = orientation == "landscape" ? {width:1125 , height:867 } : {width:790, height:1119};
		var scaleX =  pageSize.width / maxW;
		var scaleY =   pageSize.height / maxH ;
		var scale = Math.min( scaleX, scaleY);
		return {
			orientation:orientation,
			scale:scale,
			pageSize:pageSize
		}
	}
	*/
	public stopped:boolean = false;
	private buildAllPages():Promise<any>
	{
		var pages:any[] = this.filterPages()
		
		this.printInfo = this.calculatePrintInfo2(this.printOption, pages);
		// new Date()
		// var m = moment;
		// this.printInfo.timestamp = "2023/8/16 5:30 pm **";
		this.printInfo.timestamp = moment(new Date()).format("YYYY/M/D h:mma");
		// 'YYYY/M/D h:mma'
		if(this.printInfo.orientation == "landscape")
		{
			this.toLandscapeMode();
		} else {
			this.toPortraitMode();
		}
		
		var _pageInfoArray = [];
		this.jobs.forEach((info)=>{
			if(info.showStudent)
			{
				info.studentName = StudentInfo.getDisplayName(info.student, "tc");
			}
			pages.forEach((page)=>{
				var chapter = page.chapter;
				var titleInfo = [
					this.bookConfig.book.title,
					this.printOption.showChapter ? chapter.title :"",
					info.showStudent ? info.studentName :"",
					page.pageNo ? "P."+page.pageNo : ""
				];
				
				titleInfo = titleInfo.filter((t)=>{ return t ? true : false;});
				_pageInfoArray.push({
					info:info,
					width:page.attributes.w, 
					height:page.attributes.h,
					scale:page.scale,
					title:titleInfo.join(" / "),
					page:page
				});
			});
		})
		this.pageInfoArray = _pageInfoArray;
		
		return new Promise((resolve, reject)=>{
			setTimeout(()=>{
				resolve(null)
			}, 1000);
		}).then(()=>{
			var views:ROPageComponentContainer[] = [];
			this.pagesRef.forEach((view:ROPageComponentContainer)=>{
				views.push(view);
			});
			var completed = 0;
			var total = views.length;
			return PromiseUtils.reduce(
				views, 
				(prev:any, view:ROPageComponentContainer, index:number, reference:any [])=>{
					var component:ROPageComponent = view.build();
					return component.isContentReady().then(()=>{
						// page content ready
						if(this.stopped)
						{
							this.subject.next({type:"stopped"});
							return Promise.reject({type:"stopped"});
						} else {
							return Promise.resolve(null);
						}
					}).then((o:any)=>{
						// page data ready
						if(view.info.source)
						{
							// view.setPageDataForVerify(this.bookConfig.dataSource, false);
							view.setPageDataForVerify(view.info.source, false);
							return view.pageCom.isDataContentReady();
						} else {
							return Promise.resolve(null);
						}
					}).then((o:any)=>{
						completed++;
						this.progress = Math.round(completed / total * 100);
						this.subject.next({type:"message", "message":`${completed}/${total}`});
					})
				}, 
				null
			).then(()=>{
				return views;
			})
			/*
		}).then((views:any [])=>{
			return PromiseUtils.reduce(
				views, 
				(prev:any, view:ROPageComponentContainer, index:number, reference:any [])=>{
					return Promise.resolve(1).then(()=>{
						if(view.info.source)
						{
							// view.setPageDataForVerify(this.bookConfig.dataSource, false);
							view.setPageDataForVerify(view.info.source, false);
							return view.pageCom.isDataContentReady();
						} else {
							return Promise.resolve(null);
						}
					}).then(()=>{
						if(this.stopped)
						{
							this.subject.next({type:"stopped"});
							return Promise.reject({type:"stopped"});
						} else {
							return Promise.resolve(null);
						}
					});
				}, 
				null
			);
			*/
		}).then((o:any)=>{
			return PromiseUtils.delay(1000, 0);
		}).then(()=>{
			this.subject.next({type:"ready"});
			return null;
		})
		
	}
	/*
	private buildPage(page:any, info:any):Promise<any>
	{
		debugger;
		var componentRef:ComponentRef<any>  = this.dcs.createComponentRef(
			ROPageComponentContainer, 
			{
				book:this.bookConfig.book,
				page:page.page,
				context:this.context
			}
		);
		page.ref = componentRef;
		this.componentRefArray.push(componentRef);
		var component:ROPageComponent = componentRef.instance.build();
		this.container.insert(componentRef.hostView);
		return component.isContentReady()
	}
	*/
}

