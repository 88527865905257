import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { BobbleBoxComponent } from 'src/app/sharedModule/bobbleBoxModule/bobbleBox.component';
import { ICellEditorParams } from 'ag-grid-community';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { RoService } from 'src/app/service/ro.service';
import { ModalComponent } from 'src/app/sharedModule/modalModule/modal.component';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/service/alert.service';
import { SettingService } from 'src/app/service/setting.service';

@Component({
    selector:"userIconEditor",
    template: `
    <input #browseInput type="file" accept=".jpg,.jpeg,.png,.bmp,.tiff" style="display: none;" (change)="inputFileChanged($event)">
    <input #cameraInput type="file" accept="image/*;capture=camera" style="display: none;" capture (change)="inputFileChanged($event)">

<bobbleBox #bbb [backgroundColor]="settings.homeLayout && settings.homeLayout.homePageThemeColor || '#5D387A'" [rejectOnClose]="true" padding="0" [marginTop]="10" [marginBottom]="10" [marginLeft]="15" [marginRight]="15">
    <div class="container">
        <div class="left">
            <img [src]="getCurUserIcon()">
            <div class="btnContainer">
                <iconBtn width='40px' height="40px" icon="url(./assets/img/icon_upload_white.svg)" (click)="getPhoto('file')"></iconBtn>
                <iconBtn width='40px' height="40px" icon="url(./assets/img/icon_camera_white.svg)" (click)="getPhoto('camera')"></iconBtn>
            </div>
        </div>
        <div class="right">
            <div *ngIf="userIcons.length<1" class="noPhoto">{{getTranslate('noPhoto')}}</div>
			<div class="iconList">
				<div *ngFor="let icon of userIcons" class="iconContainer">
					<div class="iconWrapper" [class.selected]="userIcon && userIcon.id==icon.id" (click)="userIcon=icon">
						<img [src]="icon.url">
						<iconBtn class="delBtn" width='20px' height="20px" icon="url(./assets/img/btn_close_white.svg)" (click)="$event.stopPropagation(); delIconApi(icon)"></iconBtn>
					</div>
					<div class="dateTime">{{icon.dateTimeString}}</div>
				</div>
			</div>
            <div class="btnContainer">
                <div
					class="cfmBtn"
					[class.disabled]="!userIcon || userIcon.active==1"
					[style.color]="settings.homeLayout && settings.homeLayout.homePageThemeColor || '#8449B4'"
					(click)="updateUserIcon()"
				>{{getTranslate('confirm')}}</div>
                <div class="cnlBtn" (click)="cancelBtnClicked()">{{getTranslate('cancel')}}</div>
            </div>
        </div>
    </div>
</bobbleBox>


<modal
    #editIconModal
    title="{{getTranslate('editIcon')}}"
    width="540px"
    [lang]="datas.lang"
    style="position: fixed;"
    backgroundColor="#E9E6F1"
    cfmBtnBgColor="#5D387A"
    (cancelClick)="closeEditIconModal();"
    (confirmClick)="editIconModalCfmClicked(angularCropper)"
>
    <angular-cropper
        #angularCropper
        class="forIconEditor"
        [cropperOptions]="{ aspectRatio:1/1, minCropBoxWidth:50, minCropBoxHeight:50, viewMode: 3 }"
        [imageUrl]="cropperImageUrl"
    ></angular-cropper>
</modal>
    `,
    styleUrls: ['./userIconEditor.component.scss']
})


export class UserIconEditorComponent {
    @HostListener('dblclick', []) onDblClick() { this.coms.log(this); }
    @ViewChild('bbb', {static:true}) private bbb:BobbleBoxComponent;
    @ViewChild('browseInput', {static:true}) private browseInput:ElementRef<HTMLInputElement>;
    @ViewChild('cameraInput', {static:true}) private cameraInput:ElementRef<HTMLInputElement>;
    @ViewChild('editIconModal', {static:false}) public editIconModal:ModalComponent;

	@Output() public onEditorClose:EventEmitter<void> = new EventEmitter();

	public userId:number = 0;
    public userIcons:any[] = [];
    public userIcon:any;

    public cropperImageUrl:string = '';

    constructor(public datas:DataService, private coms:CommonService, private translate:TranslateService, private lds:LoadingService, public settings:SettingService, private als:AlertService) {
		//settings.homeLayout.homePageThemeColor
    }
    //---------------------------------------------------------------------------------------------
    private getUserIconsApi():Promise<any> {
		let uid:number = this.userId;
        if (!uid) Promise.reject('cannotReadUid');
        this.userIcons = [];
        return this.datas.post2({ data: { api: 'ROUserAndGroup.getUserIcons', json:[uid] } }).then((res:any)=>{
            if(res && res.userIcons) {
                this.userIcons = res.userIcons;
                this.userIcons.map((icon:any)=>{
                    try{
                        let date:Date = new Date(icon.time*1000);
                        icon.dateTimeString = date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDate() + '\n' + date.getHours() + ':' + date.getMinutes();
                    } catch(err) {
                        icon.dateTimeString = "";
                    }
                });
				this.userIcon = this.userIcons.find((icon=>icon.active==1));
            }
            return Promise.resolve();
        });
    }
    //---------------------------------------------------------------------------------------------
    public getCurUserIcon():string {
        if (this.userIcon && this.userIcon.url) {
            //已有指定active icon
            return this.userIcon.url;
        } else if (this.datas && this.datas.user_role && this.datas.user_role == 2) {
            //是學生
            if (this.datas && this.datas.userInfo  && this.datas.userInfo.gender && this.datas.userInfo.gender==2) {
                //是女
                return 'assets/img/avatar_student_female.svg';
            } else {
                //是男
                return 'assets/img/avatar_student_male.svg';
            }
        } else {
            //是老師 或 家長
            if (this.datas && this.datas.userInfo && this.datas.userInfo.gender && this.datas.userInfo.gender==2) {
                //是女
                return 'assets/img/avatar_teacher_female.svg';
            } else {
                //是男
                return 'assets/img/avatar_teacher_male.svg';
            }
        }
    }
    //---------------------------------------------------------------------------------------------
    public getPhoto(method:('file'|'camera')='file'):void {
		((method=='file') ? this.browseInput : this.cameraInput).nativeElement.click();
    }
    //---------------------------------------------------------------------------------------------
    public inputFileChanged(event:Event):void {
        if (event && event.target) {
            let fileInput:any = event.target;
            if (fileInput && fileInput.files && fileInput.files[0]) {
                this.handleImageFile(fileInput.files[0]);
            }
        }
    }
    //---------------------------------------------------------------------------------------------
    private handleImageFile(file:File|Blob):void {
        let cls:Function = this.lds.add();
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ()=>{
            let imgUrl:string = reader.result as string;
            this.resizeImage(imgUrl, 1024).then((resizedImgUrl:string)=>{
                this.cropperImageUrl = resizedImgUrl;
                this.editIconModal.open();
                cls();
            });
        };
    }
    //---------------------------------------------------------------------------------------------
    public editIconModalCfmClicked(angularCropper:any):void {
        let dataUrl:string = angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
        this.resizeImage(dataUrl, 200).then((resizedImgUrl:string)=>{
            //console.log(resizedImgUrl);
            this.uploadToAsset(resizedImgUrl);
            this.closeEditIconModal();
        });
    }
    //---------------------------------------------------------------------------------------------
    public closeEditIconModal():void {
        this.browseInput.nativeElement.value='';
        this.cameraInput.nativeElement.value='';
        this.editIconModal.close();
    }
    //---------------------------------------------------------------------------------------------
    public resizeImage(dataUrl:string, maxPx:number=1024):Promise<string> {
        return new Promise((resolve, reject)=>{
            // We create an image to receive the Data URI
            let img:HTMLImageElement = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = ()=>{
                if (img.width>maxPx || img.height>maxPx) {
                    let targetWidth:number = 0;
                    let targetHeight:number = 0;
                    if (img.width>img.height) {
                        targetWidth = maxPx;
                        targetHeight = img.height * (maxPx / img.width);
                    } else {
                        targetHeight = maxPx;
                        targetWidth = img.width * (maxPx / img.height);
                    }

                    // We create a canvas and get its context.
                    let canvas:HTMLCanvasElement = document.createElement('canvas');
                    let ctx = canvas.getContext('2d');

                    // We set the dimensions at the wanted size.
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;

                    // We resize the image with the canvas method drawImage();
                    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

                    resolve(canvas.toDataURL());

                } else {
                    resolve(dataUrl);
                }
                
            };

            // We put the Data URI in the image's src attribute
            img.src = dataUrl;

        });
    }
    //---------------------------------------------------------------------------------------------
    private dataURItoBlob(dataURI:string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
      
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
      
        // create a view into the buffer
        var ia = new Uint8Array(ab);
      
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
      
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        
        return blob; 
    }
    //---------------------------------------------------------------------------------------------
    private uploadToAsset(imgUrl:string):void {
        
        let cls:Function = this.lds.add();
        
        let url:string = `//${environment.serverHost}/RainbowOne/index.php/transport/Slave/upload/4`;
        let blob:Blob = this.dataURItoBlob(imgUrl);
        let fileName:string = (+new Date()).toString();

        let formData:FormData = new FormData();
        formData.append('Filedata', blob, fileName);
        formData.append('Filename', fileName);

        this.datas.post2({ url: url, data: formData, jwt: false }).then((asset:any)=>{
            this.assetToProfilePic(asset, this.userId);
        }).finally(()=>{
            cls();
        });

    }
    //---------------------------------------------------------------------------------------------
    private assetToProfilePic(asset:any, uid:number):void {
        let cls:Function = this.lds.add();
        
        this.datas.post2({ data: { api: 'ROProfilePic.upload_pic', json:[asset, uid] } }).then((res:any)=>{
            /*{
                "code": 0,
                "pics": {
                    "id": "11837",
                    "uid": "40402",
                    "s": "",
                    "m": "OKG://id/23119433/checksum/12D040D3/len/97208/token/8B0A0426/type/1/server/1/title/",
                    "l": "",
                    "time": "1631258731",
                    "active": "0"
                }
            }*/
            //重新載入所有Icon，並將剛upload的預選
            this.getUserIconsApi().then(()=>{
                if (res.pics && res.pics.id) this.userIcon = this.userIcons.find((icon=>icon.id==res.pics.id));
            });
            cls();
        });
    }
    //---------------------------------------------------------------------------------------------
    public delIconApi(userIcon:any):void {
        this.als.alert(this.getTranslate('cfmToRmIcon'),null, {showCnlBtn:true}).then(()=>{
            let cls:Function = this.lds.add();
            
            this.datas.post2({ data: { api: 'ROProfilePic.remove', json:[this.userId, userIcon.id] } }).then((res:any)=>{
                if (res.code==0) {
                    //重新載入所有Icon
                    this.getUserIconsApi();
                }
            }).finally(()=>{
                cls();
            });
        });
        
    }
    //---------------------------------------------------------------------------------------------
	public cancelBtnClicked():void {
		this.onEditorClose.emit();
		this.bbb.close();
	}
    //---------------------------------------------------------------------------------------------
	public open(targetEle:HTMLElement):void {
		this.userId = this.datas.userInfo.uid;
		this.getUserIconsApi();
		this.bbb.open(targetEle).then(()=>{}, ()=>{});
	}
    //---------------------------------------------------------------------------------------------
	public getTranslate(key:string):string {
		let currentLang:string = this.translate.currentLang || 'tc';
		let langSet:any = {
			tc: {
				noPhoto: "未有相片",
				confirm: "確定",
				cancel:'取消',
				editIcon: "編輯頭像",
				cfmToRmIcon: "確定要刪除頭像?"
			},
			sc: {
				noPhoto: "未有相片",
				confirm: "确定",
				cancel:'取消',
				editIcon: "编辑头像",
				cfmToRmIcon: "确定要删除头像?"
			},
			en: {
				noPhoto: "No Photo",
				confirm: "OK",
				cancel:'Cancel',
				editIcon: "Edit Icon",
				cfmToRmIcon: "Confirm to remove the Icon ?"
			}
		}[currentLang];
		if (!langSet) return "";
		return langSet[key] || "";
	}
    //---------------------------------------------------------------------------------------------
	public updateUserIcon():Promise<void> {
        return this.datas.post2({
			data: { api:'ROUserAndGroup.updateValue', json:[[this.userId], 'icon', this.userIcon, this.datas.userInfo.school_id] }, loading:true
		}).then((res)=>{
			this.coms.userMenuComponent.updatePersonalIcon();
			this.onEditorClose.emit();
			this.bbb.close();
		});
    }
    //---------------------------------------------------------------------------------------------
}
