import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from 'src/app/sharedModule/loadingModule/loading.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectRenderer } from './ngSelect.renderer';
import { PhotoRenderer } from './photo.renderer';
//import { BobbleBoxModule } from 'src/app/sharedModule/bobbleBoxModule/bobbleBox.module';
import { CheckBoxRenderer } from './checkbox.renderer';
import { CheckBoxHeader } from './checkbox.header';
import { CourseNameRenderer } from './course-name.renderer';
//import { SubjectSelectViewModule } from 'src/app/sharedModule/SubjectSelectViewModule/SubjectSelectView.module';
import { IconBtnRenderer } from './iconBtn.renderer';
import { SubjectModule } from '../subjectModule/subject.module';
import { StatusRenderer } from './status.renderer';
import { StatusSelectorRenderer } from './statusSelector.renderer';
import { WhitePopupModule } from '../whitePopupModule/whitePopup.module';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { OkaPulldown2Renderer } from './okaPulldown2.renderer';
import { okaPulldownRenderer } from './okaPulldown.renderer';
import { NoRowsOverlay } from './noRows.overlay';
import { OkaPulldown2Editor } from './okaPulldown2.editor';
import { ColorBoxRenderer } from './colorBox.renderer';
import { CombinedRenderer } from './combined.renderer';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { OkaPulldown2Header } from './okaPulldown2.header';
import { TextRenderer } from './text.renderer';
import { SubjectSelector3Module } from '../subjectSelector3Module/subjectSelector3.module';
import { NoCellRenderer } from './noCell.rendrer';
import { DateTimePicker3Module } from 'src/app/sharedModule/dateTimePicker3Module/dateTimePicker3.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PopupSelectRenderer } from './popupSelector.renderer';
import { TickRenderer } from './tick.renderer';


@NgModule({
    imports: [
    	CommonModule, TranslateModule, FormsModule,
		NgSelectModule, LoadingModule,
		FontAwesomeModule,WhitePopupModule,
		// for SubjectSelectRenderer use
		//BobbleBoxModule, SubjectSelectViewModule,
		SubjectModule, OkaPulldownModule, AngularSvgIconModule,
		SubjectSelector3Module, DateTimePicker3Module, UiSwitchModule
    ],
    declarations: [
		NgSelectRenderer,PhotoRenderer, TextRenderer, CheckBoxRenderer,CheckBoxHeader,
		CourseNameRenderer, IconBtnRenderer, StatusRenderer,StatusSelectorRenderer,
		OkaPulldown2Renderer,OkaPulldown2Editor,okaPulldownRenderer,
		ColorBoxRenderer, CombinedRenderer, OkaPulldown2Header,
		NoRowsOverlay, NoCellRenderer, PopupSelectRenderer, TickRenderer
    ],
    exports: [
		NgSelectRenderer,PhotoRenderer, TextRenderer, CheckBoxRenderer,CheckBoxHeader,
		CourseNameRenderer, IconBtnRenderer, StatusRenderer,StatusSelectorRenderer,
		OkaPulldown2Renderer,OkaPulldown2Editor,okaPulldownRenderer, 
		ColorBoxRenderer, CombinedRenderer, OkaPulldown2Header,
		NoRowsOverlay, NoCellRenderer, PopupSelectRenderer, TickRenderer
    ],
    providers: [
		
    ],
    entryComponents: [ 
		NgSelectRenderer,PhotoRenderer, TextRenderer, CheckBoxRenderer,CheckBoxHeader,
		CourseNameRenderer, IconBtnRenderer, StatusRenderer, StatusSelectorRenderer,
		OkaPulldown2Renderer,OkaPulldown2Editor,okaPulldownRenderer, 
		ColorBoxRenderer, CombinedRenderer, OkaPulldown2Header,
		NoRowsOverlay, NoCellRenderer, PopupSelectRenderer, TickRenderer
	]
})
export class CustomAgGridModule {
}
