import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[vkb]',
})

export class VkbDirective implements OnInit {
  private el: HTMLElement;
  @Input() vkb: any;
  constructor(public elRef: ElementRef) {

  }

  ngOnInit() {

  }

  @HostListener('focus') focus() {
    setTimeout(() => { 
      let body = document.querySelector('body');
      if (body.scrollTop > 0) {
        body.classList.add('vkb');
        body.scrollTop = 0;
      }
    }, 300);
  }

  @HostListener('blur') blur() {
    let body = document.querySelector('body');
    body.classList.remove('vkb');

    window.scrollTo(0,0);
    document.body.scrollTop = 0;
  }
}
