import { Component, Input, HostBinding, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MainMenuComponent } from './../mainMenuModule/mainMenu.component';

@Component({
    selector: 'navBar',
    template: `
        <div *ngIf="shadowTop" class="shadowTop"></div>

        <div class="left block">
			<ng-container *ngIf="options.mainMenuBtn">
				<mainMenuBtn 
					[color1]="options.mainMenuBtn.color1" [color2]="options.mainMenuBtn.color2" 
					[color3]="options.mainMenuBtn.color3" (click)="mainMenu.open()"></mainMenuBtn>
				<mainMenu #mainMenu 
					[color1]="options.mainMenuBtn.color1" [color2]="options.mainMenuBtn.color2" 
					[color3]="options.mainMenuBtn.color3"></mainMenu>
			</ng-container>
			<sectionTitle *ngIf="options.sectionTitle">{{options.sectionTitle | translate}}</sectionTitle>
			<popup-toggle #popupToggleRef *ngIf="options.toggle" 
				[(toggle)]="options.toggle.toggle" [items]="options.toggle.toggles" [options]="toggleOptions" 
				[key]="(options.toggle.key?options.toggle.key:'')"
				bindLabel="label" bindValue="value" (toggleChange)="passToggleChange($event)"></popup-toggle>
            <ng-content select="[left]"></ng-content>
        </div>

        <div class="center block">
            <ng-content select="[center]"></ng-content>
        </div>

        <div class="right block">
			<topRightToolBar right #topRightToolBarRef *ngIf="options.iconBtnsConfig" [iconBtns]="options.iconBtnsConfig"></topRightToolBar>
            <ng-content select="[right]"></ng-content>
        </div>
        
    `,
    styleUrls: ['./navBar.component.scss'],
    //host: { class: 'shadowBottom' }
})

export class NavBarComponent implements OnInit, AfterViewInit{
    @Input() @HostBinding('style.background') backgroundColor = '';
    @Input() @HostBinding('style.height') height = "";
    @Input() public shadowTop:boolean = false;
	@Input() options:any ={};
	@Output() toggleChange: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('mainMenu', {static: false}) mainMenu: MainMenuComponent;
	@ViewChild('popupToggleRef', {static: false}) popupToggleRef;
	@ViewChild('topRightToolBarRef', {static: false}) topRightToolBarRef;
    public toggleOptions = {separate: '/', separateStyles: {width: '10px', textAlign: 'center', color: 'var(--headermenu-dividercolor, #999)'}, itemStyles: {minWidth: 'auto'}};

    constructor() {
    }

	ngOnInit(): void {
		
	}

	ngAfterViewInit(): void {
		if (this.options){
			this.options.refs = {};
		}
		if (this.options.toggle){
			this.options.refs.popupToggle = this.popupToggleRef;
		}
		if (this.options.iconBtnsConfig){
			this.options.refs.topRightToolBar = this.topRightToolBarRef;
			if (this.options.iconBtnsConfig.find(e=> e.type == 'search')){
				this.options.refs.search_button = this.topRightToolBarRef.search_button;
			}
		}
	}

	passToggleChange($event){
		if (this.options.toggle && this.options.toggle.toggleChange){
			this.options.toggle.toggleChange($event);
		}
	}

}
