import { Injectable } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { Subject } from 'rxjs';
import { tap, take, catchError } from 'rxjs/operators';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';

@Injectable()
export class InlineSvgService {
	public svgIconsNames:any = [];
	constructor(public iconReg: SvgIconRegistryService, private datas: DataService, private script: ScriptService) {
	}

	regSvgFile(imgPath, name = null){
		return new Promise((resolve, reject) =>{
			let index = this.svgIconsNames.indexOf(name);
			if (index == -1){
				this.iconReg.loadSvg(this.script.imgUrl + imgPath).pipe(take(1), 
					catchError(err=>{
						reject({msg: name + ' not found'});
						return err;
					}),
					tap((svgEle:any)=>{
						let html = svgEle.outerHTML;
						let array = html.match(/(id="(.*?)(\"))/g).map(e=> e.substr(4, e.length - 5));
						array = array.filter((e,i)=> array.indexOf(e) == i);
						array.forEach((key, index) =>{
							let newKey = 'id_' + name + '_' + index + '_' + +new Date();
							while (html.indexOf(key) > -1){
								html = html.replace(key, newKey);
							}
						});
						let svgName = name?name: imgPath.split('.')[0];
						this.addSvg(svgName, html);
						resolve({name: name});
				})).subscribe();
			} else {
				resolve({name: name});
			}
			setTimeout(()=>{ reject('register svg file [' + name + '] timeout.')}, 20000);
		});
	}

	regSvgFiles(icons){
		let promises = [];
		for (let i = 0; i < icons.length; i++){
			promises.push(this.regSvgFile(icons[i].url, icons[i].name ));
		}
		return Promise.all(promises);
	}

	addSvg(name, data){
		let index = this.svgIconsNames.indexOf(name);
		if (index == -1){
			this.iconReg.addSvg(name, data);
			this.svgIconsNames.push(name);
		}
	}

}
