import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { ScriptService } from 'src/app/service/script.service';
import { LoadingService } from '../loadingModule/loading.service';
import * as moment from 'moment';
import { faVideo } from '@fortawesome/pro-solid-svg-icons';
import { SettingService } from 'src/app/service/setting.service';

@Component({
	selector: 'lesson-submission-modal',
	template: `
<div class="wrapper">
	<div *ngIf="data.lessonType != 'physical'" class="photo" [style.backgroundImage]="data.creatorPhoto">
		<div *ngIf="videoType" [ngClass]="'video-icon ' + videoType">
			<fa-icon [icon]="faVideo"></fa-icon>
		</div>
	</div>
	<div *ngIf="data.lessonType == 'physical'" class="photo lesson-type-physical"></div>
	<div class="name">{{data.name}}</div>
	<div class="details">{{details}}</div>
	<ng-container *ngIf="rows.length > 0">
		<div class="message">
			<span>{{'open-book.pls-attend-at-time-location'|translate}}</span>
		</div>
		<div class="box">
			<div [ngClass]="['box-row row-' + row.field, (last?'last':'')]" *ngFor="let row of rows;let last = last">
				<div class="box-row-label">{{row.label? row.label + ':':''}}</div>
				<div class="box-row-value">{{row.value}}</div>
			</div>
		</div>
	</ng-container>
	<div class="panel {{rows.length > 0?'':'no-location-time'}}" *ngIf="data.lessonType == 'interactive' || data.lessonType == 'video'">
		<div class="panel-join" (click)="confirmClick()">{{'open-book.join-lesson'|translate}}</div>
		<div class="panel-cancel" (click)="cancelClick()">{{'open-book.cancel'|translate}}</div>
	</div>
	<div class="action confirm" *ngIf="data.lessonType == 'physical'" (click)="confirmClick()">{{'workspace.confirm'|translate}}</div>
	
</div>
	
	`,
	styleUrls: ['./lessonSubmissionModal.modal.scss'],
})

export class LessonSubmissionModal implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@Output() installApp: EventEmitter<any> = new EventEmitter<any>();
	public photo;
	public details;
	public rows: any[] = [];
	private joinTime;
	public videoType;
	private sessionToken;
	faVideo = faVideo;
	constructor(private san: DomSanitizer, private trans: TranslateService, public datas: DataService, private http: HttpClient, private als: AlertService, private eleRef: ElementRef, private theme: ThemeService, private common: CommonService, private lds: LoadingService, public setting: SettingService) {
	}

	ngOnInit() {
		let data = this.data;
		if (data.lessonType == 'interactive') {
			this.details = { tc: '電子課', sc: '电子課', en: 'Interactive' }[this.datas.lang];
			this.details = this.details + (data.creatorName?', ' + data.creatorName:'');
			this.details = this.details + (data.tag_title?', ' + data.tag_title:'');
		} else {
			if (data.lessonType == 'video'){
				this.initVideoType(data.website);
			}
			this.details = this.trans.instant('course.lesson-type-' + data.lessonType);			
		}
		if (data.start_time || data.end_time) {
			let startTime = data.start_time ? moment(data.start_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD hh:mm A') : null;
			startTime = startTime ? startTime : this.trans.instant('common.none');
			this.rows.push({ label: this.trans.instant('open-book.time'), value: startTime + ' -', field: 'start_time' });
			let endTime = data.end_time ? moment(data.end_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD hh:mm A') : null;
			endTime = endTime ? endTime : this.trans.instant('common.none');
			this.rows.push({ label: '', value: endTime, field: 'end_time' });
		}
		if (data.locationText) {
			this.rows.push({ label: this.trans.instant('open-book.location'), value: data.locationText, field: 'location' });
		}
		this.initTheme(this.eleRef.nativeElement);
	}

	ngAfterViewInit() {
	}

	ngOnDestroy(): void {
	}

	startEndBookSession() {
		const summary = { score: 0, green_count: 1, red_count: 0, orange_count: 0, blue_count: 0, total_count: 1, pass: 1 };
		return this.datas.post('ROCourse.end_book_session', [summary]);
	}

	confirmClick() {
		if (this.data.inMapPreview) { //close lesson
			this.close.emit();
		} else { //open lesson
			this.datas.post('ROCourse.start_book_session', [this.data.bsid, this.data.book_id]).subscribe((res: any) => {
				this.sessionToken = res.token;
				this.openLesson(this.data);
			});
		}
	}

	cancelClick() {
		this.close.emit();
	}

	openLesson(data) {
		if (data.lesson_id) { //interactive
			if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
				let protocol = this.setting.getOpenAppProtocol(this.datas.appId2);
				let href = protocol + 'api.openknowledge.hk/RO/external/?action=join_lesson&id=' + data.lesson_id + '&identify=' + this.datas.jwt;
				location.href = href;
				setTimeout(() => {
					if (document.hasFocus()) {
						this.installApp.emit(href);
					}
				}, 1000);
			} else {
				this.datas.post('ROLesson.getLesson', [data.lesson_id]).subscribe((res: any) => {
					let title = encodeURI(data.name);
					let session = res.lesson.session;
					let hash = 'ro/lesson-start/' + session + '/' + data.lesson_id + '/' + data.tagId + '/' + data.name;
					location.hash = hash;
				});
			}
		} else if (data.website) { //video
			let url = data.website.indexOf('//') > -1 ? data.website : '//' + data.website;
			setTimeout(() => { window.open(url, '_blank'); });
			// this.closeAll();
		}
		this.endBookSesson();
	}

	endBookSesson() {
		if (this.sessionToken == null) {
			return;
		}
		let bookId = this.data.book_id;
		let bsid = this.data.bsid;
		let pageId = '';
		let compId = '';
		let result = { correction: true, score: 0, correct: 2 };
		let addRecord = { type: 'add', tag: this.data.type, doc: bookId, page: pageId, com: compId, answer: '{}', result: result };
		let submitRecord = { lesson_id: 0, doc: bookId, "page": pageId, mode: "none", ref_id: 0, type: "submit" };
		const closedSessionToken = this.sessionToken;
		this.datas.post('ROBookShare.save', [bsid, [addRecord, submitRecord], bookId]).subscribe((res: any) => {
			const summary = { score: 0, green_count: 1, red_count: 0, orange_count: 0, blue_count: 0, total_count: 1, pass: 1 };
			setTimeout(() => {
				this.datas.post('ROCourse.end_book_session', [closedSessionToken, summary]).subscribe((res: any) => {

				});
			}, 10);
		});
		this.sessionToken = null;
		this.close.emit(this.data);
	}

	initTheme(ele) {
		this.theme.applyStyleObj({
			default: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#333",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-selectedtextcolor": "var(--section-maincolor)",
				"pulldown-overbgcolor": "#e2e2e2",
				"popupform-panelbgcolor": "#f5f5f5",
				"color-ccc-38374f": "#ccc",
				"popupform-rowbgcolor": "#fff",
				"popupframe-headerbgcolor": "#fff",
				"popupframe-titlecolor": "#333",
				"popupform-subtitlecolor": "#333",
				"popupform-textcolor": "var(--section-maincolor)",
				"color-f9f9f9-1e1e29": "#f9f9f9",
				"color-333-fff": "#333",
				"alert-bgcolor": "#fff",
				"color-f1f1f7-262635": "#f1f1f7",
				"color-f7f7f7-222237": "#f7f7f7",
				"color-ececec-262635": "#ececec",
				"pulldown-selectedbgcolor": "var(--section-maincolor)"
			},
			dark: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-selectedtextcolor": "#fff",
				"pulldown-overbgcolor": "#1e1e29",
				"popupform-panelbgcolor": "#38374f",
				"color-ccc-38374f": "#38374f",
				"popupform-rowbgcolor": "#1e1e29",
				"popupframe-headerbgcolor": "#1e1e29",
				"popupframe-titlecolor": "#fff",
				"popupform-subtitlecolor": "#a0a0a0",
				"popupform-textcolor": "#fff",
				"color-f9f9f9-1e1e29": "#1e1e29",
				"color-333-fff": "#fff",
				"alert-bgcolor": "#2d2c43",
				"color-f1f1f7-262635": "#262635",
				"color-f7f7f7-222237": "#222237",
				"color-ececec-262635": "#262635",
				"pulldown-selectedbgcolor": "#fff"
			}
		}, ele);

	}

	initVideoType(website){
		this.videoType = 'teams';
		if (website && website.indexOf('zoom.') > -1){
			this.videoType = 'zoom';
		} else if (website && website.indexOf('meet.google.com') > -1){
			this.videoType = 'meet';
		}
	}
}
