import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'progress-modal',
	template: `
  <div class="progress-modal__wrapper">
    <div class="progress-modal__title">
      <span>{{ displayLabel | translate }}</span>
      <span class="progress-modal__dot">{{dot}}</span>
    </div>
    <div class="progress-modal__content">
      <div class="progress-modal__progress">
        <div class="progress-modal__finished" [style.width.px]="data.completedSize / data.totalFileSize * 167" [attr.ts]="ts"></div>
      </div>
      <div class="progress-modal__size">
        <span>{{ displayProgress }} </span>
      </div>
    </div>
    <div class="progress-modal__cancel" (click)="cancelClick($event)">{{'login.cancel'|translate}}</div>
  </div>
  `
	, styleUrls: ['./progress.modal.scss'],
})
export class ProgressModal implements OnInit, OnDestroy {
	@Input() data: any;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@Output() cancel: EventEmitter<any> = new EventEmitter<any>();
	public dot = '.';
	private timer$;
	public initZero = true;
	public obj: any;
	public delayPortion: number = 0;
	public forceDone = false;
	public ts: number = 0;
	constructor(private common: CommonService, private elRef: ElementRef,private theme: ThemeService) {
	}

	ngOnInit() {
		this.elRef.nativeElement.style.zIndex = this.common.getZIndex();
		this.updateDot();
		
		this.theme.applyStyleObj({
			"default": {
				"alert-bgcolor":"#FFFFFF",
				"alert-textcolor":"#000000"
			},
			"dark": {
				"alert-bgcolor":"#2D2C43",
				"alert-textcolor":"#FFFFFF"
			}
		})
		
	}

	ngOnDestroy() {
		clearTimeout(this.timer$);
	}

	public get displayLabel(){
		if (this.data.displayLabel){
			return this.data.displayLabel;
		} else if (['image','video','audio'].indexOf(this.data.options.fileType) > -1){
			return 'upload.progress-upload-' + this.data.options.fileType;
		} else {
			return 'upload.progress-upload-file';
		}
	}

	public get displayProgress() {
		let data = this.data;
		let mb = 1024 * 1024;
		let kb = 1024;
		if (data.totalFileSize >= mb) {
			return this.formatNumber(data.completedSize / mb) + '/' + this.formatNumber(data.totalFileSize / mb) + 'MB';
		} else if (this.data.totalFileSize >= kb) {
			return this.formatNumber(data.completedSize / kb) + '/' + this.formatNumber(data.totalFileSize / kb) + 'KB';
		} else {
			return this.formatNumber(data.completedSize) + '/' + this.formatNumber(data.totalFileSize) + 'B';
		}
	}

	formatNumber(number) {
		number = Math.round(number * 10) / 10;
		return number;
	}

	updateDot() {
		this.timer$ = setTimeout(() => {
			this.dot = this.dot == '...' ? '' : this.dot + '.';
			this.ts = +new Date();
			if (this.data.completedSize == this.data.totalFileSize || this.data.isCompleted) {
				setTimeout(() => { this.close.emit(null); }, 100);
			} else {
				this.updateDot();
			}
		}, 500);
	}

	cancelClick($event) {
		this.cancel.emit(null);
	}

}
