export class HTMElementTool{
    copyElementAttribute(from:HTMLElement, to:HTMLElement):void
    {
        var len = from.attributes.length;
        for(var i = 0;i < len;i++)
        {
            var attr:Attr = from.attributes.item(i);
            if(attr.name != "id")
            {
                to.setAttribute(attr.name, attr.value)
            }
        }
    }

    removeAllAttribute(dom:HTMLElement):void
    {
        while(dom.attributes.length)
        {
            var attr:Attr = dom.attributes.item(0);
            dom.removeAttribute(attr.name);
        }
    }

    iterativeTextNode(dom:ChildNode, fn:Function):void
    {
        var childNode:ChildNode | null = dom.firstChild;
        while(childNode)
        {
            if(childNode instanceof Text)
            {
                childNode = fn(childNode);
            } else {
                this.iterativeTextNode(childNode, fn);
            }
            childNode = childNode ? childNode.nextSibling : null;
        }
    }

    removeNode(dom:ChildNode, reg:RegExp)
    {
        
        this.iterativeTextNode(dom, (textNode:Text)=>{
            reg.lastIndex = -1;
            if(reg.test(textNode.textContent))
            {
                var next  = textNode.nextSibling;
                textNode.remove();
                return next;
            }
            return textNode;
        })
    }

    logTextNode(dom:ChildNode):void
    {
        this.log("  current textnode  ");
        var element:HTMLElement = <HTMLElement>dom;
        console.log(element.outerHTML);
        this.iterativeTextNode(dom, (textNode:Text)=>{
                console.log(textNode.textContent);
                return textNode;
        });
    }

    splitTextNode(dom:ChildNode, reg:RegExp)
    {
        this.iterativeTextNode(dom, (textNode:Text)=>{
            var match:any;
            while(
                textNode.textContent && 
                (reg.lastIndex = -1 ) && 
                (match = reg.exec(textNode.textContent))  &&
                (match.index < textNode.textContent.length)
            )
            {
                // match.index > 0 && 
                if(match.index + 1 < textNode.textContent.length)
                {
                    if(match.index == 0) 
                    {
                        textNode = textNode.splitText(1);
                    } else 
                    {
                        textNode = textNode.splitText(match.index);
                        textNode = textNode.splitText(1);
                    }
                } else {
                    // this is the last text node
                    if(match.index > 0) 
                    {
                        textNode = textNode.splitText(match.index);
                    }
                    break;
                }
            }
            return textNode;
        })
    }

    log(title:string)
    {
        var sym:string = "*";
        var len = 100;
        var left = Math.floor((len - title.length )/2);
        var text = title.padStart(left, sym).padEnd(len, sym);
        console.log(text);
    }
}