import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CommonModule } from '@angular/common';
import { ReportPersonalComponent } from './report-personal.component';
import { InfoComponent } from './component/info.component';
import { DetailComponent } from './component/detail.component';
import { StorageService } from './service/storage.service';
import { DataOldService} from './service/data-old.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoadingModule } from '../loadingModule/loading.module';
import { ReportPersonalModal } from './report-personal.modal';
import { PieChartModule } from '../pieChartModule/pieChart.module';
import { LanguageFileSetting, TranslateModuleLoader } from 'src/app/common/TranslateModuleLoader';
import { ROModule } from '../roModule/ro.module';
import { ObjectiveComponent } from './objective.component';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InlineSvgModule } from '../inlineSvgModule/inlineSvg.module';
import { PersonalDataChartModule } from './module/personal-data-chart/personal-data-chart.module';
import { PersonalRankingModule } from './module/personal-ranking/personal-ranking.module';

@NgModule({
  declarations: [
    ReportPersonalComponent, InfoComponent, DetailComponent, ReportPersonalModal, ObjectiveComponent
  ],
  imports: [
    CommonModule, HttpClientModule,
	LoadingModule, PieChartModule,
    TranslateModuleLoader.forRoot(),
    PerfectScrollbarModule, ROModule, FormsModule, PersonalDataChartModule, PersonalRankingModule,
    DeviceDetectorModule.forRoot(), OkaPulldownModule, FontAwesomeModule, InlineSvgModule
  ],
  exports:[
    ReportPersonalModal
  ],
  providers: [
    StorageService, DataOldService, HttpClient,
	{
		provide: LanguageFileSetting,
		useValue: {
			basePath: "./assets/langs/",
			languages: ["report-personal"]
		}
	}
  ],
  bootstrap: []
})
export class ReportPersonalModule { }
