
import { Component, ViewChild, HostListener, OnInit, AfterViewInit, SimpleChanges, OnDestroy, OnChanges , EventEmitter, Directive, Input, Output, ElementRef } from '@angular/core';
//import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';


// =======================================
// service
// =======================================
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { AlertService } from 'src/app/service/alert.service';
import { SubjectService } from 'src/app/sharedModule/subjectModule/subject.service.js';


// =======================================
// component
// =======================================
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { QuestionBankEditGridComponent } from 'src/app/coreModule/QuestionBankModule/QuestionBankEditGrid.component';

// =======================================
// popup
// =======================================
//import { Modal2Component } from 'src/app/sharedModule/modal2Module/modal2.component';

// =======================================
// icon
// =======================================
import { faSearch, faRedo,faTable, faEllipsisVertical, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faTrash, faLink, faClone } from '@fortawesome/pro-solid-svg-icons';
import { faSave, faCirclePlay, faUserPlus,faDiagramCells } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';

import lang_tc from 'src/app/coreModule/AssessmentEditModule/lang/tc.json';

//import { CreateShareService } from 'src/app/sharedModule/createShareModule/create-share.service';

import { Base64 } from 'js-base64';
import { CreateShare2Component } from 'src/app/sharedModule/createShareModule/create-share2.component.js';
import { AssessmentViewerComponent } from 'src/app/sharedModule/AssessmentViewerModule/AssessmentViewer.component';
import { Subscription } from 'rxjs';
import { BubbleBox2Component } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.component';
import { resolve } from 'url';

@Component({
	selector: 'AssessmentEditNoRouter',
	templateUrl: './AssessmentEditNoRouter.component.html',
	styleUrls: ['./AssessmentEditNoRouter.component.scss']
})


export class AssessmentEditNoRouterComponent implements OnInit {
	@ViewChild('perfectScrollbar', {static:true}) public perfectScrollbar:PerfectScrollbarComponent;
	@ViewChild('editGrid', {static:false}) public editGrid:QuestionBankEditGridComponent;
	@ViewChild('createShareModule', {static:false}) public createShareModule:CreateShare2Component;
	@ViewChild('assessmentViewer', {static:false}) assessmentViewer!: AssessmentViewerComponent;
	@ViewChild('morePopup', {static:true}) public morePopup:BubbleBox2Component;
	
	public faTrash:IconDefinition = faTrash;
	public faSave:any = faSave;
	public faCirclePlay:any = faCirclePlay;
	public faUserPlus:any = faUserPlus;
	public faPaperPlane:any = faPaperPlane;
	public faDiagramCells:IconDefinition = faDiagramCells;
	public faTable:IconDefinition = faTable;
	public faEllipsisVertical:IconDefinition = faEllipsisVertical;

	public shareModal: any = null;
	
	public mode:string ="edit";
	protected langData:any;
	public assessmentID:number;
	public assessmentSubject:string;
	public bookName:string = "";
	public entryAndContent:any;
	public records:any = [];

	public selectionPanelConfig:any;
	public selectedItems: any[] = [];
	public createShareModuleSetting:any;
	public uiView:string = 'form';
	public componentList:any = [
		{titleKey:"QBEditor.option.type_opt.QBInfoBlock"},
		{titleKey:"QBEditor.option.type_opt.QBTextMC"},
		{titleKey:"QBEditor.option.type_opt.QBRecorder"},
		{titleKey:"QBEditor.option.type_opt.QBTakePhoto"},
		{titleKey:"QBEditor.option.type_opt.QBShortAnswer"},
		{titleKey:"QBEditor.option.type_opt.QBLongQuestion"},
		{titleKey:"QBEditor.option.type_opt.QBFillingBlank"},
		{titleKey:"QBEditor.option.type_opt.QBToggleOptions"}
	];
	public lastNav:any;
	@Input() data;
	public static _langReady:boolean = false;
	public get langReady():boolean {
		return AssessmentEditNoRouterComponent._langReady;
	}
	public set langReady(value:boolean) {
		AssessmentEditNoRouterComponent._langReady = value;
	}
	protected inConfirmSave:boolean = false;
	protected autoSaveTimer:number = -1;

	constructor(
		private location: Location,private router: Router, 

		protected subjectService:SubjectService,
		public translate:TranslateService, 
		public datas:DataService,
		public coms:CommonService,
		private alertService:AlertService,
		public themeService:ThemeService,
		private route:ActivatedRoute, 
		//private createShare: CreateShareService, 
		public eleRef:ElementRef) {

		// this.lastNav = this.router.getCurrentNavigation().previousNavigation;
		
		themeService.applyStyleObj({
			"default": {
				"section-maincolor": "#3D901E",
				"section-maincolor-20pt": "#3D901E",
				"section-popupbgcolor": "#ECF4E9",
				"section-popupbgcolor2": "#fff",
				"section-titlecolor":"#000",
				"alert-okbuttoncolor": "#000",

				"pulldown-bgcolor":"#f0f0f0",
				"pulldown-labelcolor":"var(--section-maincolor)",
				"pulldown-trianglecolor":"var(--section-maincolor)",
				"pulldown-bottomcolor":"#d0d0d0",
				
				"popupform-textcolor":"var(--section-maincolor)",
				"popupform-bgcolor":"#f1f1f7",
				"popupframe-shadow":"0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"header-titlecolor": "#000",

				"credit-board-bg":"#f1f1f7",
				"credit-color":"var(--section-maincolor)",
				"popuptoggle-bgcolor":"#fff",

				"input-field-label-bgcolor":"#f5f5f5"
			},
			"dark": {
				"section-maincolor": "#3D901E",
				"section-maincolor-20pt": "#3D901E",
				"section-popupbgcolor": "#2D2C43",
				"section-popupbgcolor2": "#2D2C43",
				"section-titlecolor":"#fff",

				"pulldown-bgcolor": "#38374f",
				"pulldown-labelcolor":"#fff",
				"pulldown-trianglecolor":"#fff",
				"pulldown-bottomcolor": "#000",

				"popupform-textcolor": "#fff",
				"popupform-bgcolor": "#f1f1f7",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"header-titlecolor": "#fff",

				"credit-board-bg":"#26253a",
				"credit-color":"#fff",
				"popuptoggle-bgcolor":"#1e1e29",

				"input-field-label-bgcolor":"#38374f"
			}
		});
		//}, eleRef.nativeElement);
		this.translate.use(this.datas.lang);

		this.route.params.subscribe((params) => {
			this.datas.lang = params['lang'];
			this.datas.appId = params['appId'];
			
			this.assessmentID = params['id'];
			this.mode = (params['mode'] == "previewOnly" || params['mode'] == "edit") ? params['mode'] : "edit";

//			this.langData = lang_tc.AssessmentEditor;

//			if(!(sts && sts.otherObjs && sts.otherObjs['user']))
//			{
//				sts.loadData().then(($data) => {});
//			}

			this.translate.use(this.datas.lang);
			this.waitLanguageReady().then(success => {
				this.langReady = true;
				this.subjectService.loadSubject().subscribe(e => {
					this.loadAssessmentContent();
				});
			});
		});

		this.selectionPanelConfig = {
			items: [
				{
					name: 'delete', icon:faTrash, iconSize:'18px', multiple: true,
					callback: () => { this.editGrid.deleteSelected() }
				},
				{
					name: 'assessment-copyToQB', icon:faLink, multiple: true, iconSize:'18px',
					callback: () => { this.editGrid.copyToQB(this.assessmentID).then(success => {
						this.save();
					}) },
					disableWhen: ()=> {return this.selectedItems.find(c => parseInt(c.copyFromQB) != 0);}
				},
				{
					name: 'assessment-clone', icon:faClone, multiple: true, iconSize:'18px',
					callback: () => { this.editGrid.clone(); }
				},
			],
			style: { bottomLevel: 0, left: 0 }
		};
	}

	ngOnInit(): void {
		if (this.data){
			this.assessmentID = this.data.book_id;
			this.mode = this.data.mode;
		}
	}

	public waitLanguageReady():Promise<any> {
		if(!this.langReady && false) {
			return new Promise((resolve, reject)=>{
				this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
					resolve("ready");
				});
			});
		}

		return new Promise((resolve)=>{
			setTimeout(()=>{resolve('ready');},0);
		});
	}
	
	public getText(key:string):string {
		//return this.langData ? this.translate.parser.getValue(this.langData, key) : key;
		return this.langReady ? this.translate.instant("QBEditor."+key) : "";
	}

	public getStyleValue(key:string):any {
		return this.themeService.getStyleValue(key, this.eleRef.nativeElement);
	}

	getTotalScore():string {
		let t:number = 0;
		if(this.editGrid && this.editGrid.records) {
			this.editGrid.records.forEach((itm:any, index:number) => {
				if(Boolean(itm.hasScoring) && itm.douid!=null && itm.type!="QBInfoBlock")
				{
					if(itm.scoringType == 1)
						t+=parseFloat(itm.fullScore);
					else if(itm.scoringType == 2)
						t+=parseFloat(itm.scoreN)*parseFloat(itm.unitScore);
				}
			});
		}
		return this.getText("Total score")+" "+t;
	}

	getTotalQuestions():string {
		let num:number = 0;
		if(this.editGrid && this.editGrid.records) {
			this.editGrid.records.forEach((itm:any, index:number) => {
				if(Boolean(itm.hasScoring) && itm.douid!=null && itm.type!="QBInfoBlock")
				{
					num++;
				}
			});
		}
		//if(this.editGrid && this.editGrid.records)
		//	num = Math.max(0,this.editGrid.records.length-1);
		return this.getText("Total questions")+" "+num;
	}

	getBookSubject():string {
		if(this.langReady && this.subjectService.subjects && this.assessmentSubject && this.editGrid)
			return " ("+this.editGrid.getSubjectText(this.editGrid.getSubjectData(this.assessmentSubject))+")";
		return "";
	}

	back() {
		if (this.data && this.data.close){
			this.data.close();
			return;
		}
		if(this.mode == "previewOnly" || this.mode == "edit") {
			if(this.lastNav)
				this.location.back(); // 上一頁是由程式轉過來，先可返上一層
			else
				this.router.navigate(['workspace', this.datas.lang, this.datas.appId]); // 預設是返回工作間
		} else //if(this.mode == "preview")
			this.mode = "edit";
	}

	gridRecordToAssessmentRecord(gRecord:any):any {
		let rows:any = [];
		gRecord.forEach(row => {
			if(row.douid!=null)
			{
				if(!row.hasOwnProperty("lpf"))
					row.lpf = [];
				if(row.hasOwnProperty("copyFromQB") && row.copyFromQB!=0)
				{
					// linked question
					rows.push({
						douid: row.douid!=row.qid ? row.qid : row.douid, // 修正 id (因轉了 id 格式)
						qid: row.qid,
						copyFromQB: row.qid,
						// copyFromAssessment
						questionIndex:row.questionIndex,
						subject:row.subject,
						hasScoring:row.hasScoring, scoringType:row.scoringType, 
						scoreN:row.scoreN, unitScore:row.unitScore, fullScore:row.fullScore,
						learningObjective:row.learningObjective, lpf:row.lpf, grade:row.grade, level:row.level
					});
				}
				else
				{
					// normal question
					delete row.src;
					delete row._rowID;
					rows.push(row);
				}

			}
		});

		return rows;
	}

	// =======================================
	// service function
	// =======================================
	loadAssessmentContent()
	{
		this.datas.post2({
			data:{
				api:'QuestionBank.loadAssessmentContent',
				json:[this.assessmentID,true]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				this.bookName = res.data.title;
				this.entryAndContent = res.data;
				this.entryAndContent.content = JSON.parse(Base64.decode(this.entryAndContent.content));
				if(!this.entryAndContent.content.hasOwnProperty("setting"))
					this.entryAndContent.content.setting = {};
				var s = this.entryAndContent.content.setting;
				if(!s.hasOwnProperty("autoSave"))	s.autoSave = false;
				if(!s.hasOwnProperty("fontSize"))	s.fontSize = 26;
				if(!s.hasOwnProperty("qSize"))		s.qSize =3;
				if(!s.hasOwnProperty("scoreSize"))	s.scoreSize =3;
					
				this.entryAndContent.content.questions.forEach(e => {
					if(e.subject)
						e.subject = Array.isArray(e.subject) ? e.subject : e.subject.split(",");
					else
						e.subject = [];
				});

				this.assessmentSubject = res.subject;
				
				this.records = this.entryAndContent.content.questions;
				if(this.mode != "previewOnly") {
					// edit mode 先有 edit grid
					this.editGrid.records = this.records;
					res.users.forEach(row => {
						if(!this.editGrid.users.find(e => e.uid == row.uid)) {
							this.editGrid.users.push(row);
						}
					});
					res.schools.forEach(row => {
						if(!this.editGrid.schools.find(e => e.school_id == row.school_id)) {
							this.editGrid.schools.push(row);
						}
					});

					// 檢查連結題目有沒有更新
					let pageComs:any = res.bookStructure ? res.bookStructure.book.chapters[0].pages[0].components : [];

					this.editGrid.records.forEach((row, index) => {
						if(row.hasOwnProperty("copyFromQB") && row.copyFromQB!=0)
						{
							// linked question
							//if(row.type != pageComs[index].type)
							//	this.editGrid.state = "have change";
						}
					});

					if(this.editGrid.state == "have change") {
						this.inConfirmSave = true;
						this.alertService.alert2(this.getText("CFM.structureChanged"), 
							{}, { btns: [['yes', 'no']] }, 
							this.eleRef.nativeElement).then((key:string)=>{
							if (key=='yes') {
								this.save();
							}
							this.inConfirmSave = false;
						});
					} else {
						this.inConfirmSave = false;
					}

					this.editGrid.updateDataSequence(false);
				}
				
				if(this.mode == "edit")
					this.editGrid.getAnalysis(); // 編緝時先自動載入一次分析
			}
		});
	}

	public getState():string {
		if(this.editGrid)
			return this.editGrid.state;
		return "no change";
	}

	public share():void {
		this.createShareModule.open(this.assessmentID, this.bookName, 'assessment', this.assessmentSubject);
	}

	public addComponent($event):void {
		this.editGrid.addComponent($event.titleKey.split(".").pop());
	}

	public toggleUIView():void {
		this.uiView=this.uiView!='grid' ? 'grid' : 'form';
		if(this.uiView == "grid" && this.selectedItems.length>0)
			this.editGrid.updateGridSelected();
	}

	public moreOptions($event):void {
		this.morePopup.open($event.target).then(()=>{
			//let msg:string = "'Yes' btn clicked.";
			//alert(msg);
			//console.log(msg);
		}).catch((e)=>{
			//let msg:string = "'No' btn clicked.";
			//alert(msg);
			//console.log(msg);
		});
	}

	save():void {
		this.editGrid.fileProcess().then((res:any)=>{
			if(res) {
				// 要移除 dummy row
				console.log("save");
				this.entryAndContent.content.questions = this.gridRecordToAssessmentRecord(this.editGrid.records);
				let bookStructure:any = this.editGrid.createBookStructure(
					this.bookName, 
					this.entryAndContent.content.douid, 
					this.entryAndContent.content.questions, this.editGrid.records); // linked question 要用 grid record gen
				
				// 將 content 轉做 base 64
				let content:any = Base64.encode(JSON.stringify(this.entryAndContent.content));
				this.editGrid.updateDataSequence();
				
				this.datas.post2({
					data: { api: 'QuestionBank.updateContent', json:[this.assessmentID, this.assessmentID, content, bookStructure]}
				}).then((res:any)=>{
					this.editGrid.state = "saved";
					this.editGrid.deselectAll();
				}).catch((err:any)=>{
					console.log("timeout");
				});
			}
		});
	}

	// =======================================
	// preview function
	// =======================================
	public assesssmentPreview():void
	{
		if(this.editGrid && this.editGrid.records) {
			// update preview content
			let q:any = [];
			for(let i:number=0; i<this.editGrid.records.length; i++)
			{
				if(this.editGrid.records[i].douid!=null)
					q.push(this.editGrid.records[i]);
			}
			this.entryAndContent.content.questions = q;

		}
		this.mode = "preview";
	}

	public onSelectionChanged($event):void {
		this.selectedItems = $event;
	}

	public submit():void {
		let answers:any;
		answers = this.assessmentViewer.getAnswers(true);
		
		if(answers.length>0)
		{
			
		}
		else
		{
			//this.alertService.alert(this.getText("alert.no_question_answered"));
		}
	}


	public checkAutoSave():void {
		this.editGrid.state = "have change";
		if(!this.inConfirmSave && this.entryAndContent.content.setting.autoSave) {
			// clear auto save timer
			if(this.autoSaveTimer!=-1)
			{
				window.clearTimeout(this.autoSaveTimer);
				this.autoSaveTimer = -1;
			}
			// renew auto save timer
			this.autoSaveTimer = window.setTimeout(() => {
				this.autoSaveTimer = -1;
//				this.save();
				console.log("auto saved");
			}, 5000);
		}
	}
}
