import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { TargetSelectorComponent } from './targetSelector.component';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
		CommonModule,
        FormsModule,
        TranslateModule,
        FontAwesomeModule,
		NgSelectModule,
		PerfectScrollbarModule,
        BubbleBox2Module,
    ],
    declarations: [ TargetSelectorComponent ],
	exports:[ TargetSelectorComponent ],
	entryComponents: [],
	providers:[]
})
export class TargetSelectorModule {}
