// import { ByteArray } from "src/app/flash/utils/ByteArray";
// import { LookUpTable } from "src/app/flash/utils/LookupTable";
// import {ByteArray} from "openfl";
import { ByteArray, Endian } from "openfl";
import { LookUpTable } from "../encryption/LookupTable";
// import { LookUpTable } from "src/app/flash/utils/LookupTable";



export class RODoc
{
	constructor()
	{
		
	}
	
	static decryptBytes(loadData:ByteArray):string
	{
		
		// var loadData:ByteArray = new ByteArray(buffer);
		var descryptData:ByteArray
		var str:string;
		if (loadData && loadData.length > 3)
		{
			loadData.position = 0;
			var meta:string = loadData.readUTFBytes(3);
			if (meta == "OKD")
			{
				throw new Error("not implemented."); 
				/*
				if (!cipher)
				{
					// init Cipher
					var pad:IPad = new PKCS5();
					cipher = new CBCMode( new AESKey(encryptKey), pad);
					pad.setBlockSize(cipher.getBlockSize());
					IVMode(cipher).IV = initVector;
				}
				// is encrypt format
				// trace("read encrypt format");
				descryptData = new ByteArray();
				loadData.readBytes(descryptData);
				cipher.decrypt(descryptData);
				descryptData.position = 0;
				str = descryptData.readUTFBytes(descryptData.bytesAvailable);
				*/
			}
			else if (meta == "OK2")
			{
				throw new Error("not implemented."); 
				/*
				// is encrypt format
				// trace("read encrypt format");
				descryptData = new ByteArray();
				loadData.readBytes(descryptData);
				descryptData = LookUpTable.decrypt(descryptData);
				
				descryptData.position = 0;
				str = descryptData.readUTFBytes(descryptData.bytesAvailable);
				*/
			} else if (meta == "OK3")
			{
				throw new Error("not implemented."); 
				/*
				// trace("read encrypt format");
				descryptData = new ByteArray();
				loadData.readBytes(descryptData);
				descryptData = LookUpTable.decrypt(descryptData);
				descryptData.uncompress();
				descryptData.position = 0;
				str = descryptData.readUTFBytes(descryptData.bytesAvailable);
				*/
			} else if ( meta == "OK4")
			{
				descryptData = new ByteArray();
				descryptData.endian = Endian.BIG_ENDIAN;
				
				loadData.readBytes(descryptData);
				descryptData = LookUpTable.decrypt2(descryptData);
				descryptData.uncompress();
				descryptData.position = 0;
				str = descryptData.readUTFBytes(descryptData.bytesAvailable);
				
			}
			else
			{
				// non encrypt format
				console.log("read non encrypt format");
				loadData.position = 0;
				str = loadData.readUTFBytes(loadData.bytesAvailable);
			}
		} else {
			str = "";
		}
		/*
		var xml:XML = new XML(str);
		if (String(xml.@douid) == "")
		{
			var v:uint = ZipCRC32.getByteArrayValue(loadData);
			var checksum:String = hk.openknowledge.utils.printf(
				"%08s",
				v.toString(16).toUpperCase()
			);
			var douid:String = checksum + "-0000-0000-0000-000000000000";
			xml.@douid = douid;
		}
		*/
		return str;
	}

	static encryptBytes(content:string):ByteArray
	{
		let seed:number = Math.round(Math.random()*4294967296);
		let ba:ByteArray = new ByteArray();
		ba.writeUTFBytes("OK4");

		let encryptData:ByteArray = new ByteArray();
		encryptData.writeUTFBytes(content);
		encryptData.compress();
		encryptData = LookUpTable.encrypt2(seed, encryptData);
		ba.writeBytes(encryptData, 0, encryptData.length);
		return ba;
	}

	static toNoCompressBytes(content:string):ByteArray
	{
		let ba:ByteArray = new ByteArray();
		ba.writeByte(0xEF);
		ba.writeByte(0xBB);
		ba.writeByte(0xBF);
		//ba.writeUTFBytes('<?xml version="1.0" encoding="UTF-8"?>' + content);
		ba.writeUTFBytes(content);
		ba.position = 0;
		return ba;
	}
}