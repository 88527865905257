import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { faBook, faImage } from '@fortawesome/pro-solid-svg-icons';
import { CodeScanService } from 'src/app/sharedModule/codeScanModule/codeScan.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as isbnUtils from 'isbn-utils';
import { CommonService } from 'src/app/service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { InlineSvgService } from 'src/app/sharedModule/inlineSvgModule/inlineSvg.service';
import { MessageService } from 'primeng/api';
import { TagGroupService } from '../tagGroupModule/tagGroup.service';
import * as moment from 'moment';
import { UploadService } from '../uploadModule/upload.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { faTrash, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { SettingService } from 'src/app/service/setting.service';
import { toXML } from 'jstoxml';
import { GUID } from 'src/app/class/hk/openknowledge/encryption/GUID';

interface Options{
	label?: string;
	value?: string;
}

@Component({
	selector: 'create-ai-oral-practice-modal',
	templateUrl: `./create-ai-oral-practice.modal.html`
	, styleUrls: ['./create-ai-oral-practice.modal.scss'],
	providers: [MessageService]
})

export class CreateAIOralPracticeModal implements OnInit {
	@Input() data: any;
	@ViewChild('targetSelector', {static: false}) targetSelector;
	@ViewChild('dateTimePicker', {static: false}) dateTimePicker;
	@ViewChild('inProgressTimeRef', {static: false}) inProgressTimeRef;
	@ViewChild('objectiveSelector', {static: false}) objectiveSelector;
	@ViewChild('objectiveTextRef', {static: false}) objectiveTextRef;
	@ViewChild('bubblebox', {static: false}) bubblebox: BubbleBox2Component;


	public faBook = faBook;
	public barcodeReader;
	public okaPulldownStyle = { width: '204px' };
	public okaPulldownMenuStyle = {};
	public bookType = 'physical';
	public name = '';
	public inputMode = 'free';
	public bookAssigned = false;
	public photo;
	public book;
	public switchColor = '#684ccc';
	public ready = false;
	public file;
	public modalTitle = 'course.create-ai-oral-practice';
	public triedSubmitted = false;
	public clickSubject = new Subject();
	public codePool = 'both';
	public selectBookModal;
	public selectLibraryModal;
	public website = '';
	public remark = '';
	public animated = false;
	public faImage = faImage;
	public mediaUrl = '';
	public targets: any = [];
	public preview;
	public objectiveTitle = '';
	public infoBallText = '';
	public totalScore = 100;
	public faTrash = faTrash;
	public faCirclePlus = faCirclePlus;
	public passLevelOptions;
	public scoringItemsGuidance = ['內容必須與標題相關，否則會扣分。','',''];;

	objectiveTextClick(){
		if (this.selectorPromise && this.objectiveSelector) {
			this.objectiveSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
		} else {
			setTimeout(() => {
				const ele = this.objectiveTextRef.nativeElement;
				this.selectorPromise = this.objectiveSelector.open(ele, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					if (this.objectiveSelector.treeContent.modalSelectedItems && this.objectiveSelector.treeContent.modalSelectedItems.length){
						this.scoringScaleObjective = this.objectiveSelector.treeContent.modalSelectedItems[0];
					}
					this.initObjective();
				}).catch(e=>{ //cancel will throw error
					this.selectorPromise = null;
				});
			}, 200);
		}
	}

	get selectedLanguageVoiceOptions(): any[]{
		switch(this.form.conversationSetting.language){
			case "zh-HK":
				return this.AIVoiceOptions.zh_HK;
			case "zh-CN":
				return this.AIVoiceOptions.zh_CN;
			case "en-GB":
				return this.AIVoiceOptions.en_GB;
			case "en-US":
				return this.AIVoiceOptions.en_US;
		}
		return [];
	}

	public AIVoiceOptions = {
		zh_HK:[{
			"voice_name": "zh-HK-HiuGaaiNeural",
			"name": "zh-HK-HiuGaaiNeural",
			"display_name": "女 (曉佳 - Neural)",
			"gender": "F",
			"lang": "zh-HK",
			"cname": "女 (曉佳 - Neural)",
			"ename": "F (HiuGaai - Neural)"
		}],
		zh_CN:[],
		en_GB:[],
		en_US:[]
	}

	public form: any = {
		basicInfo:{
			tags: [],
			gradeAndSkillDescription: "",
			subject: [],
			inProgressTime:"",
			topic: "",
			description: "",
		},
		conversationSetting:{
			inputMode:"speech",
			language:"zh-HK",
			voice:"",
			speed:"normal",
			display:"hide",
			response:{
				on: true,
				sentences: "3",
				words: "10"
			},
			hints:{
				on: true,
				sentences: "3",
				times: 1
			}
		},
		scoreSetting:{
			aiFinalComment: {
				on: true,
				words:"30",
				times: 1
			},
			finalScoring: "ai-grade-teacher-review",
			scoringScale: "manual",
			scoringScaleText:""	,
			passLevel: 60,
			scoringItems: [{title: '內容', guidance:'', score: 40},{title: '文法', guidance:'', score: 30},{title: '創意',guidance:'', score: 30}]
		}
	};

	public inputModeOptions: Options[] = [
		{ label: "course.ai-oral.speech", value: "speech" },
		{ label: "course.ai-oral.speech-and-text", value: "speechText"},
		{ label: "course.ai-oral.free", value: "free" },
	];

	public AILanguageOptions: Options[] = [
		{ label: "course.ai-oral.cantonese", value: "zh-HK" },
		{ label: "course.ai-oral.mandarin", value: "zh-CN"},
		{ label: "course.ai-oral.english-uk", value: "en-GB"},
		{ label: "course.ai-oral.english-us", value: "en-US"}
	];
	public AISentencesOptions: Options[] = [
		{ label: "course.ai-oral.less-than-three-sentences", value: "3" },
		{ label: "course.ai-oral.less-than-five-sentences", value: "5"},
		{ label: "course.ai-oral.less-than-ten-sentences", value: "10"},
	];
	public responceWordsOptions: Options[] = [
		{ label: "course.ai-oral.less-than-ten-words", value: "10" },
		{ label: "course.ai-oral.less-than-twenty-words", value: "20"},
		{ label: "course.ai-oral.less-than-thirty-words", value: "30"},
	];

	public AICommentWordsOptions: Options[] = [
		{ label: "course.ai-oral.about-thirty-words", value: "30" },
		{ label: "course.ai-oral.about-fifty-words", value: "50"},
		{ label: "course.ai-oral.about-hundred-words", value: "100"},
	];

	public scoringScaleOptions: Options[] = [
		{ label: "course.ai-oral.manual-input", value: "manual" },
		{ label: "course.ai-oral.choose-from-objectives", value: "objective" },
	];

	public generateOptions: Options[] = [
		{ label: "course.ai-oral.generate-one-time", value: "1" },
		{ label: "course.ai-oral.generate-twice", value: "2"},
		{ label: "course.ai-oral.generate-three-times", value: "3"},
		{ label: "course.ai-oral.generate-four-times", value: "4"},
		{ label: "course.ai-oral.generate-five-times", value: "5"}
	]

	public AIHintOptions: Options[] = [
		{ label: "course.ai-oral.one-hint-per-conversation", value: "1" },
		{ label: "course.ai-oral.two-hints-per-conversation", value: "2"},
		{ label: "course.ai-oral.three-hints-per-conversation", value: "3"},
		{ label: "course.ai-oral.four-hints-per-conversation", value: "4"},
		{ label: "course.ai-oral.five-hints-per-conversation", value: "5"},
	];
	public AiSpeedOptions: Options[] = [
		{ label: "course.ai-oral.slow", value: "slow" },
		{ label: "course.ai-oral.normal", value: "normal" },
		{ label: "course.ai-oral.fast", value: "fast" },
		{ label: "course.ai-oral.free", value: "free" },
	];
	public diplayOptions: Options[] = [
		{ label: "course.ai-oral.hide", value: "hide" },
		{ label: "course.ai-oral.display", value: "display" },
		{ label: "course.ai-oral.free", value: "free" },
	];

	public finalScoringOptions: Options[] = [
		{ label: "course.ai-oral.ai-grading", value: "ai-grading"},
		{ label: "course.ai-oral.teacher-grading", value: "teacher-grading"},
		{ label: "course.ai-oral.ai-and-teacher-grading", value: "ai-grade-teacher-review"},
	];


	public star = 0;
	public selectorPromise;
	public targetSelecting = false;
	public startTime;
	public endTime;
	public wholeDay = 0;
	constructor(public upload: UploadService, private load: LoadingService, public datas: DataService, public iconReg: InlineSvgService, private script: ScriptService, private als: AlertService, private codeScan: CodeScanService, private sans: DomSanitizer, private coms: CommonService, private trans: TranslateService, public eleRef: ElementRef, private lds: LoadingService, private tg: TagGroupService, private setting: SettingService) {
	}

	async ngOnInit() {
		// this.initTargets();
		this.initOptions();
		this.initInProgressTime();
		this.initObjective();

		await this.getAllVoiceOptions();
		if(this.data.book_id){
			this.modalTitle = "編輯AI會話訓練";
			await this.getSetting();
		}

		await this.iconReg.regSvgFiles([
			{ url: 'course/btn_barcode.svg', name: 'btn_barcode' },
			{ url: 'course/btn_addbook.svg', name: 'btn_addbook' },
			{ url: 'course/btn_addbook_file.svg', name: 'btn_addbook_file' },
		]);
		this.ready = true;
		
	}

	private async getSetting(){
		var practiceSetting = await this.datas.post2({ data: { api: 'ROBookshelfNew.get_ai_oral_practice_setting', json: [this.data.book_id] } });

		try {
			var tags = JSON.parse(practiceSetting.group);
			// Use the parsed data as needed.
		  } catch (error) {
			// Handle the parsing error or return an appropriate response.
			tags = [];
		  }
		try {
			var subject = JSON.parse(practiceSetting.subject);

			// Use the parsed data as needed.
		  } catch (error) {
			// Handle the parsing error or return an appropriate response.
			subject = [];
		  }
		try {
			var scoringItems = JSON.parse(practiceSetting.scoring_items);
			// Use the parsed data as needed.
		  } catch (error) {
			// Handle the parsing error or return an appropriate response.
			scoringItems = [{title: '內容', guideline: '', score: 40},{title: '文法', guideline: '', score: 30},{title: '創意', guideline: '', score: 30}];
		  }
		this.form.basicInfo.tags = tags;
		this.form.basicInfo.gradeAndSkillDescription = practiceSetting.grade_skill_description;
		this.form.basicInfo.subject = subject;
		this.form.basicInfo.inProgressTime = practiceSetting.in_progress_time;
		this.form.basicInfo.topic = practiceSetting.topic;
		this.form.basicInfo.description = practiceSetting.description;
		//----------------------------------------------------------------------
		this.form.conversationSetting.inputMode = practiceSetting.input_mode;
		this.form.conversationSetting.language = practiceSetting.ai_language;
		this.form.conversationSetting.voice = practiceSetting.ai_voice;
		this.form.conversationSetting.speed = practiceSetting.ai_speed;
		this.form.conversationSetting.display = practiceSetting.display_text_in_dialog;
		this.form.conversationSetting.response.on = practiceSetting.ai_response;
		this.form.conversationSetting.response.sentences = practiceSetting.ai_response_sentences;
		this.form.conversationSetting.response.words = practiceSetting.ai_response_words;
		this.form.conversationSetting.hints.on = practiceSetting.ai_hint;
		this.form.conversationSetting.hints.sentences = practiceSetting.ai_hint_sentences;
		this.form.conversationSetting.hints.times = practiceSetting.ai_hint_times;
		//----------------------------------------------------------------------
		this.form.scoreSetting.aiFinalComment.on = practiceSetting.ai_final_comment;
		this.form.scoreSetting.aiFinalComment.words = practiceSetting.ai_final_comment_words;
		this.form.scoreSetting.finalScoring = practiceSetting.final_scoring;
		this.form.scoreSetting.scoringScale = practiceSetting.scoring_scale;
		this.form.scoreSetting.passLevel = practiceSetting.pass_level;
		this.form.scoreSetting.scoringItems = scoringItems;
		//----------------------------------------------------------------------
		if(practiceSetting.image){
			this.mediaUrl = practiceSetting.image;
			let url = 'https://oka.blob.core.windows.net/media/' + practiceSetting.image;
			this.preview = this.sans.bypassSecurityTrustStyle('url("' + url + '")');
		}
		this.updateTotalScore();
	}

	mediaUpload(){
		const uploadOptions = {size: 50 *1024 *1024, fileType: 'image', toMediaServer: 'ai-oral', width: 300, height: 162};
		this.upload.upload(uploadOptions).then((res:any)=>{
			this.mediaUrl = res.url;//https://oka.blob.core.windows.net/media/
			let url = 'https://oka.blob.core.windows.net/media/';
			url = url + res.url;
			this.preview = this.sans.bypassSecurityTrustStyle('url("' + url + '")');
		});
	}

	public scoringScaleObjective;

	initObjective(){
		const lang = this.datas.lang;
		if (this.scoringScaleObjective){
			this.objectiveTitle = this.scoringScaleObjective.name;
		} else {
			this.objectiveTitle = {tc: '請選擇...', sc:'請選擇...', en: 'Please select'}[lang];
		}
	}


	mediaClear(){
		this.preview = null;
		this.mediaUrl = '';
	}

	// loadExistShare(data){
	// 	const structure = data.structure;
	// 	const jsonObj = data.share.json?JSON.parse(data.share.json):null;
	// 	if (structure){
	// 		const info = data.structure.book.chapters[0].info;
	// 		const props = ['name','targets','abilityInfo','subjects','startTime','endTime','essayTopic','description','essayLang','aiLang','paragraph','prewritingAIGuideEnabled','prewritingAIGuideWords','prewritingAIGuideTimes','prewritingAISuggestionEnabled','prewritingAISuggestionWords','prewritingAISuggestionTimes','prewritingAIFeedbackEnabled','prewritingAIFeedbackWords','prewritingAIFeedbackTimes','prewritingAICommentEnabled','prewritingAICommentWords','scoring','scoringScale','scoringScaleText','scoringScaleObjective', 'mediaUrl'];
	// 		props.forEach(prop=>{
	// 			this[prop] = info[prop];
	// 		});
	// 		if (this.mediaUrl){
	// 			let url = 'https://oka.blob.core.windows.net/media/' + this.mediaUrl;
	// 			this.preview = this.sans.bypassSecurityTrustStyle('url("' + url + '")');
	// 		}
	// 	}
	// }

	inProgressTimeClick(){
		const isDtpOpen = this.dateTimePicker.bubbleBox2.show && this.dateTimePicker.bubbleBox2.boxPosX > 0;
		if (isDtpOpen){
			return;
		}
		let ele = this.inProgressTimeRef.nativeElement;
		let startDateTime:any = this.startTime?moment(this.startTime,"YYYY-MM-DD HH:mm:ss").toDate():null;
		let endDateTime:any = this.endTime?moment(this.endTime,"YYYY-MM-DD HH:mm:ss").toDate():null;
		let needEndTime = !!this.endTime;
		let options:any = {
			targetField: 'startDate',
			showEndDateTimeOption: true,
			showPresetOptions: false,
			value: {
				customMinutes: true,
				needEndTime: needEndTime,
				startDateTime: startDateTime,
				endDateTime: endDateTime?endDateTime:null
			}
		};

		this.dateTimePicker.open(ele, options).then((data: any) => {
			this.startTime = moment(data.startDateTime).format('YYYY-MM-DD HH:mm:ss');
			if (data.endDateTime){
				this.endTime = moment(data.endDateTime).format('YYYY-MM-DD HH:mm:ss');
			} else {
				this.endTime = null;
			}
			this.initInProgressTime();
		}).catch(e=>{
		});
	}
	
	initInProgressTime(){
		const startTimeMo = this.startTime?moment(this.startTime, 'YYYY-MM-DD HH:mm:ss'):null;
		const endTimeMo = this.endTime?moment(this.endTime, 'YYYY-MM-DD HH:mm:ss'):null;
		if (endTimeMo){
			this.form.basicInfo.inProgressTime = startTimeMo.format('YYYY/M/D h:mma') + ' - ' + endTimeMo.format('YYYY/M/D h:mma');
		} else {
			this.form.basicInfo.inProgressTime = startTimeMo.format('YYYY/M/D h:mma') + ' - ∞';
		}
	}

	private async getAllVoiceOptions(){
		this.datas.post("ROMediaLibrary.getTtsOptions").subscribe((res: any) => {
			this.AIVoiceOptions.zh_HK = [];
			if(res && res.data){
				res.data.forEach(data => {
					if(data.lang == "zh-HK"){
						this.AIVoiceOptions.zh_HK.push(data);
					}else if(data.lang == "zh-CN"){
						this.AIVoiceOptions.zh_CN.push(data);
					}else if(data.lang == "en-GB"){
						this.AIVoiceOptions.en_GB.push(data);
					}else if(data.lang == "en-US"){
						this.AIVoiceOptions.en_US.push(data);
					}
				});
				this.updateSelectedVoice();
			}
		});
	}

	ngAfterViewInit() {
		this.clickSubject.pipe(throttleTime(100)).subscribe(e => {
			if (e == 'confirm') {
				this.confirm();
			} else if (e == 'barcode') {
				this.scan();
			} else if (e == 'from-library') {
				this.fromLibrary();
			} else if (e == 'from-workspace') {
				this.fromWorkspace();
			}
		});
		setTimeout(() => { this.animated = true }, 500);
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	scan(): void {
		let options = { fps: 5, width: 400, height: 200, codeType: 'barcode', style: { width: '400px', height: '200px' } };
		this.codeScan.scan(options).then((e: any) => {
			if (e.code == 1) {
				if (this.codePool == 'book_id' || this.codePool == 'both') {
					this.checkbookRefId(e.text);
				} else {
					this.checkBarcode(e.text);
				}
			} else if (e.msg && e.msg != 'cancel') {
				this.als.okAlert('course.' + e.msg);
			}
		}).catch(err => {
			console.log(err);
		});
	}

	checkBarcode(decodedText, override = 0) {
		let code = decodedText.trim();
		const myIsbnUtils = isbnUtils.parse(code);
		let isValid = false;
		if (myIsbnUtils) {
			if (myIsbnUtils.isIsbn10() || myIsbnUtils.isIsbn13()) {
				isValid = true;
			}
		}
		if (isValid) {
			let url = '//www.googleapis.com/books/v1/volumes?q=isbn:' + code;
			this.coms.log(url);
			this.datas.get(url).subscribe((res: any) => {
				if (res.items) {
					this.parseBookInfo(res, code);
				} else {
					let msg = this.trans.instant('common.not-valid-isbn', { isbn: code });
					this.als.okAlert(msg);
				}
			});
		} else {
			let msg = this.trans.instant('common.not-valid-isbn', { isbn: code });
			this.als.okAlert(msg);
		}
	}

	checkbookRefId(decodedText) {
		this.datas.post('ROLibrary.getBookRef', [decodedText]).subscribe((res: any) => {
			if (res.book) {
				this.book = res.book;
				this.name = res.book.title;
				this.packSafeCover(res.book.cover);
			} else if (this.codePool == 'both') {
				this.checkBarcode(decodedText);
			} else {
				let msg = this.trans.instant('common.not-valid-isbn', { isbn: decodedText });
				this.als.okAlert(msg);
			}
		});
	}

	packSafeCover(cover) {
		if (cover) {
			if (cover.indexOf('//') > -1) {
				this.photo = this.sans.bypassSecurityTrustStyle("url('" + cover + "')");
			} else if (cover.indexOf('/') > -1) {
				this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/" + cover + "')");
			} else if (cover.indexOf('/') == -1 && cover != 'blank') {
				this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/library-for-reading-report/" + cover + "')");
			}
		}
	}

	fromLibrary() {
		this.selectLibraryModal = {
			book: this.book,
			close: (obj: any = null) => {
				if (obj) {
					this.book = obj;
					this.name = obj.title;
					if (obj.cover) {
						this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/library-for-reading-report/" + obj.cover + "')");
					}
				}
				this.selectLibraryModal = null;
			}
		}
	}

	fromWorkspace() {
		this.selectBookModal = {
			entries: {},
			courseBookHistory: [],
			singleOnly: true,
			close: (items: any) => {
				this.selectBookModal = null;
				if (items) {
					if (items[0].type == 'book') {
						this.load.add('get_entry_cover');
						this.datas.post('ROCourse.get_entry_cover', [items[0].id]).subscribe((res: any) => {
							this.book = items[0];
							this.name = this.book.title;
							this.book.cover = res.cover;
							this.photo = null;
							if (res.cover) {
								this.photo = this.sans.bypassSecurityTrustStyle("url('" + res.cover + "')");
							}
							this.load.remove('get_entry_cover');
						});
					} else {
						this.als.okAlert('course.pls-select-book-attach-to-report');
					}
				}
			}
		};
	}

	confirm() {
		const basicInfo = this.form.basicInfo;
		if(this.form.scoreSetting && this.form.scoreSetting.scoringItems && this.form.scoreSetting.scoringItems.length > 0){
			const scoringItems = this.form.scoreSetting.scoringItems;
			if(scoringItems.some(e=> !e.title || !e.score )){
				this.als.toastError();
				return;
			}
		}
		if(!basicInfo.tags || !basicInfo.subject || !basicInfo.topic || !basicInfo.gradeAndSkillDescription || !basicInfo.inProgressTime || basicInfo.tags.length < 1){
			this.als.toastError();
		}else{
			if(this.data.book_id){
				let bookEntry:any = {type: 'ai-oral'};
				bookEntry = this.copyFormToBookEntry(bookEntry);
				const structure = this.data.structure;
				structure.book.chapters[0].pages[0].components.forEach((comp,index)=>{
					if (this.form.scoreSetting.scoringItems.length > index){
						comp.name2 = this.form.scoreSetting.scoringItems[index].title;
						comp.score.full = this.form.scoreSetting.scoringItems[index].score;
					}
				});
				structure.book.chapters[0].pages[0].components = structure.book.chapters[0].pages[0].components.splice(0, this.form.scoreSetting.scoringItems.length);
				if (structure.book.chapters[0].pages[0].components.length < this.form.scoreSetting.scoringItems.length){
					let start = structure.book.chapters[0].pages[0].components.length;
					for(let i = start; i < this.form.scoreSetting.scoringItems.length; i++){
						let cloned = JSON.parse(JSON.stringify(structure.book.chapters[0].pages[0].components[0]));
						cloned.name2 = this.form.scoreSetting.scoringItems[i].title;
						cloned.score.full = this.form.scoreSetting.scoringItems[i].score;
						cloned.cid = GUID.create("OKD guid");
						structure.book.chapters[0].pages[0].components.push(cloned);
					}
				}
				structure.book.chapters[0].info = bookEntry;
				
				this.datas.post2({ data: { api: 'ROBookShelfNew.create_or_edit_ai_oral_practice', json: [this.form, this.datas.userInfo.uid, this.data.book_id, this.mediaUrl, this.data.book_share_id, this.startTime, this.endTime, structure] } }).then((res: any) => {
					this.data.close("edited");
					
				});
			}else{

				this.confirmCreateAIOral();
				// this.updateReportShare(this.data.bsid);
			}
		}
		
	}

	cancelClick($event) {
		$event.stopPropagation();
		if (this.data.close) {
			this.data.close();
		}
	}

	bookAssignedChange($event) {
		this.triedSubmitted = false;
		this.bookAssigned = $event;
	}

	parseBookInfo(res, isbn) {
		let title = this.trans.instant('course.unknown-book-title');
		let cover = null;
		if (res.items) {
			let item = res.items[0];
			if (item.volumeInfo) {
				title = item.volumeInfo.title;
				if (item.volumeInfo.imageLinks && item.volumeInfo.imageLinks.thumbnail) {
					cover = item.volumeInfo.imageLinks.thumbnail;
					this.photo = this.sans.bypassSecurityTrustStyle("url('" + cover + "')");
				}
			}
			this.book = { isbn: isbn, title: title, cover: cover };
		}
		this.name = title;
	}

	bookTypeChange() {
		this.triedSubmitted = false;
		this.book = null;
		this.photo = null;
	}

	uploadFileChange($event) {
		this.triedSubmitted = false;
		this.file = $event;
		this.name = this.file.originFilename;
	}

	get tags_title(){
		if (this.form.basicInfo.tags.length > 0) {
			return this.form.basicInfo.tags.map(t => t.title).join(', ');
		} 
		return this.trans.instant('commonService.please-select');
	}

	get subject_title(){
		if (this.form.basicInfo.subject.length > 0) {
			return this.form.basicInfo.subject.map(subject => subject.title).join(', ');
		} 
		return this.trans.instant('commonService.please-select');
	}

	// initTargets() {
	// 	if (this.data.data && this.data.data.tagId){
	// 		this.targets = [{tType: 'tag', title: this.data.data.tag_name, value: this.data.data.tagId}];
	// 	}
	// 	if (this.targets.length > 0) {
	// 		const targetTitles = this.targets.map(t => t.title);
	// 		this.tags_title = targetTitles.join(', ');
	// 	} else {
	// 		this.targets = [];
	// 		this.tags_title = this.trans.instant('commonService.please-select');
	// 	}
	// }

	initOptions() {
		if (this.data.bsid){
			this.star = this.data.data.star || 0;
			this.startTime = this.data.data.startTime;
			this.endTime = this.data.data.endTime;
		} else {
			this.startTime = moment();
		}

		this.form.scoreSetting.passLevel = this.setting.schoolSettings['PASSING_RATE']?this.setting.schoolSettings['PASSING_RATE']:60;
		this.passLevelOptions = [10,20,30,40,50,60,70,80,90,100].map(e=>{
			let label = `>=${e}%`;
			if (e == this.setting.schoolSettings['PASSING_RATE']){
				label = label + {tc:' (預設)',sc:' (预设)', en:' (Default)'}[this.datas.lang];
			}
			return { label: label, value: e};
		});
		this.passLevelOptions.unshift({label: {tc:'沒有', sc:'没有', en: 'None'}[this.datas.lang], value: 0});
		this.updateTotalScore();
	}

	targetSelectorOpen($event) {	
		if (this.data.book_id){
			return;
		}	
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
			this.targetSelecting = false;
		} else {
			// this.targetSelecting = true;
			setTimeout(()=>{
				this.selectorPromise = this.targetSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.targetSelecting = false;
					// this.initTargets();
				});
			},250);
		}
	}

	public updateSelectedVoice(){
		if(this.selectedLanguageVoiceOptions){
			switch(this.form.conversationSetting.language){
				case "zh-HK":
					this.form.conversationSetting.voice = "zh-HK-HiuGaaiNeural";
					break;
				case "zh-CN":
					this.form.conversationSetting.voice = "zh-CN-XiaoxiaoNeural";
					break;
				case "en-GB":
					this.form.conversationSetting.voice = "en-GB-LibbyNeural";
					break;
				case "en-US":
					this.form.conversationSetting.voice = "en-US-AriaNeural";
					break;
			}
		}
	}

	dateTimeClick(el, dateType) {
		const dateTimePicker = this.dateTimePicker;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		let	update$ = dateTimePicker.update.subscribe(dt => {
			if (dt.startDateTime) {
				this.startTime = moment(dt.startDateTime);
			} else {
				this.startTime = null;
			}
			if (dt.endDateTime) {
				this.endTime = moment(dt.endDateTime);
			} else {
				this.endTime = null;
			}
			this.wholeDay = dt.wholeDay ? 1 : 0;
		});
		if (this.startTime) {
			const last8Dight = this.startTime.format('HH:mm:ss');
			wholeDay = last8Dight == '00:00:00' ? 1 : 0;
			startTime = this.startTime.toDate();
		}
		if (this.endTime) {
			const last8Dight2 = this.endTime.format('HH:mm:ss');
			wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
			endTime = this.endTime.toDate();
		}
		dateTimePicker.open(el, startTime, endTime, wholeDay).then(data => {
			update$.unsubscribe();
		});
	}

	async confirmCreateAIOral(){
		const individualUids = this.form.basicInfo.tags.filter(t => t.tType == 'people').map(e=> e.value);
		const targets = this.form.basicInfo.tags.filter(t => t.tType == 'tag');
		if (individualUids.length > 0) {
			const schoolId = this.datas.userInfo.school_id;
			const title = 'cid-share-' + moment().format('YYYYMMDD-HHmmss');
			const tagType = 13;
			const currentYear = this.tg.defaultSchoolYear;
			const yearId = currentYear?currentYear.id:'0';
			const  json: any[] = [schoolId, yearId, schoolId, title, tagType, 'main', individualUids];
			let res: any = await this.datas.post('ROCourse.create_virtual_tag_for_group_share', json).toPromise();
			targets.push({tType: 'tag', value: res.tid, title: title});
		}
		const name = this.form.basicInfo.topic && this.form.basicInfo.topic != ''?this.form.basicInfo.topic:'AI會話訓練'; 
		let bookEntry:any = {type: 'ai-oral'};
		bookEntry = this.copyFormToBookEntry(bookEntry);
		
		this.datas.post('ROBookshelfNew.addFakeBook', [bookEntry]).subscribe((res:any)=>{
			
			bookEntry.entry_id = res.item.book_id;
			const startTime = this.startTime?moment(this.startTime,'YYYY-MM-DD HH:mm:ss').toISOString():null;
			const endTime = this.endTime?moment(this.endTime,'YYYY-MM-DD HH:mm:ss').toISOString():null;
			let bookEntryForShare = JSON.parse(JSON.stringify(bookEntry));
			bookEntryForShare.entry_id = res.item.book_id;
			const options = {type: 'custom', star: this.star, start_time: startTime, end_time: endTime };
			const targetValues = targets.map(e=>e.value);
			const obj = { entries: [{ id: bookEntryForShare.entry_id, type: 'ai-oral', title: name }], tags: targetValues, options: options };
			this.createCreate(obj, bookEntry, bookEntryForShare);
			this.createAIOralPractice(this.form, res.item.book_id, this.mediaUrl);
		});
	}

	private copyFormToBookEntry(bookEntry){
		bookEntry.name = this.form.basicInfo.topic && this.form.basicInfo.topic != ''?this.form.basicInfo.topic:'AI會話訓練'; 
		// Copy properties from basicInfo
		bookEntry.tags = this.form.basicInfo.tags;
		bookEntry.gradeAndSkillDescription = this.form.basicInfo.gradeAndSkillDescription;
		bookEntry.subject = this.form.basicInfo.subject;
		bookEntry.inProgressTime = this.form.basicInfo.inProgressTime;
		bookEntry.topic = this.form.basicInfo.topic;
		bookEntry.description = this.form.basicInfo.description;
		
		// Copy properties from conversationSetting
		bookEntry.inputMode = this.form.conversationSetting.inputMode;
		bookEntry.language = this.form.conversationSetting.language;
		bookEntry.voice = this.form.conversationSetting.voice;
		bookEntry.speed = this.form.conversationSetting.speed;
		bookEntry.display = this.form.conversationSetting.display;
		bookEntry.response = {
		  on: this.form.conversationSetting.response.on,
		  sentences: this.form.conversationSetting.response.sentences,
		  words: this.form.conversationSetting.response.words
		};
		bookEntry.hints = {
		  on: this.form.conversationSetting.hints.on,
		  sentences: this.form.conversationSetting.hints.sentences,
		  times: this.form.conversationSetting.hints.times
		};
		
		// Copy properties from scoreSetting
		bookEntry.aiFinalComment = {
		  on: this.form.scoreSetting.aiFinalComment.on,
		  words: this.form.scoreSetting.aiFinalComment.words
		};
		bookEntry.finalScoring = this.form.scoreSetting.finalScoring;
		bookEntry.scoringScale = this.form.scoreSetting.scoringScale;
		bookEntry.passLevel = this.form.scoreSetting.passLevel;
		bookEntry.scoringItems = this.form.scoreSetting.scoringItems;
		return bookEntry;
	}

	formatXML(bookEntry){
		let obj = {Doc: {Chapter: {Page: { bookEntry }}}};
		const xml = toXML(obj);
		return xml;
	}

	private async createAIOralPractice(form, bookId, mediaUrl){
		this.datas.post2({ data: { api: 'ROBookShelfNew.create_or_edit_ai_oral_practice', json: [form, this.datas.userInfo.uid, bookId, mediaUrl, this.data.book_share_id] } }).then((res: any) => {
		});
	} 

	createCreate(obj, bookEntry, bookEntryForShare) {
		this.datas.post2({ data: { api: 'ROBookShare.create_multi_share', json: [obj] } }).then((res: any) => {
			if (this.data.close){
				this.data.close(res.shares[0]);
			}

			if (res.share && res.share.id){
				const xml = this.formatXML(bookEntry);
				this.datas.post('ROBook.add_book_from_xml', [bookEntryForShare.entry_id, this.name, xml]).subscribe((res:any)=>{
					console.log("add_book_from_xml_res", res);
				});
			}
			this.datas.post2({ data: { api: 'ROBookShelfNew.update_oral_share_time', json: [res.share.id, this.startTime, this.endTime] } });
			this.datas.post2({ data: { api: 'ROBookShare.insert_book_share_subject_array', json: [res.share.id, this.form.basicInfo.subject] } });
		});
	}

	updateReportShare(bsid){
		const start_time = this.startTime?moment(this.startTime,"YYYY-MM-DD HH:mm:ss").toDate():null;
		const end_time = this.endTime?moment(this.endTime,"YYYY-MM-DD HH:mm:ss").toDate():null;
		const options = {entry_type: 'report', star: this.star, start_time: start_time, end_time: end_time };
		this.load.add('update_report_share', 5000);
		this.datas.post('ROBookshelfNew.update_other_share', [bsid, options]).subscribe((res:any)=>{
			this.load.removeAll();
			this.data.close(res.share);
		});
	}

	showInfo(field){
		const lang = this.datas.lang;
		if (field == 'ability-info'){
			return {tc:'內容可因應學生年級或學習能力水平而生成。例：一年級, 高能力',sc:'内容可因应学生年级或学习能力水平而生成。例：一年级, 高能力',en:'Content can be generated based on the grade or learning ability of students. e.g: Primary 1, High learning ability'}[lang];
		} else {
			return {tc:"1. 總分 = 每項評分準則的分數總和<br/>2. 如日後修改準則，已評的分數將清空，並需重新進行評分<br/>3. 可輸入指引欄以指示AI如何評分",sc:"1. 总分 = 每项评分准则的分数总和<br/>2. 如日后修改准则，已评的分数将清空，并需重新进行评分<br/>3. 可输入指引栏以指示AI如何评分",en:"1.Total Score = Sum of scores for each evaluation criterion.<br/>2. In case of future modifications to the criteria, previously scored scores will be cleared, and a new evaluation will be required.<br/>3. Enter guideline to instruct the AI on how to score."}[lang];
		}
	}

	addScoringItem(){
		let item = {title: '', guideline: '', score: 0};
		this.form.scoreSetting.scoringItems.push(item);
		this.updateTotalScore();
	}

	removeScoringItem(index){
		if (this.form.scoreSetting.scoringItems.length == 1){
			const msg = {tc:'最少需要一個評分準則',sc:'最少需要一个评分准则',en:'At least one evaluation criterion is required'}[this.datas.lang];
			this.als.toastError(msg);
			return;
		}
		this.form.scoreSetting.scoringItems.splice(index,1);
		this.updateTotalScore();
	}

	updateTotalScore(){
		this.totalScore = 0;
		this.form.scoreSetting.scoringItems.forEach(item=>{
			this.totalScore += +item.score;
		});
	}

	updateScoringItem(value, index, field){
		setTimeout(()=>{
			if (field == 'title'){
				this.form.scoreSetting.scoringItems[index].title = value;
			} else if(field == 'guidance'){
				this.form.scoreSetting.scoringItems[index].guidance = value;
			} else {
				this.form.scoreSetting.scoringItems[index].score = +value;
				this.updateTotalScore();
			}
		}, 10);
	}

}
