export class EPenDataType
{
	static DATA_TYPE_EPEN:number = 1;
	static DATA_TYPE_HIGHLIGHT:number = 2;
	static DATA_TYPE_DEL:number = 3;
	static DATA_TYPE_CLEAR:number = 4;
	static DATA_TYPE_UNDO:number = 5;
	static DATA_TYPE_REDO:number = 6;
	static DATA_TYPE_MARKING:number = 7;
	static DATA_TYPE_MOVE_OBJ:number = 8;
}