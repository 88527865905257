import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { SettingService } from 'src/app/service/setting.service';
import { ThemeService } from 'src/app/service/theme.service';
import { LoadingService } from '../loadingModule/loading.service';

@Component({
	selector: 'edit-phone',
	template: `
  <div class="edit-phone">
	<div class="bgLayer {{themeService.theme$.value}}-theme" [style.backgroundColor]="homePageThemeColor"></div>
    <div class="header">
      <div class="header__cancel" (click)="cancelClick()">{{"keiKokLibrary.cancel" | translate}}</div>
      <div class="header__title">{{ {tc: '變更手機號碼', sc:'變更手機號碼',en:'Change mobile number'}[lang] }}</div>
      <div class="header__confirm" [style.backgroundColor]="homePageThemeColor" (click)="confirm()">{{"keiKokLibrary.confirm" | translate}}</div>
    </div>
    <div class="black-line"></div>
    <div class="topic" [style.color]="homePageThemeColor">
      <span>{{ {tc: '用戶名稱', sc:'用戶名稱', en:'User name'}[lang]}}: {{displayName}}</span>
    </div>
    <div class="content">
      <div class="row old-phone" *ngIf="account.phone">
        <div class="label">{{ {tc: '目前手機號碼', sc:'目前手机号码',en:'Old mobile no.'}[lang] }}:</div>
        <div class="value">
          <input type="text" [(ngModel)]="oldPhone" [style.color]="homePageThemeColor" />
        </div>
      </div>
      <div class="row new-phone {{account.phone?'':'without-old-phone'}}">
        <div class="label">{{ {tc: '新手機號碼', sc:'新手机号码',en:'New mobile no.'}[lang] }}:</div>
        <div class="value">
          <input type="text" [(ngModel)]="newPhone" [style.color]="homePageThemeColor" maxlength="8" />
        </div>
      </div>
	  <div class="row verify">
        <div class="label">{{ {tc: '驗証碼', sc:'验证码',en:'Verify code'}[lang] }}:</div>
        <div class="value">
          <input type="text" [(ngModel)]="verifyCode" [style.color]="homePageThemeColor" maxlength="6" />
		  <div class="send-sms {{themeService.theme$.value}}-theme" [class.disabled]="countDown > 0" 
		  	[style.color]="homePageThemeColor" [style.borderColor]="homePageThemeColor" 
		 	(click)="sendSms()">
			<ng-container *ngIf="countDown == 0"> {{ {tc:'獲取驗証碼', sc: '获取验证码', en:'Get SMS code'}[lang] }}</ng-container> 
			<ng-container *ngIf="countDown > 0">{{ {tc: '重發',sc: '重發', en: 'Resend'}[lang] }} ({{countDown}})</ng-container> 
		  </div>
        </div>
      </div>
    </div>
    <div class="remark">
      <span *ngIf="lang == 'tc'">{{ '請註意，變更將於登入時透過SMS發送驗證碼至新手機號碼'}}</span>
      <span *ngIf="lang == 'sc'">{{ '请注意，变更将于登入时透过SMS发送验证码至新手机号码'}}</span>
      <span *ngIf="lang == 'en'">{{ 'SMS verification code sent to new mobile number during login.'}}</span>
    </div>
  </div>
  `,
	styleUrls: ['./edit-phone.modal.scss'],
})

export class EditPhoneModal implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('text', { static: true }) text;
	@Input() data:any = {};
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	public account;
	public oldPhone = '';
	public newPhone = '';
	public verifyCode = '';
	public error: string = '';
	public displayName;
	public homePageThemeColor = '#435CBE';
	public lang;
	public countDown = 0;
	public isResend = false;
	public codeFromServer = '';
	constructor(private router: Router, public datas: DataService, public setting: SettingService, public themeService:ThemeService, private eleRef: ElementRef, public als: AlertService, public lds: LoadingService, public coms: CommonService) {

	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.account = this.datas.userInfo;
		if (this.data.userInfo){
			this.account = this.data.userInfo;
		}
		if (this.datas.lang == 'en') {
			this.displayName = this.account.ename?this.account.ename: this.account.first_name + ' ' + this.account.last_name;
		} else {
			this.displayName = this.account.cname?this.account.cname: this.account.c_last_name + this.account.c_first_name;
		}
		if (this.setting.homeLayout){
			this.homePageThemeColor = this.setting.homeLayout.homePageThemeColor;
		}
		this.themeService.applyStyleObj({
			"default": {
			  "popup-bgcolor": "#F7F7F7",
			  "popup-linecolor":"var(--list-linecolor)",
			  "popupframe-headerbgcolor":"#fff",
			  "popupform-rowbgcolor":"#fff",
			  "popupform-panelbgcolor":"#f5f5f5",
			  "popupform-textcolor": "#333333",
			}, "dark": {
			  "popup-bgcolor": "#2D2C43",
			  "popupframe-headerbgcolor":"#1e1e29",
			  "popupform-rowbgcolor":"#1e1e29",
			  "popupform-panelbgcolor":"#38374f",
			  "popupform-textcolor": "#FFFFFF",
			}
		}, this.eleRef.nativeElement);
	}

	ngAfterViewInit() {

	}

	ngOnDestroy(){
		this.countDown = 0;
	}

	confirm() {
		if (this.newPhone.length != 8 || isNaN(Number(this.newPhone) ) ) {
			const msg = {tc:'請輸入8位電話號碼', sc:'请输入8位电话号码', en:'Please input 8 digit phone number.'}[this.lang];
			this.als.toastError(msg);
			return;
		}
		if (this.codeFromServer != this.verifyCode){
			const msg = {tc:'驗証碼不正確', sc:'验证码不正确', en:'Verify code is invalid.'}[this.lang];
			this.als.toastError(msg);
			return;
		}
		this.lds.add('ROHomeScreen.update_phone',5000);
		this.datas.post('ROHomeScreen.update_phone', [this.newPhone, this.account.uid]).subscribe((res:any)=>{
			this.lds.remove('ROHomeScreen.update_phone');
			if (res.code == 1){
				const msg = {tc:'新的手機號碼已生效', sc:'新的手机号码已生效', en:'Phone was updated.'}[this.lang];
				this.als.toastSuccess(msg);
			} else {
				const msg = {tc:'更新失敗', sc:'更新失败', en:'data error.'}[this.lang];
				this.als.toastError(msg);
			}
			this.close.emit({ newPhone: this.newPhone });
		});
	}

	cancelClick() {
		this.close.emit(null);
	}

	sendSms(){
		if (this.countDown){
			return;
		}
		if (this.newPhone.length != 8){
			const msg = {tc:'請輸入8位電話號碼', sc:'请输入8位电话号码', en:'Please input 8 digit phone number.'}[this.lang];
			this.als.toastError(msg);
			return;
		}
		let countDownFn = ()=>{
			setTimeout(()=>{ 
				if (this.countDown){
					this.countDown = this.countDown - 1;
					countDownFn();
				}
			}, 1000);
		};
		this.countDown = 60;
		countDownFn();
		const phoneInput = this.datas.userInfo?'':this.newPhone;
		this.datas.post('ROHomeScreen.sendVerifySms', [ this.account.uid, 'xxxxxx', this.datas.appId2, this.datas.lang, phoneInput ]).subscribe((res:any)=>{
			this.codeFromServer = '' + res.code;
			if (this.isResend){
				const msg = {tc: '已重發短訊驗証碼。', sc:'已重发短讯验证码。', en: 'SMS verification code has been re-sent.'}[this.datas.lang];
				this.als.toastSuccess(msg);
			}
			this.isResend = true;
		});
	}

}
