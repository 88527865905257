import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { OkaPulldown2Component } from '../okaPulldownModule/okaPulldown2.component';
@Component({
	selector: "okaPulldown2-editor",
	template: `
	<ng-container *ngIf="selectedItem">
		<okaPulldown2 #pd [options]="items" 
			[okaPulldownStyle]="okaPulldownStyle" [menuStyle]="okaPulldownMenuStyle"
			bindLabel="label" bindValue="value"
			[(ngModel)]="this.selectedItem" (ngModelChange)="pulldownChange($event)"></okaPulldown2> 
	</ng-container>
	
    `,
	styleUrls: ['./okaPulldown2.editor.scss']
})


export class OkaPulldown2Editor implements AfterViewInit {
	public _pd:OkaPulldown2Component;
	@ViewChild('pd', {static:false}) set pd(content: OkaPulldown2Component) {
		if(content) {
			this._pd = content;
			this._pd.toggleMenu();
		}
	}

	public params: any;
	public text = '';
	public items:any[] = [];
	public selectedItem;
	public okaPulldownStyle:any = {};
	public okaPulldownMenuStyle:any = {};
	ngAfterViewInit() {

	}

	agInit(params: ICellEditorParams): void {
		this.params = params;
		if (this.params.okaPulldownStyle){
			this.okaPulldownStyle = this.params.okaPulldownStyle;
		}
		if(this.okaPulldownStyle && !this.okaPulldownStyle.hasOwnProperty("width")) {
			this.okaPulldownStyle.width = params.eGridCell.clientWidth+"px";
		}

		this.items = this.params.items;
		if(this.params.output)
			this.selectedItem = this.params.output;
		else
			this.selectedItem = this.params.value;// ag grid default value name
		this.disableTransform();
	}
	

	refresh(params: ICellEditorParams){
		let value = this.params.output;
		this.selectedItem = this.items.find(e=> e.value == value);
		return true;
	}

	isPopup(): boolean {
        return true;
    }

	pulldownChange(event){
		let selectedItem = this.items.find(e=>e.value == event);
		if (selectedItem.callback){
			selectedItem.callback(this.params.data);
		}
		this.params.api.stopEditing();
	}

	disableTransform(){
		let ele = this.params.eGridCell.closest('.ag-center-cols-clipper');
		ele.style.transform = 'none';
	}

	getValue(): any {
		return this.selectedItem;
	}
}
