import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
/////////	
@Component({
	selector:"QText",
	template:`
	<ng-container *ngIf="qObj">
		<ng-container *ngIf="state.inEdit">
			<div class="edit_qtxt">
				<!--<div><input type="text" [(ngModel)]="qObj.question" (input)="qTextChg($event)"></div>-->
				<textarea [(ngModel)]="qObj.question" (input)="qTextChg($event)"></textarea>
				<fa-icon class="addAsset" [icon]="faPaperclip" (click)="addAsset($event, 'asset')"></fa-icon>
			</div>
		</ng-container>
		<div *ngIf="!state.inEdit" class="qtxt" [innerHTML]="getQuestionText()"></div>
		<div>
			<QMediaAssets
			[files]="qObj.asset.files"
			></QMediaAssets>
		</div>
	</ng-container>
	`
})

export class QText
{
	public faPaperclip:any = null;
	@Input() qObj:any = null;
	@Input() state:any = {inEdit:false};
	
	constructor(elementRef:ElementRef)
	{
		
	}
	
	getQuestionText()
	{
		var output:any = this.qObj ? this.qObj.question.replaceAll("\n","<br/>") : "";
		return this.continuesSpaceConvert(output);
	}
	public continuesSpaceConvert(str:string):string {
		var ary:string[] = str.split("");
		ary.forEach((t,i)=> {
			if(t == " ") {
				if(i+1<ary.length && ary[i+1]==" ")
					ary[i] = "&nbsp;";
			}
		});
		return ary.join("");
	}

	qTextChg(event)
	{
		
	}
	
	
	addAsset(event, type)
	{
		
	}

}
