import { EventEmitter, Injectable } from '@angular/core';
import {
	AsyncSubject,
	fromEvent,
	merge,
	Observable,
	Subject,
	Subscription,
	timer
} from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserIdleService {
	busyObservable: Observable<Event>;
	subscription: Subscription;
	// public expired:Subject<boolean> = new Subject<boolean>();
	public idle:Observable<any>;
	// private timer: Subscription = new Subscription();
	// private timeOutMilliSeconds: number = 1000;
	// private idleSubscription: Subscription = new Subscription();
	private onHoldSubject:AsyncSubject<any>;
	public timeout:number = 5000;
	constructor() {
		this.init();
	}
	private init() {
		this.onHoldSubject = new AsyncSubject();

		var foo = new Observable(function(observer) {
			console.log('Hello');
			observer.next(42);
		});

		this.busyObservable = merge(
			this.createSimpleObservable("start"),
			fromEvent(document, 'keydown'),
			fromEvent(document, 'click'),
			fromEvent(document, 'mousedown'),
			fromEvent(document, 'keypress'),
			fromEvent(document, 'DOMMouseScroll'),
			// fromEvent(document, 'mousewheel'),
			// fromEvent(document, 'touchmove'),
			// fromEvent(document, 'MSPointerMove'),
			// fromEvent(document, 'mousemove'),
			// fromEvent(window, 'mousemove'),
			fromEvent(window, 'resize'),
			fromEvent(window, 'resize'),
			this.onHoldSubject
		);
		this.idle = this.busyObservable.pipe(debounceTime(this.timeout), filter((o)=>{
			return o ? true : false;
		}));
	}

	private createSimpleObservable(value:any):Observable<any>
	{
		return new Observable((observer) => {
			observer.next(value);
			observer.complete();
		});
	}
	
	wait():Observable<any>
	{
		return this.idle.pipe(first());
	}

	singleWait():Promise<any>
	{
		return new Promise((resolve, reject)=>{
			this.wait().subscribe((o:any)=>{
				resolve(null);
			})
		});
	}
	onHold():void{
		// skip last event
		this.onHoldSubject.next(null);
	}
}

