import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import {  ROComponent } from "./ROComponent";

@Component({
	template:
		`鐘`,
		styles:[
			`
			
			`
		]

	
})
export class ROWatch extends ROComponent
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	
	public getTagNames():string []
	{
		return ["Watch"];
	}

	protected buildContent(): void {
		// <!--{"ver":1.3,"urlLink":"https://www.youtube.com/watch?v=JHregeIsjPQ","vidID":"JHregeIsjPQ","douid":"0CB94186-B986-313A-5B6C-47869D782B55","coordinateExpression":"UA UK KS X 44 Y 35 D T 35 L 44 H 209.80906143204624 W 419.6181228640925","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"locked":false}-->
		/**
		{
		    "ver": 1,
		    "liveDragging": true,
		    "skin": 1,
		    "secondpin": false,
		    "minutepin": true,
		    "hourpin": true,
		    "nummark": true,
		    "minmark": false,
		    "secondpinColor": "#d53a2c",
		    "minutepinColor": "#ac0061",
		    "hourpinColor": "#3f94cb",
		    "nummarkColor": "#000000",
		    "markColor": "#000000",
		    "minmarkColor": "#000000",
		    "coordinateExpression": "UA UK KS KP X 299.4 Y 87.25 D T 87.25 L 299.4 H 342.99079979648445 W 342.99079979648445",
		    "changeable": false,
		    "time": 1519934400538,
		    "hasScoring": false,
		    "locked": false,
		    "q": "{\"freezed\":0,\"y\":-156,\"color\":\"noColor\",\"level\":0,\"ballSize\":36,\"show\":false,\"x\":-12}",
		    "s": "{\"x\":342.95,\"y\":0,\"enable\":1,\"show\":1,\"freezed\":1}",
		    "questionIndex": 0,
		    "qInfo": "{\"root\":0,\"index\":0,\"id\":1,\"level\":0,\"pid\":0,\"rootIndex\":0,\"order\":0}",
		    "viewMode": "view",
		    "douid": "EA551EDE-4F0F-B04B-D3DB-D8D512D40EC9"
		}
		 */
		
	}
}


@Component({
	template:
		`數位鐘`,
		styles:[
			`
			`
		]

	
})
export class RODigitalClock extends ROComponent
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["DigitalClock"];
	}

	protected buildContent(): void {
		/*
			<!--{"ver":1,"douid":"E50F97F4-9CDA-C20E-FBA8-4CBE3628DBCC","coordinateExpression":"UA UK KH X 60 Y 435 D T 435 L 60 H 146 W 411.0442819148937","hasScoring":false,"viewMode":"view","context":"{\"24\":0,\"fillColor\":0,\"font\":\"digit\",\"time\":219726000,\"textColor\":16777215,\"frameColor\":4702960,\"changable\":false,\"showSecond\":true,\"bgType\":\"clock\"}","q":"{\"level\":0,\"y\":0,\"show\":1,\"ballSize\":36,\"freezed\":1,\"color\":\"noColor\",\"x\":-60}","s":"{\"y\":0,\"optional\":false,\"offset\":null,\"reference\":\"rt\",\"freezed\":1,\"enable\":1,\"show\":1,\"x\":411}","locked":false,"questionIndex":1,"qInfo":"{\"order\":1,\"level\":0,\"root\":1,\"rootIndex\":1,\"pid\":0,\"index\":1,\"id\":2}"}-->
		*/
		
	}
}


