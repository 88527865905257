import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import {  ROComponent, ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";

@Component({
	template:
		`<div class="btn"
			(tap)="onTap()"
			></div>
		`,
		styles:[
			`
			.btn{
				width:100%;
				height:100%;
			}
			`
		]
	/**
		{
			"ver":1,
			"actionType":"sfx",
			"actionOnPress":true,
			"actionData":"sound/PTH/phonetics/consonants/j.mp3",
			"coordinateExpression":"UA UK X 226 Y 234 D T 234 L 226 H 61.4 W 55.2",
			"locked":false,"longTouchByPassAction":false,
			"playMode":0,
			"douid":"735913CE-92AD-FC8F-93EC-DC9555A3064E",
			"ttsSpeechRate":0,"ttsPitch":1
		}
	*/
	
})
export class ROButton extends ROComponent
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	onTap()
	{
		alert("onTap");
	}
	
	public getTagNames():string []
	{
		return ["Button"];
	}

	protected initVariables():void
	{
		super.initVariables();
	}
}


@Component({
	template:
		`<div class="btn"
			(tap)="onTap()"
			></div>
		`,
		styles:[
			`
			.btn{
				width:100%;
				height:100%;
			}
			`
		]

	
})
export class ROButton2 extends ROComponent
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["Button2"];
	}

	onTap()
	{
		var options:any = this.node.attributes;
		if(options.pageID2)
		{
			this.context.subject.next({
				type:"action", 
				action:"button",
				data:{
					type:"page",
					page:options.pageID2
				}
			});
		} else if(options.pageID)
		{

			this.context.subject.next({
				type:"action", 
				action:"button",
				data:{
					type:"page",
					page:options.pageID
				}
			});
		}

	}
	
	protected buildContent():void
	{
		/*
		{
			"ver":1.5,"isVisible":false,
			"borderWidth":1,"fontColor":"#ffffff","buttonColor":"#b2cd42",
			"coordinateExpression":"UA UK X 600.1 Y 286 D T 286 L 600.1 H 57.12007591664445 W 227.0803335787668",
			"fontSize":19,"font":"Noto Sans T Chinese Regular","hasIcon":false,
			"locked":false,"src":"","pageID":"2DD01256-8489-7745-B97A-D3C5BCD16CA1","docIndex":0,
			"pageID2":"BDF652E6-032F-2B98-7F60-A1DA3EECF2BC|2DD01256-8489-7745-B97A-D3C5BCD16CA1",
			"multi":0,"douid":"5C009F11-8B86-A74C-2530-C26A54DD8FCE",
			"x":600.1,"y":286,"w":227.0803335787668,"h":57.12007591664445}
		*/
		/*
		this.node.children.forEach((childNode:XMLNode)=>{
			console.log(childNode);
		});
		*/
	}
}