import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertModule } from '../alertModule/alert.module';
import { CodeScanModule } from '../codeScanModule/codeScan.module';
import { LoadingModule } from '../loadingModule/loading.module';
import { UploadModule } from '../uploadModule/upload.module';
import { SliderModule } from 'primeng/slider';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { StudentReadReportModal } from './student-read-report.modal';
import { MmssPipe } from './mmss.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { SelectLibraryModal } from './selectLibrary.modal';
import { CustomAgGridModule } from '../customAgGridModule/customAgGrid.module';
import { InlineSvgModule } from '../inlineSvgModule/inlineSvg.module';
import { AudioModule } from '../audioModule/audio.module';
import { RODocumentService } from '../roModule/RODocumentService';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InstallAppModalModule } from '../installAppModalModule/installAppModal.module';
import { PreviewModule } from '../previewModule/preview.module';


@NgModule({
    imports: [
      CommonModule, AlertModule, LoadingModule, SliderModule, OkaPulldownModule, 
      FontAwesomeModule, UploadModule, CodeScanModule, TranslateModule, FormsModule,
	  AgGridModule, CustomAgGridModule, InlineSvgModule, AudioModule, AutoCompleteModule,
	  InstallAppModalModule, PreviewModule
    ],
    declarations: [
		StudentReadReportModal, MmssPipe, SelectLibraryModal
    ],
    exports: [
		StudentReadReportModal, SelectLibraryModal
    ],
    providers: [RODocumentService],
    bootstrap: []
})
export class StudentReadingReportModalModule { }
