import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'pie-chart',
	template: `
<div class="pie-chart" [class.is-half-half]="isHalfHalf">
	<ng-container *ngIf="pieData.length > 0">
		<div *ngFor="let pie of pieData" class="pie {{theme.theme$.value}}-theme {{pie.elClass?pie.elClass:''}}" [style.backgroundColor]="(pie.arc == 360?pie.color:'')" [ngStyle]="(pie.arc > 179?bigPieStyle:pieStyle)" [attr.start]="pie.start" [attr.value]="pie.arc">
			<div class="before {{theme.theme$.value}}-theme" [style.backgroundColor]="pie.color" [ngStyle]="(pie.arc > 179?bigBeforeStyle:beforeStyle)"></div>
			<div class="after arc-more-179 {{theme.theme$.value}}-theme" *ngIf="pie.arc > 179" [style.backgroundColor]="pie.color" [ngStyle]="bigAfterStyle"></div>
		</div>
  </ng-container>
  <div class="blank {{theme.theme$.value}}-theme" 
  	[style.width.px]="size" [style.height.px]="size" 
	[style.backgroundColor]="(options.blankColor?options.blankColor:'#ccc')"
	*ngIf="options && pieData.length == 0"></div>
</div>
  `,
	styleUrls: ['./pieChart.component.scss']
})
export class PieChartComponent implements OnInit, OnChanges {
	@Input() data = [];
	@Input() size = 40;
	@Input() options: any = {};
	public pieData = [];
	public pieStyle;
	public bigPieStyle;
	public beforeStyle;
	public bigBeforeStyle;
	public bigAfterStyle;
	public isHalfHalf = false;
	constructor(public sans: DomSanitizer, public theme: ThemeService) {

	}

	ngOnInit() {
	}

	ngOnChanges(changes) {
		this.initStyle();
		this.initData();
	}

	initData() {
		let total = 0;
		this.data.forEach(d => {
			total += d.value;
			if (d.color && d.color.indexOf('var') > -1){
				d.safeStyleColor = this.sans.bypassSecurityTrustStyle(d.color);
			} else {
				d.safeStyleColor = d.color;
			}
		});
		let start = 0;
		this.pieData = this.data.map(d => {
			let arc = Math.round((d.value / total) * 360);
			let myStart = start;
			start += arc;
			let elClass = d.elClass ? d.elClass : '';
			elClass = arc > 179 ? elClass + ' big' : elClass;
			return { elClass: elClass, start: myStart, arc: arc, color: d.color };
		});
		if (this.pieData.find(e=>isNaN(e.arc))){
			this.pieData = [];
		}
		const hasValuePie = this.data.filter(e=> e.value > 0);
		if (hasValuePie.length == 2 && hasValuePie[0].value == hasValuePie[1].value){
			this.isHalfHalf = true;
		}
	}

	initStyle() {
		let sizePx = this.size + 'px';
		let halfSizePx = (this.size / 2) + 'px';
		let beforeRadius = halfSizePx + ' 0 0 ' + halfSizePx;
		let afterRadius = '0 ' + halfSizePx + ' ' + halfSizePx + ' 0';
		this.pieStyle = { width: halfSizePx, height: sizePx, left: halfSizePx };
		this.bigPieStyle = { width: sizePx, height: sizePx };
		this.beforeStyle = { width: halfSizePx, height: sizePx, borderRadius: beforeRadius, left: '-' + halfSizePx };
		this.bigBeforeStyle = { width: halfSizePx, height: sizePx, borderRadius: beforeRadius };
		this.bigAfterStyle = { width: halfSizePx, height: sizePx, borderRadius: afterRadius, left: halfSizePx };
	}

}
