import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import * as moment from 'moment';
import { TagGroupService } from 'src/app/sharedModule/tagGroupModule/tagGroup.service';
import { UploadService } from '../uploadModule/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { faImage, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ThemeService } from 'src/app/service/theme.service';
import { SettingService } from 'src/app/service/setting.service';
import { toXML } from 'jstoxml';
import { faTrash, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { TeachingPointSelectorComponent } from '../scoreEditorPopupModule/TeachingPointSelector.component';
import { DateTimePicker3Component } from '../dateTimePicker3Module/dateTimePicker3.component';
import { GUID } from 'src/app/class/hk/openknowledge/encryption/GUID';

@Component({
	selector: 'create-aic-modal',
	template: `
	<modal2
		#modal class="{{targetSelecting?'target-selecting':''}}"
		[title]="modalTitle" [contentStyle]="modalContentStyle"
		(confirmClick)="confirm()" [confirmLabel]="confirmLabel"
		(cancelClick)="closeClick()"
		>
		<div class="section-body" *ngIf="ready">
			<div class="section-left">
				<div class="topic-row">
					<div class="topic-row-label" containRequired>{{'bookshelf.basic-info'|translate}}</div>
				</div>
				<div class="box__row first-row">
					<div class="box__row__label mark-required">{{ {tc:'寫作標題',sc: '写作标题',en: 'Writing topic'}[lang] }}</div>
					<div class="box__row__data">
						<input #nameRef type="text" [(ngModel)]="name" required />
					</div>
				</div>

				<div class="box__row description">
					<div class="box__row__label">{{ {tc:'寫作指引',sc: '写作指引',en: 'Writing guideline'}[lang] }}:</div>
					<div class="box__row__data">
						<textarea [(ngModel)]="description"></textarea>
					</div>
				</div>

				<div class="box__row tag">
					<div class="box__row__label mark-required">{{'workspace.tag'|translate}}</div>
					<div class="box__row__data" [class.disabled]="data.share && data.share.id">
						<div class="tag-title" (click)="targetSelectorOpen($event)">{{tagsTitle}}</div>
						<targetSelector #targetSelector [allowForAll]="false"
							[showLoadingWhenFetchData]="false"
							memberRole="student" [(ngModel)]="targets" 
							></targetSelector>
					</div>
				</div>
			
				<div class="box__row ability-info">
					<div class="box__row__label mark-required">
						{{'course.aic-description'|translate}}
						<div class="info-ball-wrapper">
							<additionRemarkBall 
								[bubbleText]="showInfo('ability-info')">
								</additionRemarkBall>
						</div>
					</div>
					<div class="box__row__data">
						<input type="text" [(ngModel)]="abilityInfo" required />
					</div>
				</div>

				<div class="box__row subject-row">
					<div class="box__row__label mark-required">{{'course.subject'|translate}}</div>
					<div class="box__row__data">
						<div class="subject-title" (click)="subjectSelectorOpen($event)">{{subjectsTitle}}</div>
						<subjectSelector3 #subjectSelector [(ngModel)]="subjects"
							leftBgColor="#2c97d7"
							></subjectSelector3>
					</div>
				</div>

				<div class="box__row last-row">
					<div class="box__row__label mark-required">{{'workspace.in-progress-time'|translate}}</div>
					<div class="box__row__data">
						<div class="date-title" #inProgressTimeRef (click)="inProgressTimeClick()">{{ inProgressTimeText }} </div>
						<dateTimePicker3 #dateTimePicker left [skipLoadApi]="true" [showWholeDayOption]="false"
							[lang]="datas.lang" [noDateBtn]="false" [clearBtn]="true" [showCustomMinutesOption]="true"
							></dateTimePicker3>
					</div>
				</div>

				<div class="topic-row">
					<div class="topic-row-label" containRequired>{{'bookshelf.writing-setting'|translate}}</div>
				</div>

				<div class="box__row first-row">
					<div class="box__row__label mark-required">{{'bookshelf.writing-language'|translate}}</div>
					<div class="box__row__data">
						<okaPulldown2 [options]="essayLangOptions" bindLabel="label" bindValue="value" 
						[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="essayLang"></okaPulldown2>
					</div>
				</div>

				<div class="box__row">
					<div class="box__row__label mark-required">{{'bookshelf.no-of-paragraph'|translate}}</div>
					<div class="box__row__data">
						<okaPulldown2 [options]="paragraphOptions" bindLabel="label" bindValue="value" 
						[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="paragraph"></okaPulldown2>
					</div>
				</div>

				<div class="box__row">
					<div class="box__row__label">{{'bookshelf.pre-writing-ai-gride'|translate}}:</div>
					<div class="box__row__data">
						<ui-switch [checked]="prewritingAIGuideEnabled" 
							[color]="'var(--section-maincolor)'"
							(change)="switchChange($event, 'Guide')"></ui-switch>
						<ng-container *ngIf="prewritingAIGuideEnabled">
							<okaPulldown2 [options]="prewritingAIGuideWordsOptions" bindLabel="label" bindValue="value" 
							[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="prewritingAIGuideWords"></okaPulldown2>
							<okaPulldown2 [options]="prewritingAIGuideTimesOptions" bindLabel="label" bindValue="value" 
							[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="prewritingAIGuideTimes"></okaPulldown2>
						</ng-container>
					</div>
				</div>

				<div class="box__row" *ngIf="paragraph">
					<div class="box__row__label">{{'bookshelf.pre-writing-ai-suggestion'|translate}}:</div>
					<div class="box__row__data">
						<ui-switch [checked]="prewritingAISuggestionEnabled" (change)="switchChange($event, 'Suggestion')"></ui-switch>
						<ng-container *ngIf="prewritingAISuggestionEnabled">
							<okaPulldown2 [options]="prewritingAISuggestionWordsOptions" bindLabel="label" bindValue="value" 
							[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="prewritingAISuggestionWords"></okaPulldown2>
							<okaPulldown2 [options]="prewritingAISuggestionTimesOptions" bindLabel="label" bindValue="value" 
							[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="prewritingAISuggestionTimes"></okaPulldown2>
						</ng-container>
					</div>
				</div>

				<div class="box__row last-row">
					<div class="box__row__label">{{'bookshelf.pre-writing-ai-feedback'|translate}}:</div>
					<div class="box__row__data">
						<ui-switch [checked]="prewritingAIFeedbackEnabled" (change)="switchChange($event, 'Feedback')"></ui-switch>
						<ng-container *ngIf="prewritingAIFeedbackEnabled">
							<okaPulldown2 [options]="prewritingAIFeedbackWordsOptions" bindLabel="label" bindValue="value" 
							[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="prewritingAIFeedbackWords"></okaPulldown2>
							<okaPulldown2 [options]="prewritingAIFeedbackTimesOptions" bindLabel="label" bindValue="value" 
							[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="prewritingAIFeedbackTimes"></okaPulldown2>
						</ng-container>
					</div>
				</div>

				<div class="topic-row">
					<div class="topic-row-label">
						<div containRequired>{{'bookshelf.scoring-setting'|translate}}</div>
					</div>
				</div>

				<div class="box__row first-row">
					<div class="box__row__label">{{ {tc:'合格百分比', sc:'合格百分比', en: 'Pass level'}[lang] }}:</div>
					<div class="box__row__data">
						<okaPulldown2 [options]="passLevelOptions" bindLabel="label" bindValue="value" 
						[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="passLevel"></okaPulldown2>
					</div>
				</div>

				<div class="box__row last-row scoring">
					<div class="box__row__label mark-required">{{'bookshelf.scoring-scale'|translate}}
						<div class="scoring-total">({{ {tc:'總分', sc:'总分',en:'Total score'}[lang] }}: <span class="num">{{ totalScore }}</span>)</div>
						<div class="info-ball-wrapper">
							<additionRemarkBall 
								[bubbleText]="showInfo('scoring-scale')">
								</additionRemarkBall>
						</div>
					</div>
					<div class="box__row__data">
						<div class="scoring-wrapper">
							<div class="scoring-item" *ngFor="let item of scoringItemsTitle;let i = index;">
								<div class="scoring-item-row-1">
									{{ (i + 1)}}.
									<input type="text" class="scoring-title" [ngModel]="scoringItemsTitle[i]" (ngModelChange)="updateScoringItem($event, i, 'title')"/>
									<div class="scoring-label">{{ {tc:'分數',sc:'分數',en:'Score'}[lang] }}:</div>
									<input type="text" class="scoring-num" [ngModel]="scoringItemsScore[i]" (ngModelChange)="updateScoringItem($event, i, 'score') "/>
									<div class="scoring-trash" (click)="removeScoringItem(i)">
										<fa-icon [icon]="faTrash"></fa-icon>
									</div>
								</div>
								<div class="scoring-item-row-2">
									<div class="scoring-guidance-label">{{ {tc:'指引',sc:'指引',en:'Guidance'}[lang] }}:</div>
									<textarea class="scoring-guidance" [ngModel]="scoringItemsGuidance[i]"
										(ngModelChange)="updateScoringItem($event, i, 'guidance')"></textarea>
								</div>
							</div>
						</div>
						<div class="scoring-add" (click)="addScoringItem()">
							<fa-icon [icon]="faCirclePlus"></fa-icon>
						</div>
					</div>
				</div>

				
			</div>


			<div class="section-right">
				<div class="topic-row">
					<div class="topic-row-label">{{'bookshelf.media'|translate}}</div>
					<div class="topic-row-button upload" (click)="mediaUpload()">{{ 'newsEdit.upload'|translate}}</div>
					<div class="topic-row-button clear" (click)="mediaClear()">{{ 'newsEdit.clear'|translate}}</div>
				</div>
				<div class="preview" [class.blank]="!preview" [style.backgroundImage]="preview">
					<fa-icon [icon]="faImage" *ngIf="!preview"></fa-icon>
				</div>
			</div>
			
		</div>

  	</modal2>
  `
	, styleUrls: ['./create-aic.modal.scss'],
})



export class CreateAICModal implements OnInit {
	@Input() data: any;
	@ViewChild('targetSelector', {static: false}) targetSelector;
	@ViewChild('modal', {static: false}) modal;
	@ViewChild('nameRef', {static: false}) nameRef;
	@ViewChild('inProgressTimeRef', {static: false}) inProgressTimeRef;
	@ViewChild('objectiveTextRef', {static: false}) objectiveTextRef;
	@ViewChild('dateTimePicker', {static: false}) dateTimePicker: DateTimePicker3Component;
	@ViewChild('subjectSelector', {static: false}) subjectSelector;
	@ViewChild('objectiveSelector', {static: false}) objectiveSelector;
	@Output() close:EventEmitter<any> = new EventEmitter<any>();
	public scoringItems:any = [];
	public scoringItemsTitle:any = ['內容','表達','結構','標點'];
	public scoringItemsScore:any = [40,30,20,10];
	public scoringItemsGuidance:any = ['內容不會離題，文句沙石、病句較少','文筆流暢，能運用適當的修辭和寫作技巧去表達自己的所思所想，能做到描寫貼切，包括用詞、句式等都要準確','文章內容的鋪排是否合理和容易理解，內容清晰明確，結構安排緊密','標點是否確切。錯別字: 0 – 1個給3分，2 – 4個給2分，5 – 7個給1分，8個或以不給分，重錯不給'];
	public description = '';
	public mediaUrl = '';
	public abilityInfo = '';
	public modalData;
	public name='';
	public modalTitle = '';
	public targets = [];
	public star = 0;
	public startTime;
	public endTime;
	public wholeDay;
	public tagsTitle = '';
	public objectiveTitle = '';
	public okaPulldownStyle = { width: '160px' };
	public okaPulldownMenuStyle = {};
	public selectorPromise;
	public modalContentStyle = { width: 'calc(100vw - 40px)', height: 'calc(100dvh - 94px)', padding: '0 0 0 20px', maxWidth: '984px' };	
	public targetSelecting = false;
	public ready = false;
	public confirmLabel = '';
	public showPlaceholder = false;
	public subjects = [];
	public subjectsTitle = '';
	public essayLangOptions;
	public essayLang = 'tc';
	public paragraphOptions;
	public paragraph = 0;
	public prewritingAIGuideEnabled = true;
	public prewritingAISuggestionEnabled = true;
	public prewritingAIFeedbackEnabled = true;
	public prewritingAICommentEnabled = true;
	public prewritingAIGuideWords = 30;
	public prewritingAIGuideWordsOptions;
	public prewritingAISuggestionWords = 10;
	public prewritingAISuggestionWordsOptions;
	public prewritingAIFeedbackWords = 10;
	public prewritingAIFeedbackWordsOptions;
	public prewritingAICommentWords = 30;
	public prewritingAICommentWordsOptions;
	public prewritingAIGuideTimes = 1;
	public prewritingAIGuideTimesOptions;
	public prewritingAISuggestionTimes = 1;
	public prewritingAISuggestionTimesOptions;
	public prewritingAIFeedbackTimes = 3;
	public prewritingAIFeedbackTimesOptions;
	public subjectSelectorOptions;
	public passLevel;
	public passLevelOptions;
	public inProgressTimeText = '';
	public dtpOpened = false;
	public preview;
	public faImage = faImage;
	public faTrash = faTrash;
	public faCirclePlus = faCirclePlus;
	public lang;
	public infoBallText = '';
	public totalScore = 0;
	constructor(public datas: DataService, private script: ScriptService, private trans: TranslateService, private als: AlertService, private eleRef: ElementRef, private tg: TagGroupService, private lds: LoadingService, public upload: UploadService, public sans: DomSanitizer, public theme: ThemeService, private setting: SettingService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.initTargets();
		this.initOptions();
		this.initSubjects();
		if (this.data && this.data.share){
			this.modalTitle = {tc: '編輯AI輔助作文',sc: '编辑AI辅助作文', en: 'Edit AI-assisted writing'}[this.datas.lang];
			this.confirmLabel = {tc: '確定',sc: '確定', en: 'Confirm'}[this.datas.lang];
			this.loadExistShare(this.data);
		} else {
			this.modalTitle = {tc: '新增AI輔助作文',sc: '新增AI辅助作文', en: 'New AI-assisted writing'}[this.datas.lang];
			this.confirmLabel = {tc: '新增',sc: '新增', en: 'Add'}[this.datas.lang];
			this.scoringItemsTitle.forEach((title, index) =>{
				let item:any = {title: title, score: this.scoringItemsScore[index], guidance: this.scoringItemsGuidance[index]}; 
				this.scoringItems.push(item);
				const score = isNaN(this.scoringItemsScore[index])?0:this.scoringItemsScore[index];
				this.totalScore += score;
			});
			this.startTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		}
		this.initInProgressTime();
	}

	ngAfterViewInit() {
		setTimeout(()=>{
			this.modal.open();
			setTimeout(()=>{ this.ready = true;}, 300);
			setTimeout(()=>{
				this.nameRef.nativeElement.focus();
			},600);
		},10);
	}

	ngOnDestroy(): void {
	}

	confirm(isConfirmed = false){
		let missingField = false;
		const missingScoreItem = this.scoringItems.find(e=> !e.title || !e.score || isNaN(e.score) );
		if (!this.name || !this.abilityInfo || missingScoreItem || this.targets.length == 0){
			missingField = true;
		}
		if (missingField){
			this.als.toastError('course.pls-fill-all-fields');
			this.showPlaceholder = true;
			return;
		}
		if (!isConfirmed && this.data.share && JSON.stringify(this.scoringItems) != JSON.stringify(this.data.structure.book.chapters[0].info.scoringItems)){
			const msg = {tc:'修改準則，已評的分數將清空，並需重新進行評分，確定修改?',sc:'修改准则，已评的分数将清空，并需重新进行评分，确定修改?',en:'future modifications to the criteria, previously scored scores will be cleared, and a new evaluation will be required. Confirm to modify?'}[this.lang];
			this.als.confirm(msg).then(confirmed=>{
				this.confirm(true);
			}).catch(e=>e);
			return;
		}
		let bookEntry:any = {type: 'ai-composition'};
		const props = [,'targets','abilityInfo','subjects','startTime','endTime','name','description','essayLang','paragraph','prewritingAIGuideEnabled','prewritingAIGuideWords','prewritingAIGuideTimes','prewritingAISuggestionEnabled','prewritingAISuggestionWords','prewritingAISuggestionTimes','prewritingAIFeedbackEnabled','prewritingAIFeedbackWords','prewritingAIFeedbackTimes','prewritingAICommentEnabled','prewritingAICommentWords','mediaUrl','scoringItems','passLevel'];
		props.forEach(prop=>{
			bookEntry[prop] = this[prop];
		});
		if (this.data.share){
			this.confirmUpdateAIComposition(bookEntry);
		} else {
			this.confirmCreateAIComposition(bookEntry);
		}
	}

	async confirmCreateAIComposition(bookEntry){
		const individualUids = this.targets.filter(t => t.tType == 'people').map(e=> e.value);
		const targets = this.targets.filter(t => t.tType == 'tag');
		if (individualUids.length > 0) {
			const schoolId = this.datas.userInfo.school_id;
			const title = 'cid-share-' + moment().format('YYYYMMDD-HHmmss');
			const tagType = 13;
			const currentYear = this.tg.defaultSchoolYear;
			const yearId = currentYear?currentYear.id:'0';
			const json: any[] = [schoolId, yearId, schoolId, title, tagType, 'main', individualUids];
			let res: any = await this.datas.post('ROCourse.create_virtual_tag_for_group_share', json).toPromise();
			targets.push({tType: 'tag', value: res.tid, title: title});
		}

		let targetValues = targets.map(e=> e.value);
		this.createCreate(bookEntry, targetValues);
	}

	createCreate(bookEntry, targetValues) {
		const startTime = this.startTime?moment(this.startTime,'YYYY-MM-DD HH:mm:ss').toISOString():null;
		const endTime = this.endTime?moment(this.endTime,'YYYY-MM-DD HH:mm:ss').toISOString():null;
		const options = {type: 'custom', star: 0, start_time: startTime, end_time: endTime };
		let shares = [];
		if (this.lds.isLoading == false){
			this.lds.add('ROBookShare.create_multi_share',20000);
		}
		let bookEntryForShare = JSON.parse(JSON.stringify(bookEntry));
		const tagId = targetValues.pop();
		this.datas.post('ROBookshelfNew.addFakeBook', [bookEntryForShare]).subscribe((res:any)=>{
			bookEntryForShare.entry_id = res.item.book_id;
			const obj = { entries: [{ id: bookEntryForShare.entry_id, type: 'ai-composition', title: this.name }], tags: [ tagId ], options: options };
			this.datas.post('ROBookShare.create_multi_share', [obj]).subscribe((res:any)=>{
				shares.push(...res.shares);
				if (res.share && res.share.id){
					this.datas.post('ROBookshelfNew.insertBookShareExtend',[res.share.id, 'info', null, bookEntry]).subscribe((res:any)=>{
						
					});
					const xml = this.formatXML(bookEntry);
					this.datas.post('ROBook.add_book_from_xml', [bookEntryForShare.entry_id, this.name, xml]).subscribe((res:any)=>{

					});
				}
				if (targetValues.length == 0){
					this.lds.remove('ROBookShare.create_multi_share');
					let msg = {tc: '成功新增AI作文「$title」分享',sc:'成功新增AI作文「$title」分享', en:'Created AI Writing "$title" sharing successfully'}[this.datas.lang];
					msg = msg.replace('$title', this.name);
					this.als.toastSuccess(msg);
					if (this.data.close){
						this.data.close(shares);
					}
				} else {
					this.createCreate(bookEntry, targetValues);
				}
			});
		});
		
	}

	confirmUpdateAIComposition(bookEntry){
		const bsid = this.data.share.id;
		const bookId = this.data.share.entry_id;
		const structure = this.data.structure;
		structure.book.chapters[0].pages[0].components.forEach((comp,index)=>{
			if (this.scoringItems.length > index){
				comp.name2 = this.scoringItems[index].title;
				comp.score.full = this.scoringItems[index].score;
			}
		});
		structure.book.chapters[0].pages[0].components = structure.book.chapters[0].pages[0].components.splice(0, this.scoringItems.length);
		if (structure.book.chapters[0].pages[0].components.length < this.scoringItems.length){
			let start = structure.book.chapters[0].pages[0].components.length;
			for(let i = start; i < this.scoringItems.length; i++){
				let cloned = JSON.parse(JSON.stringify(structure.book.chapters[0].pages[0].components[0]));
				cloned.name2 = this.scoringItems[i].title;
				cloned.score.full = this.scoringItems[i].score;
				cloned.cid = GUID.create("OKD guid");
				structure.book.chapters[0].pages[0].components.push(cloned);
			}
		}
		structure.book.chapters[0].info = bookEntry;
		this.lds.add('ROBookShare.edit_ai_composition',20000);
		// this.datas.post('ROBook.updateXML', )
		this.datas.post('ROBookshelfNew.edit_ai_composition', [bsid, this.name, bookId, structure]).subscribe((res:any)=>{
			const msg = {tc: '已修改AI作文分享',sc:'已修改AI作文分享', en:'AI assisted writing was created.'}[this.datas.lang];
			this.als.toastSuccess(msg);
			this.lds.remove('ROBookShare.edit_ai_composition');
			if (this.data.close){
				this.data.close(bookEntry);
			}
		});

		this.datas.post('ROBookshelfNew.insertBookShareExtend',[bsid, 'info', null, bookEntry]).subscribe((res:any)=>{
			
		});

	}

	initTargets() {
		if (this.data.share && this.data.share.id){
			this.tagsTitle = this.data.tag_name;
			return;
		}
		if (this.data.tag){
			this.tagsTitle = this.data.tag.title;
			this.targets = [{tType: 'tag', title: this.tagsTitle, value: this.data.tag.id}];
		}
		if (this.targets.length > 0) {
			const targetTitles = this.targets.map(t => t.title);
			this.tagsTitle = targetTitles.join(', ');
		} else {
			this.targets = [];
			this.tagsTitle = this.trans.instant('commonService.please-select');
		}
	}

	initOptions() {
		const lang = this.datas.lang;
		this.essayLangOptions = [
			{label: {tc: '繁體中文', sc: '繁體中文', en: 'Traditional Chinese'}[lang], value: 'tc' },
			{label: {tc: '簡體中文', sc: '簡體中文', en: 'Simplified Chinese'}[lang], value: 'sc' },
			{label: {tc: '英文', sc: '英文', en: 'English'}[lang], value: 'en' },
		];

		this.paragraphOptions = [1,2,3,4,5,6,7,8,9,10].map(e=> {
			return {label: e, value: e};
		});

		this.paragraphOptions.unshift({label: {tc:'不限', sc:'不限',en:'No limit'}[lang], value: 0})

		this.prewritingAIGuideWordsOptions = [30,50,100].map(e=>{
			const text = {tc: `大約${e}字以內`, sc: `大约${e}字以内`, en: `> ${e} words`}[lang];
			return {label: text, value: e};
		});

		this.prewritingAISuggestionWordsOptions = [10,30,50].map(e=>{
			const text = {tc: `大約${e}字以內`, sc: `大约${e}字以内`, en: `> ${e} words`}[lang];
			return {label: text, value: e};
		});

		this.prewritingAIFeedbackWordsOptions = [10,30,50].map(e=>{
			const text = {tc: `大約${e}字以內`, sc: `大约${e}字以内`, en: `> ${e} words`}[lang];
			return {label: text, value: e};
		});

		this.prewritingAICommentWordsOptions = [30,50,100].map(e=>{
			const text = {tc: `大約${e}字以內`, sc: `大约${e}字以内`, en: `> ${e} words`}[lang];
			return {label: text, value: e};
		});

		this.prewritingAIGuideTimesOptions = [1,2,3].map(e=>{
			const text = {tc: `可生成${e}次`, sc: `可生成${e}次`, en: `Generate ${e} time${e==1?'':'s'}`}[lang];
			return {label: text, value: e};
		});

		this.prewritingAISuggestionTimesOptions = [1,2,3].map(e=>{
			const text = {tc: `每段可生成${e}次`, sc: `每段可生成${e}次`, en: `${e} time${e==1?'':'s'}/paragraph`}[lang];
			return {label: text, value: e};
		});

		this.prewritingAIFeedbackTimesOptions = [1,2,3].map(e=>{
			const text = {tc: `每段可詢問${e}次`, sc: `每段可询问${e}次`, en: `${e} time${e==1?'':'s'}/paragraph`}[lang];
			return {label: text, value: e};
		});

		this.passLevel = this.setting.schoolSettings['PASSING_RATE']?this.setting.schoolSettings['PASSING_RATE']:60;
		this.passLevelOptions = [10,20,30,40,50,60,70,80,90,100].map(e=>{
			let label = `>=${e}%`;
			if (e == this.setting.schoolSettings['PASSING_RATE']){
				label = label + {tc:' (預設)',sc:' (预设)', en:' (Default)'}[lang];
			}
			return { label: label, value: e};
		});
		this.passLevelOptions.unshift({label: {tc:'沒有', sc:'没有', en: 'None'}[lang], value: 0});
		this.updateTotalScore();
	}

	targetSelectorOpen($event){
		if (this.data.share && this.data.share.id){
			return;
		}
		const lang = this.datas.lang;
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
			this.targetSelecting = false;
		} else {
			this.targetSelecting = true;
			setTimeout(()=>{
				this.selectorPromise = this.targetSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.targetSelecting = false;
					this.initTargets();
				});
			},250);
		}
		
	}

	closeClick(){
		if (this.data.close){
			this.data.close();
		}
		this.close.emit();
	}

	inProgressTimeClick(){
		const isDtpOpen = this.dateTimePicker.bubbleBox2.show && this.dateTimePicker.bubbleBox2.boxPosX > 0;
		if (isDtpOpen){
			return;
		}
		let ele = this.inProgressTimeRef.nativeElement;
		let startDateTime:any = this.startTime?moment(this.startTime,"YYYY-MM-DD HH:mm:ss").toDate():null;
		let endDateTime:any = this.endTime?moment(this.endTime,"YYYY-MM-DD HH:mm:ss").toDate():null;
		let needEndTime = !!this.endTime;
		let options:any = {
			targetField: 'startDate',
			showEndDateTimeOption: true,
			showPresetOptions: false,
			value: {
				customMinutes: true,
				needEndTime: needEndTime,
				startDateTime: startDateTime,
				endDateTime: endDateTime?endDateTime:null
			}
		};
		this.dateTimePicker.open(ele, options).then((data: any) => {
			this.startTime = moment(data.startDateTime).format('YYYY-MM-DD HH:mm:ss');
			if (data.endDateTime){
				this.endTime = moment(data.endDateTime).format('YYYY-MM-DD HH:mm:ss');
			} else {
				this.endTime = null;
			}
			this.initInProgressTime();
		}).catch(e=>{
		});
	}

	initInProgressTime(){
		const startTimeMo = this.startTime?moment(this.startTime, 'YYYY-MM-DD HH:mm:ss'):null;
		const endTimeMo = this.endTime?moment(this.endTime, 'YYYY-MM-DD HH:mm:ss'):null;
		if (endTimeMo){
			this.inProgressTimeText = startTimeMo.format('YYYY/M/D h:mma') + ' - ' + endTimeMo.format('YYYY/M/D H:mma');
		} else {
			this.inProgressTimeText = startTimeMo.format('YYYY/M/D h:mma') + ' - ∞';
		}
	}

	subjectSelectorOpen($event){
		if (this.selectorPromise && this.subjectSelector) {
			this.subjectSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
		} else {
			setTimeout(() => {
				this.selectorPromise = this.subjectSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.initSubjects();
				}).catch(e=>{ //cancel will throw error
					this.selectorPromise = null;
				});
			}, 200);
		}
	}

	initSubjects(){
		const lang = this.datas.lang;
		console.log(this.subjects);
		if (this.subjects.length == 0){
			this.subjectsTitle = {tc: '請選擇...', sc:'請選擇...', en: 'Please select'}[lang];
		} else {
			this.subjectsTitle = this.subjects.map(e=>e.title).join(',');
		}
	}

	switchChange($event, type){
		this['prewritingAI'+ type +'Enabled'] = $event;
	}

	mediaUpload(){
		const uploadOptions = {size: 50 *1024 *1024, fileType: 'image', toMediaServer: 'ai-composition', resizeRatio: 0.5};
		this.upload.upload(uploadOptions).then((res:any)=>{
			this.mediaUrl = res.url;//https://oka.blob.core.windows.net/media/
			let url = 'https://oka.blob.core.windows.net/media/';
			url = url + res.url;
			this.preview = this.sans.bypassSecurityTrustStyle('url("' + url + '")');
		});
	}

	mediaClear(){
		this.preview = null;
		this.mediaUrl = '';
	}

	loadExistShare(data){
		const structure = data.structure;
		const jsonObj = data.share.json?JSON.parse(data.share.json):null;
		if (structure){
			const info = data.structure.book.chapters[0].info;
			const props = ['name','targets','abilityInfo','subjects','startTime','endTime','description','essayLang','paragraph','prewritingAIGuideEnabled','prewritingAIGuideWords','prewritingAIGuideTimes','prewritingAISuggestionEnabled','prewritingAISuggestionWords','prewritingAISuggestionTimes','prewritingAIFeedbackEnabled','prewritingAIFeedbackWords','prewritingAIFeedbackTimes','prewritingAICommentEnabled','prewritingAICommentWords', 'mediaUrl','passLevel'];
			props.forEach(prop=>{
				this[prop] = info[prop];
			});
			if (info.scoringItems){
				this.scoringItems = [...info.scoringItems];
				this.scoringItemsTitle = [];
				this.scoringItemsScore = [];
				this.scoringItemsGuidance = [];
				this.scoringItems.forEach(item=>{
					this.totalScore += +item.score;
					this.scoringItemsTitle.push(item.title);
					this.scoringItemsScore.push(+item.score);
					this.scoringItemsGuidance.push(item.guidance);
				});
			}
			if (this.mediaUrl){
				let url = 'https://oka.blob.core.windows.net/media/' + this.mediaUrl;
				this.preview = this.sans.bypassSecurityTrustStyle('url("' + url + '")');
			}
			this.targets = this.targets.filter(e=> e.value == this.data.tagId);
			this.initSubjects();
		}
	}

	formatXML(bookEntry){
		let obj = {Doc: {Chapter: {Page: { bookEntry }}}};
		const xml = toXML(obj);
		return xml;
	}

	showInfo(field){
		const lang = this.lang;
		if (field == 'ability-info'){
			return {tc:'內容可因應學生年級或學習能力水平而生成。例：一年級, 高能力',sc:'内容可因应学生年级或学习能力水平而生成。例：一年级, 高能力',en:'Content can be generated based on the grade or learning ability of students. e.g: Primary 1, High learning ability'}[lang];
		} else {
			return {tc:"1. 總分 = 每項評分準則的分數總和<br/>2. 如日後修改準則，已評的分數將清空，並需重新進行評分<br/>3. 可輸入指引欄以指示AI如何評分",sc:"1. 总分 = 每项评分准则的分数总和<br/>2. 如日后修改准则，已评的分数将清空，并需重新进行评分<br/>3. 可输入指引栏以指示AI如何评分",en:"1.Total Score = Sum of scores for each evaluation criterion.<br/>2. In case of future modifications to the criteria, previously scored scores will be cleared, and a new evaluation will be required.<br/>3. Enter guideline to instruct the AI on how to score."}[lang];
		}
	}

	addScoringItem(){
		let item = {title: '', score: 0, guidance: ''};
		this.scoringItems.push(item);
		this.scoringItemsTitle.push('');
		this.scoringItemsScore.push(0);
		this.scoringItemsGuidance.push(0);
	}

	removeScoringItem(index){
		if (this.scoringItems.length == 1){
			const msg = {tc:'最少要有一個評分準則',sc:'最少要有一个评分准则',en:'At least one evaluation criterion is required'}[this.lang];
			this.als.toastError(msg);
			return;
		}
		this.scoringItems.splice(index,1);
		this.scoringItemsTitle.splice(index, 1);
		this.scoringItemsScore.splice(index, 1);
		this.scoringItemsGuidance.splice(index, 1);
		this.updateTotalScore();
	}

	trackByFn(index, item){
		return item.title + item.score;
	}

	updateScoringItem(value, index, field){
		setTimeout(()=>{
			if (field == 'title'){
				this.scoringItems[index].title = value;
			} else if (field == 'guidance'){
				this.scoringItems[index].guidance = value;
			} else {
				this.scoringItems[index].score = +value;
				this.updateTotalScore();
			}
		}, 10);
	}

	updateTotalScore(){
		this.totalScore = 0;
		this.scoringItems.forEach(item=>{
			const score = isNaN(item.score)?0:+item.score;
			this.totalScore += score;
		});
	}



}
