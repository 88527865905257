import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener, OnInit, TemplateRef, AfterViewInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';

@Component({
    selector: 'progressBox2',
    template: `
<div class="progress-wrapper {{hasCancelButton?'with-cancel-bottom':''}}" [ngStyle]="options.wrapperStyle">
	<div class="progress-wrapper-2 {{hasCancelButton?'with-cancel-bottom':''}}">
		<div class="progress-msg">
			<div class="progress-msg-front">{{ frontText }}</div>
			<div class="progress-msg-dot">
				<div class="loading"></div>
			</div>
			<div class="progress-msg-tail">{{ tailText }}</div>
		</div>
		<div class="progress-bar" *ngIf="progressPt !== null">
			<div class="inner-bar" [style.width]="progressPt + '%'"></div>
		</div>
	</div>
	<ng-container *ngTemplateOutlet="templateContent;"></ng-container>
	<ng-content></ng-content>
	<div class="progress-cancel" *ngIf="hasCancelButton" (click)="cancelClick()"> {{ {tc:'取消', sc: '取消', en: 'Cancel'}[datas.lang] }}</div>
</div>
    `,
    styleUrls: ['./progressBox2.component.scss']
})

export class ProgressBox2Component implements OnInit, AfterViewInit{
    @HostListener('dblclick', []) onDblClick() { console.log(this); }
    @HostBinding('style.display') hostDisplay:string = 'none';

    @HostBinding('style.zIndex') public hostZIndex = 0;
    public isOpened:boolean = false;
	
    public statusWidth:string = "35px";
    private timer:any;
	
	@Input() public options:any = {};
    @Input() public templateContent:TemplateRef<any> = null;
	@Input() public frontText;
	@Input() public tailText;
	@Input() public progressPt;
	@Input() public auto = false;
	@Input() public hasCancelButton = false;
	@Output() public onCancel = new EventEmitter<any>();
	@Output() public onClose = new EventEmitter<any>();
    constructor(private coms:CommonService, private dcs:DynamicComponentService, public translate:TranslateService, public datas: DataService) {

    }

	ngOnInit(): void {
		this.timer = setInterval(()=>{
			if (this.progressPt >= 100 && this.auto){
				setTimeout(()=>{ this.close();}, 100);
			}
        }, 1000);
		this.frontText = this.frontText?this.frontText: {tc: '上載中', sc: '上載中', en: 'Uploading'}[this.datas.lang];

	}

	ngAfterViewInit(): void {
		if (this.auto){
			setTimeout(()=>{this.open();}, 10);
		}
	}

    public open(appendEle:HTMLElement = document.body):void {
        this.hostDisplay = 'flex';
        this.isOpened = true;
        this.hostZIndex = this.coms.getZIndex();
        this.dcs.open(this, appendEle);
    }

    public close():void {
        this.hostDisplay = 'none';
        this.isOpened = false;
        clearInterval(this.timer);
        this.dcs.close(this);
		this.onClose.emit();
    }

	cancelClick(){
		this.onCancel.emit();
		this.close();
	}

}
