export class PinYinUtils 
{
	public static TYPE_SHENGMU:string = "shengmu";
	public static TYPE_YUNMU:string = "yunmu";
	public static TYPE_TONE:string = "tone";
	public static TYPE_SHENGMU_YUNMU:string = "shengmu_yunmu";
	public static TYPE_YUNMU_TONE:string = "yunmu_tone";
	public static TYPE_SHENGMU_YUNMU_TONE:string = "shengmu_yunmu_tone";
	public static TYPE_PINYIN_KEYBOARD:string = "pinyin_keyboard";
	public static ALL_TYPE:any [] = [PinYinUtils.TYPE_SHENGMU, PinYinUtils.TYPE_YUNMU, PinYinUtils.TYPE_TONE, PinYinUtils.TYPE_SHENGMU_YUNMU, PinYinUtils.TYPE_YUNMU_TONE, PinYinUtils.TYPE_SHENGMU_YUNMU_TONE];
	public static TYPE_TONE_MARK:string = "tone_mark";
	
	public static pinyinString:string = "āáǎàēéěèīíǐìōóǒòūúǔùǖǘǚǜĀÁǍÀĒÉĚÈĪÍǏÌŌÓǑÒŪÚǓÙǕǗǙǛễếểềỄẾỂỀ";
	public static pinyinRegExp:RegExp = /[āáǎàēéěèīíǐìōóǒòūúǔùǖǘǚǜĀÁǍÀĒÉĚÈĪÍǏÌŌÓǑÒŪÚǓÙǕǗǙǛễếểềỄẾỂỀ]/g;
	public static NONpinyinRegExp:RegExp = /[^āáǎàēéěèīíǐìōóǒòūúǔùǖǘǚǜĀÁǍÀĒÉĚÈĪÍǏÌŌÓǑÒŪÚǓÙǕǗǙǛễếểềỄẾỂỀ]/g;
	
	public static yunmuPositionMap:any  = {
		"ao":0, "er":0, "i":0, "in":0, "ei":0, "eng":0, 
		"üe":1, "iong":1, 
		"en":0,	"ün":0, 
		"ian":1, 
		"ong":0, 
		"ie":1, "uang":1, 
		"o":0, 
		"uan":1, "iou":1, "üan":1, "ueng":1, "ia":1, "uo":1, 
		"u":0, "uen":0, 
		"iao":1, 
		"ü":0, "ing":0, "an":0, "e":0,
		"uei":1,
		"ai":0, 
		"uai":1,
		"a":0,
		"iang":1, "ua":1,
		"ang":0,"ou":0
	}
	public static pinyinChart:any  = { 
		"1":"ˉ", "2":"ˊ", "3":"ˇ", "4":"ˋ","5":".",
		"ˉ":"1", "ˊ":"2", "ˇ":"3", "ˋ":"4",".":"5",
		"a1":"ā", "a2":"á", "a3":"ǎ", "a4":"à", 
		"o1":"ō", "o2":"ó", "o3":"ǒ", "o4":"ò",
		"e1":"ē", "e2":"é", "e3":"ě", "e4":"è", 
		"i1":"ī", "i2":"í", "i3":"ǐ", "i4":"ì", 
		"u1":"ū", "u2":"ú", "u3":"ǔ", "u4":"ù", 
		"ü1":"ǖ", "ü2":"ǘ", "ü3":"ǚ", "ü4":"ǜ",
		"ā":"a1", "á":"a2", "ǎ":"a3", "à":"a4", 
		"ō":"o1", "ó":"o2", "ǒ":"o3", "ò":"o4",
		"ē":"e1", "é":"e2", "ě":"e3", "è":"e4", 
		"ī":"i1", "í":"i2", "ǐ":"i3", "ì":"i4", 
		"ū":"u1", "ú":"u2", "ǔ":"u3", "ù":"u4", 
		"ǖ":"ü1", "ǘ":"ü2", "ǚ":"ü3", "ǜ":"ü4",
		"ễ":"ê1", "ế":"ê2", "ể":"ê3", "ề":"ê4",
		"er1":"ēr", "er2":"ér", "er3":"ěr", "er4":"èr",
		"ai1":"āi", "ai2":"ái", "ai3":"ǎi", "ai4":"ài",  
		"ei1":"ēi", "ei2":"éi", "ei3":"ěi", "ei4":"èi", 
		"ao1":"āo", "ao2":"áo", "ao3":"ǎo", "ao4":"ào",
		"ou1":"ōu", "ou2":"óu", "ou3":"ǒu", "ou4":"òu", 
		"an1":"ān", "an2":"án", "an3":"ǎn", "an4":"àn", 
		"en1":"ēn", "en2":"én", "en3":"ěn", "en4":"èn", 
		"ang1":"āng", "ang2":"áng", "ang3":"ǎng", "ang4":"àng", 
		"eng1":"ēng", "eng2":"éng", "eng3":"ěng", "eng4":"èng", 
		"ong1":"ōng", "ong2":"óng", "ong3":"ǒng", "ong4":"òng",
		"ia1":"iā", "ia2":"iá", "ia3":"iǎ", "ia4":"ià",
		"ie1":"iē", "ie2":"ié", "ie3":"iě", "ie4":"iè",
		"iao1":"iāo", "iao2":"iáo", "iao3":"iǎo", "iao4":"iào",
		"iou1":"iū", "iou2":"iú", "iou3":"iǔ", "iou4":"iù",
		"ian1":"iān", "ian2":"ián", "ian3":"iǎn", "ian4":"iàn",
		"in1":"īn", "in2":"ín", "in3":"ǐn", "in4":"ìn", 
		"iang1":"iāng", "iang2":"iáng", "iang3":"iǎng", "iang4":"iàng",
		"ing1":"īng", "ing2":"íng", "ing3":"ǐng", "ing4":"ìng", 
		"iong1":"iōng", "iong2":"ióng", "iong3":"iǒng", "iong4":"iòng",
		"ua1":"uā", "ua2":"uá", "ua3":"uǎ", "ua4":"uà",
		"uo1":"uō", "uo2":"uó", "uo3":"uǒ", "uo4":"uò",
		"uai1":"uāi", "uai2":"uái", "uai3":"uǎi", "uai4":"uài",
		"uei1":"uī", "uei2":"uí", "uei3":"uǐ", "uei4":"uì",
		"uan1":"uān", "uan2":"uán", "uan3":"uǎn", "uan4":"uàn",
		"uen1":"ūn", "uen2":"ún", "uen3":"ǔn", "uen4":"ùn",
		"uang1":"uāng", "uang2":"uáng", "uang3":"uǎng", "uang4":"uàng",
		"ueng1":"uēng", "ueng2":"uéng", "ueng3":"uěng", "ueng4":"uèng",
		"üe1":"üē", "üe2":"üé", "üe3":"üě", "üe4":"üè",
		"üan1":"uān", "üan2":"uán", "üan3":"uǎn", "üan4":"uàn",
		"ün1":"ūn", "ün2":"ún", "ün3":"ǔn", "ün4":"ùn" 
	};
	
												
	//case for all shengmu
	public static writeConvertion0:any  = { "iou":"iu", "iōu":"iū", "ióu":"iú", "iǒu":"iǔ", "iòu":"iù", 
													"uei":"ui", "uēi":"uī", "uéi":"uí", "uěi":"uǐ", "uèi":"uì",
													"uen":"un", "uēn":"ūn", "uén":"ún", "uěn":"ǔn", "uèn":"ùn"  };
	public static writeConvertion0reverse:any  = { "iu":"iou", "iū":"iōu", "iú":"ióu", "iǔ":"iǒu", "iù":"iòu", 
													"ui":"uei", "uī":"uēi", "uí":"uéi", "uǐ":"uěi", "uì":"uèi",
													"un":"uen", "ūn":"uēn", "ún":"uén", "ǔn":"uěn", "ùn":"uèn"  };
	//case for shengmu j, q, x, y
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	public static writeConvertion1:any  = { "üan":"uan", "üān":"uān", "üán":"uán", "üǎn":"uǎn", "üàn":"uàn", 
													"ün":"un", "ǖn":"ūn", "ǘn":"ún", "ǚn":"ǔn", "ǜn":"ùn" };
	public static writeConvertion1reverse:any  = { "uan":"üan", "uān":"üān", "uán":"üán", "uǎn":"üǎn", "uàn":"üàn", 
															"un":"ün", "ūn":"ǖn", "ún":"ǘn", "ǔn":"ǚn", "ùn":"ǜn" };
	public static writeConvertion2:any  = { "ü":"u", "ǖ":"ū", "ǘ":"ú", "ǚ":"ǔ", "ǜ":"ù", 
													"üe":"ue", "üē":"uē", "üé":"ué", "üě":"uě", "üè":"uè" };
	public static writeConvertion2reverse:any  = { "u":"ü", "ū":"ǖ", "ú":"ǘ", "ǔ":"ǚ", "ù":"ǜ", 
															"ue":"üe", "uē":"üē", "ué":"üé", "uě":"üě", "uè":"üè" };
	public static writeConvertionUtone:any  = { "ü":"u", "ǖ":"ū", "ǘ":"ú", "ǚ":"ǔ", "ǜ":"ù" };
	public static writeConvertionUtoneReverse:any  = { "u":"ü", "ū":"ǖ", "ú":"ǘ", "ǔ":"ǚ", "ù":"ǜ" };
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	
	//case for shengmu y (yunmu start with i)
	public static writeConvertionYI:any  = { "ia":"a", "iā":"ā", "iá":"á", "iǎ":"ǎ", "ià":"à", 
													"ie":"e", "iē":"ē", "ié":"é", "iě":"ě", "iè":"è",
													"iao":"ao", "iāo":"āo", "iáo":"áo", "iǎo":"ǎo", "iào":"ào",
													"iu":"ou", "iū":"ōu", "iú":"óu", "iǔ":"ǒu", "iù":"òu",
													"iou":"ou", "iōu":"ōu", "ióu":"óu", "iǒu":"ǒu", "iòu":"òu",
													"ian":"an", "iān":"ān", "ián":"án", "iǎn":"ǎn", "iàn":"àn",
													"iang":"ang", "iāng":"āng", "iáng":"áng", "iǎng":"ǎng", "iàng":"àng",
													"iong":"ong", "iōng":"ōng", "ióng":"óng", "iǒng":"ǒng", "iòng":"òng" };
	public static writeConvertionYIreverse:any  = { "a":"ia", "ā":"iā", "á":"iá", "ǎ":"iǎ", "à":"ià", 
													"e":"ie", "ē":"iē", "é":"ié", "ě":"iě", "è":"iè",
													"ao":"iao", "āo":"iāo", "áo":"iáo", "ǎo":"iǎo", "ào":"iào",
													//"ou":"iu", "ōu":"iū", "óu":"iú", "ǒu":"iǔ", "òu":"iù",
													"ou":"iou", "ōu":"iōu", "óu":"ióu", "ǒu":"iǒu", "òu":"iòu",
													"an":"ian", "ān":"iān", "án":"ián", "ǎn":"iǎn", "àn":"iàn",
													"ang":"iang", "āng":"iāng", "áng":"iáng", "ǎng":"iǎng", "àng":"iàng",
													"ong":"iong", "ōng":"iōng", "óng":"ióng", "ǒng":"iǒng", "òng":"iòng" };												
	//case for shengmu w (yunmu start with u)												
	public static writeConvertionWU:any  = { "ua":"a", "uā":"ā", "uá":"á", "uǎ":"ǎ", "uà":"à",
													"uo":"o", "uō":"ō", "uó":"ó", "uǒ":"ǒ", "uò":"ò",
													"uai":"ai", "uāi":"āi", "uái":"ái", "uǎi":"ǎi", "uài":"ài",
													"ui":"ei", "uī":"ēi", "uí":"éi", "uǐ":"ěi", "uì":"èi",
													"uei":"ei", "uēi":"ēi", "uéi":"éi", "uěi":"ěi", "uèi":"èi",
													"uan":"an", "uān":"ān", "uán":"án", "uǎn":"ǎn", "uàn":"àn",
													"un":"en", "ūn":"ēn", "ún":"én", "ǔn":"ěn", "ùn":"èn",
													"uen":"en", "uēn":"ēn", "uén":"én", "uěn":"ěn", "uèn":"èn",
													"uang":"ang", "uāng":"āng", "uáng":"áng", "uǎng":"ǎng", "uàng":"àng",
													"ueng":"eng", "uēng":"ēng", "uéng":"éng", "uěng":"ěng", "uèng":"èng" };
	public static writeConvertionWUreverse:any  = { "a":"ua", "ā":"uā", "á":"uá", "ǎ":"uǎ", "à":"uà",
													"o":"uo", "ō":"uō", "ó":"uó", "ǒ":"uǒ", "ò":"uò",
													"ai":"uai", "āi":"uāi", "ái":"uái", "ǎi":"uǎi", "ài":"uài",
													//"ei":"ui", "ēi":"uī", "éi":"uí", "ěi":"uǐ", "èi":"uì",
													"ei":"uei", "ēi":"uēi", "éi":"uéi", "ěi":"uěi", "èi":"uèi",
													"an":"uan", "ān":"uān", "án":"uán", "ǎn":"uǎn", "àn":"uàn",
													//"en":"un", "ēn":"ūn", "én":"ún", "ěn":"ǔn", "èn":"ùn",
													"en":"uen", "ēn":"uēn", "én":"uén", "ěn":"uěn", "èn":"uèn",
													"ang":"uang", "āng":"uāng", "áng":"uáng", "ǎng":"uǎng", "àng":"uàng",
													"eng":"ueng", "ēng":"uēng", "éng":"uéng", "ěng":"uěng", "èng":"uèng" };
	
	//defind the combination of light shengmu
	public static lightShengmuChart:any  = { "y": ["i","ia","ie","iao","iou","ian","in","iang","ing","iong","ü","üe","üan","ün"],
													 "w": ["u","ua","uo","uai","uei","uan","uen","uang","ueng"] };
													
	//tone
	public static toneReverseChart:any  = { "ā":"a", "á":"a", "ǎ":"a", "à":"a",
													"ē":"e", "é":"e", "ě":"e", "è":"e",
													"ī":"i", "í":"i", "ǐ":"i", "ì":"i",
													"ō":"o", "ó":"o", "ǒ":"o", "ò":"o",
													"ū":"u", "ú":"u", "ǔ":"u", "ù":"u",
													"ǖ":"ü", "ǘ":"ü", "ǚ":"ü", "ǜ":"ü",
													"ễ":"ê", "ế":"ê", "ể":"ê", "ề":"ê" };
	
	public static shengmuArray:any [] = ["y", "w", "b", "p", "m", "f", "d", "t", "n", "l", "g", "k", "h", "j", "q", "x", "zh", "ch", "sh", "r", "z", "c", "s",
											  "Y", "W", "B","P","M","F","D","T","N","L","G","K","H","J","Q","X","ZH","CH","SH","R","Z","C","S"];
	

											  static lowerPinYin:String = "āáǎàēéěèōóǒòūúǔùǖǘǚǜễếểềêīíǐìı";
	static upperPinYin:String = "ĀÁǍÀĒÉĚÈŌÓǑÒŪÚǓÙǕǗǙǛỄẾỂỀÊĪÍǏÌI";
	public static objToneMap: any = {
		"a": ["ā","á","ǎ","à"],
		"o": ["ō","ó","ǒ","ò"],
		"e": ["ē","é","ě","è"],
		"i": ["ī","í","ǐ","ì"],
		"u": ["ū","ú","ǔ","ù"],
		"ü": ["ǖ","ǘ","ǚ","ǜ"] 
	};

	public static capitalize(text:string):string
	{
		if (text)
		{
			var firstCharacter:string = text.charAt(0);
			if (/[A-z]/.test(firstCharacter))
			{
				firstCharacter = firstCharacter.toLocaleUpperCase()
			} else {
				var index:number = PinYinUtils.lowerPinYin.indexOf(firstCharacter);
				if (index != -1)
				{
					firstCharacter = PinYinUtils.upperPinYin.charAt(index);
				}
			}
			return firstCharacter + text.substring(1, text.length);
		} else {
			return text;
		}
	}

	static basicYunmuForm(yunmu: string): string {
		if (yunmu == "iou")
		{
			return "iu"
		} else if (yunmu == "uei")
		{
			return "ui";
		} else if (yunmu == "uen")
		{
			return "un";
		}
		return yunmu;
	}
	static toPrimitiveYT(yunmu: string, toneNumber: number, selectedTone: string): string {
		var index:number = PinYinUtils.findToneIndex(yunmu, toneNumber, selectedTone);
		if (index == -1)
		{
			return yunmu;
		}
		
		var prefix:string = yunmu.substring(0, index);
		var between:string= PinYinUtils.pinyinChart[ yunmu.charAt(index) + toneNumber];
		var subfix:string = yunmu.substr(index + 1);
		return prefix + between + subfix;
	}
	static findToneIndex(yunmu:string, toneNumber:number, selectedTone:string):number
	{
		if (toneNumber < 1 || toneNumber == 5)
		{
			return -1;
		}
		var index:number = yunmu.indexOf(selectedTone);
		if (index == -1)
		{
			
			if (selectedTone == "u" || selectedTone == "ü")
			{
				index = yunmu.search(/[uü]/g);
			}
		}
		return index;
	}

	public static getCorrectShengmu(shengmu:string, yunmu:string):string
	{
		if (shengmu == "w" || shengmu == "y" || shengmu == "")
		{
			if (PinYinUtils.lightShengmuChart.y.indexOf(yunmu) != -1)
			{
				shengmu = "y";
			} else if (PinYinUtils.lightShengmuChart.w.indexOf(yunmu) != -1)
			{
				shengmu = "w";
			} else {
				shengmu = "";
			}
		}
		return shengmu;
	}
	
	public static convertA(pinyin:string):string {
		pinyin = pinyin.replace(/a/g, 'ɑ').replace(/ā/g, 'ɑ̄').replace(/á/g, 'ɑ́').replace(/ǎ/g, 'ɑ̌').replace(/à/g, 'ɑ̀');
		return pinyin;
	}
	public static convertAreverse(pinyin:string):string {
		pinyin = pinyin.replace(/ɑ̄/g, 'ā').replace(/ɑ́/g, 'á').replace(/ɑ̌/g, 'ǎ').replace(/ɑ̀/g, 'à').replace(/ɑ/g, "a");
		return pinyin;
	}
	public static convertEI(pinyin:string):string {
		pinyin = pinyin.replace(/ễ/g, 'ê̄').replace(/Ễ/g, 'Ê̄').replace(/ể/g, 'ê̌').replace(/Ể/g, 'Ê̌');
		return pinyin;
	}
	public static convertEIreverse(pinyin:string):string {
		pinyin = pinyin.replace(/ê̄/g, 'ễ').replace(/Ê̄/g, 'Ễ').replace(/ê̌/g, 'ể').replace(/Ê̌/g, 'Ể');
		return pinyin;
	}
	
	public static showToneMarkOnly(pinyin:string):string 
	{
		if (!pinyin) {
			return "";
		}
		pinyin = pinyin.replace(/[āēīōūǖĀĒĪŌŪǕễỄ]/g,'ˉ').replace(/[áéíóúǘÁÉÍÓÚǗếẾ]/g,'ˊ').replace(/[ǎěǐǒǔǚǍĚǏǑǓǙểỂ]/g,'ˇ').replace(/[àèìòùǜÀÈÌÒÙǛềỀ]/g,'ˋ').replace(/[^ˉˊˇˋ \t]/g,' ');
		//pinyin = pinyin.replace(/[āēīōūǖĀĒĪŌŪǕ]/g, 'ˉ').replace(/[áéíóúǘÁÉÍÓÚǗ]/g, 'ˊ').replace(/[ǎěǐǒǔǚǍĚǏǑǓǙ]/g, 'ˇ').replace(/[àèìòùǜÀÈÌÒÙǛ]/g, 'ˋ');
		return pinyin;
	}
	
	private static simplifyToneMap:any [] = [
		{reg:/[āáǎà]/g, text:'a'}, {reg:/[āáǎà]/g, text:'a'}, {reg:/[ĀÁǍÀ]/g, text:'A'},
		// // ễếểề
		// "ê1":"ê̄", "ê2":"ế", "ê3":"ê̌", "ê4":"ề", 
		{reg:/[ēéěè]/g, text:'e'}, {reg:/[ĒÉĚÈ]/g, text:'E'},
		{reg:/[ōóǒò]/g, text:'o'}, {reg:/[ŌÓǑÒ]/g, text:'O'},
		{reg:/[ūúǔù]/g, text:'u'}, {reg:/[ŪÚǓÙ]/g, text:'U'},
		{reg:/[ǖǘǚǜ]/g, text:'ü'}, {reg:/[ǕǗǙǛ]/g, text:'Ü'},
		{reg:/[ễếểề]/g, text:'ê'}, {reg:/[ỄẾỂỀ]/g, text:'Ê'}
	]
	public static hideToneMark(pinyin:string, hideIhead:Boolean=true):string
	{
		//pinyin = pinyin.replace(/\w*[āáǎà]\w*/g, 'a').replace(/\w*[ɑ̄ɑ́ɑ̌ɑ̀]\w*/g, 'ɑ').replace(/\w*[ēéěè]\w*/g, 'e').replace(/\w*[īíǐì]\w*/g, 'ı').replace(/\w*[ōóǒò]\w*/g, 'o').replace(/\w*[ūúǔù]\w*/g, 'u').replace(/\w*[ǖǘǚǜ]\w*/g, 'ü');
		PinYinUtils.simplifyToneMap.forEach(function(mapping:any , index:number , reference:any []):void
		{
			pinyin = pinyin.replace(mapping.reg, mapping.text);
		});
		/*
		pinyin = pinyin
			.replace(/[āáǎà]/g, 'a')
			.replace(/[ĀÁǍÀ]/g, 'A')
			.replace(/[ēéěè]/g, 'e')
			.replace(/[ĒÉĚÈ]/g, 'E')
			.replace(/[ōóǒò]/g, 'o')
			.replace(/[ŌÓǑÒ]/g, 'O')
			.replace(/[ūúǔù]/g, 'u')
			.replace(/[ŪÚǓÙ]/g, 'U')
			.replace(/[ǖǘǚǜ]/g, 'ü')
			.replace(/[ǕǗǙǛ]/g, 'Ü')
			.replace(/[ễếểề]/g, 'ê')
			.replace(/[ỄẾỂỀ]/g, 'Ê');
			*/
		if(hideIhead){
			pinyin = pinyin.replace(/[īíǐì]/g, 'ı').replace(/[ĪÍǏÌ]/g, 'I');
		}else {
			pinyin = pinyin.replace(/[īíǐì]/g, 'i').replace(/[ĪÍǏÌ]/g, 'I');
		}
		return pinyin;
	}
	public static showToneCharacterOnly(pinyin:string):string
	{
		pinyin = pinyin.replace(PinYinUtils.NONpinyinRegExp, '');
		return pinyin;
	}
	public static findTonePosition(pinyin:string):number 
	{
		var toneStr:string = PinYinUtils.pinyinString;
		var char:string;
		var len:number  = pinyin.length;
		if (len > 0) {
			for (var i:number  = 0; i < len; i++ ) {
				char = pinyin.charAt(i);
				if (toneStr.indexOf(char) > -1) {
					return i;
				}
			}//end for
		}
		return -1;
	}
	public static findFirstToneCharacter(pinyin:string):string
	{
		var toneStr:string = "aeiouü";
		var char:string;
		var len:number  = pinyin.length;
		if (len > 0) {
			for (var i:number  = 0; i < len; i++ ) {
				char = pinyin.charAt(i);
				if (toneStr.indexOf(char) > -1) {
					return char;
				}
			}//end for
		}
		return "";
	}
	
	public static convertYunmuToWriteFormat(shengmu:string, yunmu:string):string
	{
		var yw:string = yunmu;
		if (yw != "")
		{
			if (shengmu == "y" || shengmu == "Y" || shengmu == "w" || shengmu == "W") { 
				if (PinYinUtils.writeConvertionYI[yw]) {
					yw = PinYinUtils.writeConvertionYI[yw]; //delete "i" if shengmu is "y"
				}
				if (PinYinUtils.writeConvertionWU[yw]) {
					yw = PinYinUtils.writeConvertionWU[yw]; //delete "u" if shengmu is "w"
				}
			}				
			if (PinYinUtils.writeConvertion0[yw]) {
				yw = PinYinUtils.writeConvertion0[yw]; //"iou"->"iu", "uei"->"ui", "uen"->"un"
			}
		}
		return yw;
	}
	
	//fnuciton to convert real format (yunmu) to write format (yunmu-tone)
	public static convertYunmuToYunmutone(shengmu:string, yunmu:string, toneNumber:number =0):string
	{
		var yunmutone:string = "";
		if (yunmu != "") {
			if (shengmu == "y" || shengmu == "Y" || shengmu == "w" || shengmu == "W") { 
				if (PinYinUtils.writeConvertionYI[yunmu]) {
					yunmu = PinYinUtils.writeConvertionYI[yunmu]; //delete "i" if shengmu is "y"
				}
				if (PinYinUtils.writeConvertionWU[yunmu]) {
					yunmu = PinYinUtils.writeConvertionWU[yunmu]; //delete "u" if shengmu is "w"
				}
			}				
			
			if(toneNumber > 0 && toneNumber <= 4){
				//managed in pinyinchart: "iou"->"iu", "uei"->"ui", "uen"->"un", "üan"->"uan", "ün"->"un", 
				yunmutone = PinYinUtils.pinyinChart[yunmu + String(toneNumber)]; 
			}else {
				//if no tone provided, also give a version without tone
				yunmutone = yunmu;
				if (PinYinUtils.writeConvertion0[yunmu]) {
					yunmutone = PinYinUtils.writeConvertion0[yunmu]; //"iou"->"iu", "uei"->"ui", "uen"->"un"
				}
				if (shengmu == "j" || shengmu == "J" || shengmu == "q" || shengmu == "Q" || shengmu == "x" || shengmu == "X" || shengmu == "y" || shengmu == "Y") { 
					if (PinYinUtils.writeConvertion1[yunmu]) {
						yunmutone = PinYinUtils.writeConvertion1[yunmu]; //"üan"->"uan", "ün"->"un"
					}
				}
			}
			if (shengmu == "j" || shengmu == "J" || shengmu == "q" || shengmu == "Q" || shengmu == "x" || shengmu == "X" || shengmu == "y" || shengmu == "Y") { 
				if (PinYinUtils.writeConvertion2[yunmutone]) {
					yunmutone = PinYinUtils.writeConvertion2[yunmutone]; //"ü"->"u", "üe"->"ue"
				}
			}
		}
		return yunmutone;
	}
	
	//fnuciton to convert write format (yunmu-tone) to real format (yunmu)
	public static convertYunmutoneToYunmu(shengmu:string, yunmutone:string):string
	{
		var yunmu:string = "";
		if(yunmutone != ""){
			var yunmutoneREAL:string = yunmutone;
			if(shengmu == "w" || shengmu == "W"){
				if(PinYinUtils.writeConvertionWUreverse[yunmutoneREAL]) {
					yunmutoneREAL = PinYinUtils.writeConvertionWUreverse[yunmutoneREAL]; //paste back "u" if shengmu is "w"
				}
			}
			if(shengmu == "y" || shengmu == "Y"){
				if(PinYinUtils.writeConvertionYIreverse[yunmutoneREAL]) {
					yunmutoneREAL = PinYinUtils.writeConvertionYIreverse[yunmutoneREAL]; //paste back "i" if shengmu is "y"
				}
			}
			if (shengmu == "j" || shengmu == "J" || shengmu == "q" || shengmu == "Q" || shengmu == "x" || shengmu == "X" || shengmu == "y" || shengmu == "Y") { 
				if(PinYinUtils.writeConvertion2reverse[yunmutoneREAL]) {
					yunmutoneREAL = PinYinUtils.writeConvertion2reverse[yunmutoneREAL]; //"u"->"ü", "ue"->"üe"
				}
				if (PinYinUtils.writeConvertion1reverse[yunmutoneREAL]) {
					yunmutoneREAL = PinYinUtils.writeConvertion1reverse[yunmutoneREAL]; //"uan"->"üan", "un"->"ün"
				}
			}
			yunmu = PinYinUtils.hideToneMark(yunmutoneREAL, false);
			if (PinYinUtils.writeConvertion0reverse[yunmu]) {
				yunmu = PinYinUtils.writeConvertion0reverse[yunmu]; //"iu"->"iou", "ui"->"uei", "un"->"uen"
			}
		}
		return yunmu;
	}
	
	public static convertToneToWriteFormat(shengmu:string, yunmu:string, tone:string):string
	{
		var t:string = tone;
		if (shengmu != "" && yunmu != "" && tone != "") { 
			//work around in case students choose ǖ but the write format has been converted to ū
			if (t=="ǖ" || t=="ǘ" || t=="ǚ" || t=="ǜ") {
				if(yunmu == "ü" || yunmu == "ün"){ 
					if (shengmu == "j" || shengmu == "J" || shengmu == "q" || shengmu == "Q" || shengmu == "x" || shengmu == "X" || shengmu == "y" || shengmu == "Y") { 
						t = PinYinUtils.writeConvertionUtone[t];
					}
				}
			}
		}
		return t;
	}
	public static convertToneFromWriteFormat(shengmu:string, yunmu:string, tone:string):string
	{
		var t:string = tone;
		if (yunmu != "" && tone != "") { 
			//work around in case students choose ǖ but the write format has been converted to ū
			if (t=="ū" || t=="ú" || t=="ǔ" || t=="ù") {
				if (yunmu == "ü" || yunmu == "ün") { 
					if (shengmu == "j" || shengmu == "J" || shengmu == "q" || shengmu == "Q" || shengmu == "x" || shengmu == "X" || shengmu == "y" || shengmu == "Y") { 
						t = PinYinUtils.writeConvertionUtoneReverse[t];
					}
				}
			}
		}
		return t;
	}
	
	public static analyzePinYin(pinyin:string):any 
	{
		var rlt:any [] = new Array();
		pinyin.split(/[ _\t\n\r]/).join("");
		var shengmu:string = "";
		var yunmutone:string = ""; //write format (with tone)   e.g. ǔan, iú
		var yunmu:string = "";     //real format (without tone)	e.g. üan, iou
		var tone:string = "";
		var toneSign:string = "";
		var toneNumber:number  = 0;
		var tonePosition:number  = 0;
		var char:string = pinyin.charAt(1);
		if (char == "h") { //zh, sh, ch
			shengmu = pinyin.substr(0, 2);
			yunmutone = pinyin.substr(2);
		}else {
			var charS:string = pinyin.substr(0, 1);
			if (PinYinUtils.shengmuArray.indexOf(charS) > -1) {
				shengmu = charS;
				yunmutone = pinyin.substr(1);
			}else {
				shengmu = ""; 		//零聲毋
				yunmutone = pinyin;
			}
		}
		yunmutone = PinYinUtils.convertAreverse(yunmutone); //ɑ to a
		yunmutone = PinYinUtils.convertEIreverse(yunmutone); //
		
		//convert write format (yunmu-tone) to real format (yunmu)
		yunmu = PinYinUtils.convertYunmutoneToYunmu(shengmu, yunmutone);
		//tone
		tonePosition = PinYinUtils.findTonePosition(yunmutone);
		if(tonePosition >= 0){
			tone = PinYinUtils.showToneCharacterOnly(yunmutone);
			tone = PinYinUtils.convertToneFromWriteFormat(shengmu, yunmu, tone);
			toneSign = PinYinUtils.showToneMarkOnly(tone);
			toneNumber = <number>(PinYinUtils.pinyinChart[toneSign]);
		}else {
			tone = ".";
			toneSign = ".";
			toneNumber = 5;
		}
		
		return {
			shengmu:shengmu, 
			yunmutone:yunmutone, 
			yunmu:yunmu, 
			tone:tone, 
			toneSign:toneSign, 
			toneNumber:toneNumber, 
			tonePosition:tonePosition
		}
		//rlt = new Array(shengmu, yunmutone, yunmu, tone, toneSign, toneNumber, tonePosition);
		
	}
	public static seperatePinYin(pinyin:string):any []
	{
		var o:any  = PinYinUtils.analyzePinYin(pinyin);
		return [o.shengmu, o.yunmutone, o.yunmu, o.tone, o.toneSign, o.toneNumber, o.tonePosition];
		/*
		var rlt:any [] = new Array();
		pinyin.split(/[ _\t\n\r]/).join("");
		//if (pinyin.length >= 2) {
			var shengmu:string = "";
			var yunmutone:string = ""; //write format (with tone)   e.g. ǔan, iú
			var yunmu:string = "";     //real format (without tone)	e.g. üan, iou
			var tone:string = "";
			var toneSign:string = "";
			var toneNumber:number  = 0;
			var tonePosition:number  = 0;
			var char:string = pinyin.charAt(1);
			if (char == "h") { //zh, sh, ch
				shengmu = pinyin.substr(0, 2);
				yunmutone = pinyin.substr(2);
			}else {
				var charS:string = pinyin.substr(0, 1);
				if (shengmuArray.indexOf(charS) > -1) {
					shengmu = charS;
					yunmutone = pinyin.substr(1);
				}else {
					shengmu = ""; 		//零聲毋
					yunmutone = pinyin;
				}
			}
			yunmutone = convertAreverse(yunmutone); //ɑ to a
			yunmutone = convertEIreverse(yunmutone); //
			
			//convert write format (yunmu-tone) to real format (yunmu)
			yunmu = convertYunmutoneToYunmu(shengmu, yunmutone);
			//tone
			tonePosition = findTonePosition(yunmutone);
			if(tonePosition >= 0){
				tone = showToneCharacterOnly(yunmutone);
				tone = convertToneFromWriteFormat(shengmu, yunmu, tone);
				toneSign = showToneMarkOnly(tone);
				toneNumber = int(pinyinChart[toneSign]);
			}else {
				tone = ".";
				toneSign = ".";
				toneNumber = 5;
			}
			
			rlt = new Array(shengmu, yunmutone, yunmu, tone, toneSign, toneNumber, tonePosition);
		//}
		
		return rlt;
		*/
	}
	
	public static combineYunmuAndToneChar(yunmu:string, toneChar:string):string
	{
		var str:string = "";
		var y:string;
		if (yunmu != "" && PinYinUtils.toneReverseChart[toneChar]) {
			for (var i:number  = 0; i < yunmu.length; i++ ) {
				y = yunmu.charAt(i);
				if (y == PinYinUtils.toneReverseChart[toneChar]) {
					str = yunmu.split(y).join(toneChar);
					break;
				}
			}
		}
		return str;
	}
	
}
