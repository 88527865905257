import { fromEvent, Observable, Subject, Subscription } from "rxjs";
import { FileIOService } from "src/app/service/FileIO.service";

class HTMLAudioPlayer
{
	public state:string;
	public playbackTime:number;
	public duration:number;
	constructor(private _snd:HTMLAudioElement)
	{
		["play", "playing", "timeupdate", "ended", "pause", "suspend", "loadeddata"].forEach((eventName:string)=>{
			this._snd.addEventListener(eventName, (e:any)=>{
				this.trigger(e.type, e);
			})
		})
	}

	trigger(type:string, event)
	{
		if(type == "play")
		{
			this.state = "playing";
		} else if(type == "playing")
		{
			this.state = "playing";
		} else if(type == "timeupdate")
		{
			this.playbackTime = event.currentTarget.currentTime * 1000;
		} else if(type == "ended")
		{
			this.state = "completed";
		} else if(type == "pause")
		{
			this.state = "paused";	
		} else if(type == "suspend")
		{
			this.state = "idle";
		} else if(type == "loadeddata")
		{
			this.duration = this._snd.duration * 1000;
		}
	}

	public play():Subject<any>
	{
		var subject:Subject<any> = new Subject();
		if(!this._snd) {
			
			subject.error ("no sound");
			return subject;
		}
		
		var sub:Subscription = new Subscription(()=>{});
		this.state = "playing";
		sub.add(
			fromEvent(this._snd, "ended").subscribe(()=>{
				sub.unsubscribe();
				console.log("ended");
				this.state = "idle";
				subject.complete();
				
			})
		);
		sub.add(
			fromEvent(this._snd, "pause").subscribe(()=>{
				sub.unsubscribe();
				console.log("pause");
				this.state = "idle";
				subject.complete();
				
			})
		);
		this._snd.play();
		return subject;
		
	}
	public stop():void
	{
		if(this._snd)
		{
			this._snd.pause();
			this.state = "idle";
		}
	}
}

export class RecorderAndPlayer
{
	
	
	
	public playing:boolean;
	public recording:boolean;
	public player:HTMLAudioPlayer;
	public recorder:any;
	public state:any = "idle";
	public recordingState:any;
	public recordingTime:number = 0;
	public audioFile:any;
	public hasRecording:boolean;
	
	constructor(private fileIO:FileIOService)
	{
		
	}

	public reset():void
	{
		this.state = "idle";
		this.audioFile = null;
		this.hasRecording = false;
	}
	
	public setupAudioSound(snd:HTMLAudioElement):void
	{
		this.player = new HTMLAudioPlayer(snd);
	}

	setupAudioFromURL(url: string) {
		if(url)
		{
			var snd = this.fileIO.createSoundFromURL(url);
			this.setupAudioSound(snd);
			this.hasRecording = true;
		} else {
			this.hasRecording = false;
		}
	}

	getDuration():number{
		if(!this.hasRecording) return 0;
		return this.player.duration;
	}


	public setupAudioFile(file:any):void
	{
		if(file)
		{
			this.audioFile = file;
			var snd = this.fileIO.createSoundFromFile(file);
			this.setupAudioSound(snd);
			this.hasRecording = true;
		} else {
			this.hasRecording = false;
		}
	}

	public play():Promise<any>
	{
		if(this.state !== "idle" || !this.player) return Promise.reject("not idle");
		this.state = "playing";
		return this.player.play().toPromise().then(()=>{
			this.state = "idle";
		}).catch(()=>{
			this.state = "idle";
		})
	}
	public stop():void
	{
		if(this.state !== "playing" || !this.player) return;
		this.state = "idle";
		this.player.stop();
	}

	public record():Promise<any>
	{
		if(this.state !== "idle") return Promise.reject("not allowed");
		this.state = "recording";
		this.recordingTime = 0;
		return new Promise((resolve, reject)=>{
			var subscription:Subscription = this.fileIO.record2(
				31, "wav"
			).subscribe(
				(data:any)=>{
					if(data.type == "recorder")
					{
						this.recorder = data.recorder;
						this.recordingState = "start";
					} else if(data.type == "output")
					{
						if(data.sound.type == "file")
						{
							this.recordingState = "completed";
							console.log("data", data);
							resolve(data);
							this.setupAudioFile(data.sound.file);
							this.state = "idle";
						}
					} else if(data.type == "time")
					{
						this.recordingTime = data.time * 1000;
						// this.recordingTime = data.time ;
						// console.log("recordingTime", this.recordingTime);
					}
				},
				(reason:any)=>{
					this.recordingState = "rejected";
					subscription.unsubscribe();
					this.releaseRecorder();
					reject(reason);
				},
				()=>{
					this.recordingState = "end";
					subscription.unsubscribe();
					this.releaseRecorder();
				}
			);
		});

		
	}
	
	public finishRecording() {
		if(this.recorder)
		{
			this.recorder.finishRecording();
		}
	}
	private releaseRecorder():void
	{
		if(this.recorder)
		{
			this.recorder.cancelRecording();
			this.recorder.cancelEncoding();
			this.recorder = null;
		}
	}

}
