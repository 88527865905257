import { AfterViewInit, Component,Input, NgZone,  OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { IAudioPlayer, ROAudioPlayerManager } from "./TTSManager";

@Component({
	selector: 'ro-audio-player',
	template:`
			<div 
				[class]="state" style="height:0px">
				<div class="player" [class.show-player]="player">
					<div class="skin flex">
						<div class="button  play-btn size40" (click)="toggle()"></div>
						<div class="track-container" [class.show-timeline]="hasTimeline">
							<div class="flex">
								<div class="slider-container">
									<input 
										class="slider"
										type="range" 
										min="0" 
										[attr.max]="duration" 
										[(ngModel)]="currentTime" 
										step="0.1"
										class="slider" 
										(mousedown)="startDragging()"
										(change)="sliderChange($event)"
									>
								</div>
								<span class="time-info">
									<span class="current-time">{{currentTime | date:'mm:ss'}}</span>
									/
									<span class="duration">{{duration | date:'mm:ss'}}</span>
								</span>
							</div>
						</div>

						<div *ngIf="showCloseBtn" class="button close-btn size40" (click)="close()"></div>
					</div>
				</div>
			</div>
	`,
	styleUrls:["ROAudioPlayerComponent.scss"]
})
export class ROAudioPlayerComponent implements AfterViewInit, OnDestroy
{
	public hasTimeline:boolean = true;
	public showCloseBtn:boolean = true;
	state:string;
	@Input() playerManager:ROAudioPlayerManager;
	public player:IAudioPlayer;
	
	subscription: Subscription;
	playerSubscription: Subscription;
	
	public duration:number;
	public currentTime:number;
	public dragging:boolean;

	constructor(private ngZone:NgZone)
	{
		this.state = "idle";
		this.subscription = new Subscription(()=>{});
		this.playerSubscription = new Subscription(()=>{});
	}
	startDragging():void
	{
		this.dragging = true;
	}
	toggle():void
	{
		if(this.player)this.player.toggle();
	}
	sliderChange(event:any):void
	{
		this.dragging = false;
		if(this.player) {
			this.currentTime = parseFloat(event.target.value);
			var value = this.currentTime / 1000;
			this.player.moveTo(value);
		}
	}
	replacePlayer(p:IAudioPlayer):void
	{
		if(this.player != p)
		{
			this.releasePlayer();
			this.player = p;
			this.setupPlayer();
			this.play();
		}
	}
	
	ngAfterViewInit(): void {
		this.subscription.add(
			this.playerManager.subject.subscribe((data:any)=>{
				if(data.type == "player")
				{
					// on Player changed
					this.replacePlayer(data.player);
				} 
			})
		);
	}

	ngOnDestroy(): void 
	{
		this.releasePlayer();
		this.subscription.unsubscribe();
	}

	private setupPlayer():void
	{
		this.duration = 1000;
		this.currentTime = 0;
		if(this.player)
		{
			this.duration = this.player.getDuration() * 1000;
			this.currentTime = this.player.getCurrentTime() * 1000;
			this.hasTimeline = this.player.hasTimeline();
			var that = this;
			this.playerSubscription.add(
				this.player.subject.subscribe((data:any)=>{
					// console.log("player event", data);
					if(data.type == "ready")
					{
						if(that.player == data.player)
						{
							// console.log("play on Ready");
							that.player.play();	
						}
					} else if(data.type == "state")
					{
						that.state = data.state;
						if(that.state == "completed") that.currentTime = 0;
					} else if(data.type == "duration")
					{
						that.duration = data.duration * 1000;
					} else if(data.type == "time")
					{
						if(!that.dragging)
						{
							that.currentTime = data.time * 1000;
						}
					} else if(data.type == "close")
					{
						that.replacePlayer(null);
					}
				})
			)
		} else {
			// this.hasTimeline = false;
		}
	}
	private releasePlayer():void
	{
		if(this.player)
		{
			this.player.pause();
		}
		this.playerSubscription.unsubscribe();
		this.playerSubscription = new Subscription(()=>{});

	}

	play():void
	{
		if(this.player && this.player.isReady){
			// console.log("player was ready play now");
			this.player.play();
		}
		
	}

	stop():void
	{
		if(this.player) this.player.stop();
	}
	close()
	{
		/*
		if(this.player)
		{
			this.player.close();
		}
		*/
		this.replacePlayer(null);
	}

};