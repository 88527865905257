import { Component, Input, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'dateTimePicker3SelectYear',
    template: `
<div class="rowYearOption">
    <fa-icon [icon]="faChevronLeft" class="switchYearBtn" (click)="generateYearOptions(years[0] - 12)"></fa-icon>
    <div class="yearBtn">{{(years && years[0] ? years[0] + " - " + years[years.length-1] : "")}}</div>
    <fa-icon [icon]="faChevronRight" class="switchYearBtn" (click)="generateYearOptions(years[0] + 12)"></fa-icon>
</div>

<div class="rowYear">
    <div
        class="year"
        *ngFor="let year of years; let i=index;"
        [class.selected]="date.getFullYear()==year"
        (click)="onChange.emit(year)"
    >{{year}}</div>
</div>
    `,
    styleUrls: ['./dateTimePicker3SelectYear.component.scss']
})

export class DateTimePicker3SelectYearComponent implements OnChanges {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

    public faChevronLeft:IconDefinition = faChevronLeft;
    public faChevronRight:IconDefinition = faChevronRight;

    @Input() public date:Date = new Date();

	public years:any[] = [];

    @Output() public onChange:EventEmitter<any> = new EventEmitter();

    constructor() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.date && changes.date.currentValue) {
            let tempYear = changes.date.currentValue.getFullYear();
			if (!tempYear) tempYear = new Date().getFullYear();
			this.generateYearOptions(tempYear - 4);
        }
    }
    //---------------------------------------------------------------------------------------------
	public generateYearOptions(year:number):void {
		if (!year) year = new Date().getFullYear()
		let result:any[] = [];

		for (let i=0; i<12; i++) {
			result.push(year);
			year++;
		}
		
		this.years = result;
	}
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
}
