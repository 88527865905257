import { Component, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTimePicker3Component } from './dateTimePicker3.component';


@Component({
    selector: 'dateTimePicker3SelectTime',
    template: `
<div class="rowTime">
    <div class="labelCol">{{{tc:'時',sc:'时',en:'hr'}[translate.currentLang]}}: </div>
    <div class="selectCol">
        <div
            class="timeBtn"
            [class.selected]="getHour()==hour"
            *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12]"
            (click)="setHour(hour)"
        >{{hour}}</div>
    </div>
</div>
<div class="rowMinute">
    <div class="labelCol">{{{tc:'分',sc:'分',en:'min'}[translate.currentLang]}}: </div>
    <div class="selectCol">
        <div
            class="timeBtn"
            [class.selected]="getMinute()==minute"
            *ngFor="let minute of ['00','05','10','15','20','25','30','35','40','45','50','55']"
            (click)="setMinute(minute)"
        >{{minute}}</div>
    </div>
</div>
<div *ngIf="dtp && dtp.showCustomMinutesOption" class="rowCustomMinutes" [class.short]="!enablePickDate">
	<div class="labelCol"></div>
	<div class="customMinutesBtn" [class.selected]="tempObj.customMinutes" [ngClass]="translate.currentLang" (click)="tempObj.customMinutes=!tempObj.customMinutes">{{{tc:'自定',sc:'自定',en:'Custom'}[translate.currentLang]}}...</div>
	<okaPulldown2
		*ngIf="tempObj.customMinutes"
		[okaPulldownStyle]="{width:'60px'}"
		[options]="minutesOptions"
		[ngModel]="getMinuteString()"
		(ngModelChange)="setMinute($event)"
	></okaPulldown2>
</div>
<div class="rowAmPm" [class.short]="!enablePickDate">
    <div
        class="amPmBtn"
        [class.selected]="isAm()"
        (click)="!isAm() && date.setHours(date.getHours() - 12) && timeClick.emit(date)"
    >am</div>
    <div
        class="amPmBtn"
        [class.selected]="!isAm()"
        (click)="isAm() && date.setHours(date.getHours() + 12) && timeClick.emit(date)"
    >pm</div>
</div>
    `,
    styleUrls: ['./dateTimePicker3SelectTime.component.scss']
})

export class DateTimePicker3SelectTimeComponent {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

	@Input() public dtp:DateTimePicker3Component = null;
    @Input() public lang:string;
	@Input() public tempObj:any = {};
    @Input() public date:Date = new Date();
	@Input() public enablePickDate:boolean = true;

    @Output() public timeClick:EventEmitter<Date> = new EventEmitter();

	public minutesOptions:any[] = (()=>{
		let options:any[] = [];
		for (let i=0; i<60; i++) options.push({ label: (i<10?"0":"") + i, value: i });
		return options;
	})();

    constructor(public translate:TranslateService) {
    }

    public getHour():number {
        let hour:number = this.date ? this.date.getHours() : 0;
        if (hour==0) return 12;
        else if (hour<13) return hour;
        else return hour - 12;
        //return hour < 13 ? hour : hour - 12;
    }

    public isAm():boolean {
        return this.date && this.date.getHours()<12;
    }

    public setHour(hour:number):void {
        if (this.isAm()) {
            if (hour==12) {
                this.date.setHours(0);
            } else {
                this.date.setHours(hour);
            }
        } else {
            if (hour==12) {
                this.date.setHours(12);
            } else {
                this.date.setHours(hour + 12);
            }
        }
        this.timeClick.emit(this.date);
    }

    public getMinute():number {
        return this.date ? this.date.getMinutes() : 0;
    }

	public getMinuteString():string {
		let min:number = this.getMinute();
		return (min < 10 ? "0" : "") + min.toString();
	}

    public setMinute(minute:string):void {
		let min:number = parseInt(minute)
        this.date.setMinutes(min);
        this.timeClick.emit(this.date);
    }


}
