import { Component, EventEmitter, Input, Output } from "@angular/core";
import { url } from "inspector";
import { CommonService } from "src/app/service/common.service";
import { DataService } from "src/app/service/data.service";
import { ScriptService } from "src/app/service/script.service";
import { InlineSvgService } from "../../inlineSvgModule/inlineSvg.service";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { faPeriod } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "finalReport",
	templateUrl: "./finalReport.html",
	styleUrls: ["./finalReport.scss"]
})
export class speechPracticeFinalReport {
	@Input() public parent;
	@Input() public subType;
	@Input() public bookTitle;
	@Input() public passLevel;
	@Input() public totalPageNumber;
	@Input() public userTotalScore;
	@Input() public totalScoreInEachQuestion;
	@Input() public objectiveUsed;
	@Input() public conversationData;
	@Input() public totalStarNumber;
	@Input() public aiOralfinalScore;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	// For exercise practice
	public starNumber = "0";
	public firstStatement = "";
	public secondStatement = "";
	public totalResult = 0;
	public finalResult = 0;
	public isPass;

	public courseSuccessBackground = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_success_board.svg')`;
	public courseSuccessPeople = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_success_people.svg')`;
	public courseFailBackground = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_fail_board.svg')`;
	public courseFailPeople = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_fail_people.svg')`;
	public courseEndBackground = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_end_board.svg')`;
	public yellowStar = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/star_yellow.svg')`;
	public grayStar = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/star_gray.svg')`;

	// For conversation practice
	public oralVocabAccuracyScore;
	public oralVocabCompletenessScore;
	public oralVocabFluencyScore;
	public oralVocabPronunciationScore;
	public faXmark = faXmark;
	public faPeriod = faPeriod;
	public isDetailShown = false;

	public animation_stages = []
	public animation_stage = ""
	public display_level = 0;
	public firstEDBScore = 0;
	public secondEDBScore = 0;

	// For both practice
	public lang = this.dataService.lang;
	public conversationPractice = ["oralVocab", "oralSentence", "oralFillIn", "oralMc", "oralParagraph"];
	public objective = [
		{ content: "Produce simple phrases and sentences involving repetition or lists" },
		{ content: "Use simple phrases and sentences to communicate with others" },
		{ content: "Connect ideas by using cohesive devices" },
		{ content: "Give a simple description to express meaning of an unknown word" },
		{ content: "Using single words and formulaic expressions to acknowledge, agree and disagree, ask questions and reply" },
		{ content: "Providing information in response to factual or yes/no questions" },
		{ content: "Verbalising inability to understand or asking for slower repetition of an utterance" },
		{ content: "Asking for spelling" },
		{ content: "Repeating questions and answers if they are not understood" },
	];

	constructor(public dataService: DataService, public common: CommonService, public script: ScriptService, public svgReg: InlineSvgService) { }

	ngOnInit() {
		if (!this.parent.conversationPractice.includes(this.subType)) {
			this.handleExercise();
		} else {
			this.handleConversation();
		}
		// this.handleConversation();
	}

	close() {
		this.emitter.emit({ action: "close", status: false });
	}

	reTry() {
		this.emitter.emit({ action: "reTry" });
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}

	handleExercise() {
		this.totalResult = this.totalPageNumber * 100;
		this.finalResult = Math.round(this.userTotalScore / this.totalResult * 100);

		if (this.finalResult >= 25) this.starNumber = "1";
		if (this.finalResult >= 50) this.starNumber = "2";
		if (this.finalResult >= 75) this.starNumber = "3";
		// this.isPass = this.finalResult > this.passLevel;
		this.isPass = this.starNumber !== "0";

		this.firstStatement = this.isPass ? this.translateText(
			"恭喜你!",
			"恭喜你!",
			`Congratulations! You won ${this.starNumber} stars!`
		) : this.translateText(
			"未能成功通過:",
			"未能成功通过:",
			`Try to win 3 stars!`
		)
		this.secondStatement = this.isPass ? this.translateText(
			`下一節: 下一節名稱`,
			`下一节: 下一节名称`,
			`Next level: Next level`
		) : this.translateText(``, ``, ``)
	}

	handleConversation() {
		if(this.subType === "oralAI"){
			let oralTotalScore = 0;
			for (const key in this.aiOralfinalScore) {
				if (this.aiOralfinalScore.hasOwnProperty(key)) { 
					oralTotalScore += this.aiOralfinalScore[key]; 
				}
			}
			console.log("oralTotalScore", oralTotalScore);
			this.finalResult = oralTotalScore * 100 /30;
		}else{
			this.finalResult = Math.round(this.conversationData.reduce((sum, item) => sum + item.PronScore, 0) / this.conversationData.length);
			this.oralVocabAccuracyScore = Math.round(this.conversationData.reduce((sum, item) => sum + item.AccuracyScore, 0) / this.conversationData.length);
			this.oralVocabCompletenessScore = Math.round(this.conversationData.reduce((sum, item) => sum + item.CompletenessScore, 0) / this.conversationData.length);
			this.oralVocabFluencyScore = Math.round(this.conversationData.reduce((sum, item) => sum + item.FluencyScore, 0) / this.conversationData.length);
	
			console.log(this.oralVocabAccuracyScore, this.oralVocabCompletenessScore, this.oralVocabFluencyScore, this.finalResult)
	
			this.parent.pronScore = this.finalResult;
			this.parent.accuracyScore = this.oralVocabAccuracyScore;
			this.parent.completenessScore = this.oralVocabCompletenessScore;
			this.parent.fluencyScore = this.oralVocabFluencyScore;
		}
		
		this.parent.autoSubmit();
		this.playAnimation()
	}

	playAnimation() {
		this.display_level = this.totalStarNumber;

		if (this.finalResult >= 25) {
			let pass_game_audio = <HTMLVideoElement>document.getElementById("pass_game_audio")
			pass_game_audio.pause()
			pass_game_audio.currentTime = 0
			pass_game_audio.play()

			this.animation_stages = ['start', 'repel', 'after_repel', "show_level"]
			this.animation_stages.push("add1_start", "add1_end")
			if (this.finalResult >= 50) this.animation_stages.push("add2_start", "add2_end")
			if (this.finalResult >= 75) this.animation_stages.push("add3_start", "add3_end")
			this.animation_stages.push("end")

			let animation_idx = 0
			this.animation_stage = this.animation_stages[animation_idx] //0
			animation_idx++

			let ding_audio = <HTMLVideoElement>document.getElementById("ding_audio")
			let continue_animation = (idx, stages) => {
				let timeout = 400
				this.animation_stage = stages[idx]
				if (['add1_start', 'add2_start', 'add3_start'].indexOf(this.animation_stage) != -1) {
					this.display_level++
					timeout = 150
					ding_audio.pause()
					ding_audio.currentTime = 0
					ding_audio.play()
				}
				if (stages[idx] != 'end') {
					setTimeout(() => {
						continue_animation(idx + 1, stages)
					}, timeout);
				}
			}
			setTimeout(() => {
				this.animation_stage = this.animation_stages[animation_idx] //1
				animation_idx++
				setTimeout(() => {
					this.animation_stage = this.animation_stages[animation_idx] //2
					animation_idx++
					setTimeout(() => {
						this.animation_stage = this.animation_stages[animation_idx] //3
						animation_idx++
						setTimeout(() => {
							continue_animation(animation_idx, this.animation_stages)
						}, 100);
					}, 200);
				}, 300)
			}, 1);
		} else {
			let fail_game_audio = <HTMLVideoElement>document.getElementById("fail_game_audio")
			fail_game_audio.pause()
			fail_game_audio.currentTime = 0
			fail_game_audio.play()
			let animation_idx = 0
			this.animation_stages = ['start', 'jump', 'end']

			this.animation_stage = this.animation_stages[animation_idx]
			animation_idx++
			setTimeout(() => {
				this.animation_stage = this.animation_stages[animation_idx]
				animation_idx++
				setTimeout(() => {
					this.animation_stage = this.animation_stages[animation_idx]
					animation_idx++
				}, 200);
			}, 100);

		}
	}
}