import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MainMenuComponent } from './mainMenu.component';
import { UiSwitchModule } from 'ngx-ui-switch';


@NgModule({
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        TranslateModule,
        FontAwesomeModule,
		UiSwitchModule
    ],
    declarations: [
        MainMenuComponent
    ],
    exports: [
        MainMenuComponent
    ],
    providers: [],
    bootstrap: []
})
export class MainMenuModule { }
