import { NgModule } from '@angular/core';
import { VkbDirective } from './vkb.directive';


@NgModule({
    imports: [
        //CommonModule
    ],
    declarations: [
        VkbDirective
    ],
    exports: [
        VkbDirective
    ],
    providers: [],
    bootstrap: []
})
export class VkbModule { }
