import { AfterViewInit, ApplicationRef, Component, ElementRef, HostListener, RendererFactory2, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { faCheckCircle, faCircleMinus } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { CommonService } from 'src/app/service/common.service';
import { OkaPulldown2Component } from '../okaPulldownModule/okaPulldown2.component';
import { DataService } from 'src/app/service/data.service';

@Component({
	selector: "okaPulldown2-header",
	template: `
	<div class="label" *ngIf="params.label" [ngStyle]="params.labelStyle">{{params.label}}</div>
	<ng-container *ngIf="selectedItem">
		<div class="fake" #fake></div>
		<okaPulldown2 [options]="items" #okaPulldown 
			[ngClass]="[(datas.lang?datas.lang:''),params.okaPulldownEleClass?params.okaPulldownEleClass:'', params.hiddenElement?'hiddenElement':'']"
			[okaPulldownStyle]="okaPulldownStyle" [menuStyle]="okaPulldownMenuStyle"
			bindLabel="label" bindValue="value" [ngStyle]="okaPulldownHostStyle"
			[(ngModel)]="params.output" (ngModelChange)="pulldownChange($event)"></okaPulldown2> 
	</ng-container>
    `,
	styleUrls: ['./okaPulldown2.header.scss']
})


export class OkaPulldown2Header implements AfterViewInit {
	public params: any;
	public text = '';
	public items:any[] = [];
	public selectedItem;
	public okaPulldownHostStyle:any = {};
	public okaPulldownStyle:any = {};
	public okaPulldownMenuStyle:any = {};
	@ViewChild('okaPulldown', {static: false}) dropdown: OkaPulldown2Component;
	@ViewChild('fake', {static: false}) fake;
	constructor(private factory: RendererFactory2, private eleRef: ElementRef, private appRef: ApplicationRef, private common: CommonService, public datas: DataService){

	}

	ngAfterViewInit() {

		let renderer = this.factory.createRenderer(null, null);
		let agEle = this.eleRef.nativeElement.closest('ag-grid-angular');
		let agRoot = this.params.api.gridBodyCon.eGridBody;
		let okaPulldownEle = this.eleRef.nativeElement.querySelector('okaPulldown2');
		if (this.okaPulldownHostStyle){
			for(let prop in this.okaPulldownHostStyle){
				okaPulldownEle.style[prop] = this.okaPulldownHostStyle[prop];
			}
		}
		if (okaPulldownEle){
			this.fake.nativeElement.style.width = okaPulldownEle.width + 'px';
			renderer.appendChild(agRoot, okaPulldownEle);
		}
	}

	ngOnDestroy(): void {
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		if (this.params){
			if (this.params.okaPulldownStyle){
				this.okaPulldownStyle = this.params.okaPulldownStyle;
			}
			if (this.params.okaPulldownMenuStyle){
				this.okaPulldownMenuStyle = this.params.okaPulldownMenuStyle;
			}
			if (this.params.okaPulldownHostStyle){
				this.okaPulldownHostStyle = this.params.okaPulldownHostStyle;
			}
		}
		if (this.params.hiddenElement){
			this.eleRef.nativeElement.style.display = 'none';
		}
		
		this.items = this.params.items;
		this.selectedItem = this.params.output;
	}

	refresh(params: ICellRendererParams){
		let value = this.params.output;
		this.selectedItem = this.items.find(e=> e.value == value);
		return true;
	}

	isPopup(): boolean {
        return true;
    }

	pulldownChange(event){
		if (this.params.callback){
			this.params.callback(event);
		}
	}


}
