import { fromEvent, Observable, Subscription } from "rxjs";
import { ScriptLoader } from "./ScriptLoader";
/**
 * new ByteLoader().load("GET", "URL").then((buffer:ArrayBuffer)=>{
 * })
 */
export class ByteLoader {
	constructor(public isXHR2:boolean = true)
	{
		
	}
	load(method:string, url:string):Promise<any>
	{
		return new Promise((resolve, reject)=>{
			var xmlHttpRequest = new XMLHttpRequest();
			xmlHttpRequest.open(method, url, true);

			
			if (this.isXHR2) {
				xmlHttpRequest.responseType = "arraybuffer";
			} else {
				xmlHttpRequest.overrideMimeType("text/plain; charset=x-user-defined");
			}

			xmlHttpRequest.onreadystatechange =  function()
			{
				var readyState = xmlHttpRequest.readyState;
				if (readyState === 4) {
					var status = xmlHttpRequest.status;
					switch (status) {
						case 200:
						case 304:
							var data = (this.isXHR2) ? xmlHttpRequest.response : xmlHttpRequest.responseText;
							resolve(data);
							break;
						default :
							// alert(xmlHttpRequest.statusText);
							reject(xmlHttpRequest.statusText);
							break;
					}
				}
			}.bind(this);
			xmlHttpRequest.send(null);	
		});
	}
	post(url):Promise<any>
	{
		return this.load("POST", url);
	}
	
	get(url):Promise<any>
	{
		return this.load("GET", url);
	}
}

export class FileUtils {
	public  static async fromURL(url:string, name:string, type:string):Promise<File>
	{
		var loader = new ByteLoader();
		var data = await loader.load("GET", url);
		return new File([data], name, {type:type});
	}
	public static saveAs(content:any, filename:string):Promise<any>
	{
		return ScriptLoader.load("assets/js/FileSaver.min.js", "saveAs").then((o)=>{
			var win:any = window;
			win.saveAs(new Blob([content],{type:"application/octet-stream"}), filename);
		});  
	}
	/**
	 	FileUtils.loadFile(file).then((buffer:ArrayBuffer)=>{
	 	
		});
	 * @param file 
	 * @returns 
	 */
	public static loadFile(file): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener('load', (event: any) => {
				resolve(<ArrayBuffer> reader.result);
			});
			reader.addEventListener("abort", (event) => {
				reject("abort");
			})
			reader.addEventListener("error", (event) => {
				reject("error");
			})
			reader.readAsArrayBuffer(file);
		});
	}



	/**
	 * 
	 * @param accept "image/png, image/jpeg"
		  accept="image/png"
		accept = ".png"


		accept = "image/png, image/jpeg"
		accept = ".png, .jpg, .jpeg"

		accept = "image/*"
		accept = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		
	 */
	public static browseForFiles(accept: string = null): Promise<any> {
		return new Promise((resolve, reject) => {
			var inputElement: HTMLInputElement = document.createElement("input");
			inputElement.multiple = true;
			inputElement.type = "file";
			inputElement.accept = accept;
			var sub: Subscription = fromEvent(window, "focus").subscribe((o: any) => {
				sub.unsubscribe();
				setTimeout(() => {
					if (inputElement.files.length) {
						resolve(inputElement.files);
					} else {
						reject("cancel");
					}
				}, 300);
			});
			inputElement.dispatchEvent(new MouseEvent("click"));
		})
	}

	
	/**
	 * 
	 * @param accept "image/png, image/jpeg"
		  accept="image/png"
		accept = ".png"


		accept = "image/png, image/jpeg"
		accept = ".png, .jpg, .jpeg"

		accept = "image/*"
		accept = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		
	 */
	public static browseForFile(accept: string = null): Promise<any> {
		return new Promise((resolve, reject) => {
			var inputElement: HTMLInputElement = document.createElement("input");
			inputElement.multiple = false;
			inputElement.type = "file";
			inputElement.accept = accept;
			var sub: Subscription = fromEvent(window, "focus").subscribe((o: any) => {
				sub.unsubscribe();
				setTimeout(() => {
					if (inputElement.files.length) {
						resolve(inputElement.files[0]);
					} else {
						reject("cancel");
					}
				}, 300);
			});
			inputElement.dispatchEvent(new MouseEvent("click"));
		});
	}
	
	public static browseForFile2(accept: string = null): Promise<any> {
		return new Promise((resolve, reject) => {
			var inputElement: HTMLInputElement = document.createElement("input");
			inputElement.multiple = false;
			inputElement.type = "file";
			inputElement.accept = accept;
			var sub: Subscription = fromEvent(window, "focus").subscribe((o: any) => {
				sub.unsubscribe();
				setTimeout(() => {
					if (inputElement.files.length) {
						resolve(inputElement.files[0]);
					} else {
						reject("cancel");
					}
				}, 1000);
			});
			inputElement.dispatchEvent(new MouseEvent("click"));
		});
	}

	public static uploadFile(name:string, file: any, url: string): Promise<any> {
		// enctype="multipart/form-data" 
		return new Promise((resolve, reject)=>{
			var formData:FormData = new FormData();
			formData.append(name, file);
			var request:XMLHttpRequest = new XMLHttpRequest();
			request.open("POST", url);
			request.onload = (oEvent)=>{
				if (request.status == 200) {
					try
					{
						var json = JSON.parse(request.responseText);
						resolve(json);
					} catch(err)
					{
						reject(request.responseText);
					}
				} else {
					reject(request.responseText)
				}
			};
			request.onerror = (event:any) => { // only triggers if the request couldn't be made at all
				reject(event.type);
			};
			request.send(formData);
		})
		
		// return fetch(url, { method: "POST", body: formData });
	}
	/*
	public static readAsDataURL(file:any):Promise<any>
	{

	}
	*/
}