import { NgModule } from '@angular/core';
import { ScoringComponent } from './scoring.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
		FontAwesomeModule
    ],
    declarations: [
        ScoringComponent
    ],
    exports: [
        ScoringComponent
    ],
    providers: [],
    bootstrap: []
})
export class ScoringModule { }
