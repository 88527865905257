import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'myDate',
  // pure: false
})

export class MyDatePipe implements PipeTransform {
  transform(value: any, format: string) {
    if (value == null){
      return null;
    } else if (value instanceof Date) {
      return moment(value).format(format);
    } else if (moment.isMoment(value)) {
      return value.format(format);
    } else if (!isNaN(new Date(value).getMonth() ) ){
      return moment(new Date(value)).format(format);
    } else if (value.length == 19){ //2019-02-27 17:07:16
      return moment(value).format(format);
    } else {
      return value;
      return moment.unix(value).format(format); //DD/M
    }
  }
}
