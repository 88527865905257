
import { AfterViewInit, ApplicationRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { DataService } from "src/app/service/data.service";

import { RODocumentService } from 'src/app/sharedModule/roModule/RODocumentService';
import { HammerControl } from "src/app/sharedModule/panZoomModule/HammerControl";
import { HammerTransformControl } from "src/app/sharedModule/panZoomModule/HammerTransformControl";
import { fromEvent, Observable, Subscriber, Subscription } from "rxjs";
import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
// import { ROPageComponent } from "../BookViewer.component";
import { DOMHelper } from "src/app/common/DOMHelper";
// import { faChevronLeft, faChevronRight, faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
// import { faPaperPlaneTop, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { AlertService } from "src/app/service/alert.service";
import { ROPageComponentContainer } from "./ROPage";
import { ResourceSource, ROAnswerAssetUpload, ROComponentDefinition, ROContext, ROContextService, ROLayerEditManager } from "./ROContext";
import {  ROComponent, ROComponentMap, ROContainerComponent, ROGraphicComponent, ROImageComponent, ROPageComponent, ROShapeCircleComponent, ROShapeCustomComponent, ROShapeRectangleComponent, ROSuperTextComponent, ROTLFTextComponent, ROUnknownComponent } from "./ROComponent";
// RODemoComponent, 
import { AnswerSource, ROBookConfig } from "./ROBookConfig";
import { XMLNode } from "./xml/XMLNode";
import {DomSanitizer, SafeStyle, Title} from "@angular/platform-browser";

import { AllROComponents } from "./AllROComponent";

import { IAudioPlayer, ROAudioPlayerManager } from "./TTSManager";
import { FileIOService } from "src/app/service/FileIO.service";
import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';
import { TranslateService } from "@ngx-translate/core";


import { TopLayer } from "src/app/common/TopLayer";
import { StyleUtils } from "./StyleUtils";
import { RODocument } from "./RODocument";
import { js2xml, xml2js } from "xml-js";
import { ByteArrayUtils } from "src/app/ro/hk/openknowledge/utils/ByteArrayUtils";
import { RODoc } from "src/app/ro/hk/openknowledge/ro/RODoc";
import { ROBookStructureReader } from "src/app/ro/hk/openknowledge/ro/RODocumentDecoder";
import { SelectMarkerComponent } from "./SelectMarker.component";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { LearningObjectivePopupSelector } from "../subjectModule/node-selector.component";
import { NumberPadModal } from "../numberPadModule/numberPad.component";
import { NumberPadService } from "../numberPadModule/numberPad.service";
import { XMLJSParser } from "./xml/XMLParser";
import { filter } from "rxjs/operators";
import { WindowUnloadService } from "src/app/service/WindowUnloadService";


@Component({
	selector: 'ro-book-edit-workspace',
	template:`
	<div #touch class="host" (resized)="onResized($event)" (tap)="tapOutside($event)" >
		<div class="slider-container absolute" >
			<div class="absolute scroll">
			</div>
			<div class="transformContainer absolute">
				<div class="absolute center-page-container" [style.transform]="'scale('+scale+')'">
					<div class="center-container slide" (tap)="onTap($event)" (pointerdown)="onPointerDown($event)">
						<ng-container #centerVC></ng-container>
		
		<div *ngIf="context && context.editLayerManager && context.editLayerManager.editLayers.length>0" #dimLayer class="dimLayer absolute" [style.transform]="invertZoom"
		[style.left.px]="dimX" [style.top.px]="dimY" 
		[style.width.px]="dimWidth" [style.height.px]="dimHeight"
		></div>

						<div class="pageBorder absolute" [class.referenceLine]="(context && context.config && context.config.setting.referenceLine)">
							<learningObjectivePopupSelector type="LO" *ngIf="!viewOnly" 
								[options]="{acceptCustom: 1}"
								#learningObjectiveSelector></learningObjectivePopupSelector>

							<SelectMarker
								[useInROPage]="true"
								[topCenter]="topCenter"
								[target]="selectorTarget"
								[showSelector]="!draggingComponent && !removingComponent && !resizingComponent && !removingSelector"
								#selectMarker 
								(emitter)="onSelectorMarkerEvent($event)"></SelectMarker>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="topCenter" #topCenter></div>
		
	</div>
	`,
	styles:[`
	.def{
		position:absolute;
		background-color:blue;
		left:25px;
		top:25px;
		width:50px;
		height:50px;
	}
	.abc{
		left:0px;
		top:0px;
		position:absolute;
		background-color:red;
		width:100px;
		height:100px;
	}
	.host
	{
		width:100%;
		height:100%;
		overflow: hidden;
		touch-action: auto !important;
	}

	.topCenter{
		position:absolute;
		top:0;
		left:50%;
	}

	.slider-container{
		left:50%;
		top:50%;
	}
	.dimLayer{
		transform-origin: center;
		background-color: rgba(0,0,0,0.3);
		z-index:10;
	}
	.pageBorder{
		top:0;
		border: 1px solid #000;
		width:100%;
		height:100%;
		pointer-events:none;
		z-index:20;
	}
	.referenceLine:before{
		position:absolute;
		display:block;
		content:'';
		top:40px;
		bottom:72px;
		width:100%;
		border-top:1px solid #f00;
		border-bottom:1px solid #f00;
	}

	.absolute
	{
		position:absolute;
	}

	.slide {
		position:absolute;
		touch-action: auto !important;
	}

	.host ::ng-deep ro-page .page-container{overflow: visible;}
	`]
	//styleUrls:["./ROPageSliderComponent.scss"]
})

export class ROBookEditWorkspace implements OnInit, AfterViewInit, OnChanges, OnDestroy{
	@ViewChild("touch", {static: false}) touch: ElementRef;
	@ViewChild("dimLayer", {static: false}) dimLayer: ElementRef;
	@ViewChild("centerVC", {read: ViewContainerRef, static: false}) centerVC: ViewContainerRef;
	@ViewChild('selectMarker', {static:false}) public selectMarker:SelectMarkerComponent;
	@ViewChild('learningObjectiveSelector', {static:false}) public learningObjectiveSelector:LearningObjectivePopupSelector;
	
	

	@Output() public emitter:EventEmitter<any> = new EventEmitter();
	@Output() public commonScaleY:number = 1;
	@Input() public zoom:number = 1;
	@Output() public zoomChange:EventEmitter<number> = new EventEmitter();
	@Input() public padding:number = 50;
	@Input() bookConfig:ROBookConfig;
	@Output() public componentSelect:EventEmitter<any> = new EventEmitter<any>();
	
	
	public draggingComponent:boolean = false;
	public removingComponent:boolean = false;
	public resizingComponent:boolean = false;
	public removingSelector:boolean = false;

	private currenSelectortTarget:any;
	@Output() public selectorTarget:any;

	public bookInfoReady:boolean = false;
	public bookScore:number = -1;
	public questionCount:number = -1;

	// popup edit view
	@Input() public rawData:string;
	public invertZoom:SafeStyle;
	public inBookEditView:boolean = true;
	public viewOnly:boolean = false;

	public dimX:number = 0;
	public dimY:number = 0;
	public dimWidth:number = 0;
	public dimHeight:number = 0;

	public hideSelector():void
	{
		if(this.context.editLayerManager.editLayers.length==0) {
			this.removingSelector = true;
			setTimeout(()=>{
				this.removingSelector = false;
				this.selectorTarget = this.currenSelectortTarget = null;
				this.selectMarker.cancelSelect()
			}, 200);

		}
	}

	public tapOutside(ev:any):void
	{
		this.hideSelector();
	}

	// @HostListener('pointerdown',['$event']) 
	// 這裡決定點擊到的 object 是否可選擇
	public onPointerDown(ev:any) {
		if(this.viewOnly)	return;
		if(!(this.editLayer instanceof ROComponent)) return;

		var obj = this.findPressOnTarget(ev.target);
		if(obj) {
			this.draggingComponent = true;

			// 同一個 select 則不需要轉換
			if(this.currenSelectortTarget && this.currenSelectortTarget.target == obj.elementRef.nativeElement)
				this.selectMarker.startMove(this.currenSelectortTarget, ev);
			else {
				var itm = {obj:obj, target:obj.elementRef.nativeElement};
				itm = this.switchingSelector(itm);
				this.selectMarker.startMove(itm, ev);
			}
			return;
		}

		if(this.context.editLayerManager.editLayers.length==0) {
			this.switchingSelector(null);
			this.selectMarker.cancelSelect();
		}
	}

	protected findPressOnTarget(target:any):any {
		var t:HTMLElement = this.findFirstComponent(target);
		if(t) {
			//console.log(this.editLayer, this.editLayer.hasOwnProperty("getChildComponents"));
			if(this.editLayer && this.editLayer instanceof ROContainerComponent) {
				var editLayerComponents:any[] = this.editLayer.getChildComponents();
				var obj = editLayerComponents.find(e => e.elementRef.nativeElement == t);
				if(obj) {
					if((obj instanceof ROComponent) && (<ROComponent>obj).isLocked())
						return null;

					return obj;
				}
			}
		}
		return null;
	}
	
	private switchingSelector(item:any):any
	{
		if(this.currenSelectortTarget == null || item == null)
		{
			this.currenSelectortTarget = item;
			this.selectorTarget = null;
		} else if(this.currenSelectortTarget.target == item.target)
		{
			this.currenSelectortTarget.obj = item.obj;
			return this.currenSelectortTarget;
		} else {
			this.currenSelectortTarget = item;
			this.selectorTarget = null;
		}
		return item;
	}


	@HostListener('pointerup',['$event']) onPointerUp(ev:any) {
		this.draggingComponent = false;
		if(!this.currenSelectortTarget || (this.currenSelectortTarget && !this.currenSelectortTarget.editInStage)) {
			this.selectorTarget = this.currenSelectortTarget;
			// this.selectMarker.refresh();
			this.selectMarker.updateSelectedItemsSetting();
			this.selectMarker.updateOriginalPosition();

		}
	}

	private findFirstComponent(t:any):HTMLElement
	{
		if(this.editLayer) {
			var editLayer:HTMLElement = this.editLayer.getElementRef().nativeElement;
			while(t && t.parentNode!=editLayer)
				t = t.parentNode;
			// console.log("click on",t);
			return t;

		}

		return null;
	}

	public zIndex:number = 0;
	
	public info:any = {};

	public containerHeight:number = 1024;
	public containerWidth:number = 1024;
	
	public pageScale:number = 1;
	private hammerControl: HammerControl;
	private hammerTransformControl: HammerTransformControl;
	
	public pages:any [];
	public scale:number = 0.1;
	
	public centerPage:any;

	public currentPageScale:number = 1;
	public context:ROContext;
	public playerManager:ROAudioPlayerManager;

	public editLayer:any;//ROContainerComponent;
	public state:string = "no change";
	protected changedChapters:any[] = [];

	constructor(
		private uls:UploadService,
		private fileIO:FileIOService,
		private alertService:AlertService,
		// private ngZone:NgZone,
		public elementRef:ElementRef,
		private dataService:DataService,
		private dcs:DynamicComponentService,
		private title:Title,
		private documentService:RODocumentService,
		private translate:TranslateService,
		private renderer:Renderer2,
		private roContextService:ROContextService,
		private sans: DomSanitizer,
		public unloadService:WindowUnloadService
	) {
		this.context = new ROContext();
		this.context.editLayerManager = new ROLayerEditManager();
		this.context.service = this.roContextService;
		this.context.subject.subscribe((data:any)=>{
			if(data.type == "action") {
				if(data.action == "componentAutoResized") {
					this.selectMarker.refresh(data.data);

				} else if(data.action == "selectMarkerTargetSelect") {
					this.selectorTarget = data.data;
				} else if(data.action == "selectMarkerTargetUpdate") {
					this.selectMarker.updateOriginalPosition();
				}
					
			}
		});
		this.context.assetUploader = new ROAnswerAssetUpload(dataService, uls);
		// this.context.alertService = this.alertService;
		// this.context.resourceSource = new ResourceSource(dataService, this.roContextService.fileIO);
		// this.context.documentService = documentService;
		// this.context.dataService = this.dataService;
		// this.context.dcs = dcs;
		// this.context.translateService = translate;
		// this.context.map = new ROComponentMap();
		this.playerManager = this.context.playerManager = new ROAudioPlayerManager(
			this.roContextService.resourceSource
		);//  new TTSManager();
		// this.context.fileIO = this.fileIO;
		var tmp = AllROComponents.concat(
			ROComponentDefinition
		);
		var componentMap:any = {};
		tmp.forEach((_constructor)=>{
			var names = _constructor.prototype.getTagNames();
			// console.log(_constructor, names);
			names.forEach((name:string)=>{
				componentMap[name] = _constructor;
			});
		})
		
		this.context.map.init(componentMap, ROUnknownComponent);
		this.zIndex = TopLayer.getNextIndex();
		StyleUtils.setStyleVariabe(this.elementRef.nativeElement, "top-z-index", this.zIndex);


	}
	
	// public currentPageComponent:ROComponent;
	onSelectorMarkerEvent(o:any):void
	{
		try
		{
			this.processSelectorEvent(o);
		} catch(error)
		{
			console.error(error);
		}
	}
	private processSelectorEvent(o:any):void
	{
		if(o.target == "component")
		{
			if(o.type == "action")
			{
				(<ROComponent>o.component).runCommand(o.action);
			} else if(o.type == "update")
			{
				var reference:any = o.reference;
				var newValue = reference.newValue;
				if((<ROComponent>o.component).setPropertiesThroughPanel(reference.key, newValue))
				{
					if(reference.type == "options")
					{
						if(reference.multiSelect)
						{
							reference.options.forEach((option:any)=>{
								option.selected = newValue.indexOf(option.value) != -1;
							});
						} else {
							reference.options.forEach((option:any)=>{
								option.selected = newValue == option.value;
							});
						}
					} else 
					{
						reference.value = reference.newValue;
					}
					this.onPropertyChanged(o.component, reference.key, newValue);
					
				}
			}
			
		} else if(o.target == "editor")
		{
			if(o.type == "moveStart")
			{
				this.draggingComponent = true;
				return ;
			} else if(o.type == "moveEnd")
			{
				this.draggingComponent = false;
				this.onMoveEnd();
				this.markDirty();

			} else if(o.type == "resizeStart")
			{
				this.resizingComponent = true;
			} else if(o.type == "resizeEnd")
			{
				this.resizingComponent = false;
			} else if(o.type == "action")
			{
				var action = o.action;
				if(action == "editLayer")
				{
					this.pushEditLayer(o.component);

				} else if(action == "back" || action == "complete") {
					this.popEditLayer(action == "complete");

				} else if(action == "changeImage")
				{
					if(!this.inBookEditView) // in popup edit view
						this.emitter.emit(o);

				} else if(action == "changeSound")
				{
					if(!this.inBookEditView)
						this.emitter.emit(o);
				} else if(action == "duplicate")
				{
					
					this.copySelectedComponents();
					this.pasteComponents(false);
					this.updateQuestionNumber();
					
				} else if(action == "remove")
				{
					console.log("remove", o);
					if(!this.inBookEditView)
						this.emitter.emit(o);
					this.deleteSelectedComponents();
					
				} else if(action == "learningObjective")
				{
					var com:ROComponent = o.component;
					// if(com.setPropertiesThroughPanel("learningObjective", learningObjectives))
					// console.log("learningObjective", o.reference.learningObjective);
					var originalValue = com.getPropertiesThroughPanel("learningObjective");
					this.learningObjectiveSelector.selectedItems = originalValue;
					// this.learningObjectiveSelector.open(o.dom, this.elementRef.nativeElement).then((learningObjectives:any[])=>{
					this.learningObjectiveSelector.open(o.dom, document.body).then((learningObjectives:any[])=>{
						var com:ROComponent = o.component;
						if(com.setPropertiesThroughPanel("learningObjective", learningObjectives))
							o.reference.learningObjective = learningObjectives.concat();
					}).catch((reason)=>{
						console.log("learning objective selection rejected", reason);
					})
				}
			}
		}
		this.updatePageInfo();
		this.updateBookInfo();
	}

	onPropertyChanged(component:ROComponent, key:string, value:any):void
	{
		if(key == "q.section")
		{
			this.updateQuestionNumber();
		}
	}

	onMoveEnd() {
		this.updateQuestionNumber();
	}

	updateQuestionNumber() {
		var container:ROPageComponentContainer = this.getCurrentPageComponent();
		if(!container) return;
		var page:ROPageComponent = container.pageCom;
		page.updateQuestionNumber();
		this.updatePageInfo();
		this.updateBookInfo();
	}



	onTap(event:any):void
	{
		if(this.viewOnly)	return;
		if(!(this.editLayer instanceof ROComponent)) return;

		if(event.tapCount==2) {
			// 有無 tap 中 component
			var obj = this.findPressOnTarget(event.target);
			if(obj)
				this.pushEditLayer(obj);// 能夠進入下一層則進入下一層
			else
				this.popEditLayer(false); // 返回上一層
		}
	}

	pushEditLayer(com:any):void {
		// 檢查是否可進下一層
		if(com instanceof ROComponent && (<ROComponent>com).canEditInside) {
			var itm = this.selectMarker.selectedItems.find(e=>e.obj==com);
			if(itm) {
				// =============================
				// select marker menu update
				// =============================
				// 將component 設定為 edit in stage
				itm.editInStage = true;
				itm.obj.editInStage = true;
				// 取得轉換後顯示的menu
				itm.settings.popupSelector = itm.obj.getPopupSelector();
				// 將 menu 以 bar 模式顯示
				this.selectMarker.inEdit = itm;
				this.selectMarker.selectedItems = [];
				// =============================

				this.context.editLayerManager.setEditLayer(com);
				this.dimLayerPositionUpdate();
				this.editLayer = com;
				this.selectMarker.positionUpdateCount++;
				this.emitter.emit({target:"editor",action:"changeAddComponentMenu",data:this.editLayer.getSupportedComponentPanel()});
			}
		}
	}

	popEditLayer(all:boolean):void {
		do {
			var _selectItem:ROComponent = this.context.editLayerManager.currentLayer;
			if(_selectItem) {
				_selectItem.editInStage = false;
				this.context.editLayerManager.popEditLayer(); // 退出一層
			}
			// 新編緝層
			var ccom:ROContainerComponent = <ROContainerComponent>this.context.editLayerManager.currentLayer;
		} while(all && ccom!=null)
		
		console.log(">> pop editLayer", this.editLayer);
		if(ccom!=null)  {
			this.editLayer = ccom;
			ccom.editInStage = true;
			this.selectMarker.inEdit = {editInStage:true, obj:ccom, target:ccom.getElementRef().nativeElement, settings:{popupSelector:ccom.getPopupSelector()}};
			this.selectMarker.positionUpdateCount++;
			this.selectorTarget = this.currenSelectortTarget = {obj:_selectItem, target:_selectItem.getElementRef().nativeElement};
		} else {
			this.editLayer = this.centerPage.ref.instance.pageCom;
			this.selectMarker.inEdit = null;
			this.selectorTarget = this.currenSelectortTarget = null;//{obj:_selectItem, target:_selectItem.getElementRef().nativeElement};
		}
		
		// 更新可加的 component
		this.emitter.emit({target:"editor",action:"changeAddComponentMenu",data:this.editLayer.getSupportedComponentPanel()});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.zoom)
		{
			this.onZoomInputChanged();
		}
		
		if(changes.rawData)
		{
			this.viewOnly = true;
			if(changes.rawData.currentValue) {
				if(changes.rawData.previousValue) {
					this.destoryCurrentPage();
					this.pageIndex = -1;
					this.targetPageIndex = 0;
					this.editPageFromRAWData("edit",changes.rawData.currentValue,
						{autoSave:false,referenceLine:false,fontSize:26, qSize:3, scoreSize:3});
				} else {
					window.setTimeout(() => {
						this.editPageFromRAWData("edit",changes.rawData.currentValue,
							{autoSave:false,referenceLine:false,fontSize:26, qSize:3, scoreSize:3});
					},0);
				}
				
			}
		}
	}

	onZoomInputChanged() {
		this.currentPageScale = this.zoom / this.scale;
		if(this.centerPage && this.centerPage.dom)
		{
			var dom:HTMLElement = this.elementRef.nativeElement;
			var page:HTMLElement = dom.querySelector(".transformContainer");
			var transform:any = DOMHelper.getElementTransform3D(page);
			page.style.transform = `translate3d(${transform.x}px, ${transform.y}px, 0px) scale(${this.currentPageScale})`;
		}

		this.dimLayerPositionUpdate();
	}

	rebuildCurrentPage():void
	{
		this.destoryCurrentPage();
		this.rebuildPages(true);
	}
	

	private getPageByIndex(index:number):any
	{
		if(index < 0)
		{
			return null;
			
		} else if(index >= this.pages.length)
		{
			return null;
		}
		return this.pages[index];
	}
	private subscription:Subscription
	ngOnInit(): void {
		
		this.subscription = new Subscription(()=>{})
		/*
		this.subscription.add(fromEvent(window, "beforeunload").subscribe((event:any)=>{
			if(this.dirty)
			{
				event.preventDefault();
				event.returnValue = 'stop';
			}
		}));
		*/
		this.initKeyboardEventListenerManager();
		
	}
	initKeyboardEventListenerManager() {
		var manager:KeyboardEventListenerManager = new KeyboardEventListenerManager(window);
		// Clipboard
		manager.addHandler(
			[
				{ctrlKey:true,code:"KeyC"},
				{metaKey:true,code:"KeyC"}
			], 
			(event:any)=>{this.copySelectedComponents();}
		);
		manager.addHandler(
			[
				{ctrlKey:true,code:"KeyX"},
				{metaKey:true,code:"KeyX"}
			], 
			(event:any)=>{
				this.cutComponents();
				this.selectMarker.cancelSelect();
				this.updateQuestionNumber();
			}
		);
		manager.addHandler(
			[
				{ctrlKey:true,shiftKey:"*",altKey:false,code:"KeyV"},
				{metaKey:true,shiftKey:"*",altKey:false,code:"KeyV"}
			], 
			(event:any)=>
			{
				var samePlace:boolean = event.shiftKey ? true : false;
				this.pasteComponents(samePlace);
				this.updateQuestionNumber();
			}
		);
		// component operation
		manager.addHandler(
			[{code:"Delete"}, {code:"Backspace"}], 
			(event:any)=>{this.deleteSelectedComponents();}
		);
		manager.addHandler(
			[{shiftKey:"*",code:"ArrowUp"}], 
			(event:any)=>{this.moveSelectedComponentsByKeyboardEvent(event, 0, -1);}
		);
		manager.addHandler(
			[{shiftKey:"*",code:"ArrowDown"}], 
			(event:any)=>{this.moveSelectedComponentsByKeyboardEvent(event, 0, 1);}
		);
		manager.addHandler(
			[{shiftKey:"*",code:"ArrowLeft"}], 
			(event:any)=>{this.moveSelectedComponentsByKeyboardEvent(event, -1, 0);}
		);
		manager.addHandler(
			[{shiftKey:"*",code:"ArrowRight"}], 
			(event:any)=>{this.moveSelectedComponentsByKeyboardEvent(event, 1, 0);}
		);

		this.subscription.add(manager.start()
			.pipe(
				filter((event)=>{
					return event.target instanceof HTMLBodyElement;
				})
			)
			.subscribe(
			(keyboardEvent:KeyboardEvent)=>{
				manager.process(keyboardEvent);
			}
		));

	}

	ngAfterViewInit(): void {
		
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		if (this.pages) {
			this.pages.forEach((page:any)=>{
				if(page && page.ref)
				{
					var ref:ComponentRef<any> = page.ref;
					ref.destroy();
				}
			});
		}
	}

	addComponentByTag(componentTag: string):ROComponent{
		// 
		var components:ROComponent[] = this.editLayer.getChildComponents();
		var com:ROComponent = this.editLayer.addComponentByTag(componentTag);
		if(components.length)
		{
			var lastComponent:ROComponent = components.pop();
			com.setPosition(lastComponent.x + 10, lastComponent.y + 10);
			com.saveCoordinateExpression();
		}
		com.showComponentScore();

		this.updateQuestionNumber();
		setTimeout(()=>{
			this.selectMarker.selectTarget({obj:com});
		}, 100);
		return com;
		// this.selectMarker.updateOriginalPosition();
	}
	// clipboard operations
	private copySelectedComponents():void
	{
		// component 可以 copy
		var components:ROComponent [] = this.selectMarker.getSelectedComponents();
		if(!components) return;
		console.log("copy");
		this.context.service.roClipboardService.copy(components);
	}
	private cutComponents():void
	{
		// component 可以 cut
		var components:ROComponent [] = this.selectMarker.getSelectedComponents();
		if(!components) return;
		console.log("cut");
		this.context.service.roClipboardService.cut(components);
	}
	private pasteComponents(samePlace:boolean):void
	{
		// component 可以 paste
		if(!this.context.service.roClipboardService.hasReferenceComponents()) return;
		var pageCom:ROContainerComponent = this.getCurrentPageComponent().pageCom;
		var components:ROComponent [] = this.context.service.roClipboardService.paste(
			pageCom, samePlace
		);
		components.forEach((com:ROComponent)=>{
			com.showComponentScore();
		});
		this.selectMarker.setSelectedComponents(components);
	}

	private deleteSelectedComponents():void
	{
		// component 或 component 子項(需問主 component)可以 delete
		/*
		var components:ROComponent [] = this.selectMarker.getSelectedComponents();
		if(!( components && components.length) ) return; // no component has been selected
		components.forEach((component:ROComponent)=>{
			if(component.canDelete())
				component.remove();
		})
		this.removingComponent = true;
		setTimeout(()=>{
			this.removingComponent = false;
			this.selectorTarget = this.currenSelectortTarget = null;
			this.selectMarker.cancelSelect()
		}, 200)
		this.markDirty();
		*/

		var parentCom:ROComponent = this.context.editLayerManager.currentLayer;
		if(this.selectMarker.selectedItems.length==0) return;
		this.selectMarker.selectedItems.forEach(com=>{
			if(com.obj instanceof ROComponent) {
				if(com.obj.canDelete()) {
					com.obj.remove();
					this.removingComponent = true;
				}
			} else if(parentCom && parentCom.canDelete(com.dom)) {
				parentCom.removeChild(com.dom);
				this.removingComponent = true;
			}
		});

		if(this.removingComponent) {
			setTimeout(()=>{
				this.removingComponent = false;
				if(!this.selectMarker.inEdit)
					this.selectorTarget = this.currenSelectortTarget = null;
				this.selectMarker.cancelSelect()
			}, 200)
			this.markDirty();
			this.updateQuestionNumber();
		}
	}
	
	private moveSelectedComponentsByKeyboardEvent(e:KeyboardEvent, px:number, py:number):void
	{
		if(e.shiftKey)
		{
			px *= 10;
			py *= 10;
		}
		this.moveSelectedComponentsBy(px, py);
		this.selectMarker.updateOriginalPosition();
	}
	private moveSelectedComponentsBy(px:number, py:number):ROComponent []
	{
		var components:ROComponent [] = this.selectMarker.getSelectedComponents();
		if(!( components && components.length) ) return []; // no component has been selected
		components.forEach((component:ROComponent)=>{
			if(!component.editInStage) {
				component.moveBy(px, py);
				component.saveCoordinateExpression();
			}
		});
		return components;
	}

	public openBookEdit(bookID:number,viewMode:string, setting:any):Promise<any>
	{
		this.viewOnly = false;
		return this.dataService.call("ROBook.get_book_entry", bookID).then((entry:any)=>{
			return this.documentService.open(entry).then((o:any)=>{
				var roDocument:RODocument = new RODocument(o, this.dataService);
				this.bookInit(roDocument, viewMode, setting);
				return Promise.resolve(this.bookConfig);
			});
		});
	}

	protected bookInit(roDocument:RODocument, viewMode:string, setting:any):void {
		this.bookConfig = roDocument.initAllPage({
			viewerID:0,ownerID:0,viewMode:viewMode,course:0,share:null
		}, setting);

		this.initVariables();
	}

	public editPageFromRAWData(viewMode:string,rawData:any, setting:any=null):void {
		this.inBookEditView =false;
		if(!setting)
			setting = {autoSave:false,referenceLine:true,fontSize:26, qSize:3, scoreSize:3};
		var roDocument:RODocument = new RODocument({book:{title:""},chapters:[{xml:rawData}]}, this.dataService);
		this.bookInit(roDocument, viewMode, setting);
	}

	public getRAWData():string {
		this.pages.forEach(p => {
			if(p.ref)
				p.ref.instance.pageCom.getXMLJSON();
		});

		var ch:any = this.pages[0].page.chapter;
		ch.xml = js2xml(
			{elements:[ch.node.element]},
			{
				attributeValueFn:(v: string,
					attributeName: string,
					currentElementName: string,
					currentElementObj: any
				)=> {
					if(!v) return v;
					v = v.replace(/&quot;/g,  '"');
					return this.encodeHTMLEntities(v).replace(/"/g, '&quot;');
				}
			}
		);
		return ch.xml;
	}

	private initVariables():void
	{
		console.log("initVariables");
		this.initPage();
		if(!this.viewOnly)
			this.initTouchHandler();
		this.context.updateBookPageNumber(this.bookConfig);
		this.rebuildPages();
		this.updateScale();
		this.updateZoom();
		
		this.pageIndexChange.emit(this.pageIndex);

		this.title.setTitle(this.bookConfig.book.title);
	}
	
	private updateScale():void
	{
		var p2:number = this.padding * 2;
		var size:any = {
			width:this.containerWidth - p2, 
			height:this.containerHeight - p2
		};
		this.scale = this.calculateScale(this.centerPage, size);
	}

	private updateZoom():void
	{
		this.zoom = this.scale * this.currentPageScale;
		this.zoomChange.emit(this.zoom);

		this.dimLayerPositionUpdate();
	}

	protected dimLayerPositionUpdate():void {
		if(this.centerPage && this.centerPage.ref) {
			var transform:any = DOMHelper.getElementTransform3D(this.elementRef.nativeElement.querySelector(".transformContainer"));
			var invert:number = 1/this.zoom;
			this.invertZoom = this.sans.bypassSecurityTrustStyle(`translate3d(${-transform.x*invert}px, ${-transform.y*invert}px, 0px) scale(${invert})`);
			this.dimX = -80-(this.containerWidth - this.centerPage.page.pageNode.getAttribute("w"))/2;
			this.dimY = -80-(this.containerHeight - this.centerPage.page.pageNode.getAttribute("h"))/2;
			this.dimWidth = this.containerWidth+160;
			this.dimHeight = this.containerHeight+160;
		}
	}

	onResized(event:any):void
	{
		this.containerHeight = event.newHeight;
		this.containerWidth = event.newWidth;
		this.updateScale();
		this.updateZoom();

		this.dimLayerPositionUpdate();
	}

	private calculateScale(page:any, size:any):number
	{
		if(!page) return 1;
		var pageW:number = page.page.pageNode.getAttribute("w");
		var pageH:number = page.page.pageNode.getAttribute("h");
		var w:number = pageW;
		var h:number = pageH;
		var wScale:number = size.width / w;
		var hScale:number = size.height / h;
		if(wScale <= hScale) return wScale;
		return hScale;
	}

	private initPage():void
	{
		this.context.config  = this.bookConfig;
		this.pages = this.bookConfig.pages.map((page:any, index:number)=>{
			// <ro-page #pagesComponents [scale]="pageScale" [page]="page" *ngFor="let page of pageConfig.pages" >
			/*
			var componentRef:ComponentRef<ROPageComponent> = this.dcs.createComponentRef(
				ROPageComponent, 
				{
					scale:0.5,
					page:page
				}
			);
			*/
			return {
				// ref:componentRef,
				index:index,
				page:page,
				dirty:false
				// thumbnail
			};
		})

		this.updateBookInfo();
	}
	private initTouchHandler():void
	{
		this.hammerControl = new HammerControl(this.touch.nativeElement);
		var dom:HTMLElement = this.elementRef.nativeElement;
		var page:HTMLElement = dom.querySelector(".transformContainer");
	
		var scroll:HTMLElement = dom.querySelector(".scroll");
		this.hammerTransformControl = new HammerTransformControl(this.touch.nativeElement, page);
		this.hammerTransformControl.pinZoomEnable = true;
		this.hammerTransformControl.wheelZoomEnabled = true;
		// var pageSize:number = 400;
		var halftPage:number = this.containerWidth/2;

		var pageW:number;
		var pageH:number;
/*		this.hammerControl.hammer.get('tap').set({ enable: true });
		this.hammerControl.hammer.on("tap", ev => {
			console.log("tag", ev);
			var slide:HTMLElement = dom.querySelector(".slide");
			var t = ev.target;
			while(t.nodeName!="NG-COMPONENT" && t!=slide)
				t = t.parentNode;
			if(t!=slide) {
				this.editLayerComponents.forEach(e => {
					if(e.elementRef.nativeElement == t)
						console.log("catch",e.elementRef.nativeElement);
				});
			}
			
		});*/
		
		this.hammerTransformControl.transformStart.subscribe((d:any)=>{
			pageW = this.centerPage.page.pageNode.getAttribute("w");
			pageH = this.centerPage.page.pageNode.getAttribute("h");
//			this.stop(page);
			this.stop(scroll);
			scroll.style.willChange = "transform";
//			page.style.willChange = "transform";
		});
		this.hammerTransformControl.transform.subscribe((t:any)=>{
			if(t.scale > 10)
			{
				t.scale = 10;
			} else if(t.scale < 0.5)
			{
				t.scale = 0.5;
			}

			
			var scaledPageW:number = (t.scale* pageW * this.scale)/2+50;
			var scaledPageH:number = (t.scale* pageH * this.scale)/2+50;
			if(t.x > scaledPageW)
				t.x = scaledPageW;
			else if(t.x < -scaledPageW)
				t.x = -scaledPageW;
			if(t.y > scaledPageH)
				t.y = scaledPageH;
			else if(t.y < -scaledPageH)
				t.y = -scaledPageH;
			
			scroll.style.transform = `translate3d(0px, 0px, 0px)`;
			this.currentPageScale = t.scale;
			this.updateZoom();
		});
		this.hammerTransformControl.transformEnd.subscribe((info:any)=>{
			this.updateZoom();
			return;
			/*
			var t:any = info.transform;
			var evt:any = info.evt;
			if(evt && evt.gesture)
			{
				t.x += evt.gesture.overallVelocityX * 200;
			}
			
			var scaledPageW:number = t.scale* pageW * this.scale;
			var scaledPageH:number = t.scale * pageH * this.scale;
			var halfPageW:number = Math.max(scaledPageW, this.containerWidth)/2;
			var halfPageH:number = Math.max(scaledPageH, this.containerHeight)/2;
			
//			var left:number = t.x - halfPageW;
//			var right:number = t.x + halfPageW;

			var top:number = t.y - halfPageH;
			var bottom:number = t.y + halfPageH;
			
//			this.leftOn = left > 0;
//			this.rightOn = right < 0;
			var pan:boolean = false;
			if(top > (- this.containerHeight / 2))
			{
				pan = true;
				t.y -= (top + this.containerHeight / 2);
			} else if(bottom < (this.containerHeight / 2))
			{
				pan = true;
				t.y -= (bottom - (this.containerHeight/2)) ;
			}
			
				var currentScrollTransform:any = DOMHelper.getElementTransform3D(scroll);
				if(currentScrollTransform.x != 0)
				{
					this.transformTo(scroll, `translate3d(0px, 0px, 0px)`);
				} else {
					this.stop(scroll);
				}
				if(pan){
					this.transformTo(page,  `translate3d(${t.x}px, ${t.y}px, 0px) scale(${t.scale})`);
				} else {
					this.stop(page);
				}*/
		})
		
//		this.monTransitionEnd(page);
		this.monTransitionEnd(scroll);
		fromEvent(scroll, "transitionend").subscribe((o:any)=>{
			this.rebuildPages();
			this.updateScale();
			
		});
	}
	// private slideChanged:boolean = true;
	private rebuildPages(force:boolean = false):void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var scroll:HTMLElement = dom.querySelector(".scroll");
		var page:HTMLElement = dom.querySelector(".transformContainer");
		if(this.targetPageIndex != this.pageIndex || force)
		{
			this.pageIndex = this.targetPageIndex;

			this.emptyContainerRef(this.centerVC, this.centerPage, true);
////			this.emptyContainerRef(this.leftVC, this.leftPage, false);
////			this.emptyContainerRef(this.rightVC, this.rightPage, false);
			
			this.centerPage = this.replace(this.pageIndex, this.centerPage, this.centerVC, true);
////			this.leftPage = this.replace(this.pageIndex-1, this.leftPage, this.leftVC, false);
////			this.rightPage = this.replace(this.pageIndex+1, this.rightPage, this.rightVC, false);
			
			this.updateScale();
			
			this.currentPageScale = 1;
			scroll.style.transform = "translate3d(0px, 0px, 0px)";
			page.style.transform = "translate3d(0px, 0px, 0px)";
			this.updateZoom();
			this.pageIndexChange.emit(this.pageIndex);
			
			
		}

		this.selectMarker.cancelSelect();
		this.changeEditLayer();
	}

	private emptyContainerRef(containerRef:ViewContainerRef, pageObject:any, deactivateFlag:Boolean):void
	{
		if(deactivateFlag && pageObject && pageObject.ref)
		{
			var componentRef:ComponentRef<ROPageComponentContainer> = pageObject.ref;
			componentRef.instance.deactivate();
	//		var appRef:ApplicationRef = this.dcs.getApplicationRef();
	//		appRef.detachView(componentRef.hostView);
		}
		//*
		//var appRef:ApplicationRef = this.dcs.getApplicationRef();
		while(containerRef.length)
		{
			//appRef.detachView(containerRef.hostView);
			containerRef.detach(0);
		}
		//*/
	}
	public getCurrentPageComponent():ROPageComponentContainer
	{
		var page:any = this.getPageByIndex(this.pageIndex);	
		if(page == null) return null;
		if(page.ref)
		{
			var componentRef:ComponentRef<ROPageComponentContainer> = page.ref;
			return componentRef.instance;
		}
		return null;
	}
	private destoryCurrentPage()
	{
		// this.centerPage = this.replace(this.pageIndex, this.centerPage, this.centerVC);
		var page:any = this.getPageByIndex(this.pageIndex);	
		if(page == null) return page;
		if(page.ref)
		{
			var componentRef:ComponentRef<ROPageComponentContainer> = page.ref;
			componentRef.instance.deactivate();
			componentRef.destroy();
			page.ref = null;
		}
	}

	private replace(pageIndex:number, currentPageObject, vc:ViewContainerRef, isCenter:boolean):any
	{
		var page:any = this.getPageByIndex(pageIndex);	// XMLNode
		if(page == null) return page;
		var componentRef:ComponentRef<ROPageComponentContainer>
		if(page.ref)
		{
			componentRef = page.ref;
		} else {
			
			componentRef  = this.dcs.createComponentRef(
				ROPageComponentContainer, 
				{
					book:this.bookConfig.book,
					page:page.page,
					context:this.context
				}
			);
			page.ref = componentRef;
			componentRef.instance.build();
			// componentRef.instance.setPageData(this.bookConfig.dataSource);
			componentRef.instance.showComponentScore();
		}
		if(isCenter) componentRef.instance.activate();
		
		var containerElement:HTMLElement = vc.element.nativeElement.parentElement;
		var pageNode:any = page.page.pageNode;
		var w:number = pageNode.getAttribute("w");
		var h:number = pageNode.getAttribute("h");
		containerElement.style.left = (-w/2) +"px";
		containerElement.style.top = (-h/2) +"px";
		
		vc.insert(componentRef.hostView);

		return page;
	}

	public pageIndex:number = -1;
	public targetPageIndex:number = 0;
	@Output() pageIndexChange:EventEmitter<number> = new EventEmitter();

	private monTransitionEnd(element:HTMLElement):void
	{
		fromEvent(element, "transitionend").subscribe(
			(event:any)=>{
				this.stop(element);
			}
		);
	}

	private stop(element:HTMLElement):void
	{
		element.style.transition = "";
		element.style.willChange = "";
	}
	private transformTo(element:HTMLElement, transform:string):void
	{
		if(transform != element.style.transform)
		{
			element.style.transition = "transform 0.2s";
			element.style.willChange = "transform";
			element.style.transform = transform;
		} else {
			element.style.willChange = "";
		}
	}

	
	
	goToPageByID(douid:string):boolean
	{
		var pageIndex:number = this.getPageIndexByID(douid);
		if(pageIndex == -1) return false;
		this.targetPageIndex = pageIndex;
		this.rebuildPages();
		return true;
	}

	private getPageIndexByID(douid:string):number 
	{
		for(var i:number = 0; i < this.bookConfig.pages.length;i++)
		{
			var page:any = this.bookConfig.pages[i];
			var pageNode:XMLNode = page.pageNode;
			if(pageNode.getAttribute("douid") == douid)
			{
				return i;
			}
		}
		return -1;
	}
	onTransforming(info:any):void
	{
		console.log("onTransforming", info);
	}

	@HostListener('window:info', ['$event'])
	onResize(event:CustomEvent) {
		var detail:any = event.detail;
		if(detail.key == "action")
		{
			if(detail.value == "clear")
			{
				this.info = {};
			}
		} else {
			this.info[detail.key] = detail.value;
			var tmp = this.info;
			this.info = null;
			this.info = tmp;
		}
		
	}


	// =======================================
	// edit function
	// =======================================
	public dirty:boolean = false;
	public autoSave:boolean = false;
	public markDirty():void {
		this.centerPage.dirty = true;
		this.state = "have change";
		if(!this.dirty){
			// remove task is required after saveBook
			// this.unloadService.unfinishedBusinessService.remove(this.saveBook);
			this.unloadService.unfinishedBusinessService.add(this.saveBook, this, this.autoSave);
		}
		this.dirty = true;
		let ch = this.centerPage.page.chapter;
		let old_ch = this.changedChapters.find(e=> e.id==ch.id);
		if(!old_ch) {
			this.changedChapters.push(ch);
		}
	}

	public clearDirty():void {
		this.pages.forEach(p => {
			p.dirty = false;
		});
		this.state = "saved";
		this.dirty = false;
		this.changedChapters = [];
		this.unloadService.unfinishedBusinessService.remove(this.saveBook);
	}

	public changeEditLayer(path:string = null):void {
		console.log("changeEditLayer =====");
		// testing
		if(path == null && this.centerPage && this.centerPage.ref)
			this.editLayer = this.centerPage.ref.instance.pageCom;
	}
	private encodeHTMLEntities(text) {
		let textArea = document.createElement('textarea');
		textArea.innerText = text;
		let encodedOutput=textArea.innerHTML;
		let arr=encodedOutput.split('<br>');
		encodedOutput=arr.join('\n');
		if(text != encodedOutput)
		{
			console.log("compare", text , encodedOutput);
		}
		return encodedOutput;
	}
	private markAllChapterDirty():void
	{
		var chapters = this.pages.map((pageInfo:any)=>{
			return pageInfo.page.chapter
		});
		this.changedChapters = ArrayUtils.unique(chapters);
	}
	public setDragMode():void
	{
		this.hammerTransformControl.panEnable = true;
	}
	public setSelectionMode():void
	{
		this.hammerTransformControl.panEnable = false;
	}
	public saveBook():Promise<any>{
		
		this.pages.forEach(p => {
			if(p.ref)
			{
				p.ref.instance.pageCom.getXMLJSON();
			}
		});

		let list = [];
		this.changedChapters.forEach(ch => {
			ch.xml = js2xml(
				{elements:[ch.node.element]},
				{
					attributeValueFn:(v: string,
						attributeName: string,
						currentElementName: string,
						currentElementObj: any
					)=> {
						if(!v) return v;
						v = v.replace(/&quot;/g,  '"');
						return this.encodeHTMLEntities(v).replace(/"/g, '&quot;');
					}
				}
			); // xml string
			list.push({
				version:0,
				id:ch.id,
				content:ByteArrayUtils.toBase64(RODoc.toNoCompressBytes(ch.xml)),
				reference:[]
			});
		});

		
		let book = this.pages[0].page.doc.o.book;
		let reader:ROBookStructureReader = new ROBookStructureReader();
		let bookStructure:any = reader.getBookStructure({book:{title:book.title},chapters:this.pages[0].page.doc.chapters});
		return this.dataService.call("ROBook.save_book_structure2", "workspace",book.id,bookStructure).then((result:any)=>{
			return this.dataService.call("ROBook.update_items", list).then((result:any)=>{
				console.log("save success ====");
				this.clearDirty();
			});
		});
	}
	
	public updatePageInfo():void
	{
		var pageComponentContainer: ROPageComponentContainer= this.getCurrentPageComponent();
		pageComponentContainer.updatePageInfo();
	}

	public updateBookInfo():void
	{
		this.bookConfig.document.updateBookInfo();
		this.bookScore = this.bookConfig.document.bookScore;
		this.questionCount = this.bookConfig.document.questionCount;
		this.bookInfoReady = true;
		this.context.subject.next({
			type:"notify", 
			notify:{
				type:"bookInfoUpdate"
			}
		});
	}

}



export class HTMLEntities{
	/**
	 * Convert a string to HTML entities
	 */
	static toHtmlEntities (text:string):string
	{
		return text.replace(/./gm, (s) => {
			// return "&#" + s.charCodeAt(0) + ";";
			return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
		});
	};
	
	/**
	 * Create string from HTML entities
	 */
	static fromHtmlEntities (string:string):string
	{
		return (string+"").replace(/&#\d+;/gm, (s:any)=>{
			return String.fromCharCode(s.match(/\d+/gm)[0]);
		})
	};
}
class KeyboardEventListenerManager
{
	private map:any;
	private list:any[] = [];
	constructor(public target:any)
	{
		this.map = {};
		this.list = [];
	}
	start():Observable<any>
	{
		var subscription = new Subscription(()=>{})
		return new Observable((subscriber:Subscriber<any>)=>{
			subscription.add(fromEvent(this.target, "keydown").subscribe((event:any)=>{
				subscriber.next(event);
				// this.process(event);
			}));
			return ()=>{
				subscription.unsubscribe();
				subscriber.complete();
			}
		});
	}

	/**
	 * 
	 * @param keyObj {
	 * 		ctrlKey:false // true // false // "*",
	 * 		shiftKey:false,
	 * 		altKey:false,
	 * 		code:"ArrowUp"
	 * }
	 * @param handler :(keyboardEvent:KeyboardEvent)
	 * {
	 * 	
	 * }
	 */
	addHandler(keyObjArray:any[], handler:Function)
	{
		keyObjArray.forEach((keyObj:any)=>{
			var regExp:RegExp = this.keyToRegExp(keyObj);
			// this.list.push(regExp)
			this.appendHandler(regExp, handler);
			// var key:string = this.keyToString(keyObj);
			// this.appendHandler(key, handler);
		})
		
	}

	private appendHandler(regExp:RegExp, handler:Function):void
	{
		// if(this.map.hasOwnProperty(regExp) == false)
		// 	this.map[regExp] = [];
		// this.map[regExp].push(handler);
		this.list.push({tester:regExp, handler:handler});
	}
	private keyToRegExp(key:any):RegExp
	{
		var parts:string [] = [];
		parts.push(this.toRegString(key.metaKey));
		parts.push(this.toRegString(key.ctrlKey));
		parts.push(this.toRegString(key.shiftKey));
		parts.push(this.toRegString(key.altKey));
		parts.push(this.toRegString(key.code));
		return new RegExp("^"+parts.join("-")+"$")
	}

	keyToString(key:any):string
	{
		return `${key.metaKey ? 1 : 0}-${key.ctrlKey ? 1 : 0}-${key.shiftKey ? 1 : 0}-${key.altKey ? 1 : 0}-${key.code}`;
	}

	toRegString(value):string
	{
		if(value === "*")
		{
			return ".*?"
		} else if(value === true)
		{
			return "1";
		} else if(value === false)
		{
			return "0"
		} else if(!value)
		{
			return "0";
		} else {
			return value;
		}
	}
	
	process(keyboardEvent:KeyboardEvent):void
	{
		var key:string = this.keyToString(keyboardEvent);
		/*
		if(!this.map.hasOwnProperty(key)) return;
		var handlers:Function [] = this.map[key];
		var length = handlers.length;
		for(var i = 0;i < length;i++)
		{
			var handler:Function = handlers[i];
			handler(keyboardEvent);
			if(keyboardEvent.defaultPrevented) return;
		}
		*/
		var length:number = this.list.length;
		for(var i = 0;i < length;i++)
		{
			var item:any = this.list[i];
			if(item.tester.test(key))
			{
				var handler:Function = item.handler;
				handler(keyboardEvent);
				if(keyboardEvent.defaultPrevented) return;
			}
		}
	}
}

