import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';

@Component({
	selector: "combined-renderer",
	template: `
	<div class="wrapper" [ngStyle]="wrapperStyle">
		<ng-container *ngFor="let renderer of renderers;let i = index">
			<div *ngIf="renderer.type == 'photo'" [ngClass]="['photo', (renderer.elClass?renderer.elClass:'')]"
				[class.disabled]="renderer.disabled" 
				[style.backgroundImage]="renderer.photo" [title]="(renderer.tooltip?renderer.tooltip:'')"
				[ngStyle]="renderer.style" (click)="click($event, i)">{{renderer.photoText?renderer.photoText:''}}</div>

			<div *ngIf="renderer.type == 'text'" [class.disabled]="renderer.disabled"
				class="text {{renderer.elClass?renderer.elClass:''}}" [title]="(renderer.toolTip?renderer.toolTip:renderer.text)" 
				[ngStyle]="renderer.style" (click)="click($event, i)">
				<span>{{renderer.text}}</span></div>

			<div *ngIf="renderer.type == 'faIcon'" class="icon-wrapper {{renderer.elClass?renderer.elClass:''}}"
				[ngStyle]="renderer.wrapperStyle"
				[class.disabled]="renderer.disabled">
				<fa-icon [icon]="renderer.faIcon" [title]="(renderer.tooltip?renderer.tooltip:'')"
					[ngStyle]="renderer.style" (click)="click($event, i)"></fa-icon>
				<div class="icon-text" [ngStyle]="renderer.textStyle" *ngIf="renderer.faIconText">{{renderer.faIconText}}</div>
			</div>

			<div *ngIf="renderer.type == 'svgIcon'" class="icon-wrapper {{renderer.elClass?renderer.elClass:''}}"
				[ngStyle]="renderer.wrapperStyle"
				[class.disabled]="renderer.disabled">
				<svg-icon [name]="renderer.svgIcon" [title]="(renderer.tooltip?renderer.tooltip:'')"
					[ngStyle]="renderer.style" (click)="click($event, i)"></svg-icon>
				<div class="icon-text" [ngStyle]="renderer.textStyle" *ngIf="renderer.svgIconText">{{renderer.svgIconText}}</div>
			</div>

			<div *ngIf="renderer.type == 'dateTimePicker3'" class="icon-wrapper {{renderer.elClass?renderer.elClass:''}}"
				[class.disabled]="renderer.disabled">
				<span class="dtp3-text" [ngStyle]="renderer.textStyle" (click)="dtp3Click($event, renderer)">{{renderer.text}}</span>
				<dateTimePicker3 *ngIf="renderer.dtpRendered" #dateTimePicker3 [lang]="datas.lang" 
					(ngModelChange)="test()"
					(onChange)="dtpChange($event, dateTimePicker3, renderer)"></dateTimePicker3>
			</div>

			<div *ngIf="renderer.type == 'uiSwitch&Label'" class="switch-text-wrapper {{renderer.elClass?renderer.elClass:''}}" 
				[class.disabled]="renderer.disabled" (click)="uiSwitchChange($event, renderer)">
				<span class="switch-text" [ngStyle]="renderer.switchTextStyle">{{renderer.text}}</span>
				<ui-switch class="useCssVar" [ngStyle]="renderer.switchStyle" [checked]="renderer.checked"></ui-switch>
			</div>

			<div *ngIf="renderer.type == null" [ngClass]="['remainder', (renderer.elClass?renderer.elClass:'')]" 
				[ngStyle]="renderer.style"
				[style.width]="(renderer.width?renderer.width:'50px')"></div>

			
		</ng-container>
		<whitePopup #whitePopup *ngIf="whitePopupData" [menuItems]="whitePopupData.menuItems" (onMenuClick)="whitePopupClick($event)"></whitePopup>
	</div>
	
    `,
	styleUrls: ['./combined.renderer.scss']
})


export class CombinedRenderer implements AfterViewInit {
	public params: any;
	public renderers:any = [];
	public faIcon:any;
	public clickSubject = new Subject();
	public tooltip;
	public wrapperStyle;
	private dtpPromise;
	public whitePopupData;
	@ViewChild('whitePopup', {static:false}) whitePopup;
	@ViewChild('dateTimePicker3', {static: false}) dateTimePicker3;
	constructor(public eleRef: ElementRef, public datas: DataService, public coms: CommonService){

	}

	ngAfterViewInit() {
		this.clickSubject.pipe(debounceTime(10)).subscribe((obj:any)=>{
			let index = obj.index;
			this.renderers[index].click(this.params, obj.event);
		});
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		this.renderers = this.params.renderers;
		if (this.params.wrapperStyle){
			this.wrapperStyle = this.params.wrapperStyle;
		}
	}

	click($event, index){
		const renderer = this.renderers[index];
		if (renderer.click){
			$event.stopPropagation();
			this.clickSubject.next({index: index, event: $event});
		} else if (renderer.whitePopup){
			this.whitePopupData = renderer.whitePopup;
			setTimeout(()=>{this.whitePopup.open($event.target);}, 1);
		}
	}

	refresh(params: ICellRendererParams){
		// this.agInit(params);
	}

	dtp3Click($event, renderer){
		if (this.dtpPromise){
			if (this.dateTimePicker3.bubbleBox2.closeFn){
				this.dateTimePicker3.bubbleBox2.closeFn();
			}
			this.dtpPromise = null;
			renderer.dtpRendered = undefined;
			return;
		}
		if (renderer.dtpRendered === undefined){
			renderer.dtpRendered = true;
			setTimeout(()=>{
				this.coms.waitFor(()=> this.dateTimePicker3, 20, 300).then( dtp3 =>{
					const ele = $event.target;
					const data = this.params.data;
					this.dtpPromise = this.dateTimePicker3.open(ele, renderer.dtpOptions).then(data=>{
						if (renderer.click){
							renderer.click(data);
						}
						this.dtpPromise = null;
						renderer.dtpRendered = undefined;
					}).catch(err=>{			
						this.dtpPromise = null;
						renderer.dtpRendered = undefined;
					});
				});
			}, 100);
		}
	}

	dtpChange($event, dtp3, renderer){
		if (renderer.updateText){
			const text = renderer.updateText($event);
			const textEle:any = dtp3.eleRef.nativeElement.parentNode.querySelector('span.dtp3-text');
			textEle.innerHTML = text;
		}
	}

	whitePopupClick($event){
		console.log($event);
	}

	uiSwitchChange($event, renderer){
		$event.preventDefault();
		let value = !renderer.checked;
		if (renderer.change){
			let returnValue = renderer.change(value, renderer);
			if (returnValue !== undefined){
				renderer.checked = returnValue;
			}
		} else {
			renderer.checked = value;
		}
	}

	test(){
		debugger;
	}

}
