import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from 'src/app/service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';

@Component({
	selector: 'radioBox',
	template: `
    <span [ngClass]="['radioBox', disabled?'disabled':'']" [ngStyle]="style" *ngFor="let option of options" (click)="radioBoxOnClick(option)">
        <fa-icon class="boxIcon" [ngStyle]="iconStyle" [icon]="(isSelected(option)?faCheckCircle:faCheckCircleLight)" 
		  [class.checked]="(isSelected(option)?faCheckCircle:faCheckCircleLight)" ></fa-icon>
        <span class="label" [ngStyle]="labelStyle">{{ (option[bindLabel]| translate) || option}}</span>
    </span>
    `,
	styleUrls: ['./radioBox.component.scss'],
})

export class RadioBoxComponent implements OnInit {
	public faCheckCircle: any = faCheckCircle;
	public faCheckCircleLight: any = faCheckCircleLight;
	@Input() public value;
	@Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() public options: any[] = [];
	@Input() public bindLabel: string = 'label';
	@Input() public bindValue: string = 'value';
	@Input() public disabled = false;
	@Input() public style;
	@Input() public iconStyle;
	@Input() public labelStyle;
	public isDisabled: boolean = false;
	constructor(public translate: TranslateService, private eleRef: ElementRef, private themeService: ThemeService) {
		themeService.getThemeJson("tickBoxModule.json").then((styleObj: any) => {
			themeService.applyStyleObj(styleObj, eleRef.nativeElement);
		});
	}

	ngOnInit(): void {

	}

	//-------------------------------------------------------------------------------------------------
	public radioBoxOnClick(option: any = null): void {
		if (this.disabled){
			return;
		}
		let value = this.bindValue?option[this.bindValue]:option;
		if (value != this.value){
			this.value = value;
			this.valueChange.emit(this.value);
		}
	}
	//-------------------------------------------------------------------------------------------------
	public isSelected(option){
		if (this.bindValue){
			return this.value == option[this.bindValue];
		} else {
			return this.value == option;
		}
	}
	
}
