import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";


@Component({
	template:`
	<!-- 
	<ng-container *ngIf="qObject">
		<div class="qnumCol"><div class="qnum">{{qObject.questionIndex+1}}</div></div>
		<div class="qcontent" style="font-size:var(--fontSize)" [class.editMode]="inEditMode"></div>
	</ng-container>
	-->
	<QText [qObj]="qObject" ></QText>
	
	<!-- 
	<div>
		<HR>
		answer:<span>{{qObject.answer}}</span>
		<HR>
	</div>
	-->

	
	
	`,
	
	styles:[``]
})
export class QBInfoBlock extends ROComponent
{
	// public state = {};
	public qObject:any;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["QBInfoBlock"];
	}

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
	}

	
}