import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { SettingService } from 'src/app/service/setting.service';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'reset-password2',
	template: `
  <div class="reset-password">
	<div class="bgLayer {{themeService.theme$.value}}-theme" [style.backgroundColor]="homePageThemeColor"></div>
    <div class="header">
      <div class="header__cancel" (click)="cancelClick()">{{"keiKokLibrary.cancel" | translate}}</div>
      <div class="header__title">{{ 'keiKokLibrary.change-password' | translate}}</div>
      <div class="header__confirm" [style.backgroundColor]="homePageThemeColor" (click)="confirm()">{{"keiKokLibrary.confirm" | translate}}</div>
    </div>
    <div class="black-line"></div>
    <div class="topic" [style.color]="homePageThemeColor">
		<span>{{ {tc: '用戶名稱', sc:'用戶名稱',en:'User name'}[lang]}}: {{displayName}}</span>
    </div>
    <div class="content">
      <div class="row password">
        <div class="label">{{'keiKokLibrary.new-password'|translate}}</div>
        <div class="value">
          <input type="password" [(ngModel)]="password" [style.color]="homePageThemeColor" />
        </div>
        <div class="error" *ngIf="error == 'input-password-error-6-digit'">{{'keiKokLibrary.input-password-error-6-digit'|translate}}</div>
      </div>
      <div class="row password2">
        <div class="label">{{'keiKokLibrary.retype-password'|translate}}</div>
        <div class="value">
          <input type="password" [(ngModel)]="password2" [style.color]="homePageThemeColor" />
        </div>
        <div class="error" *ngIf="error == 'input-password-error-not-match'">{{'keiKokLibrary.input-password-error-not-match'|translate}}</div>
      </div>
    </div>
    <div class="remark">
      <span>{{'keiKokLibrary.password-remark'|translate}}</span>
    </div>
  </div>
  `,
	styleUrls: ['./reset-password2.modal.scss'],
})

export class ResetPassword2Modal implements OnInit, AfterViewInit {
	@ViewChild('text', { static: true }) text;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	public password = '';
	public password2 = '';
	public error: string = '';
	public displayName;
	public homePageThemeColor = '#435CBE';
	public lang;
	constructor(private router: Router, private datas: DataService, public setting: SettingService, public themeService:ThemeService, private eleRef: ElementRef) {

	}

	ngOnInit() {
		this.lang = this.datas.lang;
		let account = this.datas.userInfo;
		if (this.datas.lang == 'en') {
			this.displayName = account.first_name + ' ' + account.last_name;
		} else {
			this.displayName = account.c_last_name + account.c_first_name;
		}
		if (this.setting.homeLayout){
			this.homePageThemeColor = this.setting.homeLayout.homePageThemeColor;
		}
		this.themeService.applyStyleObj({
			"default": {
			  "popup-bgcolor": "#F7F7F7",
			  "popup-linecolor":"var(--list-linecolor)",
			  "popupframe-headerbgcolor":"#fff",
			  "popupform-rowbgcolor":"#fff",
			  "popupform-panelbgcolor":"#f5f5f5",
			  "popupform-textcolor": "#333333",
			}, "dark": {
			  "popup-bgcolor": "#2D2C43",
			  "popupframe-headerbgcolor":"#1e1e29",
			  "popupform-rowbgcolor":"#1e1e29",
			  "popupform-panelbgcolor":"#38374f",
			  "popupform-textcolor": "#FFFFFF",
			}
		}, this.eleRef.nativeElement);
	}

	ngAfterViewInit() {

	}

	confirm() {
		if (this.password != this.password2) {
			this.error = 'input-password-error-not-match';
			return;
		} else if (this.password.length < 6) {
			this.error = 'keiKokLibrary.input-password-error-6-digit';
			return;
		}
		this.close.emit({ password: this.password });
	}

	cancelClick() {
		this.close.emit(null);
	}

}
