import { Injectable, Output,EventEmitter } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from 'src/app/service/data.service';

@Injectable()
export class StorageService {
  public bsid = 0;
  public bid = 0;
  public tid = 0;
  public appId = 'rainbowone';
  public docid = 0;
  public pageid = "0";
  public compid = 0;
  public bookStatus = '';
  public book: any = {};
  public bookType='book';
  public dataTableData: any = null;
  public gameTableData: any = null;
  public tags = [];
  public components = [];
  public caches = [];
  public lang;
  public dev;
  public debug;
  public requestThumbnailsCounter = 0;
  public error;
  public jwt;
  public componentNameLoaded = false;
  public openBookModal:any;
  public xmlChapters:any = [];
  @Output() callOpenBookModal:EventEmitter<any> = new EventEmitter();
  constructor(private device: DeviceDetectorService, private datas: DataService) {
    this.lang = 'tc';
    this.components['tc'] = [];
    this.components['en'] = [];
    this.dev = {
      info: device.getDeviceInfo(),
      isMobile: device.isMobile() || device.isTablet()
    };
    console.log(this.dev);
    this.loadComponentName();
  }

  loadComponentName(){
    this.datas.post('ROResultPanel.list_component_name').subscribe((res: any) => {
      if (res.components) {
        res.components.forEach(row => {
          if (!this.components[row.lang]) {
            this.components[row.lang] = [];
          }
          this.components[row.lang][row.com_key] = row.com_value;
        });
        this.componentNameLoaded = true;
      }
    });
  }

  public compName(key) {
    if (this.components[this.lang][key]) {
      return this.components[this.lang][key];
    } else {
      return key;
    }
  }

}
