import { ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { QBComponentBase } from "./QBComponentBase";
import { FileIOService } from "src/app/service/FileIO.service";
import { QBComponentQuestionBase } from "./QBComponentQuestionBase";


@Component({
	template:`
		<div class="q scroll-container">
			<div class="qContainer ">
				
				<div class="qnumCol flex-row-left">
					<div class="flex-column-center">
						<div class="qnum">
							{{qObject.questionIndex+1}}
						</div>
					
						<!-- speaker tts icon -->
						<!--
						<div  *ngIf="!qObject.isPageComponent" class="speaker" (click)="speechQ(qObject.question)"></div>
						-->
					</div>


					<div class="qcontent">
						
						<div class="qtxt">
							<ng-container *ngFor="let item of parts">
								<span *ngIf="item.type == 'text'" [innerHTML]="item.str"></span>
								
								<span *ngIf="item.type=='option'" 
									class="optStyle1"
									[class.selected]="item.selected"
									[class.answer]="true"
									[class.verify]="currentState.verify"
									
									(click)="answerClick(item)" [innerHTML]="item.str"></span>
								<!--
								[ngClass]="['optStyle1', answerIsSelected(i,q.grp,q.id) ? 'selected' : '', isMCAnswer(i,q.grp,q.id) ? 'answer' : '', showVerify(i)  ? 'verify' : '']"
									[class.cIcon]="itm.var.showVerify && isMCAnswer(i,q.grp,q.id)"
									[class.wIcon]="itm.var.showVerify && !isMCAnswer(i,q.grp,q.id)"
								-->
							</ng-container>
							
						</div>
					
					</div>
				</div>
			</div>

			<div class="score">
						
				<!--
					<Scoring [displayType]="'ai'" 
					(onChange)="updateScore($event)" 
					*ngIf="isSubmitted() && qObject.type!='QBInfoBlock' && isDisplayAIScore()"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
					<Scoring (onChange)="updateScore($event)" *ngIf="isSubmitted(i) && qObject.type!='QBInfoBlock' && isDisplayTeacherScore(i)"
					[mode]="viewMode" [questionSetting]="qObject" [scoreResult]="getScoreResult(i)"
					[entryAndContent]="entryAndContent" [share]="share" [questionItem]="qObject"
					[componentName]="getText('componentName.'+qObject.type)"></Scoring>
				-->
			</div>

		</div>

	`,
	styleUrls:[
		"./QBComponentQuestionBase.scss",
		"./QBToggleOPtions.scss"
	]
	/**
	 * 
	 .qcontent{
			display: flex;
			flex-direction: column;
			flex-grow: 1;

			.qtxt{
				>p, >.optStyle1, .FIB{
					display: inline-block;
					position: relative;
				}
				.optStyle1{
					position: relative;
					//background-color: var(--common-bgcolor);
					background-color: #fff;
					border-radius: 10px;
					border: 1px solid #7C7466;
					margin: 0 10px 5px;
					padding: 0 10px;

					&.selected {
						color:#000;
						background-color: #F7E0B3;
					}
				}
	 * 
	 */
})
export class QBToggleOptions extends QBComponentQuestionBase
{
	public inEdit:boolean = false;
	public inEditMode:boolean=false;
	public qObject:any;
	public parts:any [] ;
	public currentState:any = {verify:false, inputs:[]};
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, public fileio:FileIOService)
	{
		super(cdr, elementRef, fileio);
	}

	public canVerify(): boolean {
		return true;
	}

	answerClick(item)
	{
		this.parts.forEach((item2:any)=>{
			if(item2.type == "option" && item2.grp == item.grp) delete item2.selected;
		})
		item.selected = true;
		this.updateSelectionInput();
	}
	private updateSelectionInput()
	{
		var items = [];
		for(var i = 0;i < this.parts.length;i++)
		{
			var item = 	this.parts[i];
			if(item.type == "option" && item.selected) {
				items.push(item);
			}
		}
		this.currentState.inputs = items;
	}
	
	public getTagNames():string []
	{
		return ["QBToggleOptions"];
	}

	protected buildContent():void
	{
		var text:string = this.node.children[0].text;
		var object:any = JSON.parse(text);
		this.qObject = object;
		this.parts = this.getQuestionParts();
	}

	public getQuestionParts():any {
	
		
		let text:string = this.qObject.question.replaceAll("\n","<br/>");
		if(this.qObject.type == "QBToggleOptions" || this.qObject.type == "QBFillingBlank") {
			// 文字切換題
			let parts:any = [];
			let pos:number = 0;
			let re = /(\[[^\]]+\])/ig;
			let myArray;
			let grp:number = 0;
			while ((myArray = re.exec(text)) !== null) {
				// 前面有普通字串
				if(pos < myArray.index)
					parts.push({type:"text", str:text.substring(pos, myArray.index)});
				// option
				let opts = text.substring(myArray.index+1, re.lastIndex-1).split("/");
				if(this.qObject.type == "QBToggleOptions") {
					opts.forEach((opt:string,index:number) => {
						if(opt.substring(0,1)=="#")
							parts.push({type:"option", grp:grp, id:index, answer:true, str:opt.substr(1)});
						else
							parts.push({type:"option", grp:grp, id:index, answer:false, str:opt});
						if(index+1<opts.length)
							parts.push({type:"text", str:"/"});
					});

				} else {
					parts.push({type:"input", grp:grp, answer:opts});
				}
				grp++;
				pos = re.lastIndex;
			}
			if(pos < text.length)
				parts.push({type:"text", str:text.substring(pos, text.length)});

			return parts;
		} else {
			return [];	
		}
	}
	public hasAnswer(): boolean {
		return this.currentState && this.currentState.inputs && this.currentState.inputs.length > 0;
		/*
		for(var i = 0;i < this.parts.length;i++)
		{
			var item = 	this.parts[i];
			if(item.type == "option" && item.selected) return true;
		}
		return false;
		*/
	}
	private clearSelection():void
	{
		this.parts.forEach((item)=>{
			if(item.type == "option") delete item.selected;
		})
	}
	public set data(input:string)
	{
		this.clearSelection();
		this.currentState.verify = false;
		if(input)
		{
			var array:any[] = JSON.parse(input);
			var map = {};
			this.parts.forEach((item:any)=>{
				var key = this.getItemKey(item);
				if(key)
				{
					var key:string = item.grp +"/"+item.str;
					map[key] = item;
				}
			})
			if(array && array.length)
			{
				array.forEach((item:any)=>{
					var key:string = this.getItemKey(item);
					var foundItem:any = map.hasOwnProperty(key) ? map[key] : null;
					if(foundItem)
					{
						foundItem.selected = true;	
					}
				});
			}
		}
	}
	
	public get data():string {
		if(this.hasAnswer())
		{
			var o = this.getResultObjectAndAnswer();
			return o.answerForSubmit;
		}
		return null;
	}

	public verify(show:boolean)
	{
		var o = this.getResultObjectAndAnswer();
		this.resultObject = o.result;
		return this.resultObject;
	}

	private getItemKey(item:any):string
	{
		if(item.type == "option")
		{
			var key:string = item.grp +"/"+item.str;
			return key;
		}
		return null;
	}

	protected getResultObjectAndAnswer():any {
		let qObj:any = this.qObject;
		let qVar:any = this.currentState;
		let totalAns:number = 1;
		let correct:number = 0;
		let wrong:number = 0;
		let answerForSubmit:any;

		
		totalAns = 0;
		this.parts.forEach(e=> {
			if(e.type=="option")
				totalAns = Math.max(e.grp+1, totalAns);
		});
		answerForSubmit = JSON.stringify(qVar.inputs);
		qVar.inputs.forEach(e => {
			let grpOpt = this.parts.find(e2 => e2.type=="option" && e2.grp==e.grp && e2.id==e.id);
			if(grpOpt && grpOpt.answer)
				correct++;
			else
				wrong++; // 選擇錯誤
		});

//		if(correct==0 && wrong==0)
//			return null; // 無答過
		return {
			answerForSubmit:answerForSubmit,
			correct:correct,
			wrong:wrong,
			totalAns:totalAns,
			result:this.getResultObject(qObj, correct, wrong, totalAns)
		};
	}

	protected getResultObject(qObj:any, correct:number, wrong:number, totalAns:number):any {
		if(correct==-1) // 無指定答案
			return {correction:false, correct:-1, maxScore:qObj.fullScore, teacherScore:null};
		
		if(correct == totalAns && wrong == 0) // 全對
			return {
				correction:false,
				score:parseFloat(qObj.fullScore),
				correct:2,
				maxScore:qObj.fullScore
			};
		
		if(correct==0 || parseInt(qObj.scoringType)==1) // 全錯
			return {
				correction:true, // Incorrect 要改正
				score:0,
				correct:0,
				maxScore:qObj.fullScore
			};
		
		// 有對及錯
		let correctCount:number = ["QBTextMC","QBGraphicMC","QBVoiceMC"].indexOf(qObj.type)>=0 ? Math.max(0,correct-wrong) : correct;
		return {
			correction:true, // Incorrect 要改正
			score:Math.round(10*parseFloat(qObj.fullScore)*correctCount/totalAns)/10,
			correct:1,
			maxScore:qObj.fullScore
		};
	}

	
}