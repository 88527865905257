import { Injectable} from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ArrayUtils } from '../common/ArrayUtils';


@Injectable({ providedIn: 'root' })
export class StickerImageService {
	protected cache:any = {};
	protected requestTimer:number = -1;
	protected requestList:any[] = [];
	public stickers:any[] = [];
	public relations:any[] = [];
	protected school_id:number;

	constructor(private datas: DataService, private sans: DomSanitizer) {
	}

	public requestStickerImage(id:any):Promise<any> {
		id = parseInt(id);
		// 看這裡有無
		if(this.cache.hasOwnProperty(id)) {
			return Promise.resolve(this.cache[id]);
		}
		
		// 無，放入待取
		return new Promise((resolve, reject)=>{
			this.requestList.push({id:id, resolve:resolve, reject:reject});
			this.clearRequestTimer();
			this.requestTimer = window.setTimeout(()=>this.findSticker(), 16);
		});
	}

	protected clearRequestTimer():void {
		if(this.requestTimer!=-1) {
			clearTimeout(this.requestTimer);
			this.requestTimer = -1;
		}
	}

	protected findSticker():void {
		this.clearRequestTimer();

		if(this.requestList.length>0) {
			this.datas.post2({data:{
				api:'RONewPrizeNewUi.getTeacherStickersForMarking',
				json:["all"]
			}, loading: false}).then((res:any)=>{
				this.stickers = res.stickers;
				this.relations = res.relations;

				res.stickers.sort((a,b)=>{
					if(a.id>=0 && b.id>=0) {
						if(a.sort==b.sort) {
							return a.id-b.id;
						}
						return a.sort-b.sort;

					} else if(a.id<0 && b.id<0) {
						if(a.sort==b.sort) {
							return b.id-a.id;
						}
						return a.sort-b.sort;
					}
					return a.id<0 ? -1 : 1;
				});

				res.stickers.forEach(s => {
					// 加入 cache
					s.id = parseInt(s.id);
					this.cache[s.id] = s;

					if(s.image!="") {
						// convert image path
						var url = s.image.indexOf("//")==-1 ? 
							"url('//oka.blob.core.windows.net/media/"+s.image+"')" :
							"url("+s.image+")";
						s.image = this.sans.bypassSecurityTrustStyle(url);
					}	

					// 回覆要求者
					for(let i:number=0; i<this.requestList.length; i++) {
						var r:any = this.requestList[i];
						if(parseInt(r.id) == s.id || parseInt(r.id) == 0) {
							r.resolve(s);
							this.requestList.splice(i,1);
							i--;
						}
					}
				});

			});
		}
	}

}
