import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ROComponent, ROPageComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";

import { ViewContainerRef } from '@angular/core';
import { FileIOService } from "src/app/service/FileIO.service";
import { Subscription } from "rxjs";
import { ByteArray, Endian } from "openfl";
import { Base64 } from 'js-base64';
import { UniqueID } from "src/app/common/UniqueID";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";

import  * as Deserializer from './infomaniac-amf/amf/deserializer.js';
import  * as Serializer from './infomaniac-amf/amf/serializer.js';


@Component({
	template:`
		<div class="skin">
			<div [class]="state" >
				<div class="btn playStopBtn play" [class.disable]="!hasRecording"  [class.has-recording]="hasRecording" (tap)="togglePlay()"></div>
				<div class="btn recBtn" (tap)="record()"></div>

				<div class="time-container full-mode">
					<div class="time">
						<span *ngIf="state == 'recording'">{{recordingTime  | date:'mm:ss'}}</span>
						<span *ngIf="state == 'playing' ||state == 'paused'">{{playbackTime | date:'mm:ss'}}</span>
						<span *ngIf="state == 'completed'">{{duration | date:'mm:ss'}}</span>
						
						<span *ngIf="state == 'idle'">00:00</span>
					</div>
					<div *ngIf="state!='recording'" class="duration">
						<span class="separator">/</span>
						<span>{{duration | date:'mm:ss' }}</span>
					</div>
				</div>
				<div class="debug stateTxt full-mode">{{stateText}}</div>
				<div class="is-recording-light mini-mode"></div>
			</div>
		</div>
		<!-- {{audioInfo ? audioInfo.file.name : "" }} -->
	`,
	styleUrls:["./ROSoundRecorder.scss"]
})
export class ROSoundRecorder extends ROComponent
{
	public state:string = "";
	public hasRecording:boolean = false;
	public newRecording:boolean = false;
	public audioInfo:any = {};
	public duration:number = 0;
	private _snd:HTMLAudioElement;
	public playbackTime:number = 0;
	private recorder:any;
	public ref:any;
	private _data:string;
	public maxLength: number;
	public stateText:string = "";
	
	// public readyToPlay:boolean;
	public isDataComponent():boolean
	{
		return true;
	}

	private bytesToHex(bytes:ByteArray):string
	{
		bytes.position = 0;
		var array = [];
		while(bytes.bytesAvailable)
		{
			var byte:number = bytes.readByte();
			var hex = byte.toString(16);
			var char = String.fromCharCode(byte);
			if(/[0-9A-z\_\-\']/.test(char))
			{
				array.push(`${byte}-${hex}-(${char})`);
			} else {
				array.push(`${byte}-${hex}-(X)`);
			}
			
		}
		return array.join(",");
		
	}

	public set snd(input:HTMLAudioElement)
	{
		this._snd = input;
		if(this._snd)
		{
			var map:any = {
				play:(event)=>{
					this.state = "playing";
					this.stateText = this.context.translate("bookviewer.record_play");
				},
				playing:(event)=>{
					this.state = "playing";
					this.stateText = this.context.translate("bookviewer.record_play");
				},
				timeupdate:(event:any)=>{
					this.playbackTime = event.currentTarget.currentTime * 1000;
					// console.log(this.playbackTime);
				},
				ended:(event)=>{
					this.state = "completed";
					this.stateText = "";
				},
				pause:(event)=>{
					this.state = "paused";
				},
				suspend:(event)=>{
					this.state = "idle";
				},
				loadeddata:(event)=>{
					this.duration = this._snd.duration * 1000;
				}
			}
			for(var eventName in map)
			{
				this._snd.addEventListener(eventName, (e:any)=>{
					// console.log(e.type);
					map[e.type].call(this, e);
				})
			}

		}
	}

	public recordingTime:number = 0;

	public getCurrentTime():number
	{
		return new Date().getTime();
	}

	public submit():void
	{
		/*
		// https://api.openknowledge.hk/RainbowOne/index.php/transport/Slave/upload/3
		Step1
			upload  https://api.openknowledge.hk/RainbowOne/index.php/transport/Slave/upload/3
			name:"Upload"
		Step2
			api=ROAnswer.submit_resource3([
				455948,2151429,2151430,
				"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2",
				"#thumbnail#",0,"c3f8_-40B19E4C_E3A342",
				"63c67a77_1043CA",
				{
					"checksum":"DCFC517A",
					"path":"tmp_upload/2023/01/2023-01-17/2023-01-17-183744-63c67a78-163c67a786ec8e-249fdd0f.png","code":0,"token":"d90b3a2488e2b0b8e74dd8b8f5b1dfe8","len":8529,"id":12314509,"server":1,"filename":"18-37-43-E3A342.png","key":"163c67a786ec192c091847","url":"http://api.openknowledge.hk/RainbowOne/tmp_upload/2023/01/2023-01-17/2023-01-17-183744-63c67a78-163c67a786ec8e-249fdd0f.png"
				}
			])
		Step3
			api=ROBookShare.save&json=["455948",[{"result":{"maxScore":0,"correct":-1},"page":"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2","tag":"Recorder","com":"7667672E-985F-CBB0-9F70-2A54608852FB","doc":"2151430","answer":"eNrj4mZkz06tZNNONk6ziNc1MXAydHQ2c4m3NHMxd3UULkpNSy1KzUtOZZM3M042M080N453MTE2c3VhBACkLA6x","type":"add"},{"ref_id":0,"page":"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2","reference":"63c67a77_1043CA","com":"#thumbnail#","doc":"2151430","type":"asset","key":"c3f8_-40B19E4C_E3A342","resourceIndex":0,"mode":"none"},{"result":{"uid":50168,"reference":"63c67a77_1043CA","key":"c3f8_-40B19E4C_E3A342"},"page":"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2","tag":"Page","com":"#thumbnail#","doc":"2151430","answer":"eNrj4mbkLskozU3iYmTPTq1k0042TrOI1zUxcDK0dDVxjnc1djQ2MRIuSk1LLUrNS05lkzczTjYzTzQ3jzc0MDF2dmTkLi5JLEllYeTMyy9JZWQEAEC9FJg=","type":"add"},{"result":{"uid":50168,"reference":"63c67a77_1043CA","key":"c3f8_-40B19E4C_E3A342"},"page":"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2","tag":"Page","com":"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2","doc":"2151430","answer":"eNrj4mbkLskozU3iYmTPTq1k0042TrOI1zUxcDK0dDVxjnc1djQ2MRIuSk1LLUrNS05lkzczTjYzTzQ3jzc0MDF2dmTkLi5JLEllYeTMyy9JZWQEAEC9FJg=","type":"add"},{"ref_id":0,"page":"16F5A0E4-515C-CE4E-1522-A8868F6DB2D2","lesson_id":0,"doc":"2151430","type":"submit","mode":"none"}],2151429,0]

		*/
	}

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public togglePlay():void
	{
		if(this.hasRecording && this._snd)
		{
			if(this.state == "playing")
			{
				this._snd.pause();
				this.state = "paused";
				this.stateText = this.context.translate("bookviewer.record_paused");
			} else {
				this._snd.play();
				this.state = "playing";
				this.stateText = this.context.translate("bookviewer.record_play");
			}
		}
		
	}
	public play():void
	{
		if(this.hasRecording && this._snd)
		{
			this._snd.play();
			this.state = "playing";
			this.stateText = this.context.translate("bookviewer.record_play");
		}
		/*
		var o:any = this.objectToString({x:1, y:2});
		console.log(o)
		*/
		
	}
	public getTagNames():string []
	{
		return ["Recorder"];
	}
	
	protected buildContent():void
	{
		/*
		{
			"hasScoring":false,"ver":1.3,"maxLength":20,
			"controlVisible":"full",
			"douid":"7667672E-985F-CBB0-9F70-2A54608852FB","coordinateExpression":"UA UK KW KH KR X 338 Y 676 D T 676 L 338 H 64 W 258","q":"{\"freezed\":1,\"color\":\"noColor\",\"ballSize\":36,\"show\":0,\"level\":0}","s":"{\"freezed\":1,\"y\":0,\"enable\":0,\"optional\":false,\"offset\":{\"y\":0,\"x\":0},\"reference\":\"rt\",\"x\":258}","isQuestionComponent":true,"locked":false,"questionIndex":0,"qInfo":"{\"id\":1,\"rootIndex\":0,\"order\":0,\"root\":0,\"level\":0,\"pid\":0,\"index\":0}","x":338,"y":676,"w":258,"h":64}-->
		{
			"ver":1.3,"maxLength":180,
			"controlVisible":"mini",
			"coordinateExpression":"UA UK KW KH KR X 850 Y 50 D T 50 L 850 H 64 W 258","isQuestionComponent":true,"hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"locked":false,"questionIndex":0,"qInfo":"{\"rootIndex\":0,\"root\":0,\"id\":1,\"level\":0,\"pid\":0,\"index\":0,\"order\":0}","q":"{\"color\":\"noColor\",\"freezed\":1,\"level\":0,\"ballSize\":36,\"show\":0}","s":"{\"freezed\":1,\"optional\":false,\"reference\":\"rt\",\"offset\":{\"y\":0,\"x\":0},\"enable\":1}","douid":"F70B3713-A977-19B7-38E2-7D1ED5CEE786","x":850,"y":50,"w":258,"h":64}-->
		*/
		
		var options:any = this.node.attributes;
		this.maxLength = options.maxLength ? options.maxLength : 20;
		this.state = "idle";
		this.stateText = "";
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.classList.add("controls-"+options.controlVisible);
	}
	private playSnd(url:string):Promise<any>
	{
		return new Promise((resolve, reject)=>{
			var audio:any = new Audio();
			audio.addEventListener("ended", ()=>{
				resolve(null);
			});
			audio.src = url;
			// audio.
			audio.play();
			
		})
		
	}
	record():void
	{
		if(this.state == "idle" || this.state == "stop" || this.state == "paused" ||this.state == "completed" ||  this.state == null)
		{
			if(this.hasRecording)
			{
				this.context.service.alertService.confirm(
					"ro.component.recorder.overwrite-recording", true
				).then(()=>{
					this.recordNow();
				})
			} else {
				this.recordNow();
			}
		} else if(this.state == "recording")
		{
			if(this.recorder)
			{
				this.recorder.finishRecording();
			} else {
				this.state = "idle";
			}
		} else {

		}
	}
	private beepAndRecord():void
	{
		this.state = "recording";
		// return "zh-CN";
		// return "zh-HK";
		this.beep().then(()=>{
			this.recordNow();
		});
		/*
		this.context.playerManager.playText("請在 beep 一聲之後開始錄音", "zh-HK").then(()=>{
			return this.beep();
		}).then(()=>{
			this.recordNow();
		});
		*/
	}

	private beep(frequency:number = 1500, time:number = 500, volume:number = 0.1):Promise<any>
	{
		// return this.playSnd("./assets/mp3/beep.mp3");
		return new Promise((resolve:Function, reject:Function)=>{
			var win:any = window;
			const audioCtx = new (win.AudioContext || win.webkitAudioContext)();
			// now instead of connecting to aCtx.destination, connect to the gainNode
			var gainNode = audioCtx.createGain()
			gainNode.gain.value = volume // 10 %
			gainNode.connect(audioCtx.destination);
			// create Oscillator node
			const oscillator = audioCtx.createOscillator();
			oscillator.type = 'sine';
			oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime); // value in hertz
			oscillator.connect(gainNode);
			oscillator.start();
			console.log("END");
			setTimeout(
				()=>{
					oscillator.stop();
					resolve(1);
				},
				time 
			);
		});
	}

	public set data(input:string)
	{
		// clear verify and answer
		this.reset();
		this.answerChanged = true;
		this.ref = null;

		this._data = input;
		if(this._data) {
			let jsonObj = this.stringToObject(this._data);
			if(jsonObj.hasOwnProperty("key")) {
				this.ref = {
					key:this.douid,
					reference:jsonObj.reference,
					index:0
				};
			}
			this.answerChanged = false; // 載入的資料不算有用戶改答案
		}
	}
	private reset():void
	{
		// this._snd = null;
		this.answerChanged = false;
		this.releaseRecorder();
		this.releasePlayer();
		this.state = "idle";
		this.sObj.hideScore();
	}
	loadFile(obj: any):void
	{
		// if(this.context.config.dataSource.student)
		console.log(this.context.config.viewMode);
		
		if(this.context.config.viewMode == "scoring")
		{
			this.context.loadStudentFile(
				<ROPageComponent> this.page,
				this, 
				this.context.config.student.uid,
				obj, 0
			).then((url:string)=>{
				this.snd = this.context.service.fileIO.createSoundFromURL(url);
				this.hasRecording = true;
				this.newRecording = false;
				this.state = "idle";
				this.stateText = this.context.translate("bookviewer.sound_loaded");
				this.answerChanged = false;
			});
		} else if(this.context.config.viewMode == "view")
		{
			this.context.loadFile(<ROPageComponent> this.page, this, obj).then((url:string)=>{
				this.snd = this.context.service.fileIO.createSoundFromURL(url);
				this.hasRecording = true;
				this.newRecording = false;
				this.state = "idle";
				this.stateText = this.context.translate("bookviewer.sound_loaded");
				this.answerChanged = false;
			});
		}
	}

	// after assign answer
	protected onAnswerSet():void
	{
		if(this._data)
		{
			var obj:any = this.stringToObject(this._data);
			if(obj)
			{
				this.loadFile(obj);
			} else {
				this.reset();
			}
			
		} else {
			this.reset();
		}
	}

	public getAnswerAssets():any []
	{
		if(this.answerChanged) return [this.ref];
		return null;
	}

	public get data():string
	{
		return this.ref ? this.objectToString({
			key:this.ref.key,
			reference:this.ref.reference
		}) : null;
	}
	

	private recordNow():void
	{
		/*
		if(this.state != "recording")
		{
			return;
		}
		*/
		this.sObj.hideScore();
		this.state = "recording";
		this.stateText = this.context.translate("bookviewer.sound_recording");
		var subscription:Subscription = this.context.service.fileIO.record2(this.maxLength, "wav").subscribe((data:any)=>{
			if(data.type == "recorder")
			{
				this.recorder = data.recorder;
			} else if(data.type == "output")
			{
				if(data.sound.type == "file")
				{
					this.snd = this.context.service.fileIO.createSoundFromFile(data.sound.file);
					this.hasRecording = true;
					this.newRecording = true;
					this.state = "idle";
					this.stateText = this.context.translate("bookviewer.save_record_complete");
					this.answerChanged = true;
					if(this.ref == null)
					{
						this.ref = {
							key:this.douid,
							reference:this.context.createReference(),
							file:data.sound.file,
							index:0
						};
					} else {
						this.ref.reference = this.context.createReference();
						this.ref.file = data.sound.file;
					}
				}
			} else if(data.type == "time")
			{
				this.recordingTime = data.time * 1000;
			}
		},
		(reason:any)=>{
			subscription.unsubscribe();
			this.releaseRecorder();
		},
		()=>{
			subscription.unsubscribe();
			this.releaseRecorder();
		}
		);
	}
	private releaseRecorder():void
	{
		if(this.recorder)
		{
			this.recorder.cancelRecording();
			this.recorder.cancelEncoding();
			this.recorder = null;
		}
	}

	private releasePlayer() {
		if(this._snd)
		{
			this._snd.pause()
			this._snd = null;
		}
	}
	
	// ======================================================
	// data function
	// ======================================================
	public isQuestionComponent():boolean {
		return true;
	}

	public verify(_showScoring:boolean):any {
		// reset verify
		// reset score display
		this.sObj.hideScore();

		if(this.ref || this._data) {
			if(!this.resultObject)
				this.resultObject = {correct:-1};
		} else
			this.resultObject = null;

		// 顯示分數 / 已提交
		this.sObj.showScoring(_showScoring, "view", this.resultObject);
		return this.resultObject;
	}
}