import { Component, ElementRef } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: "no-cell-renderer",
	template: `{{text}}`,
	styles: [`
		:host {
			display: block;
			width: 100%;
			height: 100%;
			background-color: var(--common-bgcolor);
			color: var(--common-textcolor);
		}
	`]
})


export class NoCellRenderer {
	public params: any;
	public text:string = '';

	constructor(private eleRef:ElementRef) {
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		const ele = this.eleRef.nativeElement;
		if (this.params.backgroundColor) ele.style.setProperty('background-color', this.params.backgroundColor);
		if (this.params.color) ele.style.setProperty('color', this.params.color);
		if (this.params.text) this.text = this.params.text;
	}

}
