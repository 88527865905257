import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserIconEditorComponent } from './userIconEditor.component';
import { BobbleBoxModule } from '../bobbleBoxModule/bobbleBox.module';
import { IconBtnModule } from '../iconBtnModule/iconBtn.module';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { FormsModule } from '@angular/forms';
import { ModalModule } from '../modalModule/modal.module';


@NgModule({
    imports: [
        CommonModule,
		FormsModule,
		ModalModule,
		BobbleBoxModule,
		IconBtnModule,
		AngularCropperjsModule
    ],
    declarations: [
        UserIconEditorComponent
    ],
    exports: [
        UserIconEditorComponent
    ],
    providers: [],
    bootstrap: []
})
export class UserIconEditorModule { }
