import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { AlertService } from 'src/app/service/alert.service';
import { faBook, faFileImage, faFileAudio, faFileVideo, faFile, faBookBlank, faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'book-read-only-modal',
    template: `
    <ng-template #modal>
		<div class="container">
			<div [ngClass]="['photo', data.entryType?data.entryType:'',photo?'':'no-photo']" [style.backgroundImage]="photo">
				<svg-icon class="share-icon" *ngIf="svgIcon" [name]="svgIcon"></svg-icon>
				<fa-icon class="share-icon" *ngIf="faIcon" [icon]="faIcon"></fa-icon>
			</div>
			<div class="name">
				<span>{{data.name}}</span>
			</div>
			<div [ngClass]="['label', status]">{{('open-book.status-' + status)|translate}}</div>
			<div [ngClass]="['message', status]">
				<span>{{ ('open-book.status-message-' + status)|translate: {expDate: expDate} }}</span>
			</div>
	  </div>
    </ng-template>
  `
    , styleUrls: ['./bookReadOnly.modal.scss'],
})

export class BookReadOnlyModal implements OnInit {
    @Input() data: any;
	@Output() openBook = new EventEmitter<any>();
	@Output() close = new EventEmitter<any>();
	@ViewChild('modal', {static: true}) modal;
    public status = 'marking';
    public expDate = 'YYYY/M/D';
    public cover;
    public photo;
	public ready = false;
	public faIcon;
	public svgIcon;
	faBook = faBook;
	faBookBlank = faBookBlank;
	faGlobe = faGlobe;
	faFile = faFile;
	faFileAudio = faFileAudio;
	faFileImage = faFileImage;
	faFileVideo = faFileVideo;
	faMessageLines = faMessageLines;
    constructor(private translate: TranslateService, private sanitizer: DomSanitizer, public datas: DataService, private ro: RoService, private als: AlertService) {
    }

    ngOnInit() {
		this.status = this.data.status;
		this.status = this.status == 'checking'?'marking':this.status;
		this.initCoverForNonBook();
		const btns = this.initBtn(this.status);
		if (this.status && this.status.indexOf('not-start') > -1){
			this.expDate = this.data.startMo.format('YYYY/M/D h:mma');
		} else if (this.status && this.status.indexOf('expired') > -1 && this.data.endMo){
			this.expDate = this.data.endMo.format('YYYY/M/D h:mma');
		}
		if (this.data.cover || this.data.entry != 'book'){
			this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + this.data.cover + "')");
			this.als.alert2(null, null, { btns: btns, templateContent:this.modal }).then(key=>{
				if (key == 'read'){
					this.openClick();
				} else {
					this.close.emit();
				}
			});
		} else {
			this.datas.post('ROBookShelf.get_single_book_cover2', [this.data.bsid, this.data.book_id]).subscribe((res: any) => {
				if (res.code) {
					this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + res.url + "')");
					// this.ready = true;
					this.als.alert2(null, null, { btns: btns, templateContent:this.modal }).then(key=>{
						if (key == 'read'){
							this.openClick();
						} else {
							this.close.emit();
						}
					});
				}
			});
		}
    }

	initBtn(status){
		let btns = [];
		if (status == 'marking' || status == 'expired' || status == 'correction-expired'){
			btns = [[{key:'read',labelKey:'open-book.read', ngStyle:{backgroundColor:"var(--section-maincolor)"} }, 'cancel']];
		} else if (status == 'not-start' || status == 'correction-not-start'){
			btns = [[{key:'confirm', labelKey: 'open-book.ok-confirm', ngStyle:{backgroundColor:"var(--section-maincolor)"} }]];
		}
		return btns;
	}

	openClick() {
		this.openBook.emit({mode: 'read-index-1'}); //open as read only
	}

    closeModal($event = null) {
        this.close.emit();
    }

	initCoverForNonBook(){
		const entryType = this.data.entryType;
		if (entryType == 'image') {
			this.faIcon = this.faFileImage;
		} else if (entryType == 'video') {
			this.faIcon = this.faFileVideo;
		} else if (entryType == 'audio') {
			this.faIcon = this.faFileAudio;
			this.svgIcon = 'fileicon3_audio';
		} else if (entryType == 'url') {
			this.svgIcon = 'icon_weblink_16x16';
		} else if (entryType == 'youtube') {
			this.svgIcon = 'icon_youtube_16x12';
		} else if (entryType == 'report') {
			this.svgIcon = this.datas.lang == 'en' ? 'icon_reading_report_15x18_en' : 'icon_reading_report_15x18';
		} else if (entryType == 'pdf-paper') {
			this.svgIcon = 'fileicon3_testpaper';
		} else if (entryType == 'ai-oral') {
			this.faIcon = this.faMessageLines;
		}
	}

}
