import { Component, HostBinding, HostListener, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { BobbleBoxComponent } from './bobbleBox.component';
import { DateTimePickerSelectDateComponent } from './dateTimePickerSelectDate.component';
import { CommonService } from 'src/app/service/common.service';
import { TopLayer } from 'src/app/common/TopLayer';

@Component({
  selector: 'dateTimePicker',
  templateUrl: 'dateTimePicker.component.html',
  styleUrls: ['./dateTimePicker.component.scss']
})

export class DateTimePickerComponent implements OnChanges {
  @HostBinding('style.zIndex') public hostZIndex = 99;
  @HostListener('dblclick', []) onDblClick() { console.log(this); }
  @ViewChild("bobbleBox", {static:true}) bobbleBox: BobbleBoxComponent;
  @ViewChild("dateTimePickerSelectDateForStartDate", {static:false}) dateTimePickerSelectDateForStartDate:DateTimePickerSelectDateComponent;
  @ViewChild("dateTimePickerSelectDateForEndDate", {static:false}) dateTimePickerSelectDateForEndDate:DateTimePickerSelectDateComponent;

  // 語言
  @Input() public lang:string = 'tc';
  // 「清除資料」Button
  @Input() public clearBtn:boolean = false;

  @Input() public color1:string;
  public _color1:string;
  @Input() public color2:string;
  public _color2:string;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  // 只顯示單一日期選擇
  public dateOnly:boolean = false;
  // 正在操作的資料格
  public forField:string = 'startDate';
  // 資料暫存
  public curStartDateTime:Date = this.cloneAndFixMinute(new Date());
  public curEndDateTime:Date = this.cloneAndFixMinute(new Date());
  public needEndTime:boolean = false;
  public wholeDay:boolean = false;
  // 按下「今天」或任何日期後馬上關閉pop-up
  public closeOnClick:boolean = false;
  public originalData:any = {};
  public dirty = false;
  constructor(private coms:CommonService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
      if (changes && changes.lang && changes.lang.currentValue) {
          this.lang = changes.lang.currentValue.toLowerCase();
      }
  }

  private initBeforeOpen():void {
    this.setColor();
    if (this.dateTimePickerSelectDateForStartDate) this.dateTimePickerSelectDateForStartDate.curView = 'selectDate';
    if (this.dateTimePickerSelectDateForEndDate) this.dateTimePickerSelectDateForEndDate.curView = 'selectDate';
  }

  public open(
      targetEle:HTMLElement,
      startDateTime:Date = new Date(),
      endDateTime:Date = null, //new Date(new Date().setHours(new Date().getHours() + 1)),
      wholeDay:boolean = false,
      defaultSelect:'startDate'|'startTime'|'endDate'|'endTime' = 'startDate'
  ):Promise<any> {
      
        this.hostZIndex = TopLayer.getNextIndex();
       
      return new Promise((resolve:Function, reject:Function)=>{
          this.initBeforeOpen();
          this.dateOnly = false;
          this.curStartDateTime = this.cloneAndFixMinute(startDateTime || new Date());
          this.curEndDateTime = this.cloneAndFixMinute(endDateTime || this.curStartDateTime || new Date() );
          if (this.curStartDateTime >= this.curEndDateTime) this.curEndDateTime.setTime(this.curStartDateTime.getTime() + 300000);
          this.needEndTime = endDateTime ? true : false;
          this.wholeDay = wholeDay;
          this.originalData = {curStartDateTime: startDateTime?this.cloneAndFixMinute(startDateTime):null, curEndDateTime: endDateTime?this.cloneAndFixMinute(endDateTime):null, wholeDay: wholeDay };
          this.forField = (
              (this.wholeDay && (defaultSelect=='startTime' || defaultSelect=='endTime')) ||
              (!this.needEndTime && (defaultSelect=='endDate' || defaultSelect=='endTime'))
          ) ? 'startDate' : defaultSelect;
          this.bobbleBox.open(targetEle).then(()=>{
              resolve({
                  startDateTime: this.curStartDateTime,
                  endDateTime: this.needEndTime ? this.curEndDateTime : null,
                  wholeDay: this.wholeDay,
                  dirty: this.dirty
              });
          });
      });
  }

  public openDateOnly(targetEle:HTMLElement, date:Date=new Date(), closeOnClick:boolean=false):Promise<any> {
      return new Promise((resolve:Function, reject:Function)=>{
          this.initBeforeOpen();
          this.dateOnly = true;
          this.closeOnClick = closeOnClick;
          this.forField = 'startDate';
          this.curStartDateTime = this.cloneAndFixMinute(date || new Date());
          this.bobbleBox.open(targetEle).then(()=>{
              resolve(this.curStartDateTime);
          });
      });
  }

  public setForField(forField:string):void {
      this.forField = forField;
  }

  public getDateString(date:Date=this.cloneAndFixMinute(new Date())):string {
      if (!date) return '';
      return date.getFullYear() + "/" + this.d2(date.getMonth() + 1) + "/" + this.d2(date.getDate());
  }

  public getTimeString(date:Date=this.cloneAndFixMinute(new Date())):string {
      if (!date) return '';
      let hour:number = date.getHours();
      let min:number = date.getMinutes();
      let amPm:string = ' am';

      if (hour > 11) amPm = ' pm';
      if (hour > 12) hour = hour - 12;

      return hour + ":" + this.d2(min) + amPm;
  }

  public cloneAndFixMinute(date:Date):Date {
      let copiedDate = new Date(date.getTime());
      while ((copiedDate.getMinutes() % 5) > 0) {
          copiedDate.setMinutes(copiedDate.getMinutes()+1);
      }
      return copiedDate;
  }

  public toggleNeedEndTime():void {
      if (!this.needEndTime && !this.curEndDateTime) {
          this.curEndDateTime = this.cloneAndFixMinute(new Date(new Date().setHours(this.curStartDateTime.getHours() + 1)));
      }
      this.needEndTime=!this.needEndTime;
      if (this.needEndTime){
        this.setForField('endDate');
      } else {
        if (this.forField=='endDate') {
          this.setForField('startDate');
        }
        if (this.forField=='endTime') {
          this.setForField('startTime');
        }
      }
      this.emitUpdate();
  }

  public clearBtnClick():void {
      this.curStartDateTime = null;
      this.curEndDateTime = null;
      this.wholeDay = false;
      this.bobbleBox.submit();
      this.emitUpdate();
  }

  public cancelBtnClick():void {
    const originalData = this.originalData;
    this.curStartDateTime = originalData.curStartDateTime;
    this.curEndDateTime = originalData.curEndDateTime;
    this.wholeDay = originalData.wholeDay;
    this.bobbleBox.submit();
    this.emitUpdate();
  }

  private hexToRgba(hex:string):string {
      let r, g, b, a;

      hex = hex.replace('#', '');
      if (3 === hex.length) {
          r = hex.charAt(0);
          g = hex.charAt(1);
          b = hex.charAt(2);
      } else if (4 === hex.length) {
          r = hex.charAt(0);
          g = hex.charAt(1);
          b = hex.charAt(2);
          a = hex.charAt(3);
      } else if (6 === hex.length) {
          r = hex.substring(0, 2);
          g = hex.substring(2, 4);
          b = hex.substring(4, 6);
      } else if (8 === hex.length) {
          r = hex.substring(0, 2);
          g = hex.substring(2, 4);
          b = hex.substring(4, 6);
          a = hex.substring(6, 8);
      } else {
          return '';
      }
      if ('undefined' === typeof a) a = 'ff';
      if (1 === r.length) r += r;
      if (1 === g.length) g += g;
      if (1 === b.length) b += b;
      if (1 === a.length) a += a;
      r = parseInt(r, 16);
      g = parseInt(g, 16);
      b = parseInt(b, 16);
      a = parseInt(a, 16) / 255;
      return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
  }

  private setColor():void {
      let c1 = this.color1;
      let c2 = this.color2;

      if (c1 && !c2) {
          if (c1.startsWith('#')) {
              if (c1.length==4) {
                  c2 = c1 + c1.substr(1,3) + '1a';
              } else if (c1.length==7) {
                  c2 = c1 + '1a';
              }
          }
      }
      this._color1 = this.hexToRgba(c1 || "#AA2E83");
      this._color2 = this.hexToRgba(c2 || "#AA2E831A");//"#EFE7FB";
  }

  d2(num){
    return (num < 10) ? "0" + num.toString() : num.toString();
  }

  switchClick(){
    this.wholeDay=!this.wholeDay;
    this.setForField(this.forField=='endTime' ? 'endDate' : this.forField=='startTime' ? 'startDate' : this.forField);
    this.emitUpdate();
  }

  public dateTimeChangeHandler(changeType:string):void {
    // 單選日可能要處理click日期後即close pop up
    if (this.dateOnly) {
        if (this.closeOnClick && changeType=='startDate') this.bobbleBox.submit();
    // 如是date range，則要處理startTime大過endTime
    } else if (this.curStartDateTime >= this.curEndDateTime) {
        if (changeType=='startDate' || changeType=='startTime') {
            this.curEndDateTime.setTime(this.curStartDateTime.getTime() + 300000);
        } else if (changeType=='endDate' || changeType=='endTime') {
            this.curStartDateTime.setTime(this.curEndDateTime.getTime() - 300000);
        }
    }

    this.emitUpdate();

  }

  emitUpdate(){
    let wholeDay = false;
    if (this.curStartDateTime && this.curEndDateTime){
      const start = this.toTimeFormat(this.curStartDateTime);
      const end = this.toTimeFormat(this.curEndDateTime);
      wholeDay = start == '00:00:00' || end == '23:59:59';
    }
    this.update.emit({
      startDateTime: this.curStartDateTime,
      endDateTime: this.needEndTime ? this.curEndDateTime : null,
      wholeDay: wholeDay
    });
  }

  toTimeFormat(d){
    const h = d.getHours();
    const hours = h < 10?'0'+h:''+h;
    const m = d.getMinutes();
    const minutes = m < 10?'0'+m:''+m;
    const s = d.getSeconds();
    const seconds = s < 10?'0'+s:''+s;
    return hours + ':' + minutes + ':' + minutes;
  }

}
