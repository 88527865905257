import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { faCaretDown, faCaretUp, faCaretSquareDown, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Component({
    selector: 'okaPulldown',
    template: `

    <ng-template #selectTemplate>
      <div
        #okaPulldown
        class="okaPulldown"
        [style.width]="okaPulldownStyle.width || menuStyle.width || '150px'"
        [ngStyle]="okaPulldownStyle"
        (click)="toggleMenu()"
      >
        <div class="content">
          <span *ngIf="!selected" class="label placeholder">{{placeholder | translate}}</span>
          <span *ngIf="selected" class="label">{{(selected[bindLabel]||selected) | translate}}</span>
          <fa-icon [icon]="(isShowMenu ? faCaretUp : faCaretDown)"></fa-icon>
        </div>
      </div>
    </ng-template>

    <ng-content *ngTemplateOutlet="selectTemplate"></ng-content>

    <div
      #menu
      class="menu"
      [style.top.px]="menuTop"
      [style.left.px]="menuLeft"
      [style.width]="menuStyle.width || okaPulldownStyle.width || '150px'"
      [style.zIndex]="zIndex"
      [style.visibility]="(isShowMenu?'visible':'hidden')"
      [ngStyle]="menuStyle"
    >
      <ng-container *ngIf="menuPosition=='bottom'">
        <ng-content *ngTemplateOutlet="selectTemplate"></ng-content>
      </ng-container>

      <perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
        <div class="optionsWrapper" [class.bottom]="menuPosition=='bottom'" [class.top]="menuPosition=='top'">
          <div *ngIf="allowNull" class="option placeholder" (click)="setSelected(null)">{{placeholder | translate}}</div>
          <div
            *ngFor="let option of options"
            class="option"
            [class.selected]="selected==option"
            (click)="setSelected(option)">
            {{(option[bindLabel]||option) | translate}}
          </div>
          </div>
        </perfect-scrollbar>

        <ng-container *ngIf="menuPosition=='top'">
          <ng-content *ngTemplateOutlet="selectTemplate"></ng-content>
        </ng-container>
    </div>
    
    `,
    styleUrls: ['./okaPulldown.component.scss'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OkaPulldownComponent),
        multi: true,
      },
    ],
})

export class OkaPulldownComponent implements ControlValueAccessor,OnInit {
  public faCaretDown:IconDefinition = faCaretDown;
  public faCaretUp:IconDefinition = faCaretUp;
  public faCaretSquareDown :IconDefinition = faCaretSquareDown;

  @ViewChild('okaPulldown', {static:false}) public okaPulldown:ElementRef<HTMLDivElement>;
  @ViewChild('menu', {static:false}) public menu:ElementRef<HTMLDivElement>;

  @Input() public options:any[] = [];
  @Input() public bindLabel:string = 'label';
  @Input() public bindValue:string = '';
  @Input() public placeholder:string = 'Please Select...';
  @Input() public allowNull:boolean = false;

  @Input() public okaPulldownStyle:any = {};
  @Input() public menuStyle:any = {};

  @Output() callback = new EventEmitter<any>();
  @Input() public preset:any = {};

  public selected:any = null;
  public isShowMenu:boolean = false;
  public isDisabled:boolean = false;
  public zIndex:number = 999;
  public menuLeft:number = 0;
  public menuTop:number = 0;
  public menuPosition:string = 'bottom';

  constructor(private coms:CommonService, public datas:DataService, public translate:TranslateService, private eleRef:ElementRef, private themeService:ThemeService) {
    themeService.getThemeJson("okaPulldownModule.json").then((styleObj:any)=>{
      themeService.applyStyleObj(styleObj, eleRef.nativeElement);
    });
  }
  //-implements ControlValueAccessor---------------------------------------------------------------
  private onChange = (v: any) => {};
  private onTouched = () => {};

  ngOnInit(): void {
	if (!_.isEmpty(this.preset)) {
		this.setSelected(this.preset)
	}
  }

  writeValue(value: any): void {
    let valueToSet:any = value;
    if (this.bindValue) {
      valueToSet = this.options.find((option:any)=>option[this.bindValue]==value);
    }
    this.selected = valueToSet;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  //-----------------------------------------------------------------------------------------------
  public toggleMenu():void {
    this.isShowMenu ? this.hideMenu() : this.showMenu();
  }

  public showMenu():void {
    //因為如果init後馬上打開menu，menu的位置會不對，所以加到setTimeout內
    setTimeout(()=>{
      if (this.isDisabled || !this.okaPulldown || !this.okaPulldown.nativeElement) return;

      let rect:DOMRect = <DOMRect>this.okaPulldown.nativeElement.getBoundingClientRect();
      let menuEle:HTMLDivElement = this.menu.nativeElement;
      this.menuPosition = 'bottom'; //如果menu的位置過低超出畫面，需要改為在上面顯示
      if ((rect.top + menuEle.offsetHeight) > window.innerHeight) this.menuPosition = 'top';
      this.menuLeft = rect.left;
      this.menuTop = this.menuPosition=='bottom' ? rect.top : rect.top + rect.height - menuEle.offsetHeight;
  
      this.zIndex = this.coms.getZIndex();
      this.isShowMenu = true;
      
      let closeFn:any = (ev:PointerEvent)=>{
        if (this.menu && this.menu.nativeElement && ev.target) {
          if (!this.isDescendant(this.menu.nativeElement, ev.target)) {
            this.hideMenu();
            window.removeEventListener('mousedown', closeFn);
            window.removeEventListener('scroll', closeFn, true);
          }
        }
      };
      
      setTimeout(()=>{
          window.addEventListener('mousedown', closeFn);
          window.addEventListener('scroll', closeFn, true);
      });

    });
  }

  public hideMenu():void {
    this.isShowMenu = false;
  }

  public setSelected(value:any):void {
	// console.log(this.selected);
	// console.log(value);
	this.callback.emit(value)
    if (this.selected != value) {
      this.selected = value;
      this.onChange(this.bindValue ? this.selected[this.bindValue] : this.selected);
      this.onTouched();
    }
    this.hideMenu();
  }

  private isDescendant(parent, child):boolean {
    let node = child.parentNode;
    while (node) {
      if (node === parent) return true;
      node = node.parentNode;
    }
    return false;
  }

}
