import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { environment } from 'src/environments/environment';
import { StorageService } from './service/storage.service';
import { tap, delay } from 'rxjs/operators';
import { of, forkJoin, from, Subject } from 'rxjs';
import { LoadingService } from '../loadingModule/loading.service';
import moment from 'moment';
import { RODocumentService } from '../roModule/RODocumentService';
import { ThemeService } from '../../service/theme.service';
import { HttpClient } from '@angular/common/http';
import { ByteArray } from "openfl";
import { Base64 } from 'js-base64';
import { IconDefinition, faRedo } from '@fortawesome/pro-regular-svg-icons';
import { SettingService } from 'src/app/service/setting.service';

@Component({
	selector: 'report-personal-modal',
	template: `
    <div class="report-personal-modal">
      <div class="modal-header">
        <div class="header__title  {{themeSelected}}-theme">
          <div *ngIf="studentPhoto" class="header__photo" [style.backgroundImage]="studentPhoto"></div>
          <span *ngIf="studentName">{{ modalTitle }}</span>
        </div>
        <a class="header__close section-{{modData.section}}" [class.fromAppModal]="storage.fromAppModal" (click)="cancelModal()">
          <span>{{'commonService.confirm'|translate}}</span>
        </a>
      </div>
      <div class="modal-body section-{{modData.section}}">
	  	<div class="tabs" [class.loaded]="bothApiLoaded">
			<div class="tabs-item section-{{modData.section}}" [class.selected]="selectedTab == 'data-table'" (click)="setSelectedTab('data-table')">
				<span>{{'report-personal.data-table'|translate}}</span>
			</div>

			<div class="tabs-item section-{{modData.section}}" *ngIf="modData.show_ranking" [class.selected]="selectedTab == 'ranking'" (click)="setSelectedTab('ranking')">
				<span>{{ {tc:'排行榜',sc:'排行榜',en:'Ranking'}[lang] }}</span>
			</div>

			<div class="tabs-item section-{{modData.section}}" *ngIf="modData.show_chart" [class.selected]="selectedTab == 'chart'" (click)="setSelectedTab('chart')">
				<span>{{ {tc:'圖表',sc:'图表',en:'Chart'}[lang] }}</span>
			</div>

			<div class="pd-status">
				{{ {tc:'資料來源',sc:'资料来源',en:'Source'}[lang] }}:
				<okaPulldown2 [options]="statusOptions" bindLabel="label" bindValue="value" 
					[okaPulldownStyle]="{width: '130px'}" [(ngModel)]="selectedStatus" (ngModelChange)="refresh()"></okaPulldown2>
			</div>

			<div class="refresh" (click)="refresh()">
				<fa-icon [icon]="faRedo"></fa-icon>
			</div>
		</div>
        <report-personal [modData]="modData" [parent]="this"
			(callOpenBookModal)="callOpenBookModal($event)" *ngIf="bothApiLoaded && selectedTab == 'data-table'"></report-personal>

		<p-ranking
			*ngIf="bothApiLoaded && selectedTab == 'ranking'"
			[parent]="this"
			></p-ranking>

		<p-data-chart
			*ngIf="bothApiLoaded && selectedTab == 'chart'"
			[parent]="this"
			></p-data-chart>

      </div>
    </div>
  `
	, styleUrls: ['./report-personal.modal.scss']
})

export class ReportPersonalModal implements OnInit {
	@Input() data: any;
	@Output() public loaded: EventEmitter<any> = new EventEmitter();
	@Output() public close: EventEmitter<any> = new EventEmitter();
	@Output() public filterChange: EventEmitter<any> = new EventEmitter();
	public faRedo:IconDefinition = faRedo;  
	public onDestroy = new Subject();
	public modalStyle;
	public headerColor;
	public studentName = '';
	public modData: any = {};
	public studentPhoto;
	public bothApiLoaded = false;
	public objectiveChartData:any;
	public rankingData:any;
	public themeSelected = 'light';
	public selectedTab = 'data-table';
	public compXmlInfo:any;
	public modalTitle = '';
	public lang = 'tc';
	public statusOptions = [];
	public selectedStatus = 1;//1
	public isTeacher = false;
	constructor(private translate: TranslateService, private sanitizer: DomSanitizer, private datas: DataService, public storage: StorageService, private lds: LoadingService, private roDoc: RODocumentService, private theme: ThemeService, public eleRef: ElementRef, public http: HttpClient, public setting: SettingService) {
		this.initModal();
	}

	ngOnInit() {
		this.isTeacher = this.datas.userInfo.user_role == 3;
		this.lang = this.datas.lang;
		this.translate.use(this.datas.lang);
		this.themeSelected = this.theme.theme$.value;
		const bsid = this.data.bsid;
		const bid = this.data.book_id;
		const tagId = this.data.tag;
		let role = 2;
		this.statusOptions = [
			{label: {tc:'初次提交', sc:'初次提交', en:'First submit'}[this.lang], value: 1}, 
			{label: {tc:'改正', sc:'改正', en:'Correction'}[this.lang], value: 2}, 
		];
		this.initTheme(this.eleRef.nativeElement);
		this.storage.fromAppModal = this.data.fromAppModal ? 1 : 0;
		if (this.datas.jwtObj) {
			role = this.datas.jwtObj.user_role ? this.datas.jwtObj.user_role : role;
		}
		let bookType = this.data.type ? this.data.type : this.data.bookType;
		const section = this.getSection();
		const defaultShowRanking = this.setting.schoolSettings.SHOW_RANKING_PERSONAL_REPORT == '1'?1:0;
		const defaultShowChart = this.setting.schoolSettings.SHOW_CHART_PERSONAL_REPORT == '1'?1:0;
		this.modData = { uid: this.data.uid, bsid: bsid, bid: bid, lang: this.storage.lang, bookImage: '', bookType: bookType, structure: null, tagId: tagId, role: role, appId: this.datas.appId2, result: [], section: section, show_ranking: defaultShowRanking, show_chart: defaultShowChart };
		
		let api$ = this.datas.post('ROLearnProgress.getReportPersonalModalInfo', [this.data.uid, bsid, bid, this.selectedStatus]).pipe(tap((res: any) => {
			let user = res.user;
			let photo = environment.baseHref + 'assets/img/m2.png';
			if (user) {
				this.studentName = this.storage.lang == 'tc' ? user.cname : user.ename;
				if (this.objectiveChartData){
					this.objectiveChartData.studentName = this.studentName;
				}
				if (user.url) {
					photo = environment.resourceServer[user.server] + user.url;
				} else {
					photo = environment.baseHref + 'assets/img/' + (user.gender == '1' ? 'm' : 'f') + user.user_role + '.png';
				}
			}
			this.initModalTitle(bookType);
			if (res.bookImage) {
				this.modData.bookImage = res.bookImage.replace('storage.openknowledge.hk', 'oka.blob.core.windows.net');
			}
			this.modData.structure = res.bookStructure;
			this.modData.live_verify = res.bookStructure.live_verify == 1 ? 1 : 0;
			this.modData.result = res.result ? res.result : [];

			this.modData.viewerRole = role;
			this.storage.role = role;
			if (res.share){
				this.modData.shares = [ res.share ];
				if (res.share.show_ranking == '1' || res.share.show_ranking == '0'){
					this.modData.show_ranking = res.share.show_ranking; 	
				}
				if (res.share.show_chart == '1' || res.share.show_chart == '0'){
					this.modData.show_chart = res.share.show_chart; 	
				}
				this.modData.tag_id = res.share.tag_id;
				this.modData.tag_name = res.share.tag_name;
			}
			const bookStatus = this.modData && this.modData.book?this.modData.book.status:'unknown';
			if (this.modData.live_verify == 0 && bookStatus != 'finished' && !this.isTeacher && this.datas.userInfo.school_id != '1675'){
				this.modData.show_ranking = 0;
				this.modData.show_chart = 0;
			}
			this.modData.pass_level = res.share ? res.share.pass_level : null;
			this.modData.pass_condition = res.share ? res.share.pass_condition : null;
			this.modData.book = this.data.book;
			let status = res.share.status;
			if (res.share.end_time) {
				let mo = moment(res.share.end_time);
				status = mo.isBefore() ? 'complete' : status;
			}
			if (this.modData.book == null) {
				this.modData.book = { status: status };
			} else if (this.modData.book.status === undefined) {
				this.modData.book.status = status;
			}
			this.studentPhoto = this.sanitizer.bypassSecurityTrustStyle("url('" + photo + "')");
		}));

		let api2$ = this.datas.post('ROResultPanel.list_component_name').pipe(
			tap((res: any) => {
				if (res.components) {
					let compName = [];
					compName['tc'] = [];
					compName['en'] = [];
					res.components.forEach(row => {
						if (row.lang == 'tc' || row.lang == 'en') {
							compName[row.lang][row.com_key] = row.com_value;
						}
					});
					this.modData.compName = compName;
				}
			})
		);

		let api3$ = from(this.roDoc.open( {id: bid} ).then((res:any)=>{
			const parser = new DOMParser();
			this.compXmlInfo = [];
			res.chapters.forEach(ch => {
				let xml = ch.xml.replace(/&quot;/g, '\"');
				const scoreComs = xml.match(/<ScoreCom(.*?)\>/g) || [];
				this.compXmlInfo = [...this.compXmlInfo, ...scoreComs];
			});
		}));

		const delay$ = of(true).pipe(delay(300));

		forkJoin([api$, api2$, api3$, delay$]).subscribe(event=>{
			this.lds.remove('getReportPersonalModalInfo');
			this.modData.compXmlInfo = this.compXmlInfo;
			this.rankingData = {bsid: bsid, bookId: bid, status: this.modData.book.status, structure: this.modData.structure, result: this.modData.result, bookType: this.modData.bookType, compXmlInfo: this.compXmlInfo, tag_name: this.modData.tag_name };
			if (this.modData.structure == null || Array.isArray(this.modData.structure)) {
				this.roDoc.getBookStructure({ id: bid, bsid: bsid, book_id: bid }).then(o => {
					let modData = this.modData;
					let obj: any = { live_verify: modData.live_verify, pass_level: modData.pass_level, status: event[0].share.status, type: 'normal' };
					obj.structure = JSON.stringify(o);
					this.modData.structure = obj;
					this.rankingData.structure = obj;
				}).finally(()=>{
					this.bothApiLoaded = true;
					this.loaded.emit(null);
				});
			} else {
				this.bothApiLoaded = true;
				this.loaded.emit(null);
			}
		});

		let hostname = location.port == "" ? location.hostname : 'dev.openknowledge.hk';
		const apiUrl = '//' + hostname + '/RainbowOne/index.php/Report/get_book_share_summary/' + bsid + '/' + bid + '/1';
		this.http.post(apiUrl, {}).subscribe((data: any) => {
			if (typeof data === 'object') {
				this.initObjectiveChartData(data);
			}
		});

	}

	protected initModal() {
		this.modalStyle = {
			width: 'calc(100vw - 40px)',
			height: 'calc(100vh - 40px)',
		};
	}

	public cancelModal() {
		this.close.emit();
	}

	getSection(){
		let section = 'default';
		const href = location.href;
		if (href.indexOf('roWeb-ie') > -1){
			return 'default';
		}
		const sessionStorageSection = sessionStorage.getItem('openBookFrom');
		for (const sec of ['bookshelf','course']){
			if (href.indexOf('/'+sec + '/') > -1 || sessionStorageSection == sec){
				section = sec;
			}
		}
		if (location.href.indexOf('bookViewer') > -1){
			section = 'bookshelf';
		}
		return section;
	}

	callOpenBookModal($event) {
		this.storage.openBookModal = $event;
	}

	initTheme(ele) {
		this.theme.applyStyleObj({
			default: {
				'bfae95-53536a': "#bfae95",
				'c9dc8df-53536a': "#9dc8df",
				'dbc9ab-53536a': "#dbc9ab",
				'c1c1f8-53536a': '#c1c1d8',
				'c007e6c-53536a':'#007e6c',
				"dbc9ab-2d2c44": "#dbc9ab",
				'dbc9ab-38374f': "#dbc9ab",
				'f5eee3-38374f': "#f5eee3",
				'fff-2d2c44': "#fff",
				'c975d28-bf8249': '#975d28',
				'effafe-38374f':'#effafe',
				'f7f7fe-38374f':'#f7f7fe',
				'e4f3fb-2d2c43':'#e4f3fb',
				'c2292d5-bf8249':'#2292d5',
				'c684ccc-bf8249':'#684ccc',
				'c007e6c-bf8249':'#007e6c',
				'f0e6d7-2d2c43': "#f0e6d7",
				'f1f1f7-2d2c43': '#f1f1f7',
				'dee5e2-2d2c43': '#dee5e2'
			},
			dark: {
				'bfae95-53536a': "#53536a",
				'c9dc8df-53536a': "#53536a",
				'dbc9ab-53536a': "#53536a",
				'c1c1f8-53536a': '#53536a',
				'c007e6c-53536a':'#53536a',
				"dbc9ab-2d2c44": "#2d2c44",
				'dbc9ab-38374f': "#38374f",
				'f5eee3-38374f': "#38374f",
				'fff-2d2c44': "#2d2c44",
				'c975d28-bf8249': '#bf8249',
				'effafe-38374f':'#38374f',
				'f7f7fe-38374f':'#38374f',
				'e4f3fb-2d2c43':'#2d2c43',
				'c2292d5-bf8249':'#bf8249',
				'c684ccc-bf8249':'#bf8249',
				'c007e6c-bf8249':'#bf8249',
				'f0e6d7-2d2c43': "#2d2c43",
				'f1f1f7-2d2c43': '#2d2c43',
				'dee5e2-2d2c43': '#2d2c43'
			}
		}, ele)
	}

	setSelectedTab(tab) {
		this.selectedTab = tab;
	}

	initObjectiveChartData(res) {
		if (res.point.length == 0) {
			return;
		}
		const textAll = { tc: '所有', sc: '所有', en: 'All' }[this.datas.lang];
		const textOther = { tc: '其他', sc: '其他', en: 'Other' }[this.datas.lang];
		let peoples = [];
		if (res.people){
			res.people.forEach(e=>{
				peoples = [...peoples, ...e.students];
			})
		}
		let data = res.data.filter((e,i) => {
			const index = res.data.findIndex(e2 => e.bcid == e2.bcid && e.uid == e2.uid);
			return index == i;
			}).map(e=> {
				e.source = e.uid == this.modData.uid?'student':'class';
				return e;
			});
		let objectiveChartData: any = {
			pointTypes: [{ label: textAll, value: 'all' }],
			pointType: 'all',
			components: res.component,
			points: res.point,
			data: data,
			studentName: this.studentName,
			studentUid: this.modData.uid,
			theme: this.themeSelected,
			peoples: peoples
		};
		objectiveChartData.points.forEach(pt => {
			pt.form_id = pt.form_id ? pt.form_id : 0;
			pt.form_name = pt.form_name ? pt.form_name : textOther;
			pt.title = '(' + pt.form_name + ') ' + pt.title;
			let found = objectiveChartData.pointTypes.find(e => e.value == pt.form_id);
			if (found == null) {
				objectiveChartData.pointTypes.push({ label: pt.form_name, value: pt.form_id });
			}
		});

		if (objectiveChartData.pointTypes.length > 2) {
			objectiveChartData.pointType = 'all';
		} else {
			objectiveChartData.pointType = objectiveChartData.pointTypes[1].value;
		}

		this.objectiveChartData = objectiveChartData;
	}

	initModalTitle(bookType){
		if (bookType == 'pdf-paper'){
			this.modalTitle = this.translate.instant('report-personal.book-result-pdf-paper', {name: this.studentName});
		} else {
			//'report-personal.book-result'|translate: {name: studentName} 
			this.modalTitle = this.translate.instant('report-personal.book-result', {name: this.studentName});
		}
	}

	stringToObject(str:string):any{
		var bytes:ByteArray = this.uint8ArrayToBytes(Base64.toUint8Array(str));
		bytes.uncompress();
		bytes.position = 0;
		bytes.objectEncoding = 3;
		return bytes.readObject();
	}

	uint8ArrayToBytes(u8s:Uint8Array):ByteArray{
		var bytes:ByteArray = new ByteArray();
		u8s.forEach(b => bytes.writeByte(b));
		bytes.position = 0;
		return bytes;
	}

	refresh(){
		const bsid = this.data.bsid;
		const bid = this.data.book_id;
		this.bothApiLoaded = false;
		this.datas.post('ROLearnProgress.getReportPersonalModalInfo', [this.data.uid, bsid, bid, this.selectedStatus]).subscribe((res: any) => {
			this.modData.result = res.result ? res.result : [];
			this.bothApiLoaded = true;
			this.filterChange.emit({selectedStatus: this.selectedStatus});
		});
	}


}
