import { PromiseUtils } from "./PromiseUtils";

var loadedJS = {};
var loadedCSS = {};

export class AssetLoader
{
	/**
	 * 
	 * array:any []
	  	[
			{
				type:"css",
				url:""
			},
			{
				type:"js",
				url:""
			} 
		]	
	 * @returns 
	 */
	public static load(array:any []):Promise<any>
	{
		return Promise.all(
			array.map((element)=>{
				if(element.type == "css")
				{
					return CSSLoader.loadCSS(element.url);
				} else if(element.type == "js")
				{
					return ScriptLoader.loadJS(element.url, element.name);
				} else {
					return element;
				}
			})
		);
	}
}

export class CSSLoader
{
	public static loadCSS(url:string):Promise<any>
	{
		var key:string = url;
        if(loadedCSS.hasOwnProperty(key))
        {
            return loadedCSS[key];
        }
        
        var p = new Promise((resolve, reject) => {
            var element = document.createElement("link")
			
            element.onload = () => {
                resolve(null);
            };

            element.onerror = (err:any) => {
                console.warn("load script failed", url, err);
                delete loadedCSS[key];
                element.remove();
                reject(err);
            };
			element.setAttribute("rel", "stylesheet")
			element.setAttribute("type", "text/css")
			element.setAttribute("href", url);
            window.document.body.appendChild(element);
        });
        loadedCSS[key] = p;
        return p;
	}
}
export class ScriptLoader {

	public static loadArray(array:any[]): Promise<any>
    {
        var response:any [] = new Array(array.length);
        return PromiseUtils.reduce(
            array, 
            (prev:any, info:any, index:number, reference:any [])=>{
                return ScriptLoader.load(info.url, info.name).then((o)=>{
                    response[index] = o;
                });
            }, 
            null
        ).then((o:any)=>{
            return response;
        })
        
    }

    


	public static load(url: string, name:string = ""): Promise<any>
	{
		return ScriptLoader.loadJS(url, name);
	}
	
    public static loadJS(url: string, name:string = ""): Promise<any> {
        var key:string = url+"|"+name;
        if(loadedJS.hasOwnProperty(key))
        {
            return loadedJS[key];
        }
        
        var p = new Promise((resolve, reject) => {
            const element = window.document.createElement('script');
			element.type = 'text/javascript';
            element.onload = () => {
                var win:any = window;
                if(name)
                {
                    if(win.hasOwnProperty(name))
                    {
                        resolve(win[name]);
                    } else {
                        reject(`${name} not found`);
                    }
                } else {
                    resolve(null);
                }
            };

            element.onerror = (err:any) => {
                console.warn("load script failed", url, err);
                delete loadedJS[key];
                element.remove();
                reject(`failed to load script(${url})`);
            };
            element.src = url;
            window.document.body.appendChild(element);
        });
        loadedJS[key] = p;
        return p;
    }
}