import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserMenuComponent } from './user-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { ResetPassword2Modal } from './reset-password2.modal';
import { FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NetworkCheckModule } from '../networkCheckModule/network-check.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ThemeService } from 'src/app/service/theme.service';
import { UserIconEditorModule } from '../userIconEditorModule/userIconEditor.module';
import { EditPhoneModal } from './edit-phone.modal';

@NgModule({
    imports: [
		CommonModule,TranslateModule,FormsModule,UiSwitchModule,NetworkCheckModule,FontAwesomeModule, UserIconEditorModule
    ],
    declarations: [
        UserMenuComponent, ResetPassword2Modal, EditPhoneModal
    ],
    exports: [
        UserMenuComponent, EditPhoneModal
    ],
    providers: [ ThemeService ],
    bootstrap: []
})
export class UserMenuModule { }
