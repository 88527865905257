import { Component, HostListener, Output, EventEmitter, Input, ElementRef, OnInit } from '@angular/core';
import { faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { StorageService } from 'src/app/service/storage.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faPalette } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: "subject-selector",
  template: `
  <a class="edit-target" [ngStyle]="options.editTargetStyle" (click)="editTargetClick($event)" *ngIf="!dropDownOnly">
    <ng-container *ngIf="modalData.subject">
      <div [ngClass]="['edit-target__text',(modalData.disabled?'disabled':'')]">
        <span>{{selectedSubjectTitle}}</span>
        <div class="edit-target__text__arrow {{isOn?'opened':''}}"></div>
      </div>
    </ng-container>
    <div [ngClass]="['edit-target__text',(modalData.disabled?'disabled':'')]" *ngIf="!modalData.subject">
      <span>{{'workspace.pls-select'|translate}}...</span>
      <div class="edit-target__text__arrow {{isOn?'opened':''}}"></div>
    </div>
  </a>
  <div class="dropdown" [ngStyle]="options.dropdownStyle" *ngIf="isOn">
    <div class="triangle" [ngStyle]="options.dropdownTriangleStyle"></div>
    <div class="left">
      <div class="searchBox">
          <input placeholder="{{'workspace.please-enter-keywords' | translate}}" [(ngModel)]="searchText">
          <div [ngClass]="['icon',searchText==''?'zoom':'close']" (click)="searchText=''"></div>
      </div>
      <div *ngIf="!searchText" class="tagRowContainer">
        <perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">

          <ng-container *ngIf="!options.publicOnly">
            <div class="option-row group-row">
              <fa-icon class="open" [icon]="(favOpen ? faChevronDown : faChevronRight)" (click)="favOpen = !favOpen"></fa-icon>
              <span class="fav-sj" (click)="favOpen = !favOpen">{{'workspace.my-favourites'|translate}}</span>
            </div>
            <ng-container *ngIf="favOpen">
              <div class="option-row tag-row" *ngFor="let sj of favSubjects">
			    <div class="check" (click)="subjectClick(sj)">
			      <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			    </div>
                <span class="fav-sj" (click)="subjectClick(sj)">{{sj.title}} ({{('workspace.subject-type-' + sj.type)|translate}}, {{('workspace.subject-level-' + sj.level) |translate}})</span>
                <div *ngIf="!options.publicOnly" class="option-row__heart bookmarked" (click)="subjectHeartClick(sj)"></div>
              </div>
            </ng-container>
            <div class="line"></div>
          </ng-container>

          <div class="option-row group-row">
            <fa-icon class="open" [icon]="(p1Open? faChevronDown : faChevronRight)" (click)="p1Open = !p1Open"></fa-icon>
            <span (click)="p1Open = !p1Open">{{'workspace.subject-type-p1'|translate}}</span>
          </div>
          <ng-container *ngIf="p1Open">
            <div *ngFor="let sj of p1" class="option-row tag-row" [attr.sjid]="sj.id">
			  <div class="check" (click)="subjectClick(sj)">
			    <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			  </div>
              <span (click)="subjectClick(sj)">{{sj.title}}</span>
              <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="!options.publicOnly">
            <div class="option-row group-row">
              <fa-icon class="open" [icon]="(s1Open? faChevronDown : faChevronRight)" (click)="s1Open = !s1Open"></fa-icon>
              <span (click)="s1Open = !s1Open">{{'workspace.subject-type-s1'|translate}}</span>
            </div>
            <ng-container *ngIf="s1Open">
              <div *ngFor="let sj of s1" class="option-row tag-row" [attr.sjid]="sj.id">
				<div class="check" (click)="subjectClick(sj)">
				  <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			    </div>
                <span (click)="subjectClick(sj)">{{sj.title}}</span>
                <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
              </div>
            </ng-container>
          </ng-container>

          <div class="option-row group-row">
            <fa-icon class="open" [icon]="(p2Open? faChevronDown : faChevronRight)" (click)="p2Open = !p2Open"></fa-icon>
            <span (click)="p2Open = !p2Open">{{'workspace.subject-type-p2'|translate}}</span>
          </div>
          <ng-container *ngIf="p2Open">
            <div *ngFor="let sj of p2" class="option-row tag-row" [attr.sjid]="sj.id">
			  <div class="check" (click)="subjectClick(sj)">
			    <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			  </div>
              <span (click)="subjectClick(sj)">{{sj.title}}</span>
              <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="!options.publicOnly">
            <div class="option-row group-row">
              <fa-icon class="open" [icon]="(s2Open? faChevronDown : faChevronRight)" (click)="s2Open = !s2Open"></fa-icon>
              <span (click)="s2Open = !s1Open">{{'workspace.subject-type-s2'|translate}}</span>
            </div>
            <ng-container *ngIf="s2Open">
              <div *ngFor="let sj of s2" class="option-row tag-row" [attr.sjid]="sj.id">
				<div class="check" (click)="subjectClick(sj)">
				  <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			    </div>
                <span (click)="subjectClick(sj)">{{sj.title}}</span>
                <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
              </div>
            </ng-container>
          </ng-container>

          <div class="option-row group-row">
            <fa-icon class="open" [icon]="(p3Open? faChevronDown : faChevronRight)" (click)="p3Open = !p3Open"></fa-icon>
            <span (click)="p3Open = !p3Open">{{'workspace.subject-type-p3'|translate}}</span>
          </div>
          <ng-container *ngIf="p3Open">
            <div *ngFor="let sj of p3" class="option-row tag-row" [attr.sjid]="sj.id">
			  <div class="check" (click)="subjectClick(sj)">
			    <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
		      </div>
              <span (click)="subjectClick(sj)">{{sj.title}}</span>
              <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="!options.publicOnly">
            <div class="option-row group-row">
              <fa-icon class="open" [icon]="(s3Open? faChevronDown : faChevronRight)" (click)="s3Open = !s3Open"></fa-icon>
              <span (click)="s3Open = !s3Open">{{'workspace.subject-type-s3'|translate}}</span>
            </div>
            <ng-container *ngIf="s3Open">
              <div *ngFor="let sj of s3" class="option-row tag-row" [attr.sjid]="sj.id">
				<div class="check" (click)="subjectClick(sj)">
				  <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			    </div>
                <span (click)="subjectClick(sj)">{{sj.title}}</span>
                <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
              </div>
            </ng-container>
          </ng-container>

          <div class="option-row group-row">
            <fa-icon class="open" [icon]="(p0Open? faChevronDown : faChevronRight)" (click)="p0Open = !p0Open"></fa-icon>
            <span (click)="p0Open = !p0Open">{{'workspace.subject-type-p0'|translate}}</span>
          </div>
          <ng-container *ngIf="p0Open">
            <div *ngFor="let sj of p0" class="option-row tag-row" [attr.sjid]="sj.id">
			  <div class="check" (click)="subjectClick(sj)">
			    <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			  </div>
              <span (click)="subjectClick(sj)">{{sj.title}}</span>
              <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="!options.publicOnly">
            <div class="option-row group-row">
              <fa-icon class="open" [icon]="(s0Open? faChevronDown : faChevronRight)" (click)="s0Open = !s0Open"></fa-icon>
              <span (click)="s0Open = !s0Open">{{'workspace.subject-type-s0'|translate}}</span>
            </div>
            <ng-container *ngIf="s0Open">
              <div *ngFor="let sj of s0" class="option-row tag-row" [attr.sjid]="sj.id">
				<div class="check" (click)="subjectClick(sj)">
			      <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
		        </div>
                <span (click)="subjectClick(sj)">{{sj.title}}</span>
                <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
              </div>
            </ng-container>
          </ng-container>

        </perfect-scrollbar>
      </div>

    <div *ngIf="searchText" class="tagRowContainer">
      <perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
        <ng-container *ngFor="let sj of storage.subjects">
          <div class="option-row tag-row" *ngIf="sj.title.indexOf(searchText)>-1 && (!options.publicOnly || sj.type == 'public')" [attr.sjid]="sj.id">
			  <div class="check" (click)="subjectClick(sj)">
			    <fa-icon [class.checked]="compModalData.subject == sj.id" [icon]="(compModalData.subject == sj.id?faCheckCircle:faCheckCircleLight)"></fa-icon>
			  </div>
              <span (click)="subjectClick(sj)">{{sj.title}} ({{('workspace.subject-type-' + sj.type)|translate}}, {{('workspace.subject-level-' + sj.level) |translate}})</span>
              <div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
          </div>
        </ng-container>
      </perfect-scrollbar>
    </div>
  </div>
  <div class="right">
    <div class="label">
      <span>{{'workspace.selected' | translate}} :</span>
    </div>
    <div class="selected-list">
      <div class="selected-list__selected-item" *ngIf="compModalData.subject">
        <fa-icon class="icon" [icon]="faPalette"></fa-icon>
        <div class="text">
          <span>{{compSelectedSubjectTitle}}</span>
        </div>
        <fa-icon class="trash" [icon]="faTrash" (click)="compModalData.subject = null"></fa-icon>
      </div>
      <div class="selected-list__notice">
        <div class="selected-list__notice__image"></div>
        <div class="selected-list__notice__text">{{'workspace.single-subject-only'|translate}}</div>
      </div>
    </div>
	<div class="button-panel">
	  <div class="confirm" (click)="confirmClick()">{{'workspace.confirm'|translate}}</div>
	  <div class="cancel" (click)="layerClick($event)">{{'workspace.cancel'|translate}}</div>
	</div>
  </div>
</div>
<div class="layer" id="layer" *ngIf="isOn" (click)="layerClick($event)">

</div>
    `,
  styleUrls: ['./subject-selector.component.scss']
})


export class SubjectSelectorComponent implements OnInit {
  @Input() public modalData;
  @Input() public field;
  @Input() public options:any = {};
  @Input() public dropDownOnly:boolean = false;
  @Output() public change:EventEmitter<any> = new EventEmitter<any>();
  public compModalData;
  public faSearch = faSearch;
  public faCheck = faCheck;
  public faCircle = faCircle;
  public faTrash = faTrash;
  public faChevronRight = faChevronRight;
  public faChevronDown = faChevronDown;
  public faTimes = faTimes;
  public faCheckCircle = faCheckCircle;
  public faCheckCircleLight = faCheckCircleLight;
  public faPalette = faPalette;
  public isTagLoaded: boolean = false;

  public searchText: string = "";
  public favSubjects: any[] = [];
  public p0: any = [];
  public p1: any = [];
  public p2: any = [];
  public p3: any = [];
  public s0: any = [];
  public s1: any = [];
  public s2: any = [];
  public s3: any = [];
  public isForAll: boolean = false;
  public isOn = false;
  public year: any = null;
  public favOpen = true;
  public p0Open = true;
  public p1Open = true;
  public p2Open = true;
  public p3Open = true;
  public s0Open = true;
  public s1Open = true;
  public s2Open = true;
  public s3Open = true;
  public selectedItems:any = [];
  constructor(public storage: StorageService, 
	private translate: TranslateService, public datas: DataService, private load: LoadingService) {
  }

  ngOnInit() {
    this.compModalData = JSON.parse(JSON.stringify(this.modalData));
    this.storage.getSubjects().then((success:any)=>{
        this.initSubjects();
    });
  }

  editTargetClick($event) {
    $event.stopPropagation();
    this.compModalData = JSON.parse(JSON.stringify(this.modalData));
	console.log(this.compModalData);
	
    if (!this.modalData.disabled){
      this.setOnOff();
    }
  }

  get selectedSubjectTitle(){
	let modalData = this.modalData;
	if (!modalData.subject || this.storage.subjects == null){
		return '';
	} else {
		let subject = '' + modalData.subject;
		let arr = subject.split('-');
		if (arr.length == 2){
			const found = this.storage.subjects.find(sj=>sj.id == subject);
			return found?found.title:'';
		} else {
			const found = this.storage.subjects.find(sj=>sj.public_subject_id == subject);
			return found?found.title:'';
		}
	}
  }

  get compSelectedSubjectTitle(){
	let modalData = this.compModalData;
	if (!modalData.subject || this.storage.subjects == null){
		return '';
	} else {
		let subject = '' + modalData.subject;
		let arr = subject.split('-');
		if (arr.length == 2){
			const found = this.storage.subjects.find(sj=>sj.id == subject);
			return found?found.title:'';
		} else {
			const found = this.storage.subjects.find(sj=>sj.public_subject_id == subject);
			return found?found.title:'';
		}
	}
  }

  layerClick($event) {
    $event.stopPropagation();
    this.setOnOff();
  }

  confirmClick(){
	// this.modalData = JSON.parse(JSON.stringify(this.compModalData));
    this.modalData.subject = this.compModalData.subject;
	this.setOnOff();
	this.change.emit(this.modalData.subject);
  }

  setOnOff(){
    this.isOn = !this.isOn;
    if (this.isOn){
      this.modalData.subjectSelecting = 1;
    } else {
      this.modalData.subjectSelecting = 0;
    }
  }

  initSubjects() {
    const storage = this.storage;
    storage.subjects.forEach(sj=>{
      let varname = sj.type =='public'?'p':'s';
      const levelMap = [null,'P1-6','S1-3','S4-6'];
      const index = levelMap.indexOf(sj.level);
      varname = varname + (index>-1?index:0);
      this[varname].push(sj);
    });

	let promise = new Promise((resolve, reject)=>{
		let s = setInterval(()=>{
			if (storage.account){
				clearInterval(s);
				resolve(true);
			}
		},100);
	}).then(()=>{
		if (storage.account.personalSettings){
		  if (storage.account.personalSettings.workspace_fav_subjects){
			storage.account.personalSettings.workspace_fav_subjects.forEach(e=>{
			  const found = storage.subjects.find(sj=>sj.id == e);
			  if (found){
				this.favSubjects.push(found);
			  }
			});
		  }
		}
	});
	
  }

  subjectClick(sj){
    this.compModalData.subject = sj.id;
  }

  subjectHeartClick(sj){
    const index = this.favSubjects.indexOf(sj);
    this.load.add('fav');
    this.datas.post('ROWorkspace.setFavSubject',[sj.id]).subscribe((res:any)=>{
      console.log(res);
      if (index == -1){
        this.favSubjects.push(sj);
      } else {
        this.favSubjects.splice(index, 1);
      }
      let ps = this.storage.account.personalSettings;
      ps.workspace_fav_subjects = res.personalSettings.workspace_fav_subjects;
      this.load.remove('fav');
    });
  }

}
