import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ModalComponent } from './modal.component';
import { DynamicCompDirective } from './dynamicComp.directive';
import { MiniModal } from './mini.modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { MinuteSecondPipe } from './minute-second.pipe';
import { PieChartModule } from 'src/app/sharedModule/pieChartModule/pieChart.module';

export function HttpLoaderFactory(http: HttpClient) {
  let ts = +new Date();
  return new TranslateHttpLoader(http, "assets/", ".json?" + ts);
}

@NgModule({
  declarations: [
    ModalComponent,
    DynamicCompDirective,
    MiniModal,
    MinuteSecondPipe
  ],
  imports: [DeviceDetectorModule, CommonModule, PieChartModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    CommonModule, HttpClientModule, TranslateModule, DeviceDetectorModule, FormsModule, PerfectScrollbarModule, 
	NgSelectModule, PieChartModule,
    ModalComponent,
    DynamicCompDirective,
    MiniModal,
    MinuteSecondPipe
  ],
  entryComponents: [
    MiniModal
  ],
})

export class SharedModule {

}
