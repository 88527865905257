import { Component, HostListener, Output, EventEmitter, Input, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faUserFriends, faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { TranslateService } from '@ngx-translate/core';
import { _ } from 'ag-grid-community';
import { DataService } from 'src/app/service/data.service';
import { TagGroupService } from './tagGroup.service';
import { MemberService } from '../memberInfoModule/member.service';
import { LoadingService } from '../loadingModule/loading.service';

@Component({
	selector: "student-selector",
	templateUrl: './studentSelector.component.html',
	styleUrls: ['./studentSelector.component.scss']
})


export class StudentSelectorComponent implements OnInit {
	@Input() public modalData;
	@Input() public field;
	@Input() public selectedItems: any = [];
	@Output() public selectedItemsChange = new EventEmitter<any>();
	@Output() public open = new EventEmitter<any>();
	@Output() public close = new EventEmitter<any>();
	@Input() public options: any = {};
	public modalSelectedItems: any;
	public faSearch = faSearch;
	public faCheck = faCheck;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public faTrash = faTrash;
	public faChevronRight = faChevronRight;
	public faChevronDown = faChevronDown;
	public faTimes = faTimes;
	public faUserFriends = faUserFriends;
	public selectedYear;
	public isTagLoaded: boolean = false;

	public searchText: string = "";
	public myTags: any[] = [];
	public otherTags: any[] = [];
	public groups: any[] = [];
	public students: any[] = [];
	public isForAll: boolean = false;
	public isOn = false;
	public year: any = null;
	public myTagOpen = false;
	public otherTagOpen = false;
	public ready = false;
	constructor(public tg: TagGroupService, private translate: TranslateService, public datas: DataService, private member: MemberService, private load: LoadingService, private cd: ChangeDetectorRef) {
	}

	ngOnInit() {
		Promise.all([this.tg.getTags(), this.member.getMembers()]).then(() => {
			this.students = this.member.members.filter(e => e.user_role == 2).map(e => {
				let obj = { title: e[this.datas.lang == 'en' ? 'ename' : 'cname'], uid: e.uid, photo: e.photo, gender: e.gender };
				return obj;
			});
			if (this.selectedItems.length == 0) {
				this.selectedYear = this.tg.getDafaultYear();
			} else {
				let found = this.tg.tags.find(t => t.id == this.selectedItems[0]);
				if (found) {
					this.selectedYear = this.tg.schoolYears.find(y => y.id == found.school_year);
				} else {
					this.selectedYear = this.tg.schoolYears[0];
				}
			}
			this.modalSelectedItems = this.selectedItems.map(e => {
				return this.students.find(t => t.uid == e);
			});
			this.initTags();
			this.ready = true;
		});
	}

	editTargetClick($event) {
		$event.stopPropagation();
		if (!this.options.disabled) {
			this.setOnOff();
		}
	}

	layerClick($event) {
		$event.stopPropagation();
		this.setOnOff();
	}

	setOnOff() {
		this.isOn = !this.isOn;
		if (this.isOn) {
			this.open.emit();
		} else {

		}
	}

	initTags() {
		this.myTags = this.selectedYear.tags.filter(t => t.my_tag == 1 && t.type == 3).map(e => {
			let tag: any = { id: e.id, title: e.title, type: 3, school_year: e.school_year, open: false };
			return tag;
		});
		this.groups = this.selectedYear.tagClass.map(e => {
			let group: any = { id: e.id, title: e.title, type: 9, school_year: e.school_year, open: false };
			group.tags = e.tags.map(t => {
				let tag: any = { id: t.id, title: t.title, type: 3, school_year: t.school_year };
				return tag;
			})
			return group;
		});
		this.otherTags = this.selectedYear.tags.filter(t => t.type == 3 && !this.groups.find(g => g.id == t.pid)).map(e => {
			let tag: any = { id: e.id, title: e.title, type: 3, school_year: e.school_year, open: false };
			return tag;
		});
	}

	checkClick(item = null) {
		if (this.isDisabled(item)){
			return;
		}
		for (let idx in this.modalSelectedItems) {
			if (this.modalSelectedItems[idx].uid == item.uid) {
				this.modalSelectedItems.splice(idx, 1);
				return;
			}
		}
		this.modalSelectedItems.push(item);
	}

	myGroupClick() {
		this.myTags.forEach(t => {
			this.checkClick(t);
		});
	}

	tagOpen(tag) {
		if (tag.open) {
			tag.open = false;
		} else if (tag.students) {
			tag.open = true;
		} else {
			this.load.add('get_tag_people');
			this.datas.post2({ data: { api: 'ROWorkspace.get_tag_people', json: [tag.id, 'all'] } }).then(res => {
				tag.students = res.uids.map(e => {
					let found = this.students.find(s => e.uid == s.uid);
					return found ? found : null;
				}).filter(e => e);
				tag.open = true;
				this.load.remove('get_tag_people');
			});
		}
	}

	clear(tag = null) {
		if (tag) {
			this.modalSelectedItems = this.modalSelectedItems.filter(t => t.id != tag.id);
		} else {
			this.modalSelectedItems = [];
		}
	}

	confirmClick() {
		this.selectedItems = this.modalSelectedItems.map(e => e.uid);
		this.selectedItemsChange.emit(this.selectedItems);
		this.close.emit(this.selectedItems);
		this.isOn = false;
	}

	cancelClick() {
		this.close.emit(this.selectedItems);
		this.isOn = false;
	}

	isSelected(student) {
		let result = this.modalSelectedItems.find(e => e.uid == student.uid);
		return !!result;
	}

	isDisabled(student){
		if (this.options.disabledItems){
			let result = this.options.disabledItems.find(e=> e == student.uid);
			return !!result;
		}
		return false;
	}

	get searchedItems() {
		const text = this.searchText;
		let items = this.myTags.filter(t => t.title.indexOf(text) > -1);
		this.groups.forEach(t => {
			if (t.title.indexOf(text) > -1) {
				items.push(t);
			}
		});
		this.otherTags.forEach(t => {
			if (t.title.indexOf(text) > -1 && !items.find(e => e.id == t.id)) {
				items.push(t);
			}
		});
		return items;
	}

	public tagYearChange($event) {
		this.selectedYear = this.tg.schoolYears.find(e => e.id == $event.id);
		this.initTags();
	}

	selectAllChild(tag) {
		if (tag.students) {
			tag.students.forEach(student => {
				if (this.isDisabled(student) == false && this.isSelected(student) == false){
					this.checkClick(student);
				}
			});
		} else {
			this.load.add('get_tag_people');
			this.datas.post2({ data: { api: 'ROWorkspace.get_tag_people', json: [tag.id, 'all'] } }).then(res => {
				tag.students = res.uids.map(e => {
					let found = this.students.find(s => e.uid == s.uid);
					return found ? found : null;
				}).filter(e => e);
				this.load.remove('get_tag_people');
				this.selectAllChild(tag);
			});
		}
	}

	get selectedItemsName() {
		let arr = this.modalSelectedItems.map(e => e.title).filter(e => e);
		return arr.length > 0 ? arr.join(', ') : this.translate.instant('course.pls-select...');
	}

}
