import { Injectable } from '@angular/core';

@Injectable()
export class CommonOldService {
  public main;
  public x = 0;
  public y = 0;
  public loading = [];

  public addLoading(task:any, timeout = 0) {
    setTimeout(() => {
      this.loading.push(task);
      if (timeout) {
        setTimeout(() => { this.removeLoading(task); }, timeout);
      }
    }, 10);
  }

  public removeLoading(task) {
    setTimeout(() => {
      this.loading = this.loading.filter(i => i != task);
    }, 10);
  }

  public color(num) {
    let colors = ['#CAA1FF', '#50D48F', '#D07F40', '#5183C9', '#FF70AE', '#FFFF31'];
    return colors[num % 6];
  }

}
