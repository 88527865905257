import { CoordinateExpression } from "./CoordinateExpression";
import { ROSectionHelper } from "./ROSectionHelper";
import { XMLNode } from "./xml/XMLNode";

export class QuestionOrderHelper
{
	// private var _ce:CoordinateExpression;
	constructor()
	{

	}
	
	
	public static getQuestionCount(docXML:XMLNode):number
	{
		var sum:number = 0;
		docXML.children.forEach((chapterXMLNode:XMLNode)=>{
			chapterXMLNode.children.forEach((page:XMLNode)=>{
				var pageQuestionCount:number = page.getAttribute("questionCount") ;
				sum += pageQuestionCount;
			})
		});
		return sum;
	}
	
	public static getComponentQuestionOffset(cXML:XMLNode):any
	{
		var name:string = cXML.tag;
		if (name == "LongQuestion3")
		{
			return {
				x:60,
				y:0
			};
		} else if (name == "MC2")
		{
			return {
				x:60,
				y:0
			};
		} else if (name == "GraphicMC")
		{
			return {
				x:60,
				y:0
			};
		} else {
			return {
				x:0,
				y:0
			};
		}
	}
	public static update(pageXML:XMLNode, xmlArray:XMLNode[]):void
	{
		var _ce:CoordinateExpression = new CoordinateExpression(pageXML, null);
		var list:any [] = [];
		var questionCount:number = 0;
		var pageSectionInfo = {
			"X":0
		};
		
		xmlArray.forEach((com:XMLNode, index:number)=>
		{
			var q:any;
			var py:number;
			var px:number;
			var show:boolean;
			try
			{

				q = JSON.parse(com.getAttribute("q"));
				show = q.show;
			} catch (err)
			{
				q = {level:0};
				show = false;
			}
			if (com.hasAttribute("coordinateExpression"))
			{
				var ceString:string = com.getAttribute("coordinateExpression");
				_ce.parseExpression(ceString);
				px = _ce.getX();
				py = _ce.getY();
			} else {
				px = com.getAttribute("x");
				py = com.getAttribute("y");
			}
			var offset:any = QuestionOrderHelper.getComponentQuestionOffset(com);
			if (offset)
			{
				px += offset.x;
				py += offset.y;
			}
			
			
			var title:string = com.getAttribute("questionLabelTitle");
			var section:string = com.getAttribute("questionSection");
			if(!section) section = "X";
			var obj:any = {
				section:section ,
				id:(index+1),
				title:title,
				hasTitle:title ? 1 : 0,
				xml:com,
				x:px,
				y:py,
				info:q,
				show:show,
				level:q.level ? q.level : 0,
				position:px + py * 10
			};
			list.push(obj);
			if (obj.level == 0 && obj.hasTitle == 0 && show)
			{
				if(!pageSectionInfo.hasOwnProperty(section)) {
					pageSectionInfo[section] = 0;
					// counterMap.keys.push(section);
				}
				pageSectionInfo[section]++;
				questionCount++;
			}
			
		});
		var originalList:any [] = list;
		list.forEach((element:any)=>
		{
			element.position = element.x + element.y * 10;
			element.children = [];
		});
		
		list.sort(
			(a,b)=>{
				if(a.position > b.position) return 1;
				else if(a.position < b.position) return -1;
				return 0;
			}
		);
			
		// var level:number = 1;
		// var levelArray:any [] = list.map((element)=>{return element.level});
		// Underscore.pluck(list, "level");
		list = this.sortLevel(list);
		QuestionOrderHelper.updateIndex(originalList, list);

		

		/*
		var a:any [] = [];
		pageSectionInfo.keys.forEach((key:string)=>{
			var count:number = pageSectionInfo[key];
			if(count) a.push(key == "X" ? pageSectionInfo[key] : key + pageSectionInfo[key]);
		});
		
		if(a.length)
		{
			var aString:string = a.join("");
			pageXML.setAttribute("questionSection", aString);
		} else {
			pageXML.removeAttribute("questionSection");
		}
		
		*/
		// var parser:ROSectionHelper = new ROSectionHelper();
		var questionSection:string = ROSectionHelper.stringify(pageSectionInfo);
		if(questionSection)
			pageXML.setAttribute("questionSection", questionSection);
		else 
			pageXML.removeAttribute("questionSection");
		pageXML.setAttribute("questionCount", questionCount);
	}
	
	
	/*
	private function drawList(list:Array):void
	{
		var len:int = list.length;
		this.graphics.lineStyle(1);
		for (var i:int = 0; i < len; i++)
		{
			var o:Object = list[i];
			this.graphics.drawCircle(o.x, o.y, 5);
			var tf:TextField = new TextField();
			tf.text = o.index;
			tf.x = o.x;
			tf.y = o.y;
			addChild(tf);
		}
	}
	*/
	
	private static updateIndex(originalList:any [], list:any[]):void 
	{
		QuestionOrderHelper.updateChildrenIndex(list, null);
		originalList.forEach((o:any)=>{
			if (o.level > 0)
			{
				QuestionOrderHelper.updateRoot(o);
				QuestionOrderHelper.updateChildrenIndex(o.children, o.root);
			} else {
				o.root = o;
				QuestionOrderHelper.updateChildrenIndex(o.children, o);
			}
		})
	}
	
	private static updateRoot(o:any):any
	{
		if (o.root)
		{
			return o.root;
		} else {
			if (o.parent)
			{
				o.root = QuestionOrderHelper.updateRoot(o.parent);
			}
		}
		return null;
	}
	
	private static getTitle(level:number, index:number):string
	{
		return "("+(index+1)+")";
		/*
		if(level == 1)
		{

			return TextStyleCreator.lowerAlphaString(index + 1);
		} else if (level == 2)
		{
			return TextStyleCreator.lowerRomanString(index + 1);
		} else if (level == 3)
		{
			return TextStyleCreator.cjkHeavenlyStemString(index + 1);
		}
		return "";
		*/
	}
	
	private static updateChildrenIndex(children:any[], root:any):void
	{
		var len:number = children.length;
		var counter:number = 0;
		var sectionInfo:any = {
			X:0
		};
		//var colorIndex = 0;
		for (var i:number = 0 ; i < len; i++)
		{
			var c:any = children[i];
			// c.xml.@questionIndex = counter;
			if (c.level)
			{
				c.xml.setAttribute("questionIndex", counter);
				var pid:number = c.parent ? c.parent.id : 0;
				if (root)
				{
					var nameArray:any[] = [];
					//c.name = counter;
					if (c.level > 1)
					{
						nameArray.push(c.parent.name);
					}
					if (c.hasTitle)
					{
						nameArray.push(c.title);
					} else {
						nameArray.push(QuestionOrderHelper.getTitle(c.level, counter));
					}
					c.name = nameArray.join(" ");
					c.qInfo = {index:counter, level:c.level, root:root.order, id:c.id, pid:pid, order:i, name:c.name, rootIndex:root.rootIndex};
				} else {
					c.qInfo = {index:counter, level:c.level, root:0, error:1, id:c.id, pid:pid, order:i, rootIndex:0};
				}
			} else {
				if(sectionInfo.hasOwnProperty(c.section) == false) sectionInfo[c.section] = 0;
				c.xml.setAttribute("questionIndex", sectionInfo[c.section]);
				if (c.show)
				{
					c.rootIndex = counter;
				} else {
					c.rootIndex = -1;
				}
				c.qInfo = {index:counter, level:c.level, root:i, id:c.id, pid:0, order:i, rootIndex:counter};
			}
			c.xml.setAttribute("qInfo", JSON.stringify(c.qInfo));
			// c.xml.@qInfo = JSON.stringify(c.qInfo);
			c.index = counter;
			c.order = i;
			if (c.show && !c.hasTitle)
			{
				counter++;
				sectionInfo[c.section]++;
			}
			
		}
	}
	
	private static getDistance(o1:any, o2:any):number
	{
		var dx:number = o1.x - o2.x;
		var dy:number = o1.y - o2.y;
		return dx * dx + dy * dy;
	}
	/**
	 * filter root items
	 * @param list 
	 * @returns 
	 */
	private static sortLevel(list:any []):any[] 
	{
		var output:any[] = [];
		list.forEach((o:any, i:number)=>{
			if (o.level > 0)
			{
				var parentLevel:number = o.level - 1;
				if (QuestionOrderHelper.findParent(o, 0, i, list, parentLevel))
				{
					
				} else {
					output.push(o);
				}
			} else {
				output.push(o);
			}
		});
		return output;
	}
	
	private static findParent(o:any, min:number, max:number, list:any [], targetLevel:number):boolean
	{
		var state:any = null;
		for (var i:number = max - 1; i >= min ; i--)
		{
			var item:any = list[i];
			if (item.level == targetLevel)
			{
				if (o.x >= item.x )
				{
					var distance:number = QuestionOrderHelper.getDistance(o, item);
					if (state == null)
					{
						state = {
							distance:distance,
							parent:item
						};
					} else if (state.distance > distance)
					{
						state.distance = distance;
						state.parent = item;
					}
				}
			}
		}
		if (state){
			state.parent.children.push(o);
			o.parent  = state.parent;
			return true;
		}
		return false;
	}
		
	
}
