import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
	selector: "status-renderer",
	template: `
	<div class="wrapper" 
		*ngIf="selectedItem"
		[ngStyle]="params.wrapperStyle" 
		[style.backgroundColor]="(selectedItem.bgColor?selectedItem.bgColor:null)" 
		[style.color]="selectedItem.color" [style.borderColor]="(selectedItem.borderColor?selectedItem.borderColor:'transparent')"
		[style.borderColor]="(selectedItem.borderColor?selectedItem.borderColor:'transparent')"
		(click)="clickSubject.next($event)">
			<div class="text" 
				[ngStyle]="(selectedItem.textStyle?selectedItem.textStyle:params.textStyle)"
				[style.color]="(selectedItem.color?selectedItem.color:'#fff')"
				[style.backgroundColor]="(selectedItem.bgColor?selectedItem.bgColor:null)">
				{{ selectedItem.titleKey?(selectedItem.titleKey |translate) : selectedItem.title }}
			</div>
	</div>
    `,
	styleUrls: ['./status.renderer.scss']
})


export class StatusRenderer implements AfterViewInit {
	public params: any;
	public text = '';
	public clickSubject = new Subject();
	public items:any[] = [];
	public selectedItem;
	ngAfterViewInit() {
		this.clickSubject.pipe(debounceTime(100)).subscribe(event=>{
			if (this.params.click){
				this.params.click(event);
			}
		});
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		this.items = this.params.items;
		let value = this.params.output || this.params.value;
		this.selectedItem = this.items.find(e=> e.value == value);
	}

	refresh(params: ICellRendererParams){
		this.params = params;
		let value = this.params.output || this.params.value;
		this.selectedItem = this.items.find(e=> e.value == value);
		return true;
	}


}
