import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { MiniModal } from '../modal/mini.modal';
@Injectable({ providedIn: 'root' })

export class ModalService {
  public modalList: any[] = [];

  constructor(public commonService: CommonService) { }

  create(obj: any) {
    this.modalList.push(obj);
    return obj;
  }

  close(index = -1) {
    if (index == -1)
      this.modalList.pop();
    else
      this.modalList.splice(index, 1);
  }

  alert(msg, callback = () => { }) {
    let obj = {
      comp: MiniModal, data: {
        message: msg, type: 'alert',
        close: () => {
          callback();
          this.close();
        }
      }, hideHeader: true
    };
    this.modalList.push(obj);
  }

  confirm(msg, callback, options:any = {}) {
    let obj:any = {
      comp: MiniModal,
      data: { message: msg, type: 'confirm', close: () => { this.close(); }, confirm: callback },
      hideHeader: true
    };
    if (options.confirmText){
      obj.data.confirmText = options.confirmText;
    }
    if (options.cancelText){
      obj.data.cancelText = options.cancelText;
    }
    if (options.hideIcon){
      obj.data.hideIcon = 1;
    }
    this.modalList.push(obj);
  }

  error(msg) {
    let obj = { comp: MiniModal, data: { message: msg, type: 'alert', close: () => { this.close() } }, hideHeader: true };
    this.modalList.push(obj);
  }

  flash() {
    let obj = { comp: MiniModal, data: { message: '', type: 'alert', modClass: 'modal-hidden',close: ()=>{
      this.close();}
    },
      hideHeader: true };
    this.modalList.push(obj);
    // setTimeout(()=>{this.modalList = [];},500);
  }

}
