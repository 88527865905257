import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RadioBoxComponent } from './radioBox.component';


@NgModule({
    imports: [
      CommonModule,
      FontAwesomeModule,
      PerfectScrollbarModule,
      TranslateModule
    ],
    declarations: [
        RadioBoxComponent
    ],
    exports: [
        RadioBoxComponent
    ],
})
export class RadioBoxModule { }
