import { Component, ElementRef, EventEmitter, HostListener, Injectable, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
// import { AlertComponent, PromptDialogComponent, SelectionComponent, SelectionComponent2 } from '../sharedModule/alertModule/alert.component';
// import { DynamicComponentService } from './dynamicComponent.service';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { WhitePopupService } from '../../whitePopupModule/whitePopup.service';
import { BubbleBox2Component } from '../../bubbleBox2Module/bubbleBox2.component';
import { PinYinObject } from '../PinYin';
import { PinYinUtils } from '../PinYinUtils';
// import { Alert2Component } from '../sharedModule/alert2Module/alert2.component';
@Component({
	selector:"pinyin-keyboard-layout",
    template: `
		<div class="keyboard"
			[class.active]="active"
			>
			<div class="key-container pinyin-font">
				<div class="flex">
					<div class="left" *ngIf="keyboardOptions.shengmu">
						<div class="shengmu">
							<div class="keyboard-line" *ngFor="let shengmuLine of _arrShengmu">
								<div class="button" *ngFor="let shengmu of shengmuLine"  (click)="assignPinYin($event, 'shengmu', shengmu, shengmu)">
									{{shengmu}}
								</div>
							</div>
						</div>
					</div>
					<div class="right" *ngIf="keyboardOptions.toneNumber">
						<div class="tone">
							<div class="keyboard-line"  >
								<div 
									class="button padding-10" 
									(click)="assignPinYin($event, 'toneNumber', 1, 'ˉ')"
									>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
									  <svg viewBox="-1.5 -1.5 27 3">
									    <path fill="none" stroke="#6b4b2d" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M0 0h24" paint-order="fill stroke markers"/>
									  </svg>
									</svg>

								</div>

								<div 
									class="button padding-10" 
									
									(click)="assignPinYin($event, 'toneNumber', 2, 'ˉ')"
									>
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 88 88">
										<svg viewBox="0 0 27.45 20.5">
											<path fill="#6b4b2d" stroke="none" paint-order="stroke fill markers" d=" M 26.45 0 L 27.15 0.6 L 27.45 1.25 L 3.05 20.2 L 1.7 20.5 Q 0.85 20.35 0.4 19.65 Q -0.1 19 0.05 18.2 Q 0.15 17.35 0.85 16.9 L 26.45 0"/>
										</svg>
									</svg>
								</div>
								<div 
									class="button padding-10" 
									
									(click)="assignPinYin($event, 'toneNumber', 3, 'ˉ')"
									>
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 88 88">
										<svg viewBox="-1.5 -1.5 29.05 20.45">
											<path fill="none" stroke="#6b4b2d" paint-order="fill stroke markers" d=" M 8.55 17.45 L 8.8 17.45 L 8.9 17.45 Q 11.25 17 15.05 13.4 Q 18.85 9.8 21.5 6.35 L 26.05 0.15 M 0 0 Q 1.05 4.7 3.1 11 Q 5.1 17.25 8.05 17.45" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" stroke-dasharray=""/>
										</svg>
									</svg>
								</div>
								<div 
									class="button padding-10" 
									
									(click)="assignPinYin($event, 'toneNumber', 4, 'ˉ')"
									>
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 88 88">
										<svg viewBox="0 0 27.45 20.5">
											<path fill="#6b4b2d" stroke="none" paint-order="stroke fill markers" d=" M 0.4 0.85 Q 0.85 0.15 1.7 0 L 3.05 0.3 L 27.45 19.25 L 27.15 19.9 L 26.45 20.5 L 0.85 3.6 Q 0.15 3.15 0.05 2.3 Q -0.1 1.5 0.4 0.85"/>
										</svg>
									</svg>
								</div>
								<div 
									class="button padding-10" 
									
									(click)="assignPinYin($event, 'toneNumber', 5, '.')"
									>
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 88 88">
										<svg viewBox="0 0 50 50">
											<circle fill="#6b4b2d" cx="25" cy="25" r="10" />
										</svg>
									</svg>
								</div>

								
									
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="keyboardOptions.yunmu">
					<div class="yunmu">
						<div class="keyboard-line" *ngFor="let yunmuLine of _arrYunmu">
							<div class="button" *ngFor="let yunmu of yunmuLine" (click)="assignPinYin($event, 'yunmu', yunmu, yunmu)">
								{{	formatYunmuForKeyboard(yunmu) }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="button2">
				<div 
					class="button" 
					(click)="clear()">
					{{'commonService.clear'|translate}}
				</div>
				<div 
					class="button"
					(click)="closeKeyboard($event)"
					>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						viewBox="0 0 88 88"
						>
						<defs>
							<rect id="block" fill="#72675d" width="4" height="4" />
						</defs>
						<g>
							<rect fill="#72675d" x="23" y="27" width="42" height="29" rx="5"/>
							<rect fill="#e9e0d8" x="25" y="29" width="38" height="25" rx="4"/>
							<g transform="translate(30, 33)">
								<use xlink:href="#block" /><use xlink:href="#block" x="6"/><use xlink:href="#block" x="12"/><use xlink:href="#block" x="18"/><use xlink:href="#block" x="24"/>
							</g>
							<g transform="translate(33, 40)">
								<use xlink:href="#block"/><use xlink:href="#block" x="6"/><use xlink:href="#block" x="12"/><use xlink:href="#block" x="18"/>
							</g>
							<path class="blocks" fill="#72675d" d="M 34 47 L 54 47 L 54 50 L 34 50 L 34 47" />
							<path class="arrow" fill="#72675d" d="M 44.05 64.05 L 39.05 59.05 L 49.05 59.05 L 44.05 64.05"/>
						</g>
					</svg>
					
					

				</div>
			</div>
			<div #container></div>
		</div>

    `,
	styles:[
		`

		.keyboard{
			z-index:var(--keyboard-z-index);
			transform: translate(0px, 100%);
		}

		.keyboard.active{
			transform: translate(0px, 0px);
		}
		
		.left{
			margin-right:66px;
		}
		.padding-10{
			padding:10px;
		}
		.shengmu .button{
			background-color:#E2FCC0;
		}
		.yunmu .button{
			background-color:#FFE3AB;
		}
		.tone .button{
			background-color:#BDE1FC;
		}
		.button2 .button{
			background-color:#E9E0D8;
			border:none;
		}
		
		.keyboard{
			transition: transform 0.3s ease;
			// transform: translate(0px, 0px);
			// box-shadow: #00000080 inset 0px 5px 12px 0px;
			box-shadow: #0000001a inset 0px 6px 6px 0px;
			
			position:absolute;
			bottom:0px;
			width:100%;
			
			background-color:#E9E0D8;
			padding-top:22px;
			padding-left:63px;
			padding-bottom:18px;
		}
		
		.keyboard.hidden-keyboard{
			transform: translate(0px, 100%);
		}
		.button2
		{
			position: absolute;
		    right: 10px;
		    bottom: 18px;
			display: flex;
		}

		.keyboard-line{
			margin-bottom:9px;
		}
		
		.flex{
			display:flex;
		}
		.button:active{
			transform:translate(0px, 2px);
			filter: brightness(0.8);
		}
		.button{
			width:58px;
			height:58px;
			text-align:center;
			line-height:58px;
			font-size:16px;
			margin-right:10px;
			display:inline-block;
			border:solid 2px #B69A77;
			border-radius:10px;
		}
		`
	]
})
export class PinYinKeyboardLayoutComponent 
{
	@Input() name:string;
	public active:boolean = false;
	public timer:any;
	public locked:boolean;
	// public hidden:boolean;
	@Input() target:any;
	@Output() emitter:EventEmitter<any>= new EventEmitter();
	public pinyin:any;
	public keyboardOptions:any = {
		shengmu:true,
		yunmu:true,
		toneNumber:true
	};
	public _arrShengmu:any [] = [ 
		["b", "p", "m", "f", "d", "t", "n", "l"],
	   	["g", "k", "h", "j", "q", "x"],
	   	["zh", "ch", "sh", "r", "z", "c", "s"]
										
	];
	public _arrYunmu:any [] = [
		["a","o","e","er","ai","ei","ao","ou","an","en","ang","eng","ong"],
		["i","ia","ie","iao","iou","ian","in","iang","ing","iong"],
		["u","ua","uo","uai","uei","uan","uen","uang","ueng"],
		["ü","üe","üan","ün"]
	];
	public _arrToneMark:any [] = [[
		{num:1, text:"ˉ", symbol:"#tonemark1"},
		{num:2, text:"ˊ", symbol:"#tonemark2"},
		{num:3, text:"ˇ", symbol:"#tonemark3"},
		{num:4, text:"ˋ", symbol:"#tonemark4"},
		{num:5, text:".", symbol:"#tonemark5"}
	]];
	protected _objTone:any ={
		"a":["ā","á","ǎ","à"],
		"o":["ō","ó","ǒ","ò"],
		"e":["ē","é","ě","è"],
		"i":["ī","í","ǐ","ì"],
		"u":["ū","ú","ǔ","ù"],
		"ü":["ǖ","ǘ","ǚ","ǜ"]
	};
												
	constructor(private whitePopupService:WhitePopupService)
	{

	}
	

	private lockExtend():void
	{
		// console.log("lockExtend");
		if(this.target)
		{
			this.locked = true;
			if(this.timer)
			{
				clearTimeout(this.timer)
			}
			this.timer = setTimeout(()=>{
				console.log("clear log");
				this.locked = false;
			}, 500);
		}
	}

	public switchTarget(t:HTMLElement):void
	{
		console.log(this.name, "switchTarget", t);
		// this.hidden = false;
		this.target = t;
		this.lockExtend();
		this.updateKeybordOptions();
	}
	updateKeybordOptions() {
		if(this.target)
		{
			var temp:any = this.target.target;
			if(temp.options)
			{
				this.keyboardOptions = temp.options;
				this.pinyin = temp.pinyin;
			} else {
				this.keyboardOptions = {
					shengmu:true,
					yunmu:true,
					toneNumber:true
				};
				this.pinyin = null;
			}
			this.active = true;
		} else {
			this.active = false;
		}
		console.log(this.name, "active", this.active);
	}

	public focus():void
	{
		setTimeout(()=>{
			this.target.focus();
		}, 0)
		this.lockExtend();
	}

	formatYunmuForKeyboard(yunmu:string):string
	{
		if(yunmu == 'iou')
		{
			return "iu";
		} else if(yunmu == "uei")
		{
			return "ui";
		} else if(yunmu == "uen")
		{
			return "un";
		} else {
			return yunmu; 
		}
	}
	
	assignPinYin(event:any, type:string, value:any, text:string):void
	{
		// if(type == "shengmu") this.hasShengMu = true;
		event.stopPropagation();
		this.next({
			pinyin:this.pinyin,
			type:"assign", 
			key:type, 
			value:value, 
			text:text, 
			target:event.currentTarget,
			// hasShengMu:this.hasShengMu,
			options:this.keyboardOptions
		});
	}
	
	clear():void
	{
		this.next({type:"clear"});
	}

	closeKeyboard(event:Event):void
	{
		if(this.target) this.target.blur();
		this.emitter.emit({type:"close"});
		/*
		// this.hidden = true;
		this.active = false;
		setTimeout(()=>{
			if(!this.active)
			{
				
			}
		}, 310);
		*/
	}

	private next(data:any):void
	{
		this.emitter.next(data);
	}
	
	@HostListener('mousedown', ['$event'])
	@HostListener('click', ['$event'])
		onMouseEvent(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
	}
	
}
@Component({
    template: `
		<pinyin-keyboard-layout 
			#keyboard1
			(emitter)="onTrigger($event)"
			[name]="'keyboard1'"
			(click)="clickKeyboardBody($event)"
			></pinyin-keyboard-layout>
		<pinyin-keyboard-layout 
			#keyboard2
			[name]="'keyboard2'"
			(emitter)="onTrigger($event)"
			(click)="clickKeyboardBody($event)"
			></pinyin-keyboard-layout>

		<bubbleBox2 #zeroShengMuPopup
			
			[autoClose]="false"
			[position]="'top'"
			[autoZIndex]="false"
			[backgroundColor]="'#333333'" 
			[padding]="0" 
			[contentContainerZindex]="'var(--top-z-index)'"
			[options]="{noAnimate:true}"
			[autoTailColor]="false"
			>
			<div class="zero-sheng-mu-container">
				<div class="zero-sheng-mu pinyin-font" *ngIf="zeroShengMu">
					<div class="zero-sheng-mu-button pinyin-button" *ngFor="let z of zeroShengMu.items" (click)="selectYunmu(zeroShengMu.data, z)">{{z}}</div>
				</div>
			</div>
		</bubbleBox2>
		<bubbleBox2 #pinyinTonePopup
			
			[autoClose]="false"
			[position]="'top'"
			[autoZIndex]="false"
			[backgroundColor]="'#333333'" 
			[padding]="0" 
			[contentContainerZindex]="'var(--top-z-index)'"
			[options]="{noAnimate:true}"
			[autoTailColor]="false"
			>
			<div class="pinyin-tone-container">
			
				<div class="pinyin-tone pinyin-font" *ngIf="pinyinTone">
					<div class="pinyin-tone-label" >聲調位置:</div>
					<div class="pinyin-tone-button pinyin-button" *ngFor="let option of pinyinTone.options"
						(click)="selectPinYinTonePosition(pinyinTone, option)"
						>{{option.text}}
					</div>
				</div>
			</div>
		</bubbleBox2>
		<div #container></div>
    `,
	styles:[
		`
		.pinyin-button{
			cursor: pointer;
		}
		.pinyin-button:active{
			transform:translate(0px, 2px);
		}
		.pinyin-button:active{
			transform:translate(0px, 2px);
		}
		.pinyin-tone-container
		{
			width:auto;
			min-width:80px;
			height:54px;
			padding-top:8px;
			padding-bottom:8px;
			padding-left:5px;
			padding-right:5px;
		}

		.pinyin-tone
		{
			display:flex;
			margin-left:auto;
			margin-right:auto;
		}

		.pinyin-tone-label{
			padding-left:10px;
			color:white;
			line-height:38px;
			height:38px;
		}
		.pinyin-tone-button
		{
			height:38px;
			width:38px;
			color:white;
			line-height:38px;
			background-color:transparent;
			border:solid 2px black;
			border-radius:10px;
			margin-left:5px;
			margin-right:5px;
			text-align:center;
		}

		.zero-sheng-mu-container
		{
			width:auto;
			min-width:80px;
			height:40px;
			padding-top:5px;
			padding-bottom:5px;
			padding-left:5px;
			padding-right:5px;
		}

		.zero-sheng-mu
		{
			display:flex;
			margin-left:auto;
			margin-right:auto;
		}

		.zero-sheng-mu-button
		{
			background-color:black;
			line-height:30px;
			height:30px;
			color:white;
			margin-left:5px;
			margin-right:5px;
			border-radius:10px;
			padding-left:10px;
			padding-right:10px;
		}


		.left{
			margin-right:66px;
		}
		.padding-10{
			padding:10px;
		}
		.hidden{
			display:none;
		}
		.shengmu .button{
			background-color:#E2FCC0;
		}
		
		.yunmu .button{
			background-color:#FFE3AB;
		}
		.tone .button{
			background-color:#BDE1FC;
		}
		.button2 .button{
			background-color:#E9E0D8;
			border:none;
		}
		
		.keyboard{
			transition: transform 0.3s ease;
			transform: translate(0px, 0px);
			// box-shadow: #00000080 inset 0px 5px 12px 0px;
			box-shadow: #0000001a inset 0px 6px 6px 0px;
			
			position:absolute;
			bottom:0px;
			width:100%;
			
			background-color:#E9E0D8;
			padding-top:22px;
			padding-left:63px;
			padding-bottom:18px;
		}
		.keyboard.hidden-keyboard{
			transform: translate(0px, 100%);
		}
		.button2
		{
			position: absolute;
		    right: 10px;
		    bottom: 18px;
			display: flex;
		}

		.keyboard-line{
			margin-bottom:9px;
		}
		
		.flex{
			display:flex;
		}
		.button:active{
			transform:translate(0px, 2px);
			filter: brightness(0.8);
		}
		.button{
			width:58px;
			height:58px;
			text-align:center;
			line-height:58px;
			font-size:16px;
			margin-right:10px;
			display:inline-block;
			border:solid 2px #B69A77;
			border-radius:10px;
		}
		`
	]
})


export class PinYinKeyboardComponent
{
	// public dismissing:boolean;
	@ViewChild('keyboard1', { static: false }) _keyboard1:PinYinKeyboardLayoutComponent;
	@ViewChild('keyboard2', { static: false }) _keyboard2:PinYinKeyboardLayoutComponent;

	@ViewChild('zeroShengMuPopup', { static: false }) zeroShengMuPopup:BubbleBox2Component;
	@ViewChild('pinyinTonePopup', { static: false }) pinyinTonePopup:BubbleBox2Component;
	
	@ViewChild('container', { static: false }) container:ElementRef;
	public pinyinTone:any;
	public zeroShengMu:any;
	public target:any;
	public _activeKeyboard:PinYinKeyboardLayoutComponent;
	
	public timer:any;
	public locked:boolean;
	public hidden:boolean;
	// @Input() target:any;
	@Output() emitter:EventEmitter<any>= new EventEmitter();
	public keyboardOptions:any = {};
	public _arrShengmu:any [] = [ 
		["b", "p", "m", "f", "d", "t", "n", "l"],
	   	["g", "k", "h", "j", "q", "x"],
	   	["zh", "ch", "sh", "r", "z", "c", "s"]
										
	];
	public _arrYunmu:any [] = [
		["a","o","e","er","ai","ei","ao","ou","an","en","ang","eng","ong"],
		["i","ia","ie","iao","iou","ian","in","iang","ing","iong"],
		["u","ua","uo","uai","uei","uan","uen","uang","ueng"],
		["ü","üe","üan","ün"]
	];
	public _arrToneMark:any [] = [[
		{num:1, text:"ˉ", symbol:"#tonemark1"},
		{num:2, text:"ˊ", symbol:"#tonemark2"},
		{num:3, text:"ˇ", symbol:"#tonemark3"},
		{num:4, text:"ˋ", symbol:"#tonemark4"},
		{num:5, text:".", symbol:"#tonemark5"}
	]];
	protected _objTone:any ={
		"a":["ā","á","ǎ","à"],
		"o":["ō","ó","ǒ","ò"],
		"e":["ē","é","ě","è"],
		"i":["ī","í","ǐ","ì"],
		"u":["ū","ú","ǔ","ù"],
		"ü":["ǖ","ǘ","ǚ","ǜ"]
	};
												
										
	constructor()
	{

	}

	clickKeyboardBody(event):void
	{
		this.zeroShengMuPopup.close();
		this.pinyinTonePopup.close();
	}
	public getKeyboardOptions(target:any):any
	{
		if(target)
		{
			var temp:any = target.target;
			if(temp.options)
			{
				return temp.options;
				
			} else {
				return {
					shengmu:true,
					yunmu:true,
					toneNumber:true
				};
			}
		} else {
			return null;
		}
	}
	private lastKeyboardOption:any;
	private sameKeyboardOption(option1:any, option2:any):boolean
	{
		console.log("option1", option1, "option2", option2);
		if((!option1) && (!option2)) return true;
		if((!option1) || (!option2)) return false;
		return (
			option1.shengmu == option2.shengmu &&
			option1.yunmu == option2.yunmu &&
			option1.toneNumber == option2.toneNumber
		);
	}
	public switchTarget(t:HTMLElement):void
	{
		// console.log("PinYinKeyboardComponent.switchTarget", t);
		if(!this._keyboard1)
		{
			setTimeout(()=>{
				this.switchTarget(t);
			}, 100);
			return;
		}
		console.log("keyboard1", this._keyboard1.active, "keyboard2", this._keyboard2.active);
		if(this.target != t)
		{
			this.target = t;
			if(this.target)
			{
				if(this._activeKeyboard)
				{
					this._activeKeyboard.switchTarget(null);
				}
				var newKeyboardOption:any = this.getKeyboardOptions(t);
				if(this.sameKeyboardOption(newKeyboardOption, this.lastKeyboardOption))
				{
					if(this._activeKeyboard == this._keyboard2)
					{
						this._activeKeyboard = this._keyboard2;
					} else {
						this._activeKeyboard = this._keyboard1;
					}
				} else {
					if(this._activeKeyboard == this._keyboard2)
					{
						this._activeKeyboard = this._keyboard1;
					} else {
						this._activeKeyboard = this._keyboard2;
					}
				}
				this.lastKeyboardOption = newKeyboardOption;
				this._activeKeyboard.switchTarget(t);
			} else {
				if(this._activeKeyboard)
				{
					this._activeKeyboard.switchTarget(null);
				}
			}
		}
		// console.log("keyboard1", this._keyboard1.active, "keyboard2", this._keyboard2.active);
	}
	

	dismiss():Promise<any>{
		var that:PinYinKeyboardComponent = this;
		if(that)
		{
			return new Promise((resolve, reject)=>{
				setTimeout(()=>{
					if(that._activeKeyboard && that._activeKeyboard.locked)
					{
						// console.log("dismiss", "Failed");
						reject(null);
					} else {
						// console.log("dismiss", "OK");
						resolve(null);
					}
				}, 200)	;
			});
		} else {
			return Promise.resolve(null);
		}
	}
	
	selectYunmu(data:any, yunmu:string):void
	{
		var s:string = /^([yw]).*$/.test(yunmu) ? yunmu.replace(/^([yw]).*$/, "$1") : "";
		data.s = s;
		data.yunmu = yunmu;
		this.next(data);
		// this.zeroShengMuPopup.close();
	}
	
	private updateZeroShengMuList(data:any) {
		var yunmu: any = data.value;
		var zeroShengMuMap = {
			"i":["yi", "i"],
			"ia":["ya", "ia"],
			"ie":["ye", "ie"],
			"iao":["yao", "iao"],
			"iou":["you", "iu"],
			"iu":["you", "iu"],
			"ian":["yan", "ian"],
			"in":["yin", "in"],
			"iang":["yang", "iang"],
			"ing":["ying", "ing"],
			"iong":["yong", "iong"],

			"u":["wu", "u"],
			"ua":["wa", "ua"],
			"uo":["wo", "uo"],
			"uai":["wai", "uai"],
			"uei":["wei", "ui"],
			"ui":["wei", "ui"],
			"uan":["wan", "uan"],

			"uen":["wen", "un"], // "un":["weu", "un"],
			
			"uang":["wang", "uang"],
			"ueng":["weng", "ueng"],
			
			"ü":["yu", "ü"],
			"üe":["yue", "üe"],
			"üan":["yuan", "üan"],
			"ün":["yun", "ün"]
		};
		if(zeroShengMuMap.hasOwnProperty(yunmu))
		{
			this.zeroShengMu = {
				data:data,
				items: zeroShengMuMap[yunmu] 
			}
			return true;
		}
		return false;
	}
	public selectPinYinTonePosition(o:any, option:any):void
	{
		var data:any = o.data;
		data.selectedTone = option.selectedTone;
		this.next(data);
	}
	private getPinYinSelectableTone(pinyin:PinYinObject):string []
	{
		return pinyin.y.match(/[aeiouüễ]/g);
		/*
		if(pinyin.yunmu == "iou")
		{
			if(pinyin.s == "y") return ["o","u"];
			return ["i","u"];
		}
		if(pinyin.s == "y") return pinyin.y.match(/[aeouüễ]/g);
		if(pinyin.s == "w") {
			debugger;
			// return pinyin.yunmu.match(/[aeouüễ]/g);
		}
		return pinyin.yunmu.match(/[aeiouüễ]/g);
		*/
	}
	private shouldShowPinYinToneSelection(data:any):boolean
	{
		// this.pinyinTone = null;
		if(data.key != "toneNumber")return false;
		if(!data.options.isQP) return false;
		var ctrl = this.target.target.control;
		var pinyin:PinYinObject = ctrl.getPinYinObject();
		if(!pinyin.yunmu) return false;
		if(!data.value || data.value == 5) return false;
		var matches = this.getPinYinSelectableTone(pinyin);
		if(matches == null || matches.length <= 1) return false;
		this.pinyinTone = {
			data:data,
			options:matches.map((pinyin)=>{
				var text:string = PinYinUtils.objToneMap[pinyin][data.value - 1];
				return {
					text:text,
					selectedTone:pinyin,
					tone:data.value
				};
			})
		};
		
		return true;
	}
	

	private shouldShowQShengmuSelection(data:any):boolean
	{
		
		if(data.key != "yunmu")return false;
		if(!data.options.isQN) return false;
		if(!/^[iuü].*$/.test(data.value)) return false;
		
		var ctrl = this.target.target.control;
		var pinyin:PinYinObject = ctrl.getPinYinObject();
		var shengmu = pinyin.s;
		return (!shengmu || shengmu == "y" || shengmu == "w");
	}
	public onTrigger(data:any):void
	{
		// this.zeroShengMuPopup.closeNow();
		// this.pinyinTonePopup.closeNow();
		if(this.shouldShowQShengmuSelection(data))
		{
			// zero shengmu selection request
			this.updateZeroShengMuList(data);
			this.zeroShengMuPopup.openOrMove(data.target, this.container.nativeElement);
			this.pinyinTonePopup.close();
		} else if(this.shouldShowPinYinToneSelection(data))
		{
			// pinyin tone position selection request
			this.pinyinTonePopup.openOrMove(data.target, this.container.nativeElement);
			this.zeroShengMuPopup.close();
		} else {
			this.next(data);
			this.pinyinTonePopup.close();
			this.zeroShengMuPopup.close();
		}
		
		/*
		if(data && data.type == "close")
		{
			this.switchTarget(null);
		}
		*/
	}
	/*
	clear():void
	{
		this.next({type:"clear"});
	}
	
	closeKeyboard(event:Event):void
	{
		this.hidden = true;
		setTimeout(()=>{
			if(this.hidden)
			{
				this.emitter.emit({type:"close"});
				this.target.blur();
			}
		}, 310);
	}
	*/
	private next(data:any):void
	{
		if(this.target)
		{
			var o:any = this.target.target;
			if(o.subject)
			{
				o.subject.next(data);
			}
		}
	}
	
	@HostListener('mousedown', ['$event'])
	@HostListener('click', ['$event'])
		onMouseEvent(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
	}
	
};


@Injectable({ providedIn: 'root' })
export class PinYinKeyboardService {
	private lastTarget:HTMLElement;
	
	private _root:HTMLElement;
	private keyboard:any = null;
    constructor(private dcs:DynamicComponentService, private translate:TranslateService) {

    }

	private createKeyboard():void
	{
		if(!this.keyboard)
		{
			this.keyboard = this.dcs.create(PinYinKeyboardComponent);
			var instance:PinYinKeyboardComponent = this.keyboard.compRef.instance;
			instance.emitter.subscribe((o:any)=>{
				if(o.type == "close")
				{
					this.dismiss();
				}
			})
		}
	}

	public setupRootElement(root:HTMLElement):void
	{
		this._root = root;
	}
    private sub:Subscription;
	
	public switchTarget(dom:HTMLElement) {

		if(this.lastTarget != dom)
		{
			if(this.lastTarget)
			{
				this.lastTarget.blur();
				
			}
			if(this.sub)
			{
				this.sub.unsubscribe();
				this.sub = null;
			}
			if(dom)
			{
				dom.focus();
				this.showKeyboard(dom);
				this.sub = fromEvent(dom, "blur").subscribe((a:any)=>{
					this.dismiss();
				})
			} else {
				this.showKeyboard(null);
				this.dismiss();
			}
			this.lastTarget = dom;
		}
	}
	
	public container:HTMLElement;

	public showKeyboard(target:HTMLElement):void
	{
		this.createKeyboard();
		console.log("PinYinKeyboardService.showKeyboard", target);
		this.dcs.open(this.keyboard, this.container);
		var instance:PinYinKeyboardComponent = this.keyboard.compRef.instance;
		instance.switchTarget(target);
	}
	dismiss():void{
		if(this.keyboard)
		{
			var instance:PinYinKeyboardComponent = this.keyboard.compRef.instance;
			instance.switchTarget(null);
			instance.dismiss().then(()=>{
				this.close();
			}).catch(()=>{
				
			});
		}
	}
	
	close():void
	{
		if(this.keyboard)
		{
			//this.dcs.close(this.keyboard);
			this.dcs.destroy(this.keyboard);
			this.keyboard = null;
			console.log("close keyboard");
		}
		if(this.lastTarget)
		{
			this.lastTarget.blur();
			this.lastTarget = null;
		}
	}

	/*
	public close():void
	{
		if(this.keyboard)
		{
			// var instance:PinYinKeyComponent = this.keyboard.compRef.instance;
			this.dcs.close(this.keyboard);
		}
	}
	*/
}
