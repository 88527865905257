import { DataUtils } from "./DataUtils";
import { ObjectUtils } from "./ObjectUtils";

export class ArrayUtils{
	static equivalent(array1:any[], array2:any[]):boolean
	{
		return array1.length === array2.length && array1.every((array1Element:any, index) => {
			return array1Element === array2[index];
		});
	}
	
	static intersects(array1: any[], array2: any[]): any[] {
		return array1.filter(
			(element:any):Boolean => {
			return array2.indexOf(element) !== -1;
		});
	}
	static appendToArray(elements:any [], to:any[]):void
	{
		elements.forEach((element)=>{
			to.push(element);
		});
	}
	static exec(regex:RegExp, str:string):any[]
	{
		var m:any 
		var output:any [] = [];
		while ((m = regex.exec(str)) !== null) {
		    // This is necessary to avoid infinite loops with zero-width matches
		    if (m.index === regex.lastIndex) {
		        regex.lastIndex++;
		    }
		    output.push(m);
		}
		return output;
	}
	static remove(array:any[] , element:any):void
	{
		var index = array.indexOf(element);
		if(index !== -1) array.splice(index, 1);
	}

	static unique(elements:any []):any[] {
		return elements.filter((element:any, index:number)=>{
			return elements.indexOf(element, index+1) == -1;
		});
	}

	public static forEach(items, fn)
	{
		var len = items.length;
		for(var i = 0;i <len;i++)
		{
			fn.call(null, items[i], i, items);
		}
	}
	/**
	 * 
	 * @param array 
	 * @param matchObject - function or extact object
	 * @returns 
	 */
	public static findObject(array:any [], matchObject: any): any 
	{
		var len = array.length;
		for (var i = 0; i < len; i++) {
			if (ObjectUtils.match(array[i], matchObject)) return array[i];
		}
		return null;
	}

	/**
	 * 
	 * @param array 
	 * @param fn (element):boolean=>{}
	 * @param fromIndex start index
	 * @returns number elment index or -1 (not found)
	 */
	public static indexOf(array:any [], fn:Function, fromIndex:number = 0): number
	{
		for(var i = 0;i < array.length;i++)
		{
			var element:any = array[i];
			if(fn.call(null, element))
			{
				return i;
			}
		}
		return -1;
	}

	static findFirstElementInfo(array:any [], fn:Function, fromIndex:number = 0): any
	{
		for(var i = fromIndex;i < array.length;i++)
		{
			var element:any = array[i];
			if(fn.call(null, element))
			{
				return {
					index:i,
					element:element
				};
			}
		}
		return {element:null,index:-1};
	}
	static find(array:any [], match:any):any
	{
		var fn:Function = (typeof match == "function") ? (element, m)=>{
			return match(element);
		} : ObjectUtils.matchElement;
		var len = array.length;
		for(var i = 0;i < len;i++)
		{
			var element = array[i];
			if(fn(element, match)) return element;
		}
		return null;
		
	}
	
	static union(array1:any [], array2:any []):any []
	{
		return array1.concat(
			array2.filter(
				(element)=>{
					return array1.indexOf(element) == -1;
				}
			)
		);
	}

	static difference(array1:any[], exception:any [] ):any []
	{
		return array1.filter(
			(element)=>{
				return exception.indexOf(element) == -1;
			}
		);
	}

	static replaceElement(array:any [], removeElement:any, replacement:any):void
	{
		var index = array.indexOf(removeElement);
		if(index != -1)
		{
			array[index] = replacement;
		}
		// if(index != -1) array.splice(index, 1, );
	}
	static removeElement(array:any [], element:any):void
	{
		var index = array.indexOf(element);
		if(index != -1) array.splice(index, 1);
	}
	static fastRemoveElement(array:any [], element:any):void
	{
		var index = array.indexOf(element);
		if(index != -1) {
			if(index + 1 == array.length)
			{
				array.pop();
			} else {
				var lastElement = array.pop();
				array[index] = lastElement;
			}

		}
	}
	/*
	[
	  	{
	  		key:"id",
	  		order:"ASC",
	  		type:"numeric"
	  	},
	  	{
	  		key:"name",
	  		order:"ASC",
	  		type:"string"
	  	}
	 * ]
	*/
	static sortOn(array:any [], items:any []):any []
	{
		var s:ArraySort = new ArraySort(items);
		return array.sort(s.sort.bind(s));
	}

	public static randomize(ary:any [] ):any [] 
	{
		var copy:any [] = ary.concat();
		var output:any [] =  [];
		for(var i = copy.length;i > 0;i--)
		{
			var index:number = Math.floor(Math.random() * i);
			var element:any = copy[index];
			output.push(element);
			var lastIndex = i - 1;
			if(index !== lastIndex)
			{
				copy[index] = copy[lastIndex];
			}
		}
		return output;
	}

}

class ArraySort
{
	/**
	 * 
	 * @param orderBy 
	 * 
	 */
	constructor(private orderByArray:any [])
	{
		this.orderByArray.forEach((field)=>{
			if(field.order == "ASC"){
				field.greaterThan = 1;
				field.lessThan = -1
			} else {
				field.greaterThan = -1;
				field.lessThan = 1;
			}
		})
	}

	private getValue(element:any, field:any):any
	{
		if(!element.hasOwnProperty(field.key)) return 0;
		var value = element[field.key];
		if(value === null) return 0;
		if(field.type == "numeric")
		{
			if(isNaN(value)) return 0;
			return parseFloat(value);
		} else {
			return value;
		}	
	}

	public sort(a:any, b:any)
	{
		var count = this.orderByArray.length;
		for(var i = 0; i < count; i++) {
			var field:any = this.orderByArray[i];
			var aValue = this.getValue(a, field);
			var bValue = this.getValue(b, field);
			if(aValue !== bValue)
			{
				if(aValue > bValue)
				{
					return field.greaterThan;
				} else {
					return field.lessThan;
				}
			}
		}
		return 0;
		// return result;
	}

	
}