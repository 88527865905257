import { Component, HostBinding, Input, Output, EventEmitter, HostListener, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { TranslateService } from '@ngx-translate/core';
import { RoService } from 'src/app/service/ro.service';
import { Observable, Subscription, Subscriber } from 'rxjs';
import { TopLayer } from 'src/app/common/TopLayer';
import { DataService } from 'src/app/service/data.service';


////////////////////////////////////////
@Component({
	selector: 'selection',
	template: `
        <div class="container">
            <span class="title">{{message}}</span>
            <HR>
            <div class="selections">
                <div 
                    *ngFor="let selection of selections; index as i; first as isFirst" 
                    class="button selection {{selection == selectedObject ? 'selected' : ''}}"
                    (click)="changeSelection(selection);"
                    >
                    {{selection.label}}
                    <div class="checkbox right"></div>
                </div>
            </div>

            <div class="btnArea">
                <div class="btn cfm" (click)="confirm()">
                    <img src="./assets/img/icon_alert_tick.svg">
                    {{confirmLabel || _cfmText}}
                </div>
                <div class="btn cnl" (click)="cancel();">
                    <img src="./assets/img/icon_alert_cross.svg">
                    {{cancelLabel || _cnlText}}
                </div>
            </div>
        </div>
    `,
	styleUrls: ['./alert.component.scss']
})

export class SelectionComponent {
	@HostListener('dblclick', []) onDblClick() { console.log(this); }
	@HostBinding('style.backgroundColor') public hostBackgroundColor: string;
	@HostBinding('style.zIndex') public hostZIndex = 0;

	@Input() public message: string = "";
	@Input() public showCnlBtn: boolean = false;

	@Input() public confirmLabel: string = "";
	@Input() public cancelLabel: string = "";

	public emitter: EventEmitter<any> = new EventEmitter();
	public target: HTMLElement;
	public direction: string = "down";
	public selections: any = [{ label: 1, value: 1 }];

	public _showShadow: boolean = true;
	public _cfmText: string = this.translate.instant("alert.confirm");
	public _cnlText: string = this.translate.instant("alert.cancel");

	@Output() observable: Observable<any>;

	private showingShadow: boolean = false;
	public selectedObject: any = null;
	private subscribers: Array<any> = null;
	constructor(
		// private coms:CommonService, 
		private dcs: DynamicComponentService,
		private translate: TranslateService,
		private ros: RoService) {
		this.subscribers = []
		this.observable = new Observable((subscriber) => {
			this.subscribers.push(subscriber);
		});
		this.getPromise().finally(this.close.bind(this));
	}

	public getPromise(): Promise<any> {
		var value: any = null;
		return new Promise((resolve, reject) => {
			this.observable.subscribe(
				(newValue: any) => {// next
					value = newValue;
				},
				() => {
					reject("cancel");
				},
				() => {
					resolve(value);
				}
			);
		})
	}

	public next(value: any): void {
		this.subscribers.forEach((subscriber: Subscriber<any>) => {
			subscriber.next(this.selectedObject);
		})
	}

	public confirm(): void {
		this.subscribers.forEach((subscriber: Subscriber<any>) => {
			subscriber.next(this.selectedObject);
			subscriber.complete();
		})
	}

	public cancel() {
		this.subscribers.forEach((subscriber: Subscriber<any>) => {
			subscriber.error("cancel");
		})
	}

	public changeSelection(selection: any): void {
		this.selectedObject = selection;
	}

	public open(appendEle: HTMLElement = document.body): void {
		this.hostBackgroundColor = this._showShadow ? 'rgba(0, 0, 0, 0.5)' : 'none';
		this.hostZIndex = TopLayer.getNextIndex();
		this.dcs.open(this, appendEle);
		if (this._showShadow) {
			this.ros.request("showShadow", null, false);
			this.showingShadow = true;
		}
	}

	public close(): void {
		this.dcs.close(this);
		if (this.showingShadow) this.ros.request("hideShadow", null, false);
	}
}
//////////

@Component({
	selector: 'selection2',
	template: `
		<div 
			class="shadow" 
			[style.zIndex]="hostZIndex" 
			(click)="clickOutside()"></div>
		<div 
			class="selection-container"
			[class.down]="direction == 'down'"
			[class.up]="direction == 'up'"
			[style.zIndex]="hostZIndex"
			[style.left.px]="offset.x"
			[style.top.px]="offset.y"
			>
			<div class="selection-view" >
				<div class="line button no-select" *ngFor="let obj of selection;" (click)="execute($event, obj);">
					{{obj.label}}
				</div>
			</div>
			<div class="arrow"></div>
		</div>
    `,
	styleUrls: ['./selection.component.scss']
})
//////////
export class SelectionComponent2 {
	@HostBinding('style.zIndex') public hostZIndex = 9999;
	public selection: any[];
	public emitter: EventEmitter<any> = new EventEmitter();
	public target: HTMLElement;

	public offset: any = {
		x: 0,
		y: 0
	};
	public direction: string = "down";

	constructor(private coms: CommonService, private dcs: DynamicComponentService) {
	}
	clickOutside(): void {
		this.cancel()
	}
	cancel(): void {
		this.emitter.error("cancel");
		this.close();
	}

	execute(event: any, element: any): void {
		this.emitter.emit(element);
		this.close();
		event.stopImmediatePropagation();
	}

	getGlobalXY(el: any) {
		var _x = 0;
		var _y = 0;
		while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
			_x += el.offsetLeft - el.scrollLeft;
			_y += el.offsetTop - el.scrollTop;
			el = el.offsetParent;
		}
		return { x: _x, y: _y };
	}
	// var x = getOffset( document.getElementById('yourElId') ).left;

	public open(appendEle: HTMLElement = document.body): void {
		this.hostZIndex = this.coms.getZIndex();

		this.dcs.open(this, appendEle);
		if (this.target) {
			var tmpOffset = this.getGlobalXY(this.target);
			tmpOffset.x += this.target.clientWidth / 2;
			if (this.direction == "down") tmpOffset.y += this.target.clientHeight;
			this.offset = tmpOffset;
		}

	}


	public close(): void {
		this.dcs.close(this);
	}

}
////////////////////////////////////////
@Component({
	// selector: 'alert',
	template: `
		<div 
			class="alert-dialog container" [ngStyle]="containerStyle"
			[style.maxWidth]="(templateContent ? '100%' : containerMaxWidth)"
		>
			<div *ngIf="message" [ngStyle]="msgStyle" class="message-container">
				<div class="message" [innerHTML]="message"></div>
			</div>
            <ng-container *ngTemplateOutlet="templateContent"></ng-container>
			<div class="btnArea" [ngStyle]="btnAreaStyle">
				<div class="btn cfm" (click)="confirmClick.emit();emitter.emit(); close();">
					<img *ngIf="showCnlBtn && !btnAreaStyle.hideIcon" src="./assets/img/icon_alert_tick.svg">
					{{confirmLabel || _cfmText}}
				</div>
				<div class="btn cnl" *ngIf="showCnlBtn" (click)="cancelClick.emit('cancel');emitter.error('cancel'); close();">
					<img *ngIf="!btnAreaStyle.hideIcon" src="./assets/img/icon_alert_cross.svg">
					{{cancelLabel || _cnlText}}
				</div>
			</div>
		</div>
    `,
	styleUrls: ['./alert.component.scss']
})

export class AlertComponent {
	@HostListener('dblclick', []) onDblClick() { console.log(this); }
	@HostBinding('style.backgroundColor') public hostBackgroundColor: string;
	@HostBinding('style.zIndex') public hostZIndex = 0;

	@Input() public message: string = "";
	@Input() public msgStyle: any = {};
	@Input() public containerStyle: any = {};
	@Input() public btnAreaStyle: any = {};
	@Input() public showCnlBtn: boolean = false;

	@Input() public confirmLabel: string = "";
	@Input() public cancelLabel: string = "";
	public templateContent: TemplateRef<any> = null;
	public _showShadow: boolean = true;
	public _cfmText: string = this.translate.instant("alert.confirm");
	public _cnlText: string = this.translate.instant("alert.cancel");

	@Output() confirmClick: EventEmitter<any> = new EventEmitter();
	@Output() cancelClick: EventEmitter<any> = new EventEmitter();
	@Output() emitter: EventEmitter<any> = new EventEmitter();
	@Input() public containerMaxWidth = '300px';
	private showingShadow: boolean = false;

	constructor(private coms: CommonService, private dcs: DynamicComponentService, private translate: TranslateService, private ros: RoService) {
	}

	public open(appendEle: HTMLElement = document.body): void {
		this.hostBackgroundColor = this._showShadow ? 'rgba(0, 0, 0, 0.5)' : 'none';
		this.hostZIndex = this.coms.getZIndex();
		this.dcs.open(this, appendEle);
		if (this._showShadow) {
			this.ros.request("showShadow", null, false);
			this.showingShadow = true;
		}
	}

	public close(): void {
		this.dcs.close(this);
		if (this.showingShadow) this.ros.request("hideShadow", null, false);
	}

}


////////////////////////////////////////
@Component({
	// selector: 'prompt-dialog',
	template: `
		<div class="bg">
		</div>
		<div class="prompt-dialog container">
			<div class="header">
				<div class="button left btn cnl" (click)="cancel()">
					{{cancelLabel || _cnlText}}
				</div>
				<div class="title">
					{{title}}
				</div>
				<div class="button right btn cfm" (click)="confirm()">
					{{confirmLabel || _cfmText}}
				</div>
			</div>
			<div class="message-container">
				<input 
					dom-directive (onInit)="input.focus()"
					#input 
					class="text-input" 
					type="text" 
					(keyup.enter)="confirm()"
					[(ngModel)]="text"/>
			</div>
		</div>
    `,

	styleUrls: ['./alert.component.scss']
})

export class PromptComponent implements OnInit {
	// @ViewChild('input', {static: false}) input;

	@HostListener('dblclick', []) onDblClick() { console.log(this); }
	@HostBinding('style.backgroundColor') public hostBackgroundColor: string;
	@HostBinding('style.zIndex') public hostZIndex = 0;

	@Input() public title: string = "";
	@Input() public text: string = "";

	@Input() public showCnlBtn: boolean = false;

	@Input() public confirmLabel: string = "";
	@Input() public cancelLabel: string = "";
	public _showShadow: boolean = true;

	public _cfmText: string = this.translate.instant("alert.confirm");
	public _cnlText: string = this.translate.instant("alert.cancel");

	@Output() confirmClick: EventEmitter<any> = new EventEmitter();
	@Output() cancelClick: EventEmitter<any> = new EventEmitter();

	private showingShadow: boolean = false;

	constructor(
		private coms: CommonService, private dcs: DynamicComponentService, private translate: TranslateService, private ros: RoService, public datas: DataService
	) {

	}
	ngOnInit(): void {

	}

	public confirm(): void {
		this.confirmClick.emit(this.text);
		this.close();
	}

	public cancel(): void {
		this.cancelClick.emit('cancel');
		this.close();
	}

	public open(appendEle: HTMLElement = document.body): void {
		this.hostBackgroundColor = this._showShadow ? 'rgba(0, 0, 0, 0.5)' : 'none';
		this.hostZIndex = this.coms.getZIndex();
		this.dcs.open(this, appendEle);
		if (this._showShadow) {
			if (localStorage.getItem('in-web') == '0' || !this.datas.isInWeb) this.ros.request("showShadow", null, false);
			this.showingShadow = true;
		}
	}

	public close(): void {
		this.dcs.close(this);
		if (this.showingShadow && (localStorage.getItem('in-web') == '0' || !this.datas.isInWeb) ) this.ros.request("hideShadow", null, false);
	}

}

///////////////////


////////////////////////////////////////
@Component({
	selector: 'prompt-dialog',
	template: `
	<div 
		class="prompt-dialog" >
		<div class="header">
			<div class="btn cnl" (click)="onCancel()">
				Cancel
			</div>
			<div class="message">{{title}}</div><BR>
			<div class="btn cfm" (click)="onConfirm()">OK</div>
		</div>
		
		<div class="message-container">
			<div class="shadow-top"></div>
			<div>
				<input [(ngModel)]="value">
			</div>
		</div>
		
	</div>
    `,
	// styleUrls: ['./alert2.component.scss']
	styleUrls: ['./alert.component.scss']
})

export class PromptDialogComponent {
	@HostListener('dblclick', []) onDblClick() { console.log(this); }
	@HostBinding('style.backgroundColor') public hostBackgroundColor: string;
	@HostBinding('style.zIndex') public hostZIndex = 0;

	@Input() public title: string;
	@Input() public value:string;
	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	// @Output() btnClick: EventEmitter<any> = new EventEmitter();

	constructor(
		private coms: CommonService,
		private dcs: DynamicComponentService,
		public translate: TranslateService,
		private ros: RoService
	) {
		
	}

	public onConfirm(): void 
	{
		this.emitter.emit(this.value);
		this.close();
	}
	public onCancel(): void {
		this.emitter.error("cancel");
		this.close();
	}

	public open(appendEle: HTMLElement = document.body): void {
		this.dcs.open(this, appendEle);
	}

	public close(): void {
		this.dcs.close(this);
	}

}


