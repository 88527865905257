import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallAppModal } from './installApp.modal';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
		CommonModule, TranslateModule,
		NgSelectModule, FormsModule
	],
    declarations: [
		InstallAppModal
    ],
    exports: [
        InstallAppModal
    ],
    providers: [
	],
	entryComponents: [InstallAppModal]
})

export class InstallAppModalModule {
}
