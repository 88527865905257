import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { preloadImage } from 'src/app/common/customFunction';
@Component({
	selector: 'create-game-modal',
	template: `
	<modal2
		#modal
		class="create-game-modal" [title]="modalTitle"
		[headerStyle]="{border: '0'}"
		[contentStyle]="{padding: '0'}"
		(confirmClick)="confirm()"
		(cancelClick)="confirm(false)"
		>
		<popup-toggle class="bookshelflist-popuptoggle" 
			[(toggle)]="toggle" bindLabel="label" bindValue="value"
			(toggleChange)="toggleChange()"
			[items]="toggles"></popup-toggle>
		<div class="game-container">
			<div class="game-container-1" *ngIf="games && toggle == 'choose-game'">
				<div class="item" *ngFor="let game of games" (click)="gameSelectClick(game)">
					<img class="item-cover" [src]="game.cover" />
					<div class="item-title">
						<span>{{game.title}}</span>
					</div>
					<fa-icon 
						[class.selected]="(selectedGame && selectedGame.id == game.id?icons.faCheckCircle:icons.faCheckCircleLight)" 
						[icon]="(selectedGame && selectedGame.id == game.id?icons.faCheckCircle:icons.faCheckCircleLight)"></fa-icon>
				</div>
			</div>

			<div class="game-container-2" *ngIf="games && toggle == 'set-game' && this.selectedGame">
				<div class="topic"> {{ {tc:'基本設定', sc:'基本設定', en: 'General setting'}[lang] }}</div>
				<div>
					<div clas="row first">
						<div class="row-label">{{ {tc:'名稱',sc:'名稱',en:'Name'}[lang] }}</div>
						<div class="row-value">
							<input type="text" [ngModel]="name" />
						</div>
					</div>
					<div class="row">
						<div class="row-label">{{ {tc: '科目', sc: '科目', en: 'Subject'}[lang] }} </div>
						<div class="row-value">
							<subjectSelector3 #subjectSelector 
								[(ngModel)]="subjects"
								leftBgColor="#2c97d7"
								></subjectSelector3>
						</div>
					</div>
					<div class="row" *ngIf="this.selectedGame.subitems && this.selectedGame.subitems.length > 1">
						<div class="row-label">{{ {tc:'遊戲', sc:'游戏', en:'Game'}[this.lang] }}</div>
						<div class="row-value">
							<okaPulldown2 [options]="this.selectedGame.subitems" bindLabel="title" [(ngModel)]="selectedSubitem"></okaPulldown2>
						</div>
					</div>
				</div>
				<div class="row" *ngFor="let row of settingRows">
					<div class="row-label">

					</div>
					<div class="row-value">
						
					</div>
				</div>
			</div>

		</div>

		
  	</modal2>
  `
	, styleUrls: ['./create-game.modal.scss'],
})



export class CreateGameModal implements OnInit {
	@Input() data: any;
	@ViewChild('modal', {static: false}) modal;
	@ViewChild('nameRef', {static: false}) nameRef;
	@Output() close:EventEmitter<any> = new EventEmitter<any>();
	public toggles;
	public toggle = 'choose-game';
	public toggleOptions;
	public url='';
	public name='';
	public modalTitle = '';
	public lang='tc';
	public games:any = [];
	public icons = {faCheckCircleLight , faCheckCircle };
	public selectedGame;
	public selectedSubitem;
	public settingRows:any = [];
	public subjects:any  = [];
	constructor(public datas: DataService, private script: ScriptService, private trans: TranslateService, private als: AlertService, public sans: DomSanitizer) {
	}

	ngOnInit() {
		const lang = this.datas.lang;
		this.lang = lang;
		this.toggles = [
			{label:{tc:'選擇遊戲',sc:'选择游戏',en:'Choose game'}[lang],value:'choose-game'}, 
			{label: {tc:'設定遊戲',sc:'设定游戏',en:'Edit setting'}[lang], value:'set-game', onEnter: ()=> {
				if (this.toggle != 'choose-game'){
					return false;	
				}
				return true;
				}
			},
			{label: {tc:'設定題目',sc:'设定题目',en:'Set question'}[lang], value:'set-question', onEnter: ()=> {
				if (this.toggle != 'set-game'){
					return false;
				}
				return true;
				}
			}
		];
		if (this.data.data){
			this.modalTitle = {tc:'編輯遊戲',sc:'编辑游戏',en:'Edit game'}[this.lang];
			this.url = this.data.data.url;
			this.name = this.data.data.name;
		} else {
			this.modalTitle = {tc:'建立遊戲',sc:'建立游戏',en:'Add game'}[this.lang];
		}
		this.modalTitle = this.trans.instant(this.modalTitle);
		this.getData();
	}

	ngAfterViewInit() {
		setTimeout(()=>{
			this.modal.open();
		},10);
	}

	ngOnDestroy(): void {
	}

	getData(){
		this.datas.post('ROGame.list').subscribe((res:any)=>{
			let games = res.items.map(game=>{
				let url = location.href.indexOf('localhost') > -1?'//dev.openknowledge.hk':'';
				url = url + '/RainbowOne/webapp/OKAGames/SelectGames/image/' + game.image;
				game.imageUrl = url;
				game.cover = this.sans.bypassSecurityTrustUrl(url);
				game.subitems = res.subitems.filter(e=> e.item_id == game.id);
				game.title = game['title_'+this.lang];
				return game;
			});
			preloadImage(games.map(e=> e.imageUrl)).then(e=>{
				this.games = games;
				this.gameSelectClick(this.games[0]);
			});
		});
	}

	confirm(isConfirm = false){
		if (isConfirm){
			this.data.close(this.data);
			return;
		}
		this.data.close();
	}

	toggleChange(){
		if (this.toggle == 'choose-game'){

		} else if (this.toggle == 'set-game'){
			this.name = this.selectedGame.title;
			if (this.selectedGame.subitems){
				let subitem = this.selectedGame.subitems[0];
				this.name = subitem.title;
			}
		} else if (this.toggle == 'set-question'){

		}
	}

	initSettingForSubitem(subitem){

	}

	gameSelectClick(game){
		this.selectedGame = game;
	}
}
