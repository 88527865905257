import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TagSelectorComponent } from './tagSelector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { StudentSelectorComponent } from './studentSelector.component';

@NgModule({
    imports: [
		CommonModule,TranslateModule,FormsModule, FontAwesomeModule,
		NgSelectModule,
		PerfectScrollbarModule
    ],
	exports:[ TagSelectorComponent, StudentSelectorComponent ],
    declarations: [ TagSelectorComponent, StudentSelectorComponent ],
	entryComponents: [
    ],
	providers:[]
})
export class TagGroupModule { }
