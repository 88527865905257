
var resolveWith = function (value) {
	if (value && typeof value.then === 'function') {
		return value;
	}
	return Promise.resolve(value);
};

interface QueueItem {
  promiseGenerator: () => Promise<any>;
  resolve: (value: any) => void;
  reject: (error: Error) => void;
  notify: (message: any) => void;
}

interface QueueOptions {
  onEmpty?: () => void;
}

export class PromiseQueue {
  private options: QueueOptions;
  private pendingPromises: number;
  private maxPendingPromises: number;
  private maxQueuedPromises: number;
  private queue: QueueItem[];

  constructor(maxPendingPromises: number = Infinity, maxQueuedPromises: number = Infinity, options?: QueueOptions) {
    this.options = options || {};
    this.pendingPromises = 0;
    this.maxPendingPromises = maxPendingPromises;
    this.maxQueuedPromises = maxQueuedPromises;
    this.queue = [];
  }

  static configure(GlobalPromise: PromiseConstructor) {
    Promise = GlobalPromise;
  }

  add(promiseGenerator: () => Promise<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.queue.length >= this.maxQueuedPromises) {
        reject(new Error('Queue limit reached'));
        return;
      }

      this.queue.push({
        promiseGenerator: promiseGenerator,
        resolve: resolve,
        reject: reject,
        notify: (() => {})
      });

      this._dequeue();
    });
  }

  getPendingLength(): number {
    return this.pendingPromises;
  }

  getQueueLength(): number {
    return this.queue.length;
  }

  private _dequeue(): boolean {
    if (this.pendingPromises >= this.maxPendingPromises) {
      return false;
    }

    const item = this.queue.shift();
    if (!item) {
      if (this.options.onEmpty) {
        this.options.onEmpty();
      }
      return false;
    }

    try {
      this.pendingPromises++;

      resolveWith(item.promiseGenerator())
        .then((value) => {
          this.pendingPromises--;
          item.resolve(value);
          this._dequeue();
        }, (err) => {
          this.pendingPromises--;
          item.reject(err);
          this._dequeue();
        }, (message) => {
          item.notify(message);
        });
    } catch (err) {
      this.pendingPromises--;
      item.reject(err);
      this._dequeue();
    }

    return true;
  }
}

// export default Queue;