import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'fileSize'
})

export class FileSizePipe implements PipeTransform {
    transform(size: number): string {
        if (size > 1024 * 1024) {
          return (size / (1024 * 1024)).toFixed(2) + 'MB';
        } else if (size == -1 || !size) {
          return '';
        } else {
          return (size / 1024).toFixed(0) + 'KB';
        }
    }
}
