import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { ROComponent, ROContainerComponent, ROTLFTextComponent } from "./ROComponent";

@Component({
	template:`
		<div *ngIf="showIcon" class="link-icon"></div>

		`
		
		,
	styles:[
		`
		.link-icon{
			background-image:url(./assets/img/component/link_icon.svg);
			width:38px;
			height:38px;
		}
		`
	]
})

export class ROLink extends ROContainerComponent
{
	/**
	{"ver":1.1,"link":"http://www.google.com","showIcon":true,"douid":"A55BC956-B46D-871A-76BF-D26CF0260723","coordinateExpression":"UA UK KH KR X 138 Y 120 D T 120 L 138 H 42 W 200","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"locked":false,"x":138,"y":120}
	{"ver":1.1,"link":"http://www.google.com","showIcon":true,"coordinateExpression":"UA UK KH KR X 139 Y 193 D T 193 L 139 H 42 W 200","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"locked":false,"douid":"55CD920A-05DA-D5F8-0DE9-A3FD81B056A8","target":"external","x":139,"y":193}
	{"ver":1.1,"link":"http://www.google.com","showIcon":false,"coordinateExpression":"UA UK KH KR X 146 Y 273 D T 273 L 146 H 42 W 185.8105919311372","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"locked":false,"target":"external","douid":"CCCE71D6-A040-69EC-2A40-165405EE8816","x":146,"y":273}
	*/
	public link:string;
	public showIcon:boolean;
	public target:string;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	protected buildContent(): void {
		super.buildContent();
		var dom:HTMLElement = this.elementRef.nativeElement;
		var options:any = this.node.attributes;
		this.link = options.link;
		this.showIcon = options.showIcon;
		this.target = options.target;
		dom.setAttribute("title", this.link);
		/*
		var a = document.createElement("a");
		a.innerHTML = "OPEN";
		a.href = this.link;
		a.target = "_blank";
		dom.appendChild(a);
		*/
	}
	public getTagNames():string []
	{
		return ["Link2"];
	}
	decodeHTMLEntities(str) {
		let txt = new DOMParser().parseFromString(str, "text/html");
		return txt.documentElement.textContent;
	}
	

	@HostListener('tap', ['$event'])
	// @HostListener('click', ['$event'])
	onClick(event) {
		// this.link = "https://www.w3schools.com";
		// this.link = "https://ro2.azurewebsites.net/RainbowOne/youtube/?modestbranding=1&version=3&html5=1&videoID=PsZszv4qHu4";
		
		// this.link = "https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
		// this.link = "https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B"
		/*
		this.link = this.decodeHTMLEntities(
			this.link
		);
		
		*/

		// var url:any = this.context.service.domSanitizer.bypassSecurityTrustUrl(this.link);
		// window.open(this.link, "_blank");
		if(this.context.config.viewMode == "edit") return;
		if(!this.context.answerEnabled) return;
		// if(!(this.context.config.viewMode == "preview" || this.context.config.viewMode == "view" )) return;

		if (this.target == "external")
		{
			window.open(this.link, "_blank");
		} else {
			this.context.service.browserService.createFullScreenBrowser(this.link);
		}

	}

}