import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBoxComponent } from './progressBox.component';
import { ProgressBox2Component } from './progressBox2.component';



@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ProgressBoxComponent, ProgressBox2Component
    ],
    exports: [
        ProgressBoxComponent, ProgressBox2Component
    ],
    providers: [
    ],
    entryComponents: [
        ProgressBoxComponent, ProgressBox2Component
    ]
})
export class ProgressBoxModule {
}
