import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './preview.component';
import {TooltipModule} from 'primeng/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        TooltipModule,
        TranslateModule
    ],
    declarations: [
        PreviewComponent
    ],
    exports: [
        PreviewComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class PreviewModule {
}
