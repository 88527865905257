import { NgModule } from '@angular/core';
import { IconBtnComponent } from './iconBtn.component';


@NgModule({
    imports: [
    ],
    declarations: [
        IconBtnComponent
    ],
    exports: [
        IconBtnComponent
    ],
    providers: [],
    bootstrap: []
})
export class IconBtnModule { }
