import { AfterViewInit, ApplicationRef, Component, ElementRef, EventEmitter, HostListener, Injector, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";


//////////////////////////////////////////////////////////////////////////////////////////
@Component({
	
	template:`
	<div class="body">
		<div style="width:100vw;height:80vh">
			<ro-book-preview-component [book]="book" ></ro-book-preview-component>
		</div>
		<button (click)="openBook1()">new_book</button>
		<button (click)="openBook2()">S_Test</button>
		<button (click)="openBook3()">分數組件</button>
		{{book|json}}
	</div>
	`,
	styles:[
		`
		.body{
			background:white;
		}
		`
	]
})

export class ROBookPreviewDemoComponent{
	
	public book:any;
	public book1:any;
	public book2:any;
	public book3:any;
	constructor()
	{
		var singlePageDocID:number = 2151429;
		var multiPageDocID:number = 97578;
		var otherDocID:number = 1622654;

		this.book1 = {id:singlePageDocID};
		this.book2 = {id:multiPageDocID};
		this.book3 = {id:otherDocID};
		
		// this.documentService.open({id:1622654}).then((o:any)=>{
		// 1024 // 768 // 
		// this.openBook({id:singlePageDocID});
		this.book = null;
	}
	public openBook1():void
	{
		this.book = this.book1;
	}
	public openBook2():void
	{
		this.book = this.book2;
	}
	public openBook3():void
	{
		this.book = this.book3;
	}
	
}
