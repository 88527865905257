import { Component, Input } from "@angular/core";

@Component({
	selector: "exercisePractice",
	templateUrl: "./exercisePractice.html",
	styleUrls: ["./exercisePractice.scss"],
})
export class exercisePractice {
	@Input() public parent;
	@Input() public context;
	@Input() public myRecorder;
	@Input() public processing;
	@Input() public ctx;
	@Input() public fontSize;

	@Input() public lang;
	@Input() public subType;
	@Input() public totalPageNumber;
	@Input() public pageIndex;

	@Input() public accuracyScore;
	@Input() public completenessScore;
	@Input() public fluencyScore;
	@Input() public confidence;
	@Input() public pronScore;

	@Input() public displayTopic;
	@Input() public correctAnswer;
	@Input() public userRecordAnswer;
	@Input() public answerChoice;									// ["Just keep quiet...", "Be as loud as..."]
	@Input() public fillInTypeTopic;								// ["Some students need the proper", "fill", "to succeed."]
	@Input() public recognitionWordArray;							// [{Word: "how"...}, {Word: "are"...}, {Word: "you"...}]

	@Input() public isRecorded;
	

	public viewMode;

	ngOnInit() {
		this.viewMode = this.context.config.viewMode;
	}
	
	speak() {
		this.parent.speak();
	}

	record() {
		this.parent.record();
	}

	finishRecording() {
		this.parent.finishRecording();
	}

	play() {
		this.parent.play();
	}

	reTry() {
		this.parent.reTry();
	}

	nextQuestion() {
		this.parent.nextQuestion();
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}
}