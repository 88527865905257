import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { ModalModule } from 'src/app/sharedModule/modalModule/modal.module';
import { ToolTipModule } from 'src/app/sharedModule/toolTipModule/toolTip.module';
import { FormsModule } from '@angular/forms';
import { VkbModule } from 'src/app/sharedModule/vkbModule/vkb.module';
import { NgSelectModule } from '@ng-select/ng-select';


import { DateTimePickerModule } from 'src/app/module/dateTimePicker/dateTimePicker.module';

// ag grid 使用的 component
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PreviewModule } from 'src/app/sharedModule/previewModule/preview.module';
import { BubbleBox2Module } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.module';
//import { WhitePopupModule } from 'src/app/sharedModule/whitePopupModule/whitePopup.module';
// ag grid cell component
import { CustomAgGridModule } from 'src/app/sharedModule/customAgGridModule/customAgGrid.module';

import { UiSwitchModule } from 'ngx-ui-switch';

import { AssessmentEditNoRouterComponent } from './assessmentEditNoRouter.component';
import { AssessmentViewerModule } from 'src/app/sharedModule/AssessmentViewerModule/AssessmentViewer.module';
import { CreateShareModule } from 'src/app/sharedModule/createShareModule/create-share.module';
import { Modal2Module } from 'src/app/sharedModule/modal2Module/modal2.module';
import { FileFormatterModule } from 'src/app/sharedModule/customAgGridModule/fileformatter.module';
import { SelectionPanelModule } from 'src/app/sharedModule/selectionPanelModule/selectionPanel.module';
import { SubjectModule } from 'src/app/sharedModule/subjectModule/subject.module';
import { LanguageFileSetting, TranslateModuleLoader } from 'src/app/common/TranslateModuleLoader';
import { HeaderIconBtnModule } from 'src/app/sharedModule/headerIconBtnModule/headerIconBtn.module';
import { NavBarModule } from 'src/app/sharedModule/navBarModule/navBar.module';
import { WhitePopupModule } from 'src/app/sharedModule/whitePopupModule/whitePopup.module';
//import { CreateShare2Module } from 'src/app/sharedModule/createShareModule/create-share2.module';
import { QuestionBankEditGridModule } from 'src/app/coreModule/QuestionBankModule/QuestionBankEditGrid.module';
import { NumberStepperModule } from 'src/app/sharedModule/numberStepperModule/numberStepper.module';
////////////////////////////////////////////////////////////////////////////////////////////////////////////

@NgModule({
	imports: [
	CommonModule,FormsModule,
        TranslateModuleLoader.forRoot(),

		UiSwitchModule,
		NgSelectModule,
		
		ModalModule,

		NavBarModule,HeaderIconBtnModule,
		
		ToolTipModule,
		VkbModule,
		DateTimePickerModule,

		SelectionPanelModule, 
		FontAwesomeModule,
		SubjectModule,
		AgGridModule,CustomAgGridModule,
		FileFormatterModule,
		PreviewModule,WhitePopupModule,

		BubbleBox2Module,NumberStepperModule,
		AssessmentViewerModule, CreateShareModule, Modal2Module,
		QuestionBankEditGridModule
	],
	declarations: [
		AssessmentEditNoRouterComponent
	],
	exports: [ AssessmentEditNoRouterComponent ],
	providers: [
        {
			provide: LanguageFileSetting, 
			useValue: {
				basePath:"./assets/langs/",
				languages:["home-screen", "subjectSelector3", "questionbank"]
			}
		}
    ],
	entryComponents: []
})
export class AssessmentEditNoRouterModule {}
