import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";

@Component({
	selector:"ROInfo",
	template:`
	<button (click)="log()">🛈</button>
		
`,
	styles:[``]
})
export class ROInfo 
{
	@Input() public info:any;

	constructor(elementRef:ElementRef)
	{
		
	}

	log()
	{
		console.log(this.info);
	}
	
	
}