export class CharacterType
{
	static SYMBOL:string =
		" !\"#$%&'()*,./:;<>?@[]^_`{|}~—“”"+
		"：　。《》「」︿！＂＃＄％＆＇（）＊＋，／＜＝＞？＠［＼］＿｛｜｝" +
		"\r\n\t" + decodeURIComponent('%E2%80%A8');
	
		
	static SYMBOL2:string = " \n\r\t<>?:\"{}~!@#$%^&*()_+,./'[]`-=;\\|├　─　┼　┴　┬　┤　┌　┐　╞　═　╪　╡　│　▕　└　┘　╭　╮　╰　╯╔╦╗　╠═╬╣　╓╥╖　╒╤╕　║╚╩╝╟╫╢　╙╨╜　╞╪╡　╘╧╛°○¤　●◎⊕⊙　▪　▫◘　◙　☼☺☻﹣　﹦　≡　｜　∣　∥　–　︱　—　︳　╴¯　￣　﹉　﹊　﹍　﹎　﹋　﹌　﹏　︴　﹨　∕　╲　╱　＼　／　↑　↓←　→　↖　↗　↙　↘〔　〕　【　】　﹝　﹞〈　〉﹙　﹚　《　》　（　）｛　｝　﹛　﹜『　』　「　」　＜　＞　≦　≧　﹤　﹥︵　︶　︷　︸　︹　︺　︻　︼︽　︾　︿　﹀　∩　∪　﹁　﹂　﹃　﹄＿　ˍ　▁　▂　▃　▄　▅　▆　▇　█　▏　▎　▍　▌　▋　▊　▉　◢　◣　◥　◤　►　◄▣　▤　▥　▦　▧　▨　▩　▒　░㊣　㊟　㊕　㊗　㊡　㊝①　②　③　④　⑤　⑥　⑦　⑧　⑨　⑩　⑪　⑫　⑬　⑭　⑮　⑯　⑰　⑱　⑲　⑳⑴　⑵　⑶　⑷　⑸　⑹　⑺　⑻　⑼　⑽㈠　㈡　㈢　㈣　㈤　㈥　㈦　㈧　㈨　㈩Ⅰ　Ⅱ　Ⅲ　Ⅳ　Ⅴ　Ⅵ　Ⅶ　Ⅷ　Ⅸ　Ⅹ　Ⅺ　Ⅻⓐ　ⓑ　ⓒ　ⓓ　ⓔ　ⓕ　ⓖ　ⓗ　ⓘ　ⓙ　ⓚ　ⓛ　ⓜ　ⓝⓞ　ⓟ　ⓠ　ⓡ　ⓢ　ⓣ　ⓤ　ⓥ　ⓦ　ⓧ　ⓨ　ⓩ⒜　⒝　⒞　⒟　⒠　⒡　⒢　⒣　⒤　⒥　⒦　⒧　⒨　⒩⒪　⒫　⒬　⒭　⒮　⒯　⒰　⒱　⒲　⒳　⒴　⒵　。，「、」：？！“”…";
		
	static END_OF_SENTENCE_SYMBOL:string = "；;。.！!？?";
	
	
	static isSpace(text:string):boolean
	{
		return /[ \r\n\t　＿]/.test(text);
	}
	
	

	static isAllSpace(item:string):boolean
	{
		return /^[\s]{1,}$/.test(item);
	}
	
	static foundInString(item:string, searchText:string, foundAll:boolean = false):boolean
	{
		if (item.length == 1)
		{
			return searchText.indexOf(item) != -1;
		} else {
			var flip:boolean = !foundAll;
			for(var i:number = 0; i < item.length; i++)
			{
				var char:string = item.charAt(i);
				var found:boolean = searchText.indexOf(char) != -1;
				if (found == flip)
				{
					return flip;
				}
			}
			return foundAll;
		}
	}
	
	
	static isSymbol(item:string, foundAll:boolean):boolean
	{
		return CharacterType.foundInString(
			item, CharacterType.SYMBOL, foundAll
		) || CharacterType.foundInString(
			item, CharacterType.SYMBOL2, foundAll
		) ;
	}
	
	
	static isEndOfSentenceSymbol(symbol:string):boolean
	{
		return CharacterType.foundInString(
			symbol, 
			CharacterType.END_OF_SENTENCE_SYMBOL, 
			false
		);
	}
	
	
}

