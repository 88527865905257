import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import {  ROComponent, ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";
import { DOMHelper } from "src/app/common/DOMHelper";

@Component({
	// template:`Ball{{node.attributes|json}}`
	template:`
		<svg overflow="visible"
			class="photo-hunt"
			width="100%" height="100%"
			
			(click)="onTap($event)"
			>
			<defs>
				<image 
					id="photo_hunt_answer"
					href="/assets/ro/component/photo_hunt_answer.svg"
					class="answer2"
					x="0" y="0"
				/>
			</defs>
			<rect width="100%" height="100%" fill="transparent" />
			<g>
				<use xlink:href="#photo_hunt_answer"
					class="answer"
					*ngFor="let circle of circles" 
					[attr.x]="circle.cx" 
					[attr.y]="circle.cy" 
					[attr.width]="circle.rx" 
					[attr.height]="circle.ry"
					[class.active]="circle.show"
					stroke="red"
					stroke-width="11"
					(click)="onSelectCircle(circle)"
					/>
			</g>
			<g>
				<circle 
					*ngFor="let c of list" 
					[attr.cx]="c.x" [attr.cy]="c.y" r="10" fill="blue"/>
			</g>
			
		</svg>
		`,
		// 
		//url(~/assets/ro/app_icons/app_data_mode.svg);
	styles:[
		// 	[attr.style]="show ? 'fill:yellow;stroke:purple;stroke-width:2' : 'fill:blue;stroke:purple;stroke-width:2' " 
		
		`
			.answer2{
				
			}
			.photo-hunt .answer{
				fill:blue;
			}
			.photo-hunt .answer.active{
				fill:yellow;
			}
		`
	]
	
})
export class ROPhotoHuntComponent extends ROComponent
{
	public circles: any[];
	public list:any [];
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.init();
		this.initEventListener();
	}

	init()
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.background = "red";
		dom.style.border = "solid 1px blue";
		this.list = [
			{
				x:1,
				y:1
			},
			{
				x:100,
				y:100
			},
			{
				x:200,
				y:200
			},
			{
				x:300,
				y:300
			}
		]
	}

	initEventListener()
	{
		// var dom:HTMLElement = this.elementRef.nativeElement;
		// dom.addEventListener("tap", this.onTap.bind(this));
		// {"payload":{"queryType":"assessment","startTime":"2024-05-07 17:01:40","title":"20240510 sol_test3.docx","messageType":"complete","entryType":"assessment","path":[21036,114676,2826288],"section":"cloud-share","entry_id":2826400}}
		
	}
	private selectedCircle:any = null;
	onSelectCircle(circle:any)
	{
		this.selectedCircle = circle;
	}
	onTap(event:PointerEvent):void
	{
		// console.log(event, this.selectedCircle);

		
		
		// console.log(event.target, event.currentTarget);
		var dom:HTMLElement = this.elementRef.nativeElement;
		// var rect:any = dom.getBoundingClientRect();
		var gp = {
			x:event.pageX,
			y:event.pageY
		};
		// this.list.push();
		// this.list.push(pt);
		var ref:any = DOMHelper.getDOMGlobalPosition(dom);
		// { left:rect.x, top: rect.y, scale:_scale}
		var localPoint = {
			x:(gp.x - ref.left) / ref.scale,
			y:(gp.y - ref.top ) / ref.scale
		};

		if(this.selectedCircle)
		{
			this.selectedCircle.show = true;
			// event.stopImmediatePropagation();
			
		} else {
			
			if(localPoint.x < 0 ) localPoint.x = 0;
			if(localPoint.y < 0 ) localPoint.y = 0;
			
			if(localPoint.x > this.w ) localPoint.x = this.w;
			if(localPoint.y > this.h ) localPoint.y = this.h;
			this.list = [localPoint];
		}
		this.selectedCircle = null;
	}
	
	public getTagNames():string []
	{
		return ["PhotoHunt"];
	}
	private map(m:any, names:string[])
	{
		var output:any = {};
		names.forEach((name:string, index:number)=>{
			if(name && m[index] !== undefined)
			{
				output[name] = m[index];
			}
		});
		return output;
	}
	protected initVariables():void
	{
		super.initVariables();
		console.log("initVariables");
	}
	
	protected buildChildren():void
	{
		console.log("buildChildren");
	}

	protected buildContent():void
	{
		this.initCircles();
	}

	private initCircles():void
	{
		var names:string [] = ["o", "type", "value", "type"];
		this.circles = this.node.children.map((node:XMLNode)=>{
			var reg:RegExp = /([A-z]{1,})\s{1,}([\-]{0,1}[0-9.]{1,})|([A-z]{1,})/g
			var attr:any = node.attributes;
			var mArray:any = ArrayUtils.exec(reg, attr.coordinateExpression);
			var position:any = {};
			mArray.map((m:any)=>{
				var info = this.map(m, names);
				if(info.type)
				{
					if(info.value)
					{
						position[info.type] = parseFloat(info.value);
					} else {
						position[info.type] = true;
					}
				}
			});
			var rx = position.W /2;
			var ry = position.H /2;
			var cx = position.X + rx;
			var cy = position.Y + ry;
			return {
				show:false,
				cx:cx,
				cy:cy,
				rx:rx,
				ry:ry
			}
		})
	}
	
}