import { Component, Input, OnInit, ViewChild,Renderer2, Output, EventEmitter} from '@angular/core';
import { StorageService } from 'src/app/service/storage.service';
import { environment } from 'src/environments/environment';
import { faArrowAltCircleDown } from '@fortawesome/pro-regular-svg-icons';
import { faPlay, faPause } from '@fortawesome/pro-solid-svg-icons';
import { isObject } from 'util';

@Component({
  selector: 'audio-player',
  template: `
  <div class="container">
    <audio #audio [src]="filePath" preload="auto">
      <source [src]="filePath" [attr.type]="fileType"/>
    </audio>
    <div class="audio-button" [ngClass]="{'download':!allowGet,'downloading':allowGet && !downloaded,'control':downloaded}" (click)="buttonClick(audio,current)">
      <fa-icon class="fa-play" [icon]="faPlay" size="lg" *ngIf="!allowGet || (downloaded && status == 'pause')"></fa-icon>
      <fa-icon #pauseButton class="fa-pause" [icon]="faPause" size="lg" *ngIf="downloaded && status == 'play'"></fa-icon>
	  <ring-loading *ngIf="(allowGet && !downloaded)"></ring-loading>
	</div>
    <div class="progress" [ngClass]="(downloaded?'downloaded':'')" >
      <div #current class="current">
        <div class="current__dot"></div>
      </div>
      <div class="duration" *ngIf="length">{{formatDuration(length)}}</div>
      <!-- <div class="size" *ngIf="!downloaded && fileSize">{{fileSize|fileSize}}</div>-->
    </div>
    <!-- <input type="hidden" [(ngModel)]="storage.voiceInfo[msg.id]" /> -->
  </div>
  `,
  styleUrls: ['./audio-player.component.scss']
})

export class AudioPlayerComponent implements OnInit{
  @Input() msg;
  @ViewChild('audio',{static: true}) audio;
  @ViewChild('current',{static: false}) current;
  @Output() teacherSpeaking: EventEmitter<any> = new EventEmitter();

  public status = 'notDownload';
  public fileType = 'audio/mpeg';
  public allowGet = false;
  public downloaded = false;
  public filePath;
  public fileSize;
  public length;
  public audioObj;
  private isMine: boolean;
  public faPlay = faPlay;
  public faPause = faPause;
  public faArrowAltCircleDown = faArrowAltCircleDown;
  constructor(public renderer:Renderer2,public storage:StorageService) {}

  //forDemo
  // -------------------------------------------------------------------------------

	@ViewChild('pauseButton', {static: false}) pauseButton!: any;
	@Output() childStatusChange = new EventEmitter<boolean>();

	ngAfterViewInit() {
		if (this.pauseButton) {
		this.pauseButton.statusChanged.subscribe((status: boolean) => {
			// Handle the status change of the child component here
			console.log('Child status changed:', status);
			this.childStatusChange.emit(status);
		});
		}
	}

  //---------------------------------------------------

  ngOnInit(){
    if (!isObject(this.msg.data)){
      this.msg.data = JSON.parse(this.msg.data);
    }
    this.filePath = this.msg.data.path;
    if (!this.filePath){
      setTimeout(()=>{this.msg.deleted = 1;},10);
    } else if (this.filePath.indexOf('chat/') > -1){
      this.filePath = '//oka.blob.core.windows.net/media/' + this.filePath;
    } else if (this.filePath.indexOf('tmp_upload') > -1){
      if (location.hostname.indexOf('localhost') > -1){
        this.filePath = '//ro2.azurewebsites.net/RainbowOne/' + this.filePath;
      } else {
        this.filePath = '//'+ location.hostname +'/RainbowOne/' + this.filePath;
      }
    }
	this.audio.nativeElement.onloadedmetadata = () => {
		this.length = this.audio.nativeElement.duration;
	  };
	  
    this.fileSize = this.msg.data.size;
    this.isMine = (this.msg.author == this.storage.uid);
  }

  public formatDuration(durationInSeconds: number): string {
	const minutes = Math.floor(durationInSeconds / 60);
	const seconds = Math.floor(durationInSeconds % 60);
	const formattedMinutes = String(minutes).padStart(2, '0');
	const formattedSeconds = String(seconds).padStart(2, '0');
	return `${formattedMinutes}:${formattedSeconds}`;
  }

  public control(el, current) {
    this.status = (this.status == 'pause'?'play':'pause');
    console.log(el);
    if (this.status == 'play') {
      el.play();
		if(this.msg && this.msg.author == 0){
			this.teacherSpeaking.emit(true);
		}

      this.setPosition(el,current);
    } else {
      el.pause();
		this.teacherSpeaking.emit(false);

    }
  }

  public buttonClick(audio, current) {
    if (!this.downloaded) {
      this.getFile();
    } else {
      this.control(this.audio.nativeElement,current)
    }
  }

  public setPosition(audio,current){
	const maxWidth = 160;
    setTimeout(()=>{
      let pos:number = audio.currentTime/audio.duration * maxWidth;
      if (audio.duration > 0 && !audio.paused && pos < maxWidth){
        current.style.left = pos + 'px';
        this.setPosition(audio,current);
      } else if (audio.currentTime >= audio.duration || pos >= maxWidth){
		this.teacherSpeaking.emit(false);
        this.status = 'pause';
        pos = 0;
        current.style.left = '0px';
        this.audio.nativeElement.pause();
      }
    },500);
  }

  fastMove($event, audio, current) {
    if (!this.downloaded || audio == null) {
      return;
    }
    let durationPos = 133;
    if (this.isMine) {
      durationPos = document.body.offsetWidth - 185;
    }
    let pt: number = ($event.clientX - durationPos) / 70;
    if (audio.paused){
      audio.currentTime = audio.duration * pt;
      current.style.left = (pt * 70) + 'px';
    } else {
      audio.currentTime = audio.duration * pt;
    }
  }

  getFile() {
    this.allowGet = true;
    const current = this.current.nativeElement;
    let timeout$ = setTimeout(() => {
      this.audioObj = this.audio.nativeElement;
      this.renderer.listen(this.audio.nativeElement, 'loadedmetadata', (event) => {
        this.audioObj = this.audio.nativeElement;
        this.downloaded = location.href.indexOf('localhost') > -1 || true; //=true when prod mode
        this.status = 'play';
        this.audioObj.play();
        this.setPosition(this.audioObj, current);
      });
    }, 500);
    setTimeout(()=>{
      this.audioObj = this.audio.nativeElement;
      if (this.audio){
        this.downloaded = true;
        this.status = 'play';
        this.audioObj.play();
		if(this.msg && this.msg.author == 0){
			this.teacherSpeaking.emit(true);
		}
        this.setPosition(this.audioObj, current);
      }
    },2000);
  }
}
