import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateTimePicker3Component } from './dateTimePicker3.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateTimePicker3SelectMonthComponent } from './dateTimePicker3SelectMonth.component';
import { DateTimePicker3SelectDateComponent } from './dateTimePicker3SelectDate.component';
import { DateTimePicker3SelectTimeComponent } from './dateTimePicker3SelectTime.component';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { TranslateModule } from '@ngx-translate/core';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { DateTimePicker3SelectYearComponent } from './dateTimePicker3SelectYear.component';
import { RadioBoxModule } from '../radioBoxModule/radioBox.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BubbleBox2Module,
    FontAwesomeModule,
	TranslateModule,
	RadioBoxModule,
	OkaPulldownModule
  ],
  declarations: [
    DateTimePicker3Component,
    DateTimePicker3SelectTimeComponent,
    DateTimePicker3SelectDateComponent,
    DateTimePicker3SelectMonthComponent,
	DateTimePicker3SelectYearComponent
  ],
  exports: [
    DateTimePicker3Component
  ],
  providers: [],
  entryComponents: []
})
export class DateTimePicker3Module {
}
