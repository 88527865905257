import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NetworkCheckComponent } from './network-check.component';
import { LoadingModule } from '../loadingModule/loading.module';
import { MomentPipe } from './moment.pipe';
import { SpeedTestService } from './speedtest.service';
import { ScriptService } from 'src/app/service/script.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
		CommonModule,TranslateModule,LoadingModule, FontAwesomeModule
    ],
    declarations: [
        NetworkCheckComponent, MomentPipe
    ],
    exports: [
        NetworkCheckComponent
    ],
    providers: [SpeedTestService, ScriptService],
    bootstrap: []
})
export class NetworkCheckModule { }
