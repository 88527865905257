import { Component, HostListener, Output, EventEmitter, Input, ElementRef, OnInit } from '@angular/core';
import { faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: "share-tag-selector",
  template: `
  <a class="edit-target" (click)="editTargetClick($event)">
    <ng-container *ngIf="confirmedSelectItems.length > 0">
      <div [ngClass]="['edit-target__text',(modalData.disabled?'disabled':'')]">
        <span>
          <ng-container *ngFor="let tag of confirmedSelectItems; let last = last;">{{tag.title}}<ng-container *ngIf="!last">,</ng-container></ng-container>
        </span>
      </div>
    </ng-container>
    <div [ngClass]="['edit-target__text',(modalData.disabled?'disabled':'')]" *ngIf="confirmedSelectItems.length == 0">
      <span>{{'workspace.pls-select'|translate}}</span>
    </div>
  </a>
  <div class="dropdown" *ngIf="isOn">
    <div class="triangle"></div>
    <div class="left">
      <div class="searchBox">
          <input placeholder="{{'workspace.please-enter-keywords' | translate}}" [(ngModel)]="searchText">
          <div [ngClass]="['icon',searchText==''?'zoom':'close']" (click)="searchText=''"></div>
      </div>
      <div *ngIf="!searchText" class="tagRowContainer">
        <perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
          <div class="option-row group-row">
            <fa-icon class="open" [icon]="(myTagOpen ? faChevronDown : faChevronRight)" (click)="myTagOpen = !myTagOpen"></fa-icon>
            <fa-icon class="check" [class.selected]="isMyTagsSelected" [icon]="(isMyTagsSelected?faCheckCircle:faCheckCircleLight)" (click)="checkClick(null,'my-tag')"></fa-icon>
            <span class="my-tag" (click)="checkClick(null,'my-tag')">{{'workspace.my-group'|translate}}</span>
            <div *ngIf="myTagOpen" class="select-all-child" (click)="checkClick(null,'my-tag')">{{'workspace.select-all-child'|translate}}</div>
          </div>
          <ng-container *ngIf="myTagOpen">
            <ng-container *ngFor="let tag of myTags">
              <div class="option-row tag-row">
                <fa-icon class="point" [icon]="faCircle"></fa-icon>
                <fa-icon class="check" [class.selected]="isSelected(tag,'tag')" [icon]="(isSelected(tag,'tag')?faCheckCircle:faCheckCircleLight)" (click)="checkClick(tag,'tag')"></fa-icon>
                <span class="my-tag" (click)="checkClick(tag,'tag')">{{tag.title}}</span>
              </div>
            </ng-container>
            <div class="option-row tag-row" *ngIf="myTags.length == 0">
              <fa-icon class="point" [icon]="faCircle"></fa-icon>
              <span>{{'workspace.no-tag'|translate}}</span>
            </div>
          </ng-container>
          <div class="line"></div>
          <ng-container *ngFor="let group of groups; let first=first;">
            <div class="option-row group-row">
              <fa-icon class="open" [icon]="(group.open ? faChevronDown : faChevronRight)" (click)="group.open = !group.open"></fa-icon>
              <!--<fa-icon class="check" [class.selected]="isSelected(group,'tag')" [icon]="faCheck" (click)="checkClick(group,'group')"></fa-icon>-->
              <span>{{group.title}}</span>
              <div *ngIf="group.open" class="select-all-child" (click)="selectAllChild(group,'tag')">{{'workspace.select-all-child'|translate}}</div>
            </div>
            <ng-container *ngIf="group.open">
              <ng-container *ngFor="let tag of group.tags">
                <div class="option-row tag-row">
                  <fa-icon class="point" [icon]="faCircle"></fa-icon>
                  <fa-icon class="check" [class.selected]="isSelected(tag,'tag')" [icon]="(isSelected(tag,'tag')?faCheckCircle:faCheckCircleLight)" (click)="checkClick(tag,'tag')"></fa-icon>
                  <span (click)="checkClick(tag,'tag')">{{tag.title}}</span>
                </div>
              </ng-container>
              <div class="option-row tag-row" *ngIf="group.tags.length == 0">
                <fa-icon class="point" [icon]="faCircle"></fa-icon>
                <span>{{'workspace.no-tag'|translate}}</span>
              </div>
            </ng-container>
          </ng-container>
          <div class="option-row group-row">
            <fa-icon class="open" [icon]="(otherTagOpen ? faChevronDown : faChevronRight)" (click)="otherTagOpen = !otherTagOpen"></fa-icon>
            <span>{{'workspace.other-group'|translate}}</span>
          </div>
          <ng-container *ngIf="otherTagOpen">
            <ng-container *ngFor="let tag of otherTags">
              <div class="option-row tag-row">
                <fa-icon class="open" [icon]="(tag.open ? faChevronDown : faChevronRight)" (click)="tagOpenClick(tag)"></fa-icon>
                <fa-icon class="check" [class.selected]="isSelected(tag,'tag')" [icon]="(isSelected(tag,'tag')?faCheckCircle:faCheckCircleLight)" (click)="checkClick(tag,'tag')"></fa-icon>
                <span (click)="checkClick(tag,'tag')">{{tag.title}}</span>
              </div>
            </ng-container>
            <div class="option-row tag-row" *ngIf="otherTags.length == 0">
              <fa-icon class="point" [icon]="faCircle"></fa-icon>
              <span>{{'workspace.no-tag'|translate}}</span>
            </div>
          </ng-container>
        </perfect-scrollbar>
      </div>

    <div *ngIf="searchText" class="tagRowContainer">
      <perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
        <ng-container *ngFor="let tag of searchedItems">
          <div [ngClass]="['option-row']" >
              <fa-icon class="point" [icon]="faCircle"></fa-icon>
              <fa-icon class="check" [class.selected]="isSelected(tag, tag.uid?'uid':'tag')" [icon]="(isSelected(tag, tag.uid?'uid':'tag')?faCheckCircle:faCheckCircleLight)" (click)="checkClick(tag,tag.uid?'uid':'tag')"></fa-icon>
              <span (click)="checkClick(tag,tag.uid?'uid':'tag')">{{tag.title}}</span>
          </div>
        </ng-container>
      </perfect-scrollbar>
    </div>
    <div class="year">
      <div class="year__logo"></div>
      <ng-select class="workspace-tag-year" [items]="storage.schoolYears" bindLabel="title" [(ngModel)]="modalData.schoolYear" [disabled]="false" [closeOnSelect]="true" name="treatment" [clearable]="false" [searchable]="false" (change)="tagYearChange($event)">
        <ng-template ng-header-tmp>{{'redeem.year'|translate}}:</ng-template>
        <ng-template ng-label-tmp let-item="item">{{(item.title)|translate}}</ng-template>
        <ng-template ng-option-tmp let-item="item">{{(item.title)|translate}}</ng-template>
      </ng-select>
    </div>
  </div>
  <div class="right">
    <div class="label">
      <span>{{'workspace.selected' | translate}} ({{selectedItems.length > 0? selectedItems.length:0}}):</span>
      <div class="clear-all" (click)="checkClick()">{{'workspace.clear-all'|translate}}</div>
    </div>
    <div class="selectedList">
      <perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
        <div class="selectedItem" *ngFor="let item of selectedItems;let index=index;">
          <div [ngClass]="['icon', item.tagType == 3?'tag':'group']" *ngIf="item.target_type == 'tag'"></div>
          <div class="icon people" [style.backgroundImage]="item.photo" *ngIf="item.target_type == 'uid'"></div>
          <div class="text">
            <ng-container *ngIf="item.target_type == 'tag'">
              <span class="text__title" *ngIf="item.tagType == 3">{{item.title}}</span>
              <ng-container *ngIf="item.tagType == 9">
                <div class="text__title group">
                  <span>{{item.title}}</span>
                </div>
                <div class="child-tag">
                  <span>(</span>
                  <div class="child-tag__icon"></div>
                  <span class="child-tag__text">{{item.tagsTitle}}</span>
                  <span>)</span>
                </div>
              </ng-container>
            </ng-container>
            <span class="text__title" *ngIf="item.target_type == 'uid'">{{item.title}}</span>
          </div>
          <fa-icon class="trash" [icon]="faTrash" (click)="checkClick(item, item.target_type)"></fa-icon>
        </div>
      </perfect-scrollbar>

	<div *ngIf="multiSelect==false" class="notice">
		<div class="image"></div>
		<div class="text">{{'workspace.single-subject-only'|translate}}</div>
	</div>
    </div>
	<div class="button-panel">
		<div class="confirm" (click)="confirmClick()">{{'workspace.confirm'|translate}}</div>
		<div class="cancel" (click)="layerClick($event)">{{'workspace.cancel'|translate}}</div>
	</div>
  </div>
</div>
<div class="layer" id="layer" *ngIf="isOn" (click)="layerClick($event)">

</div>
    `,
  styleUrls: ['./share-tag-selector.component.scss']
})


export class ShareTagSelectorComponent implements OnInit {
	@Input() public buttonStyle:string = "text";
	@Input() public multiSelect:boolean = true;

  @Input() public modalData;
  @Input() public field;
  @Input() public options:any = {};
  private compModalData;
  public faSearch = faSearch;
  public faCheck = faCheck;
  public faCircle = faCircle;
  public faTrash = faTrash;
  public faChevronRight = faChevronRight;
  public faChevronDown = faChevronDown;
  public faTimes = faTimes;
  public faCheckCircle = faCheckCircle;
  public faCheckCircleLight = faCheckCircleLight;
  public isTagLoaded: boolean = false;

  public searchText: string = "";
  public myTags: any[] = [];
  public otherTags: any[] = [];
  public groups: any[] = [];
  public members: any[] = [];
  public isForAll: boolean = false;
  public isOn = false;
  public year: any = null;
  public myTagOpen = false;
  public otherTagOpen = false;

  constructor(public storage: StorageService, private translate: TranslateService, public datas: DataService) {
  }

  ngOnInit() {
	this.compModalData = JSON.parse(JSON.stringify(this.modalData));
    this.initTags(this.compModalData);
  }

  editTargetClick($event) {
    $event.stopPropagation();
    if (!this.modalData.disabled){
      this.initTags(this.modalData);
      this.setOnOff();
    }
  }

  layerClick($event) {
    $event.stopPropagation();
    this.setOnOff();
  }

  confirmClick(){
//	this.modalData = JSON.parse(JSON.stringify(this.compModalData));
	if(this.modalData.targets && this.modalData.targets.length>0)
		this.modalData.targets.splice(0,this.modalData.targets.length);
	else
		this.modalData.targets = [];
	this.compModalData.targets.forEach(e =>{
		this.modalData.targets.push(e);
	});
	this.setOnOff();
  }

  setOnOff(){
    this.isOn = !this.isOn;
    const el = <HTMLElement>document.querySelector('.share-modal');
    if (this.isOn){
      el.classList.add('tag-open');
    } else {
      el.classList.remove('tag-open');
    }
  }

  initTags(modalData) {
    this.myTags = modalData.schoolYear.tags.filter(t=> t.my_tag == 1 && t.type == 3).map(e=>{
      let tag:any = {id: e.id, title: e.title, type: 3,school_year: e.school_year, open: false};
      return tag;
    });
    this.groups = modalData.schoolYear.tagClass.map(e=>{
      let group:any = {id: e.id, title: e.title, type: 9, school_year: e.school_year, open: false};
      group.tags = e.tags.map(t=>{
        let tag:any = {id: t.id, title: t.title, type: 3,school_year: t.school_year};
        tag.selected = modalData.targets.find(p=> p.id == t.id)?1:0;
        return tag;
      })
      return group;
    });
    this.otherTags = modalData.schoolYear.tags.filter(t=> t.type == 3 && !this.groups.find(g=> g.id == t.pid)).map(e=>{
      let tag:any = {id: e.id, title: e.title, type: 3, school_year: e.school_year, open: false};
      return tag;
    });
  }

  checkClick(item = null, type = null){
    const modalData = this.compModalData;
    if (item == null && type == null){
      modalData.targets = modalData.targets.filter(e=> e.school_year != modalData.schoolYear.id);
      return;
    } else if (type == 'my-tag'){
      if (this.isMyTagsSelected){
        modalData.targets = modalData.targets.filter(e=> {
          if (e.target_type == 'uid'){
            return true;
          }
          return !this.myTags.find(t=> t.id == e.target);
        });
      } else {
        this.myTags.forEach(t=>{
          if (!modalData.targets.find(p=>p.target_type == 'tag' && p.target == t.id)){
            let obj:any = {title: t.title, target: t.id, tagType: 3, target_type: 'tag', school_year: modalData.schoolYear.id};
            modalData.targets.push(obj);
          }
        });
      }
      return;
    }
    const field = this.field;//i forget reason
    let target = item.target?item.target:item.id;
    if (!target && item.uid){
      target = item.uid;
    }
    const targetType = type == 'uid'?type:'tag';
    const found = modalData.targets.find(e=> e.target == target && e.target_type == targetType && e.school_year == modalData.schoolYear.id);
    if (found){
      const index = modalData.targets.indexOf(found);
      modalData.targets.splice(index, 1);
    } else {
      let obj:any = {title: item.title, target: target, target_type: targetType, school_year: modalData.schoolYear.id };
      if (targetType == 'tag'){
        obj.tagType = item.type;
        if (item.type == 9){
          obj.tagsTitle = item.tags.map(t=>t.title).join(',');
        }
      } else {
        obj.photo = item.photo;
      }
      modalData.targets.push(obj);
    }

  }

  isSelected(item, type){
	let modalData = this.compModalData;
    if (type == 'tag'){
      return !!modalData.targets.find(e=> e.target_type == 'tag' && e.target == item.id && e.school_year == modalData.schoolYear.id);
    } else if (type == 'uid'){
      return !!modalData.targets.find(e=> e.target_type == 'uid' && e.target == item.uid && e.school_year == modalData.schoolYear.id);
    }
  }

  get selectedItems(){
    const modalData = this.compModalData;
    return modalData.targets.filter(e=> e.school_year == modalData.schoolYear.id);
  }

  get confirmedSelectItems(){
	const modalData = this.modalData;
    return modalData.targets.filter(e=> e.school_year == modalData.schoolYear.id);
  }

  get searchedItems(){
    const text = this.searchText;
    let items = this.myTags.filter(t=> t.title.indexOf(text) > -1);
    this.groups.forEach(t=>{
      if (t.title.indexOf(text) > -1){
        items.push(t);
      }
    });
    this.otherTags.forEach(t=>{
      if (t.title.indexOf(text) > -1 && !items.find(e=> e.id == t.id)){
        items.push(t);
      }
    });
    return items;
  }

  get isMyTagsSelected(){
    let num = 0;
    this.compModalData.targets.forEach(e=>{
      if (e.target_type == 'tag' && this.myTags.find(t=> t.id == e.target)){
        num++;
      }
    });
    return num == this.myTags.length && num > 0;
  }

  public tagYearChange($event) {
    console.log(this, $event);
    this.compModalData.targets = [];
    this.initTags(this.compModalData);
  }

  tagOpenClick(tag){
    if (tag.members){
      tag.open = !tag.open;
    } else {
      this.datas.post2({data: {api: 'ROWorkspace.get_tag_people',json:[tag.id, 'all']}}).then(res=>{
        tag.members = this.storage.peoples.filter(t=> res.uids.find(r=> r.uid == t.uid)).map(e=>{
          let member = {title: this.datas.lang == 'en'?e.ename: e.cname, uid: e.uid, photo: e.photo, user_role: e.user_role};
          return member;
        });
        tag.open = !tag.open;
      });
    }
  }

  selectAllChild(tag,childType){
    console.log(tag,childType);
    if (childType == 'tag'){
      tag.tags.forEach(t=>{
        this.checkClick(t,'tag');
      });
    } else if (childType == 'member'){
      tag.members.forEach(m=>{
        this.checkClick(m,'uid');
      });
    }
  }

}
