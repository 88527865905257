import { Component, HostListener, NgZone, ViewChild, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { CommonService } from 'src/app/service/common.service';
declare var Chart;

@Component({
	selector: 'p-box-plot-chart',
	template: `
	<div class="card" *ngIf="chartData.scoredComponents.length > 0">
		<div class="panel" [ngClass]="theme.theme$.value + '-theme'">
			<ng-container *ngIf="chartDatasets">
				<div class="item">
					<div class="item-box" [style.backgroundColor]="chartDatasets[0].borderColor"></div>
					<div class="item-label">{{chartDatasets[0].label}}</div>
				</div>
				<div class="item">
					<div class="item-box personal"></div>
					<div class="item-label">{{ chartData.studentName }}</div>
				</div>
			</ng-container>
		</div>
		<div class="body">
			
			<div class="chart-wrapper">
				<canvas #canvas></canvas>
			</div>
		</div>
	</div>
	<div class="no-data" *ngIf="chartData.scoredComponents.length == 0">
		<div class="no-data-image"></div>
		<div class="no-data-text">{{ {tc:'未有題目',sc:'未有题目',en:'No question'}[datas.lang] }}</div>
	</div>
  `,
	styleUrls: ['./personal-box-plot.chart.scss']
})
export class PersonalBoxPlotChart implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	public chartData:any;
	public chartDatasets:any;
	public chartLabels:any;
	public chartObj:any;
	public chartOptions:any;
	public yMax = 100;
	public stepSize = 1;
	@ViewChild('canvas', {static: false}) canvas;
	public onDestroy = new Subject();
	public faCheck = faCheck;
	public lang;
	constructor(public lds: LoadingService, public datas:DataService, public theme: ThemeService, public coms: CommonService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.chartData = this.parent.chartData;
	}

	ngAfterViewInit(): void {
		setTimeout(()=>{
			this.initChartData();
			this.initChartOptions();
			this.renderChart();
		});
		this.parent.parent.filterChange.pipe(takeUntil(this.onDestroy)).subscribe(event=>{
			this.initChartData();
			this.chartObj.data.datasets = this.chartDatasets;
			this.chartObj.update();
		});
	}
	
	ngOnDestroy() {
		if (this.chartObj){
			this.chartObj.destroy();
		}
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	initChartOptions(){
		const axisYLabel = {tc: '總分', sc: '总分', en: 'Score'}[this.datas.lang];
		const axisXLabel = {tc: '組別', sc: '組別', en: 'Group'}[this.datas.lang];
		this.chartOptions = {
			maintainAspectRatio: false,
			animation: {
				duration: 300,
				onComplete: (event)=>{
					const chart = event.chart;
				}
			},
			scales: {
				y: {
					categoryPercentage: 0.9,
					barPercentage: 0.8,
					grid: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					border: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					ticks: {
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 14,
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
					},
					title: {
						display: true,
						text: axisYLabel,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 18,
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
					},
				},
				x: {
					categoryPercentage: 0.9,
					barPercentage: 0.8,
					padding: 10,
					title: {
						display: false,
					},
					grid: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					border: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					ticks: {
						display: true,
						padding: 30,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 18,
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
					}
				}
            },
			responsive: true,
			title: true,
			layout: { padding: { left: 20, right: 20, top: 20, bottom: -7 } },
			plugins: {
				legend: {
					display: false,
					labels: {
						display: false,
					},
				},
			},
			quantiles: '7',
		};
	}

	initChartData(){
		let chartDatasets = [];
		const selectedShares = this.chartData.shares[0];
		this.chartData.shares.forEach((share)=>{
			let dataset:any = {
				label: share.tag_fulltitle,
				borderColor: share.color,
				backgroundColor: share.color + '80',
				borderWidth: 1,
				outlierRadius: 3,
				outlierColor: '#999999',
				itemRadius: 0,
				data: [
					share.peoples.map(e=> e.score),
				],
				bsid: share.bsid,
				quantiles: 'fivenum',
				minStats: 'min',
				coef: 0
			};
			chartDatasets.push(dataset);
		});

		this.chartDatasets = chartDatasets;
	}

	calculateAverge(){
		let maxCount = 0;
		this.chartData.shares.forEach(share=>{
			maxCount = Math.max(maxCount, share.peoples.length);
		});
		let shareScores = this.chartData.shares.map(share=>{
			let shareScores = [];
			const shareScoreAverage = share.peoples.map(e=> e.score?e.score:0).reduce((a,b)=> a + b) / share.peoples.length;
			for(let i = 0; i < maxCount; i++){
				if (i < share.peoples.length){
					const score = share.peoples[i].score?share.peoples[i].score:0;
					shareScores.push(score);
				} else {
					shareScores.push(shareScoreAverage);
				}
			}
			shareScores = shareScores.sort((a,b)=>{
				if (a > b){
					return 1;
				} else if (b > a){
					return -1;
				} else {
					return 0;
				}
			});
			return shareScores;
		});

		let avgShareScores = [];
		for(let i = 0; i < maxCount; i++){
			let sum = 0;
			for(let j = 0; j < shareScores.length; j++){
				sum += shareScores[j][i];
			}
			sum = Math.round(sum * 10 / shareScores.length) / 10;
			avgShareScores.push(sum);
		}
		return avgShareScores;
	}
	
	renderChart(){
		if (this.chartData.scoredComponents.length == 0){
			return;
		}
		if (this.chartObj) {
			this.chartObj.destroy();
		}
		this.coms.waitFor(()=> this.canvas && this.canvas.nativeElement, 20, 200).then(()=>{
			const canvasEle = this.canvas.nativeElement;
			const ctx = canvasEle.getContext('2d');
			this.lds.add('renderChart', 5000);
			setTimeout(() => {
				const axisXLabel = {tc: '組別', sc: '組別', en: 'Group'}[this.datas.lang];
				this.chartObj = new Chart(ctx, {
					type: 'boxplot',
					data: {
						labels: [ axisXLabel ],
						datasets: this.chartDatasets,
					},
					options: this.chartOptions,
					plugins: [
						{
							beforeDraw: (chart, args, options)=> {
								this.beforeDraw(chart);
							}
						}
					]
				});
			}, 800);
		});
	}

	beforeDraw(chart){
		if (chart._sortedMetasets){
			const ctx = chart.ctx;
			chart._sortedMetasets.forEach(ms => {
				const boxPlotEle = ms.data[0];
				if (boxPlotEle.q1 == boxPlotEle.q3){
					boxPlotEle.q1 = boxPlotEle.max;
					boxPlotEle.q3 = boxPlotEle.max;
				}
				ctx.textAlign = 'center';
				let font = "17px 'Noto Sans T Chinese Regular' red";
				ctx.font = font;
				const swapFillStyle = ctx.fillStyle;
				ctx.fillStyle = this.theme.theme$.value == 'dark'?'#fff':'#333';
				const x = boxPlotEle.x;
				const y = chart.chartArea.bottom + 25;
				
				const text = ms.label;
				ctx.fillText(text, x , y);
				ctx.fillStyle = swapFillStyle;
				ctx.save();
			});

			const student = this.chartData.shares[0].peoples.find(e=> e.uid == this.chartData.uid);
			const score = student?student.score:0;
			// ctx.fillStyle = this.chartDatasets[0].backgroundColor;
			this.coms.waitFor(()=> this.chartObj.boxes[3]._labelItems, 20, 30).then(()=>{
				const labelItems = this.chartObj.boxes[3]._labelItems;
				let tickIndex = -1;
				let index = 0;
				while (tickIndex == -1 && index < labelItems.length){
					if (+labelItems[index].label > score){
						tickIndex = index - 1;
					}
					index++;
				}
				tickIndex = tickIndex == -1?labelItems.length - 1:tickIndex;
				const x = this.chartObj.boxes[4]._labelItems[0].options.translation[0];
				const y = labelItems[tickIndex].options.translation[1];
				ctx.beginPath();
				ctx.arc(x, y, 5, 0, 2 * Math.PI);
				const swapFillStyle = ctx.fillStyle;
				ctx.fillStyle = '#FD6585';
				ctx.fill();
				ctx.fillStyle = swapFillStyle;
				ctx.lineWidth = 2;
				const swapStrokeStyle = ctx.strokeStyle;
				ctx.strokeStyle = '#FD6585';
				ctx.stroke();
				ctx.strokeStyle = swapStrokeStyle;
				ctx.save();
			});
		}
		this.lds.remove('renderChart');
	}


}
