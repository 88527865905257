export class PinYinMapUtils
{
	/*
	private static const dict:Array = [
		{"test":"(.*)-(.*)","pinyin":"$1$2","l1":"$1","l2":"$2","t_index":0},
		{"test":"(.*)-(uei|ui)","pinyin":"","l1":"","l2":"ui","t_index":1},
		{"test":"(.*)-(iou|iu)","pinyin":"","l1":"","l2":"iu","t_index":1},
		{"test":"(.*)-(uen|un)","pinyin":"","l1":"","l2":"un","t_index":0},
		{"test":"(.*)-(ia|iao|ie|iu|iou|ian|iang|iong|ua|uo|uai|ui|uan|uang|ueng)","pinyin":"","l1":"","l2":"","t_index":1},
		{"test":"(.*)-(üan|üe)","pinyin":"","l1":"","l2":"","t_index":1},
		{"test":"()-(ia|iao|ie|iu|iou|ian|iang|iong)","pinyin":"","l1":"","l2":"","t_index":0},
		{"test":"()-(ua|uo|uai|ui|uan|uang|ueng)","pinyin":"","l1":"","l2":"","t_index":0},
		{"test":"()-i(.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null},
		{"test":"()-u(.*)","pinyin":"w$2","l1":"w","l2":"$2","t_index":null},
		{"test":"()-(ü.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null},
		{"test":"(j|q|x|)-ü(.*)","pinyin":"","l1":"","l2":"u$2","t_index":null},
		{"test":"()-(i|in|ing)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null},
		{"test":"()-(u)","pinyin":"wu","l1":"w","l2":"u","t_index":null},
		{"test":"()-(ü)","pinyin":"yu","l1":"y","l2":"u","t_index":null},
		{"test":"()-(uei|ui)","pinyin":"wei","l1":"w","l2":"ei","t_index":0},
		{"test":"()-(uen|un)","pinyin":"wen","l1":"w","l2":"en","t_index":0},
		{"test":"()-(iou|iu)", "pinyin":"you", "l1":"y", "l2":"ou", "t_index":0}
	];
	*/
	// 零聲母solution
	/*
	private static const dict:Array = [
		{"test":"(.*)-(.*)","pinyin":"$1$2","l1":"$1","l2":"$2","t_index":0,"p":""},
		{"test":"(.*)-.*?([aeiouü]).*","pinyin":"","l1":"","l2":"","t_index":null,"p":"$2"},
		{"test":"(.*)-(ia|iao|ian|iang|ua|uai|uan|uang|üan)","pinyin":"","l1":"","l2":"","t_index":null,"p":"a"},
		{"test":"(.*)-(ie|ui|uei|un|uen|ueng|üe)","pinyin":"","l1":"","l2":"","t_index":null,"p":"e"},
		{"test":"(.*)-(iu|iou|iong|uo)","pinyin":"","l1":"","l2":"","t_index":null,"p":"o"},
		{"test":"(.*)-(ü|ün)","pinyin":"","l1":"","l2":"","t_index":null,"p":"ü"},
		{"test":"(.*)-(uei|ui)","pinyin":"","l1":"","l2":"ui","t_index":1,"p":""},
		{"test":"(.*)-(iou|iu)","pinyin":"","l1":"","l2":"iu","t_index":1,"p":""},
		{"test":"(.*)-(uen|un)","pinyin":"","l1":"","l2":"un","t_index":0,"p":""},
		{"test":"(.*)-(ia|iao|ie|iu|iou|ian|iang|iong|ua|uo|uai|ui|uan|uang|ueng)","pinyin":"","l1":"","l2":"","t_index":1,"p":""},
		{"test":"(.*)-(üan|üe)","pinyin":"","l1":"","l2":"","t_index":1,"p":""},
		{"test":"()-(ia|iao|ie|iu|iou|ian|iang|iong)","pinyin":"","l1":"","l2":"","t_index":0,"p":""},
		{"test":"()-(ua|uo|uai|ui|uan|uang|ueng)","pinyin":"","l1":"","l2":"","t_index":0,"p":""},
		{"test":"()-i(.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":""},
		{"test":"()-u(.*)","pinyin":"w$2","l1":"w","l2":"$2","t_index":null,"p":""},
		{"test":"()-(ü.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":""},
		{"test":"(j|q|x|)-ü(.*)","pinyin":"","l1":"","l2":"u$2","t_index":null,"p":""},
		{"test":"()-(i|in|ing)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":""},
		{"test":"()-(u)","pinyin":"wu","l1":"w","l2":"u","t_index":null,"p":""},
		{"test":"()-(ü)","pinyin":"yu","l1":"y","l2":"u","t_index":null,"p":""},
		{"test":"()-(uei|ui)","pinyin":"wei","l1":"w","l2":"ei","t_index":0,"p":""},
		{"test":"()-(uen|un)","pinyin":"wen","l1":"w","l2":"en","t_index":0,"p":""},
		{"test":"()-(iou|iu)", "pinyin":"you", "l1":"y", "l2":"ou", "t_index":0, "p":""}
	]
	*/
	
	private static dict:any [] = [
		{"test":"(.*)-(.*)","pinyin":"$1$2","l1":"$1","l2":"$2","t_index":0,"p":"","p2":""},
		{"test":"(.*)-.*?([aeiouü]).*","pinyin":"","l1":"","l2":"","t_index":null,"p":"$2","p2":"$2"},
		{"test":"(.*)-(ia|iao|ian|iang|ua|uai|uan|uang|üan)","pinyin":"","l1":"","l2":"","t_index":null,"p":"a","p2":"a"},
		{"test":"(.*)-(uen|un|uei)","pinyin":"","l1":"","l2":"","t_index":null,"p":"e","p2":"u"},
		{"test":"(.*)-(ie|ui|ueng|üe)","pinyin":"","l1":"","l2":"","t_index":null,"p":"e","p2":"e"},
		{"test":"(.*)-(iu|iou|iong|uo)","pinyin":"","l1":"","l2":"","t_index":null,"p":"o","p2":"o"},
		{"test":"(.*)-(ü|ün)","pinyin":"","l1":"","l2":"","t_index":null,"p":"ü","p2":"ü"},
		{"test":"(j|q|x|)-(ü|ün)","pinyin":"","l1":"","l2":"","t_index":null,"p":"","p2":"u"},
		{"test":"(.*)-(uei|ui)","pinyin":"","l1":"","l2":"ui","t_index":1,"p":"","p2":"i"},
		{"test":"(.*)-(iou|iu)","pinyin":"","l1":"","l2":"iu","t_index":1,"p":"","p2":"u"},
		{"test":"(.*)-(uen|un)","pinyin":"","l1":"","l2":"un","t_index":0,"p":"","p2":"u"},
		{"test":"()-i(.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"()-u(.*)","pinyin":"w$2","l1":"w","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"()-(ü.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"(j|q|x|)-ü(.*)","pinyin":"","l1":"","l2":"u$2","t_index":null,"p":"","p2":""},
		{"test":"()-(i|in|ing)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"()-(u)","pinyin":"wu","l1":"w","l2":"u","t_index":null,"p":"","p2":""},
		{"test":"()-(ü)","pinyin":"yu","l1":"y","l2":"u","t_index":null,"p":"","p2":""},
		{"test":"()-(uei|ui)","pinyin":"wei","l1":"w","l2":"ei","t_index":0,"p":"","p2":"e"},
		{"test":"()-(uen|un)","pinyin":"wen","l1":"w","l2":"en","t_index":0,"p":"","p2":"e"},
		{"test":"()-(iou|iu)", "pinyin":"you", "l1":"y", "l2":"ou", "t_index":0, "p":"", "p2":"o"},
		
		
		{"test":"()-(ê)","pinyin":"$2","l1":"","l2":"$2","t_index":0,"p":"ê","p2":"ê"}

	];
	
	/*
	
	[
		{"test":"(.*)-(.*)","pinyin":"$1$2","l1":"$1","l2":"$2","t_index":0,"p":"","p2":""},
		{"test":"(.*)-.*?([aeiouü]).*","pinyin":"","l1":"","l2":"","t_index":null,"p":"$2","p2":"$2"},
		{"test":"(.*)-(ia|iao|ian|iang|ua|uai|uan|uang|üan)","pinyin":"","l1":"","l2":"","t_index":null,"p":"a","p2":"a"},
		{"test":"(.*)-(uen|un|uei)","pinyin":"","l1":"","l2":"","t_index":null,"p":"e","p2":"u"},
		{"test":"(.*)-(ie|ui|ueng|üe)","pinyin":"","l1":"","l2":"","t_index":null,"p":"e","p2":"e"},
		{"test":"(.*)-(iu|iou|iong|uo)","pinyin":"","l1":"","l2":"","t_index":null,"p":"o","p2":"o"},
		{"test":"(.*)-(ü|ün)","pinyin":"","l1":"","l2":"","t_index":null,"p":"ü","p2":"ü"},
		{"test":"(j|q|x|)-(ü|ün)","pinyin":"","l1":"","l2":"","t_index":null,"p":"","p2":"u"},
		{"test":"(.*)-(uei|ui)","pinyin":"","l1":"","l2":"ui","t_index":1,"p":"","p2":""},
		{"test":"(.*)-(iou|iu)","pinyin":"","l1":"","l2":"iu","t_index":1,"p":"","p2":"i"},
		{"test":"(.*)-(uen|un)","pinyin":"","l1":"","l2":"un","t_index":0,"p":"","p2":"u"},
		{"test":"()-i(.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"()-u(.*)","pinyin":"w$2","l1":"w","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"()-(ü.*)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"(j|q|x|)-ü(.*)","pinyin":"","l1":"","l2":"u$2","t_index":null,"p":"","p2":""},
		{"test":"()-(i|in|ing)","pinyin":"y$2","l1":"y","l2":"$2","t_index":null,"p":"","p2":""},
		{"test":"()-(u)","pinyin":"wu","l1":"w","l2":"u","t_index":null,"p":"","p2":""},
		{"test":"()-(ü)","pinyin":"yu","l1":"y","l2":"u","t_index":null,"p":"","p2":""},
		{"test":"()-(uei|ui)","pinyin":"wei","l1":"w","l2":"ei","t_index":0,"p":"","p2":"e"},
		{"test":"()-(uen|un)","pinyin":"wen","l1":"w","l2":"en","t_index":0,"p":"","p2":"e"},
		{"test":"()-(iou|iu)", "pinyin":"you", "l1":"y", "l2":"ou", "t_index":0, "p":"", "p2":"o"}
	];
	*/
	private static toneMap:any =  {
		"1":"ˉ", "2":"ˊ", "3":"ˇ", "4":"ˋ",
		"a1":"ā", "a2":"á", "a3":"ǎ", "a4":"à", 
		"o1":"ō", "o2":"ó", "o3":"ǒ", "o4":"ò",
		"e1":"ē", "e2":"é", "e3":"ě", "e4":"è", 
		"ê1":"ễ", "ê2":"ế", "ê3":"ể", "ê4":"ề", 
		"i1":"ī", "i2":"í", "i3":"ǐ", "i4":"ì", 
		"u1":"ū", "u2":"ú", "u3":"ǔ", "u4":"ù", 
		"ü1":"ǖ", "ü2":"ǘ", "ü3":"ǚ", "ü4":"ǜ"
	};
	/*
	private static const toneMap:any = {
		"a1":"ā", "a2":"á", "a3":"ǎ", "a4":"à", 
		"o1":"ō", "o2":"ó", "o3":"ǒ", "o4":"ò",
		"e1":"ē", "e2":"é", "e3":"ě", "e4":"è", 
		"i1":"ī", "i2":"í", "i3":"ǐ", "i4":"ì", 
		"u1":"ū", "u2":"ú", "u3":"ǔ", "u4":"ù", 
		"ü1":"ǖ", "ü2":"ǘ", "ü3":"ǚ", "ü4":"ǜ"
	};
	*/
	private static pinyinChart:any = {
		"1":"ˉ", "2":"ˊ", "3":"ˇ", "4":"ˋ","5":".",
		"ˉ":"1", "ˊ":"2", "ˇ":"3", "ˋ":"4",".":"5",
		"a1":"ā", "a2":"á", "a3":"ǎ", "a4":"à", 
		"o1":"ō", "o2":"ó", "o3":"ǒ", "o4":"ò",
		"e1":"ē", "e2":"é", "e3":"ě", "e4":"è", 
		"i1":"ī", "i2":"í", "i3":"ǐ", "i4":"ì", 
		"u1":"ū", "u2":"ú", "u3":"ǔ", "u4":"ù", 
		"ü1":"ǖ", "ü2":"ǘ", "ü3":"ǚ", "ü4":"ǜ",
		"ā":"a1", "á":"a2", "ǎ":"a3", "à":"a4", 
		"ō":"o1", "ó":"o2", "ǒ":"o3", "ò":"o4",
		"ē":"e1", "é":"e2", "ě":"e3", "è":"e4", 
		"ī":"i1", "í":"i2", "ǐ":"i3", "ì":"i4", 
		"ū":"u1", "ú":"u2", "ǔ":"u3", "ù":"u4", 
		"ǖ":"ü1", "ǘ":"ü2", "ǚ":"ü3", "ǜ":"ü4",
		"ễ":"ê1", "ế":"ê2", "ể":"ê3", "ề":"ê4",
		"er1":"ēr", "er2":"ér", "er3":"ěr", "er4":"èr",
		"ai1":"āi", "ai2":"ái", "ai3":"ǎi", "ai4":"ài",  
		"ei1":"ēi", "ei2":"éi", "ei3":"ěi", "ei4":"èi", 
		"ao1":"āo", "ao2":"áo", "ao3":"ǎo", "ao4":"ào",
		"ou1":"ōu", "ou2":"óu", "ou3":"ǒu", "ou4":"òu", 
		"an1":"ān", "an2":"án", "an3":"ǎn", "an4":"àn", 
		"en1":"ēn", "en2":"én", "en3":"ěn", "en4":"èn", 
		"ang1":"āng", "ang2":"áng", "ang3":"ǎng", "ang4":"àng", 
		"eng1":"ēng", "eng2":"éng", "eng3":"ěng", "eng4":"èng", 
		"ong1":"ōng", "ong2":"óng", "ong3":"ǒng", "ong4":"òng",
		"ia1":"iā", "ia2":"iá", "ia3":"iǎ", "ia4":"ià",
		"ie1":"iē", "ie2":"ié", "ie3":"iě", "ie4":"iè",
		"iao1":"iāo", "iao2":"iáo", "iao3":"iǎo", "iao4":"iào",
		"iou1":"iū", "iou2":"iú", "iou3":"iǔ", "iou4":"iù",
		"ian1":"iān", "ian2":"ián", "ian3":"iǎn", "ian4":"iàn",
		"in1":"īn", "in2":"ín", "in3":"ǐn", "in4":"ìn", 
		"iang1":"iāng", "iang2":"iáng", "iang3":"iǎng", "iang4":"iàng",
		"ing1":"īng", "ing2":"íng", "ing3":"ǐng", "ing4":"ìng", 
		"iong1":"iōng", "iong2":"ióng", "iong3":"iǒng", "iong4":"iòng",
		"ua1":"uā", "ua2":"uá", "ua3":"uǎ", "ua4":"uà",
		"uo1":"uō", "uo2":"uó", "uo3":"uǒ", "uo4":"uò",
		"uai1":"uāi", "uai2":"uái", "uai3":"uǎi", "uai4":"uài",
		"uei1":"uī", "uei2":"uí", "uei3":"uǐ", "uei4":"uì",
		"uan1":"uān", "uan2":"uán", "uan3":"uǎn", "uan4":"uàn",
		"uen1":"ūn", "uen2":"ún", "uen3":"ǔn", "uen4":"ùn",
		"uang1":"uāng", "uang2":"uáng", "uang3":"uǎng", "uang4":"uàng",
		"ueng1":"uēng", "ueng2":"uéng", "ueng3":"uěng", "ueng4":"uèng",
		"üe1":"üē", "üe2":"üé", "üe3":"üě", "üe4":"üè",
		"üan1":"uān", "üan2":"uán", "üan3":"uǎn", "üan4":"uàn",
		"ün1":"ūn", "ün2":"ún", "ün3":"ǔn", "ün4":"ùn" 
	};
	public static ready:boolean = false;
	public static init():void
	{
		var len:number = PinYinMapUtils.dict.length;
		for (var i:number = 0; i < len; i++)
		{
			PinYinMapUtils.dict[i].reg = new RegExp("^"+PinYinMapUtils.dict[i].test+"$");
		}
		
		PinYinMapUtils.ready = true;
	}
	
	private static  simplify(str:string):string
	{
		return str ? str : "";
	}
	
	public static  basicAnalysis(shengmu:string, yunmu:string, tone:number):any
	{
		if(PinYinMapUtils.ready== false)
		{
			PinYinMapUtils.init();
		}
		var len:number = PinYinMapUtils.dict.length;
		var pinyin:string;
		var s:string = PinYinMapUtils.simplify(shengmu) + "-" + PinYinMapUtils.simplify(yunmu);
		var l1:string;
		var l2:string;
		var p:string;
		var p2:string;
		for (var i:number = 0 ; i < len; i++)
		{
			var d:any = PinYinMapUtils.dict[i];
			var reg:RegExp = RegExp(d.reg);
			var match:boolean = reg.test(s);
			if (match)
			{
				if (d.pinyin)
				{
					pinyin = s.replace(reg, d.pinyin);
				}
				if (d.l1)
				{
					l1 = s.replace(reg, d.l1);
				}
				if (d.l2)
				{
					l2 = s.replace(reg, d.l2);
				}
				if (d.p)
				{
					p = s.replace(reg, d.p);
				}
				if (d.p2)
				{
					p2 = s.replace(reg, d.p2);
				}
			}
		}
		return {
			shengmu:l1,
			yunmu:l2,
			tone:p2,
			toneNone:p
		};
	}
	
	public static  analysis(shengmu:string, yunmu:string, tone:number):any
	{
		var oo:any = PinYinMapUtils.basicAnalysis(shengmu, yunmu, tone);
		var l1:string = oo.shengmu;
		var l2:string = oo.yunmu;
		var toneCharacter:string = oo.tone;
		
		var yunmuTone:string ;
		var tIndex:number;
		
		if (tone == 0 || tone == 5 || tone === undefined)
		{
			yunmuTone = l2 ;
		} else 
		{
			if (yunmu)
			{
				tIndex = l2.indexOf(toneCharacter);
				
				if (tIndex == 0)
				{
					yunmuTone = PinYinMapUtils.toneMap[l2.charAt(tIndex)+tone] + l2.substring(1, l2.length);
				} else {
					yunmuTone = l2.substring(0, tIndex) + PinYinMapUtils.toneMap[l2.charAt(tIndex)+tone] + l2.substring(tIndex+1, l2.length);
				}
			} else {
				yunmuTone = PinYinMapUtils.toneMap[tone];
			}
		} 
		return {
			o:{
				s:shengmu,
				y:yunmu
			},
			v:{
				py:l1 + yunmuTone,
				yt:yunmuTone,
				s:l1,
				y:l2,
				ti:tIndex,
				p:toneCharacter
			}
		}
		
	}

	
	public static convertPinYin(teacherShengmu:string, yunmuIsQ:boolean, shengmu:string, yunmu:string, tone:number, noneShengmu:boolean, selectedTone:any= null):any
	{
		var p:string;
		var p2:string;
		var l2:string;
		var l1:string;
		var basic2:any;
		var basic:any = PinYinMapUtils.basicAnalysis(shengmu, yunmu, tone);
		if (yunmuIsQ)
		{
			l1 = basic.shengmu;
			l2 = basic.yunmu;
			p2 = basic.tone;
			p = basic.toneNone;
		} else {
			basic2 = PinYinMapUtils.basicAnalysis(teacherShengmu, yunmu, tone);
			l1 = basic.shengmu;
			l2 = basic2.yunmu;
			p2 = basic2.tone;
			p = basic2.toneNone;
			
		}
		

		
		var info:any;
		var yunmuTone:string ;
		var tIndex:number;
		
		if (noneShengmu)
		{ // exception handling
			l1 = "";
			if (yunmu == "uei")
			{
				l2 = "ui";
			} else if (yunmu == "uen")
			{
				l2 = "un";
			} else if (yunmu == "iou")
			{
				l2 = "iu";
			} else {
				l2 = yunmu;
			}
		}
		
		if (tone == 0 || tone == 5)
		{
			yunmuTone = l2;
			selectedTone = null;
		} else 
		{
			if (yunmu)
			{
				
				info = PinYinMapUtils.findToneIndex(l2, selectedTone, noneShengmu ? p : p2);
				tIndex = info.index;
				selectedTone = info.tone;
				yunmuTone = PinYinMapUtils.convertYunmu(tIndex, l2, tone);
				
			} else {
				yunmuTone = PinYinMapUtils.toneMap[tone];
			}
		} 
		return {
			o:{
				s:shengmu,
				y:yunmu
			},
			v:{
				py:l1 + yunmuTone,
				yt:yunmuTone,
				s:l1,
				y:l2,
				ti:tIndex,
				t:selectedTone
			}
		}
		
	}
	private static  convertYunmu(tIndex:number, visualYunmu:string, tone:number):string
	{
		if (tone == 0 || tone == 5)
		{
			return visualYunmu;
		} else {
			if (tIndex == 0)
			{
				return PinYinMapUtils.toneMap[visualYunmu.charAt(tIndex)+tone] + visualYunmu.substring(1,visualYunmu.length);
			} else {
				return visualYunmu.substring(0, tIndex) + PinYinMapUtils.toneMap[visualYunmu.charAt(tIndex)+tone] + visualYunmu.substring(tIndex+1, visualYunmu.length);
			}
		}
		
	}
	private static  findToneIndex(visualYunmu:string, selectedTone:any, defaultValue:string):any
	{
		var p:string;
		var tIndex:number;
		if (!selectedTone)
		{
			selectedTone = defaultValue;
		} else if (selectedTone === true)
		{
			// find the first
			selectedTone = visualYunmu.replace(/.*?([aeiouü]).*/, "$1");
		}
		
		if (selectedTone == "ü" || selectedTone == "u" )
		{
			tIndex = visualYunmu.search(/[uü]/);
			selectedTone = visualYunmu.charAt(tIndex);
		} else {
			tIndex = visualYunmu.indexOf(selectedTone);
		}
		
		if (tIndex == -1)
		{
			// should not happen
			// tIndex = l2.replace(/.*?([aeiouü]).*/, "$1");
			selectedTone = visualYunmu.replace(/.*?([aeiouü]).*/, "$1");;
			tIndex = visualYunmu.indexOf(selectedTone);
		}
		return {
			index:tIndex,
			tone:selectedTone
		}
	}
}

