import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { StorageService } from 'src/app/service/storage.service';
import { LoadingService } from '../loadingModule/loading.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { SubjectService } from './../subjectModule/subject.service';
import { TagGroupService } from '../tagGroupModule/tagGroup.service';
import { SettingService } from 'src/app/service/setting.service';
import { PermissionService } from 'src/app/service/permission.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
	selector: 'create-share-modal',
	template: `
	
	<modal2 #modal2
		[ngClass]="[
			'create-share-modal',
			shareModal.share.share_type, 
			'entry-' + shareModal.share.entry_type,
			shareModal.subjectSelecting?'subject-selecting':'',
			shareModal.targetSelecting?'target-selecting':'',
			'toggle-' + toggle,
			preset && preset.isOn?'preset-open':'']"
		[width]="'512px'" [title]="modalTitle|translate"
		[contentStyle]="contentStyle"
		(confirmClick)="confirmClick()"
		(cancelClick)="cancelClick($event)"
		>
	<popup-toggle [items]="toggles" [(toggle)]="toggle" bindLabel="label" bindValue="value" 
		[options]="toggleOptions" (toggleChange)="toggleChange()"></popup-toggle>
	<div class="share-modal__content share-{{shareModal.share.share_type}} toggle-{{toggle}}">
	  <ng-container *ngIf="animated">
		
		<ng-container *ngIf="toggle == 'basic'">
			<div class="row entry">
				<div class="row__label">{{'workspace.choose-file'|translate}}:</div>
				<div class="row__data">
				<ng-container *ngIf="shareModal.share.entry_type">	
					<div class="entry-icon" [ngClass]="shareModal.share.entry_type"></div>
					<div class="entry-title">
						<span>{{shareModal.share.entry_title}}</span>
					</div>
				</ng-container>
				<div class="reselect" *ngIf="shareModal.share.id == null" (click)="reselectedClick()">{{'bookshelf.reselect'|translate}}</div>
				</div>
			</div>
			<div class="row tag">
				<div class="row__label">{{'workspace.tag'|translate}}:</div>
				<div class="row__data" [class.disabled]="shareModal.share && shareModal.share.id">
					<div class="tag-title" (click)="targetSelectorOpen($event)">{{tags_title}}</div>
					<targetSelector #targetSelector [allowForAll]="false"
						memberRole="student" [(ngModel)]="shareModal.targets" 
						></targetSelector>
				</div>
			</div>

			<div class="row type-setting" *ngIf="shareModal.share.entry_type == 'book' || shareModal.share.entry_type == 'collection' || shareModal.share.entry_type == 'assessment' 
			|| shareModal.share.entry_type == 'pdf-paper'">
				<div class="row__label">{{'workspace.type-setting'|translate}}:</div>
				<div class="row__data">
				<preset-button4 #preset [preset]="shareModal.share"></preset-button4>
				</div>
			</div>

			<div class="row type-star" *ngIf="shareModal.share.entry_type != 'book' && shareModal.share.entry_type != 'collection' && shareModal.share.entry_type != 'assessment' 
			&& shareModal.share.entry_type != 'pdf-paper'">
				<div class="row__label">{{'workspace.text-star'|translate}}:</div>
				<div class="row__data">
				<numberInput min="0" max="50" [(ngModel)]="shareModal.share.star"></numberInput>
				</div>
			</div>

			<ng-container *ngIf="shareModal.share.share_type != 'normal'">
				<div class="row in-progress-time">
				<div class="row__label">{{'workspace.in-progress-time'|translate}}:</div>
				<div class="row__data">
					<a class="datetime in-progress" #inProgressTimeEl (click)="dateTimeClick(inProgressTimeEl, 'in-progress')">
					<ng-container *ngIf="!shareModal.startTime && !shareModal.endTime">
						<span class="datetime__text">{{'workspace.pls-select'|translate}}</span>
					</ng-container>
					<ng-container *ngIf="shareModal.startTime && shareModal.endTime">
						<span class="datetime__text">{{shareModal.startTime | myDate: (shareModal.wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} - {{shareModal.endTime | myDate: shareModal.wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a'}}</span>
					</ng-container>
					<ng-container *ngIf="shareModal.startTime != null && shareModal.endTime == null">
						<span class="datetime__text">{{shareModal.startTime | myDate: (shareModal.wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}}{{'workspace.up-to-forever' | translate}}</span>
					</ng-container>
					</a>
				</div>
				</div>
				<div class="row correction-time">
				<div class="row__label">{{'workspace.correction-time'|translate}}:</div>
				<div class="row__data">
					<a class="datetime correction" #correctionTimeEl (click)="dateTimeClick(correctionTimeEl, 'correction')">
					<ng-container *ngIf="!shareModal.correctionStartTime && !shareModal.correctionEndTime">
						<span class="datetime__text">{{'workspace.pls-select'|translate}}</span>
					</ng-container >
					<ng-container *ngIf="shareModal.correctionStartTime && shareModal.correctionEndTime">
						<span class="datetime__text">{{shareModal.correctionStartTime | myDate: (shareModal.correctionWholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} - {{shareModal.correctionEndTime | myDate: shareModal.correctionWholeDay?'YYYY/M/D':'YYYY/M/D h:mm a'}}</span>
					</ng-container >
					<ng-container *ngIf="shareModal.correctionStartTime != null && shareModal.correctionEndTime == null">
						<span class="datetime__text">{{shareModal.correctionStartTime | myDate: (shareModal.correctionWholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}}{{'workspace.up-to-forever' | translate}}</span>
					</ng-container>
					</a>
				</div>
				</div>
			</ng-container>

			<div class="row subject">
				<div class="row__label">
				<span>{{ 'workspace.subject' |translate}}:</span>
				</div>
				<div class="row__data">
					<div class="subject-title" (click)="subjectSelectorOpen($event)">{{subjects_title}}</div>
					<subjectSelector3 #subjectSelector [allowForAll]="false"
						[(ngModel)]="shareModal.subjects"
						>
					</subjectSelector3>  
				</div>
			</div>
			
			
			<div class="row short-link" *ngIf="shareModal.share.entry_type == 'book' && (datas.appId == 'rainbowone' || datas.appId == 'rainbowxstar')">
				<div class="row__label">
					<ng-container *ngIf="shareModal.share.id">{{'bookshelf.short-url'|translate}}:</ng-container>
					<ng-container *ngIf="shareModal.share.id == null">{{'workspace.gen-short-url'|translate}}:</ng-container>
				</div>
				<div class="row__data">
				<ui-switch class="useCssVar" *ngIf="shareModal.share.id == null"
					[checked]="shareModal.genShareUrl == true" 
					(change)="shareModal.genShareUrl = $event;"></ui-switch>
				<ng-container *ngIf="shareModal.share && shareModal.share.id != null">
					<input type="text" [(ngModel)]="shareModal.share.short_url" disabled />
					<div class="generate" *ngIf="!shareModal.share.short_url" (click)="generateClick($event)">{{'bookshelf.generate'|translate}}</div>
					<div class="copy" *ngIf="shareModal.share.short_url" (click)="copyClick($event)">{{'bookshelf.copy'|translate}}</div>
				</ng-container>
				</div>
			</div>
			
		</ng-container>

		<ng-container *ngIf="toggle == 'advanced'">
			<div class="topic">{{ {tc:'成績顯示 (學生)',sc:'成绩显示 (学生)',en:'Result display (Student)'}[lang] }}</div>
			<div class="row first">
				<div class="row__label">{{ {tc:'排行榜',sc:'排行榜',en:'Ranking'}[lang] }}:</div>
				<div class="row__data">
					<okaPulldown2 class="show-ranking" [options]="showRankingOptions" bindLabel="label" bindValue="value" 
					[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="shareModal.share.show_ranking"
					(ngModelChange)="showChartChange('ranking')"></okaPulldown2>
				</div>
			</div>
			<div class="row show-chart">
				<div class="row__label">{{ {tc:'圖表',sc:'图表',en:'Chart'}[lang] }}:</div>
				<div class="row__data show-chart">
					<okaPulldown2 class="show-chart" [options]="showChartOptions" bindLabel="label" bindValue="value" 
						[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="shareModal.share.show_chart"
						(ngModelChange)="showChartChange('chart')"></okaPulldown2>
					<div class="show-chart-wrapper show-chart-{{shareModal.share.show_chart}}">
						<div class="show-chart-item">
							<div class="show-chart-label">{{ {tc:'成績分佈 (折線)',sc:'成绩分布 (折线)', en:'Result (Line)'}[lang]}}:</div>
							<ui-switch [checked]="shareModal.share.show_chart_chart1 == '1'"
								(change)="shareModal.share.show_chart_chart1 = $event?'1':'0';"></ui-switch>
						</div>
						<div class="show-chart-item">
							<div class="show-chart-label">{{ {tc:'箱形圖',sc:'箱形圖', en:'Box plot'}[lang]}}:</div>
							<ui-switch [checked]="shareModal.share.show_chart_chart2 == '1'"
								(change)="shareModal.share.show_chart_chart2 = $event?'1':'0';"></ui-switch>
						</div>
						<div class="show-chart-item">
							<div class="show-chart-label">{{ {tc:'學習重點 (雷達)',sc:'学习重点 (雷达)', en:'Objectives (Radar)'}[lang]}}:</div>
							<ui-switch [checked]="shareModal.share.show_chart_chart3 == '1'"
								(change)="shareModal.share.show_chart_chart3 = $event?'1':'0';"></ui-switch>
						</div>
						<div class="show-chart-item">
							<div class="show-chart-label">{{ {tc:'題目表現',sc:'题目表现', en:'Questions'}[lang]}}:</div>
							<ui-switch [checked]="shareModal.share.show_chart_chart4 == '1'"
								(change)="shareModal.share.show_chart_chart4 = $event?'1':'0';"></ui-switch>
						</div>
						<div class="show-chart-item">
							<div class="show-chart-label">{{ {tc:'章節',sc:'章节', en:'Chapters'}[lang]}}:</div>
							<ui-switch [checked]="shareModal.share.show_chart_chart5 == '1'"
								(change)="shareModal.share.show_chart_chart5 = $event?'1':'0';"></ui-switch>
						</div>
						<div class="show-chart-item">
							<div class="show-chart-label">{{ {tc:'貼紙統計 (學生)',sc:'贴纸统计 (学生)', en:'Stickers (Student)'}[lang]}}:</div>
							<ui-switch [checked]="shareModal.share.show_chart_chart6 == '1'"
								(change)="shareModal.share.show_chart_chart6 = $event?'1':'0';"></ui-switch>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	  </ng-container>
	</div>
	<dateTimePicker3 #dateTimePicker 
		[showCustomMinutesOption]="true"
		[clearBtn]="true" [lang]="datas.lang"></dateTimePicker3>
  </modal2>

<div class="short-urls-modal" *ngIf="shortUrlsModal">
  	<div class="short-urls-modal__wrapper">
    	<div class="short-urls-modal__header">
      		<div class="short-urls-modal__header__title">{{ 'workspace.short-urls-modal-title' | translate}}</div>
      		<div class="short-urls-modal__header__confirm" (click)="close.emit(shareModal)">{{"workspace.close" | translate}}</div>
    	</div>
    	<div class="top-shadow first"></div>
    	<div class="short-urls-modal__topic">{{('workspace.' + shortUrlsModal.item.type + '-name')|translate}}: {{shortUrlsModal.item.title}}</div>
    	<div class="short-urls-modal__content">
      		<div class="short-urls-modal__content__header">
        		<div class="short-urls-modal__content__header__tag">{{'workspace.tag'|translate}}</div>
        		<div class="short-urls-modal__content__header__link">{{'workspace.link'|translate}}</div>
      		</div>
      		<perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
        		<div [ngClass]="['short-urls-modal__row',first?'first':'',last?'last':'']" *ngFor="let share of shortUrlsModal.shares;let first=first;let last=last;">
          			<div class="short-urls-modal__row__tag">
            			<span>{{share.tagTitle}}</span>
          			</div>
          			<div class="short-urls-modal__row__link">{{share.short_url}}</div>
          			<div class="short-urls-modal__row__copy" (click)="copyToClipboard(share.short_url, share)">{{('workspace.' + (share.copied?'copied':'copy') )|translate}}</div>
        		</div>
        		<div class="short-urls-modal__row__remain" [style.height.px]="(shortUrlsModal.shares.length > 9?20: 10)"></div>
      		</perfect-scrollbar>
    	</div>
  	</div>
</div>

  `
	, styleUrls: ['./create-share.modal.scss'],
})
export class CreateShareModal implements OnInit, OnDestroy, AfterViewInit {
	@Input() shareModal: any;
	public shortUrlsModal: any;
	@ViewChild('dateTimePicker', { static: false }) dateTimePicker;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('targetSelector', { static: false }) targetSelector;
	@ViewChild('subjectSelector', { static: false }) subjectSelector;
	@ViewChild('preset', { static: false }) preset;
	private selectorPromise;
	public tags_title = '';
	public subjects_title = '';
	public targets: any[] = [];
	@ViewChild('modal2', { static: false }) modal2;
	public contentStyle;
	public modalTitle = 'workspace.create-share';
	public animated = false;
	public hasPromissionEditPeriod = false;
	public lang = 'tc';
	public toggle;
	public toggles:any;
	public toggleOptions = {};
	public isForEdit = false;
	public showRankingOptions;
	public showChartOptions;
	public showChartObj:any;
	public okaPulldownStyle = {width: '220px'};
	constructor(public datas: DataService, private als: AlertService, private translate: TranslateService, private storage: StorageService, private load: LoadingService, public eleRef: ElementRef, public subject: SubjectService, private tg: TagGroupService, public sts: SettingService, public pm: PermissionService, public coms: CommonService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.toggles = [{label: {tc: '基本', sc:'基本', en:'Basic'}[this.lang], value: 'basic'}, { label: {tc:'進階', sc:'進階', en: 'Advanced'}[this.lang], value: 'advanced'}];
		this.toggle = this.toggles[0].value;
		this.contentStyle = { padding: '0' };
		this.initShareModal(this.shareModal);
		if (this.shareModal.share.id) {
			this.isForEdit = true;
			this.modalTitle = 'course.edit-share';
		}
		this.initTargets();
		this.initStartTime();
		this.subject.loadSubject().pipe(take(1)).subscribe(s => {
			this.initSubjects();
		});
		if (this.pm.role_premission_list && this.pm.role_premission_list.indexOf('bookshelf_modify_period') > -1) {
			this.hasPromissionEditPeriod = true;
		}
		this.initPersonalReportSettings();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.modal2.open();
		}, 20);

		setTimeout(() => {
			this.animated = true;
		}, 620);
	}

	ngOnDestroy() {
	}

	initShareModal(shareModal) {
		if (shareModal.share.id == null) {
			shareModal.subjects = [];
			this.subjects_title = this.translate.instant('commonService.please-select');
			const share = shareModal.share;
			share.share_type = share.share_type ? share.share_type : 'normal';
			const defaultPassingRate = this.sts.schoolSettings.PASSING_RATE ? this.sts.schoolSettings.PASSING_RATE : '50';
			share.pass_level = defaultPassingRate;
			share.time_limit = 'null';
			share.star = 0;
			share.max_submission_count = 'null';
			share.live_verify = 1;
			share.show_default_answer = 1;
			share.auto_submit = 1
			share.repeat_star = 0;
			share.ruby = 0;
			share.emberald = 0;
			share.crystal = 0;
			share.prize_type = 'star';
		} else {

		}
	}

	dateTimeClick(el, dateType) {
		if (this.hasPromissionEditPeriod == false && this.shareModal.share.id != null) {
			// disable later
		}
		const dateTimePicker = this.dateTimePicker;
		const shareModal = this.shareModal;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		let update$ = null;
		if (dateType == 'in-progress') {
			if (shareModal.startTime) {
				const last8Dight = shareModal.startTime.format('HH:mm:ss');
				wholeDay = last8Dight == '00:00:00' ? 1 : 0;
				startTime = shareModal.startTime.toDate();
			}
			if (shareModal.endTime) {
				const last8Dight2 = shareModal.endTime.format('HH:mm:ss');
				wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
				endTime = shareModal.endTime.toDate();
			}
		} else {
			if (shareModal.correctionStartTime) {
				const last8Dight = shareModal.correctionStartTime.format('HH:mm:ss');
				wholeDay = last8Dight == '00:00:00' ? 1 : 0;
				startTime = shareModal.correctionStartTime.toDate();
			}
			if (shareModal.correctionEndTime) {
				const last8Dight2 = shareModal.correctionEndTime.format('HH:mm:ss');
				wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
				endTime = shareModal.correctionEndTime.toDate();
			}
		}

		let needEndTime = !!endTime;
		let dtpOptions: any = { value: { startDateTime: startTime, endDateTime: endTime, isWholeDay: wholeDay, customMinutes: true, needEndTime: needEndTime }, skipLoadApi: true };
		dateTimePicker.open(el, dtpOptions).then(dt => {
			if (dateType == 'in-progress') {
				if (dt.startDateTime) {
					shareModal.startTime = moment(dt.startDateTime);
				} else {
					shareModal.startTime = null;
				}
				if (dt.endDateTime) {
					shareModal.endTime = moment(dt.endDateTime);
				} else {
					shareModal.endTime = null;
				}
				shareModal.wholeDay = dt.wholeDay ? 1 : 0;
			} else {
				if (dt.startDateTime) {
					shareModal.correctionStartTime = moment(dt.startDateTime)
				} else {
					shareModal.correctionStartTime = null;
				}
				if (dt.endDateTime) {
					shareModal.correctionEndTime = moment(dt.endDateTime);
				} else {
					shareModal.correctionEndTime = null;
				}
				shareModal.correctionWholeDay = dt.wholeDay ? 1 : 0;
			}
		});
	}

	cancelClick($event) {
		this.close.emit(null);
	}

	async confirmClick() {
		let modal = this.shareModal;
		if (modal.targets.length == 0 || modal.startTime == null) {
			this.als.toastError();
			return;
		}
		const options: any = JSON.parse(JSON.stringify(modal.share));
		options.type = options.share_type;
		options.share_type = null;
		options.time_limit = options.time_limit == 'null' ? null : options.time_limit;
		if (modal.startTime) {
			options.start_time = modal.startTime.toISOString();
		}
		if (modal.endTime) {
			options.end_time = modal.endTime.toISOString();
		}
		if (modal.correctionStartTime) {
			options.correction_start_time = modal.correctionStartTime.toISOString();
		}
		if (modal.correctionEndTime) {
			options.correction_end_time = modal.correctionEndTime.toISOString();
		}
		if (modal.share && modal.share.id) {
			this.updateExistShare(options);
			return true;
		}
		const targets = modal.targets.filter(t => t.tType == 'tag');
		const individualUids = modal.targets.filter(t => t.tType == 'people').map(e => e.value);
		if (individualUids.length > 0) {
			const schoolId = this.datas.userInfo.school_id;
			const title = 'cid-share-' + moment().format('YYYYMMDD-HHmmss');
			const tagType = 13;
			const currentYear = this.tg.defaultSchoolYear;
			const yearId = currentYear ? currentYear.id : '0';
			const json: any[] = [schoolId, yearId, schoolId, title, tagType, 'main', individualUids];
			let res: any = await this.datas.post('ROCourse.create_virtual_tag_for_group_share', json).toPromise();
			targets.push({ tType: 'tag', value: res.tid, title: title });
		}
		if (targets.length == 0) {
			const msg = { tc: '請選擇組別', sc: '请选择组别', en: 'Please select group' }[this.datas.lang];
			this.als.toastError(msg);
			return;
		}
		this.checkShareExist(options, targets, modal.subjects);
		// this.close.emit(this.shareModal);
	}

	checkShareExist(options, targets, subjects) {
		const share = this.shareModal.share;
		targets = targets.filter(t => t.tType == 'tag').map(e => e.value);
		let o = { entries: [{ id: share.entry_id, type: share.entry_type, title: share.entry_title }], tags: targets, options: options, subjects: subjects };
		this.datas.post2({ data: { api: 'ROBookShare.find_book_share', json: [targets, share.entry_id] } }).then((res: any) => {
			if (res.share) {
				const author = res.share.created_by[this.datas.lang == 'en' ? 'ename' : 'cname'];
				const shareType = this.translate.instant('workspace.share-type-' + res.share.type);
				const target = res.share.tags.length > 0 ? res.share.tags[0].title : '';
				this.als.alert2('workspace.confirm-create-share', { author: author, time: res.share.created_at, shareType: shareType, target: target }, { showCnlBtn: true }).then(() => {
					this.createCreate(o);
				}).catch(err => { });
			} else {
				this.createCreate(o);
			}
		});
	}

	createCreate(obj) {
		const objOptions = obj.options;
		const dbFields = ['created_at', 'active', 'type', 'name', 'created_by', 'live_verify', 'verify_response', 'status', 'permission', 'start_time', 'end_time', 'json', 'submit_allow', 'auto_submit', 'submit_index', 'pass_level', 'show_default_answer', 'show_default_answer_2', 'school_id', 'school_year', 'date', 'temp', 'new_created', 'max_submission_count', 'correction_start_time', 'correction_end_time', 'has_correction', 'time_limit', 'star', 'checksum', 'open_mode', 'repeat_star', 'in_course', 'pass_condition', 'next_bsid', 'prev_bsid', 'short_url', 'auto_pass', 'font_size', 'ruby', 'emberald', 'crystal', 'prize_type', 'show_ranking', 'show_chart','show_chart_chart1','show_chart_chart2','show_chart_chart3','show_chart_chart4','show_chart_chart5','show_chart_chart6'];
		let options: any = {};
		for (let prop in obj.options) {
			if (dbFields.indexOf(prop) > -1 && obj.options[prop] !== undefined) {
				options[prop] = obj.options[prop];
			}
		}
		obj.options = options;
		this.datas.post2({ data: { api: 'ROBookShare.create_multi_share', json: [obj] } }).then((res: any) => {
			this.shareModal.shares = res.shares.map(sh => {
				sh.short_url = null;
				sh.tagTitle = sh.tags ? sh.tags[0].title : 'n/a';
				return sh;
			});
			if (this.shareModal.genShareUrl) {
				this.genMultiShareUrl();
			} else {
				this.close.emit(this.shareModal);
			}
		});
	}

	genMultiShareUrl() {
		const bookId = this.shareModal.share.entry_id;
		const item = { id: bookId, type: this.shareModal.share.entry_type, title: this.shareModal.share.entry_title };
		const share = this.shareModal.shares.find(s => s.short_url == null);
		if (share) {
			this.datas.post2({ data: { api: 'ROBookShare.create_share_url', json: [share.id, bookId] } }).then((res: any) => {
				if (res.share) {
					share.short_url = res.share.short_url;
					setTimeout(() => { this.genMultiShareUrl(); }, 10);
				} else if (res.message) {
					this.als.alert(res.message).then(() => {
						this.close.emit(this.shareModal);
					});
				}
			});
		} else {
			this.load.remove('create-link');
			this.modal2.close();
			this.shortUrlsModal = { item: item, shares: this.shareModal.shares };
		}
	}

	copyToClipboard(copyValue, obj = null) {
		const inputEl: any = document.createElement("input");
		inputEl.value = copyValue;
		inputEl.style.opacity = "0";
		inputEl.readOnly = false;
		inputEl.contentEditable = true;
		document.body.appendChild(inputEl);
		inputEl.select();
		let range = document.createRange();
		range.selectNodeContents(inputEl);
		let s = window.getSelection();
		s.removeAllRanges();
		s.addRange(range);
		inputEl.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
		try {
			const successful = document.execCommand('copy');
			this.als.toastSuccess('bookshelf.copied-success');
		} catch (err) {
		}
		document.body.removeChild(inputEl);
		if (obj) {
			obj.copied = true;
			setTimeout(() => { obj.copied = false; }, 1000);
		}
	}

	onTargetSelectorConfirm($event) {
		console.log($event);
		this.targets = $event;
		this.shareModal.targetSelecting = false;
	}

	onTargetSelectorClose($event) {
		this.shareModal.targetSelecting = false;
	}

	targetSelectorOpen($event) {
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.shareModal.targetSelecting = false;
			this.selectorPromise = null;
		} else {
			this.shareModal.targetSelecting = true;
			setTimeout(() => {
				this.selectorPromise = this.targetSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.shareModal.targetSelecting = false;
					this.initTargets();
				});
			}, 200);
		}
	}

	initTargets() {
		if (this.shareModal && this.shareModal.targets && this.shareModal.targets.length > 0) {
			const targetTitles = this.shareModal.targets.map(t => t.title);
			this.tags_title = targetTitles.join(', ');
		} else if (this.shareModal && this.shareModal.tagId) {
			this.tags_title = this.shareModal.tag_name ? this.shareModal.tag_name : '';
			this.shareModal.targets = [{ tType: 'tag', title: this.tags_title, value: this.shareModal.tagId }];
			if (this.tags_title.indexOf('cid-') > -1) {
				this.tags_title = this.translate.instant('course.sc-individual');
			}
		} else {
			this.shareModal.targets = [];
			this.tags_title = this.translate.instant('commonService.please-select');
		}
	}

	subjectSelectorOpen($event) {
		if (this.selectorPromise && this.subjectSelector) {
			this.subjectSelector.bubbleBox2.closeFn();
			this.shareModal.subjectSelecting = false;
			this.selectorPromise = null;
		} else {
			this.shareModal.subjectSelecting = true;
			setTimeout(() => {
				this.selectorPromise = this.subjectSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.shareModal.subjectSelecting = false;
					this.initSubjects();
				}).catch(e => { //cancel will throw error
					this.selectorPromise = null;
					this.shareModal.subjectSelecting = false;
				});
			}, 200);
		}
	}

	initSubjects() {
		if (this.shareModal && this.shareModal.subjects && this.shareModal.subjects.length) {
			const subjectTitles = this.shareModal.subjects.map(s => s.title);
			this.subjects_title = subjectTitles.join(', ');
		} else if (this.shareModal && this.shareModal.subjectId) {

		} else {
			this.shareModal.subjects = [];
			this.subjects_title = this.translate.instant('commonService.please-select');
		}
	}

	initStartTime() {
		const modal = this.shareModal;
		if (modal.share.id) {
			const share = this.shareModal.share;
			modal.startTime = share.startTime;
			modal.endTime = share.endTime;
			modal.correctionStartTime = share.correctionStartTime;
			modal.correctionEndTime = share.correctionEndTime;
		} else {
			if (this.shareModal.startTime === undefined) {
				this.shareModal.startTime = moment();
			}
		}
	}

	updateExistShare(options) {
		const modal = this.shareModal;
		const bsid = modal.share.id;
		const book_id = modal.share.entry_id;
		let shareType = options.type === undefined ? null : options.type;
		shareType = shareType == null ? options.share_type : shareType;
		['ruby', 'emberald', 'crystal'].forEach(prop => {
			if (options.prize_type != prop) {
				options[prop] = 0;
			}
		});
		let clonedOptions:any = { type: shareType, short_url: modal.share.short_url, ruby: options.ruby ? options.ruby : 0, emberald: options.emberald ? options.emberald : 0, crystal: options.crystal ? options.crystal : 0, prize_type: options.prize_type ? options.prize_type : 'star'};

		const optionsKeys = ['pass_level','show_default_answer','auto_submit','live_verify','time_limit','max_submission_count','start_time','end_time','correction_start_time','correction_end_time','star','repeat_star','show_ranking','show_chart','show_chart_chart1','show_chart_chart2','show_chart_chart3','show_chart_chart4','show_chart_chart5','show_chart_chart6'];
		optionsKeys.forEach(prop=>{
			clonedOptions[prop] = options[prop];
		});

		const reqObj = { id: bsid, options: clonedOptions, tags: [modal.tagId], entries: [{ info: { book_id: book_id }, id: book_id }], subjects: modal.subjects };
		// this.api.post(['ROBookShare.edit_share', reqObj]).subscribe((res: any) => {
		// 	console.log(res);
		// });
		console.log(reqObj);
		this.load.add('edit_share', 5000);
		this.datas.post('ROBookshare.edit_share', [reqObj]).subscribe((res: any) => {
			this.load.remove('edit_share');
			this.close.emit(this.shareModal);
		});
	}


	generateClick($event) {
		$event.stopPropagation();
		this.load.add('gen', 3000);
		const bsid = this.shareModal.share.id;
		const bookId = this.shareModal.share.entry_id;
		this.datas.post('ROBookShare.create_share_url', [bsid, bookId]).subscribe((res: any) => {
			this.shareModal.share.short_url = res.share.short_url;
			this.load.remove('gen');
		});
	}

	copyClick($event) {
		const text = this.shareModal.share.short_url;
		$event.stopPropagation();
		const inputEl: any = document.createElement("input");
		inputEl.value = text;
		inputEl.style.opacity = "0";
		inputEl.readOnly = false;
		inputEl.contentEditable = true;
		document.body.appendChild(inputEl);
		inputEl.select();
		let range = document.createRange();
		range.selectNodeContents(inputEl);
		let s = window.getSelection();
		s.removeAllRanges();
		s.addRange(range);
		inputEl.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
		try {
			const successful = document.execCommand('copy');
		} catch (err) {
		}
		this.als.toastSuccess('bookshelf.copied-success');
		document.body.removeChild(inputEl);
	}

	reselectedClick() {
		this.modal2.close();
		let payload: any = { action: 'reselect-book' };
		if (this.shareModal.share.share_type == 'teacher') {
			payload.teacherUse = true;
		}
		if (this.shareModal.sourceFrom == 'uploadFile') {
			payload.action = 'reupload-file';
		}
		this.close.emit(payload);
	}

	initPersonalReportSettings(){
		const lang = this.datas.lang;
		this.showChartOptions = [
			{label: {tc:'按校本',sc:'按校本',en:'School-based'}[lang], value: 'default'},
			{label: {tc:'顯示',sc:'顯示',en:'Display'}[lang], value: '1'},
			{label: {tc:'不顯示',sc:'不顯示',en:'Hide'}[lang], value: '0'}];
		this.showRankingOptions = [
			{label: {tc:'按校本',sc:'按校本',en:'School-based'}[lang], value: 'default'},
			{label: {tc:'顯示',sc:'顯示',en:'Display'}[lang], value: '1'},
			{label: {tc:'不顯示',sc:'不顯示',en:'Hide'}[lang], value: '0'}];
		if (this.isForEdit){
			// this.shareModal.share.show_ranking = 
			// this.shareModal.show_chart_chart1
		} else {
			this.shareModal.share.show_ranking = 'default';
			this.shareModal.share.show_chart = 'default';
			this.shareModal.share.show_chart_chart1 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART1;
			this.shareModal.share.show_chart_chart2 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART2;
			this.shareModal.share.show_chart_chart3 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART3;
			this.shareModal.share.show_chart_chart4 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART4;
			this.shareModal.share.show_chart_chart5 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART5;
			this.shareModal.share.show_chart_chart6 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART6;
		}
		for(let i = 1; i < 7; i++){
			if (this.shareModal.share['show_chart_chart' + i] === undefined){
				this.shareModal.share['show_chart_chart' + i] = this.sts.schoolSettings['SHOW_CHART_PERSONAL_REPORT_CHART' + i];
			}
		}

	}

	showChartChange(field){
		const lang = this.datas.lang;
		if (field == 'ranking'){
			const el:any = document.querySelector('okapulldown2.show-ranking span.label');
			const showRanking = this.shareModal.share.show_ranking;
			if (showRanking == 'default'){
				if (this.sts.schoolSettings.SHOW_RANKING_PERSONAL_REPORT == '1'){
					el.innerText = {tc:'*顯示 (按校本)', sc:'*显示 (按校本)', en:'*Display (School-based)'}[lang];
				} else {
					el.innerText = {tc:'*不顯示 (按校本)', sc:'*不显示 (按校本)', en:'*Hide (School-based)'}[lang];
				}
			} else if (showRanking == '1'){
				el.innerText = {tc:'顯示', sc:'显示', en:'Display'}[lang];
			} else {
				el.innerText = {tc:'不顯示', sc:'不显示', en:'Hide'}[lang];
			}
			return;
		}
		const showChart = this.shareModal.share.show_chart;
		const el:any = document.querySelector('okapulldown2.show-chart span.label');
		if (showChart == 'default'){
			if (this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT == '1'){
				el.innerText = {tc:'*顯示 (按校本)', sc:'*显示 (按校本)', en:'*Display (School-based)'}[lang];
			} else {
				el.innerText = {tc:'*不顯示 (按校本)', sc:'*不显示 (按校本)', en:'*Hide (School-based)'}[lang];
			}
			this.shareModal.share.show_chart_chart1 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART1;
			this.shareModal.share.show_chart_chart2 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART2;
			this.shareModal.share.show_chart_chart3 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART3;
			this.shareModal.share.show_chart_chart4 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART4;
			this.shareModal.share.show_chart_chart5 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART5;
			this.shareModal.share.show_chart_chart6 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART6;
		} else if (showChart == '0'){
			el.innerText = {tc:'不顯示', sc:'不显示', en:'Hide'}[lang];
			this.shareModal.share.show_chart_chart1 = '0';
			this.shareModal.share.show_chart_chart2 = '0';
			this.shareModal.share.show_chart_chart3 = '0';
			this.shareModal.share.show_chart_chart4 = '0';
			this.shareModal.share.show_chart_chart5 = '0';
			this.shareModal.share.show_chart_chart6 = '0';
		} else {
			el.innerText = {tc:'顯示', sc:'显示', en:'Display'}[lang];
			this.shareModal.share.show_chart_chart1 = '1';
			this.shareModal.share.show_chart_chart2 = '1';
			this.shareModal.share.show_chart_chart3 = '1';
			this.shareModal.share.show_chart_chart4 = '1';
			this.shareModal.share.show_chart_chart5 = '1';
			this.shareModal.share.show_chart_chart6 = '1';
		}
	}

	toggleChange(){
		setTimeout(()=>{
			this.coms.waitFor(()=> document.querySelector('okapulldown2.show-ranking'), 100, 100).then(()=>{
				this.showChartChange('ranking');
				this.showChartChange('chart');
			}).catch(e=>{
				
			});
		}, 10);
	}


}
