import { Component, Input, HostListener, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'dateTimePickerSelectTime',
    template: `
<div class="rowTime">
    <div class="labelCol">{{{tc:'時',sc:'时',en:'hr'}[lang]}}: </div>
    <div class="selectCol">
        <div
            class="timeBtn"
            [class.selected]="getHour()==hour"
            [style.backgroundColor]="(getHour()==hour ? color1 : 'transparent')"
            *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12]"
            (click)="setHour(hour)"
        >{{hour}}</div>
    </div>
</div>
<div class="rowMinute">
    <div class="labelCol">{{{tc:'分',sc:'分',en:'min'}[lang]}}: </div>
    <div class="selectCol">
        <div
            class="timeBtn"
            [class.selected]="getMinute()==minute"
            [style.backgroundColor]="(getMinute()==minute ? color1 : 'transparent')"
            *ngFor="let minute of [00,05,10,15,20,25,30,35,40,45,50,55]"
            (click)="setMinute(minute)"
        >{{minute}}</div>
    </div>
</div>
<div class="rowAmPm">
    <div
        class="amPmBtn"
        [class.selected]="isAm()"
        [style.backgroundColor]="(isAm() ? color1 : 'transparent')"
        (click)="!isAm() && date.setHours(date.getHours() - 12) && timeClick.emit(date)"
    >am</div>
    <div
        class="amPmBtn"
        [class.selected]="!isAm()"
        [style.backgroundColor]="(!isAm() ? color1 : 'transparent')"
        (click)="isAm() && date.setHours(date.getHours() + 12) && timeClick.emit(date)"
    >pm</div>
</div>
    `,
    styleUrls: ['./dateTimePickerSelectTime.component.scss']
})

export class DateTimePickerSelectTimeComponent {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

    @Input() public lang:string;
    @Input() public color1:string;
    @Input() public color2:string;
    @Input() public date:Date = new Date();

    @Output() public timeClick:EventEmitter<Date> = new EventEmitter();

    constructor() {
    }

    public getHour():number {
        let hour:number = this.date.getHours();
        if (hour==0) return 12;
        else if (hour<13) return hour;
        else return hour - 12;
        //return hour < 13 ? hour : hour - 12;
    }

    public isAm():boolean {
        return this.date.getHours()<12;
    }

    public setHour(hour:number):void {
        if (this.isAm()) {
            if (hour==12) {
                this.date.setHours(0);
            } else {
                this.date.setHours(hour);
            }
        } else {
            if (hour==12) {
                this.date.setHours(12);
            } else {
                this.date.setHours(hour + 12);
            }
        }
        this.timeClick.emit(this.date);
    }

    public getMinute():number {
        return this.date ? this.date.getMinutes() : 0;
    }

    public setMinute(minute:number):void {
      this.date.setMinutes(minute);
      this.timeClick.emit(this.date);
    }


}
