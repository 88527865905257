import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { speechBox } from "./speechBox";
import { AlertService } from "src/app/service/alert.service";
import { ChatService } from "src/app/service/chatGPT.service";
import { DataService } from "src/app/service/data.service";
import { StorageService } from "src/app/service/storage.service";
import { LoadingService } from "../../loadingModule/loading.service";
import * as RecordRTC from 'recordrtc';
import { UploadService } from "../../uploadModule/upload.service";
import { ThemeService } from "src/app/service/theme.service";
import { environment } from "src/environments/environment";
import ro from "@mobiscroll/angular/dist/js/i18n/ro";

@Component({
	selector: "conversationPractice",
	templateUrl: "./conversationPractice.html",
	styleUrls: ["./conversationPractice.scss"],
})
export class conversationPractice {
	@Input() public context;
	@Input() public myData;
	@Input() public myRecorder;
	@Input() public lang;
	@Input() public parent;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	@ViewChild("speechBox", { static: false }) speechBoxContainer: speechBox;
	@ViewChild('dialogContainer', { static: false }) dialogContainer!: any;

	// For media
	public recorder: any = null;
	public stream: any = null;
	public timer;
	public recordingTime = 30;
	public recordTimer;
	public isResReceived = false;
	public status: string = "idle";							// "idle" / "recording" / "loading" / "playing"
	public robotImageStatus: string = "idle";				// "idle" / "talk" / "correct" / "wrong" / "wrong_last"
	public hintQuota = 3;
	public audio: HTMLAudioElement = new Audio();
	public speed = 1.1;
	public AiSpeedOptions: any[] = [
		{ label: "Slow", value: 0.9 },
		{ label: "Normal", value: 1.1 },
		{ label: "Fast", value: 1.3 },
	];

	// For EDB Question
	public objective = [
		{ number: 1, content: "Produce simple phrases and sentences involving repetition or lists" },
		{ number: 2, content: "Use simple phrases and sentences to communicate with others" },
		{ number: 3, content: "Connect ideas by using cohesive devices" },
		{ number: 4, content: "Give a simple description to express meaning of an unknown word" },
		{ number: 5, content: "Using single words and formulaic expressions to acknowledge, agree and disagree, ask questions and reply" },
		{ number: 6, content: "Providing information in response to factual or yes/no questions" },
		{ number: 7, content: "Verbalising inability to understand or asking for slower repetition of an utterance" },
		{ number: 8, content: "Asking for spelling" },
		{ number: 9, content: "Repeating questions and answers if they are not understood" },
	];
	public objectiveUsed = [];
	public EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0];

	// For Default Question
	public defaultQuestion = [];
	public questionIndex = 0;

	// Config
	public userName;
	public starNumberInExercise = 0;
	public totalStarNumber;
	public audioInfo = [];
	public promptLog = [];
	public conversation = [];
	public eachQuestionScore = [];
	public questionNumber = 1;

	public isAnswerDefaultQuestion = false;
	public userGreetingPass = false;
	public userGreetingCounter = 1;
	public userFirstEDBPass = false;
	public userSecondEDBPass = false;
	public defaultQuestionFinish = false;
	public receiveMyData = false;

	public aiOralChar = 'student';

	constructor(
		private chat: ChatService, public dataService: DataService, public storage: StorageService, private lds: LoadingService,
		private alert: AlertService, public upload: UploadService, public datas: DataService, private eleRef: ElementRef, private theme: ThemeService
	) {
		this.theme.applyStyleObj({
			"default": { "section-maincolor": "#0052AF" },
			"dark": { "section-maincolor": "#0052AF" }
		}, this.eleRef.nativeElement);
	}

	ngOnInit() {
		console.log(this.parent.conversationFinish)
		this.aiOralChar = sessionStorage.getItem('aiOralChar') || '1';
		this.preloadImage();
		this.userName = this.dataService.userInfo.last_name;
		this.setDefaultQuestion();
		setTimeout(() => {
			this.parent.totalPageNumber = this.defaultQuestion.length;
		}, 1000)
		if (!this.parent.conversationFinish) {
			this.greeting();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes)
		if (!this.receiveMyData &&
			changes.myData.currentValue.conversationData ||
			changes.myData.currentValue.conversationRecord) {
			this.receiveMyData = true;
			this.conversation = changes.myData.currentValue.conversationRecord;
		}
	}

	/////////////////////////////////////////
	//             Media function          //
	/////////////////////////////////////////
	public record(): void {
		this.status = "recording";
		this.timer = setInterval(() => {
			if (this.recordingTime === 0) {
				this.finishRecording();
			} else {
				this.recordingTime = this.recordingTime - 1;
			}
		}, 1000);


		navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
			let options = {
				mimeType: "audio/wav",
				numberOfAudioChannels: 1,
				desiredSampRate: 16000,
			};
			//Start Actuall Recording
			let StereoAudioRecorder: any = RecordRTC.StereoAudioRecorder;
			this.recorder = new StereoAudioRecorder(stream, options);
			this.stream = stream;
			this.recorder.record();
		});

	}

	public finishRecording(): void {
		this.status = "loading";
		this.recordingTime = 30;
		clearInterval(this.timer);
		this.recorder.stop((blob) => {
			let file: File = new File([blob], "record");

			let reference: string = this.context.createReference();
			let uid = this.context.service.dataService.userInfo.uid;

			if (this.parent.myData == null) {
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
				this.parent.myData = {
					user: uid,
					key: this.parent.fileReference.key,
					reference: this.parent.fileReference.reference
				}
			} else {
				this.parent.myData.user = uid;
				this.parent.myData.reference = reference;
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
			}

			this.parent.answerChanged = true;

			this.upload.uploadFileObject(file).then((res) => {
				const hostname = location.href.indexOf('localhost') > -1 ? 'dev.openknowledge.hk' : location.hostname;
				let filePath = '//' + hostname + '/RainbowOne/' + res.path;
				if(this.parent.subType === "oralAI"){
					//free conversation
					let file: File = new File([blob], "test.mp3");
					this.getSttApi(file, filePath);
				}else{
					this.ttsApi(filePath);
				}
			});

			let tracks = this.stream.getTracks();
			for (let track of tracks) track.stop();
		});
		this.status = "idle";
	}

	private  getSttApi(wavFile: File, filePath): Promise<void> {
		let data: FormData = new FormData();
		data.append('api', 'ROMediaLibrary.getSttFromWav');
		data.append('file', wavFile);

		var sttLangCode = "en-GB";
		data.append('json', JSON.stringify([sttLangCode]));

		return this.datas.post2({ data: data, loading: true }).then(async (res: any) => {
			if(!res.data || res.data.length == 0){
				setTimeout(() => {
					this.alert.alert2(this.translateText(
						"偵測不到聲音，請重新再試。",
						"偵測不到聲音，請重新再試。",
						"Cannot detect the sound recording, please try again"
					), null, { btns: [["ok"]] });
				}, 2000);
			}
			var message:string = "";
			if (res.data) {
				for (let data of res.data) {
					message = data.transcript;
				}
			}
			console.log("message", message);
			this.promptLog.push({ role: "user", content: message })
			this.conversation.push({ role: "user", content: message, audioPath: filePath })
			console.log(this.promptLog);
			
			let promptInput =  [...this.promptLog].filter(message=> !message.isHint).slice(-4);
			promptInput.unshift(this.promptLog[0])
			const robotRes = await this.sentPromptApi(promptInput);
			this.scrollToBottom();
			this.lds.remove("chatResponse");
			let content = JSON.parse(robotRes).choices[0].message.content;
		// }

		this.playAudio(content, this.replaceSymbol(content), false);
		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
			return;
		}).catch((err: any) => {
			return;
		});
	}

	async ttsApi(filePath: string) {
		let lang = "en-US";
		let dataWithAnswer: FormData = new FormData();
		let dataWithoutAnswer: FormData = new FormData();
		dataWithAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithoutAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		if (this.parent.subType === "oralFillIn") {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.defaultQuestion[this.questionIndex].answer, lang]));
		} else {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.defaultQuestion[this.questionIndex].name, lang]));
		}
		dataWithoutAnswer.append("json", JSON.stringify([filePath, "", lang]));

		try {
			this.isResReceived = false;
			let firstRecordTime = Date.now();

			const recordTimeCount = setInterval(async () => {
				this.recordTimer = Date.now();
				console.log(this.recordTimer - firstRecordTime)

				// 超時
				if (this.recordTimer - firstRecordTime > 10000 && !this.isResReceived) {
					clearInterval(recordTimeCount);
					this.alert.toastError(this.translateText(
						"網絡繁忙，自動重試中，請稍候",
						"网络繁忙，自动重试中，请稍后",
						"Network latency, repeat processing automatically, result is going to release"
					));
				}
			}, 1000);

			if (this.isAnswerDefaultQuestion) {
				const firstRequest = await this.dataService.post2({ data: dataWithAnswer, loading: true });
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: true });
				Promise.all([firstRequest, secondRequest]).then(([firstRequest, secondRequest]) => {
					if (!firstRequest.result || !secondRequest.result ||
						firstRequest.result.RecognitionStatus === "InitialSilenceTimeout" ||
						secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						if (this.parent.subType === "oralVocab" || this.parent.subType === "oralMc") {
							this.conversation.push({ role: "user", content: secondRequest.result.DisplayText, audioPath: filePath })
						} else if (this.parent.subType === "oralFillIn") {
							let userAnswer = secondRequest.result.NBest[0].Lexical.charAt(0).toUpperCase() + secondRequest.result.NBest[0].Lexical.slice(1);
							this.conversation.push({ role: "user", content: userAnswer, audioPath: filePath })
						} else if (this.parent.subType === "oralSentence" || this.parent.subType === "oralParagraph") {
							this.conversation.push(
								{
									role: "user",
									content: this.defaultQuestion[this.questionIndex].name,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						}
						this.promptLog.push({ role: "user", content: secondRequest.result.DisplayText })

						console.log(firstRequest.result)
						this.eachQuestionScore.push({
							PronScore: Math.round(firstRequest.result.NBest[0].PronScore),
							AccuracyScore: Math.round(firstRequest.result.NBest[0].AccuracyScore),
							CompletenessScore: Math.round(firstRequest.result.NBest[0].CompletenessScore),
							FluencyScore: Math.round(firstRequest.result.NBest[0].FluencyScore)
						})
						this.scrollToBottom();
						if (this.parent.subType === "oralVocab" || this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
							console.log(secondRequest.result)
							this.checkPronunciation(secondRequest.result);
						} else if (this.parent.subType === "oralSentence" || this.parent.subType === "oralParagraph") {
							console.log(firstRequest.result)
							this.checkPronunciation(firstRequest.result);
						}
					}
				})
			} else {
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: true });
				Promise.all([secondRequest]).then(([secondRequest]) => {
					if (!secondRequest.result || secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						console.log(secondRequest.result)
						this.promptLog.push({ role: "user", content: secondRequest.result.DisplayText })
						this.conversation.push({ role: "user", content: secondRequest.result.DisplayText, audioPath: filePath })

						this.scrollToBottom();
						if (!this.userGreetingPass) this.checkUserGreeting(secondRequest.result.DisplayText);
						else if (!this.userFirstEDBPass) this.checkUserFirstEDB(secondRequest.result.DisplayText);
						else if (!this.userSecondEDBPass) this.checkUserSecondEDB(secondRequest.result.DisplayText);
					}
				})
			}
		} catch (err) {
			console.error("Assessment Fail", err);
		}
	}

	/////////////////////////////////////////
	//           GPT chat function         //
	/////////////////////////////////////////
	sentPromptApi(prompt: any[]) {
		// without queue
		this.lds.add("chatResponse");
		return this.chat.oralChat(prompt, "EP_AI_ENG_CHAT");
	}

	async greeting() {
		let prompt = {
			role: "system",
			content: `You're a dedicated primary school teacher with a passion for engaging students in meaningful learning experiences. Your goal is to hold an individual oral training session with a student named ${this.userName}, focusing on the topic of 'Food and Drink.' Begin the session by greeting ${this.userName} warmly. After the greeting, simply explain what the topic is about. Your responses should be clear, friendly, and concise. Remember to encourage ${this.userName} to share his thoughts and experiences related to food and drink throughout the session. In the following discussion, if there is any foul language, sexual or racism content, warn the student to stop. Avoid using symbol like: -/’ and ensure the response is limited in 20 words.`
		};

		if(this.parent.subType === "oralAI"){
			prompt = {
				role: "system",
				content: `Assistant is user's best friend John, Assistant has been living in Germany for five years and will be returning to Hong
							Kong during the Easter holidays. Assistant misses the local food in Hong Kong and wants user to take
							him to user's favorite restaurant. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
			};
		}

		this.promptLog.push(prompt);


		try {
			let content = "";
			if(this.parent.subType === "oralAI"){
				content = `Hi ${this.userName}, It's so great to be back in Hong Kong! I've really missed the food here. What restaurant do you recommend we go to?`
			}else{
				const res = await this.sentPromptApi(this.promptLog);
				this.lds.remove("chatResponse");
				content = JSON.parse(res).choices[0].message.content;
			}

			this.playAudio(content, this.replaceSymbol(content), false);
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

		} catch (error) {
			console.log(error);
		}
	}

	async checkUserGreeting(content: string) {
		const prompt = {
			role: "system",
			content: `Determine whether ${content} is a correct greet response to the you or not. Return "true" / "false".`,
		}
		this.promptLog.push(prompt)

		try {
			const res = await this.sentPromptApi(this.promptLog);
			this.lds.remove("chatResponse");
			const content = JSON.parse(res).choices[0].message.content;
			let isCorrectGreet = content === "true";
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

			let resPrompt = { role: "system", content: "", };
			if (this.userGreetingCounter < 2) {						// 1st greeting
				if (isCorrectGreet) {
					resPrompt.content = `Your task is to respond ${this.userName}'s greeting. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 20 words.`;
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						this.lds.remove("chatResponse");
						const content = JSON.parse(res).choices[0].message.content;

						this.playAudio(content, `${this.replaceSymbol(content)}🙂`, false);
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

						this.userGreetingPass = isCorrectGreet;

						let duration = this.audioInfo[this.audioInfo.length - 1].duration;
						setTimeout(() => {
							this.firstEDBQuestion();
						}, duration * 1000)
					} catch (error) {
						console.log(error);
					}
				} else {
					resPrompt.content = `Your task is to explain the correct way to greet someone and greet ${this.userName} warmly at the end of your response. Avoid using symbol like: -/’ and ensure the response is limited in 20 words.`;
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						this.lds.remove("chatResponse");
						const content = JSON.parse(res).choices[0].message.content;

						this.playAudio(content, `${this.replaceSymbol(content)}😐`, false);
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

						this.userGreetingCounter++;
					} catch (error) {
						console.log(error);
					}
				}
			} else if (this.userGreetingCounter > 1) {				// 2nd greeting
				if (isCorrectGreet) {
					resPrompt.content = `Your task is to respond ${this.userName}'s greeting. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 20 words.`;
				} else {
					resPrompt.content = `Your task is to guide the student through a conversation, gently suggesting that ${this.userName} might need more practice with greetings. Please respond to the ${this.userName} by first acknowledging the current progress and then suggesting that ${this.userName} could benefit from additional greeting practice. Make sure to keep the tone positive and motivating. Avoid using symbol like: -/’ and ensure the response is limited in 20 words.`;
				}
				this.promptLog.push(resPrompt);

				try {
					const res = await this.sentPromptApi(this.promptLog);
					this.lds.remove("chatResponse");
					const content = JSON.parse(res).choices[0].message.content;

					this.playAudio(content, `${this.replaceSymbol(content)}🙂`, false);
					this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

					this.userGreetingPass = isCorrectGreet;

					let duration = this.audioInfo[this.audioInfo.length - 1].duration;
					setTimeout(() => {
						this.firstEDBQuestion();
					}, duration * 1000)
				} catch (error) {
					console.log(error);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async firstEDBQuestion() {
		const prompt = {
			role: "system",
			content: `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to inform ${this.userName} that you are going to start asking a few simple questions about today's topic. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`
		};

		this.promptLog.slice(0, 1);
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			this.lds.remove("chatResponse");
			const content = JSON.parse(res).choices[0].message.content;

			this.playAudio(content, this.replaceSymbol(content), false);
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })


			if (res) {
				let prompt = {
					role: "system",
					content: `Your goal is to stimulate critical thinking and ensure deep understanding of the material. Your task is to generate a question based on one of the specific educational objectives from ${this.objective}. Return the response with the format: [objective's number, "question you generated"], the question you generated must with quotes and put into square brackets. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`
				};
				this.promptLog.push(prompt)

				const res = await this.sentPromptApi(this.promptLog);
				this.lds.remove("chatResponse");
				const question = JSON.parse(res).choices[0].message.content;
				const questionString = this.modifiedString(question)
				const array = JSON.parse(questionString)

				let duration = this.audioInfo[this.audioInfo.length - 1].duration;
				setTimeout(() => {
					this.playAudio(array[1], this.replaceSymbol(`${array[0]} ${array[1]}`), false);
				}, duration * 1000)

				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(questionString) });

				this.objectiveUsed.push(array[0]);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserFirstEDB(content: string) {
		const prompt = {
			role: "system",
			content: `Determine whether ${content} is a correct response to your question or not, and give score over 100 based on how the content relates to the ${this.objective[this.objectiveUsed.length - 1].content}. Return with the format: [true / false, the score], the response must put into an array. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`,
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			this.lds.remove("chatResponse");
			const content = JSON.parse(res).choices[0].message.content;
			this.EDBScore[this.objectiveUsed[0] - 1] = parseInt(`${content[content.length - 3]}${content[content.length - 2]}`);
			this.parent.EDBScore[this.objectiveUsed[0] - 1] = parseInt(`${content[content.length - 3]}${content[content.length - 2]}`);
			let jsonObject = JSON.parse(content);

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let resPrompt = { role: "system", content: "", };
			if (res) {
				if (jsonObject[0]) {
					resPrompt.content = `${this.userName} did well in the last question, your task is to praise ${this.userName} for the performance. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`;
				} else {
					resPrompt.content = `${this.userName} could perform better in the last question, your task is to encourage ${this.userName}. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`;
				}
				this.promptLog.push(resPrompt);

				const res = await this.sentPromptApi(this.promptLog);
				this.lds.remove("chatResponse");
				const content = JSON.parse(res).choices[0].message.content;
				this.playAudio(content, this.replaceSymbol(content), false);

				let duration = this.audioInfo[this.audioInfo.length - 1].duration + 2;
				setTimeout(() => {
					console.log(this.audioInfo[this.audioInfo.length - 1])
					console.log(duration)

					this.secondEDBQuestion();
				}, duration * 1000)

				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

				this.userFirstEDBPass = true;
			}
		} catch (error) {
			console.log(error);
		}
	}

	async secondEDBQuestion() {
		let prompt = {
			role: "system",
			content: `Your goal is to stimulate critical thinking and ensure deep understanding of the material. Your task is to generate a question based on one of the specific educational objectives from ${this.objective}. Return with the format: [objective's number, "question you generated"], the question you generated must with quotes and avoid using the objective number ${this.objectiveUsed[this.objectiveUsed.length - 1]}. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`
		};
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			this.lds.remove("chatResponse");
			const question = JSON.parse(res).choices[0].message.content;
			const questionString = this.modifiedString(question)
			const array = JSON.parse(questionString)

			let duration = this.audioInfo[this.audioInfo.length - 1].duration;
			this.playAudio(array[1], this.replaceSymbol(`${array[0]} ${array[1]}`), false);

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(questionString) });

			this.objectiveUsed.push(array[0]);
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserSecondEDB(content: string) {
		const prompt = {
			role: "system",
			content: `Determine whether ${content} is a correct response to your question or not, and give score over 100 based on how the content relates to the ${this.objective[this.objectiveUsed.length - 1].content}. Return with the format: [true / false, the score], the response must put into an array. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`,
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			this.lds.remove("chatResponse");
			const content = JSON.parse(res).choices[0].message.content;
			console.log(content)
			this.EDBScore[this.objectiveUsed[1] - 1] = parseInt(`${content[content.length - 3]}${content[content.length - 2]}`);
			this.parent.EDBScore[this.objectiveUsed[1] - 1] = parseInt(`${content[content.length - 3]}${content[content.length - 2]}`);
			let jsonObject = JSON.parse(content);

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let resPrompt = { role: "system", content: "", };
			if (res) {
				if (jsonObject[0]) {
					resPrompt.content = `${this.userName} did well in the last question, your task is to praise ${this.userName} for the performance. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`;
				} else {
					resPrompt.content = `${this.userName} could perform better in the last question, your task is to encourage ${this.userName}. Keep in mind that your tone should be warm and encouraging, making the user feel welcomed and motivated to continue with the training. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`;
				}
				this.promptLog.push(resPrompt);

				const res = await this.sentPromptApi(this.promptLog);
				this.lds.remove("chatResponse");
				const content = JSON.parse(res).choices[0].message.content;

				console.log(this.audioInfo)
				let duration = this.audioInfo[this.audioInfo.length - 1].duration;
				setTimeout(() => {
					console.log(content)
					this.playAudio(content, this.replaceSymbol(content), false);
				}, duration * 1000)
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
				console.log(this.audioInfo)
				duration = this.audioInfo[this.audioInfo.length - 1].duration;
				setTimeout(() => {
					this.defaultQuestionSession();
				}, duration * 1000)

				this.userSecondEDBPass = true;
			}
		} catch (error) {
			console.log(error);
		}
	}

	async defaultQuestionSession() {
		this.isAnswerDefaultQuestion = true;
		try {
			if (this.questionIndex < 1) {
				const prompt = {
					role: "system",
					content: `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to inform ${this.userName} that you are going to start the exercise about today's topic. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 15 words.`
				};
				this.promptLog.slice(0, 1);
				this.promptLog.push(prompt);

				const res = await this.sentPromptApi(this.promptLog);
				this.lds.remove("chatResponse");
				const content = JSON.parse(res).choices[0].message.content;
				console.log(content)

				this.playAudio(content, this.replaceSymbol(content), false);
				let duration = this.audioInfo[this.audioInfo.length - 1].duration;
				console.log(duration)
				setTimeout(() => {
					this.askDefaultQuestion();
				}, duration * 1000)

				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
			} else {
				let duration = this.audioInfo[this.audioInfo.length - 1].duration;
				console.log(duration)
				setTimeout(() => {
					this.askDefaultQuestion();
				}, duration * 1000)
			}
		} catch (error) {
			console.log(error)
		}
	}

	async askDefaultQuestion() {
		let intro = "";
		let question = this.defaultQuestion[this.questionIndex].name;
		let reading = this.defaultQuestion[this.questionIndex].reading;
		let audioContent;

		if (this.parent.subType === "oralFillIn") {
			intro = `Please answer the missing part of the phrase:`
		} else if (this.parent.subType === "oralMc") {
			intro = `Which response would you choose, if someone says:`
		} else if (this.parent.subType === "oralParagraph") {
			intro = `Please read the paragraph:`
		} else if (this.parent.subType === "oralSentence") {
			intro = `Please read the phrase:`
		} else if (this.parent.subType === "oralVocab") {
			intro = `Please read the vocab:`
		}

		if (this.questionIndex < 1) {
			if (this.parent.subType === "oralFillIn") {
				const split = question.split("(");
				let firstPartReadingText = split[0].trim();
				let secondPartReadingText = split[1].split(")")[1].trim();
				let fillInTypeTopic = `${intro} ${firstPartReadingText} ${''} ${secondPartReadingText}`;
				console.log(fillInTypeTopic)

				if (this.parent.difficulty !== "hard") {
					audioContent = { intro: `${intro} ${reading}`, question: `${intro} ${question}` };
				} else {
					audioContent = { intro: fillInTypeTopic, question: `${intro} ${question}` };
				}
			} else if (this.parent.subType === "oralMc") {
				audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
			} else {
				if (this.parent.difficulty !== "hard") {
					audioContent = { intro: `${intro} ${question}`, question: question };
				} else {
					audioContent = { intro: intro, question: `${intro} ${question}` };
				}
			}

			let duration = this.audioInfo[this.audioInfo.length - 1].duration;
			console.log(duration)
			setTimeout(() => {
				if (this.parent.subType === "oralFillIn") {
					this.playAudio(audioContent.intro, audioContent.question, true);
				} else if (this.parent.subType === "oralMc") {
					this.playAudio(audioContent.intro, audioContent.question, true);

					if (this.parent.difficulty !== "hard") {
						let duration = this.audioInfo[this.audioInfo.length - 1].duration;
						setTimeout(() => {
							let content = `Choice A: ${this.defaultQuestion[this.questionIndex].choice[0]}`
							this.playAudio(content, this.replaceSymbol(content), false);

							let duration = this.audioInfo[this.audioInfo.length - 1].duration;
							setTimeout(() => {
								let content = `Choice B: ${this.defaultQuestion[this.questionIndex].choice[1]}`
								this.playAudio(content, this.replaceSymbol(content), false);
							}, duration * 1000)
						}, duration * 1000)
					} else {
						let duration = this.audioInfo[this.audioInfo.length - 1].duration;
						setTimeout(() => {
							let contentA = `Choice A: ${this.defaultQuestion[this.questionIndex].choice[0]}`
							this.conversation.push({ role: "assistant", content: contentA });
							let contentB = `Choice B: ${this.defaultQuestion[this.questionIndex].choice[1]}`
							this.conversation.push({ role: "assistant", content: contentB });

							this.scrollToBottom();
						}, duration * 1000)
					}
				} else {
					this.playAudio(audioContent.intro, audioContent.question, true);
				}
			}, duration * 1000)

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(intro) });

			this.parent.totalPageNumber = this.defaultQuestion.length;
			this.parent.pageIndex = this.questionNumber;
		} else {
			if (this.parent.subType === "oralFillIn") {
				const split = question.split("(");
				let firstPartReadingText = split[0].trim();
				let secondPartReadingText = split[1].split(")")[1].trim();
				let fillInTypeTopic = [`${intro} ${firstPartReadingText}`, secondPartReadingText];
				console.log(fillInTypeTopic)

				if (this.parent.difficulty !== "hard") {
					audioContent = { intro: `${intro} ${reading}`, question: `${intro} ${question}` };
				} else {
					audioContent = { intro: fillInTypeTopic, question: `${intro} ${question}` };
				}
			} else if (this.parent.subType === "oralParagraph") {
				if (this.parent.difficulty !== "hard") {
					audioContent = { intro: intro, question: `${intro} ${question}` };
				}
			} else if (this.parent.subType === "oralMc") {
				audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
			} else {
				if (this.parent.difficulty !== "hard") {
					audioContent = { intro: `${intro} ${question}`, question: question };
				} else {
					audioContent = { intro: intro, question: `${intro} ${question}` };
				}
			}

			let duration = this.audioInfo[this.audioInfo.length - 1].duration;
			console.log(duration)
			setTimeout(() => {
				if (this.parent.subType === "oralFillIn") {
					this.playAudio(audioContent.intro, audioContent.question, true);
				} else if (this.parent.subType === "oralMc") {
					this.playAudio(audioContent.intro, audioContent.question, true);

					if (this.parent.difficulty !== "hard") {
						let duration = this.audioInfo[this.audioInfo.length - 1].duration;
						setTimeout(() => {
							let content = `Choice A: ${this.defaultQuestion[this.questionIndex].choice[0]}`
							this.playAudio(content, this.replaceSymbol(content), false);

							let duration = this.audioInfo[this.audioInfo.length - 1].duration;
							setTimeout(() => {
								let content = `Choice B: ${this.defaultQuestion[this.questionIndex].choice[1]}`
								this.playAudio(content, this.replaceSymbol(content), false);
							}, duration * 1000)
						}, duration * 1000)
					} else {
						let duration = this.audioInfo[this.audioInfo.length - 1].duration;
						setTimeout(() => {
							let contentA = `Choice A: ${this.defaultQuestion[this.questionIndex].choice[0]}`
							this.conversation.push({ role: "assistant", content: contentA });
							let contentB = `Choice B: ${this.defaultQuestion[this.questionIndex].choice[1]}`
							this.conversation.push({ role: "assistant", content: contentB });

							this.scrollToBottom();
						}, duration * 1000)
					}
				} else {
					this.playAudio(audioContent.intro, audioContent.question, true);
				}
			}, duration * 1000)

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(intro) });

			this.parent.totalPageNumber = this.defaultQuestion.length;
			this.parent.pageIndex = this.questionNumber;
		}
	}

	async checkPronunciation(content: any) {
		let prompt = { role: "system", content: "", };
		if (this.parent.subType === "oralVocab") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.DisplayText} as the same as ${this.defaultQuestion[this.questionIndex].name} and ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If one of these criteria's result is false, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralSentence") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralFillIn") {
			let userAnswer = content.NBest[0].Lexical.charAt(0).toUpperCase() + content.NBest[0].Lexical.slice(1);
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${userAnswer} as the same as ${this.defaultQuestion[this.questionIndex].answer} and ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralMc") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.DisplayText} as the same as ${this.defaultQuestion[this.questionIndex].answer} and ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		} else if (this.parent.subType === "oralParagraph") {
			prompt.content = `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to determine does ${content.NBest[0].PronScore} greater than ${this.parent.ctx.passLevel}. If not, encourage ${this.userName} practice more on the pronunciation. Otherwise, praise ${this.userName} did well. Do not use the number as the response and end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 5 words.`
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			this.lds.remove("chatResponse");
			const content = JSON.parse(res).choices[0].message.content;
			console.log(content)

			this.playAudio(content, this.replaceSymbol(content), false);
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });


			if (this.questionIndex < this.defaultQuestion.length - 1) {
				this.questionIndex++;
				this.questionNumber++;
				let duration = this.audioInfo[this.audioInfo.length - 1].duration;
				setTimeout(() => {
					this.defaultQuestionSession();
				}, duration * 1000)
			} else {
				let duration = this.audioInfo[this.audioInfo.length - 1].duration;
				setTimeout(() => {
					this.finishing();
				}, duration * 1000)
			}
		} catch (error) {
			console.log(error)
		}
	}

	public async finishing() {
		const prompt = [
			{
				role: "system",
				content: `Your task is to inform ${this.userName} that the exercise is completed and final report would be generated. Do not end with interrogative sentence. Avoid using symbol like: -/’ and ensure the response is limited in 10 words.`,
			},
		];
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(prompt);
			this.lds.remove("chatResponse");
			const content = JSON.parse(res).choices[0].message.content;

			let duration = this.audioInfo[this.audioInfo.length - 1].duration;
			console.log(duration)
			setTimeout(() => {
				this.playAudio(content, this.replaceSymbol(content), false);


				this.passingBackToParent();
			}, duration * 1000)
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

		} catch (error) {
			console.log(error);
		}
	}

	/////////////////////////////////////////
	//          js handling function       //
	/////////////////////////////////////////
	public scrollToBottom(): void {
		if (this.dialogContainer) {
			const container = this.dialogContainer.nativeElement;
			setTimeout(() => {
				container.scrollTop = container.scrollHeight;
			}, 500);
		}
	}

	public translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}

	public extractValue(stringOfObject) {
		const regex = /"[^"]+"\s*:\s*(true|false)/;
		const match = stringOfObject.match(regex);
		if (match) {
			if (match[1] === "true") return true;
			else return false;
		}
		else return false;
	}

	public setRobotImageStatus(status: string) {
		if (this.robotImageStatus === status) return;
		this.robotImageStatus = status;
		if (status === "wrong") {
			setTimeout(() => {
				this.setRobotImageStatus("wrong_last");
			}, 3000);
		} else if (status === "correct") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			});
		} else if (status === "wrong_last") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			}, 3000);
		}
	}

	public playAudio(text: string, conversationContent: string, askDefaultQuestion: boolean): void {
		this.getAudioDuration(text).then(res => {
			this.audioInfo.push({ url: res.url, duration: res.duration })
			this.conversation.push({ role: "assistant", content: this.replaceSymbol(conversationContent) });

			if (askDefaultQuestion) {
				if (this.parent.subType === "oralVocab") this.conversation.push({ role: "assistant", content: this.defaultQuestion[this.questionIndex].url });
			}

			this.scrollToBottom();
			this.status = 'playing'
			this.setRobotImageStatus("talk");
			this.audio.setAttribute("src", res.url);
			this.audio.play();

			setTimeout(() => {
				this.setRobotImageStatus("idle");
				this.status = 'idle'
			}, res.duration * 1000);
		});
	}

	public getAudioDuration(text: string): Promise<any> {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: 'F',
			txt: text,
			speed: this.speed,
			lang: 'en-GB',
			pitch: 1,
			name: "en-GB-LibbyNeural",
			style: "",
		};
		this.setRobotImageStatus("talk");

		return new Promise((resolve) => {
			this.datas.get2({ url: url, data: data, jwt: false }).then((res: any) => {
				if (res.token && res.token.url) {
					let statusUrl: string = res.token.url;
					if (statusUrl.substr(0, 7) == "http://") statusUrl = "https://" + statusUrl.substring(7);
					this.datas.get2({ url: statusUrl, jwt: false }).then((res: any) => {
						if (res.url) {
							const audio = new Audio(`${res.url}`);
							audio.addEventListener('loadedmetadata', () => {
								const url = res.url;
								const duration = Math.round(audio.duration);
								resolve({ url, duration });
							});
						}
					});
				}
			});
		});
	}

	public passingBackToParent() {
		let duration = this.audioInfo[this.audioInfo.length - 1].duration + 4;
		setTimeout(() => {
			this.parent.isOralVocabReportOpen = true;
			this.parent.conversationData = this.eachQuestionScore;
			this.parent.myData.conversationData = this.eachQuestionScore;
			this.parent.myData.conversationFinish = true;
			this.parent.myData.objectiveUsed = this.objectiveUsed;
			this.parent.myData.EDBScore = this.EDBScore;
			let stringifyResult = JSON.stringify(this.conversation);
			let uriEncodeResult = encodeURIComponent(stringifyResult);
			let encodeResult = btoa(uriEncodeResult);
			this.parent.myData.conversationRecord = encodeResult;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, duration * 1000)
	}

	public listenButtonClicked(filePath) {
		this.audio.setAttribute("src", filePath);
		this.audio.play();
	}

	public replaceSymbol(content) {
		if (content.includes('’')) {
			content = content.replace(/’/g, "'");
		}
		if (content.includes('"')) {
			content = content.replace(/"/g, "");
		}
		if (content.includes(';')) {
			content = content.replace(/-/g, ",");
		}
		return content;
	}

	public setDefaultQuestion() {
		if (this.parent.subType === "oralVocab") {
			this.defaultQuestion = [
				{
					name: "Juice",
					url: "https://oka.blob.core.windows.net/media/ai-composition/2024/08/20/school(272)/time(234414)-Juice.png"
				},
				{
					name: "Waiter",
					url: "https://oka.blob.core.windows.net/media/ai-composition/2024/08/20/school(272)/time(234550)-Waiter.png"
				},
				// {
				// 	name: "Egg",
				// 	url: "https://oka.blob.core.windows.net/media/ai-composition/2024/08/20/school(272)/time(234611)-Egg.png"
				// },
				// {
				// 	name: "Coffee",
				// 	url: "https://oka.blob.core.windows.net/media/ai-composition/2024/08/20/school(272)/time(234625)-Coffee.png"
				// },
				// {
				// 	name: "Plate",
				// 	url: "https://oka.blob.core.windows.net/media/ai-composition/2024/08/20/school(272)/time(234640)-Plate.png"
				// }
			]
		} else if (this.parent.subType === "oralSentence") {
			this.defaultQuestion = [
				// {
				// 	name: "This restaurant is always open and serves breakfast, lunch and dinner."
				// },
				// {
				// 	name: "Waiter, may I have some scrambled eggs, a cup of coffee, and two glasses of orange juice?"
				// },
				{
					name: "This special curry dish tastes both sweet and spicy."
				},
				{
					name: "Careful, sir. The plate is hot."
				},
				// {
				// 	name: "There is an extra charge if you wish to bring your own bottle of wine."
				// }
			]
		} else if (this.parent.subType === "oralFillIn") {
			this.defaultQuestion = [
				{
					name: "My wife and I would like a table for () please.",
					reading: "My wife and I would like a table for two please.",
					answer: "Two"
				},
				// {
				// 	name: "May I have a ____ of water, please?",
				// 	reading: "May I have a glass of water, please?",
				// 	answer: "Glass"
				// },
				// {
				// 	name: "Careful, sir. The ____ is hot.",
				// 	reading: "Careful, sir. The plate is hot.",
				// 	answer: "Plate"
				// },
				{
					name: "Enjoy your (), sir.",
					reading: "Enjoy your meal, sir.",
					answer: "Meal"
				}
			]
		} else if (this.parent.subType === "oralMc") {
			this.defaultQuestion = [
				{
					name: "Good morning. Would you like some water?",
					choice: ["I don't drink water.", "Yes, I would like some water."],
					answer: "Yes, I would like some water."
				},
				{
					name: "Are you ready to order?",
					choice: ["Not yet. Could you give me a few more minutes?", "I don't want to order."],
					answer: "Not yet. Could you give me a few more minutes?"
				},
				{
					name: "How is your meal?",
					choice: ["It is yum yum, so no thank you.", "It's delicious, thank you for asking."],
					answer: "It's delicious, thank you for asking."
				},
				{
					name: "Is there anything else I can get you?",
					choice: ["No, thank you.", "No, I want more to drink."],
					answer: "No, thank you."
				}
			]
		} else if (this.parent.subType === "oralParagraph") {
			this.defaultQuestion = [
				{
					name: `Tonight Mum taught me how to make pumpkin soup. 
					
					First, I heated the butter on a saucepan over medium heat. 
					
					When it melted, Mum added some chopped onion and cooked them for two minutes. 
					
					Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat.`,
				}
			]
		}
	}

	public modifiedString(string: string): string {
		return string.replace(/(\d+), (.+)\]/, (match, p1, p2) => {
			// Check if p2 is already enclosed in quotes
			if (p2.startsWith('"') && p2.endsWith('"')) {
				return `${p1}, ${p2}]`;
			} else {
				return `${p1}, "${p2}"]`;
			}
		});
	}

	preloadImage() {
		let url = ['oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/robot_waiter_talk.png', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_correct.png', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong.gif', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong_last.png'];
		if (this.aiOralChar == '2') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/panda_talking.png');
		} else if (this.aiOralChar == '3') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/robot_talk.png');
		} else {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/student_talk.png');
		}
		url.forEach(e => {
			const imgEle = new Image();
			imgEle.src = '//' + e;
		});
	}

	public readText(text: string): void {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: 'F',
			txt: text,
			speed: this.speed,
			lang: 'en-GB',
			pitch: 1,
			name: "en-GB-LibbyNeural",
			style: "",
		};
		this.setRobotImageStatus("talk");

		this.datas.get2({ url: url, data: data, jwt: false }).then((res: any) => {
			if (res.token && res.token.url) {
				let statusUrl: string = res.token.url;
				if (statusUrl.substr(0, 7) == "http://")
					statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
				this.datas
					.get2({ url: statusUrl, jwt: false })
					.then((res: any) => {
						if (res.url) {
							this.audio.setAttribute("src", res.url);
							this.audio.play();
							setTimeout(() => {
								this.setRobotImageStatus("idle");
							}, 5000);

							//});
						}
					});
			}
		});
	}

	public 	getHint() {
		if(this.hintQuota <= 0){
			return
		}
		//只用最後4條訊息獲取對話 節省tokens
		var tempConversation = [...this.conversation].filter(message=> !message.isHint).slice(-4);
		tempConversation.push(
			{
				role: 'user',
				content: "How should I respond your question? give me an example in 20 words"
			}
		)

		this.chat.oralChat(tempConversation).then(res => {
			let content = JSON.parse(res).choices[0].message.content;
			this.conversation.push({ role: "user", content: this.replaceSymbol(content), isHint:true });
			this.hintQuota--;
			this.scrollToBottom();
		});

		//with queue
		// this.lds.add("chatResponse");
		// this.robotStartTime = new Date();
		// this.chat.oralChatQueue(tempConversation).then(res=>{
		// 	console.log(res);
		// 	if(res && res.token){
		// 		this.checkGPTResponse(res.token, true);
		// 	}
		// });
	}

	// markCoversation(conversation, criteria = ["content", "grammer", "creativity"]) {
	// 	let criteriaString = criteria.join(",");
	// 	conversation.push(
	// 		{
	// 			role: 'user',
	// 			content: `score the conversation based on " + criteriaString + " in x out of 10. return with the json format: {
	// 				content: x,
	// 				grammer: x,
	// 				creativity: x
	// 				}, the response must put into an array`,
	// 		}
	// 	);
	// 	this.chat.oralChat(conversation).then(res => {
	// 		let content = JSON.parse(res).choices[0].message.content;
	// 		console.log(content);
	// 	});
	// }
}
