import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanZoomComponent } from './PanZoomComponent';
import { HammerTransformDirective } from './HammerTransformDirective';

@NgModule({
    imports: [
		CommonModule
    ],
    declarations: [
        PanZoomComponent,
        HammerTransformDirective
    ],
    exports: [
        PanZoomComponent,
        HammerTransformDirective
	],
	entryComponents: [ 
		PanZoomComponent
	],
    providers: [],
    bootstrap: []
})
export class PanZoomModule { 
	
}
