import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";


@Component({
	selector:"ROAsset",
	template:`<img 
	[attr.src]="imageURL" 
	[attr.width]="w"
	[attr.height]="h"
/>
	`
})
export class ROAsset implements OnChanges
{
	@Input() input:any;
	public imageURL:string;
	public w:number = 100;
	public h:number = 100;
	
	constructor(elementRef:ElementRef)
	{
		
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.imageURL = "//oka.blob.core.windows.net/media/"+this.input.url;
		this.w = this.input.width;
		this.h = this.input.height;
	}

}
