import { ListRange } from '@angular/cdk/collections';
// ListRange = {start,end}
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const isEqual = <T>(a: T, b: T) => a === b;

export class VirtualScrollGridViewStrategy implements VirtualScrollStrategy {
	// 標準寫法，不需要改動
	private _scrolledIndexChange = new Subject<number>();
	public scrolledIndexChange: Observable<number> = this._scrolledIndexChange.pipe(distinctUntilChanged());
	private _viewport?: CdkVirtualScrollViewport;

	// 修改計算的部份
	private _itemSize: number;
	private _itemPerRow: number;

	constructor(itemSize: number, itemPerRow: number) {
		this._itemSize = itemSize;
		this._itemPerRow = itemPerRow;
	}

	// 標準寫法，不需要改動
	_minBufferPx = 100; // 不用 buffer
	_maxBufferPx = 100; // 不用 buffer
	attach(viewport: CdkVirtualScrollViewport) {
		this._viewport = viewport;
		this._updateTotalContentSize();
		this._updateRenderedRange();
	}
	detach() {
		this._scrolledIndexChange.complete();
		delete this._viewport;
	}
	onContentScrolled() {
		this._updateRenderedRange();
	}
	onDataLengthChanged() {
		this._updateTotalContentSize();
		this._updateRenderedRange();
	}
	onContentRendered() {}
	onRenderedOffsetChanged() {}
	scrollToIndex(index: number, behavior: ScrollBehavior): void {
		if(this._viewport)
			this._viewport.scrollToOffset(this._getItemOffset(index), behavior);
	}


	// 修改計算的部份
	public update(itemSize: number = 0, itemPerRow: number = 0) {
		this._itemSize = itemSize;
		this._itemPerRow = itemPerRow;
		this._updateTotalContentSize();
		this._updateRenderedRange();
	}

	private _getItemOffset(index: number): number {
		// 計算 render view 的 y offset
		var row = Math.floor(index/this._itemPerRow);
		return row * this._itemSize;
	}

	private getListRangeAt(scrollOffset: number, viewportHeight: number): ListRange {
		if(!this._viewport)
			return {start:0,end:0};

		var start =  Math.floor(Math.max(0,scrollOffset) / this._itemSize)*this._itemPerRow;
		var end =  Math.min(Math.floor((scrollOffset+viewportHeight) / this._itemSize)*this._itemPerRow + this._itemPerRow*2, this._viewport.getDataLength());

		return {start:start, end:end};
	}

	private _updateRenderedRange() {
		if (!this._viewport) return;

		const viewportHeight = this._viewport.elementRef.nativeElement.clientHeight;
		if(viewportHeight==0) {
			window.setTimeout(()=>{
				this._updateRenderedRange();
			},0);
		}

		const scrollOffset = this._viewport.measureScrollOffset();
		const newRange = this.getListRangeAt(scrollOffset, viewportHeight);
		const oldRange = this._viewport.getRenderedRange(); // item index range

		if (isEqual(newRange, oldRange)) return; // 無改變不需要更新

		this._viewport.setRenderedRange(newRange);
		this._viewport.setRenderedContentOffset(this._getItemOffset(newRange.start));
		this._scrolledIndexChange.next(newRange.start);
	}

	private _updateTotalContentSize() {
		if(!this._viewport)
			return;

		var rows = Math.ceil(this._viewport.getDataLength()/this._itemPerRow); // 總記錄佔多少行
		this._viewport.setTotalContentSize(rows*this._itemSize);
	}

}





export function factory (dir: VirtualScrollGridViewDirective) {
	return dir.scrollStrategy
}

@Directive({
	selector: 'cdk-virtual-scroll-viewport[virtualScrollGridViewStrategy]',
	providers: [{
		provide: VIRTUAL_SCROLL_STRATEGY,
		useFactory: factory,
		deps: [forwardRef(() => VirtualScrollGridViewDirective)]
	}],
})

export class VirtualScrollGridViewDirective implements OnChanges {
	constructor(private elRef: ElementRef, private cd: ChangeDetectorRef) {}
	
	@Input() itemSize:number = 70; // 代表 item width 和 height in px
	@Input() itemPerRow:number = 3;
  
	scrollStrategy:VirtualScrollGridViewStrategy =
		new VirtualScrollGridViewStrategy(this.itemSize, this.itemPerRow);
  
	ngOnChanges(changes: SimpleChanges) {
		this.scrollStrategy.update(this.itemSize, this.itemPerRow); // 修改部份
		this.cd.detectChanges();
	}
}