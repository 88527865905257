export class SVGEPenPathCreator
{
	createMarkerPath(pts:any[]):string
	{
		if(pts.length == 0) return "";
		if(pts.length == 1)
		{
			var pt = pts[0];
			return `M${pt.x.toFixed(2)} ${pt.y.toFixed(2)} L${(pt.x + 0.01).toFixed(2)} ${pt.y.toFixed(2)}`;
		} 
		var items:any = [];
		pts.reduce((prev, pt, index)=>{
			if(index == 0)
			{
				items.push(`M${pt.x.toFixed(2)} ${pt.y.toFixed(2)}`);
			} else {
				var md = {x:(pt.x + prev.x)/2, y:(pt.y + prev.y)/2};
				if(index == 1)
				{
					items.push(`L${md.x.toFixed(2)} ${md.y.toFixed(2)}`);
				} else {
					items.push(`Q${prev.x.toFixed(2)} ${prev.y.toFixed(2)} ${md.x.toFixed(2)} ${md.y.toFixed(2)}`);
				}
			}
			return pt;
		}, null)
		return items.join(" ");	
	}
	
	createPathFromPtsForHighlight(pts, boxSize:any)
    {
		var pathCreator:SVGEPenPathCreator = new SVGEPenPathCreator();
		var output:any[] = pathCreator.getHighlightPoints(pts, {x:boxSize.width/2,y:boxSize.height/2});
		
		if(output.length)
		{
			return output.map((pt, index:number)=>{
				return `${index == 0 ? "M" :""}${pt.x.toFixed(2)} ${pt.y.toFixed(2)}`;
			}).join(" ") +"Z";
			
		}
		return "";
    }

	getHighlightPoints(boxes:any[], vector:any):any []
	{
		if(boxes.length == 0) return [];
		
		if(boxes.length == 1)
		{
			var box = boxes[0];
			var left:number = box.x - vector.x;
			var right:number = box.x + vector.x;
			var top:number = box.y - vector.y;
			var bottom:number = box.y + vector.y;
			return [
				{x:left, y:top}, {x:right, y:top},
				{x:right, y:bottom}, {x:left, y:bottom}
			];
		}
		var items:any [] = [];
		boxes.reduce(
			(prev, point, index)=>{
				var dx = prev.x - point.x;
				var dy = prev.y - point.y;
				var direction = this.getDirectionFromDxDy(dx, dy);
				items.push({
					point:point,
					direction:direction,
					from:this.calculateLeftRightPoint(prev, vector, direction),
					to:this.calculateLeftRightPoint(point, vector, direction)
				})
				return point;
			}
		);
		var path1 = [];
		var path2 = [];
		
		var lastIndex = items.length - 1;
		
		items.reduce(
			(prevItem, item, index:number)=>{
				if(index == 0)
				{
					var pt = this.getEndPoint(item.point, vector, item.direction, false);
					path1.push(pt);
				}
				
				if(
					prevItem && item && 
					this.isOppositeDirection(
						prevItem.direction, item.direction
					)
				)
				{
					var pt = this.getEndPoint(prevItem.point, vector, prevItem.direction, true);
					path1.push(pt);
				}
				path1.push(item.from.left);
				path1.push(item.to.left);
				path2.push(item.from.right);
				path2.push(item.to.right);
				if(lastIndex == index)
				{
					var pt = this.getEndPoint(item.point, vector, item.direction, true);
					path1.push(pt);
				}
				return item;
			},
			null
		);
		path2.reverse();
		return path1.concat(path2);
	}

	isOppositeDirection(d1, d2)
	{
		return this.flipDirection(d1) == d2;
	}
	
	flipDirection(direction)
	{
		return direction <= 2 ? direction + 2 : direction - 2;
	}
	
	getEndPoint(box, vector, direction, inverse)
	{
		if(inverse) direction = this.flipDirection(direction);
		
		if(direction == 1 || direction == 3 )
		{
			if(direction == 1)
			{
				return {x:box.x + vector.x, y:box.y+vector.y};
			} else {
				return {x:box.x - vector.x, y:box.y-vector.y};
			}
		} else
		{
			if(direction == 2)
			{
				return {x:box.x + vector.x, y:box.y-vector.y};
			} else {
				return {x:box.x - vector.x, y:box.y+vector.y};
			}
		}
	}

	
	calculateLeftRightPoint(box, vector, direction)
	{
		if(direction == 1 || direction == 3 )
		{
			if(direction == 1)
			{
				return {
					// direction:direction,
					left:{x:box.x + vector.x, y:box.y - vector.y}, // 右上
					right:{x:box.x - vector.x, y:box.y + vector.y} // 左下
				}
			} else {
				return {
					// direction:direction,
					left:{x:box.x - vector.x , y:box.y + vector.y}, // 左下 
					right:{x:box.x + vector.x, y:box.y - vector.y}// 右上
				}
			}
		} else
		{
			if(direction == 2)
			{
				return {
					// direction:direction,
					left:{x:box.x - vector.x, y:box.y - vector.y}, // 左上
					right:{x:box.x + vector.x, y:box.y + vector.y} // 右下
				}
			} else {
				return {
					// direction:direction,
					left:{x:box.x + vector.x, y:box.y + vector.y}, // 右下
					right:{x:box.x - vector.x, y:box.y - vector.y} // 左上
				}
			}
		}
	}

	
	
	getDirectionFromDxDy(dx:number, dy:number)
	{
		if(dx >=0 )
		{
			if(dy>= 0)
			{
				return 1;
			} else {
				return 2;
			}
		}
		if(dy<= 0)
		{
			return 3;
		}
		return 4;
	}
	

}