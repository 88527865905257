import { Component, Input, Output, EventEmitter, OnInit, Injector, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { SettingService } from 'src/app/service/setting.service';

@Component({
	selector: 'install-app-modal',
	template: `
    <div class="install-app-modal">
      <div class="close" (click)="closeClick()"></div>
      <tbody><tr style="height:calc(100vh - 200px);">
				<td class="valign-middle align-right format1 bold" style="width:50%;padding-left:25px;padding-right:25px;">
					<div class="left_message">
						<div class="lang" *ngIf="datas.lang=='tc'">
							需要{{appTitle}} app 來開啟電子書，你已安裝嗎？
						</div>
						<div class="lang" *ngIf="datas.lang=='sc'">
							需要{{appTitle}} app 来开启电子书，你已安装吗？
						</div>
						<div class="lang" *ngIf="datas.lang=='en'">
							Do you have {{appTitle}} app installed?
						</div>
					</div>
				</td>
				<td class="valign-middle"><div class="splitter"></div></td>
				<td class="valign-middle align-center" style="width:50%">
					<div id="header_container" style="position:relative">
            <ng-select class="lang-select" [items]="langItems" bindLabel="label" bindValue="value" [(ngModel)]="datas.lang" [clearable]="false" [searchable]="false" (change)="langChange($event)">
            </ng-select>
					</div>
					<div>
						<a class="launch" launch="" (click)="downloadClick()">
							<table class="box">
								<tbody><tr>
									<td class="icon"><img class="download-logo"></td>
									<td class="padding10 bold">
										<div class="box-title" style="margin-top:5px;">
											<div class="lang" *ngIf="datas.lang=='tc'">
												下載{{appTitle}} app
											</div>
											<div class="lang" *ngIf="datas.lang=='sc'">
												下载{{appTitle}} app
											</div>
											<div class="lang" *ngIf="datas.lang=='en'">
												Download the {{appTitle}} app
											</div>
										</div>

										<div class="box-description description">
											<div class="lang" *ngIf="datas.lang=='tc'">
												你需要它來編輯或開啟電子書。
											</div>
											<div class="lang" *ngIf="datas.lang=='sc'">
												你需要它来编辑或开启电子书。
											</div>
											<div class="lang" *ngIf="datas.lang=='en'">
												You need the app to edit or open e-book.
											</div>
										</div>
									</td>
								</tr>
							</tbody></table>
						</a>
						<a class="launch" launch="" [attr.href]="href" (click)="openApp($event, href)">
							<table class="box">
								<tbody><tr>
									<td class="icon"><img class="{{appTitle == 'RainbowOne'?'rainbowone':'rainbowstar'}}-logo" width="42"></td>
									<td class="padding10 bold">

										<div class="box-title" style="margin-top:5px;">
											<div class="lang" *ngIf="datas.lang=='tc'">
												開啟{{appTitle}} app
											</div>
											<div class="lang" *ngIf="datas.lang=='sc'">
												开启{{appTitle}} app
											</div>
											<div class="lang" *ngIf="datas.lang=='en'">
												Open the {{appTitle}} app
											</div>
										</div>

										<div class="box-description description">
											<div class="lang" *ngIf="datas.lang=='tc'">
												我已安裝app，按此打開電子書。
											</div>
											<div class="lang" *ngIf="datas.lang=='sc'">
												我已安装app，按此打开电子书。
											</div>
											<div class="lang" *ngIf="datas.lang=='en'">
												Already have it? Open your e-Book now.
											</div>
										</div>
									</td>
								</tr>
							</tbody></table>
						</a>
						<div style="height:100px;"></div>
					</div>
				</td>
			</tr>
		</tbody>
    </div>
  `
	, styleUrls: ['./installApp.modal.scss'],
})
export class InstallAppModal implements OnInit {
	@Input() data: any;
	@Output() public close: EventEmitter<any> = new EventEmitter();
	public modalStyle;
	public headerColor;
	public href;
	public appTitle = 'RainbowOne';
	public langItems = [{ label: '繁體中文', value: 'tc' }, { label: '简体中文', value: 'sc' }, { label: 'English', value: 'en' }];
	constructor(private translate: TranslateService, public datas: DataService, private injector: Injector, private san: DomSanitizer, private common: CommonService, private eleRef: ElementRef, public setting: SettingService) {
		this.initModal();
	}

	ngOnInit() {
		if (this.data.href) {
			this.href = this.san.bypassSecurityTrustUrl(this.data.href);
			if (this.data.href.indexOf('rainbowstar') == 0 || this.setting.schoolSettings.DEFAULT_OPEN_APP == '"RS"'){
				this.appTitle = 'RainbowStar';
			}
		}
		this.eleRef.nativeElement.style.zIndex = this.common.getZIndex();
		const root = document.querySelector('app-root');
		root.appendChild(this.eleRef.nativeElement);
	}

	protected initModal() {
		this.modalStyle = {
			width: '860px',
			height: '400px'
		};
	}

	public closeClick() {
		this.close.emit();
		const ele = this.eleRef.nativeElement;
		const root = document.querySelector('app-root');
		root.removeChild(ele);
	}

	langChange($event) {
		this.translate.use($event);
	}

	downloadClick() {
		const dev = this.datas.dev;
		const os = dev.info.os.toLowerCase();
		if (this.appTitle == 'RainbowStar'){
			if (dev.isMobile == false) {
				window.open('https://www.elfie.org.hk/rainbowstar');
			} else if (os == 'ios' || os == 'mac') {
				window.open('https://apps.apple.com/hk/app/rainbowstar/id1251256553');
			} else if (os == 'android') {
				window.open('https://play.google.com/store/apps/details?id=air.hk.openknowledge.rainbowstar');
			} else {
				window.open('https://www.elfie.org.hk/rainbowstar');
			}
		} else {
			if (dev.isMobile == false) {
				window.open('https://www.rainbowone.hk/download');
			} else if (os == 'ios' || os == 'mac') {
				window.open('https://itunes.apple.com/us/app/rainbowone/id1019429184?mt=8');
			} else if (os == 'android') {
				window.open('https://play.google.com/store/apps/details?id=air.hk.openknowledge.rainbowone');
			} else {
				window.open('https://www.rainbowone.hk/download');
			}
		}
	}

	openApp($event, href){
		window.open(this.data.href, '_self');
	}


}
