import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { faFilePlus, faMessageSmile, faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons';
import { faUserRobot } from '@fortawesome/pro-light-svg-icons';
import { CommonService } from './common.service';
import { LoadingService } from '../sharedModule/loadingModule/loading.service';
import { ScriptService } from './script.service';
import { DynamicComponentService } from './dynamicComponent.service';
import { OpenBookHandler } from '../sharedModule/openBookModule/openBook.handler';
import { SettingService } from './setting.service';
import { MessageService } from 'primeng/api';
import { WebSocketService } from './webSocket.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
	public allMessages: any[] = [];
	public unreadMessages: any[] = [];

	public unread: number = 0;
	private lastCheckMsgTime: number = 0;
	readonly numOfMsgPerBatch: number = 50;

	public isNoMoreAllMsg: boolean = false;
	public isNoMoreUnreadMsg: boolean = false;
	private nextCheckTimer: any = null;
	readonly defOnlineIntervalSec: number = 120;
	readonly defOfflineIntervalSec: number = 300;
	private onlineIntervalSec: number = this.defOnlineIntervalSec;
	private offlineIntervalSec: number = this.defOfflineIntervalSec;
	private boostUntil: number = 0;

	private _isGettingAllOldMessage: boolean = false;
	private _isGettingUnreadOldMessage: boolean = false;
	private _isGettingNewMessage: boolean = false;
	private pointer: any = { max: null, minAll: null, minUnread: null };

	public lastClickedMessage: any;
	public messageConfig: any = {
		/*
		這裡可以定義不同的messageType的顯示及按下的操作
		以content_type作為key，
		value是一個function，function只有一個參數，就是API返回的message
		於function內設定icon, title, tag的顯示、及click的行為
		*/
		default: (msg: any) => ({ //default
			//不同content_type可用不同的icon、title、tag的style，也可用msg內容去判斷如何顯示
			iconSetting: { icon: faMessageSmile, ngStyle: { fontSize: "18px" } },
			title: (() => {
				let t: string = typeof msg.content;
				let c: any = msg.content;
				return (t == 'undefined') ? '' : (t == 'string' || t == 'number') ? c : (t == 'object') ? (c.text || c.string || c.message || JSON.stringify(c)) : '';
			})()
			//沒有tagSetting就不顯示tag
		}),
		test_content_type: (msg: any) => ({ //sample 這是個function，直接return個object
			toast: { key: 'tc', severity: 'success', summary: { tc: '沒有內容文字', sc: '沒有內容文字', en: 'No content text' }[this.translate.currentLang] },
			iconSetting: { icon: faMessageSmile, ngStyle: { fontSize: "18px" } },
			fileIconPath: "./assets/img/fileicon/svg-to-png/fileicon3_qb.png",
			title: (msg.content && msg.content.text) || { tc: '沒有內容文字', sc: '沒有內容文字', en: 'No content text' }[this.translate.currentLang],
			tagSetting: {
				title: { tc: '其他', sc: '其他', en: 'Other' }[this.translate.currentLang],
				ngStyle: { backgroundColor: "#fab858" }
			},
			click: (msg: any) => console.log(msg.content)
		}),
		test_content_type_teachingPlan: (msg: any) => { //sample 這是個function，可以按msg內容來決定config的內容
			let toastMsg: string = { tc: '你的教案已準備就緒! ', sc: '你的教案已準備就緒! ', en: 'Your teachingPlan is ready! ' }[this.translate.currentLang];
			let toastConfig: any = { key: 'tc', severity: 'success', summary: toastMsg };
			return {
				toast: toastConfig,
				iconSetting: { icon: faUserRobot, ngStyle: { fontSize: "20px", backgroundColor: "#6F53E4" } },
				fileIconPath: "./assets/img/home-screen/set1_teachingplan.png",
				title: { tc: '去教案', sc: '去教案', en: 'go to teachingPlan' }[this.translate.currentLang],
				tagSetting: {
					title: { tc: '教學計劃', sc: '教學計劃', en: 'TeachingPlan' }[this.translate.currentLang],
					ngStyle: { backgroundColor: "#C55978" }
				},
				click: (msg: any) => {
					let cls: Function = this.lds.add();
					this.router.navigate(['teachingPlan', this.datas.lang, this.datas.appId]).then(() => cls());
				}
			}
		},
		test_content_type_userAndGroup: (msg: any) => ({ //sample
			iconSetting: { icon: faFilePlus, ngStyle: { fontSize: "20px", backgroundColor: "#B0446C" } },
			fileIconPath: "./assets/img/fileicon/icon_folder_16x14.svg",
			title: { tc: '去用戶管理', sc: '去用戶管理', en: 'go to UserAndGroup' }[this.translate.currentLang],
			tagSetting: {
				title: { tc: '用戶管理', sc: '用戶管理', en: 'UserAndGroup' }[this.translate.currentLang],
				ngStyle: { backgroundColor: "#8449B4" }
			},
			click: (msg: any) => {
				let cls: Function = this.lds.add();
				this.router.navigate(['userAndGroup', this.datas.lang, this.datas.appId]).then(() => cls());
			}
		}),
		ai_marking: (msg: any) => {
			const startText = { tc: '$title 已開始AI批改中。', sc: '$title 已开始AI批改中。', en: '$title AI Marking in progress.' }[this.datas.lang];
			const completeText = { tc: '$title AI批改完成，可於書架查看。', sc: '$title AI批改完成，可于书架查看。', en: '$title AI Marking completed. You can view it on the Bookshelf.' }[this.datas.lang];
			const errorText = { tc: '「$title」批改失敗。系統現時繁忙，請稍後再試。', sc: '「$title」批改失败。系统现时繁忙，请稍后再试。', en: '「$title」marking failed. The system is busy. Try again later.' }[this.datas.lang];
			const payload = msg.content.payload;
			const messageMap = { startText, completeText, errorText };
			let message = messageMap[payload.messageType + 'Text'];
			message = message.replace('$title', payload.title ? payload.title : '');
			let fileIconPath = null;

			if (payload.entryType) {
				fileIconPath = this.getFileIconPathFromEntryType(payload.entryType);
			} else if (payload.queryType == 'assessment') {
				fileIconPath = this.getFileIconPathFromEntryType('assessment');
			}

			let toastMsg = {
				tc: '已開始AI批改，請稍後片刻。完成後將再作通知。',
				sc: '已开始AI批改，请稍后片刻。完成后将再作通知。',
				en: `AI marking in progress. You will be notified once it's completed.`
			}[this.translate.currentLang];

			if (payload.messageType == 'complete') {
				toastMsg = {
					tc: `「${payload.title || ''}」AI批改完成。`,
					sc: `「${payload.title || ''}」AI批改完成`,
					en: `"${payload.title || ''}" AI marking completed.`
				}[this.translate.currentLang];
			} else if (payload.messageType == 'error') {
				toastMsg = {
					tc: `「${payload.title || ''}」批改失敗。系統現時繁忙，請稍後再試。`,
					sc: `「${payload.title || ''}」批改失败。系统现时繁忙，请稍后再试。`,
					en: `"${payload.title || ''}" AI marking failed. The system is busy. Try again later.`
				}[this.translate.currentLang];
			}

			let toastConfig: any = { key: 'tc', severity: payload.messageType == 'error' ? 'fail' : 'success', summary: toastMsg, life: 8000 };

			let config: any = {
				toast: toastConfig,
				iconSetting: { icon: faUserRobot, ngStyle: { fontSize: "20px", backgroundColor: "#6F53E4" } },
				title: message,
				fileIconPath: fileIconPath,
				tagSetting: {
					title: { tc: '書架', sc: '書架', en: 'Bookshelf' }[this.translate.currentLang],
					ngStyle: { backgroundColor: "#2292d5" }
				},
				click: (msg: any) => {
					if (location.href.indexOf('/bookshelf/') > -1) {
						this.coms.sectionComponent.highlightRow(payload);
					} else {
						this.router.navigate(['bookshelf', this.datas.lang, this.datas.appId2, 'bookshelf-list']);
						setTimeout(() => {
							this.coms.waitFor(() => this.coms.sectionComponent && this.coms.sectionComponent.highlightRow, 30, 300).then(bookShelfComp => {
								this.coms.sectionComponent.highlightRow(payload);
							});
						}, 2000);
					}
				}
			};
			return config;
		},
		ai_book_generating: (msg: any) => {
			let message = ""
			const severity = msg.content.message == 'ai_book_gen_failed' ? 'fail' : 'success';

			switch (msg.content.message) {
				case "ai_book_generating":
					message = this.translate.instant("workspace.ai_gen_book_started")
					break;
				case "ai_book_gen_completed":
					message = this.translate.instant("workspace.ai_gen_book_success", { entry_name: msg.content.book_name || "" })
					break;
				case "ai_book_gen_failed":
					message = this.translate.instant("workspace.ai_gen_book_failed", { entry_name: msg.content.book_name || "" })
					break;
			}

			let error_log = []
			if (msg.content.details && JSON.parse(msg.content.details)['error_log']) {
				error_log = JSON.parse(msg.content.details)['error_log']
			}
			for (const error of error_log) {
				if (error.error == "get picture failed") {
					message = this.translate.instant("workspace.ai_gen_book_success_but_some_error_occurred", {
						entry_name: msg.content.book_name,
						error_log_message: this.translate.instant("workspace.get_picture_failed")
					})
					break;
				}
			}

			let toastConfig = { key: "tc", severity: severity, summary: message, life: 30000 };

			return {
				toast: toastConfig,
				iconSetting: { icon: faFilePlus, ngStyle: { fontSize: "20px", backgroundColor: "#A12E51" } },
				title: this.translate.instant(`notification.${msg.content.message}`, { book_name: msg.content.book_name }),
				fileIconPath: this.getFileIconPathFromEntryType('book'),
				tagSetting: {
					title: { tc: '工作間', sc: '工作间', en: 'Workspace' }[this.translate.currentLang],
					ngStyle: { backgroundColor: "#A12E51" }
				},
				click: (msg: any) => {
					const payload = msg.content;
					if (location.href.indexOf('/workspace/') > -1) {
						this.coms.sectionComponent.highlightRow(payload);
						if (msg.content.entry_id) {
							// this.coms.sectionComponent.entry_redirect_with_id(msg.content.entry_id);
						}
					} else {
						this.router.navigate(['workspace', this.datas.lang, this.datas.appId, 'cloud']);
						this.coms.waitFor(() => this.coms.sectionComponent, 30, 300)
							.then(e => {
								this.coms.sectionComponent.highlightRow(payload);
							});
					}
				},
				callWhenReceive: (msg:any)=>{
					if (location.href.indexOf('/workspace/') > -1) {
						const comp = this.coms.sectionComponent;
						const payload = msg.content;
						this.coms.sectionComponent.highlightRow(payload, false);
					}
				}
			}
		},
		ai_worksheet: (msg: any) => {
			const startText = { tc: '「$title」 已開始生成中。', sc: '「$title」 已开始生成中。', en: '\"$title\" generation in progress.' }[this.datas.lang];
			const completeText = { tc: '「$title」 生成完成，可於工作間查看。', sc: '「$title」 生成完成，可于工作间查看。', en: '\"$title\" generation completed. You can view it on the Files.' }[this.datas.lang];
			const errorText = { tc: '「$title」 產生失敗。', sc: '「$title」 产生失败。', en: '\"$title\" generation failure.' }[this.datas.lang];
			const payload = msg.content.payload;
			const messageMap = { startText, completeText, errorText };
			let message = messageMap[payload.messageType + 'Text'];
			message = message.replace('$title', payload.title ? payload.title : '');
			const startToast = { tc: '已開始AI生成工作紙，請稍後片刻。完成後將再作通知。', sc: '已开始AI生成工作纸，请稍后片刻。完成后将再作通知。', en: 'AI generation in progress. You will be notified once it\'s completed.' }[this.datas.lang];
			const completeToast = { tc: '「$title」AI工作紙生成完成', sc: '「$title」AI工作纸生成完成', en: '"$title" AI generation completed.' }[this.datas.lang];
			// const errorToast = {tc: '「$title」 生成失敗。', sc: '「$title」 生成失败。', en: '"$title" generation failed.'}[this.datas.lang];
			const errorToast = { tc: '「$title」 產生失敗，內容太多、過於太複雜或系統太繁忙，請稍後再試。', sc: '「$title」 产生失败，内容太多、过于太复杂或系统太繁忙，请稍后再试。', en: '"$title" AI generation failed. Large or complex content, or busy system. Try again later.' }[this.datas.lang];
			const toastMap = { startToast, completeToast, errorToast };
			let toastMessage = toastMap[payload.messageType + 'Toast'];
			toastMessage = toastMessage.replace('$title', payload.title ? payload.title : '');
			let toast = { key: 'tc', severity: payload.messageType == 'error' ? 'error' : 'success', summary: toastMessage, life: 8000 };
			let config: any = {
				iconSetting: { icon: faFilePlus, ngStyle: { fontSize: "20px", backgroundColor: "#A12E51" } },
				title: message,
				toast: toast,
				fileIconPath: this.getFileIconPathFromEntryType(payload.entryType),
				tagSetting: {
					title: { tc: '工作間', sc: '工作间', en: 'Workspace' }[this.translate.currentLang],
					ngStyle: { backgroundColor: "#A12E51" }
				},
				click: (msg: any) => {
					if (location.href.indexOf('/workspace/') > -1) {
						const comp = this.coms.sectionComponent;
						this.coms.sectionComponent.highlightRow(payload);
					} else {
						this.router.navigate(['workspace', this.datas.lang, this.datas.appId2, 'cloud']);
						this.coms.waitFor(() => this.coms.sectionComponent, 30, 300)
							.then(event => {
								this.coms.sectionComponent.highlightRow(payload);
							});
					}
				},
				callWhenReceive: (msg:any)=>{
					if (location.href.indexOf('/workspace/') > -1) {
						const comp = this.coms.sectionComponent;
						this.coms.sectionComponent.highlightRow(payload, false);
					}
				}
			};
			return config;
		},
		learning_objective_form_updated: (msg: any) => {
			const startText = { tc: '「$title」 內的學習重點已更新，請檢查相關準備中的教學計劃。', sc: '「$title」 内的点已更内的学习重点已更新，请检查相关准备中的教学计划。', en: '\"$title\" has been updated.' }[this.datas.lang];
			const payload = msg.content.payload;
			const messageMap = { startText };
			let message = messageMap[payload.messageType + 'Text'];
			message = message.replace('$title', payload.title ? payload.title : '');
			let config: any = {
				iconSetting: { icon: faBullseyeArrow, ngStyle: { fontSize: "20px", backgroundColor: "#3B96A5" } },
				title: message,
				fileIconPath: this.getFileIconPathFromEntryType(payload.entryType),
				tagSetting: {
					title: { tc: '學習重點', sc: '学习重点', en: 'Learning Points' }[this.translate.currentLang],
					ngStyle: { backgroundColor: "#3B96A5" }
				},
				click: (msg: any) => {
					console.log("cliclk", msg);
					if (location.href.indexOf('/learningPoints/') > -1) {
						const comp = this.coms.sectionComponent;
						this.coms.sectionComponent.selectRow(payload);
					} else {
						this.router.navigate(['learningPoints', this.datas.lang, this.datas.appId2]);
						this.coms.waitFor(() => this.coms.sectionComponent, 30, 3000)
							.then(event => {
								this.coms.sectionComponent.selectRow(payload);
							});
					}
				}
			};
			return config;
		}

	}

	constructor(private sts: SettingService, private datas: DataService, private router: Router, private translate: TranslateService, private coms: CommonService, private lds: LoadingService, public script: ScriptService, private toast: MessageService, private wss:WebSocketService) {
		//if (datas && datas.userInfo && datas.userInfo.school_id==272) this.getLastMessageApi();
		//有AI功能就會有notification噹噹
		this.coms.waitFor(() => this.sts.schoolSettings, 10, 500).then(schoolSettings => {
			if (schoolSettings.ADD_AI_SHARE_AND_ENTRY == '1') this.getLastMessageApi();

			wss.getMessageObservable('notificationService_newNotificationMessage').subscribe((msg:any)=>this.getNewMessageApi(true));
		});
	}

	//由module trigger的"一次性"檢查新message
	public checkNewMessage(): void {
		this.getNewMessageApi();
		//如果API已在call就直接return
		/*if (this._isGettingNewMessage) return;
		this._isGettingNewMessage = true;
		this.datas.post2({ data: { api: "ROMessage.get_next_message", json:["all", "after", { min:this.pointer.minAll, max:this.pointer.max }, this.numOfMsgPerBatch] }, loading: false }).then((res) => {
			this.handleGetMsgResponse(res, "all", "after");
			this.lastCheckMsgTime = +new Date();
			this._isGettingNewMessage = false;
		});*/
	}

	//加速檢查新message
	//duration=持續多少秒
	//onlineIntervalSec=於使用roWeb時每多少秒檢查一次message
	//offlineIntervalSec=當roWeb於非focus視窗(如縮到最小)時每多少秒檢查一次message
	public boost(duration: number = 60, onlineIntervalSec: number = this.onlineIntervalSec, offlineIntervalSec: number = this.offlineIntervalSec): Promise<void> {
		return new Promise((resolve, reject) => {
			this.boostUntil = +new Date() + (Math.min(duration, 600) * 1000);
			console.log('boost message check until: ' + new Date(this.boostUntil).toString());
			this.onlineIntervalSec = Math.max(onlineIntervalSec, 10);
			this.offlineIntervalSec = Math.max(offlineIntervalSec, 30);
			this.getNewMessageApi();
			setTimeout(() => resolve(), duration * 1000);
		});
	}

	//提前取消"加速檢查"
	public clearBoost(): void {
		this.onlineIntervalSec = this.defOnlineIntervalSec;
		this.offlineIntervalSec = this.defOfflineIntervalSec;
	}

	//取得最近的message，只在剛打開roWeb頁的時候才使用
	private getLastMessageApi(): void {
		if (this._isGettingNewMessage) return;
		this._isGettingNewMessage = true;
		this.datas.post2({ data: { api: "ROMessage.get_next_message", json: ["all", "before", null, this.numOfMsgPerBatch] }, loading: false }).then((res) => {
			this.handleGetMsgResponse(res, "all", "before");
			this.lastCheckMsgTime = +new Date();
			this._isGettingNewMessage = false;
			//setTimeout(()=>this.getNewMessageApi(), 1000 * 60 * 2);
			this.setNextCheck();
		});
	}

	//每隔一段時間檢查一次是否有新的message
	private getNewMessageApi(force:boolean = false): void {
		if (
			!this._isGettingNewMessage && //沒有正在取新message
			(
				force ||
				!document.hidden || //user正在用roWeb
				(+new Date() - this.lastCheckMsgTime) > (1000 * this.offlineIntervalSec) //大於5分鐘
			)
		) {
			this._isGettingNewMessage = true;
			this.datas.post2({ data: { api: "ROMessage.get_next_message", json: ["all", "after", { min: this.pointer.minAll, max: this.pointer.max }, this.numOfMsgPerBatch] }, loading: false }).then((res) => {
				this.handleGetMsgResponse(res, "all", "after");
				this.lastCheckMsgTime = +new Date();
				this._isGettingNewMessage = false;
				//setTimeout(()=>this.getNewMessageApi(), 1000 * 60 * 2);
				this.setNextCheck();
			});
		} else {
			//setTimeout(()=>this.getNewMessageApi(), 1000 * 60 * 1);
			this.setNextCheck(Math.min(this.onlineIntervalSec, 60));
		}
	}

	private setNextCheck(sec: number = null): void {
		if (this.boostUntil > 0 && this.boostUntil < +new Date()) {
			this.clearBoost();
			this.boostUntil = 0;
		}

		if (!sec) sec = this.onlineIntervalSec;

		if (this.nextCheckTimer) {
			clearTimeout(this.nextCheckTimer);
			this.nextCheckTimer = null;
		}
		this.nextCheckTimer = setTimeout(() => this.getNewMessageApi(), 1000 * sec);
	}

	//在Scroll到底時取得另一batch的舊message
	public getOlderMessageApi(apiType: "all" | "unread"): void {
		//如果已在取舊message，就不再call
		if (apiType == "all") {
			if (this._isGettingAllOldMessage) return;
			this._isGettingAllOldMessage = true;
		}
		if (apiType == "unread") {
			if (this._isGettingUnreadOldMessage) return;
			this._isGettingUnreadOldMessage = true;
		}

		this.datas.post2({
			data: {
				api: "ROMessage.get_next_message", json: [apiType, "before", {
					min: { all: this.pointer.minAll, unread: this.pointer.minUnread }[apiType],
					max: this.pointer.max
				}, this.numOfMsgPerBatch]
			}, loading: false
		}).then((res) => {
			this.handleGetMsgResponse(res, apiType, "before");

			//取消marking
			if (apiType == "all") this._isGettingAllOldMessage = false;
			if (apiType == "unread") this._isGettingUnreadOldMessage = false;
		});
	}

	private lastShowToastMsgTime: number = 0;
	private showToastMsg(config: any): void {
		let now: number = +new Date();
		if ((now - this.lastShowToastMsgTime) > 1000) {
			this.lastShowToastMsgTime = now;
			this.toast.add(config);
		} else {
			setTimeout(() => this.showToastMsg(config), 500);
		}
	}

	//處理收到的message
	private handleGetMsgResponse(res: any, apiType: string, apiDirection: string): void {
		if (res.messages) res.messages
			//處理msg前需要先排序一次，以免後來的msg先出現toastMsg
			.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : (b.created_at > a.created_at) ? -1 : 0)
			.map((msg: any) => {
				//設定config
				msg.config = this.getMsgConfig(msg);
				//TaostMessage
				if (apiType == 'all' && apiDirection == 'after'){
					if (msg.config.toast) this.showToastMsg(msg.config.toast);
					if (msg.config.log) console.log(msg.config.log);
					if (msg.config.callWhenReceive){
						msg.config.callWhenReceive(msg);
					}
				}
				//加入或取代現有messages
				let foundInAllMessages: any = this.allMessages.find((i: any) => i.id == msg.id);
				(foundInAllMessages) ? this.allMessages[this.allMessages.indexOf(foundInAllMessages)] = msg : this.allMessages.push(msg);

				let foundInUnreadMessages: any = this.unreadMessages.find((i: any) => i.id == msg.id);
				if (!msg.status || !msg.status.read_at) {
					(foundInUnreadMessages) ? this.unreadMessages[this.unreadMessages.indexOf(foundInUnreadMessages)] = msg : this.unreadMessages.push(msg);
				} else {
					this.unreadMessages = this.unreadMessages.filter((m: any) => m != foundInUnreadMessages);
				}
			});

		//設定是否已沒有更舊的msg
		if (apiDirection == "before" && (res.messages.length < this.numOfMsgPerBatch)) { //如果是向前，而已到底
			this.isNoMoreUnreadMsg = true; //因為apiType不是"all"就是"unread"，都代表已沒有更舊的unread msg
			if (apiType == "all") this.isNoMoreAllMsg = true; //如果是apiType是"all"，沒有所有更舊的unread msg
		}

		//排序
		this.allMessages.sort((a: any, b: any) => (a.id > b.id) ? -1 : (b.id > a.id) ? 1 : 0);
		this.allMessages.sort((a: any, b: any) => (a.created_at > b.created_at) ? -1 : (b.created_at > a.created_at) ? 1 : 0);
		this.unreadMessages.sort((a: any, b: any) => (a.id > b.id) ? -1 : (b.id > a.id) ? 1 : 0);
		this.unreadMessages.sort((a: any, b: any) => (a.created_at > b.created_at) ? -1 : (b.created_at > a.created_at) ? 1 : 0);

		//更新pointer
		if (res.pointer && res.pointer.max && res.pointer.min) {
			if (apiType == "all") {
				if (apiDirection == "after") this.pointer.max = res.pointer.max;
				if (apiDirection == "before") {
					this.pointer.minAll = res.pointer.min;
					if (!this.pointer.minUnread) this.pointer.minUnread = res.pointer.min;
					if (!this.pointer.max) this.pointer.max = res.pointer.max;
				}
			} else if (apiType == "unread") {
				if (apiDirection == "after") console.log('err: should not get_next_message with unread type + after direction.');
				if (apiDirection == "before") this.pointer.minUnread = res.pointer.min;
			}
		}

		//更新unread
		if (res.count) {
			this.unread = res.count.unread || 0;
		}
	}

	//取得content_type的config，service內部用only
	private getMsgConfig(msg): any {
		let contentTypeProfile: any = this.messageConfig[msg.content_type] || this.messageConfig.default;
		return contentTypeProfile(msg);
	}

	//標示已讀，只由notificationPopup component使用
	public markAsRead(msg: any): void {
		if (!msg.status) msg.status = {};
		if (!msg.status.read_at) msg.status.read_at = this.coms.getDateString(new Date(), 'yyyymmddhhmmss', '-', ':', 'T', true) + "Z";
		for (let i = this.unreadMessages.length - 1; i >= 0; i--) if (this.unreadMessages[i].id == msg.id) this.unreadMessages.splice(i, 1);
		//this.unreadMessages = this.unreadMessages.filter((m:any)=>m.id!=msg.id);
		this.unread--;
		this.datas.post2({ data: { api: "ROMessage.read_message", json: [msg.id] }, loading: false });
	}

	//標示全部已讀，只由notificationPopup component使用 //"2024-03-20T13:15:24Z"
	public markAllAsRead(): void {
		let addReadAtTime: any = (msg: any): void => {
			if (!msg.status) msg.status = {};
			if (!msg.status.read_at) msg.status.read_at = this.coms.getDateString(new Date(), 'yyyymmddhhmmss', '-', ':', 'T', true) + "Z";
		}
		this.allMessages.map(addReadAtTime);
		this.unreadMessages.splice(0, this.unreadMessages.length);

		this.unread = 0;
		this.datas.post2({ data: { api: "ROMessage.read_all_message" }, loading: false });
	}

	private getFileIconPathFromEntryType(entryType) {
		const entryTypeMap = {
			book: 'icon_book_15x18.svg',
			assessment: 'fileicon3_qb.svg',
			'ai-composition': 'icon_writing_14x14.svg',
			'ai-oral': 'icon_conversation_14x14.svg',
		};
		if (!entryTypeMap[entryType]) {
			return null;
		}
		return this.script.imgUrl + 'fileicon/' + entryTypeMap[entryType];
	}

}
