import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderIconBtnComponent } from './headerIconBtn.component';
import { SearchButtonComponent } from './search-button.component';


@NgModule({
    imports: [
		FormsModule,
        CommonModule,
        FontAwesomeModule
    ],
    declarations: [
        HeaderIconBtnComponent, SearchButtonComponent
    ],
    exports: [
        HeaderIconBtnComponent, SearchButtonComponent
    ],
	entryComponents:[HeaderIconBtnComponent, SearchButtonComponent],
    providers: [],
    bootstrap: []
})
export class HeaderIconBtnModule { }
