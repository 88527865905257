import { Component, Input, Output, EventEmitter, HostListener, HostBinding, ViewChild, ElementRef, DoCheck, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

@Component({
    selector: 'toolTip',
    template: `
    <div
        #container
        class="container"
        [style.backgroundColor]="backgroundColor"
        [style.left]="containerLeft"
        [style.top]="containerTop"
        (click)="$event.stopPropagation();"
    >
        <div class="innerContainer">
            <ng-content></ng-content>
        </div>
    </div>
    
    <div
        #triangle
        class="triangle"
        [style.backgroundColor]="backgroundColor"
        [style.left]="triangleLeft"
        [style.top]="triangleTop"
    ></div>
    `,
    styleUrls: ['./toolTip.component.scss']
})

export class ToolTipComponent {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

    @HostListener('click', []) onClick() { this.close(); }

    @HostBinding('style.zIndex') public hostZIndex = 0;
    //@HostBinding('style.display') public hostDisplay:string = 'none';
    @HostBinding('style.visibility') public hostVisibility:string = 'hidden';

    @ViewChild('container', {static: false}) private container:ElementRef;
    @ViewChild('triangle', {static: false}) private triangle:ElementRef;


    @Input() public  backgroundColor:string = "#333";
    @Input() public position:string = "top"; //top, left, right, bottom

    @Input() public containerLeft:string = "auto";
    @Input() public containerTop:string = "auto";

    @Input() public triangleLeft:string = "auto";
    @Input() public triangleTop:string = "auto";

    private targetEle:HTMLElement = null;

    constructor(private coms:CommonService) {
    }

//-------------------------------------------------------------------------------------------------
    public open(event:any) {
        this.hostZIndex = this.coms.getZIndex();
        this.hideContainer();
        if (event.target) {
            this.targetEle = event.target;
            this.setPosition(this.targetEle).then(()=>{
                this.showContainer();
            })
        } else {
            this.showContainer();
        }
    }
    //---------------------------------------------------------------------------------------------
    private showContainer():void {
        this.hostVisibility = 'visible';

        let tempHeight:number = this.container.nativeElement.offsetHeight
        this.container.nativeElement.style.height = 0 + 'px';
        setTimeout(()=>{
            this.container.nativeElement.style.transition = 'height 0.2s, opacity 0.2s ease';
            this.container.nativeElement.style.opacity = 1;
            this.container.nativeElement.style.height = tempHeight + 'px';
            setTimeout(()=>{
                this.triangle.nativeElement.style.visibility = 'visible';
            }, 200);
        });
    }
    //-------------------------------------------------------------------------------------------------
    private hideContainer():void {
        this.triangle.nativeElement.style.visibility = 'hidden';
        this.container.nativeElement.style.transition = 'none';
        this.container.nativeElement.style.opacity = 0;
        this.hostVisibility = 'hidden';
    }
//-------------------------------------------------------------------------------------------------
    public close() {
        this.hideContainer();
        //this.hostVisibility = 'hidden';
    }
//-------------------------------------------------------------------------------------------------
    private setPosition(target:HTMLElement):Promise<any> {
        return new Promise((resolve, reject)=>{
            let count:number = 100;
            let timer:any = setInterval(()=>{
                count--;
                if (count < 0) {
                    clearInterval(timer);
                    reject();
                } else if (this.container.nativeElement.clientHeight > 0 && this.container.nativeElement.clientWidth > 0) {
                    clearInterval(timer);

                    let screenWidth:number = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                    let screenHeight:number = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    
                    let containerWidth:number = this.container.nativeElement.offsetWidth;
                    let containerHeight:number = this.container.nativeElement.offsetHeight;
    
                    // Set container position
                    let containerLeft:number = 0;
                    let containerTop:number = 0;
    
                    let targetRect:ClientRect = target.getBoundingClientRect();

                    if (this.position=="left") {
                        containerLeft = (targetRect.left - containerWidth - 10);
                        containerTop = (targetRect.top - ((containerHeight - targetRect.height) / 2));
                    } else if (this.position=="right") {
                        containerLeft = (targetRect.left + targetRect.width + 10);
                        containerTop = (targetRect.top - ((containerHeight - targetRect.height) / 2));
                    } else if (this.position=="top") {
                        containerLeft = (targetRect.left - ((containerWidth - targetRect.width) / 2));
                        containerTop = (targetRect.top - containerHeight - 10);
                    } else if (this.position=="bottom") {
                        containerLeft = (targetRect.left - ((containerWidth - targetRect.width) / 2));
                        containerTop = (targetRect.top + targetRect.height + 10);
                    }
    
                    if (containerLeft < 10) containerLeft = 10;
                    if (containerLeft > screenWidth - containerWidth) containerLeft = screenWidth - containerWidth - 10;
                    if (containerTop < 10) containerTop = 10;
                    if (containerTop > screenHeight - containerHeight) containerTop = screenHeight - containerHeight - 10;
    
                    this.containerLeft = containerLeft + 'px';
                    this.containerTop = containerTop + 'px';

                    //Set trangle position
                    if (this.position=="left") {
                        this.triangleLeft = containerLeft + containerWidth - 14 + 'px';
                        this.triangleTop = targetRect.top + (targetRect.height  / 2) - 11 + 'px';
                    } else if (this.position=="right") {
                        this.triangleLeft = containerLeft - 5 + 'px';
                        this.triangleTop = targetRect.top + (targetRect.height  / 2) - 11 + 'px';
                    } else if (this.position=="top") {
                        this.triangleLeft = targetRect.left + (targetRect.width / 2) - 14 + 'px';
                        this.triangleTop = containerTop + containerHeight - 14 + 'px';
                    } else if (this.position=="bottom") {
                        this.triangleLeft = targetRect.left + (targetRect.width / 2) - 14 + 'px';
                        this.triangleTop = containerTop - 6 + 'px';
                    }
                    resolve();
                }
    
            }, 100);

        });
    }
//-------------------------------------------------------------------------------------------------

}
