import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from '../service/data.service';

@Component({
	selector: '[containRequired]',
	template: "<ng-content></ng-content><span>{{ word }}</span>",
	styles: [
		`
		
		span{
			margin-left: 10px;
			font-size: 12px;
			line-height: 12px;
			font-weight: 400;
			color: var(--common-errorcolor, #df3354);
		}
		
		
		
		`
	]
})
export class ContainRequired implements OnInit {
	public word = '';
	constructor(public datas: DataService) { }

	ngOnInit(): void {
		this.word = {tc: '( * 為必填項目)', sc: '( * 為必填項目)', en:'( * Required field)'}[this.datas.lang];
	}
}