import { ByteArray } from "openfl";

export class MarkingScore
{
	public constructor(public score:number, x:number, y:number, edit:boolean = false)
	{
		
	}
	
	static fromBytes(ba:ByteArray):any
	{
		var score:number = ba.readByte();
		var x:number = ba.readInt();
		var y:number = ba.readInt();
		return {
			score:score,
			x:x,
			y:y
		};
		// return new MarkingScore(score, x, y);
	}
	
	
}