import { Injectable, EventEmitter } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ModalService } from 'src/app/service/modal.service';
import { DataService } from 'src/app/service/data.service';
import { tap } from 'rxjs/operators';
import { SubjectService } from '../sharedModule/subjectModule/subject.service';

@Injectable({ providedIn: 'root' })
export class StorageService {
    public book_class:any = [] // [{ID: '1', tag_name: '哲學/哲学/Philosophy', school_id: '0'},{ID: '2', tag_name: '心理學/心理学/Psychology', school_id: '0'}]
	public yearkey:object ={}  // example {1:"to2021",2:"to2022"}
	public schoolYears = [];
	public courses = [];
	public tags = [];
	public account: any;
	public selectedItems = [];
	public activeItem;
	public lang;
	public appId;
	public dev;
	public debug;
	public uid;
	public schoolId = 0;
	public role;
	public hasClientTag = false;
	public version;
	public tmpObject: any = {};
	public jwt;
	public jwtObj;
	public inBrowser = false;
	public components = [];
	public teachers = [];
	public lastRowId;
	public subjects: any;
	public peoples:any = [];
	public inBranch = false;
	public imgPath = '';
    public openBookModal:any;

	constructor(private datas: DataService, 
		protected subjectService:SubjectService,
		private device: DeviceDetectorService, private modal: ModalService) {
		this.lang = 'tc';
		this.dev = {
			info: device.getDeviceInfo(),
			isMobile: device.isMobile() || device.isTablet()
		};
		console.log(this.dev);
		this.fixIosScroll();
	}

	fixIosScroll() {
		if (this.dev.isMobile) {
			document.ontouchstart = function (e) {
				document.body.scrollTop = 0;
			};
			document.ontouchend = function (e) {
				document.body.scrollTop = 0;
			};
		}
	}

	fixIosRender() {
		const os = this.device.os.toLocaleLowerCase();
		const device = this.device.device.toLocaleLowerCase();
		if (os == 'ios' || (this.dev.isMobile && os == 'mac') || device == 'ipad' || device == 'iphone') {
			setTimeout(() => {
				const mod = this.modal.flash();
				setTimeout(() => {
					const index = this.modal.modalList.indexOf(mod);
					this.modal.close(index);
					const body = document.querySelector('body');
					body.classList.remove('lock-scroll');
				}, 500);
			}, 1000);
		}
	}

	public compName(key) {
		if (this.components[this.lang][key]) {
			return this.components[this.lang][key];
		} else {
			return key;
		}
	}

	/*getSubject() {
		const url = this.datas.apiUrl + '?api=ROSchoolSubject.get_subjects&json=[]&jwt=' + this.datas.jwt;
		return this.datas.get(url).pipe(tap((res: any) => {
			if (res.subjects) {
				this.subjects = res.subjects.map(e => {
					let sj: any = { type: e.type, school_subject_id: e.school_subject_id, public_subject_id: e.public_subject_id, level: e.level };
					sj.id = (sj.public_subject_id ? sj.public_subject_id : '0') + '-' + (sj.school_subject_id ? sj.school_subject_id : '0');
					sj.title = this.datas.lang == 'en' ? e.ename : e.cname;
					sj.short_name = e.short_name;

					return sj;
				});

				
			}
		}));
	}*/
	getSubjects():Promise<any> {
		return new Promise((resolve,reject)=>{
			this.subjectService.getSubjects().then((success:any) => {
				this.subjects = success;
				resolve(success);
			}, (reason:any)=> {
				reject(reason);
			});
		});
	}
}