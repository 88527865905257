import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { tap, expand, zip } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { AlertService } from 'src/app/service/alert.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { ThemeService } from 'src/app/service/theme.service';
import { InlineSvgService } from 'src/app/sharedModule/inlineSvgModule/inlineSvg.service';

@Component({
	selector: 'select-book-modal',
	template: `

	<modal2
		#modal2
		[width]="modalWidth" [title]="'course.pls-select-content'|translate"
		[minWidth]="modalWidth" [headerStyle]="headerStyle"
		[contentStyle]="contentStyle"
		(confirmClick)="addClick($event)"
		(cancelClick)="cancelClick($event)"
		>
      	<div class="modal-body" [style.minWidth]="modalWidth" #body>
		  	<popup-toggle [(toggle)]="toggle" bindLabel="label" bindValue="value" [items]="toggles"></popup-toggle>
			<div class="main" #main>
				<section *ngFor="let col of columns;let i=index" [attr.level]="i" [ngClass]="(col.children.length == 0?'empty':'')" [style.width.px]="columnWidth">
					<div class="column-header">
						<a class="field">
							<span>{{'course.item'|translate}}</span>
						</a>
					</div>
					<div class="column-wrapper" #ps *ngIf="col.books" [ngClass]="(datas.dev.isMobile?'mobile':'')">
						<div [ngClass]="['item collection', (columns.indexOf(item) > - 1?'selected':'')]" *ngFor="let item of col.children" (click)="folderClick(item)">
							<i class="collection section-{{toggle}}">
								<svg-icon [name]="item.icon"></svg-icon>
								<div class="license-copy" *ngIf="toggle == 'license' && !item.copy">C</div>
							</i>
							<div class="item__name">{{item.title}}</div>
							<a class="item__select" (click)="itemClick($event, item)">
								<fa-icon [ngClass]="(selectedItems.indexOf(item) > -1?'checked':'')" [icon]="(selectedItems.indexOf(item) > -1?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</a>
						</div>
						<div class="item book entry-{{item.type}}" *ngFor="let item of col.books" (click)="itemClick($event, item)">
							<i [ngClass]="[item.type, toggle == 'license'?'section-license':'']">
								<svg-icon [name]="item.icon"></svg-icon>
								<div class="license-copy" *ngIf="toggle == 'license' && !item.copy">C</div>
							</i>
							<div class="item__name">{{item.title}}</div>
							<a class="item__select" (click)="itemClick($event, item)">
								<fa-icon [ngClass]="(selectedItems.indexOf(item) > -1?'checked':'')" [icon]="(selectedItems.indexOf(item) > -1?faCheckCircle:faCheckCircleLight)"></fa-icon>
							</a>
						</div>
					</div>
				</section>
			</div>
		</div>
    </modal2>
  `
	, styleUrls: ['./select-book.modal.scss'],
})
export class SelectBookModal implements OnInit {
	@Input() data: any;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public modalStyle;
	public headerColor;
	public root: any = {};
	private firstColPos = 0;
	public columnWidth;
	public modalWidth;
	public maxColumn: number = 0;
	public columns: any[] = [];
	@ViewChild('main', { static: false }) main;
	@ViewChild('modal2', {static: false}) modal2;
	private moveFirstColumn$;
	public selectedItems: any = [];
	public toggle = 'cloud';
	public toggles: any = [
		{ label: 'course.cloud-folder', value: 'cloud', onEnter: ()=>{
			this.getData('cloud');
		}},
		{ label: 'course.license-folder', value: 'license', onEnter:()=>{
			this.getData('license');
		}},
	];
	public headerStyle;
	public contentStyle;
	constructor(public datas: DataService, public load: LoadingService, private als: AlertService, private theme: ThemeService, private eleRef: ElementRef, private iconReg: InlineSvgService) {
		this.initModal();
		this.iconReg.regSvgFiles([
			{url: 'icon_folder.svg', name: 'icon_folder'},
			{url: 'course/icon_folder_blue.svg', name: 'icon_folder_blue'},
			{url: 'icon_folder_purple.svg', name: 'icon_folder_purple'},
			{url: 'icon_book.svg', name: 'icon_book'},
			{url: 'icon_book_purple.svg', name: 'icon_book_purple'},
			{url: 'fileicon/fileicon2_qb.svg', name: 'fileicon2_qb'},
			{url: 'fileicon/fileicon2_xls.svg', name: 'fileicon2_xls'},
			{url: 'fileicon/fileicon2_doc.svg', name: 'fileicon2_doc'},
			{url: 'fileicon/fileicon2_pdf.svg', name: 'fileicon2_pdf'},
			{url: 'fileicon/fileicon2_ppt.svg', name: 'fileicon2_ppt'},
			{url: 'fileicon/fileicon2_image.svg', name: 'fileicon2_image'},
			{url: 'fileicon/fileicon2_others.svg', name: 'fileicon2_others'},
			{url: 'fileicon/fileicon2_audio.svg', name: 'fileicon2_audio'},
			{url: 'fileicon/fileicon2_video.svg', name: 'fileicon2_video'}
		]);
	}

	ngOnInit() {
		this.theme.applyStyleObj({
			"default": {
				"remainer-bgcolor": "#F1F1F7",
				"popupform-rowselectedbgcolor": "#dbdbdb",
				"popupframe-okbuttonwidth": "80px",
				"popupframe-okbuttonheight": "34px",
				"popupframe-okbuttontextsize": "17px",
				"popupframe-okbuttontextweight": "bold",
				"popupframe-okbuttontextcolor": "#FFFFFF",
				"popupframe-okbuttoninsidemargin": "10px",
				"popupframe-cancelbuttonwidth":"80px",
				"popupframe-cancelbuttonheight":"54px",
				"popupframe-cancelbuttontextweight":"bold",
				"cancelbuttontextcolor":"#999",
				"first-book-row":"#f0f0f0",
				"selected-book-row":"#e6e6e6",
				"popupform-rowbordercolor": "#ccc"
			},
			"dark": {
				"remainer-bgcolor": "#1E1E29",
				"popupform-rowselectedbgcolor": "#2d2c43",
				"first-book-row":"2d2c43",
				"selected-book-row":"#2d2c43",
				"popupform-rowbordercolor": "#53536a"
			}
		}, this.eleRef.nativeElement);
		this.getData(this.toggle);
		
	}

	ngAfterViewInit(){
		setTimeout(()=>{
			this.modal2.open();
		},10);
	}

	getData(toggle){
		this.toggle = toggle;
		this.columns = [];
		this.datas.post('ROCourse.list_all_entries', [this.datas.userInfo.school_id]).subscribe((res: any) => {
			this.load.remove('load-entries');
			if (res.code == 0) {
				this.initRoot(res.root, res.entries);
				this.columns[0] = this.root;
				this.initWidth();
			}
		}, (err) => {
			this.data.close();
			this.load.remove('load-entries');
		});
	}

	protected initModal() {
		// this.modalStyle = {
		// 	width: '896px',
		// 	height: '636px',
		// };
		this.contentStyle = { padding: '0', height: 'calc(100dvh - 80px)', minWidth: '896px' };
		this.headerStyle = { borderBottom: '0' };
	}

	initWidth() {
		this.columnWidth = 284;
		if (this.maxColumn >= 3 || true) {
			this.modalWidth = '896px';
		} else if (this.maxColumn == 2) {
			this.modalWidth = (284 * 2) + 'px';
		} else {
			this.columnWidth = 896;
			this.modalWidth = '896px';
		}
	}

	initRoot(root: any, entries = []) {
		root.children = entries.filter(e => e.pid == root.id && e.type == 'collection');
		if (this.toggle == 'cloud'){
			root.children = root.children.filter(e=>e.permission == null || e.permission == 3);
		} else {
			root.children = root.children.filter(e=>e.permission != null && e.permission != 3);
		}
		root.children.sort((a, b) => {
			let aTitle = a ? a.title : '';
			let bTitle = b ? b.title : '';
			return aTitle.toLowerCase() > bTitle.toLowerCase() ? 1 : -1;
		});
		if (this.toggle == 'cloud'){
			let found = entries.find(e => e.type == 'course_folder');
			if (found) {
				found.title = this.datas.lang == 'en' ? 'Course files' : '課程的檔案';
				found.icon = this.toggle == 'cloud'?'icon_folder_blue':'icon_folder_purple';
				root.children.unshift(found);
			}
		}
		root.children.forEach(e => { 
			e.level = 1;
			this.setIcon(e);
		});
		root.books = entries.filter(e => e.pid == root.id && (e.type != 'collection' && e.type != 'course_folder'));
		if (this.toggle == 'cloud'){
			root.books = root.books.filter(e=>e.permission == null || e.permission == 3);
		} else {
			root.books = root.books.filter(e=>e.permission != null && e.permission != 3);
		}
		root.books.forEach(book=>{
			this.setIcon(book);
		});
		root.books.sort((a, b) => {
			let aTitle = a ? a.title : '';
			let bTitle = b ? b.title : '';
			return aTitle.toLowerCase() > bTitle.toLowerCase() ? 1 : -1;
		});
		root.level = 0;
		this.root = root;
		// for(let child of parent.children){
		//   child.level = parent.level + 1;
		//   this.maxColumn = child.level > this.maxColumn?child.level: this.maxColumn;
		//   this.findChild(child, entries);
		// }

	}

	folderClick(item) {
		this.folderClickHandle(item);
		if (!item.books) {
			this.datas.post('ROCourse.list_all_entries', [item.id]).subscribe((res: any) => {
				if (res.entries) {
					item.children = res.entries.filter(e => e.type == 'collection' && e.pid == item.id);
					if (this.toggle == 'cloud'){
						item.children = item.children.filter(e=>e.permission == null || e.permission == 3);
					} else {
						item.children = item.children.filter(e=>e.permission != null && e.permission != 3);
					}
					item.children.forEach(e => { 
						e.level = item.level + 1;
						this.setIcon(e);
					});
					item.children.sort((a, b) => {
						let aTitle = a ? a.title : '';
						let bTitle = b ? b.title : '';
						return aTitle.toLowerCase() > bTitle.toLowerCase() ? 1 : -1;
					});
					item.books = res.entries.filter(e => e.type != 'collection' && e.pid == item.id);
					if (this.toggle == 'cloud'){
						item.books = item.books.filter(e=>e.permission == null || e.permission == 3);
					} else {
						item.books = item.books.filter(e=>e.permission != null && e.permission != 3);
					}
					item.books.forEach(e=>{
						this.setIcon(e);
					})
					item.books.sort((a, b) => {
						let aTitle = a ? a.title : '';
						let bTitle = b ? b.title : '';
						return aTitle.toLowerCase() > bTitle.toLowerCase() ? 1 : -1;
					});
					// this.folderClickHandle(item);
				}
				this.load.remove('get-entries');
			});
		}
	}

	folderClickHandle(item) {
		let main = this.main.nativeElement;
		this.firstColPos = Math.ceil(main.scrollLeft / this.columnWidth);
		if (item.type != 'collection' && item.type != 'course_folder') {
			return false;
		} else if (this.columns.length == item.level) {
			this.columns.push(item);
			if (item.level >= this.firstColPos + 1) {
				let target = main.scrollLeft + 286;
				this.firstColPos = this.firstColPos + 1
				setTimeout(() => { this.moveFirstColumn(target, 0); }, 100);
			}
		} else if (item == this.columns[item.level]) {
			if (this.firstColPos < item.level - 2) {
				let target = main.scrollLeft + 286;
				this.firstColPos += 1;
				setTimeout(() => { this.moveFirstColumn(target, 0); }, 100);
			} else if (this.firstColPos > 0) {
				let target = main.scrollLeft - 286;
				this.firstColPos = this.firstColPos - 1;
				let kill = this.columns.length - item.level - 3;
				setTimeout(() => { this.moveFirstColumn(target, kill); }, 100);
			} else if (item.level == 0) {
				this.columns.splice(1, this.columns.length, item);
			} else if (this.columns.length > item.level + 1) {
				this.columns.splice(item.level + 1, this.columns.length);
			}
		} else {
			if (main.scrollLeft > 0) {
				setTimeout(() => { this.moveFirstColumn(0, 0); }, 10);
				setTimeout(() => {
					let len = this.columns.length;
					this.columns.splice(item.level, len, item);
				}, 500);
			} else {
				let len = this.columns.length;
				this.columns.splice(item.level, len, item);
			}
		}
	}

	moveFirstColumn(target, kill) {
		clearTimeout(this.moveFirstColumn$);
		let main = this.main.nativeElement;
		let step = main.scrollLeft < target ? 16 : -16;
		let swap = main.scrollLeft;
		main.scrollLeft = main.scrollLeft + step;
		if (Math.abs(main.scrollLeft - target) <= 16 && main.scrollLeft != target) {
			main.scrollLeft = target;
			this.columns.splice(this.columns.length - kill, kill);
		} else if (main.scrollLeft != target && swap != main.scrollLeft) {
			this.moveFirstColumn$ = setTimeout(() => {
				this.moveFirstColumn(target, kill);
			}, 10);
		}
	}

	itemClick($event, item) {
		$event.stopPropagation();
		if (item.type == 'pdf-paper'){
			return;
		}
		if (this.data.singleOnly && this.selectedItems.length > 0){
			this.selectedItems = [];
		}
		if (this.selectedItems.length > 0 && item.type == 'collection') {
			if (this.selectedItems[0].level != item.level) {
				this.als.alert2('course.select-same-column-item');
				return;
			}
		}
		let index = this.selectedItems.indexOf(item);
		if (index == -1) {
			if (item.type == 'collection' && !item.books) {
				this.selectedItems.push(item);
				this.updateItem(item).subscribe((res: any) => { });
			} else {
				this.selectedItems.push(item);
			}
		} else {
			this.selectedItems.splice(index, 1);
		}
	}

	addClick($event = null) {
		if ($event){
			$event.stopPropagation();
		}
		if (this.selectedItems.length == 0) {
			this.als.alert2('course.no-book-added');
			return;
		} else {
			this.data.close(this.selectedItems);
		}
	}

	cancelClick($event) {
		$event.stopPropagation();
		this.data.close();
	}

	public saveModal() {
		return true;
	}

	public confirmModal() {
		if (this.data.confirm) {

		}
		this.data.close();
	}

	public cancelModal() {
		// this.data.close();
		return true;
	}

	updateItem(item) {
		return this.datas.post('ROCourse.list_all_entries', [item.id]).pipe(expand((res: any) => {
			if (res.entries) {
				item.children = res.entries.filter(e => e.type == 'collection' && e.pid == item.id);
				item.children.forEach(e => { e.level = item.level + 1; });
				item.children.sort((a, b) => {
					let aTitle = a ? a.title : '';
					let bTitle = b ? b.title : '';
					return aTitle.toLowerCase() > bTitle.toLowerCase() ? 1 : -1;
				});
				item.books = res.entries.filter(e => e.type != 'collection' && e.pid == item.id);
				item.books.sort((a, b) => {
					let aTitle = a ? a.title : '';
					let bTitle = b ? b.title : '';
					return aTitle.toLowerCase() > bTitle.toLowerCase() ? 1 : -1;
				});
				if (item.children) {
					let reqs = item.children.map(n => this.updateItem(n));
					return new Observable().pipe(zip(...reqs));
				} else {
					return new Observable().pipe(zip([]));
				}
			}
		}));
	}

	setIcon(item){
		if (item.type == 'collection'){
			item.icon = this.toggle == 'cloud'?'icon_folder_blue':'icon_folder_purple';
		} else if (item.type == 'book'){
			item.icon = this.toggle == 'cloud'?'icon_book':'icon_book_purple';
		} else if (item.type == 'assessment'){
			item.icon = 'fileicon2_qb';
		} else if (item.type == 'excel'){
			item.icon = 'fileicon2_xls';
		} else if (item.type == 'word'){
			item.icon = 'fileicon2_doc';
		} else if (item.type == 'pdf'){
			item.icon = 'fileicon2_pdf';
		} else if (item.type == 'ppt'){
			item.icon = 'fileicon2_ppt';
		} else if (item.type == 'image'){
			item.icon = 'fileicon2_image';
		} else if (item.type == 'file'){
			item.icon = 'fileicon2_others';
		} else if (item.type == 'audio'){
			item.icon = 'fileicon2_audio';
		} else if (item.type == 'video'){
			item.icon = 'fileicon2_video';
		} else if (item.type == 'others'){
			item.icon = 'fileicon2_others';
		} else if (item.type == 'pdf-paper'){
			item.icon = 'fileicon2_testpaper';
		} else {
		}
	}

}
