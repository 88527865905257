import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class RoService {

    private callId:number = 1;
    private checkMultipartSupport:boolean = true;
    private supportMutipart:boolean = true;
	public isInWeb = false;
    constructor(private coms:CommonService) {
		const isInApp = location.href.indexOf('api.openknowledge.hk') > -1 || location.href.indexOf('ro2.azurewebsites.net') > -1;
		this.isInWeb = !isInApp;
        window['ro'] = {
            calls: {},
            callback: (callId:number, status:any, data:string) => {
                //this.coms.log('RoReturn status: ' + status);
                if (status=='ack') {
                    //this.coms.log('Got ACK of call id: ' + callId);
                    if (this.callingId == callId) {
                        this.callingId = null;
                        this.curRetryNum = null;
                        this.nextHash();
                    };
                } else {
                    //this.coms.log('RoReturn data: ' + JSON.stringify(data));
                    let call = window['ro'].calls[callId];
                    if (call) {
                        (status == 200) ? call.resolve(data) : call.reject(data);
                        //delete window['ro'].calls[callId];
                        window['ro'].deleteCall(callId);
                    }
                }
            },
            deleteCall: (callId:number) => {
                delete window['ro'].calls[callId];
            }
        }
    }
    //---------------------------------------------------------------------------------------------
    private lastRequestTime:number = 0;
    //---------------------------------------------------------------------------------------------
	private prepareResponse(resolve:Function, reject:Function):any {
		let callId:number = this.callId++;
		var response = { id:callId, resolve:resolve, reject:reject };
		window['ro'].calls[callId] = response;
		return response;
	}
    //---------------------------------------------------------------------------------------------
    public request(name:string, data:any={}, timeoutRejct:boolean=true, prefix:string="ro/request"):Promise<any> {
        let callName:string = [prefix, name].join("/");
        //this.coms.log('in ro.service.request, name: ' + callName);
        this.coms.log('ro.request(' + callName + ',     data: ' + JSON.stringify(data));
        let p = new Promise((resolve, reject)=>{
			if (localStorage.getItem('in-web') == '1' || this.isInWeb){
				reject({msg: 'in-web'});
			} else {
				var response:any = this.prepareResponse(resolve, reject);
				this.callRo(callName, data, response, timeoutRejct);
			}
        });
        p.then(()=>{
          this.coms.log('response(' + callName + ') OK');
        },
        (reason)=>{
          this.coms.log('error response:' + callName + '     failed: ' + JSON.stringify(reason));
        });
        return p;
    }
    //---------------------------------------------------------------------------------------------
    /*public ready() {
        let callName:string = "ro/ready";
        this.coms.log('in ro.service.request, name: ' + callName);
        return new Promise((resolve, reject)=>{
			var response:any = this.prepareResponse(resolve, reject);
            this.callRo(callName, {}, response, false);
        });
    }*/
    //---------------------------------------------------------------------------------------------
    private callRo(name:string, data:any={}, response:any={}, timeoutRejct:boolean):void
	{
		if(!this.checkMultipartSupport)
		{
            //this.coms.log('in ro.service.callRo.checkMultipartSupport');
            let supportMultiPartResponse:any = this.prepareResponse(
                ()=>{
                    this.checkMultipartSupport = true;
                    this.supportMutipart = true;
                    //this.coms.log('in ro.service.callRo.checkMultipartSupport, supportMutipart: ' + this.supportMutipart);
                    this.callRo(name, data, response, timeoutRejct);
                },
                ()=>{
                    this.checkMultipartSupport = true;
                    //this.coms.log('in ro.service.callRo.checkMultipartSupport, supportMutipart: ' + this.supportMutipart);
                    this.callRo(name, data, response, timeoutRejct);
                }
            );
            this.simpleCall("ro/request/supportMultiPart", "", supportMultiPartResponse, true);
            /*setTimeout(()=>{
                if (!this.checkMultipartSupport) supportMultiPartResponse.reject();
            },6000);*/
		} else {
			this.middleWareCall(name, data, response, timeoutRejct);
		}
    }
    //---------------------------------------------------------------------------------------------
	private middleWareCall(name:string, data:any = {}, response:any={}, timeoutRejct:boolean):void
	{
        //this.coms.log('in ro.service.middleWareCall, supportMutipart: ' + this.supportMutipart);
		if(this.supportMutipart)
		{
			this.multiplePartRequest(name, data, response, timeoutRejct);
		} else {
			this.encodedRequest(name, data, response, timeoutRejct);
		}
	}
    //---------------------------------------------------------------------------------------------
	private toHex(tmp:String):String
	{
		var tmp_len = tmp.length;
		var parts = [];
		for (var i = 0; i < tmp_len; i += 1) {
			var charCode = tmp.charCodeAt(i);
			parts.push(charCode.toString(16));
		}
		return parts.join(",");
	}
	private splitHex(hex:String):string[]
	{
		var out = [];
		while (hex.length > 0)
		{
			out.push(hex.substr(0, 512));
			hex = hex.substring(512);
		}
		return out;
	}
	private multiplePartRequest(name:string, data:any = {}, response:any = {}, timeoutRejct):void
	{
        //this.coms.log('in ro.service.multiplePartRequest, name: ' + name + '  data: ' + JSON.stringify(data));
		let json:String = JSON.stringify(data);
		if(json.length > 480 )
		{
			let hexJSON = this.toHex(json);
			let array = this.splitHex(hexJSON);
            var len = array.length;

            let curIndex:number = 0;
            let loopFn:Function = ()=>{
                if (curIndex<len){
                    this.simpleCall(
                        "ro/request/append",
                        "id("+response.id+")-index("+curIndex+")-total("+(len-1)+")-hex("+array[curIndex]+")",
                        this.prepareResponse(
                            ()=>{
                                //curIndex++;
                                //loopFn();
                            }, ()=>{
                                //error
                                response.reject();
                            }
                        ),
                        false
                    );
                    curIndex++;
                    loopFn();
                } else {
                    //Last call
                    this.simpleCall( name, "", response, timeoutRejct);
                }
            };
            loopFn();
		} else {
			this.encodedRequest(name, data, response, timeoutRejct);
		}
	}

	// this behavior is different from original Jimmy's version
	private encodedRequest(name:string, data:any = {}, response:any = {}, timeoutRejct:boolean):void
	{
        //this.coms.log('in ro.service.encodedRequest, name: ' + name + '  data: ' + JSON.stringify(data));
		//this.coms.log('encodedRequest Name: ' + name);
		//this.coms.log('encodedRequest Data: ' + JSON.stringify(data));
		this.simpleCall(name, encodeURIComponent(btoa(unescape(encodeURIComponent(JSON.stringify(data))))), response, timeoutRejct);
	}
	// final call to RO
	private simpleCall(name:string, data:string = '', response:any = {}, timeoutRejct):void
	{
        //this.coms.log('in ro.service.simpleCall, name: ' + name + '  data: ' + JSON.stringify(data));
		let hash:string = [ name, "window.ro.callback", response.id, data, this.now() ].join("/");
		this.assignHash2(hash, response.id, name, timeoutRejct);
    }

    private hashQueue:any[] = [];
    private callingId:number = null;
    private curRetryNum:number = null;
    private assignHash2(hash:string, callId:number, name:string, timeoutRejct:boolean, retry:number=0):void {
        this.hashQueue.push({
            id: callId,
            hash: hash,
            name: name,
            timeoutRejct: timeoutRejct,
            retry: retry
        });

        this.nextHash();
    }

    private nextHash():void {
        if (this.callingId!=null || this.hashQueue.length<1) return;
        if (this.isCalling()) {
            const apiName = this.hashQueue[0].name.substr(11);
            this.coms.log('HTTP is calling[' + apiName + '], wait 1 sec.');
            setTimeout(()=>this.nextHash(), 1000);
            return;
        }
        let hashObj:any = this.hashQueue.shift();
        this.callingId = hashObj.id;
        this.curRetryNum = hashObj.retry;
        window.location.hash = hashObj.hash;
        //this.coms.log('hash: ' + hashObj.hash);
        setTimeout(()=>{
            //this.coms.log('callingId: ' + this.callingId + '   curRetryNum: ' + this.curRetryNum + '   hashObj.id: ' + hashObj.id + '   hashObj.retry: ' + hashObj.retry);
            if (this.callingId==hashObj.id && this.curRetryNum==hashObj.retry) {
                if (hashObj.name!='ro/request/append' && hashObj.name.indexOf('ro/ready')<0) {
                    if (hashObj.retry<3) {
                        //this.coms.log('retry ro call: ' + hashObj.name + '   id: ' + hashObj.id + '   retry: ' + (hashObj.retry + 1));
                        this.assignHash2(hashObj.hash, hashObj.id, hashObj.name, hashObj.timeoutRejct, hashObj.retry + 1);
                    } else {
                        //this.coms.log('Did not get "ack" from RO for "' + hashObj.name + '"(id:' + hashObj.id + ') in 1 sec. Will call next.');
                        if (hashObj.timeoutRejct) window['ro'].callback(hashObj.id, 408, 'Request Timeout.');
                        window['ro'].deleteCall(hashObj.id);
                    }
                }
                this.callingId = null;
                this.curRetryNum = null;
                this.nextHash();
            }
        },1000);
    }

	private assignHash(hash)
	{
		this.delay(()=>{
			this.lastRequestTime = this.now();
			window.location.hash = hash;
		});
	}

	private now()
	{
		return new Date().getTime();
	}

	private delay(fn:Function)
	{
		if (new Date().getTime() > this.lastRequestTime + 500) {
            fn();
        } else {
            setTimeout(()=>{this.delay(fn)}, 500);
        }
	}
    //---------------------------------------------------------------------------------------------
    private isCalling():boolean {
        return (window['httpCalling'] && window['httpCalling'].length>0);
    }
    //---------------------------------------------------------------------------------------------

}
