import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { ScriptService } from 'src/app/service/script.service';
import { LoadingService } from '../loadingModule/loading.service';
import { faFileAudio, faFileImage, faFile, faFileVideo, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faGlobe, faClone, faCheck } from '@fortawesome/pro-regular-svg-icons';
import downloadjs from "downloadjs";

@Component({
	selector: 'other-book-type-submission-modal',
	template: `
<div class="wrapper type-{{data.type}}">
	<div [ngClass]="['photo', 'type-' + data.type, photo?'preview':'']" [style.backgroundImage]="photo">
		<fa-icon [icon]="icon" *ngIf="icon"></fa-icon>
		<div *ngIf="svgIcon" class="icon icon-{{svgIcon}}"></div>
	</div>
	<div class="name">{{data.name}}</div>
	<div class="status" *ngIf="data.pass">
		<div class="status-complete">{{'open-book.finished'|translate}}</div>
	</div>
	<div class="message">{{ modalMessage }}</div>
	<div class="box" *ngIf="data.type == 'url'">
		<span class="box-link" (click)="linkClick()">{{data.url}}</span>
		<div class="box-copy" (click)="copyClick()">
			<fa-icon [icon]="faClone"></fa-icon>
		</div>
	</div>
	<div class="read-button" *ngIf="data.type != 'url'" (click)="linkClick()">
		<ng-container *ngIf="data.type == 'url'">{{'open-book.browse'|translate}}</ng-container>
		<ng-container *ngIf="data.type == 'youtube'">{{'open-book.watch'|translate}}</ng-container>
		<ng-container *ngIf="data.type != 'youtube' && data.type != 'url'">{{'open-book.open'|translate}}</ng-container>
	</div>
	<ng-container *ngIf="data.pass == 0 || !data.pass">
		<div class="action i-have-done" [class.clicked]="sessionToken" (click)="iHaveDone()">
			<ng-container *ngIf="data[prizeType] > 0">{{('open-book.i-have-done-' + prizeType) | translate: data }}</ng-container>
			<ng-container *ngIf="data[prizeType] == 0">{{'open-book.i-have-done'|translate}}</ng-container>
		</div>
		<div class="action close" (click)="cancelClick($event)">{{'workspace.close'|translate}}</div>
	</ng-container>
	<div class="action close-pass" *ngIf="data.pass" (click)="cancelClick($event)">{{'workspace.close'|translate}}</div>
	
</div>
<div class="toast" *ngIf="toast">
	<fa-icon class="toast-check" [icon]="faCheck"></fa-icon>
	<div class="toast-message">{{'open-book.link-copied'|translate}}</div>
	<div class="toast-exit" (click)="toast=false">
		<fa-icon class="toast-xmark" [icon]="faXmark"></fa-icon>
	</div>	
</div>
<preview #preview></preview>
	
	`,
	styleUrls: ['./otherBookTypeSubmissionModal.modal.scss'],
})

export class OtherBookTypeSubmissionModal implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;

	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('modal', { static: false }) modal;
	@ViewChild('preview', { static: false }) preview;
	public sessionToken;
	public photo;
	public icon;
	public svgIcon;
	faFileAudio = faFileAudio;
	faFileImage = faFileImage;
	faFileVideo = faFileVideo;
	faFile = faFile;
	faGlobe = faGlobe;
	faClone = faClone;
	faXmark = faXmark;
	faCheck = faCheck;
	public toast = false;
	public shortUrl;
	public pageId = null;
	public compId = null;
	public readed = false;
	public prizeType = 'star';
	public modalMessage = '';
	constructor(private san: DomSanitizer, private trans: TranslateService, public datas: DataService, private http: HttpClient, private als: AlertService, private eleRef: ElementRef, private theme: ThemeService, private common: CommonService, private lds: LoadingService) {
	}

	ngOnInit() {
		console.log(this.data);
		this.initModalMessage(this.data);
		this.prizeType = this.data.prize_type?this.data.prize_type:'star';
		this.getStructure();
		this.initTheme(this.eleRef.nativeElement);
		this.initIcon(this.data.type);

	}

	ngAfterViewInit() {
	}

	ngOnDestroy(): void {
	}

	test() {
		// this.audioObj = {url: 'keiKokLibrary/2022/08/09/school(26434)/time(111035)-2022-08-09-111031-62f1d027-162f1d02714ba3-495f07ea.m4a', size: 1000, len: 1000};
	}

	initIcon(sourceType) {

		if (sourceType == 'video') {
			this.icon = this.faFileVideo;
		} else if (sourceType == 'audio') {
			this.icon = this.faFileAudio;
		} else if (sourceType == 'image') {
			this.icon = this.faFileImage;
		} else if (sourceType == 'pdf' || sourceType == 'word' || sourceType == 'excel' || sourceType == 'ppt') {
			this.svgIcon = sourceType;
		} else if (sourceType == 'youtube') {
			if (this.data.imageUrl) {
				this.photo = this.san.bypassSecurityTrustStyle("url('" + this.data.imageUrl + "')");
			}
		} else if (sourceType == 'url') {
			if (this.data.imageUrl == null) {
				this.icon = this.faGlobe;
			} else {
				this.photo = this.san.bypassSecurityTrustStyle("url('" + this.data.imageUrl + "')");
			}
		} else if (sourceType == 'others') {
			this.icon = this.faFile;
		}
	}

	iHaveDone() {
		if (this.data.inMapPreview){
			this.close.emit();
			return;
		} else if (this.sessionToken == null) {
			return;
		}
		let bookId = this.data.book_id;
		let bsid = this.data.bsid;
		let result = { correction: true, score: 0, correct: 2 };
		let addRecord = { type: 'add', tag: this.data.type, doc: bookId, page: this.pageId, com: this.compId, answer: '{}', result: result };
		let submitRecord = { lesson_id: 0, doc: bookId, "page": this.pageId, mode: "none", ref_id: 0, type: "submit" };
		this.lds.add('confirm');
		this.readed = true;
		this.datas.post('ROBookShare.save', [bsid, [addRecord, submitRecord], bookId]).subscribe((res: any) => {
			const summary = { score: 0, green_count: 1, red_count: 0, orange_count: 0, blue_count: 0, total_count: 1, pass: 1 };
			setTimeout(() => {
				this.datas.post('ROCourse.end_book_session', [this.sessionToken, summary]).subscribe((res: any) => {
					this.sessionToken = null;
					this.lds.remove('confirm');
					this.close.emit(this.data);
				});
			}, 200);			
		});
	}

	cancelClick(event) {
		event.stopPropagation();
		if (this.sessionToken) {
			const summary = { score: 0, green_count: 0, red_count: 0, orange_count: 0, blue_count: 0, total_count: 0, forceNotPass: 1 };
			this.datas.post('ROCourse.end_book_session', [this.sessionToken, summary]).subscribe((res: any) => {
				this.sessionToken = null;
			});
		}
		this.close.emit(this.readed?this.data:null);
	}


	initTheme(ele) {
		/*this.theme.applyStyleObj({
			default:{
				"color-333-fff":"#333"
			},
			dark:{
				"color-333-fff":"#fff"
			}
		}, document.body);*/
		this.theme.applyStyleObj({
			default: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#333",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-selectedtextcolor": "var(--section-maincolor)",
				"pulldown-overbgcolor": "#e2e2e2",
				"popupform-panelbgcolor": "#f5f5f5",
				"color-ccc-38374f": "#ccc",
				"popupform-rowbgcolor": "#fff",
				"popupframe-headerbgcolor": "#fff",
				"popupframe-titlecolor": "#333",
				"popupform-subtitlecolor": "#333",
				"popupform-textcolor": "var(--section-maincolor)",
				"color-f9f9f9-1e1e29": "#f9f9f9",
				"color-333-fff": "#333",
				"alert-bgcolor": "#fff",
				"color-f1f1f7-262635": "#f1f1f7",
				"color-f7f7f7-222237": "#f7f7f7",
				"pulldown-selectedbgcolor": "var(--section-maincolor)"
			},
			dark: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-selectedtextcolor": "#fff",
				"pulldown-overbgcolor": "#1e1e29",
				"popupform-panelbgcolor": "#38374f",
				"color-ccc-38374f": "#38374f",
				"popupform-rowbgcolor": "#1e1e29",
				"popupframe-headerbgcolor": "#1e1e29",
				"popupframe-titlecolor": "#fff",
				"popupform-subtitlecolor": "#a0a0a0",
				"popupform-textcolor": "#fff",
				"color-f9f9f9-1e1e29": "#1e1e29",
				"color-333-fff": "#fff",
				"alert-bgcolor": "#2d2c43",
				"color-f1f1f7-262635": "#262635",
				"color-f7f7f7-222237": "#222237",
				"pulldown-selectedbgcolor": "#fff"
			}
		}, ele);

	}

	saveCommentData(answer) {

	}

	copyClick() {
		this.toast = false;
		setTimeout(() => {
			navigator.clipboard.writeText(this.data.url);
			this.toast = true;
			// setTimeout(() => { this.toast = false; }, 6000);
		}, 10);
	}

	linkClick() {
		if (this.sessionToken == null && this.data.action != 'open-read-only') {
			this.datas.post('ROCourse.start_book_session', [this.data.bsid, this.data.book_id]).subscribe((res: any) => {
				this.sessionToken = res.token;
				if (this.data.type == 'youtube'){
					this.preview.setFileList([this.data.fileObj]);
					this.preview.open();
				} else if (this.data.type != 'url' && this.data.type != 'game') {
					this.datas.post('ROWorkspace.get_entry_details', [this.data.book_id]).subscribe((res: any) => {
						if (res.row) {
							let serverUrl = '//oka.blob.core.windows.net/media/';
							this.preview.setUrlPrefix(serverUrl);
							let obj = JSON.parse(res.row.json);
							let arr = obj.url.split('.');
							let fileObj = { name: this.data.name, url: obj.url, ext: arr[arr.length - 1] };
							this.preview.setFileList([fileObj]);
							if (this.data.course_id === undefined) {
								this.preview.setBtns([
									{
										title: this.trans.instant('profile.download'), icon: 'assets/img/preview/btnicon_download_white.svg', callback: () => {
											console.log('download');
											this.datas.get(serverUrl + obj.url, { responseType: 'blob' }).subscribe(blob => {
												downloadjs(blob, this.data.name, blob.type);
											});
										}
									},
								]);
							}
							this.preview.open();
						}
					});
				} else {
					setTimeout(()=>{window.open(this.data.url, '_blank');});
				}
			});
		} else {
			if (this.data.type == 'youtube') {
				let serverUrl = '//oka.blob.core.windows.net/media/';
				this.preview.setUrlPrefix(serverUrl);
				this.preview.setFileList([this.data.fileObj]);
				this.preview.open();
			} else if (this.data.type != 'url' && this.data.type != 'game') { //word/excel/files...
				this.datas.post('ROWorkspace.get_entry_details', [this.data.book_id]).subscribe((res: any) => {
					if (res.row) {
						let serverUrl = '//oka.blob.core.windows.net/media/';
						this.preview.setUrlPrefix(serverUrl);
						let obj = JSON.parse(res.row.json);
						let arr = obj.url.split('.');
						let fileObj = { name: this.data.name, url: obj.url, ext: arr[arr.length - 1] };
						this.preview.setFileList([fileObj]);
						if (this.data.course_id === undefined) {
							this.preview.setBtns([
								{
									title: this.trans.instant('profile.download'), icon: 'assets/img/preview/btnicon_download_white.svg', callback: () => {
										console.log('download');
										this.datas.get(serverUrl + obj.url, { responseType: 'blob' }).subscribe(blob => {
											downloadjs(blob, this.data.name, blob.type);
										});
									}
								},
							]);
						}
						this.preview.open();
					}
				});
			} else {
				setTimeout(()=>{window.open(this.data.url, '_blank');});
			}
		}
	}

	getStructure(){
		const entry_id = this.data.book_id;
		this.datas.post('ROBook.get_book_structure', [entry_id]).subscribe((obj:any)=>{
			this.pageId = '';
			this.compId = '';
			if (obj && obj.book && obj.book.chapters && obj.book.chapters[0].pages){
				const page = obj.book.chapters[0].pages[0];
				if (page.id){
					this.pageId = page.id;
					if (page.components){
						this.compId = page.components[0].cid;
					}
				}
			}
		});
	}

	initModalMessage(data){
		//'open-book.pls-read-' + (data.type=='url'|| data.type=='youtube'?data.type:'file') + (data.pass?'2':'') )|translate
		let msg = 'open-book.pls-read-';
		const nonFileType = ['url', 'youtube','game'];
		if (nonFileType.indexOf(data.type) > -1){
			msg = msg + data.type;
		} else {
			msg = msg + 'file';
		}
		msg = msg + (data.pass?'2':'');
		this.modalMessage = this.trans.instant(msg);
	}

}
