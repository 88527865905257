/**
 * // constructor
 	var caller:DelayCaller = new DelayCaller((list:any [])=>{
		var urlArray:string [] = list.map((obj)=>{
			return obj.input;
		});
		return this.dataService.call("Resource.lookup_array", urlArray, true).then((o:any)=>{
			list.forEach((o2:any, inputIndex:number)=>{

				o.mapping.forEach((temp:any, index:number)=>{
					if(temp.from == o2.input)
					{
						o2.output = temp.to;
					}
				});
			});
		});
	}, 200);
	// call flow
	return this.caller.append({
		input:src
	}).then((obj:any)=>{
		if(obj.output) return obj.output;
		return Promise.reject(obj);
	}).then((data:any)=>{
		return new Promise((resolve)=>{
			setTimeout(()=>{
				resolve(data);
			}, 10);
		});
	});
 */
export class DelayCaller
{
	queue:any = [];
	private delayPromise:Promise<any>
	constructor(private callback:Function, private time:number)
	{

	}

	delay(time:number):Promise<any>
	{
		return new Promise((resolve:Function)=>{
			setTimeout(()=>{resolve(null)}, time);
		})
	}
	
	append(obj:any):Promise<any>
	{
		if(this.queue.length == 0)
		{
			this.delayPromise = this.delay(this.time).then((o:any)=>{
				return this.fetch();
			})
		}
		if(obj)
		{
			this.queue.push(obj);
		}
		return this.delayPromise.then(()=>{
			return obj;
		})
	}
	fetch():Promise<any>
	{
		var fetchList:any []  = this.queue;
		this.delayPromise = null;
		this.queue = [];
		return this.callback.call(null, fetchList);
	}

}
