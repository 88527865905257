import { NgModule } from '@angular/core';
import { NavBarComponent } from './navBar.component';
import { CommonModule } from '@angular/common';
import { PopupToggleModule } from '../popupToggleModule/popupToggle.module';
import { MainMenuBtnModule } from '../mainMenuBtnModule/mainMenuBtn.module';
import { SectionTitleModule } from '../sectionTitleModule/sectionTitle.module';
import { TranslateModule } from '@ngx-translate/core';
import { MainMenuModule } from '../mainMenuModule/mainMenu.module';
import { TopRightToolBarModule } from '../topRightToolBarModule/topRightToolBar.module';


@NgModule({
    imports: [
        CommonModule, PopupToggleModule, MainMenuBtnModule, SectionTitleModule, TranslateModule, MainMenuModule,
		TopRightToolBarModule
    ],
    declarations: [
        NavBarComponent
    ],
    exports: [
        NavBarComponent
    ],
    providers: [],
    bootstrap: []
})
export class NavBarModule { }
