import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { options } from "@mobiscroll/angular";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { UniqueID } from "src/app/common/UniqueID";
import {  ROColorUtils, ROComponent, ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";


@Component({
	// template:`Ball{{node.attributes|json}}`
	/*
	{
		"ver":1.2,
		"borderWidth":3,"borderColor":"#86cb70",
		"borderStyle":"line",
		"shapeAlpha":1,
		"numPoints":1,
		"coordinateExpression":"UA UK X 298 Y 355 D T 355 L 298 H 96.1 W 40.25",
		"startArrowType":"none","endArrowType":"none",
		"arrowSize":5,"locked":false,
		"douid":"B90DA689-6DC5-D8FB-0F63-8BDC4ABEE992",
		"dashW":4,"dashS":5,"dotS":2,"studEditable":false,"lineMode":2}
	
	*/
	template:``,
	// template:`<svg viewBox="0 0 1 1" width="1" height="1"><g><path stroke="#ff0000" d="M 0 0 Q 1 0 1 1 Q 1 2 0 2"></path></g></svg>`,
	styles:[
		``
	]
	
})
export class ROShapeLineComponent extends ROComponent
{
	public size:number = 32;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["ShapeLine"];
	}

	public startPoint:any;
	public endPoint:any;
	protected buildContent():void
	{
		var pts:string []= [];
		var options:any = this.node.attributes;
		var node1:XMLNode = this.node.children[0];
		var node2:XMLNode = this.node.children[1];
		this.startPoint = node1.attributes;
		this.endPoint = node2.attributes;
		
		pts.push(`M${node1.attributes.x} ${node1.attributes.y}`);
		pts.push(`L${node2.attributes.x} ${node2.attributes.y}`);
		
		var pathD:string = pts.join(" ");

		var borderWidth:number = this.node.attributes.borderWidth;
		var strokeColor = ROColorUtils.formatColor(
			this.node.attributes.borderColor, "#000000"
		);
		
		var markerDefintions:string [] = [];
		
		
		var pathAttributes:string [] =[];
		pathAttributes.push(`stroke="${strokeColor}"`);
		if(options.shapeAlpha != 1)
		{
			pathAttributes.push(`opacity="${options.shapeAlpha}"`);
		}
		if(this.node.attributes.borderStyle == "line")
		{
			
		} else if(this.node.attributes.borderStyle == "dashed")
		{
			var dashW:number = this.node.attributes.dashW;
			var dashS:number = this.node.attributes.dashS + borderWidth;
			pathAttributes.push(`stroke-dasharray="${dashW} ${dashS}"`);
		} else 
		{
			var dashSize = this.node.attributes.dotS + borderWidth;
			pathAttributes.push(`stroke-dasharray="0 ${dashSize}"`);
		}
		if(this.node.attributes.startArrowType != "none")
		{
			var arrowSize:number = this.node.attributes.arrowSize;
			var markerStartID:string = UniqueID.createID("marker-");
			pathAttributes.push(
				`marker-start="url(#${markerStartID})"`
			);
			if(this.node.attributes.startArrowType.indexOf("tri")!= -1)
			{
				// arrow
				markerDefintions.push(
					SVGMarkerUtils.createStartArrowMarker(markerStartID, strokeColor, this.node.attributes.arrowSize)
				);
			} else if(this.node.attributes.startArrowType == "ball")
			{
				markerDefintions.push(
					SVGMarkerUtils.createCircleMarker(markerStartID, strokeColor, arrowSize)
				);
			}
		}
		if(this.node.attributes.endArrowType != "none")
		{
			var markerEndID:string = UniqueID.createID("marker-");
			pathAttributes.push(
				`marker-end="url(#${markerEndID})"`
			);
		
			if(this.node.attributes.endArrowType.indexOf("tri")!= -1)
			{
				markerDefintions.push(
					SVGMarkerUtils.createEndArrowMarker(markerEndID, strokeColor, this.node.attributes.arrowSize)
				);
			} else if(this.node.attributes.endArrowType == "ball")
			{
				markerDefintions.push(
					SVGMarkerUtils.createCircleMarker(markerEndID, strokeColor, arrowSize)
				);
			}

		}
		
		if(this.w == 0) this.w = 1;
		if(this.h == 0) this.h = 1;
		
		// ":"none","endArrowType":"none",
		this.elementRef.nativeElement.innerHTML = `
			<svg 
				viewBox="0 0 ${this.w} ${this.h}" width="${this.w}" height="${this.h}" 
				overflow="visible"
				style="position: absolute;"
				>
					${markerDefintions.join("")}
					<path 
						fill="none" 
						stroke-width="${borderWidth}"
						d="${pathD}"
						stroke-linecap="round"
						${pathAttributes.join(" ")}
						>
					</path>
				</svg>
		`
		// d="M 0 0 Q ${this.w} 0 ${this.w} ${h2} Q ${this.w} ${this.h} 0 ${this.h}" 
	}
}

@Component({
	// template:`Ball{{node.attributes|json}}`
	
	template:``,
	// template:`<svg viewBox="0 0 1 1" width="1" height="1"><g><path stroke="#ff0000" d="M 0 0 Q 1 0 1 1 Q 1 2 0 2"></path></g></svg>`,
	styles:[
		/*
		`svg{
			width:100%;
			height:100%;
		}`
		*/
	]
	
})
export class ROShapeCurveLineComponent extends ROComponent
{
	public size:number = 32;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["ShapeCurveLine"];
	}

	protected initVariables():void
	{
		super.initVariables();
	}
	protected buildContent():void
	{
		/*
		{
			"ver":1.2,
			"borderWidth":3,"borderColor":"#86cb70",
			"borderStyle":"line",
			"shapeAlpha":1,
			"numPoints":1,
			"coordinateExpression":"UA UK X 298 Y 355 D T 355 L 298 H 96.1 W 40.25",
			"startArrowType":"none","endArrowType":"none",
			"arrowSize":5,"locked":false,
			"douid":"B90DA689-6DC5-D8FB-0F63-8BDC4ABEE992",
			"dashW":4,"dashS":5,"dotS":2,"studEditable":false,"lineMode":2}
		
		*/
		
		var options:any = this.node.attributes;
		var pts:string []= [];
		var ptn:any = this.quad(0.5, this.node.children);
		
		var node1:XMLNode = this.node.children[0];
		// var node2:XMLNode = this.node.children[1];
		var node3:XMLNode = this.node.children[2];
		
		pts.push(`M ${node1.attributes.x} ${node1.attributes.y}`);
		pts.push(`Q ${ptn.x} ${ptn.y} ${node3.attributes.x} ${node3.attributes.y}`);
		
		var pathD:string = pts.join(" ");

		
		var borderWidth:number = this.node.attributes.borderWidth;
		var strokeColor = ROColorUtils.formatColor(
			this.node.attributes.borderColor, "#000000"
		);
		var markerDefintions:string [] = [];
		var pathAttributes:string [] =[];
		pathAttributes.push(`stroke="${strokeColor}"`);
		if(this.node.attributes.borderStyle == "dashed")
		{
			var dashW:number = this.node.attributes.dashW;
			var dashS:number = this.node.attributes.dashS + borderWidth;
			pathAttributes.push(`stroke-dasharray="${dashW} ${dashS}"`);
			
		} else if(this.node.attributes.borderStyle == "dotted")
		{
			var dashSize = this.node.attributes.dotS + borderWidth;
			pathAttributes.push(`stroke-dasharray="0 ${dashSize}"`);
		}
		if(this.node.attributes.startArrowType != "none")
		{
		
			var markerStartID:string = UniqueID.createID("marker-");
			pathAttributes.push(
				` marker-start="url(#${markerStartID})"`
			);
			
			if(this.node.attributes.startArrowType.indexOf("tri")!= -1)
			{
				// arrow
				markerDefintions.push(
					 SVGMarkerUtils.createStartArrowMarker(markerStartID, strokeColor, this.node.attributes.arrowSize)
				);
				
			} else if(this.node.attributes.startArrowType == "ball")
			{
				markerDefintions.push(
					SVGMarkerUtils.createCircleMarker(markerStartID, strokeColor, this.node.attributes.arrowSize)
				);
			}
		}
		if(this.node.attributes.endArrowType != "none")
		{
			var markerEndID:string = UniqueID.createID("marker-");
			pathAttributes.push(
				`marker-end="url(#${markerEndID})"`
			);
		
			if(this.node.attributes.endArrowType.indexOf("tri")!= -1)
			{
				markerDefintions.push(
					SVGMarkerUtils.createEndArrowMarker(markerEndID, strokeColor, this.node.attributes.arrowSize)
				);
			} else if(this.node.attributes.endArrowType == "ball")
			{
				markerDefintions.push(
					SVGMarkerUtils.createCircleMarker(markerEndID, strokeColor, this.node.attributes.arrowSize)
				);
			}
		}
		
		
		if(options.shapeAlpha != 1)
		{
			pathAttributes.push(`opacity="${options.shapeAlpha}"`);
		}
		// markerString

		var pathAttributeString:String = pathAttributes.join(" ")
		

		// ":"none","endArrowType":"none",
		this.elementRef.nativeElement.innerHTML = `
			<svg 
				viewBox="0 0 ${this.w} ${this.h}" 
				width="${this.w}" height="${this.h}" 
				overflow="visible"
				style="position: absolute;"
				>
					${markerDefintions.join("")}
					<path 
						fill="none" 
						stroke="${strokeColor}" 
						stroke-width="${borderWidth}"
						d="${pathD}"
						stroke-linecap="round"
						
						${pathAttributeString}
						
						>
					</path>
				</svg>
		`
		// d="M 0 0 Q ${this.w} 0 ${this.w} ${h2} Q ${this.w} ${this.h} 0 ${this.h}" 
	}

	
	protected quad(t:number,nodes:XMLNode [], startPoint:number=0):any{
		if (nodes.length < 3) 
		{
			return nodes[1].attributes;
		}
		
		var result:any = {};
		var oneMinusTSq:number = (1-t) * (1-t);
		var TSq:number = t * t;
		var OneMinusT:number = 2*(1 - t)*t;
		
		var pt1:any = nodes[startPoint].attributes;
		var pt2:any = nodes[startPoint + 1].attributes;
		var pt3:any = nodes[startPoint + 2].attributes;
		result.x = (pt2.x - (TSq * pt3.x) - (oneMinusTSq * pt1.x))/OneMinusT;
		result.y = (pt2.y - (TSq * pt3.y) - (oneMinusTSq * pt1.y)) / OneMinusT ;
		return result;
	}

	/*
	protected buildContent():void
	{
		
	}
	*/
}

class SVGMarkerUtils
{
	public static createCircleMarker(id:string, fillColor:string, size:number):string
	{
		return `<marker 
			fill="${fillColor}" 
			id="${id}" 
			viewBox="0 0 100 100" 
			width="100" height="100"
			refX="0" refY="0" 
			markerUnits="userSpaceOnUse" 
			markerWidth="100" 
			markerHeight="100"
			orient="auto"
			overflow="visible"
			>
			<circle cx="0" cy="0" r="${size}" ></circle>
		</marker>`;

	}
	public static createStartArrowMarker(id:string, fillColor:string, arrowSize:number):string
	{
		var markerW:number =  arrowSize * 3;
		var markerH:number = arrowSize * 2;
		var markerRefX:number = markerW;
		var markerRefY:number = arrowSize;
		
		return ` <marker 
				fill="${fillColor}" 
				id="${id}" 
				viewBox="0 0 ${markerW} ${markerH}" 
				refX="${markerRefX}" refY="${markerRefY}"
				markerUnits="userSpaceOnUse" 
				markerWidth="${markerW}" markerHeight="${markerW}" 
				orient="auto"
				overflow="visible"
				>
				<path 
				  d="M ${markerW} 0 
				  	L0 ${markerRefY}
					L${markerW} ${markerH}z"></path>
			</marker>
		`;
	}
	public static createEndArrowMarker(id:string, fillColor:string, arrowSize:number):string
	{
		var markerW:number =  arrowSize * 3;
		var markerH:number = arrowSize * 2;
		var markerRefX:number = markerW;
		var markerRefY:number = arrowSize;

		// arrow
		return `<marker 
			fill="${fillColor}" 
			id="${id}" 
			viewBox="0 0 ${markerW} ${markerH}" 
			refX="0" refY="${markerRefY}" 
			markerUnits="userSpaceOnUse" 
			markerWidth="${markerW}" markerHeight="${markerW}" orient="auto"
			overflow="visible"
			>
		  	<path 
		  		d="M0 0 
				L${markerW} ${markerRefY} 
				L0 ${markerH}z"></path>
		</marker>`;
	}
}