import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PieChartComponent } from './pieChart.component';

@NgModule({
    imports: [
        CommonModule,TranslateModule
    ],
    declarations: [
        PieChartComponent
    ],
    exports: [
        PieChartComponent
    ],
    providers: [
	],
	entryComponents: [PieChartComponent]
})
export class PieChartModule {
}
