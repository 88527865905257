import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BobbleBoxComponent } from './bobbleBox.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        BobbleBoxComponent,
    ],
    exports: [
        BobbleBoxComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class BobbleBoxModule {
}
