import { NgModule } from '@angular/core';
import { ToolTipComponent } from './toolTip.component';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ToolTipComponent
    ],
    exports: [
        ToolTipComponent
    ],
    providers: [],
    bootstrap: []
})
export class ToolTipModule { }
