import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, RendererFactory2, AfterViewInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { ScriptService } from 'src/app/service/script.service';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';
import { LoadingService } from '../loadingModule/loading.service';
import { HttpClient } from '@angular/common/http';
import { getMomentFromObject } from 'src/app/common/customFunction';
import { SettingService } from 'src/app/service/setting.service';

@Component({
	selector: 'open-book-handler',
	template: `
<ng-container *ngIf="ready">
	
	<install-app-modal *ngIf="installAppData" [data]="installAppData" 
		(close)="installAppDataClose()"></install-app-modal>

	<course-book-repeat-open-modal 
		*ngIf="courseBookRepeatOpenModal" [data]="courseBookRepeatOpenModal" 
		(openBook)="openBookHandler($event)" (close)="closeAll()">
		</course-book-repeat-open-modal>

	<book-finish-modal
		*ngIf="bookFinishModal" [data]="bookFinishModal" 
		(openBook)="openBookHandler($event)" (close)="closeAll()">
		</book-finish-modal>

	<book-time-limit-modal
		*ngIf="bookTimeLimitModal" [data]="bookTimeLimitModal"
		(openBook)="openBookHandler($event)" (close)="closeAll()">
		</book-time-limit-modal>
	
	<book-read-only-modal
		*ngIf="bookReadOnlyModal" [data]="bookReadOnlyModal"
		(openBook)="openBookHandler($event)" (close)="closeAll()">
		</book-read-only-modal>
		
	<student-read-report-modal
		*ngIf="studentReadReportModal" [data]="studentReadReportModal"
		(close)="closeAll($event)"
		></student-read-report-modal>

	<ai-oral-practice-modal
		*ngIf="AIOralPracticeModal" [data]="AIOralPracticeModal"
		(close)="closeAll($event)"
		></ai-oral-practice-modal>
	
	<other-book-type-submission-modal 
		*ngIf="otherBookTypeSubmissionModal" [data]="otherBookTypeSubmissionModal" 
		(close)="closeAll($event)"
		></other-book-type-submission-modal>
	
	<lesson-submission-modal
		*ngIf="lessonSubmissionModal" [data]="lessonSubmissionModal"
		(close)="closeAll($event)" (installApp)="setInstallAppData($event)">

		</lesson-submission-modal>

	<AssessmentEditNoRouter *ngIf="assessmentEditNoRouterData" 
		[data]="assessmentEditNoRouterData"></AssessmentEditNoRouter>
	
	<collection-modal *ngIf="collectionModal" [data]="collectionModal"></collection-modal>
	<ai-composition-submission-modal
		*ngIf="aiCompositionModal" [data]="aiCompositionModal"
		(close)="closeAll($event)"></ai-composition-submission-modal>

	<ai-composition-marking-modal
		*ngIf="aiCompositionMarkingModal" [data]="aiCompositionMarkingModal"
		(close)="closeAll($event)"></ai-composition-marking-modal>

	<ai-composition-personal-report
		*ngIf="aiCompositionPersonalReport" [data]="aiCompositionPersonalReport"
		(close)="closeAll($event)"></ai-composition-personal-report>

	<preview #preview></preview>

</ng-container>
  `,
	styleUrls: ['./openBook.handler.scss']
})

export class OpenBookHandler implements OnInit, AfterViewInit, OnDestroy {
	@Input() data;
	@Output() close = new EventEmitter<any>();
	@ViewChild('preview', { static: false }) preview;
	private isInWeb = false;
	private isTeacher = false;
	public installAppData;
	public courseBookRepeatOpenModal;
	public studentReadReportModal;
	public AIOralPracticeModal;
	public otherBookTypeSubmissionModal;
	public lessonSubmissionModal;
	public bookTimeLimitModal;
	public bookReadOnlyModal;
	public bookFinishModal;
	public assessmentEditNoRouterData;
	public collectionModal;
	public aiCompositionModal;
	public aiCompositionMarkingModal;
	public aiCompositionPersonalReport;
	public ready = false;
	public zIndex;
	public fileTypeContains = ['word', 'pdf', 'excel', 'ppt', 'image', 'video', 'audio', 'file'];
	/*
	data: {book_id: book_id, bsid: bsid, type: bookType, shareType: shareType, photo: safedBackgroundImageStyle}

	*/
	public webROBookPreviewEnabled: boolean = false;
	constructor(private datas: DataService, private als: AlertService, private router: Router, private lds: LoadingService, private ros: RoService, private eleRef: ElementRef, public svgReg: InlineSvgService, private renderFactory: RendererFactory2, private common: CommonService, private san: DomSanitizer, private script: ScriptService, private trans: TranslateService, private http: HttpClient, private setting: SettingService, public coms: CommonService) {

	}

	ngOnInit() {

		let data = this.data;
		this.lds.add('open-book-handler');
		let zIndex = this.common.getZIndex();
		this.eleRef.nativeElement.style.zIndex = zIndex;
		this.checkData(data);
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			this.isInWeb = true;
		}
		if (this.datas.userInfo.user_role > 2 && this.datas.userInfo.user_role != 5 && this.datas.userInfo.user_role != 6) {
			this.isTeacher = true;
		}
		try {
			this.coms.waitFor(()=> this.setting.schoolSettings, 30, 200).then(()=>{
				this.webROBookPreviewEnabled = this.setting.schoolSettings.WEB_ROBOOK_PREVIEW_ENABLED == '1' ? true : false;
				this.checkServerTime(data);
			});
		} catch (e) {
			this.closeAll();
			this.close.emit();
			throw e;
		}
		this.initSvgIcons().then(() => {
			this.fetchCover();
			this.lds.remove('open-book-handler');
			this.ready = true;
		});
		
	}

	ngAfterViewInit() {
		let renderer = this.renderFactory.createRenderer(null, null);
		let appRoot = document.querySelector('app-root');
		renderer.appendChild(appRoot, this.eleRef.nativeElement);
	}

	ngOnDestroy(): void {
	}

	initSvgIcons() {
		let icons = [
			{ url: 'btn_close_gray.svg', name: 'btn_close_gray' },
			{ url: 'course/icon_folder.svg', name: 'icon_folder' },
			{ url: 'course/icon_folder_blue.svg', name: 'icon_folder_blue' },
			{ url: 'icon_book.svg', name: 'icon_book' },
			{ url: 'fileicon/fileicon2_pdf.svg', name: 'fileicon2_pdf' },
			{ url: 'fileicon/fileicon2_audio.svg', name: 'fileicon2_audio' },
			{ url: 'fileicon/fileicon2_doc.svg', name: 'fileicon2_doc' },
			{ url: 'fileicon/fileicon2_image.svg', name: 'fileicon2_image' },
			{ url: 'fileicon/fileicon2_others.svg', name: 'fileicon2_others' },
			{ url: 'fileicon/fileicon2_ppt.svg', name: 'fileicon2_ppt' },
			{ url: 'fileicon/fileicon2_qb.svg', name: 'fileicon2_qb' },
			{ url: 'fileicon/fileicon2_video.svg', name: 'fileicon2_video' },
			{ url: 'fileicon/fileicon2_xls.svg', name: 'fileicon2_xls' },
			{ url: 'fileicon/icon_weblink_24x24.svg', name: 'icon_weblink_24x24' },
			{ url: 'fileicon/icon_youtube_24x18.svg', name: 'icon_youtube_24x18' },
			{ url: 'course/icon_elesson_28x23.svg', name: 'icon_elesson_28x23' },
			{ url: 'course/icon_lesson_googlemeet_28x19.svg', name: 'icon_lesson_googlemeet_28x19' },
			{ url: 'course/icon_lesson_teams_28x19.svg', name: 'icon_lesson_teams_28x19' },
			{ url: 'course/icon_lesson_zoom_28x19.svg', name: 'icon_lesson_zoom_28x19' },
			{ url: 'fileicon/icon_reading_report_24x28.svg', name: 'icon_reading_report_24x28' },
			{ url: 'fileicon/icon_reading_report_24x28_en.svg', name: 'icon_reading_report_24x28_en' },
		];
		return this.svgReg.regSvgFiles(icons);
	}

	checkServerTime(data) {
		console.log("checkServerTime", data);
		if (data.startMo == null && data.endMo == null) {
			this.checkOpenBookWorkflow(data);
		} else {
			const startMoToCheck = data.startMo ? data.startMo : getMomentFromObject(new Date());
			const endMoToCheck = data.endMo ? data.endMo : getMomentFromObject('2047-12-31 23:59:59');
			this.datas.post('KenCore.getServerTime').subscribe((res: any) => {
				const serverMo = getMomentFromObject(res.str);
				if (serverMo.isBetween(startMoToCheck, endMoToCheck)) {
					this.checkOpenBookWorkflow(data);
				} else if (data.status == 'finished' && (data.book_type == 'ai-composition' || data.book_type == 'ai-oral') ) { //這兩類即使是過期, 只要是已完成, 都出個人批改
					this.checkOpenBookWorkflow(data);
				} else {
					if (serverMo.isBefore(startMoToCheck)) {
						data.status = data.status && data.status.indexOf('correction') > -1 ? 'correction-not-start' : 'not-start';
					} else {
						data.status = data.status && data.status.indexOf('correction') > -1 ? 'correction-expired' : 'expired';
					}
					this.bookReadOnlyModal = data;
				}
			});
		}
	}

	checkOpenBookWorkflow(data, callIndex = 0) {
		console.log("checkOpenBookWorkflow", data);
		if (data.status == 'checking' && !this.isTeacher && data.action != 'open-read-only'){
			this.bookReadOnlyModal = data;
		} else if (data.action == 'checking') {
			this.openAsChecking(data);
		} else if (data.action == 'open-read-only') {
			this.openAsReadOnly(data);
		} else if (data.action == 'open-ai-oral-practice') {
			this.openAIOralPractice(data);
		} else if (data.action == 'course-book-repeat-open' || data.status == 'finished') {
			this.openAsComplete(data);
		} else if (data.action == 'create-student-free-report' || data.action == 'edit-student-report') {
			this.openReport(data);
		} else if (data.action == 'view-student-result') {
			this.openAsViewStudentResult(data);
		} else if (data.action == 'open-complete') {
			this.openAsComplete(data);
		} else if (data.action == 'open-expired' || data.action == 'open-not-start' || data.action == 'open-marking' ||
			data.action == 'open-correction-not-start' || data.action == 'open-correction-expired') {
			this.bookReadOnlyModal = data;
		} else if (data.action == 'open-test-exam' ||
			((data.time_limit > 0 || data.share_type == 'test' || data.share_type == 'exam') && callIndex == 0)
			&& data.type == 'book') {
			this.openTextExam(data);//revise later
		} else if (data.action == 'open-read-only') {
			this.openAsReadOnly(data);
		} else if (data.action == 'clean-redo') {
			this.openAsNormal(data);
		} else if (data.action == 'normal') {
			this.openAsNormal(data);
		}
	}

	openAsNormal(data) {
		this.addVisitLog();
		if (data.type == 'book' || data.type == 'wordSpeechPractice') {
			this.openBook(data);
		} else if (data.type == 'assessment' || data.type == 'pdf-paper') {
			this.openAssessmentPdfPaper(data);
		} else if (data.type == 'report') {
			this.openReport(data);
		} else if (data.type == 'course-lesson') {
			this.openLesson(data);
		} else if (data.type == 'url') {
			this.openLink(data);
		} else if (data.type == 'youtube') {
			this.openYoutube(data);
		} else if (this.fileTypeContains.indexOf(data.type) > -1) {
			this.openFiles(data);
		} else if (data.type == 'collection') {
			this.openCollection(data);
		} else if (data.type == 'ai-oral') {
			this.openAIOralPractice(data);
		} else if (data.type == 'ai-composition') {
			this.openAIComposition(data);
		} else if (data.type == 'game'){
			this.openGame(data);
		}
	}

	private openAIOralPractice(data,readonly=false) {
		data.readonly = readonly;
		this.AIOralPracticeModal = this.data;
	}

	openAsChecking(data) {
		if (this.isTeacher == false) { // abnormal case
			this.als.okAlert('not authorized for student');
			return;
		}
		if (data.share && data.share.details && data.share.details.subType == 'aiOral'){
			data.type = 'ai-oral';
		}
		if (data.isWSP && data.subType == 'aiOral'){
			data.type = 'ai-oral';
		}
		if (data.type == 'assessment' || data.type == 'pdf-paper') {
			let path: any = [0, data.viewOnly && data.type == 'pdf-paper' ? 'submission_review' : 'scoring'];
			if (data.uid) {
				path.push(data.uid);
			}
			this.lds.add('bookViewer');
			this.router.navigate(['bookViewer', this.datas.lang, this.datas.appId, data.bsid, data.book_id, ...path]).then(() => {
				this.lds.remove('bookViewer');
				this.closeAll();
			});
		} else if (data.type == 'report') {
			this.als.okAlert('common.system-function-not-ready');
			this.closeAll();
		} else if (data.type == 'book') {
			if (this.isInWeb) {
				if (data.isWSP || (data.share && data.share.details && data.share.details.isWSP === 1)){
					let path: any = [0, data.viewOnly && data.type == 'pdf-paper' ? 'submission_review' : 'scoring'];
					if (data.uid) {
						path.push(data.uid);
					}
					this.lds.add('bookViewer');
					this.router.navigate(['bookViewer', this.datas.lang, this.datas.appId, data.bsid, data.book_id, ...path]).then(() => {
						this.lds.remove('bookViewer');
						this.closeAll();
					});
					return;
				}
				this.lds.add('create_share_url_for_approval');
				this.datas.post('ROBookShare.create_share_url_for_approval', [data.bsid]).subscribe((res: any) => {
					if (res.url) {
						this.lds.remove('create_share_url_for_approval');
						let href = res.url.replace('https', this.setting.getOpenAppProtocol(this.datas.appId2, false));
						window.location.href = href;
						this.lds.removeAll();
						setTimeout(() => {
							if (document.hasFocus()) {
								this.setInstallAppData(href);
							} else {
								this.close.emit(null);
							}
						}, 1000);
					}
				});
			} else {
				this.lds.add('viewStudentResult');
				this.ros.request('viewStudentResult', data).then((data: any) => {
					this.lds.removeAll();
					this.close.emit(null);
				});
			}
		} else if (data.type == 'report') {
			this.openReport(data);
		} else if (data.type == 'course-lesson') {
			this.openLesson(data);
		} else if (data.type == 'url') {
			this.openLink(data);
		} else if (data.type == 'youtube') {
			this.openYoutube(data);
		} else if (this.fileTypeContains.indexOf(data.type) > -1) {
			this.openFiles(data);
		} else if (data.type == 'ai-composition'){
			this.openAICompositionMarking(data);
		} else if (data.type == 'ai-oral'){
			this.openAIOralMarking(data);
		} else {
			this.als.okAlert('common.system-function-not-ready');
			this.closeAll();
		}
	}

	openAsReadOnly(data) {
		if (data.type == 'book') {
			this.openBook(data, true);
		} else if (data.type == 'assessment' || data.type == 'pdf-paper') {
			this.openAssessmentPdfPaper(data, true);
		} else if (data.type == 'report') {
			this.openReport(data, true);
		} else if (data.type == 'course-lesson') {
			this.openLesson(data, true);
		} else if (data.type == 'url') {
			this.openLink(data, true);
		} else if (data.type == 'youtube') {
			this.openYoutube(data, true);
		} else if (this.fileTypeContains.indexOf(data.type) > -1) {
			this.openFiles(data);
		} else if (data.type == 'collection') {
			this.openCollection(data, true);
		} else if (data.type == 'ai-composition'){
			this.openAIComposition(data, true);
		} else if (data.type == 'ai-oral'){
			this.openAIOralPractice(data, true);

		}
	}

	openAsViewStudentResult(data) {
		let uid = data.uid ? data.uid : this.datas.userInfo.uid;
		let bsid = data.bsid;
		let bookId = data.book_id;
		if (data.type == 'book') {
			if (this.isInWeb) {
				this.lds.add('create_view_student_result_url');
				let chapterId = data.chapter_id ? data.chapter_id : null;
				let pageId = data.page_id ? data.page_id : null;
				this.datas.post('ROBook.create_view_student_result_url', [uid, bsid, bookId, chapterId, pageId, 0]).subscribe((res: any) => {
					let url = res.url.replace('https', this.setting.getOpenAppProtocol(this.datas.appId2, false));
					if (url.indexOf('?') > -1) {
						url = url + '&identity=' + this.datas.jwt;
					} else {
						url = url + '?identify=' + this.datas.jwt;
					}
					location.href = url;
					this.lds.remove('create_view_student_result_url');
					setTimeout(() => {
						if (document.hasFocus()) {
							this.installAppData = { href: url };
						} else {
							this.closeAll();
						}
					}, 1000);
				});
			} else {
				this.lds.add('viewStudentResult');
				let apiData = { tag: data.tagId ? data.tagId : null, bsid: bsid, bid: bookId, index: data.submitIndex ? data.submitIndex : 1 }; //1 = 初次提交/ 2 = 改正
				this.ros.request('viewStudentResult', apiData).then((data: any) => {
					this.lds.removeAll();
					this.close.emit(null);
				});
			}
		} else if (data.type == 'assessment') {
			this.router.navigate(['bookViewer', this.datas.lang, this.datas.appId2, bsid, bookId, '0/scoring/' + uid]).then(() => {
				this.closeAll();
			});

		} else if (data.type == 'report') {
			this.openReport(data, true);
		} else if (data.type == 'course-lesson') {
			this.openLesson(data, true);
		} else if (data.type == 'url') {
			this.openLink(data, true);
		} else if (data.type == 'youtube') {
			this.openYoutube(data, true);
		} else if (this.fileTypeContains.indexOf(data.type) > -1) {
			this.openFiles(data);
		}
	}

	openTextExam(data) {
		this.bookTimeLimitModal = data;
	}

	installAppDataClose() {
		this.installAppData = null;
		this.close.emit(null);
	}

	openBook(data, readOnly = false) {
		if (this.isInWeb) {
			if (readOnly) {
				if (this.webROBookPreviewEnabled) {
					this.router.navigate(['bookViewer', this.datas.lang, this.datas.appId2, data.bsid, data.book_id, '0', 'review']).then(() => {
						setTimeout(() => { this.closeAll(); }, 10);
					});
					return;
				}
				this.datas.post('ROBookShare.create_view_book_share', [data.bsid, data.book_id, null, null, 1]).subscribe((res: any) => {
					let url = res.url.replace('https', this.setting.getOpenAppProtocol(this.datas.appId2, false));
					if (url.indexOf('?') > -1) {
						url = url + '&identity=' + this.datas.jwt;
					} else {
						url = url + '?identify=' + this.datas.jwt;
					}
					location.href = url;
					setTimeout(() => {
						if (document.hasFocus()) {
							this.setInstallAppData(url);
						} else {
							this.closeAll();
						}
					}, 1000);
				});
			} else if (this.webROBookPreviewEnabled) {
				this.router.navigate(['bookViewer', this.datas.lang, this.datas.appId2, data.bsid, data.book_id, '0', 'view']).then(() => {
					setTimeout(() => { this.closeAll(); }, 10);
				});
			} else {
				this.datas.post('ROBookShare.open_book_share', [data.bsid, data.book_id]).subscribe((res: any) => {
					if (res.url == null) {
						this.toastErr();
						return;
					}
					let url = res.url.replace('https', this.setting.getOpenAppProtocol(this.datas.appId2, false));
					if (url.indexOf('?') > -1) {
						url = url + '&identity=' + this.datas.jwt;
					} else {
						url = url + '?identify=' + this.datas.jwt;
					}
					// location.href = url;
					setTimeout(() => {
						if (document.hasFocus() || true) {
							this.setInstallAppData(url);
						} else {
							this.closeAll();
						}
					}, 1000);
				});
			}
		} else { // in app
			if (readOnly || data.action == 'clean-redo') {
				this.lds.add('open-book');
				const mode = data.action == 'clean-redo' ? 'clean-redo' : 'read-index-1';
				let apiData = { mode: mode, type: 'book_share', bsid: data.bsid, bid: data.book_id, force: 1 };
				this.ros.request('openBookAs', apiData).then(res => {
					this.lds.remove('open-book');
				}).catch(e => {
					this.lds.remove('open-book');
					this.als.okAlert('cannot-open-book');
				});
			} else {
				let ts = + +new Date();
				let hash = 'open/book_share/' + data.bsid + '/' + data.book_id + '/1/' + ts;
				this.closeAll();
				location.hash = hash;
			}
		}
	}

	openCollection(data, readOnly = false) {
		this.datas.post('ROBookShare.get_book_detail', [data.bsid]).subscribe((res: any) => {
			if (res.entries) {
				res.entries[0].children.sort((a, b) => {
					let aKey = (a.type == 'collection' ? 1 : 2) + a.title;
					let bKey = (b.type == 'collection' ? 1 : 2) + b.title;
					if (aKey > bKey) return 1;
					if (aKey < bKey) return -1;
					return 0;
				});
				const collectionModal = {
					...data, info: res, close: (bookData: any) => {
						if (bookData) {
							console.log(bookData);
							this.checkServerTime(bookData)
						} else {
							this.collectionModal = null;
							this.closeAll();
						}
					}
				};
				this.collectionModal = collectionModal;
			} else {
				this.als.toastError('under construction');
			}
		});

	}

	openAssessmentPdfPaper(data, readOnly = false) {
		let mode = readOnly ? 'review' : 'view';
		if (data.status && data.status.indexOf('correction') > -1) {
			mode = 'correction';
		}
		mode = data.mode ? data.mode : mode;
		let assessmentEditData = JSON.parse(JSON.stringify(data));
		assessmentEditData.mode = mode;
		assessmentEditData.close = (data) => {
			this.assessmentEditNoRouterData = null;
			this.closeAll(data);
		};
		// this.assessmentEditNoRouterData = assessmentEditData;
		if (this.data.bsid) {
			this.router.navigate(['bookViewer', this.datas.lang, this.datas.appId2, this.data.bsid, this.data.book_id, '0', mode]).then(() => {
				setTimeout(() => { this.closeAll(); }, 10);
			})
			console.log(`/bookViewer/${this.datas.lang}/${this.datas.appId2}/${this.data.bsid}/${this.data.book_id}/0/${mode}`);
		}
		/*
		this.router.navigate(['assessmentEdit', this.datas.lang, this.datas.appId2, this.data.book_id, 'previewOnly']).then(()=>{
			this.closeAll(readOnly? null: data);
		});*/

	}

	openReport(data, readOnly = false) {
		if (data.bsid) {
			let inputMode = data.inputMode ? data.inputMode : 'free';
			let bookTitle = data.name ? data.name.replace('閱讀報告 - ', '') : '';
			let cover = null;
			if (data.book) {
				bookTitle = data.book.title;
				cover = data.book.cover;
			}
			this.studentReadReportModal = { bsid: data.bsid, book_id: data.book_id, bookAssigned: data.bookAssigned, inputMode: inputMode, bookTitle: bookTitle, cover: cover, readOnly: readOnly, bookType: data.bookType, website: data.website, remark: data.remark, modalTitle: data.modalTitle, workspace_book_id: data.workspace_book_id, file: data.file };
			if (data.course_id) {
				this.studentReadReportModal.num = data.num;
				this.studentReadReportModal.bookItemName = data.name;
			}
		} else if (data.comment) {
			this.studentReadReportModal = { bsid: data.bsid, comment: data.comment };
		} else if (readOnly) {
			let inputMode = data.inputMode ? data.inputMode : 'free';
			let bookTitle = data.name ? data.name.replace('閱讀報告 - ', '') : '';
			this.studentReadReportModal = { book_id: data.book_id, bookAssigned: data.bookAssigned, inputMode: inputMode, bookTitle: bookTitle, cover: data.cover, readOnly: readOnly, bookType: data.bookType, website: data.website, remark: data.remark, modalTitle: data.modalTitle, workspace_book_id: data.workspace_book_id, file: data.file };
			if (data.course_id) {
				this.studentReadReportModal.num = data.num;
				this.studentReadReportModal.bookItemName = data.name;
			}
		} else { //create student free reading report
			this.studentReadReportModal = {
				bookAssigned: 0, bookTitle: '', inputMode: 'free', inputMethod: 'text'
			};
		}
	}

	openLesson(data, readOnly = false) {
		if (data.lessonType == 'physical') {
			if (this.datas.userInfo.school_id == 20982) {
				data.locationText = data.location;
				this.lessonSubmissionModal = data;
				return;
			}
			this.lds.add('get_option_define');
			this.datas.post('ROSchoolOptionDefine.get_option_define', ['location']).subscribe((res: any) => {
				if (res.json.options) {
					let locationOptions = res.json.options.map(opt => {
						return { label: opt.labels[this.datas.lang], value: opt.value };
					});
					this.lds.remove('get_option_define');
					let found = locationOptions.find(e => e.value == data.location);
					data.locationText = found ? found.label : data.location;
					this.lessonSubmissionModal = data;
				}
			});
			return;
		}
		this.lessonSubmissionModal = data;
	}

	openLink(data, readOnly = false) {
		let url = data.url;
		if (url == null) {
			this.toastErr();
			return;
		}
		if (url.indexOf('://') == -1) {
			url = 'http://' + url;
		} else if (url.substr(0, 4) != 'http') {
			url = 'http:' + url;
		}
		this.lds.add('getSeoImage');
		this.datas.post('ROLink.getSeoImage', [url]).subscribe((res: any) => {
			let modalData: any = JSON.parse(JSON.stringify(data));
			modalData.url = url;
			if (res && res.html) {
				const parser = new DOMParser();
				const htmlDoc = parser.parseFromString(res.html, 'text/html');
				let imageUrl = null;
				if (htmlDoc.querySelector('meta[property="og:image"]')) {
					imageUrl = htmlDoc.querySelector('meta[property="og:image"]').getAttribute('content');
				} else if (htmlDoc.querySelector('link[rel="icon"]')) {
					imageUrl = htmlDoc.querySelector('link[rel="icon"]').getAttribute('href');
				}
				modalData.imageUrl = imageUrl;
			}
			this.otherBookTypeSubmissionModal = modalData;
			this.lds.remove('getSeoImage');
		});
	}

	openYoutube(data, readOnly = false) {
		let url = data.url;
		if (url == null) {
			this.toastErr();
			return;
		}
		let yid = null;
		if (url.indexOf('youtu.be') > -1) {
			let arr = url.split('/');
			yid = arr[arr.length - 1];
		} else if (url.indexOf('?v=') > -1) {
			let start = url.indexOf('?v=') + 3;
			let end = url.indexOf('&', start);
			if (end > -1) {
				yid = url.substr(start, end - start);
			} else {
				yid = url.substr(start);
			}
		} else {
			url = url.substr(0, url.indexOf('#') == -1 ? url.length : url.indexOf('#'));
			url = url.substr(0, url.indexOf('?') == -1 ? url.length : url.indexOf('?'));
			let arr = url.split('/');
			yid = arr[arr.length - 1];
		}
		let modalData: any = JSON.parse(JSON.stringify(data));
		modalData.imageUrl = '//i3.ytimg.com/vi/' + yid + '/0.jpg';
		// modalData.imageUrl = '//i.ytimg.com/vi/' + yid + '/maxresdefault.jpg'; //未必有
		modalData.fileObj = { name: data.name, url: yid, ext: 'youtube', readOnly: readOnly };
		modalData.title = data.name;
		modalData.youtube_id = yid;
		this.otherBookTypeSubmissionModal = modalData;
	}

	openGame(data, readOnly = false){
		let url = data.url;
		if (!url){
			this.als.toastError('no url');
			return;
		}
		if (url.indexOf('?') > -1){
			url = url + '&jwt=' + this.datas.jwt;
		} else {
			url = url + '?jwt=' + this.datas.jwt;
		}
		window.open(url, '_blank');
		this.closeAll();
	}

	openFiles(data, readOnly = false) {
		this.datas.post('ROWorkspace.getFileLink', [data.book_id]).subscribe((res: any) => {
			if (res.row) {
				let obj = JSON.parse(res.row.json);
				if (obj.url == null) {
					this.toastErr();
					return;
				}
				let arr = obj.url.split('.');
				let modalData: any = JSON.parse(JSON.stringify(data));
				modalData.fileObj = { name: data.name, url: obj.url, ext: arr[arr.length - 1], readOnly: readOnly };
				modalData.title = data.name;
				modalData.url = obj.url;
				this.otherBookTypeSubmissionModal = modalData;
			} else {
				this.als.okAlert('Asset not found').then(() => {
					this.closeAll();
				});
			}
		});
	}

	openAIComposition(data, readOnly = false) {
		this.datas.post('ROLearnProgress.getBookShare', [data.bsid]).subscribe((res: any) => {
			const structure = JSON.parse(res.structure);
			const info = structure.book.chapters[0].info;
			this.aiCompositionModal = { info: info, share: res.share, structure: structure, entry: res.entry, previewComp: this.preview, readOnly: readOnly };
			if (data.callback){
				this.aiCompositionModal.callback = data.callback;
			}
		});
	}

	openAICompositionMarking(data){
		const share = data.share;
		console.log("sahre", data.share);
		this.coms.waitFor(()=> this.ready, 50,300).then(()=>{
			const tagName = share.tag_name || share.tagName || share.tag_title || '';
			this.aiCompositionMarkingModal = {info: share.info, share: share, tag_title: tagName, entry_title: share.title, previewComp: this.preview };
			if (data.callback){
				this.aiCompositionMarkingModal.callback = data.callback;
			}
		});
	}

	openAICompositionPersonalReport(data){
		this.datas.post('ROLearnProgress.getBookShare', [ data.bsid ]).subscribe((res: any) => {
			const structure = JSON.parse(res.structure);
			const info = structure.book.chapters[0].info;
			const uid = this.datas.userInfo.uid;
			let students = [ {uid: uid, submitted: data.total_count != '0' }];
			let share = res.share;
			share.statusText = {tc:'已完成', sc:'已完成', en:'Finished'}[this.datas.lang];
			this.aiCompositionPersonalReport = { info: info, share: res.share, students: students, selectedUid: uid, readOnly: true };
			if (data.callback){
				this.aiCompositionPersonalReport.callback = data.callback;
			}
		});
	}

	openAIOralMarking(data){

		const share = data.share;
		console.log("share", share);
		this.coms.waitFor(()=> this.ready, 50,300).then(()=>{
			const tagName = share.tag_name || share.tagName || share.tag_title || '';
			this.aiCompositionMarkingModal = {info: share.info, share: share, tag_title: tagName, entry_title: share.title, previewComp: this.preview };
			if (data.callback){
				this.aiCompositionMarkingModal.callback = data.callback;
			}
		});
	}

	addVisitLog() {
		this.datas.post('ROBookshelfKen.logSectionVisit', ['read']).subscribe();
	}

	openAsComplete(data) {
		data.status = 'normal';
		if (data.type == 'url') {
			this.openLink(data);
		} else if (data.type == 'youtube') {
			this.openYoutube(data);
		} else if (data.type == 'report') {
			this.openReport(data);
		} else if (data.type == 'course-lesson') {
			this.openLesson(data);
		} else if (data.type == 'ai-composition') {
			this.openAICompositionPersonalReport(data);
		} else if (data.type == 'ai-oral'){
			this.openAIOralPractice(data);
		} else if (data.type != 'book' && data.type != 'pdf-paper' && data.type != 'assessment') {
			this.openFiles(data);
		} else if (data.action == 'course-book-repeat-open') {
			this.courseBookRepeatOpenModal = data;
		} else {
			this.bookFinishModal = data;
		}
	}

	openBookHandler($event = null) {
		this.courseBookRepeatOpenModal = null;
		this.bookFinishModal = null;
		this.bookTimeLimitModal = null;
		this.bookReadOnlyModal = null;
		if ($event == null) { //normal open
			this.data.action = 'normal';
		} else if ($event.mode == 'read-index-1') {
			this.data.action = 'open-read-only';
		} else if ($event.mode == 'clean-redo') {
			this.data.action = 'clean-redo';
		}
		this.checkOpenBookWorkflow(this.data, 1);
	}

	closeAll(data = null) {
		console.log("closeAll");
		console.log("data", data);
		setTimeout(() => {
			this.installAppData = null;
			this.courseBookRepeatOpenModal = null;
			this.bookFinishModal = null;
			this.studentReadReportModal = null;
			this.otherBookTypeSubmissionModal = null;
			this.installAppData = null;
			this.collectionModal = null;
			this.AIOralPracticeModal = null;
			this.aiCompositionModal = null;
			this.lds.removeAll(); //remove collection load
			this.close.emit(data);
		}, 10);
		setTimeout(() => {
			const ele = document.querySelector('open-book-handler');
			if (ele) {
				ele.remove();
			}
		}, 100);
	}

	fetchCover() {
		let data = this.data;
		if (this.data.type == 'book') {
			this.datas.post('ROBookshelf.get_single_book_cover2', [data.bsid, data.book_id]).subscribe((res: any) => {
				let url = this.script.imgUrl + 'course/blank-book.jpg';
				if (res.url) {
					url = res.url
						.replace('http://', '//').replace('https://', '//')
						.replace('storage.openknowledge.hk', 'oka.blob.core.windows.net');
				}
				data.photo = this.san.bypassSecurityTrustStyle("url('" + url + "')");
			});
		} else if (data.type == 'collection') {
			data.photo = {};//rev later
		} else if (data.type == 'assessment') {
			let url = this.script.imgUrl + 'course/blank-assessment.jpg';
			data.photo = this.san.bypassSecurityTrustStyle("url('" + url + "')");
		} else if (data.type == 'report') {
			data.photo = {};//rev later
		} else {
			data.photo = {};
		}

	}

	setInstallAppData(href) {
		this.lessonSubmissionModal = null;
		this.installAppData = { href: href };
	}

	toastErr() {
		this.closeAll();
		this.als.toastError('open-book.open-book-fail');
		this.close.emit();
	}

	checkData(data) {
		if (data.type == null && data.entry_type !== undefined) {
			data.type = data.entry_type;
		}
		if (data.entryType == null) {
			if (data.type) {
				data.entryType = data.type;
			}
		}
		if (data.start_time) {
			data.startMo = getMomentFromObject(data.start_time);
		}
		if (data.end_time) {
			data.endMo = getMomentFromObject(data.end_time);
		}
		if (data.correction_start_time) {
			data.correctionStartMo = getMomentFromObject(data.correction_start_time);
		}
		if (data.correction_end_time) {
			data.correctionEndMo = getMomentFromObject(data.correction_end_time);
		}
		if (data.bookId === undefined) {
			data.bookId = data.book_id;
		}
		if (data.book_id === undefined) {
			data.book_id = data.bookId;
		}
		return data;
	}
}
