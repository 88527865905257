import {
	Directive, Output, EventEmitter, HostBinding, HostListener, ElementRef
} from '@angular/core';
import { DragManager } from '../sharedModule/roBookModule/DragManager';
import { Subject, Subscription } from 'rxjs';
import { DOMHelper } from '../common/DOMHelper';

@Directive({
	selector: '[drag-to-scroll]'
})
export class DragToScrollDirective {
	@Output() public tapElement:EventEmitter<Element> = new EventEmitter();

	public onDrag:boolean = false;
	public startx:number;
	public starty:number;
	public diffx:number;
	public diffy:number;
	public dom:any;
	public speed:number = 0.03;
	protected timecount:number;
	protected animateTimer:number = -1;
	protected lastTime:number = 0;

	private dd:DragManager;

	constructor(private elementRef:ElementRef)
	{
		this.dom = elementRef.nativeElement;
		this.dd = new DragManager();
	}
	
	@HostListener('touchstart', ['$event'])
	@HostListener('mousedown', ['$event'])
	startPress(e):void {
		if (this.onDrag === false) {
			this.onDrag = e.offsetX <= e.target.clientWidth && e.offsetY <= e.target.clientHeight;
			this.startx = e.clientX + this.dom.scrollLeft;
			this.starty = e.clientY + this.dom.scrollTop;
			this.diffx = 0;
			this.diffy = 0;


			e.stopImmediatePropagation();
			var startTime:number = new Date().getTime();
			var moveDistance:number = 0;
			var subject:Subject<any> = e.type == "touchstart" ?  this.dd.touchStart(e) : this.dd.pointerStart(e);
			var subscription:Subscription = subject.subscribe((o:any)=>{
				
				if(o.type == "start") {
				} else if(o.type == "move") {
					if (this.onDrag) {
						this.diffx = (this.startx - (o.point.x + this.dom.scrollLeft));
						this.diffy = (this.starty - (o.point.y + this.dom.scrollTop));
						this.dom.scrollLeft += this.diffx;
						this.dom.scrollTop += this.diffy;
						moveDistance+=Math.sqrt(this.diffx * this.diffx + this.diffy * this.diffy);
					}
				} else if(o.type == "end" || o.type == "cancel" || o.type == "timeout") {
					subscription.unsubscribe();

					this.onDrag = false;
					this.timecount = 1;
					this.updateAnimationFrame();
					if(o.type == "end" && new Date().getTime() - startTime <= 250 && moveDistance <= 8) {
						this.tapElement.emit(document.elementFromPoint(o.point.x, o.point.y));
					}
				}
			});

		}
	}


	protected updateAnimationFrame():void {
		var step:number = Math.sin(this.timecount)*1.1;
		if (step <= 0) {
			// 動畫完結
			this.diffx = 0;
			this.diffy = 0;
			this.onDrag = false;
			if(this.animateTimer!=-1) {
				clearTimeout(this.animateTimer);
				this.animateTimer = -1;
			}
			
		} else {
			// 餘力移動
			this.dom.scrollLeft += this.diffx * step;
			this.dom.scrollTop += this.diffy * step;
			this.timecount -= this.speed;
			
			var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - this.lastTime));
			this.lastTime = currTime + timeToCall;
            this.animateTimer = window.setTimeout(()=>{this.updateAnimationFrame();}, timeToCall);
		}
	}
}
