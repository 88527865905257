import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SubjectSelector2Component } from './subject-selector2.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ExpandableComponent, TreeSelectorComponent, TreeNodeComponent, TreeSelectorContentComponent, LearningObjectivePopupSelector } from './node-selector.component';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';

@NgModule({
    imports: [
		CommonModule,TranslateModule,FormsModule,FontAwesomeModule, PerfectScrollbarModule, BubbleBox2Module, OkaPulldownModule
    ],
	exports:[
		LearningObjectivePopupSelector, SubjectSelector2Component, TreeSelectorContentComponent, TreeSelectorComponent, TreeNodeComponent, ExpandableComponent
	],
    declarations: [
		LearningObjectivePopupSelector, SubjectSelector2Component, TreeSelectorContentComponent, TreeSelectorComponent, TreeNodeComponent, ExpandableComponent
    ],
	entryComponents: [
		LearningObjectivePopupSelector
    ],
	providers:[]
})
export class SubjectModule { }
