import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Alert2Component } from './alert2.component';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeService } from 'src/app/service/theme.service';



@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        Alert2Component
    ],
    exports: [
    ],
    providers: [
      ThemeService
    ],
    entryComponents: [
        Alert2Component
    ]
})
export class Alert2Module {
}
