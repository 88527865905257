import { Injectable } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })

export class TagGroupService {
	public schoolYears: any;
	public tags:any = [];
	public inBranch = false;
	public subs$;
	public defaultSchoolYear;
	private schoolId;
	constructor(private datas: DataService, private sans: DomSanitizer) {
		if (this.datas.userInfo && this.schoolYears == null && this.subs$ == null) {
			this.subs$ = this.loadSchoolYear().subscribe(()=>{
				this.defaultSchoolYear = this.getDafaultYear();
			});
		}
	}

	loadSchoolYear() {
		return this.datas.post('ROWorkspace.get_school_year_and_tag').pipe(map((res: any) => {
			if (res.account) {
				this.schoolId = res.account.school_id;
				this.tags = res.tags;
				this.inBranch = this.tags.find(t => t.my_tag == 1 && t.gtype != 'main') && !this.tags.find(t => t.my_tag == 1 && t.gtype == 'main' && t.type != 1 && t.rid != null);
				this.schoolYears = res.school_years;
				this.schoolYears.forEach(year => {
					year.start_time = year.start_time ? moment.unix(year.start_time) : null;
					year.end_time = year.end_time ? moment.unix(year.end_time) : null;
					year.tags = this.tags.filter(t => t.school_year == year.id && t.type == 3);
					year.tagClass = this.tags.filter(t => t.school_year == year.id && t.type == 9);
					year.tagClass.forEach(tc => {
						tc.tags = year.tags.filter(t => t.pid == tc.id);
					});
				});
			}
			this.defaultSchoolYear = this.getDafaultYear()
			return res;
		}));
	}

	getTags(options:any={active: 'all', schoolYear: 'all'}){
		return new Promise((resolve, reject)=>{
			let interval = setInterval(()=>{
				if (this.schoolYears){
					clearInterval(interval);
					setTimeout(()=>{
						this.defaultSchoolYear = this.getDafaultYear();
						let output = {tags: this.tags, schoolYears: this.schoolYears, defaultSchoolYear: this.defaultSchoolYear };
						resolve(output);
					},100);
				}
			},100);
			setTimeout(()=>{
				clearInterval(interval);
				reject({msg: 'getTags: timeout'});
			},5000);
		});
	}

	getDafaultYear(){
		let output = null;
		if (this.schoolYears.length == 0){
			return null;
		}
		this.schoolYears.forEach(y=>{
			if (output == null){
				if (y.start_time && y.end_time){
					if (y.start_time.isBefore() && y.end_time.isAfter()){
						output = y;
					}
				} else if (y.start_time){
					if (y.start_time.isBefore()){
						output = y;
					}
				} else if (y.end_time){
					if (y.end_time.isAfter()){
						output = y;
					}
				}
			}
		});
		return output?output:this.schoolYears[0];
	}

	resetDataifSchoolChange(){
		if (this.datas.userInfo && this.datas.userInfo.school_id){
			if (this.datas.userInfo.school_id != this.schoolId){
				this.loadSchoolYear().subscribe();
			}
		}
	}


}
