export class SectionColorCode
{
	public static color = {
		A:"#5BB9D9",
		B:"#FFA346",
		C:"#7085C8",
		D:"#FC6E9D",
		E:"#68C3C4",
		F:"#84C870",
		G:"#F0B837",
		H:"#EB6F86",
		I:"#90B7DE",
		J:"#AD90DE",
		K:"#BBD379",
		L:"#F28D6D",
		M:"#FFA2A2",
		N:"#2383A4",
		O:"#E57D0A",
		P:"#4D63A5",
		Q:"#D8366C",
		R:"#1E9C9C",
		S:"#4CA233",
		T:"#D09104",
		U:"#D4556C",
		V:"#5C8DBD",
		W:"#7B5FB3",
		X:"#9EBA57",
		Y:"#D56E4D",
		Z:"#E87979"
	}
}