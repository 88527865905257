import { Component, Input } from '@angular/core';
import { CodeScanService } from './codeScan.service';

@Component({
	template: `
  <div class="wrapper">
	<video id="code-reader" [ngStyle]="options.style"></video>
	<div class="red-line" *ngIf="options.redLine"></div>
	<div class="code-reader-back" [class.off]="isOff" (click)="backClick()"></div>
	<div *ngIf="message && options.debug" class="code-reader-message">{{message}}</div>
  </div>
  `,
	styleUrls: ['./codeScan.component.scss']
})

export class CodeScanComponent {
	public isOff = false;
	public message;
	@Input() public options:any={};
	constructor() {
	}

	backClick() {
		this.isOff = true;
	}

}
