import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentMenuBarComponent } from './componentMenuBar.component';
import { TranslateModule } from '@ngx-translate/core';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    imports: [
		UiSwitchModule,
		CommonModule,
		TranslateModule,
		BubbleBox2Module,
		FontAwesomeModule
    ],
    declarations: [
		ComponentMenuBarComponent
    ],
    exports: [
		ComponentMenuBarComponent
    ],
    providers: [
    ],
    entryComponents: [ComponentMenuBarComponent]
})
export class ComponentMenuBarModule {
}
