import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
    selector: 'headerIconBtn',
    template: `
    <div class="touchArea" [class.disabled]="disabled" [class.noHoverEffect]="noHoverEffect">
        <div class="iconWrapper" [ngStyle]="iconWrapperStyle">
            <fa-icon *ngIf="iconType=='fa' && icon" [icon]="icon" class="icon fa"></fa-icon>
            <img *ngIf="iconType=='img' && icon" [src]="icon">
        </div>
    </div>
    `,
    styleUrls: ['./headerIconBtn.component.scss']
})

export class HeaderIconBtnComponent implements OnInit{    
    @Input() public iconType:string = "fa";
    @Input() public disabled:boolean = false;
    @Input() public icon:any = "";
    @Input() public iconWrapperStyle:any = {};
    @Input() public noHoverEffect:boolean = false;
	public nativeElement;
    constructor(public eleRef: ElementRef) {
    }

	ngOnInit(){
		this.nativeElement = this.eleRef.nativeElement;
	}


}
