import { Component, HostBinding, Input, Output, EventEmitter, HostListener, ComponentFactoryResolver, OnDestroy, ApplicationRef, Injector } from '@angular/core';

import { ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { OnInit } from '@angular/core';
import { TopLayer } from 'src/app/common/TopLayer';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { Alert } from './Alert';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';


export class DialogOption
{
	hostZIndex = 0;
	style:object = {};
	options:any;
    showCnlBtn:boolean = false;
	backgroundColor:string = "#EBEFF0";
	
	saveEnabled:boolean = false;
    saveLabel:string ;
	
	confirmEnabled:boolean = true;
    confirmLabel:string ;//= "確定";
	
	cancelEnabled:boolean = true;
    cancelLabel:string ;// = "取消";
	title:any = "Default Title";
	warning:string;
	emitter:EventEmitter<any> = new EventEmitter();
	constructor()
	{
		
	}
}



///////////////////////////////////////////////////////////////////////////////////////////////////
export interface IDialogContent {
    // set data(value: any)
	dialogOptions:DialogOption;
	data:any;
	confirm():Promise<any>;
	save():Promise<any>;
	emitter:EventEmitter<any>;
};
/*
//////////////
@Component({
	template: `
		<div class="input-container">

			<input
				[(ngModel)]="data.value"
				/>
		</div>
	`,
	styles: [`
		.input-container{
			padding:20px;
		}

		input{
			text-align: center;
			outline: none;
			border:solid 0px;
			width:384px;
			height:46px;
			border-radius:10px;
			padding-left: 12px;
			padding-right:12px;
		}
	`]
})

export class PromptLabelInput implements IDialogContent {
	@Output() emitter: EventEmitter<any> = new EventEmitter();
	public data: any;
	public dialogOptions: DialogOption;
	constructor() {
	}

	confirm(): Promise<any> {
		return Promise.resolve(this.data);
	}

};
*/


@Component({
    selector: 'my-dialog',
    template: `
		<div class="dialog" *ngIf="dialogOption" [ngStyle]="dialogOption.style">

				<div class="header" >
					<div class="center">
						<div class="title">{{dialogOption.title}}</div>
					</div>
					<div class="left">
						<div *ngIf="dialogOption.cancelEnabled"
							class="button cancel-btn" (click)="onCloseClick()">{{dialogOption.cancelLabel}}</div>
					</div>
					<div class="right">
						<div class="button confirm-btn" (click)="onConfirmClick()">{{dialogOption.confirmLabel}}</div>
					</div>

				</div>
				<div  class="dialog-content-container">
					<ng-content></ng-content>
				</div>
			</div>
			`
	 ,
     styleUrls: ['dialog.scss']
})


export class BasicDialog implements OnInit, OnDestroy {
	
	@ViewChild('body', { static: true }) body: any;
	
    /// @HostListener('dblclick', []) onDblClick() { console.log(this); }

	
    @HostBinding('style.zIndex') public hostZIndex = 0;
	/*
	@Input() style:object = {}
	@Input() public options:any;

    @Input() public showCnlBtn:boolean = false;

	@Input() public backgroundColor:string = "#EBEFF0";
	@Input() public confirmEnabled:boolean = true;
    @Input() public confirmLabel:string = "確定";
	@Input() public cancelEnabled:boolean = true;
    @Input() public cancelLabel:string = "取消";
	@Input() public title:any = "YYY";

	*/
	@Output() confirm:EventEmitter<any> = new EventEmitter();
	@Output() cancel:EventEmitter<any> = new EventEmitter();

	@Input() dialogOption:DialogOption;
	@Input() counter:number = 0;
	subscription: Subscription;
    constructor(private coms:CommonService, private dcs:DynamicComponentService)
	{
		// this.hostZIndex = 100;
		this.hostZIndex = this.coms.getZIndex();
		// console.log("hostZIndex", this.hostZIndex);
    }
	
	ngOnInit(): void {
		if(this.dialogOption)
		{
			this.subscription = this.dialogOption.emitter.subscribe((d:any)=>{
				this.counter++
			})
		}
	}
	ngOnDestroy(): void {
		if(this.subscription)
		{
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	onConfirmClick():void
	{
		// this.close();
		if(this.dialogOption.confirmEnabled)
		{
			this.confirm.emit("confirm");
		}
	}

	onCloseClick():void
	{
		if(this.dialogOption.cancelEnabled)
		{
			this.cancel.emit("cancel");
		}
	}

    public open(appendEle:HTMLElement = document.body):void {
        this.hostZIndex = this.coms.getZIndex();
		// console.log("hostZIndex", this.hostZIndex);
        this.dcs.open(this, appendEle);
    }

    public close():void {
        this.dcs.close(this);
    }

}
///////////
@Component({
    selector: 'basic-dialog-container',
    template: `
		<div class="dialog" 
			[ngStyle]="style">

			<div class="header">
				<div class="center">
					<div class="title">{{title}}</div>
				</div>
				<div class="left">
				
					<div 
						*ngIf="!leftButtons" 
						class="button cancel-btn" 
						(click)="onCloseClick()">{{cancelLabel}}</div>
					<div *ngFor="let btn of leftButtons"
						class="button {{btn.className}}" 
						(click)="onButtonClick(btn)"
					>{{btn.name}}</div>
				</div>
				<div class="right" style="display:flex">
					<div *ngIf="!rightButtons" class="button confirm-btn" (click)="onConfirmClick()">{{confirmLabel}}</div>
					<div *ngFor="let btn of rightButtons"
						class="button {{btn.className}}" 
						(click)="onButtonClick(btn)"
						>{{btn.name}}</div>
				</div>
			</div>
			<div  class="dialog-content-container">
				<ng-content></ng-content>
			</div>
		</div>
			`
	 ,
     styleUrls: ['dialog.scss']
})


export class BasicDialogContainer implements OnInit, OnDestroy {
	
	@ViewChild('body', { static: true }) body: any;
	
    @HostBinding('style.zIndex') public hostZIndex = TopLayer.getNextIndex();
	
	@Output() confirm:EventEmitter<any> = new EventEmitter();
	@Output() cancel:EventEmitter<any> = new EventEmitter();
	@Output() emitter:EventEmitter<any> = new EventEmitter();
	
	@Input() public style:any;
	@Input() public title:string = "title";
	@Input() public confirmLabel:String = "Confirm";
	@Input() public cancelLabel:String = "Cancel";
	@Input() public rightButtons:any = null; 
	@Input() public leftButtons:any = null; 
	
    constructor(
		// private coms:CommonService, private dcs:DynamicComponentService
	)
	{
		
    }
	onButtonClick(btn):void
	{
		this.emitter.emit(btn);
	}
	ngOnInit(): void {
		
	}
	ngOnDestroy(): void {
		
	}

	onConfirmClick():void
	{
		this.confirm.emit("confirm");
	}

	onCloseClick():void
	{
		this.cancel.emit("cancel");
	}
	
}


///////////////////////////////////////////////////////////////////////////////////////////////////
/**
	 	var com = dcs.create(
			DialogContainer ,
			{
				dialogOptions:{
					title:"my title",
					confirmEnabled:true,
					confirmLabel:"完成",
					cancelEnabled:true,
					cancelLabel:"取消"
				}

				view:view,
				data:data
			}
		);
		com.open();

*/
@Component({
	template: `
		<div>
			<my-dialog 
				#dialog 
				[dialogOption]="options"
				class="dialog" 
				(confirm)="onConfirm($event)" (cancel)="onCancel($event)" >
				<div #container [style]="style"></div>
			</my-dialog>
		<div>
    `,
	providers: [DynamicComponentService]
})

export class DialogContainer  implements OnInit
{
	@ViewChild('dialog', {static:true}) dialog:BasicDialog;
	@ViewChild('container', {static:true}) container:any;
	@Input() public dialogOptions:any = null;
	@Output() public options:DialogOption = null;
	@Input() public data:any = null;
	@Input() public view:any = null;
	@Input() public style:any = null;

	@Output() events:EventEmitter<any> = new EventEmitter();

	// @Input() public title:any;

	// @Output() emitter:EventEmitter<any> = new EventEmitter();
	public instance:IDialogContent;
	// public dcs:DynamicComponentService;
	constructor(
		private translateService:TranslateService,
		//private coms:CommonService,
		public router:Router,
		public route:ActivatedRoute,
		private dcs:DynamicComponentService,
		public componentFactoryResolver: ComponentFactoryResolver,
		//private _activatedRoute:ActivatedRoute,
		private injector: Injector,
		private appRef: ApplicationRef,
		private alertServices:AlertService
	) {

	}

	/*
	fillDialogOptions():void
	{
		if(this.dialogOptions)
		{
			for(var key in this.dialogOptions)
			{
				this.dialog[key] = this.dialogOptions[key];
			}
		}
	}
	*/

	replaceDialogOption():void
	{
		if(this.dialogOptions)
		{
			var options:DialogOption = new DialogOption();
			for(var key in this.dialogOptions)
			{
				options[key] = this.dialogOptions[key];
			}
			this.options = options;
		}
	}
	ngOnInit(): void
	{
		var ref:any = this.componentFactoryResolver.resolveComponentFactory(this.view).create(this.injector);
		this.instance =  ref.instance;
		this.instance.data = this.data;
		this.appRef.attachView(ref.hostView);
		this.container.nativeElement.appendChild(ref.hostView.rootNodes[0]);
		if(this.dialogOptions)
		{
			if(this.dialogOptions.confirmLabel == undefined)
				this.dialogOptions.confirmLabel = this.translateService.instant("commonService.confirm") ;
			if(this.dialogOptions.cancelLabel == undefined)
				this.dialogOptions.cancelLabel = this.translateService.instant("commonService.cancel") ;
		}
		this.replaceDialogOption();
		// this.fillDialogOptions();
		this.instance.emitter.subscribe((type:any)=>{
			if(type == "close")
			{
				this.close();
			} else if(type == "cancel")
			{
				this.close();
			} else if(type == "confirm")
			{
				this.confirm();
			} else if(type == "titleChanged")
			{
				var tmp:any = this.options;
				this.options = null;
				this.options = tmp;
			}
		})
		this.instance.dialogOptions = this.options;
	}

	confirm():void
	{
		this.instance.confirm().then((o:any)=>{
			this.close();
			this.events.emit({type:"confirm", data:o});
		}, (reason)=>{
			if(typeof reason == "string")
			{
				this.alertServices.alert(reason, null, null, false);
				// Alert.alert(reason);
			}
		});
	}
	onConfirm(event)
	{
		this.confirm();
	}
	open()
	{
		this.dcs.open(this);
	}
	onCancel(event)
	{
		this.close();
		this.events.emit({type:"cancel"});
	}

	close()
	{
		this.dcs.close(this);
	}

}


export class Dialog
{
	/*
	static prompt(dcs: DynamicComponentService, title: string, defaultValue: string, confirmLabel:string = "確定", cancelLabel:string = "取消"): Promise<any> {
		// var dcs: DynamicComponentService = SingleInstance.get("dcs");
		
		return new Promise((resolve, reject) => {
			var com: DialogContainer = dcs.create(
				DialogContainer,
				{
					dialogOptions: {
						title: title,
						confirmLabel: confirmLabel,
						cancelLabel: cancelLabel,
						cancelEnabled: true,
						style: {
							backgroundColor: '#EAEFF0'
						}
					},
					view: PromptLabelInput,
					data: {
						value: defaultValue
					}
				}

			);
			com.open();
			com.events.subscribe((o: any) => {
				if (o.type == "confirm") {
					resolve(o.data.value)
				} else if (o.type == "cancel") {
					reject(o.type);
				}
			});
		});
	}
	*/
}

