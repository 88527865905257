import { Component, Input, OnInit, ViewChild, ElementRef, Renderer2, Output, EventEmitter, AfterViewInit, NgZone, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isObject } from 'util';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { ScriptService } from 'src/app/service/script.service';
import { ThemeService } from 'src/app/service/theme.service';
import { ChatService } from 'src/app/service/chatGPT.service';
import { Message } from 'src/app/service/chatGPT.service';
import { DataService } from 'src/app/service/data.service';
import moment from 'moment';
import { StorageService } from 'src/app/service/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageComponent } from './message.component';
import { ChineseTextHelper } from 'src/app/common/ChineseTextHelper';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';
import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import { LoadingService } from '../loadingModule/loading.service';
import { AlertService } from 'src/app/service/alert.service';

@Component({
	selector: 'app-thread',
	templateUrl: "thread.component.html",
	styleUrls: ['./thread.component.scss']
})

export class ThreadComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChildren(MessageComponent) messages!: QueryList<MessageComponent>;
	@ViewChild('chatRoom', { static: false }) chatRoom: ElementRef;
	@Input() data: any = {};//{status: string,{id: int, image: safeStyle, title: string, author: string, recordAllow: bool, imageAllow: bool},message: [], people: []}
	@Input() practiceSetting;
	@Input() isMarking = false;
	@Input() markingStudent;
	@Input() status: string = "open"
	@Output() teacherSpeaking: EventEmitter<any> = new EventEmitter();
	@Output() showGrading: EventEmitter<any> = new EventEmitter();
	public audio: HTMLAudioElement = new Audio();
	public timer$;
	@Output() deleted = new EventEmitter<any>();
	@Output() studentSpeaking = new EventEmitter<any>();
	public flipOpen = true;
	public inDesktopApp = false;
	public headerBackgroundImage;
	public headerHeight = 50;
	public author = "";
	public student;
	public comment = "";
	public score = {
		totalScore: undefined,
		subScore: "",
	}
	public localDisplayText;
	public faMessage = faMessage;
	public localAISpeed;
	public localAISPeedOptions = [
		{ label: "慢", value: "slow" },
		{ label: "正常", value: "normal" },
		{ label: "快", value: "fast" }
	];
	public localInputMode;
	public localInputModeOptions = [
		{ label: "語音", value: "speech" },
		{ label: "語音及文字", value: "speechText" }
	];
	public loadedSvg: boolean = false;
	public conversation: Message[] = [];
	public autoRead = false;
	public robotStartTime;

	@ViewChild('ps', { static: false }) ps;
	private messageComponentsChangesSubscription: Subscription | undefined;
	constructor(private sans: DomSanitizer, private sanitizer: DomSanitizer, private zone: NgZone, private script: ScriptService, private theme: ThemeService, private chat: ChatService, public datas: DataService, public storage: StorageService, private http: HttpClient, private iconReg: InlineSvgService, private lds: LoadingService
		, private als: AlertService) {
	}
	public end = false;

	ngAfterViewInit() {
		if (!this.isMarking) {
			this.messageComponentsChangesSubscription = this.messages.changes.subscribe((components: QueryList<MessageComponent>) => {
				setTimeout(() => {
					if ((components.length == 1 || (components.last && components.last.msg.author != 0)) && !this.end) {
						this.autoRead = true;
					}
					if (components.last && components.last.msg.author == 0 && components.last.audioPlayer && this.autoRead) {
						components.last.audioPlayer.getFile();
					}

				}, 500);
			});
		}
	}

	ngOnInit() {
		this.theme.applyStyleObj(
			{
				default: {
					"header-background-color": "#F7F7F7",
					"second-background-color": "#FFFFFF",
					"chat-room-background-color": "#EAE8E8",
				},
				dark: {
					"header-background-color": "#38374f",
					"second-background-color": "#1E1E29",
					"chat-room-background-color": "#1E1E29",
				},
			},
			document.body
		);
		this.iconReg.regSvgFiles([
			{ url: '/btn_more_gray.svg', name: 'btn_more_gray' },

		]).then((res: any) => {
			this.loadedSvg = true;
		});


		setTimeout(() => {
			this.setInstruction();
			if (!this.isMarking) {
				if (!this.data.messages || this.data.messages.length == 0) {
					this.setRobotFirstMessage(this.practiceSetting.topic);
				}
			}
			this.setLocalSetting();
			this.initHeaderBackgroundImage();
			this.getAvatar();
			this.setHistory();
		}, 200);
	}

	ngOnChanges(changes) {
		if (changes.markingStudent) {
			this.student = this.markingStudent;
		}
	}

	private setHistory() {
		this.data.messages.forEach(message => {
			if (message.author == 0) {
				if (message.content_type == 'comment') {
					this.comment = message.voice_text;
				} else if (message.content_type == 'scores') {
					const scoreData: string = message.voice_text;

					const regex: RegExp = /{([^}]*)}/; // Matches content within {}
					const match: RegExpMatchArray | null = scoreData.match(regex);

					if (match) {
						const extractedContent: string = match[1]; // Extract the content within {}
						this.score.subScore = extractedContent.replace(/['',]/g, '');
						this.calTotalScore(extractedContent);
					} else {
						console.log("No content found within '{}' braces.");
					}
				}
				else {
					this.conversation.push({
						role: "assistant",
						content: message.voice_text,
					});
				}
			} else if (message.author != -1) {
				this.conversation.push({
					role: "user",
					content: message.voice_text,
				});
			}
		});
	}

	private calTotalScore(scoreObject: string) {
		const keyValuePairs: string[] = scoreObject.replace(/'/g, '').split(', ');

		// Initialize variables to store sum and count
		let sum: number = 0;
		let count: number = 0;

		// Iterate through each key-value pair to extract scores and calculate sum and count
		keyValuePairs.forEach(pair => {
			const [key, scoreStr] = pair.split(':');
			const score: number = parseInt(scoreStr);

			// Check if the parsed score is a valid number
			if (!isNaN(score)) {
				sum += score;
				count++;
			}
		});

		// Calculate the average score
		const averageScore: number = count > 0 ? (sum / count) * 10 : 0;
		this.score.totalScore = Math.floor(averageScore);
	}

	private setLocalSetting() {
		if (this.practiceSetting) {
			if (this.practiceSetting.ai_speed == "free" && this.status == "open") {
				this.localAISpeed = "normal";
			}
			if (this.practiceSetting.input_mode == "free" && (this.status == "open" || this.isMarking)) {
				this.localInputMode = "speech";
			}
			if (this.practiceSetting.display_text_in_dialog == "free") {
				this.localDisplayText = false;
			}
		}
	}

	private setRobotFirstMessage(topic: string) {
		const firstMessage = {
			"zh-HK": `同學你好,今日我地會討論關於${topic}嘅議題,請分享你對呢個議題嘅睇法。`,
			"zh-CN": `同学你好,今天我们会讨论关于${topic}的议题,请分享你对这个议题的看法。`,
			"en-US": `Hello, today we will discuss the topic of ${topic}, please share your views on this topic.`,
			"en-GB": `Hello, today we will discuss the topic of ${topic}, please share your views on this topic.`,
		}[this.practiceSetting.ai_language]
		this.playAudio(firstMessage);
		this.conversation.push({
			role: "assistant",
			content: firstMessage
		});

	}

	private setInstruction() {
		this.conversation.push({
			role: "system",
			content: {
				"zh-HK": `你係一名老師, User係一名學生。你地要以${this.practiceSetting.topic}為主題作討論。每次都要回應學生並再問學生一條相關問題以繼續討論。 你每次嘅回應句子必須少於${this.practiceSetting.ai_response_sentences}句, 每句字數必須少於${this.practiceSetting.ai_response_words}字,句子必須為廣東話口語。如果學生嘅回應與主題無關,請回覆'呢個同我地嘅討論主題無關'`,
				"zh-CN": `你是一名老師, User是一名學生。你地要以${this.practiceSetting.topic}為主題作討論。每次都要回應學生並再問學生一條相關問題以繼續討論。 你每次的回應句子必須少於${this.practiceSetting.ai_response_sentences}句, 每句字數必須少於${this.practiceSetting.ai_response_words}字。如果學生的回應與主題無關,請回覆'這與我們的討論主題無關'`,
				"en-GB": `You are a teacher, and the user is a student. You both have to discuss the topic of ${this.practiceSetting.topic}. Each time, you need to respond to the student and ask the student another related question to continue the discussion. Your responses should consist of fewer than ${this.practiceSetting.ai_response_sentences} sentences and each sentence should have fewer than ${this.practiceSetting.ai_response_words} words. The sentences must be spoken in English(US). If the student's response is unrelated to the topic, Please respond with 'This is unrelated to our discussion topic'`,
				"en-US": `You are a teacher, and the user is a student. You both have to discuss the topic of ${this.practiceSetting.topic}. Each time, you need to respond to the student and ask the student another related question to continue the discussion. Your responses should consist of fewer than ${this.practiceSetting.ai_response_sentences} sentences and each sentence should have fewer than ${this.practiceSetting.ai_response_words} words. The sentences must be spoken in English(GB). If the student's response is unrelated to the topic, Please respond with 'This is unrelated to our discussion topic'`,
			}[this.practiceSetting.ai_language]
		});
	}


	ngOnDestroy() {
		if (this.messageComponentsChangesSubscription) {
			this.messageComponentsChangesSubscription.unsubscribe();
		}
		clearTimeout(this.timer$);
		this.timer$ = null;
	}

	sentPromptApi(event) {
		this.scrollToBottom();
		this.conversation.push({ role: 'user', content: event });
		// without queue
		this.robotStartTime = new Date();
		this.lds.add("chatResponse");
		this.chat.oralChat(this.conversation.slice(0, 1).concat(this.conversation.slice(-6))).then(res => {
			this.chatRoom.nativeElement.click();
			console.log("time for response without queue: ", (new Date().getTime() - this.robotStartTime.getTime()) / 1000 + "s");
			var chatGPTRespose: Message = JSON.parse(res).choices[0].message;
			this.conversation.push(chatGPTRespose);
			this.lds.remove("chatResponse");
			this.playAudio(chatGPTRespose.content);
			this.robotStartTime = null;
		});



		// with queue
		// this.lds.add("chatResponse");
		// this.robotStartTime = new Date();
		// this.chat.oralChatQueue(this.conversation.slice(-10)).then(res=>{
		// 	console.log(res);
		// 	if(res && res.token){
		// 		this.checkGPTResponse(res.token);
		// 	}
		// });

	}

	private checkGPTResponse(token, isHint = false) {
		this.datas.post2({
			data: {
				api: "ROChatGPT.get_result_by_token",
				json: [token]
			},
			loading: true,
		}).then((res: any) => {
			if (res != null) {
				this.lds.remove("chatResponse");
				var chatGPTRespose: Message = res.choices[0].message;
				if (isHint) {
					this.data.messages.push(
						{
							content_type: "hint",
							data: chatGPTRespose.content,
							created_at: "2023-11-13 13:56:49",
						}
					);
					const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
					const msgObj = { id: 0, content_type: 'hint', data: chatGPTRespose.content, author: -1, created_at: createdAt, quote: {}, voice_text: chatGPTRespose.content };
					this.saveMessage(msgObj);
					setTimeout(() => {
						this.scrollToBottom();
					}, 20);
				} else {
					this.conversation.push(chatGPTRespose);
					this.playAudio(chatGPTRespose.content);
					setTimeout(() => {
						this.scrollToBottom();
					}, 20);
				}
				console.log("time for response with queue: ", (new Date().getTime() - this.robotStartTime.getTime()) / 1000 + "s");
				this.robotStartTime = null;
			} else {
				if ((new Date().getTime() - this.robotStartTime.getTime()) / 1000 < 15) {
					//少於15秒就再check
					setTimeout(() => {
						this.checkGPTResponse(token, isHint);

					}, 1000);
				} else {
					this.lds.remove("chatResponse");
					const errorMsg = { "tc": "系統現時繁忙,請稍後再試", "sc": "系统现时繁忙,请稍后再试", "en": "The system is busy, Try again later" };
					this.als.toastError(errorMsg[this.datas.lang]);
				}
			}
		});
	}

	public focus() {
		console.log("focus");
	}

	public initHeaderBackgroundImage() {
		if (this.practiceSetting.image) {
			let mediaUrl = 'https://oka.blob.core.windows.net/media/' + this.practiceSetting.image;
			this.headerBackgroundImage = this.sans.bypassSecurityTrustStyle('url("' + mediaUrl + '")');
		}
	}

	public playAudio(text: string, addVoiceMessage = true): void {
		let url: string =
			"//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";

		let data: any = {
			gender: 'F',
			txt: text,
			speed: 1.1,
			lang: this.practiceSetting.ai_language,
			pitch: 1,
			name: this.practiceSetting.ai_voice,
			style: "",
		};

		var speed = this.localAISpeed ? this.localAISpeed : this.practiceSetting.ai_speed;

		if (speed == "fast") {
			data.speed = 1.5;
		} else if (speed == "slow") {
			data.speed = 0.7;
		}
		this.datas
			.get2({ url: url, data: data, jwt: false })
			.then((res: any) => {
				if (res.token && res.token.url) {
					let statusUrl: string = res.token.url;
					if (statusUrl.substr(0, 7) == "http://")
						statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
					this.datas
						.get2({ url: statusUrl, jwt: false })
						.then((res: any) => {
							if (res.url) {
								setTimeout(() => {
									this.scrollToBottom();
								}, 200);
								//navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream)=> {
								this.audio.setAttribute("src", res.url);
								const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
								const json = JSON.stringify({ path: res.url, size: 5, length: 5 });
								const msgObj = { content_type: 'voice', data: json, quote: this.data.quote, author: 0, created_at: createdAt, voice_text: text };
								if (addVoiceMessage) {
									this.data.messages.push(msgObj);
								}
								this.saveMessage(msgObj);

							}
							setTimeout(() => {
								this.scrollToBottom();
							}, 2000);
						});
				}
			});
	}

	public saveMessage(msgObj) {
		const postTime = this.data.messages.map(e => +moment(e.created_at).toDate())
			.filter((e, i) => this.data.messages.length - i < 500)
			.join(',');

		this.datas.post('ROBookshelfNew.post_chat_message', [msgObj, this.data.thread.id, postTime]).subscribe((res: any) => {

			if (res.insert_id) {
				if (msgObj.content_type == 'voice') {
					try {
						if (isObject(res.msgObj.data)) {
							msgObj.data = res.msgObj.data;
						} else {
							msgObj.data = JSON.parse(res.msgObj.data);
						}
						console.log("msgObj", msgObj);
						//   this.moveScrollTop(1);
					} catch (e) {
						const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
						this.data.messages.push({ id: 0, content_type: 'text', data: 'err:' + res.msgObj.data, author: 0, created_at: createdAt, quote: {} });
					}
					// this.common.removeLoading('add-voice');
				}
				msgObj.id = res.insert_id;
			} else {
				const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
			}
		});
	}

	public checkNeedUpdate() {
		clearTimeout(this.timer$);
		this.timer$ = null;
		const thread = this.data.thread;
	}

	closeThread() {
		this.data.status = 'close';
		setTimeout(() => { this.data.status = 'closed' }, 500);
		this.data.thread = {};
	}

	backClick($event) {
		$event.stopPropagation();
		this.closeThread();
	}

	private async getAvatar() {
		var peopleArray = [this.practiceSetting.author_id];
		var studentId;
		if (this.data && this.data.messages) {
			for (var i = 0; i < this.data.messages.length; i++) {
				if (this.data.messages[i].author > 0) {
					studentId = this.data.messages[i].author;
					peopleArray.push(studentId);
					break;
				}
			}
		}

		if (!studentId) {
			const CurrentLoginStudentId = this.datas.userInfo.user_role == 2 ? this.datas.userInfo.uid : null;
			if (CurrentLoginStudentId) {
				studentId = CurrentLoginStudentId;
				peopleArray.push(studentId);
			}
		}
		try {
			let res = await this.datas.post2({
				data: {
					api: "AssessmentForm.getPhotos",
					json: [peopleArray]
				},
				loading: true,
			});
			if (res && res.photo) {
				let author = res.photo.find(p => p.uid === this.practiceSetting.author_id);
				if (this.datas.lang == 'en' && author.ename) {
					this.author = author.ename;
				} else {
					this.author = author.display_name;
				}
				this.student = res.photo.find(p => p.uid === studentId);
			}
			setTimeout(() => {
				this.scrollToBottom();
			}, 1);
		} catch (error) {
			console.log(error);
		}
	}

	sanitizePhoto(photo, defaultPhoto) {
		let urls = "url('" + this.script.assetsUrl + "img/news/" + defaultPhoto + "')";
		if (photo) {
			urls = "url('" + photo + "')," + urls;
		}
		return this.sanitizer.bypassSecurityTrustStyle(urls);
	}

	expandClick() {
		this.flipOpen = !this.flipOpen;
		setTimeout(() => {
			try {
				const header = <HTMLElement>document.querySelector('.thread-header');
				const board = <HTMLElement>document.querySelector('.thread-board');
				const content = <HTMLElement>document.querySelector('.thread-content');
				const panel = <HTMLElement>document.querySelector('message-panel > .container');
				const windowRoot = <HTMLElement>document.querySelector('window-root');
				const vh = windowRoot.offsetHeight;
				if (header && board && panel) {
					const contentHeight = content ? (content.offsetHeight > 30 ? 120 : 30) : 0;
					const height = vh - header.offsetHeight - contentHeight - panel.offsetHeight - 17;//num is margin
					board.style.height = height + 'px';
					this.scrollToBottom();
				}
			} catch (error) {

			}

		}, 50);
	}

	scrollToBottom() {
		const scroller = <HTMLElement>document.querySelector('.thread-board .ps');
		if (scroller) {
			scroller.scrollTop = scroller.scrollHeight;
		}
	}

	getHint() {
		//只用最後4條訊息獲取對話 節省tokens
		var tempConversation = [...this.conversation].slice(-4);

		tempConversation.push(
			{
				role: 'user',
				content: {
					"zh-HK": "我應該回答什麼來回應你的回答?請給予例子且最多80字",
					"zh-CN": "我应该回答什么来回应你的回答?请给予例子且最多80字",
					"en-GB": "Give me an example answer to respond your question in 30 words",
					"en-US": "Give me an example answer to respond your question in 30 words",
				}[this.practiceSetting.ai_language]
			}
		)

		//without queue
		this.chat.oralChat(tempConversation).then(res => {
			var chatGPTRespose: Message = JSON.parse(res).choices[0].message;
			if (this.practiceSetting.ai_language == "zh-HK") {
				chatGPTRespose.content = ChineseTextHelper.toTraditionalized(chatGPTRespose.content);
			} else if (this.practiceSetting.ai_language == "zh-CN") {
				chatGPTRespose.content = ChineseTextHelper.toSimplized(chatGPTRespose.content);

			}

			this.data.messages.push(
				{
					content_type: "hint",
					data: chatGPTRespose.content,
					created_at: "2023-11-13 13:56:49",
				}
			);
			const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
			const msgObj = { id: 0, content_type: 'hint', data: chatGPTRespose.content, author: -1, created_at: createdAt, quote: {}, voice_text: chatGPTRespose.content };
			this.saveMessage(msgObj);
			setTimeout(() => {
				this.scrollToBottom();
			}, 20);
		});

		//with queue
		// this.lds.add("chatResponse");
		// this.robotStartTime = new Date();
		// this.chat.oralChatQueue(tempConversation).then(res=>{
		// 	console.log(res);
		// 	if(res && res.token){
		// 		this.checkGPTResponse(res.token, true);
		// 	}
		// });
	}

}