import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/service/data.service';

@Component({
	selector: 'p-ranking-table',
	template: `
  <div class="header section-{{section}}" #header>
    <div class="header__rank"></div>
    <div class="header__score">{{'total-result'|translate}}</div>
    <div class="header__name">{{'student'|translate}}</div>
    <div class="header__tag">{{'result-panel.tag'|translate}}</div>
    <div class="header__result" *ngFor="let chapter of rankingData.book.chapters;let i = index">
		<ng-container *ngIf="isPdfPaper">{{'result-panel.question-section'|translate}}{{chapter.title}}</ng-container>
		<ng-container *ngIf="!isPdfPaper">{{'ch'|translate}}{{i+1}}</ng-container>
		
	</div>
  </div>
  <div [ngClass]="['body', 'in-main']">
	<perfect-scrollbar #ps [ngClass]="['ranking-table in-main',(usePS?'':'mobile')]" [disabled]="!usePS" (psScrollX)="re($event)">
      <div class="row" *ngFor="let row of students">
        <div [ngClass]="['row__rank', 'rank-' + (row.score > 0? row.rank:''), row.elClass]">{{row.rank}}</div>
        <div [ngClass]="['row__score', row.elClass]">{{row.elClass == 'not-submit'?'?':row.score}}</div>
        <div class="row__name section-{{section}}">
          <span>{{row.name}}</span>
        </div>
		<div class="row__tag">
			<span>{{row.tag_title}}</span>
		</div>
        <div class="row__result" *ngFor="let result of row.result">{{result}}</div>
      </div>
    </perfect-scrollbar>
  </div>
  `,
	styleUrls: ['./personal-ranking.table.scss']
})
export class PersonalRankingTable implements OnInit {
	@Input() public tag;
	@Input() public parent;
	@ViewChild('header', { static: false }) header;
	public usePS: boolean = false;
	public rankingData;
	public students: any[] = [];
	public loaded = false;
	public whole: any = {};
	public section = 'default';
	public isPdfPaper = false;
	public lang;
	constructor(public datas: DataService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.rankingData = this.parent.rankingData;
		this.isPdfPaper = this.rankingData.bookType == 'pdf-paper';
		this.usePS = !(this.datas.dev.isMobile && this.rankingData.book.chapters.length < 6);
		this.section = this.getSection();
		this.updateAll();
	}

	updateAll() {
		let rankingData = this.rankingData;
		let students = rankingData.students.map(student => {
			let newStudent: any = { uid: student.uid, name: this.lang == 'tc' ? student.cname : student.ename, tag_title: student.tag_title, result: [], score: 0, fullCorrect: 0, partCorrect: 0 };
			let submitted = false;
			rankingData.book.chapters.forEach(chapter => {
				let chapterSum: any = 0;
				chapter.pages.forEach(page => {
					page.components.forEach(comp => {
						let found = rankingData.rows.find(row => row.cid == comp.id && row.uid == student.uid);
						if (found && comp.hasScoring) {
							submitted = true;
							let score = parseFloat(found.score);
							score = isNaN(score) ? 0 : score;
							chapterSum += score;
							newStudent.score += score;
							if (found.score >= found.max) {
								newStudent.fullCorrect++;
							} else if (found.score > 0) {
								newStudent.partCorrect++;
							}
						}
					});
				});
				chapterSum = ('' + chapterSum).indexOf('.') > -1 ? chapterSum.toFixed(1) : chapterSum;
				chapterSum = submitted ? chapterSum : '?';
				newStudent.result.push(chapterSum);
			});
			newStudent.score = ('' + newStudent.score).indexOf('.') > -1 ? newStudent.score.toFixed(1) : newStudent.score;
			newStudent.score = parseFloat(newStudent.score);
			if (newStudent.score >= rankingData.book.max * (rankingData.book.passLevel / 100)) {
				newStudent.elClass = 'green';
			} else if (!submitted) {
				newStudent.elClass = 'not-submit';
			} else {
				newStudent.elClass = 'red';
			}
			return newStudent;
		});
		students.sort((a, b) => {
			if (a.score < b.score) {
				return 1;
			} else if (a.score == b.score) {
				if (a.fullCorrect != b.fullCorrect) {
					return a.fullCorrect < b.fullCorrect ? 1 : -1;
				} else if (a.partCorrect != b.partCorrect) {
					return a.partCorrect < b.partCorrect ? 1 : -1;
				} else {
					return a.uid > b.uid ? 1 : -1;
				}
			} else {
				return -1;
			}
		});
		this.initRank(students);
		this.students = students;
	}

	initRank(students) {
		let rank = 1;
		let i = 0;
		while (i < students.length) {
			if (i == 0 && students[0].elClass != 'not-submit') {
				students[0].rank = rank;
				rank++;
			} else if (i == 0 && students[0].elClass == 'not-submit') {
				students[i].rank = rank;
				rank++;
			} else if (students[i].score == students[i - 1].score && students[i].elClass != 'not-submit') {
				students[i].rank = students[i - 1].rank;
				rank++;
			} else {
				students[i].rank = rank;
				rank++;
			}
			i++;
		}
	}

	re($event) {
		this.header.nativeElement.scrollLeft = $event.target.scrollLeft;
	}

	getSection() {
		let section = 'default';
		const href = location.href;
		if (href.indexOf('roWeb-ie') > -1){
			return 'default';
		}
		const sections = ['bookshelf', 'course', 'lesson'];
		const sessionStorageSection = sessionStorage.getItem('openBookFrom');
		for (const sec of sections) {
			if (href.indexOf('/' + sec + '/') > -1 || sessionStorageSection == sec) {
				section = sec;
			}
		}
		return section;
	}


}
