import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight, faBan } from '@fortawesome/pro-light-svg-icons';

@Component({
	selector: "checkbox-renderer",
	template: `
	<fa-icon *ngIf="isSelected && params && params.node.selectable" class="checked" [icon]="faCheckCircle"></fa-icon>
	<fa-icon *ngIf="!isSelected && params && params.node.selectable"  [icon]="faCheckCircleLight"></fa-icon>
	<fa-icon *ngIf="!isSelected && params && !params.node.selectable && params.node.displayed" class="disable"  [icon]="faBan"></fa-icon>
     `,
	styleUrls: ['./checkbox.renderer.scss']
})


export class CheckBoxRenderer implements AfterViewInit {
	public params: any;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public faBan = faBan;
	public clickSubject = new Subject();
	public isSelected = false;
	ngAfterViewInit() {
		this.clickSubject.pipe(debounceTime(10)).subscribe(() => {
			if(this.params.node.selectable) {
				this.isSelected = !this.isSelected;
				let node = this.params.node;
				node.setSelected(this.isSelected);
			}
		});
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	@HostListener("click") onClick() {
		if (!this.params.blockClick){
			this.clickSubject.next();
		}
	}

	agInit(params: ICellEditorParams): void {
		this.params = params;
		let node = params.node;
		let api = params.api;
		this.isSelected = node.isSelected();
	}

	refresh(params: ICellRendererParams) {
		this.isSelected = params.node.isSelected();
		return true;
	}

}
