import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { MarkingLayer } from "./MarkingLayer";
import { result } from "lodash";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";
import { ROPageComponent } from "../ROComponent";
import { Keyboard, KeyboardEvent } from "openfl";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { TextFlowUtils } from "../TextFlowUtils";

export class StickerBase
{
	protected pageWidth:number;
	protected pageHeight:number;
	protected markingLayer:MarkingLayer;

	public sourceJSON:any;
	public isCreator:boolean;
	public readOnly:boolean;
	public maxSize:number;
	public icon:SafeStyle;
	public canResize:boolean = true;
	public profileImg:any;

	// popup position
	public px:number;
	public py:number;
	public isOpenPopup:boolean = false;
	// voice note
	public timeStr:string="00:00";
	public state:any;
	protected snd:HTMLAudioElement;
	protected ms:number;
	// text note
	public editStr:string = "";
	public quill:any;

	public retryCount:number = 0;
	public miniPopup:boolean = true;

	constructor(markingLayer:MarkingLayer, jsonSettings:any, isCreator:boolean, readOnly:boolean)  {
		this.markingLayer = markingLayer;
		this.pageWidth = this.markingLayer.pageCom.node.attributes.w;
		this.pageHeight = this.markingLayer.pageCom.node.attributes.h;

		this.sourceJSON = jsonSettings;
		this.isCreator = isCreator;
		this.readOnly = !readOnly ? !this.isCreator : true;

		var sans:DomSanitizer = this.markingLayer.context.service.domSanitizer;
		var basePath:string = "url('https://oka.blob.core.windows.net/media/redeem/stickers/";
		if(this.sourceJSON.type=="starPublic") {
			this.icon = sans.bypassSecurityTrustStyle(basePath+"sticker_star.svg')");
			this.maxSize = 80;
		} else if(this.sourceJSON.type=="teacherStickerPublic") {
			if(this.sourceJSON.remark.id==0) {// right icon
				this.icon = sans.bypassSecurityTrustStyle(basePath+"sticker_0.svg')");
				this.maxSize = 40;
			} else if(this.sourceJSON.remark.id==1) {// wrong icon
				this.icon = sans.bypassSecurityTrustStyle(basePath+"sticker_1.svg')");
				this.maxSize = 40;
			} else if(this.sourceJSON.remark.id==2) {// like icon
				this.icon = sans.bypassSecurityTrustStyle(basePath+"sticker_like.svg')");
				this.maxSize = 80;

			} else if(this.sourceJSON.remark.id<0) {
				this.icon = null;
				this.markingLayer.context.service.stickerImageService.requestStickerImage(this.sourceJSON.remark.id).then(success => {
					this.icon = success.image;
				});
				this.canResize = true;
				this.maxSize = 80;
			}

		} else if(this.sourceJSON.type=="teacherStickerSchool") {
			this.icon = null;
			this.markingLayer.context.service.stickerImageService.requestStickerImage(this.sourceJSON.remark.id).then(success => {
				this.icon = success.image;
			});
			this.canResize = true;
			this.maxSize = 80;
		}
			
		if(this.maxSize == 40) {
			this.canResize = false;
			this.size = 40;
		}

		if(this.readOnly)
			this.canResize = false;

		if(this.sourceJSON.type=="teacherVoiceNote") {
			this.state = this.sourceJSON.remark.key ? "ready" : "norec";
		}
			

		this.markingLayer.context.service.userInfoHelper.requestUserInfo(this.createBy).then(info =>{
			this.profileImg = this.markingLayer.context.service.userInfoHelper.getProfileImgObject(info, this.markingLayer.context.service.translateService.currentLang);
		});

/*		if(isNaN(this.x))
			this.x = 20;
		else if(this.x <20)
			this.x = 20;
		if(isNaN(this.y))
			this.y = 20;
		else if(this.y <20)
			this.y = 20;
		this.size = 20;*/

		if(this.dataID!=-1) {
			this.openPopup(true);// old record
			this.updatePopupPosition();
		}
			
	}

	public set dataID(val:number) {
		// 當加入 DB 成功時，會設定返個 id
		this.sourceJSON.id = val;
	}		
	public get dataID():number {
		return this.sourceJSON.id;
	}

	public set x(val:number) {
		this.sourceJSON.remark.x = Math.round(val);
	}
	public get x():number {
		return this.sourceJSON.remark.x;
	}
	
	public set y(val:number) {
		this.sourceJSON.remark.y = Math.round(val);
	}
	public get y():number {
		return this.sourceJSON.remark.y;
	}

	public get createBy():number {
		return this.sourceJSON.remark.createBy;
	}
		
	public set createTime(val:string) {
		this.sourceJSON.createTime = val;
	}
	public get createTime():string {
		return this.sourceJSON.hasOwnProperty("createTime") ? this.sourceJSON.createTime : "-";
	}

	public get owner():number {
		return this.sourceJSON.hasOwnProperty("uid") ? this.sourceJSON.uid : this.markingLayer.pageCom.source.student.uid;
	}
	
	public set size(val:number) {
		val = Math.min(Math.max(40, val), this.maxSize);
		this.sourceJSON.remark.w = val;
		this.sourceJSON.remark.h = val;
	}
	public get size():number {
		if(this.sourceJSON.remark.hasOwnProperty("w"))
			return this.sourceJSON.remark.w;
		return 50;
	}

	public hitTestEraser(eraser:any):Boolean {
		if(this.dataID==-1)
			return false; // 未入 DB ，暫不處理其他動作
		
		let ang:number = Math.atan2(this.x - (eraser.x), (eraser.y) - this.y);
		let pt:any = {x:eraser.x - eraser.width * Math.sin(-ang) / 2, y:eraser.y - eraser.width * Math.cos( ang) / 2};
		let half:number = this.size / 2;
		return Math.abs(pt.x - this.x) <= half && Math.abs(pt.y - this.y) <= half;
	}

	public updatePopupPosition():void {
		var _w:number;
		var _h:number;
		if(this.sourceJSON.type=="teacherTextNote") {
			_w = 260/2;
			if(this.miniPopup)
				_h = 50 + 30;
			else
				_h = 180 + 30;
		} else if(this.sourceJSON.type=="teacherVoiceNote") {
			if(this.state == "norec")
				_w = this.isCreator && !this.readOnly ? 110/2 : 60/2;
			else if(this.state == "recording")
				_w = 110/2;
			else if(this.state == "ready")
				_w = this.isCreator && !this.readOnly ? 250/2-25-20 : 250/2-25-25-20;
			else if(this.state == "playing")
				_w = 200/2-25-20;
			_h = 60 + 30;
		} else {
			this.px = 0;
			this.py =30;
			return;
		}

		var _x:number = this.x;
		var _y:number = this.y;

		if(_x+_w > this.pageWidth) {
			_x = -(_x - (this.pageWidth - _w))-_w;
		} else if(_x - _w<0) {
			_x = - _x;
		} else {
			_x = -_w;
		}

		if(_y+_h > this.pageHeight) {
			// up
			_y = -_h;
		} else {
			_y = 30;
		}
		
		this.px = _x;
		this.py = _y;
	}

	public togglePopup():void {
		if(!this.isOpenPopup) {
			this.openPopup(true);
		} else {
			this.closePopup();
		}
	}

	public openPopup(miniPopup:boolean = false):void {
		this.miniPopup = miniPopup;


		this.isOpenPopup = true;
		if(this.sourceJSON.type=="teacherTextNote") {
			var str:string = this.sourceJSON.remark.text;
			// text flow 資料，需要轉換做 HTML
//			console.log(">> text src:",str);
			this.editStr = str.indexOf("<TextFlow")==0 ? this.textFlowToHtml(str) : str;
//			console.log(">> ",this.editStr);
			this.updateTextState(null, true);
		}
	}

	public closePopup():void {
		this.isOpenPopup = false;
		if(this.sourceJSON.type=="teacherVoiceNote") {
			this.stop();
		}
	}

	// =========================
	// sound sticker function
	// =========================
	public recordSound():void {
		this.snd = null;
		this.ms = 0;
		this.state = "recording";
		this.updatePopupPosition();
		var cfg:any = this.markingLayer.context.config;
		var ref:any = {key:GUID.create("OKD guid"),reference:this.markingLayer.context.createReference(), index:this.sourceJSON.remark.resourceIndex};
		var pageCom:ROPageComponent = this.markingLayer.pageCom;
		// 開始錄音
		this.markingLayer.context.service.fileIO.record().then(result=>{
			// 上載錄音
			this.markingLayer.context.assetUploader.uploadAsset(
				{bsid:cfg.share ? cfg.share.id : 0, bookId: cfg.book.id}, 
				{chapterId:pageCom.chapter.id, pageId:pageCom.douid, componentId:"#VOICE_NOTE#"+this.owner}, 
				ref, result.file).then(result=>{
				// 更新 sticker asset 記錄
				this.sourceJSON.remark.key = ref.key;
				this.sourceJSON.remark.reference = ref.reference;
				// 更新 DB 記錄
				this.markingLayer.change.emit({type:"updateSticker", data:this});
			}); 

			// 轉做可播放 state
			this.snd = this.markingLayer.context.service.fileIO.createSoundFromFile(result.file);
			this.timeStr = "00:00";
			this.state = "ready";
			this.updatePopupPosition();

		}).catch(error=>{
			// 轉回預備錄音
			this.state = "norec";
			this.timeStr = "00:00";
			this.updatePopupPosition();
		});
		window.setTimeout(()=>this.updateTime(), 100);
	}

	public stop():void {
		if(this.state == "recording") {
			this.markingLayer.context.service.fileIO.stopRecord();
		} else {
			if(this.snd) {
				this.snd.pause();
				this.snd.currentTime = 0;
			}
			this.timeStr = "00:00";
			this.state = "ready";
			this.updatePopupPosition();
		}
		
	}

	public play():void {
		this.state = "playing";
		this.updatePopupPosition();

		if(this.snd) {
			// 已有聲音，不需要載入。開始播放聲音。
			this._play();
			return;
		}

		// 取得聲音 url
		this.markingLayer.context.loadStudentFile(this.markingLayer.pageCom, {douid:"#VOICE_NOTE#"+this.owner}, this.createBy, 
			{key:this.sourceJSON.remark.key, reference:this.sourceJSON.remark.reference}, this.sourceJSON.remark.resourceIndex
		).then((url:string)=>{
			// 開始播放聲音。
			this.snd = this.markingLayer.context.service.fileIO.createSoundFromURL(url);
			this._play();
		}).catch(e=> {
			var s:any = this.markingLayer.context.service;
			s.alertService.alert(s.translateService.instant("bookviewer.alert.load_data_fail"));
			this.stop();
		});
	}

	protected _play():void {
		this.snd.play();
		this.snd.onended = ()=> {
			this.stop();
		};
		window.setTimeout(()=>this.updateTime(), 100);
	}

	protected updateTime():void {
		if((this.state == "playing" && this.snd) || (this.state == "recording" && this.markingLayer.context.service.fileIO.getRecordingState() !="compressing")) {
			var time:number;
			if(this.state == "playing")
				time = this.snd.currentTime;
			else {
				this.ms+=100;
				time = this.ms/1000;
			}

			this.timeStr = this.to2Digi(Math.floor(time/60))+":"+this.to2Digi(Math.floor(time%60));
			window.setTimeout(()=>this.updateTime(), 100);
		}
	}

	protected to2Digi(val:number):string {
		return (val<10 ? "0" : "")+val;
	}

	// =========================
	// text sticker function
	// =========================
	public contentUpdate():void {
//		console.log(this.editStr);
		this.sourceJSON.remark.text = this.htmlToTextFlow(this.editStr);
//		console.log("save:", this.sourceJSON.remark.text);

		// 用 HTML格式儲存，RO 自己轉換去 text flow 觀看
		//this.sourceJSON.remark.text = this.editStr;
		this.markingLayer.change.emit({type:"updateSticker", data:this});
		this.isOpenPopup = false;
	}

	public setTextStyle(event:any, style:string):void {
		if(event)
			event.preventDefault();

		var quill:any = this.quill;
		if(quill) {
			quill.format(style, !quill.getFormat()[style], 'user');
			this.updateTextState(null, true);
		}
	}

	public updateTextState(data:any, force:boolean=false):void {
		if (force || (data && data.range)) {
			var quill:any = this.quill;
			if(quill) {
				if(!this.state)
					this.state = {};
				if(this.editStr=="" || this.editStr==null) {
					this.state.bold = false;
					this.state.italic = false;
					this.state.underline = false;
					this.state.color = '#000000';
				} else {
					var style:any = quill.getFormat();
					this.state.bold = style.bold;
					this.state.italic = style.italic;
					this.state.underline = style.underline;
					this.state.color = style.color ? style.color : '#000000';
				}
			}
		}
	}

	public setTextColor(event:any, val):void {
		if(event)
			event.preventDefault();

		var quill:any = this.quill;
		if(quill) {
			quill.format('color', val, 'user');
			this.updateTextState(null, true);
		}
	}

	// 簡單轉換
	protected textFlowToHtml(textflow:string):string {
		// TextFlow fontFamily fontSize <- remove
		// span -> font
		// p -> div
		// fontStyle="italic"
		// fontWeight="bold"
		// textDecoration="underline"
		/*
		var convertor = new TextFlowUtils();
		textflow = convertor.textFlowToDOM(textflow,false).firstElementChild.innerHTML;
		textflow = textflow.replace(/<dom class="t"><\/dom><dom class="b"><\/dom>/gi, '');
console.log(textflow);
		*/

//		textflow = textflow.replace(/(<TextFlow\b[^>]*>)|(<\/TextFlow>)|( fontFamily=\"[a-zA-Z\d\s]+\")|( fontSize=\"[a-zA-Z\d\s]+\")/gi, '')
//			.replace(/<span\b/gi, '<font').replace(/<\/span>/gi, '</font>').replace(/<p>/gi, '<div>').replace(/<\/p>/gi, '</div>');


		return textflow;
	}

	protected htmlToTextFlow(html:string):string {
		// div -> p
		// font -> span
		return html;
//		return '<TextFlow fontFamily="Noto Sans T Chinese Regular" fontSize="15" fontLookup="embeddedCFF" lineBreak="toFit" textAlign="left" whiteSpaceCollapse="preserve" version="3.0.0" xmlns="http://ns.adobe.com/textLayout/2008">'+
//			html.replace(/<div>/gi, '<p>').replace(/<\/div>/gi, '</p>').replace(/<font\b/gi, '<span').replace(/<\/font>/gi, '</span>')+'</TextFlow>';
	}

	public duplicateObject():any {
		/*id: -1, type: "teacherVoiceNote", num:1, remark:{
			createBy:creator, x:x, y:y, id:0,
			resourceIndex:this.findCanUseResourceIndex("teacherVoiceNote") // 找可用 resource id
		}
		id: -1, type: "teacherTextNote", num:1, remark:{
				createBy:creator, x:x, y:y, text:"", id:0
			}
		
		*/

		var tmpObj:any = null;
		if(this.sourceJSON.type=="teacherTextNote") {
			var tmpObj:any = {id: -1, type: "teacherTextNote", num:1, remark:{}};
			ObjectUtils.copy(this.sourceJSON.remark, tmpObj.remark);

		} else if(this.sourceJSON.type=="teacherVoiceNote") {
		}
		return tmpObj;
	}
}