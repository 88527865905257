import { Component, Input, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dateTimePicker3SelectDate',
    template: `
<div class="rowToday">
    <div class="todayBtn" (click)="selectToday()">{{{tc:'今天',sc:'今天',en:'Today'}[translate.currentLang]}}</div>
</div>

<ng-container *ngIf="curView=='selectDate'">
    <div class="rowMonth">
        <fa-icon [icon]="faChevronLeft" class="switchMonthBtn" (click)="switchLastMonth()"></fa-icon>
        <div class="monthBtn" (click)="curView='selectMonth'">{{calendarYear + '/' + (calendarMonth+1)}}</div>
        <fa-icon [icon]="faChevronRight" class="switchMonthBtn" (click)="switchNextMonth()"></fa-icon>
    </div>
    <div class="rowDate">
        <div
          *ngFor="let day of daies[translate.currentLang]; let i=index"
          class="day"
          [class.holiday]="i==0"
        >{{day}}</div>
        <div
            class="date"
            *ngFor="let calDate of dates"
            [class.today]="isToday(calDate)"
            [class.selected]="isSameDate(date, calDate)"
            [class.holiday]="calDate.getDay()==0"
            [class.curMonth]="calDate.getMonth()==calendarMonth"
			[class.disabled]="!isDateAvailable(calDate)"
            (click)="dateClickHandler(calDate)"
        >{{calDate.getDate()}}</div>
    </div>
</ng-container>
<dateTimePicker3SelectMonth *ngIf="curView=='selectMonth'" [date]="date" (onChange)="monthChanged($event)"></dateTimePicker3SelectMonth>
    `,
    styleUrls: ['./dateTimePicker3SelectDate.component.scss']
})

export class DateTimePicker3SelectDateComponent implements OnChanges {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

    public faChevronLeft:IconDefinition = faChevronLeft;
    public faChevronRight:IconDefinition = faChevronRight;

    @Input() public lang:string = 'tc';
    @Input() public date:Date = new Date();
	// 只可選的日期 e.g.: [ {start:DateObj, end:DateObj}, {start:DateObj, end:DateObj} ]
	@Input() public availableDates:any[] = [];
	// 不可選的日期 e.g.: [ {start:DateObj, end:DateObj}, {start:DateObj, end:DateObj} ]
	@Input() public unavailableDates:any[] = [];

    public curView:string = 'selectDate'; //SelectMonth

	private daiesTc:string[] = ['日', '一', '二', '三', '四', '五', '六'];
	private daiesEn:string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    public daies:any={ tc:this.daiesTc, sc:this.daiesTc, en:this.daiesEn };
    public dates:Date[] = [];

    public calendarYear:number;
    public calendarMonth:number;

    @Output() dateClick:EventEmitter<Date> = new EventEmitter();

    constructor(public translate:TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.date && changes.date.currentValue) {
            this.calendarYear = changes.date.currentValue.getFullYear();
            this.calendarMonth = changes.date.currentValue.getMonth();
            this.updateDates();
        }
    }
    //---------------------------------------------------------------------------------------------
    private getLastSunday(curDate:Date=new Date()):Date {
        let lastSunday:Date = new Date(curDate);
        lastSunday.setDate(curDate.getDate() - curDate.getDay());
        return lastSunday;
    }
    //---------------------------------------------------------------------------------------------
    public updateDates(year:number=this.calendarYear, month:number=this.calendarMonth):void {        
        this.dates.splice(0, this.dates.length);
        let firstDateOfMonth:Date = new Date(year, month, 1, 0, 0, 0, 0);
        let lastDateOfMonth:Date = new Date(firstDateOfMonth);
        lastDateOfMonth.setMonth(lastDateOfMonth.getMonth() + 1);
        lastDateOfMonth.setDate(lastDateOfMonth.getDate() - 1);
        
        let firstShowDate:Date = this.getLastSunday(firstDateOfMonth);
        let lastShowDate:Date = new Date(firstShowDate);
        while(lastShowDate<=lastDateOfMonth) {
            for (let i=0; i<7; i++) {
                let thisDate:Date = new Date(lastShowDate);
                this.dates.push(thisDate);
                lastShowDate.setDate(lastShowDate.getDate() + 1);
            }
        }
        
        lastShowDate.setDate(lastShowDate.getDate() - 1);
    }
    //---------------------------------------------------------------------------------------------
    public getDateTextColor(date:Date):string {
        if (date.getDay()==0) return '#FEB9BD';
        if (date.getMonth()!=this.calendarMonth) return '#999';
        return '#000';
    }
    //---------------------------------------------------------------------------------------------
    public isSameDate(date1:Date, date2:Date):boolean {
        if (!date1 || !date2) return false;
        return (date1.getFullYear()==date2.getFullYear() && date1.getMonth()==date2.getMonth() && date1.getDate()==date2.getDate());
    }
    //---------------------------------------------------------------------------------------------
    public isToday(date:Date):boolean {
        return this.isSameDate(date, new Date());
    }
    //---------------------------------------------------------------------------------------------
    public switchLastMonth():void {
        this.calendarMonth--;
        if (this.calendarMonth<0) {
            this.calendarMonth = 11;
            this.calendarYear--;
        }
        this.updateDates();
    }
    //---------------------------------------------------------------------------------------------
    public switchNextMonth():void {
        this.calendarMonth++;
        if (this.calendarMonth>11) {
            this.calendarMonth = 0;
            this.calendarYear++;
        }
        this.updateDates();
    }
    //---------------------------------------------------------------------------------------------
    public monthChanged(yearMonthObj:any={year: this.date.getFullYear(), month: this.date.getMonth()}):void {
        //this.calendarYear = this.date.getFullYear();
        //this.calendarMonth = this.date.getMonth();
        this.calendarYear = yearMonthObj.year;
        this.calendarMonth = yearMonthObj.month;

        this.updateDates();
        this.curView='selectDate';
    }
    //---------------------------------------------------------------------------------------------
    public selectToday():void {
        let today:Date = new Date();
        this.dateClickHandler(today);
        /*this.date.setFullYear(today.getFullYear());
        this.date.setMonth(today.getMonth());
        this.date.setDate(today.getDate());
*/
        this.monthChanged();
    }
    //---------------------------------------------------------------------------------------------
    public dateClickHandler(date:Date):void {
        this.date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        this.dateClick.emit(this.date);
    }
	//---------------------------------------------------------------------------------------------
	private isDateInRange(d:Date, start:Date, end:Date):boolean {
		if (start && end) return d>=start && d<=end;
		else if (start) return d>=start;
		else if (end) return d<=end;
		else return false;
	}
	//---------------------------------------------------------------------------------------------
	public isDateAvailable(d:Date):boolean {
		if (this.availableDates) {
			return this.availableDates.find((i:any)=>this.isDateInRange(d, i.start, i.end));
		} else if (this.unavailableDates) {
			return !this.unavailableDates.find((i:any)=>this.isDateInRange(d, i.start, i.end));
		} else {
			return true;
		}
	}
}
