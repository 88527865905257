import { Injectable } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { tap } from 'rxjs/operators';
import { resolve } from 'path';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class SubjectService {
	public subjects: any;
	protected observable:Observable<any>;
	private learningObjectPendingList:any [];
	constructor(private datas: DataService, private trans: TranslateService) {
		if ((this.subjects == null) && !this.observable) {
			this.loadSubject().subscribe();
		}
		this.learningObjectPendingList = [];
	}
	private loading:boolean;
	private loadCompleted:boolean;
	loadSubject() {
		this.loading = true;
		if(this.observable){
			return this.observable;
        }
		
		const url = this.datas.apiUrl + '?api=ROSchoolSubject.get_subjects&json=[]&jwt=' + this.datas.jwt;
		this.observable = this.datas.get(url).pipe(tap((res: any) => {
			if (res.subjects) {
				this.subjects = res.subjects.map(e => {
					let sj: any = { type: e.type, school_subject_id: e.school_subject_id, public_subject_id: e.public_subject_id, level: e.level };
					sj.id = (sj.public_subject_id ? sj.public_subject_id : '0') + '-' + (sj.school_subject_id ? sj.school_subject_id : '0');
					sj.title = this.datas.lang == 'en' ? e.ename : e.cname;
					sj.short_name = e.short_name;
					return sj;
				});
				
			}
			this.observable = null;
			this.loadCompleted = true;
		}));
		return this.observable;
	}

	getSubjects(options={}){
		return new Promise((resolve,reject)=>{
			let interval = setInterval(()=>{
				if (this.subjects){
					resolve(this.subjects);
				}
			},100);
			setTimeout(()=>{
				clearInterval(interval);
				reject({msg: 'getSubjects: timeout'});
			},5000);
		});
	}

    public getSubjectById(id:string):any {
		if (id == null){
			return null;
		}
		let parts:any = id.indexOf("-")>=0 ? id.split("-") : [id,0];
		parts[0] = parts[0]=="" ? 0 : parseInt(parts[0]);
		parts[1] = parts[1]=="" ? 0 : parseInt(parts[1]);
		id = parts[0] +"-" + parts[1];
		return this.subjects.find((sub:any)=>sub.id==id);
    }

	public getLevelFromLevelName(levelName){
		if (this.trans.translations[this.datas.lang]){
			if (this.trans.translations[this.datas.lang]['schoolSetting']){
				const labelMap = this.trans.translations[this.datas.lang]['schoolSetting'].label.subject_level_type;
				for (let prop in labelMap){
					if (labelMap[prop] == levelName){
						return prop;
					}
				}
			}
		}
		return null;
	}

	public getTypeNameFromtype(type: string): string | null {
		const publicLabel = {tc:"公用", sc:"公用", en:"Public"};
		const schoolLabel = {tc:"本校", sc:"本校", en:"School"};
		if(type == 'public'){
			return publicLabel[this.datas.lang];
		}else if(type == 'school'){
			return schoolLabel[this.datas.lang];
		}
		return null;
	}

	public getLevelNameFromLevel(level: string): string | null {
		if (this.trans.translations[this.datas.lang]) {
			if (this.trans.translations[this.datas.lang]['schoolSetting']) {
				const labelMap = this.trans.translations[this.datas.lang]['schoolSetting'].label.subject_level_type;
				if (labelMap.hasOwnProperty(level)) {
					return labelMap[level];
				}
			}
		}
		return null;
	}
	getLearningObjectiveNames(array: any[] ) :Promise<any>
	{
		if(array &&array.length)
		{
			return Promise.all(
				array.map((id:string)=>{
					return this.getLearningObjectiveName(id);
				})
			).then((textArray:any [])=>{
				return textArray.join(", ");
			})
			
			
		}
		return Promise.resolve("");
	}

	getLearningObjectiveNameFromCache(id:string):string {
		return this.learningObjectiveCache.hasOwnProperty(id) ? this.learningObjectiveCache[id].name : id;
	}

	getLearningObjectivePublicStateFromCache(id:string):number {
		return this.learningObjectiveCache.hasOwnProperty(id) ? this.learningObjectiveCache[id].school_id==0 ? 1 : 0 : -1;
	}

	getLearningObjectiveName(id:string):Promise<any>
	{
		if (id.indexOf('custom|') === 0){
			return Promise.resolve(id.split('|')[1]);
		}
		if(!this.delayCaller) this.delayCaller = new DelayCaller(
			this.loadLearningObjectives.bind(this), 100
		);
		if(this.learningObjectiveCache.hasOwnProperty(id))
		{
			return Promise.resolve(this.learningObjectiveCache[id].name);
		}
		var resolver:MyResolver = new MyResolver() 
		var o:any = {
			id:id, 
			point_id:id.split("-")[1],
			resolver:resolver
		}
		this.learningObjectPendingList.push(o);
		this.delayCaller.schedule();
		return resolver.getPromise();
	}
	private learningObjectiveCache:any = {

	}
	private loadLearningObjectives():void
	{
		var copy = this.learningObjectPendingList;
		this.learningObjectPendingList = [];
		var idArray = copy.map((l)=>{
			return l.id;
		}).filter((value, index, array)=> {
			return array.indexOf(value) === index;
		});
		this.datas.call(
			'AssessmentForm.get_points_only',
			idArray
		).then((data:any)=>{
			
			console.log(copy);
			console.log(idArray);
			var map:any = {};
			data.points.forEach((pt)=>{
				map[pt.assessment_form_id +"-"+pt.id] = pt;
				map[pt.assessment_form_id +"-"+pt.point_id] = pt;
				map[pt.assessment_form_id +"-"+pt.point_id+"-"+pt.version] = pt;
			})
			copy.forEach((o)=>{
				var found = map[o.id];
				if(found){
					o.resolver.resolve(found.name);
					this.learningObjectiveCache[o.id] = found;
				} else {
					o.resolver.resolve("");
					this.learningObjectiveCache[o.point_id] = {name:""};
				}
			})
		});
	}
	private delayCaller:DelayCaller
	
}

class DelayCaller
{
	private timerID:any = -1;
	constructor(private call:Function, private delay:number)
	{

	}
	public schedule():void
	{
		if(this.timerID != -1)
			clearTimeout(this.timerID);
		this.timerID = setTimeout(()=>{
			this.timerID = -1;
			this.call();
		}, this.delay);
	}
	

}
class MyResolver
{
	private promise: Promise<any>;
	private resolveHandler:Function;
	private rejectHandler:Function;
	constructor()
	{
		this.promise = new Promise((resolve, reject)=>{
			this.resolveHandler = resolve;
			this.rejectHandler = reject;
		})
	}
	getPromise():Promise<any>
	{
		return this.promise;
	}
	resolve(value)
	{
		this.resolveHandler.call(null, value);
	}
	reject(reason)
	{
		this.rejectHandler.call(null, reason);
	}
}