export class ROSectionHelper
{
	
	/**
	 * 
	 * info strcture
	 * {
		 	X:1,
		 	A:2
	 * }
	 * @param pageSectionInfo 
	 */
	public static unionInfo(info1: any, info2: any) {
		var uninoObject:any = {};
		var key1Array:string [] = Object.keys(info1);
		var key2Array:string [] = Object.keys(info2);
		var fn = (key:string)=>{
			if(uninoObject.hasOwnProperty(key)) return;
			var value:number = 0;
			if(info1.hasOwnProperty(key)) value += info1[key];
			if(info2.hasOwnProperty(key)) value += info2[key];
			uninoObject[key] = value;
		};
		key1Array.forEach(fn);
		key2Array.forEach(fn);
		return uninoObject;
	}

	public static stringify(sectionInfo:any, filter:any = null):string
	{
		var list:any = [];
		if(filter == null) filter = sectionInfo;
		if(filter.hasOwnProperty("X") && sectionInfo.hasOwnProperty("X") && sectionInfo.X)
			list.push({key:"", value:sectionInfo.X});
		
		for(var key in filter)
		{
			if(key != "X" && sectionInfo.hasOwnProperty(key) && sectionInfo[key])
			{
				var value = sectionInfo[key];
				list.push({key:key, value:value});
			}
		}
		return list.map((item:any):string=>{
			return item.key+item.value;
		}).join("");
	}

	public static parse(section:string):any{
		var output:any = {};
		if(!section) return output;
		const regex = /([A-Z]{0,})([0-9]{1,})/gm;
		let m;
		
		while ((m = regex.exec(section)) !== null) {
		    // This is necessary to avoid infinite loops with zero-width matches
		    if (m.index === regex.lastIndex) {
		        regex.lastIndex++;
		    }
			var key = m[1] ? m[1] : "X";
		    var value = parseInt(m[2]);
			output[key] = value;
		}
		return output;
	}
}