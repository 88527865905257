import {
	Directive, Output, EventEmitter, HostBinding, HostListener, OnInit, OnDestroy
} from '@angular/core';

/**
 * Example Usage:
 * <div dom-directive #view (onInit)="onInit(view)"></div>
 *
 */
@Directive({
	selector: '[dom-directive]'
})
export class DOMDirective implements OnInit, OnDestroy{
	
	@Output() onInit = new EventEmitter();
	ngOnInit(): void {
		
		this.onInit.emit({});
	}

	@Output() onDestory = new EventEmitter();
	ngOnDestroy(): void {
		this.onDestory.emit(null);
	}
}
