import { Component, Input } from '@angular/core';

@Component({
    selector: 'sectionTitle',
    template: `
    <span class="sectionTitle">
        <ng-container *ngIf="title">{{title}}</ng-container>
        <ng-content *ngIf="!title"></ng-content>
    </span>
    `,
    styleUrls: ['./sectionTitle.component.scss']
})

export class SectionTitleComponent {
  @Input() public title:string = "";

  constructor() {
  }
  //-----------------------------------------------------------------------------------------------


}
