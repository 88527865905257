import { AfterViewInit, Component, ElementRef, HostListener, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
@Component({
	selector: "text-renderer",
	template: `
	<div *ngIf="text !== null" class="text" [ngClass]="classObject" [ngStyle]="textStyle" >
		<span>{{text}}</span>
	</div>
    `,
	styleUrls: ['./text.renderer.scss']
})


export class TextRenderer implements AfterViewInit, OnChanges {
	public params: any;
	public textStyle:any;
	public faIconStyle:any;
	public currentValue: any;
	public text = '';
	public faIcon:any;
	public tooltip;
	public classObject:any = {};
	constructor(public eleRef: ElementRef){

	}
	ngAfterViewInit(): void {
		
	}
	ngOnChanges(changes: SimpleChanges): void {
		
	}
	agInit(params: ICellEditorParams): void {
		
		this.params = params;
		if (this.params.style){
			if(this.params.classObject)
			{
				this.classObject = this.params.classObject;
			}
			if (this.params.style.text) {
				this.textStyle = this.params.style.text;
			}
			if (this.params.style.host){
				let hostStyle = this.eleRef.nativeElement.style;
				for (let prop in this.params.style.host){
					hostStyle[prop] = this.params.style.host[prop];
				}
			}
		}
		
		
		// this.text = this.params.getValue();
		this.text = this.params.formatValue(this.params.getValue());
		
		
		if (this.params.tooltip){
			this.tooltip = this.params.tooltip;
		} else {
			this.tooltip = this.text;
		}
	}

	

}
