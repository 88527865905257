import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioComponent } from './audio.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    declarations: [
        AudioComponent
    ],
    exports: [
        AudioComponent
    ],
    providers: [
    ],
    entryComponents: []
})
export class AudioModule {
}