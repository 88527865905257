import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TextTokenizer } from "src/app/common/TextTokenizer";

@Component({
	selector: "speechBox",
	templateUrl: "./speechBox.html",
	styleUrls: ["./speechBox.scss"]
})
export class speechBox {
	@ViewChild('dictionaryPopup', { static: false }) dictionaryPopup: any
	@Input() public parent;
	@Input() public role: "robot" | "student" = "robot";
	@Input() public canPlay: boolean = true;
	@Input() public content: string = "";
	@Input() public recognition: [] = [];
	@Input() public showPlayButton: boolean = false;
	@Input() public showListenButton: boolean = false;
	@Input() public ImageUrl: string = "";
	@Input() public isCorrect: boolean = undefined;
	@Input() public isHint: boolean = false;

	@Output() public playButtonClicked: EventEmitter<void> = new EventEmitter<void>(); 
	@Output() public listenButtonClicked: EventEmitter<void> = new EventEmitter<void>();

	public clicked_span:any = null
	public text_array = []
	public dictionaryPopupBGstyle = {
		top : '-100vh',
		left : '-100vw',
		height : '200vh',
		width: "200vw"
	}
	public recognitionText = [];
	
	ngOnInit() {
		// let regExp = new RegExp("(\\p{Emoji_Presentation}|\\b\\w+'\\w+|\\b\\w+\\b|[^\\w\\s])", "gu");
		// this.text_array = this.content.replace(/"/g, '\"').match(regExp);
		this.text_array = this.content.split(/(?=\s)|(?<=\s)/);
		if (this.content && this.recognition) {
			this.handleRecognitionWord();
		}
	}

	public play() {
		this.parent.readText(this.content)
	}

	isEnglishWord(str) {
		str = str.trim()
		if (str.length >=2 && [',','.','?','!',"'","…",";",'"'].indexOf(str[str.length-1]) != -1 ) {
			str = str.substring(0,str.length-1)
		}else if (str.length >=3 && str.substring(str.length-2,str.length) == "'s") {
			str = str.substring(0,str.length-2)
		}
		return /^[a-zA-Z]+$/.test(str);
	}

	isLastCharPunctuation(str){
		str = str.trim()
		return str.length >=2 && [',','.','?','!',"'","…",";",'"'].indexOf(str[str.length-1]) != -1
	}
	openDictionary(str){
		str = str.trim()
		if (str.length >=2 && [',','.','?','!',"'","…",";",'"'].indexOf(str[str.length-1]) != -1 ) {
			str = str.substring(0,str.length-1)
		}else if (str.length >=3 && str.substring(str.length-2,str.length) == "'s") {
			str = str.substring(0,str.length-2)
		}
		
		if (this.isEnglishWord(str)) {
			this.dictionaryPopup.open(str)
		}
	}

	onDictionaryPopupClosed(event){	
		console.log(event)
		try {
			this.clicked_span.classList.remove("yellowBorder")
		} catch (error) {
			console.log(error);
		}	
	}

	addYellowBorder(eventTarget){
		this.clicked_span = eventTarget
		this.clicked_span.classList.add("yellowBorder")
	}

	handleRecognitionWord() {
		let answer: any = this.recognition;
		for (let [index, word] of answer.entries()) {
			if (index === 0) {
				this.recognitionText.push({ item: `${word.Word.charAt(0).toUpperCase()}${word.Word.slice(1)}`, error: word.ErrorType, accuracyScore: word.AccuracyScore })
			} else {
				this.recognitionText.push({ item: word.Word, error: word.ErrorType, accuracyScore: word.AccuracyScore })
			}
		}
	}
		
}