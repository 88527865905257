import { Injectable, ComponentFactoryResolver, ApplicationRef, ComponentRef, Injector, EmbeddedViewRef, NgZone } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { CommonService } from 'src/app/service/common.service';
import { StorageService } from 'src/app/service/storage.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, delay, take, filter, tap } from 'rxjs/operators';
import { CreateShareModal } from './create-share.modal';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from 'src/app/service/alert.service';
import { TagGroupService } from '../tagGroupModule/tagGroup.service';
import { MemberService } from '../memberInfoModule/member.service';

@Injectable({ providedIn: 'root' })
export class CreateShareService {
	public createShareModal;
	private modalData;
	constructor(private datas: DataService, private http: HttpClient, 
		private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, 
		private injector: Injector, private zone: NgZone, private storage: StorageService, 
		private sans: DomSanitizer, private als: AlertService, private load: LoadingService, private tg: TagGroupService, private member: MemberService) {
		this.getInfo().subscribe();
		this.loadTags();
		this.loadMembers();
		
		this.storage.getSubjects();
	}

	private loadTags(){
		this.tg.getTags().then((obj:any)=>{
			this.storage.schoolYears = obj.schoolYears;
			this.storage.tags = obj.tags;
			this.storage.inBranch = this.storage.tags.find(t => t.my_tag == 1 && t.gtype != 'main') && !this.storage.tags.find(t => t.my_tag == 1 && t.gtype == 'main' && t.type != 1 && t.rid != null);
		});
	}

	private loadMembers(){
		this.member.getMembers().then(members=>{
			this.storage.peoples = members;
		});
	}

	public test(){
		let modalObj = {
			type:'alert',
			title: 'upload.exceed-limit-1',
			confirm: ()=>{
				console.log('test')
			}
		};
	}

	public open(item:any){
		//item = {id: int, type: string, title: string, ...}
		return new Promise((resolve, reject)=>{
			let delay = new Promise((resolve2, reject2)=>{
				this.load.add('open');
				let s = setInterval(()=>{
					if (this.storage.account && this.storage.schoolYears.length > 0){
						clearInterval(s);
						this.load.remove('open');
						resolve2(null);
					}
				},100);
			}).then(()=>{
				this.zone.run(()=>{
					let componentRef = this.componentFactoryResolver
						.resolveComponentFactory(CreateShareModal)
						.create(this.injector);
					this.appRef.attachView(componentRef.hostView);
					this.createShareModal = componentRef.instance;
					this.initModalData(item);
					this.createShareModal.shareModal = this.modalData;
					let close$ = this.createShareModal.close.subscribe(data => {
						if (data){
							resolve(data);
						} else {
							reject({msg: 'cancel'});
						}
						document.body.removeChild(this.createShareModal.nativeElement);
						this.createShareModal = null;
						close$.unsubscribe();
						close$ = null;
					});
					this.createShareModal.nativeElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
					document.body.appendChild(this.createShareModal.nativeElement);
				});
			});
		});
	}

	getInfo() {
		const storage = this.storage;
		return this.datas.post('ROWorkspace.getInfo').pipe(tap((res: any) => {
			if (res.account) {
				this.storage.account = res.account;
				if (res.account.personalSettings) {
					try {
						this.storage.account.personalSettings = JSON.parse(this.storage.account.personalSettings);
					} catch (e) {
						this.storage.account.personalSettings = {};
					}
				}
			}
		}));
	}

	initModalData(item){
		const startTime = moment();
		let year = this.storage.schoolYears.find(y => y.id == this.storage.account.school.school_year);
		if (year == null && this.storage.schoolYears.length > 0){
			year = this.storage.schoolYears.find(y=> {
				if (y.start_time && y.end_time){
					return y.start_time.isBefore() && y.end_time.isAfter();
				} else{
					return false;
				}
			})
		}
		if (year == null && this.storage.schoolYears.length > 0){
			year = this.storage.schoolYears[this.storage.schoolYears.length - 1];
		} else if (this.storage.schoolYears.length == 0){
			this.als.alert('workspace.no-school-year');
		}
		let subjectId = this.storage.subjects[0].id;
		this.modalData = {
			item: item, targets: [], subject: subjectId, share: { share_type: 'homework', pass_level: '50', time_limit: 'null', star: 0, repeat_star: 0, live_verify: 1, show_default_answer: 1, auto_submit: 1 }, startTime: startTime, endTime: null, wholeDay: false, correctionStartTime: null, correctionEndTime: null, correctionWholeDay: false, schoolYear: year, genShareUrl: false};
		return this.modalData;
	}





}
