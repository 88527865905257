import {
	Directive, Output, EventEmitter, HostBinding, HostListener
} from '@angular/core';

/**
 * Example Usage:
 * <input #button type="button" long-press-directive (onLongPress)="onLongPress()" (onLongPressing)="onLongPressing()">button</button>
 *
 */
@Directive({
	selector: '[long-press-directive]'
})
export class LongPressDirective {
	pressing: boolean;
	longPressing: boolean;

	timeout: any;
	interval: any;
	public longPressTimeout:any = 500;
	public intervalTime:any = 50;
	@Output() onLongPress = new EventEmitter();
	@Output() onLongPressing = new EventEmitter();
	@HostBinding('class.press') get press() { return this.pressing; }
	@HostBinding('class.longpress') get longPress() { return this.longPressing; }
	constructor()
	{
		
	}
	@HostListener('touchstart', ['$event'])
	@HostListener('mousedown', ['$event'])
	onMouseDown(event) {
		// console.log("LongPress", event.type);
		this.pressing = true;
		this.longPressing = false;
		this.timeout = setTimeout(() => {
			this.longPressing = true;
			this.onLongPress.emit(event);

			this.interval = setInterval(() => {
				this.onLongPressing.emit(event);
			}, this.intervalTime);

		}, this.longPressTimeout);
	}

	@HostListener('touchend', ['$event'])
	@HostListener('mouseup', ['$event'])
	@HostListener('mouseleave', ['$event'])
	@HostListener('click', ['$event'])

	endPress(event) {

		clearTimeout(this.timeout);
		clearInterval(this.interval);
		if(this.longPressing)
		{
			event.stopPropagation();
			event.stopImmediatePropagation();
			event.preventDefault();
			// console.log("LongPress", event.type, "BLOCK");
			return false;
		}
		// console.log("LongPress", event.type, "NO BLOCK");
	}
}
