import { ElementRef, EmbeddedViewRef } from "@angular/core";

export class AngularViewUtils
{

	public static appendChild(container:any, child:any):void
	{
		var containerDOM:HTMLElement = AngularViewUtils.getHTMLElement(container);
		var childDOM:HTMLElement = AngularViewUtils.getHTMLElement(child);
		if(containerDOM && childDOM)
		{
			// if(childDOM.parentElement)childDOM.parentElement.removeChild(childDOM);
			containerDOM.appendChild(childDOM);
		}
		/*
		comp:T, appendEle:HTMLElement = document.body):void {
		if (!comp['compRef'] || comp['compRef'].hostView['_appRef']) return;
		this.appRef.attachView(comp['compRef'].hostView);
		appendEle.appendChild((comp['compRef'].hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
		*/
	}

	private static getHTMLElement(view:any):HTMLElement
	{
		if(!view) return null;
		if(view instanceof ElementRef) return view.nativeElement;
		if(view instanceof HTMLElement) return view;
		if(view.hasOwnProperty("compRef"))
		{
			var ref:any = view.compRef;
			if(ref.hasOwnProperty("hostView"))
			{
				return (ref.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
			}
		}
		return  null;
	}
}