import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { faBook } from '@fortawesome/pro-solid-svg-icons';
import { CodeScanService } from 'src/app/sharedModule/codeScanModule/codeScan.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as isbnUtils from 'isbn-utils';
import { CommonService } from 'src/app/service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { InlineSvgService } from 'src/app/sharedModule/inlineSvgModule/inlineSvg.service';
import { MessageService } from 'primeng/api';
import { TagGroupService } from '../tagGroupModule/tagGroup.service';
import * as moment from 'moment';


@Component({
	selector: 'create-report-modal',
	template: `
  <div [ngClass]="['create-report-modal', 'book-type-' + bookType, 
  	bookAssigned && bookType == 'physical'?'bookAssigned':'', animated?'animated-end':'',
	targetSelecting?'target-selecting':'',
	book?'has-cover':'']" *ngIf="ready">
	<div class="modal-header">
		<a class="modal-header__cancel" (click)="cancelClick($event)">{{'course.cancel'|translate}}</a>
		<div class="modal-header__title">{{ modalTitle |translate}}</div>
		<a class="modal-header__confirm" (click)="clickSubject.next('confirm')">{{'course.confirm'|translate}}</a>
	</div>
	<div class="modal-body">
		<div class="modal-body__left" [ngClass]="'book-type-' + bookType" *ngIf="animated">
			<div class="box__row first-row tag">
				<div class="box__row__label">{{'workspace.tag'|translate}}:</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<div class="tag-title" (click)="targetSelectorOpen($event)">{{tags_title}}</div>
					<targetSelector #targetSelector [allowForAll]="false"
						memberRole="student" [(ngModel)]="targets" 
						></targetSelector>
				</div>
			</div>
	
			<div class="box__row type-setting">
				<div class="box__row__label">{{'workspace.text-star'|translate}}:</div>
				<div class="box__row__data">
					<numberInput min="0" max="50" [(ngModel)]="star"></numberInput>
				</div>
			</div>
			<div class="box__row">
				<div class="box__row__label">{{'course.book-type'|translate}} :</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<okaPulldown2 [options]="bookTypeOptions" bindLabel="label" bindValue="value" 
					[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="bookType" (changing)="bookTypeChange()"></okaPulldown2>
				</div>
			</div>
			<div class="box__row">
				<div class="box__row__label">{{'course.input-mode'|translate}} :</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<okaPulldown2 [options]="inputModeOptions" bindLabel="label" bindValue="value"
					[okaPulldownStyle]="okaPulldownStyle" [(ngModel)]="inputMode"></okaPulldown2>
				</div>
			</div>
			<div class="box__row" *ngIf="bookType == 'physical'">
				<div class="box__row__label">{{'course.assign-book'|translate}} :</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<ui-switch class="useCssVar" [checked]="bookAssigned" color="var(--section-maincolor)"
					(change)="bookAssignedChange($event)"></ui-switch>
				</div>
			</div>
			<div class="box__row" *ngIf="(bookAssigned && bookType == 'physical') || bookType == 'library'">
				<div class="box__row__label">{{'course.book-title'|translate}} *</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<input type="text" [(ngModel)]="name"/>
					<div class="not-filled" *ngIf="triedSubmitted && (name == '' || name == null ) && book == null">{{'course.pls-select-book-attach-to-report'|translate}}</div>
				</div>
			</div>
			<ng-container *ngIf="bookType == 'website'">
				<div class="box__row">
					<div class="box__row__label">{{'course.link'|translate}} *</div>
					<div class="box__row__data" [class.disabled]="data.bsid">
						<input type="text" [(ngModel)]="website" />
						<div class="not-filled" *ngIf="triedSubmitted && (website=='' || website == null)">{{'course.pls-input-url'|translate}}</div>
					</div>
				</div>				
			</ng-container>
			<ng-container *ngIf="bookType == 'file'">
				<div class="box__row">
					<div class="box__row__label">{{'course.file'|translate}} *</div>
					<div class="box__row__data" [class.disabled]="data.bsid">
						<upload-button [file]="file" [triedSubmitted]="triedSubmitted" (change)="uploadFileChange($event)"></upload-button>
					</div>
				</div>				
			</ng-container>
			<div class="box__row">
				<div class="box__row__label">{{'workspace.in-progress-time'|translate}}:</div>
				<div class="box__row__data">
					<div class="datetime in-progress" #inProgressTimeEl (click)="dateTimeClick(inProgressTimeEl, 'in-progress')">
						<ng-container *ngIf="!startTime && !endTime">
							<span class="datetime__text">{{'workspace.pls-select'|translate}}</span>
						</ng-container>
						<ng-container *ngIf="startTime && endTime">
							<span class="datetime__text">{{startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} - {{endTime | myDate: wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a'}}</span>
						</ng-container>
						<ng-container *ngIf="startTime != null && endTime == null">
							<span class="datetime__text">{{startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}}{{'workspace.up-to-forever' | translate}}</span>
						</ng-container>
					</div>
					<dateTimePicker #dateTimePicker [color1]="'var(--section-maincolor, #6a243b)'" [color2]="'var(--section-popupbgcolor, #F5ECF0)'" 
						[clearBtn]="true" [lang]="datas.lang"></dateTimePicker>
				</div>
			</div>
			<div class="box__row last-row">
				<div class="box__row__label">{{'course.remark'|translate}} :</div>
				<div class="box__row__data" [class.disabled]="data.bsid">
					<input type="text" [(ngModel)]="remark"/>
				</div>
			</div>
			
		</div>
		<div class="modal-body__right book-type-{{bookType}} {{bookAssigned?'bookAssigned':''}}" *ngIf="animated"
			[class.disabled]="!!data.bsid" 
			[class.has-cover]="book != null && (bookType =='physical' || bookType =='library')"
			[class.no-margin]="book == null && bookType !='physical' && bookType !='library'"
			>
			<div class="two-choice" *ngIf="book == null && bookType=='physical'">
				<div class="barcode" (click)="clickSubject.next('barcode')">
					<svg-icon name="btn_barcode"></svg-icon>
					<div class="text">{{'course.scan-barcode'|translate}}</div>
				</div>
				<div class="from-library" (click)="clickSubject.next('from-library')">
					<svg-icon name="btn_addbook"></svg-icon>
					<div class="text">{{'course.select-from-library'|translate}}</div>
				</div>
			</div>
			<div class="from-library" *ngIf="book == null && bookType=='library'" (click)="clickSubject.next('from-workspace')">
				<svg-icon name="btn_addbook_file"></svg-icon>
				<div class="text">{{'course.select-from-workspace'|translate}}</div>
			</div>
			<div class="preview" *ngIf="book">
				<div class="preview__image bt-{{bookType}}" [class.blank]="photo == null" [style.backgroundImage]="photo">
					<fa-icon *ngIf="!photo" [icon]="faBook"></fa-icon>
					<div *ngIf="!photo && book.title" class="text">{{ book.title }}</div>
					<div *ngIf="!photo && !book.title" class="text">{{ 'course.no-cover'|translate }}</div>
				</div>
				<ng-container *ngIf="bookType == 'physical'">
					<div class="preview__barcode" (click)="clickSubject.next('barcode')">
						<div class="preview__barcode__btn">
							<svg-icon name="btn_barcode"></svg-icon>
						</div>
					</div>
					<div class="preview__library" (click)="clickSubject.next('from-library')">
						<div class="preview__library__btn">
							<svg-icon name="btn_addbook"></svg-icon>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="bookType == 'library'">
					<div class="preview__barcode" (click)="clickSubject.next('from-workspace')">
						<div class="preview__barcode__btn">
							<svg-icon name="btn_addbook_file"></svg-icon>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
  </div>
  <select-book-modal *ngIf="selectBookModal" [data]="selectBookModal"></select-book-modal>
  <select-library-modal *ngIf="selectLibraryModal" [data]="selectLibraryModal" 
	(close)="selectLibraryModal.close($event)"></select-library-modal>
  `
	, styleUrls: ['./create-report.modal.scss'],
	providers: [MessageService]
})



export class CreateReportModal implements OnInit {
	@Input() data: any;
	@ViewChild('targetSelector', {static: false}) targetSelector;
	@ViewChild('inProgressTimeEl', {static: false}) inProgressTimeEl;
	@ViewChild('dateTimePicker', {static: false}) dateTimePicker;
	public faBook = faBook;
	public barcodeReader;
	private pauseScan = false;
	public okaPulldownStyle = { width: '204px' };
	public okaPulldownMenuStyle = {};
	public bookType = 'physical';
	public name = '';
	public inputMode = 'free';
	public bookAssigned = false;
	public photo;
	public book;
	public switchColor = '#684ccc';
	public ready = false;
	public file;
	public modalTitle = 'course.create-report';
	public triedSubmitted = false;
	public bookTypeOptions: any = [{ label: 'course.bt-physical', value: 'physical' }, { label: 'course.bt-library', value: 'library' }, { label: 'course.bt-file', value: 'file' }, { label: 'course.bt-website', value: 'website' }];
	public inputModeOptions: any = [{ label: 'course.im-free', value: 'free' }, { label: 'course.im-text', value: 'text' }, { label: 'course.im-audio', value: 'audio' }, { label: 'course.im-photo', value: 'photo' }, { label: 'course.im-video', value: 'video' }];
	public clickSubject = new Subject();
	public codePool = 'both';
	public selectBookModal;
	public selectLibraryModal;
	public website = '';
	public remark = '';
	public animated = false;
	public targets: any = [];
	public tags_title = '';
	public star = 0;
	public selectorPromise;
	public targetSelecting = false;
	public startTime;
	public endTime;
	public wholeDay = 0;
	constructor(private load: LoadingService, public datas: DataService, public iconReg: InlineSvgService, private script: ScriptService, private als: AlertService, private codeScan: CodeScanService, private sans: DomSanitizer, private coms: CommonService, private trans: TranslateService, public eleRef: ElementRef, private lds: LoadingService, private tg: TagGroupService) {
	}

	ngOnInit() {
		this.initTargets();
		this.initOptions();
		this.datas.post('ROLibrary.getCodePool').subscribe((res: any) => {
			this.codePool = res.setting ? res.setting : 'both';
		});
		if (this.data.data) {
			this.modalTitle = 'course.edit-report';
			let defaultData = this.data.data;
			if (defaultData.book) {
				this.book = defaultData.book;
				this.name = this.book.title;
				this.photo = null;
				if (this.book.cover) {
					this.packSafeCover(this.book.cover);
				}
			}
			if (defaultData.file) {
				this.file = defaultData.file;
			}
			this.bookAssigned = defaultData.bookAssigned == 1;
			if (this.bookAssigned) {
				this.name = defaultData.name.replace('閱讀報告 - ', '');
			}
			if (defaultData.bookType == 'library' && defaultData.book && defaultData.book.id) {
				this.datas.post('ROBookshelf.get_single_book_cover3', [defaultData.book.id]).subscribe((res: any) => {
					if (res.url) {
						this.book.cover = res.url;
						this.packSafeCover(res.url);
					}
				});
			}
			this.inputMode = defaultData.inputMode;
			this.bookType = defaultData.bookType;
			this.website = defaultData.website;
			this.remark = defaultData.remark;
		}
		this.iconReg.regSvgFiles([
			{ url: 'course/btn_barcode.svg', name: 'btn_barcode' },
			{ url: 'course/btn_addbook.svg', name: 'btn_addbook' },
			{ url: 'course/btn_addbook_file.svg', name: 'btn_addbook_file' },
		]).then(() => {
			this.ready = true;
		});
	}

	ngAfterViewInit() {
		this.clickSubject.pipe(throttleTime(100)).subscribe(e => {
			if (e == 'confirm') {
				this.confirm();
			} else if (e == 'barcode') {
				this.scan();
			} else if (e == 'from-library') {
				this.fromLibrary();
			} else if (e == 'from-workspace') {
				this.fromWorkspace();
			}
		});
		setTimeout(() => { this.animated = true }, 500);
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	scan(): void {
		let options = { fps: 5, width: 400, height: 200, codeType: 'barcode', style: { width: '400px', height: '200px' } };
		this.codeScan.scan(options).then((e: any) => {
			if (e.code == 1) {
				if (this.codePool == 'book_id' || this.codePool == 'both') {
					this.checkbookRefId(e.text);
				} else {
					this.checkBarcode(e.text);
				}
			} else if (e.msg && e.msg != 'cancel') {
				this.als.okAlert('course.' + e.msg);
			}
		}).catch(err => {
			console.log(err);
		});
	}

	checkBarcode(decodedText, override = 0) {
		let code = decodedText.trim();
		const myIsbnUtils = isbnUtils.parse(code);
		let isValid = false;
		if (myIsbnUtils) {
			if (myIsbnUtils.isIsbn10() || myIsbnUtils.isIsbn13()) {
				isValid = true;
			}
		}
		if (isValid) {
			let url = '//www.googleapis.com/books/v1/volumes?q=isbn:' + code;
			this.coms.log(url);
			this.datas.get(url).subscribe((res: any) => {
				if (res.items) {
					this.parseBookInfo(res, code);
				} else {
					let msg = this.trans.instant('common.not-valid-isbn', { isbn: code });
					this.als.okAlert(msg);
				}
			});
		} else {
			let msg = this.trans.instant('common.not-valid-isbn', { isbn: code });
			this.als.okAlert(msg);
		}
	}

	checkbookRefId(decodedText) {
		this.datas.post('ROLibrary.getBookRef', [decodedText]).subscribe((res: any) => {
			if (res.book) {
				this.book = res.book;
				this.name = res.book.title;
				this.packSafeCover(res.book.cover);
			} else if (this.codePool == 'both') {
				this.checkBarcode(decodedText);
			} else {
				let msg = this.trans.instant('common.not-valid-isbn', { isbn: decodedText });
				this.als.okAlert(msg);
			}
		});
	}

	packSafeCover(cover) {
		if (cover) {
			if (cover.indexOf('//') > -1) {
				this.photo = this.sans.bypassSecurityTrustStyle("url('" + cover + "')");
			} else if (cover.indexOf('/') > -1) {
				this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/" + cover + "')");
			} else if (cover.indexOf('/') == -1 && cover != 'blank') {
				this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/library-for-reading-report/" + cover + "')");
			}
		}
	}

	fromLibrary() {
		this.selectLibraryModal = {
			book: this.book,
			close: (obj: any = null) => {
				if (obj) {
					this.book = obj;
					this.name = obj.title;
					if (obj.cover) {
						this.photo = this.sans.bypassSecurityTrustStyle("url('//oka.blob.core.windows.net/media/library-for-reading-report/" + obj.cover + "')");
					}
				}
				this.selectLibraryModal = null;
			}
		}
	}

	fromWorkspace() {
		this.selectBookModal = {
			entries: {},
			courseBookHistory: [],
			singleOnly: true,
			close: (items: any) => {
				this.selectBookModal = null;
				if (items) {
					if (items[0].type == 'book') {
						this.load.add('get_entry_cover');
						this.datas.post('ROCourse.get_entry_cover', [items[0].id]).subscribe((res: any) => {
							this.book = items[0];
							this.name = this.book.title;
							this.book.cover = res.cover;
							this.photo = null;
							if (res.cover) {
								this.photo = this.sans.bypassSecurityTrustStyle("url('" + res.cover + "')");
							}
							this.load.remove('get_entry_cover');
						});
					} else {
						this.als.okAlert('course.pls-select-book-attach-to-report');
					}
				}
			}
		};
	}

	confirm() {
		this.triedSubmitted = true;
		let options = { bookType: this.bookType, inputMode: this.inputMode, bookAssigned: this.bookAssigned ? 1 : 0, name: this.name, book: this.book, website: this.website, remark: this.remark, file: this.file, startTime: this.startTime, endTime: this.endTime, star: this.star, wholeDay: this.wholeDay, targets: this.targets };
		if (this.bookType == 'website' && (this.website == null || this.website == '') ||
			this.bookType == 'file' && this.file == null ||
			this.bookType == 'library' && this.book == null ||
			(this.bookAssigned && this.book == null && this.name == '' && this.bookType == 'physical') ||
			this.targets.length == 0
		) {
			this.als.toastError('common.pls-complete-all-required-item');
		} else if (this.data.bsid === undefined) {
			this.confirmCreateReportShare();
		} else {
			this.updateReportShare(this.data.bsid);
		}
	}

	cancelClick($event) {
		$event.stopPropagation();
		if (this.data.close) {
			this.data.close();
		}
	}

	bookAssignedChange($event) {
		this.triedSubmitted = false;
		this.bookAssigned = $event;
	}

	parseBookInfo(res, isbn) {
		let title = this.trans.instant('course.unknown-book-title');
		let cover = null;
		if (res.items) {
			let item = res.items[0];
			if (item.volumeInfo) {
				title = item.volumeInfo.title;
				if (item.volumeInfo.imageLinks && item.volumeInfo.imageLinks.thumbnail) {
					cover = item.volumeInfo.imageLinks.thumbnail;
					this.photo = this.sans.bypassSecurityTrustStyle("url('" + cover + "')");
				}
			}
			this.book = { isbn: isbn, title: title, cover: cover };
		}
		this.name = title;
	}

	bookTypeChange() {
		this.triedSubmitted = false;
		this.book = null;
		this.photo = null;
	}

	uploadFileChange($event) {
		this.triedSubmitted = false;
		this.file = $event;
		this.name = this.file.originFilename;
	}

	initTargets() {
		if (this.data.data && this.data.data.tagId){
			this.targets = [{tType: 'tag', title: this.data.data.tag_name, value: this.data.data.tagId}];
		}
		if (this.targets.length > 0) {
			const targetTitles = this.targets.map(t => t.title);
			this.tags_title = targetTitles.join(', ');
		} else {
			this.targets = [];
			this.tags_title = this.trans.instant('commonService.please-select');
		}
	}

	initOptions() {
		if (this.data.bsid){
			this.star = this.data.data.star || 0;
			this.startTime = this.data.data.startTime;
			this.endTime = this.data.data.endTime;
		} else {
			this.startTime = moment();
		}
	}

	targetSelectorOpen($event) {		
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
			this.targetSelecting = false;
		} else {
			this.targetSelecting = true;
			setTimeout(()=>{
				this.selectorPromise = this.targetSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.targetSelecting = false;
					this.initTargets();
				});
			},250);
		}
	}

	dateTimeClick(el, dateType) {
		const dateTimePicker = this.dateTimePicker;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		let	update$ = dateTimePicker.update.subscribe(dt => {
			if (dt.startDateTime) {
				this.startTime = moment(dt.startDateTime);
			} else {
				this.startTime = null;
			}
			if (dt.endDateTime) {
				this.endTime = moment(dt.endDateTime);
			} else {
				this.endTime = null;
			}
			this.wholeDay = dt.wholeDay ? 1 : 0;
		});
		if (this.startTime) {
			const last8Dight = this.startTime.format('HH:mm:ss');
			wholeDay = last8Dight == '00:00:00' ? 1 : 0;
			startTime = this.startTime.toDate();
		}
		if (this.endTime) {
			const last8Dight2 = this.endTime.format('HH:mm:ss');
			wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
			endTime = this.endTime.toDate();
		}
		dateTimePicker.open(el, startTime, endTime, wholeDay).then(data => {
			update$.unsubscribe();
		});
	}

	async confirmCreateReportShare(){
		const individualUids = this.targets.filter(t => t.tType == 'people').map(e=> e.value);
		const targets = this.targets.filter(t => t.tType == 'tag');
		if (individualUids.length > 0) {
			const schoolId = this.datas.userInfo.school_id;
			const title = 'cid-share-' + moment().format('YYYYMMDD-HHmmss');
			const tagType = 13;
			const currentYear = this.tg.defaultSchoolYear;
			const yearId = currentYear?currentYear.id:'0';
			const  json: any[] = [schoolId, yearId, schoolId, title, tagType, 'main', individualUids];
			let res: any = await this.datas.post('ROCourse.create_virtual_tag_for_group_share', json).toPromise();
			targets.push({tType: 'tag', value: res.tid, title: title});
		}
		if (targets.length == 0){
			const msg = {tc:'請選擇組別', sc: '请选择组别', en:'Please select group'}[this.datas.lang];
			this.als.toastError(msg);
			return;
		}
		const name = this.name && this.name != ''?this.name:'閱讀報告'; 
		if (this.book == null){
			this.book = { title: '', cover: null };
		}
		let book_entry:any = {type: 'report', name: this.name, book: this.book, website: this.website, file: this.file, remark: this.remark, inputMode: this.inputMode, bookAssigned: this.bookAssigned || this.book?1:0, bookType: this.bookType };

		this.datas.post('ROBookshelfNew.addFakeBook', [book_entry]).subscribe((res:any)=>{
			book_entry.entry_id = res.item.book_id;
			const startTime = this.startTime?this.startTime.toISOString():null;
			const endTime = this.endTime?this.endTime.toISOString():null;
			const options = {type: 'custom', star: this.star, start_time: startTime, end_time: endTime };
			const targetValues = targets.map(e=>e.value);
			const o = { entries: [{ id: book_entry.entry_id, type: 'report', title: name }], tags: targetValues, options: options };
			this.createCreate(o);
		});
	}


	createCreate(obj) {
		this.datas.post2({ data: { api: 'ROBookShare.create_multi_share', json: [obj] } }).then((res: any) => {
			if (this.data.close){
				this.data.close(res.shares[0]);
			}
		});
	}

	updateReportShare(bsid){
		const start_time = this.startTime?this.startTime.toISOString() : null;
		const end_time = this.endTime?this.endTime.toISOString() : null;
		const options = {entry_type: 'report', star: this.star, start_time: start_time, end_time: end_time };
		this.load.add('update_report_share', 5000);
		this.datas.post('ROBookshelfNew.update_other_share', [bsid, options]).subscribe((res:any)=>{
			this.load.removeAll();
			this.data.close(res.share);
		});
	}


}
