import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // ngModel 需要
import { TranslateModule } from '@ngx-translate/core';
import { QuestionBankEditGridComponent } from './QuestionBankEditGrid.component';

// ag grid 使用的 component
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// ag grid cell component
import { CustomAgGridModule } from 'src/app/sharedModule/customAgGridModule/customAgGrid.module';
import { FileFormatterModule } from 'src/app/sharedModule/customAgGridModule/fileformatter.module';

import { PreviewModule } from 'src/app/sharedModule/previewModule/preview.module';
import { SubjectModule } from 'src/app/sharedModule/subjectModule/subject.module';
import { SoundRecorderPopupModule } from 'src/app/sharedModule/soundRecorderPopupModule/soundRecorderPopup.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AssessmentViewerModule } from 'src/app/sharedModule/AssessmentViewerModule/AssessmentViewer.module';
import { SubjectSelector3Module } from 'src/app/sharedModule/subjectSelector3Module/subjectSelector3.module';
import { OkaPulldownModule } from 'src/app/sharedModule/okaPulldownModule/okaPulldown.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { OKDQBModule } from 'src/app/sharedModule/AssessmentViewerModule/OKDQB.module';
import { WhitePopupModule } from 'src/app/sharedModule/whitePopupModule/whitePopup.module';
import { ComponentMenuBarModule } from 'src/app/sharedModule/componentMenuBarModule/componentMenuBar.module';
import { Modal2Module } from 'src/app/sharedModule/modal2Module/modal2.module';
import { ROBookModule } from 'src/app/sharedModule/roBookModule/ROBook.module';
import { SelectMarkerModule } from 'src/app/sharedModule/roBookModule/SelectMarker.module';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkAutoSizeVirtualScroll } from './autoSizeVirtualScroll';
import { QBSetFilterComponent } from './customFilter/QuestionBankCustomFilter.component';


@NgModule({
    imports: [
		CommonModule,FormsModule,TranslateModule,
		SubjectModule,
		AgGridModule,CustomAgGridModule,
		FileFormatterModule,
		PreviewModule, SoundRecorderPopupModule,
		AssessmentViewerModule,

		FontAwesomeModule, SubjectSelector3Module,
		// form view
		DragDropModule, OkaPulldownModule,UiSwitchModule, WhitePopupModule,OKDQBModule,ComponentMenuBarModule,
		Modal2Module,ROBookModule,SelectMarkerModule,
		ScrollingModule
    ],
    declarations: [
        QuestionBankEditGridComponent,
		CdkAutoSizeVirtualScroll,
		QBSetFilterComponent
    ],
    exports: [
		QuestionBankEditGridComponent
    ],
    providers: [],
    entryComponents: [
		QBSetFilterComponent
	],
    bootstrap: []
})
export class QuestionBankEditGridModule { }