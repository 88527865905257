import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent, PromptComponent, SelectionComponent, SelectionComponent2 , PromptDialogComponent} from './alert.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from 'src/app/directive/DirectiveModule';
import { BasicDialog, BasicDialogContainer, DialogContainer } from './Dialog';
import { CustomDialogContainer } from './CustomDialog';



@NgModule({
    imports: [
		CommonModule, FormsModule, DirectiveModule
    ],
    declarations: [
        SelectionComponent, SelectionComponent2, AlertComponent, PromptComponent, 
		BasicDialogContainer, 
		DialogContainer,
		BasicDialog,
		CustomDialogContainer, PromptDialogComponent
    ],
    exports: [
		// Alert
		BasicDialogContainer,
		DialogContainer,
		BasicDialog
    ],
    providers: [
    ],
    entryComponents: [
        SelectionComponent, SelectionComponent2, AlertComponent, PromptComponent, 
		BasicDialogContainer, 
		BasicDialog,
		DialogContainer,
		PromptDialogComponent
    ]
})
export class AlertModule {
}
