import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { OkaPulldownComponent } from 'src/app/sharedModule/okaPulldownModule/okaPulldown.component';

@Component({
    selector:"okaPulldownRenderer",
    template: `
    <okaPulldown
        *ngIf="params && params.okaPulldownParams"
        #okaPulldown
        [options]="params.okaPulldownParams.options"
        [bindLabel]="params.okaPulldownParams.bindLabel"
        [bindValue]="params.okaPulldownParams.bindValue"
        [placeholder]="params.okaPulldownParams.placeholder || 'profile.pleaseSelect'"
        [okaPulldownStyle]="params.okaPulldownParams.okaPulldownStyle || {}"
        [menuStyle]="params.okaPulldownParams.menuStyle || {}"
        (ngModelChange)="optionChanged($event)"
        [(ngModel)]="value"
    ></okaPulldown>
    <input #additionalTextInput *ngIf="isSelectedOptionNeedText()" [(ngModel)]="additionalText">
    `,
    styleUrls: ['./okaPulldown.renderer.scss']
})

//not yet tried
export class okaPulldownRenderer implements AfterViewInit {
    @ViewChild('okaPulldown', {static:false}) public okaPulldown:OkaPulldownComponent;
    @ViewChild('additionalTextInput', {static:false}) public additionalTextInput:ElementRef<HTMLInputElement>;

    public params:any;
    public value:any;
    public additionalText:any;

    constructor() {
    }
    //-------------------------------------------------------------------------------------------------
    ngAfterViewInit() {
        this.okaPulldown.showMenu();
    }
    //-------------------------------------------------------------------------------------------------
    // Optional - Params for rendering
    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.value = this.params.orgValue;
        this.additionalText = this.params.additionalText;
    }

    // Should return the final value to the grid, the result of the editing
    getValue(): any {
        return {
            value: this.value,
            additionalText: this.additionalText
        }
    }

    // Gets called once after initialised.
    // If you return true, the editor will appear in a popup
    isPopup(): boolean {
      return true;
    }

    // Gets called once, only if isPopup() returns true. Return "over" if the
    // popup should cover the cell, or "under" if it should be positioned below
    // leaving the cell value visible. If this method is not present, the
    // default is "over"
    /*getPopupPosition():string {
        return "over";
    };*/

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    /*isCancelBeforeStart():boolean {
        return false;
    }*/
    
    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    /*isCancelAfterEnd():boolean {
        return false;
    }*/

    // If doing full row edit, then gets called when tabbing into the cell.
    /*focusIn():boolean {
        return false;
    };*/

    // If doing full row edit, then gets called when tabbing out of the cell.
    /*focusOut():boolean {
        return false;
    };*/

    //-------------------------------------------------------------------------------------------------
    public isSelectedOptionNeedText():boolean {
        let selectedOption:any = this.getSelectedOption();
        return (selectedOption && selectedOption.need_text > 0);
    }
    //-------------------------------------------------------------------------------------------------
    public getSelectedOption(value:any=this.value):any {
        let option:any = ((this.params && this.params.okaPulldownParams && this.params.okaPulldownParams.options) || []).find((item:any)=>item.value==value);
        return option || null;
    }
    //-------------------------------------------------------------------------------------------------
    public optionChanged(value:any):void {
        this.value = value;
        if (this.isSelectedOptionNeedText()) {
            setTimeout(()=>{
                if (this.additionalTextInput && this.additionalTextInput.nativeElement) this.additionalTextInput.nativeElement.focus();
            });
        } else {
            this.additionalText = "";
            this.params.api.stopEditing();
        }
    }
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------

}
