import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TickBoxComponent, TickBoxIconComponent } from './tickBox.component';


@NgModule({
    imports: [
      CommonModule,
      FontAwesomeModule,
      PerfectScrollbarModule,
      TranslateModule
    ],
    declarations: [
        TickBoxComponent, TickBoxIconComponent
    ],
    exports: [
        TickBoxComponent, TickBoxIconComponent
    ],
    providers: [],
    bootstrap: [],
	entryComponents:[TickBoxIconComponent]
})
export class TickBoxModule { }
