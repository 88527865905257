import { Component, HostListener, Output, EventEmitter, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { MemberService } from './member.service';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
	selector: "teacher-selector",
	template: `
  <a class="edit-target" [ngStyle]="(options.editTargetStyle?options.editTargetStyle:{})" (click)="editTargetClick($event)">
	<div class="edit-target__text">{{'course.select-teacher'|translate}}</div>
	<div class="edit-target__triangle"></div>
  </a>
  <div class="dropdown" *ngIf="isOn">
    <div class="triangle" [style.right.px]="trianglePos"></div>
    <div class="left">
      <div class="searchBox">
          <input placeholder="{{'workspace.please-enter-keywords' | translate}}" [(ngModel)]="searchText">
          <div [ngClass]="['icon',searchText==''?'zoom':'close']" (click)="searchText=''"></div>
      </div>
      <div *ngIf="!searchText" class="tagRowContainer">
        <perfect-scrollbar class="width-4" [class.mobile]="datas.dev.isMobile" [disabled]="datas.dev.isMobile">
			<div class="option-row people" *ngFor="let teacher of teachers">
			<div class="check" (click)="checkClick(teacher)">
				<fa-icon [class.checked]="isSelected(teacher)" [icon]="(isSelected(teacher)?faCheckCircle:faCheckCircleLight)"></fa-icon>
			</div>
			<div class="point"></div>
				<span class="my-tag" (click)="checkClick(teacher)">{{teacher[lang == 'en'?'ename':'cname']}}</span>
	  		</div>
        </perfect-scrollbar>
      </div>

    <div *ngIf="searchText" class="tagRowContainer">
		<perfect-scrollbar class="width-4" [class.mobile]="datas.dev.isMobile" [disabled]="datas.dev.isMobile">
			<ng-container *ngFor="let teacher of searchedItems">
				<div [ngClass]="['option-row']">
					<div class="point"></div>
					<div class="check" (click)="checkClick(teacher)">
						<fa-icon [class.checked]="isSelected(teacher)" [icon]="(isSelected(teacher)?faCheckCircle:faCheckCircleLight)"></fa-icon>
					</div>
					<span (click)="checkClick(teacher)">{{teacher[lang == 'en'?'ename':'cname']}}</span>
				</div>
			</ng-container>
      	</perfect-scrollbar>
    </div>
  </div>
  <div class="right">
    <div class="label">
      <span>{{'workspace.selected' | translate}} ({{selectedItems.length > 0? selectedItems.length:0}}):</span>
      <div class="clear-all" (click)="clear()">{{'workspace.clear-all'|translate}}</div>
    </div>
    <div class="selectedList">
	  <perfect-scrollbar class="width-4" [class.mobile]="datas.dev.isMobile"[disabled]="datas.dev.isMobile">
        <div class="selectedItem" *ngFor="let item of selectedItems;let index=index;">
          <div class="icon people" [style.backgroundImage]="item.photo"></div>
          <div class="text">
			<span class="text__title">{{item[datas.lang == 'en'?'ename':'cname']}}</span>
          </div>
          <fa-icon class="trash" [icon]="faTrash" (click)="checkClick(item)"></fa-icon>
        </div>
      </perfect-scrollbar>
    </div>
	<div class="button-panel">
		<div class="confirm" (click)="confirmClick()">{{'course.confirm'|translate}}</div>
		<div class="cancel" (click)="cancelClick()">{{'course.cancel'|translate}}</div>
	</div>
  </div>
</div>
<div class="layer" id="layer" *ngIf="isOn" (click)="setOnOff()">

</div>
    `,
	styleUrls: ['./teacher-selector.component.scss']
})


export class TeacherSelectorComponent implements OnInit, OnDestroy {
	@Input() public selectedTeachers: any = [];
	@Output() public selectedTeachersChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() public options: any = {};
	@Output() public open: EventEmitter<any> = new EventEmitter<any>();
	@Output() public close: EventEmitter<any> = new EventEmitter<any>();
	@Output() public change: EventEmitter<any> = new EventEmitter<any>();
	public modalSelectedTeachers: any = [];
	public faSearch = faSearch;
	public teachers: any = [];
	public faCheck = faCheck;
	public faCircle = faCircle;
	public faTrash = faTrash;
	public faChevronRight = faChevronRight;
	public faChevronDown = faChevronDown;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public searchText: string = "";
	public isOn = false;
	public lang;
	public trianglePos = 109;
	private fetchIndex = 0;
	private onDestroy = new Subject();
	constructor(private translate: TranslateService, public datas: DataService, private sans: DomSanitizer) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.fetchTeachers();
		this.modalSelectedTeachers = [...this.selectedTeachers];
		if (this.datas.lang == 'en'){
			this.trianglePos = 83;
		}
	}

	ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.unsubscribe();
	}

	fetchTeachers(){
		if (this.fetchIndex == -1){
			return;
		}
		this.datas.post('ROMemberInfo.getTeachers',[this.fetchIndex]).pipe(takeUntil(this.onDestroy)).subscribe((res:any)=>{
			let newTeachers = res.teachers.map(e=>{
				let role = e.user_role > 2 ? 'teacher' : 'student';
				let url = '//ro2.azurewebsites.net/RainbowOne/webapp/2.8/roAnalyticsChart/assets/img/avatar_student_male.svg';
				if (e.url) {
					url = environment.resourceServer[e.server] + e.url;
					const photo = new Image();
					photo.onerror = () => {
						url = '//ro2.azurewebsites.net/RainbowOne/webapp/2.8/roAnalyticsChart/assets/img/avatar_' + role + '_' + (e.gender == 1 ? 'male' : 'female') + '.svg';
						e.photo = this.sans.bypassSecurityTrustStyle("url('" + url + "')");
					};
					photo.src = url;
				} else {
					url = '//ro2.azurewebsites.net/RainbowOne/webapp/2.5/roAnalyticsChart/assets/img/avatar_' + role + '_' + (e.gender == 1 ? 'male' : 'female') + '.svg';
				}
				e.photo = this.sans.bypassSecurityTrustStyle("url('" + url + "')");
				return e;
			});
			this.teachers = [...this.teachers, ...newTeachers];
			if (res.teachers.length == 100 || res.teachers.length == 0){
				this.fetchIndex = -1;
			} else {
				this.fetchIndex++;
				setTimeout(()=>{this.fetchTeachers();}, 500);
			}
		});
	}

	editTargetClick($event) {
		$event.stopPropagation();
		if (!this.options.disabled) {
			this.setOnOff();
		}
	}

	layerClick($event) {
		$event.stopPropagation();
		this.setOnOff();
	}

	setOnOff() {
		this.isOn = !this.isOn;
		if (this.isOn) {
			this.open.emit();
		} else {
			this.close.emit(this.selectedTeachers);
		}
	}

	checkClick(item = null) {
		let index = this.modalSelectedTeachers.indexOf(item.uid);
		if (index > -1) {
			this.modalSelectedTeachers.splice(index, 1);
		} else {
			this.modalSelectedTeachers.push(item.uid);
		}
		this.change.emit(this.selectedTeachers);
	}

	isSelected(item) {
		return !!this.modalSelectedTeachers.find(t => t == item.uid);
	}

	clear() {
		this.modalSelectedTeachers = [];
	}

	confirmClick() {
		this.selectedTeachers = [...this.modalSelectedTeachers];
		this.selectedTeachersChange.emit(this.selectedTeachers);
		this.isOn = false;
		this.close.emit(this.selectedTeachers);
	}

	cancelClick() {
		this.isOn = false;
		this.modalSelectedTeachers = [...this.selectedTeachers];
		this.close.emit(this.selectedTeachers);
	}

	get selectedItems() {
		return this.teachers.filter(t => this.modalSelectedTeachers.indexOf(t.uid) > -1);
	}

	get searchedItems() {
		if (!this.searchText) {
			return [];
		}
		let output = this.teachers.filter(e => e.cname.indexOf(this.searchText) > -1 || e.ename.indexOf(this.searchText) > -1);
		return output ? output : [];
	}


}
