import { Component, HostBinding, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

@Component({
    selector: 'bobbleBox',
    template: `
    <div class="backdrop" [style.zIndex]="backdropZindex" [ngStyle]="backDropStyle" (click)="(submitOnClose ? submit() : rejectOnClose ? rejectFn() : close())"></div>
    <div
        #contentContainer
        class="contentContainer"
        [style.zIndex]="contentContainerZindex"
        [style.left.px]="boxPosX"
        [style.top.px]="boxPosY"
        [style.backgroundColor]="backgroundColor"
        [style.padding.px]="padding"
        [class.no-animate]="options.noAnimate"
        tabindex="0"
    >
        <div class="contentWrapper">
            <ng-content></ng-content>
        </div>
        <ng-container *ngIf="contentContainer">
            <div
                *ngIf="boxPos=='bottom'"
                class="boxTail"
                [style.left.px]="tailPosX"
                [style.top.px]="tailPosY"
                [style.backgroundColor]="backgroundColor"
            ></div>
            <div
                *ngIf="boxPos=='top'"
                class="boxTail"
                [style.left.px]="tailPosX"
                [style.top.px]="tailPosY"
                [style.backgroundColor]="backgroundColor"
            ></div>
            <div
                *ngIf="boxPos=='left'"
                class="boxTail"
                [style.left.px]="tailPosX"
                [style.top.px]="tailPosY"
                [style.backgroundColor]="backgroundColor"
            ></div>
            <div
                *ngIf="boxPos=='right'"
                class="boxTail"
                [style.left.px]="tailPosX"
                [style.top.px]="tailPosY"
                [style.backgroundColor]="backgroundColor"
            ></div>
        </ng-container>
    </div>
    `,
    styleUrls: ['./bobbleBox.component.scss']
})

export class BobbleBoxComponent {
    @HostListener('dblclick', []) onDblClick() { console.log(this); }
    @ViewChild("contentContainer", {static:true}) contentContainer: ElementRef;
    //@HostListener('click', []) onClick() { this.close(); }
    @HostBinding('style.zIndex') public backdropZindex = 100;
    @HostBinding('style.display') hostDisplay:string = 'none';

    @Input() submitOnClose:boolean = false;
    @Input() rejectOnClose:boolean = false;
    @Input() backgroundColor:string = '#FFFFFF';
    @Input() padding:number = 10;
    @Input() marginTop:number = 0;
    @Input() marginBottom:number = 0;
    @Input() marginLeft:number = 0;
    @Input() marginRight:number = 0;
    @Input() options:any = {};
    @Input() backDropStyle:any = {};
    @Input() hCenter:'no'|'try'|'force' = 'no';
    public contentContainerZindex:number = 101;

    public boxPos:string = "bottom"; //top/bottom/left/right;
    public targetRect:DOMRect = null;
    public boxPosX:number = -10000;
    public boxPosY:number = -10000;
    public tailPosX:number = 0;
    public tailPosY:number = 0;

    private targetEle:HTMLElement = null;
    private resolveFn:Function = null;
    public rejectFn:Function = null;

    public isClosed():boolean { return this.hostDisplay == 'none'; }
    public isOpened():boolean { return !this.isClosed(); }

    constructor(private coms:CommonService) {
    }

//-------------------------------------------------------------------------------------------------
    public open(targetEle:HTMLElement):Promise<any> {
        return new Promise((resolve:Function, reject:Function)=>{
            this.resolveFn = resolve;
            this.rejectFn = reject;
            this.targetEle = targetEle;

            this.backdropZindex = this.coms.getZIndex();
            this.contentContainerZindex = this.coms.getZIndex();

            this.resize();

            this.hostDisplay = 'flex';

            if (this.contentContainer.nativeElement) {
                this.contentContainer.nativeElement.focus();
            }
        });
    }
    //-------------------------------------------------------------------------------------------------
    public submit():void {
        this.resolveFn();
        this.close();
    }
    //-------------------------------------------------------------------------------------------------
    public close():void {
        this.resolveFn = null;
        this.targetEle = null;
        this.hostDisplay = 'none';
        this.boxPosX = -10000;
        this.boxPosY = -10000;
        //if (this.submit) this.submit();
    }
    //-------------------------------------------------------------------------------------------------
    public resize():void {
        if (!this.targetEle) return;
        let counter:number = 1000;

        let timer = setInterval(() => {
            counter--;
            if (counter<1) clearInterval(timer);
            let boxEle = this.contentContainer.nativeElement;
            if (boxEle.clientHeight && boxEle.clientWidth){
                clearInterval(timer);

                let screenWidth:number = document.body.offsetWidth;
                let screenHeight:number = document.body.offsetHeight;
                let boxHeight:number = boxEle.offsetHeight;
                let boxWidth:number = boxEle.offsetWidth;
                let targetRect:DOMRect = this.targetEle.getBoundingClientRect() as DOMRect;

                let getBoxPosX:Function = ()=>{
                    let hCenterPosX:number = (screenWidth - boxWidth) / 2;
                    if (this.hCenter=='force') {
                        return hCenterPosX;
                    } else if (this.hCenter=='try') {
                        //box的border-radius是10，tail的width是20，所以有30 offset
                        if ((hCenterPosX + boxWidth - 30) < targetRect.left) {
                            return targetRect.left - boxWidth + 30;
                        } else if ((hCenterPosX + 30) > targetRect.right) {
                            return targetRect.right - 30;
                        } else {
                            return hCenterPosX;
                        }
                    } else {
                        return targetRect.left + ((targetRect.width - boxWidth) / 2) + this.marginLeft;
                    }
                }

                if ((targetRect.bottom + boxHeight) <= screenHeight) {
                    // Set box to bottom
                    this.boxPos = 'bottom';
                    this.boxPosX = targetRect.left + ((targetRect.width - boxWidth) / 2) + this.marginLeft;
                    this.boxPosY = targetRect.bottom + 10;
                } else if ((targetRect.top - boxHeight) >= 0) {
                    // Set box to top
                    this.boxPos = 'top';
                    this.boxPosX = getBoxPosX();
                    this.boxPosY = targetRect.top - boxHeight - 10;
                } else if ((targetRect.right + boxWidth) <= screenWidth) {
                    // Set box to right
                    this.boxPos = 'right';
                    this.boxPosX = targetRect.right + 10;
                    this.boxPosY = targetRect.top + ((targetRect.height - boxHeight) / 2) + this.marginTop;
                } else {
                    // Set box to left
                    this.boxPos = 'left';
                    this.boxPosX = targetRect.left - boxWidth - 10;
                    this.boxPosY = targetRect.top + ((targetRect.height - boxHeight) / 2) + this.marginTop;
                }

                if (this.boxPosX<0) this.boxPosX = this.marginLeft;
                if ((this.boxPosX + boxWidth) > screenWidth) this.boxPosX = screenWidth - boxWidth - (this.marginRight || 0);
                if (this.boxPosY<0) this.boxPosY = this.marginTop;
                if ((this.boxPosY + boxHeight) > screenHeight) this.boxPosY = screenHeight - boxHeight - this.marginBottom;


                let getTailPosX:Function = ()=>{
                    let minRight:number = Math.min(this.boxPosX+boxWidth, targetRect.right);
                    let maxLeft:number = Math.max(this.boxPosX, targetRect.left);
                    return (minRight - maxLeft) / 2 + maxLeft - 6 - this.boxPosX;
                };
                //Set the tail
                if (this.boxPos=='bottom') {
                    //this.tailPosX = targetRect.width / 2 + targetRect.left - this.boxPosX - 9.9;
                    this.tailPosX = getTailPosX();
                    this.tailPosY = -7;
                } else if (this.boxPos=='top') {
                    //this.tailPosX = targetRect.width / 2 + targetRect.left - this.boxPosX - 9.9;
                    this.tailPosX = getTailPosX();
                    this.tailPosY = boxHeight - 7;
                } else if (this.boxPos=='left') {
                    this.tailPosX = boxWidth - 7;
                    this.tailPosY = targetRect.height / 2 + targetRect.top - this.boxPosY - 9.9;
                } else if (this.boxPos=='right') {
                    this.tailPosX = -7;
                    this.tailPosY = targetRect.height / 2 + targetRect.top - this.boxPosY - 9.9;
                }

            }
        },10);
    }
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------

}
