import { ByteArray } from "openfl";
import { DrawingBase } from "./DrawingBase";
import { SmoothDrawingType } from "./SmoothDrawingType";


export class SmoothDrawing extends DrawingBase
{
	// private lastMidPoint:any;
	public endPath:string;
	constructor()
	{
		super();
	}

	setup(dataType:number, thickness:number, color:number, alpha:number):DrawingBase{
		super.setup(dataType, thickness, color, alpha);
		return this;
	}
	protected reset():void
	{
		super.reset();
		this.endPath = "";
	}

	public addPoint(point:any, savePoint:boolean = true):string {
		// SmoothLineDrawing Line Drawing
		if(savePoint)
		{ // pop last segment if necessary
			if(this.pts.length > 1)
			{
				var d1 = this.distance(this.pts[this.pts.length - 1], point);
				if(d1 < this.minD)
				{
					var d2 = this.distance(this.pts[this.pts.length - 2], this.pts[this.pts.length - 1]);
					if(d2 < this.minD)
					{
						this.pathArray.pop();
						this.pts.pop();
					}
				}
			}
		}
		this.lastPoint = this.pts.length ? this.pts[this.pts.length - 1] : null;
		// console.log("lastP 2 ", lastP);
		if(savePoint)
			this.pts.push(point);
		if(this.pts.length <= 1)
		{
			if(this.dataType == SmoothDrawingType.DT_EPEN)
			{
				this.pathArray = [`M${point.x} ${point.y}`];
			}	
		} else {

			var midPoint:any = {x:(this.lastPoint.x+point.x)/2, y:(this.lastPoint.y+point.y)/2};
			if(this.dataType == SmoothDrawingType.DT_EPEN) {
				// 用線連點
				//this.path += " L"+point.x+" "+point.y;
				// 用曲線連點
				this.pathArray.push(` Q${this.lastPoint.x} ${this.lastPoint.y} ${midPoint.x} ${midPoint.y}`);
				this.endPath = `L${point.x} ${point.y}`;
				/*
				this.path += " S"+(this.lastPoint.x)+" "+(this.lastPoint.y)+" "+
					(midPoint.x)+" "+(midPoint.y);
				*/
				// this.lastMidPoint = midPoint;
			}
		}
		this.path = this.pathArray.join(" ") + " "+ this.endPath;
		this.lastPoint = point;
		return this.path;
	}

	public fromBytes(typeID:number, ba:ByteArray):DrawingBase {
		var color:number = ba.readUnsignedInt(); // 4 bytes
		var alpha:number = ((color >> 24) & 0xff) / 255;
		var thickness:number = ba.readFloat(); // 4 bytes
		if (thickness >= 8) alpha = 0.5;
		var len:number = ba.readUnsignedInt(); // 4 bytes
		
		var sm:SmoothDrawing = this;
		sm.setup(typeID, thickness, color & 0xffffff, alpha);
		// var sm:SmoothDrawing = new SmoothDrawing(typeID, thickness, color & 0xffffff, alpha);
		var p:any = {};
		p.x = ba.readInt();
		p.y = ba.readInt();
		sm.addPoint(p);
		for (var i:number = 1; i < len; i++) {
			var tx:number = ba.readInt();
			var ty:number = ba.readInt();
			var p2:any = {x:p.x + tx, y:p.y + ty};
			sm.addPoint(p2);
			p = p2;
		}
		sm.dataID = 0;
		return sm;
	}

}