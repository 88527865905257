import { Injectable, Output, EventEmitter, NgZone } from '@angular/core';
import { resolve } from 'path';
import { DataService } from 'src/app/service/data.service';
import { catchError, throwIfEmpty, timeout } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class SpeedTestService {
	private upload: EventEmitter<any> = new EventEmitter();
	private download: EventEmitter<any> = new EventEmitter();
	public reqs:any[] = [];
	public chartInstance:any[] = [];
	constructor(private datas: DataService, private zone: NgZone) {

	}

	testRunning(updown, res, item) {
		if (!item[updown + 'Started']){
			return;
		}
		const now = Date.now();
		let percent = 0.0;
		let Bps = 0;
		let mbit = 0;
		let eta = 0;
		let total = res.total;
		let diff = 0;
		if (updown === 'upload') {
			total = item.binaryData ? item.binaryData.size : total;
		}

		if (res.lengthComputable && total && item[updown+'Status'] == null) {
			diff = (now - item.start) / 1000;
			Bps = res.loaded / diff;
			mbit = Bps / 1024 / 1024 * 8;
			item[updown] = Math.round(mbit * 10) / 10;
			item[updown] = item[updown] > 100?Math.round(item[updown]):item[updown];
			item[updown + 'Queue'] = [...item[updown + 'Queue'], mbit];
			item[updown + 'Queue'] = item[updown+'Queue'].filter((e,i)=> i < 5);
			if (item[updown + 'Chart']) {
				item[updown + 'Chart'].data.datasets[0].data = item[updown + 'Queue'];
				try { item[updown + 'Chart'].update(); } catch (e) { }
			}
		}

	}

	testDownload(item) {
		return new Promise((resolve, reject) => {
			item.req = new XMLHttpRequest;
			this.reqs.push(item.req);
			item.start = Date.now();
			item.testStatus = 'downloading';

			item.req.onprogress = (res) => {
				this.zone.run(()=>{
					if (item.downloadStarted && item.downloadStatus == null){
						this.testRunning('download', res, item);
					}
				});
			};

			item.req.onreadystatechange = (res) => {
				this.zone.run(()=>{
					if (item.req) {
						if (item.req.readyState === 4 && item.downloadStarted) {
							item.binaryData = item.req.response;
							resolve(item);
						}
					}
				});
			};
			item.req.onerror = (err)=>{
				this.zone.run(()=>{
					if (item.downloadStarted){
						reject(err);
					}
				});
			}
			let ts = +new Date();
			let downloadUrl = item.downloadUrl + (item.downloadUrl.indexOf('?')>-1?'&ts=':'?ts=') + ts;
			item.req.open('GET', downloadUrl, true);
			item.req.responseType = 'blob';
			item.req.send(null);
			setTimeout(()=>{
				this.zone.run(()=>{
					reject('timeout');
				});
			}, 20000);
		});
	}

	testUpload(item) {
		return new Promise((resolve, reject) => {
			if (item.req) {
				item.req.abort();
			}
			if (!item.uploadStarted){
				return;
			}
			item.req = new XMLHttpRequest;
			this.reqs.push(item.req);
			item.start = Date.now();
			item.testStatus = 'uploading';
			if (item.req.upload) {
				item.req.upload.onprogress = (res) => {
					if (item.uploadStatus == null && item.uploadStarted){
						this.testRunning('upload', res, item);
					}
				};

				item.req.upload.onloadend = (res) => {
					resolve(item);
				};
			} else {
				item.req.onprogress = (res) => {
					if (item.uploadStatus == null && item.uploadStarted){
						this.testRunning('upload', res, item);
					}
				};

				item.req.onreadystatechange = onreadystatechange = (res) => {
					if (item.req) {
						if (item.req.readyState === 4 && item.uploadStarted) {
							this.testDone('upload', res, item);
						}
					}
				};
			}
			item.req.onerror = (err)=>{
				if (item.uploadStarted){
					// item.uploadStatus = 'fail';
					reject(err);
				}
			};
			let ts = +new Date();
			let uploadUrl = item.uploadUrl + (item.uploadUrl.indexOf('?')>-1?'&ts=':'?ts=') + ts;
			item.req.open('POST', uploadUrl, true);
			item.req.send(item.binaryData);
			setTimeout(()=>{
				this.zone.run(()=>{
					reject('timeout');
				});
			}, 20000);
		});
	}

	testDone(updown, res, item) {
		return new Promise((resolve, reject) => {
			const diff = (Date.now() - item.start) / 1000;

			if (updown === 'download') {
				item.binaryData = item.req.response;

				this.testUpload(item).then(() => {
					setTimeout(() => {
						resolve(item);
					}, 100);
				});
			}
			else {
				item.binaryData = null;
				item.req = null;
				resolve(item);
			}
		});
	}

	cancelAllReq(){
		this.reqs.forEach(r=>{
			r.abort();
		});
		this.reqs = [];
	}


}
