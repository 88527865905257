import { Component, Input } from "@angular/core";
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";

@Component({
  selector: "qb-set-filter",
  templateUrl: "./QuestionBankCustomFilter.component.html",
  styleUrls: ['./QuestionBankCustomFilter.component.scss'],
})
export class QBSetFilterComponent implements IFilterAngularComp {
    // data source (options from server) 
	public params: IFilterParams;
	public dataArray: Array<string> = [];
    public formattedResult: object = {};
    public fetchOptions:object;
    
    // source after filter 
    public filterArray: Array<string> = [];
	public filterItems: Array<string> = [];
	public isEverythingSelected: boolean = true;
    public inputValue: string
    public isNoMatches: boolean = false;
	
	agInit(params: IFilterParams): void {
		this.params = params;
        this.setUpData()
	}

    setUpData() {
        this.fetchOptions = this.params.colDef.filterParams.fetchOptions()
        this.params.colDef.filterParams.values({
			colDef: this.params.colDef, 
			success: this.success.bind(this)
		})
    }

	// get the custom values to the filter array
	success(res: []) {
		res.sort((a, b) => {
            if (a === null && b === null) {
                return 0
            } else if (a === null) {
                return -1
            } else if (b === null) {
                return 1
            } else {
                return (a as string).localeCompare(b as string)
            }
		})
		this.dataArray = [...res]
        this.filterArray = [...res]
		this.filterItems = [...res]
        this.formatData()
	}

	isChecked(item: string) {
		return this.filterItems.includes(item)
	}

	toggleItem(value: string) {
		if (this.filterItems.includes(value)) {
			const index = this.filterItems.indexOf(value)
			if (index > -1) {
				this.filterItems.splice(index, 1)
			}
		} else {
			this.filterItems.push(value)
		}

		this.onFilterChanged();
	}

	toggleAll() {
		this.isEverythingSelected = !this.isEverythingSelected;
		if (this.isEverythingSelected) {
			this.filterItems = [...this.dataArray]
		} else {
			this.filterItems = []
		}
		this.onFilterChanged();
	}

    formatData() {
       this.formattedResult = this.dataArray.reduce((acc, item) => {
            const formattedValue = this.params.colDef.filterParams.valueFormatter({
                value: item
            })
            acc[item] = formattedValue
            return acc
        }, {})
    }

    debounce(func, delay) {
        let timerId
        return function (...args) {
            clearTimeout(timerId)

            timerId = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        }
    }

    debouncedHandleKeyPress = this.debounce((event: KeyboardEvent) => {
        const inputValue = this.inputValue.trim()
        const isInputEmpty = inputValue == ""

        if (isInputEmpty) {
            // when input clear to empty status, show all the options
            this.filterArray = [...this.dataArray]
        } else {
            // filter the option based on the input values
            this.filterArray = this.dataArray.filter((item) => {
                const formattedResult = this.formattedResult[item]
                return (
                    formattedResult !== null && 
                    formattedResult.includes(inputValue)
                )
            })
            // display no matches text
            this.isNoMatches = this.filterArray.length === 0
        }

        // choose the options based on miniFilter or enter
        const shouldApplyMiniFilter = this.params.colDef.filterParams.applyMiniFilterWhileTyping
        if (shouldApplyMiniFilter || event.key == "Enter") {
            this.filterItems = isInputEmpty ? [...this.dataArray] : [...this.filterArray]
            this.onFilterChanged()
        }
    }, 0)

	onFilterChanged() {
	    this.params.colDef.filterParams.gridFilterChange({
            api: this.params.api, 
            filterValue: this.filterItems, 
            isAll: this.filterItems.length == this.dataArray.length
        })
        this.formatData()
	  	this.params.filterChangedCallback();
	}

    onNewRowsLoaded() {
        const newFetchOptions = this.params.colDef.filterParams.fetchOptions()
        const isSameOptions = JSON.stringify(this.fetchOptions) === JSON.stringify(newFetchOptions)
        if (!isSameOptions) {
            this.setUpData()
        }
    }

    isFilterActive(): boolean {
        return true;
    }
    
    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return true
    }  
  
	setModel(item:any) {
        return this.filterItems = item.values
	}

    getModel() {
        return this.filterItems
	}
  
	destroy() {
	}
}
