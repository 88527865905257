import { AfterViewInit, Component, ViewChild } from '@angular/core';

@Component({
  selector: 'ring-loading',
  template: `
    <!-- <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> -->
    <canvas #ring width="32" height="32">
  `,
  styleUrls: ['./ring-loading.component.scss']
})

export class RingLoadingComponent implements AfterViewInit{
  @ViewChild('ring',{static: false}) ring;
  constructor() {

  }

  ngAfterViewInit(){
    let c:any = this.ring.nativeElement;
    let ctx:any = c.getContext("2d");
    ctx.beginPath();
    ctx.arc(16, 16, 10, 1, 2 * Math.PI);
    ctx.stroke();
  }
}
