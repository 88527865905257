import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { UploadService } from './upload.service';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'upload-button',
	template: `
<div class="filename" (click)="previewClick(file)">
	<span *ngIf="file">{{file.originFilename}}</span>
</div>
<div class="not-filled" *ngIf="triedSubmitted && !file">{{'course.pls-press-right-upload'|translate}}</div>
<div class="trash" [class.v-hidden]="file == null" (click)="trashClick()">
	<fa-icon [icon]="faTrash"></fa-icon>
</div>
<div class="upload" (click)="uploadClick()">{{'mediaLibrary.upload'|translate}}</div>
<preview #preview></preview>
`
	, styleUrls: ['./upload-button.component.scss'],
})
export class UploadButton implements OnInit, OnDestroy, AfterViewInit {
	@Input() public file: any;
	@Input() public options: any;
	@Input() public triedSubmitted = false;
	@Output('') public change = new EventEmitter<any>();
	@ViewChild('preview', { static: false }) preview;
	public faTrash = faTrash;
	constructor(private upload: UploadService) {
	}

	ngOnInit() {

	}

	ngOnDestroy() {

	}

	ngAfterViewInit(): void {

	}

	uploadClick() {
		let options = this.options?this.options:{};
		this.upload.upload(options).then(data=>{
			console.log(data);
			if (data){
				this.file = data;
				this.change.emit(data);
			}
		});
	}

	trashClick(){
		this.file = null;
		this.change.emit();
	}

	previewClick(file) {		
		let serverUrl = '//oka.blob.core.windows.net/media/';
		if (file.url.indexOf('tmp_upload') > -1){
			if (location.port == ''){
				serverUrl = '//' + location.hostname + '/RainbowOne/';
			} else {
				serverUrl = '//dev.openknowledge.hk/RainbowOne/';
			}
		}
		this.preview.setUrlPrefix(serverUrl);
		const ext = file.originFilename.split('.')[file.originFilename.split('.').length - 1];
		let fileObj = { name: file.originFilename, url: file.url, ext: ext };
		this.preview.setFileList([fileObj]);
		this.preview.open();
	}

}