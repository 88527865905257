import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ScriptService } from 'src/app/service/script.service';
import { StorageService } from 'src/app/service/storage.service';
import { ThemeService } from 'src/app/service/theme.service';
import { faDownToBracket } from '@fortawesome/pro-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'audioPlayer',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss']
})

export class AudioComponent implements OnInit {
    @ViewChild('audio', { static: false }) audio: any
    @ViewChild("timeline", { static: false }) timeline: any
    @ViewChild("tracktime", { static: false }) tracktime: any
    @ViewChild("tracktime2", { static: false }) tracktime2: any
    @ViewChild("downloadBtn", { static: false }) downloadBtn: any
    @Input() audio_src: any
    @Input() timeline_width: string = "200px"
    public faDownToBracket = faDownToBracket
    public audio_playing: boolean = false
    public file_name: string
    constructor(private sanitizer: DomSanitizer, public theme: ThemeService, public eleRef: ElementRef, public script: ScriptService, public storage: StorageService) {

    }
    ngOnInit(): void {
        if (this.storage.role == undefined) {
            console.warn("storage.role is needed!!!!!!!");
            this.storage.role = 2
        }
        this.file_name = this.audio_src.split("/")
        this.file_name = this.file_name[this.file_name.length - 1]
        this.init_audio()
        this.theme.applyStyleObj({
            "default": {
                "color-333-fff": "#333",
            },
            "dark": {
                "color-333-fff": "#fff"
            }
        }, this.eleRef.nativeElement);
    }

    convertHMS(value) {
        const sec = parseInt(value, 10);
        let hours: any = Math.floor(sec / 3600);
        let minutes: any = Math.floor((sec - (hours * 3600)) / 60);
        let seconds: any = sec - (hours * 3600) - (minutes * 60);
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        if (hours < 1) {
            return minutes + ':' + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    }

    init_audio() {
        setTimeout(() => {
            console.log(this.audio.nativeElement);
            this.audio.nativeElement.oncanplay = () => {
                this.tracktime.nativeElement.innerHTML = this.convertHMS(this.audio.nativeElement.currentTime)
                this.tracktime2.nativeElement.innerHTML = ' / ' + this.convertHMS(this.audio.nativeElement.duration)
            }

            this.timeline.nativeElement.addEventListener('change', () => {
                this.audio.nativeElement.currentTime = (this.timeline.nativeElement.value * this.audio.nativeElement.duration) / 100;
                console.log(this.audio.nativeElement.currentTime);
            });
            this.timeline.nativeElement.onmousedown = () => {
                this.audio.nativeElement.pause()
                this.audio_playing = false
                this.timeline.nativeElement.onmousemove = () => {
                    this.timeline.nativeElement.style.backgroundSize = `${this.timeline.nativeElement.value}% 100%`
                    this.tracktime.nativeElement.innerHTML = this.convertHMS(this.timeline.nativeElement.value / 100 * this.audio.nativeElement.duration)
                }
                this.timeline.nativeElement.onmouseup = () => {
                    this.timeline.nativeElement.onmousemove = null
                }
            }

            this.audio.nativeElement.onended = () => {
                this.audio_playing = false
            }
            this.audio.nativeElement.ontimeupdate = () => {
                const percentagePosition = (100 * this.audio.nativeElement.currentTime) / this.audio.nativeElement.duration;
                this.timeline.nativeElement.style.backgroundSize = `${percentagePosition}% 100%`;
                this.timeline.nativeElement.value = percentagePosition;
                this.tracktime.nativeElement.innerHTML = this.convertHMS(this.audio.nativeElement.currentTime)
            }
        }, 0);
    }
    play() {
        if (this.audio.nativeElement.paused) {
            this.audio.nativeElement.play();
            this.audio_playing = true
        } else {
            this.audio_playing = false
            this.audio.nativeElement.pause();
        }
    }
    download() {
        const getFileBlob = (url) => {
            return new Promise((resolve) => {
                const xhr = new XMLHttpRequest()
                xhr.open('GET', url, true)
                xhr.responseType = 'blob'
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response)
                    }
                }
                xhr.send()
            })
        }
        const saveAsFile = (blob, filename) => {
            if (window.navigator['msSaveOrOpenBlob']) {
                console.log(navigator);
                navigator.msSaveBlob(blob, filename)
            }
            else {
                const link = document.createElement('a')
                const body = document.querySelector('body')
                link.href = window.URL.createObjectURL(blob) // 创建对象url
                link.download = filename
                // fix Firefox
                link.style.display = 'none'
                body.appendChild(link)
                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href) // 通过调用 URL.createObjectURL() 创建的 URL 对象
            }
        }
        getFileBlob(this.audio_src).then((blob) => {
            saveAsFile(blob, this.file_name)
        })
    }
}