import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { LoadingService } from '../loadingModule/loading.service';
import { AlertService } from 'src/app/service/alert.service';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'book-finish-modal',
	template: `
		<div class="book-finish-modal {{theme.theme$.value}}-theme" *ngIf="ready">
			<svg-icon class="close {{theme.theme$.value}}-theme" name="btn_close_gray" (click)="cancelModal()"></svg-icon>
			<div class="photo {{data.book_type?data.book_type:''}}" [style.backgroundImage]="photo"></div>
			<div class="name">
				<span>{{data.name}}</span>
			</div>
			<div class="label">{{ {tc:'已完成',sc:'已完成',en:'Complete'}[lang] }}</div>
			<div class="message {{theme.theme$.value}}-theme">{{ message }}</div>
			<div class="choice {{theme.theme$.value}}-theme" *ngFor="let choice of choices" (click)="choiceClick(choice)">
				<div class="choice__topic {{datas.lang}}">{{choice.topic|translate}}</div>
				<div class="choice__text {{datas.lang}}">{{choice.text|translate}}</div>
			</div>
		</div>
  `
	, styleUrls: ['./bookFinish.modal.scss'],
})
export class BookFinishModal implements OnInit {
	@Input() data: any;
	@Output() openBook = new EventEmitter<any>();
	@Output() close = new EventEmitter<any>();
	public message = '';
	public choices;
	public photo;
	public ready = false;
	public lang;
	constructor(private translate: TranslateService, private sanitizer: DomSanitizer, public datas: DataService, private ro: RoService, private lds: LoadingService, private als: AlertService, private inlineSvg: InlineSvgService, public theme: ThemeService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.message = {tc: '請選擇使用模式:', sc: '请选择使用模式:', en: 'Please choose a mode to use:'}[this.lang];
		this.inlineSvg.regSvgFiles([
			{url: 'btn_close_gray.svg', name: 'btn_close_gray'},
			]).then(()=>{
			if (this.data.cover){
				this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + this.data.cover + "')");
				this.ready = true;
			} else {
				this.datas.post('ROBookShelf.get_single_book_cover2', [this.data.bsid, this.data.book_id]).subscribe((res: any) => {
					if (res.code) {
						this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + res.url + "')");
						this.ready = true;
					}
				});
			}
		});
		this.initChoices(this.data);
	}

	initChoices(data) {
		this.choices = [
			{ id: 1, topic: 'open-book.finish-1-1', text: 'open-book.finish-1-2' },//查看初次提交或改正後之作答，但不可修改答案。
			{ id: 4, topic: 'open-book.finish-2-1', text: 'open-book.finish-2-2' },//重新作答所有題目，答案不會傳給老師。
		];
	}

	choiceClick(choice) {
		if (choice.id == 1) { //read only
			this.openBook.emit({ mode: 'read-index-1' });
		} else if (choice.id == 2) { //clear answer then read
			this.writeReadLog();
			this.readStart();
		} else if (choice.id == 4){
			this.writeReadLog();
			this.openBook.emit({ mode: 'clean-redo' });
		} else { //normal open
			this.writeReadLog();
			this.openBook.emit();
		}
	}

	readStart() {
		let shareId = this.data.bsid ? this.data.bsid : 0;
		let bookId = this.data.bid ? this.data.bid : 0;
		this.lds.add('clear_my_book_answer');
		this.datas.post('ROBookShare.clear_my_book_answer', [shareId, bookId]).subscribe((res: any) => {
			this.lds.remove('clear_my_book_answer');
			if (res.code !== null) {
				this.openBook.emit();
			}
		});
	}

	writeReadLog() {
		this.datas.post('ROBookshelfKen.logSectionVisit', ['read']).subscribe();
	}

	public cancelModal() {
		this.close.emit();
	}

}
