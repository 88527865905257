import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Base64ObjectUtils, ROComponent, ROPageComponent } from "../ROComponent";
import { RecorderAndPlayer } from "../SoundRecorder";
import { DataService } from "src/app/service/data.service";
import { UploadService } from "../../uploadModule/upload.service";
import { AlertService } from "src/app/service/alert.service";
import { WhitePopupService } from "../../whitePopupModule/whitePopup.service";
import { ROBookConfig } from "../ROBookConfig";
import { CommonService } from "src/app/service/common.service";
import { speechPracticeInstruction } from "./instruction";
import { speechPracticeHeader } from "./header";
import { speechPracticeFinalReport } from "./finalReport";
import { conversationPractice } from "./conversationPractice";
import { exercisePractice } from "./exercisePractice";
import { ChatService } from "src/app/service/chatGPT.service";

@Component({
	selector: 'html-container2',
	template: `
		<div #content class="html-container"></div>
	`
})

export class HTMLContainer2 implements OnInit, AfterViewInit, OnChanges {
	@ViewChild('content', { static: false }) public content: any;
	@Input() public html: any;

	constructor() { }

	ngOnChanges(changes: SimpleChanges): void { this.updateHTML() }

	ngOnInit(): void { }

	ngAfterViewInit(): void { this.updateHTML() }

	private updateHTML(): void {
		if (this.content) this.content.nativeElement.innerHTML = this.html;
	}
}

/////

@Component({
	selector: 'speechPracticeROSTT',
	templateUrl: './speechPracticeROSTT.html',
	styleUrls: ['./speechPracticeROSTT.scss'],
})

export class speechPracticeROSTT extends ROComponent {
	@ViewChild('instruction', { static: false }) instructionContainer: speechPracticeInstruction;
	@ViewChild('header', { static: false }) headerContainer: speechPracticeHeader;
	@ViewChild('exercisePractice', { static: false }) exercisePracticeContainer: exercisePractice;
	@ViewChild('conversationPractice', { static: false }) conversationPracticeContainer: conversationPractice;
	@ViewChild('finalResult', { static: false }) finalResultContainer: speechPracticeFinalReport;
	/////////////////////////////////////////
	//       Original config variable      //
	/////////////////////////////////////////
	public myRecorder: RecorderAndPlayer;
	public processing: boolean;
	private fileReference: any;
	public ctx: any;

	/////////////////////////////////////////
	//       Initialize data variable      //
	/////////////////////////////////////////
	public bookId;
	public bookIdForDemo = [
		'2868218', '2868222', '2868224', '2868226', '2868228', '2868230', '2868247', '2868251', '2868261', '2868265', '2868281',
		'2868283', '2868285', '2868288', '2868303', '2868305', '2868307', '2868309', '2868311', '2868313', '2868315', '2878880',
		'2902759', '2902762', '2902769', '2902772', '2973103', '2973105', '2973111', '2973113', '2973117', '2973306'
	]
	public dataForAIOral: any = { bsid: "", book_id: "" };
	public progressPercent = "0%";
	public questionNumber = 1;
	public bookConfig;
	public viewMode;
	public backgroundUrl;
	public fontSize = 'N';
	public lang = this.dataService.lang;					// lang for translateText()

	// Data from book structure
	public bookStructure;
	public totalStarNumber;
	public mainType;										// 練習形式 / 對話形式
	public subType;											// 題型
	public exercisePractice = ["word", "sentence", "fillIn", "mc", "paragraph", "aiOral"];
	public conversationPractice = ["oralVocab", "oralSentence", "oralFillIn", "oralMc", "oralParagraph", "oralAI"];
	public difficulty;
	public bookTitle;
	public pageId;
	public totalPageNumber = 0;
	public pageIndex = 0;
	public introduction = "";
	public displayTopic;									// 顯示題目
	public fillInTypeTopic = [];							// 填充題題目				
	public answerChoice = [];								// 可選取答案	(唯一 / 多項)
	public correctAnswer;									// 正確答案
	public answerWithoutContractions;
	public userTotalScore;

	// Data after recording
	public recordTimer;
	public myData;
	public accuracyScore;									// 準確分數
	public completenessScore;								// 完整分數
	public fluencyScore;									// 流暢分數
	public confidence;										// 測試準確度
	public pronScore;										// 發音總分
	public userRecordAnswer;								// 作答後user答案
	public recognitionAnswer = [];							// 作答後辨識結果
	public recognitionWordArray = [];
	public foulLanguage = [];

	// Controlling state
	public isInstruction = true;							// true -> show Instruction Page
	public isInput = false;
	public isRecorded = false;								// true -> show user answer (compare to correct answer)
	public isFinalReportOpen = false;
	public isOralVocabReportOpen = false;
	public aiOralfinalScore = {
		content: 10,
		grammar: 10,
		creativity: 10,
	};

	public conversationData;
	public EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0];
	public conversationFinish

	public contractions = [
		{ label: "'m", value: " am" },
		{ label: "'s", value: " is" },
		{ label: "'re", value: " are" },
		{ label: "'ll", value: " will" },
		{ label: "'ve", value: " have" },
		{ label: "'d", value: " would" },
		{ label: "n't", value: " not" },
	]

	// 成績表Result
	public totalScoreInEachQuestion = 10;

	// Reading config
	public audio: HTMLAudioElement = new Audio();
	public ttsSetting: any = {								// 朗讀題目config
		langType: "en-US",
		preprocess: "0",
		pitch: 1,
		speed: 1,
		voice: "en-US-CoraNeural",
		speakingStyle: "general",
		gender: "F"
	};
	public downloadUrl;

	constructor(
		cdr: ChangeDetectorRef,
		elementRef: ElementRef,
		public dataService: DataService,
		public upload: UploadService,
		public alert: AlertService,
		private whitePopupService: WhitePopupService,
		public coms: CommonService,
		public chat: ChatService
	) { super(cdr, elementRef) }

	ngOnInit() {
		let config: ROBookConfig = this.context.config;
		this.bookConfig = this.context.config;
		this.viewMode = this.context.config.viewMode;
		if (config.share.created_by.uid === this.dataService.userInfo.uid || this.dataService.userInfo.user_role == 3) {
			this.isInstruction = false;
		}
		this.bookId = config.book.id;
		this.loadBookStructure();
		this.findEnrollmentId(config.share.id)
		this.dataForAIOral = { bsid: config.share.id, book_id: this.bookId };
		if (location.href.indexOf('/review') > -1) {
			this.dataForAIOral.readonly = true;
			this.dataForAIOral.status = 'read-only';
		}
		this.bookTitle = config.book.title;
		this.conversationFinish = false;
	}

	/////////////////////////////////////////
	//       Load BookViewer Content       //
	/////////////////////////////////////////
	protected buildContent(): void {
		this.myRecorder = new RecorderAndPlayer(this.context.service.fileIO);
		let options: any = this.node.attributes;
		this.ctx = Base64ObjectUtils.base64Decode(options.context);
		if (this.ctx.backgroundImg) this.backgroundUrl = `url('${this.ctx.backgroundImg}')`;
		if (this.ctx.fontSize) this.fontSize = this.ctx.fontSize;
		if (['N', 'S', 'L'].indexOf(this.fontSize) == -1) {
			this.fontSize = 'N';
		}
	}

	// Submitted answer data
	public set data(input: string) {
		this.myRecorder.reset();
		this.defaultAnswer = null;
		this.answerChanged = false;
		if (input == null) {
			this.myData = {};
			return;
		} else {
			this.isInput = true;
		}

		try {
			this.myData = this.stringToObject(input);
			console.log(this.myData)

			if (this.exercisePractice.includes(this.subType)) {
				console.log("hi")
				let decodeResult = atob(this.myData.record);
				let uriDecodeResult = decodeURIComponent(decodeResult);
				let jsonResult = JSON.parse(uriDecodeResult);

				if (this.myData && jsonResult) {
					console.log(this.myData)
					this.isRecorded = this.myData.isRecorded;
					this.isInstruction = this.myData.isInstruction;

					this.accuracyScore = jsonResult.AccuracyScore;
					this.completenessScore = jsonResult.CompletenessScore;
					this.fluencyScore = jsonResult.FluencyScore;
					this.confidence = Math.round(jsonResult.Confidence * 100);
					this.pronScore = Math.round(jsonResult.PronScore);
					this.userRecordAnswer = this.myData.userRecordAnswer;
				}

				if (this.myData.mainType) this.mainType = this.myData.mainType;
				if (this.myData.subType) this.subType = this.myData.subType;

				if (jsonResult.Words.length > 1) {
					this.recognitionAnswer = jsonResult.Words;
					this.recognitionWordArray = [];
					for (let word of this.recognitionAnswer) {
						this.recognitionWordArray.push(word)
					}
				} else {
					this.recognitionWordArray = [];
					this.recognitionWordArray.push({ Word: this.myData.text, AccuracyScore: jsonResult.AccuracyScore })
				}
				let config: ROBookConfig = this.context.config;
				let pageWithScore = Object.values(config.dataSource.answerMap)
					.filter((item: any) => item.tag === "STT");
				if (pageWithScore) this.isInstruction = false;
			} else {
				console.log("hi")
				let decodeResult = atob(this.myData.conversationRecord);
				let uriDecodeResult = decodeURIComponent(decodeResult);
				let jsonResult = JSON.parse(uriDecodeResult);
	
				this.myData.conversationRecord = jsonResult;
				this.conversationFinish = this.myData.conversationFinish;
			}
		} catch (err) {
			console.log(err);
		}
	}

	// Book Structure config
	async loadBookStructure() {
		let data: FormData = new FormData();
		data.append("api", "SpeechPractice.loadBookStructure");
		data.append("json", JSON.stringify([this.bookId]));

		try {
			const res = await this.dataService.post2({ data: data, loading: true });
			let result = res.result.structure.replace(/\\/g, "");
			let object = JSON.parse(result);
			console.log(object)
			this.bookStructure = object;

			/////////////////////////////////////////
			//           Required config           //
			/////////////////////////////////////////
			// type
			this.subType = res.subType;
			if (!this.conversationPractice.includes(this.subType)) {
				this.totalPageNumber = this.context.config.pages.length;
				this.pageIndex = this.context.config.pageIndex;
				this.progressPercent = `${(this.pageIndex + 1) / this.totalPageNumber * 100}%`;
			}

			// difficulty
			this.difficulty = object.book.chapters[0].difficulty
			// page id
			let config: ROBookConfig = this.context.config;
			let pageIdArray = object.book.chapters[0].pages.map((page: any) => page.id)
			this.pageId = pageIdArray[config.pageIndex]

			object.book.chapters[0].pages.map((page: any) => {
				if (page.id === this.pageId) {
					// displayTopic / fillInTypeTopic
					if (this.subType === "fillIn") {
						const split = page.displayText.split("(");
						let firstPartReadingText = split[0].trim();
						let secondPartReadingText = split[1].split(")")[1].trim();
						this.fillInTypeTopic = [firstPartReadingText, "fill", secondPartReadingText];
						this.displayTopic = "";
						console.log(this.fillInTypeTopic)
					} else {
						this.displayTopic = page.displayText
					}
					// ttsSetting.text
					if (this.difficulty === "easy") {
						let readingTextWithAnswer = page.displayText.replace(/\(\s*\)/, `${page.correctAnswer}`);
						this.ttsSetting["text"] = [readingTextWithAnswer];
					} else {
						if (this.subType === "fillIn") {
							const split = page.displayText.split("(");
							let firstPartReadingText = split[0].trim();
							let secondPartReadingText = split[1].split(")")[1].trim();
							this.ttsSetting["text"] = [firstPartReadingText, secondPartReadingText];
						}
					}
					// answerChoice
					page.answerChoice.map((item) => {
						this.answerChoice.push({ label: item, value: item.slice(0, -1) })
					})
					// correctAnswer
					if (this.subType === "word" || this.subType === "fillIn" || this.subType === "oralVocab") {
						this.correctAnswer = page.correctAnswer;
					} else {
						this.correctAnswer = page.correctAnswer.slice(0, -1);
					}
					console.log("correctAnswer: ", this.correctAnswer);
				}
			}
			)
		} catch (err) {
			console.log(err)
		}
	}

	async findEnrollmentId(bsid) {
		let data: FormData = new FormData();
		data.append("api", "SpeechPractice.findEnrollmentId");
		data.append("json", JSON.stringify([bsid]));

		try {
			const res = await this.dataService.post2({ data: data, loading: true });
			let enrollmentId = res.result.enrollment_id
			this.dataService.post('ROEnroll.get_enrollment_details_for_map', [enrollmentId]).subscribe((res: any) => {
				console.log(res)
				res.course_summary ? res.course_summary.star ? this.totalStarNumber = res.course_summary.star : this.totalStarNumber = 0 : this.totalStarNumber = 0;
			})
		} catch (error) {
			console.log(error)
		}
	}

	nextQuestion() {
		this.progressPercent = `${(this.pageIndex + 1) / this.totalPageNumber}%`;
		this.questionNumber += 1;
		this.context.subject.next({
			type: "action",
			action: "nextQuestion",
		});
	}

	speak() {
		if (this.ttsSetting.text) {
			let data: any = {
				gender: this.ttsSetting.gender,
				speed: this.ttsSetting.speed,
				lang: this.ttsSetting.langType,
				pitch: this.ttsSetting.pitch,
				name: this.ttsSetting.voice,
				style: this.ttsSetting.speakingStyle,
			};
			let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";

			this.ttsSetting.text.forEach((item, index, array) => {
				setTimeout(() => {
					data["txt"] = item;
					this.dataService.get2({ url: url, data: data, jwt: false }).then((res: any) => {
						if (res.token && res.token.url) {
							let statusUrl: string = res.token.url;
							if (statusUrl.substr(0, 7) == "http://") statusUrl = "https://" + statusUrl.substring(7);
							this.dataService.get2({ url: statusUrl, jwt: false }).then((res: any) => {
								if (res.url) {
									this.audio.setAttribute("src", res.url);
									this.audio.play();
								}
							});
						}
					});
				}, index * 3500);
			})
		} else {
			this.alert.toastError(this.translateText("此難度不會提供朗讀示範", "此难度不会提供朗读示范", "No reading example will be provided in Hard level"));
		}
	}

	record(): Promise<any> {
		if (!this.context.isAnswerEnabled()) {
			return Promise.reject("not allow to answer");
		}

		this.userRecordAnswer = "";

		return (
			this.myRecorder.hasRecording ?
				this.context.service.alertService.confirm("ro.component.recorder.overwrite-recording", true) :
				Promise.resolve(1)
		).then((o: any) => {
			return this.recordNow();
		}).then((data: any) => {
			this.processing = true;
			return this.processRecording(data);
		}).then(
			() => {
				this.processing = false;
			}
		).catch(
			(reason: any) => {
				this.processing = false;
				console.log("record failed", reason);
			}
		)
	}

	finishRecording() {
		this.myRecorder.finishRecording();
	}

	play() {
		if (this.myRecorder && this.myRecorder.hasRecording) {
			this.myRecorder.play();
		}
	}

	private processRecording(data: any): Promise<any> {
		let reference: string = this.context.createReference();
		let uid = this.context.service.dataService.userInfo.uid;
		console.log(data)
		if (this.myData == null) {
			this.fileReference = {
				key: this.douid,
				reference: reference,
				file: data.sound.file,
				index: 0
			};
			this.myData = {
				user: uid,
				key: this.fileReference.key,
				reference: this.fileReference.reference
			}
		} else {
			this.myData.user = uid;
			this.myData.reference = reference;
			this.fileReference = {
				key: this.douid,
				reference: reference,
				file: data.sound.file,
				index: 0
			};
		}

		this.answerChanged = true;

		return this.uploadAsset().then((res) => {
			console.log(data.sound)
			this.upload.uploadFileObject(data.sound.file).then((res) => {
				const hostname = location.href.indexOf('localhost') > -1 ? 'dev.openknowledge.hk' : location.hostname;
				let filePath = '//' + hostname + '/RainbowOne/' + res.path;

				// if(environment.production){
				// 	filePath = '//api.openknowledge.hk/RainbowOne/' + res.path;
				// }

				this.assessment(filePath);
			});
		});
	}

	async assessment(filePath: string) {
		let lang = "en-US";
		let dataWithAnswer: FormData = new FormData();
		let dataWithoutAnswer: FormData = new FormData();
		dataWithAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithoutAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithAnswer.append("json", JSON.stringify([filePath, this.correctAnswer, lang]));
		dataWithoutAnswer.append("json", JSON.stringify([filePath, "", lang]));

		try {
			let firstRecordTime = Date.now();

			const recordTimeCount = setInterval(async () => {
				this.recordTimer = Date.now();
				console.log(this.recordTimer - firstRecordTime)

				// 超時
				if (this.recordTimer - firstRecordTime > 10000 && this.isRecorded) {
					clearInterval(recordTimeCount);
					this.alert.toastError(this.translateText(
						"網絡繁忙，自動重試中，請稍候",
						"网络繁忙，自动重试中，请稍后",
						"Network latency, repeat processing automatically, result is going to release"
					));
				}
			}, 1000);


			const firstRequest = await this.dataService.post2({ data: dataWithAnswer, loading: true });
			const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: true });
			Promise.all([firstRequest, secondRequest]).then(([firstRequest, secondRequest]) => {
				if (!firstRequest.result || !secondRequest.result ||
					firstRequest.result.RecognitionStatus === "InitialSilenceTimeout" ||
					secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
					this.alert.alert2(this.translateText(
						"偵測不到聲音，請重新再試。",
						"偵測不到聲音，請重新再試。",
						"Cannot detect the sound recording, please try again"
					), null, { btns: [["ok"]] });
					clearInterval(recordTimeCount);
				} else {
					clearInterval(recordTimeCount);
					this.foulLanguageCheck(secondRequest.result.NBest[0]);
					this.userRecordAnswer = this.checking(secondRequest.result);

					console.log(this.userRecordAnswer);
					console.log(this.answerWithoutContractions);
					console.log(this.recognitionWordArray);
					console.log(this.foulLanguage);

					// 5 Scores
					this.accuracyScore = firstRequest.result.NBest[0].AccuracyScore;
					this.completenessScore = firstRequest.result.NBest[0].CompletenessScore;
					this.fluencyScore = firstRequest.result.NBest[0].FluencyScore;
					this.confidence = Math.round(firstRequest.result.NBest[0].Confidence * 100);
					this.pronScore = Math.round(firstRequest.result.NBest[0].PronScore);
					this.isRecorded = true;

					// save result into myData
					let stringifyResult = JSON.stringify(firstRequest.result.NBest[0]);
					let uriEncodeResult = encodeURIComponent(stringifyResult);
					let encodeResult = btoa(uriEncodeResult);
					this.myData.record = encodeResult;
					this.myData.userRecordAnswer = this.userRecordAnswer;
					this.myData.answerWithoutContractions = this.answerWithoutContractions;
					this.myData.isRecorded = this.isRecorded;
					if (this.foulLanguage) this.myData.foulLanguage = this.foulLanguage;

					// user & recognition answer
					// this.isInstruction = false;

					this.autoSubmit();

					if (this.subType === "sentence" || this.subType === "paragraph") {
						this.recognitionAnswer = firstRequest.result.NBest[0].Words;
						this.handleRecognitionWord();
					}
				}
			})
		} catch (err) {
			console.error("Assessment Fail", err);
		}
	}

	autoSubmit() {
		console.log(this.myData)
		this.context.subject.next({
			type: "action",
			action: "submitAfterRecord",
		});
	}

	handleRecognitionWord() {
		this.recognitionWordArray = [];

		for (let word of this.recognitionAnswer) {
			this.recognitionWordArray.push(word)
		}
	}

	foulLanguageCheck(record) {
		let isFoulLanguage;
		if (record.MaskedITN) isFoulLanguage = (record.MaskedITN.match(/\*/g) || []).length >= 2;
		if (isFoulLanguage) this.foulLanguage.push({ content: record.ITN, userId: "3872" });
	}

	checking(record) {
		console.log(record)
		let isContractions = this.contractions.some(item => this.correctAnswer.includes(item.label));
		this.answerWithoutContractions = this.correctAnswer;
		if (isContractions) {
			this.contractions.forEach(item => {
				this.answerWithoutContractions = this.answerWithoutContractions.replace(new RegExp(item.label, 'g'), item.value);
			});
		}

		let checkingNumber = parseInt(record.DisplayText.slice(0, -1))
		if (checkingNumber && record.NBest[0].AccuracyScore > this.ctx.passLevel || this.subType === "mc") {
			let capitalize = record.NBest[0].Lexical.charAt(0).toUpperCase() + record.NBest[0].Lexical.substring(1);
			return capitalize;
		} else {
			return record.DisplayText.slice(0, -1);
		}
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}

	async buttonAction(data: any) {
		console.log(data)
		if (data.action === "close") {
			if(this.subType === "oralAI"){
				try {
					const res = await this.scoreCoversation(this.conversationPracticeContainer.conversation);
					let content = JSON.parse(res).choices[0].message.content;
					if(typeof(content) === "string"){
						content = JSON.parse(content);
					}
					this.aiOralfinalScore = content;
					console.log("score obj", typeof(content),content);
				} catch (error) {
					console.log(error);
				}
			}
			this.close(data.isFinalReportOpen, data.userTotalScore)
		} else if (data.action === 'reTry' && this.subType === "paragraph") {
			this.reTry()
		} else {
			this.context.subject.next({
				type: "action",
				action: "reTry",
			});
		}
	}

	close(isFinalReportOpen: boolean, userTotalScore: number) {
		console.log(isFinalReportOpen, userTotalScore)
		if (isFinalReportOpen) {
			this.isFinalReportOpen = isFinalReportOpen;
			this.userTotalScore = userTotalScore;
		} else {
			this.context.subject.next({
				type: "action",
				action: "close",
			});
		}
	}

	reTry() {
		this.isRecorded = false;
		this.isFinalReportOpen = false;
		this.questionNumber = 1;
	}

	private recordNow(): Promise<any> {
		this.myData = null;
		// this.showPercent = false;
		return this.myRecorder.record();
	}

	public getTagNames(): string[] {
		return ["STT"];
	}

	public get data(): string {
		if (this.myData) {
			return this.objectToString2(this.myData);
		}
		return null;
	}

	// after assign answer
	protected onAnswerSet(): void {
		if (this.myData) {
			this.loadFile(this.myData);
		}
	}

	loadFile(obj: any): void {
		if (obj && obj.hasOwnProperty("key") && obj.hasOwnProperty("reference")) {
			if (this.context.config.viewMode == "scoring") {
				this.context.loadStudentFile(
					<ROPageComponent>this.page,
					this,
					this.context.config.student.uid,
					obj, 0
				).then((url: string) => {
					this.myRecorder.setupAudioFromURL(url);
				});
			} else if (this.context.config.viewMode == "view" || this.context.config.viewMode == "review") {
				this.context.loadFile(<ROPageComponent>this.page, this, obj).then((url: string) => {
					this.myRecorder.setupAudioFromURL(url);
				});
			}
		} else
			this.myRecorder.setupAudioFromURL(null);
	}

	private uploadAsset(): Promise<any> {
		return this.context.uploadComponentAsset(this);
	}

	public getAnswerAssets(): any[] {
		if (this.answerChanged) return [this.fileReference];
		return null;
	}

	// ======================================================
	// data function
	// ======================================================
	public isQuestionComponent(): boolean {
		return true;
	}

	private mathRound(n: number, ratio: number): number {
		return Math.round(n / ratio) * ratio;
	}

	public correctState: number;

	public canVerify(): boolean {
		return true;
	}
	public defaultAnswer: any = null;

	public hideDefaultAnswer(): void {
		this.defaultAnswer = null;
		if (this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}

	public showDefaultAnswer(): void {
		this.defaultAnswer = true;
		if (this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}

	public verify(_showScoring: boolean): any {
		let score: Number;
		let correct: number = -1;
		if (this.pronScore) {
			if (this.pronScore == 100) {
				correct = 2;
			} else if (this.pronScore == 0) {
				correct = 0;
			} else {
				correct = 1;
			}

			if (this.exercisePractice.includes(this.subType)) {
				this.resultObject = {
					score: this.totalScoreInEachQuestion * this.pronScore / 100,
					maxScore: this.totalScoreInEachQuestion,
					scorePer: this.pronScore,
					correct: correct,
					correction: false,
					"2280-1": this.pronScore,
					"2280-2": this.accuracyScore,
					"2280-3": this.completenessScore,
					"2280-4": this.fluencyScore,
				};
			} else {
				this.resultObject = {
					score: this.totalScoreInEachQuestion * this.pronScore / 100,
					maxScore: this.totalScoreInEachQuestion,
					scorePer: this.pronScore,
					correct: correct,
					correction: false,
					"2280-1": this.pronScore,
					"2280-2": this.accuracyScore,
					"2280-3": this.completenessScore,
					"2280-4": this.fluencyScore,
					"2822-1": this.EDBScore[0],
					"2822-2": this.EDBScore[1],
					"2822-3": this.EDBScore[2],
					"2822-4": this.EDBScore[3],
					"2822-5": this.EDBScore[4],
					"2822-6": this.EDBScore[5],
					"2822-7": this.EDBScore[6],
					"2822-8": this.EDBScore[7],
					"2822-9": this.EDBScore[8],
				};
			}


			this.correctState = correct;
		} else {
			this.resultObject = null;
			score = 0;
			correct = 0;
		}
		this.correctState = _showScoring ? correct : -1;
		return this.resultObject;
	}

	closeFromAIOral($event) {
		if (this.coms.sectionComponent && location.href.indexOf('/bookViewer') > -1) {
			this.coms.sectionComponent.roBookViewer.close();
		}
		// this.context.subject.next({
		// 	type:"action", 
		// 	action:"answerChanged",
		// 	data: this
		// });
	}

	scoreCoversation(conversation, criteria = ["content", "grammer", "creativity"]) {
		let criteriaString = criteria.join(",");
		const scoreContent = [...conversation.filter(message => !message.isHint)];
		scoreContent.push(
			{
				role: 'user',
				content: `score the conversation based on " + ${criteriaString} + " in x out of 10. return with the json format: {
					content: x,
					grammer: x,
					creativity: x
					}`,
			}
		);
		return this.chat.oralChat(scoreContent);
	}
}
