export class SmoothDrawingType
{
	// data type
	public static readonly DT_EPEN:number = 1;
	public static readonly DT_HIGHLIGHT:number = 2;
	/*static readonly DT_DEL:number = 3;
	static readonly DT_CLEAR:number = 4;
	static readonly DT_UNDO:number = 5;
	static readonly DT_REDO:number = 6;
	static readonly DT_MARKING:number = 7;
	static readonly DT_MOVE_OBJ:number = 8;*/

}