import { ApplicationRef, ComponentFactoryResolver, ElementRef, Injectable, Injector, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
import { DialogOption, IDialogContent } from "src/app/sharedModule/alertModule/Dialog";
import { Modal2Component } from "./modal2.component";


@Injectable({ providedIn: 'root' })
export class Modal2Service implements OnDestroy{
	private elementRef:ElementRef;
	constructor(
		private dcs:DynamicComponentService,
		private translateService:TranslateService
	) 
	{
		
    }

	setup(elementRef:ElementRef)
	{
		this.elementRef =  elementRef;
	}

	create(
		title:string, 
		view:any, 
		data:any, 
		saveLabel:string,
		confirmLabel:string, 
		cancelLabel:string, 
		contentStyle:any,
		extraOptions:any = null
	):Modal2Component
	{
		var options = new DialogOption();
		options.title = title;
		options.saveLabel = saveLabel;
		options.confirmLabel = confirmLabel;
		options.cancelLabel = cancelLabel;
		
		options.confirmEnabled = confirmLabel ? true : false;
		options.saveEnabled = saveLabel ? true : false;
		options.showCnlBtn = cancelLabel ? true : false;
		
		
		var instance:IDialogContent = this.dcs.create(view ,{});
		ObjectUtils.copyTo(
			{
				data:data,
				dialogOptions : options
			}, 
			instance
		);
		var modal:Modal2Component = this.dcs.create(
			Modal2Component ,
			ObjectUtils.merge(
				{
					showSaveBtn:options.saveEnabled,
					saveLabel:options.saveLabel,
					showConfirmBtn:options.confirmEnabled,
					confirmLabel:options.confirmLabel,
					cancelLabel:options.cancelLabel,
					showCancelBtn:options.showCnlBtn,
					title:options.title,
					contentStyle:ObjectUtils.merge(
						{
							padding:'0px',
							backgroundColor:"--section-popupbgcolor"
						},
						contentStyle
					),
					lang:this.translateService.currentLang,
					width:"1000px"
				},
				extraOptions
			)
		);
		var container:HTMLElement = modal.getContentContainer();
		
		// this.linkDialogView(modal, instance);
		this.linkDialogView(modal, options, instance);
		this.dcs.open(modal, this.elementRef ? this.elementRef.nativeElement : document.body);
		this.dcs.open(instance, container);
		modal.open();
		return modal;
	}

	
	private linkDialogView(modal:Modal2Component, dialogOption:DialogOption, instance:IDialogContent):void
	{
		var subscription:Subscription = new Subscription(()=>{
			modal.close();
			setTimeout(()=>{
				this.dcs.destroy(modal);
				this.dcs.destroy(instance);
			}, 300);
		});
		
		modal.confirmHandler = ()=>{
			return instance.confirm();
		}

		subscription.add(
			dialogOption.emitter.subscribe((o:any)=>{
				modal.warning = dialogOption.warning;
				modal.title = dialogOption.title;
			})
		);
		
		subscription.add(
			modal.emitter.subscribe((data:any)=>{
				subscription.unsubscribe();
			})
		);
		subscription.add(
			modal.cancelClick.subscribe((data:any)=>{
				subscription.unsubscribe();
			})
		);
		subscription.add(
			modal.saveClick.subscribe((data:any)=>{
				instance.save();
			})
		);
		
	}
	
	ngOnDestroy(): void {
		
	}


}