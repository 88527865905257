import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { ScriptService } from 'src/app/service/script.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { preloadImage } from 'src/app/common/customFunction';
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";
import * as moment from 'moment';
import { LoadingService } from '../loadingModule/loading.service';
import { DateTimePicker3Component } from '../dateTimePicker3Module/dateTimePicker3.component';
import { TargetSelectorComponent } from '../targetSelectorModule/targetSelector.component';
import { SubjectSelector3Component } from '../subjectSelector3Module/subjectSelector3.component';
import { SettingService } from 'src/app/service/setting.service';
@Component({
	selector: 'create-game-modal',
	template: `
	<modal2
		#modal
		class="create-game-modal" [title]="modalTitle"
		[headerStyle]="{border: '0'}"
		[contentStyle]="{padding: '0'}"
		(confirmClick)="confirm()"
		(cancelClick)="confirm(false)"
		>
		<popup-toggle class="bookshelflist-popuptoggle" 
			[(toggle)]="toggle" bindLabel="label" bindValue="value"
			(toggleChange)="toggleChange()"
			[items]="toggles"></popup-toggle>
		<div class="game-container">
			<div class="game-container-1" *ngIf="games && toggle == 'choose-game'">
				<div class="item" *ngFor="let game of games" (click)="gameSelectClick(game)">
					<img class="item-cover" [src]="game.cover" />
					<div class="item-title">
						<span>{{game.title}}</span>
					</div>
					<fa-icon 
						[class.selected]="(selectedGame && selectedGame.id == game.id?icons.faCheckCircle:icons.faCheckCircleLight)" 
						[icon]="(selectedGame && selectedGame.id == game.id?icons.faCheckCircle:icons.faCheckCircleLight)"></fa-icon>
				</div>
			</div>

			<div class="game-container-2" *ngIf="games && toggle == 'set-game' && this.selectedGame">
				<div class="game-container-2-left">
					<div class="topic"> {{ {tc:'基本設定', sc:'基本設定', en: 'General setting'}[lang] }}</div>
					<div class="row first">
						<div class="row-label">{{ {tc:'名稱',sc:'名稱',en:'Name'}[lang] }}</div>
						<div class="row-data">
							<input type="text" [ngModel]="name" />
						</div>
					</div>
					<div class="row">
						<div class="row-label">{{ {tc: '組別', sc: '組別', en: 'Groups'}[lang] }} </div>
						<div class="row-data">
							<div class="tag-title" (click)="targetSelectorOpen($event)">{{tags_title}}</div>
							<targetSelector #targetSelector [allowForAll]="false"
								memberRole="student" [(ngModel)]="targets" 
								></targetSelector>
						</div>
					</div>
					<div class="row">
						<div class="row-label">{{ {tc: '科目', sc: '科目', en: 'Subject'}[lang] }} </div>
						<div class="row-data">
							<div class="subject-title" (click)="subjectSelectorOpen($event)">{{subjects_title}}</div>
							<subjectSelector3 #subjectSelector 
								[(ngModel)]="subjects"
								leftBgColor="#2c97d7"
								></subjectSelector3>
						</div>
					</div>
					<div class="row in-progress-time">
						<div class="row-label">{{ {tc:'「進行中」時段',sc:'「進行中」時段',en:'Open period'}[lang] }}:</div>
						<div class="row-data">
							<a class="datetime in-progress" #inProgressTimeEl (click)="dateTimeClick(inProgressTimeEl, 'in-progress')">
								<ng-container *ngIf="!startTime && !endTime">
									<span class="datetime-text">{{ {tc:'請選擇...',sc:'请选择...',en:'Select...'}[lang] }}</span>
								</ng-container>
								<ng-container *ngIf="startTime && endTime">
									<span class="datetime-text">{{ startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} - {{endTime | myDate: wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a'}}</span>
								</ng-container>
								<ng-container *ngIf="startTime != null && endTime == null">
									<span class="datetime-text">{{startTime | myDate: (wholeDay?'YYYY/M/D':'YYYY/M/D h:mm a')}} {{'workspace.up-to-forever' | translate}}</span>
								</ng-container>
							</a>
							<dateTimePicker3 #dateTimePicker 
								[showCustomMinutesOption]="true"
								[clearBtn]="true" [lang]="datas.lang"></dateTimePicker3>
						</div>
					</div>
					<div class="row" *ngIf="this.selectedGame.subitems && this.selectedGame.subitems.length > 1">
						<div class="row-label">{{ {tc:'遊戲', sc:'游戏', en:'Game'}[this.lang] }}</div>
						<div class="row-data">
							<okaPulldown2 [options]="this.selectedGame.subitems" bindLabel="title" [(ngModel)]="selectedSubitem"></okaPulldown2>
						</div>
					</div>
				</div>
				<div class="game-container-2-right">
					<div class="topic"> {{ {tc:'遊戲設定', sc:'游戏設定', en: 'General setting'}[lang] }}</div>
					<div class="row row-type-{{row.type}}" *ngFor="let row of settingRows;let index=i">
						<div class="row-label">
							<span>{{ row.label }}</span>
						</div>
						<div class="row-data">
							<input type="text" *ngIf="row.type == 'text'" />
							<input type="text" *ngIf="row.type == 'int'" (input)="numInput($event, 'int')"/>
						</div>
					</div>
				</div>
			</div>

		</div>

		
  	</modal2>
  `
	, styleUrls: ['./create-game.modal.scss'],
})

export class CreateGameModal implements OnInit {
	@Input() data: any;
	@ViewChild('modal', {static: false}) modal;
	@ViewChild('nameRef', {static: false}) nameRef;
	@ViewChild('inProgressTimeEl', {static: false}) inProgressTimeEl;
	@ViewChild('dateTimePicker', {static: false}) dateTimePicker: DateTimePicker3Component;
	@ViewChild('subjectSelector', { static: false }) subjectSelector: SubjectSelector3Component;
	@ViewChild('targetSelector', {static: false}) targetSelector: TargetSelectorComponent;
	@Output() close:EventEmitter<any> = new EventEmitter<any>();
	public toggles;
	public toggle = 'choose-game';
	public toggleOptions;
	public url='';
	public name='';
	public modalTitle = '';
	public lang='tc';
	public games:any = [];
	public icons = {faCheckCircleLight , faCheckCircle };
	public selectedGame;
	public selectedSubitem;
	public settingRows:any = [];
	public questionRows:any = [];
	public subjects:any  = [];
	public targets:any = [];
	public hasScoring = true;
	public startTime:any = null;
	public endTime:any = null;
	public wholeDay = 0;
	private selectorPromise;
	public tags_title = '';
	public subjects_title = '';
	constructor(public datas: DataService, private script: ScriptService, private trans: TranslateService, private als: AlertService, public sans: DomSanitizer, public lds: LoadingService, public eleRef: ElementRef, public sts: SettingService) {
	}

	ngOnInit() {
		const lang = this.datas.lang;
		this.lang = lang;
		this.toggles = [
			{label:{tc:'選擇遊戲',sc:'选择游戏',en:'Choose game'}[lang],value:'choose-game'}, 
			{label: {tc:'設定遊戲',sc:'设定游戏',en:'Edit setting'}[lang], value:'set-game', onEnter: ()=> {
				if (this.toggle != 'choose-game'){
					return false;	
				}
				return true;
				}
			},
			{label: {tc:'設定題目',sc:'设定题目',en:'Set question'}[lang], value:'set-question', onEnter: ()=> {
				if (this.toggle != 'set-game'){
					return false;
				}
				return true;
				}
			}
		];
		if (this.data.data){
			this.modalTitle = {tc:'編輯遊戲',sc:'编辑游戏',en:'Edit game'}[this.lang];
			this.url = this.data.data.url;
			this.name = this.data.data.name;
		} else {
			this.modalTitle = {tc:'建立遊戲',sc:'建立游戏',en:'Add game'}[this.lang];
		}
		this.modalTitle = this.trans.instant(this.modalTitle);
		this.initSubject();
		this.initTargets();
		this.getData();
	}

	ngAfterViewInit() {
		setTimeout(()=>{
			this.modal.open();
		},10);
	}

	ngOnDestroy(): void {
	}

	test(){
		/*
		this.questionRows = [
			{question: 'color', answers: ['wrong1a','correct1','wrong1b','wrong1c'], name2: 'Q1',correctAnswer: 'correct1', correctAnswerIndex: 1},
			{question: 'color', answers: ['wrong2a','correct2','wrong2b','wrong2c'], name2: 'Q2',correctAnswer: 'correct2', correctAnswerIndex: 1},
			{question: 'color', answers: ['wrong3a','correct3','wrong3b','wrong3c'], name2: 'Q3',correctAnswer: 'correct3', correctAnswerIndex: 1},
			{question: 'color', answers: ['wrong4a','correct4','wrong4b','wrong4c'], name2: 'Q4',correctAnswer: 'correct4', correctAnswerIndex: 1},
		];*/
		this.questionRows = [
			{question:'Classmate', correctAnswer: 'Classmote', questionType: 'spelling'},
			{question:'Goodbye', correctAnswer: 'Goodbye', questionType: 'spelling'},
			{question:'Greet', correctAnswer: 'Greet', questionType: 'spelling'},
			{question:'Hello', correctAnswer: 'Hello', questionType: 'spelling'},
		];
	}

	getData(){
		this.lds.add('ROGame.list',5000);
		this.datas.post('ROGame.list').subscribe((res:any)=>{
			let games = res.items.map(game=>{
				let url = location.href.indexOf('localhost') > -1?'//dev.openknowledge.hk':'';
				url = url + '/RainbowOne/webapp/OKAGames/SelectGames/image/' + game.image;
				game.imageUrl = url;
				game.cover = this.sans.bypassSecurityTrustUrl(url);
				game.subitems = res.subitems.filter(e=> e.item_id == game.id);
				game.subitems.forEach(subitem=>{
					try{ subitem.settings = JSON.parse(subitem.settings); } catch(e){ }
					try{ subitem.questions = JSON.parse(subitem.questions); } catch(e){ }
				});
				game.title = game['title_'+this.lang];
				return game;
			});
			const gameImageUrls = games.map(e=> e.imageUrl);
			// preloadImage(gameImageUrls).then(e=>{
				this.games = games;
				this.gameSelectClick(this.games[0]);
				this.lds.remove('ROGame.list');
			// });
		});
	}

	confirm(isConfirm = true){
		this.test();
		if (isConfirm){
			if (this.targets.length == 0){
				const msg = { tc: '請選擇組別', sc: '请选择组别', en: 'Please select group' }[this.datas.lang];
				this.als.toastError(msg);
				return;
			}
			if (this.questionRows.length == 0){
				const msg = {tc: '請輸入題目', sc: '請輸入题目',en:'Please input questions'}[this.lang];
				this.als.toastError(msg);
				return;
			}
			const data = this.createGame();
			this.data.close(data);
			return;
		}
		this.data.close();
	}

	toggleChange(){
		const lang = this.datas.lang;
		if (this.toggle == 'choose-game'){

		} else if (this.toggle == 'set-game'){
			this.name = this.selectedGame.title;
			if (this.selectedGame.subitems && this.selectedGame.subitems.length > 0){
				let subitem = this.selectedGame.subitems[0];
				this.name = subitem.title;
				this.subitemChange(subitem);
			}
		} else if (this.toggle == 'set-question'){

		}
	}

	initSettingForSubitem(subitem){

	}

	gameSelectClick(game){
		this.selectedGame = game;

	}

	subjectSelectorOpen($event) {
		if (this.selectorPromise && this.subjectSelector) {
			this.subjectSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
		} else {
			// this.shareModal.subjectSelecting = true;
			setTimeout(() => {
				this.selectorPromise = this.subjectSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.initSubject();
				}).catch(e => { //cancel will throw error
					this.selectorPromise = null;
				});
			}, 200);
		}
	}

	initSubject(){
		if (this.subjects.length > 0) {
			const subjectTitles = this.subjects.map(s => s.title);
			this.subjects_title = subjectTitles.join(', ');
		} else {
			this.subjects_title = {tc:'請選擇...',sc:'请选择...',en:'Select...'}[this.lang];
		}
	}

	targetSelectorOpen($event){
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
		} else {
			setTimeout(() => {
				this.selectorPromise = this.targetSelector.open($event.target, this.eleRef.nativeElement).then(data => {
					this.selectorPromise = null;
					this.initTargets();
				});
			}, 200);
		}
	}

	initTargets() {
		if (this.targets.length > 0) {
			const targetTitles = this.targets.map(t => t.title);
			this.tags_title = targetTitles.join(', ');
		} else {
			this.tags_title = {tc:'請選擇...',sc:'请选择...',en:'Select...'}[this.lang];
		}
	}

	dateTimeClick(el, dateType) {
		const dateTimePicker = this.dateTimePicker;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		let update$ = null;
		if (startTime) {
			const last8Dight = startTime.format('HH:mm:ss');
			wholeDay = last8Dight == '00:00:00' ? 1 : 0;
			startTime = this.startTime.toDate();
		}
		if (endTime) {
			const last8Dight2 = endTime.format('HH:mm:ss');
			wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
			endTime = this.endTime.toDate();
		}
		
		let needEndTime = !!endTime;
		let dtpOptions: any = { value: { startDateTime: startTime, endDateTime: endTime, isWholeDay: wholeDay, customMinutes: true, needEndTime: needEndTime }, skipLoadApi: true };
		dateTimePicker.open(el, dtpOptions).then(dt => {
			if (dt.startDateTime) {
				this.startTime = moment(dt.startDateTime);
			} else {
				this.startTime = null;
			}
			if (dt.endDateTime) {
				this.endTime = moment(dt.endDateTime);
			} else {
				this.endTime = null;
			}
			this.wholeDay = dt.wholeDay ? 1 : 0;
		});
	}

	async createGame(){
		const pageid = GUID.create("OKD guid");
		let setting:any = {id: this.selectedGame.id, url: this.selectedGame.url, image: this.selectedGame.imageUrl, game: {} };
		this.settingRows.forEach(r=>{
			if (r.prop && r.value !== undefined){
				setting.game[r.prop] = r.value;
			}
		});
		let maxScore = 0;
		let components:any = this.questionRows.map((r,i)=>{
			let scoreObj:any = {full: r.score?parseFloat(r.score):1, n: r.n?r.n:1, unit: r.unit?r.unit: 1};
			maxScore += scoreObj.full;
			let comp:any = {type: 'Rogame', pageNumber: 1, hasScoring: this.hasScoring, order: i, page: pageid};
			const exceptedProp = ['score','n','unit']; 
			for(const prop in r){
				if (exceptedProp.indexOf(prop) === -1){
					comp[prop] = r[prop];
				}
			}
			comp.score = scoreObj;
			comp.cid = GUID.create("OKD guid");
			comp.name = '' + (i + 1);
			comp.learningObjective = r.learningObjective?r.learningObjective: [];
			return comp;
		});

		let page:any = {pageNumber: 1, id: pageid, title: 'Page 1', components: components };
		let chapter:any = {pageCount: 1, title: 'Game', pages: [ page ] };
		let structure:any = {book: {title: this.name, maxScore: maxScore, chapters: [ chapter ], setting: setting } };
		const resEntry:any = await this.datas.post('ROGame.createGame', [ structure ]).toPromise();
		if (resEntry.book_id === undefined){
			this.als.toastError(JSON.stringify(resEntry));
			return;
		}
		const startTime = this.startTime?moment(this.startTime,'YYYY-MM-DD HH:mm:ss').toISOString():null;
		const endTime = this.endTime?moment(this.endTime,'YYYY-MM-DD HH:mm:ss').toISOString():null;
		const shareOptions = {type: 'custom', star: 0, start_time: startTime, end_time: endTime };
		const targets = this.targets.filter(t => t.tType == 'tag');
		const individualUids = this.targets.filter(t => t.tType == 'people').map(e => e.value);
		if (individualUids.length > 0) {
			const schoolId = this.datas.userInfo.school_id;
			const title = 'cid-share-' + moment().format('YYYYMMDD-HHmmss');
			const tagType = 13;
			const currentYearId = this.sts.current_year_id;
			const json: any[] = [schoolId, currentYearId, schoolId, title, tagType, 'main', individualUids];
			let virtualTagRes: any = await this.datas.post('ROCourse.create_virtual_tag_for_group_share', json).toPromise();
			targets.push({ tType: 'tag', value: virtualTagRes.tid, title: title });
		}
		const targetIds = targets.map(e=> e.value);
		let o = { entries: [{ id: resEntry.book_id, type: 'game', title: this.name }], tags: targetIds, options: shareOptions, subjects: this.subjects };
		const shareRes = await this.datas.post2({ data: { api: 'ROBookShare.create_multi_share', json: [ o ] } });
		if (shareRes){
			let shares = shareRes.shares.map(sh => {
				sh.short_url = null;
				sh.tagTitle = sh.tags ? sh.tags[0].title : 'n/a';
				return sh;
			});
			this.data.shares = shares;
			this.data.bsids = shares.map(e=> e.id);
			this.close.emit(this.data);
		};
	}

	subitemChange(subitem){
		const lang = this.datas.lang;
		let settingRows = [
			{label: {tc:'遊戲時間',sc:'游戏时间',en:'Game time'}[lang], type:'float', value: 100},
			{label: {tc:'背景圖片',sc:'背景图片',en:'Background'}[lang], type:'image', value: ''},
			{label: {tc:'指引',sc:'指引',en:'Introduction'}[lang], type:'textarea', value: ''},
		];
		if (subitem.settings){
			settingRows = [...settingRows, subitem.settings];
		}
		this.settingRows = settingRows.map((e:any)=>{
			let obj:any = Object.assign({}, e);
			if (typeof e.label === 'object'){
				obj.label = e.label[lang]; 
			}
			if (e.options){
				obj.options = e.options.map((e:any)=>{
					if (typeof e.label === 'object'){
						return e.label[lang];
					}
				});
			}
			return obj;
		});
	}

	numInput($event, numType='int'){
		debugger;
	}

}
