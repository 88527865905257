import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener,OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';

@Component({
    selector: 'modal',
    template: `
<div class="modal" [style.width]="width" [style.height]="height">
    <div class="header">
        <div class="cancelBtn" [style.visibility]="(showCancelBtn ? 'inherit' : 'hidden')" (click)="cancelClick.emit()">{{cancelLabel}}</div>
        <div class="title">{{title}}</div>
        <div class="confirmBtn" [style.visibility]="(showConfirmBtn ? 'inherit' : 'hidden')" (click)="confirmClick.emit()" [style.backgroundColor]="cfmBtnBgColor">{{confirmLabel}}</div>
    </div>
    <div class="content" [style.backgroundColor]="backgroundColor" [ngStyle]="contentStyle">
        <div class="shadowTop"></div>
        <ng-content></ng-content>
    </div>
</div>
    `,
    styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnChanges{
    @HostListener('dblclick', []) onDblClick() { console.log(this); }
    @HostListener("click", ["$event"]) public onClick(event:PointerEvent):void { event.stopPropagation(); }

    @HostBinding('style.zIndex') public hostZIndex = 99;
    @HostBinding('style.display') public hostDisplay:string = 'none';

    @Input() public width:string = "";
    @Input() public height:string = "";
    @Input() public title:string = "";
    @Input() public contentStyle:any = {};
    @Input() public backgroundColor:string = "#F5ECF0";

    @Input() public showConfirmBtn:boolean = true;
    @Input() public showCancelBtn:boolean = true;

    @Input() public lang:"tc"|"sc"|"en" = "tc";
    @Input() public cancelLabel:string = "";
    @Input() public confirmLabel:string = "";
    @Input() public cfmBtnBgColor:string = "#834370"

    public data:any = null;

    @Output() confirmClick:EventEmitter<any> = new EventEmitter();
    @Output() cancelClick:EventEmitter<any> = new EventEmitter();

    constructor(private coms:CommonService, public datas: DataService) {
    }

    public ngOnChanges(changes:SimpleChanges) {

        if (changes.lang && changes.lang.currentValue) this.setLang(changes.lang.currentValue);
    }

    private setLang(lang:string="tc") {
        this.cancelLabel = { tc:"取消", sc:"取消", en:"Cancel" }[lang];
        this.confirmLabel = { tc:"確定", sc:"确定", en:"OK" }[lang];
    }

    public open() {
        if (sessionStorage.getItem('in-app') || !this.datas.isInWeb) {
			window.location.hash='#ro/showDialog';
		}
        this.hostZIndex = this.coms.getZIndex();
        this.hostDisplay = 'flex';

    }

    public close() {
        if (sessionStorage.getItem('in-app') || !this.datas.isInWeb) {
			window.location.hash='#ro/hideDialog';
		}
        this.hostDisplay = 'none';
    }


}
