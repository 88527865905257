var idNumber:any = 100;
export class UniqueID {
    static getNextID():any
	{
        idNumber ++;
        return idNumber;
	}
	static createID(prefix:string = ""):string
	{
		idNumber++;
		return prefix + idNumber;
	}
}