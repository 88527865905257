import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MarkingLayer } from "./MarkingLayer";

export class CorrrectionPhoto
{
	protected markingLayer:MarkingLayer;
	public sourceJSON:any;
	public readOnly:boolean;
	public url:SafeUrl;

	constructor(markingLayer:MarkingLayer, sourceJSON:any, readOnly:boolean, assetURL:string = null)  {
		this.markingLayer = markingLayer;
		this.sourceJSON = sourceJSON;
		this.readOnly = readOnly;

		var sans:DomSanitizer = this.markingLayer.context.service.domSanitizer;
		if(assetURL) {
			this.url = sans.bypassSecurityTrustUrl(assetURL);
		} else {
			// 取得 asset url
			this.markingLayer.context.loadStudentFile(this.markingLayer.pageCom, {douid:"#correction_photo#"}, this.sourceJSON.uid, 
				{key:this.sourceJSON.key, reference:this.sourceJSON.reference}, this.sourceJSON.index
			).then((url:string)=>{
				this.url = sans.bypassSecurityTrustUrl(url);
			}).catch(e=> {
				var s:any = this.markingLayer.context.service;
				s.alertService.alert(s.translateService.instant("bookviewer.alert.load_data_fail"));
			});
		}
	}
	
	public set x(val:number) {
		this.sourceJSON.x = Math.round(val);
	}
	public get x():number {
		return this.sourceJSON.x;
	}
	
	public set y(val:number) {
		this.sourceJSON.y = Math.round(val);
	}
	public get y():number {
		return this.sourceJSON.y;
	}

	public set width(val:number) {
		this.sourceJSON.width = Math.round(val);
	}
	public get width():number {
		return this.sourceJSON.width;
	}

	public set height(val:number) {
		this.sourceJSON.height = Math.round(val);
	}
	public get height():number {
		return this.sourceJSON.height;
	}

	public get index():number {
		return this.sourceJSON.index;
	}
}