import { Component, HostBinding, Input, Output, EventEmitter, HostListener, ComponentFactoryResolver, OnDestroy } from '@angular/core';

import { ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { OnInit } from '@angular/core';
import { TopLayer } from 'src/app/common/TopLayer';




@Component({
    selector: 'custom-dialog-container',
    template: `
		<div class="dialog" 
			[ngStyle]="style">

			<div class="header">
				<div class="center">
					<div class="title">{{title}}</div>
				</div>
				<div class="left">
					<div 
						class="button cancel-btn" (click)="onCloseClick()">{{cancelLabel}}</div>
				</div>
				<div class="right">
					<div class="button confirm-btn" (click)="onConfirmClick()">{{confirmLabel}}</div>
					<div class="button confirm-btn" (click)="onConfirmClick()">{{confirmLabel}}</div>
				</div>
			</div>
			<div  class="dialog-content-container">
				<ng-content></ng-content>
			</div>
		</div>
			`
	 ,
     styleUrls: ['CustomDialog.scss']
})


export class CustomDialogContainer implements OnInit, OnDestroy {
	
	@ViewChild('body', { static: true }) body: any;
	
    @HostBinding('style.zIndex') public hostZIndex = TopLayer.getNextIndex();
	
	@Output() confirm:EventEmitter<any> = new EventEmitter();
	@Output() cancel:EventEmitter<any> = new EventEmitter();
	
	@Input() public style:any;
	@Input() public title:string = "title";
	@Input() public confirmLabel:String = "Confirm";
	@Input() public cancelLabel:String = "Cancel";
	
    constructor(
		// private coms:CommonService, private dcs:DynamicComponentService
	)
	{
		
    }
	
	ngOnInit(): void {
		
	}
	ngOnDestroy(): void {
		
	}

	onConfirmClick():void
	{
		this.confirm.emit("confirm");
	}

	onCloseClick():void
	{
		this.cancel.emit("cancel");
	}
	
}

