import { Component, Input, Output, EventEmitter, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { CollectionModal } from './collection.modal';
import { LoadingService } from '../loadingModule/loading.service';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { tap } from 'rxjs/operators';
import { zip } from 'rxjs';
import { faClockThree } from '@fortawesome/pro-light-svg-icons';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'book-time-limit-modal',
	template: `
    <div class="book-time-limit-modal" *ngIf="ready">
      <div [ngClass]="['photo', (photo?'':'no-cover'), data.book_type?data.book_type:'']" [style.backgroundImage]="photo"></div>
      <div class="name">
        <span>{{data.name}}</span>
      </div>
      <div [ngClass]="'message ' + datas.lang">
        <div class="message__section">{{('open-book.st-' + data.share_type)|translate}}</div>
        <div [ngClass]="['time-limit', datas.lang, (remainer != data.time_limit?'has-remainer':'')]">
		  <fa-icon class="time-limit__icon" [icon]="faClockThree"></fa-icon>
          <span>{{'open-book.time-limit2'|translate: {min: timeLimitMinute} }}</span>
        </div>
        <div [ngClass]="['remainer',datas.lang]" *ngIf="remainer != data.time_limit">
		  <fa-icon class="time-limit__icon" [icon]="faClockThree"></fa-icon>
          <span>{{'open-book.remainer'|translate:{remainer: remainer} }}</span>
        </div>
        <div class="message__star" *ngIf="data.star != 'each' && data.star > 0">
          <div class="message__star__text">{{'open-book.chance-to-get-star'|translate}}:</div>
          <div class="message__star__num" *ngIf="data.star > 3">x {{data.star}}</div>
          <ng-container *ngIf="data.star <= 3">
            <div class="message__star__icon" *ngFor="let s of numToArray(data.star)"></div>
          </ng-container>
        </div>
		<div class="message__star" *ngIf="data.star == 'each'">
          <div class="message__star__text">{{'open-book.chance-to-get-star'|translate}}:</div>
          <div class="message__star__num">{{'open-book.chance-to-get-star-each' | translate}}</div>
        </div>
      </div>
      <div class="share-info" [style.backgroundColor]="shareInfoBgColor">
        <div *ngIf="data.pass_condition !== undefined && data.pass_condition != 'all-question'">{{ 'open-book.share-info-pass-level'|translate: data }}</div>
		<div *ngIf="data.pass_condition == 'all-question'">{{ 'open-book.share-info-pass-level-all-question'|translate }}</div>
        <div *ngIf="data.max_submission_count !== undefined">{{ data.max_submission_count > 2?('open-book.share-info-max-submission-count-x'|translate: data): ('open-book.share-info-max-submission-count-' + data.max_submission_count)|translate }}</div>
        <div>{{ ('open-book.share-info-auto-submit-' + (data.auto_submit == 1?1:0) )|translate  }}</div>
        <div>{{ ('open-book.share-info-live-verify-' + (data.live_verify == 1?1:0) )|translate }}</div>
      </div>
      <div class="panel">
        <ng-container *ngIf="remainer > 0 || !data.time_limit; else leaveOnly">
          <div class="start" (click)="startClick($event)">{{'open-book.start'|translate}}</div>
          <div class="cancel" (click)="closeModal($event)">{{'commonService.cancel'|translate}}</div>
        </ng-container>
        <ng-template #leaveOnly>
          <div class="leave" (click)="closeModal($event)">{{'open-book.leave'|translate}}</div>
        </ng-template>
      </div>
    </div>
  `
	, styleUrls: ['./bookTimeLimit.modal.scss'],
})
export class BookTimeLimitModal implements OnInit {
	@Input() data: any;
	@Output() openBook = new EventEmitter<any>();
	@Output() close = new EventEmitter<any>();
	public cover;
	public photo;
	public faClockThree = faClockThree;
	public remainer = 0;
	public timeLimitMinute = 0;
	public ready = false;
	public shareInfoBgColor = '#ececec';
	constructor(private translate: TranslateService, private sanitizer: DomSanitizer, private ro: RoService, private lds: LoadingService, public datas: DataService, private zone: NgZone, private theme: ThemeService) {
	}

	ngOnInit() {
		let observerables = [];
		if (this.data.time_limit){
			observerables.push(this.getRemainer());
		}
		if (this.theme.themeSelected == 'dark'){
			this.shareInfoBgColor = '#262635';
		}
		if (this.data.cover){
			this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + this.data.cover + "')");			
		} else {
			observerables.push(this.getPhoto());
		}

		if (observerables.length > 0){
			this.lds.add('getRemainer', 2000);
			zip(...observerables).subscribe(()=>{
				this.ready = true;
				this.lds.remove('getRemainer');
			});
		} else {
			this.ready = true;
		}
		

	}


	getRemainer(){
		this.timeLimitMinute = this.data.time_limit / 60;
		return this.datas.post('ROBookshelfKen.getRemainer', [this.data.bsid, this.data.book_id]).pipe(tap((res: any) => {
			let used = 0;
			if (res.duration) {
				used = res.duration / 60;
			}
			if (res.share) {
				const timeLimit = res.share.time_limit ? parseInt(res.share.time_limit) : 0;
				this.timeLimitMinute = timeLimit / 60;
			}
			this.remainer = this.timeLimitMinute - used;
			this.remainer = Math.round(this.remainer) == this.remainer ? this.remainer : Math.round(this.remainer * 10) / 10;
			this.remainer = this.remainer <= 0 ? 0 : this.remainer;
		}));
	}

	getPhoto(){
		return this.datas.post('ROBookShelf.get_single_book_cover2', [this.data.bsid, this.data.book_id]).pipe(tap((res: any) => {
			if (res.code) {
				this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + res.url + "')");
			}
		}));
	}

	startClick($event) {
		this.openBook.emit();
	}

	closeModal($event = null) {
		this.close.emit();
	}

	numToArray(num) {
		return new Array(num);
	}
}
