import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { Matrix } from "src/app/common/Matrix";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { UniqueID } from "src/app/common/UniqueID";
import {  ROColorUtils, ROComponent, ROContainerComponent, ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";
class MaskDefintion{
	static path:string [] = [
		"M0 0 100 0 100 100 0 100Z",
		"M85.35 85.35Q70.7 100 49.95 100 29.25 100 14.6 85.35 -0.05 70.75 -0.05 50 -0.05 29.3 14.6 14.6 29.25 0 49.95 0 70.7 0 85.35 14.6 99.95 29.3 99.95 50 99.95 70.75 85.35 85.35Z",
		"M49.85 0 100 38 81.1 99.8 19.3 100 0 38.35Z",
		"M74.95 0 100 50 75 100 25 100 0 50 25 0Z",
		"M100 64.6 71.95 100 27.45 99.6 0 63.75 10.25 19.45 50.5 0 90.4 20.1Z",
		"M100 70.65 70.7 100 29.3 100 0 70.65 0 29.3 29.3 0 70.7 0.05 100 29.3Z",
		"M62.5 37.05 100 37.8 70.35 61.9 81.3 99.55 50.25 77.5 19.5 100 29.9 62.2 0 38.5 37.5 37.2 49.75 0Z",
		"M50 100Q-15.35 45.35 4.7 13.9 24.4 -17.05 50 20.75 75.6 -17.05 95.3 13.9 115.35 45.35 50 100Z",
		"M50 0 100 50 50 100 0 50Z",
		"M100 69.7Q100 82.25 85.35 91.1 70.7 100 50 100 29.2 100 14.65 91.1 0 82.25 0 69.7 0 68.65 0.75 67.35 2.05 61.4 5.9 53.1 11.3 41.4 19.8 28.9 28.35 16.45 36.25 8.55 45 0 50 0 54.85 0 63.6 8.55 71.65 16.5 80.2 28.9 88.7 41.4 94.1 53.1 97.95 61.4 99.35 67.35 100 68.65 100 69.7Z",
		"M100 20Q100 0 80 0L20 0Q0 0 0 20L0 80Q0 100 20 100L80 100Q100 100 100 80Z"
	];

	
	static scalePath(path:string, w:number, h:number)
	{
		var scaleX:number = w /100;
		var scaleY:number = h / 100;
		return path.replace(
			/([\-0-9.]{1,})[ ]{1,}([\-0-9.]{1,})/g, 
			(match,match1,match2,index)=>{
				match1 = scaleX * parseFloat(match1);
				match2 = scaleY * parseFloat(match2);
				return match1 +" " + match2;
			}
		);
	}
	static getMaskPathDefintion(shapeIndex:number):string
	{
		return MaskDefintion.path[shapeIndex];
	}
	static getTransformedPath(shapeIndex:number, x:number, y:number, w:number, h:number, rotation:number):string
	{
		
		var scaleX:number = w /100;
		var scaleY:number = h / 100;

		var path = MaskDefintion.path[shapeIndex];
		var matrix:any = new Matrix();
		// 
		matrix.translate(x, y); 
		
		matrix.rotateDeg(rotation);
		matrix.scale(scaleX, scaleY);
		
		
		return path.replace(
			/([\-0-9.]{1,})[ ]{1,}([\-0-9.]{1,})/g, 
			(match,match1,match2,index)=>{
				var px:number =  parseFloat(match1);
				var py:number =  parseFloat(match2);
				
				var point = matrix.applyToPoint(px, py);
				// match1 = x + scaleX * parseFloat(match1);
				// match2 = y + scaleY * parseFloat(match2);
				return point.x +" " + point.y;
				// return match1 +" " + match2;
			}
		);
	}

	static getMaskPath(shapeIndex:number, w:number, h:number):string
	{
		var path = MaskDefintion.path[shapeIndex];
		return  MaskDefintion.scalePath(path, w, h);
	}
	static renderMask(shapeIndex:number, w:number, h:number):HTMLElement
	{
		var path = MaskDefintion.path[shapeIndex];
		// path = MaskDefintion.scalePath(path, w, h);
		// console.log(path);
		// var svg:HTMLElement = document.createElement("svg");
		var ns = 'http://www.w3.org/2000/svg';
		var svg:any = document.createElementNS(ns, 'svg');

		path = MaskDefintion.scalePath(path, w, h);

		// var ns = 'http://www.w3.org/2000/svg'
		
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
		/*
		svg.setAttribute("xmlns","http://www.w3.org/2000/svg");
		svg.setAttribute("version","1.1");
		svg.setAttribute("xmlns:xlink","http://www.w3.org/1999/xlink");
		svg.setAttribute("preserveAspectRatio","none");
		*/
		svg.setAttribute("viewBox", `0 0 ${w} ${h}`);
		svg.setAttribute("width", `${w}`);
		svg.setAttribute("height", `${h}`);
		svg.setAttribute("overflow", "visible");
		
		svg.innerHTML = `<g><path d="${path}" fill="white"></path></g>`;
		return svg;
	}

	static renderFrame(
		shapeIndex:number, frameColor:string, w:number, h:number, strokeWidth:number):HTMLElement
	{
		var path = MaskDefintion.scalePath(MaskDefintion.path[shapeIndex], w, h);

		var ns = 'http://www.w3.org/2000/svg';
		var svg:any = document.createElementNS(ns, 'svg');
		var offset = strokeWidth / 2;
		w += strokeWidth;
		h += strokeWidth;

		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
		

		svg.setAttribute("viewBox", `0 0 ${w} ${h}`);
		svg.setAttribute("width", `${w}`);
		svg.setAttribute("height", `${h}`);
		svg.setAttribute("transform", `translate(${-offset}, ${-offset})`);
		svg.innerHTML = `<g transform="translate(${offset}, ${offset})">
			<path d="${path}" fill="none" stroke="${frameColor}" stroke-width="${strokeWidth}"></path>
		</g>`;
		return svg;
	}

		
}
@Component({
	// template:`Ball{{node.attributes|json}}`
	template:
		`
		
		`,
		styles:[
			`
			
			
			`
		]
	/**
	{
		"ver":1.1,
		"shape":0,"rotation":0,
		"useBorder":false,
		"coordinateExpression":"UA UK X 818.25 Y 451 D T 451 L 818.25 H 200.05 W 140.75",
		"douid":"4DB150BB-C8B2-3662-8D0A-91A4DB84AAA8",
		"locked":false}
	 */
	
})
export class ROMask extends ROComponent
{
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["Mask"];
	}

	renderMask(containerDOM: HTMLElement, maskDOM:HTMLElement) {
		var optinos:any = this.node.attributes;
		var path:string = MaskDefintion.getTransformedPath(
			optinos.shape, 
			this.x, this.y,
			this.w, this.h,
			this.rotation
		);
		maskDOM.style.clipPath = `path('${path}')`;
		var options:any = this.node.attributes;
		if(options.useBorder)
		{
			var borderWidth:number = options.borderWidth * 2;
			var borderColor:string = ROColorUtils.formatColor(options.borderColor, "none");
			var shapeColor:string = ROColorUtils.formatColor(options.shapeColor, "none");
			
			var svg:HTMLElement = document.createElement("svg");
			containerDOM.prepend(svg);
			/**
			 	border width not good
			 	transform="translate(${this.x}, ${this.y}) rotate(${this.rotation}) scale(${scaleX}, ${scaleY})"
			  	// not good effect
			 	stroke-linejoin="miter" 
				stroke-miterlimit="3"
			 */
			svg.outerHTML = `<svg 
				overflow="visible" 
				style="position:absolute;" 
				width="100" height="100" 
				viewBox="0 0 100 100"
				>
				<path 
					
					stroke="${borderColor}"
					stroke-width="${borderWidth}"
					d="${path}" fill="none"
				/>
				<path 
					stroke="none"
					d="${path}" fill="${shapeColor}"
				/>
			</svg>`;
		}
		

	}
}


@Component({
	template:`
		
	`,
	styles:[
		`
		:host{
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
		}
		`
	]
	
})
export class ROGroupComponent extends ROContainerComponent
{
	// public tagName:string = "Group";
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["Group"];
	}

	protected buildChildren():void
	{
		var containerDOM:HTMLElement = this.elementRef.nativeElement;
		this.children = [];
		var maskDOM:HTMLElement = document.createElement("div");
		containerDOM.appendChild(maskDOM);
		this.node.children.forEach((childNode:XMLNode)=>{
			var child = this.context.createForDOM(maskDOM, childNode, this.page, this);
			this.children.push(
				child
			);
		});
		var len = this.children.length;
		for(var i = 0;i < len;i++)
		{
			var child:any = this.children[i];
			if(child instanceof ROMask)
			{
				
				var mask:ROMask = child;
				mask.renderMask(containerDOM, maskDOM)
			}
		}
		
	}
}
